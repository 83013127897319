import React from 'react';
import oFetch from 'o-fetch';
import Sortable from 'react-sortablejs';

export default function SortableCategoryList(props) {
  const [onChange, items, itemRender] = oFetch(props, 'onChange', 'items', 'itemRender');

  function renderItems() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(itemRender(item), {
        key: itemId.toString(),
      });
    });
  }

  return (
    <Sortable
      options={{
        handle: '.boss-tag__handle',
        animation: 250,
      }}
      className="boss-form__group"
      onChange={onChange}
    >
      {renderItems()}
    </Sortable>
  );
}
