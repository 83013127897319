import React from 'react';
import oFetch from 'o-fetch';
import MossPayRatesForm from './moss-pay-rates-form';

export default function EditMossPayRateModal(props) {
  const [onSubmit, mossPayRatesOptions, mossPayRate] = oFetch(
    props,
    'onSubmit',
    'mossPayRatesOptions',
    'mossPayRate',
  );
  const initialValues = {
    id: oFetch(mossPayRate, 'id'),
    name: oFetch(mossPayRate, 'name'),
    calculationType: oFetch(mossPayRate, 'calculationType'),
    rate: oFetch(mossPayRate, 'rate'),
  };

  return (
    <MossPayRatesForm
      onSubmit={onSubmit}
      mossPayRatesOptions={mossPayRatesOptions}
      initialValues={initialValues}
      buttonTitle="Update"
    />
  );
}
