import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';
import { fromJS } from 'immutable';

import { openWarningModal } from '@/components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';

import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import Dashboard from '../components/dashboard';
import StaffMemberCard from '../components/staff-member-card';
import StaffMemberProfileActions from '../components/staff-member-profile-actions';
import EditProfilePage from '../components/edit-profile-page';
import EnableProfile from '../components/enable-profile';
import { AddLinkedUserModal } from '../components/add-linked-user-modal';
import DashboardProfile from '../components/dashboard-profile';
import {
  currentStaffMemberSelector,
  getAccessoriesInPossession,
  getAccessoriesInPossessionFlatList,
  getDisableFormAccessoriesFlatList,
  staffTypesSelector,
  hasLinkedUserSelector,
} from '../selectors';
import ContentModal from '@/components/content-modal';
import { DisableStaffMemberModal } from '../components/disable-staff-member-modal';
import EditAvatarForm from '../components/edit-avatar-form';
import { useModal } from '@/components/hooks-components/modals';

import confirm from '@/lib/confirm-utils';

import {
  editProfile as startEditProfile,
  enableStaffMember,
  cancelEditProfile,
  disableStaffMemberRequest,
  showEditAvatarModal,
  hideEditAvatarModal,
  markRetakeAvatar,
  addLinkedUserAction,
} from '../actions';

const mapStateToProps = state => {
  return {
    staffMember: currentStaffMemberSelector(state),
    staffMemberAccessories: getAccessoriesInPossessionFlatList(state),
    staffMemberDisableAccessories: getDisableFormAccessoriesFlatList(state),
    hasLinkedUser: hasLinkedUserSelector(state),
    editProfile: state.getIn(['profile', 'editProfile']),
    staffTypes: staffTypesSelector(state),
    genderValues: state.getIn(['profile', 'genderValues']),
    editAvatarModal: state.getIn(['profile', 'editAvatarModal']),
    venues: state.getIn(['profile', 'venues']),
    accessibleVenues: state.getIn(['profile', 'accessibleVenues']),
    payRates: state.getIn(['profile', 'payRates']),
    accessiblePayRates: state.getIn(['profile', 'accessiblePayRates']),
    reviveFormConfig: state.getIn(['profile', 'reviveFormConfig']),
    accessibleBaseStandardSecurityPayRates: state.getIn([
      'profile',
      'accessibleBaseStandardSecurityPayRates',
    ]),
    accessibleBaseStewardSecurityPayRates: state.getIn(['profile', 'accessibleBaseStewardSecurityPayRates']),
    permissionsData: state.getIn(['profile', 'permissionsData']),
    accessoriesInPossession: getAccessoriesInPossession(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        startEditProfile,
        cancelEditProfile,
        disableStaffMemberRequest,
        showEditAvatarModal,
        hideEditAvatarModal,
        enableStaffMember,
        markRetakeAvatar,
        addLinkedUserAction,
      },
      dispatch,
    ),
  };
};

const isActive = (currentPage, page) => {
  return currentPage === page ? 'boss-button_state_active' : '';
};

function ProfileWrapper(props) {
  const { openModal } = useModal();

  useEffect(() => {
    require('../styles.sass');
  }, []);

  const [
    staffMember,
    editProfile,
    accessibleVenues,
    staffTypes,
    accessiblePayRates,
    genderValues,
    editAvatarModal,
    currentPage,
    permissionsData,
    venues,
    accessibleBaseStandardSecurityPayRates,
    accessibleBaseStewardSecurityPayRates,
    accessoriesInPossession,
    startEditProfile,
    cancelEditProfile,
    showEditAvatarModal,
    hideEditAvatarModal,
    disableStaffMemberRequest,
    enableStaffMember,
    markRetakeAvatar,
    addLinkedUserAction,
    staffMemberAccessories,
    staffMemberDisableAccessories,
  ] = oFetch(
    props,
    'staffMember',
    'editProfile',
    'accessibleVenues',
    'staffTypes',
    'accessiblePayRates',
    'genderValues',
    'editAvatarModal',
    'currentPage',
    'permissionsData',
    'venues',
    'accessibleBaseStandardSecurityPayRates',
    'accessibleBaseStewardSecurityPayRates',
    'accessoriesInPossession',
    'actions.startEditProfile',
    'actions.cancelEditProfile',
    'actions.showEditAvatarModal',
    'actions.hideEditAvatarModal',
    'actions.disableStaffMemberRequest',
    'actions.enableStaffMember',
    'actions.markRetakeAvatar',
    'actions.addLinkedUserAction',
    'staffMemberAccessories',
    'staffMemberDisableAccessories',
  );
  const staffMemberJS = oFetch(props, 'staffMember').toJS();
  const staffTypesJS = oFetch(props, 'staffTypes').toJS();
  const [hasUnhandledAccessories, hasLinkedUser, hasInvite] = oFetch(
    staffMemberJS,
    'hasUnhandledAccessories',
    'hasLinkedUser',
    'hasInvite',
  );

  function handleDisableStaffMember(params) {
    const [closeModal, values] = oFetch(params, 'closeModal', 'values');

    if (hasLinkedUser) {
      return confirm(
        "Deleting this staff member will also delete it's associated user account. They would no longer be able to clock in to jsm systems.",
        {
          actionButtonText: 'Confirm',
          title: 'WARNING !!!',
        },
      ).then(() => {
        return disableStaffMemberRequest({ values: values.toJS(), onSuccess: closeModal });
      });
    } else {
      return disableStaffMemberRequest({ values: values.toJS(), onSuccess: closeModal });
    }
  }

  function handleEnableProfileSubmit(hideModal, values) {
    return enableStaffMember({ values, onSuccess: hideModal });
  }

  function openAddLinkedUserModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddLinkedUserModal,
      config: {
        title: 'Add Linked User',
      },
      onSubmit(closeModal, values) {
        return addLinkedUserAction({
          values,
          onSuccess() {
            closeModal();
            openCompleteAddLinkedUserModal();
          },
        });
      },
      props: {
        canBypassJsmMailbox: oFetch(permissionsData.toJS(), 'canBypassJsmMailbox'),
        staffMember: staffMemberJS,
        initialValues: {
          email: '',
          role: null,
          jsmBarsApplicationPermissionData: [],
          bypassJsmMailbox: false,
        },
      },
    });
  }

  function openCompleteAddLinkedUserModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ({ onSubmit }) => {
        return (
          <Fragment>
            <div className="boss-modal-window__message-block">
              <p className="boss-modal-window__message-text">Confirmaiton Email Sent to new email address.</p>
              <p className="boss-modal-window__message-text">
                Linked user will be created when staff member uses email to sets their new password
              </p>
            </div>
            <div className="boss-modal-window__actions">
              <button onClick={onSubmit} className="boss-button boss-modal-window__button">
                Dismiss
              </button>
            </div>
          </Fragment>
        );
      },
      config: {
        title: 'Almost done!',
        baseClassName: 'boss-modal-window boss-modal-window_role_almost-done',
      },
      onSubmit(closeModal) {
        closeModal();
      },
      props: {},
    });
  }

  function openEnableProfileModal(args) {
    const reviveFormConfig = oFetch(props, 'reviveFormConfig').toJS();
    const staffMember = oFetch(args, 'staffMember');
    const venues = oFetch(args, 'venues');
    const isSecurity = oFetch(staffMember, 'is_security_staff');
    const payRates = oFetch(args, 'payRates');
    const accessibleBaseStandardSecurityPayRates = oFetch(args, 'accessibleBaseStandardSecurityPayRates');
    const accessibleBaseStewardSecurityPayRates = oFetch(args, 'accessibleBaseStewardSecurityPayRates');
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EnableProfile,
      onSubmit: handleEnableProfileSubmit,
      props: {
        staffMember,
        venues,
        isSecurity,
        accessibleBaseStandardSecurityPayRates,
        accessibleBaseStewardSecurityPayRates,
        payRates,
        reviveFormConfig,
        staffTypes: staffTypesJS,
      },
      config: {
        title: 'Enable Staff Member',
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
      },
    });
  }

  function tryOpenDisableStaffMemberModal(params) {
    if (hasUnhandledAccessories) {
      openWarningModal({
        submit: handleClose => {
          handleClose();
        },
        config: {
          title: `You can't disable this staff member`,
          text: 'You must resolve all pending accessory requests to continue.',
          buttonText: 'Dismiss',
        },
        props: {},
      });
    } else {
      return openDisableStaffMemberModal();
    }
  }

  function openDisableStaffMemberModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: DisableStaffMemberModal,
      onSubmit(closeModal, values) {
        return handleDisableStaffMember({ staffMember, closeModal, values });
      },
      props: {
        accessories: staffMemberDisableAccessories,
        initialValues: {
          neverRehire: false,
          disableReason: '',
          accessoryRequests: getAccessoriesFormValues({
            staffMemberAccessories: staffMemberDisableAccessories,
          }),
        },
      },
      config: {
        title: 'Disable Staff Member',
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
      },
    });
  }

  function renderCardActions(currentPage, isSecurityStaff, canViewDisciplinary) {
    const staffMember = oFetch(props, 'staffMember');
    const staffMemberId = oFetch(staffMember.toJS(), 'id');

    return (
      <div className="boss-page-dashboard__switches">
        <a
          href={`/staff_members/${staffMemberId}/profile`}
          className={`${isActive(
            currentPage,
            'profile',
          )} boss-button boss-button_type_small boss-button_role_profile boss-page-dashboard__switch`}
        >
          Profile
        </a>
        <a
          href={`/staff_members/${staffMemberId}/holidays`}
          className={`${isActive(
            currentPage,
            'holidays',
          )} boss-button boss-button_type_small boss-button_role_holidays boss-page-dashboard__switch`}
        >
          Holidays
        </a>
        {!isSecurityStaff && (
          <a
            href={`/staff_members/${staffMemberId}/owed_hours`}
            className={`${isActive(
              currentPage,
              'owed_hours',
            )} boss-button boss-button_type_small boss-button_role_timelog boss-page-dashboard__switch`}
          >
            Owed hours
          </a>
        )}
        <a
          href={`/staff_members/${staffMemberId}/shifts`}
          className={`${isActive(
            currentPage,
            'shifts',
          )} boss-button boss-button_type_small boss-button_role_timelog boss-page-dashboard__switch`}
        >
          Shifts
        </a>
        {!isSecurityStaff && (
          <a
            href={`/staff_members/${staffMemberId}/accessories`}
            className={`${isActive(
              currentPage,
              'accessories',
            )} boss-button boss-button_type_small boss-button_role_accessories boss-page-dashboard__switch`}
          >
            Accessories
          </a>
        )}
        <a
          href={`/staff_members/${staffMemberId}/payments`}
          className={`${isActive(
            currentPage,
            'payments',
          )} boss-button boss-button_type_small boss-button_role_payments boss-page-dashboard__switch`}
        >
          Payments
        </a>
        {canViewDisciplinary && (
          <a
            href={`/staff_members/${staffMemberId}/disciplinaries`}
            className={`${isActive(
              currentPage,
              'disciplinary',
            )} boss-button boss-button_type_small boss-button_role_disciplinary boss-page-dashboard__switch`}
          >
            Disciplinary
          </a>
        )}
        <a
          href={`/staff_members/${staffMemberId}/quizzes`}
          className={`${isActive(
            currentPage,
            'quizzes',
          )} boss-button boss-button_type_small boss-button_role_quizzes boss-page-dashboard__switch`}
        >
          Quizzes
        </a>
      </div>
    );
  }

  function handleMarkRetakeAvatar(handleClose, { staffMemberId }) {
    return markRetakeAvatar(staffMemberId).finally(resp => {
      handleClose();
    });
  }

  function handleMarkRetakeAvatarModal() {
    const staffMember = oFetch(props, 'staffMember');
    const staffMemberId = oFetch(staffMember.toJS(), 'id');

    openWarningModal({
      submit: handleMarkRetakeAvatar,
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Force Retake',
      },
      props: { staffMemberId },
    });
  }

  function renderContent(permissionsData, currentPage, editProfile, profileProps) {
    if (editProfile) {
      return <EditProfilePage permissionsData={permissionsData} {...profileProps} />;
    }
    if (currentPage === 'disciplinaries' || currentPage === 'quizzes') {
      return props.children;
    } else {
      return <div className="boss-page-main__flow">{props.children}</div>;
    }
  }

  function getAccessoriesFormValues(params) {
    const staffMemberAccessories = oFetch(params, 'staffMemberAccessories');
    return staffMemberAccessories.map(staffMemberAccessory => {
      const requestId = staffMemberAccessory.get('requestId');
      return fromJS({
        requestId: requestId,
        status: null,
      });
    });
  }

  const profileProps = {
    accessibleVenues,
    staffTypes,
    accessiblePayRates,
    genderValues,
    staffMember,
    permissionsData,
    accessibleBaseStandardSecurityPayRates,
    accessibleBaseStewardSecurityPayRates,
    accessories: staffMemberDisableAccessories,
    accessoryRequests: getAccessoriesFormValues({
      staffMemberAccessories: staffMemberDisableAccessories,
    }),
  };
  const permissionsDataJS = permissionsData.toJS();
  const jsStaffMember = staffMember.toJS();
  const editAvatarFormInitial = {
    avatar: oFetch(jsStaffMember, 'avatar'),
    setupForFacialRecognition: true,
  };
  const jobType = staffTypes.find(type => type.get('id') === staffMember.get('staff_type'));
  const isSecurityStaff = oFetch(jsStaffMember, 'is_security_staff');
  const canViewDisciplinary = oFetch(permissionsDataJS, 'disciplinariesTab.canViewPage');
  const markedRetakeAvatar = oFetch(jsStaffMember, 'markedRetakeAvatar');
  const canCreateLinkedUser = oFetch(permissionsDataJS, 'canCreateLinkedUser');
  const showCreateInviteButton = !hasInvite && !hasLinkedUser && canCreateLinkedUser;

  return (
    <Fragment>
      <ContentModal show={editAvatarModal} onClose={hideEditAvatarModal} title="Edit Avatar">
        <EditAvatarForm markedRetakeAvatar={markedRetakeAvatar} initialValues={editAvatarFormInitial} />
      </ContentModal>
      <DashboardWrapper>
        {editProfile && (
          <DashboardProfile title="Edit Profile" onCancel={cancelEditProfile} buttonText="Cancel editing" />
        )}
        {!editProfile && (
          <Dashboard>
            <div className="boss-page-dashboard__group">
              <StaffMemberCard
                currentPage={currentPage}
                staffMember={jsStaffMember}
                accessoriesInPossession={accessoriesInPossession}
                jobType={jobType.toJS()}
                onEditAvatar={showEditAvatarModal}
                onMarkRetakeAvatar={handleMarkRetakeAvatarModal}
                venues={venues.toJS()}
                permissionsData={permissionsData}
              />
              <StaffMemberProfileActions
                staffMember={staffMember}
                permissionsData={permissionsData}
                onEditProfile={startEditProfile}
                onDisableStaffMember={tryOpenDisableStaffMemberModal}
                onAddLinkedUser={openAddLinkedUserModal}
                showCreateInviteButton={showCreateInviteButton}
                onEnableProfile={() =>
                  openEnableProfileModal({
                    payRates: accessiblePayRates,
                    staffMember: staffMember.toJS(),
                    venues: venues.toJS(),
                    accessibleBaseStandardSecurityPayRates,
                    accessibleBaseStewardSecurityPayRates,
                  })
                }
              />
            </div>
            {renderCardActions(currentPage, isSecurityStaff, canViewDisciplinary)}
          </Dashboard>
        )}
      </DashboardWrapper>

      <ContentWrapper>
        {renderContent(permissionsData, currentPage, editProfile, profileProps)}
      </ContentWrapper>
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper);
