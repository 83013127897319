import React from 'react';
import oFetch from 'o-fetch';

export function HeaderWrapper(props) {
  const [children, title, url, prefix] = oFetch(props, 'children', 'title', 'url', 'prefix');

  return (
    <div className={`${prefix}-page-header__inner`}>
      <div className={`${prefix}-page-header__logo-group`}>
        <a href={url} className={`${prefix}-page-header__logo`}>
          {title}
        </a>
      </div>
      {children}
    </div>
  );
}
