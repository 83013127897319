import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';

import reducers from './redux';
import { loadInitialData } from './redux/actions';
import SecurityRotaOverviewPage from './containers/security-rota-overview-page';

export function SecurityRotaOverviewApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const { accessToken } = props;
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    setStore(store);
    store.dispatch(loadInitialData(props));
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <SecurityRotaOverviewPage />
    </Provider>
  );
}
