import oFetch from 'o-fetch';
import React from 'react';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export function WagePercentage(props) {
  const venueWagePercentage = oFetch(props, 'venueWagePercentage');
  const [wagePercentageCents, adjustedWagePercentageCents, thresholdCents, lastUpdatedAt] = oFetch(
    venueWagePercentage,
    'wagePercentageCents',
    'adjustedWagePercentageCents',
    'thresholdCents',
    'lastUpdatedAt',
  );
  const pendingUpdate = oFetch(venueWagePercentage, "pendingUpdate");

  const wagePercentage = wagePercentageCents / 100.0;
  const adjustedWagePercentage = adjustedWagePercentageCents / 100.0;
  const threshold = thresholdCents / 100.0;

  const maxPercentage = Math.max(wagePercentage, adjustedWagePercentage, 100.0, threshold);

  const wagePercentageRatio = wagePercentage / maxPercentage;
  const adjustedWagePercentageRatio = adjustedWagePercentage / maxPercentage;
  const thresholdRatio = threshold / maxPercentage;

  const scaledWagePercentage = wagePercentage * wagePercentageRatio;
  const scaledAdjustedWagePercentage = adjustedWagePercentage * adjustedWagePercentageRatio;
  const scaledThreshold = threshold * thresholdRatio;

  const formattedLastUpdatedAt = safeMoment
    .iso8601Parse(lastUpdatedAt)
    .format(utils.humanDateFormatWithTime());
  return (
    <div className="boss-board__header-group">
      <div className="boss-progress-bar boss-progress-bar_adjust_wrap">
        <div className="boss-progress-bar__content">
          <div className="boss-progress-bar__info boss-progress-bar__info_position_before">
            <p className="boss-progress-bar__label boss-progress-bar__label_color_accent-purple">
              Wage Percentage { pendingUpdate && " (Data Pending Update)"}
            </p>
            <p className="boss-progress-bar__meta">Last Updated at: {formattedLastUpdatedAt}</p>
          </div>
          <div className="boss-progress-bar__meter">
            <div
              className="boss-progress-bar__value boss-progress-bar__value_position_front boss-progress-bar__value_color_accent-green"
              style={{ width: `${scaledAdjustedWagePercentage}%` }}
            >
              {`${adjustedWagePercentage}%`}
            </div>
            <div
              className="boss-progress-bar__value boss-progress-bar__value_position_back"
              style={{ width: `${scaledWagePercentage}%` }}
            >
              {`${wagePercentage}%`}
            </div>
            <div
              className="boss-progress-bar__marker boss-progress-bar__marker_color_accent-carmine"
              style={{ left: `${scaledThreshold}%` }}
            >
              <div className="boss-progress-bar__marker-line" />
              <p className="boss-progress-bar__marker-text">{`${threshold}%`}</p>
            </div>
          </div>
          <div className="boss-table boss-table_page_bonai-venue-hour-data">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_row">
                    <div className="boss-number__content">
                      <p className="boss-number__label boss-number__label_position_before">
                        Wage Percentage:
                      </p>
                      <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                        {wagePercentage}%
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_row">
                    <div className="boss-number__content">
                      <p className="boss-number__label boss-number__label_position_before">
                        Adjusted Wage Percentage:
                      </p>
                      <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                        {adjustedWagePercentage}%
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_row">
                    <div className="boss-number__content">
                      <p className="boss-number__label boss-number__label_position_before">
                        Target Threshold:
                      </p>
                      <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                        {threshold}%
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
