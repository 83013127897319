import React from 'react';
import oFetch from 'o-fetch';
import { DashboardWrapper } from '../../components/dashboard';

export default function CountingDashboard(props) {
  const [onBack, weekStart, weekEnd, weekCount, currentVenueName] = oFetch(
    props,
    'onBack',
    'weekStart',
    'weekEnd',
    'weekCount',
    'currentVenueName',
  );

  function getLastUpdated() {
    if (weekCount === null) {
      return null;
    }
    const lastEventData = oFetch(weekCount, 'lastEventData');
    const [formattedEventType, eventBy, formattedEventAt] = oFetch(
      lastEventData,
      'formattedEventType',
      'eventBy',
      'formattedEventAt',
    );
    return (
      <span className="purple-page-main__title-faded">
        ({formattedEventType} by {eventBy} on {formattedEventAt})
      </span>
    );
  }

  return (
    <DashboardWrapper>
      <div className="purple-page-main__info-group">
        <div className="purple-page-main__info-group-area">
          <h1 className="purple-page-main__title">
            Counting History - {currentVenueName} {getLastUpdated()}
          </h1>
          <div className="purple-page-main__info-group-meta">
            <p className="purple-meta purple-meta_type_light purple-meta_size_xl purple-meta_icon_date">
              <span className="purple-meta__text">
                {weekStart} - {weekEnd}
              </span>
            </p>
          </div>
        </div>
        <div className="purple-page-main__info-group-area">
          <div className="purple-page-main__info-group-actions">
            <button
              onClick={onBack}
              type="button"
              className="purple-button purple-button_color_accent-primary purple-button_size_m purple-page-main__info-group-action"
            >
              <span className="purple-button__text">Back</span>
            </button>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
