import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import ProfileWrapper from '../profile-wrapper';
import * as selectors from './redux/selectors';
import * as commonSelectors from '../profile-wrapper/selectors';
import * as actions from './redux/actions';

import {
  Stats,
  CategoriesFilter,
  CategoriesHeader,
  CategoriesList,
  CategoryItem,
  FirstTimeCategoriesHeader,
  FirstTimeCategoryItem,
} from './components';

function Quizzes(props) {
  const [
    quizzesStats,
    staffMemberCategories,
    firstTimeStaffMemberCategories,
    firstRank,
    changeCategoryFilter,
    activeFilter,
    currentStaffMember,
  ] = oFetch(
    props,
    'quizzesStats',
    'staffMemberCategories',
    'firstTimeStaffMemberCategories',
    'firstRank',
    'changeCategoryFilter',
    'activeFilter',
    'currentStaffMember',
  );

  function renderFirstTimeCategoriesHeader() {
    const rankName = oFetch(firstRank, 'name');
    return <FirstTimeCategoriesHeader rankName={rankName} />;
  }

  function renderFirstTimeCategory(category) {
    return <FirstTimeCategoryItem category={category} />;
  }

  function renderCategoriesHeader() {
    return (
      <CategoriesHeader>
        <CategoriesFilter activeFilter={activeFilter} onChange={changeCategoryFilter} />
      </CategoriesHeader>
    );
  }

  function renderCategoryHeaderActions(categoryHistory) {
    const quizCategoryId = oFetch(categoryHistory, 'quizCategoryId');
    return (
      <div className="boss-check__header-actions">
        <a
          href={`quizzes/${quizCategoryId}/category-history`}
          className="boss-button boss-button_role_view-history-alt boss-button_type_extra-small boss-check__header-action"
        >
          History
        </a>
      </div>
    );
  }

  function renderCategory(category) {
    return <CategoryItem renderActions={renderCategoryHeaderActions} category={category} />;
  }

  return (
    <ProfileWrapper currentPage="quizzes">
      <Stats stats={quizzesStats} />
      <CategoriesList
        firstTime={true}
        renderHeader={renderFirstTimeCategoriesHeader}
        categories={firstTimeStaffMemberCategories}
        renderCategory={renderFirstTimeCategory}
      />
      <CategoriesList
        firstTime={false}
        categories={staffMemberCategories}
        renderHeader={renderCategoriesHeader}
        renderCategory={renderCategory}
      />
    </ProfileWrapper>
  );
}

function mapStateToProps(state) {
  return {
    quizzesStats: selectors.getQuizzesStats(state),
    staffMemberCategories: selectors.getStaffMemberCategories(state),
    firstTimeStaffMemberCategories: selectors.getFirstTimeStaffMemberCategories(state),
    firstRank: selectors.getFirstRank(state),
    activeFilter: selectors.activeFilterSelector(state),
    currentStaffMember: commonSelectors.currentStaffMemberSelector(state),
  };
}

const mapDispatchToProps = {
  changeCategoryFilter: actions.changeCategoryFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quizzes);
