import React from 'react';
import oFetch from 'o-fetch';
import { BigNumber } from 'bignumber.js';
import { PaymentForm } from './payment-form';

export function EditPayment(props) {
  const [onSubmit, bonusPayment] = oFetch(props, 'onSubmit', 'bonusPayment');
  const initialValues = {
    id: oFetch(bonusPayment, 'id'),
    staffMemberId: oFetch(bonusPayment, 'staffMemberId'),
    year: oFetch(bonusPayment, 'year'),
    quarter: oFetch(bonusPayment, 'quarter'),
    amountCents: new BigNumber(oFetch(bonusPayment, 'amountCents')).div(100),
  };
  return (
    <PaymentForm initialValues={initialValues} onSubmit={onSubmit} buttonText="Update Bonus Payment" edit />
  );
}
