import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import utils from '@/lib/utils';
import ComponentErrors from '@/components/component-errors';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';

export default class RotaForecast extends React.Component {
  static propTypes = {
    rotaForecast: PropTypes.object.isRequired,
    forecastedTake: PropTypes.string.isRequired,
    canEditForecastedTake: PropTypes.bool,
    onForecastedTakeChanged: PropTypes.func,
    onUpdateForecastClick: PropTypes.func,
    isUpdatingForecast: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const _rotaForecast = oFetch(props, 'rotaForecast');
    this.state = {
      forecastedTake: utils.formatMoney(oFetch(_rotaForecast, 'forecasted_take_cents') / 100),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const _rotaForecast = oFetch(nextProps, 'rotaForecast');
    this.setState({
      forecastedTake: utils.formatMoney(oFetch(_rotaForecast, 'forecasted_take_cents') / 100),
    });
  }

  render() {
    return (
      <div className="boss-board__rota">
        <div className="boss-forecast">
          {this.getForecastHeaderRow()}
          {this.getErrorComponent()}
          {this.getForecastBody()}
          {this.getForecastFooter()}
        </div>
      </div>
    );
  }

  onUpdateForecast = () => {
    this.setState(
      {
        forecastedTake: this.state.forecastedTake,
      },
      () => {
        this.props.onUpdateForecastClick(this.state.forecastedTake);
      },
    );
  };

  getForecastBody() {
    var dataRows = getDataRows(this.props.rotaForecast);
    var dataRowComponents = dataRows.map(row => this.getForcastDataRowComponent(row));

    return <div>{dataRowComponents}</div>;
  }

  getForecastFooter() {
    const rotaForecast = oFetch(this.props, 'rotaForecast');
    const requiringUpdate = oFetch(rotaForecast, 'requiring_update');
    var dataRows = getFooterRows(rotaForecast);
    var dataRowComponents = dataRows.map(row => {
      return this.getStandardDataRowComponent({
        row: row,
        useAlertStyling: false,
        requiringUpdate,
      });
    });

    return <div className="boss-forecast__group boss-forecast__group_role_footer">{dataRowComponents}</div>;
  }

  getForecastHeaderRow() {
    var forecastedTakeComponent = (
      <div>
        &pound;
        {this.state.forecastedTake}
      </div>
    );
    var updateForecastButton = null;

    if (this.props.canEditForecastedTake) {
      forecastedTakeComponent = (
        <div className="boss-forecast__cell">
          <span className="boss-forecast__amount-currency">&pound;&nbsp;</span>
          <input
            data-test-marker-forecasted-take
            value={this.state.forecastedTake || ''}
            className="boss-forecast__amount-input"
            onChange={event => this.setState({ forecastedTake: event.target.value })}
            type="text"
          />
        </div>
      );

      updateForecastButton = (
        <a
          className="boss-button boss-button_type_small"
          data-test-marker-update-forecast-button
          onClick={() => this.onUpdateForecast()}
        >
          Update
        </a>
      );

      if (this.props.isUpdatingForecast) {
        updateForecastButton = <div className="boss-spinner" />;
      }
    }

    return (
      <div className="boss-forecast__row boss-forecast__row_role_header">
        {this.props.isUpdatingForecast ? (
          <div className="boss-spinner" />
        ) : (
          <div className="boss-forecast__row boss-forecast__row_role_header">
            <div className="boss-forecast__cell">Forecast</div>
            <div className="boss-forecast__cell">{forecastedTakeComponent}</div>
            <div className="boss-forecast__cell">{updateForecastButton}</div>
          </div>
        )}
      </div>
    );
  }

  getErrorComponent() {
    return (
      <ComponentErrors
        errorHandlingId={this.props.errorHandlingId}
        extraStyle={{ marginBottom: -10, marginTop: 10 }}
      />
    );
  }

  getForcastDataRowComponent(row) {
    const thresholdPercentage = oFetch(row, 'thresholdPercentage');
    const rowPercentage = oFetch(row, 'percentage');
    // TODO: Replace float with BigNumber?
    const useAlertStyling =
      rowPercentage <= 0 || (thresholdPercentage && rowPercentage > parseFloat(thresholdPercentage));
    const requiringUpdate = oFetch(this.props.rotaForecast, 'requiring_update');

    return this.getStandardDataRowComponent({
      row: row,
      useAlertStyling: useAlertStyling,
      thresholdPercentage,
      requiringUpdate,
    });
  }

  getPercentageBadgeContent = ({ useAlertStyling, percentage, thresholdPercentage }) => {
    const rowPercentageClass = useAlertStyling ? 'boss-button_role_alert' : 'boss-button_role_secondary';
    const badgeContent = (
      <p
        className={
          'boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_secondary ' +
          rowPercentageClass
        }
      >
        {percentage !== null ? Math.round(percentage * 100) / 100 + '%' : '0%'}
      </p>
    );
    if (thresholdPercentage === null) {
      return badgeContent;
    }
    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={this.getThresholdContent(thresholdPercentage)}
      >
        {badgeContent}
      </Tooltip>
    );
  };

  getThresholdContent = thresholdPercentage => {
    return (
      <div className="boss-tooltip-portal__content">
        <div className="boss-tooltip-portal__text">
          {'Threshold: '}
          <span className="boss-tooltip-portal__text-marked">{thresholdPercentage}%</span>
        </div>
      </div>
    );
  };

  getStandardDataRowComponent({ thresholdPercentage = null, requiringUpdate, ...options }) {
    const row = oFetch(options, 'row');

    const useAlertStyling = oFetch(options, 'useAlertStyling');
    const rowClassNames = cn('boss-forecast__row', {
      'boss-forecast__row_state_calculating': requiringUpdate,
    });
    return (
      <div className={rowClassNames} key={row.title}>
        <div className="boss-forecast__cell">{row.title}</div>
        {requiringUpdate && <div className="boss-forecast__cell">N/A</div>}
        {!requiringUpdate && (
          <div className="boss-forecast__cell">&pound;{utils.formatMoney(row.total / 100)}</div>
        )}
        {!requiringUpdate && (
          <div className="boss-forecast__cell">
            {row.percentage !== undefined &&
              this.getPercentageBadgeContent({
                useAlertStyling,
                percentage: row.percentage,
                thresholdPercentage,
              })}
          </div>
        )}
        {requiringUpdate && (
          <div className="boss-forecast__cell">
            <div className="boss-indicator">
              <span className="boss-indicator__icon boss-indicator__icon_calculator" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function getFooterRows(rotaForecast) {
  return [
    {
      title: 'Tax & NI',
      total: oFetch(rotaForecast, 'tax_and_ni_cents'),
    },
    {
      title: 'Total',
      total: oFetch(rotaForecast, 'total_cents'),
      percentage: oFetch(rotaForecast, 'total_percentage'),
    },
  ];
}

function getDataRows(rotaForecast) {
  return [
    {
      title: 'Overheads',
      total: oFetch(rotaForecast, 'overhead_total_cents'),
      percentage: oFetch(rotaForecast, 'overhead_total_percentage'),
      thresholdPercentage: oFetch(rotaForecast, 'venue_overheads_threshold_percentage'),
    },
    {
      title: 'Staff',
      total: oFetch(rotaForecast, 'staff_total_cents'),
      percentage: oFetch(rotaForecast, 'staff_total_percentage'),
      thresholdPercentage: oFetch(rotaForecast, 'venue_staff_threshold_percentage'),
    },
    {
      title: 'PRs',
      total: oFetch(rotaForecast, 'pr_total_cents'),
      percentage: oFetch(rotaForecast, 'pr_total_percentage'),
      thresholdPercentage: oFetch(rotaForecast, 'venue_pr_threshold_percentage'),
    },
    {
      title: 'Kitchen',
      total: oFetch(rotaForecast, 'kitchen_total_cents'),
      percentage: oFetch(rotaForecast, 'kitchen_total_percentage'),
      thresholdPercentage: oFetch(rotaForecast, 'venue_kitchen_threshold_percentage'),
    },
    {
      title: 'Security',
      total: oFetch(rotaForecast, 'security_total_cents'),
      percentage: oFetch(rotaForecast, 'security_total_percentage'),
      thresholdPercentage: oFetch(rotaForecast, 'venue_security_threshold_percentage'),
    },
  ];
}
