import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import oFetch from 'o-fetch';
import Disciplinary from './containers/disciplinary';
import configureStore from '../store';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { initialProfileLoad } from '../profile-wrapper/actions';
import profileReducer from '../profile-wrapper/reducers';
import disciplinariesReducer from './redux/reducers/disciplinaries-reducer';
import filterReducer from './redux/reducers/filter-reducer';
import warningLimitsReducer from './redux/reducers/warning-limits-reducer';
import warningsReducer from './redux/reducers/warnings-reducer';
import permissionsReducer from './redux/reducers/permissions-reducer';
import companyNameReducer from './redux/reducers/company-name-reducer';
import appealToNameReducer from './redux/reducers/appeal-to-name-reducer';
import currentUserFullNameReducer from './redux/reducers/current-user-full-name-reducer';
import { ModalsProvider } from '@/components/hooks-components/modals';

export function StaffMemberProfileDisciplinaryApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    require('./styles.css');
    const store = configureStore(
      combineReducers({
        profile: profileReducer,
        disciplinaries: disciplinariesReducer,
        filter: filterReducer,
        permissions: permissionsReducer,
        warnings: warningsReducer,
        warningLimits: warningLimitsReducer,
        companyName: companyNameReducer,
        appealToName: appealToNameReducer,
        currentUserFullName: currentUserFullNameReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <Disciplinary />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
