import React from 'react';
import Modal from 'react-modal';
import oFetch from 'o-fetch';
import cn from 'classnames';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

import modalDecorator from './modal-decorator';

class CustomContentModal extends React.Component {
  render() {
    const [modalClass, children] = oFetch(this.props, 'modalClass', 'children');
    const modalClassNames = cn('ReactModal__Content ReactModal__Content--after-open boss-modal-window', {
      [modalClass]: true,
    });
    return (
      <Modal isOpen={true} contentLabel="Modal" ariaHideApp={false} className={modalClassNames}>
        {children}
      </Modal>
    );
  }
}

function openCustomModal({ modalClass }, props = {}, onSubmit, onClose, rootWrapper, closeCallback) {
  const whenCloseClicked = () => {
    closeCallback();
    onClose();
  };

  return function (Component) {
    rootWrapper.render(
      <ReactErrorsBoundaryWrapper>
        <CustomContentModal modalClass={modalClass}>
          <Component onSubmit={onSubmit} onClose={whenCloseClicked} {...props} />
        </CustomContentModal>
      </ReactErrorsBoundaryWrapper>,
    );
  };
}

export default modalDecorator(openCustomModal);
