import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
export class ColoredMultipleValue extends React.Component {
  handleMouseDown = event => {
    if (event.type === 'mousedown' && event.button !== 0) {
      return;
    }
    if (this.props.onClick) {
      event.stopPropagation();
      this.props.onClick(this.props.value, event);
      return;
    }
    if (this.props.value.href) {
      event.stopPropagation();
    }
  };

  onRemove = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onRemove(this.props.value);
  };

  handleTouchEndRemove = event => {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) return;

    // Fire the mouse events
    this.onRemove(event);
  };

  handleTouchMove = event => {
    // Set a flag that the view is being dragged
    this.dragging = true;
  };

  handleTouchStart = event => {
    // Set a flag that the view is not being dragged
    this.dragging = false;
  };

  render() {
    return (
      <div
        className="Select-value"
        style={{ backgroundColor: this.props.value.color }}
      >
        <span
          className="Select-value-icon"
          onMouseDown={this.onRemove}
          onTouchEnd={this.handleTouchEndRemove}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
        >
          ×
        </span>
        <span className="Select-value-label">{this.props.children}</span>
      </div>
    );
  }
}

export class ColoredSingleValue extends React.Component {
  handleMouseDown = event => {
    if (event.type === 'mousedown' && event.button !== 0) {
      return;
    }
    if (this.props.onClick) {
      event.stopPropagation();
      this.props.onClick(this.props.value, event);
      return;
    }
    if (this.props.value.href) {
      event.stopPropagation();
    }
  };

  handleTouchEndRemove = event => {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) return;

    // Fire the mouse events
    this.onRemove(event);
  };

  handleTouchMove = event => {
    // Set a flag that the view is being dragged
    this.dragging = true;
  };

  handleTouchStart = event => {
    // Set a flag that the view is not being dragged
    this.dragging = false;
  };

  render() {
    return (
      <div
        className="Select-value"
        style={{ color: '#4c4c4c' }}
      >
        <span
          className="Select-color-indicator"
          style={{ backgroundColor: this.props.value.color }}
        />
        {this.props.children}
      </div>
    );
  }
}

export class ColoredOption extends React.Component {
  handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };

  handleMouseEnter = event => {
    this.props.onFocus(this.props.option, event);
  };

  handleMouseMove = event => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  render() {
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
        style={{ backgroundColor: this.props.option.color, color: '#FFFFFF' }}
      >
        {this.props.children}
      </div>
    );
  }
}

export const ColoredSingleOption = selectOption(props => {
  return (
    <div>
      <span
        className="Select-color-indicator"
        style={{ backgroundColor: props.option.color }}
      />
      <span>{props.option.label}</span>
    </div>
  );
});

export const AccessoryColorOption = selectOption(props => {
  const [color, label] = oFetch(props, 'option.color', 'option.label');
  return (
    <div>
      <span
        className="Select-color-indicator Select-color-indicator_type_square"
        style={{ backgroundColor: color }}
      />
      <span>{label}</span>
    </div>
  );
});

export const AccessoryColorValue = selectValue(props => {
  return (
    <span className="Select-value-label">
      <span
        className="Select-color-indicator Select-color-indicator_type_square"
        style={{ backgroundColor: props.value.color }}
      />
      {props.children}
    </span>
  );
});

export const AccessoryIconOption = selectOption(props => {
  const [value, label] = oFetch(props, 'option.value', 'option.label');
  const {
    option: { color },
  } = props;
  const className = `Select-icon-indicator Select-icon-indicator_${value}`;
  const colorStyle = color ? { color } : {};
  return (
    <span>
      <span
        className={className}
        style={colorStyle}
      />
      {label}
    </span>
  );
});

export const TimeSelectValue = selectValue(props => {
  const [asterisk, children] = oFetch(props, 'value.asterisk', 'children');
  return (
    <span
      className="Select-value-label"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {asterisk && <span style={{
        fontSize: '18px',
        fontWeight: '800',
        color: 'red',
        marginRight: '2px',
      }}
                   >*</span>}
      <span>{children}</span>
    </span>
  );
});

export class TimeSelectOption extends React.Component {
  handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };

  handleMouseEnter = event => {
    this.props.onFocus(this.props.option, event);
  };

  handleMouseMove = event => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  render() {
    const [asterisk, children] = oFetch(this.props, 'option.asterisk', 'children');

    return (
      <span
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        {asterisk && <span style={{
          fontSize: '18px',
          fontWeight: '800',
          color: 'red',
        }}
                     >*</span>}
        <span>{children}</span>
      </span>
    );
  }
}

export const AccessoryColoredIconOption = selectOption(props => {
  const [icon, label] = oFetch(props, 'option.icon', 'option.label');
  const {
    option: { color },
  } = props;

  const className = `Select-icon-indicator Select-icon-indicator_${icon}`;
  const colorStyle = color ? { color } : {};
  return (
    <span>
      <span
        className={className}
        style={colorStyle}
      />
      {label}
    </span>
  );
});

export const AccessoryIconValue = selectValue(props => {
  const [value, children] = oFetch(props, 'value.value', 'children');
  const {
    value: { color },
  } = props;
  const className = `Select-icon-indicator Select-icon-indicator_${value}`;
  const colorStyle = color ? { color } : {};
  return (
    <span className="Select-value-label">
      <span
        className={className}
        style={colorStyle}
      />
      {children}
    </span>
  );
});

export const AccessoryEmptyValue = selectValue(props => {
  return null;
});

export const AccessoryColoredIconValue = selectValue(props => {
  const [icon, children] = oFetch(props, 'value.icon', 'children');
  const {
    value: { color },
  } = props;
  const className = `Select-icon-indicator Select-icon-indicator_${icon}`;
  const colorStyle = color ? { color } : {};
  return (
    <span className="Select-value-label">
      <span
        className={className}
        style={colorStyle}
      />
      {children}
    </span>
  );
});

function selectOption(Component) {
  return function Option(props) {
    function handleMouseDown(event) {
      const [onSelect, option] = oFetch(props, 'onSelect', 'option');
      event.preventDefault();
      event.stopPropagation();
      onSelect(option, event);
    }

    function handleMouseEnter(event) {
      const [onFocus, option] = oFetch(props, 'onFocus', 'option');
      onFocus(option, event);
    }

    function handleMouseMove(event) {
      const [onFocus, isFocused, option] = oFetch(props, 'onFocus', 'isFocused', 'option');
      if (isFocused) return;
      onFocus(option, event);
    }

    return (
      <div
        className={props.className}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        title={props.option.title}
      >
        <Component {...props} />
      </div>
    );
  };
}

function selectValue(Component) {
  return class Value extends React.Component {
    handleMouseDown = event => {
      if (event.type === 'mousedown' && event.button !== 0) {
        return;
      }
      if (this.props.onClick) {
        event.stopPropagation();
        this.props.onClick(this.props.value, event);
        return;
      }
      if (this.props.value.href) {
        event.stopPropagation();
      }
    };

    handleTouchEndRemove = event => {
      if (this.dragging) return;
      this.onRemove(event);
    };

    handleTouchMove = event => {
      this.dragging = true;
    };

    handleTouchStart = event => {
      this.dragging = false;
    };

    render() {
      return (
        <div className="Select-value">
          <Component {...this.props} />
        </div>
      );
    }
  };
}

export const StaffMemberFullValue = selectValue(props => {
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-avatar">
          <img
            src={props.value.avatarUrl}
            alt={`${props.value.fullName} avatar`}
          />
        </span>
        <span className="Select-staff-member-info">
          <span className="Select-staff-member-name">{props.value.fullName}</span>
          <span className="Select-staff-member-type">
            <span
              className="boss-button boss-button_type_label boss-button_type_no-behavior"
              style={{ backgroundColor: props.value.staffTypeColor }}
            >
              {props.value.staffTypeName}
            </span>
          </span>
        </span>
      </span>
    </>
  );
});

export const StaffMemberFullOption = selectOption(props => {
  const option = oFetch(props, 'option');
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-avatar">
          <img
            src={option.avatarUrl}
            alt={`${oFetch(option, 'fullName')} avatar`}
          />
        </span>
        <span className="Select-staff-member-info">
          <span className="Select-staff-member-name">{oFetch(option, 'fullName')}</span>
          <span className="Select-staff-member-type">
            <span
              className="boss-button boss-button_type_label boss-button_type_no-behavior"
              style={{ backgroundColor: oFetch(option, 'staffTypeColor') }}
            >
              {oFetch(option, 'staffTypeName')}
            </span>
          </span>
        </span>
      </span>
    </>
  );
});

export const UserWithRoleValue = selectValue(props => {
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-info">
          <span className="Select-staff-member-name">{props.value.fullName}</span>
          <span className="Select-staff-member-type">
            <span className="boss-button boss-button_type_label boss-button_type_no-behavior">
              {props.value.role}
            </span>
          </span>
        </span>
      </span>
    </>
  );
});

export const UserWithRoleOption = selectOption(props => {
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-info">
          <span className="Select-staff-member-name">{props.option.fullName}</span>
          <span className="Select-staff-member-type">
            <span className="boss-button boss-button_type_label boss-button_type_no-behavior">
              {props.option.role}
            </span>
          </span>
        </span>
      </span>
    </>
  );
});

export const UserYouValue = selectValue(props => {
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-info">
          <span className="Select-staff-member-name">{props.value.fullName}</span>
        </span>
      </span>
    </>
  );
});

export const UserYouOption = selectOption(props => {
  return (
    <>
      <span className="Select-staff-member">
        <span className="Select-staff-member-info">
          {props.option.you && (
            <span
              className="Select-staff-member-name"
              style={{ color: '#aaa', fontSize: '10' }}
            >
              You{' '}
            </span>
          )}
          <span className="Select-staff-member-name">{props.option.fullName}</span>
        </span>
      </span>
    </>
  );
});

export const ImageOption = selectOption(props => {
  return (
    <span className="Select-staff-member">
      <span
        className="Select-staff-member-avatar"
        style={{ marginRight: '10px' }}
      >
        <img
          src={oFetch(props.option, 'image')}
          alt=""
        />
      </span>
      <span className="Select-staff-member-name">{oFetch(props.option, 'label')}</span>
    </span>
  );
});

export const ImageValue = selectValue(props => {
  return (
    <span className="Select-staff-member">
      <span
        className="Select-staff-member-avatar"
        style={{ marginRight: '10px' }}
      >
        <img
          src={oFetch(props.value, 'image')}
          alt=""
        />
      </span>
      <span className="Select-staff-member-name">{oFetch(props.value, 'label')}</span>
    </span>
  );
});

export const PendingVenueOption = selectOption(props => {
  const hasPendingDevices = oFetch(props.option, 'hasPendingDevices');
  const label = oFetch(props.option, 'name');
  const optionClassNames = cn('Select-option', { 'is-focused': props.isFocused });
  const color = hasPendingDevices ? '#f4a84f' : '#84bef0';

  return (
    <div className={optionClassNames}>
      <span
        className={'Select-color-indicator Select-color-indicator_type_square'}
        style={{ backgroundColor: color }}
      />{' '}
      {label}
    </div>
  );
});

export class PendingVenueValue extends React.Component {
  handleMouseDown = event => {
    if (event.type === 'mousedown' && event.button !== 0) {
      return;
    }
    if (this.props.onClick) {
      event.stopPropagation();
      this.props.onClick(this.props.value, event);
      return;
    }
    if (this.props.value.href) {
      event.stopPropagation();
    }
  };

  onRemove = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onRemove(this.props.value);
  };

  handleTouchEndRemove = event => {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) return;

    // Fire the mouse events
    this.onRemove(event);
  };

  handleTouchMove = event => {
    // Set a flag that the view is being dragged
    this.dragging = true;
  };

  handleTouchStart = event => {
    // Set a flag that the view is not being dragged
    this.dragging = false;
  };

  render() {
    const hasPendingDevices = oFetch(this.props.value, 'hasPendingDevices');
    const color = hasPendingDevices ? '#f4a84f' : '#84bef0';
    return (
      <div
        className="Select-value"
        style={{ backgroundColor: color }}
      >
        <span
          className="Select-value-icon"
          onMouseDown={this.onRemove}
          onTouchEnd={this.handleTouchEndRemove}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
        >
          ×
        </span>
        <span className="Select-value-label">{this.props.children}</span>
      </div>
    );
  }
}
