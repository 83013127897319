import React from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

import { QuestionForm } from './forms';

function AddQuestion(props) {
  const [
    questionTypesOptions,
    questionCategoriesOptions,
    questionAreasOptions,
    requiredQuestionType,
    onSubmit,
    currentVenueId,
  ] = oFetch(
    props,
    'questionTypesOptions',
    'questionCategoriesOptions',
    'questionAreasOptions',
    'requiredQuestionType',
    'onSubmit',
    'currentVenueId',
  );

  const firstType = oFetch(questionTypesOptions[0], 'value');

  const initialValues = {
    venueId: currentVenueId,
    text: null,
    category: null,
    area: null,
    type: firstType,
  };

  return (
    <QuestionForm
      form="add-question-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      questionTypesOptions={questionTypesOptions}
      questionCategoriesOptions={questionCategoriesOptions}
      questionAreasOptions={questionAreasOptions}
      requiredQuestionType={requiredQuestionType}
      submitButtonText="Add Question"
    />
  );
}

const mapStateToProps = state => {
  return {
    questionTypesOptions: selectors.getQuestionTypesOptions(state),
    questionCategoriesOptions: selectors.getAddQuestionCategoriesOptions(state),
    questionAreasOptions: selectors.getAddQuestionAreasOptions(state),
    requiredQuestionType: selectors.requiredQuestionTypeSelector(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
