import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { BonusPaymentStatus } from './bonus-payment-status';

export function BonusPaymentItem(props) {
  const [bonusPayment, onDisable, onEdit] = oFetch(props, 'bonusPayment', 'onDisable', 'onEdit');
  const [
    id,
    staffMemberFullName,
    avatarUrl,
    amountCents,
    createdAt,
    status,
    isDisabled,
    canDisable,
    canUpdate,
    isMarkedNotReceived,
    reportedReceivedCents,
  ] = oFetch(
    bonusPayment,
    'id',
    'staffMemberFullName',
    'avatarUrl',
    'amountCents',
    'createdAt',
    'status',
    'isDisabled',
    'canDisable',
    'canUpdate',
    'isMarkedNotReceived',
    'reportedReceivedCents',
  );

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime());

  const cardClassNames = cn('card-holder m-b-30', { 'card-holder__deleted': isDisabled });

  return (
    <div className={cardClassNames} style={{ borderLeft: 0 }}>
      <div className="boss-check boss-check_role_board boss-check_page_ops-diary-index">
        <div className="boss-check__header">
          <div className="boss-board__button-group">
            <BonusPaymentStatus
              status={status}
              isDisabled={isDisabled}
              isMarkedNotReceived={isMarkedNotReceived}
              reportedReceivedCents={reportedReceivedCents}
            />
          </div>
          <div className="boss-board__button-group">
            {canUpdate && (
              <button
                onClick={() => onEdit({ bonusPayment })}
                className="boss-button boss-button_role_edit boss-button_type_small boss-check__button float-left"
              >
                Edit
              </button>
            )}
            {canDisable && (
              <AsyncButton
                className="boss-button boss-button_role_cancel boss-button_type_small boss-check__button float-left"
                text="Disable"
                pendingText="Processing ..."
                onClick={() => onDisable(id)}
              />
            )}
          </div>
        </div>
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                className="boss-check__box"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <div className="boss-user-summary__avatar">
                  <div className="boss-user-summary__avatar-inner">
                    <img src={avatarUrl} alt={staffMemberFullName} className="boss-user-summary__pic" />
                  </div>
                </div>
                <h3>{staffMemberFullName}</h3>
              </div>
              <div
                className="boss-check__box"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <div className="boss-check__header-tags">
                  <span
                    className="boss-tag boss-tag_size_xxs boss-tag_border_accent-green boss-check__header-tag"
                    style={{ margin: 0 }}
                  >
                    <span className="boss-tag__text" style={{ fontSize: '55px', fontWeight: '700' }}>
                      {utils.centsFormat(amountCents)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="boss-check__created-on">
              <p className="boss-check__created-on-text">
                <strong>Created on: </strong>
                {formattedCreatedAt}
              </p>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
