import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import oFetch from 'o-fetch';
import * as types from '../../profile-wrapper/constants';
import * as historyTypes from './types';
const initialState = Immutable.fromJS({
  startsAt: null,
  endsAt: null,
});

export default handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const filterParams = oFetch(action.payload, 'filterParams');
      const startsAt = oFetch(filterParams, 'starts_at');
      const endsAt = oFetch(filterParams, 'ends_at');
      return Immutable.fromJS({ startsAt, endsAt });
    },
    [historyTypes.HISTORY_FILTER_CHANGE]: (state, action) => {
      const startsAt = oFetch(action.payload, 'startsAt');
      const endsAt = oFetch(action.payload, 'endsAt');
      return state.set('startsAt', startsAt).set('endsAt', endsAt);
    },
  },
  initialState,
);
