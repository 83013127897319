import React from 'react';
import oFetch from 'o-fetch';
import { QuarterSelector } from '@/components/quarter-selector';

export function BossFormQuarterSelect(props) {
  const [input, meta, quarters] = oFetch(props, 'input', 'meta', 'quarters');
  const [value, onChange] = oFetch(input, 'value', 'onChange');
  const { error, touched, submitError } = meta;
  const hasError = touched && (error || submitError);

  function handleSelect(value) {
    onChange(value);
  }

  return (
    <div className="boss-form__field">
      <p className="boss-form__label">
        <span className="boss-form__label-text">Quarter</span>
      </p>
      <QuarterSelector
        selected={value}
        name={oFetch(input, 'name')}
        quarterNumbers={quarters}
        onChange={handleSelect}
      />
      {hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            {(error || submitError).map((errorItem, index) => {
              return (
                <span key={index} className="boss-form__error-line">
                  {errorItem}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </div>
  );
}
