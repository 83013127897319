import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { BossFormInput, BossFormSelectNew, BossFormEmployementStatus } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import { workFormValidators } from './validators';
import * as c from '../../constants';

function WorkForm(props) {
  function renderBaseErrors(errors) {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  }

  const [handleSubmit, previousPage] = oFetch(props, 'handleSubmit', 'previousPage');
  const [
    payRateOptions,
    forStandardSecurityStaffSecurityPayRatesOptions,
    forStewardSecurityStaffSecurityPayRatesOptions,
    isSecurity,
    isSteward,
    employmentStatusesOptions,
    valid,
    asyncValidating,
    selectedMossStaffMemberId,
  ] = oFetch(
    props,
    'payRateOptions',
    'forStandardSecurityStaffSecurityPayRatesOptions',
    'forStewardSecurityStaffSecurityPayRatesOptions',
    'isSecurity',
    'isSteward',
    'employmentStatusesOptions',
    'valid',
    'asyncValidating',
    'selectedMossStaffMemberId',
  );
  const { error } = props;
  const linkableStaffMemberOptions = oFetch(useLegacyGlobal(), 'linkableStaffMemberOptions');
  const selectedLinkableMossStaffMemberOption = useMemo(() => {
    if (!selectedMossStaffMemberId) {
      return null;
    }
    return linkableStaffMemberOptions.find(linkableStaffMember => {
      return oFetch(linkableStaffMember, 'id') === selectedMossStaffMemberId;
    });
  }, [selectedMossStaffMemberId]);

  const hasSelectedLinkStaffMemberOption = selectedLinkableMossStaffMemberOption != null;

  return (
    <div className="boss-page-main__group">
      <div className="boss-form">
        {error && renderBaseErrors(error)}
        {!hasSelectedLinkStaffMemberOption && (
          <Field
            label={c.WORK_FORM_FIELDS_TITLES[c.PIN_CODE_FIELD_NAME]}
            name={c.PIN_CODE_FIELD_NAME}
            component={BossFormInput}
          />
        )}
        {hasSelectedLinkStaffMemberOption && (
          <div>
            <label>Pin Code</label>
            <p>from Linked Staff Member</p>
          </div>
        )}
        <Field
          label={c.WORK_FORM_FIELDS_TITLES[c.DAY_PREFERENCE_FIELD_NAME]}
          name={c.DAY_PREFERENCE_FIELD_NAME}
          description="Preferred days to work displayed in the rota (e.g. mornings and weekends)"
          component={BossFormInput}
        />
        <Field
          label={c.WORK_FORM_FIELDS_TITLES[c.HOUR_PREFERENCE_FIELD_NAME]}
          name={c.HOUR_PREFERENCE_FIELD_NAME}
          description="Preferred number of hours to work per week displayed in the Rota (e.g. 40, 20+)"
          component={BossFormInput}
        />
        {!isSecurity && (
          <Field
            label={c.WORK_FORM_FIELDS_TITLES[c.PAY_RATE_FIELD_NAME]}
            name={c.PAY_RATE_FIELD_NAME}
            valueKey="id"
            labelKey="name"
            options={payRateOptions}
            component={BossFormSelectNew}
          />
        )}
        {isSecurity && !isSteward && (
          <Field
            label={c.WORK_FORM_FIELDS_TITLES[c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]}
            name={c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME}
            valueKey="id"
            labelKey="name"
            options={forStandardSecurityStaffSecurityPayRatesOptions}
            component={BossFormSelectNew}
          />
        )}
        {isSecurity && isSteward && (
          <Field
            label={c.WORK_FORM_FIELDS_TITLES[c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]}
            name={c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME}
            valueKey="id"
            labelKey="name"
            options={forStewardSecurityStaffSecurityPayRatesOptions}
            component={BossFormSelectNew}
          />
        )}
        <Field
          name={c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME}
          label={c.WORK_FORM_FIELDS_TITLES[c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME]}
          options={employmentStatusesOptions}
          component={BossFormEmployementStatus}
        />
        <div className="boss-form__field boss-form__field_role_controls">
          <button
            onClick={previousPage}
            type="button"
            className="boss-button boss-button_role_back boss-form__submit_adjust_row"
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            type="button"
            disabled={!valid || asyncValidating}
            className="boss-button boss-form__submit boss-form__submit_adjust_single"
          >
            {asyncValidating ? `Checking for flagged or existing staff members ...` : `Continue`}
          </button>
        </div>
      </div>
    </div>
  );
}

const formSelector = formValueSelector(c.FORM_NAME);

const mapStateToProps = state => {
  const selectedMossStaffMemberId = formSelector(state, c.LINK_MOSS_STAFF_MEMBER_FIELD_NAME);
  return {
    selectedMossStaffMemberId,
  };
};

export default reduxForm({
  form: c.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: workFormValidators,
  onChange(values, dispatch, props, previousValues) {
    const isSecurity = oFetch(props, 'isSecurity');
    if (isSecurity) {
      dispatch(change(c.FORM_NAME, c.ACCESSORIES_FIELD_NAME, {}));
    }
  },
  shouldAsyncValidate({ asyncErrors, initialized, trigger, blurredField, pristine, syncValidationPasses }) {
    if (!syncValidationPasses) {
      return false;
    }
    switch (trigger) {
      case 'blur':
      case 'change':
        return true;
      case 'submit':
        return false;
      default:
        return false;
    }
  },
})(connect(mapStateToProps)(WorkForm));
