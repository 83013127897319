import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import * as constants from '../constants';
import StaffMemberPayments from './staff-member-payments';
import PayslipModal from './payslip-modal';
import { PaymentsDetailsModal } from './payments-details-modal';
import { ParseErrorPayments } from './parse-error-payments';
import { SkippedInvalidPaymentsList } from './skipped-invalid-payments';

export default function PaymentsBoard(props) {
  const paymentsData = oFetch(props, 'paymentsData');
  const resultType = oFetch(paymentsData, 'resultType');

  const { openModal } = useModal();

  function openPayslipModal(params) {
    const [payslip, fullName] = oFetch(params, 'payslip', 'fullName');

    return openModal({
      onSubmit() { },
      config: {
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payslip
            </div>
          );
        },
        baseClassName: 'boss-modal-window boss-modal-window_role_payslip',
      },
      props: {
        payslip: payslip,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PayslipModal,
    });
  }

  function openPaymentDetailsModal(params) {
    const [payments, fullName] = oFetch(params, 'payments', 'fullName');

    return openModal({
      onSubmit() { },
      config: {
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payments
            </div>
          );
        },
        baseClassName: 'boss-modal-window boss-modal-window_role_csv-upload-payments',
      },
      props: {
        payments: payments,
        fullName: fullName,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PaymentsDetailsModal,
    });
  }

  if (resultType === constants.PARSE_ERROR_RESULT_TYPE) {
    const [headerRowErrors, titleRowErrors, headerRows] = oFetch(
      paymentsData,
      'headerRowErrors',
      'titleRowErrors',
      'headerRows',
    );
    return (
      <ParseErrorPayments
        headerRowErrors={headerRowErrors}
        titleRowErrors={titleRowErrors}
        headerRows={headerRows}
      />
    );
  }

  if (resultType === constants.PROCESSED_RESULT_TYPE) {
    const [
      createdPayments,
      skippedExistingPayments,
      skippedInvalidPayments,
      updatedPayments,
    ] = oFetch(
      paymentsData,
      'createdPayments',
      'skippedExistingPayments',
      'skippedInvalidPayments',
      'updatedPayments',
    );

    return (
      <Fragment>
        <SkippedInvalidPaymentsList skippedInvalidPayments={skippedInvalidPayments} />

        <StaffMemberPayments
          payments={createdPayments}
          openPayslipModal={openPayslipModal}
          openPaymentDetailsModal={openPaymentDetailsModal}
          title="Created Payments"
        />
        <StaffMemberPayments
          payments={updatedPayments}
          openPayslipModal={openPayslipModal}
          openPaymentDetailsModal={openPaymentDetailsModal}
          title="Updated Payments"
        />
        <StaffMemberPayments
          payments={skippedExistingPayments}
          openPayslipModal={openPayslipModal}
          openPaymentDetailsModal={openPaymentDetailsModal}
          title="Skipped because already exists"
        />
      </Fragment>
    );
  }

  throw new Error('Wrong payments data result type');
}
