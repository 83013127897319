import React from 'react';
import classnames from 'classnames';
import oFetch from 'o-fetch';

export default class ScoreChart extends React.Component {
  static displayName = 'ScoreChart';

  render() {
    let currentScore = oFetch(this.props, 'currentScore');
    const thresholdPercentage = oFetch(currentScore, 'thresholdPercentage');
    const passPercentage = oFetch(currentScore, 'passPercentage');

    if (oFetch(currentScore, 'requiredQuestionPassed')) {
      const currentCategory = oFetch(this.props, 'currentCategory');
      const currentCategoryName = oFetch(currentCategory, 'name');
      return (
        <div className="boss-board__chart">
          <div
            id={`${currentCategoryName.toLowerCase()}-score`}
            className="boss-chart-score"
            data-id={`${currentCategoryName.toLowerCase()}-score`}
            data-current={oFetch(currentScore, 'categoryScore')}
            data-total={oFetch(currentScore, 'totalScore')}
            data-required-failed-question-count={oFetch(currentScore, 'requiredFailedQuestionCount')}
            data-threshold-percentage={thresholdPercentage}
            data-pass-percentage={passPercentage}
            data-size="250"
          />
        </div>
      );
    } else {
      const currentCategory = oFetch(this.props, 'currentCategory');
      const currentCategoryName = oFetch(currentCategory, 'name');
      return (
        <div className="boss-board__chart">
          <div
            id={`${currentCategoryName.toLowerCase()}-failures`}
            className="boss-chart-failures"
            data-id={`${currentCategoryName.toLowerCase()}-failures`}
            data-current={oFetch(currentScore, 'categoryScore')}
            data-total={oFetch(currentScore, 'totalScore')}
            data-required-failed-question-count={oFetch(currentScore, 'requiredFailedQuestionCount')}
            data-threshold-percentage={thresholdPercentage}
            data-pass-percentage={passPercentage}
            data-size="250"
          />
        </div>
      );
    }
  }
}
