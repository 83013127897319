import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

export function ReportTableHeader(props) {
  function renderWeekCells() {
    const staffTypeName = oFetch(props, 'staffTypeName');
    const mStartDate = oFetch(props, 'mStartDate');

    return [1, 2, 3, 4, 5, 6, 7].map(weekDay => {
      const mCurrentDate = mStartDate.isoWeekday(weekDay);
      return (
        <div
          key={`${staffTypeName}:header:${weekDay}`}
          className="boss-table__cell boss-table__cell_role_header"
        >
          {mCurrentDate.format(utils.tableDateFormat)} <br />{' '}
          {mCurrentDate.format(utils.monthDateFormat)}
        </div>
      );
    });
  }

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell boss-table__cell_role_header">Name</div>
      {renderWeekCells()}
      <div className="boss-table__cell boss-table__cell_role_header">Total Hours</div>
      <div className="boss-table__cell boss-table__cell_role_header">Amount</div>
      <div className="boss-table__cell boss-table__cell_role_header">Status</div>
    </div>
  );
}
