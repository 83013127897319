import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import { BossFormAvatar, BossFormCheckbox } from '@/components/boss-form';
import { appRoutes } from '@/lib/legacy-routes';

import { validateAvatarForUpdateAction } from '../actions';

function handleUpdateAvatar(values, dispatch) {
  const avatarBase64 = oFetch(values, 'avatar');
  const setupForFacialRecognition = oFetch(values, 'setupForFacialRecognition');

  return dispatch(validateAvatarForUpdateAction({ avatarBase64, setupForFacialRecognition }));
}

const submission = (values, dispatch) => {
  return handleUpdateAvatar(values.toJS(), dispatch);
};

const EditAvatar = ({ handleSubmit, submitting, markedRetakeAvatar, disableUpload, error }) => {
  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            if (error.exist) {
              return error.exist.map(existError => {
                const [type, id, fullName] = oFetch(existError, 'type', 'id', 'full_name');
                if (type === 'staff_member') {
                  return (
                    <p key={id} className="boss-alert__text">
                      Staff member <a href={appRoutes.staffMemberProfile(id)}>{fullName}</a> with avatar you
                      have tried upload already exist
                    </p>
                  );
                }
                if (type === 'moss_staff_member') {
                  return (
                    <p key={id} className="boss-alert__text">
                      Moss Staff member{' '}
                      <a href={appRoutes.mossStaffMemberProfile({ mossStaffMemberId: id })}>{fullName}</a>{' '}
                      with avatar you have tried upload already exist
                    </p>
                  );
                }
                throw new Error(
                  `Wrong staff member type. Must be one of [staff_member, moss_staff_member], got: ${type}`,
                );
              });
            }
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="boss-modal-window__form">
      <form onSubmit={handleSubmit(submission)} className="boss-form">
        {error && renderBaseError(error)}
        <Field
          name="setupForFacialRecognition"
          label="Setup for facial recognition"
          leftPosition={false}
          component={BossFormCheckbox}
        />
        <Field
          name="avatar"
          markedRetakeAvatar={markedRetakeAvatar}
          disableUpload={disableUpload}
          component={BossFormAvatar}
        />
        <div className="boss-form__field boss-form__field_justify_center">
          <button
            disabled={submitting || disableUpload}
            type="submit"
            className="boss-button boss-button_role_submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};
const selector = formValueSelector('edit-avatar-form');

const mapStateToProps = (state, ownProps) => {
  const avatar = selector(state, 'avatar');
  if (avatar) {
    const initialValues = oFetch(ownProps, 'initialValues');
    const initialValuesJS = initialValues.toJS();
    const initialAvatar = oFetch(initialValuesJS, 'avatar');
    const isTheSameAvatar = initialAvatar === avatar;
    return {
      disableUpload: isTheSameAvatar,
    };
  }
  return {
    disableUpload: true,
  };
};

const ConnectedEditAvatar = connect(mapStateToProps)(EditAvatar);

export default reduxForm({
  form: 'edit-avatar-form',
})(ConnectedEditAvatar);
