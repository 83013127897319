import React from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import FacialRecognitionSettings from './facial-recognition-settings';

export function FacialRecognitionSettingsApp(props) {
  return (
    <ModalsProvider>
      <FacialRecognitionSettings {...props} />
    </ModalsProvider>
  );
}
