import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Field, reduxForm, formValueSelector, change } from 'redux-form/immutable';
import { connect } from 'react-redux';

import utils from '@/lib/utils';
import { useSiaBadgeValidation } from '@/components/hooks-components/use-sia-badge-validation';
import { ColoredSingleValue, ColoredSingleOption } from '@/components/boss-form/colored-select';

import BossFormCalendar from '@/components/boss-form/boss-form-calendar';
import { BossFormInput, BossFormSelectNew, BossFormCheckbox } from '@/components/boss-form';
import { SIAFormField, NonEditableSIAField } from '../../../AddStaffMember/components/fields/sia-form-field';

import * as selectors from '../selectors';

function EnableProfileForm(props) {
  function renderBaseError(error) {
    return (
      <div className="boss-alert boss-alert_role_area boss-alert_context_above">
        <p className="boss-alert__text">{error}</p>
      </div>
    );
  }

  const { error, submitting, valid } = props;
  const isStaffTypeSelected = oFetch(props, 'isStaffTypeSelected');
  const staffTypeOptions = oFetch(props, 'staffTypeOptions');
  const staffMemberId = oFetch(props, 'staffMemberId');
  const siaValidationGUID = oFetch(props, 'siaValidationGUID');
  const canBypassSiaValidation = oFetch(props, 'canBypassSiaValidation');
  const isSecurity = oFetch(props, 'isSecurity');
  const checkSiaNumber = oFetch(props, 'checkSiaNumber');
  const requireNiNumber = oFetch(props, 'requireNiNumber');
  const formChange = oFetch(props, 'change');
  const securitySteward = oFetch(props, 'securitySteward');
  const accessibleBaseStandardSecurityPayRates = oFetch(props, 'accessibleBaseStandardSecurityPayRates');
  const standardBasePayRateOptions = accessibleBaseStandardSecurityPayRates.map(
    forStandardSecurityStaffSecurityPayRate => {
      const payRateDisplayName = oFetch(forStandardSecurityStaffSecurityPayRate, 'name');
      const securityBasePayRateId = oFetch(forStandardSecurityStaffSecurityPayRate, 'id');
      return { label: payRateDisplayName, value: securityBasePayRateId };
    },
  );
  const accessibleBaseStewardSecurityPayRates = oFetch(props, 'accessibleBaseStewardSecurityPayRates');
  const stewardBasePayRateOptions = accessibleBaseStewardSecurityPayRates.map(
    forStewardSecurityStaffSecurityPayRate => {
      const payRateDisplayName = oFetch(forStewardSecurityStaffSecurityPayRate, 'name');
      const securityBasePayRateId = oFetch(forStewardSecurityStaffSecurityPayRate, 'id');
      return { label: payRateDisplayName, value: securityBasePayRateId };
    },
  );

  const payRates = oFetch(props, 'payRates');
  const payRateOptions = payRates.map(payRate => {
    const payRateDisplayName = oFetch(payRate, 'name');
    const payRateId = oFetch(payRate, 'id');

    return { label: payRateDisplayName, value: payRateId };
  });

  const venues = oFetch(props, 'venues');
  const enableStaffMemberMasterVenueOptions = venues.map(venue => {
    const venueId = oFetch(venue, 'id');
    const venueName = oFetch(venue, 'name');

    return { label: venueName, value: venueId };
  });
  const { checkSIANumber, siaBadgeValidating } = useSiaBadgeValidation({
    staffMemberId: staffMemberId,
    onDowngradeClick() {
      window.alert(`Staff member can't be downgrade to stewart from here`);
    },
    onNotFoundSubmit: handleSIANotFoundSubmit,
    onInvalidTryAgainClick: handleInvalidSiaTryAgain,
    onRequestFailed: handleCheckSIARequestFailed,
    onDuplicatesModalSubmit(closeModal) {
      formChange('siaBadgeNumber', '');
      closeModal();
    },
    onNonFrontLineModalSubmit(closeModal) {
      formChange('siaBadgeNumber', '');
      closeModal();
    },
    onSiaCheckSuccess: handleSiaCheckSuccess,
    onFormatInvalid() {},
  });

  const nonStewardSecurity = isSecurity && !securitySteward;
  const canBypassValidation = canBypassSiaValidation || !checkSiaNumber;
  const canShowSiaBadgeExpiryDateField = nonStewardSecurity && canBypassValidation;

  function handleInvalidSiaTryAgain(closeModal) {
    closeModal();
    formChange('siaBadgeNumber', '');
    formChange('siaBadgeExpiryDate', '');
    formChange('siaValidationGUID', '');
    formChange('canBypassSiaValidation', false);
  }

  function handleSiaCheckSuccess(data) {
    const [guid, expiryDate] = oFetch(data, 'guid', 'expiryDate');

    formChange('siaValidationGUID', guid);
    formChange('siaBadgeExpiryDate', expiryDate);
    formChange('canBypassSiaValidation', false);
  }

  function handleCheckSIARequestFailed(params) {
    const [closeModal, guid] = oFetch(params, 'closeModal', 'guid');

    formChange('canBypassSiaValidation', true);
    formChange('siaValidationGUID', guid);
    formChange('siaBadgeExpiryDate', '');

    closeModal();
  }

  function handleSIANotFoundSubmit(closeModal) {
    formChange('canBypassSiaValidation', false);
    formChange('siaBadgeExpiryDate', '');
    formChange('siaValidationGUID', '');
    closeModal();
  }

  return (
    <div>
      {error && renderBaseError(error)}
      <form onSubmit={props.handleSubmit} className="boss-page-main__group boss-form">
        <div className="boss-form__group">
          <h3 className="boss-form__group-title">Please enter new start date</h3>
          <Field
            name="startsAt"
            valueFormat={utils.commonDateFormat}
            component={BossFormCalendar}
            label=""
            disabled={siaBadgeValidating}
          />
        </div>
        {isStaffTypeSelected && isSecurity && (
          <div>
            <div className="boss-form__row boss-form__row_justify_end boss-form__row_layout_nowrap">
              <Field
                component={BossFormCheckbox}
                className="boss-form__field_layout_actual"
                name="securitySteward"
                left={false}
                label="Steward"
                disabled={siaBadgeValidating}
              />
            </div>
          </div>
        )}
        <Field
          label={'Staff Type'}
          name={'staffTypeId'}
          options={staffTypeOptions}
          clearable
          valueComponent={ColoredSingleValue}
          disabled={siaBadgeValidating}
          optionComponent={ColoredSingleOption}
          component={BossFormSelectNew}
        />
        {isStaffTypeSelected && (
          <Fragment>
            {!isSecurity && (
              <div className="boss-form__group">
                <Field
                  required
                  clearable={false}
                  mutli={false}
                  name="masterVenueId"
                  label="Master Venue"
                  valueKey="value"
                  labelKey="label"
                  options={enableStaffMemberMasterVenueOptions}
                  component={BossFormSelectNew}
                  disabled={siaBadgeValidating}
                />
              </div>
            )}
            <div className="boss-form__field">
              <Field
                name="nationalInsuranceNumber"
                label="National Insurance Number"
                component={BossFormInput}
                disabled={siaBadgeValidating}
              />
            </div>
            {!isSecurity && (
              <div className="boss-form__field">
                <Field
                  required
                  clearable={false}
                  mutli={false}
                  name="payRateId"
                  label="Pay Rate"
                  valueKey="value"
                  labelKey="label"
                  options={payRateOptions}
                  component={BossFormSelectNew}
                  disabled={siaBadgeValidating}
                />
              </div>
            )}
            {isSecurity && (
              <div className="boss-form__field">
                <Field
                  required
                  clearable={true}
                  multi={false}
                  name="baseSecurityPayRateId"
                  label="Pay Rate"
                  valueKey="value"
                  labelKey="label"
                  options={securitySteward ? stewardBasePayRateOptions : standardBasePayRateOptions}
                  component={BossFormSelectNew}
                  disabled={siaBadgeValidating}
                />
              </div>
            )}
            {nonStewardSecurity && (
              <Field
                label={'SIA Badge Number'}
                name={'siaBadgeNumber'}
                staffMemberId={staffMemberId}
                siaBadgeValidating={siaBadgeValidating}
                validateSiaNumber={checkSiaNumber}
                checkSIANumber={checkSIANumber}
                component={SIAFormField}
              />
            )}
            {!canShowSiaBadgeExpiryDateField && siaValidationGUID && checkSiaNumber && (
              <Field
                label={'SIA badge expiry date'}
                name={'siaBadgeExpiryDate'}
                component={NonEditableSIAField}
              />
            )}
            {canShowSiaBadgeExpiryDateField && (
              <Field
                label={'SIA badge expiry date'}
                name={'siaBadgeExpiryDate'}
                valueFormat={utils.commonDateFormat}
                component={BossFormCalendar}
                disabled={siaBadgeValidating}
              />
            )}
          </Fragment>
        )}
        <div className="boss-form__field">
          <button
            disabled={!valid || submitting || siaBadgeValidating}
            className={`boss-button boss-form__submit ${props.buttonClass}`}
          >
            {props.buttonText}
          </button>
        </div>
      </form>
    </div>
  );
}

EnableProfileForm.propTypes = {};

EnableProfileForm.defaultProps = {
  buttonClass: 'boss-button_role_confirm',
  buttonText: 'Enable',
};

const selector = formValueSelector('enable-profile-form');
const mapStateToProps = state => {
  const siaValidationGUID = selector(state, 'siaValidationGUID') || '';
  const canBypassSiaValidation = selector(state, 'canBypassSiaValidation') || false;
  const isSecurity = selectors.isEnableSecuritySelector(state);
  const securityStaffTypesIds = selectors.getSecurityStaffTypesIds(state).toJS();
  const isStaffTypeSelected = selectors.isEnableStaffTypeSelectedSelector(state);
  const securitySteward = selectors.isEnableSecurityStewardSelector(state) || false;
  return {
    siaValidationGUID,
    canBypassSiaValidation,
    isSecurity,
    securitySteward,
    isStaffTypeSelected,
    securityStaffTypesIds,
  };
};

function validate(values, props) {
  const [
    isSecurity,
    securitySteward,
  ] = oFetch(
    props,
    'isSecurity',
    'securitySteward',
  );
  let errors = {};
  let valuesJS = values.toJS();
  let requiredFields = ['startsAt', 'staffTypeId'];
  if (!isSecurity) {
    requiredFields.push('masterVenueId');
    requiredFields.push('nationalInsuranceNumber');
    requiredFields.push('payRateId');
  } else  {
    requiredFields.push('baseSecurityPayRateId');
    requiredFields.push('nationalInsuranceNumber');
    if (!securitySteward) {
      requiredFields.push('siaBadgeNumber');
    }
    requiredFields.push('siaBadgeNumber');
  }

  const missingFields = requiredFields.filter(field => values.get(field) === undefined || values.get(field) === '' || values.get(field) === null);
  if (oFetch(missingFields, 'length') > 0) {
    missingFields.forEach(field => {
      errors[field] ||= [];
      errors[field].push("is required");
    });
  }

  if ( !!isSecurity && !securitySteward) {
    const siaBadgeNumber = values.get('siaBadgeNumber');
    if (siaBadgeNumber) {
      const matched = siaBadgeNumber.match(/^\d{16}$/);
      if (!matched) {
        errors = { ...errors, siaBadgeNumber: [`format is not correct`] };
      }
    }
  }

  return errors;
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'enable-profile-form',
    validate: validate,
    onChange(values, dispatch, props, previousValues) {
      const valuesJS = values.toJS();
      const previousValuesJS = previousValues.toJS();
      if (Object.keys(previousValuesJS).length === 0) {
        return;
      }
      const [isSecurity, securityStaffTypesIds] = oFetch(props, 'isSecurity', 'securityStaffTypesIds');
      const previousStaffType = oFetch(previousValuesJS, 'staffTypeId');
      const previousStaffTypeIsSecurity = securityStaffTypesIds.includes(previousStaffType);
      const stewardValue = oFetch(valuesJS, 'securitySteward');
      const oldStewardValue = oFetch(previousValuesJS, 'securitySteward');
      if ((previousStaffTypeIsSecurity && !isSecurity) || (!previousStaffTypeIsSecurity && isSecurity)) {
        dispatch(change('enable-profile-form', 'siaValidationGUID', null));
        dispatch(change('enable-profile-form', 'siaBadgeNumber', null));
        dispatch(change('enable-profile-form', 'siaBadgeExpiryDate', null));
        dispatch(change('enable-profile-form', 'securitySteward', false));
        dispatch(change('enable-profile-form', 'payRateId', null));
        dispatch(change('enable-profile-form', 'baseSecurityPayRateId', null));
        dispatch(change('enable-profile-form', 'masterVenueId', null));
        dispatch(change('enable-profile-form', 'nationalInsuranceNumber', null));
      }
      if (oldStewardValue !== stewardValue) {
        dispatch(change('enable-profile-form', 'siaValidationGUID', null));
        dispatch(change('enable-profile-form', 'siaBadgeNumber', null));
        dispatch(change('enable-profile-form', 'siaBadgeExpiryDate', null));
        dispatch(change('enable-profile-form', 'baseSecurityPayRateId', null));
      }
    },
  })(EnableProfileForm),
);
