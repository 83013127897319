import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';

class VenueItem extends PureComponent {
  render() {
    const venue = oFetch(this.props, 'venue');
    const [
      venueId,
      name,
      bossVenue,
      mossVenue,
      venueEnabled,
      availableBossStaffCount,
      fixedBossStaffCount,
      safeFloatPoundValue,
      tillFloatPoundValue,
      lat,
      lng,
      changeOrderSiteId,
    ] = oFetch(
      venue,
      'id',
      'name',
      "bossVenue",
      "mossVenue",
      'enabled',
      'availableBossStaffCount',
      'fixedBossStaffCount',
      'safeFloatPoundValue',
      'tillFloatPoundValue',
      'lat',
      'lng',
      'changeOrderSiteId',
    );
    const [onEditVenue, onOpenFruitOrderFields, onDisableVenue, onEnableVenue] = oFetch(
      this.props,
      'onEditVenue',
      'onOpenFruitOrderFields',
      'onDisableVenue',
      'onEnableVenue',
    );

    const venueTypeStringParts = [bossVenue && "Boss", mossVenue && "Moss"]
      //remove empty values
      .filter((el) => el);
    const venueTypeString = venueTypeStringParts.join(", ")

    return (
      <div className="boss-table__row">
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Name</p>
            <p className="boss-table__text boss-table__text_adjust_wrap">{name}</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Type</p>
            <p className="boss-table__text boss-table__text_adjust_wrap">{venueTypeString}</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Available Boss Staff</p>
            <p className="boss-table__text">
              { !bossVenue && '0' }
              { bossVenue && <a href={oFetch(appRoutes, 'staffMembersIndex')({ venueId })} className="boss-table__link">
                {availableBossStaffCount}
              </a> }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Fixed Boss Staff</p>
            <p className="boss-table__text">{ bossVenue ? fixedBossStaffCount : '0' }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Safe Float</p>
            <p className="boss-table__text">{ bossVenue && utils.moneyFormat(safeFloatPoundValue) }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Till Float</p>
            <p className="boss-table__text">{ bossVenue && utils.moneyFormat(tillFloatPoundValue) }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Location</p>
            <p className="boss-table__text">
              { bossVenue && `${lat}, ${lng}` }
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Change Order Site ID</p>
            <p className="boss-table__text boss-table__text_adjust_wrap">{ bossVenue ? changeOrderSiteId : 'n/a' }</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Fruit Order Fields</p>
            <div className="boss-table__actions">
              { bossVenue && <button
                onClick={() => onOpenFruitOrderFields(venue)}
                type="button"
                className="boss-button boss-button_role_details boss-button_type_small boss-table__action"
              >
                Details
              </button> }
            </div>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Action</p>
              { venueEnabled && <div className="boss-check__row boss-check__row_role_buttons">
                  <button
                    onClick={() => onEditVenue(venue)}
                    type="button"
                    className="boss-button boss-button_role_edit-light-alt boss-button_type_extra-small boss-check__button"
                  >
                    Edit
                  </button><br/>
                  <button
                    onClick={() => onDisableVenue({ venue }) }
                    type="button"
                    className="boss-button boss-button_role_cancel-light boss-button_type_extra-small boss-check__button"
                  >
                    Disable
                  </button>
                </div> }
              { !venueEnabled && <div className="boss-check__row boss-check__row_role_buttons">
                  <button
                    onClick={ () => onEnableVenue({ venue }) }
                    type="button"
                    className="boss-button button_role_details-light boss-button_type_extra-small boss-check__button">
                    Enable
                  </button>
                </div> }
          </div>
        </div>
      </div>
    );
  }
}

VenueItem.propTypes = {
  venue: PropTypes.object.isRequired,
};

export default VenueItem;
