import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { CollapsibleFilter } from '@/components/hooks-components';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import { ColoredSingleOption, ColoredMultipleValue } from '@/components/boss-form/colored-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

const options = [
  {
    label: 'Confirmed',
    value: 'confirmed',
    color: '#78d965',
  },
  {
    label: 'Pending',
    value: 'pending',
    color: '#8e8e8e',
  },
  {
    label: 'Rejected Wrong Amount',
    value: 'rejected_wrong_amount',
    color: '#f4a84f',
  },
  {
    label: 'Disabled',
    value: 'disabled',
    color: '#ed7f7e',
  },
];

export function DashboardFilter(props) {
  const [selectedStates, onStatesChange, nameQuery, onNameChange] = oFetch(
    props,
    'selectedStates',
    'onStatesChange',
    'nameQuery',
    'onNameChange',
  );

  function handleNameChange(e) {
    onNameChange(e.target.value);
  }

  return (
    <CollapsibleFilter>
      <div className="boss-form">
        <div className="boss-form__row">
          <div className="boss-form__field boss-form__field_layout_full">
            <label className="boss-form__label">
              <span className="boss-form__label-text">States</span>
            </label>
            <div className="boss-form__select">
              <Select
                value={selectedStates}
                onChange={onStatesChange}
                clearable
                valueComponent={ColoredMultipleValue}
                optionComponent={ColoredSingleOption}
                simpleValue
                multi
                placeholder="Select states"
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="boss-form__row">
          <div className="boss-form__field boss-form__field_layout_full">
            <label className="boss-form__label">
              <span className="boss-form__label-text">Staff Member Name</span>
            </label>
            <div className="boss-form__search">
              <input
                name="search"
                type="text"
                className="boss-form__input"
                placeholder="Search..."
                value={nameQuery}
                onChange={handleNameChange}
              />
            </div>
          </div>
        </div>
      </div>
    </CollapsibleFilter>
  );
}
