import utils from '@/lib/utils';
const APP_PREFIX = 'MARKETING_TASKS';

export const LOAD_INITIAL_DATA = utils.createType(APP_PREFIX, 'LOAD_INITIAL_DATA');
export const ADD_MARKETING_TASK = utils.createType(APP_PREFIX, 'ADD_MARKETING_TASK');
export const ADD_MARKETING_TASK_REQUEST = utils.createType(APP_PREFIX, 'ADD_MARKETING_TASK_REQUEST');
export const UPDATE_MARKETING_TASK_REQUEST = utils.createType(APP_PREFIX, 'UPDATE_MARKETING_TASK_REQUEST');
export const REPLACE_MARKETING_TASK_REQUEST = utils.createType(APP_PREFIX, 'REPLACE_MARKETING_TASK_REQUEST');
export const UPDATE_MARKETING_TASK = utils.createType(APP_PREFIX, 'UPDATE_MARKETING_TASK');
export const UPDATE_MARKETING_TASK_DETAILS = utils.createType(APP_PREFIX, 'UPDATE_MARKETING_TASK_DETAILS');
export const REMOVE_MARKETING_TASK = utils.createType(APP_PREFIX, 'REMOVE_MARKETING_TASK');
