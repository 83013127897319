import { useEffect, useMemo } from 'react';
import React from 'react';
import _ from 'underscore';
import oFetch from 'o-fetch';
import calculateChartBoundaries from './calculate-chart-boundaries';
import RotaChartInner from './rota-chart-inner';
import { useLegacyGlobal } from '@/components/hooks-components/global';

/**
This is a wrapper around the D3 rota chart that handles small state changes
like hover highlighting that don't work well with a full re-render of the chart
(due to e.g. mouseenter being re-triggered when a bar is replaced while under the cursor
... which in turn would cause a re-render).
 */
export default function RotaChart(props) {
  const rotaShifts = oFetch(props, 'rotaShifts');
  const [appType, graphXAxisHours] = oFetch(useLegacyGlobal(), 'appType', 'graphXAxisHours');

  const chartBoundaries = useMemo(() => calculateChartBoundaries({ rotaShifts, appType }), [rotaShifts]);

  useEffect(() => {
    require('./style.css');
  }, []);

  return (
      <RotaChartInner
        appType={appType}
        rotaShifts={_(rotaShifts).sortBy(item => item.starts_at)}
        startTime={chartBoundaries.start}
        endTime={chartBoundaries.end}
        staff={props.staff}
        graphXAxisHours={graphXAxisHours}
        staffTypes={props.staffTypes}
        onShiftClick={props.onShiftClick}
        updateStaffToShow={props.updateStaffToShow}
        getShiftColor={props.getShiftColor}
      />
  );
}
