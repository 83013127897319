import React from 'react';
import RotaChart from "./rota-chart";
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import oFetch from 'o-fetch';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  // mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  eventPassthrough: true,
  click: true
};

function RotaGraph(props) {
  const [staffMembers, staffTypes, rotaShifts, onShiftClick] = oFetch(props, 'staffMembers', 'staffTypes', 'rotaShifts', 'onShiftClick');

  function getShiftColor(shift) {
    const staffMember = staffMembers.find(staff => staff.id === shift.staff_member);
    const staffType = staffTypes.find(staffType => staffType.id === staffMember.staff_type);
    return staffType.color;
  }

  return (
    <div className="boss-rotas__graphs-list">
      <div className="boss-rotas__graphs-item">
        <div className="rota-chart">
          <div className="rota-chart__inner">
            <ReactIScroll
              iScroll={iScroll}
              options={scrollOptions}
            >
              <RotaChart
                rotaShifts={rotaShifts}
                staffTypes={staffTypes}
                staff={staffMembers}
                getShiftColor={(shift) => getShiftColor(shift)}
                onShiftClick={onShiftClick}
              />
            </ReactIScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RotaGraph;
