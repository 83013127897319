import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import { KPICard } from './kpi-card';
import { KpiDataWrapper } from './kpi-data-wrapper';
import { VenueSafeCheckTooltipContent } from './tooltip-content';
import { WagePercentageCell } from './wage-percentage-cell';
import { SiteIssueCell } from './site-issue-cell';

export function validate(data) {
  return true;
}

export function KPICardList(props) {
  const [kpiDataList, onEditRating, onAddRating, canHandleAction] = oFetch(
    props,
    'kpiDataList',
    'onEditRating',
    'onAddRating',
    'canHandleAction',
  );

  function tooltipContent(params) {
    const [data, name] = oFetch(params, 'data', 'name');
    if (name === 'venue_safe_check_submissions') {
      return <VenueSafeCheckTooltipContent data={data} />;
    }
  }

  function renderSimpleCell(params) {
    const [name, title, value, data, className] = oFetch(
      params,
      'name',
      'title',
      'value',
      'data',
      'className',
    );
    if (name === 'wage_percentage') {
      return <WagePercentageCell title={title} value={value} data={data} />;
    }
    return (
      <div className="boss-kpi__data-item boss-kpi__data-item_role_month">
        <span className={className}>{title}</span>
        <div className="boss-kpi__data-indicator">
          <span className="boss-kpi__data-indicator-value">{value}</span>
        </div>
      </div>
    );
  }

  function renderKpiDataItem(params) {
    const [kpiItemData, dataValueType, dataTimeType, name] = oFetch(
      params,
      'kpiItemData',
      'dataValueType',
      'dataTimeType',
      'name',
    );
    const title = oFetch(kpiItemData, 'title');
    const { data } = kpiItemData;

    const timeClassName = cn({
      'boss-kpi__data-number': dataTimeType === 'weekly',
      'boss-kpi__data-title': dataTimeType === 'monthly',
    });
    if (dataValueType === 'simple') {
      const value = oFetch(kpiItemData, 'value');
      return renderSimpleCell({ value, title, data: data || null, name, className: timeClassName });
    }
    if (dataValueType === 'split') {
      if (name === 'site_issue') {
        return <SiteIssueCell kpiItemData={kpiItemData} timeClassName={timeClassName} />;
      }
      const [leftValue, leftDescription, rightValue, rightDescription] = oFetch(
        kpiItemData,
        'leftValue',
        'leftDescription',
        'rightValue',
        'rightDescription',
      );

      return (
        <div className="boss-kpi__data-item boss-kpi__data-item_role_month">
          <div className={timeClassName}>{title}</div>
          <div className="boss-kpi__data-group">
            <div className="boss-kpi__data-area">
              <div className="boss-kpi__data-indicator">
                <span className="boss-kpi__data-indicator-value">{leftValue}</span>
              </div>
              <div className="boss-kpi__data-label">{leftDescription}</div>
            </div>
            <div className="boss-kpi__data-area">
              <div className="boss-kpi__data-indicator">
                <span className="boss-kpi__data-indicator-value">{rightValue}</span>
              </div>
              <div className="boss-kpi__data-label">{rightDescription}</div>
            </div>
          </div>
        </div>
      );
    }
    if (dataValueType === 'pass_fail') {
      const value = oFetch(kpiItemData, 'value');
      if (value === null) {
        return (
          <div className="boss-kpi__data-item boss-kpi__data-item_role_week">
            <span className={timeClassName}>{title}</span>
            <div className={'boss-kpi__data-indicator'}>
              <span className="boss-kpi__data-indicator-value">N/A</span>
            </div>
          </div>
        );
      }
      const pass = value;
      const indicatorClassName = cn('boss-kpi__data-indicator', {
        'boss-kpi__data-indicator_role_fail': !pass,
        'boss-kpi__data-indicator_role_pass': pass,
      });

      return (
        <Tooltip
          className="boss-kpi__data-item boss-kpi__data-item_role_week"
          arrow
          theme="light"
          position="bottom"
          interactive
          html={tooltipContent({ data, name })}
        >
          <Fragment>
            <span className={timeClassName}>{title}</span>
            <div className={indicatorClassName}>
              <span className="boss-kpi__data-indicator-value">
                <span className="boss-kpi__data-indicator-icon" />
              </span>
            </div>
          </Fragment>
        </Tooltip>
      );
    }
  }

  function renderKpiCardContent(params) {
    const [dataTimeType, dataValueType, data, name] = oFetch(
      params,
      'dataTimeType',
      'dataValueType',
      'data',
      'name',
    );

    return (
      <KpiDataWrapper
        data={data}
        itemRenderer={kpiItemData => renderKpiDataItem({ kpiItemData, dataTimeType, dataValueType, name })}
        dataTimeType={dataTimeType}
      />
    );
  }

  function renderList() {
    return kpiDataList.map((kpiDataItem, index) => {
      const kpiId = oFetch(kpiDataItem, 'kpiId');
      const [
        title,
        name,
        score,
        dataTimeType,
        dataValueType,
        data,
        ratedAt,
        ratedByUserName,
        ratingNote,
      ] = oFetch(
        kpiDataItem,
        'title',
        'name',
        'score',
        'dataTimeType',
        'dataValueType',
        'data',
        'ratedAt',
        'ratedByUserName',
        'ratingNote',
      );
      const hasRating = ratedAt && score;
      const actionHandler = hasRating ? onEditRating : onAddRating;
      return (
        <KPICard
          key={kpiId}
          kpiId={kpiId}
          title={title}
          score={score}
          note={ratingNote}
          ratedAt={ratedAt}
          ratedByUserName={ratedByUserName}
          canHandleAction={canHandleAction}
          actionTitle={hasRating ? 'Edit Rating' : 'Add Rating'}
          actionHandler={() =>
            actionHandler({
              kpiDataRenderer: () => renderKpiCardContent({ dataTimeType, dataValueType, data, name }),
              kpiId,
              score,
              ratingNote,
            })
          }
        >
          {renderKpiCardContent({ dataTimeType, dataValueType, data, name })}
        </KPICard>
      );
    });
  }

  return <div className="boss-page-main__flow boss-page-main__flow_adjust_boards">{renderList()}</div>;
}
