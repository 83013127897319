import oFetch from 'o-fetch';
import React from 'react';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function MobileReportsList(props) {
  const [reports, onUserClick] = oFetch(props, 'reports', 'onUserClick');

  function renderReports() {
    return reports.map(report => {
      const reportId = oFetch(report, 'id');
      const [userId, userFullName, context, reportText, at, uuid] = oFetch(
        report,
        'userId',
        'userFullName',
        'context',
        'reportText',
        'at',
        'uuid',
      );
      const reportAt = safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTimeAndSeconds());

      return (
        <div
          key={reportId}
          className="boss-check boss-check_role_panel boss-check_page_holidays-report-details"
        >
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <p className="boss-check__text boss-check__text_role_user">
                <span className="boss-check__text-label">Created by: </span>
                <a
                  href={`/users/${userId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="boss-table__text boss-action__text"
                >
                  {userFullName}
                </a>
              </p>
              <p className="boss-check__text boss-check__text_role_secondary">({reportAt})</p>
            </div>
          </div>
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <p className="boss-check__text boss-check__text_role_date-calendar boss-check__text_marked">
                {context}
              </p>
            </div>
          </div>
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <a
                href={`https://rollbar.com/item/uuid/?uuid=${uuid}`}
                target="_blank"
                rel="noreferrer"
                className="boss-check__text  boss-check__text_marked boss-action__text"
              >
                Rollbar Item Link
              </a>
            </div>
          </div>
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <button
                onClick={() => onUserClick(userId, userFullName)}
                className="boss-table__text boss-action__text"
              >
                All Items
              </button>
            </div>
          </div>
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <div className="boss-check__box">
                <p className="boss-check__text boss-check__text_role_note">{reportText}</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return <>{renderReports()}</>;
}
