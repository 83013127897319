import { z } from 'zod';

export const $MessageScheduleStaffMember = z.object({
  id: z.number().int().positive(),
  fullName: z.string(),
  phoneNumber: z.string(),
}).strict();

export const $MessageScheduleUser = z.object({
  id: z.number().int().positive(),
  fullName: z.string(),
}).strict();

export const $NewMessageScheduleFormValues = z.object({
  id: z.number().int().positive().optional(),
  staffMemberId: z.number().int().positive().nullable(),
  sendAt: z.string().datetime({ offset: true }).nullable(),
  message: z.string().nullable(),
}).strict();

export const $MessageSchedule = z.object({
  id: z.number().int().positive(),
  staffMember: $MessageScheduleStaffMember,
  sendAt: z.string().datetime({ offset: true }),
  message: z.string(),
  status: z.string(),
  isPending: z.boolean(),
  isSent: z.boolean(),
  isFailed: z.boolean(),
  isDisabled: z.boolean(),
  createdAt: z.string().datetime({ offset: true }),
  disabledAt: z.string().datetime({ offset: true }).nullable(),
  createdBy: $MessageScheduleUser,
  disabledBy: $MessageScheduleUser.nullable(),
});

export const $MessageSchedules = $MessageSchedule.array();

export type MessageScheduleStaffMember = z.infer<typeof $MessageScheduleStaffMember>;
export type MessageScheduleUser = z.infer<typeof $MessageScheduleUser>;
export type NewMessageScheduleFormValues = z.infer<typeof $NewMessageScheduleFormValues>;
export type MessageSchedule = z.infer<typeof $MessageSchedule>;
export type MessageSchedules = z.infer<typeof $MessageSchedules>;
