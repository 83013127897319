import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { openContentModal, openInfoModal } from '@/components/modals';
import safeMoment from '@/lib/safe-moment';
import RejectSecurityShiftRequest from './reject-security-shift-request';
import { BOSS_APP_TYPE } from '@/lib/rota-date';

function RequestsItem(props) {
  function handleOpenRejectSecurityShiftRequest(rejectRequestFormInitialValues) {
    openContentModal({
      submit: handleRejectRequest,
      config: { title: 'Reject Shift Request' },
      props: { rejectRequestFormInitialValues },
    })(RejectSecurityShiftRequest);
  }

  function handleRejectRequest(hideModal, values) {
    const onRejectSecurityShiftRequest = oFetch(props, 'onRejectSecurityShiftRequest');
    return onRejectSecurityShiftRequest(values).then(() => {
      hideModal();
    });
  }

  function handleOpenNoteModal(note) {
    openInfoModal({
      config: { title: 'Note', text: note },
    });
  }

  const shiftRequest = oFetch(props, 'shiftRequest');
  const isAssignable = oFetch(shiftRequest, 'permissions.isAssignable');
  const isRejectable = oFetch(shiftRequest, 'permissions.isRejectable');
  const onOpenAssignPage = oFetch(props, 'onOpenAssignPage');
  const sStartsAt = oFetch(shiftRequest, 'startsAt');
  const sEndsAt = oFetch(shiftRequest, 'endsAt');
  const venueName = oFetch(shiftRequest, 'venueName');
  const id = oFetch(shiftRequest, 'id');
  const note = oFetch(shiftRequest, 'note');
  const venueId = oFetch(shiftRequest, 'venueId');
  const appType = BOSS_APP_TYPE;

  const rejectRequestFormInitialValues = {
    id,
    venueId,
  };

  return (
    <div className="boss-check boss-check_page_ssr-requests">
      <div className="boss-check__header">
        <div className="boss-check__header-group">
          <h3 className="boss-check__title boss-check__title_role_time">
            {utils.intervalRotaDatesFormat({
              mStartsAt: safeMoment.iso8601Parse(sStartsAt),
              mEndsAt: safeMoment.iso8601Parse(sEndsAt),
              appType,
            })}
          </h3>
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_main boss-check__text_role_venue">
                {venueName}
              </p>
            </div>
            {note && (
              <div className="boss-check__header-meta-item">
                <p
                  onClick={() => handleOpenNoteModal(note)}
                  className="boss-check__text boss-check__text_role_main boss-check__text_role_note boss-check__text_role_link"
                >
                  Show Note
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="boss-check__header-actions">
          {isAssignable && (
            <button
              onClick={() => onOpenAssignPage(shiftRequest)}
              type="button"
              className="boss-button boss-button_role_confirm boss-button_type_small boss-check__header-action"
            >
              Assign
            </button>
          )}
          {isRejectable && (
            <button
              onClick={() => handleOpenRejectSecurityShiftRequest(rejectRequestFormInitialValues)}
              type="button"
              className="boss-button boss-button_role_cancel boss-button_type_small boss-check__header-action"
            >
              Reject
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestsItem;
