import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

export default class TagList extends React.Component {
  state = {
    filterValue: '',
  };

  handleFilterChange = e => {
    this.setState({ filterValue: oFetch(e, 'target.value') });
  };

  filter = (value, tags) => {
    return utils.filterBy(value, tags, 'name');
  };

  getTags = (tags, filterValue) => {
    if (!filterValue) {
      return tags;
    }
    return this.filter(filterValue, tags);
  };

  renderTagList = tags => {
    const renderItem = oFetch(this.props, 'renderItem');
    const isTagsExist = tags.length !== 0;
    const filterValue = oFetch(this.state, 'filterValue');

    if (!isTagsExist) {
      if (filterValue) {
        return <h3>No tags found</h3>;
      }
      return <h3>No tags exists</h3>;
    }
    return tags.map(tag => {
      const tagId = oFetch(tag, 'id');
      return React.cloneElement(renderItem(tag), {
        key: tagId,
      });
    });
  };

  render() {
    const tags = oFetch(this.props, 'tags');
    const filterValue = oFetch(this.state, 'filterValue');

    return (
      <div className="boss-form">
        <div className="boss-form__group">{this.renderTagList(this.getTags(tags, filterValue))}</div>
        <div className="boss-form__field boss-form__field_layout_max">
          <div className="boss-form__search">
            <label className="boss-form__label">
              <input
                onChange={this.handleFilterChange}
                name="search"
                value={filterValue}
                className="boss-form__input"
                placeholder="Type tag name here..."
                type="text"
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}
