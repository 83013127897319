import React from 'react';
import humanize from 'string-humanize';
import pluralize from 'pluralize';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import confirm from '@/lib/confirm-utils';
import utils from '@/lib/utils';
import classNames from 'classnames';
import { staffMemberProfileHolidaysPermissions } from '@/lib/permissions';

const PENDING_STATUS = 'pending';
const ACCEPTED_STATUS = 'accepted';
const REJECTED_STATUS = 'rejected';

const statusClasses = {
  [PENDING_STATUS]: 'boss-table__text_role_pending-status',
  [ACCEPTED_STATUS]: 'boss-table__text_role_success-status',
  [REJECTED_STATUS]: 'boss-table__text_role_alert-status',
};

const ActionsCell = ({
  label,
  holidaysId,
  holiday,
  deleteHoliday,
  onEditHoliday,
  editableHoliday,
  isStaffMemberDisabled,
  isEditable,
  isDeletable,
  isFrozen,
}) => {
  const openEditHoliday = () => {
    onEditHoliday(holiday);
  };

  const onDelete = holiday => {
    confirm('Are you sure ?', {
      title: 'Delete holiday',
      actionButtonText: 'Delete',
    }).then(() => {
      deleteHoliday(holiday);
    });
  };

  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        {!isStaffMemberDisabled &&
          !isFrozen && (
            <p className="boss-table__actions">
              {isEditable && (
                <button
                  onClick={openEditHoliday}
                  className="boss-button boss-button_type_small boss-button_role_update boss-table__action"
                >
                  Edit
                </button>
              )}
              {isDeletable && (
                <button
                  onClick={() => onDelete(holiday)}
                  className="boss-button boss-button_type_small boss-button_role_cancel boss-table__action"
                >
                  Delete
                </button>
              )}
            </p>
        )}
      </div>
    </div>
  );
};

const SimpleCell = ({ label, text, classNames }) => {
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        <p className={`boss-table__text ${classNames}`}>{text}</p>
      </div>
    </div>
  );
};

const TypeCell = (args) => {
  const label = oFetch(args, 'label');
  const appTypeText = oFetch(args, 'appTypeText');
  const showAppType = appTypeText !== null;
  const holidayTypeText = oFetch(args, 'holidayTypeText');

  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        <div className="boss-table__info-group">
          {showAppType && (
            <p className="boss-table__text">
              <span className="boss-table__text-line"><b>{appTypeText}</b></span>
            </p>
          )}
          <p className="boss-table__text">
            <span className="boss-table__text-line">{holidayTypeText}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const CreatedByCell = ({ label, creator, created, status, requestedBy, requestedAt }) => {
  if (status === 'Pending') {
    return (
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">{label}</p>
          <div className="boss-table__info-group">
            <p className="boss-table__text">
              <span className="boss-table__text-line">
                <span className="boss-table__text-label">Requested: </span>
                {creator}
              </span>
              <span className="boss-table__text-meta">{created}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (status === 'Accepted') {
    return (
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">{label}</p>
          <div className="boss-table__info-group">
            <p className="boss-table__text">
              <span className="boss-table__text-line">
                <span className="boss-table__text-label">Requested: </span>
                {requestedBy}
              </span>
              <span className="boss-table__text-meta">{requestedAt}</span>
            </p>
            <p className="boss-table__text">
              <span className="boss-table__text-line">
                <span className="boss-table__text-label">Accepted: </span>
                {creator}
              </span>
              <span className="boss-table__text-meta">{created}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (status === 'Rejected') {
    return (
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">{label}</p>
          <div className="boss-table__info-group">
            <p className="boss-table__text">
              <span className="boss-table__text-line">
                <span className="boss-table__text-label">Requested: </span>
                {creator}
              </span>
              <span className="boss-table__text-meta">{created}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        <p className="boss-table__text">
          <span className="boss-table__text-line">{creator}</span>
          <span className="boss-table__text-meta">{created}</span>
        </p>
      </div>
    </div>
  );
};

const Row = (args) => {
  const holiday = oFetch(args, 'holiday');
  const deleteHoliday = oFetch(args, 'deleteHoliday');
  const onEditHoliday = oFetch(args, 'onEditHoliday');
  const isStaffMemberDisabled = oFetch(args, 'isStaffMemberDisabled');
  const permissionsData = oFetch(args, 'permissionsData');
  const multipleAppsPresent = oFetch(args, 'multipleAppsPresent');

  const jsHoliday = holiday.toJS();
  const id = oFetch(jsHoliday, 'id');
  const pageAppType = 'boss';
  const holidayAppType = oFetch(jsHoliday, 'app_type');
  const pageMatchesHolidayAppType = pageAppType === holidayAppType;
  const type = humanize(oFetch(jsHoliday, 'holiday_type'));
  const fullHolidayType = humanize(oFetch(jsHoliday, 'full_holiday_type'));
  const status = humanize(oFetch(jsHoliday, 'state'));
  const note = oFetch(jsHoliday, 'note') || '-';
  const creator = oFetch(jsHoliday, 'creator');
  const requestedBy = jsHoliday.requestedBy;
  const requestedAt =
    jsHoliday.requestedAt && `(${safeMoment.iso8601Parse(jsHoliday.requestedAt).format('Do MMMM YYYY - HH:mm')})`;
  const cerated = `(${safeMoment.iso8601Parse(oFetch(jsHoliday, 'created_at')).format('Do MMMM YYYY - HH:mm')})`;
  const editable = oFetch(jsHoliday, 'editable');
  const isFrozen = oFetch(jsHoliday, 'frozen');
  const isPublic = oFetch(jsHoliday, 'isPublic');
  const sPayslipDate = oFetch(jsHoliday, 'payslip_date');
  const payslipDateText = sPayslipDate ? safeMoment.uiDateParse(sPayslipDate).format(utils.commonDateFormat) : 'N/A';

  const holidayDaysCount = utils.getDaysCountFromInterval(
    oFetch(jsHoliday, 'start_date'),
    oFetch(jsHoliday, 'end_date'),
  );

  const hasEditPermissions =
    oFetch(jsHoliday, 'type') === 'holiday'
      ? oFetch(staffMemberProfileHolidaysPermissions, 'canEditHoliday')({ permissionsData: permissionsData, id: id })
      : oFetch(staffMemberProfileHolidaysPermissions, 'canEditHolidayRequest')({
        permissionsData: permissionsData,
        id: id,
      });
  const isEditable = hasEditPermissions && editable && pageMatchesHolidayAppType;

  const hasDeletePermissions =
    oFetch(jsHoliday, 'type') === 'holiday'
      ? oFetch(staffMemberProfileHolidaysPermissions, 'canDestroyHoliday')({ permissionsData: permissionsData, id: id })
      : oFetch(staffMemberProfileHolidaysPermissions, 'canDestroyHolidayRequest')({
        permissionsData: permissionsData,
        id: id,
      });
  const isDeletable = hasDeletePermissions && editable && pageMatchesHolidayAppType;

  const rowClass = classNames({
    'boss-table__row': true,
    'boss-table__row_state_frozen': isFrozen,
    'boss-table__row_state_alert': isPublic
  });

  const appTypeText = multipleAppsPresent ? `${holidayAppType[0].toUpperCase()}${holidayAppType.substring(1).toLowerCase()}` : null;
  const holidayTypeText = `${holidayDaysCount} ${pluralize(fullHolidayType, holidayDaysCount)}${isPublic ? ' (Public)' : ''}`;

  return (
    <div className={rowClass}>
      <TypeCell
        label="type"
        appTypeText={appTypeText}
        holidayTypeText={holidayTypeText}
      />
      <SimpleCell
        label="status"
        text={status}
        classNames={statusClasses[oFetch(jsHoliday, 'state')]}
      />
      <SimpleCell
        label="dates"
        text={utils.formatDateForHoliday(holiday.toJS())}
      />
      <SimpleCell
        label="note"
        text={note}
      />
      <CreatedByCell
        label="Created"
        creator={creator}
        created={cerated}
        status={status}
        requestedAt={requestedAt}
        requestedBy={requestedBy}
      />
      <SimpleCell
        label="payslipDate"
        text={payslipDateText}
      />
      <ActionsCell
        label="actions"
        isEditable={isEditable}
        isDeletable={isDeletable}
        isStaffMemberDisabled={isStaffMemberDisabled}
        holidaysId={oFetch(jsHoliday, 'id')}
        holiday={holiday}
        deleteHoliday={deleteHoliday}
        onEditHoliday={onEditHoliday}
        isFrozen={isFrozen}
      />
    </div>
  );
};

const Header = () => {
  return (
    <div className="boss-table__row">
      <div className="boss-table__cell boss-table__cell_role_header">Type</div>
      <div className="boss-table__cell boss-table__cell_role_header">Status</div>
      <div className="boss-table__cell boss-table__cell_role_header">Dates</div>
      <div className="boss-table__cell boss-table__cell_role_header">Note</div>
      <div className="boss-table__cell boss-table__cell_role_header">Created</div>
      <div className="boss-table__cell boss-table__cell_role_header">Payslip Date</div>
      <div className="boss-table__cell boss-table__cell_role_header" />
    </div>
  );
};

const HolidaysTableDesktop = ({ holidays, deleteHoliday, onEditHoliday, isStaffMemberDisabled, permissionsData }) => {

  const renderHolidays = (args) => {
    const multipleAppsPresent = oFetch(args, 'multipleAppsPresent');
    const holidays = oFetch(args, 'holidays');
    return holidays.map(holiday => {
      return (
        <Row
          multipleAppsPresent={multipleAppsPresent}
          key={holiday.get('id')}
          isStaffMemberDisabled={isStaffMemberDisabled}
          holiday={holiday}
          permissionsData={permissionsData}
          deleteHoliday={deleteHoliday}
          onEditHoliday={onEditHoliday}
        />
      );
    });
  };

  const holidaysJs = holidays.toJS();
  const uniqAppTypes = new Set(holidaysJs.map((holiday) => {
    return oFetch(holiday, 'app_type');
  }));
  const multipleAppsPresent = uniqAppTypes.size > 1;

  return (
    <div className="boss-table boss-table_page_smp-holiday-requests">
      <Header />
      {renderHolidays({ holidays, multipleAppsPresent })}
    </div>
  );
};

const HolidaysTable = ({ holidays, deleteHoliday, onEditHoliday, isStaffMemberDisabled, permissionsData }) => {
  return (
    <div className="boss-board__manager-table">
      <HolidaysTableDesktop
        holidays={holidays}
        isStaffMemberDisabled={isStaffMemberDisabled}
        deleteHoliday={deleteHoliday}
        onEditHoliday={onEditHoliday}
        permissionsData={permissionsData}
      />
    </div>
  );
};

export default HolidaysTable;
