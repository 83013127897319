import React from 'react';
import oFetch from 'o-fetch';
import { useDispatch } from 'react-redux';

import { useMarketingTaskModals } from '../../../marketing-task-modals';
import * as actions from '../../../redux/actions';
import { ConfirmButtons } from '../../confirm-buttons';
import { TaskCompletion } from './task-completion';
import { TaskRejection } from './task-rejection';

export function TaskDetailsReview(props) {
  const { openRejectTaskModal } = useMarketingTaskModals();
  const dispatch = useDispatch();
  const marketingTask = oFetch(props, 'marketingTask');
  const [currentCompletion, currentRejection, permissions] = oFetch(
    marketingTask,
    'currentCompletion',
    'currentRejection',
    'permissions',
  );
  const [id, isInReview] = oFetch(marketingTask, 'id', 'isInReview');
  const [canRejectCompletion, canAccept] = oFetch(permissions, 'canRejectCompletion', 'canAccept');
  function handleAcceptClick() {
    return dispatch(actions.acceptMarketingTask({ marketingTaskId: id }));
  }

  return (
    <div className="boss-task__group boss-task__group_role_review">
      <div className="boss-task__group-header">
        <div className="boss-task__group-info">
          <h3 className="boss-task__group-title boss-task__group-title_state_alert">Review</h3>
        </div>
      </div>
      <div className="boss-task__group-content">
        {currentRejection && <TaskRejection rejection={currentRejection} />}
        {currentCompletion && <TaskCompletion completion={currentCompletion} />}
        {isInReview && (canRejectCompletion || canAccept) && (
          <ConfirmButtons
            text={`This task has been mark completed.\n If there is a physical deliverable such as prints, pleases only accept this task on arrival.`}
            onYesClick={handleAcceptClick}
            onNoClick={() => openRejectTaskModal(marketingTask)}
            canNoClick={canRejectCompletion}
            canYesClick={canAccept}
            yesText="Accept"
            noText="Reject"
          />
        )}
      </div>
    </div>
  );
}
