import React from 'react';
import oFetch from 'o-fetch';

export default function WeekDayLastEventLabel(props) {
  const lastEventData = oFetch(props, 'lastEventData');
  const [formattedEventType, eventBy, formattedEventAt] = oFetch(
    lastEventData,
    'formattedEventType',
    'eventBy',
    'formattedEventAt',
  );

  return (
    <div className="purple-form__field purple-form__field_size_max">
      <p className="purple-form__text">
        <span className="purple-form__text-light">{formattedEventType} by </span>
        <span className="purple-form__text-bold">{eventBy}</span>
        <span className="purple-form__text-light"> at </span>
        <span className="purple-form__text-bold">{formattedEventAt}</span>
      </p>
    </div>
  );
}
