import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import utils from '@/lib/utils';

export function SecurityPayRateCollapsibleCard(props) {
  const [title, defaultOpen, actionRender, children] = oFetch(
    props,
    'title',
    'defaultOpen',
    'actionRender',
    'children',
  );
  const [isOpen, setOpenState] = useState(defaultOpen);

  function titleRender() {
    if (utils.isFunction(title)) {
      return title();
    }
    return (
      <div className="boss-check__header-group">
        <h3 className="boss-check__title">{title}</h3>
      </div>
    );
  }

  function toggleCard() {
    setOpenState(!isOpen);
  }

  return (
    <div className="boss-check boss-check_role_board boss-check_page_security-pay-rate-base">
      <div className="boss-check__header">
        {titleRender()}
        <div className="boss-check__header-actions">{actionRender()}</div>
        <div
          onClick={toggleCard}
          className="boss-check__dropdown-link boss-check__dropdown-link_type_icon boss-check__dropdown-link_state_closed"
        >
          Toggle Dropdown
        </div>
      </div>
      <Collapse isOpened={isOpen} className="boss-check__dropdown" style={{ display: 'block' }}>
        {children}
      </Collapse>
    </div>
  );
}

SecurityPayRateCollapsibleCard.defaultProps = {
  defaultOpen: false,
  actionRender: () => {},
};
