import React from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';
import cn from 'classnames';
import { ColoredSingleOption, ColoredMultipleValue } from '@/components/boss-form/colored-select';

export default function StaffTypesField(props) {
  const [input, meta, options] = oFetch(props, 'input', 'meta', 'options');
  const [name, value, onChange] = oFetch(input, 'name', 'value', 'onChange');
  const { error, touched } = meta;
  const hasError = touched && error;

  function handleChange(values) {
    if (!values) {
      return onChange([]);
    }
    const valuesArray = values.split(',').map(value => parseInt(value));
    onChange(valuesArray);
  }

  return (
    <div className="boss-form__field boss-form__field_layout_max">
      <div className="boss-form__select">
        <Select
          name={name}
          value={value}
          onChange={handleChange}
          clearable
          valueComponent={ColoredMultipleValue}
          optionComponent={ColoredSingleOption}
          simpleValue
          multi
          placeholder="Select Staff Type"
          options={options}
        />
      </div>
      {hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error}</span>
          </p>
        </div>
      )}
    </div>
  );
}
