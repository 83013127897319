import React from 'react';
import oFetch from 'o-fetch';
import { TaskForm } from '../forms';
import { UPLOADED_STATE } from '@/components/boss-form/boss-form-trix-rte-field-with-attachments/file-list';

export function EditTaskModalContent(props) {
  const [onSubmit, marketingTask] = oFetch(props, 'onSubmit', 'marketingTask');

  const initialValues = {
    taskId: oFetch(marketingTask, 'id'),
    title: oFetch(marketingTask, 'title'),
    venueId: oFetch(marketingTask, 'venueId'),
    deadlineDate: oFetch(marketingTask, 'deadlineDate'),
    description: oFetch(marketingTask, 'description'),
    fileIds: oFetch(marketingTask, 'attachments').map(attachment => {
      return oFetch(attachment, 'blob_id');
    }),
  };

  const files = oFetch(marketingTask, 'attachments').reduce((acc, attachment) => {
    acc[oFetch(attachment, 'blob_id')] = {
      id: oFetch(attachment, 'blob_id'),
      name: oFetch(attachment, 'filename'),
      url: oFetch(attachment, 'url'),
      file: null,
      state: UPLOADED_STATE,
      isImage: oFetch(attachment, 'is_image'),
      contentType: oFetch(attachment, 'content_type'),
    };
    return acc;
  }, {});

  return (
    <div className="boss-modal-window__form">
      <TaskForm initialValues={initialValues} onSubmit={onSubmit} files={files} buttonText="Update" />
    </div>
  );
}
