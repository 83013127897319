import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const pageOptions = oFetch(payload, 'pageOptions');
      const permissions = oFetch(pageOptions, 'permissions');
      return permissions;
    },
  },
  initialState,
);
