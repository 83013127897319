import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../../../profile-wrapper/constants';

const initialState = [];

export default handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const quizCategories = oFetch(action, 'payload.quizCategories');
      return quizCategories;
    },
  },
  initialState,
);
