import React, { useCallback, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import cn from 'classnames';

import oFetch from 'o-fetch';

const VALID_FILE_TYPES = '.csv';
const MAX_FILE_SIZE = 10000000; // 1MB

const CSVUpload = props => {
  const [fileName, setFileName] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onUpload = oFetch(props, 'onUpload');

  const uploadClassNames = cn('boss-upload', { 'boss-upload_state_error': !!errors });

  function onDropRejected(rejectedEntries) {
    const rejectedEntry = rejectedEntries[0];
    const [errors, file] = oFetch(rejectedEntry, 'errors', 'file');
    const fileName = oFetch(file, 'name');
    const normalizedErrors = errors.map(error => {
      return oFetch(error, 'message');
    });
    const error = {
      fileName: fileName,
      messages: normalizedErrors,
    };
    setErrors(error);
  }

  const onDropAccepted = useCallback(acceptedFiles => {
    const acceptedFile = acceptedFiles[0];
    const fileName = oFetch(acceptedFile, 'name');

    setErrors(null);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileAsBinaryString = oFetch(fileReader, 'result');
      onUpload({ csvFileBinary: fileAsBinaryString });
    };

    fileReader.readAsBinaryString(acceptedFile);

    setUploading(true);
    setFileName(fileName);
  }, []);

  function renderDropzone() {
    return (
      <ReactDropzone
        accept={VALID_FILE_TYPES}
        maxSize={MAX_FILE_SIZE}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className="boss-upload__area">
              <div className="boss-upload__area-inner">
                {isDragActive ? (
                  <p className="boss-upload__area-text">Drop the csv file here ...</p>
                ) : (
                    <p className="boss-upload__area-text">
                      Click to select a csv file or drag it to upload
                    </p>
                  )}
                <input {...getInputProps()} />
                <button
                  type="button"
                  className="boss-button boss-button_role_primary boss-upload__area-button"
                >
                  Choose File
                </button>
              </div>
            </div>
          );
        }}
      </ReactDropzone>
    );
  }

  function renderUploading() {
    return (
      <div className="boss-upload__area">
        <div className="boss-upload__area-inner">
          <p className="boss-upload__area-text boss-upload__area-text_adjust_wrap">
            Uploading <span className="boss-upload__area-text-marked">{fileName}</span>...
          </p>
          <div className="boss-spinner boss-spinner_size_large boss-upload__area-spinner" />
        </div>
      </div>
    );
  }

  function renderErrors() {
    const [messages, fileName] = oFetch(errors, 'messages', 'fileName');
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text boss-form__error-text_adjust_wrap">
          <span className="boss-form__error-line">
            <span className="boss-form__error-marked">{fileName}</span> {messages}
          </span>
        </p>
      </div>
    );
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_csv-upload">
      <div className="boss-form">
        <div className="boss-form__field">
          {errors && renderErrors()}
          <div className={uploadClassNames}>{uploading ? renderUploading() : renderDropzone()}</div>
        </div>
      </div>
    </div>
  );
};

export default CSVUpload;
