/* eslint-disable react/prop-types */
import React from 'react';
import Select from '@/lib/boss-react-select';
import { SelectTimeInputRenderer } from '@/components/boss-form/time-input-render';
import oFetch from 'o-fetch';
import { RotaDate, mGetTimeAsterisk } from '@/lib/rota-date';

const BossFormTimeSelect = (props) => {
  const {
    label,
    required,
    placeholder,
    input: { value, onChange, name },
    meta: { touched, error },
    disabled,
  } = props;
  const intervalMinutes = oFetch(props, 'interval');
  //dDate is wrong here expects moment
  const mDate = props.dDate;
  const appType = oFetch(props, 'appType');
  const rotaDate = mDate && RotaDate.mFromDate({
    mCalendarDate: mDate,
    appType,
  });

  const getOptions = (args) => {
    const intervalMinutes = oFetch(args, 'intervalMinutes');
    const rotaDate = oFetch(args, 'rotaDate');
    const mTime = rotaDate.mStartTime();
    const mEndTime = rotaDate.mEndTime();

    let offset = 0;
    let options = [{ value: 0, label: `${mGetTimeAsterisk({ mTime })}${mTime.format('HH:mm')}` }];
    while(!mTime.isAfter(mEndTime)) {
      mTime.add(intervalMinutes, 'minutes');
      offset = offset + intervalMinutes;

      options.push({
        value: offset,
        label: `${mGetTimeAsterisk({ mTime })}${mTime.format('HH:mm')}`,
      });
    }

    return options;
  };

  return (
    <div className="boss-form__field">
      <label htmlFor={name} className="boss-form__label">
        <span className="boss-form__label-text">{`${label} ${required ? '*' : ''}`}</span>
      </label>
      <div className={`boss-form__select ${touched && error && 'boss-form__select_state_error'}`}>
        <Select
          options={getOptions({ intervalMinutes, rotaDate })}
          onChange={onChange}
          name={name}
          simpleValue
          inputRenderer={SelectTimeInputRenderer}
          placeholder={placeholder || 'Select ...'}
          value={value}
          disabled={disabled}
        />
      </div>
      {touched && error && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default BossFormTimeSelect;
