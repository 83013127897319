import oFetch from 'o-fetch';
import React, { Fragment, useState } from 'react';
import ContentWrapper from '@/components/content-wrapper';

import {
  UserProfileDashboard,
  PersonalDetails,
  LinkedUserAccessDetails,
  NotLinkedUserAccessDetails,
  AccountDetails,
} from './components';
import { userProfileHooks } from './user-profile-hooks';

export function UserProfile(props) {
  const permissions = oFetch(props, 'permissions');
  const {
    user,
    forceSyncInProgress,
    jsmSyncEnabled,
    forceSyncUser,
    openPersonalDetailsEditModal,
    openAccessDetailsEditModal,
    openDisableModal,
    enableUser,
    appPermissions,
    openAddNewAppModal,
    openAddTemporaryPermissionModal,
    openEditTemporaryPermissionModal,
    openEditRoleModal,
    updateAppPermanentPermissions,
    disableTemporaryPermission,
    disableAppPermissions,
    openVenuePermissionDetailsModal,
  } = userProfileHooks({
    initialUser: oFetch(props, 'user'),
    initialAppAccessData: oFetch(props, 'appAccessData'),
    permissions,
    initialJsmSyncEnabled: oFetch(props, 'jsmSyncEnabled'),
  });
  const isLinked = oFetch(user, 'isLinked');

  function handleForceSyncClick() {
    forceSyncUser();
  }

  return (
    <Fragment>
      <UserProfileDashboard
        user={user}
        onDisableClick={openDisableModal}
        onEnableClick={enableUser}
        permissions={permissions}
        forceSyncInProgress={forceSyncInProgress}
        onForceSyncClick={handleForceSyncClick}
        jsmSyncEnabled={jsmSyncEnabled}
      />
      <ContentWrapper className="boss-page-main__flow">
        <PersonalDetails
          user={user}
          onEditClick={openPersonalDetailsEditModal}
        />
        <AccountDetails user={user} />
        {isLinked && (
          <LinkedUserAccessDetails
            user={user}
            onRoleEditClick={openEditRoleModal}
            appPermissions={appPermissions}
            onUpdatePermanentPermissions={updateAppPermanentPermissions}
            onAddNewApp={openAddNewAppModal}
            onDisableTemporaryAccess={disableTemporaryPermission}
            onDisableAppPermissions={disableAppPermissions}
            onAddTemporaryAccess={openAddTemporaryPermissionModal}
            onEditTemporaryAccess={openEditTemporaryPermissionModal}
            onVenueClick={openVenuePermissionDetailsModal}
          />
        )}
        {!isLinked && (
          <NotLinkedUserAccessDetails
            user={user}
            onEditClick={openAccessDetailsEditModal}
          />
        )}
      </ContentWrapper>
    </Fragment>
  );
}
