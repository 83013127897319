import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

CountingField.defaultProps = {
  pence: false,
};

export default function CountingField(props) {
  const [names, label, pence, editable] = oFetch(props, 'names', 'label', 'pence', 'editable');
  const [normalName, foreignName] = names;
  const [normalField, foreignField] = oFetch(props, `${normalName}`, `${foreignName}`);
  const [normalFieldInput, normalFieldMeta] = oFetch(normalField, 'input', 'meta');
  const [foreignFieldInput, foreignFieldMeta] = oFetch(foreignField, 'input', 'meta');
  const [normalFieldValue, normalFieldOnChange] = oFetch(normalFieldInput, 'value', 'onChange');
  const [foreignFieldValue, foreignFieldOnChange] = oFetch(foreignFieldInput, 'value', 'onChange');

  const inputPattern = pence ? null : '[0-9]';

  const normalInputClassNames = cn(
    'purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell',
    {
      'purple-form-field_state_error': !!normalFieldMeta.error,
    },
  );
  const foreignInputClassNames = cn(
    'purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell',
    {
      'purple-form-field_state_error': !!foreignFieldMeta.error,
    },
  );
  const mobileFieldClassNames = cn('purple-form-field  purple-form-field_role_control', {
    'purple-form-field_highlight_gray-light': !editable,
  });

  function getTotal() {
    const total = (normalFieldValue || 0) + (foreignFieldValue || 0);
    if (pence) {
      return total / 100;
    }
    return total;
  }

  function handleNormalFieldOnChange(e) {
    const newValue = oFetch(e, 'target.value');
    const regExp = !pence ? new RegExp('^[0-9]+$') : new RegExp('^[0-9]+(.[0-9]{0,2})?$');
    if (newValue === '') {
      return normalFieldOnChange(newValue);
    }
    if (!regExp.test(newValue)) {
      e.target.select();
      return normalFieldOnChange(normalFieldValue);
    }
    const normalizedValue = pence ? +parseFloat(newValue).toFixed(2) : +parseInt(newValue);
    if (pence) {
      normalFieldOnChange(normalizedValue * 100);
    } else {
      return normalFieldOnChange(normalizedValue);
    }
  }

  function handleForeignFieldOnChange(e) {
    const newValue = oFetch(e, 'target.value');
    const regExp = !pence ? new RegExp('^[0-9]+$') : new RegExp('^[0-9]+(.[0-9]{0,2})?$');

    if (newValue === '') {
      return foreignFieldOnChange(newValue);
    }
    if (!regExp.test(newValue)) {
      e.target.select();
      return foreignFieldOnChange(foreignFieldValue);
    }
    const normalizedValue = pence ? +parseFloat(newValue).toFixed(2) : +parseInt(newValue);
    if (pence) {
      foreignFieldOnChange(normalizedValue * 100);
    } else {
      foreignFieldOnChange(normalizedValue);
    }
  }

  function getValue(value) {
    if (pence) {
      return (value || 0) / 100;
    }
    return value || 0;
  }

  function handleFocus(e) {
    e.target.select();
  }

  return (
    <div className="purple-table__group">
      <div className="purple-table__row">
        <div className="purple-table__cell">
          <div className="purple-table__info">
            <p className="purple-table__text">{label}</p>
          </div>
        </div>
        {editable && (
          <div className="purple-table__cell">
            <div className={normalInputClassNames}>
              <div className="purple-form-field__input purple-form-field__input_size_l">
                <input
                  onFocus={handleFocus}
                  placeholder={0}
                  onChange={handleNormalFieldOnChange}
                  value={getValue(normalFieldValue)}
                />
              </div>
            </div>
          </div>
        )}
        {!editable && (
          <div className="purple-table__cell">
            <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_justify_center purple-form-field_adjust_table-cell">
              <div className="purple-form-field__box purple-form-field__box_size_l">
                £{getValue(normalFieldValue)}
              </div>
            </div>
          </div>
        )}
        {editable && (
          <div className="purple-table__cell">
            <div className={foreignInputClassNames}>
              <div className="purple-form-field__input purple-form-field__input_size_l">
                <input
                  placeholder={0}
                  onFocus={handleFocus}
                  onChange={handleForeignFieldOnChange}
                  value={getValue(foreignFieldValue)}
                />
              </div>
            </div>
          </div>
        )}
        {!editable && (
          <div className="purple-table__cell">
            <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_justify_center purple-form-field_adjust_table-cell">
              <div className="purple-form-field__box purple-form-field__box_size_l">
                £{getValue(foreignFieldValue)}
              </div>
            </div>
          </div>
        )}
        <div className="purple-table__cell">
          <div className="purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell">
            <div className="purple-form-field__value">£{getTotal()}</div>
          </div>
        </div>
      </div>
      <div className="purple-table__panel">
        <div className="purple-panel purple-panel_role_board-group">
          <div className="purple-panel__group">
            <div className="purple-panel__group-content">
              <div className="purple-panel__title">{label}</div>
              <div className="purple-panel__group-line">
                <div className={mobileFieldClassNames}>
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Value</span>
                  </p>
                  {!editable && (
                    <div className="purple-form-field__box purple-form-field__box_size_l">
                      £{getValue(normalFieldValue)}
                    </div>
                  )}
                  {editable && (
                    <div className="purple-form-field__input purple-form-field__input_size_l">
                      <input
                        placeholder="0"
                        onFocus={handleFocus}
                        onChange={handleNormalFieldOnChange}
                        value={getValue(normalFieldValue)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="purple-panel__group-line">
                <div className={mobileFieldClassNames}>
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Foreign</span>
                  </p>
                  {!editable && (
                    <div className="purple-form-field__box purple-form-field__box_size_l">
                      £{getValue(foreignFieldValue)}
                    </div>
                  )}
                  {editable && (
                    <div className="purple-form-field__input purple-form-field__input_size_l">
                      <input
                        placeholder="0"
                        onFocus={handleFocus}
                        onChange={handleForeignFieldOnChange}
                        value={getValue(foreignFieldValue)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="purple-panel__group-line">
                <div className="purple-form-field purple-form-field_role_control">
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Total</span>
                  </p>
                  <div className="purple-form-field__value">£{getTotal()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
