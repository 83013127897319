import oFetch from 'o-fetch';
import React, { Fragment, useMemo, useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form/immutable';
import { BossFormSelect } from '@/components/boss-form';

export function BossFormAppRoleVenueSelect(props) {
  const [inviteTypeOptions, roleOptions, venues, venuesAccess] = oFetch(
    props,
    'inviteTypeOptions',
    'roleOptions',
    'venues',
    'venuesAccess',
  );
  const [inviteType, role, venueIds] = oFetch(props, 'inviteType', 'role', 'venueIds');
  const roleValue = oFetch(role, 'input.value');
  const [inviteTypeValue, setInviteTypeValue] = useState(oFetch(inviteType, 'input.value'));
  function normalizedSetInviteTypeValue(value) {
    // this is required because Field.onChange is somehow destucturing its arguments
    // cause the value to become an object with a preventDefault function instead of the actual string value
    const normalizedValue = Object.entries(value).reduce((acc, [key, value]) => {
      if (key !== 'preventDefault') {
        acc.push(value);
      }
      return acc;
    }, []).join('');
    setInviteTypeValue(normalizedValue);
  }
  const prevRoleValue = useRef(roleValue);
  const hasAllVenueAccess = useMemo(() => {
    if (!roleValue) {
      return false;
    }
    return oFetch(venuesAccess[roleValue], 'hasAllVenueAccess');
  }, [roleValue]);

  const normalisedVenueOptions = useMemo(() => {
    return venues.reduce((acc, venue) => {
      let includeVenue = true;
      const [isBoss, isMoss] = oFetch(venue, 'isBoss', 'isMoss');

      if (inviteTypeValue === "boss") {
        includeVenue = isBoss;
      } else if (inviteTypeValue === "moss") {
        includeVenue = isMoss;
      } else if (inviteTypeValue === "") {
        includeVenue = false;
      } else {
        throw new Error(`unsupported invite type encountered: ${inviteTypeValue}`);
      }

      if (includeVenue) {
        acc.push({
          label: oFetch(venue, 'name'),
          value: oFetch(venue, 'id'),
        });
      }

      return acc;
    }, []);
  }, [inviteTypeValue]);

  useEffect(() => {
    if (inviteTypeValue === "") {
      const roleChange = oFetch(role, 'input.onChange');
      const venueIdsChange = oFetch(venueIds, 'input.onChange');
      venueIdsChange([]);
      roleChange("");
    }

    if (prevRoleValue.current !== roleValue) {
      const venueIdsChange = oFetch(venueIds, 'input.onChange');
      if (hasAllVenueAccess) {
        const allVenueIds = normalisedVenueOptions.map(venueOption => oFetch(venueOption, 'value'));
        venueIdsChange(allVenueIds);
      } else {
        venueIdsChange([]);
      }
    }
  }, [roleValue, inviteTypeValue]);

  function renderRoleSelect() {
    if (!inviteTypeValue) {
      return null;
    }

    return (
      <Field
        name="role"
        label="Role"
        required
        options={roleOptions}
        clearable={true}
        component={BossFormSelect}
      />
    );
  }

  function renderVenuesSelect() {
    if (!roleValue) {
      return null;
    }
    return (
      <Field
        component={BossFormSelect}
        name="venueIds"
        selectAll
        multi
        label="Venues"
        options={normalisedVenueOptions}
      />
    );
  }

  return (
    <Fragment>
      <Field
        name="inviteType"
        label="Type"
        required
        options={inviteTypeOptions}
        onChange={normalizedSetInviteTypeValue}
        component={BossFormSelect}
      />
      { renderRoleSelect() }
      {renderVenuesSelect()}
    </Fragment>
  );
}
