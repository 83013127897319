import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = null;

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const permissions = oFetch(action.payload, 'permissions');
      return permissions;
    },
    [types.ADD_EVENT]: (state, action) => {
      const permissions = oFetch(action.payload, 'permissions');
      return { ...state, events: { ...state.events, ...permissions } };
    },
    [types.ADD_REMINDER]: (state, action) => {
      const permissions = oFetch(action.payload, 'permissions');
      return { ...state, reminders: { ...state.reminders, ...permissions } };
    },
    [types.ADD_TASK]: (state, action) => {
      const permissions = oFetch(action.payload, 'permissions');
      return { ...state, tasks: { ...state.tasks, ...permissions } };
    },
  },
  initialState,
);
