import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';

import { BasicCell } from './table';

export function MoneyDiffCell(props) {
  const [value1, value2, delta, withitThreshold] = oFetch(
    props,
    'value1',
    'value2',
    'delta',
    'withitThreshold',
  );

  function renderDiff() {
    if (delta && delta !== 0) {
      const className = cn({
        'boss-table__text-faded': withitThreshold,
        'boss-table__text-alert': !withitThreshold,
      });
      return <span className={className}>({utils.moneyFormat(delta / 100.0)})</span>;
    }
  }

  return (
    <BasicCell>
      {utils.moneyFormat(value1 / 100.0)}&nbsp;/&nbsp;{utils.moneyFormat(value2 / 100.0)}
      <div>{renderDiff()}</div>
    </BasicCell>
  );
}
