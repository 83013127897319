import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import reducers from '../HoursConfirmation/redux/reducers';
import { loadInitialStateAction } from '../HoursConfirmation/redux/actions';
import HoursConfirmation from '../HoursConfirmation/hours-confirmation';

export default function HoursConfirmationApp(props) {
  const [store, setStore] = useState();

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(loadInitialStateAction(props));
    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <LegacyGlobalProvider
      global={{
        timeSelectOptions: null,
        graphXAxisHours: null,
      }}
    >
      <Provider store={store}>
        <ModalsProvider>
          <HoursConfirmation />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
