import React from 'react';
import oFetch from 'o-fetch';
import { ChangeVenueForm } from '../forms';

export function ChangeVenue(props) {
  const [onSubmit, processing, venueOptions, device] = oFetch(
    props,
    'onSubmit',
    'processing',
    'venueOptions',
    'device',
  );

  const [deviceId, venueId] = oFetch(device, 'id', 'venueId');

  const initialValues = {
    deviceId: deviceId,
    venueId: venueId,
  };

  return (
    <ChangeVenueForm
      onSubmit={onSubmit}
      processing={processing}
      venueOptions={venueOptions}
      initialValues={initialValues}
    />
  );
}
