import React from 'react';
import oFetch from 'o-fetch';
import { QuestionForm } from './forms';
import { connect } from 'react-redux';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

function EditQuestion(props) {
  const [
    questionTypesOptions,
    question,
    questionCategoriesOptions,
    questionAreasOptions,
    requiredQuestionType,
    onSubmit,
    currentVenueId,
  ] = oFetch(
    props,
    'questionTypesOptions',
    'question',
    'questionCategoriesOptions',
    'questionAreasOptions',
    'requiredQuestionType',
    'onSubmit',
    'currentVenueId',
  );
  const [
    id,
    text,
    type,
    questionnaireCategoryId,
    questionnaireAreaId,
    requiredQuestion,
  ] = oFetch(
    question,
    'id',
    'text',
    'type',
    'questionnaireCategoryId',
    'questionnaireAreaId',
    'requiredQuestion',
  );

  const initialValues = {
    id,
    text,
    type,
    venueId: currentVenueId,
    category: questionnaireCategoryId,
    area: questionnaireAreaId,
    required: requiredQuestion,
  };

  return (
    <QuestionForm
      form="edit-question-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      questionTypesOptions={questionTypesOptions}
      questionCategoriesOptions={questionCategoriesOptions}
      questionAreasOptions={questionAreasOptions}
      requiredQuestionType={requiredQuestionType}
      submitButtonText="Update Question"
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  const question = oFetch(ownProps, 'question');
  const questionnaireCategoryId = oFetch(question, 'questionnaireCategoryId');
  const questionnaireAreaId = oFetch(question, 'questionnaireAreaId');

  return {
    questionTypesOptions: selectors.getQuestionTypesOptions(state),
    questionCategoriesOptions: selectors.getEditQuestionCategoriesOptions({ state, questionnaireCategoryId }),
    questionAreasOptions: selectors.getEditQuestionAreasOptions({ state, questionnaireAreaId }),
    requiredQuestionType: selectors.requiredQuestionTypeSelector(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);
