import React, { useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import utils from '@/lib/utils';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';

import { AppDetails } from './components';

export function usersHooks(params) {
  const initialUsers = oFetch(params, 'initialUsers');
  const [initialFilterValues, apps] = oFetch(useLegacyGlobal(), 'filter', 'apps');
  const [currentFilter, setCurrentFilter] = useState(initialFilterValues);
  const [email, name, role, status] = oFetch(currentFilter, 'email', 'name', 'role', 'status');

  const { openModal } = useModal();

  const normalizedUsers = useMemo(() => {
    return initialUsers.map(user => {
      const venuePermissions = oFetch(user, 'venuePermissions');
      const userId = oFetch(user, 'id');
      const normalizedAppVenuesPermissions = Object.entries(utils.groupBy('appId')(venuePermissions)).map(
        venuePermissionEntry => {
          const [id, venuePermissions] = venuePermissionEntry;
          const appVenues = Object.entries(utils.groupBy('venueName')(venuePermissions)).map(venueEntry => {
            const [venueName, permissions] = venueEntry;
            const hasPermanent = permissions.some(permission => oFetch(permission, 'isPermanent'));
            return {
              venueName,
              hasPermanent,
              timedPermissions: permissions.filter(
                permission => oFetch(permission, 'isTemporaryUser') || oFetch(permission, 'isTemporaryAi'),
              ),
            };
          });
          return {
            appId: parseInt(id),
            name: oFetch(
              apps.find(app => oFetch(app, 'id') === parseInt(id)),
              'title',
            ),
            venues: appVenues,
          };
        },
      );
      return { ...user, appVenuesPermissions: normalizedAppVenuesPermissions };
    });
  }, [initialUsers, currentFilter]);

  const filteredUsers = useMemo(() => {
    return normalizedUsers.filter(user => {
      const emailMatched = email === '' || utils.recordMatch(email, user, 'email');
      const nameMatched = name === '' || utils.recordMatch(name, user, 'fullName');
      const roleMatched = role === '' || utils.recordMatch(role, user, 'role');
      const statusMatched = status === '' || utils.recordMatch(status, user, 'status');

      return emailMatched && nameMatched && roleMatched && statusMatched;
    });
  }, [normalizedUsers, currentFilter]);

  function handleFilterChange(params) {
    utils.setNewPageQueryParams(params);
    setCurrentFilter(params);
  }

  function openAppDetailsModal(params) {
    const appVenuesPermission = oFetch(params, 'appVenuesPermission');
    const [appName, venues] = oFetch(appVenuesPermission, 'name', 'venues');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AppDetails,
      onSubmit: closeModal => {
        closeModal();
      },
      config: {
        title: () => (
          <div className="boss-modal-window__header">
            <span className="boss-modal-window__marked">{appName}</span> App Venue Details
          </div>
        ),
      },
      props: {
        venues,
      },
    });
  }

  return {
    handleFilterChange,
    users: filteredUsers,
    openAppDetailsModal,
  };
}
