import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import ClockInOutView from './containers/clock-in-out-view';
import actionCreators from '@/redux/actions';
import { createBossStore } from '@/redux/store';
import utils from '@/lib/utils';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';

export function ClockInOutApp() {
  const [store, setStore] = useState(null);
  const appType = BOSS_APP_TYPE;

  useEffect(() => {
    require('./styles.css');
    const store = createBossStore();
    const interval = setInterval(() => ensureOnCorrectDay(), 2000);

    store.subscribe(function () {
      window.localStorage.setItem('clockInOutApiKey', store.getState().apiKey);
    });

    const localStorageApiKey = window.localStorage.getItem('clockInOutApiKey');
    if (localStorageApiKey != null && localStorageApiKey !== '') {
      store.dispatch(actionCreators().setApiKeyAndFetchClockInOutAppData(localStorageApiKey));
    }
    setStore(store);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (store === null) {
    return null;
  }

  function ensureOnCorrectDay() {
    const now = new Date();
    const rotaDate = RotaDate.fromTime({
      dTime: now,
      appType,
    });
    const dBeginningOfRotaDay = rotaDate.startTime();

    let lastUpdateTime = new Date(Date.parse(window.localStorage.getItem('clockInOutLastUpdateTime')));
    if (lastUpdateTime.toString() === 'Invalid Date') {
      lastUpdateTime = dBeginningOfRotaDay;
      window.localStorage.setItem('clockInOutLastUpdateTime', dBeginningOfRotaDay);
    }

    const nextRequiredUpdate = utils.addDaysToDate(lastUpdateTime, 1);
    if (now > nextRequiredUpdate) {
      window.localStorage.setItem('clockInOutLastUpdateTime', dBeginningOfRotaDay);
      window.location.reload();
    }
  }

  // window.boss.rollbarData.currentUser - use as additional(person) information in Rollbar
  // In Clocking App we using StaffMember instead of authenticated user
  // So we just delete this object
  delete window.boss.rollbarData.currentUser;

  return (
    <Provider store={store}>
      <ClockInOutView />
    </Provider>
  );
}
