import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { CollapsibleCard } from '@/components/hooks-components';

export default function FacialRecognitionSettings(props) {
  const [
    fsFaceMatch,
    fsFaceSize,
    fsFaceInBounds,
    fsSunglasses,
    fsEyesOpen,
    fsBrightness,
    fsSharpness,
    faceSize,
    faceInBounds,
    sunglasses,
    eyesOpen,
    brightness,
    sharpness,
    sfFaceSize,
    sfFaceInBounds,
    sfSunglasses,
    sfEyesOpen,
    sfBrightness,
    sfSharpness,
  ] = oFetch(
    props,
    'fsFaceMatch',
    'fsFaceSize',
    'fsFaceInBounds',
    'fsSunglasses',
    'fsEyesOpen',
    'fsBrightness',
    'fsSharpness',
    'faceSize',
    'faceInBounds',
    'sunglasses',
    'eyesOpen',
    'brightness',
    'sharpness',
    'sfFaceSize',
    'sfFaceInBounds',
    'sfSunglasses',
    'sfEyesOpen',
    'sfBrightness',
    'sfSharpness',
  );
  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Facial Recognition Settings</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <CollapsibleCard title="Profile Avatar Settings">
          <div className="boss-board__group">
            <p className="boss-board__text">
              These rules are applied when add and updating staff members setup for facial
              recognition
            </p>
            <div className="boss-overview">
              <div className="boss-overview__group" />
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face Match</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsFaceMatch}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face Size</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsFaceSize} of total image</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face in Bounds</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsFaceInBounds}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sunglasses</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsSunglasses}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Eyes Open</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsEyesOpen}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Brightness</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsBrightness}</span>
                </p>
              </div>
              <div className="boss-overview__group boss-overview__group_position_last">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sharpness</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{fsSharpness}</span>
                </p>
              </div>
            </div>
          </div>
        </CollapsibleCard>
        <CollapsibleCard
          title={() => {
            return (
              <h2 className="boss-board__title boss-board__title_size_medium">
                Non Scannable Avatar Settings
                <a
                  href="/facial_recognition_settings/edit_profile_bypass_settings"
                  className="boss-button boss-button_type_small boss-button_role_edit boss-board__action"
                >
                  Edit
                </a>
              </h2>
            );
          }}
        >
          <div className="boss-board__group">
            <p className="boss-board__text">
              These rules are applied when adding or updating staff members when choosing the not to
              setup for facial recognition options.
            </p>
            <div className="boss-overview">
              <div className="boss-overview__group" />
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face Size</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{faceSize}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face in Bounds</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{faceInBounds}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sunglasses</span>
                </h4>
                <p className="boss-overview__text">{sunglasses}</p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Eyes Open</span>
                </h4>
                <p className="boss-overview__text">{eyesOpen}</p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Brightness</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{brightness}</span>
                </p>
              </div>
              <div className="boss-overview__group boss-overview__group_position_last">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sharpness</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{sharpness}</span>
                </p>
              </div>
            </div>
          </div>
        </CollapsibleCard>
        <CollapsibleCard
          title={() => {
            return (
              <h2 className="boss-board__title boss-board__title_size_medium">
                Scan Settings
                <a
                  href="/facial_recognition_settings/edit_scan_settings"
                  className="boss-button boss-button_type_small boss-button_role_edit boss-board__action"
                >
                  Edit
                </a>
              </h2>
            );
          }}
        >
          <div className="boss-board__group">
            <p className="boss-board__text">
              These rules are applied when scanning yourself in the native clocking app.
            </p>
            <div className="boss-overview">
              <div className="boss-overview__group" />
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face Size</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{sfFaceSize}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Face in Bounds</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{sfFaceInBounds}</span>
                </p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sunglasses</span>
                </h4>
                <p className="boss-overview__text">{sfSunglasses}</p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Eyes Open</span>
                </h4>
                <p className="boss-overview__text">{sfEyesOpen}</p>
              </div>
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Brightness</span>
                </h4>
                <p className="boss-overview__text">{sfBrightness}</p>
              </div>
              <div className="boss-overview__group boss-overview__group_position_last">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Sharpness</span>
                </h4>
                <p className="boss-overview__text">
                  <span className="boss-overview__text-marked">{sfSharpness}</span>
                </p>
              </div>
            </div>
          </div>
        </CollapsibleCard>
      </ContentWrapper>
    </Fragment>
  );
}
