import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import * as types from './types';
import * as selectors from './selectors';

export const loadInitialState = createAction(types.LOAD_INITIAL_STATE);

export const loadPageData = params => (dispatch, getState) => {
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const page = oFetch(params, 'page');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(loadInitialState(data));
    },
  }).get(`/api/v1/venue-health-checks`, {
    params: {
      page: page,
      venueId: currentVenueId,
    },
  });
};

export const loadMore = () => (dispatch, getState) => {
  const currentPage = selectors.getCurrentPage(getState());
  const nextPage = currentPage + 1;
  return dispatch(loadPageData({ page: nextPage }));
};
