import React  from 'react';
import { RadioGroup, Radio } from '@/components/radio-group';
import oFetch from 'o-fetch';
import _ from 'lodash';

function BossFormSwitcher(props: any) {
  const input = oFetch(props, 'input');

  const renderSwitcherData = () => {
    const options = oFetch(props, 'options')
    if (!_.isArray(options)) {
      throw new Error(`supplied data must be array. got: ${options}`);
    }

    return options.map((item: any, key: any) => {
      return (
        <label
          key={key.toString()} className="boss-form__switcher-label"
        >
          <Radio
            value={oFetch(item, 'value')} className="boss-form__switcher-radio"
          />
          <span className="boss-form__switcher-label-text">
            {oFetch(item, 'label')}
          </span>
        </label>
      );
    });
  };

  const handleChange = (value: any) => {
    input.onChange(value);
  };

  return (
    <div className="boss-form__field">
      <RadioGroup
        name="filter"
        // className="boss-form__switcher"
        selectedValue={oFetch(props, 'input').value}
        onChange={handleChange}
      >
        {renderSwitcherData()}
      </RadioGroup>
    </div>
  );
};

export default BossFormSwitcher;