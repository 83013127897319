import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import cn from 'classnames';

export default class ChecklistItem extends Component {
  state = {
    isOpened: false,
  };

  toggleCollapse = () => {
    this.setState(state => ({ isOpened: !oFetch(state, 'isOpened') }));
  };

  renderChildren = () => {
    const children = oFetch(this.props, 'children');
    if (typeof children === 'function') {
      return children(this.toggleCollapse);
    }
    return children;
  };

  render() {
    const [actionRenderer, titleRenderer, checklist] = oFetch(
      this.props,
      'actionRenderer',
      'titleRenderer',
      'checklist',
    );

    const isOpened = oFetch(this.state, 'isOpened');

    const arrowClassNames = cn('boss-board__switch boss-board__switch_type_angle', {
      'boss-board__switch_state_opened': isOpened,
    });

    return (
      <section className="boss-board boss-board_context_stack boss-board_role_checklist">
        <header className="boss-board__header">
          {titleRenderer(checklist)}
          {actionRenderer(checklist)}
          <div className="boss-board__button-group">
            <button onClick={this.toggleCollapse} type="button" className={arrowClassNames} />
          </div>
        </header>
        <Collapse
          isOpened={isOpened}
          theme={{
            collapse: 'boss-board__content boss-board__content_state_opened',
            content: 'boss-board__content-inner',
          }}
        >
          <div className="boss-board__checklist">{this.renderChildren()}</div>
        </Collapse>
      </section>
    );
  }
}
