import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form';
import utils from '@/lib/utils';
import * as types from './types';
import * as requests from './requests';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const addQuestionToStore = createAction(types.ADD_QUESTION);
export const addQuestionAndDeleteOldInStore = createAction(types.ADD_QUESTION_AND_DELETE_OLD);
export const updateQuestionInStore = createAction(types.UPDATE_QUESTION);
export const removeQuestionFromStore = createAction(types.REMOVE_QUESTION);

function getQuestionFormData(values) {
  const [questionImage, questionText, questionType, answers, categoryId, tags] = oFetch(
    values,
    'questionImage',
    'questionText',
    'questionType',
    'answers',
    'categoryId',
    'tags',
  );

  const formData = new FormData();
  const answersExists = answers.length > 0;
  const tagsExists = tags.length > 0;
  if (!tagsExists) {
    formData.set('tags', null);
  } else {
    tags.forEach(tag => {
      formData.append('tags[]', tag);
    });
  }
  formData.set('questionImage', questionImage);
  formData.set('questionImage', questionImage);
  formData.set('questionText', questionText);
  formData.set('questionType', questionType);
  formData.set('categoryId', categoryId);
  if (answersExists) {
    answers.forEach(answer => {
      formData.append('answers[][image]', answer.image);
      formData.append('answers[][description]', answer.description);
      formData.append('answers[][correct]', answer.correct);
    });
  } else {
    formData.set('answers', null);
  }

  return formData;
}

export const createQuestion = values => dispatch => {
  const formData = getQuestionFormData(values);

  return requests.brCreateQuestion({
    values: formData,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      const [question, answers, questionTagsIds] = oFetch(data, 'question', 'answers', 'questionTagsIds');
      dispatch(addQuestionToStore({ question, answers, questionTagsIds }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Question created Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'questionText', 'questionType', 'answers', 'image'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const updateQuestion = values => dispatch => {
  const formData = getQuestionFormData(values);
  const questionId = oFetch(values, 'id');
  return requests.brUpdateQuestion({
    values: { formData, questionId },
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      const [question, answers, questionTagsIds] = oFetch(data, 'question', 'answers', 'questionTagsIds');
      dispatch(addQuestionAndDeleteOldInStore({ question, answers, questionId, questionTagsIds }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Question updated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'questionText', 'questionType', 'answers', 'image'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const moveQuestion = values => dispatch => {
  const [
    quizCategoryQuestionId,
    quizCategoryId,
  ] = oFetch(
    values,
    'quizCategoryQuestionId',
    'quizCategoryId',
  )

  return requests.brMoveQuestion({
    values: { quizCategoryQuestionId, quizCategoryId },
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      dispatch(removeQuestionFromStore({ quizCategoryQuestionId }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Question Moved Successfully' });
    },
    errorHandler: params => {
      return false;
    },
  });
}

export const disableQuestion = values => dispatch => {
  const questionId = oFetch(values, 'id');
  return requests.brDisableQuestion({
    values: { questionId },
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      const question = oFetch(data, 'question');
      dispatch(updateQuestionInStore({ question }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Question disabled Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'questionText', 'questionType', 'answers', 'image'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const enableQuestion = values => dispatch => {
  const questionId = oFetch(values, 'id');
  return requests.brEnableQuestion({
    values: { questionId },
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      const question = oFetch(data, 'question');
      dispatch(updateQuestionInStore({ question }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Question enabled Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'questionText', 'questionType', 'answers', 'image'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
