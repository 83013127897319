import React, { useMemo } from 'react';
import { RotaDateUIUtils, getHoursBetween } from '@/lib/rota-date';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';

import {
  convertClockInPeriodToIntervals,
  convertHoursAcceptancePeriodsToInterval,
} from './convert-clock-in-period-to-intervals';
import HoursChartUi from './hours-chart-content';
import EventImage from './event-image';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export default function EventsGraph(props) {
  const [clockingDay, appType, normalizedClockInEvents] = oFetch(props, 'clockingDay', 'appType', 'normalizedClockInEvents');
  const uisDate = oFetch(clockingDay, 'date');
  const [firstEvent, lastEvent] = oFetch(clockingDay, 'firstEvent', 'lastEvent');
  const graphXAxisHoursFromGlobals = oFetch(useLegacyGlobal(), 'graphXAxisHours');

  const graphXAxisHours = useMemo(() => {
    if (graphXAxisHoursFromGlobals === null) {
      const shiftGraphRotaDate = RotaDateUIUtils.sFromDate({ sDate: uisDate, appType });
      return shiftGraphRotaDate.getGraphXAxisHours();
    }
    return graphXAxisHoursFromGlobals;
  }, []);

  function getHoursAcceptanceIntervals() {
    const notDeletedHoursAcceptancePeriods = props.hoursAcceptancePeriods.filter(
      hap => oFetch(hap, 'status') !== 'deleted',
    );
    return getIntervalsFromHoursAcceptancePeriodList(notDeletedHoursAcceptancePeriods);
  }

  function getRotaedChartIntervals() {
    return props.rotaedShifts.map(shift => {
      var label =
        safeMoment.iso8601Parse(shift.startsAt).format('HH:mm') +
        ' - ' +
        safeMoment.iso8601Parse(shift.endsAt).format('HH:mm');

      return {
        startOffsetInHours: getHoursSinceStartOfDay(shift.startsAt),
        endOffsetInHours: getHoursSinceStartOfDay(shift.endsAt),
        label,
        headDoormanHour: oFetch(shift, 'headDoormanHour'),
        type: 'rotaed',
      };
    });
  }

  function getHoursSinceStartOfDay(date) {
    const rotaDate = oFetch(props, 'rotaDate');

    const hoursBetween = getHoursBetween({
      dStartTime: rotaDate.startTime(),
      dEndTime: safeMoment.iso8601Parse(date).toDate()
    });
    return hoursBetween;
  }

  function getIntervalsFromClockInPeriodList(clockInList) {
    var clockedIntervals = [];
    clockInList.forEach(clockIn => {
      clockedIntervals = clockedIntervals.concat(convertClockInPeriodToIntervals(clockIn));
    });
    var intervals = clockedIntervals.map(interval => {
      var startTime = interval.startsAt;
      var endTime = interval.endsAt;
      var tooltipLabel =
        safeMoment.iso8601Parse(startTime).format('HH:mm') +
        ' - ' +
        safeMoment.iso8601Parse(endTime).format('HH:mm');
      return {
        startOffsetInHours: getHoursSinceStartOfDay(startTime),
        endOffsetInHours: getHoursSinceStartOfDay(endTime),
        type: interval.type,
        tooltipLabel,
      };
    });

    return intervals;
  }

  function getIntervalsFromHoursAcceptancePeriodList(hoursAcceptancePeriods) {
    var clockedIntervals = convertHoursAcceptancePeriodsToInterval(hoursAcceptancePeriods);
    var intervals = clockedIntervals.map(interval => {
      var startTime = interval.startsAt;
      var endTime = interval.endsAt;
      var tooltipLabel =
        safeMoment.iso8601Parse(startTime).format('HH:mm') +
        ' - ' +
        safeMoment.iso8601Parse(endTime).format('HH:mm');
      return {
        startOffsetInHours: getHoursSinceStartOfDay(startTime),
        endOffsetInHours: getHoursSinceStartOfDay(endTime),
        type: interval.type,
        tooltipLabel,
      };
    });

    return intervals;
  }

  function getClockedChartIntervals() {
    return getIntervalsFromClockInPeriodList(props.clockedClockInPeriods);
  }

  return (
    <div className="boss-hrc__info">
      <EventImage
        event={firstEvent ? normalizedClockInEvents[0] : null}
        events={normalizedClockInEvents}
        widthPx={150}
        className=""
      />
      <HoursChartUi
        clockedIntervals={getClockedChartIntervals()}
        hoursAcceptanceIntervals={getHoursAcceptanceIntervals()}
        rotaedIntervals={getRotaedChartIntervals()}
        events={normalizedClockInEvents}
        graphXAxisHours={graphXAxisHours}
      />
      <EventImage
        event={lastEvent ? normalizedClockInEvents[normalizedClockInEvents.length - 1] : null}
        events={normalizedClockInEvents}
        widthPx={150}
        className=""
      />
    </div>
  );
}
