import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

import EventForm from '../../components/event-form';

class EditEvent extends Component {
  render() {
    const [onSubmit, eventTypesOptions, venueOptions, currentVenue, localEventType, globalEventType, event] = oFetch(
      this.props,
      'onSubmit',
      'eventTypesOptions',
      'venueOptions',
      'currentVenue',
      'localEventType',
      'globalEventType',
      'event',
    );
    const [id, title, description, date, eventType, startsAt, endsAt, forVenuesIds] = oFetch(
      event,
      'id',
      'title',
      'description',
      'date',
      'eventType',
      'startsAt',
      'endsAt',
      'forVenuesIds',
    );
    const initialValues = {
      id: id,
      title: title,
      description: description,
      date: date,
      startsAt: startsAt,
      endsAt: endsAt,
      eventType: eventType,
      venuesIds: forVenuesIds,
    };
    return (
      <div className="boss-modal-window__form">
        <EventForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          eventTypesOptions={eventTypesOptions}
          venueOptions={venueOptions}
          currentVenue={currentVenue}
          localEventType={localEventType}
          globalEventType={globalEventType}
          buttonText="Edit event"
        />
      </div>
    );
  }
}
export default modalRedux(combineReducers({ form: formReducer }))(EditEvent);
