import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {};
export const financeReportsOverviewDataReducer = handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const financeReportPageTotals = oFetch(payload, 'financeReportPageTotals');

      return {
        hourlyCalculatedTotalGrossCents: oFetch(financeReportPageTotals, 'hourlyCalculatedTotalGrossCents'),
        hourlyTotalGrossUploadedCents: oFetch(financeReportPageTotals, 'hourlyTotalGrossUploadedCents'),
        hourlyGrossDeltaCents: oFetch(financeReportPageTotals, 'hourlyGrossDeltaCents'),
        hourlyGrossDeltaWithinThreshold: oFetch(financeReportPageTotals, 'hourlyGrossDeltaWithinThreshold'),
        hourlyTotalNetWagesCents: oFetch(financeReportPageTotals, 'hourlyTotalNetWagesCents'),
        salaryCalculatedTotalGrossCents: oFetch(financeReportPageTotals, 'salaryCalculatedTotalGrossCents'),
        salaryTotalGrossUploadedCents: oFetch(financeReportPageTotals, 'salaryTotalGrossUploadedCents'),
        salaryGrossDeltaCents: oFetch(financeReportPageTotals, 'salaryGrossDeltaCents'),
        salaryGrossDeltaWithinThreshold: oFetch(financeReportPageTotals, 'salaryGrossDeltaWithinThreshold'),
        salaryTotalNetWagesCents: oFetch(financeReportPageTotals, 'salaryTotalNetWagesCents'),
        calculatedTotalGrossCents: oFetch(financeReportPageTotals, 'calculatedTotalGrossCents'),
        totalGrossUploadedCents: oFetch(financeReportPageTotals, 'totalGrossUploadedCents'),
        totalGrossDeltaCents: oFetch(financeReportPageTotals, 'totalGrossDeltaCents'),
        totalGrossDeltaWithinThreshold: oFetch(financeReportPageTotals, 'totalGrossDeltaWithinThreshold'),
        totalNetWagesCents: oFetch(financeReportPageTotals, 'totalNetWagesCents'),
      };
    },
  },
  initialState,
);
