export const LOAD_INITIAL_STATE = 'ACCESSORY_REQUESTS/LOAD_INITIAL_STATE';
export const LOAD_INITIAL_ACCESSORY_REQUESTS =
  'ACCESSORY_REQUESTS/LOAD_INITIAL_ACCESSORY_REQUESTS';
export const SET_VENUE = 'ACCESSORY_REQUESTS/SET_VENUE';
export const DROP_PAGE_NUMBER = 'ACCESSORY_REQUESTS/DROP_PAGE_NUMBER';
export const LOAD_MORE = 'ACCESSORY_REQUESTS/LOAD_MORE';
export const UPDATE_REQUEST = 'ACCESSORY_REQUESTS/UPDATE_REQUEST';
export const REMOVE_REQUEST = 'ACCESSORY_REQUESTS/REMOVE_REQUEST';
export const UPDATE_REFUND_REQUEST =
  'ACCESSORY_REQUESTS/UPDATE_REFUND_REQUEST';
export const REMOVE_REFUND_REQUEST =
  'ACCESSORY_REQUESTS/REMOVE_REFUND_REQUEST';
export const REMOVE_ACCESSORY = 'ACCESSORY_REQUESTS/REMOVE_ACCESSORY';
