import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const hoursAcceptancePeriods = oFetch(action, 'payload.hoursAcceptancePeriods');
      return hoursAcceptancePeriods.map(hoursAcceptancePeriod => {
        const id = oFetch(hoursAcceptancePeriod, 'id');
        return {
          ...hoursAcceptancePeriod,
          frontendId: id,
        };
      });
    },
    [types.CLOCK_OUT]: (state, action) => {
      const hoursAcceptancePeriod = oFetch(action, 'payload.hoursAcceptancePeriod');
      const hoursAcceptancePeriodId = oFetch(hoursAcceptancePeriod, 'id');
      const hoursAcceptancePeriodWithFrontendId = {
        ...hoursAcceptancePeriod,
        frontendId: hoursAcceptancePeriodId,
      };
      return [...state, hoursAcceptancePeriodWithFrontendId];
    },
    [types.SET_HOURS_ACCEPTANCE_PERIOD_STATUS]: (state, action) => {
      const [status, frontendId] = oFetch(action, 'payload.status', 'payload.frontendId');
      return state.map(hoursAcceptancePeriod => {
        const setStatusFrontendId = oFetch(hoursAcceptancePeriod, 'frontendId');
        if (setStatusFrontendId === frontendId) {
          return {
            ...hoursAcceptancePeriod,
            status,
          };
        }
        return hoursAcceptancePeriod;
      });
    },
    [types.ACCEPT_HOURS_ACCEPTANCE_PERIOD]: (state, action) => {
      const [acceptedHoursAcceptancePeriod, frontendId] = oFetch(
        action,
        'payload.hoursAcceptancePeriod',
        'payload.frontendId',
      );
      const acceptedHoursAcceptancePeriodId = oFetch(acceptedHoursAcceptancePeriod, 'id');
      return state.map(hoursAcceptancePeriod => {
        const setStatusFrontendId = oFetch(hoursAcceptancePeriod, 'frontendId');
        if (setStatusFrontendId === frontendId) {
          return { ...acceptedHoursAcceptancePeriod, frontendId: acceptedHoursAcceptancePeriodId };
        }
        return hoursAcceptancePeriod;
      });
    },
    [types.UPDATE_PERIOD_DATA]: (state, action) => {
      const [frontendId, startsAt, endsAt] = oFetch(
        action,
        'payload.frontendId',
        'payload.startsAt',
        'payload.endsAt',
      );
      return state.map(hoursAcceptancePeriod => {
        const hapToUpdateFrontendId = oFetch(hoursAcceptancePeriod, 'frontendId');

        if (hapToUpdateFrontendId === frontendId) {
          return {
            ...hoursAcceptancePeriod,
            startsAt,
            endsAt,
          };
        }
        return hoursAcceptancePeriod;
      });
    },
    [types.ADD_NEW_ACCEPTANCE_PERIOD]: (state, action) => {
      const [date, effectiveStaffMemberId, newStartsEndsTime, frontendId, bypassTimeLimits, venueId, appType] = oFetch(
        action.payload,
        'date',
        'effectiveStaffMemberId',
        'newStartsEndsTime',
        'frontendId',
        'bypassTimeLimits',
        'venueId',
        'appType'
      );
      const [startsAt, endsAt] = oFetch(newStartsEndsTime, 'startsAt', 'endsAt');
      return [
        ...state,
        {
          frontendId,
          startsAt,
          endsAt,
          date,
          bypassTimeLimits,
          venueId: venueId,
          status: 'pending',
          reasonNote: null,
          effectiveStaffMember: effectiveStaffMemberId,
          appType,
          acceptedAt: null,
          id: null,
          acceptedBy: null,
          frozen: false,
        },
      ];
    },
    [types.REMOVE_HOURS_ACCEPTANCE_PERIOD]: (state, action) => {
      const deletedHoursAcceptancePeriod = oFetch(action.payload, 'hoursAcceptancePeriod');
      const deletedId = oFetch(deletedHoursAcceptancePeriod, 'id');
      return state.map(hoursAcceptancePeriod => {
        const id = oFetch(hoursAcceptancePeriod, 'id');
        if (id === deletedId) {
          return {
            ...hoursAcceptancePeriod,
            ...deletedHoursAcceptancePeriod,
          };
        }
        return hoursAcceptancePeriod;
      });
    },
    [types.RE_ENABLE_HOURS_ACCEPTANCE_PERIOD]: (state, action) => {
      const reEnableHoursAcceptancePeriod = oFetch(action.payload, 'hoursAcceptancePeriod');
      const reEnableHapId = oFetch(reEnableHoursAcceptancePeriod, 'id');
      return state.map(hoursAcceptancePeriod => {
        const id = oFetch(hoursAcceptancePeriod, 'id');
        if (id === reEnableHapId) {
          return {
            ...hoursAcceptancePeriod,
            ...reEnableHoursAcceptancePeriod,
          };
        }
        return hoursAcceptancePeriod;
      });
    },
  },
  initialState,
);
