import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';

import { useModal } from '@/components/hooks-components/modals';
import utils from '@/lib/utils';
import { LegacyCloseInsideModalWrapper, LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

import {
  AddForStaffMembersSecurityPayRateModal,
  EditForStaffMembersSecurityPayRateModal,
  AddExceptionModal,
  UpdateExceptionModal,
  AddForSpecialSecurityHoursSecurityPayRateModal,
  EditForSpecialSecurityHoursSecurityPayRateModal,
  VenuesAssignmentsModal,
  DeleteConfirmationModal,
} from './components';
import * as requests from './requests';

export const FOR_STAFF_MEMBERS_TYPE = 'for_staff_members_type';
export const FOR_SPECIAL_SECURITY_HOURS_TYPE = 'for_special_security_hours_type';
export const FOR_STAFF_MEMBERS_FILTER_SHOW_ALL = 'for_staff_members_filter_show_all';
export const FOR_STAFF_MEMBERS_FILTER_STEWARD_ONLY = 'for_staff_members_filter_steward_only';

export function securityPayRatesHook(params) {
  const [initialType, initialForStaffMembersFilter, initialSecurityPayRates, venues] = oFetch(
    params,
    'initialType',
    'initialForStaffMembersFilter',
    'initialSecurityPayRates',
    'venues',
  );
  const { openModal, openPromiseModal } = useModal();

  const [selectedType, setSelectedType] = useState(initialType);
  const [selectedForStaffMembersFilter, setSelectedForStaffMembersFilter] = useState(
    initialForStaffMembersFilter,
  );
  const [securityPayRates, setSecurityPayRates] = useState(initialSecurityPayRates);

  const isForStaffMembersSelected = selectedType === FOR_STAFF_MEMBERS_TYPE;

  const memoizedSortByOriginalCreatedAt = useMemo(() => {
    return [...securityPayRates].sort((a, b) => {
      const aOriginalCreatedAt = oFetch(a, 'originalCreatedAt');
      const bOriginalCreatedAt = oFetch(b, 'originalCreatedAt');
      if (aOriginalCreatedAt < bOriginalCreatedAt) return -1;
      if (aOriginalCreatedAt > bOriginalCreatedAt) return 1;
      return 0;
    });
  }, [securityPayRates]);

  function filterSecurityPayRatesByType(selectedType, securityPayRates) {
    return securityPayRates.filter(securityPayRate => {
      if (selectedType === FOR_STAFF_MEMBERS_TYPE) {
        return oFetch(securityPayRate, 'isForStaffMembers');
      } else if (selectedType === FOR_SPECIAL_SECURITY_HOURS_TYPE) {
        return oFetch(securityPayRate, 'isForSpecialSecurityHours');
      } else {
        throw new Error(`unsupported type ${selectedType}`);
      }
    });
  }

  const memoizedFilteredSecurityPayRatesByForStaffMembersTypes = useMemo(
    () => filterSecurityPayRatesByType(FOR_STAFF_MEMBERS_TYPE, memoizedSortByOriginalCreatedAt),
    [FOR_STAFF_MEMBERS_TYPE, memoizedSortByOriginalCreatedAt],
  );

  const memoizedFilteredSecurityPayRatesByForSpecialSecurityHoursType = useMemo(
    () => filterSecurityPayRatesByType(FOR_SPECIAL_SECURITY_HOURS_TYPE, memoizedSortByOriginalCreatedAt),
    [FOR_SPECIAL_SECURITY_HOURS_TYPE, memoizedSortByOriginalCreatedAt],
  );

  const memoizedFilteredSecurityPayRatesByForStewardFilter = useMemo(
    () =>
      memoizedFilteredSecurityPayRatesByForStaffMembersTypes.filter(securityBasePayRate => {
        return oFetch(securityBasePayRate, 'isForStewardSecurityStaff');
      }),
    [FOR_STAFF_MEMBERS_FILTER_STEWARD_ONLY, memoizedFilteredSecurityPayRatesByForStaffMembersTypes],
  );

  const forStaffMembersPayRatesCount = memoizedFilteredSecurityPayRatesByForStaffMembersTypes.length;
  const forSpecialSecurityHoursSecurityPayRateCount =
    memoizedFilteredSecurityPayRatesByForSpecialSecurityHoursType.length;
  const forStewardsPayRateCount = memoizedFilteredSecurityPayRatesByForStewardFilter.length;

  const selectedSecurityPayRates = isForStaffMembersSelected
    ? selectedForStaffMembersFilter === FOR_STAFF_MEMBERS_FILTER_SHOW_ALL
      ? memoizedFilteredSecurityPayRatesByForStaffMembersTypes
      : memoizedFilteredSecurityPayRatesByForStewardFilter
    : memoizedFilteredSecurityPayRatesByForSpecialSecurityHoursType;

  function addSecurityPayRate(securityPayRate) {
    setSecurityPayRates([...securityPayRates, securityPayRate]);
  }

  function replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate) {
    setSecurityPayRates(() => {
      return securityPayRates.map(storeSecurityPayRate => {
        if (oFetch(storeSecurityPayRate, 'id') === oldSecurityPayRateId) {
          return newSecurityPayRate;
        }
        return storeSecurityPayRate;
      });
    });
  }

  function updateSecurityPayRate(securityPayRate) {
    setSecurityPayRates(() => {
      return securityPayRates.map(storeSecurityPayRate => {
        if (oFetch(storeSecurityPayRate, 'id') === oFetch(securityPayRate, 'id')) {
          return securityPayRate;
        }
        return storeSecurityPayRate;
      });
    });
  }

  function removeSecurityPayRateException(params) {
    const [securityPayRateId, securityPayRateExceptionId] = oFetch(
      params,
      'securityPayRateId',
      'securityPayRateExceptionId',
    );
    setSecurityPayRates(() => {
      return securityPayRates.map(storeSecurityPayRate => {
        if (oFetch(storeSecurityPayRate, 'id') === securityPayRateId) {
          const newExceptions = oFetch(storeSecurityPayRate, 'securityPayRateExceptions').filter(
            securityPayRateException => {
              return oFetch(securityPayRateException, 'id') !== securityPayRateExceptionId;
            },
          );
          return { ...storeSecurityPayRate, securityPayRateExceptions: newExceptions };
        }
        return storeSecurityPayRate;
      });
    });
  }

  function handleTypeChange(newType) {
    setSelectedType(newType);
  }

  function handleForStaffMembersFilterChange(newForStaffMembersFilter) {
    setSelectedForStaffMembersFilter(newForStaffMembersFilter);
  }

  function openAddForStaffMembersSecurityPayrateModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddForStaffMembersSecurityPayRateModal,
      onSubmit(handleClose, values) {
        return requests.brCreateforStaffMembersSecurityPayRate({
          values,
          successHandler(params) {
            const securityPayRate = oFetch(params, 'data.securityPayRate');
            addSecurityPayRate(securityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Security Pay Rate',
      },
      props: {},
    });
  }

  function openAddForSpecialSecurityHoursSecurityPayrateModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddForSpecialSecurityHoursSecurityPayRateModal,
      onSubmit(handleClose, values) {
        return requests.brCreateForSpecialSecurityHoursSecurityPayRate({
          values,
          successHandler(params) {
            const securityPayRate = oFetch(params, 'data.securityPayRate');
            addSecurityPayRate(securityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Security Pay Rate',
      },
      props: {},
    });
  }

  function openEditForStaffMembersSecurityPayrateModal(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditForStaffMembersSecurityPayRateModal,
      onSubmit(handleClose, values) {
        return requests.brUpdateForStaffMembersSecurityPayRate({
          values,
          successHandler(params) {
            const newSecurityPayRate = oFetch(params, 'data.securityPayRate');
            replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Update Security Pay Rate',
      },
      props: {
        customSecurityPayRate: securityPayRate,
      },
    });
  }

  function openEditForSpecialSecurityHoursSecurityPayrateModal(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditForSpecialSecurityHoursSecurityPayRateModal,
      onSubmit(handleClose, values) {
        return requests.brUpdateForSpecialSecurityHoursSecurityPayRate({
          values,
          successHandler(params) {
            const newSecurityPayRate = oFetch(params, 'data.securityPayRate');
            replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Update Security Pay Rate',
      },
      props: {
        customSecurityPayRate: securityPayRate,
      },
    });
  }

  function openAddExceptionModal(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');

    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddExceptionModal,
      onSubmit(handleClose, values) {
        return requests.brCreateSecurityPayRateException({
          values,
          successHandler(params) {
            const newSecurityPayRate = oFetch(params, 'data.securityPayRate');
            replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Exception',
      },
      props: {
        securityPayRate: securityPayRate,
      },
    });
  }

  function openUpdateExceptionModal(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const securityPayRateException = oFetch(params, 'securityPayRateException');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');

    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: UpdateExceptionModal,
      onSubmit(handleClose, values) {
        return requests.brUpdateSecurityPayRateException({
          values,
          successHandler(params) {
            const newSecurityPayRate = oFetch(params, 'data.securityPayRate');
            replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate);
            handleClose();
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Update Exception',
      },
      props: {
        securityPayRate: securityPayRate,
        securityPayRateException: securityPayRateException,
      },
    });
  }

  function openDeleteExceptionModalWarning(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const securityPayRateException = oFetch(params, 'securityPayRateException');
    const securityPayRateExceptionId = oFetch(securityPayRateException, 'id');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');

    return openPromiseModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: DeleteConfirmationModal,
      onSubmit(handleClose) {
        handleClose();
        return requests.brDeleteSecurityPayRateException({
          values: {
            securityPayRateId: oldSecurityPayRateId,
            securityPayRateExceptionId,
          },
          successHandler() {
            removeSecurityPayRateException({
              securityPayRateId: oldSecurityPayRateId,
              securityPayRateExceptionId,
            });
          },
          errorHandler(data) {
            const errors = oFetch(data, 'errors');
            return utils.normalizeFinalFormErrors(errors);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_danger',
        title: 'ARE YOU SURE?',
      },
      props: {
        securityPayRate: securityPayRate,
        securityPayRateException: securityPayRateException,
      },
    });
  }

  function openVenuesAssignmentsModal(params) {
    const securityPayRate = oFetch(params, 'securityPayRate');
    const oldSecurityPayRateId = oFetch(securityPayRate, 'id');
    const name = oFetch(securityPayRate, 'name');

    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: VenuesAssignmentsModal,
      onSubmit(handleClose, values) {
        return requests.brUpdateForSpecialSecurityHoursSecurityPayRateVenuesAssignments({
          values,
          successHandler(params) {
            const newSecurityPayRate = oFetch(params, 'data.securityPayRate');
            replaceSecurityPayRate(oldSecurityPayRateId, newSecurityPayRate);
            handleClose();
          },
          errorHandler() {},
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title() {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{name}</span> Venue Assignments
            </div>
          );
        },
      },
      props: {
        specialSecurityPayRate: securityPayRate,
        venuesOptions: getVenuesOptions(),
      },
    });
  }

  function getVenuesOptions() {
    return venues.map(venue => {
      const [id, name] = oFetch(venue, 'id', 'name');
      return {
        value: id,
        label: name,
      };
    });
  }

  return {
    selectedType,
    forStaffMembersPayRatesCount,
    forSpecialSecurityHoursSecurityPayRateCount,
    forStewardsPayRateCount,
    selectedForStaffMembersFilter: selectedForStaffMembersFilter,
    onForStaffMembersFilterChange: handleForStaffMembersFilterChange,
    onTypeChange: handleTypeChange,
    securityPayRates: selectedSecurityPayRates,
    openAddForStaffMembersSecurityPayrateModal: openAddForStaffMembersSecurityPayrateModal,
    openAddForSpecialSecurityHoursSecurityPayrateModal: openAddForSpecialSecurityHoursSecurityPayrateModal,
    openEditForStaffMembersSecurityPayrateModal: openEditForStaffMembersSecurityPayrateModal,
    openEditForSpecialSecurityHoursSecurityPayrateModal: openEditForSpecialSecurityHoursSecurityPayrateModal,
    openAddExceptionModal: openAddExceptionModal,
    openUpdateExceptionModal: openUpdateExceptionModal,
    openDeleteExceptionModalWarning: openDeleteExceptionModalWarning,
    openVenuesAssignmentsModal: openVenuesAssignmentsModal,
  };
}
