import { getAsDataURL } from '@/utils/image-utils';
import 'blueimp-canvas-to-blob';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

type ImagePickerProps = {
  onPicked: (fileDataURL: string) => void;
};

export type ImagePickerHandle = {
  open: () => void;
};

export const ImagePickerFunc = forwardRef(function ImagePicker(props: ImagePickerProps, ref: any) {
  const { onPicked } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  async function onChange() {
    if (!inputRef.current) {
      return;
    }

    const files: File[] = await Promise.resolve([].slice.call(inputRef.current.files));

    if (files.length === 0 || !files[0]) {
      return;
    }
    const file = files[0];

    const fileDataURL = await getAsDataURL(file);
    onPicked(fileDataURL as string);
  }

  useImperativeHandle(ref, () => ({
    open() {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  }));

  return (
    <div style={{ display: 'none' }}>
      <input
        type="file"
        multiple={false}
        accept={'image/*'}
        onChange={onChange}
        ref={inputRef}
      />
    </div>
  );
});
