import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import { globalReducer } from './reducers';
import { initialPageLoad } from './actions';
import { PaymentsPageUI } from './components/payments-page-ui';
import { initialProfileLoad } from '../profile-wrapper/actions';
import profileReducer from '../profile-wrapper/reducers';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';

export function StaffMemberProfilePaymentsApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    const store = configureStore(
      combineReducers({
        global: globalReducer,
        profile: profileReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    store.dispatch(initialPageLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <PaymentsPageUI />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
