import React, { useEffect } from 'react';
import { $MossPartyPeopleAppProps, MossPartyPeopleAppProps } from './types';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { IndexPage } from './components/index-page';
import { GlobalProvider } from './globals';


export function MossPartyPeopleApp(rawProps: unknown) {
  const props: MossPartyPeopleAppProps = $MossPartyPeopleAppProps.parse(rawProps);

  useEffect(() => {
    window.boss.accessToken = props.pageData.accessToken;
  }, []);

  return (
    <>
      <GlobalProvider global={{
        filterTypeOptions: props.pageData.filterTypeOptions,
      }} >
        <ModalsProvider>
          <IndexPage
            nonStaffMemberStaffPartyPeople={props.nonStaffMemberStaffPartyPeople}
            pagination={props.pageData.pagination}
            filter={props.pageData.filter}
            mossStaffMemberOptions={props.pageData.mossStaffMemberOptions}
            staffPartyQuizTeamOptions={props.pageData.staffPartyQuizTeamOptions}
          />
        </ModalsProvider>
      </GlobalProvider>
    </>
  );
}