import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';
import { renderTooltipInfoHtml, renderTooltipTimeHtml } from './render-tooltip-html';
import RotaOverviewChartInner from './rota-overview-chart-inner';
import oFetch from 'o-fetch';

const MILLISECONDS_PER_MINUTE = 60 * 1000;

RotaOverviewChart.propTypes = {
  shifts: PropTypes.array.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  staff: PropTypes.array.isRequired,
  dDateOfRota: PropTypes.instanceOf(Date),
  breakdown: PropTypes.array.isRequired,
  granularity: PropTypes.number.isRequired,
};

export default function RotaOverviewChart(props) {
  var breakdown = props.breakdown;
  const dDateOfRota = oFetch(props, 'dDateOfRota');
  const rotaDate = RotaDate.fromDate({ dCalendarDate: dDateOfRota, appType: BOSS_APP_TYPE });

  function getSelectionData(breakdown, obj) {
    var seriesName = obj.data.key;
    var index = obj.index;

    var group = getGroupsByName()[seriesName];
    var shifts = breakdown[index].shiftsByGroup[group.id];
    return {
      shifts,
      groupId: group.id,
      date: breakdown[index].date,
    };
  }

  function getGroupsByName() {
    return _(props.groups).indexBy('name');
  }

  function getChartData(breakdown) {
    var groups = props.groups;
    var series = [];
    var granularity = props.granularity;

    groups.forEach(function (group) {
      var values = breakdown.map(function (item) {
        return {
          value: item.shiftsByGroup[group.id].length,
          label: item.date.valueOf(),
        };
      });
      series.push({
        key: group.name,
        values: values,
        color: group.color,
        pointStart: rotaDate.startTime().valueOf(),
        pointInterval: granularity * MILLISECONDS_PER_MINUTE,
      });
    });
    return series;
  }

  return (
    <RotaOverviewChartInner
      chartData={getChartData(breakdown)}
      rotaDate={rotaDate}
      onElementClick={obj => {
        getSelectionData(breakdown, obj);
      }}
      onElementMouseover={obj => {
        getSelectionData(breakdown, obj);
      }}
      onElementMouseout={function (obj) {}}
      tooltipInfoGenerator={obj => {
        const groupsById = props.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {});

        var selectedGroupTitle = obj.series[0].key;

        var date = breakdown[obj.index].date;
        var breakdownAtPoint = _(breakdown).find(point => point.date.valueOf() === date.valueOf());
        var selectedGroupId = _(groupsById).find({ name: selectedGroupTitle }).id;

        var html = renderTooltipInfoHtml({
          shiftsByGroupId: breakdownAtPoint.shiftsByGroup,
          selectedGroupId,
          groupsById,
          shiftDates: getSelectionData(breakdown, obj),
        });
        return html;
      }}
      tooltipTimeGenerator={obj => {
        var groupsById = props.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {});
        var selectedGroupTitle = obj.series[0].key;

        var selectedGroupId = _(groupsById).find({ name: selectedGroupTitle }).id;

        var html = renderTooltipTimeHtml({
          selectedGroupId,
          groupsById,
          shiftDates: getSelectionData(breakdown, obj),
          staff: props.staff,
        });
        return html;
      }}
      noData={'No Shifts currently rotaed'}
    />
  );
}
