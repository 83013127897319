import React, { useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import { MessageSchedule } from './message-schedule';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { type MessageSchedule as MessageScheduleType } from '@/types/MessageSchedule';

interface MessageScheduleAppProps {
  accessToken: string;
  messageSchedules: [MessageScheduleType]
  staffMembers: [unknown],
  statusTitles: [value: string, label: string]
}

export function MessageScheduleApp(props: MessageScheduleAppProps) {
  const accessToken = oFetch(props, 'accessToken');
  const staffMembers = oFetch(props, 'staffMembers');
  const messageSchedules = oFetch(props, 'messageSchedules');
  const statusTitles = oFetch(props, 'statusTitles');

  useEffect(() => {
    window.boss.accessToken = accessToken;
  }, []);

  const statusOptions = useMemo(() => {
    return Object.entries(statusTitles).map((entry) => {
      const [value, label] = entry;
      return { value, label };
    });
  }, [statusTitles]);

  return (
    <LegacyGlobalProvider global={{ staffMembers, statusTitles, statusOptions }}>
      <ModalsProvider>
        <MessageSchedule messageSchedules={messageSchedules} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
