import React, { useEffect  } from 'react';
import { Page } from './components/page';
import { MossFinanceReportHourTagsAppProps, $MossFinanceReportHourTagsAppProps } from './types';
import { ModalsProvider } from '@/components/hooks-components/modals';

export function MossFinanceReportHourTagsApp(props: unknown): JSX.Element {
  const parsedProps: MossFinanceReportHourTagsAppProps = $MossFinanceReportHourTagsAppProps.parse(props);
  useEffect(() => {
    const accessToken = parsedProps.accessToken;
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, []);

  return (
    <ModalsProvider>
      <Page
        {...parsedProps}
      />
    </ModalsProvider>
  );
}