import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const questionnaireAreas = oFetch(action, 'payload.questionnaireAreas');
      return questionnaireAreas;
    },
    [types.ADD_AREA_TO_STORE]: (state, action) => {
      const questionnaireArea = oFetch(action, 'payload.questionnaireArea');
      return [...state, questionnaireArea];
    },
    [types.DELETE_AREA_FROM_STORE]: (state, action) => {
      const areaId = oFetch(action, 'payload.areaId');
      return state.filter(area => oFetch(area, 'id') !== areaId);
    },
  },
  initialState,
);
