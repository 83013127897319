import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import queryString from 'query-string';

import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import * as selectors from './redux/selectors';
import { TagItem, TagList } from './components';

function QuizCategoryTags(props) {
  const [quizCategoryQuestionsTags, quizCategoryUrl, quizCategory] = oFetch(
    props,
    'quizCategoryQuestionsTags',
    'quizCategoryUrl',
    'quizCategory',
  );

  const quizCategoryName = oFetch(quizCategory, 'name');

  function handleTagClick(tag) {
    const qp = getQueryParams();
    const tagId = oFetch(tag, 'id');

    window.history.pushState('state', 'title', `${quizCategoryUrl}?${queryString.stringify({ ...qp, tags: tagId })}`);
    document.location.reload(true);
  }

  function renderTagItem(tag) {
    return <TagItem onClick={handleTagClick} tag={tag} />;
  }

  function getQueryParams() {
    return queryString.parse(window.location.search);
  }

  return (
    <div>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {quizCategoryName}{' '}
              </span>Category Tags
            </h1>
            <div className="boss-page-dashboard__buttons-group">
              <a href={quizCategoryUrl} className="boss-button boss-button_role_primary boss-page-dashboard__button">
                Back to Category Details
              </a>
            </div>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <div className="boss-page-main__group boss-page-main__group_adjust_quiz-category-tags">
          <TagList tags={quizCategoryQuestionsTags} renderItem={renderTagItem} />
        </div>
      </ContentWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    quizCategoryQuestionsTags: selectors.getTags(state),
  };
};

export default connect(mapStateToProps)(QuizCategoryTags);
