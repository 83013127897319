import oFetch from 'o-fetch';
import React, { useState, useMemo } from 'react';
import utils from '@/lib/utils';
import StaffMembersFilter from './staff-members-filter';
import StaffMemberPayment from './staff-member-payment';

export default function StaffMemberPaymentList(props) {
  const [paymentsFromProps, staffMemberPaymentRenderer] = oFetch(
    props,
    'payments',
    'staffMemberPaymentRenderer',
  );
  const groupByStaffMemberId = utils.groupBy('staffMember.id');
  const normalizedByStaffMemberPayments = useMemo(() => {
    return normalizeByStaffMemberPayments();
  }, [paymentsFromProps]);

  const [filteredStaffMembers, setFilteredStaffMembers] = useState(normalizedByStaffMemberPayments);
  const totalCount = normalizedByStaffMemberPayments.length;
  const currentCount = filteredStaffMembers.length;

  function normalizeByStaffMemberPayments() {
    const staffMembers = paymentsFromProps.reduce((acc, payment) => {
      const staffMember = oFetch(payment, 'staffMember');
      const staffMemberId = oFetch(staffMember, 'id');
      return {
        ...acc,
        [staffMemberId]: staffMember,
      };
    }, {});

    const grouppedPayments = groupByStaffMemberId(paymentsFromProps);
    return Object.entries(grouppedPayments).map(paymentEntry => {
      const [staffMemberId, paymentsData] = paymentEntry;
      const staffMember = staffMembers[staffMemberId];
      return {
        ...staffMember,
        paymentsData: paymentsData,
      };
    });
  }

  function handleFilter(filterValue) {
    if (filterValue === '') {
      setFilteredStaffMembers(normalizedByStaffMemberPayments);
    } else {
      const filteredRecords = utils.fullFilter(filterValue, normalizedByStaffMemberPayments, [
        'name',
      ]);
      setFilteredStaffMembers(filteredRecords);
    }
  }

  function renderPaymentslist() {
    return (
      <div className="boss-users__flow">
        <div className="boss-users__flow-list">
          {filteredStaffMembers.map((staffMember, index) => {
            return React.cloneElement(staffMemberPaymentRenderer(staffMember), {
              key: index,
            });
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="boss-board__group">
      <div className="boss-users">
        <StaffMembersFilter
          totalCount={totalCount}
          currentCount={currentCount}
          onChange={handleFilter}
        />
        {renderPaymentslist()}
      </div>
    </div>
  );
}
