import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import reducers from './redux/reducers';
import { loadInitialData } from './redux/actions';
import MossFinanceReportsPage from './containers/page';

export function MossFinanceReportsApp(props) {
  const [store, setStore] = useState();
  const isAdminPlus = oFetch(props, 'isAdminPlus');

  useEffect(() => {
    require('./styles.css');
    const accessToken = oFetch(props, 'accessToken');
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    setStore(store);
    store.dispatch(loadInitialData(props));
  }, []);

  if (!store) {
    return null;
  }

  return (
    <LegacyGlobalProvider global={{ isAdminPlus: isAdminPlus }}>
      <Provider store={store}>
        <ModalsProvider>
          <MossFinanceReportsPage />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
