import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

function InviteList(props) {
  function renderRows(invites) {
    const rowRenderer = oFetch(props, 'rowRenderer');

    return invites.map(invite => {
      return React.cloneElement(rowRenderer(invite), { key: oFetch(invite, 'id') });
    });
  }

  const invites = oFetch(props, 'invites');

  if (invites.size === 0) {
    return (
      <div className="boss-page-main__group boss-page-main__group_adjust_ssr-requests">
        <div className="boss-page-main__text-placeholder">There are no invites to show.</div>
      </div>
    );
  }

  return <Fragment>{renderRows(invites)}</Fragment>;
}

export default InviteList;
