import React, { Component } from 'react';
import oFetch from 'o-fetch';
import humanize from 'string-humanize';
import AsyncButton from 'react-async-button';
import { userPermissions } from '@/lib/user-permissions';

import safeMoment from '@/lib/safe-moment';

function Cell({ label, text, children }) {
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <div className="boss-table__label">{label}</div>
        {children || <p className="boss-table__text">{text}</p>}
      </div>
    </div>
  );
}

class HolidayRequestsItem extends Component {
  state = {
    forward: false,
    isSending: false,
    errors: [],
  };

  onButtonClick = action => {
    this.setState({
      isSending: true,
      errors: [],
    });
    const forward = oFetch(this.state, 'forward');
    return action({ forward }).catch(payload => {
      this.setState({
        isSending: false,
      });

      const response = payload.response;
      if (response && response.status === 422) {
        const errors = oFetch(response, 'data.errors');
        this.setState({ errors: errors });
      }
    });
  };

  render() {
    const viewReportUrl = oFetch(this.props, 'viewReportUrl');
    const holidayRequest = oFetch(this.props, 'holidayRequest');
    const holidayRequestId = oFetch(holidayRequest, 'id');
    const holidayType = oFetch(holidayRequest, 'holidayType');
    const mStartDate = safeMoment.uiDateParse(oFetch(holidayRequest, 'startDate'));
    const mEndDate = safeMoment.uiDateParse(oFetch(holidayRequest, 'endDate'));
    const note = oFetch(holidayRequest, 'note');

    const formattedDate = `${mStartDate.format('ddd DD-MM-YYYY')} - ${mEndDate.format('ddd DD-MM-YYYY')}`;
    const days = mEndDate.diff(mStartDate, 'days') + 1;
    const formattedDays = days > 1 ? `( ${days} days )` : `( ${days} day )`;

    const onAcceptClick = oFetch(this.props, 'onAcceptClick');
    const onRejectClick = oFetch(this.props, 'onRejectClick');
    const errors = oFetch(this.state, 'errors');
    const errorKeys = errors ? Object.keys(errors) : {};

    const permissionsData = oFetch(this.props, 'permissionsData').toJS();
    const allHolidayRequestPermissions = oFetch(permissionsData, 'holidayRequests');
    const holidayRequestPermissions = oFetch(allHolidayRequestPermissions, holidayRequestId);
    const holidayRequestPagePermissions = oFetch(userPermissions, 'holidayRequestPage');
    const canAccept = oFetch(holidayRequestPermissions, 'canAccept')
    const canReject = oFetch(holidayRequestPermissions, 'canReject')
    const canForward = oFetch(holidayRequestPermissions, 'canForward');

    return (
      <div className="boss-table__group">
        {errorKeys.length > 0 && (
          <div className="boss-alert boss-alert_role_area">
            <p className="boss-alert__text">
              {errorKeys.reduce((result, errorKey, index) => {
                let keyErrors = oFetch(errors, errorKey);
                if (errorKey === '_error') {
                  keyErrors.forEach((error, errIndex) => {
                    result.push((
                      <span
                        key={`request:${holidayRequestId}errorMessage${index}:${errIndex}`}
                        className="boss-alert__text-line"
                      >
                        {error}.
                      </span>
                    ));
                  });
                } else {
                  keyErrors.forEach((error, errIndex) => {
                    result.push(
                      <span
                        key={`request:${holidayRequestId}errorMessage${index}:${errIndex}`}
                        className="boss-alert__text-line"
                      >
                        {`${errorKey} ${error}`}.
                      </span>
                    );
                  });
                }
                return result;
              }, [])}
            </p>
          </div>
        )}
        <div className="boss-table__row">
          <Cell
            label="Type"
            text={humanize(holidayType)}
          />
          <Cell label="Dates">
            <p className="boss-table__text">
              <span className="boss-table__text-line">{formattedDate}</span>
              <span className="boss-table__text-meta">{formattedDays}</span>
            </p>
          </Cell>
          <Cell
            label="Note"
            text={note}
          />
          <Cell label="Holidays Report">
            <div className="boss-table__actions">
              <a
                href={viewReportUrl}
                className="boss-button boss-button_role_view-report boss-button_type_extra-small"
              >
                View Report
              </a>
            </div>
          </Cell>

          {canAccept && canReject && (
            <Cell label="Action">
              <div className="boss-table__actions">
                { canForward && <label className="boss-form__checkbox-label">
                  <span>Forward</span>
                  <input
                    onChange={(event) => {
                      const target = oFetch(event, 'target');
                      this.setState({ forward: oFetch(target, 'checked')});
                    }}
                    type="checkbox"
                    disabled={this.state.isSending}
                    checked={this.state.forward}
                  />
                </label> }
                {canAccept && (
                  <AsyncButton
                    disabled={this.state.isSending}
                    className="boss-button boss-button_type_extra-small boss-button_role_success boss-table__action"
                    text="Accept"
                    pendingText="Accepting ..."
                    onClick={() => this.onButtonClick(onAcceptClick)}
                  />
                )}
                {canReject && (
                  <AsyncButton
                    disabled={this.state.isSending}
                    className="boss-button boss-button_type_extra-small boss-button_role_cancel-light boss-table__action"
                    text="Reject"
                    pendingText="Rejecting ..."
                    onClick={() => this.onButtonClick(onRejectClick)}
                  />
                )}
              </div>
            </Cell>
          )}
        </div>
      </div>
    );
  }
}

export default HolidayRequestsItem;
