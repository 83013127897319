import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import { reduxForm, FieldArray, Field } from 'redux-form';

import { ChecklistTitleField, ChecklistItemField } from './fields';

class RenderItems extends Component {
  state = {
    itemDescription: '',
  };

  handleAddItem = () => {
    const fields = oFetch(this.props, 'fields');
    const itemDescription = oFetch(this.state, 'itemDescription');
    fields.push({ description: itemDescription });
    this.handleClearItemDescription();
  };

  handleItemDescriptionChange = e => {
    const value = oFetch(e, 'target.value');
    this.setState({ itemDescription: value });
  };

  handleClearItemDescription = () => {
    this.setState({ itemDescription: '' });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleAddItem();
    }
  };

  render() {
    const [fields, meta] = oFetch(this.props, 'fields', 'meta');
    const itemDescription = oFetch(this.state, 'itemDescription');

    return (
      <div className="boss-checklist__items">
        {fields.map((field, index) => {
          return (
            <Field
              key={index}
              name={`${field}.description`}
              onDelete={() => fields.remove(index)}
              component={ChecklistItemField}
            />
          );
        })}
        <div className="boss-checklist__item">
          <div className="boss-checklist__control">
            <div className="boss-checklist__field">
              <input
                type="text"
                value={itemDescription}
                onChange={this.handleItemDescriptionChange}
                onKeyDown={this.handleKeyDown}
                className="boss-checklist__text-input boss-checklist__text-input_adjust_icon"
                placeholder="Type item name here..."
              />
              {itemDescription && (
                <button
                  onClick={this.handleClearItemDescription}
                  type="button"
                  className="boss-checklist__icon boss-checklist__icon_role_field-cancel"
                >
                  Cancel
                </button>
              )}
            </div>
            <button
              onClick={this.handleAddItem}
              disabled={!itemDescription}
              type="button"
              className="boss-button boss-button_type_icon boss-button_role_add boss-checklist__btn"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class ChecklistForm extends Component {
  handleTitleOnFocus = () => {
    const onOpen = oFetch(this.props, 'onOpen');
    onOpen();
  };

  handleAddNewClick = () => {
    this.checklistTitle.focus();
  };

  handleCancelClick = () => {
    const onClose = oFetch(this.props, 'onClose');
    onClose();
  };

  renderBaseErrors = errors => {
    return (
      <div className="boss-checklist__alert">
        <div className="boss-alert boss-alert_role_info">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  renderButtonTitle = (submitting, edit) => {
    if (edit) {
      return submitting ? 'Updating checklist ...' : 'Update checklist';
    }
    return submitting ? 'Creating checklist ...' : 'Create checklist';
  };

  render() {
    const [isOpened, edit] = oFetch(this.props, 'isOpened', 'edit');
    const [handleSubmit, submitting] = oFetch(this.props, 'handleSubmit', 'submitting');
    const { error } = this.props;

    return (
      <section className="boss-board boss-board_context_stack">
        <div className="boss-board__form">
          <header className="boss-board__header">
            <Field
              name="name"
              inputRef={el => (this.checklistTitle = el)}
              onFocus={this.handleTitleOnFocus}
              component={ChecklistTitleField}
            />
            {!edit && (
              <div className="boss-board__button-group boss-board__button-group_role_add">
                {!isOpened && (
                  <button
                    onClick={this.handleAddNewClick}
                    type="button"
                    className="boss-button boss-button_role_add boss-board__action boss-board__action_type_fluid boss-board__action_role_switch"
                  >
                    Add new
                  </button>
                )}
                {isOpened && (
                  <button
                    onClick={this.handleCancelClick}
                    type="button"
                    className="boss-button boss-button_role_cancel boss-board__action boss-board__action_type_fluid boss-board__action_role_switch"
                  >
                    Cancel
                  </button>
                )}
              </div>
            )}
          </header>
          <Collapse
            isOpened={isOpened}
            theme={{
              collapse: 'boss-board__content boss-board__content_state_opened',
              content: 'boss-board__content-inner',
            }}
          >
            <div className="boss-board__checklist">
              <div className="boss-board__checklist">
                <div className="boss-checklist">
                  <div className="boss-checklist__content">
                    {error && this.renderBaseErrors(error)}
                    <FieldArray name="items" component={RenderItems} />
                    <div className="boss-checklist__actions">
                      <button
                        onClick={handleSubmit}
                        disabled={submitting}
                        className="boss-button boss-button_role_primary"
                        type="button"
                      >
                        {this.renderButtonTitle(submitting, edit)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </section>
    );
  }
}

export default reduxForm()(ChecklistForm);
