import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

export default function openModal(submit, props) {
  return function (Component) {
    const bodyFirst = document.body.firstChild;
    const wrapper = document.createElement('div');
    const rootWrapper = createRoot(wrapper);
    bodyFirst.parentNode.insertBefore(wrapper, bodyFirst);

    const handleClose = (...args) => {
      return new Promise((resolve, reject) => {
        resolve(submit(hideModal, ...args));
      });
    };

    const hideModal = () => {
      setTimeout(() => {
        rootWrapper.unmount();
      }, 50);
    };

    rootWrapper.render(
      <ReactErrorsBoundaryWrapper>
        <Component onSubmit={handleClose} onClose={hideModal} {...props} />
      </ReactErrorsBoundaryWrapper>,
    );
  };
}

export function modalRedux(reducers) {
  const store = configureStore(reducers);
  return function (Component) {
    return function WrappedComponent(props) {
      return (
        <Provider store={store}>
          <Component {...props} />
        </Provider>
      );
    };
  };
}
