import React from 'react';
import oFetch from 'o-fetch';
import InvitesFilterForm from './invites-filter-form';

function InvitesFilter(props) {
  function handleSubmit(values) {
    return oFetch(props, 'onFilter')(values);
  }

  return (
    <InvitesFilterForm
      initialValues={oFetch(props, 'initialValues')}
      onSubmit={handleSubmit}
    />
  );
}

export default InvitesFilter;
