import React from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import CategoryItem from './category-item';
import SortableCategoryList from './sortable-category-list';
import { ManageCategoriesForm } from './forms';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

function renderCategory(params) {
  const [category, submitting, deleteCategory, currentVenueId] = oFetch(params, 'category', 'submitting', 'deleteCategory', 'currentVenueId');
  return <CategoryItem category={category} onDelete={deleteCategory} submitting={submitting} currentVenueId={currentVenueId} />;
}

function ManageCategories(props) {
  const [questionnaireCategories, onSubmit, colorsOptions, deleteCategory, currentVenueId] = oFetch(
    props,
    'questionnaireCategories',
    'onSubmit',
    'colorsOptions',
    'deleteCategory',
    'currentVenueId'
  );

  function renderCategoryList(submitting) {
    return (
      <SortableCategoryList
        onChange={() => {}}
        items={questionnaireCategories}
        itemRender={category => renderCategory({ category, submitting, deleteCategory, currentVenueId })}
      />
    );
  }

  const initialValues = {
    venueId: currentVenueId,
    name: null,
    color: null,
  };

  return (
    <ManageCategoriesForm
      initialValues={initialValues}
      categoryListRender={renderCategoryList}
      colorsOptions={colorsOptions}
      onSubmit={onSubmit}
      form="manage-categories-form"
    />
  );
}

const mapStateToProps = state => {
  return {
    questionnaireCategories: selectors.getActiveQuestionnaireCategories(state),
    colorsOptions: selectors.getColorsOptions(state),
  };
};

const mapDispatchToProps = {
  deleteCategory: actions.deleteCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories);
