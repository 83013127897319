import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import { openContentModal } from '@/components/modals';
import AddOwedHour from '../components/add-owed-hour';
import { getOwedHoursWeeks } from '../selectors';
import { fromJS } from 'immutable';

import * as actions from '../actions';

import OwedHoursHeader from '../components/owed-hours-header';
import OwedHoursTable, { Row } from '../components/owed-hours-table';
import OwedHoursMobileItem from '../components/owed-hours-mobile-item';

import OwedHoursFilter from '../components/owed-hours-filter';
import EditOwedHours from '../components/edit-owed-hours';
import { ProfileWrapper as BossProfileWrapper } from '../../profile-wrapper';
import { ProfileWrapper as MossProfileWrapper } from '../../../MossStaffMemberProfile/profile-wrapper';

import { BOSS_STAFF_MEMBER_TYPE, MOSS_STAFF_MEMBER_TYPE } from '../../../StaffMemberProfile/holidays-page/constants';

const mapStateToProps = state => {
  const stateImmut = fromJS(state);

  const mossHourTags = stateImmut.getIn(['owedHours', 'mossHourTags']);
  if (!mossHourTags) {
    throw new Error('owedHours.mossHourTags is not present in state');
  }
  const effectiveStaffMember =  stateImmut.getIn(['owedHours', 'effectiveStaffMember']);

  return {
    mossHourTags: mossHourTags.toJS(),
    effectiveStaffMember,
    owedHourWeeks: getOwedHoursWeeks(stateImmut),
    newOwedHour: stateImmut.getIn(['owedHours', 'newOwedHour']),
    editOwedHour: stateImmut.getIn(['owedHours', 'editOwedHour']),
    editedOwedHours: stateImmut.getIn(['owedHours', 'editedOwedHours']),
    disabled: effectiveStaffMember.get('disabled'),
    startDate: stateImmut.getIn(['owedHours', 'startDate']),
    endDate: stateImmut.getIn(['owedHours', 'endDate']),
    permissionsData: stateImmut.getIn(['owedHours', 'permissionsData']),
    payslipStartDate: stateImmut.getIn(['owedHours', 'payslipStartDate']),
    payslipEndDate: stateImmut.getIn(['owedHours', 'payslipEndDate']),
  };
};

const mapDispatchToProps = {
  createOwedHour: actions.createOwedHour,
  deleteOwedHour: actions.deleteOwedHour,
  editOwedHour: actions.editOwedHour,
  filter: actions.filter,
};

function getOwedHourPermissions(owedHour, permissions) {
  const owedHourId = oFetch(owedHour, 'id');
  const owedHourPermissions = permissions[owedHourId];

  if (!owedHourPermissions) {
    throw new Error(
      `Permission for owed hour with id: ${owedHourId}, doesn't present on owedHoursPermissions object`,
    );
  }

  return owedHourPermissions;
}
class OwedHours extends React.PureComponent {
  handleCreateOwedHour = (closeModal, values) => {
    const createOwedHour = oFetch(this.props, 'createOwedHour');
    const setLastMossHourTagId = oFetch(this.props, 'setLastMossHourTagId');
    return createOwedHour({ values, setLastMossHourTagId }).then(() => {
      closeModal();
    });
  };

  handleUpdateOwedHour = (closeModal, values) => {
    const editOwedHour = oFetch(this.props, 'editOwedHour');
    const setLastMossHourTagId = oFetch(this.props, 'setLastMossHourTagId');
    return editOwedHour({ values, setLastMossHourTagId }).then(() => {
      closeModal();
    });
  };

  getOpenAddOwedHourModal = (args) => {
    const effectiveStaffMemberType = oFetch(args, 'effectiveStaffMemberType');
    const mossHourTags = oFetch(this.props, 'mossHourTags');
    return () => {
      openContentModal({
        submit: this.handleCreateOwedHour,
        config: { title: 'Add owed hour' },
        props: {
          effectiveStaffMemberType,
          mossHourTags,
          lastMossHourTagId: oFetch(this.props, 'lastMossHourTagId'),
        },
      })(AddOwedHour);
    };
  };

  getOpenEditOwedHourModal = (args) => {
    const effectiveStaffMemberType = oFetch(args, 'effectiveStaffMemberType');
    const mossHourTags = oFetch(this.props, 'mossHourTags');

    return (owedHour) => {
      openContentModal({
        submit: this.handleUpdateOwedHour,
        config: { title: 'Edit owed hour' },
        props: {
          owedHour,
          mossHourTags,
          effectiveStaffMemberType,
        },
      })(EditOwedHours);
    };
  };

  render() {
    const [
      effectiveStaffMember,
      immutOwedHourWeeks,
      disabled,
      startDate,
      endDate,
      payslipStartDate,
      payslipEndDate,
      filter,
      deleteOwedHour,
      mossHourTags,
    ] = oFetch(
      this.props,
      'effectiveStaffMember',
      'owedHourWeeks',
      'disabled',
      'startDate',
      'endDate',
      'payslipStartDate',
      'payslipEndDate',
      'filter',
      'deleteOwedHour',
      'mossHourTags',
    );
    const owedHourWeeks = immutOwedHourWeeks.toJS();

    const permissionsData = oFetch(this, 'props.permissionsData').toJS();
    const canCreateOwedHours = oFetch(permissionsData, 'canCreateOwedHours');
    const owedHoursPermissions = oFetch(permissionsData, 'owedHoursPermissions');
    const effectiveStaffMemberJS = effectiveStaffMember.toJS();
    const isSecurityStaff = oFetch(effectiveStaffMemberJS, 'is_security_staff');

    const effectiveStaffMemberType = oFetch(this.props, 'staffMemberType');

    let EffectiveProfileWrapper = undefined;
    if (effectiveStaffMemberType === BOSS_STAFF_MEMBER_TYPE) {
      EffectiveProfileWrapper = BossProfileWrapper;
    } else if (effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE) {
      EffectiveProfileWrapper = MossProfileWrapper;
    } else {
      throw new Error(`unsupported effectiveStaffMemberType: ${effectiveStaffMemberType}`);
    };

    if (isSecurityStaff) {
      return null;
    } else {
      return (
        <EffectiveProfileWrapper currentPage="owed_hours">
          <section className="boss-board">
            <OwedHoursHeader
              canCreateOwedHours={canCreateOwedHours}
              isStaffMemberDisabled={disabled}
              title="Owed hours"
              onAddNew={this.getOpenAddOwedHourModal({effectiveStaffMemberType})}
            />
            <div className="boss-board__main">
              <div className="boss-board__manager">
                <OwedHoursFilter
                  startDate={startDate}
                  endDate={endDate}
                  filter={filter}
                  payslipStartDate={payslipStartDate}
                  payslipEndDate={payslipEndDate}
                />
                <OwedHoursTable
                  owedHourWeeks={owedHourWeeks}
                  effectiveStaffMemberType={effectiveStaffMemberType}
                  owedHourRenderer={owedHour => (
                    <Row
                      owedHour={owedHour}
                      mossHourTags={mossHourTags}
                      effectiveStaffMemberType={effectiveStaffMemberType}
                      deleteOwedHours={deleteOwedHour}
                      openEditModal={this.getOpenEditOwedHourModal({effectiveStaffMemberType})}
                      isStaffMemberDisabled={disabled}
                      owedHourPermissions={getOwedHourPermissions(owedHour, owedHoursPermissions)}
                    />
                  )}
                  owedHourMobileRenderer={owedHour => (
                    <OwedHoursMobileItem
                      owedHour={owedHour}
                      effectiveStaffMemberType={effectiveStaffMemberType}
                      mossHourTags={mossHourTags}
                      isStaffMemberDisabled={disabled}
                      deleteOwedHours={deleteOwedHour}
                      openEditModal={this.getOpenEditOwedHourModal({effectiveStaffMemberType})}
                      owedHourPermissions={getOwedHourPermissions(owedHour, owedHoursPermissions)}
                    />
                  )}
                />
              </div>
            </div>
          </section>
        </EffectiveProfileWrapper>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwedHours);
