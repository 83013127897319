import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { CategoryItem } from './category-item';

export function CategoriesList(props) {
  const categories = oFetch(props, 'categories');

  function renderCategories() {
    return categories.map((category, index) => {
      return <CategoryItem key={index} category={category} />;
    });
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Training Materials</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>{renderCategories()}</ContentWrapper>
    </Fragment>
  );
}
