import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import { $MessageSchedule } from '@/types/MessageSchedule';
import { normalizeFinalFormErrors } from '@/lib/normalize-final-form-errors';

export function createMessageSchedule(params: any) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const onFailure  = oFetch(params, 'onFailure');

  return bossRequestHttp({
    successHandler(params: any) {
      const messageSchedule = $MessageSchedule.parse(oFetch(params, 'data.messageSchedule'));
      onSuccess(messageSchedule);
    },
    errorHandler(params: any) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      onFailure();

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'staffMemberId', 'sendAt', 'message'],
        });
        return normalizeFinalFormErrors(errors);
      }
      return false;
    }
  }).post(apiRoutes.createAevaMessageSchedule.getPath(), values);
}

export function updateMessageSchedule(params: any) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const onFailure = oFetch(params, 'onFailure');
  const messageScheduleId = oFetch(values, 'id');

  return bossRequestHttp({
    successHandler(params: any) {
      const messageSchedule = $MessageSchedule.parse(oFetch(params, 'data.messageSchedule'));
      onSuccess(messageSchedule);
    },
    errorHandler(params: any) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      onFailure();
      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'staffMemberId', 'sendAt', 'message'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
      return false;
    }
  }).put(apiRoutes.updateAevaMessageSchedule.getPath(messageScheduleId), values);
}

export function disableMessageSchedule(params: any) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const messageScheduleId = oFetch(values, 'messageScheduleId');

  return bossRequestHttp({
    successHandler(params: any) {
      const messageSchedule = oFetch(params, 'data.messageSchedule');
      onSuccess(messageSchedule);
    },
    errorHandler() {
      return false;
    }
  }).delete(apiRoutes.disableAevaMessageSchedule.getPath(messageScheduleId));
}
