import React from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import { reduxForm, Fields, formValueSelector } from 'redux-form';
import cn from 'classnames';
import { CountingField } from './form-fields';
import WeekDayLastEventLabel from './week-day-last-event-label';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

function DayCountingForm(props) {
  const [submitting, handleSubmit, totalValue, isValuesEmpty, lastEventData, editable] = oFetch(
    props,
    'submitting',
    'handleSubmit',
    'totalValue',
    'isValuesEmpty',
    'lastEventData',
    'editable',
  );

  const { error } = props;

  const disabled = !editable || submitting;

  const submitButtonClassNames = cn('purple-button purple-form__action', {
    'purple-button_color_accent-red': isValuesEmpty,
    'purple-button_color_accent-primary': !isValuesEmpty,
  });

  function renderHeader() {
    return (
      <div className="purple-table__row purple-table__row_role_header">
        <div className="purple-table__cell purple-table__cell_role_header">Denomination</div>
        <div className="purple-table__cell purple-table__cell_role_header">Value</div>
        <div className="purple-table__cell purple-table__cell_role_header">Foreign</div>
        <div className="purple-table__cell purple-table__cell_role_header">Total</div>
      </div>
    );
  }

  function getButtonTitle() {
    if (isValuesEmpty) {
      return 'Submit Zero Value Count';
    } else {
      return 'Update';
    }
  }

  function renderBaseError() {
    if (!error) {
      return null;
    }
    return (
      <div className="purple-form-error purple-form-error_role_general purple-form-error_position_above">
        {error.map((errorMessage, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {errorMessage}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <div className="purple-form">
      {renderBaseError()}
      <div className="purple-form__group">
        <div className="purple-form__group">
          <div className="purple-table purple-table_page_counting-totals">
            {renderHeader()}
            <Fields
              label={'£50'}
              editable={!disabled}
              names={['fiftyNotesTotalPounds', 'foreignFiftyNotesTotalPounds']}
              component={CountingField}
            />
            <Fields
              label={'£20'}
              editable={!disabled}
              names={['twentyNotesTotalPounds', 'foreignTwentyNotesTotalPounds']}
              component={CountingField}
            />
            <Fields
              label={'£10'}
              editable={!disabled}
              names={['tenNotesTotalPounds', 'foreignTenNotesTotalPounds']}
              component={CountingField}
            />
            <Fields
              label={'£5'}
              editable={!disabled}
              names={['fiveNotesTotalPounds', 'foreignFiveNotesTotalPounds']}
              component={CountingField}
            />
            <Fields
              label={'Change'}
              pence
              editable={!disabled}
              names={['changeTotalPence', 'foreignChangeTotalPence']}
              component={CountingField}
            />
          </div>
          <div className="purple-form__row purple-form__row_justify_end purple-form__row_adjust_no-wrap purple-form__row_position_last">
            <div className="purple-form__field purple-form__field_size_third purple-form__field_position_last">
              <div className="purple-form-field purple-form-field_role_control purple-form-field_justify_center">
                <p className="purple-form-field__label purple-form-field__label_size_l-m-major purple-form-field__label_adjust_control-fluid">
                  <span className="purple-form-field__label-text">Total</span>
                </p>
                <div className="purple-form-field__value">£{totalValue}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="purple-form__row purple-form__row_justify_end purple-form__row_align_middle purple-form__row_position_last">
          {lastEventData && <WeekDayLastEventLabel lastEventData={lastEventData} />}
          {editable && (
            <div className="purple-form__field purple-form__field_size_min purple-form__field_position_last">
              {!submitting && (
                <div className="purple-form__actions purple-form__actions_justify_center">
                  <button onClick={handleSubmit} className={submitButtonClassNames} type="submit">
                    <span className="purple-button__text">{getButtonTitle()}</span>
                  </button>
                </div>
              )}
              {submitting && (
                <div className="purple-form__actions purple-form__actions_justify_center">
                  <p className="purple-form__text">Updating...</p>
                  <div className="purple-spinner purple-spinner_position_after purple-spinner_size_xs purple-form__spinner" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state, props) => {
  const form = oFetch(props, 'form');
  const selector = formValueSelector(form);
  const { changeTotalPence, foreignChangeTotalPence, ...formValues } = selector(
    state,
    'fiftyNotesTotalPounds',
    'foreignFiftyNotesTotalPounds',
    'twentyNotesTotalPounds',
    'foreignTwentyNotesTotalPounds',
    'tenNotesTotalPounds',
    'foreignTenNotesTotalPounds',
    'fiveNotesTotalPounds',
    'foreignFiveNotesTotalPounds',
    'changeTotalPence',
    'foreignChangeTotalPence',
  );
  const changePounds = (changeTotalPence + foreignChangeTotalPence) / 100;
  const totalValue =
    Object.values(formValues).reduce((acc, value) => {
      return acc + (value || 0);
    }, 0) + changePounds;
  return { totalValue, isValuesEmpty: totalValue === 0 };
})(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    onChange(newValues, dispatch, props, oldValue) {
      const pristine = oFetch(props, 'pristine');
      const date = oFetch(newValues, 'date');
      dispatch(actions.setTabStatus({ [date]: { pristine } }));
    },
  })(DayCountingForm),
);
