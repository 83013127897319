import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = [];

export default handleActions(
  {
    [t.FLAGGED_STAFF_MEMBERS]: (state, action) => {
      const flaggedStaffMembers = action.payload;
      return flaggedStaffMembers.map(flaggedStaffMember => {
        const inStateStaffMember = state.find(
          staffMember => oFetch(flaggedStaffMember, 'id') === oFetch(staffMember, 'id'),
        );
        if (inStateStaffMember) {
          return {
            ...flaggedStaffMember,
            reviewed: oFetch(inStateStaffMember, 'reviewed'),
          };
        }
        return {
          ...flaggedStaffMember,
          reviewed: false,
        };
      });
    },
    [t.REVIEW_FLAGGED_STAFF_MEMBER]: (state, action) => {
      const reviewedStaffMemberId = action.payload;
      return state.map(flaggedStaffMember => {
        if (oFetch(flaggedStaffMember, 'id') === reviewedStaffMemberId) {
          return {
            ...flaggedStaffMember,
            reviewed: true,
          };
        }
        return flaggedStaffMember;
      });
    },
    [t.UN_REVIEW_FLAGGED_STAFF_MEMBER]: (state, action) => {
      const unReviewedStaffMemberId = action.payload;
      return state.map(flaggedStaffMember => {
        if (oFetch(flaggedStaffMember, 'id') === unReviewedStaffMemberId) {
          return {
            ...flaggedStaffMember,
            reviewed: false,
          };
        }
        return flaggedStaffMember;
      });
    },
  },
  initialState,
);
