import React, { useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';
import Spinner from '@/components/spinner';

const TRADING_OPTIONS = [
  {
    value: true,
    label: 'Trading',
  },
  {
    value: false,
    label: 'Not Trading',
  },
];

export function VenueTradingForm(props) {
  const [trading, onUpdate] = oFetch(props, 'trading', 'onUpdate');

  const [tradingValue, setTradingValue] = useState(trading);
  const [editable, setEditable] = useState(false);
  const [updating, setUpdating] = useState(false);

  const label = tradingValue === true ? 'Trading' : 'Not Trading';
  const labelClassName = cn('boss-indicator', {
    'boss-indicator_color_accent-red': !tradingValue,
    'boss-indicator_color_accent-green': tradingValue,
  });
  function startEditing() {
    setEditable(true);
  }

  function finishEditing() {
    setEditable(false);
  }

  function handleChange(item) {
    setTradingValue(item);
  }

  async function handldeUpdate() {
    setUpdating(true);
    await onUpdate({
      trading: tradingValue,
      onSuccess() {
        finishEditing();
        setUpdating(false);
      },
      onFailure() {
        setTradingValue(trading);
        finishEditing();
        setUpdating(false);
      },
    });
  }

  function renderSelect() {
    return (
      <Fragment>
        <div className="boss-page-dashboard__status-select">
          <div className="boss-form">
            <div className="boss-form__select boss-form__select_type_narrow">
              <Select
                value={tradingValue}
                onChange={handleChange}
                simpleValue
                clearable={false}
                multi={false}
                options={TRADING_OPTIONS}
              />
            </div>
          </div>
        </div>
        <button
          className="boss-button boss-button_role_secondary-light boss-button_type_extra-small boss-page-dashboard__status-action"
          type="button"
          onClick={handldeUpdate}
        >
          Save
        </button>
      </Fragment>
    );
  }

  function renderView() {
    return (
      <Fragment>
        <div className="boss-page-dashboard__status-indicator">
          <div className={labelClassName}>
            <div className="boss-indicator__label boss-indicator__label_size_l">{label}</div>
          </div>
        </div>
        <button
          onClick={startEditing}
          className="boss-button boss-button_role_primary-light boss-button_type_extra-small boss-page-dashboard__status-action"
          type="button"
        >
          Update
        </button>
      </Fragment>
    );
  }

  return (
    <div className="boss-page-dashboard__status-group">
      {updating && <Spinner />}
      {editable && !updating && renderSelect()}
      {!editable && !updating && renderView()}
    </div>
  );
}
