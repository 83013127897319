import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { DashboardWrapper, PanelWrapper } from '../components';
import { StaffTypes } from './components';

export function ViewChecklistAssignmentsApp(props) {
  const assignedStaffTypes = oFetch(props, 'assignedStaffTypes');

  return (
    <Fragment>
      <DashboardWrapper checklistName="test" link="/check_lists" />
      <ContentWrapper>
        <PanelWrapper title="Assigned Staff Types">
          <StaffTypes staffTypes={assignedStaffTypes} />
        </PanelWrapper>
      </ContentWrapper>
    </Fragment>
  );
}
