import { combineReducers } from 'redux';
import historyReducer from './history-reducer';
import filterReducer from './filter-reducer';
import pagesReducer from './pages-reducer';
import staticReducer from './static-reducer';

export default combineReducers({
  filter: filterReducer,
  pages: pagesReducer,
  static: staticReducer,
  history: historyReducer,
});
