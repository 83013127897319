import React from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';

import {
  UsersList,
  UserItem,
  ReportsList,
  ReportItem,
  MobileReportsList,
  RollbarItemsModalContent,
} from './components';

export function ErrorReports(props) {
  const [reports, userReports] = oFetch(props, 'reports', 'userReports');
  const { openModal } = useModal();

  function openRollbarItemsModal(userId, userFullName) {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: RollbarItemsModalContent,
      onSubmit() {},
      config: {
        title: `${userFullName} rollbar items`,
      },
      props: {
        userId,
      },
    });
  }

  return (
    <>
      <DashboardWrapper>
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Error Reports</h1>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {/* <UsersList
          userReports={userReports}
          renderUser={params => <UserItem renderReportItem={() => {}} {...params} />}
        /> */}
        <ReportsList
          reports={reports}
          reportRender={report => <ReportItem onUserClick={openRollbarItemsModal} report={report} />}
        />
        <MobileReportsList onUserClick={openRollbarItemsModal} reports={reports} />
      </ContentWrapper>
    </>
  );
}
