import React from 'react';
import oFetch from 'o-fetch';

export default function DashboardWrapper(props) {
  const [title, children] = oFetch(props, 'title', 'children');

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_checklist">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            <div className="boss-page-dashboard__buttons-group">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
