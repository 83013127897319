
import { useContext } from 'react';
import { getGlobalProviders } from '@/components/hooks-components/global';
import { MossPartyPeopleAppGlobals } from './types';

const { GlobalProvider: GGlobalProvider, GlobalContext } = getGlobalProviders<MossPartyPeopleAppGlobals>();

export const GlobalProvider = GGlobalProvider;

export function useGlobal(): MossPartyPeopleAppGlobals {
  const context = useContext<MossPartyPeopleAppGlobals | null>(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used inside the GlobalProvider');
  }
  return context;
}