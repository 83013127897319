import React from 'react';
import Modal from 'react-modal';

export default function ModalWrapper({ show, children, onClose }) {
  return (
    <Modal
      isOpen={show}
      shouldCloseOnOverlayClick={true}
      contentLabel="Modal"
      className="ReactModal__Content ReactModal__Content--after-open boss-modal-window boss-modal-window_role_rota"
      ariaHideApp={false}
    >
      <button
        onClick={onClose}
        className="boss-modal-window__close boss-modal-window__close_primary"
      />
      <div className="boss-modal-window__content">{children}</div>
    </Modal>
  );
}
