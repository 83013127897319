import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = null;

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const questionsTagsIds = oFetch(action.payload, 'questionsTagsIds');
      return questionsTagsIds;
    },
    [types.ADD_QUESTION]: (state, action) => {
      const [questionTagsIds, question] = oFetch(action.payload, 'questionTagsIds', 'question');
      const questionId = oFetch(question, 'id');
      return { ...state, [questionId]: questionTagsIds };
    },
    [types.ADD_QUESTION_AND_DELETE_OLD]: (state, action) => {
      const [questionTagsIds, question] = oFetch(action.payload, 'questionTagsIds', 'question');
      const questionId = oFetch(question, 'id');
      return { ...state, [questionId]: questionTagsIds };
    },
  },
  initialState,
);
