import React, { Component } from 'react';
import PropTypes from 'prop-types';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { appRoutes } from "@/lib/legacy-routes";
import oFetch from 'o-fetch';

class RotaWeekDays extends Component {
  state = {
    sHighlightDate: oFetch(this.props, 'sDate'),
  };

  generateWeek(sStartDate) {
    let mStartOfWeek = safeMoment.uiDateParse(sStartDate).startOf('isoweek');
    let mEndOfWeek = safeMoment.uiDateParse(sStartDate).endOf('isoweek');

    let sDates = [];
    let mCurrentDate = mStartOfWeek;

    while (mCurrentDate <= mEndOfWeek) {
      sDates.push(mCurrentDate.format(utils.commonDateFormat));
      mCurrentDate = mCurrentDate.clone().add(1, 'd');
    }
    return sDates;
  }

  renderDays(week) {
    return <div className="boss-paginator boss-paginator_size_full">{this.renderTabList(week)}</div>;
  }

  renderTabList(week) {
    const sHighlightDate = safeMoment.uiDateParse(oFetch(this.state, 'sHighlightDate'));
    return week.map((sDate, index) => {
      const mCurrentDate = safeMoment.uiDateParse(sDate);

      const mModifiedDate = safeMoment.uiDateParse(sDate);
      const tabClassName = sHighlightDate.isSame(mModifiedDate, 'days') ? 'boss-paginator__action_state_active' : '';
      const formatedDate = sHighlightDate.isSame(mModifiedDate, 'days')
        ? mModifiedDate.format('D MMMM')
        : mModifiedDate.format('D');

      return (
        <div key={index} className="boss-paginator__group">
          <button
            onClick={() => this.loadDayRota(sDate)}
            className={`boss-paginator__action boss-paginator__action_type_light ${tabClassName}`}
          >
            {formatedDate}
          </button>
          <div className="boss-paginator__meta">
            <a
              href={appRoutes.securityRotaShiftRequests({ mStartDate: mCurrentDate }) }
              className="boss-paginator__meta-link boss-paginator__meta-link_role_button-light"
            >
              Requests: {this.props.securityShiftRequestsCount[sDate]}
            </a>
          </div>
        </div>
      );
    });
  }

  loadDayRota = uiDate => {
    this.setState({
      sHighlightDate: uiDate,
    });
    this.props.onDateChange(uiDate);
  };

  render() {
    const sDate = oFetch(this.props, 'sDate');
    const currentWeek = this.generateWeek(sDate);

    return <div className="boss-rotas__days-nav">{this.renderDays(currentWeek)}</div>;
  }
}

RotaWeekDays.propTypes = {
  sDate: PropTypes.string.isRequired,
  securityShiftRequestsCount: PropTypes.object,
};
export default RotaWeekDays;
