import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {};

export default handleActions(
  {
    [types.SET_TAB_STATUS]: (state, action) => {
      const weekDayTabStatuses = oFetch(action, 'payload');
      return {
        ...state,
        ...weekDayTabStatuses,
      };
    },
    [types.ADD_DAY_COUNT]: (state, action) => {
      const date = oFetch(action, 'payload.weekDayCount.date');
      return { ...state, [date]: { ...state[date], pristine: true } };
    },
    [types.UPDATE_DAY_COUNT]: (state, action) => {
      const date = oFetch(action, 'payload.weekDayCount.date');
      return { ...state, [date]: { ...state[date], pristine: true } };
    },
    [types.UPDATE_WEEK_COUNT]: (state, action) => {
      return { ...state, all: { ...state['all'], pristine: true } };
    },
  },
  initialState,
);
