import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function CountingLooseField(props) {
  const [input, meta, disabled] = oFetch(props, 'input', 'meta', 'disabled');
  const [value, name, onChange] = oFetch(input, 'value', 'name', 'onChange');

  function handleChange(e) {
    const newValue = oFetch(e, 'target.value');
    const regExp = new RegExp('^[0-9]+(.[0-9]{0,2})?$');

    if (newValue === '') {
      return onChange(newValue);
    }

    if (!regExp.test(newValue)) {
      e.target.select();
      return onChange(value);
    }

    const normalizedValue = +parseFloat(newValue).toFixed(2);
    onChange(normalizedValue * 100);
  }

  function getNormalizedValue() {
    return (value || 0) / 100;
  }

  function handleFocus(e) {
    e.target.select();
  }

  return (
    <div className="purple-table__row">
      <div className="purple-table__cell">
        <div className="purple-table__info">
          <p className="purple-table__text">£</p>
        </div>
      </div>
      <div className="purple-table__cell">
        <div className="purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell">
          <div className="purple-form-field__input purple-form-field__input_size_l">
            <input
              name={name}
              placeholder={0}
              onFocus={handleFocus}
              value={getNormalizedValue()}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="purple-form-field purple-form-field_role_control purple-visible_size_m-minor">
          <p className="purple-form-field__label purple-form-field__label_type_border">
            <span className="purple-form-field__label-text">£</span>
          </p>
          <div className="purple-form-field__input purple-form-field__input_size_l">
            <input
              name={name}
              type="number"
              placeholder="0"
              value={getNormalizedValue()}
              disabled={disabled}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
