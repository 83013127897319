import { createAction } from 'redux-actions';
import * as types from './types';

import oFetch from 'o-fetch';

import {
  markReportCompletedRequest,
  markReportsCompletedRequest,
  getBankDetailsRequest,
  wipeBankDetailsRequest,
  uncompleteMossFinanceReportRequest,
  updateMossFinanceReportNoteRequest,
  finishMossFinanceReportWeekRequest,
  undoMossFinanceReportWeekRequest,
} from '../requests';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const setReportStatusDone = createAction(types.SET_REPORT_STATUS_DONE);
export const changePayRateFilter = createAction(types.CHANGE_PAY_RATE_FILTER);
export const markReportsCompletedAction = createAction(types.MARK_REPORTS_COMPLETED);
export const updateMossFinanceReportAction = createAction(types.UPDATE_MOSS_FINANCE_REPORT);
export const updateMossFinanceReportWeekAction = createAction(types.UPDATE_MOSS_FINANCE_REPORT_WEEK);

export const markReportCompleted = options => (dispatch, getState) => {
  const pageStateJS = oFetch(getState(), 'page');
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const mossStaffMemberId = oFetch(options, 'mossStaffMemberId');

  return markReportCompletedRequest({ date, mossStaffMemberId, venueId }).then(response => {
    dispatch(markReportsCompletedAction(response.data));
    dispatch(updateMossFinanceReportWeekAction(response.data));
  });
};

export const finishMossFinanceReportWeek = params => (dispatch, getState) => {
  return finishMossFinanceReportWeekRequest({
    values: params,
    onSuccess(data) {
      dispatch(updateMossFinanceReportWeekAction(data));
    },
  });
};

export const undoMossFinanceReportWeek = params => (dispatch, getState) => {
  return undoMossFinanceReportWeekRequest({
    values: params,
    onSuccess(data) {
      dispatch(updateMossFinanceReportWeekAction(data));
    },
  });
};

export const uncompleteMossFinanceReport = options => (dispatch, getState) => {
  const pageStateJS = oFetch(getState(), 'page');
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const mossStaffMemberId = oFetch(options, 'mossStaffMemberId');
  const onSuccess = oFetch(options, 'onSuccess');

  return uncompleteMossFinanceReportRequest({
    values: { date, mossStaffMemberId, venueId },
    onSuccess(data) {
      onSuccess();
      dispatch(updateMossFinanceReportAction(data));
      dispatch(updateMossFinanceReportWeekAction(data));
    },
  });
};

export const updateMossFinanceReportNote = params => (dispatch, getState) => {
  const pageStateJS = oFetch(getState(), 'page');
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');
  const note = oFetch(params, 'note');
  const onSuccess = oFetch(params, 'onSuccess');

  return updateMossFinanceReportNoteRequest({
    values: { date, mossStaffMemberId, venueId, note },
    onSuccess(data) {
      onSuccess();
      dispatch(updateMossFinanceReportAction(data));
    },
    onFailure() {},
  });
};

export const getBankDetailsAction = params => (dispatch, getState) => {
  return getBankDetailsRequest(params);
};

export const markReportsCompleted = options => (dispatch, getState) => {
  const pageStateJS = oFetch(getState(), 'page');
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const mossStaffMemberIds = oFetch(options, 'mossStaffMemberIds');

  return markReportsCompletedRequest({ date, mossStaffMemberIds, venueId }).then(response => {
    dispatch(markReportsCompletedAction(response.data));
    dispatch(updateMossFinanceReportWeekAction(response.data));
  });
};
