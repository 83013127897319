import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import * as types from './types';
import {
  addSecurityShiftRequestRequest,
  updateSecurityShiftRequestRequest,
  deleteSecurityShiftRequestRequest,
  getSecurityShiftRequestsByDateRequest,
} from '../requests';

import * as selectors from '../selectors';

export const loadInitialData = createAction(types.INITIAL_LOAD);
export const addSecurityShiftRequestAction = createAction(types.ADD_SECURITY_SHIFT_REQUEST);
export const updateSecurityShiftRequestAction = createAction(types.UPDATE_SECURITY_SHIFT_REQUEST);

export const changeWeekDay = params => (dispatch, getState) => {
  const sChosenDate = oFetch(params, 'sChosenDate');
  dispatch(changeWeekDayAction({ sChosenDate }));
  return getSecurityShiftRequestsByDateRequest({
    sDate: sChosenDate,
    onSuccess(data) {
      dispatch(changeWeekDayComplete(data));
    },
  });
};

const addAndIncrementForCurrentWeek = resp => (dispatch, getState) => {
  const weekDates = selectors.weekDatesSelector(getState());
  const chosenDate = selectors.chosenDateSelector(getState());
  const securityShiftRequest = oFetch(resp, 'data.securityShiftRequest');
  const rotaDate = oFetch(securityShiftRequest, 'rotaDate');
  if (weekDates.includes(rotaDate)) {
    if (chosenDate === rotaDate) {
      dispatch(addSecurityShiftRequestAction({ securityShiftRequest }));
    }
    dispatch(incrementForDayAction(rotaDate));
  }
};
const updateForCurrentWeek = resp => (dispatch, getState) => {
  const weekDates = selectors.weekDatesSelector(getState());
  const chosenDate = selectors.chosenDateSelector(getState());
  const securityShiftRequest = oFetch(resp, 'data.securityShiftRequest');
  const rotaDate = oFetch(securityShiftRequest, 'rotaDate');
  if (weekDates.includes(rotaDate)) {
    if (chosenDate === rotaDate) {
      dispatch(updateSecurityShiftRequestAction({ securityShiftRequest }));
    } else {
      dispatch(removeShiftRequestAction({ securityShiftRequest }));
      dispatch(incrementForDayAction(rotaDate));
      dispatch(decrementForDayAction(chosenDate));
    }
  } else {
    dispatch(removeShiftRequestAction({ securityShiftRequest }));
    dispatch(decrementForDayAction(chosenDate));
  }
};
const updateAndDecrementForCurrentWeek = resp => (dispatch, getState) => {
  const weekDates = selectors.weekDatesSelector(getState());
  const chosenDate = selectors.chosenDateSelector(getState());
  const securityShiftRequest = oFetch(resp, 'data.securityShiftRequest');
  const rotaDate = oFetch(securityShiftRequest, 'rotaDate');
  if (weekDates.includes(rotaDate)) {
    if (chosenDate === rotaDate) {
      dispatch(updateSecurityShiftRequestAction({ securityShiftRequest }));
    }
    dispatch(decrementForDayAction(rotaDate));
  }
};

export const addSecurityShiftRequest = params => (dispatch, getState) => {
  const startsAt = oFetch(params, 'startsAt');
  const endsAt = oFetch(params, 'endsAt');
  const venueId = oFetch(params, 'venueId');
  const note = oFetch(params, 'note');
  return addSecurityShiftRequestRequest({
    startsAt,
    endsAt,
    note,
    venueId,
  }).then(resp => {
    dispatch(addAndIncrementForCurrentWeek(resp));
    return resp;
  });
};

export const editSecurityShiftRequest = values => (dispatch, getState) => {
  const startsAt = oFetch(values, 'startsAt');
  const endsAt = oFetch(values, 'endsAt');
  const venueId = oFetch(values, 'venueId');
  const id = oFetch(values, 'id');
  const note = oFetch(values, 'note');

  return updateSecurityShiftRequestRequest({
    startsAt,
    endsAt,
    venueId,
    id,
    note,
  }).then(response => {
    dispatch(updateForCurrentWeek(response));
    return response;
  });
};

export const deleteSecurityShiftRequest = id => (dispatch, getState) => {
  return deleteSecurityShiftRequestRequest({ id }).then(response => {
    dispatch(updateAndDecrementForCurrentWeek(response));
    return response;
  });
};

export const changeWeekDayAction = createAction(types.CHANGE_WEEK_DAY);
export const removeShiftRequestAction = createAction(types.REMOVE_SHIFT_REQUEST);
export const incrementForDayAction = createAction(types.INCREMENT_FOR_DAY);
export const decrementForDayAction = createAction(types.DECREMENT_FOR_DAY);
export const changeWeekDayComplete = createAction(types.CHANGE_WEEK_DAY_COMPLETE);
