import React from 'react';
import oFetch from 'o-fetch';
import MossPayRatesForm from './moss-pay-rates-form';

export default function AddMossPayRateModal(props) {
  const [onSubmit, mossPayRatesOptions, initialCalculationType] = oFetch(
    props,
    'onSubmit',
    'mossPayRatesOptions',
    'initialCalculationType',
  );
  const initialValues = {
    name: '',
    calculationType: initialCalculationType,
    rate: null,
  };

  return (
    <MossPayRatesForm
      onSubmit={onSubmit}
      mossPayRatesOptions={mossPayRatesOptions}
      initialValues={initialValues}
      buttonTitle="Create"
    />
  );
}
