import React from 'react';
import oFetch from 'o-fetch';

export default function ContentBoardWrapper(props) {
  const children = oFetch(props, 'children');

  return (
    <div className="purple-board">
      <div className="purple-board__inner">{children}</div>
    </div>
  );
}
