import React from 'react';
import oFetch from 'o-fetch';

export default function TagItem(props) {
  const [tag, onClick] = oFetch(props, 'tag', 'onClick');
  const [name, count] = oFetch(tag, 'name', 'count');

  return (
    <button
      type="button"
      onClick={() => onClick(tag)}
      className="boss-tag boss-tag_size_xl boss-tag_color_accent-blue boss-tag_role_action"
    >
      <div className="boss-tag__service">{count}</div>
      <p className="boss-tag__text">{name}</p>
    </button>
  );
}
