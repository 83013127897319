const CLOCKED_IN_STATUS = 'clocked_in';
const CLOCKED_OUT_STATUS = 'clocked_out';
const ON_BREAK_STATUS = 'on_break';

export const BOSS_STAFF_TYPE = 'boss';
export const MOSS_STAFF_TYPE = 'moss';

export const clockingStatuses = {
  CLOCKED_IN_STATUS,
  CLOCKED_OUT_STATUS,
  ON_BREAK_STATUS,
};

export const clockingStatusTitles = {
  [CLOCKED_IN_STATUS]: 'CLOCKED IN',
  [CLOCKED_OUT_STATUS]: 'CLOCKED OUT',
  [ON_BREAK_STATUS]: 'ON BREAK',
};