import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import TabFilter from '../components/tab-filter';
import StaffMemberList from '../components/staff-member-list';
import { getStaffMembersOnWrongPayrate } from '../requests';
import Page from '../components/page';
import oFetch from 'o-fetch';

class StaffMembersOnWrongPayrate extends Component {
  state = {
    staffMembers: Immutable.List([]),
    staffMembersWronglyOnUnder18Payrate: Immutable.List([]),
    staffMembersWronglyOn18To20Payrate: Immutable.List([]),
    staffMembersWronglyOn21To22Payrate: Immutable.List([]),
    staffMembersWronglyOn23PlusPayrate: Immutable.List([]),
    isLoaded: false,
  };

  componentDidMount() {
    if (this.props.count > 0) {
      getStaffMembersOnWrongPayrate().then(res => {
        const resData = oFetch(res, 'data');
        const imStaffMembersWronglyOnUnder18Payrate = Immutable.fromJS(
          oFetch(resData, 'staffWronglyOnUnder18Payrate').map(staffMember => ({
            ...staffMember,
            fullName: `${oFetch(staffMember, 'firstName')} ${oFetch(staffMember, 'surname')}`,
          }))
        );
        const imStaffMembersWronglyOn18To20Payrate = Immutable.fromJS(
          oFetch(resData, 'staffWronglyOn18To20Payrate').map(staffMember => ({
            ...staffMember,
            fullName: `${oFetch(staffMember, 'firstName')} ${oFetch(staffMember, 'surname')}`,
          })),
        );
        const imStaffMembersWronglyOn21To22Payrate = Immutable.fromJS(
          oFetch(resData, 'staffWronglyOn21To22Payrate').map(staffMember => ({
            ...staffMember,
            fullName: `${oFetch(staffMember, 'firstName')} ${oFetch(staffMember, 'surname')}`,
          })),
        );
        const imStaffMembersWronglyOn23PlusPayrate = Immutable.fromJS(
          oFetch(resData, 'staffWronglyOn23PlusPayrate').map(staffMember => ({
            ...staffMember,
            fullName: `${oFetch(staffMember, 'firstName')} ${oFetch(staffMember, 'surname')}`,
          })),
        );

        const staffMembers = imStaffMembersWronglyOnUnder18Payrate
          .concat(imStaffMembersWronglyOn18To20Payrate)
          .concat(imStaffMembersWronglyOn21To22Payrate)
          .concat(imStaffMembersWronglyOn23PlusPayrate);

        this.setState({
          staffMembersWronglyOnUnder18Payrate: imStaffMembersWronglyOnUnder18Payrate,
          staffMembersWronglyOn18To20Payrate: imStaffMembersWronglyOn18To20Payrate,
          staffMembersWronglyOn21To22Payrate: imStaffMembersWronglyOn21To22Payrate,
          staffMembersWronglyOn23PlusPayrate: imStaffMembersWronglyOn23PlusPayrate,
          staffMembers,
          isLoaded: true,
        });
      });
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    const staffMembersWronglyOnUnder18Payrate = oFetch(this.state, 'staffMembersWronglyOnUnder18Payrate');
    const staffMembersWronglyOn18To20Payrate = oFetch(this.state, 'staffMembersWronglyOn18To20Payrate');
    const staffMembersWronglyOn21To22Payrate = oFetch(this.state, 'staffMembersWronglyOn21To22Payrate');
    const staffMembersWronglyOn23PlusPayrate = oFetch(this.state, 'staffMembersWronglyOn23PlusPayrate');

    return (
      <Page
        title={this.props.title}
        venues={this.props.venues}
        count={this.props.count}
        staffMembers={this.state.staffMembers}
        staffTypes={this.props.staffTypes}
        tabsFilterRenderer={() => (
          <TabFilter
            showPayRates
            payRates={{
              staffMembersWronglyOnUnder18Payrate,
              staffMembersWronglyOn18To20Payrate,
              staffMembersWronglyOn21To22Payrate,
              staffMembersWronglyOn23PlusPayrate,
            }}
          />
        )}
        staffMemberListRenderer={staffMembers => (
          <StaffMemberList staffMembers={staffMembers} withAge />
        )}
      />
    );
  }
}

StaffMembersOnWrongPayrate.propTypes = {
  count: PropTypes.number.isRequired,
  venues: ImmutablePropTypes.list,
  staffTypes: ImmutablePropTypes.list,
  title: PropTypes.string.isRequired,
};

export default StaffMembersOnWrongPayrate;
