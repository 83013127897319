import blueimpLoadImage from 'blueimp-load-image';

export default function loadImage(file, options) {
  return new Promise((resolve, reject) => {
    blueimpLoadImage(
      file,
      canvas => {
        canvas.toBlob(blob => {
          const newFile = new File([blob], file.name || 'file.jpg', {
            type: file.type || 'image/jpeg',
          });
          newFile.preview = window.URL.createObjectURL(blob);
          resolve(newFile);
        }, file.type || 'image/jpeg');
      },
      { ...options, canvas: true },
    );
  });
}
