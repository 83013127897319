import React, { useEffect, useState } from 'react';
import oFetch from 'o-fetch';
import Spinner from '@/components/spinner';
import * as requests from '../../requests';
import { ActivityList } from './activities';

export function ActivityModalContent(props) {
  const marketingTask = oFetch(props, 'marketingTask');
  const [marketingTaskId, title] = oFetch(marketingTask, 'id', 'title');

  const [fetching, setFetching] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      await requests.getMarketingTaskHistoryEvents({
        values: {
          marketingTaskId,
        },
        onSuccess(data) {
          const events = oFetch(data, 'events');
          setEvents(events);
        },
      });
      setFetching(false);
    })();
  }, []);

  if (fetching) {
    return (
      <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview">
        <div className="boss-overview__group boss-overview__group_position_last">
          <ActivityList events={events} />
        </div>
      </div>
    </div>
  );
}
