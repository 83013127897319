import React, { Fragment } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable';
import BossFormSelect from '@/components/boss-form/boss-form-select';
import BossFormInput from '@/components/boss-form/boss-form-input';
import BossFormCalendar from '@/components/boss-form/boss-form-calendar';
import humanize from 'string-humanize';
import notify from '@/components/global-notification';
import { NonEditableSIAField } from '../../../../AddStaffMember/components/fields/sia-form-field';
import { updatePersonalDetailsRequest } from '../../actions';

const validate = values => {
  const errors = {};

  return errors;
};

const PersonalDetailsForm = ({
  error,
  handleSubmit,
  submitting,
  genderValues,
  onSubmissionComplete,
  checkSiaNumber,
  nonStewardSecurity,
  ...props
}) => {
  const submission = (values, dispatch) => {
    return dispatch(updatePersonalDetailsRequest(values.toJS()));
  };

  const capitalizedGenderValues = genderValues.map(gender => humanize(gender));

  function renderNameFields() {
    if (nonStewardSecurity && checkSiaNumber) {
      return (
        <Fragment>
          <Field label="First Name" name={'first_name'} component={NonEditableSIAField} />
          <Field label="Surname" name={'surname'} component={NonEditableSIAField} />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Field component={BossFormInput} required name="first_name" type="text" label="First Name" />
        <Field component={BossFormInput} required name="surname" type="text" label="Surname" />
      </Fragment>
    );
  }

  return (
    <form onSubmit={handleSubmit(submission)}>
      {error && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error.join(', ')}</span>
          </p>
        </div>
      )}
      <header className="boss-content-switcher__header">
        <h2 className="boss-content-switcher__title">Personal Details</h2>
      </header>
      {renderNameFields()}
      <Field
        component={BossFormSelect}
        name="gender"
        required
        label="Gender"
        placeholder="Select gender ..."
        options={capitalizedGenderValues.toJS()}
      />
      <Field name="date_of_birth" component={BossFormCalendar} label="Date of birth" required />

      <div className="boss-form__field boss-form__field_justify_end">
        <button
          className="boss-button boss-form__submit boss-form__submit_adjust_single"
          type="submit"
          disabled={submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'personal-details-form',
  validate,
})(PersonalDetailsForm);
