import React from 'react';
import oFetch from 'o-fetch';
import utils from "@/lib/utils";
import * as moment from 'moment';

export default function HistoryList(props) {
  function renderHistoryItem(items) {
    const itemRenderer = oFetch(props, 'itemRenderer');
    return items.map((item, index) => {
      const jsItem = item.toJS();
      return React.cloneElement(itemRenderer(jsItem), {
        key: index.toString(),
      });
    });
  }

  const [
    items, mStaffMemberCreatedAt, mBeganTracking, loadMorePending, createdBy
  ] = oFetch(props, 'items', 'mStaffMemberCreatedAt', 'mBeganTracking', 'loadMorePending', 'createdBy');

  let createdAfterTrackingBegan = moment.max(mBeganTracking, mStaffMemberCreatedAt) == mStaffMemberCreatedAt;


  return (
    <div className="boss-timeline boss-timeline_role_payments">
      <ul className="boss-timeline__list">
        {renderHistoryItem(items)}
        {(mBeganTracking != null) && !loadMorePending && !createdAfterTrackingBegan && (
          <li className="boss-timeline__item boss-timeline__item_role_card boss-timeline__item_role_initial">
            <div className="boss-timeline__inner boss-timeline__inner_role_card boss-timeline__inner_role_initial">
              <div className="boss-timeline__header boss-timeline__header_role_card">
                <h3 className="boss-timeline__title">
                  <span className="boss-timeline__title-light">{mBeganTracking.format(utils.humanDateFormatWithTime())}</span>
                </h3>
              </div>
              <div className="boss-timeline__group boss-timeline__group_role_card">
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-marked">Began tracking changes</span>
                </p>
              </div>
            </div>
          </li>
        )}
        {(mBeganTracking != null) && !loadMorePending && createdAfterTrackingBegan && (
          <li className="boss-timeline__item boss-timeline__item_role_card boss-timeline__item_role_initial">
            <div className="boss-timeline__inner boss-timeline__inner_role_card boss-timeline__inner_role_initial">
              <div className="boss-timeline__header boss-timeline__header_role_card">
                <h3 className="boss-timeline__title">
                  <span className="boss-timeline__title-light">{mStaffMemberCreatedAt.format(utils.humanDateFormatWithTime())}</span>
                </h3>
              </div>
              <div className="boss-timeline__group boss-timeline__group_role_card">
                <p className="boss-timeline__text">
                <span className="boss-timeline__text-faded">Created By</span>
                  <span className="boss-timeline__text-marked"> {createdBy}</span>
                </p>
              </div>
            </div>
          </li>
        )}
        {(mBeganTracking == null) && !loadMorePending && (
          <li className="boss-timeline__item boss-timeline__item_role_card boss-timeline__item_role_initial">
            <div className="boss-timeline__inner boss-timeline__inner_role_card boss-timeline__inner_role_initial">
              <div className="boss-timeline__header boss-timeline__header_role_card">
                <h3 className="boss-timeline__title">
                  <span className="boss-timeline__title-light" />
                </h3>
              </div>
              <div className="boss-timeline__group boss-timeline__group_role_card">
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-marked">Started tracking changes: Unknown</span>
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
