import React from 'react';

import RotaDailyDashboard from '../components/rota-daily-dashboard';
import RotaDailyContent from '../components/rota-daily-content';
import RotaDailyMobileFilter from '../components/rota-daily-mobile-filter';

function RotaDaily() {
  return (
    <>
      <RotaDailyMobileFilter />
      <RotaDailyDashboard />
      <RotaDailyContent />
    </>
  );
}

export default RotaDaily;
