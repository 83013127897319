import React, { useEffect } from 'react';
import Modal from 'react-modal';
import oFetch from 'o-fetch';

import { ReviewList, ReviewItem } from './review';

export function FlaggedModal(props) {
  const [
    isOpened,
    notReviewedStaffMembers,
    reviewedStaffMembers,
    onReview,
    onUndo,
    onClose,
  ] = oFetch(
    props,
    'isOpened',
    'notReviewedStaffMembers',
    'reviewedStaffMembers',
    'onReview',
    'onUndo',
    'onClose',
  );

  useEffect(() => {
    if (isOpened && notReviewedStaffMembers.length === 0) {
      onClose();
    }
  }, [notReviewedStaffMembers]);

  return (
    <Modal
      isOpen={isOpened}
      className="boss-modal-window boss-modal-window_role_staff-members-review"
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <div className="boss-modal-window__header">Review Staff Members</div>
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__vetting">
          <ReviewList
            className="boss-vetting__review"
            staffMembers={notReviewedStaffMembers}
            itemRenderer={staffMember => (
              <ReviewItem onButtonClick={onReview} staffMember={staffMember} />
            )}
          />
          <ReviewList
            className="boss-vetting__review"
            staffMembers={reviewedStaffMembers}
            itemRenderer={staffMember => (
              <ReviewItem onButtonClick={onUndo} staffMember={staffMember} />
            )}
          />
        </div>
      </div>
    </Modal>
  );
}
