import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const otherQuizCategories = oFetch(action.payload, 'otherQuizCategories');
      return otherQuizCategories;
    },
  },
  initialState,
);
