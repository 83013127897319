import React from 'react';
import oFetch from 'o-fetch';
export function AcceptedTaskList(props) {
  const [tasks, renderTask] = oFetch(props, 'tasks', 'renderTask');

  function renderTasks() {
    return tasks.map(task => {
      const taskId = oFetch(task, 'id');

      return React.cloneElement(renderTask(task), {
        key: taskId,
      });
    });
  }

  return <>{renderTasks()}</>;
}
