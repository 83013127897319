import React from 'react';
import Modal from 'react-modal';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

import modalDecorator from './modal-decorator';

export const MODAL_TYPE1 = 'MODAL_TYPE1';
export const MODAL_TYPE2 = 'MODAL_TYPE2';

const MODAL_TYPE_CLASSNAMES = {
  [MODAL_TYPE1]: 'boss-modal-window_role_edit',
  [MODAL_TYPE2]: 'boss-modal-window_role_confirm',
};

const MODAL_CLOSE_TYPE_CLASSNAMES = {
  [MODAL_TYPE1]: 'boss-modal-window__close-inner',
  [MODAL_TYPE2]: 'boss-modal-window__close',
};

class ContentModal extends React.Component {
  render() {
    const { show, children, title, modalClassName, onClose, type } = this.props;
    return (
      <Modal
        isOpen={show}
        contentLabel="Modal"
        ariaHideApp={false}
        className={`ReactModal__Content ReactModal__Content--after-open boss-modal-window ${
          modalClassName === undefined ? MODAL_TYPE_CLASSNAMES[type || MODAL_TYPE1] : modalClassName
        }`}
      >
        <button onClick={onClose} className={MODAL_CLOSE_TYPE_CLASSNAMES[type]} />
        <div className="boss-modal-window__header">{title}</div>
        <div className="boss-modal-window__content">{children}</div>
      </Modal>
    );
  }
}

function openContentModal(
  { title = 'Content Modal', type = MODAL_TYPE1, modalClassName },
  props = {},
  onSubmit,
  onClose,
  rootWrapper,
  closeCallback,
) {
  const whenCloseClicked = () => {
    closeCallback();
    onClose();
  };

  return function (Component) {
    rootWrapper.render(
      <ReactErrorsBoundaryWrapper>
        <ContentModal
          show={true}
          type={type}
          title={title}
          onClose={whenCloseClicked}
          modalClassName={modalClassName}
        >
          <Component onSubmit={onSubmit} onClose={whenCloseClicked} {...props} />
        </ContentModal>
      </ReactErrorsBoundaryWrapper>,
    );
  };
}

export default modalDecorator(openContentModal);
