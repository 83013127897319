import oFetch from 'o-fetch';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import useModal from './use-modal';
import { WarningModal } from './contents';

export function useConfirmationModal() {
  const { openPromiseModal } = useModal();

  function openWarningModal(params) {
    const [onSubmit, config, props] = oFetch(params, 'onSubmit', 'config', 'props');
    return openPromiseModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: WarningModal,
      onSubmit: onSubmit,
      props: props,
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_danger',
        ...config,
      },
    });
  }

  return {
    openWarningModal,
  };
}
