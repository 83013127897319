import React from 'react';

import EnableProfileForm from './enable-profile-form';
import oFetch from 'o-fetch';

const EnableProfilePage = ({ mossStaffMember, accessibleVenues, mossStaffTypes, validateAvatarForCreateUrl, mossPayRates }) => {
  const initialValues = {
    mossStaffMemberId: oFetch(mossStaffMember, 'id'),
    firstName: oFetch(mossStaffMember, 'first_name'),
    surname: oFetch(mossStaffMember, 'surname'),
    avatar: '',
    validatedAvatarGUID: '',
    setupForFacialRecognition: false,
    mainVenue: oFetch(mossStaffMember, 'master_venue'),
    mossStaffType: oFetch(mossStaffMember, 'moss_staff_type'),
    mossPayRate: null,
    pinCode: '',
  };
  return (
    <EnableProfileForm
      initialValues={initialValues}
      mossStaffMember={mossStaffMember}
      mossStaffTypes={mossStaffTypes}
      mossPayRates={mossPayRates}
      venues={accessibleVenues}
      validateAvatarForCreateUrl={validateAvatarForCreateUrl}
    />
  );
};

export default EnableProfilePage;
