import * as c from '../../constants';
import oFetch from 'o-fetch';

export const basicInformationFormValidators = (values, props) => {
  const errors = {};
  const linkedMossStaffMemberId = oFetch(values, 'linkedMossStaffMemberId');
  const linkMossStaffMemberSelected = linkedMossStaffMemberId !== null;

  if (oFetch(props, 'isSecurity')) {
    if (!oFetch(props, 'isSteward')) {
      if (!values[c.SIA_BADGE_NUMBER_FIELD_NAME]) {
        errors[c.SIA_BADGE_NUMBER_FIELD_NAME] = ['This is a required field!'];
      }
      if (!values[c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME]) {
        errors[c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME] = ['This is a required field!'];
      }
    }
  }
  if (!linkMossStaffMemberSelected) {
    if (!values[c.FIRST_NAME_FIELD_NAME]) {
      errors[c.FIRST_NAME_FIELD_NAME] = ['This is a required field!'];
    }
    if (!values[c.SURNAME_FIELD_NAME]) {
      errors[c.SURNAME_FIELD_NAME] = ['This is a required field!'];
    }
    if (!values[c.VALIDATED_AVATAR_GUID_FIELD_NAME]) {
      errors[c.VALIDATED_AVATAR_GUID_FIELD_NAME] = ['This is a required field!'];
    }
  }

  if (!values[c.GENDER_FIELD_NAME]) {
    errors[c.GENDER_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.DATE_OF_BIRTH_FIELD_NAME]) {
    errors[c.DATE_OF_BIRTH_FIELD_NAME] = ['This is a required field!'];
  }
  if (!linkMossStaffMemberSelected) {
    if (!values[c.VALIDATED_AVATAR_GUID_FIELD_NAME]) {
      errors[c.VALIDATED_AVATAR_GUID_FIELD_NAME] = ['This is a required field!'];
    }
  }
  if (!values[c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME]) {
    errors[c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.EMAIL_FIELD_NAME]) {
    errors[c.EMAIL_FIELD_NAME] = ['This is a required field!'];
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[c.EMAIL_FIELD_NAME])) {
    errors[c.EMAIL_FIELD_NAME] = 'It should be a correct email address!';
  }
  if (!values[c.ADDRESS_FIELD_NAME]) {
    errors[c.ADDRESS_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.COUNTRY_FIELD_NAME]) {
    errors[c.COUNTRY_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.COUNTY_FIELD_NAME]) {
    errors[c.COUNTY_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.POST_CODE_FIELD_NAME]) {
    errors[c.POST_CODE_FIELD_NAME] = ['This is a required field!'];
  }
  if (!values[c.PHONE_NUMBER_FIELD_NAME]) {
    errors[c.PHONE_NUMBER_FIELD_NAME] = ['This is a required field!'];
  } else if (!/[0-9]+/i.test(values[c.PHONE_NUMBER_FIELD_NAME])) {
    errors[c.PHONE_NUMBER_FIELD_NAME] = 'It should be a correct phone number';
  }
  return errors;
};

export const workFormValidators = (values, props) => {
  const errors = {};
  const linkedMossStaffMemberId = oFetch(values, 'linkedMossStaffMemberId');
  const linkMossStaffMemberSelected = linkedMossStaffMemberId !== null;

  if (!linkMossStaffMemberSelected) {
    if (!values[c.PIN_CODE_FIELD_NAME]) {
      errors[c.PIN_CODE_FIELD_NAME] = ['This is a required field!'];
    } else if (!/^[0-9]+$/i.test(values[c.PIN_CODE_FIELD_NAME])) {
      errors[c.PIN_CODE_FIELD_NAME] = 'Pin code must be numbers only';
    }
  }
  if (oFetch(props, 'isSecurity')) {
    if (oFetch(props, 'isSteward')) {
      if (!values[c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]) {
        errors[c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME] = ['This is a required field!'];
      }
    } else {
      if (!values[c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]) {
        errors[c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME] = ['This is a required field!'];
      }
    }
  } else {
    if (!values[c.PAY_RATE_FIELD_NAME]) {
      errors[c.PAY_RATE_FIELD_NAME] = ['This is a required field!'];
    }
  }
  if (!values[c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME]) {
    errors[c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME] = ['This is a required field!'];
  }
  return errors;
};

export const venueFormValidators = (values, props) => {
  const errors = {};
  if (!oFetch(props, 'isSecurity')) {
    if (!values[c.MASTER_VENUE_FIELD_NAME]) {
      errors[c.MASTER_VENUE_FIELD_NAME] = ['This is a required field!'];
    }
  }
  if (!values[c.STARTS_AT_FIELD_NAME]) {
    errors[c.STARTS_AT_FIELD_NAME] = ['This is a required field!'];
  }
  return errors;
};

export const previewFormValidation = (values, props) => {
  return {
    ...basicInformationFormValidators(values, props),
    ...workFormValidators(values, props),
    ...venueFormValidators(values, props),
  };
};
