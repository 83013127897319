import React from 'react';
import PropTypes from 'prop-types';
import { ModalsProvider } from '@/components/hooks-components/modals';
import PayRates from './pay-rates';

PayRatesApp.propTypes = {
  accessToken: PropTypes.string,
};

export function PayRatesApp(props) {
  const { accessToken, ...otherProps } = props;

  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <PayRates {...otherProps} />
    </ModalsProvider>
  );
}
