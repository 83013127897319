import React from 'react';
import oFetch from 'o-fetch';

export function KpiDataWrapper(props) {
  const [dataTimeType, itemRenderer, data] = oFetch(props, 'dataTimeType', 'itemRenderer', 'data');

  function getTitle() {
    if (dataTimeType === 'weekly') {
      return 'Weekly';
    }
    if (dataTimeType === 'monthly') {
      return 'Monthly';
    }
    throw new Error(`Wrong time type, expect: weekly, monthly. Got: ${dataTimeType}`);
  }

  function renderItems() {
    return data.map((kpiItem, index) => {
      return React.cloneElement(itemRenderer(kpiItem), {
        key: index,
      });
    });
  }

  return (
    <div className="boss-board__main-group">
      <div className="boss-kpi js-kpi">
        <div className="boss-kpi__data">
          <div className="boss-kpi__data-title">{getTitle()}</div>
          <div className="boss-kpi__data-list">{renderItems()}</div>
        </div>
      </div>
    </div>
  );
}
