import { connect } from 'react-redux';
import { VenueAlertLevelsPage } from '../components';
import {
  activeFilterSelector,
  redAlertsSelector,
  amberAlertsSelector,
  levelTypesSelector,
  redLevelTypeSelector,
  amberLevelTypeSelector,
  currentLevelSelector,
} from '../selectors';
import { changeFilterAction } from '../redux/actions';

const mapStateToProps = state => {
  return {
    activeFilter: activeFilterSelector(state),
    redAlerts: redAlertsSelector(state),
    amberAlerts: amberAlertsSelector(state),
    levelTypes: levelTypesSelector(state),
    redLevelType: redLevelTypeSelector(state),
    amberLevelType: amberLevelTypeSelector(state),
    currentLevel: currentLevelSelector(state),
  };
};

const mapDispatchToProps = {
  changeFilterAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueAlertLevelsPage);
