import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';

import safeMoment from '@/lib/safe-moment';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import TaskNote from './task-note';
import ImageGallery from './image-gallery';

export default function TaskModal(props) {
  const [saveMode, setSaveMode] = useState(false);
  const [status, setStatus] = useState(props.selectedMaintenanceTask.status);
  const [previousStatus, setPreviousStatus] = useState(props.selectedMaintenanceTask.status);
  const [republishProcessing, setRepublishProcessing] = useState(false);
  const [updateText, setUpdateText] = useState('Update');
  const emergencyRepublishPeriodSeconds = oFetch(useLegacyGlobal(), 'emergencyRepublishPeriodSeconds');
  const [republishSecondsLeft, setRepublishSecondsLeft] = useState(emergencyRepublishPeriodSeconds);
  const [disableRepublishButton, setDisableRepublishButton] = useState(true);
  const selectedMaintenanceTask = oFetch(props, 'selectedMaintenanceTask');

  useEffect(() => {
    if (isEmergency && task.lastWhatsappMessageSendAt) {
      const mLastWhatsappMessageSendAt = safeMoment.iso8601Parse(task.lastWhatsappMessageSendAt);

      const timeoutId = setInterval(() => {
        const current = moment();
        const secondsLeft = emergencyRepublishPeriodSeconds - (current - mLastWhatsappMessageSendAt) / 1000;
        setRepublishSecondsLeft(secondsLeft);
        if (secondsLeft <= 0) {
          setDisableRepublishButton(false);
        }
      }, 1000);

      return () => {
        clearInterval(timeoutId);
      };
    }
  }, []);

  async function handleRepublishMessage() {
    const republishMessage = oFetch(props, 'republishMessage');
    setRepublishProcessing(true);
    await republishMessage(selectedMaintenanceTask);
    setRepublishProcessing(false);
  }

  function renderTransitions() {
    const sortedTaskTransistions = oFetch(selectedMaintenanceTask, 'maintenanceTaskTransitions').sort(
      (a, b) => {
        const createdAtA = oFetch(a, 'createdAt');
        const createdAtB = oFetch(b, 'createdAt');

        if (createdAtA < createdAtB) {
          return 1;
        } else if (createdAtA > createdAtB) {
          return -1;
        } else {
          return 0;
        }
      },
    );

    return sortedTaskTransistions.map(transition => {
      return (
        <li
          className={`boss-overview__activity-item boss-overview__activity-item_role_${transition.toState}`}
          key={transition.id}
        >
          <p className="boss-overview__meta">
            <span className="boss-overview__meta-label">{stateActionLabel(transition.toState)} by </span>
            <span className="boss-overview__meta-user"> {transition.requesterUser.name} </span>
            <span className="boss-overview__meta-date">
              {' '}
              {safeMoment
                .iso8601Parse(oFetch(transition, 'createdAt'))
                .format(oFetch(utils, 'humanDateFormatWithTime')())}
            </span>
          </p>
        </li>
      );
    });
  }

  function stateActionLabel(state) {
    const stateMessages = {
      accepted: 'Accepted',
      completed: 'Completed',
      rejected: 'Rejected',
    };

    return oFetch(stateMessages, state);
  }

  function onClose() {
    props.setFrontendState({ showModal: false });
    props.setCurrentMaintenanceTask(null);
  }

  function handleStatusChange(object) {
    setStatus(object.value);
  }

  function renderOption(option) {
    return (
      <span>
        <span className={`Select-color-indicator Select-color-indicator_status_${option.label}`} />{' '}
        {option.label}
      </span>
    );
  }

  function statusOptions() {
    const allowedTransitions = _.clone(props.selectedMaintenanceTask.allowedTransitions);

    allowedTransitions.unshift(props.selectedMaintenanceTask.status);

    return _.uniq(allowedTransitions).map(status => {
      return {
        label: status,
        value: status,
        className: 'Select-value_status_' + status,
        optionClassName: '',
      };
    });
  }

  function renderCurrentStatus() {
    if (saveMode) {
      return (
        <form action="#" className="boss-form">
          <div className="boss-form__field boss-form__field_layout_fluid">
            <div className="boss-form__select boss-form__select_size_small-fluid">
              <Select
                name="status"
                onChange={handleStatusChange}
                options={statusOptions()}
                placeholder="Select Status"
                value={status}
                searchable={false}
                clearable={false}
                optionRenderer={renderOption}
                valueRenderer={renderOption}
              />
            </div>
          </div>
        </form>
      );
    } else {
      return renderStatusBadge();
    }
  }

  function renderStatusBadge() {
    const status = props.selectedMaintenanceTask.status;

    if (status === 'pending') {
      return (
        <p className="boss-button boss-button_type_small boss-button_role_pending boss-button_type_no-behavior">
          Pending
        </p>
      );
    } else if (status === 'rejected') {
      return (
        <p className="boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_alert">
          Rejected
        </p>
      );
    } else if (status === 'completed') {
      return (
        <p className="boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_ok ">
          Completed
        </p>
      );
    } else if (status === 'accepted') {
      return (
        <p className="boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_ok">
          Accepted
        </p>
      );
    }
  }

  function renderStatusButton() {
    if (props.selectedMaintenanceTask.allowedTransitions.length < 1) {
      return;
    }

    if (saveMode) {
      return (
        <span
          className="boss-overview__link boss-overview__link_role_save boss-overview__link_position_after"
          onClick={handleStatusUpdate}
        >
          Save
        </span>
      );
    } else {
      return (
        <span
          className="boss-overview__link boss-overview__link_role_edit boss-overview__link_position_after"
          onClick={handleStatusButtonChange}
        >
          {updateText}
        </span>
      );
    }
  }

  function handleStatusButtonChange() {
    setSaveMode(!saveMode);
  }

  function handleStatusUpdate() {
    setUpdateText('Updating...');

    if (props.selectedMaintenanceTask.status !== status) {
      props.selectedMaintenanceTask.status = status;

      props.changeStatus(props.selectedMaintenanceTask).then(argument => {
        setSaveMode(!saveMode);
        setUpdateText('Update');

        if (argument.error) {
          props.setFrontendState({
            showErrorBox: true,
            errorMessage: `Invalid transition`,
          });
          props.selectedMaintenanceTask.status = previousStatus;
          setStatus(previousStatus);
        }

        props.setFrontendState({ showModal: false });
      });
    } else {
      setSaveMode(!saveMode);
      setUpdateText('Update');
    }
  }

  function renderStatusUpdate() {
    return (
      <div className="boss-overview__group">
        <h4 className="boss-overview__label">
          <span className="boss-overview__label-text">Current Status</span>

          {renderStatusButton()}
        </h4>

        <div className="boss-overview__status">{renderCurrentStatus()}</div>
      </div>
    );
  }

  function handleButtonClick(e) {
    e.preventDefault();

    $(e.target)
      .closest('.boss-overview__dropdown')
      .each(function () {
        const dropdownAction = $(this).find('.boss-overview__dropdown-switch');
        const dropdownActionText = $(this).find('.boss-overview__dropdown-switch-text');
        const dropdownContent = $(this).find('.boss-overview__dropdown-content');
        const text = dropdownActionText.text();

        if (text === 'Show Notes' || text === 'Hide Notes') {
          dropdownActionText.text(text === 'Show Notes' ? 'Hide Notes' : 'Show Notes');
        } else if (text === 'Show Activity' || text === 'Hide Activity') {
          dropdownActionText.text(text === 'Show Activity' ? 'Hide Activity' : 'Show Activity');
        }

        dropdownAction.toggleClass('boss-overview__dropdown-switch_state_closed');
        dropdownContent.slideToggle().toggleClass('boss-overview__dropdown-content_state_closed');
      });
  }

  function handleEditClick() {
    onClose();
    props.setFrontendState({ showNewTaskModal: true });
    props.setCurrentMaintenanceTask(props.selectedMaintenanceTask);
  }

  function userHasElevatedAccess() {
    const currentUser = oFetch(props, 'currentUser');
    return !(oFetch(currentUser, 'role') === 'maintenance_staff');
  }

  const task = props.selectedMaintenanceTask;
  const priority = task.priority.split('_')[0];
  const isEmergency = priority === 'emergency';
  const isCompleted = status === 'completed';

  return (
    <Modal
      isOpen={props.frontend.showModal}
      className="boss-modal-window boss-modal-window_role_task-overview"
      ariaHideApp={false}
      onRequestClose={onClose}
      contentLabel="ReactModalPortal"
    >
      <button type="button" className="boss-modal-window__close-inner" onClick={onClose} />

      <div
        className="boss-modal-window__header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {task.title}
          {userHasElevatedAccess() && (
            <button
              onClick={handleEditClick}
              className="boss-modal-window__action boss-modal-window__action_role_edit boss-modal-window__action_position_after"
            >
              Edit
            </button>
          )}
        </div>
        {isEmergency && !isCompleted && !task.lastWhatsappMessageSendAt && (
          <button
            onClick={handleRepublishMessage}
            disabled={republishProcessing || disableRepublishButton}
            className="boss-button boss-button_type_small boss-button_role_ok"
            style={{ marginRight: '10px' }}
          >
            {republishProcessing ? 'Processing ...' : 'Republish Message'}
          </button>
        )}
        {isEmergency && !isCompleted && task.lastWhatsappMessageSendAt && (
          <Tooltip
            arrow
            theme="dark"
            position="bottom"
            html={
              <span>
                Can be republished in{' '}
                {moment.duration(republishSecondsLeft * 1000).format('m [minutes] s [seconds]')}
              </span>
            }
          >
            <button
              onClick={handleRepublishMessage}
              disabled={republishProcessing || disableRepublishButton}
              className="boss-button boss-button_type_small boss-button_role_ok"
              style={{ marginRight: '10px' }}
            >
              {republishProcessing ? 'Processing ...' : 'Republish Message'}
            </button>
          </Tooltip>
        )}
      </div>

      <div className="boss-modal-window__content">
        <div className="boss-modal-window__overview">
          <div className="boss-overview">
            {isEmergency && task.lastWhatsappMessageSendAt && (
              <div className="boss-overview__group">
                <h4 className="boss-overview__label">
                  <span className="boss-overview__label-text">Last whatsapp message</span>
                </h4>

                <p className="boss-overview__text">
                  Send At:{' '}
                  {safeMoment
                    .iso8601Parse(task.lastWhatsappMessageSendAt)
                    .format(utils.humanDateFormatWithTime())}
                </p>
              </div>
            )}

            <div className="boss-overview__group">
              <h4 className="boss-overview__label">
                <span className="boss-overview__label-text">Priority</span>
              </h4>

              <p
                className={`boss-overview__indicator boss-overview__indicator_priority_${
                  isEmergency ? 'high' : priority
                }`}
              >
                {priority}
              </p>
            </div>

            <div className="boss-overview__group">
              <h4 className="boss-overview__label">
                <span className="boss-overview__label-text">Description</span>
              </h4>

              <p className="boss-overview__text">{task.description}</p>
            </div>

            <ImageGallery {...props} />

            {renderStatusUpdate()}

            <div className="boss-overview__dropdown boss-overview__dropdown_context_stack boss-overview__dropdown_active-mobile">
              <div className="boss-overview__dropdown-header">
                <button
                  className="boss-overview__dropdown-switch boss-overview__dropdown-switch_role_activity boss-overview__dropdown-switch_state_closed"
                  onClick={handleButtonClick}
                >
                  <span className="boss-overview__dropdown-switch-text">Show Activity</span>
                </button>
              </div>
              <div className="boss-overview__dropdown-content boss-overview__dropdown-content_state_closed">
                <div className="boss-overview__group">
                  <h4 className="boss-overview__label boss-overview__label_state_hidden-mobile">
                    <span className="boss-overview__label-text">Activity</span>
                  </h4>

                  <ul className="boss-overview__activity">
                    {renderTransitions()}

                    <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
                      <p className="boss-overview__meta">
                        <span className="boss-overview__meta-label">Created by </span>
                        <span className="boss-overview__meta-user">{task.creatorUser.name} </span>
                        <span className="boss-overview__meta-date">
                          {moment(task.createdAt).format(utils.humanDateFormatWithTime())}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <TaskNote {...props} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
