import React, { Component } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';

import { SimpleDashboard, DashboardActions } from '@/components/boss-dashboards';
import utils from '@/lib/utils';
import ContentWrapper from '@/components/content-wrapper';
import { openContentModal } from '@/components/modals';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';
import { Calendar, CalendarBlankItem, CalendarDayItem, CalendarToolbar, AddEvent, AddReminder } from '../components';

export class HandoverPlanners extends Component {
  handleCreateEvent = (closeModal, values) => {
    const handleCreateEvent = oFetch(this.props, 'handleCreateEvent');
    return handleCreateEvent(values).then(closeModal);
  };

  handleCreateReminder = (closeModal, values) => {
    values = {
      responseDateRangeEnd: oFetch(this.props, 'viewEndDate'),
      responseDateRangeStart: oFetch(this.props, 'viewStartDate'),
      ...values,
    };
    const handleCreateReminder = oFetch(this.props, 'handleCreateReminder');
    return handleCreateReminder(values).then(closeModal);
  };

  openCreateReminderModal = () => {
    const recurrenceTypesOptions = oFetch(this.props, 'recurrenceTypesOptions');
    openContentModal({
      submit: this.handleCreateReminder,
      config: { title: 'Create Reminder' },
      props: { currentDate: null, recurrenceTypesOptions, showDateField: true },
    })(AddReminder);
  };

  openCreateEventModal = () => {
    const [eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType] = oFetch(
      this.props,
      'eventTypesOptions',
      'venueOptions',
      'currentVenue',
      'globalEventType',
      'localEventType',
    );
    openContentModal({
      submit: this.handleCreateEvent,
      config: { title: 'Create Event' },
      props: { eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType },
    })(AddEvent);
  };

  renderDay = day => {
    const [eventsCountByDate, remindersCountDataByDate, tasksCountDataByDate, currentVenueId] = oFetch(
      this.props,
      'eventsCountByDate',
      'remindersCountDataByDate',
      'tasksCountDataByDate',
      'currentVenue.id',
    );

    const formattedDay = day.format(utils.commonDateFormat);
    const formattedCurrentDay = moment().format(utils.commonDateFormat);
    const isCurrentDay = formattedCurrentDay === formattedDay;
    const eventsCount = eventsCountByDate[formattedDay] || 0;
    const remindersCountData = remindersCountDataByDate[formattedDay] || { total: 0 };
    const tasksCountData = tasksCountDataByDate[formattedDay] || { total: 0 };

    const href = appRoutes.handoverPlannersWeekView({ mDate: day, venueId: currentVenueId });

    return (
      <CalendarDayItem
        day={day}
        isCurrentDay={isCurrentDay}
        eventsCount={eventsCount}
        remindersCountData={remindersCountData}
        tasksCountData={tasksCountData}
        href={href}
      />
    );
  };

  renderToolbar = props => {
    const [viewStartDate, venueId] = oFetch(this.props, 'viewStartDate', 'currentVenue.id');
    const mViewStartDate = safeMoment.uiDateParse(viewStartDate);
    const rotaDate = RotaDate.fromTime({
      dTime: new Date(),
      appType: BOSS_APP_TYPE,
    });
    const mTodayDate = moment(rotaDate.calendarDate());

    return (
      <CalendarToolbar
        prevHref={appRoutes.handoverPlannersMonthPrev({ mDate: mViewStartDate, venueId })}
        nextHref={appRoutes.handoverPlannersMonthNext({ mDate: mViewStartDate, venueId })}
        todayHref={appRoutes.handoverPlannersWeekView({ mDate: mTodayDate, venueId })}
        weekHref={appRoutes.handoverPlannersWeekView({ mDate: mViewStartDate, venueId })}
        {...props}
        mTodayDate={mTodayDate}
      />
    );
  };

  render() {
    const [viewStartDate, permissions] = oFetch(this.props, 'viewStartDate', 'permissions');
    const mViewStartDate = safeMoment.uiDateParse(viewStartDate);
    const [canCreateEvent, canCreateReminder] = oFetch(permissions, 'event.canCreate', 'reminder.canCreate');
    return (
      <div>
        <SimpleDashboard className="boss-page-dashboard_page_handover-planner" title="Handover & Planner">
          <DashboardActions>
            {canCreateEvent && (
              <button onClick={this.openCreateEventModal} className="boss-button boss-button_role_add">
                Add Event
              </button>
            )}
            {canCreateReminder && (
              <button onClick={this.openCreateReminderModal} className="boss-button boss-button_role_add">
                Add Reminder
              </button>
            )}
          </DashboardActions>
        </SimpleDashboard>
        <ContentWrapper>
          <div className="boss-page-main__group boss-page-main__group_adjust_hp-calendar boss-page-main__group_context_stack">
            <Calendar
              date={mViewStartDate}
              toolbarRenderer={this.renderToolbar}
              dayRenderer={this.renderDay}
              blankRenderer={day => <CalendarBlankItem day={day} />}
              defaultView="month"
            />
          </div>
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  eventTypesOptions: selectors.getEventTypesOptions(state),
  venueOptions: selectors.venuesSelector(state),
  currentVenue: selectors.currentVenueSelector(state),
  globalEventType: selectors.globalEventTypeSelector(state),
  localEventType: selectors.localEventTypeSelector(state),
  eventsCountByDate: selectors.getEventsCount(state),
  remindersCountDataByDate: selectors.getRemindersCountDataByDate(state),
  tasksCountDataByDate: selectors.getTasksCountDataByDate(state),
  permissions: selectors.permissionsSelector(state),
  viewStartDate: selectors.viewStartDateSelector(state),
  viewEndDate: selectors.viewEndDateSelector(state),
  singleRecurringType: selectors.singleRecurringTypeSelector(state),
  recurrenceTypesOptions: selectors.getRecurrenceTypesOptions(state),
});

const mapDispatchToProps = {
  handleCreateEvent: actions.createEvent,
  handleCreateReminder: actions.createReminder,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandoverPlanners);
