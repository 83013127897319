import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import {
  mossStaffMemberOwedHours as importedMossStaffMemberOwedHours,
  mossStaffMemberHoursOverview as importedMossStaffMemberHoursOverview,
  mossStaffMemberProfile as importedMossStaffMemberProfile,
} from '@/lib/app-routes';
const appRoutes = {
  mossStaffMemberOwedHours: importedMossStaffMemberOwedHours,
  mossStaffMemberHoursOverview: importedMossStaffMemberHoursOverview,
  mossStaffMemberProfile: importedMossStaffMemberProfile,
} as const;
import { Tooltip } from 'react-tippy';
import utils from '@/lib/utils';

import { StatusColumn } from './status_column';
import { MossFinanceReport } from '../types';
import { UI_DATE_FORMAT } from '@/lib/date-fns-formats';
import { format } from 'date-fns';
import { minutesToFormattedUiHours } from '@/lib/hours-helpers';
import { formatAsCurrency } from '@/lib/currency-helpers';

const cellStyle: CSSProperties = { flexDirection: 'row', alignItems: 'center' } as const;

type MossFinanceReportItemProps = {
  key: string,
  startDate: Date,
  endDate: Date,
  weekDates: Date[],
  mossFinanceReport: MossFinanceReport,
  // validationErrors={currentReportValidationErrors}
  // onOpenPayslip={openPayslipModal}
  onNoteClick: (args: { mossFinanceReport: MossFinanceReport, edit: boolean }) => void,
};

export function MossFinanceReportItem(props: MossFinanceReportItemProps) {
  const mossFinanceReport = props.mossFinanceReport;

  function renderWeekDaysCells() {
    const mondayHoursCount = utils.round(mossFinanceReport.mondayHoursCount, 2);
    const tuesdayHoursCount = utils.round(mossFinanceReport.tuesdayHoursCount, 2);
    const wednesdayHoursCount = utils.round(mossFinanceReport.wednesdayHoursCount, 2);
    const thursdayHoursCount = utils.round(mossFinanceReport.thursdayHoursCount, 2);
    const fridayHoursCount = utils.round(mossFinanceReport.fridayHoursCount, 2);
    const saturdayHoursCount = utils.round(mossFinanceReport.saturdayHoursCount, 2);
    const sundayHoursCount = utils.round(mossFinanceReport.sundayHoursCount, 2);

    const mondayHasDeletedHours = mossFinanceReport.mondayHasDeletedHours;
    const tuesdayHasDeletedHours = mossFinanceReport.tuesdayHasDeletedHours;
    const wednesdayHasDeletedHours = mossFinanceReport.wednesdayHasDeletedHours;
    const thursdayHasDeletedHours = mossFinanceReport.thursdayHasDeletedHours;
    const fridayHasDeletedHours = mossFinanceReport.fridayHasDeletedHours;
    const saturdayHasDeletedHours = mossFinanceReport.saturdayHasDeletedHours;
    const sundayHasDeletedHours = mossFinanceReport.sundayHasDeletedHours;

    const mossStaffMemberId = mossFinanceReport.mossStaffMemberId;
    const weekDates = props.weekDates;
    const hoursPending = mossFinanceReport.hoursPending;
    const daysNeedingCompletion = mossFinanceReport.daysNeedingCompletion;

    return [
      [mondayHoursCount, mondayHasDeletedHours],
      [tuesdayHoursCount, tuesdayHasDeletedHours],
      [wednesdayHoursCount, wednesdayHasDeletedHours],
      [thursdayHoursCount, thursdayHasDeletedHours],
      [fridayHoursCount, fridayHasDeletedHours],
      [saturdayHoursCount, saturdayHasDeletedHours],
      [sundayHoursCount, sundayHasDeletedHours],
    ].map((dayHours, index) => {
      const [dayHoursCount, hasDeletedHours] = dayHours;

      const weekDate = weekDates[index];
      if (!weekDate) {
        throw new Error(`Week date not found at index ${index}`);
      }
      const uiDate = format(weekDate, UI_DATE_FORMAT);
      if (hoursPending === true && daysNeedingCompletion[uiDate]) {
        const tooltipContent = (
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={appRoutes.mossStaffMemberHoursOverview({ mossStaffMemberId, date: weekDate })}
            >
              {(daysNeedingCompletion[uiDate] || []).join(', ')}
            </a>
          </span>
        );
        return (
          <div
            key={index}
            className={getCellClassNames({ alertStyles: true })}
            style={cellStyle}
          >
            <a
              href={appRoutes.mossStaffMemberHoursOverview({ mossStaffMemberId, date: weekDate })}
              className={`${getTextClassNames({ alertStyles: true })} boss-table__link`}
            >
              {dayHoursCount}
            </a>
            { renderQuestionMarkTooltip(tooltipContent) }
          </div>
        );
      } else {
        return (
          <div
            key={index}
            className={getCellClassNames({ alertStyles: false })}
            style={cellStyle}
          >
            <p
              style={{ marginBottom: 0 }}
              className={getTextClassNames({ alertStyles: false })}
            >
              <a
                href={appRoutes.mossStaffMemberHoursOverview({ mossStaffMemberId, date: weekDate })}
                className={`${getTextClassNames({ alertStyles: false })} boss-table__link`}
              >
                {dayHoursCount}
              </a>
            </p>
            {hasDeletedHours && (
              <div className="boss-corner boss-corner_position_bottom-left boss-corner_color_accent-red-saturated">
                <span className="boss-corner__icon boss-corner__icon_trash" />
              </div>
            )}
          </div>
        );
      }
    });
  }

  function getCellClassNames(args: { alertStyles: boolean }) {
    return classNames({
      'boss-table__cell': true,
      'boss-table__cell_state_alert': args.alertStyles,
    });
  }

  function getTextClassNames(args: { alertStyles: boolean }) {
    return classNames({
      'boss-boss-table__text': true,
      'boss-table__text_state_alert': args.alertStyles,
    });
  }

  function renderQuestionMarkTooltip(content: React.ReactElement<any, string>) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={content}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderNoteTooltip(args: { note: string }) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        trigger="click"
        interactive
        html={
          <div className="boss-overview">
            <p
              className="boss-overview__text"
              style={{ whiteSpace: 'pre-line' }}
            >
              {args.note}
            </p>
          </div>
        }
      >
        <span className="boss-table__tooltip">
          <p className="boss-table__text boss-table__text_role_action">
            <span className="boss-indicator boss-table__indicator">
              <span className="boss-indicator__icon boss-indicator__icon_envelope" />
            </span>
          </p>
        </span>
      </Tooltip>
    );
  }

  const mossFinanceReportId = mossFinanceReport.id;
  const onNoteClick = props.onNoteClick;
  const note = mossFinanceReport.note;

  const fullName = mossFinanceReport.mossStaffMemberName;
  const weeklyHours = utils.round(mossFinanceReport.weeklyHoursCount, 2);
  const owedHoursMinuteCount = mossFinanceReport.owedHoursMinuteCount;

  const payRateDescription = mossFinanceReport.mossPayRateDescription;
  const totalHoursCount = utils.round(mossFinanceReport.totalHoursCount, 2);
  const totalCents = mossFinanceReport.calculatedGrossPayCents;

  const mossStaffMemberId = mossFinanceReport.mossStaffMemberId;
  const containsTimeShiftedOwedHours = mossFinanceReport.containsTimeShiftedOwedHours;
  const pendingCalculation = mossFinanceReport.pendingCalculation;
  const mossStaffMemberDisabled = mossFinanceReport.mossStaffMemberDisabled;
  const hoursPending = mossFinanceReport.hoursPending;
  const hasIncompleteHolidays = mossFinanceReport.hasIncompleteHolidays;

  const fullNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': mossStaffMemberDisabled,
  });
  const fullNameCellTextClassName = classNames({
    'boss-table__text': true,
  });
  const optionalRowClassNames = classNames({
    'boss-table__row_state_alert': hoursPending || hasIncompleteHolidays,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });
  const owedHoursClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': owedHoursMinuteCount !== 0 && containsTimeShiftedOwedHours,
  });

  return (
    <div
      key={`mossFinaceReport:${mossFinanceReportId}`}
      className={`boss-table__row ${optionalRowClassNames}`}
    >

      <div className={fullNameCellClassName}>
        <p className={fullNameCellTextClassName}>
          <a
            href={appRoutes.mossStaffMemberProfile({ mossStaffMemberId })}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {fullName}
          </a>
        </p>
      </div>
      {renderWeekDaysCells()}
      <div
        className={getCellClassNames({ alertStyles: false })}
        style={cellStyle}
      >
        <p className={getTextClassNames({ alertStyles: false })}>{weeklyHours}</p>
      </div>
      {owedHoursMinuteCount === 0 ? (
        <div
          className={owedHoursClassName}
          style={cellStyle}
        >
          <p className={getTextClassNames({ alertStyles: false })}>{minutesToFormattedUiHours({ minutes: owedHoursMinuteCount }) }</p>
        </div>
      ) : (
        <div
          className={owedHoursClassName}
          style={cellStyle}
        >
          <a
            href={appRoutes.mossStaffMemberOwedHours({
              mossStaffMemberId,
              dates: null,
              payslipDates: {
                start: props.startDate,
                end: props.endDate,
              },
            })}
            className={`${getTextClassNames({ alertStyles: false })} boss-table__link`}
          >
         {minutesToFormattedUiHours({ minutes: owedHoursMinuteCount })}
          </a>
        </div>
      )}

      <div
        className={getCellClassNames({ alertStyles: false })}
        style={cellStyle}
      >
        <p className={getTextClassNames({ alertStyles: false })}>{payRateDescription}</p>
      </div>
      <div
        className={getCellClassNames({ alertStyles: false })}
        style={cellStyle}
      >
        <p
          className={`${getTextClassNames({
            alertStyles: false,
          })} boss-table__text_role_important`}
        >
          {totalHoursCount}
        </p>
      </div>
      <div
        className={getCellClassNames({ alertStyles: false })}
        style={cellStyle}
      >
        <p
          className={getTextClassNames({ alertStyles: false })}
          style={totalCents < 0 ? { color: 'red' } : {}}
        >
          {formatAsCurrency({ cents: totalCents })}
        </p>
      </div>
      <StatusColumn
        mossFinanceReport={mossFinanceReport}
      />
      <div className="boss-table__cell">
        {note && renderNoteTooltip({ note })}
        <div className="boss-table__actions">
          {note && (
            <button
              onClick={() => onNoteClick({
                mossFinanceReport,
                edit: !!note
              })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Edit Note
            </button>
          )}
          {!note && (
            <button
              onClick={() => onNoteClick({
                mossFinanceReport,
                edit: !!note,
              })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Add Note
            </button>
          )}
        </div>
      </div>
    </div>
  );
}