import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export const brCreateTask = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createHandoverPlannerTaskRoute = apiRoutes.createHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createHandoverPlannerTaskRoute.getPath(), values);
};

export const brDeleteTask = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteHandoverPlannerTaskRoute = apiRoutes.deleteHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteHandoverPlannerTaskRoute.getPath(id));
};

export const brDeclineTask = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const declineReason = oFetch(values, 'declineReason');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const declineHandoverPlannerTaskRoute = apiRoutes.declineHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(declineHandoverPlannerTaskRoute.getPath(id), { declineReason });
};

export const brPostponeTask = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const date = oFetch(values, 'date');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const postponeHandoverPlannerTaskRoute = apiRoutes.postponeHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(postponeHandoverPlannerTaskRoute.getPath(id), { date });
};

export const brEditTask = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const editHandoverPlannerTaskRoute = apiRoutes.editHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(editHandoverPlannerTaskRoute.getPath(id), values);
};

export const brCreateEvent = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createHandoverPlannerEventRoute = apiRoutes.createHandoverPlannerEvent;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createHandoverPlannerEventRoute.getPath(), values);
};

export const brDeleteEvent = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteHandoverPlannerEventRoute = apiRoutes.deleteHandoverPlannerEvent;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteHandoverPlannerEventRoute.getPath(id));
};

export const brEditEvent = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const editHandoverPlannerEventRoute = apiRoutes.editHandoverPlannerEvent;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(editHandoverPlannerEventRoute.getPath(id), values);
};

export const brCreateReminder = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createHandoverPlannerReminderRoute = apiRoutes.createHandoverPlannerReminder;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createHandoverPlannerReminderRoute.getPath(), values);
};

export const brDeleteReminder = params => {
  const values = oFetch(params, 'values');
  const date = oFetch(values, 'date');
  const id = oFetch(values, 'reminderId');
  const [responseDateRangeStart, responseDateRangeEnd] = oFetch(values, 'viewStartDate', 'viewEndDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteHandoverPlannerReminderRoute = apiRoutes.deleteHandoverPlannerReminder;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteHandoverPlannerReminderRoute.getPath(id), {
    params: {
      date: date,
      responseDateRangeStart: responseDateRangeStart,
      responseDateRangeEnd: responseDateRangeEnd,
    },
  });
};

export const brDeleteRecurringFutureReminders = params => {
  const values = oFetch(params, 'values');
  const date = oFetch(values, 'date');
  const id = oFetch(values, 'reminderId');
  const [responseDateRangeStart, responseDateRangeEnd] = oFetch(values, 'viewStartDate', 'viewEndDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteRecurringFutureHandoverPlannerRemindersRoute = apiRoutes.deleteRecurringFutureHandoverPlannerReminders;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteRecurringFutureHandoverPlannerRemindersRoute.getPath(id), {
    params: {
      date: date,
      responseDateRangeStart: responseDateRangeStart,
      responseDateRangeEnd: responseDateRangeEnd,
    },
  });
};

export const brDeleteRecurringSingleReminders = params => {
  const values = oFetch(params, 'values');
  const date = oFetch(values, 'date');
  const id = oFetch(values, 'reminderId');
  const [responseDateRangeStart, responseDateRangeEnd] = oFetch(values, 'viewStartDate', 'viewEndDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteRecurringSingleHandoverPlannerRemindersRoute = apiRoutes.deleteRecurringSingleHandoverPlannerReminders;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteRecurringSingleHandoverPlannerRemindersRoute.getPath(id), {
    params: {
      date: date,
      responseDateRangeStart: responseDateRangeStart,
      responseDateRangeEnd: responseDateRangeEnd,
    },
  });
};

export const brDeleteRecurringAllReminders = params => {
  const values = oFetch(params, 'values');
  const date = oFetch(values, 'date');
  const id = oFetch(values, 'reminderId');
  const [responseDateRangeStart, responseDateRangeEnd] = oFetch(values, 'viewStartDate', 'viewEndDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteRecurringAllHandoverPlannerRemindersRoute = apiRoutes.deleteRecurringAllHandoverPlannerReminders;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).delete(deleteRecurringAllHandoverPlannerRemindersRoute.getPath(id), {
    params: {
      date: date,
      responseDateRangeStart: responseDateRangeStart,
      responseDateRangeEnd: responseDateRangeEnd,
    },
  });
};

export const brEditReminder = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const editHandoverPlannerReminderRoute = apiRoutes.editHandoverPlannerReminder;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(editHandoverPlannerReminderRoute.getPath(id), values);
};

export const brCompleteReminder = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const responseDateRangeStart = oFetch(values, 'responseDateRangeStart');
  const responseDateRangeEnd = oFetch(values, 'responseDateRangeEnd');
  const selectedDate = oFetch(values, 'selectedDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const completeHandoverPlannerReminderRoute = apiRoutes.completeHandoverPlannerReminder;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(completeHandoverPlannerReminderRoute.getPath(id), {
    date: selectedDate,
    responseDateRangeStart: responseDateRangeStart,
    responseDateRangeEnd: responseDateRangeEnd,
  });
};

export const brActivateReminder = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const selectedDate = oFetch(values, 'selectedDate');
  const responseDateRangeStart = oFetch(values, 'responseDateRangeStart');
  const responseDateRangeEnd = oFetch(values, 'responseDateRangeEnd');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const activateHandoverPlannerReminderRoute = apiRoutes.activateHandoverPlannerReminder;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(activateHandoverPlannerReminderRoute.getPath(id), {
    date: selectedDate,
    responseDateRangeStart: responseDateRangeStart,
    responseDateRangeEnd: responseDateRangeEnd,
  });
};

export const brCompleteTask = params => {
  const values = oFetch(params, 'values');
  const date = oFetch(values, 'date');
  const id = oFetch(values, 'id');

  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');

  const completeHandoverPlannerTaskRoute = apiRoutes.completeHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(completeHandoverPlannerTaskRoute.getPath(id), { date });
};

export const brActivateTask = params => {
  const values = oFetch(params, 'values');
  const id = oFetch(values, 'id');
  const date = oFetch(values, 'date');

  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');

  const activateHandoverPlannerTaskRoute = apiRoutes.activateHandoverPlannerTask;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(activateHandoverPlannerTaskRoute.getPath(id), { date });
};

export const brSort = params => {
  const sortData = oFetch(params, 'sortData');
  const currentVenueId = oFetch(params, 'currentVenueId');
  const currentDate = oFetch(params, 'currentDate');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const handoverPlannerSort = apiRoutes.handoverPlannerSort;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(handoverPlannerSort.getPath(), { sortData, venueId: currentVenueId, date: currentDate });
};
