import React, { Component } from 'react';
import oFetch from 'o-fetch';

function SecurityShiftRequestVenueList(props) {
  const securityShiftRequestsGrupedByVenue = oFetch(props, 'securityShiftRequestsGrupedByVenue');
  const venueCardRenderer = oFetch(props, 'venueCardRenderer');

  function renderItems() {
    if (securityShiftRequestsGrupedByVenue.length === 0) {
      return <h1 className="boss-page-main__text-placeholder">No requests found</h1>;
    }

    return Object.entries(securityShiftRequestsGrupedByVenue).map(([venueName, securityShiftRequests]) => {
      if (securityShiftRequests.length === 0) return;
      return React.cloneElement(venueCardRenderer(securityShiftRequests, venueName), {
        key: venueName,
      });
    });
  }

  return (
    <div className="boss-board__inner">
      <div className="boss-board__cards">{renderItems()}</div>
    </div>
  );
}

export default SecurityShiftRequestVenueList;
