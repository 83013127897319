import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';

import { RatingSelectorFormField } from './rating-selector-form-field';

export function RatingForm(props) {
  const [kpiDataRenderer, onSubmit, initialValues, submitTitle] = oFetch(
    props,
    'kpiDataRenderer',
    'onSubmit',
    'initialValues',
    'submitTitle',
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values, ...rest }) => {
        return (
          <div className="boss-modal-window__form">
            <div className="boss-form">
              <div className="boss-form__group">
                <p className="boss-form__label">
                  <span className="boss-form__label-text">Values</span>
                </p>
                <div className="boss-form__data">{kpiDataRenderer()}</div>
              </div>
              <div className="boss-form__group">
                <p className="boss-form__label">
                  <span className="boss-form__label-text">Rating</span>
                </p>
                <div className="boss-form__area">
                  <Field name="rating" parse={value => value} component={RatingSelectorFormField} />
                </div>
              </div>
              <Field name="note" parse={value => value} component={BossFormTextarea} label="Note" />
              <div className="boss-form__field">
                <button
                  disabled={submitting}
                  onClick={handleSubmit}
                  className="boss-button boss-form__submit"
                  type="submit"
                >
                  {submitTitle}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
