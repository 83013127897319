import React from 'react';
import oFetch from 'o-fetch';

export function MonthList(props) {
  const [renderItem, months] = oFetch(props, 'renderItem', 'months');

  function renderItems() {
    const items = []

    Object.entries(months).forEach(monthEntry => {
      const [label, issues] = monthEntry;

      if (issues) {
        items.push(React.cloneElement(renderItem({ monthIssues: issues, monthLabel: label }), { key: label }));
      }
    });

    return items;
  }

  return <div className="boss-card-container">{renderItems()}</div>;
}
