import React, { useMemo, Fragment } from 'react';
import oFetch from 'o-fetch';
import { Fields, reduxForm, Field } from 'redux-form/immutable';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { BossFormSelectNew } from '@/components/boss-form';

function ChangeVenueModal(props) {
  const [accessibleMasterVenues, handleSubmit, submitting] = oFetch(
    props,
    'accessibleMasterVenues',
    'handleSubmit',
    'submitting',
  );
  const { error } = props;

  function renderBaseErrors(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {error && renderBaseErrors(error)}
      <div className="boss-modal-window__form">
        <Field
          component={BossFormSelectNew}
          name="master_venue"
          clearable={false}
          required
          label="Master Venue"
          labelKey="name"
          valueKey="id"
          placeholder="Select main venue ..."
          options={accessibleMasterVenues}
        />

        <div className="boss-form__field">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={submitting}
            className="boss-button boss-button_role_submit boss-form__submit"
          >
            {submitting ? 'Updating ...' : 'Update'}
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const ChangeVenueForm = reduxForm({
  form: 'change-master-venue-form',
})(ChangeVenueModal);

export function ChangeVenueButton(props) {
  const { openModal } = useModal();
  const [accessibleVenues, masterVenue, onChangeMasterVenue, mossStaffMemberId] = oFetch(
    props,
    'accessibleVenues',
    'masterVenue',
    'onChangeMasterVenue',
    'mossStaffMemberId',
  );

  const masterVenueName = masterVenue?.name;

  const accessibleMasterVenues = useMemo(
    () => accessibleVenues.filter(venue => oFetch(venue, 'id') !== oFetch(masterVenue, 'id')),
    [masterVenue],
  );
  function openEditVenueModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ChangeVenueForm,
      onSubmit(closeModal, values) {
        const valuesJS = values.toJS();
        return onChangeMasterVenue({
          values: valuesJS,
          onSuccess() {
            closeModal();
          },
        });
      },
      props: {
        accessibleMasterVenues,
        initialValues: {
          mossStaffMemberId: mossStaffMemberId,
          master_venue: null,
        },
      },
      config: {
        title: 'Change Staff Member Venue',
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
      },
    });
  }

  return (
    <div className="boss-form__field">
      <p className="boss-form__label">
        <span className="boss-form__label-text">Master Venue</span>
      </p>
      <div>
        <span className="boss-form__text-primary" style={{ marginRight: '20px' }}>
          {masterVenueName}
        </span>
        <button
          onClick={openEditVenueModal}
          type="button"
          className="boss-button boss-button_role_edit-light boss-button_type_extra-small boss-table__action"
        >
          Change
        </button>
      </div>
    </div>
  );
}
