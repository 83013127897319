import React from 'react';
import oFetch from 'o-fetch';
import { reduxForm, Field } from 'redux-form';
import { StaffTypesField } from './fields';

function AssignStaffTypesForm(props) {
  const [options, handleSubmit, submitting, pristine] = oFetch(
    props,
    'options',
    'handleSubmit',
    'submitting',
    'pristine',
  );

  return (
    <div className="boss-board__group">
      <div className="boss-form">
        <div className="boss-form__group boss-form__group_role_board">
          <div className="boss-form__row boss-form__row_align_center">
            <Field name="staffTypes" options={options} component={StaffTypesField} />
            <div className="boss-form__field boss-form__field_layout_fluid boss-form__field_justify_center">
              <button
                onClick={handleSubmit}
                disabled={submitting || pristine}
                type="button"
                className="boss-button boss-button_role_confirm boss-form__submit"
              >
                {submitting ? 'Assigning' : 'Assign'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'assign-staff-types',
})(AssignStaffTypesForm);
