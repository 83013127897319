import React from 'react';
import cn from 'classnames';

const BossFormTextarea = ({
  input,
  label,
  required,
  style = {},
  containerClasses,
  classNames,
  disabled = false,
  meta,
  meta: { touched, error, warning, submitError },
  note,
  placeholder,
  labelClassNames,
}) => {
  const containerClassNames = cn('boss-form__field', { [containerClasses]: !!containerClasses });
  const textAreaClassNames = cn('boss-form__textarea', {
    [classNames]: !!classNames,
    'boss-form__textarea_state_error': touched && (error || submitError),
  });
  return (
    <div
      style={style}
      className={containerClassNames}
    >
      {label && (
        <label className="boss-form__label">
          <span className={`boss-form__label-text ${labelClassNames || ''}`}>{`${label} ${required ? '*' : ''
          }`}</span>
        </label>
      )}
      <textarea
        {...input}
        placeholder={placeholder || label}
        disabled={disabled}
        className={textAreaClassNames}
      />
      {note && <p className="boss-form__field-note">{note}</p>}
      {touched && (error || submitError) && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error || submitError}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default BossFormTextarea;
