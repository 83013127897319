import React from 'react';
import oFetch from 'o-fetch';

function LoadMoreButton(props) {
  const currentAmount = oFetch(props, 'currentAmount');
  const totalAmount = oFetch(props, 'totalAmount');
  const isButtonShown = currentAmount < totalAmount;
  const onClick = oFetch(props, 'onClick');
  const buttonText = props.buttonText || 'Load More';

  return (
    <div>
      <div className="boss-page-main__count boss-page-main__count_space_large">
        <span className="boss-page-main__count-text">Showing&nbsp;</span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{currentAmount}</span>
        <span className="boss-page-main__count-text">&nbsp;of&nbsp;</span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{totalAmount}</span>
      </div>
      {isButtonShown && (
        <div className="boss-page-main__actions boss-page-main__actions_position_last">
          <button
            onClick={onClick}
            className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}

export default LoadMoreButton;
