import oFetch from 'o-fetch';
import { useState } from 'react';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseOutsideModalWrapper, LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { CreateSiteIssueModalContent, UpdateSiteIssueModalContent } from './components';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import * as requests from './requests';

export function useSiteIssuesStates(initialValues) {
  const initialMonths = oFetch(initialValues, 'months');
  const { openModal } = useModal();
  const [venueId, venueName] = oFetch(useLegacyGlobal(), 'venueId', 'venueName');
  const [months, setMonths] = useState(initialMonths);

  function addSiteIssueToState(siteIssue) {
    const monthName = oFetch(siteIssue, 'monthName');
    return setMonths({
      ...months,
      [monthName]: [...months[monthName], siteIssue],
    });
  }

  function updateSiteIssueInState(updateSiteIssue) {
    const [updateId, monthName] = oFetch(updateSiteIssue, 'id', 'monthName');
    return setMonths(prevState => {
      return {
        ...prevState,
        [monthName]: prevState[monthName].map(siteIssue => {
          const id = oFetch(siteIssue, 'id');
          if (id === updateId) {
            return updateSiteIssue;
          }
          return siteIssue;
        }),
      };
    });
  }

  function openCreateSiteIssueModal(params) {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: CreateSiteIssueModalContent,
      onSubmit: (closeModal, values) => {
        return requests.createSiteIssue({
          values: { ...values, venueId },
          onSuccess(data) {
            const siteIssue = oFetch(data, 'siteIssue');
            addSiteIssueToState(siteIssue);
            closeModal();
          },
        });
      },
      config: {
        title: `Add New Site Issue: ${venueName}`,
      },
      props: {},
    });
  }

  function openEditSiteIssueModal(params) {
    const siteIssue = oFetch(params, 'siteIssue');
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: UpdateSiteIssueModalContent,
      onSubmit: (closeModal, values) => {
        return requests.updateSiteIssue({
          values: values,
          onSuccess(data) {
            const siteIssue = oFetch(data, 'siteIssue');
            updateSiteIssueInState(siteIssue);

            closeModal();
          },
        });
      },
      config: {
        title: `Edit Site Issue: ${venueName}`,
      },
      props: {
        siteIssue,
      },
    });
  }

  function activateSiteIssue(params) {
    const id = oFetch(params, 'id');
    return requests.activateSiteIssue({
      values: {
        id,
      },
      onSuccess(data) {
        const siteIssue = oFetch(data, 'siteIssue');
        updateSiteIssueInState(siteIssue);
      },
    });
  }

  function setPendingSiteIssue(params) {
    const id = oFetch(params, 'id');
    return requests.setPendingSiteIssue({
      values: {
        id,
      },
      onSuccess(data) {
        const siteIssue = oFetch(data, 'siteIssue');
        updateSiteIssueInState(siteIssue);
      },
    });
  }

  function disableSiteIssue(params) {
    const id = oFetch(params, 'id');
    return requests.disableSiteIssue({
      values: {
        id,
      },
      onSuccess(data) {
        const siteIssue = oFetch(data, 'siteIssue');
        updateSiteIssueInState(siteIssue);
      },
    });
  }

  return {
    months,
    activateSiteIssue,
    setPendingSiteIssue,
    disableSiteIssue,
    openEditSiteIssueModal,
    openCreateSiteIssueModal,
  };
}
