import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const questions = oFetch(action.payload, 'questions');
      return questions;
    },
    [types.ADD_QUESTION]: (state, action) => {
      const question = oFetch(action.payload, 'question');
      return [...state, question];
    },
    [types.ADD_QUESTION_AND_DELETE_OLD]: (state, action) => {
      const newQuestion = oFetch(action.payload, 'question');
      const oldQuestionId = oFetch(action.payload, 'questionId');
      return state.map(question => {
        if (oFetch(question, 'id') === oldQuestionId) {
          return newQuestion;
        }
        return question;
      });
    },
    [types.UPDATE_QUESTION]: (state, action) => {
      const updatedQuestion = oFetch(action.payload, 'question');
      const questionId = oFetch(action.payload, 'question.id');
      return state.map(question => {
        if (oFetch(question, 'id') === questionId) {
          return updatedQuestion;
        }
        return question;
      });
    },
    [types.REMOVE_QUESTION]: (state, action) => {
      const quizCategoryQuestionId = oFetch(action.payload, 'quizCategoryQuestionId');

      return state.filter(( question ) => {
        return oFetch(question, 'id') != quizCategoryQuestionId;
      });
    },
  },
  initialState,
);
