import oFetch from 'o-fetch';
import React from 'react';

export function InviteNotLinkedContent(props) {
  const invite = oFetch(props, 'invite');
  const venueNames = oFetch(invite, 'venueNames');

  return (
    <div className="boss-check__group boss-check__group_marked">
      <div className="boss-check__group-content">
        <p className="boss-check__text boss-check__text_size_l boss-check__text_role_location">
          <span className="boss-check__text-marked">Venues: </span>
          <span>{venueNames || 'N/A'}</span>
        </p>
      </div>
    </div>
  );
}
