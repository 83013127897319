import React from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import QuizRanks from './quiz-ranks';

export function QuizRanksApp(props) {
  return (
    <ModalsProvider>
      <QuizRanks {...props} />
    </ModalsProvider>
  );
}
