import React from 'react';
import oFetch from 'o-fetch';

export default function QuizCategoriesItem(props) {
  const [category, size] = oFetch(props, 'category', 'size');
  const [name, color, url] = oFetch(category, 'name', 'color', 'url');

  return (
    <a
      href={url}
      style={{ backgroundColor: color }}
      className="boss-count boss-count_role_link boss-count_adjust_row boss-count_size_half boss-count_type_badge"
    >
      <div className="boss-count__group">
        <p className="boss-count__label">{name}</p>
        <p className="boss-count__number">{size}</p>
      </div>
    </a>
  );
}
