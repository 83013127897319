import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {
  paymentWeekPublished: null,
  paymentWeekPublishTime: null,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const paymentWeekPublished = oFetch(action, 'payload.paymentWeekPublished');
      const paymentWeekPublishTime = oFetch(action, 'payload.paymentWeekPublishTime');
      return {
        paymentWeekPublished: paymentWeekPublished,
        paymentWeekPublishTime: paymentWeekPublishTime,
      };
    },
    [types.UPDATE_PAYMENT_WEEK]: (state, action) => {
      const paymentWeekPublished = oFetch(action, 'payload.paymentWeekPublished');
      const paymentWeekPublishTime = oFetch(action, 'payload.paymentWeekPublishTime');
      return {
        paymentWeekPublished: paymentWeekPublished,
        paymentWeekPublishTime: paymentWeekPublishTime,
      };
    },
  },
  initialState,
);
