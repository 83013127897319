import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const checklistsItems = oFetch(action, 'payload.checklistsItems');
      return checklistsItems;
    },
    [types.ADD_CHECKLIST]: (state, action) => {
      const checklistItems = oFetch(action, 'payload.checklistItems');
      return [...state, ...checklistItems];
    },
    [types.UPDATE_CHECKLIST]: (state, action) => {
      const [checklistId, checklistItems] = oFetch(action, 'payload.checklistId', 'payload.checklistItems');
      const filteredChecklistItems = state.filter(
        checklistItem => oFetch(checklistItem, 'checklistId') !== checklistId,
      );
      return [...filteredChecklistItems, ...checklistItems];
    },
    [types.REMOVE_CHECKLIST_FROM_STORE]: (state, action) => {
      const checklistId = oFetch(action, 'payload.checklistId');
      const checklistItems = state.filter(item => oFetch(item, 'checklistId') !== checklistId);
      return checklistItems;
    },
  },
  initialState,
);
