import React from 'react';
import oFetch from 'o-fetch';
import { appRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export function NotesModalContent(props) {
  const [notes, weekStartDate, venueId] = oFetch(props, 'notes', 'weekStartDate', 'venueId');

  function renderNotes() {
    return notes.map(noteDataItem => {
      const [
        note,
        financeReportId,
        staffMemberFullName,
        staffTypeName,
        staffTypeColor,
        createdByFullName,
        createdAt,
      ] = oFetch(
        noteDataItem,
        'note',
        'financeReportId',
        'staffMemberFullName',
        'staffTypeName',
        'staffTypeColor',
        'createdByFullName',
        'createdAt',
      );
      const href = appRoutes.financeReports({
        startDate: weekStartDate,
        venueId: venueId,
      });
      return (
        <div key={financeReportId} className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Name</p>
              <p className="boss-table__text">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${href}#scroll_to=${financeReportId}`}
                  className="boss-table__link"
                >
                  {staffMemberFullName}
                </a>
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Staff Type</p>
              <p className="boss-table__text">
                <span
                  className="boss-button boss-button_type_label"
                  style={{ backgroundColor: staffTypeColor }}
                >
                  {staffTypeName}
                </span>
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Note</p>
              <p className="boss-table__text" style={{ whiteSpace: 'pre-line' }}>
                {note}
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Created By</p>
              <p className="boss-table__text">
                <span className="boss-table__text-line">{createdByFullName}</span>
                <span className="boss-table__text-meta">
                  ({safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime())})
                </span>
              </p>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview">
        <div className="boss-overview__group boss-overview__group_position_last">
          <div className="boss-table boss-table_page_modulr-reports-venue-notes">
            <div className="boss-table__row boss-table__row_role_header">
              <div className="boss-table__cell boss-table__cell_role_header">Name</div>
              <div className="boss-table__cell boss-table__cell_role_header">Staff Type</div>
              <div className="boss-table__cell boss-table__cell_role_header">Note</div>
              <div className="boss-table__cell boss-table__cell_role_header">Created By</div>
            </div>
            {renderNotes()}
          </div>
        </div>
      </div>
    </div>
  );
}
