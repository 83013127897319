import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import humanize from 'string-humanize';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import * as constants from './constants';

const REFUND_REQUEST_STATUSES = {
  pending: 'Refund Requested',
  accepted: 'Refund accepted',
  rejected: 'Refund rejected',
  completed: 'Refund completed',
  refunded_usable: 'Refunded Usable',
  refunded_unusable: 'Refunded Unusable',
  refunded_unknown: 'Refunded Unknown',
};

const REQUEST_STATUS_CLASS_PREFIXES = {
  [constants.ACCESSORY_REQUEST_STATUS_ACCEPTED]: 'accepted',
  [constants.ACCESSORY_REQUEST_STATUS_COMPLETED]: 'accepted',
  [constants.ACCESSORY_REQUEST_STATUS_PENDING]: 'pending',
  [constants.ACCESSORY_REQUEST_STATUS_REJECTED]: 'rejected',
  [constants.ACCESSORY_REQUEST_STATUS_CANCELED]: 'rejected',
  [constants.ACCESSORY_REQUEST_STATUS_WRITE_OFF]: 'rejected',
  [constants.ACCESSORY_REQUEST_STATUS_UNRETURNED_AFTER_MOVE]: 'rejected',
  [constants.ACCESSORY_REQUEST_STATUS_CHARGED_UNRETURNABLE]: 'rejected',
  [constants.ACCESSORY_REQUEST_STATUS_RECEIVED]: 'received',
  [constants.ACCESSORY_REQUEST_STATUS_CHARGED_NOT_RETURNED]: 'received',
  [constants.ACCESSORY_REQUEST_STATUS_RETURNED]: 'received',
  [constants.ACCESSORY_REQUEST_STATUS_CHARGED_LEGACY]: 'received',
};

const REFUND_REQUEST_STATUS_CLASS_PREFIXES = {
  pending: 'requested',
  accepted: 'accepted',
  rejected: 'rejected',
  completed: 'accepted',
  charge_not_returned: 'accepted',
  refunded_usable: 'accepted',
  refunded_unusable: 'accepted',
  refunded_unknown: 'accepted',
};

function AccessoryRequestItem(props) {
  const [isRefundsTimelineOpened, setIsRefundsTimelineOpened] = useState(false);
  const [processing, setProcessing] = useState(false);

  async function setProcessingState(args, action) {
    setProcessing(true);
    await action(args);
    setProcessing(false);
  }

  function renderRequestActions(accessoryRequest) {
    const accessoryRequestId = oFetch(accessoryRequest, 'id');
    const status = oFetch(accessoryRequest, 'status');
    const refundDeadlineExpired = oFetch(accessoryRequest, 'refundDeadlineExpired');
    const hasRefundRequest = oFetch(accessoryRequest, 'hasRefundRequest');
    const refundRequestStatus = oFetch(accessoryRequest, 'refundRequestStatus');
    const onAccessoryRefund = oFetch(props, 'onAccessoryRefund');
    const onReturnAccessory = oFetch(props, 'onReturnAccessory');
    const onAccessoryCancel = oFetch(props, 'onAccessoryCancel');
    const onWriteOffAccessory = oFetch(props, 'onWriteOffAccessory');
    const accessoryRequestPermissions = oFetch(props, 'accessoryRequestPermissions');
    const [isCancelable, isRefundable, isWriteoffable] = oFetch(
      accessoryRequestPermissions,
      'isCancelable',
      'isRefundable',
      'isWriteoffable',
    );
    const refundRequestStatusRejected = refundRequestStatus === 'rejected';

    if (
      status === constants.ACCESSORY_REQUEST_STATUS_CHARGED_NOT_RETURNED ||
      status === constants.ACCESSORY_REQUEST_STATUS_CHARGED_LEGACY
    ) {
      return (
        <div className="boss-requests__actions">
          {(!hasRefundRequest || refundRequestStatusRejected) && isRefundable && !refundDeadlineExpired && (
            <button
              onClick={() => setProcessingState(accessoryRequestId, onAccessoryRefund)}
              className={`boss-requests__action boss-requests__action_role_request`}
            >
              Request refund
            </button>
          )}
          {isWriteoffable && status === constants.ACCESSORY_REQUEST_STATUS_CHARGED_NOT_RETURNED && (
            <button
              onClick={() => setProcessingState(accessoryRequestId, onWriteOffAccessory)}
              className={`boss-requests__action boss-tag__service_icon_close boss-tag_size_xs`}
              style={{ display: 'block' }}
            >
              Write off
            </button>
          )}
        </div>
      );
    }
    if (status === constants.ACCESSORY_REQUEST_STATUS_UNRETURNED_AFTER_MOVE) {
      return (
        <div className="boss-requests__actions">
          {!refundDeadlineExpired && (
            <button
              onClick={() => setProcessingState(accessoryRequestId, onReturnAccessory)}
              className={`boss-requests__action boss-requests__action_role_request`}
            >
              Return accessory
            </button>
          )}
        </div>
      );
    }
    if (status === constants.ACCESSORY_REQUEST_STATUS_PENDING) {
      return (
        <div className="boss-requests__actions">
          {isCancelable && (
            <button
              className={`boss-requests__action boss-requests__action_role_cancel`}
              onClick={() => setProcessingState(accessoryRequestId, onAccessoryCancel)}
            >
              Cancel
            </button>
          )}
        </div>
      );
    }
    return null;
  }

  function toggleRefundTimeline() {
    setIsRefundsTimelineOpened(!isRefundsTimelineOpened);
  }

  function renderTimeline(accessoryRequest) {
    return accessoryRequest.timeline.map((timelineItem, key) => {
      const date = safeMoment
        .iso8601Parse(oFetch(timelineItem, 'createdAt'))
        .format(utils.humanDateFormatWithTime());
      const requestType = oFetch(timelineItem, 'requestType');
      const requestAction =
        oFetch(timelineItem, 'state') === 'pending' ? 'Requested' : oFetch(timelineItem, 'state');
      const requester = oFetch(timelineItem, 'requester');
      const fullName = requester ? oFetch(requester, 'fullName') : 'N/A';
      return (
        <div key={`${key}`} className="boss-requests__details-record">
          <p className="boss-requests__details-text">
            {requestType === 'refundRequest' ? `Refund ${humanize(requestAction)}` : humanize(requestAction)}
            {` on ${date} by ${fullName}`}
            <span className="boss-requests__details-text-marked" />
          </p>
        </div>
      );
    });
  }

  const accessoryRequest = oFetch(props, 'accessoryRequest');
  const timeline = oFetch(accessoryRequest, 'timeline');
  const [accessoryIcon, accessoryIconColor] = oFetch(accessoryRequest, 'accessoryIcon', 'accessoryIconColor');
  const requestDate = safeMoment
    .iso8601Parse(oFetch(timeline[timeline.length - 1], 'createdAt'))
    .format(utils.humanDateFormatWithTime());
  const status = oFetch(accessoryRequest, 'status');
  const accessoryName = oFetch(accessoryRequest, 'accessoryName');
  const size = oFetch(accessoryRequest, 'size');
  const hasRefundRequest = oFetch(accessoryRequest, 'hasRefundRequest');
  const refundRequestStatus = oFetch(accessoryRequest, 'refundRequestStatus');
  const refundStatusClassPrefix = REFUND_REQUEST_STATUS_CLASS_PREFIXES[refundRequestStatus];
  const requestStatusClassPrefix = REQUEST_STATUS_CLASS_PREFIXES[status];
  const statusClassPrefix = hasRefundRequest ? refundStatusClassPrefix : requestStatusClassPrefix;
  const venueName = oFetch(accessoryRequest, 'venueName');
  const requestFrozen = oFetch(accessoryRequest, 'requestFrozen');
  const sPayslipDate = oFetch(accessoryRequest, 'payslipDate');
  const sPayslipDateText = sPayslipDate
    ? safeMoment.uiDateParse(sPayslipDate).format(utils.commonDateFormat)
    : null;
  const refundFrozen = oFetch(accessoryRequest, 'refundFrozen');
  const sRefundPayslipDate = oFetch(accessoryRequest, 'refundPayslipDate');
  const sRefundPayslipDateText = sRefundPayslipDate
    ? safeMoment.uiDateParse(sRefundPayslipDate).format(utils.commonDateFormat)
    : null;
  return (
    <li className="boss-requests__item">
      <div className="boss-requests__meta">
        <div className="boss-requests__meta-info">
          <div className="boss-requests__meta-text">{requestDate}</div>
          <div className="boss-requests__meta-text">
            <span className="boss-requests__meta-text-bold">{venueName}</span>
          </div>
        </div>
        {!processing && (
          <div className={`boss-requests__status boss-requests__status_role_${statusClassPrefix}`}>
            {hasRefundRequest
              ? oFetch(REFUND_REQUEST_STATUSES, refundRequestStatus)
              : oFetch(constants.ACCESSORY_REQUEST_STATUS, status)}
          </div>
        )}
        {processing && (
          <div className={`boss-requests__status boss-requests__status_role_${statusClassPrefix}`}>
            Processing ...
          </div>
        )}
      </div>
      {!processing && (
        <div className="boss-requests__content">
          <div className="boss-requests__header">
            <h3 className="boss-requests__title">
              <span className="boss-requests__title-indicator">
                <span className="boss-indicator">
                  <span
                    className={`boss-indicator__icon boss-indicator__icon_${accessoryIcon}`}
                    style={{ color: accessoryIconColor }}
                  />
                </span>
              </span>
              <span className="boss-requests__title-name">{accessoryName}</span>
              <span className="boss-requests__title-size">{size ? `(${size})` : '(N/A)'}</span>
            </h3>
            {renderRequestActions(accessoryRequest)}
          </div>
          {sPayslipDateText && (
            <div className="boss-requests__details">
              <div className="boss-requests__details-content">
                <div className="boss-requests__details-line">
                  <div className="boss-requests__details-value">
                    <p
                      className={`boss-request__details-text ${
                        requestFrozen ? 'boss-requests__details-text_indicator_frozen' : ''
                      }`}
                    >
                      Payslip Date:{' '}
                      <span className="boss-requests__details-text-marked">{sPayslipDateText}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {sRefundPayslipDateText && (
            <div className="boss-requests__details">
              <div className="boss-requests__details-content">
                <div className="boss-requests__details-line">
                  <div className="boss-requests__details-value">
                    <p
                      className={`boss-request__details-text ${
                        refundFrozen ? 'boss-requests__details-text_indicator_frozen' : ''
                      }`}
                    >
                      Refund Payslip Date:{' '}
                      <span className="boss-requests__details-text-marked">{sRefundPayslipDateText}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="boss-requests__details">
            <p
              className={`boss-requests__details-switch ${
                isRefundsTimelineOpened
                  ? 'boss-requests__details-switch_role_cancel'
                  : 'boss-requests__details-switch_role_list'
              }`}
              onClick={toggleRefundTimeline}
            >
              {isRefundsTimelineOpened ? 'Hide' : 'Show timeline'}
            </p>
            <Collapse
              isOpened={isRefundsTimelineOpened}
              className="boss-requests__details-dropdown"
              style={{ display: 'block' }}
            >
              <div className="boss-requests__details-content">{renderTimeline(accessoryRequest)}</div>
            </Collapse>
          </div>
        </div>
      )}
    </li>
  );
}

export default AccessoryRequestItem;
