import React, { Fragment } from 'react';
import ContentWrapper from '@/components/content-wrapper';
import { BonusPaymentsDashboard, BonusPaymentItem, BonusPaymentsList, DashboardFilter } from './components';
import { bonusPaymentsAdminHooks } from './bonus-payments-admin-hooks';
import oFetch from 'o-fetch';

export function BonusPaymentsAdmin(props) {
  const {
    openAddPaymentModal,
    bonusPayments,
    disableBonusPayment,
    handleFilterStatesChange,
    filterStates,
    filterName,
    handleFilterNameChange,
    openEditPaymentModal,
  } = bonusPaymentsAdminHooks({
    initialBonusPayments: oFetch(props, 'bonusPayments'),
    selectedYear: oFetch(props, 'year'),
    selectedQuarter: oFetch(props, 'quarterNumber'),
  });
  const canCreate = oFetch(props, 'canCreate');

  function renderBonusPayment(bonusPayment) {
    return (
      <BonusPaymentItem
        bonusPayment={bonusPayment}
        onEdit={openEditPaymentModal}
        onDisable={disableBonusPayment}
      />
    );
  }

  function renderFilter() {
    return (
      <DashboardFilter
        selectedStates={filterStates}
        nameQuery={filterName}
        onStatesChange={handleFilterStatesChange}
        onNameChange={handleFilterNameChange}
      />
    );
  }

  return (
    <Fragment>
      <BonusPaymentsDashboard renderFilter={renderFilter} />
      <ContentWrapper
        className={'boss-page-main__inner_opaque boss-page-main__inner_space_large boss-card-container'}
      >
        <div className="boss-page-dashboard__group">
          <h1 />
          <div className="boss-page-dashboard__buttons-group">
            {canCreate && (
              <button
                onClick={openAddPaymentModal}
                type="button"
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                New Payment
              </button>
            )}
          </div>
        </div>
        <BonusPaymentsList bonusPayments={bonusPayments} renderBonusPayment={renderBonusPayment} />
      </ContentWrapper>
    </Fragment>
  );
}
