import React from 'react';
import { connect } from 'react-redux';
import ProfileWrapper from '../profile-wrapper';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import AsyncButton from 'react-async-button';

import utils from '@/lib/utils';
import { Filter, HistoryItem, HistoryList } from './components';
import * as selectors from './redux/selectors';
import * as actions from './redux/actions';

class QuizCategoryHistory extends React.Component {
  state = {
    filterButtonDisabled: false,
  };

  renderHistoryItem = historyItem => {
    return <HistoryItem item={historyItem} />;
  };

  handleFilterChange = params => {
    const handleFilter = oFetch(this.props, 'handleFilter');
    const [mStartDate, mEndDate] = oFetch(params, 'startDate', 'endDate');
    const uiStartDate = mStartDate.format(utils.commonDateFormat);
    const uiEndDate = mEndDate.format(utils.commonDateFormat);

    handleFilter({
      uiStartDate,
      uiEndDate,
    });
  };

  handleFilterClick = () => {
    const [mStartDate, mEndDate] = oFetch(this.props, 'filter.mStartDate', 'filter.mEndDate');
    const uiStartDate = mStartDate.format(utils.commonDateFormat);
    const uiEndDate = mEndDate.format(utils.commonDateFormat);
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      start_date: uiStartDate,
      end_date: uiEndDate,
    })}`;
    window.history.pushState('state', 'title', url);
    this.setState({ filterButtonDisabled: true });
    window.location.reload(true);
  };

  render() {
    const [mStartDate, mEndDate, history, showLoadMore, quizCategoryName] = oFetch(
      this.props,
      'filter.mStartDate',
      'filter.mEndDate',
      'history',
      'showLoadMore',
      'quizCategoryName',
    );
    const loadMore = oFetch(this.props, 'loadMore');
    const filterButtonDisabled = oFetch(this.state, 'filterButtonDisabled');

    return (
      <ProfileWrapper currentPage="quizzes">
        <section className="boss-board boss-board_role_profile-disciplinary">
          <header className="boss-board__header">
            <h2 className="boss-board__title">{quizCategoryName} Quiz History</h2>
          </header>
          <div className="boss-board__main">
            <div className="boss-board__manager">
              <div className="boss-board__manager-group boss-board__manager-group_role_data">
                <Filter
                  startDate={mStartDate}
                  endDate={mEndDate}
                  onFilterClick={this.handleFilterClick}
                  onChange={this.handleFilterChange}
                  disabled={filterButtonDisabled}
                />
                <HistoryList items={history} itemRenderer={this.renderHistoryItem} />
                {showLoadMore && (
                  <div className="boss-board__manager-actions">
                    <AsyncButton
                      className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
                      text="Load More"
                      pendingText="Loading more ..."
                      onClick={loadMore}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </ProfileWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: selectors.getFilter(state),
    history: selectors.getHistory(state),
    showLoadMore: selectors.showLoadMore(state),
    quizCategoryName: selectors.quizCategoryName(state),
  };
};

const mapDispatchToProps = {
  handleFilter: actions.handleFilter,
  loadMore: actions.loadMore,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizCategoryHistory);
