import React from 'react';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';

import * as constants from '../constants';

const scrollOptions = {
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  scrollbars: true,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export default function CountingHistoryModal(props) {
  const [normalizedChanges, eventType] = oFetch(props, 'normalizedChanges', 'eventType');

  function renderList(params) {
    return normalizedChanges.map((item, index) => {
      const [label, from, to] = oFetch(item, 'label', 'from', 'to');
      if (eventType === constants.CREATE_EVENT) {
        return (
          <li key={index} className="purple-activity__item">
            <div className="purple-activity__marker" />
            <div className="purple-activity__record">
              <p className="purple-activity__text">
                <span className="purple-activity__text-bold">{label}</span>
                <span className="purple-activity__text-faded"> was created with </span>
                <span className="purple-activity__text-bold">£{to}</span>
              </p>
            </div>
          </li>
        );
      }
      if (eventType === constants.UPDATE_EVENT) {
        return (
          <li key={index} className="purple-activity__item">
            <div className="purple-activity__marker" />
            <div className="purple-activity__record">
              <p className="purple-activity__text">
                <span className="purple-activity__text-bold">{label}</span>
                <span className="purple-activity__text-faded"> was updated from </span>
                <span className="purple-activity__text-bold">£{from}</span>
                <span className="purple-activity__text-faded"> to </span>
                <span className="purple-activity__text-bold">£{to}</span>
              </p>
            </div>
          </li>
        );
      }
      throw new Error(`Wrong event type, got: ${eventType}`);
    });
  }

  return (
    <div className="purple-modal__content">
      <ReactIScroll iScroll={iScroll} options={scrollOptions}>
        <div className="purple-overview">
          <div className="purple-overview__group">
            <div className="purple-activity">
              <ul className="purple-activity__list">{renderList()}</ul>
            </div>
          </div>
        </div>
      </ReactIScroll>
    </div>
  );
}
