import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class UserList extends Component {
  renderItems = items => {
    const itemRender = oFetch(this.props, 'itemRender');
    return items.map((item, index) => {
      return React.cloneElement(itemRender(item), {
        key: index,
      });
    });
  };

  render() {
    const items = oFetch(this.props, 'items');
    return (
      <div className="boss-users__flow">
        <div className="boss-users__flow-list">{this.renderItems(items)}</div>
      </div>
    );
  }
}
