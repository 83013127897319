import React from 'react';
import classNames from 'classnames';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';

import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';

const cellStyle = { flexDirection: 'row', alignItems: 'center' };

export function PaidHolidaysColumn(props) {
  const [mossFinanceReport, startDate, endDate] = oFetch(
    props,
    'mossFinanceReport',
    'startDate',
    'endDate',
  );
  const [mossStaffMemberId, containsTimeShiftedHolidays, paidHolidayDaysCount] = oFetch(
    mossFinanceReport,
    'mossStaffMemberId',
    'containsTimeShiftedHolidays',
    'paidHolidayDaysCount',
  );
  const hasIncompleteHolidays = oFetch(mossFinanceReport, 'hasIncompleteHolidays');

  const mStartDate = safeMoment.uiDateParse(startDate);
  const mEndDate = safeMoment.uiDateParse(endDate);

  const paidHolidayDaysCountClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning':
      paidHolidayDaysCount !== 0 && containsTimeShiftedHolidays,
  });

  const textClassNames = classNames('boss-boss-table__text boss-table__link', {});

  function renderQuestionMarkTooltip(content) {
    return (
      <Tooltip arrow theme="light" position="right" interactive html={content}>
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function tooltipContent() {
    return (
      <a
        href={appRoutes.mossStaffMemberProfileHolidaysTab({
          mossStaffMemberId: mossStaffMemberId,
          mStartDate: mStartDate,
          mEndDate: mEndDate,
        })}
        target="_blank"
        rel="noreferrer"
      >
        Incomplete holiday requests exist
      </a>
    );
  }

  return (
    <div className={paidHolidayDaysCountClassName} style={cellStyle}>
      {paidHolidayDaysCount === 0 && (
        <p className={textClassNames}>
          {paidHolidayDaysCount}
          {hasIncompleteHolidays && renderQuestionMarkTooltip(tooltipContent())}
        </p>
      )}
      {paidHolidayDaysCount !== 0 && (
        <a
          href={appRoutes.mossStaffMemberProfileHolidaysTabFromMossFinanceReport({
            mossStaffMemberId: mossStaffMemberId,
            mPayslipStartDate: mStartDate,
            mPayslipEndDate: mEndDate,
          })}
          target="_blank"
          rel="noreferrer"
          className={textClassNames}
        >
          {paidHolidayDaysCount}
          {hasIncompleteHolidays && renderQuestionMarkTooltip(tooltipContent())}
        </a>
      )}
    </div>
  );
}
