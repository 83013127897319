import { useReducer, useMemo } from 'react';
import { createAction, handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

const ADD_PAY_RATE = 'ADD_PAY_RATE';
const UPDATE_PAY_RATE = 'UPDATE_PAY_RATE';
const DELETE_PAY_RATE = 'DELETE_PAY_RATE';

const addPayRateAction = createAction(ADD_PAY_RATE);
const updatePayRateAction = createAction(UPDATE_PAY_RATE);
const deletePayRateAction = createAction(DELETE_PAY_RATE);

const payRatesReducer = handleActions(
  {
    [ADD_PAY_RATE]: (state, action) => {
      const payRate = oFetch(action, 'payload.payRate');
      return [...state, payRate];
    },
    [UPDATE_PAY_RATE]: (state, action) => {
      const payRateToUpdate = oFetch(action, 'payload.payRate');
      return state.map((payRate) => {
        const payRateId = oFetch(payRate, 'id');
        const payRateToUpdateId = oFetch(payRateToUpdate, 'id');
        if (payRateId === payRateToUpdateId) {
          return payRateToUpdate;
        }
        return payRate;
      });
    },
    [DELETE_PAY_RATE]: (state, action) => {
      const payRateToDelete = oFetch(action, 'payload.payRate');
      const payRateToDeleteId = oFetch(payRateToDelete, 'id');
      return state.filter((payRate) => oFetch(payRate, 'id') !== payRateToDeleteId);
    },
  },
  [],
);

function normalizePayRate(params) {
  const [payRates, staffMembersCounts] = oFetch(params, 'payRates', 'staffMembersCounts');
  return payRates.reduce(
    (acc, payRate) => {
      const [payRateId, isAdminPayRate] = oFetch(payRate, 'id', 'isAdminPayRate');
      const counts = staffMembersCounts[payRateId] || 0;
      const normalizedPayRate = {
        ...payRate,
        staffMembersCounts: counts,
      };
      if (isAdminPayRate) {
        return {
          ...acc,
          adminRates: [...acc.adminRates, normalizedPayRate],
        };
      }
      return {
        ...acc,
        normalRates: [...acc.normalRates, normalizedPayRate],
      };
    },
    {
      normalRates: [],
      adminRates: [],
    },
  );
}

export default function usePayRatesApiCalls(initialData) {
  const [payRates, staffMembersCounts, WEEKLY_CALCULATION_TYPE, HOURLY_CALCULATION_TYPE] = oFetch(
    initialData,
    'payRates',
    'staffMembersCounts',
    'WEEKLY_CALCULATION_TYPE',
    'HOURLY_CALCULATION_TYPE',
  );
  const [payRatesState, dispatchPayRates] = useReducer(payRatesReducer, payRates);

  const [normalRates, adminRates] = oFetch(
    useMemo(() => normalizePayRate({ payRates: payRatesState, staffMembersCounts }), [
      payRatesState,
      staffMembersCounts,
    ]),
    'normalRates',
    'adminRates',
  );
  const payRatesOptions = [
    { value: WEEKLY_CALCULATION_TYPE, label: 'Salary Per Week' },
    { value: HOURLY_CALCULATION_TYPE, label: 'Incremental Per Hour' },
  ];

  function createPayRate(params) {
    const [values, handleClose] = oFetch(params, 'values', 'handleClose');

    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error', 'name', 'calculationType', 'rate'],
          });
          globalNotifications.showDefaultFailureMessage({ message: 'Create pay rate failed' });
          return Promise.resolve(errors);
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchPayRates(addPayRateAction(data));
        handleClose();
      },
    }).post(`/api/v1/pay_rates`, {
      ...values,
    });
  }

  function createAdminPayRate(params) {
    const [values, handleClose] = oFetch(params, 'values', 'handleClose');

    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error', 'name', 'calculationType', 'rate'],
          });
          globalNotifications.showDefaultFailureMessage({ message: 'Create pay rate failed' });
          return Promise.resolve(errors);
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchPayRates(addPayRateAction(data));
        handleClose();
      },
    }).post(`/api/v1/pay_rates/create_admin`, {
      ...values,
    });
  }

  function updatePayRate(params) {
    const [values, handleClose] = oFetch(params, 'values', 'handleClose');
    const payRateId = oFetch(values, 'id');

    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error', 'name', 'calculationType', 'rate'],
          });
          globalNotifications.showDefaultFailureMessage({ message: 'Create pay rate failed' });
          return Promise.resolve(errors);
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchPayRates(updatePayRateAction(data));
        handleClose();
      },
    }).put(`/api/v1/pay_rates/${payRateId}`, {
      ...values,
    });
  }

  function deletePayRate(params) {
    const payRate = oFetch(params, 'payRate');
    const payRateId = oFetch(payRate, 'id');

    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchPayRates(deletePayRateAction({ payRate }));
      },
    }).delete(`/api/v1/pay_rates/${payRateId}`);
  }

  return {
    normalRates: normalRates,
    adminRates: adminRates,
    payRatesOptions: payRatesOptions,
    createPayRate: createPayRate,
    createAdminPayRate: createAdminPayRate,
    deletePayRate: deletePayRate,
    updatePayRate: updatePayRate,
  };
}
