import React from 'react';
import oFetch from 'o-fetch';
import moment from 'moment';
import BossDatePicker from '@/components/react-dates/boss-date-picker';
import RcTimePicker from 'rc-time-picker';
import safeMoment from '@/lib/safe-moment';

export function BossFormDateTimePicker(props: any) {
  const { label } = props;
  const input = oFetch(props, 'input');
  const meta = oFetch(props, 'meta');
  const disabled = oFetch(props, 'disabled');
  const value = oFetch(input, 'value');
  const onChange = oFetch(input, 'onChange');

  function handleChange(selectedValue: any) {
    if (selectedValue === null) {
      onChange(selectedValue);
    } else {
      if (value === '' && selectedValue.format('DD:MM:YYYY') === moment().format('DD:MM:YYYY')) {
        const isoString = moment().add(1, 'minute').format();
        onChange(isoString);
      } else {
        const isoString = selectedValue.format();
        onChange(isoString);

      }
    }
  }

  function getDisabledHours(value: string) {
    if (value === '' || (safeMoment.iso8601Parse(value).format('DD:MM:YYYY') === moment().format('DD:MM:YYYY'))) {
      const prevHour = moment().subtract(1, 'hour').get('hour');
      return [...Array(prevHour + 1).keys()];
    } else {
      return [];
    }
  }

  function getDisabledMinutes(value: string) {
    if (value === '' || (safeMoment.iso8601Parse(value).format('DD:MM:YYYY') === moment().format('DD:MM:YYYY'))) {
      const prevMinute = moment().subtract(1, 'minute').get('minute');
      return [...Array(prevMinute + 1).keys()];
    } else {
      return [];
    }
  }

  return (
    <div className="boss-form__row boss-form__row_layout_wrap-xs">
      <div className="boss-form__field boss-form__field_layout_max">
        {label && (
          <p className="boss-form__label">
            <span className="boss-form__label-text">{label}</span>
          </p>
        )}
        <BossDatePicker
          id="date"
          date={value ? safeMoment.iso8601Parse(value) : null}
          onApply={handleChange}
          disabled={disabled}
          isDayBlocked={(mDay) => {
            const currentMoment = moment();
            if (mDay.format('MM:DD:YYYY') === currentMoment.format('MM:DD:YYYY')) {
              return false;
            }
            return mDay < currentMoment;
          }}
          invalid={meta.touched && (meta.error || meta.submitError)}
        />

        {meta.touched && (meta.error || meta.submitError) && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{meta.error || meta.submitError}</span>
            </p>
          </div>
        )}
      </div>
      <div className="boss-form__field boss-form__field_no-label boss-form__field_layout_third">
        <RcTimePicker
          className={`rc-time-picker time-picker-input time-picker-input_type_icon ${meta.touched && (meta.error || meta.submitError) && 'time-picker-input_state_error' } false`}
          placeholder="Select time ..."
          disabledHours={() => {
            return getDisabledHours(value);
          }}
          disabledMinutes={() => {
            return getDisabledMinutes(value);
          }}
          { ...(value ? { value: safeMoment.iso8601Parse(value) } : {}) }
          hideDisabledOptions
          showSecond={false}
          allowEmpty={false}
          disabled={disabled || value === ''}
          onChange={handleChange}
        />
        {meta.touched && (meta.error || meta.submitError) && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{meta.error || meta.submitError}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
