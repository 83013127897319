import React, { Fragment, useState, useRef, useMemo, useEffect } from 'react';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import oFetch from 'o-fetch';
import useOnClickOutside from 'use-onclickoutside';
import cn from 'classnames';

import utils from '@/lib/utils';

const scrollOptions = {
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  scrollbars: true,
  // stops ctrl clicking for a new tab because it leaves page in weird state
  click: true,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export function QuickMenu(props) {
  const [quickMenu, prefix] = oFetch(props, 'quickMenu', 'prefix');
  const [showMenu, setShowMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [fetching, setFetching] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, closeMenu);

  const filteredResult = useMemo(() => {
    return utils.quickMenuHighlightResults(utils.quickMenuFilter(searchQuery, quickMenu), searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    document.body.addEventListener('keypress', keyPress);
    document.body.addEventListener('keydown', keyPress);

    return () => {
      document.body.removeEventListener('keypress', keyPress);
      document.body.removeEventListener('keydown', keyPress);
    };
  }, []);

  function keyPress(e) {
    if (e.keyCode === 27) {
      setSearchQuery('');
      closeMenu();
      setFetching(false);
    } else {
      const input = document.getElementById('onFocusInput');
      if (input) {
        input.focus();
      }
    }
  }

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  function handleInputChange(e) {
    const {
      target: { value },
    } = e;

    setSearchQuery(value);
  }

  return (
    <div ref={ref}>
      <button
        className={`${prefix}-page-header__action ${prefix}-page-header__action_role_search`}
        onClick={toggleMenu}
      >
        Search
      </button>
      <div className={`${prefix}-page-header__dropdowns`}>
        {showMenu && (
          <div
            className={`${prefix}-page-header__dropdown ${prefix}-page-header__dropdown_role_search ${prefix}-page-header__dropdown_state_opened`}
          >
            <div className={`${prefix}-page-header__dropdown-header`}>
              {!fetching && (
                <p
                  className={`${prefix}-page-header__dropdown-label ${prefix}-page-header__dropdown-label_role_search`}
                >
                  Search
                </p>
              )}
              {fetching && <p className={`${prefix}-page-header__dropdown-label`}>Page Loading...</p>}
              {!fetching && (
                <div className={`${prefix}-page-header__dropdown-filter`}>
                  <div className={`${prefix}-form`}>
                    <div className={`${prefix}-form__field ${prefix}-form__field_position_last`}>
                      <div className={`${prefix}-form-field ${prefix}-form-field_adjust_quick-access-search`}>
                        <div className={`${prefix}-form-field__input`}>
                          <input
                            type="text"
                            value={searchQuery}
                            id="onFocusInput"
                            className={`${prefix}-form__input`}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={closeMenu}
                className={`${prefix}-page-header__dropdown-label ${prefix}-page-header__dropdown-label_role_action ${prefix}-page-header__dropdown-label_role_close ${prefix}-page-header__dropdown-label_type_icon`}
              >
                Close
              </button>
            </div>

            <div className={`${prefix}-page-header__dropdown-scroll`}>
              <ReactIScroll iScroll={iScroll} options={scrollOptions}>
                <div className={`${prefix}-page-header__dropdown-content`}>
                  <QuickMenuList
                    prefix={prefix}
                    filteredQuickMenuData={filteredResult}
                    itemRender={(args) => {
                      const childItem = oFetch(args, 'childItem');
                      const section = oFetch(args, 'section');
                      const iconTypeClass = oFetch(args, 'iconTypeClass');
                      return (
                        <QuickMenuItem
                          iconTypeClass={iconTypeClass}
                          fetching={fetching}
                          item={childItem}
                          color={oFetch(section, 'color')}
                          prefix={prefix}
                          onClick={() => setFetching(true)}
                        />
                      );
                    }}
                  />
                </div>
              </ReactIScroll>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function QuickMenuItem(props) {
  const [clicked, setClicked] = useState(false);
  const [item, color, prefix, fetching, onClick] = oFetch(
    props,
    'item',
    'color',
    'prefix',
    'fetching',
    'onClick',
  );
  const iconTypeClass = oFetch(props, 'iconTypeClass');

  const [description, path] = oFetch(item, 'description', 'path');
  const highlightedDescription = item.highlisghtedDescription;

  const itemClassNames = cn(`${prefix}-alias`, {
    [`${prefix}-alias_state_inactive`]: fetching && !clicked,
    [`${prefix}-alias_state_loading`]: clicked,
  });

  function handleClick() {
    setClicked(true);
    onClick();
  }

  return (
    <div className={itemClassNames}>
      <a href={path} onClick={handleClick} className={`${prefix}-alias__link`}>
        {!clicked && (
          <span
            className={`${prefix}-alias__icon ${iconTypeClass}`}
            style={{ backgroundColor: color, borderColor: color }}
          >
            {utils.generateQuickMenuAlias(description)}
          </span>
        )}
        {fetching && clicked && (
          <span className={`${prefix}-alias__spinner`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40" fill={color}>
              <path
                opacity=".25"
                d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
              />
              <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 16 16"
                  to="360 16 16"
                  dur="0.75s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </span>
        )}
        <span
          className={`${prefix}-alias__text`}
          style={{ color }}
          dangerouslySetInnerHTML={{ __html: highlightedDescription || description }}
        />
      </a>
    </div>
  );
}

export function QuickMenuList(props) {
  const [filteredQuickMenuData, prefix, itemRender] = oFetch(props, 'filteredQuickMenuData', 'prefix', 'itemRender');
  const bossSections = oFetch(filteredQuickMenuData, 'bossSections');
  const hasBossQuickMenuItems = oFetch(bossSections, 'length') > 0;
  const mossSections = oFetch(filteredQuickMenuData, 'mossSections');
  const hasMossQuickMenuItems = oFetch(mossSections, 'length') > 0;

  const renderSections = (args) => {
    const sections = oFetch(args, 'sections');
    const iconTypeClass = oFetch(args, 'iconTypeClass');

    return sections.map((section, key) => {
      return <div className={`${prefix}-quick-access__group`} key={key}>
        <div className={`${prefix}-quick-access__group-header`}>
          <h4
            className={`${prefix}-quick-access__group-title`}
            dangerouslySetInnerHTML={{ __html: section.highlightedName || section.name }}
          />
        </div>
        <div className={`${prefix}-quick-access__aliases`}>
          {oFetch(section, 'items').map((childItem, key) => (
            <div key={key} className={`${prefix}-quick-access__alias`}>
              {React.cloneElement(itemRender({childItem, section, iconTypeClass}))}
            </div>
          ))}
        </div>
      </div>
    });
  };

  return (
    <div className={`${prefix}-quick-access`}>
      { hasBossQuickMenuItems && <div className="boss-quick-access__area">
        <div className="boss-quick-access__area-header">
          <h3 className="boss-quick-access__area-title">Boss</h3>
        </div>
        { renderSections({sections: bossSections, iconTypeClass: `${prefix}-alias__icon_type_circular`}) }
      </div> }
      { hasMossQuickMenuItems && <div className="boss-quick-access__area">
        <div className="boss-quick-access__area-header">
          <h3 className="boss-quick-access__area-title">Moss</h3>
        </div>
        { renderSections({sections: mossSections, iconTypeClass: `${prefix}-alias__icon_type_rounded`}) }
      </div> }
    </div>
  );
}
