import React, { useEffect, useState } from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { $MossHourTagsAppProps, MossHourTagsAppData, MossHourTagsAppProps } from './types';
import { MossHourTagsIndexPage } from './moss-hour-tags-index-page';

export function MossHourTagsApp(props: unknown) {
  const mossHourTagAppProps: MossHourTagsAppProps = $MossHourTagsAppProps.parse(props);
  const { accessToken, ...rawMossHourTagData } = mossHourTagAppProps;
  const [mossHourTagAppData, setMossHourTagAppData] = useState<MossHourTagsAppData>(rawMossHourTagData);

  useEffect(() => {
    window.boss.accessToken = mossHourTagAppProps.accessToken;
  }, []);

  return (
    <ModalsProvider>
      <MossHourTagsIndexPage
        mossHourTagAppData={mossHourTagAppData}
        setMossHourTagAppData={setMossHourTagAppData}
      />
    </ModalsProvider>
  );
}