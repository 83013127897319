import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

export function faceIdLookup(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const imageUrl = oFetch(values, 'imageUrl');

  return bossRequestHttp({
    successHandler(data) {
      const [faceDetails, matchedStaffMembers, staffMemberFaceMatches, faceIdsWithoutMatches] = oFetch(
        data,
        'data.faceDetails',
        'data.matchedStaffMembers',
        'data.staffMemberFaceMatches',
        'data.faceIdsWithoutMatches',
      );
      onSuccess({
        faceDetails,
        imageUrl,
        matchedStaffMembers,
        staffMemberFaceMatches,
        faceIdsWithoutMatches,
      });
    },
    errorHandler(data) {
      const errors = oFetch(data, 'errors');
      return utils.normalizeFinalFormErrors(errors);
    },
  }).post('/api/v1/rekognition_test/face_id_lookup', {
    imageUrl,
  });
}

export function faceIdLookupByBase64(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const base64 = oFetch(values, 'base64');

  return bossRequestHttp({
    successHandler(data) {
      const [faceDetails, matchedStaffMembers, staffMemberFaceMatches, faceIdsWithoutMatches] = oFetch(
        data,
        'data.faceDetails',
        'data.matchedStaffMembers',
        'data.staffMemberFaceMatches',
        'data.faceIdsWithoutMatches',
      );
      onSuccess({
        faceDetails,
        imageUrl: base64,
        matchedStaffMembers,
        staffMemberFaceMatches,
        faceIdsWithoutMatches,
      });
    },
    errorHandler(data) {
      const errors = oFetch(data, 'errors');
      return utils.normalizeFinalFormErrors(errors);
    },
  }).post('/api/v1/rekognition_test/face_id_lookup', {
    base64,
  });
}
