import { BossFormSelectNew } from '@/components/boss-form';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';

type Props = {
  processing: boolean;
  mainWhatsappInstanceId: any;
  options: any[];
};

export function WhatsappFeatureInstancesField(props: Props) {
  const { processing, options, mainWhatsappInstanceId } = props;
  const { input: { value } } = mainWhatsappInstanceId;

  const currentFallbackOptions = useMemo(() => {
    return options.filter((option) => {
      return option.value !== value;
    });
  }, [value]);

  return (
    <>
      <Field
        name={'mainWhatsappInstanceId'}
        label="Main Whatsapp Instance"
        parse={value => value}
        disabled={processing}
        options={options}
        component={BossFormSelectNew}
      />
      <Field
        name={'fallbackWhatsappInstanceId'}
        label="Fallback Whatsapp Instance"
        parse={value => value}
        disabled={processing}
        options={currentFallbackOptions}
        component={BossFormSelectNew}
        clearable
      />
    </>
  );
}
