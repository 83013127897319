import z from 'zod';
import { $BossUiTime, DATE_REGEX } from '@/lib/schema-funcs';

export const REQUIRING_UPDATE_REPORT_STATUS = 'requiring_update' as const;
export const PROCESSING_REPORT_STATUS = 'processing' as const;
export const READY_REPORT_STATUS = 'ready' as const;
export const DONE_REPORT_STATUS = 'done' as const;
export const DORMANT_REPORT_STATUS = 'dormant' as const;

export const $MossFinanceReportStatus = z.union([
  z.literal(REQUIRING_UPDATE_REPORT_STATUS),
  z.literal(PROCESSING_REPORT_STATUS),
  z.literal(READY_REPORT_STATUS),
  z.literal(DONE_REPORT_STATUS),
  z.literal(DORMANT_REPORT_STATUS),
]);

export const $MossFinanceReport = z.object({
  id: z.number(),
  note: z.union([z.string(), z.null()]),
  completable: z.boolean(),
  calculatedGrossPayCents: z.number().int().nonnegative(),
  mossStaffMemberId: z.number().int().positive(),
  mossStaffMemberName: z.string().min(1),
  mondayHoursCount: z.number().nonnegative(),
  tuesdayHoursCount: z.number().nonnegative(),
  wednesdayHoursCount: z.number().nonnegative(),
  thursdayHoursCount: z.number().nonnegative(),
  fridayHoursCount: z.number().nonnegative(),
  saturdayHoursCount: z.number().nonnegative(),
  sundayHoursCount: z.number().nonnegative(),
  mondayHasDeletedHours: z.boolean(),
  tuesdayHasDeletedHours: z.boolean(),
  wednesdayHasDeletedHours: z.boolean(),
  thursdayHasDeletedHours: z.boolean(),
  fridayHasDeletedHours: z.boolean(),
  saturdayHasDeletedHours: z.boolean(),
  sundayHasDeletedHours: z.boolean(),
  owedHoursMinuteCount: z.number().int().nonnegative(),
  mossPayRateDescription: z.string().min(1),
  total: z.number().nonnegative(),
  totalHoursCount: z.number().nonnegative(),
  status: $MossFinanceReportStatus,
  hoursPending: z.boolean(),
  mossStaffMemberDisabled: z.boolean(),
  containsTimeShiftedOwedHours: z.boolean(),
  daysNeedingCompletion: z.record(
    z.string().regex(DATE_REGEX),
    z.array(z.string().min(1)),
  ),
  pendingCalculation: z.boolean(),
  mossStaffTypeName: z.string().min(1),
  mossStaffTypeId: z.number().int().positive(),
  completedAt: z.union([$BossUiTime(z), z.null()]),
  noteCreatedBy: z.union([z.string().min(1), z.null()]),
  noteCreatedAt: z.union([$BossUiTime(z), z.null()]),
  dataIncomplete: z.boolean(),
  hasIncompleteHolidays: z.boolean(),
  completionDateReached: z.boolean(),
  paidHolidayDaysCount: z.number().int().nonnegative(),
  sickLeaveDaysCount: z.number().int().nonnegative(),
  containsTimeShiftedHolidays: z.boolean(),
  frontendId: z.number().int().positive(),
}).strict();

export type MossFinanceReport = z.infer<typeof $MossFinanceReport>;