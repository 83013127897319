import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { BigNumber } from 'bignumber.js';
import { Form, Field } from 'react-final-form';
import { BossFormInput } from '@/components/boss-form';
import { BossFormDateTimePicker } from './boss-form-date-time-picker';

export function ExceptionForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={(formValues, ...rest) => {
          const rawHourlyRateCents = oFetch(formValues, 'hourlyRateCents');
          const values = {
            ...formValues,
            hourlyRateCents:
              rawHourlyRateCents && new BigNumber(rawHourlyRateCents).times(100).integerValue().toNumber(),
          };
          return onSubmit(values, ...rest);
        }}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <div className="boss-table__form">
              {submitError && (
                <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                  <p className="boss-alert__text">{submitError}</p>
                </div>
              )}
              <div className="boss-form">
                <Field
                  name="hourlyRateCents"
                  parse={value => value}
                  label="Rate"
                  unit="£"
                  isCurrency
                  component={BossFormInput}
                />
                <Field
                  name="startsAt"
                  parse={value => value}
                  label="Starts At"
                  component={BossFormDateTimePicker}
                />
                <Field
                  name="endsAt"
                  parse={value => value}
                  label="Ends At"
                  component={BossFormDateTimePicker}
                />

                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-button_role_add boss-form__submit"
                    disabled={submitting}
                    type="submit"
                  >
                    Create Exception
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
