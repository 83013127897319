import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class FilterList extends Component {
  renderItems = items => {
    const [itemRender, selected] = oFetch(this.props, 'itemRender', 'selected');
    return items.map((item, index) => {
      const id = oFetch(item, 'id');
      const isItemSelected = selected.includes(id);
      return React.cloneElement(itemRender(item), {
        key: index,
        selected: isItemSelected,
      });
    });
  };

  render() {
    const items = oFetch(this.props, 'items');
    return <div className="boss-page-main__controls">{this.renderItems(items)}</div>;
  }
}
