import React from 'react';
import oFetch from 'o-fetch';
import PayRatesForm from './pay-rates-form';

export default function AddPayRateModal(props) {
  const [onSubmit, payRateType, payRatesOptions] = oFetch(
    props,
    'onSubmit',
    'payRateType',
    'payRatesOptions',
  );
  const initialValues = {
    name: '',
    calculationType: null,
    rate: null,
  };

  return (
    <PayRatesForm
      onSubmit={onSubmit}
      payRatesOptions={payRatesOptions}
      initialValues={initialValues}
      payRateType={payRateType}
      buttonTitle="Create Pay Rate"
    />
  );
}
