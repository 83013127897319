import React from 'react';
import cn from 'classnames';
import oFetch from 'o-fetch';
import { MONTH_VIEW, WEEK_VIEW } from './calendar';

export default function CalendarToolbar(params) {
  const [mDate, view, nextHref, prevHref, todayHref, weekHref, monthHref, mTodayDate] = oFetch(
    params,
    'mDate',
    'view',
    'nextHref',
    'prevHref',
    'todayHref',
    'weekHref',
    'monthHref',
    'mTodayDate',
  );

  const monthButtonClassNames = cn('boss-planner__switch boss-planner__switch_role_view', {
    'boss-planner__switch_state_active': view === MONTH_VIEW,
  });
  const weekButtonClassNames = cn('boss-planner__switch boss-planner__switch_role_view', {
    'boss-planner__switch_state_active': view === WEEK_VIEW,
  });

  const isToday = mDate.isSame(mTodayDate);
  const todayButtonActiveClass = isToday ? 'boss-planner__switch_state_active' : '';
  return (
    <div className="boss-planner__toolbar">
      <h2 className="boss-planner__date">{mDate.format('MMMM YYYY')}</h2>
      <div className="boss-planner__switches boss-planner__switches_role_views">
        {view === MONTH_VIEW ? (
          <button className={monthButtonClassNames} type="button">
            Month
          </button>
        ) : (
          <a href={monthHref} className={monthButtonClassNames} type="button">
            Month
          </a>
        )}
        {view === WEEK_VIEW ? (
          <button className={weekButtonClassNames} type="button">
            Week
          </button>
        ) : (
          <a href={weekHref} className={weekButtonClassNames} type="button">
            Week
          </a>
        )}
      </div>
      <div className="boss-planner__switches boss-planner__switches_role_navigation">
        <a
          href={todayHref}
          className={`boss-planner__switch boss-planner__switch_role_view ${todayButtonActiveClass}`}
          type="button"
        >
          Today
        </a>
        <a href={prevHref} className="boss-planner__switch boss-planner__switch_role_prev">
          Prev
        </a>
        <a href={nextHref} className="boss-planner__switch boss-planner__switch_role_next">
          Next
        </a>
      </div>
    </div>
  );
}

CalendarToolbar.defaultProps = {
  weekHref: null,
  monthHref: null,
};
