import { combineReducers } from 'redux';
import questionnaireResponses from './questionnaire-responses-reducer';
import pagination from './pagination-reducer';
import staticData from './static-reducer';

export default combineReducers({
  questionnaireResponses,
  pagination,
  staticData,
});
