import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const staffTypesSelector = state => state.getIn(['profile', 'staffTypes']);
export const payRatesSelector = state => state.getIn(['profile', 'payRates']);
export const venuesSelector = state => state.getIn(['profile', 'venues']);
export const accessibleVenuesSelector = state => state.getIn(['profile', 'accessibleVenues']);
export const accessiblePayRatesSelector = state => state.getIn(['profile', 'accessiblePayRates']);
export const accessibleBaseStandardSecurityPayRatesSelector = state =>
  state.getIn(['profile', 'accessibleBaseStandardSecurityPayRates']);
export const accessibleBaseStewardSecurityPayRatesSelector = state =>
  state.getIn(['profile', 'accessibleBaseStewardSecurityPayRates']);
export const permissionsDataSelector = state => state.getIn(['profile', 'permissionsData']);
