import React, { useState, useEffect, useRef } from 'react';
import oFetch from 'o-fetch';
import useOnClickOutside from 'use-onclickoutside';

import StaffMemberItem from './staff-member-item';

function HeaderRow({ children }) {
  return (
    <div className="boss-staff-summary__cell boss-staff-summary__cell_role_header">
      {children}
    </div>
  );
}

function Header() {
  return (
    <div className="boss-staff-summary__row boss-staff-summary__row_role_header">
      <HeaderRow>Name</HeaderRow>
      <HeaderRow>Shifts</HeaderRow>
      <HeaderRow>Holidays</HeaderRow>
      <HeaderRow>Preferences</HeaderRow>
      <HeaderRow>Action</HeaderRow>
    </div>
  );
}

const PAGE_SIZE = 10;

function StaffMemberList(props) {
  const [currentStaffId, setCurrentStaffId] = useState(null);
  const [staffMembers, setStaffMembers] = useState(props.staffMembers.slice(0, PAGE_SIZE));
  const tooltipEl = useRef(null);
  const prevStaffMemberSize = useRef(props.staffMembers.size);
  useOnClickOutside(tooltipEl, handleCloseTooltip);

  useEffect(() => {
    if (prevStaffMemberSize.current !== props.staffMembers.size) {
      prevStaffMemberSize.current = props.staffMembers.size;
      setStaffMembers(props.staffMembers.slice(0, PAGE_SIZE));
    }
  }, [props.staffMembers.size]);

  useEffect(() => {
    setStaffMembers(prev => {
      const currentStaffMemberIds = prev.map(sm => sm.get('id'));
      return props.staffMembers.filter(staffMember => {
        return currentStaffMemberIds.includes(staffMember.get('id'));
      });
    });
  }, [props.staffMembers]);

  function handleCloseTooltip() {
    document.body.classList.remove("boss-body_state_inactive");
    setCurrentStaffId(null);
  }

  function handleAddShiftClick(staffId) {
    document.body.classList.add("boss-body_state_inactive");
    setCurrentStaffId(staffId);
  }

  function renderStaffMembers() {
    const sRotaDate = oFetch(props, 'sRotaDate');
    return staffMembers.map((staffMember, key) => {
      return (
        <StaffMemberItem
          ref={tooltipEl}
          staffMember={staffMember}
          staffTypes={props.staffTypes}
          sRotaDate={sRotaDate}
          onAddShiftClick={handleAddShiftClick}
          currentStaffId={currentStaffId}
          key={key}
          handleAfterAdd={handleCloseTooltip}
          isMultipleShift={props.isMultipleShift}
          rotaStatus={props.rotaStatus}
        />
      );
    });
  }

  function getLoadMoreSizes() {
    const currentSize = staffMembers.size;
    const fullSize = props.staffMembers.size;
    let loadSize = PAGE_SIZE;
    if ((fullSize - currentSize) < PAGE_SIZE) {
      loadSize = fullSize - currentSize;
    }

    return { currentSize, loadSize };
  }

  function loadMore() {
    setStaffMembers((prev) => {
      const { currentSize, loadSize } = getLoadMoreSizes();
      const staffMembers = props.staffMembers.slice(currentSize, currentSize + loadSize);
      return prev.concat(staffMembers);
    });
  }

  function renderLoadMoreButton() {
    return (
      <div className="boss-staff-summary__actions">
        <button
          onClick={loadMore}
          className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
        >Load More</button>
      </div>
    );
  }

  const showLoadMore = staffMembers.size !== props.staffMembers.size;

  return (
    <div className="boss-rotas__staff">
      <div className="boss-staff-summary boss-staff-summary_page_rotas-daily">
        <Header />
        {renderStaffMembers()}
        {showLoadMore && renderLoadMoreButton()}
      </div>
    </div>
  );
}

export default StaffMemberList;
