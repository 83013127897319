import React, { Component } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import oFetch from 'o-fetch';

const moment = extendMoment(Moment);

export default class WeekDayFilter extends Component {
  getFirstDayOfWeekMoment = currentDate => {
    return currentDate.clone().startOf('isoWeek');
  };

  getLastDayOfWeekMoment = currentDate => {
    return currentDate.clone().endOf('isoWeek');
  };
  getWeekRange = currentDate => {
    const mWeekStart = this.getFirstDayOfWeekMoment(currentDate);
    const mWeekEnds = this.getLastDayOfWeekMoment(currentDate);
    return moment.range(mWeekStart, mWeekEnds);
  };

  renderWeek = currentDate => {
    const weekRange = this.getWeekRange(currentDate);
    const renderDay = oFetch(this.props, 'renderDay');
    return Array.from(weekRange.by('day')).map((day, index) => {
      return React.cloneElement(renderDay(day), { key: index });
    });
  };

  render() {
    const currentDate = oFetch(this.props, 'date');
    return (
      <div className="boss-page-main__filter">
        <div className="boss-form">
          <div className="boss-form__field">
            <div className="boss-form__switcher boss-form__switcher_role_week">{this.renderWeek(currentDate)}</div>
          </div>
        </div>
      </div>
    );
  }
}
