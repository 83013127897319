import oFetch from 'o-fetch';
import React from 'react';

import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { Attachment } from '../../attachment';

export function TaskCompletion(props) {
  const completion = oFetch(props, 'completion');
  const [attachments, note, creatorFullName, createdAt] = oFetch(
    completion,
    'attachments',
    'note',
    'creatorFullName',
    'createdAt',
  );
  const hasAttachments = attachments.length > 0;
  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);

  function renderAttachments(attachments) {
    return attachments.map(attachment => {
      const attachmentId = oFetch(attachment, 'id');
      return <Attachment key={attachmentId} attachment={attachment} />;
    });
  }

  return (
    <div className="boss-task__result">
      <div className="boss-task__result-content">
        <p className="boss-task__result-text">{note}</p>
        {hasAttachments && (
          <div className="boss-task__result-attachments">{renderAttachments(attachments)}</div>
        )}
        <p className="boss-task__result-text boss-task__result-text_role_meta">
          {creatorFullName} @ {formattedCreatedAt}
        </p>
      </div>
    </div>
  );
}
