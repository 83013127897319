import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { appRoutes } from '@/lib/legacy-routes';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';

import oFetch from 'o-fetch';
import Dashboard from './dashboard';
import CardList from './card-list';
import ReportList from './report-list';
import ReportItem from './report-item';
import PayslipModal from './payslip-modal';
import { sendPayslipEmail } from '../requests';

function Page(props) {
  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');
  const date = oFetch(props, 'date');
  const venueId = oFetch(props, 'venueId');
  const filterType = oFetch(props, 'filterType');
  const weekDates = oFetch(props, 'weekDates');
  const staffTypesWithFinanceReports = oFetch(props, 'staffTypesWithFinanceReports');
  const permissions = oFetch(props, 'permissions');

  const paymentWeekData = oFetch(props, 'paymentWeekData');
  const paymentDataByStaffMemberId = oFetch(props, 'paymentDataByStaffMemberId');

  const { openModal } = useModal();

  function handleDateChange(selection) {
    goToPayrollReportsPage({
      startDate: oFetch(selection, 'startUIDate'),
      venueId: oFetch(props, 'venueId'),
    });
  }

  function goToPayrollReportsPage({ startDate, venueId }) {
    window.location.href = appRoutes.payrollReports({
      startDate,
      venueId,
    });
  }

  function handleFilterChange(filter) {
    const changePayRateFilter = oFetch(props, 'changePayRateFilter');
    changePayRateFilter({ filter });
  }

  function openPayslipModal(params) {
    const [payslip, fullName] = oFetch(params, 'payslip', 'fullName');

    return openModal({
      onSubmit(closeModal) {
        return sendPayslipEmail({ payslip, closeModal });
      },
      config: {
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payslip
            </div>
          );
        },
        baseClassName: 'boss-modal-window boss-modal-window_role_payslip',
      },
      props: {
        payslip: payslip,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PayslipModal,
    });
  }

  return (
    <div className="boss-page-main boss-page-main_adjust_payroll-reports">
      <Dashboard
        title="Payroll Reports"
        date={date}
        startDate={startDate}
        endDate={endDate}
        venueId={venueId}
        filterType={filterType}
        onDateChange={handleDateChange}
        onFilterChange={handleFilterChange}
      />
      <CardList
        staffTypesWithFinanceReports={staffTypesWithFinanceReports}
        paymentWeekData={paymentWeekData}
        permissions={permissions}
        itemRenderer={staffType => {
          return (
            <ReportList
              staffType={staffType}
              startDate={startDate}
              paymentDataByStaffMemberId={paymentDataByStaffMemberId}
              itemRenderer={(report, reportPaymentDataItem) => {
                return (
                  <ReportItem
                    weekDates={weekDates}
                    report={report}
                    reportPaymentData={reportPaymentDataItem}
                    startDate={startDate}
                    endDate={endDate}
                    onOpenPayslip={openPayslipModal}
                    paymentWeekData={paymentWeekData}
                  />
                );
              }}
            />
          );
        }}
      />
    </div>
  );
}

Page.propTypes = {
  date: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  filterType: PropTypes.string.isRequired,
  staffTypesWithFinanceReports: ImmutablePropTypes.list.isRequired,
  weekDates: ImmutablePropTypes.list.isRequired,
  changePayRateFilter: PropTypes.func.isRequired,
};

export default Page;
