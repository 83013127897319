import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';

export function AcceptTaskEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, at] = oFetch(event, 'userFullName', 'at');

  const formattedAt = useMemo(() => atFormat(at), [at]);

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_assigned">
      <p className="boss-overview__text">
        Accepted by {userFullName} @ {formattedAt}
      </p>
    </li>
  );
}
