import { createAction } from 'redux-actions';
import queryString from 'query-string';

import * as types from './types';

export const loadInitialStateAction = createAction(types.LOAD_INITIAL_STATE);
export const changeFilterInStoreAction = createAction(types.CHANGE_FILTER);

export const changeFilterAction = filter => dispatch => {
  const queryStringObject = queryString.parse(window.location.search);

  window.history.pushState(
    'state',
    'title',
    `${window.location.pathname}?${queryString.stringify({ ...queryStringObject, filter })}`,
  );
  dispatch(changeFilterInStoreAction(filter));
};
