import React from 'react';
import cn from 'classnames';

export default function DashboardWrapper({ classes = '', children }) {
  const dashboardClasses = cn('boss-page-dashboard', { [classes]: !!classes });
  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className={dashboardClasses}>{children}</div>
      </div>
    </div>
  );
}
