import React from 'react';
import oFetch from 'o-fetch';
import RcTimePicker from 'rc-time-picker';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import 'rc-time-picker/assets/index.css';
import './time.sass';

export function BossFormDatetimePicker(props) {
  const [meta, input] = oFetch(props, 'meta', 'input');
  const { fieldClassName, label } = props;
  const { error, touched } = meta;
  const [onChange, value, name] = oFetch(input, 'onChange', 'value', 'name');
  const fieldClassNames = cn('boss-form__field', { [fieldClassName]: fieldClassName });
  const fieldLabel = label === undefined ? name : label;

  return (
    <div className={fieldClassNames}>
      <label className="boss-form__label">
        <span className="boss-form__label-text">{fieldLabel}</span>
      </label>
      <RcTimePicker
        className={`time-picker-input time-picker-input_type_icon ${
          touched && error && 'time-picker-input_state_error'
        }`}
        placeholder="Select time ..."
        value={value ? safeMoment.iso8601Parse(value) : null}
        hideDisabledOptions
        showSecond={false}
        onChange={onChange}
      />
      {touched && error && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error}</span>
          </p>
        </div>
      )}
    </div>
  );
}
