import React from 'react';
import oFetch from 'o-fetch';
import { BasicInformationForm, WorkForm, VenueForm, PreviewForm, AccessoriesForm } from './forms';
import * as c from '../constants';

export default function AddStaffMemberWizard(props) {
  const [currentPage, onNextPage, onPreviousPage, staffMemberGeneralFieldsValidation] = oFetch(
    props,
    'currentPage',
    'onNextPage',
    'onPreviousPage',
    'staffMemberGeneralFieldsValidation',
  );
  const onSubmit = oFetch(props, 'onSubmit');

  const [
    genderOptions,
    payRateOptions,
    forStandardSecurityStaffSecurityPayRatesOptions,
    forStewardSecurityStaffSecurityPayRatesOptions,
    accessibleVenueOptions,
    venueOptions,
    staffTypeOptions,
    employmentStatusesOptions,
    isSecurity,
    isSteward,
    securityStaffTypesIds,
  ] = oFetch(
    props,
    'options.genderOptions',
    'options.payRateOptions',
    'options.forStandardSecurityStaffSecurityPayRatesOptions',
    'options.forStewardSecurityStaffSecurityPayRatesOptions',
    'options.accessibleVenueOptions',
    'options.venueOptions',
    'options.staffTypeOptions',
    'options.employmentStatusesOptions',
    'options.isSecurity',
    'options.isSteward',
    'options.securityStaffTypesIds',
  );
  if (currentPage === 1) {
    return (
      <BasicInformationForm
        initialValues={{
          ...c.BASIC_INFORMATION_FORM_INITIAL_VALUES,
          ...c.WORK_FORM_INITIAL_VALUES,
          ...c.VENUE_FORM_INITIAL_VALUES,
          ...c.ACCESSORIES_FORM_INITIAL_VALUES,
        }}
        isSecurity={isSecurity}
        isSteward={isSteward}
        securityStaffTypesIds={securityStaffTypesIds}
        forStandardSecurityStaffSecurityPayRatesOptions={forStandardSecurityStaffSecurityPayRatesOptions}
        forStewardSecurityStaffSecurityPayRatesOptions={forStewardSecurityStaffSecurityPayRatesOptions}
        staffTypeOptions={staffTypeOptions}
        genderOptions={genderOptions}
        onSubmit={staffMemberGeneralFieldsValidation}
      />
    );
  }
  if (currentPage === 2) {
    return (
      <WorkForm
        payRateOptions={payRateOptions}
        forStandardSecurityStaffSecurityPayRatesOptions={forStandardSecurityStaffSecurityPayRatesOptions}
        forStewardSecurityStaffSecurityPayRatesOptions={forStewardSecurityStaffSecurityPayRatesOptions}
        staffTypeOptions={staffTypeOptions}
        isSecurity={isSecurity}
        isSteward={isSteward}
        employmentStatusesOptions={employmentStatusesOptions}
        previousPage={onPreviousPage}
        onSubmit={onNextPage}
      />
    );
  }
  if (currentPage === 3) {
    return (
      <VenueForm
        accessibleVenueOptions={accessibleVenueOptions}
        venueOptions={venueOptions}
        isSecurity={isSecurity}
        previousPage={onPreviousPage}
        onSubmit={onNextPage}
      />
    );
  }

  if (currentPage === 4) {
    return <AccessoriesForm previousPage={onPreviousPage} onSubmit={onNextPage} />;
  }

  if (currentPage === 5) {
    return (
      <PreviewForm
        isSecurity={isSecurity}
        isSteward={isSteward}
        previousPage={onPreviousPage}
        onSubmit={onSubmit}
      />
    );
  }
  throw new Error('Wrong page');
}
