import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const assignedStaffTypes = oFetch(action, 'payload.assignedStaffTypes');
      return assignedStaffTypes;
    },
    [types.ADD_STAFF_TYPES]: (state, action) => {
      const staffTypes = oFetch(action, 'payload.staffTypes');
      return [...state, ...staffTypes];
    },
    [types.REMOVE_STAFF_TYPE]: (state, action) => {
      const staffTypeId = oFetch(action, 'payload.staffTypeId');

      return state.filter(staffType => oFetch(staffType, 'id') !== staffTypeId);
    },
  },
  initialState,
);
