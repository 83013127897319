import { bossRequestHttp } from "@/lib/request-api";
import oFetch from "o-fetch";
import utils from "@/lib/utils";
import { PublicHolidaysFormValues } from "./components";
import { $BossHttpSuccessHandlerParams, BossHttpSuccessHandlerParams } from './types/public-holiday';

export type CreatePublicHolidayParams = {
  values: PublicHolidaysFormValues;
  onSuccess: (publicHoliday: any) => void;
};

export type DeletePublicHolidayParams = {
  publicHolidayId: number;
  onSuccess: (publicHoliday: any) => void;
};

export function createPublicHoliday(params: CreatePublicHolidayParams) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  const date = oFetch(values, 'date');
  const description = oFetch(values, 'description');

  return bossRequestHttp({
    errorHandler(params: any) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'date'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
      return false;
    },
    successHandler(params: BossHttpSuccessHandlerParams) {
      const parsedParams = $BossHttpSuccessHandlerParams.parse(params);
      const data = oFetch(parsedParams, 'data');
      const publicHoliday = oFetch(data, 'publicHoliday');
      const globalNotifications = oFetch(parsedParams, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({});
      onSuccess(publicHoliday);
    },
  }).post(`/api/v1/public_holidays`, {
    date,
    description,
  });
}

export function deletePublicHoliday(params: DeletePublicHolidayParams) {
  const publicHolidayId = oFetch(params, 'publicHolidayId');
  const onSuccess = oFetch(params, 'onSuccess');

  return bossRequestHttp({
    errorHandler(params: any) {},
    successHandler(params: any) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = oFetch(params, 'data');
      const publicHoliday = oFetch(data, 'publicHoliday');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(publicHoliday);
    },
  }).delete(`/api/v1/public_holidays/${publicHolidayId}`);
}
