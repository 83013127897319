import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export function AreYouSureToWipeBankDetailsModal(props) {
  const [onClose, onSubmit] = oFetch(props, 'onClose', 'onSubmit');

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
        <div className="boss-modal-window__message-group">
          <p className="boss-modal-window__message-note">
            <span style={{ textAlign: 'center' }} className="boss-modal-window__message-line">
              These bank details will be permanently wiped this staff member's record. Typically, You should
              only do this if the bank details are invalid.
            </span>
          </p>
        </div>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onClose}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          CANCEL
        </button>
        <AsyncButton
          className="boss-button boss-button_role_cancel boss-modal-window__button"
          text="WIPE BANK DETAILS"
          pendingText="WIPING ..."
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
