import oFetch from 'o-fetch';
import React, { Fragment, useMemo, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { BossFormSelectNew } from '@/components/boss-form';

export function BossFormRoleVenueSelect(props) {
  const [permittedRolesOptions, venuesOptions, venuesAccess, roleName, venuesName] = oFetch(
    props,
    'permittedRolesOptions',
    'venuesOptions',
    'venuesAccess',
    'roleName',
    'venuesName',
  );
  const [role, venues] = oFetch(props, roleName, venuesName);
  const roleValue = oFetch(role, 'input.value');
  const prevRoleValue = useRef(roleValue);
  const hasAllVenueAccess = useMemo(() => {
    if (!roleValue) {
      return false;
    }
    return oFetch(venuesAccess[roleValue], 'hasAllVenueAccess');
  }, [roleValue]);

  useEffect(() => {
    if (prevRoleValue.current !== roleValue) {
      const venueIdsChange = oFetch(venues, 'input.onChange');
      if (hasAllVenueAccess) {
        const allVenueIds = venuesOptions.map(venueOption => oFetch(venueOption, 'value'));
        venueIdsChange(allVenueIds);
      } else {
        venueIdsChange([]);
      }
    }
  }, [roleValue]);

  function renderVenuesSelect() {
    if (!roleValue) {
      return null;
    }
    return (
      <Field
        component={BossFormSelectNew}
        name={venuesName}
        multi
        selectAll
        label="Venues"
        options={venuesOptions}
      />
    );
  }

  return (
    <Fragment>
      <Field
        name={roleName}
        label="Role"
        required
        options={permittedRolesOptions}
        clearable={true}
        component={BossFormSelectNew}
      />
      {renderVenuesSelect()}
    </Fragment>
  );
}
