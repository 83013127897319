import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormSelectNew } from '@/components/boss-form';

export function PermanentAccessForm(props) {
  const [initialValues, onSubmit, isPersisted] = oFetch(props, 'initialValues', 'onSubmit', 'isPersisted');
  const venuesOptions = oFetch(props, 'venuesOptions');

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p
                key={index}
                className="boss-alert__text"
              >
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError, pristine }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-form">
              <Field
                name="venueIds"
                label="Permanent Access"
                component={BossFormSelectNew}
                multi
                selectAll
                parse={value => value}
                options={venuesOptions}
              />
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  className="boss-button boss-button_role_add boss-form__submit"
                  disabled={submitting || (isPersisted && pristine)}
                  type="submit"
                >
                  Update permanent access
                </button>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
