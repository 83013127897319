import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';

import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import * as actions from './redux/actions';
import QuizCategoryTags from './quiz-category-tags';
import reducers from './redux/reducers';

export function QuizCategoryTagsApp(props) {
  const [store, setStore] = useState(null);
  const accessToken = oFetch(props, 'accessToken');
  const [quizCategoryUrl, quizCategory] = oFetch(props, 'quizCategoryUrl', 'quizCategory');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialState(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <QuizCategoryTags quizCategoryUrl={quizCategoryUrl} quizCategory={quizCategory} />
    </Provider>
  );
}
