import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {};
export const paymentDataByStaffMemberIdReducer = handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const payload = oFetch(action, 'payload');
      return oFetch(payload, 'paymentDataByStaffMemberId');
    }
  },
  initialState,
)
