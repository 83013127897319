import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { DashboardFilterWrapper } from '../../components/dashboard';
import { UIInputField, UISelectField } from '../../components/ui-fields';
import { capitalize } from '../../utils';

export default function Filter(props) {
  const [filterParams, tagOptions, statuses] = oFetch(
    props,
    'filterParams',
    'tagOptions',
    'statuses',
  );
  const [name, status, tags] = oFetch(filterParams, 'name', 'status', 'tags');
  const [nameValue, setNameValue] = useState(name);
  const [statusValue, setStatusValue] = useState(status);
  const [tagsValue, setTagsValue] = useState(tags);
  const statusOptions = useMemo(() => {
    return getStatuses();
  }, [statuses]);

  function getStatuses() {
    return statuses.map(status => {
      return {
        value: status,
        label: capitalize(status),
      };
    });
  }

  return (
    <DashboardFilterWrapper>
      <form className="purple-form">
        <div className="purple-form__row">
          <div className="purple-form__field purple-form__field_size_half">
            <div className="purple-form-field">
              <UIInputField
                label="Name"
                name="name"
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
              />
            </div>
          </div>
          <div className="purple-form__field purple-form__field_size_half purple-form__field_position_last">
            <UISelectField
              label="Status"
              name="status"
              value={statusValue}
              onChange={setStatusValue}
              options={statusOptions}
            />
          </div>
        </div>
        <div className="purple-form__field">
          <UISelectField
            label="Tags"
            name="tags[]"
            value={tagsValue}
            onChange={setTagsValue}
            valueKey="id"
            labelKey="name"
            isMulti
            options={tagOptions}
          />
        </div>
        <div className="purple-form__field purple-form__field_size_min purple-form__field_justify_end purple-form__field_position_last">
          <button
            type="submit"
            className="purple-button purple-button_size_l purple-button_color_accent-primary purple-button_size_full-xs"
          >
            <span className="purple-button__text">Update</span>
          </button>
        </div>
      </form>
    </DashboardFilterWrapper>
  );
}
