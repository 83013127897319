import React from 'react';
import oFetch from 'o-fetch';
import Modal from 'react-modal';
import cn from 'classnames';

export default function CustomContentModal(props) {
  const children = oFetch(props, 'children');
  const { modalClass } = props;

  const modalClassNames = cn('ReactModal__Content ReactModal__Content--after-open purple-modal', {
    [modalClass]: true,
  });

  return (
    <Modal isOpen={true} contentLabel="Modal" className={modalClassNames} ariaHideApp={false}>
      {children}
    </Modal>
  );
}
