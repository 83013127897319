import React from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';

export default function MossPayRatesForm(props) {
  const [onSubmit, initialValues, mossPayRatesOptions, buttonTitle] = oFetch(
    props,
    'onSubmit',
    'initialValues',
    'mossPayRatesOptions',
    'buttonTitle',
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values, ...rest }) => {
        return (
          <div className="boss-page-main__group boss-page-main__group_adjust_pr-form">
            <div className="boss-form">
              <Field
                name="name"
                errorKey="submitError"
                parse={value => value}
                label="Name"
                component={BossFormInput}
              />
              <Field
                name="calculationType"
                label="Calculation Type"
                errorKey="submitError"
                options={mossPayRatesOptions}
                component={BossFormSelectNew}
              />
              <Field
                name="rate"
                label="Rate"
                unit="£"
                parse={value => value}
                isCurrency
                type="number"
                errorKey="submitError"
                component={BossFormInput}
              />
              <div className="boss-form__field boss-form__field_justify_mobile-center">
                <button
                  disabled={submitting}
                  onClick={handleSubmit}
                  className="boss-button boss-button_role_add boss-form__submit"
                  type="submit"
                >
                  {buttonTitle}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
