import oFetch from 'o-fetch';
import React, { Fragment, useState } from 'react';
import ContentWrapper from '@/components/content-wrapper';
import DashboardWrapper from '@/components/dashboard-wrapper';
import * as zip from '@/lib/training-materials-zip';
import * as actions from '../actions';
import { HtmlPreview } from './';
import { UploadTrainingMaterialsZip } from './upload-training-materials-zip';

export function CreateCategory(props) {
  const [category, updateCategoryHtml] = oFetch(props, 'category', 'updateCategoryHtml');
  const name = oFetch(category, 'name');

  const [html, setHTML] = useState(null);
  const [sections, setSections] = useState({});
  const [materialsZipFile, setMaterialsZipFile] = useState(null);
  const [showZipUpload, setShowZipUpload] = useState(true);
  const [saving, setSaving] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  async function handleHTMLUpload(params) {
    clearHtmlAndSections();
    const [processed, zipFile] = oFetch(params, 'processed', 'zipFile');
    const categoryId = oFetch(params, 'categoryId');
    const htmlFileContent = oFetch(processed, 'htmlFile.content');
    await actions.sanitizeHTML({
      htmlFileContent,
      categoryId,
      onFailure(errors) {
        setSubmitErrors(errors);
      },
      onSuccess(sanitizedContent) {
        const images = oFetch(processed, 'images');

        const htmlWithProcessedImages = zip.processImagesPath({ html: sanitizedContent, images });
        const sections = zip.parseHTMLSections(htmlWithProcessedImages);

        setMaterialsZipFile(zipFile);
        setSections(sections);
        setHTML(htmlWithProcessedImages);
        setShowZipUpload(false);
      },
    });
  }

  async function handleSave() {
    setSaving(true);
    await actions.uploadMaterialsZip({
      materialsZipFile,
      category,
      onSuccess: updateCategoryHtml,
      onFailure: setSubmitErrors,
    });
  }

  function clearHtmlAndSections() {
    setHTML(null);
    setSections({});
    setMaterialsZipFile(null);
  }

  function renderButtons() {
    if (materialsZipFile) {
      return (
        <Fragment>
          <button
            type="button"
            disabled={saving}
            className="boss-button boss-button_role_success boss-page-dashboard__button"
            onClick={handleSave}
          >
            {saving ? 'Saving ...' : 'Save'}
          </button>
          <button
            type="button"
            disabled={saving}
            className="boss-button boss-button_role_reload boss-page-dashboard__button"
            onClick={() => setShowZipUpload(true)}
          >
            Change Zip File
          </button>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_training-materials">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {name}
              </span>{' '}
              Training Materials
            </h1>
            <div className="boss-page-dashboard__buttons-group">{renderButtons()}</div>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {showZipUpload && (
          <UploadTrainingMaterialsZip
            categoryId={oFetch(category, 'id')}
            onUpload={handleHTMLUpload}
            submitErrors={submitErrors}
          />
        )}
        {html && (
          <div className="boss-page-main__group boss-page-main__group_context_stack boss-page-main__group_adjust_training-materials">
            <div className="boss-preview">
              <div className="boss-preview__group">
                <HtmlPreview
                  iFrameID={'preview'}
                  html={html}
                  title={'Preview'}
                  sections={sections}
                />
              </div>
            </div>
          </div>
        )}
      </ContentWrapper>
    </Fragment>
  );
}
