import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import BossSelect from './boss-select';
import { ColoredSingleOption, ColoredMultipleValue } from '@/components/boss-form/colored-select';

function VenueSelect(props) {
  function handleChange(values) {
    const onChange = oFetch(props, 'onChange');
    if (values === '') {
      onChange([]);
    } else {
      onChange(values.split(','));
    }
  }

  return (
    <BossSelect
      className={props.className}
      onChange={handleChange}
      selected={oFetch(props, 'selected')}
      options={oFetch(props, 'options')}
      label="name"
      value="id"
      mappedProps={{
        simpleValue: true,
        multi: true,
        valueComponent: ColoredMultipleValue,
        optionComponent: ColoredSingleOption,
      }}
    />
  );
}

VenueSelect.PropTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  venueTypes: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default VenueSelect;
