import React from 'react';
import oFetch from 'o-fetch';

import AcceptedHoursAcceptancePeriodForm from './accepted-hours-acceptance-period-form';

export default function AcceptedHoursAcceptancePeriodItem(props) {
  const [
    hoursAcceptancePeriod,
    onUnaccept,
    mossHourTags,
  ] = oFetch(
    props,
    'hoursAcceptancePeriod',
    'onUnaccept',
    'mossHourTags',
  );
  const frontendId = oFetch(hoursAcceptancePeriod, 'frontendId');
  const specialPayRateSecurityHours = oFetch(hoursAcceptancePeriod, 'specialPayRateSecurityHours');

  const hasSpecialSecurityHours = specialPayRateSecurityHours.length > 0;

  const initialValues = {
    frontendId: frontendId,
  };

  return (
    <div className="boss-hrc__shift">
      <div className="boss-time-shift boss-time-shift_updated">
        <div className="boss-time-shift__header">
          <h4 className="boss-time-shift__title">Shift</h4>
          {hasSpecialSecurityHours && (
            <div className="boss-time-shift__checkbox boss-time-shift__checkbox_state_hdh-active">
              <label className="boss-time-shift__checkbox-label">
                <span className="boss-time-shift__checkbox-label-text boss-time-shift__checkbox-label-text_type_checked-inactive">
                  Head Door
                </span>
              </label>
            </div>
          )}
        </div>
        <AcceptedHoursAcceptancePeriodForm
          initialValues={initialValues}
          mossHourTags={mossHourTags}
          hoursAcceptancePeriod={hoursAcceptancePeriod}
          form={`accepted-hours-acceptance-period-form-${frontendId}`}
          onSubmit={onUnaccept}
          hasSpecialSecurityHours={hasSpecialSecurityHours}
          specialPayRateSecurityHours={specialPayRateSecurityHours}
        />
      </div>
    </div>
  );
}
