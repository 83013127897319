import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

import EventForm from './event-form';

class AddEvent extends Component {
  render() {
    const [onSubmit, eventTypesOptions, venueOptions, currentVenue, localEventType, globalEventType] = oFetch(
      this.props,
      'onSubmit',
      'eventTypesOptions',
      'venueOptions',
      'currentVenue',
      'localEventType',
      'globalEventType',
    );
    const { selectedDate } = this.props;
    const initialValues = {
      title: null,
      description: null,
      date: selectedDate || null,
      startsAt: null,
      endsAt: null,
      eventType: null,
      venuesIds: null,
    };
    return (
      <div className="boss-modal-window__form">
        <EventForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          eventTypesOptions={eventTypesOptions}
          venueOptions={venueOptions}
          currentVenue={currentVenue}
          localEventType={localEventType}
          globalEventType={globalEventType}
          buttonText="Add event"
        />
      </div>
    );
  }
}
export default modalRedux(combineReducers({ form: formReducer }))(AddEvent);
