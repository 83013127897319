import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import { configureStore } from '../configure-store';
import MaintenanceContainer from './containers/maintenance';
import maintenance from './reducers/maintenance';
import forms from './reducers/forms';
import { setInitialData } from './actions/initial-load';
import oFetch from 'o-fetch';

export function MaintenanceApp(props) {
  const [store, setStore] = useState(null);
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore({ maintenance, forms });
    store.dispatch(setInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <LegacyGlobalProvider
      global={{
        emergencyRepublishPeriodSeconds: oFetch(props, 'emergencyRepublishPeriodSeconds'),
      }}
    >
      <Provider store={store}>
        <MaintenanceContainer />
      </Provider>
    </LegacyGlobalProvider>
  );
}
