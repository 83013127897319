import React, { Component } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { openContentModal } from '@/components/modals';

import ContentWrapper from '@/components/content-wrapper';
import { SimpleDashboard, DashboardFilter, AlertsList, AddAlert, AlertItem, EditAlert } from '../components';
import * as constants from '../constants';

export default function VenueAlertLevelsPage(props) {
  const [
    activeFilter,
    redAlerts,
    amberAlerts,
    levelTypes,
    redLevelType,
    amberLevelType,
    currentLevel,
  ] = oFetch(
    props,
    'activeFilter',
    'redAlerts',
    'amberAlerts',
    'levelTypes',
    'redLevelType',
    'amberLevelType',
    'currentLevel',
  );
  const changeFilterAction = oFetch(props, 'changeFilterAction');

  function renderTitle() {
    const levelClass = cn('boss-page-dashboard__status', {
      'boss-page-dashboard__status_level_red': currentLevel === redLevelType,
      'boss-page-dashboard__status_level_amber': currentLevel === amberLevelType,
    });
    return (
      <div className="boss-page-dashboard__group">
        <h1 className="boss-page-dashboard__title">Venue Alert Levels</h1>
        {currentLevel && <div className={levelClass}>This venue is at {currentLevel} Alert</div>}
      </div>
    );
  }
  return (
    <div>
      <SimpleDashboard
        titleRenderer={renderTitle}
        filterRenderer={() => (
          <DashboardFilter
            tabs={constants.FILTER_TABS}
            activeFilter={activeFilter}
            onTabChange={changeFilterAction}
          />
        )}
      />
      <ContentWrapper>
        <AlertsList
          title="Red Alerts"
          alerts={redAlerts}
          level={redLevelType}
          levels={levelTypes}
          perPage={2}
          alertRenderer={venueAlert => <AlertItem venueAlert={venueAlert} />}
        />
        <AlertsList
          title="Amber Alerts"
          alerts={amberAlerts}
          level={amberLevelType}
          levels={levelTypes}
          perPage={2}
          alertRenderer={venueAlert => <AlertItem venueAlert={venueAlert} />}
        />
      </ContentWrapper>
    </div>
  );
}
