import React from 'react';
import oFetch from 'o-fetch';
import pluralize from 'pluralize';
import cn from 'classnames';

export default function CalendarDayItem(props) {
  function renderTasksBadge(tasksCountData) {
    const [total, active, postponed, declined, completed] = oFetch(
      tasksCountData,
      'total',
      'active',
      'postponed',
      'declined',
      'completed',
    );

    return (
      <p className="boss-planner__record boss-planner__record_role_task">
        <span className="boss-planner__record-number">{total}</span>
        <span className="boss-planner__record-indicators">
          {active > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_pending" />
          )}
          {postponed > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_postponed" />
          )}
          {completed > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_completed" />
          )}
          {declined > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_declined" />
          )}
        </span>
      </p>
    );
  }

  function renderInfoTooltip(params) {
    const eventsCount = oFetch(params, 'eventsCount');
    const remindersCountData = oFetch(params, 'remindersCountData');
    const remindersTotal = oFetch(remindersCountData, 'total');
    const reminderDataKeys = extractDataKeys(remindersCountData);
    const tasksCountData = oFetch(params, 'tasksCountData');
    const tasksTotal = oFetch(tasksCountData, 'total');
    const taskDataKeys = extractDataKeys(tasksCountData);
    const total = eventsCount + remindersTotal + tasksTotal;
    const anyItemsExist = total > 0;

    const eventItems = [`${eventsCount} ${pluralize('event', eventsCount)}`];
    const reminderItems = Object.keys(reminderDataKeys).map(key => {
      return reminderInfoToolTipTextForKey({ reminderDataKeys, key });
    });
    const taskItems = Object.keys(taskDataKeys).map(key => {
      return taskInfoToolTipTextForKey({ taskDataKeys, key });
    });

    if (!anyItemsExist) {
      return null;
    } else {
      return (
        <div className="boss-planner__day-details">
          {eventsCount > 0 && renderInfoTooltipSection(eventItems)}
          {remindersTotal > 0 && renderInfoTooltipSection(reminderItems)}
          {tasksTotal > 0 && renderInfoTooltipSection(taskItems)}
        </div>
      );
    }
  }

  function extractDataKeys(source) {
    return Object.keys(source).reduce((result, key) => {
      const value = oFetch(source, key);
      if (key != 'total' && value > 0) {
        result[key] = value;
      }
      return result;
    }, {});
  }

  function renderInfoTooltipSection(itemList) {
    return (
      <ul className="boss-planner__list">
        {itemList.map((item, index) => {
          return (
            <li key={index} className="boss-planner__list-item">
              <p className="boss-planner__text boss-planner__text_size_s">
                <span className="boss-planner__text-bold">{item}</span>
              </p>
            </li>
          );
        })}
      </ul>
    );
  }

  function reminderInfoToolTipTextForKey(params) {
    const reminderDataKeys = oFetch(params, 'reminderDataKeys');
    const key = oFetch(params, 'key');
    const total = oFetch(reminderDataKeys, key);

    switch (key) {
      case 'activeCount':
        return `${total} pending ${pluralize('reminder', total)}`;
        break;
      case 'completedCount':
        return `${total} completed ${pluralize('reminder', total)}`;
        break;
      default:
        throw new Error('Unsupported reminders key: ' + key + ' encountered');
    }
  }

  function taskInfoToolTipTextForKey(params) {
    const taskDataKeys = oFetch(params, 'taskDataKeys');
    const key = oFetch(params, 'key');
    const total = oFetch(taskDataKeys, key);

    switch (key) {
      case 'active':
        return `${total} active ${pluralize('task', total)}`;
        break;
      case 'postponed':
        return `${total} postponed ${pluralize('task', total)}`;
        break;
      case 'declined':
        return `${total} declined ${pluralize('task', total)}`;
        break;
      case 'completed':
        return `${total} completed ${pluralize('task', total)}`;
        break;
      default:
        throw new Error('Unsupported tasks key: ' + key + ' encountered');
    }
  }

  function renderRemindersBadge(remindersCountData) {
    const [total, activeCount, deletedCount, completedCount] = oFetch(
      remindersCountData,
      'total',
      'activeCount',
      'deletedCount',
      'completedCount',
    );
    const postponedCount = 0;

    return (
      <div className="boss-planner__record boss-planner__record_role_reminder">
        <span className="boss-planner__record-number">{total}</span>

        <span className="boss-planner__record-indicators">
          {activeCount > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_pending" />
          )}
          {postponedCount > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_postponed" />
          )}
          {completedCount > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_completed" />
          )}
          {deletedCount > 0 && (
            <span className="boss-planner__record-indicator boss-planner__record-indicator_role_declined" />
          )}
        </span>
      </div>
    );
  }

  const [mDay, eventsCount, remindersCountData, tasksCountData, isCurrentDay, showDay, href] = oFetch(
    props,
    'day',
    'eventsCount',
    'remindersCountData',
    'tasksCountData',
    'isCurrentDay',
    'showDay',
    'href',
  );
  const remindersCount = oFetch(remindersCountData, 'total');
  const tasksCount = oFetch(tasksCountData, 'total');
  const day = mDay.format('D');
  const dayClassNames = cn('boss-planner__day', { 'boss-planner__day_type_today': isCurrentDay });

  return (
    <a href={href} className={dayClassNames}>
      <div className="boss-planner__day-header">{showDay && <h3 className="boss-planner__day-date">{day}</h3>}</div>
      <div className="boss-planner__day-content">
        {eventsCount > 0 && (
          <button type="button" className="boss-planner__record boss-planner__record_role_event">
            {eventsCount}
          </button>
        )}
        {remindersCount > 0 && renderRemindersBadge(remindersCountData)}
        {tasksCount > 0 && renderTasksBadge(tasksCountData)}
      </div>
      {renderInfoTooltip({ eventsCount, remindersCountData, tasksCountData })}
    </a>
  );
}

CalendarDayItem.defaultProps = {
  showDay: true,
};
