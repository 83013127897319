import React from 'react';
import oFetch from 'o-fetch';

export default function HistoryList(props) {
  const [items, itemRenderer] = oFetch(props, 'items', 'itemRenderer');
  const itemsExists = items.length > 0;
  function renderItems() {
    if (!itemsExists) {
      return <h3>No history found</h3>;
    }
    return items.map((item, index) => {
      return React.cloneElement(itemRenderer(item), {
        key: index.toString(),
      });
    });
  }

  return (
    <div className="boss-board__manager-timeline">
      <div className="boss-timeline boss-timeline_role_smp-quizzes-history">
        <ul className="boss-timeline__list">{renderItems()}</ul>
      </div>
    </div>
  );
}
