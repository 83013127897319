import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { AppVenuesList } from './app-venues-list';
import { AppVenue } from './app-venue';

export function AppDetails(props) {
  const venues = oFetch(props, 'venues');
  const [selectedVenue, setSelectedVenue] = useState(null);

  if (selectedVenue === null) {
    return <AppVenuesList venues={venues} onVenueClick={venue => setSelectedVenue(venue)} />;
  }
  return <AppVenue venue={selectedVenue} onBackClick={() => setSelectedVenue(null)} />;
}
