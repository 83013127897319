import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function CountingBundleField(props) {
  const [label, input, meta, disabled] = oFetch(props, 'label', 'input', 'meta', 'disabled');
  const [value, name, onChange] = oFetch(input, 'value', 'name', 'onChange');

  function handleChange(e) {
    const newValue = oFetch(e, 'target.value');
    const regExp = new RegExp('^[0-9]+$');

    if (newValue === '') {
      return onChange(newValue);
    }

    if (!regExp.test(newValue)) {
      e.target.select();
      return onChange(value);
    }

    return onChange(+parseInt(newValue));
  }

  function handleFocus(e) {
    e.target.select();
  }

  return (
    <div className="purple-table__row">
      <div className="purple-table__cell">
        <div className="purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell purple-hidden_size_m-minor">
          <div className="purple-form-field__input purple-form-field__input_size_l">
            <input
              name={name}
              onFocus={handleFocus}
              disabled={disabled}
              placeholder="0"
              value={value || 0}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="purple-form-field purple-form-field_role_control purple-visible_size_m-minor">
          <p className="purple-form-field__label">
            <span className="purple-form-field__label-text">{label}</span>
          </p>
          <div className="purple-form-field__input purple-form-field__input_size_l">
            <input
              name={name}
              disabled={disabled}
              onFocus={handleFocus}
              placeholder="0"
              value={value || 0}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="purple-table__cell">
        <div className="purple-table__info">
          <p className="purple-table__text">{label}</p>
        </div>
      </div>
    </div>
  );
}
