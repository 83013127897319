import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { createBossStore } from '@/redux/store';
import { Provider } from 'react-redux';
import actionCreators from '@/redux/actions';
import { ModalsProvider } from '@/components/hooks-components/modals';
import RotaOverviewPage from './rota-overview-page';

export function RotaOverviewApp(props) {
  const [store, setStore] = useState(null);
  useEffect(() => {
    const store = createBossStore();
    store.dispatch(actionCreators().loadInitialRotaOverviewAppState(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  const rotaWeeklyDay = oFetch(props, 'rotaWeeklyDay');
  const tradingData = oFetch(props, 'tradingData');

  return (
    <Provider store={store}>
      <ModalsProvider>
        <RotaOverviewPage
          rotaDetailsObject={rotaWeeklyDay}
          venue={oFetch(props, 'venue')}
          venues={oFetch(props, 'venues')}
          tradingData={tradingData}
        />
      </ModalsProvider>
    </Provider>
  );
}
