import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';

export function updateOptions(values) {
  const [
    adviceWordCountThreshold,
    aevaSystemPromptTemplate,
    aevaUserPromptTemplate,
  ] = oFetch(
    values,
    'adviceWordCountThreshold',
    'aevaSystemPromptTemplate',
    'aevaUserPromptTemplate',
  );
  const taskStaticAdviceText = values.taskStaticAdviceText || "";

  return bossRequestHttp({
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ message: 'ÆVA Options Updated Successfuly' });
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'taskStaticAdviceText',
            'aevaSystemPromptTemplate',
            'aevaUserPromptTemplate',
            'adviceWordCountTreshold',
          ],
        });

        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).put(`/api/v1/marketing_tasks_page_options`, {
    taskStaticAdviceText,
    adviceWordCountThreshold,
    aevaSystemPromptTemplate,
    aevaUserPromptTemplate,
  });
}
