import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { IS_FIRST_STEP, IS_LAST_STEP, IS_MIDDLE_STEP } from './step';
import { STEPS_FIELDS } from '../constants';

export default function Steps(props) {
  const steps = oFetch(props, 'steps');
  const currentPage = oFetch(props, 'currentPage');
  const [stepRenderer, stepsValidation, submitErrors] = oFetch(
    props,
    'stepRenderer',
    'stepsValidation',
    'submitErrors',
  );

  function isActiveStep(page) {
    return page === currentPage;
  }

  function getPosition(page, stepsCount) {
    if (page === 1) {
      return IS_FIRST_STEP;
    }
    if (page === stepsCount) {
      return IS_LAST_STEP;
    }
    return IS_MIDDLE_STEP;
  }

  function renderSteps(steps) {
    const stepsCount = Object.keys(steps).length;
    return Object.entries(steps).map(entry => {
      const [page, title] = entry;
      const stepValidation = stepsValidation[page] || {};
      const intPage = parseInt(page);
      const stepFields = STEPS_FIELDS[page];
      const hasSubmitError = Object.keys(stepFields).some(field => Object.keys(submitErrors).includes(field));
      return React.cloneElement(stepRenderer(entry), {
        page: intPage,
        selected: isActiveStep(intPage),
        title: title,
        key: page,
        submitted: !!stepValidation.submitted || hasSubmitError,
        hasErrors:
          (!!stepValidation.submitted && (stepValidation.hasSyncErrors || stepValidation.hasAsyncErrors)) ||
          hasSubmitError,
        position: getPosition(intPage, stepsCount),
      });
    });
  }

  return (
    <div className="boss-page-dashboard__group boss-page-dashboard__group_spaced">
      <div className="boss-steps" style={{ justifyContent: 'center' }}>
        {renderSteps(steps)}
      </div>
    </div>
  );
}
