import React from 'react';
import Select from '@/lib/boss-react-select';
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';
import { RotaDate, START_TIME_TYPE, END_TIME_TYPE, BOSS_APP_TYPE, decodeRotaTimeValue, rotaTimesToCalendarTime } from '@/lib/rota-date';
import { SelectTimeInputRenderer } from '@/components/boss-form/time-input-render';
import oFetch from 'o-fetch';

import {
  getPossibleShiftStartTimeStrings,
  getPossibleShiftEndTimeStrings,
} from '@/lib/possible-shift-time-strings';

class BossFormTimeInput extends React.Component {
  static propTypes = {};

  getStartsAtOptions = (input, callback, shiftRotaDate) => {
    callback(null, {
      options: getPossibleShiftStartTimeStrings(
        this.props.granularityInMinutes,
        shiftRotaDate.startTime(),
      ).map(timeString => ({ value: timeString, label: timeString })),
      complete: true,
    });
  };

  getEndsAtOptions = (input, callback, shiftRotaDate) => {
    callback(null, {
      options: getPossibleShiftEndTimeStrings(
        this.props.granularityInMinutes,
        shiftRotaDate.startTime(),
      ).map(timeString => ({ value: timeString, label: timeString })),
      complete: true,
    });
  };

  updateStartsTime(newValue, shiftRotaDate) {
    if (!newValue.value) {
      return;
    }
    const {hours, minutes} = decodeRotaTimeValue({
      sEncodedTime: oFetch(newValue, 'value'),
    });
    const sNewDate = rotaTimesToCalendarTime({
      hours,
      minutes,
      timeType: START_TIME_TYPE,
      rotaDate: shiftRotaDate,
    }).toISOString();
    this.props.startsAt.input.onChange(sNewDate);
  }

  updateEndsTime(newValue, shiftRotaDate) {
    if (!newValue.value) {
      return;
    }
    const { hours, minutes } = decodeRotaTimeValue({
      sEncodedTime: oFetch(newValue, 'value'),
    });
    const sNewDate = rotaTimesToCalendarTime({
      hours,
      minutes,
      timeType: END_TIME_TYPE,
      rotaDate: shiftRotaDate,
    }).toISOString();
    this.props.endsAt.input.onChange(sNewDate);
  }

  render() {
    const { startsAt, endsAt, date } = this.props;
    const appType = BOSS_APP_TYPE;
    if (!date) {
      return (
        <div className="boss-form__field">
          <div className="boss-form__interval boss-form__interval_size_narrow">
            <div className="boss-form__interval-item">
              <p className="boss-form__label">
                <span className="boss-form__label-text">Start Time</span>
              </p>
            </div>
            <div className="boss-form__interval-item">
              <p className="boss-form__label">
                <span className="boss-form__label-text">End Time</span>
              </p>
            </div>
          </div>
          <h2>Please select a date</h2>
        </div>
      );
    }
    const mShiftStartsAt = safeMoment.uiDateParse(date);
    const shiftRotaDate = RotaDate.fromTime({
      dTime: mShiftStartsAt.toDate(),
      appType,
    });

    const timeStartsAt = startsAt.input.value;
    const timeEndsAt = endsAt.input.value;
    const sStartsAtDateValue = moment(timeStartsAt).format('HH:mm');
    const sEndsAtDateValue = moment(timeEndsAt).format('HH:mm');
    const selectStartAt = (
      <Select.Async
        value={sStartsAtDateValue}
        loadOptions={(input, callback) => this.getStartsAtOptions(input, callback, shiftRotaDate)}
        clearable={false}
        inputRenderer={SelectTimeInputRenderer}
        searchable={true}
        onChange={value => this.updateStartsTime(value, shiftRotaDate)}
      />
    );
    const selectEndsAt = (
      <Select.Async
        value={sEndsAtDateValue}
        loadOptions={(input, callback) => this.getEndsAtOptions(input, callback, shiftRotaDate)}
        clearable={false}
        inputRenderer={SelectTimeInputRenderer}
        searchable={true}
        onChange={value => this.updateEndsTime(value, shiftRotaDate)}
      />
    );

    return (
      <div className={`boss-form__field ${this.props.className}`}>
        <div className="boss-form__interval boss-form__interval_size_narrow">
          <div className="boss-form__interval-item">
            <p className="boss-form__label">
              <span className="boss-form__label-text">Start Time</span>
            </p>
            <div
              className={`boss-form__select boss-form__select_role_time ${
                startsAt.meta.touched && startsAt.meta.error && 'boss-form__select_state_error'
              }`}
            >
              {selectStartAt}
            </div>
            {startsAt.meta.touched && startsAt.meta.error && (
              <div className="boss-form__error">
                <p className="boss-form__error-text">
                  <span className="boss-form__error-line">{startsAt.meta.error}</span>
                </p>
              </div>
            )}
          </div>
          <div className="boss-form__interval-delimiter" />
          <div className="boss-form__interval-item">
            <p className="boss-form__label">
              <span className="boss-form__label-text">End Time</span>
            </p>
            <div
              className={`boss-form__select boss-form__select_role_time ${
                endsAt.meta.touched && endsAt.meta.error && 'boss-form__select_state_error'
              }`}
            >
              {selectEndsAt}
            </div>
            {endsAt.meta.touched && endsAt.meta.error && (
              <div className="boss-form__error">
                <p className="boss-form__error-text">
                  <span className="boss-form__error-line">{endsAt.meta.error}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BossFormTimeInput.defaultProps = {
  granularityInMinutes: 30,
  className: '',
};

export default BossFormTimeInput;
