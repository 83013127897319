import React from 'react';
import Modal from 'react-modal';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function LegacyDefaultModalWrapper(props) {
  const [children, onRequestClose] = oFetch(props, 'children', 'onRequestClose');
  const { baseClassName } = props;

  const baseClassNames = cn({ [baseClassName]: !!baseClassName });
  return (
    <Modal
      isOpen={true}
      className={baseClassNames}
      contentLabel="Modal"
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {children}
    </Modal>
  );
}
