import React from 'react';
import Modal from 'react-modal';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

import modalDecorator from './modal-decorator';

class ConfirmationModal extends React.Component {
  render() {
    const { show, children, title, onClose } = this.props;

    return (
      <Modal
        isOpen={show}
        contentLabel="Modal"
        ariaHideApp={false}
        className="ReactModal__Content ReactModal__Content--after-open boss-modal-window boss-modal-window_role_warning"
      >
        <button onClick={onClose} className="boss-modal-window__close" />
        <div className="boss-modal-window__header">{title}</div>
        <div className="boss-modal-window__content">{children}</div>
      </Modal>
    );
  }
}

function openConfirmationModal(
  { title = 'Content Modal' },
  props = {},
  onSubmit,
  onClose,
  rootWrapper,
  closeCallback,
) {
  const whenCloseClicked = () => {
    closeCallback();
    onClose();
  };

  return function (Component) {
    rootWrapper.render(
      <ReactErrorsBoundaryWrapper>
        <ConfirmationModal show={true} title={title} onClose={whenCloseClicked}>
          <Component onSubmit={onSubmit} onClose={whenCloseClicked} {...props} />
        </ConfirmationModal>
      </ReactErrorsBoundaryWrapper>,
    );
  };
}

export default modalDecorator(openConfirmationModal);
