import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import oFetch from 'o-fetch';
import { SimpleDashboard, DashboardActions } from '@/components/boss-dashboards';
import ContentWrapper from './content-wrapper';
import VenueList from './venue-list';
import VenueItem from './venue-item';
import VenueMobileItem from './venue-mobile-item';
import pureToJS from '@/hocs/pure-to-js';
import { openContentModal } from '@/components/modals';
import AddVenue from './add-venue';
import EditVenue from './edit-venue';
import FruitOrderFields from './fruit-order-fields';
import LoadMore from '@/components/load-more/load-more-children';
import { WarningModalContent } from './warning-modal-content';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

const PureToJSVenueItem = pureToJS(VenueItem);
const PureToJSVenueMobileItem = pureToJS(VenueMobileItem);

class Page extends Component {
  handleAddVenueSubmit = (hideModal, values) => {
    return oFetch(
      this.props,
      'addVenueRequested',
    )(values).then(resp => {
      hideModal();
      return resp;
    });
  };

  handleEditVenueSubmit = (hideModal, values) => {
    return oFetch(
      this.props,
      'editVenueRequested',
    )(values).then(resp => {
      hideModal();
      return resp;
    });
  };

  handleAddVenue = () => {
    const fruitOrderFields = oFetch(this.props, 'fruitOrderFields');
    openContentModal({
      submit: this.handleAddVenueSubmit,
      config: { title: 'Add Venue' },
      props: {
        fruitOrderFields: fruitOrderFields.toJS(),
      },
    })(AddVenue);
  };

  handleEditVenue = venue => {
    const fruitOrderFields = oFetch(this.props, 'fruitOrderFields');
    openContentModal({
      submit: this.handleEditVenueSubmit,
      config: {
        title: (
          <div>
            Edit <span className="boss-modal-window__marked">{oFetch(venue, 'name')}</span>
          </div>
        ),
      },
      props: {
        venue,
        fruitOrderFields: fruitOrderFields.toJS(),
      },
    })(EditVenue);
  };

  handleDisableVenue = args => {
    const venue = oFetch(args, 'venue');
    const openModal = oFetch(this.context, 'openModal');
    const disableVenueRequested = oFetch(this.props, 'disableVenueRequested');

    return openModal({
      onSubmit(closeModal) {
        closeModal();
        return disableVenueRequested({ venueId: oFetch(venue, 'id') });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Disable',
      },
      config: {
        title: 'WARNING !!!',
        modalClass: 'boss-modal-window_role_confirm',
      },
    })(WarningModalContent);
  };

  handleEnableVenue = args => {
    const venue = oFetch(args, 'venue');
    const openModal = oFetch(this.context, 'openModal');
    const enableVenueRequested = oFetch(this.props, 'enableVenueRequested');

    return openModal({
      onSubmit(closeModal) {
        closeModal();
        return enableVenueRequested({ venueId: oFetch(venue, 'id') });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Enable',
      },
      config: {
        title: 'WARNING !!!',
        modalClass: 'boss-modal-window_role_confirm',
      },
    })(WarningModalContent);
  };

  handleOpenFruitOrderFields = venue => {
    const venueEnabled = oFetch(venue, 'enabled');
    openContentModal({
      config: {
        title: (
          <div>
            Fruit Order Fields for <span className="boss-modal-window__marked">{oFetch(venue, 'name')}</span>
            {venueEnabled && (
              <span
                onClick={() => this.handleEditVenue(venue)}
                className="boss-modal-window__action boss-modal-window__action_role_edit boss-modal-window__action_position_after"
              >
                Edit
              </span>
            )}
          </div>
        ),
      },
      props: {
        venue,
      },
    })(FruitOrderFields);
  };

  render() {
    const venues = oFetch(this.props, 'venues');
    return (
      <div className="boss-page-main">
        <SimpleDashboard title="Venues" className="boss-page-dashboard_page_venues">
          <DashboardActions>
            <button
              onClick={this.handleAddVenue}
              type="button"
              className="boss-button boss-button_role_add boss-page-dashboard__button"
            >
              Add Venue
            </button>
            <a
              href="/accessories"
              className="boss-button boss-button_role_accessories-alt boss-page-dashboard__button"
            >
              Accessories
            </a>
          </DashboardActions>
        </SimpleDashboard>
        <LoadMore items={venues} perPage={10}>
          {({ visibleItems, onLoadMore }) => (
            <ContentWrapper>
              <VenueList
                venues={visibleItems}
                itemRenderer={venue => (
                  <PureToJSVenueItem
                    onEditVenue={this.handleEditVenue}
                    onDisableVenue={this.handleDisableVenue}
                    onOpenFruitOrderFields={this.handleOpenFruitOrderFields}
                    onEnableVenue={this.handleEnableVenue}
                    venue={venue}
                  />
                )}
                mobileItemRenderer={venue => (
                  <PureToJSVenueMobileItem
                    onEditVenue={this.handleEditVenue}
                    onEnableVenue={this.handleEnableVenue}
                    onDisableVenue={this.handleDisableVenue}
                    onOpenFruitOrderFields={this.handleOpenFruitOrderFields}
                    venue={venue}
                  />
                )}
              />
              <div className="boss-page-main__count boss-page-main__count_space_large">
                <span className="boss-page-main__count-text">Showing&nbsp;</span>
                <span className="boss-page-main__count-text boss-page-main__count-text_marked">
                  {visibleItems.size}
                </span>
                <span className="boss-page-main__count-text">&nbsp;of&nbsp;</span>
                <span className="boss-page-main__count-text boss-page-main__count-text_marked">
                  {venues.size}
                </span>
              </div>
              {venues.size !== visibleItems.size && (
                <div className="boss-page-main__actions boss-page-main__actions_position_last">
                  <button
                    onClick={onLoadMore}
                    className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
                  >
                    Load More
                  </button>
                </div>
              )}
            </ContentWrapper>
          )}
        </LoadMore>
      </div>
    );
  }
}

Page.propTypes = {
  venues: PropTypes.instanceOf(Immutable.List).isRequired,
  possibleReminderUsersAddVenue: PropTypes.instanceOf(Immutable.List).isRequired,
  fruitOrderFields: PropTypes.instanceOf(Immutable.List).isRequired,
  addVenueRequested: PropTypes.func.isRequired,
  editVenueRequested: PropTypes.func.isRequired,
  disableVenueRequested: PropTypes.func.isRequired,
  enableVenueRequested: PropTypes.func.isRequired,
};
Page.contextTypes = { openModal: PropTypes.func };
export default Page;
