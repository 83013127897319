import oFetch from 'o-fetch';
import { http, bossRequestHttp } from '@/lib/request-api';
import { openErrorModal } from '@/components/modals';

export const publishPaymentWeekRequest = params => {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const venueId = oFetch(values, 'venueId');
  const date = oFetch(values, 'date');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Payments published successfully',
      });
      onSuccess(data);
    },
  }).post(`/api/v1/payments/publish_payment_week`, { venueId, date });
};

export const sendPayslipEmail = params => {
  const [payslip, closeModal] = oFetch(params, 'payslip', 'closeModal');
  const payslipId = oFetch(payslip, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Payslip email was send successfully',
      });
      closeModal();
    },
  }).post(`/api/v1/finance_reports/send_payslip`, {
    payslipId: payslipId,
  });
};
