import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { loadImage } from '@/components/image-resizer';

export default class ImageRotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedFile: props.file,
      rotation: [1, 8, 3, 6],
      currentRotationIndex: 0,
    };
  }

  rotateImage = index => {
    loadImage(this.props.file, {
      maxWidth: 1024,
      orientation: this.state.rotation[index],
      crossOrigin: 'anonymous',
    }).then(file => {
      this.setState({ updatedFile: file, currentRotationIndex: index });
    });
  };

  componentDidMount() {
    this.rotateImage(0);
  }

  onRotateLeft = () => {
    const index =
      this.state.currentRotationIndex + 1 > this.state.rotation.length - 1
        ? 0
        : this.state.currentRotationIndex + 1;
    this.rotateImage(index);
  };

  onRotateRight = async () => {
    const index =
      this.state.currentRotationIndex - 1 < 0
        ? this.state.rotation.length - 1
        : this.state.currentRotationIndex - 1;
    this.rotateImage(index);
  };

  render() {
    const [onSubmit, onClose] = oFetch(this.props, 'onSubmit', 'onClose');
    const updatedFile = oFetch(this.state, 'updatedFile');
    return (
      <div className="boss-add-avatar-block boss-modal-window__form boss-form">
        <div className="boss-edit-image-block boss-add-avatar-block_adjust_edit-image-block">
          <div className="boss-edit-image-block__cropper-block">
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className="boss-buttons-group boss-edit-image-block_adjust_buttons-group"
            >
              <button
                type="button"
                className="boss-button boss-buttons-group_adjust_button"
                onClick={this.onRotateLeft}
              >
                Rotate Left
              </button>
              <button
                type="button"
                className="boss-button boss-buttons-group_adjust_button"
                onClick={this.onRotateRight}
              >
                Rotate Right
              </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img src={updatedFile.preview} width="70%" />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className="boss-buttons-group boss-edit-image-block_adjust_buttons-group"
            >
              <button
                type="button"
                className="boss-button boss-buttons-group_adjust_button"
                onClick={() => onSubmit(updatedFile)}
              >
                Ok
              </button>
              <button
                type="button"
                className="boss-button boss-buttons-group_adjust_button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
