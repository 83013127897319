import React, { useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';
import humanize from 'string-humanize';

function getOptionOrAny(item) {
  if (item) {
    return { value: oFetch(item, 'id'), label: oFetch(item, 'name') };
  } else {
    return { value: 'Any', label: 'Any' };
  }
}

const anyOption = { value: 'Any', label: 'Any' };

export function MossStaffMembersFilterForm(props) {

  const statuses = oFetch(props, 'statuses');
  const venues = oFetch(props, 'venues');
  const mossStaffTypes = oFetch(props, 'moss_staff_types');

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [name, setName] = useState(oFetch(props, 'selected_name'));
  const [status, setStatus] = useState({ value: oFetch(props, 'selected_status'), label: humanize(oFetch(props, 'selected_status')) });
  const [venue, setVenue] = useState(getOptionOrAny(oFetch(props, 'selected_venue')));
  const [mossStaffType, setMossStaffType] = useState(getOptionOrAny(oFetch(props, 'selected_moss_staff_type')));

  const filterButtonClassNames = cn('boss-dropdown__switch boss-dropdown__switch_role_filter', {
    'boss-dropdown__switch_state_opened': isFilterOpen,
  });

  const statusOptions = useMemo(() => {
    return [anyOption, ...statuses.map(status => ({ value: status, label: humanize(status) }))];
  }, []);

  const venueOptions = useMemo(() => {
    return [anyOption, ...venues.map(venue => ({ value: oFetch(venue, 'id'), label: oFetch(venue, 'name') }))];
  }, []);

  const mossStaffTypeOptions = useMemo(() => {
    return [anyOption, ...mossStaffTypes.map(mossStaffType => ({ value: oFetch(mossStaffType, 'id'), label: oFetch(mossStaffType, 'name') }))];
  }, []);

  function toggleFilter() {
    setIsFilterOpen(!isFilterOpen);
  }

  function renderManagerFilter() {
    return (
      <div className="boss-form__group boss-form__group_layout_half">
        <div className="boss-form__field">
          <label className="boss-form__label">
            <span className="boss-form__label-text">Venue</span>
          </label>

          <div className="boss-form__select">
            <Select
              classNames="venue-select"
              name="venue"
              value={venue}
              options={venueOptions}
              onChange={setVenue}
              clearable={false}
              searchable={true}
            />
          </div>
        </div>

        <div className="boss-form__field">
          <label className="boss-form__label">
            <span className="boss-form__label-text">Staff Type</span>
          </label>

          <div className="boss-form__select">
            <Select
              classNames="staff_type-select"
              name="moss_staff_type"
              value={mossStaffType}
              options={mossStaffTypeOptions}
              onChange={setMossStaffType}
              clearable={false}
              searchable={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="boss-page-dashboard__filter">
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <button
            onClick={toggleFilter}
            className={filterButtonClassNames}
          >
            Filtering
          </button>
        </div>
        <Collapse isOpened={isFilterOpen}>
          <div style={{ paddingBottom: 15 }} />
          <div
            className="boss-dropdown__content"
            style={{ display: 'block', marginTop: 0 }}
          >
            <div className="boss-dropdown__content-inner">
              <form
                action="#"
                className="boss-form"
              >
                <div className="boss-form__row boss-form__row_position_last">
                  <div className="boss-form__group boss-form__group_layout_half">
                    <div className="boss-form__field">
                      <label className="boss-form__label">
                        <span className="boss-form__label-text">Name</span>
                        <input
                          name="name_text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="boss-form__input"
                          type="text"
                        />
                      </label>
                    </div>

                    <div className="boss-form__field">
                      <label className="boss-form__label">
                        <span className="boss-form__label-text">Status</span>
                      </label>

                      <div className="boss-form__select">
                        <Select
                          classNames="status-select"
                          name="status"
                          value={status}
                          options={statusOptions}
                          onChange={setStatus}
                          clearable={false}
                          searchable={false}
                        />
                      </div>
                    </div>
                  </div>

                  {!props.isSecurityManager && renderManagerFilter()}
                </div>
                <div className="boss-form__field boss-form__field_justify_end boss-form__field_no-label">
                  <button
                    type="submit"
                    className="boss-button boss-form__submit boss-form__submit_adjust_single"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
