import React, { useMemo } from 'react';
import Select from '@/lib/boss-react-select';
import { formatAsCurrency } from '@/lib/currency-helpers';
import {
  mossHourTagsReportIndex as importedMossHourTagsReportIndex,
} from '@/lib/app-routes';
import { MossHourTagsSectionProps, MossHourTag } from '../types';
const appRoutes = {
  mossHourTagsReportIndex: importedMossHourTagsReportIndex,
};

export function MossHourTagsSection(props: MossHourTagsSectionProps): JSX.Element {
  const [filterTags, setFilterTags] = React.useState<number[]>([]);
  const [enabledTags, disabledTags] = useMemo(() => {
    const enabledTags = [] as MossHourTag[];
    const disabledTags = [] as MossHourTag[];
    props.mossHourTags.forEach((mossHourTag) => {
      if (mossHourTag.isDisabled) {
        disabledTags.push(mossHourTag);
      } else {
        enabledTags.push(mossHourTag);
      }
    });
    return [enabledTags, disabledTags];
  }, [props.mossHourTags]);

  const filteredMossHourTagCount = props.mossHourTags.length;
  const totalMossHourTagCount = filteredMossHourTagCount + props.filteredOutMossHourTags.length;
  const showingAll = totalMossHourTagCount === filteredMossHourTagCount;

  function toggleHighlight(args: { mossHourTagId: number }): () => void {
    return () => {
      if (props.highlightedTagId === args.mossHourTagId) {
        return;
      }

      props.setHighlightedTagId(args.mossHourTagId);
    };
  }

  function handleTagChange(selectedOptions: string): void {
    const arrayValues = selectedOptions
      .split(',')
      .filter(Boolean)
      .map((id) => parseInt(id));
    setFilterTags(arrayValues);
  }

  function addTags(): void {
    const mossHourTagIds = [
      ...props.mossHourTags.map((m) => m.id),
      ...filterTags,
    ];

    window.location.href = appRoutes
      .mossHourTagsReportIndex({
        startDate: props.startDate,
        mossHourTagIds: mossHourTagIds,
      });
  }

  function handleShowAllClick(): void {
    const href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: null,
    });
    window.location.href = href;
  }

  function handleShowOnlyClick(mossHourTagId: number): void {
    window.location.href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: [mossHourTagId],
    });
  }

  function handleRemoveTagClick(mossHourTagId: number): void {
    let mossHourTagIds = [] as number[];
    if (props.mossHourTags.length === 0) {
      // Need all tags except this one
      disabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
      enabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
    } else if ((props.mossHourTags.length === 1) && (props.mossHourTags[0]?.id && (props.mossHourTags[0]?.id === mossHourTagId))) {
      // need all tags so do nothing
    } else {
      // need current tags except this one
      mossHourTagIds = props.mossHourTags.filter((mossHourTag) => mossHourTag.id !== mossHourTagId).map((mossHourTag) => mossHourTag.id);
    }

    const href = appRoutes.mossHourTagsReportIndex({
      startDate: props.startDate,
      mossHourTagIds: mossHourTagIds.length === 0 ? null : mossHourTagIds,
    });
    window.location.href = href;
  }

  function renderMossHourTags(mossHourTags: MossHourTag[]): JSX.Element[] {
    return mossHourTags.map((mossHourTag) => {
      const totalCents = mossHourTag.owedHourCents + mossHourTag.hoursAcceptancePeriodCents;

      return (
        <div
          onClick={toggleHighlight({ mossHourTagId: mossHourTag.id })}
          key={`mossHourTag:${mossHourTag.id}`}
          className={[
            'boss-tag boss-tag_size_xl',
            'boss-tag_role_hours-tag',
            'boss-tag_role_action',
            'js-tippy-tag',
            mossHourTag.isDisabled ? 'boss-tag_state_disabled' : null,
            mossHourTag.id === props.highlightedTagId ? 'boss-tag_state_selected' : null,
          ].filter(Boolean).join(' ')}
          aria-expanded="false"
        >
          {mossHourTag.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
            <span className="boss-corner__icon boss-corner__icon_calculator" />
          </div>}
          <p className="boss-tag__text">
            {`${mossHourTag.name} `}
            <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
          </p>
          <div
            className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
            onClick={() => handleShowOnlyClick(mossHourTag.id)}
          />
          <div
            className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
            onClick={() => handleRemoveTagClick(mossHourTag.id)}
          />
        </div>
      );
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_hours-tags">
      <div className="boss-board__main">
        <div className="boss-board__main-inner">
          <div className="boss-board__filter">
            <div
              className="boss-form"
            >
              <div className="boss-form__row boss-form__row_align_center">
                <div className="boss-form__field boss-form__field_layout_min">
                  <p className="boss-form__text">
                    <span>Showing {filteredMossHourTagCount} of {totalMossHourTagCount}</span>
                    {!showingAll && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-form__action"
                        onClick={handleShowAllClick}
                      >Show All</button>
                    )}
                  </p>
                </div>

                <div className="boss-form__field boss-form__field_layout_max">
                  <div className="boss-form__select">
                    <Select
                      disabled={props.filteredOutMossHourTags.length === 0}
                      placeholder={"Filtered Out Tags..."}
                      value={filterTags}
                      label={null}
                      simpleValue
                      options={props.filteredOutMossHourTags.map((mossHourTag) => {
                        return {
                          label: mossHourTag.name,
                          value: mossHourTag.id
                        };
                      })}
                      multi
                      clearable={true}
                      onChange={handleTagChange}
                    />
                  </div>
                </div>
                <button
                  disabled={filterTags.length === 0}
                  onClick={() => addTags()}
                  className="boss-button"
                >Update</button>
              </div>
            </div>
          </div>

          <div className="boss-board__tags">
            {props.mossHourTags.length === 0 && (
              <div className="boss-board__tags-group">
                <div className="boss-board__tags-group-inner">
                  <div className="boss-board__tags-group-text">No tags found</div>
                </div>
              </div>
            )}

            {enabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {renderMossHourTags(enabledTags)}
              </div>
            )}

            {disabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {renderMossHourTags(disabledTags)}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
