import React from 'react';
import oFetch from 'o-fetch';
import moment from 'moment';
import utils from '@/lib/utils';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';

import EnableProfileForm from './enable-profile-form';

export default function EnableProfile(props) {
  const staffTypes = oFetch(props, 'staffTypes');

  function getStaffTypeOptions() {
    return staffTypes.map(staffType => {
      return {
        label: oFetch(staffType, 'name'),
        value: oFetch(staffType, 'id'),
        color: oFetch(staffType, 'color'),
      };
    });
  }

  function handleSubmit(values, dispatch) {
    return props.onSubmit(values.toJS(), dispatch);
  }

  const reviveFormConfig = oFetch(props, 'reviveFormConfig');
  const nationalEnsuranceNumberExist = oFetch( reviveFormConfig, 'nationalEnsuranceNumberExist');
  const staffMember = oFetch(props, 'staffMember');
  const venues = oFetch(props, 'venues');
  const currentMasterVenueId = oFetch(staffMember, 'master_venue');
  const payRateId = oFetch(staffMember, 'pay_rate');
  const baseSecurityPayRateId = oFetch(staffMember, 'baseSecurityPayRateId');
  const staffTypeId = oFetch(staffMember, 'staff_type');
  const nationalInsuranceNumber = oFetch(staffMember, 'national_insurance_number');
  const staffMemberId = oFetch(staffMember, 'id');
  const payRates = oFetch(props, 'payRates');
  const checkSiaNumber = oFetch(staffMember, 'checkSiaNumber');
  const accessibleBaseStandardSecurityPayRates = oFetch(props, 'accessibleBaseStandardSecurityPayRates');
  const accessibleBaseStewardSecurityPayRates = oFetch(props, 'accessibleBaseStewardSecurityPayRates');
  const dCurrentRotaDateStartTime = RotaDate.fromTime({ dTime: new Date(), appType: BOSS_APP_TYPE }).startTime();
  const mCurrentRotaDateStartTime = moment(dCurrentRotaDateStartTime);

  const initialValues = {
    staffMemberId: staffMemberId,
    startsAt: mCurrentRotaDateStartTime.format(utils.commonDateFormat),
    masterVenueId: currentMasterVenueId,
    nationalInsuranceNumber: nationalInsuranceNumber,
    payRateId: payRateId,
    baseSecurityPayRateId: baseSecurityPayRateId,
    securitySteward: false,
    canBypassSiaValidation: false,
    siaValidationGUID: null,
    staffTypeId: staffTypeId,
    siaBadgeNumber: null,
    siaBadgeExpiryDate: null,
  };

  return (
    <EnableProfileForm
      onSubmit={handleSubmit}
      venues={venues}
      payRates={payRates}
      staffMemberId={staffMemberId}
      requireNiNumber={!nationalEnsuranceNumberExist}
      checkSiaNumber={checkSiaNumber}
      accessibleBaseStandardSecurityPayRates={accessibleBaseStandardSecurityPayRates}
      accessibleBaseStewardSecurityPayRates={accessibleBaseStewardSecurityPayRates}
      staffTypeOptions={getStaffTypeOptions()}
      initialValues={initialValues}
    />
  );
}
