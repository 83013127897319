import React from 'react';
import oFetch from 'o-fetch';

export function DeviceNameFilter(props) {
  const [onFilterDeviceNameChange, value] = oFetch(props, 'onFilterDeviceNameChange', 'value');

  function handleChange(e) {
    const value = oFetch(e.target, 'value');
    onFilterDeviceNameChange(value);
  }

  return (
    <div className="boss-form__field">
      <div className="boss-form__search">
        <label className="boss-form__label">
          <input
            name="search"
            value={value}
            onChange={handleChange}
            type="text"
            className="boss-form__input"
            placeholder="Device Name"
          />
        </label>
        <span onClick={() => onFilterDeviceNameChange('')} className="boss-form__search-clear">
          Clear
        </span>
      </div>
    </div>
  );
}
