import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';
import { initialProfileLoad } from '../profile-wrapper/actions';
import { loadInitialState } from './redux/actions';
import AccessoriesContainer from './containers/accessories-container';
import accessoriesReducer from './redux/reducers';
import profileReducer from '../profile-wrapper/reducers';

export function StaffMemberAccessoriesApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    const store = configureStore(
      combineReducers({
        accessoriesPage: accessoriesReducer,
        profile: profileReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    store.dispatch(
      loadInitialState({
        ...props,
      }),
    );
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <AccessoriesContainer />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
