import { combineReducers } from 'redux-immutable';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialGlobalState = Immutable.List();

const effectiveStaffMembersReducer = handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const payload = oFetch(action, 'payload');
      return Immutable.fromJS(oFetch(payload, 'effectiveStaffMembers'));
    },
    [types.REMOVE_STAFF_MEMBER]: (state, action) => {
      const effectiveStaffMemberId = oFetch(action, 'payload');

      return state.filter(effectiveStaffMember => oFetch(effectiveStaffMember, 'id') !== effectiveStaffMemberId);
    },
  },
  initialGlobalState,
);

export default effectiveStaffMembersReducer;
