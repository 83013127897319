import utils from '@/lib/utils';
const APP_PREFIX = 'ADD_STAFF_MEMBER';

export const LOAD_INITIAL_DATA = utils.createType(APP_PREFIX, 'LOAD_INITIAL_DATA');
export const SUBMIT_FAILED = utils.createType(APP_PREFIX, 'SUBMIT_FAILED');
export const FLAGGED_STAFF_MEMBERS = utils.createType(APP_PREFIX, 'FLAGGED_STAFF_MEMBERS');
export const EXISTING_STAFF_MEMBERS = utils.createType(APP_PREFIX, 'EXISTING_STAFF_MEMBERS');
export const REVIEW_FLAGGED_STAFF_MEMBER = utils.createType(APP_PREFIX, 'REVIEW_FLAGGED_STAFF_MEMBER');
export const UN_REVIEW_FLAGGED_STAFF_MEMBER = utils.createType(APP_PREFIX, 'UN_REVIEW_FLAGGED_STAFF_MEMBER');
export const CHANGE_STEP = utils.createType(APP_PREFIX, 'CHANGE_STEP');
export const START_FLAGGED_FETCHING = utils.createType(APP_PREFIX, 'START_FLAGGED_FETCHING');
export const END_FLAGGED_FETCHING = utils.createType(APP_PREFIX, 'END_FLAGGED_FETCHING');
export const UPDATE_ACCESSORIES = utils.createType(APP_PREFIX, 'UPDATE_ACCESSORIES');
