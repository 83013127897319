import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';

import {
  SecurityPayRatesDashboard,
  SecurityPayRatesList,
  ForStaffMembersSecurityPayRateItem,
  ForSpecialSecurityHoursSecurityPayRateItem,
  ForStaffMembersStaffTypeFilter,
} from './components';
import {
  securityPayRatesHook,
  FOR_STAFF_MEMBERS_TYPE,
  FOR_SPECIAL_SECURITY_HOURS_TYPE,
  FOR_STAFF_MEMBERS_FILTER_SHOW_ALL,
} from './security-pay-rates-hook';

export function SecurityPayRates(props) {
  const [initialSecurityPayRates, venues] = oFetch(props, 'securityPayRates', 'venues');
  const {
    selectedType,
    onTypeChange,
    selectedForStaffMembersFilter,
    onForStaffMembersFilterChange,
    securityPayRates,
    forStaffMembersPayRatesCount,
    forStewardsPayRateCount,
    forSpecialSecurityHoursSecurityPayRateCount,
    openAddForStaffMembersSecurityPayrateModal,
    openAddForSpecialSecurityHoursSecurityPayrateModal,
    openEditForStaffMembersSecurityPayrateModal,
    openEditForSpecialSecurityHoursSecurityPayrateModal,
    openAddExceptionModal,
    openUpdateExceptionModal,
    openDeleteExceptionModalWarning,
    openVenuesAssignmentsModal,
  } = securityPayRatesHook({
    initialType: FOR_STAFF_MEMBERS_TYPE,
    initialForStaffMembersFilter: FOR_STAFF_MEMBERS_FILTER_SHOW_ALL,
    initialSecurityPayRates: initialSecurityPayRates,
    venues: venues,
  });

  const isForStaffMembersSelected = selectedType === FOR_STAFF_MEMBERS_TYPE;

  function renderItem(securityPayRate) {
    if (selectedType === FOR_STAFF_MEMBERS_TYPE) {
      return (
        <ForStaffMembersSecurityPayRateItem
          onEditPayRate={openEditForStaffMembersSecurityPayrateModal}
          onAddExceptionClick={openAddExceptionModal}
          onEditExceptionClick={openUpdateExceptionModal}
          onDeleteExceptionClick={openDeleteExceptionModalWarning}
          securityPayRate={securityPayRate}
        />
      );
    }
    if (selectedType === FOR_SPECIAL_SECURITY_HOURS_TYPE) {
      return (
        <ForSpecialSecurityHoursSecurityPayRateItem
          onEditPayRate={openEditForSpecialSecurityHoursSecurityPayrateModal}
          onVenuesAssignmentsClick={openVenuesAssignmentsModal}
          onAddExceptionClick={openAddExceptionModal}
          onEditExceptionClick={openUpdateExceptionModal}
          onDeleteExceptionClick={openDeleteExceptionModalWarning}
          securityPayRate={securityPayRate}
        />
      );
    }
  }

  return (
    <Fragment>
      <SecurityPayRatesDashboard
        selected={selectedType}
        onChange={onTypeChange}
        forStaffMembersPayRatesCount={forStaffMembersPayRatesCount}
        forSpecialSecurityHoursSecurityPayRateCount={forSpecialSecurityHoursSecurityPayRateCount}
        onAddForStaffMembersPayrateClick={openAddForStaffMembersSecurityPayrateModal}
        onAddForSpecialSecurityHoursPayrateClick={openAddForSpecialSecurityHoursSecurityPayrateModal}
      />
      <ContentWrapper>
        {isForStaffMembersSelected && (
          <ForStaffMembersStaffTypeFilter
            selected={selectedForStaffMembersFilter}
            showAllPayRateCount={forStaffMembersPayRatesCount}
            forStewardsPayRateCount={forStewardsPayRateCount}
            onChange={onForStaffMembersFilterChange}
          />
        )}
        <SecurityPayRatesList list={securityPayRates} renderItem={renderItem} />
      </ContentWrapper>
    </Fragment>
  );
}
