import { createAction } from 'redux-actions';
import { apiRoutes, appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { SubmissionError } from 'redux-form/immutable';
import utils from "@/lib/utils";

import {
  MOSS_STAFF_MEMBER_TYPE,
  UPDATE_AVATAR,
  INITIAL_LOAD,
  ADD_NEW_HOLIDAY,
  CANCEL_ADD_NEW_HOLIDAY,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_REQUEST_SUCCESS,
  CLOSE_HOLIDAY_MODAL,
  DELETE_HOLIDAY,
  OPEN_EDIT_HOLIDAY_MODAL,
  EDIT_HOLIDAY_SUCCESS,
  CLOSE_EDIT_HOLIDAY_MODAL,
  FILTER,
  UPDATE_HOLIDAYS_COUNT,
  DELETE_HOLIDAY_REQUEST,
  EDIT_HOLIDAY_REQUEST_SUCCESS,
  ADD_NEW_HOLIDAY_PERMISSION,
  ADD_NEW_HOLIDAY_REQUEST_PERMISSION,
} from './constants';

export const initialLoad = createAction(INITIAL_LOAD);
export const addNewHoliday = createAction(ADD_NEW_HOLIDAY);
export const cancelAddNewHoliday = createAction(CANCEL_ADD_NEW_HOLIDAY);

export const filterAction = createAction(FILTER);
export const addHolidaySuccess = createAction(ADD_HOLIDAY_SUCCESS);
export const addHolidayRequestSuccess = createAction(ADD_HOLIDAY_REQUEST_SUCCESS);
export const editHolidaySuccess = createAction(EDIT_HOLIDAY_SUCCESS);
export const editHolidayRequestSuccess = createAction(EDIT_HOLIDAY_REQUEST_SUCCESS);
export const closeHolidayModal = createAction(CLOSE_HOLIDAY_MODAL);
export const closeEditHolidayModal = createAction(CLOSE_EDIT_HOLIDAY_MODAL);
export const deleteHolidayRequestAction = createAction(DELETE_HOLIDAY_REQUEST);
export const deleteHolidayAction = createAction(DELETE_HOLIDAY);
export const updateHolidaysAction = createAction(UPDATE_HOLIDAYS_COUNT);

export const deleteHoliday = holidayId => (dispatch, getState) => {
  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    ['profile.mossStaffMember.id']
  );

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const holidayId = oFetch(params, 'data');
      dispatch(deleteHolidayAction({ holidayId }));
      return dispatch(updateHolidaysCount(mossStaffMemberId));
    },
  }).delete(`/api/v1/staff_members/${mossStaffMemberId}/holidays/${holidayId}`);
};

export const deleteHolidayRequest = (args) => (dispatch, getState) => {
  const holidayRequestId = oFetch(args, 'holidayRequestId');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(deleteHolidayRequestAction(holidayRequestId));
    },
  }).delete(
    `/api/v1/holiday-requests/${holidayRequestId}`,
  );
};

export const editHoliday = ({ startDate, endDate, holidayType, note, id }) => (
  dispatch,
  getState,
) => {
  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    'profile.mossStaffMember.id',
  );
  const formateStartDate = startDate.format('DD-MM-YYYY');
  const formatedEndDate = endDate.format('DD-MM-YYYY');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType', 'payslipDate'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(editHolidaySuccess(data));
      dispatch(closeEditHolidayModal());
      return dispatch(updateHolidaysCount(mossStaffMemberId));
    },
  }).put(`/api/v1/staff_members/${mossStaffMemberId}/holidays/${id}`, {
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const editHolidayRequest = ({ startDate, endDate, holidayType, note, id }) => (
  dispatch,
  getState,
) => {
  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    'profile.mossStaffMember.id',
  );
  const formateStartDate = startDate.format('DD-MM-YYYY');
  const formatedEndDate = endDate.format('DD-MM-YYYY');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType', 'payslipDate'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(editHolidayRequestSuccess(data));
      dispatch(closeEditHolidayModal());
      return dispatch(updateHolidaysCount(mossStaffMemberId));
    },
  }).put(`/api/v1/holiday-requests/${id}`, {
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
    staff_member_id: mossStaffMemberId,
  });
};

export const addHoliday = ({ startDate, endDate, holidayType, note }) => (dispatch, getState) => {
  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    'profile.mossStaffMember.id',
  );
  const formateStartDate = startDate ? startDate.format('DD-MM-YYYY') : null;
  const formatedEndDate = endDate ? endDate.format('DD-MM-YYYY') : null;

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const newHoliday = oFetch(data, 'holiday');
      const newPermissions = oFetch(data, 'permissions');

      dispatch(addHolidaySuccess({ newHoliday, newPermissions }));
      dispatch(closeHolidayModal());
      return dispatch(updateHolidaysCount(mossStaffMemberId));
    },
  }).post(`/api/v1/staff_members/${mossStaffMemberId}/holidays`, {
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const addHolidayRequest = (args) => (
  dispatch,
  getState,
) => {
  const argsJS = utils.callIfExists({
    obj: args,
    funcName: 'toJS',
  });

  const [
    startDate,
    endDate,
    holidayType,
    note
  ] = oFetch(
    argsJS,
    "startDate",
    "endDate",
    "holidayType",
    "note"
  );

  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    'profile.mossStaffMember.id',
  );
  const formateStartDate = startDate ? startDate.format('DD-MM-YYYY') : null;
  const formatedEndDate = endDate ? endDate.format('DD-MM-YYYY') : null;

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const newHolidayRequest = oFetch(data, 'holiday_request');
      const newPermissions = oFetch(data, 'permissions');
      dispatch(addHolidayRequestSuccess({ newHolidayRequest, newPermissions }));
      dispatch(closeHolidayModal());
    },
  }).post(
    `/api/v1/holiday-requests`,
    {
      start_date: formateStartDate,
      end_date: formatedEndDate,
      note: note,
      holiday_type: holidayType,
      staff_member_type: MOSS_STAFF_MEMBER_TYPE,
      moss_staff_member_id: mossStaffMemberId,
    }
  );
};

const updateHolidaysCount = mossStaffMemberId => dispatch => {
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateHolidaysAction(data));
    },
  }).get(`/api/v1/staff_members/${mossStaffMemberId}/holidays/holidays_count`);
};

export const filter = (sStartDate, sEndDate, sStartPayslipDate, sEndPayslipDate) => (
  dispatch,
  getState,
) => {
  const mossStaffMemberId = oFetch(
    utils.callIfExists({
      obj: getState(),
      funcName: 'toJS',
    }),
    'profile.mossStaffMember.id',
  );
  const mStartDate = sStartDate && safeMoment.uiDateParse(sStartDate);
  const mEndDate = sEndDate && safeMoment.uiDateParse(sEndDate);
  const mPayslipStartDate = sStartPayslipDate && safeMoment.uiDateParse(sStartPayslipDate);
  const mPayslipEndDate = sEndPayslipDate && safeMoment.uiDateParse(sEndPayslipDate);

  const getApiUrl = apiRoutes.mossStaffMemberProfileHolidaysIndex.getPath({
    mossStaffMemberId: mossStaffMemberId,
    mStartDate: mStartDate,
    mEndDate: mEndDate,
    mPayslipStartDate: mPayslipStartDate,
    mPayslipEndDate: mPayslipEndDate,
  });
  const getWebUrl = appRoutes.mossStaffMemberProfileHolidaysTab({
    mossStaffMemberId: mossStaffMemberId,
    mStartDate: mStartDate,
    mEndDate: mEndDate,
    mPayslipStartDate: mPayslipStartDate,
    mPayslipEndDate: mPayslipEndDate,
  });

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(filterAction(data));
      window.history.pushState('state', 'title', `${getWebUrl}`);
    },
  }).get(getApiUrl);
};

export const openEditModal = holiday => {
  return {
    type: OPEN_EDIT_HOLIDAY_MODAL,
    payload: holiday,
  };
};

export const closeEditModal = () => {
  return {
    type: CLOSE_EDIT_HOLIDAY_MODAL,
  };
};
