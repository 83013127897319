import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';
import { AttachmentChangesDetailsButton } from './attachment-changes-details-button';

export function CompleteTaskEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, note, at, attachments] = oFetch(event, 'userFullName', 'note', 'at', 'attachments');

  const formattedAt = useMemo(() => atFormat(at), [at]);
  const hasAttachments = attachments.length > 0;

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_completed">
      <p className="boss-overview__text">
        Task marked as completed by {userFullName} @ {formattedAt}
      </p>
      {note && (
        <p className="boss-overview__text">
          <span className="boss-overview__text-large boss-overview__text-marked">Note - {note}</span>
        </p>
      )}
      {hasAttachments && (
        <AttachmentChangesDetailsButton
          text="Attachments added"
          oldAttachments={[]}
          newAttachments={attachments}
        />
      )}
    </li>
  );
}
