import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class SimpleDashboard extends Component {
  renderTitle = () => {
    const { titleRenderer } = this.props;
    if (!titleRenderer) {
      return null;
    }
    return React.cloneElement(titleRenderer());
  };

  renderFilter = () => {
    const { filterRenderer } = this.props;
    if (!filterRenderer) {
      return null;
    }
    return React.cloneElement(filterRenderer());
  };

  render() {
    return (
      <div className="boss-page-main__dashboard">
        <div className="boss-page-main__inner">
          <div className="boss-page-dashboard boss-page-dashboard_updated">
            {this.renderTitle()}
            <div className="boss-page-dashboard__filter">{this.renderFilter()}</div>
          </div>
        </div>
      </div>
    );
  }
}
