import React from 'react';
import oFetch from 'o-fetch';
import { toColumns } from '@/lib/utils';
export default function QuizCategoriesList(props) {
  const [items, itemRender, quizCategoriesSizes] = oFetch(props, 'items', 'itemRender', 'quizCategoriesSizes');

  function renderItems() {
    if (items.length === 0) {
      return <h1>No Categories</h1>;
    }

    const columnedItems = toColumns(items, 2);

    return columnedItems.map((group, index) => {
      return (
        <div key={index} className="boss-users__stats-group">
          {group.map(item => {
            const id = oFetch(item, 'id');
            return React.cloneElement(itemRender(item), {
              key: id.toString(),
              size: quizCategoriesSizes[id],
            });
          })}
        </div>
      );
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_staff-vetting">
      <div className="boss-users">
        <div className="boss-users__stats">{renderItems()}</div>
      </div>
    </div>
  );
}
