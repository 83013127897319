import React, { Fragment } from 'react';
import cn from 'classnames';
import oFetch from 'o-fetch';

export function SIAFormField(props) {
  const { label, placeholder } = props;
  const [input, meta, validateSiaNumber, checkSIANumber, siaBadgeValidating] = oFetch(
    props,
    'input',
    'meta',
    'validateSiaNumber',
    'checkSIANumber',
    'siaBadgeValidating',
  );

  const touched = oFetch(meta, 'touched');
  const { error, submitError, dispatch, form } = meta;
  const [onChange, value, name, onFocus, onBlur] = oFetch(
    input,
    'onChange',
    'value',
    'name',
    'onFocus',
    'onBlur',
  );

  const inputClassNames = cn('boss-form__input', {
    'boss-form__input_state_error': touched && (error || submitError),
  });

  function handleChange(e) {
    const value = (e.target.value || '').trim();
    if (value === '') {
      return onChange(value);
    }
    const matched = value.match(/^[0-9]+$/);
    if (matched) {
      if (validateSiaNumber && value.length === 16) {
        checkSIANumber({
          siaNumber: value,
          debounceTimeout: 500,
        });
      }
      onChange(value);
    }
  }

  return (
    <Fragment>
      <div className="boss-form__field">
        <div className="boss-form__label-group">
          {label && (
            <label className="boss-form__label">
              <span className="boss-form__label-text">{label}</span>
            </label>
          )}
          {siaBadgeValidating && (
            <div className="boss-indicator boss-indicator_context_label-group">
              <div className="boss-indicator__icon boss-indicator__icon_spinner" />
              <div className="boss-indicator__label boss-indicator__label_size_xs">
                Checking SIA Badge Number...
              </div>
            </div>
          )}
        </div>
        <input
          onChange={handleChange}
          className={inputClassNames}
          value={value}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {touched && (error || submitError) && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error || submitError}</span>
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
}
