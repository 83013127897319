import React, { Component } from 'react';
import oFetch from 'o-fetch';
import StaffDashboard from '../../components/staff-dashboard';
import { FilterItem, FilterList, Filter, UserItem, UserList } from './components';

export default class WithoutScannableAvatars extends Component {
  renderDashboardTitle = () => {
    const staffMembersCount = oFetch(this.props, 'count');
    const formattedCount = staffMembersCount > 0 ? `+${staffMembersCount}` : 0;
    return (
      <h1 className="boss-page-dashboard__title">
        <span className="boss-page-dashboard__title-text">Staff Members without Scannable Avatar</span>
        <span className="boss-page-dashboard__title-info">{formattedCount}</span>
      </h1>
    );
  };

  render() {
    const [filterItems, staffMembers, onTextFilterChange, tabFilterSelections, onTabFilterSelect, count] = oFetch(
      this.props,
      'filterItems',
      'staffMembers',
      'onTextFilterChange',
      'tabFilterSelections',
      'onTabFilterSelect',
      'count',
    );
    const showingCount = staffMembers.length;
    return (
      <div>
        <StaffDashboard title={this.renderDashboardTitle} />
        <div className="boss-page-main__content">
          <div className="boss-page-main__inner">
            <FilterList
              items={filterItems}
              selected={tabFilterSelections}
              itemRender={item => <FilterItem item={item} onSelect={onTabFilterSelect} />}
            />
            <div className="boss-page-main__group boss-page-main__group_adjust_staff-vetting">
              <div className="boss-users">
                <Filter onChange={onTextFilterChange} showingCount={showingCount} fullCount={count} />
                <UserList items={staffMembers} itemRender={item => <UserItem item={item} />} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
