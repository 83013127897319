import { handleActions } from 'redux-actions';
import * as types from '../types';

const initialState = false;

export default handleActions(
  {
    [types.TOGGLE_HISTORY]: (state, action) => {
      return !state;
    },
  },
  initialState,
);
