import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { NotesModalContent } from './components';

export function useModulrReports(params) {
  const [venues, weekStartDate] = oFetch(params, 'venues', 'weekStartDate');
  const groupByModulrPayrollCompanyName = utils.groupBy('modulrPayrollCompanyName');
  const { openModal } = useModal();

  const modulrCompanies = useMemo(() => {
    const grouppedByModulrCompanies = groupByModulrPayrollCompanyName(venues);
    const calculatedModulrCompanies = Object.entries(grouppedByModulrCompanies).map(
      modulrPayrollCompanyEntry => {
        const [modulrPayrollCompanyName, venues] = modulrPayrollCompanyEntry;
        const calculatedData = venues.reduce(
          (acc, venue) => {
            const [
              modulrPayrollCompanyId,
              canDownloadBulkPaymentsFile,
              payableFinanceReportsWithPayslipCount,
              payableFinanceReportsWithPayslipAndBankDetailsCount,
              financeReportsCompletedCount,
              totalNetWagesCents,
            ] = oFetch(
              venue,
              'modulrPayrollCompanyId',
              'canDownloadBulkPaymentsFile',
              'payableFinanceReportsWithPayslipCount',
              'payableFinanceReportsWithPayslipAndBankDetailsCount',
              'financeReportsCompletedCount',
              'financeReportTotals.totalNetWagesCents',
            );

            return {
              modulrPayrollCompanyId: modulrPayrollCompanyId,
              canDownloadBulkPaymentsFile:
                oFetch(acc, 'canDownloadBulkPaymentsFile') && canDownloadBulkPaymentsFile,
              financeReportsCompletedCount:
                oFetch(acc, 'financeReportsCompletedCount') + financeReportsCompletedCount,
              payableFinanceReportsWithPayslipAndBankDetailsCount:
                oFetch(acc, 'payableFinanceReportsWithPayslipAndBankDetailsCount') + payableFinanceReportsWithPayslipAndBankDetailsCount,
              payableFinanceReportsWithPayslipCount: oFetch(acc, 'payableFinanceReportsWithPayslipCount') + payableFinanceReportsWithPayslipCount,
              totalNetWagesCents: oFetch(acc, 'totalNetWagesCents') + totalNetWagesCents,
            };
          },
          {
            modulrPayrollCompanyId: null,
            canDownloadBulkPaymentsFile: true,
            payableFinanceReportsWithPayslipAndBankDetailsCount: 0,
            payableFinanceReportsWithPayslipCount: 0,
            totalNetWagesCents: 0,
            financeReportsCompletedCount: 0,
          },
        );
        return {
          name: modulrPayrollCompanyName,
          ...calculatedData,
        };
      },
    );
    return [...calculatedModulrCompanies].sort((a, b) => oFetch(a, 'name').localeCompare(oFetch(b, 'name')));
  }, [venues]);

  const grandTotalNetWagesCents = useMemo(() => {
    return modulrCompanies.reduce((acc, modulrCompany) => {
      return acc + oFetch(modulrCompany, 'totalNetWagesCents');
    }, 0);
  }, [modulrCompanies]);

  const setupForPayment = useMemo(() => {
    return modulrCompanies.reduce(
      (acc, modulrCompany) => {
        const [payableFinanceReportsWithPayslipAndBankDetailsCount, payableFinanceReportsWithPayslipCount] = oFetch(
          modulrCompany,
          'payableFinanceReportsWithPayslipAndBankDetailsCount',
          'payableFinanceReportsWithPayslipCount',
        );
        return {
          payableFinanceReportsWithPayslipAndBankDetailsCount:
            oFetch(acc, 'payableFinanceReportsWithPayslipAndBankDetailsCount') + payableFinanceReportsWithPayslipAndBankDetailsCount,
          payableFinanceReportsWithPayslipCount: oFetch(acc, 'payableFinanceReportsWithPayslipCount') + payableFinanceReportsWithPayslipCount,
        };
      },
      {
        payableFinanceReportsWithPayslipAndBankDetailsCount: 0,
        payableFinanceReportsWithPayslipCount: 0,
      },
    );
  }, [modulrCompanies]);

  const sortedVenues = useMemo(() => {
    return [...venues].sort((a, b) => {
      const aModulrPayrollCompanyName = oFetch(a, 'modulrPayrollCompanyName');
      const bModulrPayrollCompanyName = oFetch(b, 'modulrPayrollCompanyName');
      const aVenueName = oFetch(a, 'name');
      const bVenueName = oFetch(b, 'name');

      if (aModulrPayrollCompanyName !== bModulrPayrollCompanyName) {
        return aModulrPayrollCompanyName.localeCompare(bModulrPayrollCompanyName);
      }
      return aVenueName.localeCompare(bVenueName);
    });
  }, [venues]);

  function openNotesModal(params) {
    const [notes, venueName, venueId] = oFetch(params, 'notes', 'venueName', 'venueId');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: NotesModalContent,
      onSubmit(handleClose) { },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_venue-notes',
        title() {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{venueName}</span> Notes
            </div>
          );
        },
      },
      props: { notes, weekStartDate, venueId },
    });
  }

  return {
    modulrCompanies: modulrCompanies,
    openNotesModal: openNotesModal,
    sortedVenues: sortedVenues,
    grandTotalNetWagesCents: grandTotalNetWagesCents,
    setupForPayment: setupForPayment,
  };
}
