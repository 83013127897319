import React, { useEffect } from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import Contacts from './contacts';
import oFetch from 'o-fetch';

export function ContactsPage(props) {
  const accessToken = oFetch(props, 'accessToken');

  useEffect(() => {
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, [accessToken]);

  return (
    <ModalsProvider>
      <Contacts {...props} />
    </ModalsProvider>
  );
}
