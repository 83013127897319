import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import PaymentUpload from './payment-upload';

export function PaymentUploadApp(props) {
  const accessToken = oFetch(props, 'accessToken');

  useEffect(() => {
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, [accessToken]);

  return (
    <ModalsProvider>
      <PaymentUpload {...props} />
    </ModalsProvider>
  );
}
