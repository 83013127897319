import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import filter from './filter-reducer';
import effectiveStaffMembers from './effective-staff-members-reducer';
import clockInPeriods from './clock-in-periods-reducer';
import clockInNotes from './clock-in-notes-reducer';
import clockInBreaks from './clock-in-breaks-reducer';
import clockInEvents from './clock-in-events-reducer';
import rotaShifts from './rota-shifts-reducer';
import rotas from './rotas-reducer';
import hoursAcceptanceBreaks from './hours-acceptance-breaks-reducer';
import hoursAcceptancePeriods from './hours-acceptance-periods-reducer';
import staticData from './static-data-reducer';
import permissions from './permissions-reducer';
import specialPayRateSecurityHours from './special-payrate-security-hours-reducer';
import owedHoursReducer from './owed-hours-reducer';

export default combineReducers({
  filter,
  effectiveStaffMembers,
  clockInPeriods,
  clockInNotes,
  clockInBreaks,
  clockInEvents,
  rotaShifts,
  rotas,
  hoursAcceptanceBreaks,
  hoursAcceptancePeriods,
  staticData,
  permissions,
  specialPayRateSecurityHours,
  form: formReducer,
  owedHours: owedHoursReducer,
});