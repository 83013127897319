import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import configureStore from '@/apps/store';
import * as commonReducers from '../redux/reducers';
import * as localReducers from './redux/reducers';
import * as actions from '../redux/actions';
import HandoverPlannersWeek from './handover-planners-week';

export function HandoverPlannersWeekApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const reducers = combineReducers({
      static: commonReducers.staticReducer,
      permissions: commonReducers.permissions,
      events: localReducers.eventsReducer,
      reminders: localReducers.remindersReducer,
      tasks: localReducers.tasksReducer,
      postponedTasksHistory: localReducers.postponedTasksHistoryReducer,
      sortIndices: localReducers.sortIndices,
      reminderDates: localReducers.reminderDatesReducer,
      form: formReducer,
    });
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialData({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <HandoverPlannersWeek />
    </Provider>
  );
}
