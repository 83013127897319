import React from 'react';
import { connect } from 'react-redux';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import { confirmation } from '@/lib/confirm-utils';
import actionCreators from '@/redux/actions';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { PublishRotaModal } from './publish-rota-modal';

const ROTA_PUBLISHED_STATUS = 'published';

function RotaHeader(props) {
  const tradingData = oFetch(props, 'tradingData');
  const { openModal } = useModal();

  function handlePublishRotaWeek() {
    return openModal({
      onSubmit(closeModal, tradingData) {
        return oFetch(
          props,
          'publishRotaWeek',
        )({
          tradingData,
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: 'Publish Rota',
        baseClassName: 'boss-modal-window boss-modal-window_role_publish-payment-week',
      },
      props: {
        tradingData,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PublishRotaModal,
    });
  }

  function onPublish() {
    confirmation(
      [
        "Publishing a rota will send out email confirmations and can't be undone.",
        'Do you want to continue?',
      ],
      {
        title: 'WARNING !!!',
      },
    ).then(() => {
      handlePublishRotaWeek();
    });
  }

  const startDate = safeMoment
    .uiDateParse(utils.formatJSDateToUIDate(props.startDate))
    .format('Do MMMM YYYY');
  const endDate = safeMoment
    .uiDateParse(utils.formatJSDateToUIDate(props.endDate))
    .format('Do MMMM YYYY');
  const venueName = props.venue.name;
  var hasBeenPublished = props.rota.status === ROTA_PUBLISHED_STATUS;

  const date = `${startDate} - ${endDate}`;

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_rotas-weekly">
          <h1 className="boss-page-dashboard__title">
            <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
              {venueName} &nbsp;{' '}
            </span>
            <span className="boss-page-dashboard__title-text"> Rotas </span>
          </h1>
          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__meta">
              <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date">
                <span className="boss-page-dashboard__meta-text">{date}</span>
              </p>
            </div>
            <div className="boss-page-dashboard__buttons-group">
              {props.publishingInProgress ? (
                <div className="boss-spinner" />
              ) : hasBeenPublished ? null : (
                <button
                  type="button"
                  onClick={onPublish}
                  className="boss-button boss-button_role_publish boss-page-dashboard__button"
                >
                  Publish
                </button>
              )}
              <a
                href={props.pdfHref}
                className="boss-button boss-button_role_pdf-download boss-page-dashboard__button"
              >
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    publishingInProgress: _.some(state.apiRequestsInProgress.PUBLISH_ROTAS),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    publishRotaWeek: function (params) {
      const [tradingData, onSuccess] = oFetch(params, 'tradingData', 'onSuccess');
      return dispatch(
        actionCreators().publishRotas({
          venueServerId: ownProps.rota.venue.serverId,
          date: ownProps.startDate,
          tradingData: tradingData,
          onSuccess: onSuccess,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RotaHeader);
