import { useState } from 'react';
import oFetch from 'o-fetch';
import { BigNumber } from 'bignumber.js';
import { LegacyCloseOutsideModalWrapper, LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';

import { useModal } from '@/components/hooks-components/modals';
import * as requests from './requests';
import { DifferentAmountForm } from './components';

export function bonusPaymentsHooks(params) {
  const [initialBonusPayments, paths] = oFetch(params, 'initialBonusPayments', 'paths');
  const [bonusPayments, setBonusPayments] = useState(initialBonusPayments);
  const { openModal } = useModal();

  function removeBonusPaymentFromStore(bonusPaymentId) {
    setBonusPayments(bonusPayments => {
      return bonusPayments.filter(bonusPayment => {
        const id = oFetch(bonusPayment, 'id');
        return id !== bonusPaymentId;
      });
    });
  }

  function receivedBonusPayment(bonusPaymentId) {
    return requests.receivedBonusPayment({
      bonusPaymentId,
      path: oFetch(paths, 'receivedPath'),
      onSuccess() {
        removeBonusPaymentFromStore(bonusPaymentId);
      },
    });
  }

  function didNotReceiveBonusPayment(bonusPaymentId) {
    return requests.receivedBonusPayment({
      bonusPaymentId,
      path: oFetch(paths, 'didNotReceivePath'),
      onSuccess() {
        removeBonusPaymentFromStore(bonusPaymentId);
      },
    });
  }

  function openWrongAmountModal(bonusPaymentId) {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: DifferentAmountForm,
      onSubmit: (closeModal, values) => {
        const [amountCents, bonusPaymentId] = oFetch(values, 'amountCents', 'bonusPaymentId');
        return requests.receivedDifferentAmountBonusPayment({
          path: oFetch(paths, 'receivedDifferentAmountPath'),
          bonusPaymentId,
          amountCents:
            amountCents !== null
              ? new BigNumber(amountCents).times(100).integerValue().toNumber()
              : amountCents,
          onSuccess() {
            removeBonusPaymentFromStore(bonusPaymentId);
            closeModal();
          },
        });
      },
      config: {
        title: 'I received Different Amount',
      },
      props: {
        initialValues: {
          bonusPaymentId: bonusPaymentId,
          amountCents: null,
        },
      },
    });
  }

  return {
    bonusPayments,
    openWrongAmountModal,
    receivedBonusPayment,
    didNotReceiveBonusPayment,
  };
}
