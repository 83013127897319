import oFetch from 'o-fetch';
import React from 'react';
import { AevaAssistant } from '@/lib/aeva-assistant';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function MarketingTaskAevaAssistant(props) {
  const [values, venueOptions] = oFetch(props, 'values', 'venueOptions');
  const pageOptions = oFetch(useLegacyGlobal(), 'pageOptions');
  const adviceWordCountThreshold = oFetch(pageOptions, 'adviceWordCountThreshold');
  const permissions = oFetch(useLegacyGlobal(), 'permissions');
  const userCanSetupAeva = oFetch(permissions, 'canSetupAeva');

  return (
    <AevaAssistant
      userCanSetupAeva={userCanSetupAeva}
      values={values}
      validation={(values) => {
        const errors = [];

        //strip out html tags
        const descriptionText = values.description && values.description.replace(/<\/?[^>]+>/gi, ' ').trim();
        if (!(oFetch(values, 'title') && oFetch(values, 'venueId') && descriptionText && oFetch(values, 'description'))) {
          errors.push("values missing");
        }
        if (descriptionText) {
          const wordCount = oFetch((descriptionText.match(/[\w]+/g) || []), 'length');
          if (wordCount < adviceWordCountThreshold) {
            errors.push('word count insufficient');
          }
        }

        return errors;
      }}
      initialAevaSystemPromptTemplate={oFetch(pageOptions, 'aevaSystemPromptTemplate')}
      initialAevaUserPromptTemplate={oFetch(pageOptions, 'aevaUserPromptTemplate')}
      normalize={{
        title: values => oFetch(values, 'title'),
        deadline(values, aevaUtils) {
          const deadline = oFetch(values, 'deadlineDate');
          return deadline !== null
            ? aevaUtils.safeMoment.uiDateParse(deadline).format(aevaUtils.bossUtils.fullDayFormat)
            : null;
        },
        venue(values) {
          const venueId = oFetch(values, 'venueId');
          return venueOptions.find(v => oFetch(v, 'value') === venueId)?.label || null;
        },
        text(values, aevaUtils) {
          return aevaUtils.htmlToText(oFetch(values, 'description'));
        },
        html: values => oFetch(values, 'description'),
        attachmentInfo(values) {
          return oFetch(values, 'fileIds');
        },
      }}
    />
  );
}
