import React, { Component } from 'react';
import DropZone from '@/lib/boss-react-dropzone';
import oFetch from 'o-fetch';

import { loadImage } from '@/components/image-resizer';
import { openContentModal } from '@/components/modals';
import ImageRotation from '@/components/image-rotation';

const VALID_FILE_TYPES = '.jpg, .jpeg, .png';
const MAX_FILE_SIZE = 1000000; // 1MB

export default class IncludeImage extends Component {
  constructor(props) {
    super(props);
    const value = oFetch(props, 'input.value');
    this.state = {
      isIncludeImage: !!value,
    };
  }

  dropRejected = () => {};
  dropAccepted = async files => {
    const file = files[0];
    let resizedFile;
    if (file.size > MAX_FILE_SIZE) {
      resizedFile = await loadImage(file, {
        maxWidth: 1024,
        orientation: true,
        crossOrigin: 'anonymous',
      });
    } else {
      resizedFile = file;
    }
    const onChange = oFetch(this.props, 'input.onChange');
    openContentModal({
      submit: (modalClose, file) => {
        onChange(file);
        modalClose();
      },
      config: { title: 'Test' },
      props: { file: resizedFile },
    })(ImageRotation);
  };

  renderPickImage = () => {
    return (
      <div className="boss-upload__field boss-upload__field_adjust_form">
        <p className="boss-upload__field-label boss-upload__field-label_adjust_form">Add Image</p>
        <DropZone
          className="boss-upload__field-input"
          accept={VALID_FILE_TYPES}
          onDropAccepted={this.dropAccepted}
          onDropRejected={this.dropRejected}
        >
          {({ rejectedFiles }) => {
            const hasRejected = rejectedFiles.length > 0;
            if (hasRejected) {
              return (
                <div className="boss-upload__preview boss-upload__preview_adjust_form boss-upload__preview_state_broken">
                  <button type="button" className="boss-upload__preview-link" />
                </div>
              );
            }
          }}
        </DropZone>
      </div>
    );
  };

  handleClearImage = () => {
    const onChange = oFetch(this.props, 'input.onChange');
    onChange(null);
  };

  renderPreview = value => {
    return (
      <div className="boss-upload__preview boss-upload__preview_adjust_form">
        <img
          src={value.preview}
          crossOrigin="anonymous"
          alt="Question Image"
          className="boss-upload__preview-image boss-upload__preview-image_size_s"
        />
        <div className="boss-upload__preview-actions">
          <button
            onClick={this.handleClearImage}
            className="boss-button boss-button_role_delete boss-button_type_small"
            type="button"
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  handleIncludeImage = e => {
    const checked = oFetch(e.target, 'checked');
    if (!checked) {
      this.handleClearImage();
    }
    this.setState({
      isIncludeImage: checked,
    });
  };

  render() {
    const value = oFetch(this.props, 'input.value');
    const isIncludeImage = oFetch(this.state, 'isIncludeImage');
    return (
      <div className="boss-form__field boss-form__field">
        <label className="boss-form__checkbox-label boss-form__checkbox-label_context_label-group">
          <input
            onChange={this.handleIncludeImage}
            checked={isIncludeImage}
            type="checkbox"
            className="boss-form__checkbox-input"
          />
          <span className="boss-form__checkbox-label-text">Include Image</span>
        </label>
        {isIncludeImage && (
          <div className="boss-upload">{value ? this.renderPreview(value) : this.renderPickImage()}</div>
        )}
      </div>
    );
  }
}
