import React from 'react';
import oFetch from 'o-fetch';
import { Field } from 'redux-form';
import TextAnswer from './text-answer';
import ImageAnswer from './image-answer';

export default function Answers(props) {
  const [fields, meta, isTextTypeSelected, onAnswerChange] = oFetch(
    props,
    'fields',
    'meta',
    'isTextTypeSelected',
    'onAnswerChange',
  );

  function handleAddAnswer() {
    fields.push({ description: null, image: null, correct: false });
  }

  const AnswerComponent = isTextTypeSelected ? TextAnswer : ImageAnswer;

  return (
    <div className="boss-form__group">
      <div className="boss-choice-list boss-choice-list_role_question-form">
        <div className="boss-choice-list__header">
          <h3 className="boss-choice-list__title">Answers</h3>
        </div>
        <div className="boss-choice-list__content">
          <div className="boss-choice-list__controls">
            {fields.map((answer, index) => {
              return (
                <Field
                  name={answer}
                  onChange={(...args) => onAnswerChange(index, ...args)}
                  key={index}
                  onRemove={() => fields.remove(index)}
                  component={AnswerComponent}
                />
              );
            })}
            <div className="boss-form__field">
              <button
                onClick={handleAddAnswer}
                className="boss-button boss-button_role_add boss-form__submit"
                type="button"
              >
                Add Answer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
