import cn from 'classnames';
import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import AsyncButton from 'react-async-button';

export function AreYouSureEligibleModalContent(props) {
  const [onClose, onSubmit] = oFetch(props, 'onClose', 'onSubmit');

  return (
    <Fragment>
      <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
        <div className="boss-modal-window__message-group">
          <p className="boss-modal-window__message-note">
            <span className="boss-modal-window__message-line">
              This will mark this staff member as eligible for receiving this bonus
            </span>
          </p>
        </div>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onClose}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          Cancel
        </button>
        <AsyncButton
          className="boss-button boss-modal-window__button"
          text={`I'm sure`}
          pendingText="Marking ..."
          onClick={onSubmit}
        />
      </div>
    </Fragment>
  );
}
