import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Form, Field, Fields } from 'react-final-form';
import { VenuesAssignmentsFormField } from './venues-assignments-form-field';

export function VenuesAssignmentsForm(props) {
  const [onSubmit, initialValues, venuesOptions] = oFetch(
    props,
    'onSubmit',
    'initialValues',
    'venuesOptions',
  );
  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, ...rest }) => {
          return (
            <div className="boss-table__form">
              <div className="boss-form">
                <Field
                  name="venues"
                  parse={value => value}
                  options={venuesOptions}
                  component={VenuesAssignmentsFormField}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-button_role_add boss-form__submit"
                    disabled={submitting}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
