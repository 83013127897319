import React from 'react';
import Modal from 'react-modal';

const BossDetailsModal = ({ isOpen, children, onCloseClick, className = '' }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Modal"
      className={`ReactModal__Content ReactModal__Content--after-open boss-modal-window ${className}`}
      ariaHideApp={false}
    >
      <button onClick={onCloseClick} className="boss-modal-window__close" />
      {children}
    </Modal>
  );
};

export default BossDetailsModal;
