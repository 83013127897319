import oFetch from 'o-fetch';
import React from 'react';
import { DetailsCard } from './details-card';

export function AccountDetails(props) {
  const user = oFetch(props, 'user');

  const [status, staffMemberId] = oFetch(user, 'statusText', 'staffMemberId');

  return (
    <DetailsCard
      number={2}
      title="Account Details"
    >
      <ul className="boss-details__list">
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">Status</p>
          <p className="boss-details__value">{status}</p>
        </li>
        {!staffMemberId && (
          <li className="boss-details__item">
            <p className="boss-details__label boss-details__label_size_small">Staff Member</p>
            <p className="boss-details__value">
              <span className="boss-details__value-line">No associated staff member</span>
            </p>
          </li>
        )}
        {staffMemberId && (
          <li className="boss-details__item">
            <p className="boss-details__label boss-details__label_size_small">Staff Member</p>
            <p className="boss-details__value">
              <a
                target="_blank"
                rel="noreferrer"
                href={`/staff_members/${staffMemberId}`}
                className="boss-button boss-button_role_view-details boss-button_type_small"
              >
                View
              </a>
            </p>
          </li>
        )}
      </ul>
    </DetailsCard>
  );
}
