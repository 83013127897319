import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {
  venues: [],
  apps: [],
};

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const [venues, apps] = oFetch(action, 'payload.venues', 'payload.apps');
      return { venues, apps };
    },
  },
  initialState,
);
