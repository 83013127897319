import oFetch from 'o-fetch';
import React, { Fragment, useMemo, useEffect, useRef } from 'react';
import { Field } from 'redux-form/immutable';
import { BossFormSelect } from '@/components/boss-form';

export function BossFormRoleVenueSelect(props) {
  const [permittedRolesOptions, venuesOptions, venuesAccess] = oFetch(
    props,
    'permittedRolesOptions',
    'venuesOptions',
    'venuesAccess',
  );
  const [role, venueIds] = oFetch(props, 'role', 'venueIds');
  const roleValue = oFetch(role, 'input.value');
  const prevRoleValue = useRef(roleValue);
  const hasAllVenueAccess = useMemo(() => {
    if (!roleValue) {
      return false;
    }
    return oFetch(venuesAccess[roleValue], 'hasAllVenueAccess');
  }, [roleValue]);

  useEffect(() => {
    if (prevRoleValue.current !== roleValue) {
      const venueIdsChange = oFetch(venueIds, 'input.onChange');
      if (hasAllVenueAccess) {
        const allVenueIds = venuesOptions.map(venueOption => oFetch(venueOption, 'value'));
        venueIdsChange(allVenueIds);
      } else {
        venueIdsChange([]);
      }
    }
  }, [roleValue]);

  function renderVenuesSelect() {
    if (!roleValue) {
      return null;
    }
    return (
      <Field
        component={BossFormSelect}
        name="venueIds"
        selectAll
        multi
        label="Venues"
        options={venuesOptions}
      />
    );
  }

  return (
    <Fragment>
      <Field
        name="role"
        label="Role"
        required
        options={permittedRolesOptions}
        clearable={true}
        component={BossFormSelect}
      />
      {renderVenuesSelect()}
    </Fragment>
  );
}
