import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';

import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import { SiteIssues } from './site-issues';

export function SiteIssuesApp(props) {
  const [enums, permissions, year, years, venueId, venueName, filter] = oFetch(
    props,
    'pageData.enums',
    'pageData.permissions',
    'pageData.year',
    'pageData.years',
    'pageData.venueId',
    'pageData.venueName',
    'pageData.filter',
  );
  useEffect(() => {
    require('./style.sass');
    window.boss.accessToken = oFetch(props, 'accessToken');
  }, []);

  return (
    <LegacyGlobalProvider
      global={{
        enums: enums,
        permissions: permissions,
        year: year,
        years: years,
        venueId: venueId,
        venueName: venueName,
        filter: filter,
      }}
    >
      <ModalsProvider>
        <SiteIssues {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
