import React from 'react';
import DashboardWrapper from '@/components/dashboard-wrapper';
import oFetch from 'o-fetch';
import StaffTrackingWeekPicker from './staff-tracking-week-picker';

export default function Dashboard(props) {
  const [weekStart, weekEnd] = oFetch(props, 'weekStart', 'weekEnd');

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">
            <span className="boss-page-dashboard__title-text">Staff Tracking</span>
          </h1>
          <StaffTrackingWeekPicker
            weekStart={weekStart}
            weekEnd={weekEnd}
          ></StaffTrackingWeekPicker>
        </div>
      </div>
    </DashboardWrapper>
  );
}
