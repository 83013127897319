import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import oFetch from 'o-fetch';
import { BossFormTextarea } from '@/components/boss-form';

const FORM_NAME = 'decline-form';

class DeclineForm extends Component {
  render() {
    const [message, deleteButtonText, showDeleteButton, onClose, onDelete, onAccept, handleSubmit, submitting] = oFetch(
      this.props,
      'message',
      'deleteButtonText',
      'showDeleteButton',
      'onClose',
      'onDelete',
      'onAccept',
      'handleSubmit',
      'submitting',
    );
    const { error } = this.props;
    return (
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
          <div className="boss-modal-window__message-group">
            <p className="boss-modal-window__message-note">
              <span className="boss-modal-window__message-line">{message}</span>
            </p>
          </div>
          <div className="boss-modal-window__message-group">
            <div className="boss-form">
              {error && (
                <div className="boss-form__error">
                  <p className="boss-form__error-text">
                    <span className="boss-form__error-line">{error}</span>
                  </p>
                </div>
              )}
              <Field name="declineReason" label="Reason" required component={BossFormTextarea} />
            </div>
          </div>
        </div>
        <div className="boss-modal-window__actions boss-modal-window__actions_adjust_group">
          {showDeleteButton && (
            <div className="boss-modal-window__actions-group">
              <button
                onClick={handleSubmit((...args) => onDelete(onClose, ...args))}
                disabled={submitting}
                type="button"
                className="boss-button boss-button_role_delete boss-modal-window__button"
              >
                {deleteButtonText}
              </button>
            </div>
          )}
          <button
            onClick={onClose}
            disabled={submitting}
            type="button"
            className="boss-button boss-button_role_inactive boss-modal-window__button"
          >
            CANCEL
          </button>
          <button
            onClick={handleSubmit(onAccept)}
            disabled={submitting}
            type="button"
            className="boss-button boss-modal-window__button"
          >
            ACCEPT
          </button>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
})(DeclineForm);
