import React from 'react';
import { combineReducers } from 'redux-immutable';
import oFetch from 'o-fetch';
import { reducer as formReducer, SubmissionError } from 'redux-form/immutable';
import { modalRedux } from '@/components/modals';
import utils from '@/lib/utils';
import * as constants from './constants';
import NewAccessoryRequestForm from './new-accessory-request-form';

class NewAccessoryRequest extends React.Component {
  handleSubmit = (values, dispatch) => {
    return this.props.onSubmit(values.toJS(), dispatch);
  };

  render() {
    const initialValues = {
      accessoryType: constants.MISC_TYPE,
      accessoryId: null,
      size: null,
    };
    const accessoriesOptions = oFetch(this.props, 'accessoriesOptions');
    return (
      <NewAccessoryRequestForm
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        accessoriesOptions={accessoriesOptions}
        buttonText="Add Request"
        buttonClass="boss-button_role_add"
      />
    );
  }
}
export default modalRedux(combineReducers({ form: formReducer }))(NewAccessoryRequest);
