import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import oFetch from 'o-fetch';
import { BossFormCalendar } from '@/components/boss-form';
import utils from '@/lib/utils';

const FORM_NAME = 'postpone-form';

class PostponeForm extends Component {
  renderBaseError = error => {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {error.map((errorItem, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  };
  render() {
    const [handleSubmit, submitting] = oFetch(this.props, 'handleSubmit', 'submitting');
    const { error } = this.props;
    return (
      <div className="boss-modal-window__content">
        {error && this.renderBaseError(error)}
        <div className="boss-modal-window__form">
          <Field name="date" component={BossFormCalendar} disableBeforeRotaDay valueFormat={utils.commonDateFormat} />
          <div className="boss-form__field">
            <button
              onClick={handleSubmit}
              disabled={submitting}
              className="boss-button boss-button_role_add boss-form__submit"
            >
              Postpone
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
})(PostponeForm);
