import React from 'react';
import oFetch from 'o-fetch';

import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { RequestItemServiceButtons } from './request-item-service-buttons';

import { Attachment } from '../../attachment';
import { useMarketingTaskModals } from '../../../marketing-task-modals';

export function RemovedRequestItem(props) {
  const { openRequestHistoryModal } = useMarketingTaskModals();
  const [request, isAcceptedTask] = oFetch(props, 'request', 'isAcceptedTask');
  const [currentQuestion, creatorFullName, createdAt, isAccepted] = oFetch(
    request,
    'currentQuestion',
    'creatorFullName',
    'createdAt',
    'isAccepted',
  );
  const [text, questionAttachments, currentAnswer] = oFetch(
    currentQuestion,
    'text',
    'attachments',
    'currentAnswer',
  );

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);
  const hasAttachments = questionAttachments.length > 0;
  const hasAnswer = currentAnswer !== null;

  function renderAttachments(attachments) {
    return attachments.map(attachment => {
      const attachmentId = oFetch(attachment, 'id');
      return <Attachment key={attachmentId} attachment={attachment} />;
    });
  }

  function renderAnswer(currentAnswer) {
    const [text, attachments, creatorFullName, createdAt] = oFetch(
      currentAnswer,
      'text',
      'attachments',
      'creatorFullName',
      'createdAt',
    );
    const hasAttachments = attachments.length > 0;
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);
    return (
      <div className="boss-task__request-group boss-task__request-group_role_answer">
        <div className="boss-task__request-indicator">A</div>
        <div className="boss-task__request-info">
          <p className="boss-task__request-text boss-task__request-text_role_primary">{text}</p>
          {hasAttachments && (
            <div className="boss-task__request-attachments">{renderAttachments(attachments)}</div>
          )}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
    );
  }

  function renderClarification(currentAnswer) {
    const currentClarification = oFetch(currentAnswer, 'currentClarification');
    if (!currentClarification) {
      return null;
    }

    const [text, attachments, creatorFullName, createdAt] = oFetch(
      currentClarification,
      'text',
      'attachments',
      'creatorFullName',
      'createdAt',
    );
    const hasAttachments = attachments.length > 0;
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);

    return (
      <div className="boss-task__request-group boss-task__request-group_role_clarification">
        <div className="boss-task__request-info">
          <p className="boss-task__request-text boss-task__request-text_role_secondary">
            User left the following clarification:
          </p>
          <p className="boss-task__request-text boss-task__request-text_role_primary">{text}</p>
          {hasAttachments && renderAttachments(attachments)}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={'boss-task__request boss-task__request_state_removed'}>
      <RequestItemServiceButtons
        isAccepted={isAccepted}
        isRemoved={true}
        onEditClick={() => {}}
        onHistoryClick={() => openRequestHistoryModal(request)}
        onRemoveClick={() => {}}
        isAcceptedTask={isAcceptedTask}
        canEdit={false}
        canRemove={false}
      />
      <div className="boss-task__request-group boss-task__request-group_role_question">
        <div className="boss-task__request-indicator">Q</div>
        <div className="boss-task__request-info">
          <p className="boss-task__request-text boss-task__request-text_role_primary">{text}</p>
          {hasAttachments && (
            <div className="boss-task__request-attachments">{renderAttachments(questionAttachments)}</div>
          )}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
      {hasAnswer && renderAnswer(currentAnswer)}
      {hasAnswer && renderClarification(currentAnswer)}
    </div>
  );
}
