import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { JobOption, OTHER_JOB_TITLE_VALUE } from '../types';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import oFetch from 'o-fetch';

type SomethingWithStringInput = {
  input: {
    onChange: (value: string) => void,
    value: string,
  },
};

type JobTitleFieldsProps = {
  jobOptions: JobOption[],
  jobTitleFieldName: string,
  jobTitleFieldLabel: string,
  jobTitle: SomethingWithStringInput,
  otherJobTitleFieldName: string,
  otherJobTitleFieldLabel: string,
  otherJobTitle: SomethingWithStringInput,
  initialOtherJobTitleValue: string,
};

export default function JobTitleFields(props: JobTitleFieldsProps) {
  const otherJobTypeSelected = props.jobTitle.input.value === OTHER_JOB_TITLE_VALUE;
  const jobTitleInput = oFetch(props.jobTitle, 'input');
  const otherJobTitleInput = oFetch(props.otherJobTitle, 'input');

  useEffect(() => {
    if (jobTitleInput.value !== OTHER_JOB_TITLE_VALUE) {
      otherJobTitleInput.onChange('');
    } else {
      otherJobTitleInput.onChange(props.initialOtherJobTitleValue);
    }
  }, [jobTitleInput.value]);

  return (
    <>
      <Field
        required
        name={props.jobTitleFieldName}
        label={props.jobTitleFieldLabel}
        labelKey="name"
        valueKey="value"
        placeholder="Choose Option ..."
        parse={(value: any) => value}
        options={props.jobOptions}
        component={BossFormSelectNew}
      />
      { otherJobTypeSelected && (
        <Field
          name={props.otherJobTitleFieldName}
          label={props.otherJobTitleFieldLabel}
          parse={(value: string) => value}
          component={BossFormInput}
        />
      ) }
    </>
  );
}