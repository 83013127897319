import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import React, { useEffect } from 'react';
import { WhatsappFeatures } from './whatsapp-features';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';

export function WhatsappFeaturesApp(props: any) {
  console.log(props);
  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    window.boss.accessToken = accessToken;
    require('./style.css');
  }, []);

  return (
    <LegacyGlobalProvider global={{}}>
      <ModalsProvider>
        <WhatsappFeatures {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
