import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const UPLOADING_STATE = 'uploading';
export const UPLOADED_STATE = 'uploaded';
export const BROKEN_STATE = 'broken';

FileList.propTypes = {
  onDeleteFile: PropTypes.func,
  onFileSelect: PropTypes.func,
};

export function FileList(props) {
  const [files, onFileSelect, selectedFile] = oFetch(props, 'files', 'onFileSelect', 'selectedFile');

  const { onDeleteFile } = props;

  function renderFileList() {
    return Object.values(files).map((file, index) => {
      if (file.state === UPLOADING_STATE) {
        return (
          <div
            key={index}
            className="boss-upload__preview boss-upload__preview_adjust_flow boss-upload__preview_state_loading"
          />
        );
      }
      if (file.state === BROKEN_STATE) {
        return (
          <div
            key={index}
            className="boss-upload__preview boss-upload__preview_adjust_flow boss-upload__preview_state_broken"
          >
            <div className="boss-upload__preview-link" />
          </div>
        );
      }
      if (file.state === UPLOADED_STATE) {
        const previewClassNames = cn('boss-upload__preview boss-upload__preview_adjust_flow', {
          'boss-upload__preview_role_image': file.isImage,
          'boss-upload__preview_role_file': !file.isImage,
        });
        return (
          <button key={index} onClick={() => onFileSelect(file)} type="button" className={previewClassNames}>
            <div className="boss-upload__preview-link">
              {file.isImage && <img alt={file.name} className="boss-upload__preview-image" src={file.url} />}
              <div className="boss-upload__preview-caption">
                <p className="boss-upload__preview-caption-text">{file.name}</p>
              </div>
            </div>
          </button>
        );
      }
      throw new Error('Wrong file state');
    });
  }

  return (
    <>
      {selectedFile && (
        <div className="boss-upload__full boss-upload__full_adjust_form">
          <button onClick={() => onFileSelect(null)} type="button" className="boss-upload__full-close">
            Close
          </button>
          <img src={selectedFile.url} alt="attachment-1" className="boss-upload__full-image" />
          {onDeleteFile && (
            <div className="boss-upload__full-actions">
              <AsyncButton
                className="boss-button boss-button_role_delete boss-button_type_small"
                text="Delete"
                pendingText="Deleting ..."
                onClick={() => onDeleteFile && onDeleteFile(selectedFile)}
              />
            </div>
          )}
        </div>
      )}
      <div className="boss-upload__flow boss-upload__flow_adjust_form">{renderFileList()}</div>
    </>
  );
}
