import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

import utils from '@/lib/utils';

function Cell(props) {
  const text = oFetch(props, 'text');
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__text">{text}</p>
      </div>
    </div>
  );
}

function Row(props) {
  const [title, value] = oFetch(props, 'title', 'value');
  if (!value) {
    return null;
  }
  return (
    <div className="boss-table__row">
      <Cell text={title} />
      <Cell text={value} />
    </div>
  );
}

export default function PayslipModal(props) {
  const [payslip, onSubmit] = oFetch(props, 'payslip', 'onSubmit');

  function renderLeftColumn() {
    const lineItems = oFetch(payslip, 'lineItems');
    let nextIndex = oFetch(lineItems, 'length');

    const sickPayCents = oFetch(payslip, 'sickPayCents')
    const hasSickPay = !!(sickPayCents && (sickPayCents > 0));
    let sickPayIndex = null;
    if (hasSickPay) {
      sickPayIndex = nextIndex;
      nextIndex++;
    }

    const smpPayCents = oFetch(payslip, 'smpPayCents')
    const hasSmpPay = !!(smpPayCents && smpPayCents > 0);
    let smpPayIndex = null;
    if (hasSmpPay) {
      smpPayIndex = nextIndex;
      nextIndex++;
    }
    const renderPaymentsTableCashItem = function(params) {
      const itemTitle = oFetch(params, 'itemTitle');
      const cents = oFetch(params, 'cents');
      const index = oFetch(params, 'index');

      return (
        <div key={index} className="boss-table__row">
          <Cell text={itemTitle} />
          <Cell text={""} />
          <Cell text={""} />
          <Cell text={utils.formatMoneyCents({cents: cents})} />
        </div>
      );
    };

    return (
      <div className="boss-table__column">
        <div className="boss-table boss-table_page_payslip-payments">
          <div className="boss-table__row boss-table__row_role_header">
            <div className="boss-table__cell boss-table__cell_role_header">Payments</div>
            <div className="boss-table__cell boss-table__cell_role_header">Hours</div>
            <div className="boss-table__cell boss-table__cell_role_header">Rate</div>
            <div className="boss-table__cell boss-table__cell_role_header">Amount</div>
          </div>
          {lineItems.map((lineItem, index) => {
            const [
              name,
              hours,
              rateCents,
              totalCents
            ] = oFetch(
              lineItem,
              'name',
              'hours',
              'rateCents',
              'totalCents',
            );
            return (
              <div key={index} className="boss-table__row">
                <Cell text={name} />
                <Cell text={hours} />
                <Cell text={utils.formatMoneyCents(rateCents)} />
                <Cell text={utils.formatMoneyCents(totalCents)} />
              </div>
            );
          })}
          { hasSickPay && renderPaymentsTableCashItem({ itemTitle: "Sick Pay", cents: sickPayCents, index: sickPayIndex }) }
          { hasSmpPay && renderPaymentsTableCashItem({ itemTitle: "SMP Pay", cents: smpPayCents, index: smpPayIndex }) }
        </div>
      </div>
    );
  }

  function renderCenterColumn() {
    const [
      payeTaxDeductionCents,
      nationalInsuranceDeductionCents,
      eePensionDeductionCents,
      erPensionDeductionCents,
      studentLoanDeductionCents,
      customDeductions,
    ] = oFetch(
      payslip,
      'payeTaxDeductionCents',
      'nationalInsuranceDeductionCents',
      'eePensionDeductionCents',
      'erPensionDeductionCents',
      'studentLoanDeductionCents',
      'customDeductions',
    );

    return (
      <div className="boss-table__column">
        <div className="boss-table boss-table_page_payslip-deductions">
          <div className="boss-table__row boss-table__row_role_header">
            <div className="boss-table__cell boss-table__cell_role_header">Deductions</div>
            <div className="boss-table__cell boss-table__cell_role_header">Amount</div>
          </div>
          <Row title="PAYE Tax" value={utils.formatMoneyCents(payeTaxDeductionCents)} />
          <Row
            title="National Insurance"
            value={utils.formatMoneyCents(nationalInsuranceDeductionCents)}
          />
          <Row title="Ee Pension" value={utils.formatMoneyCents(eePensionDeductionCents)} />
          <Row title="Er Pension" value={utils.formatMoneyCents(erPensionDeductionCents)} />
          <Row title="Student Loan" value={utils.formatMoneyCents(studentLoanDeductionCents)} />
          {customDeductions.map((customDeduction, index) => {
            const [name, totalCents] = oFetch(customDeduction, 'name', 'totalCents');
            return <Row key={index} title={name} value={utils.formatMoneyCents(totalCents)} />;
          })}
        </div>
      </div>
    );
  }

  function renderRightColumn() {
    const [
      totalGrossPayTdCents,
      grossForTaxTdCents,
      taxPaidTdCents,
      earningsForNiTdCents,
      nationalInsuranceTdCents,
      eePensionCents,
      erPensionCents,
      earningForNiCents,
      grossForTaxCents,
      totalGrossPayCents,
      natInsuranceNumber,
    ] = oFetch(
      payslip,
      'totalGrossPayTdCents',
      'grossForTaxTdCents',
      'taxPaidTdCents',
      'earningsForNiTdCents',
      'nationalInsuranceTdCents',
      'eePensionCents',
      'erPensionCents',
      'earningForNiCents',
      'grossForTaxCents',
      'totalGrossPayCents',
      'natInsuranceNumber',
    );
    return (
      <div className="boss-table__column">
        <div className="boss-table boss-table_page_payslip-totals">
          <div className="boss-table__row boss-table__row_role_header">
            <div className="boss-table__cell boss-table__cell_role_header">Year to Date</div>
            <div className="boss-table__cell boss-table__cell_role_header">Amount</div>
          </div>
          <Row title="Total Gross Pay TD" value={utils.formatMoneyCents(totalGrossPayTdCents)} />
          <Row title="Gross for Tax TD" value={utils.formatMoneyCents(grossForTaxTdCents)} />
          <Row title="Tax Paid TD" value={utils.formatMoneyCents(taxPaidTdCents)} />
          <Row title="Earnings for NI TD" value={utils.formatMoneyCents(earningsForNiTdCents)} />
          <Row
            title="National Insurance TD"
            value={utils.formatMoneyCents(nationalInsuranceTdCents)}
          />
          <Row title="Ee Pension TD" value={utils.formatMoneyCents(eePensionCents)} />
          <Row title="Er Pension TD" value={utils.formatMoneyCents(erPensionCents)} />
          <Row title="Earnings for NI" value={utils.formatMoneyCents(earningForNiCents)} />
          <Row title="Gross for Tax" value={utils.formatMoneyCents(grossForTaxCents)} />
          <Row title="Total Gross Pay" value={utils.formatMoneyCents(totalGrossPayCents)} />
          <Row title="Nat. Insurance No." value={natInsuranceNumber} />
        </div>
      </div>
    );
  }

  function renderPayslipInfo() {
    const [departmentName, paymentMethod, paymentPeriod] = oFetch(
      payslip,
      'departmentName',
      'paymentMethod',
      'paymentPeriod',
    );

    return (
      <div className="boss-table boss-table_page_payslip-info">
        <div className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__text">
                <span className="boss-table__text-marked">Department</span> {departmentName}
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__text">
                <span className="boss-table__text-marked">Payment Method</span> - {paymentMethod}
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__text">
                <span className="boss-table__text-marked">Payment Period</span> - {paymentPeriod}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderPayslipSummary() {
    const [
      wkMt,
      displayDate,
      taxCode,
      employeeNumber,
      employeeName,
      netPayCents,
      departmentNumber,
      companyName,
    ] = oFetch(
      payslip,
      'wkMt',
      'displayDate',
      'taxCode',
      'employeeNumber',
      'employeeName',
      'netPayCents',
      'departmentNumber',
      'companyName',
    );

    return (
      <div className="boss-table boss-table_page_payslip-summary">
        <div className="boss-table__row boss-table__row_role_header">
          <div className="boss-table__cell boss-table__cell_role_header">WK/MT</div>
          <div className="boss-table__cell boss-table__cell_role_header">Date</div>
          <div className="boss-table__cell boss-table__cell_role_header">Dept.</div>
          <div className="boss-table__cell boss-table__cell_role_header">Tax Code</div>
          <div className="boss-table__cell boss-table__cell_role_header">Empl. No.</div>
          <div className="boss-table__cell boss-table__cell_role_header">Employee Name</div>
          <div className="boss-table__cell boss-table__cell_role_header">Net Pay</div>
        </div>
        <div className="boss-table__row">
          <Cell text={wkMt} />
          <Cell text={displayDate} />
          <Cell text={departmentNumber} />
          <Cell text={taxCode} />
          <Cell text={employeeNumber} />
          <Cell text={employeeName} />
          <Cell text={utils.formatMoneyCents(netPayCents)} />
        </div>
      </div>
    );
  }

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__overview">
        <div className="boss-overview">
          <div className="boss-overview__group">
            <div className="boss-overview__scroll">
              <div className="boss-overview__scroll-content">
                <div className="boss-table boss-table_page_payslip">
                  <div className="boss-table__group">
                    <div className="boss-table boss-table_page_payslip-header">
                      <div className="boss-table__row">
                        <div className="boss-table__cell">
                          <div className="boss-table__info">
                            <p className="boss-table__text">{companyName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__group">{renderPayslipInfo()}</div>
                  <div className="boss-table__group">
                    <div className="boss-table__row">
                      {renderLeftColumn()}
                      {renderCenterColumn()}
                      {renderRightColumn()}
                    </div>
                  </div>
                  <div className="boss-table__group">{renderPayslipSummary()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-overview__group boss-overview__group_position_last">
            <h4 className="boss-overview__label">
              <span className="boss-overview__label-text">Email Payslip to Staff Member</span>
              <AsyncButton
                text="Send"
                pendingText="Sending ..."
                onClick={onSubmit}
                className="boss-button boss-button_role_email-payslip boss-button_type_extra-small boss-overview__label-action"
              />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
