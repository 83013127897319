import React from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { Bonai } from './bonai';

export function BonaiApp(props) {
  const { accessToken, ...otherProps } = props;

  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <Bonai {...otherProps} />
    </ModalsProvider>
  );
}
