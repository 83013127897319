import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { StaffTrackingDashboard, StaffTrackingItem } from './components';

export default function StaffTracking(props) {
  const [weekStart, weekEnd, deletionEvents, creationEvents] = oFetch(
    props,
    'weekStart',
    'weekEnd',
    'deletionEvents',
    'creationEvents',
  );

  const creationEventsCount = creationEvents.length;
  const deletionEventsCount = deletionEvents.length;

  function renderStaffAddedHeader() {
    return (
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header">Name</div>
        <div className="boss-table__cell boss-table__cell_role_header">Date</div>
        <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
      </div>
    );
  }

  function renderStaffDisabledHeader() {
    return (
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header">Name</div>
        <div className="boss-table__cell boss-table__cell_role_header">Date</div>
        <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
      </div>
    );
  }

  function renderDeletionEventItem(item) {
    const [url, fullName, date, venue] = oFetch(item, 'url', 'fullName', 'date', 'venue');

    return (
      <div className="boss-table__row">
        <div className="boss-table__cell">
          <p className="boss-table__text">
            <a href={url} className="boss-table__link">
              {fullName}
            </a>
          </p>
        </div>
        <div className="boss-table__cell">
          <p className="boss-table__text">{date}</p>
        </div>
        <div className="boss-table__cell">
          <p className="boss-table__text">{venue}</p>
        </div>
      </div>
    );
  }

  function renderCreationEventItem(item) {
    const [url, fullName, date, venue] = oFetch(item, 'url', 'fullName', 'date', 'venue');

    return (
      <div className="boss-table__row">
        <div className="boss-table__cell">
          <p className="boss-table__text">
            <a href={url} className="boss-table__link">
              {fullName}
            </a>
          </p>
        </div>
        <div className="boss-table__cell">
          <p className="boss-table__text">{date}</p>
        </div>
        <div className="boss-table__cell">
          <p className="boss-table__text">{venue}</p>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <StaffTrackingDashboard weekStart={weekStart} weekEnd={weekEnd} />
      <ContentWrapper>
        <StaffTrackingItem
          count={creationEventsCount}
          title="Staff Added"
          items={creationEvents}
          tableHeader={renderStaffAddedHeader}
          itemRenderer={renderCreationEventItem}
        />
        <StaffTrackingItem
          count={deletionEventsCount}
          title="Staff Disabled"
          items={deletionEvents}
          tableHeader={renderStaffDisabledHeader}
          itemRenderer={renderDeletionEventItem}
        />
      </ContentWrapper>
    </Fragment>
  );
}
