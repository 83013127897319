import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form } from 'react-final-form';
import { Fields } from '@/components/boss-form';
import { BossFormRoleVenueSelect } from '@/components/final-form-fields';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function NotLinkedUserAccessDetailsForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  const [permittedRolesOptions, venuesOptions, venuesAccess] = oFetch(
    useLegacyGlobal(),
    'permittedRolesOptions',
    'venuesOptions',
    'venuesAccess',
  );
  const userUnlinkedApplicationTypes = oFetch(props, 'userUnlinkedApplicationTypes');

  const filteredVenuesOptions = venuesOptions.reduce((acc, venueOption) => {
    const appTypes = oFetch(venueOption, 'appTypes');
    userUnlinkedApplicationTypes.forEach((userUnlinkedApplicationType) => {
      if (appTypes.includes(userUnlinkedApplicationType)) {
        acc.push(venueOption);
      }
    });
    return acc;
  }, [])

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Fields
                  names={['role', 'venues']}
                  roleName="role"
                  venuesName="venues"
                  permittedRolesOptions={permittedRolesOptions}
                  venuesAccess={venuesAccess}
                  originalRender={BossFormRoleVenueSelect}
                  venuesOptions={filteredVenuesOptions}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-form__submit boss-form__submit_adjust_single"
                    disabled={submitting}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
