import React from 'react';
import Select from '@/lib/boss-react-select';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import oFetch from 'o-fetch';

function HighlightOptionComponent(props) {
  function handleMouseDown(event) {
    const [onSelect, option] = oFetch(props, 'onSelect', 'option');
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }

  function handleMouseEnter(event) {
    const [onFocus, option] = oFetch(props, 'onFocus', 'option');
    onFocus(option, event);
  }

  function handleMouseMove(event) {
    const [onFocus, isFocused, option] = oFetch(props, 'onFocus', 'isFocused', 'option');
    if (isFocused) return;
    onFocus(option, event);
  }
  const { option, inputValue } = props;
  const matches = match(option.label, inputValue, { insideWords: true });
  const parts = parse(option.label, matches);

  return (
    <div
      className={props.className}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={option.title}
    >
      <div>
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </div>
  );
}

export function BossSelectHighlight(props) {
  const [
    value,
    onChange,
    valueKey,
    labelKey,
    clearable,
    multi,
    placeholder,
    options,
    disabled,
    valueComponent,
  ] = oFetch(
    props,
    'value',
    'onChange',
    'valueKey',
    'labelKey',
    'clearable',
    'multi',
    'placeholder',
    'options',
    'disabled',
    'valueComponent',
  );

  const [controlClassName, menuClassName] = oFetch(props, 'controlClassName', 'menuClassName');

  return (
    <Select
      value={value}
      valueKey={valueKey}
      labelKey={labelKey}
      onChange={onChange}
      clearable={clearable}
      optionComponent={HighlightOptionComponent}
      valueComponent={valueComponent}
      simpleValue
      multi={multi}
      placeholder={placeholder}
      options={options}
      disabled={disabled}
      controlClassName={controlClassName}
      menuClassName={menuClassName}
    />
  );
}

BossSelectHighlight.defaultProps = {
  multi: false,
  clearable: false,
  placeholder: '',
  disabled: false,
  valueKey: 'value',
  labelKey: 'label',
  controlClassName: '',
  menuClassName: '',
  valueComponent: null,
};
