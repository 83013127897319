import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

import ReminderForm from '../../components/reminder-form';

class EditReminder extends Component {
  render() {
    const [reminderDate, onSubmit, recurrenceTypesOptions] = oFetch(
      this.props,
      'reminderDate',
      'onSubmit',
      'recurrenceTypesOptions',
    );
    const [handoverPlannerReminderId, title, description] = oFetch(
      reminderDate,
      'handoverPlannerReminderId',
      'title',
      'description',
    );
    const initialValues = {
      id: handoverPlannerReminderId,
      title: title,
      description: description,
    };
    return (
      <div className="boss-modal-window__form">
        <ReminderForm
          recurrenceTypesOptions={recurrenceTypesOptions}
          initialValues={initialValues}
          isEditing={true}
          showDateField={false}
          onSubmit={onSubmit}
          buttonText="Edit reminder"
        />
      </div>
    );
  }
}
export default modalRedux(combineReducers({ form: formReducer }))(EditReminder);
