import React from 'react';
import PropTypes from 'prop-types';
import RotaFilter from '@/components/security-rota/security-rota-filter';
import oFetch from 'o-fetch';

function SecurityRotaContent(props) {
  function renderLoader() {
    return (
      <div className="boss-rotas__days-item">
        <section className="boss-board">
          <div className="boss-spinner" />
        </section>
      </div>
    );
  }

  const {
    isLoading,
    rotaWeekDaysRenderer,
    leftSideRenderer,
    rightSideRenderer,
    sRotaDate,
    rotaShiftsLength,
    totalRequestsCount,
  } = props;
  const sRotaEditUrlDate = oFetch(props, 'sRotaEditUrlDate');

  return (
    <div className="boss-page-main__content">
      <div className="boss-page-main__inner">
        <div className="boss-rotas">
          <div className="boss-rotas__days">
            <div className="boss-form__row  boss-form__row_justify_space">
              <div />
              <RotaFilter
                page="weekly"
                securityShiftRequestsCount={totalRequestsCount}
                sCurrentRotaDate={sRotaEditUrlDate}
              />
            </div>
            {rotaWeekDaysRenderer()}
            {isLoading ? (
              renderLoader()
            ) : (
              <div className="boss-rotas__days-item">
                <section className="boss-board">
                  <header key="header" className="boss-board__header boss-board__header_adjust_rotas-weekly">
                    <h2 className="boss-board__title boss-board__title_size_small">
                      <a
                        href={`security_rotas/${sRotaEditUrlDate}`}
                        className="boss-board__title-link boss-board__title-link_role_date"
                      >
                        &nbsp;{sRotaDate}&nbsp;
                      </a>
                      <a
                        href={`security_rotas/${sRotaEditUrlDate}`}
                        className="boss-button boss-button_type_small boss-button_role_edit-light boss-board__title-action"
                      >
                        &nbsp;Edit&nbsp;
                      </a>
                    </h2>
                  </header>
                  <div key="content" className="boss-board__main">
                    {rotaShiftsLength === 0 ? (
                      <div className="boss-board__main-inner">
                        <p className="boss-board__text-placeholder">No Data Available.</p>
                      </div>
                    ) : (
                      <div className="boss-board__rota">
                        <div className="boss-board__graph">
                          <div className="boss-board__graph-inner">
                            <div className="rota-overview-chart">
                              <div className="rota-overview-chart__inner">{leftSideRenderer()}</div>
                            </div>
                          </div>
                        </div>
                        {rightSideRenderer()}
                      </div>
                    )}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

SecurityRotaContent.propTypes = {
  rotaWeekDaysRenderer: PropTypes.func.isRequired,
  leftSideRenderer: PropTypes.func.isRequired,
  rightSideRenderer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sRotaEditUrlDate: PropTypes.string.isRequired,
  sRotaDate: PropTypes.string.isRequired,
  rotaShiftsLength: PropTypes.number.isRequired,
  totalRequestsCount: PropTypes.number.isRequired,
};

export default SecurityRotaContent;
