import React, { Component } from 'react';
import Select from '@/lib/boss-react-select';
import oFetch from 'o-fetch';
import cn from 'classnames';
import {
  AccessoryColorOption,
  AccessoryColorValue,
  AccessoryIconOption,
  AccessoryIconValue,
} from './colored-select';
export default class BossFormAccessoryIconFields extends Component {
  getColor = value => {
    const iconsColorsOptions = oFetch(this.props, 'iconsColorsOptions');
    const colorData = iconsColorsOptions.find(option => oFetch(option, 'value') === value);
    return oFetch(colorData, 'color');
  };

  getIconLabel = value => {
    const iconsOptions = oFetch(this.props, 'iconsOptions');
    const iconData = iconsOptions.find(option => oFetch(option, 'value') === value);
    return oFetch(iconData, 'label');
  };

  renderErrors = errors => {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  render() {
    const [names, iconsOptions, iconsColorsOptions] = oFetch(
      this.props,
      'names',
      'iconsOptions',
      'iconsColorsOptions',
    );
    const fieldIcon = this.props[names[0]];
    const fieldIconColor = this.props[names[1]];
    const [fiOnChange, fiName, fiValue, fiMeta, fiTouched] = oFetch(
      fieldIcon,
      'input.onChange',
      'input.name',
      'input.value',
      'meta',
      'meta.touched',
    );
    const [ficOnChange, ficName, ficValue, ficMeta, ficTouched] = oFetch(
      fieldIconColor,
      'input.onChange',
      'input.name',
      'input.value',
      'meta',
      'meta.touched',
    );
    const fiError = fiMeta.error;
    const ficError = ficMeta.error;
    const valuesExist = fiValue && ficValue;
    const fiSelectClassName = cn('boss-form__select', { 'boss-form__select_state_error': fiError });
    const ficSelectClassName = cn('boss-form__select', {
      'boss-form__select_state_error': ficError,
    });
    return (
      <div className="boss-form__row">
        <div className="boss-form__field boss-form__field_layout_max">
          <div className="boss-form__row">
            <div className="boss-form__field boss-form__field_layout_half">
              <label className="boss-form__label">
                <span className="boss-form__label-text">Accessory Icon</span>
              </label>
              <div className={fiSelectClassName}>
                <Select
                  options={iconsOptions}
                  onChange={fiOnChange}
                  name={fiName}
                  valueComponent={AccessoryIconValue}
                  optionComponent={AccessoryIconOption}
                  value={fiValue}
                  simpleValue
                  multi={false}
                  searchable={false}
                  clearable={false}
                />
                {fiTouched && fiError && this.renderErrors(fiError)}
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_half">
              <label className="boss-form__label">
                <span className="boss-form__label-text">Accessory Icon Color</span>
              </label>
              <div className={ficSelectClassName}>
                <Select
                  options={iconsColorsOptions}
                  onChange={ficOnChange}
                  name={ficName}
                  valueComponent={AccessoryColorValue}
                  optionComponent={AccessoryColorOption}
                  value={ficValue}
                  simpleValue
                  multi={false}
                  searchable={false}
                  clearable={false}
                />
                {ficTouched && ficError && this.renderErrors(ficError)}
              </div>
            </div>
          </div>
        </div>
        <div className="boss-form__field boss-form__field_layout_min">
          <p className="boss-form__label">
            <span className="boss-form__label-text">Preview</span>
          </p>
          {valuesExist && (
            <div className="boss-form__area boss-form__area_size_nano">
              <div className="boss-indicator boss-indicator_adjust_tooltip boss-indicator_adjust_flow">
                <div
                  className={`boss-indicator__icon boss-indicator__icon_${fiValue}`}
                  style={{ color: this.getColor(ficValue) }}
                />
                <div className="boss-indicator__tooltip">
                  <span className="boss-indicator__tooltip-line">
                    <span className="boss-indicator__tooltip-marked">
                      {this.getIconLabel(fiValue)}
                    </span>
                  </span>
                  <span className="boss-indicator__tooltip-line">Venue name</span>
                </div>
              </div>
              <div className="boss-indicator boss-indicator_adjust_tooltip boss-indicator_adjust_flow">
                <div
                  className={`boss-indicator__icon boss-indicator__icon_${fiValue}`}
                  style={{ color: this.getColor(ficValue) }}
                />
                <div className="boss-indicator__counter">2</div>
                <div className="boss-indicator__tooltip">
                  <span className="boss-indicator__tooltip-line">
                    <span className="boss-indicator__tooltip-marked">
                      {this.getIconLabel(fiValue)}
                    </span>
                  </span>
                  <span className="boss-indicator__tooltip-line">Venue name</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
