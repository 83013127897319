import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Fields } from 'redux-form';
import uuid from 'uuid/v1';
import HeadDoorTimeInterval from './head-door-time-interval';

export function HeadDoorHoursArray(props) {
  const [
    fields,
    hoursAcceptancePeriod,
    activeSpecialSecurityPayrates,
    meta,
    rotaDate,
  ] = oFetch(
    props,
    'fields',
    'hoursAcceptancePeriod',
    'activeSpecialSecurityPayrates',
    'meta',
    'rotaDate',
  );
  const { submitting } = meta;
  const [frontendId, startsAt, endsAt] = oFetch(
    hoursAcceptancePeriod,
    'date',
    'frontendId',
    'startsAt',
    'endsAt',
  );

  function onAddDoorHours(fields) {
    const newHeadDoorHours = {
      id: null,
      frontendId: uuid(),
      securityPayRateId: null,
      securityPayRateName: null,
      hoursAcceptancePeriod: frontendId,
      startsAt: startsAt,
      endsAt: endsAt,
    };

    fields.push(newHeadDoorHours);
  }

  return (
    <Fragment>
      {fields.map((headDoorHours, index) => {
        return (
          <div key={index} className="boss-time-shift__hdh-item">
            <Fields
              names={[
                `${headDoorHours}.startsAt`,
                `${headDoorHours}.endsAt`,
                `${headDoorHours}.securityPayRateId`,
                `${headDoorHours}.base`,
              ]}
              specialSecurityPayrates={activeSpecialSecurityPayrates}
              component={HeadDoorTimeInterval}
              index={index}
              granularityInMinutes={1}
              rotaDate={rotaDate}
              deleteRenderer={() => {
                return (
                  <div className="boss-time-shift__actions">
                    <button
                      disabled={submitting}
                      onClick={() => fields.remove(index)}
                      type="button"
                      className="boss-button boss-button_type_icon boss-button_role_cancel boss-time-shift__button boss-time-shift__button_role_delete-break"
                    >
                      <i className="fa fa-remove" />
                    </button>
                  </div>
                );
              }}
            />
          </div>
        );
      })}
      <div className="boss-time-shift__actions">
        <button
          type="button"
          onClick={() => onAddDoorHours(fields)}
          className="boss-button boss-button_role_add boss-time-shift__button boss-time-shift__button_role_add-break"
        >
          Add HD shift
        </button>
      </div>
    </Fragment>
  );
}
