import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export const paginationSelector = state => oFetch(state, 'pagination');
export const questionnaireResponsesSelector = state => oFetch(state, 'questionnaireResponses');
export const staticDataSelector = state => oFetch(state, 'staticData');

export const getQuestionnaireResponses = createSelector([questionnaireResponsesSelector], questionnaireResponses => {
  return questionnaireResponses.map(questionnaireResponse => {
    const at = oFetch(questionnaireResponse, 'at');
    const formattedAt = safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTime());
    return {
      ...questionnaireResponse,
      formattedAt,
    };
  });
});

export const getCurrentPage = createSelector([paginationSelector], pagination => {
  return oFetch(pagination, 'currentPage');
});

export const getTotalPages = createSelector([paginationSelector], pagination => {
  return oFetch(pagination, 'totalPages');
});

export const getTotalCount = createSelector([paginationSelector], pagination => {
  return oFetch(pagination, 'totalCount');
});

export const getCurrentVenueId = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'venueId');
});

export const getNewResponsePath = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'newResponsePath');
});

export const getManageQuestionnairePath = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'manageQuestionnairePath');
});

export const getCanManageQuestionnaire = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'canManageQuestionnaire');
});

export const getCanCreateQuestionnaireRepsonse = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, "canCreateQuestionnaireResponse");
})

export const loadMoreVisible = createSelector([getTotalPages, getCurrentPage], (totalPages, currentPage) => {
  return currentPage < totalPages;
});
