import React from 'react';
import { CreateNonStaffMemberStaffPartyPersonForm } from './create-non-staff-member-staff-party-person-form';
import { MossStaffMemberOption, CreateFormValues, CreateFormSubmitHandler } from '../types';

type CreateModalContentProps = {
  mossStaffMemberOptions: MossStaffMemberOption[]
  onSubmit: CreateFormSubmitHandler,
};

export function CreateModalContent(props: CreateModalContentProps) {

  const initialValues: CreateFormValues = {
    mossStaffMemberId: null,
    phoneNumber: "",
    email: "",
  };

  return (
    <CreateNonStaffMemberStaffPartyPersonForm
      mossStaffMemberOptions={props.mossStaffMemberOptions}
      initialValues={initialValues}
      onSubmit={props.onSubmit}
    />
  );
}