import oFetch from 'o-fetch';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

class StaffCountBlock extends Component {
  render() {
    const count = this.props.count !== undefined ? this.props.count : 0;
    const title = oFetch(this.props, 'title');
    const accentColorClassName = oFetch(this.props, 'accentColorClassName');
    const effectiveAccentColorClassName = (count > 0) ? accentColorClassName : '';
    const accentIconClassName = oFetch(this.props, 'accentIconClassName');
    const classes = [
      'boss-count',
      'boss-count_role_link',
      'boss-count_adjust_row',
      'boss-count_size_half',
      'boss-count_type_badge',
      'boss-count_adjust_icon-before',
      effectiveAccentColorClassName,
    ].filter(n => n); //Remove undefined && null values
    const blockClassNames = classNames(classes.join(' '));

    return (
      <Link to={ this.props.href } className={ blockClassNames }>
        <div className="boss-count__group">
          <div className={ `boss-count__icon ${accentIconClassName}` }></div>
          <div className="boss-count__info">
            <p className="boss-count__number">{ count }</p>
            <p className="boss-count__label">{ title }</p>
          </div>
        </div>
      </Link>
    );
  }
}

StaffCountBlock.propTypes = {
  count: PropTypes.number,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  accentColorClassName: PropTypes.string.isRequired,
  accentIconClassName: PropTypes.string.isRequired,
};

export default StaffCountBlock;
