import { createSelector } from 'reselect';
import utils from '@/lib/utils';
import getVenueColor from '@/lib/get-venue-color';
import oFetch from 'o-fetch';

export const securityShiftRequestsSelector = state => oFetch(state, 'securityShiftRequests');
export const countsSelector = state => oFetch(state, 'counts');
export const weekDatesSelector = state => oFetch(state, 'pageOptions.weekDates');
export const weekStartDateSelector = state => oFetch(state, 'pageOptions.startDate');
export const weekEndDateSelector = state => oFetch(state, 'pageOptions.endDate');
export const chosenDateSelector = state => oFetch(state, 'pageOptions.chosenDate');
export const venuesSelector = state =>
  oFetch(state, 'pageOptions.accessibleVenues').map(venue => {
    return {
      ...venue,
      color: getVenueColor(oFetch(venue, 'id')),
    };
  });
const grouppedByVenue = utils.groupBy('venueName');

export const getPendingSecurityShiftRequests = createSelector(
  securityShiftRequestsSelector,
  securityShiftRequests => {
    return grouppedByVenue(
      securityShiftRequests.filter(
        securityShiftRequest => oFetch(securityShiftRequest, 'status') === 'pending',
      ),
    );
  },
);

export const getCompletedSecurityShiftRequests = createSelector(
  securityShiftRequestsSelector,
  securityShiftRequests => {
    return grouppedByVenue(
      securityShiftRequests.filter(
        securityShiftRequest => oFetch(securityShiftRequest, 'status') !== 'pending',
      ),
    );
  },
);
