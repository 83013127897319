import React from 'react';
import utils from '@/lib/utils';
import VenueRotaOverviewChart from '../venue-rota-overview-chart';
import RotaForecast from '../containers/rota-forecast';
import safeMoment from '@/lib/safe-moment';
import rotaStatusTitles from '@/lib/rota-status-titles';
import oFetch from 'o-fetch';

export default class RotaCurrentDay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverData: null,
      selectionData: null,
    };
  }

  render() {
    const isTrading = oFetch(this.props, 'isTrading');
    const isDeliveryDay = oFetch(this.props, 'isDeliveryDay');
    const rota = oFetch(this.props, 'rota');
    const mRotaDate = safeMoment
      .uiDateParse(utils.formatJSDateToUIDate(oFetch(rota, 'date')));

    const sFormattedRotaDateLong = mRotaDate.format('dddd, D MMMM YYYY');

    const sFormattedRotaEditUrlDate = mRotaDate.format('DD-MM-YYYY');
    const status = oFetch(rota, 'status');
    const dDateOfRota = mRotaDate.toDate();

    return (
      <div className="boss-rotas__days-item">
        <section className="boss-board">
          {this.props.isLoading ? (
            <div className="boss-spinner" />
          ) : (
            [
              <header
                key="header"
                className="boss-board__header boss-board__header_adjust_rotas-weekly"
              >
                <h2 className="boss-board__title boss-board__title_size_small">
                  <a
                    href={`rotas/${sFormattedRotaEditUrlDate}`}
                    className="boss-board__title-link boss-board__title-link_role_date"
                  >
                    &nbsp;{sFormattedRotaDateLong}&nbsp;
                  </a>
                  <a
                    href={`rotas/${sFormattedRotaEditUrlDate}`}
                    className="boss-button boss-button_type_small boss-button_role_edit-light boss-board__title-action"
                  >
                    &nbsp;Edit&nbsp;
                  </a>
                </h2>
                <div className="boss-board__button-group">
                  <p className="boss-button boss-button_role_published boss-button_type_extra-small boss-button_type_no-behavior boss-board__button">
                    {rotaStatusTitles[status]}
                  </p>
                  {isDeliveryDay && <p className="boss-button boss-button_role_delivery-day boss-button_type_extra-small boss-button_type_no-behavior boss-board__button">
                    {'Delivery Day'}
                  </p>}
                  {!isTrading && <p className="boss-button boss-button_role_not-trading boss-button_type_extra-small boss-button_type_no-behavior boss-board__button">
                    {'Not Trading'}
                  </p>}
                </div>
              </header>,
              <div
                key="content"
                className="boss-board__main"
              >
                <div className="boss-board__rota">
                  <div className="boss-board__graph">
                    <div className="boss-board__graph-inner">
                      <div className="rota-overview-chart">
                        <div className="rota-overview-chart__inner">
                          <VenueRotaOverviewChart
                            staff={this.props.staff}
                            shifts={this.props.shifts}
                            dDateOfRota={dDateOfRota}
                            staffTypes={this.props.staffTypesWithShifts}
                            onHoverShiftsChange={data => this.setState({ hoverData: data })}
                            onSelectionShiftsChange={data => this.setState({ selectionData: data })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-board__forecast">
                    <RotaForecast
                      isLoading={this.props.isLoading}
                      rotaClientId={this.props.rota.clientId}
                      forecast={this.props.rotaForecast}
                      canEditForecastedTake={true}
                    />
                  </div>
                </div>
              </div>,
            ]
          )}
        </section>
      </div>
    );
  }
}
