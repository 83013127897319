import React from 'react';
import EditHolidayForm from './edit-holiday-content-form';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';

class EditHolidayContent extends React.PureComponent {
  render() {
    const [
      canForwardHolidays,
      holiday,
      onSubmit,
      buttonTitle
    ] = oFetch(
      this.props,
      'canForwardHolidays',
      'holiday',
      'onSubmit',
      'buttonTitle',
    );

    const holidayData = holiday.toJS();
    const initialValues = {
      forward: false,
      note: oFetch(holidayData, 'note'),
      startDate: safeMoment.uiDateParse(oFetch(holidayData, 'start_date')),
      endDate: safeMoment.uiDateParse(oFetch(holidayData, 'end_date')),
      holidayType: oFetch(holidayData, 'holiday_type'),
      id: oFetch(holidayData, 'id'),
    };

    return (
      <div className="boss-modal-window__form">
        <EditHolidayForm
          canForwardHolidays={canForwardHolidays}
          initialValues={initialValues}
          submission={onSubmit}
          buttonTitle={buttonTitle}
        />
      </div>
    );
  }
}

export default EditHolidayContent;