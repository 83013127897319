import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { reset } from 'redux-form';

import TaskForm from './task-form';

class AddTask extends Component {
  onSubmit = (values, dispatch, form) => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    return onSubmit(values).then(() => {
      dispatch(reset(oFetch(form, 'form')));
    });
  };
  render() {
    const date = oFetch(this.props, 'date');
    const initialValues = {
      text: null,
      date: date,
    };
    return <TaskForm initialValues={initialValues} onSubmit={this.onSubmit} buttonText="Add task" />;
  }
}
export default AddTask;
