import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

DetailsCard.defaultProps = {
  onEditClick: null,
  baseClassName: '',
};

export function DetailsCard(props) {
  const [number, title, onEditClick, children, baseClassName] = oFetch(
    props,
    'number',
    'title',
    'onEditClick',
    'children',
    'baseClassName',
  );
  const baseClassNames = cn('boss-page-main__isle', { [baseClassName]: !!baseClassName });

  return (
    <div className={baseClassNames}>
      <section className="boss-details">
        <p className="boss-details__pointer">
          <span className="boss-details__pointer-text">{number}</span>
        </p>
        <div className="boss-details__content">
          <h3 className="boss-details__title">
            <span className="boss-details__title-label">{title}</span>
            {onEditClick && (
              <span className="boss-details__title-action">
                <button
                  onClick={onEditClick}
                  className="boss-button boss-button_role_update boss-button_type_extra-small"
                >
                  Edit
                </button>
              </span>
            )}
          </h3>
          {children}
        </div>
      </section>
    </div>
  );
}
