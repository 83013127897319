import React, { useMemo, useEffect } from 'react';
import oFetch from 'o-fetch';

import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import { BonusPaymentsAdmin } from './bonus-payments-admin';

export function BonusPaymentsAdminApp(props) {
  const [year, quarter, years, quarterNumbers, staffMembers, yearsQuarters] = oFetch(
    props,
    'year',
    'quarterNumber',
    'years',
    'quarterNumbers',
    'staffMembers',
    'yearsQuarters',
  );

  const staffMembersOptions = useMemo(() => {
    return staffMembers.map(staffMember => {
      const [id, fullName, avatarUrl] = oFetch(staffMember, 'id', 'fullName', 'avatarUrl');
      return {
        value: id,
        label: fullName,
        image: avatarUrl,
      };
    });
  }, [staffMembers]);

  const yearsOptions = useMemo(() => {
    return years.map(year => {
      return {
        value: year,
        label: year,
      };
    });
  }, [years]);

  useEffect(() => {
    require('./styles.css');
    window.boss.accessToken = oFetch(props, 'accessToken');
  }, []);

  return (
    <LegacyGlobalProvider
      global={{
        year,
        quarter,
        years,
        quarterNumbers,
        staffMembersOptions,
        yearsOptions,
        yearsQuarters,
      }}
    >
      <ModalsProvider>
        <BonusPaymentsAdmin {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
