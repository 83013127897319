import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

const DetailsListItem = props => {
  let value = oFetch(props.item, 'value');
  const name = oFetch(props.item, 'name');
  const { labelClass, valueClass } = props;
  if (typeof value === 'function') {
    value = value();
  }
  const labelClassNames = cn('boss-details__label boss-details__label boss-details__label_size_small', {
    [labelClass]: !!labelClass,
  });
  const valueClassNames = cn('boss-details__value', { [valueClass]: !!valueClass });
  return (
    <li className="boss-details__item">
      <div className={labelClassNames}>{name}</div>
      <div className={valueClassNames}>{value}</div>
    </li>
  );
};

export default DetailsListItem;
