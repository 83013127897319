import React from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';

import DashboardWrapper from '@/components/dashboard-wrapper';
import { AcceptedTasksFilter } from './accepted-tasks-filter';

export function AcceptedTasksDashboard(props) {
  const [onBackClick, onFilterSubmit, acceptedTasks] = oFetch(
    props,
    'onBackClick',
    'onFilterSubmit',
    'acceptedTasks',
  );
  const currentQueryString = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
  const filterInitialValues = {
    title: currentQueryString.title || '',
    startDate: currentQueryString.start_date || null,
    endDate: currentQueryString.end_date || null,
    venueIds: currentQueryString.venues || [],
    assignedToId: currentQueryString.assigned_to || null,
  };
  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Accepted Marketing Tasks</h1>
          <div className="boss-page-dashboard__buttons-group">
            <a
              href="/marketing_tasks"
              className="boss-button boss-button_role_back-alt boss-page-dashboard__button"
            >
              Back
            </a>
          </div>
        </div>
        <AcceptedTasksFilter
          initialValues={filterInitialValues}
          acceptedTasks={acceptedTasks}
          onSubmit={onFilterSubmit}
        />
      </div>
    </DashboardWrapper>
  );
}
