import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  selectedTab: null,
};

export default handleActions(
  {
    [types.SET_FILTER]: (state, action) => {
      const selectedTab = oFetch(action, 'payload.selectedTab');
      return {
        selectedTab: selectedTab,
      };
    },
  },
  initialState,
);
