import React from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { ContactDetails } from './contacts-details';

export function ContactsDetailsApp(props) {
  return (
    <ModalsProvider>
      <ContactDetails {...props} />
    </ModalsProvider>
  );
}
