import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {
  permissions: {
    canViewMangeTagsPage: false,
  },
  questionTypes: {},
  singleTextType: null,
  multiTextType: null,
  singleImageType: null,
  multiImageType: null,
  quizCategoryQuestionTags: [],
};

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const [
        permissions,
        questionTypes,
        singleTextType,
        multiTextType,
        singleImageType,
        multiImageType,
        questionActiveState,
        questionDisabledState,
        quizCategoryQuestionTags,
      ] = oFetch(
        action.payload,
        'permissions',
        'questionTypes',
        'singleTextType',
        'multiTextType',
        'singleImageType',
        'multiImageType',
        'questionActiveState',
        'questionDisabledState',
        'quizCategoryQuestionTags',
      );
      return {
        ...state,
        permissions,
        questionTypes,
        singleTextType,
        multiTextType,
        singleImageType,
        multiImageType,
        questionActiveState,
        questionDisabledState,
        quizCategoryQuestionTags,
      };
    },
  },
  initialState,
);
