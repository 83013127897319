import React from 'react';
import oFetch from 'o-fetch';

export default function CategoriesHeader(props) {
  const rankName = oFetch(props, 'rankName');
  return (
    <header className="boss-board__header">
      <h2 className="boss-board__title">{rankName} Categories</h2>
    </header>
  );
}
