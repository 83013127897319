import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const specialPayRateSecurityHours = oFetch(action, 'payload.specialPayRateSecurityHours');
      return specialPayRateSecurityHours.map(specialPayRateSecurityHoursItem => {
        const id = oFetch(specialPayRateSecurityHoursItem, 'id');
        return {
          ...specialPayRateSecurityHoursItem,
          frontendId: id,
        };
      });
    },
    [types.UPDATE_PERIOD_DATA]: (state, action) => {
      const specialPayRateSecurityHours = oFetch(action, 'payload.specialPayRateSecurityHours');
      const frontendId = oFetch(action, 'payload.frontendId');

      return [
        ...state.filter(periodBreak => oFetch(periodBreak, 'hoursAcceptancePeriod') !== frontendId),
        ...specialPayRateSecurityHours,
      ];
    },
    [types.ACCEPT_HOURS_ACCEPTANCE_PERIOD]: (state, action) => {
      const specialPayRateSecurityHours = oFetch(action, 'payload.specialPayRateSecurityHours');
      const frontendId = oFetch(action, 'payload.frontendId');

      return [
        ...state.filter(
          specialPayRateSecurityHoursItem =>
            oFetch(specialPayRateSecurityHoursItem, 'hoursAcceptancePeriod') !== frontendId,
        ),
        ...specialPayRateSecurityHours,
      ];
    },
  },
  initialState,
);
