import React from 'react';
import oFetch from 'o-fetch';
import SubmissionsListItem from './submissions-list-item';

function SubmissionsList(props) {
  const [items, onDetailsClick] = oFetch(props, 'items', 'onDetailsClick');

  const itemsExist = items.size > 0;

  if (!itemsExist) {
    return <h1 className="boss-table__cell boss-table__cell_role_header">No checklists found</h1>;
  }

  function renderTableHeader() {
    return (
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header" />
        <div className="boss-table__cell boss-table__cell_role_header">Date</div>
        <div className="boss-table__cell boss-table__cell_role_header">Check List</div>
        <div className="boss-table__cell boss-table__cell_role_header">Created By</div>
        <div className="boss-table__cell boss-table__cell_role_header">Status</div>
        <div className="boss-table__cell boss-table__cell_role_header">Action</div>
      </div>
    );
  }

  function renderItems(items) {
    return items.map((item, key) => {
      return <SubmissionsListItem onDetailsClick={onDetailsClick} key={key} item={item} />;
    });
  }

  return (
    <div className="boss-table boss-table_page_checklist-review">
      {renderTableHeader()}
      {renderItems(items)}
    </div>
  );
}

export default SubmissionsList;
