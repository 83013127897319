import React from 'react';
import oFetch from 'o-fetch';
import { UISelectField } from '../ui-fields';

export default function SelectField(props) {
  const [label, placeholder, isMulti, options, valueKey, labelKey] = oFetch(
    props,
    'label',
    'placeholder',
    'isMulti',
    'options',
    'valueKey',
    'labelKey',
  );
  const [input, meta] = oFetch(props, 'input', 'meta');
  const [onChange, value, name] = oFetch(input, 'onChange', 'value', 'name');
  const { submitError } = meta;
  const errors = submitError || [];

  return (
    <UISelectField
      label={label}
      isMulti={isMulti}
      valueKey={valueKey}
      labelKey={labelKey}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={options}
      name={name}
      errors={errors}
    />
  );
}

SelectField.defaultProps = {
  label: null,
  placeholder: null,
  isMulti: false,
  valueKey: 'value',
  labelKey: 'label',
};
