import React, { useState } from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { RadioGroup, Radio } from '@/components/radio-group';
import { appRoutes } from '@/lib/legacy-routes';

export default function WeekFilter(props) {
  const counts = oFetch(props, 'counts');
  const sDate = oFetch(props, 'sDate');
  const onChange = oFetch(props, 'onChange');
  const disabled = oFetch(props, 'disabled');

  function changeURLDate(sDate) {
    const mDate = safeMoment.uiDateParse(sDate);
    window.history.pushState('state', 'title', appRoutes.securityRotaShiftRequests({ mStartDate: mDate }));
  }

  function handleFilterChange(sDate) {
    changeURLDate(sDate);
    onChange(sDate);
  }

  function renderWeekDays() {
    const week = Object.entries(counts).map((countsEntry, index) => {
      const [weekDay, dayCounts] = countsEntry;
      const [weekDayName, count] = oFetch(dayCounts, 'name', 'count');

      return (
        <label key={weekDay} className="boss-form__switcher-label">
          <Radio value={weekDay} disabled={disabled} className="boss-form__switcher-radio" />
          <span className="boss-form__switcher-label-text">{`${weekDayName} (${count})`}</span>
        </label>
      );
    });
    return week;
  }

  return (
    <div className="boss-form__field boss-form__row_position_last">
      <RadioGroup
        name="weekFilter"
        selectedValue={sDate}
        onChange={handleFilterChange}
        className="boss-form__switcher boss-form__switcher_role_week"
      >
        {renderWeekDays()}
      </RadioGroup>
    </div>
  );
}
