import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = null;

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const financeReportWeek = oFetch(action, 'payload.financeReportWeek');
      return financeReportWeek;
    },
    [types.UPDATE_FINANCE_REPORT_WEEK]: (state, action) => {
      const financeReportWeek = oFetch(action, 'payload.financeReportWeek');
      return financeReportWeek;
    },
  },
  initialState,
);
