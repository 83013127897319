import React from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import utils from "@/lib/utils";

export function MissingVenueWagePercentageSection(props) {
  const venueWagePercentage = oFetch(props, 'venueWagePercentage');
  const sLastUpdatedAt = oFetch(venueWagePercentage, 'lastUpdatedAt');
  const pendingUpdate = oFetch(venueWagePercentage, 'pendingUpdate');

  const formattedLastUpdatedAt = sLastUpdatedAt && safeMoment.iso8601Parse(sLastUpdatedAt).format(utils.humanDateFormatWithTime());

  return (
    <div className="boss-board__header-group">
      <div className="boss-progress-bar boss-progress-bar_adjust_wrap">
        <div className="boss-progress-bar__content">
          <div className="boss-progress-bar__info boss-progress-bar__info_position_before">
            <p className="boss-progress-bar__label boss-progress-bar__label_color_accent-purple">
              Wage Percentage { pendingUpdate && " (Data Pending Update)"}
            </p>
            { sLastUpdatedAt && <p className="boss-progress-bar__meta">Last Updated at: {formattedLastUpdatedAt}</p> }
          </div>
          { !sLastUpdatedAt && <div className="boss-progress-bar__status boss-progress-bar__status_color_accent-purple">
            No Api Data Exists
          </div> }
          { sLastUpdatedAt && <div className="boss-progress-bar__status">
            No Sales Data Exists
          </div> }
        </div>
      </div>
    </div>
  );
}
