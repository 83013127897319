import React from 'react';
import { QRCode } from 'react-qr-svg';

export default function QRCodeModalContent(props: { guid: string, onPrintGuid: (args: { guid: string }) => void }) {
  const guid = props.guid;

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block">
        <QRCode value={guid} />
      </div>

      <div className="boss-image-recognition__actions">
        <button
          type="button"
          className="boss-button boss-button_role_print boss-image-recognition__button"
          onClick={() => props.onPrintGuid({ guid }) }
        >
          Print QR Code
        </button>
      </div>
    </div>
  );
}
