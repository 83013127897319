import oFetch from 'o-fetch';
import React from 'react';
import PaymentsDetailsModalItem from './payments-details-modal-item';

export default function PaymentsDetailsModal(props) {
  const [payments, fullName] = oFetch(props, 'payments', 'fullName');

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview">
        {payments.map((paymentData, index) => {
          return (
            <PaymentsDetailsModalItem key={index} paymentData={paymentData} fullName={fullName} />
          );
        })}
      </div>
    </div>
  );
}
