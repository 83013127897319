import React from 'react';

import BossSelect from '@/components/boss-select';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';
import AsyncButton from 'react-async-button';
import oFetch from 'o-fetch';

function BooleanStringOrNull(value) {
  if (value === null) {
    return null;
  }
  if (!value) {
    return false;
  }
  return String(value.valueOf()).toLowerCase() === true.toString();
}

function SubmissionsFilter(props) {
  const [onCretedByChange, onStatusChange, onSearch] = oFetch(
    props,
    'onCretedByChange',
    'onStatusChange',
    'onSearch',
  );
  function handleCretedByChange(createdBy) {
    onCretedByChange(createdBy && createdBy.value);
  }

  function handleStatusChange(status) {
    onStatusChange(status && status.value);
  }

  function handleSearchValueChange(e) {
    onSearchValueChange(e.target.value);
  }

  const [
    isFilterOpen,
    creators,
    startDate,
    endDate,
    onToggleFilter,
    onDatesChange,
    onSearchValueChange,
    searchValue,
    createdBy,
    status,
  ] = oFetch(
    props,
    'isFilterOpen',
    'creators',
    'startDate',
    'endDate',
    'onToggleFilter',
    'onDatesChange',
    'onSearchValueChange',
    'searchValue',
    'createdBy',
    'status',
  );
  const parsedStatus = BooleanStringOrNull(status);
  const createdByOptions = creators.toJS().map(creator => {
    return {
      id: oFetch(creator, 'id'),
      combined_id: `${oFetch(creator, 'id')}_${oFetch(creator, 'class_name')}`,
      full_name: oFetch(creator, 'full_name'),
    };
  });

  const statusOptions = [
    { value: true, label: 'Ok' },
    { value: false, label: 'Problem' },
  ];

  const selectedCreatedBy = createdByOptions.find(item => oFetch(item, 'combined_id') === createdBy);
  const selectedStatus = statusOptions.find(item => item.value === parsedStatus);
  const filterCn = isFilterOpen ? 'boss-dropdown__content_state_opened' : '';
  const filterButtonCn = isFilterOpen ? 'boss-dropdown__switch_state_opened' : '';

  return (
    <div className={`boss-page-dashboard__filter ${filterCn}`}>
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <div className="boss-dropdown__header-group" />
          <button
            onClick={onToggleFilter}
            className={`boss-dropdown__switch boss-dropdown__switch_role_filter ${filterButtonCn}`}
          >
            Filter
          </button>
        </div>
        <div className="boss-dropdown__content" style={{ display: isFilterOpen ? 'block' : 'none' }}>
          <div className="boss-dropdown__content-inner">
            <div className="boss-form">
              <div className="boss-form__row">
                <div className="boss-form__field boss-form__field_layout_third">
                  <label className="boss-form__label">
                    <span className="boss-form__label-text">Date</span>
                  </label>
                  <div className="date-control date-control_type_icon date-control_type_interval-fluid">
                    <BossDateRangePicker
                      startDateId="startDate"
                      endDateId="endDate"
                      startDate={startDate}
                      endDate={endDate}
                      onApply={onDatesChange}
                    />
                  </div>
                </div>
                <div className="boss-form__field boss-form__field_layout_third">
                  <label className="boss-form__label">
                    <span className="boss-form__label-text">Created By</span>
                  </label>
                  <BossSelect
                    options={createdByOptions}
                    selected={selectedCreatedBy}
                    onChange={handleCretedByChange}
                    label="full_name"
                    value="combined_id"
                    mappedProps={{
                      placeholder: 'Select created by ...',
                    }}
                  />
                </div>
                <div className="boss-form__field boss-form__field_layout_third">
                  <label className="boss-form__label">
                    <span className="boss-form__label-text">Status</span>
                  </label>
                  <BossSelect
                    options={statusOptions}
                    onChange={handleStatusChange}
                    selected={selectedStatus}
                    mappedProps={{
                      placeholder: 'Select status ...',
                    }}
                  />
                </div>
              </div>
              <div className="boss-form__field">
                <label className="boss-form__label">
                  <span className="boss-form__label-text">Search</span>
                  <input
                    name="search"
                    defaultValue={searchValue}
                    onChange={handleSearchValueChange}
                    type="text"
                    className="boss-form__input"
                  />
                </label>
              </div>
              <div className="boss-form__field">
                <AsyncButton
                  className="boss-button boss-form__submit boss-form__submit_adjust_single"
                  text="Update"
                  pendingText="Updating..."
                  onClick={onSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmissionsFilter;
