const URL = window.URL || window.webkitURL;

export function canvasToBlob(canvas: HTMLCanvasElement, type?: string, quality?: number): Promise<Blob | null> {
  return new Promise(resolve => canvas.toBlob(resolve, type || 'image/png', quality));
}

export function getAsDataURL(file: File | Blob | null): Promise<string | ArrayBuffer | null> {
  if (file === null) {
    return Promise.resolve(null);
  }
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onloadend = () => {
      if (reader.error) {
        resolve(null);
      } else {
        resolve(reader.result);
      }
    };

    reader.readAsDataURL(file);
  });
}

export function resizeToLimit(file: File | Blob | null, limit: number, resolution: number): Promise<File | Blob | null> {
  if (file === null) {
    return Promise.resolve(null);
  }
  const url = URL.createObjectURL(file);
  const image = new Image();

  return new Promise(resolve => {
    image.onload = () => {
      image.onload = null;
      URL.revokeObjectURL(url);

      tryResizeToLimit({
        image,
        limit: limit,
        type: file.type || 'image/jpeg',
        resolution: resolution,
        callback: (blob: Blob) => {
          let newFile;
          const fileName = (file as File).name || 'image.jpeg';
          try {
            newFile = new File([blob], fileName, {
              type: file.type || 'image/jpeg',
              lastModified: (file as File).lastModified,
            });
          } catch (e) {
            newFile = blob;
          }

          resolve(newFile);
        },
      });
    };

    image.src = url;
  });
}

export function resizeToResolution(file: File, resolution: number) {
  return resizeToLimit(file, file.size, resolution);
}

export function tryResizeToLimit({ image, type, resolution, limit, callback }: any) {
  const canvas = document.createElement('canvas');

  let width;
  let height;

  const { naturalWidth, naturalHeight } = image;

  if (naturalWidth > naturalHeight) {
    if (naturalWidth <= resolution) {
      width = naturalWidth;
      height = naturalHeight;
    } else {
      const scaleDownFactor = naturalWidth / resolution;

      width = resolution;
      height = naturalHeight / scaleDownFactor;
    }
  } else if (naturalHeight > naturalWidth) {
    if (naturalHeight <= resolution) {
      width = naturalWidth;
      height = naturalHeight;
    } else {
      const scaleDownFactor = naturalHeight / resolution;

      width = naturalWidth / scaleDownFactor;
      height = resolution;
    }
  } else {
    if (naturalWidth <= resolution) {
      width = naturalWidth;
      height = naturalHeight;
    } else {
      width = resolution;
      height = resolution;
    }
  }

  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');

  if (context === null) {
    return;
  }

  context.drawImage(image, 0, 0, width, height);
  canvas.toBlob(
    blob => {
      if (blob === null) {
        return;
      }
      if (blob.size > limit) {
        setTimeout(
          () =>
            tryResizeToLimit({
              image,
              type,
              limit,
              resolution: resolution * 0.9,
              callback: callback,
            }),
          1,
        );

        return;
      }

      callback(blob);
    },
    type,
    1,
  );
}
