import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { handleUnexpectedFailure } from '@/lib/handle-unexpected-failure';

const defaultConfig = {
  NAME_ATTR: 'data-react-class',
  PROPS_ATTR: 'data-react-props',
  render: (component, props) => (
    <ErrorBoundary fallbackRender={() => null} onError={handleUnexpectedFailure}>
      {React.createElement(component, props)}
    </ErrorBoundary>
  ),
  strict: true,
};

export function mountComponents(components, configOverrides = {}) {
  const config = { ...defaultConfig, ...configOverrides };
  const nodes = window.document.querySelectorAll(`[${config.NAME_ATTR}]`);

  for (let i = 0; i < nodes.length; ++i) {
    const node = nodes[i];
    const componentName = node.getAttribute(config.NAME_ATTR);
    const component = components[componentName];
    const propsJson = node.getAttribute(config.PROPS_ATTR);
    const props = propsJson && JSON.parse(propsJson);
    const rootNode = createRoot(node);
    if (typeof component === 'undefined') {
      const message = "Cannot find component: '" + componentName + "'";
      if (console && console.log) {
        console.log(`%c[react-rails-ujs] %c${message} for element`, 'font-weight: bold', '', node);
      }
      if (config.strict) {
        throw new Error(`[react-rails-ujs] ${message}`);
      }
    } else {
      rootNode.render(config.render(component, props));
    }
  }
}
