import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { DuplicateStaffMember } from './duplicate-staff-member';

export function DuplicatesModal(props) {
  const [onSubmit, staffMembers] = oFetch(props, 'onSubmit', 'staffMembers');

  return (
    <Fragment>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text" style={{ marginBottom: '10px' }}>
          The SIA badge number you supplied cannot be added because it already on the following
          staff members
        </span>
        <span className="boss-modal-window__message-text">
          {staffMembers.map((staffMember, index) => {
            return <DuplicateStaffMember key={index} staffMember={staffMember} />;
          })}
        </span>
      </div>
      <div className="boss-modal-window__actions">
        <button type="button" onClick={onSubmit} className="boss-button boss-modal-window__button">
          Continue
        </button>
      </div>
    </Fragment>
  );
}
