import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError, reset } from 'redux-form';
import groupBy from 'lodash/groupBy';
import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';
import * as types from './types';
import * as selectors from './selectors';

const formNamesLabels = {
  fiftyNotesTotalPounds: '£50',
  foreignFiftyNotesTotalPounds: 'Foreign £50',
  twentyNotesTotalPounds: '£20',
  foreignTwentyNotesTotalPounds: 'Foreign £20',
  tenNotesTotalPounds: '£10',
  foreignTenNotesTotalPounds: 'Foreign £10',
  fiveNotesTotalPounds: '£5',
  foreignFiveNotesTotalPounds: 'Foreign £5',
};

export const loadInitialState = createAction(types.LOAD_INITIAL_STATE);
export const setFilter = createAction(types.SET_FILTER);
export const toggleEditMode = createAction(types.TOGGLE_EDIT_MODE);
export const editModeOff = createAction(types.EDIT_MODE_OFF);
export const toggleHistory = createAction(types.TOGGLE_HISTORY);
export const setTabStatus = createAction(types.SET_TAB_STATUS);
export const addDayCount = createAction(types.ADD_DAY_COUNT);
export const updateDayCountInStore = createAction(types.UPDATE_DAY_COUNT);
export const updateWeekCountInStore = createAction(types.UPDATE_WEEK_COUNT);

export const setInitialActiveTab = () => (dispatch, getState) => {
  const [weekStart, weekEnd, weekDaysCounts] = oFetch(
    selectors.getDataForInitialTab(getState()),
    'weekStart',
    'weekEnd',
    'weekDaysCounts',
  );
  const weekRange = selectors.getWeekRange({ weekStart, weekEnd });
  const weekRangeUIDate = weekRange.map(day => day.format(utils.commonDateFormat));
  const groupedByDateCounts = groupBy(weekDaysCounts, wc => oFetch(wc, 'date'));
  const selectedTab = weekRangeUIDate.reduce((acc, item) => {
    const dayCount = groupedByDateCounts[item];
    if (dayCount === undefined && acc === 'all') {
      return item;
    }
    return acc;
  }, 'all');
  dispatch(setFilter({ selectedTab }));
};

export const createDayCount = values => (dispatch, getState) => {
  const currentVenueId = selectors.currentVenueIdSelector(getState());

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      if (statusCode === 422) {
        const normalizedBaseErrors = Object.entries(formNamesLabels).reduce((acc, entry) => {
          const [key, label] = entry;
          const keyErrors = errors[key];
          if (keyErrors === undefined) {
            return acc;
          }
          const labeledErrors = keyErrors.reduce((acc, error) => {
            return [...acc, `${label}: ${error}`];
          }, []);
          return [...acc, ...labeledErrors];
        }, []);
        const baseErrors = errors._error || {};
        const normalizedErrors = { ...errors, _error: [...baseErrors, ...normalizedBaseErrors] };
        globalNotifications.showDefaultFailureMessage({ type: 'purple' });
        throw new SubmissionError(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addDayCount(data));
      dispatch(setInitialActiveTab());
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
    },
  }).post(`/api/v1/purple/counting`, {
    ...values,
    venueId: currentVenueId,
  });
};

export const updateDayCount = values => (dispatch, getState) => {
  const date = oFetch(values, 'date');
  const currentVenueId = selectors.currentVenueIdSelector(getState());

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      if (statusCode === 422) {
        const normalizedBaseErrors = Object.entries(formNamesLabels).reduce((acc, entry) => {
          const [key, label] = entry;
          const keyErrors = errors[key];
          if (keyErrors === undefined) {
            return acc;
          }
          const labeledErrors = keyErrors.reduce((acc, error) => {
            return [...acc, `${label}: ${error}`];
          }, []);
          return [...acc, ...labeledErrors];
        }, []);
        const baseErrors = errors._error || {};
        const normalizedErrors = { ...errors, _error: [...baseErrors, ...normalizedBaseErrors] };
        globalNotifications.showDefaultFailureMessage({ type: 'purple' });
        throw new SubmissionError(normalizedErrors);
      }
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateDayCountInStore(data));
      dispatch(setInitialActiveTab());
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
    },
  }).put(`/api/v1/purple/counting/${date}`, {
    ...values,
    venueId: currentVenueId,
  });
};

export const createWeekCount = values => (dispatch, getState) => {
  const currentVenueId = selectors.currentVenueIdSelector(getState());

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');

      globalNotifications.showDefaultFailureMessage({ type: 'purple' });
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateWeekCountInStore(data));
      dispatch(setInitialActiveTab());
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
    },
  }).post(`/api/v1/purple/counting/create_all`, {
    ...values,
    venueId: currentVenueId,
  });
};

export const updateWeekCount = values => (dispatch, getState) => {
  const weekStart = oFetch(values, 'weekStart');
  const currentVenueId = selectors.currentVenueIdSelector(getState());

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');

      globalNotifications.showDefaultFailureMessage({ type: 'purple' });
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateWeekCountInStore(data));
      dispatch(setInitialActiveTab());
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
    },
  }).put(`/api/v1/purple/counting/${weekStart}/update_all`, {
    ...values,
    venueId: currentVenueId,
  });
};
