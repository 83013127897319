import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat, singleValueChanged } from './utils';
import { Attachments } from './attachments';
import { TextValueChanges } from './text-value-changes';
import { SingleTextValueChanges } from './single-text-value-changes';
import { AttachmentChangesDetailsButton } from './attachment-changes-details-button';

export function UpdateQuestionEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, oldQuestion, oldAttachments, newQuestion, newAttachments, at] = oFetch(
    event,
    'userFullName',
    'oldQuestion',
    'oldAttachments',
    'newQuestion',
    'newAttachments',
    'at',
  );

  const formattedAt = useMemo(() => atFormat(at), [at]);

  const singleChanges = singleValueChanged(['newQuestion:oldQuestion:Question'], event);
  const newAttachmentsExist = newAttachments.length > 0;

  if (singleChanges !== false && !newAttachmentsExist) {
    return (
      <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
        <SingleTextValueChanges
          name={oFetch(singleChanges, 'label')}
          oldValue={oFetch(singleChanges, 'oldValue')}
          newValue={oFetch(singleChanges, 'newValue')}
          postfix={` by ${userFullName} @ ${formattedAt}`}
          isHtml={false}
        />
      </li>
    );
  }

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
      <p className="boss-overview__text">
        Updated by {userFullName} @ {formattedAt}
      </p>
      {newQuestion && <TextValueChanges name="Question" oldValue={oldQuestion} newValue={newQuestion} />}
      {newAttachmentsExist && <AttachmentChangesDetailsButton oldAttachments={oldAttachments} newAttachments={newAttachments} />}
    </li>
  );
}
