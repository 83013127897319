import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { NoteCollapsible } from './note-collapsible';

export function TemporaryItem(props) {
  const item = oFetch(props, 'item');
  const [startTime, endTime, isTemporaryAi, isTemporaryUser, note, createdAt] = oFetch(
    item,
    'startTime',
    'endTime',
    'isTemporaryAi',
    'isTemporaryUser',
    'note',
    'createdAt',
  );

  function getTimeFormat() {
    if (isTemporaryAi) {
      return utils.commonDateFormatWithTime();
    }
    if (isTemporaryUser) {
      return utils.uiRotaDateFormat;
    }
    throw new Error('Incorrect timed type');
  }

  const formattedStartTime = safeMoment.iso8601Parse(startTime).format(getTimeFormat());
  const formattedEndTime = safeMoment.iso8601Parse(endTime).format(getTimeFormat());

  const indicatorClassNames = cn('boss-indicator__icon', {
    'boss-indicator__icon_ai-chip': isTemporaryAi,
    'boss-indicator__icon_user-solid': isTemporaryUser,
  });

  return (
    <div className="boss-table__row">
      <div className="boss-table__column">
        <div className="boss-table__cell">
          <p className="boss-table__text">
            <span className="boss-indicator boss-table__indicator">
              <span className={indicatorClassNames} />
            </span>
          </p>
        </div>
      </div>
      <div className="boss-table__column">
        <div className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Start Time</p>
              <p className="boss-table__text">{formattedStartTime}</p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">End Time</p>
              <p className="boss-table__text">{formattedEndTime}</p>
            </div>
          </div>
        </div>
        <NoteCollapsible note={note} createdAt={createdAt} />
      </div>
    </div>
  );
}
