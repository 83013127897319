import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export function AttendanceModal(props) {
  const items = oFetch(props, 'items');

  return (
    <div className="boss-users">
      <div className="boss-users__flow boss-users__flow_type_no-space">
        <div className="boss-users__flow-list">
          {items.map((item, index) => {
            const [
              staffMemberId,
              fullName,
              avatarUrl,
              shifts,
              isClockedIn,
              isOnBreak,
              eventAt,
            ] = oFetch(
              item,
              'staffMemberId',
              'fullName',
              'avatarUrl',
              'shifts',
              'isClockedIn',
              'isOnBreak',
              'eventAt',
            );
            return (
              <div
                key={staffMemberId}
                className="boss-users__flow-item boss-users__flow-item_size_half"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/staff_members/${staffMemberId}`}
                  className="boss-user-summary boss-user-summary_role_venue-dashboard-attendance boss-user-summary_role_link"
                >
                  <div className="boss-user-summary__side">
                    <div className="boss-user-summary__avatar">
                      <div className="boss-user-summary__avatar-inner">
                        <img src={avatarUrl} alt={fullName} className="boss-user-summary__pic" />
                      </div>
                    </div>
                  </div>
                  <div className="boss-user-summary__content">
                    <div className="boss-user-summary__header">
                      <h2 className="boss-user-summary__name">{fullName}</h2>
                    </div>
                    <ul className="boss-user-summary__review-list">
                      {shifts && (
                        <li className="boss-user-summary__review-item">
                          <span className="boss-user-summary__review-label">
                            Rotaed Shift Time:{' '}
                          </span>
                          <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                            {shifts
                              .map(shift => {
                                const [startsAt, endsAt] = oFetch(shift, 'startsAt', 'endsAt');
                                const formattedStartsAt = safeMoment
                                  .iso8601Parse(startsAt)
                                  .format(utils.commonDateFormatTimeOnly());
                                const formattedEndsAt = safeMoment
                                  .iso8601Parse(endsAt)
                                  .format(utils.commonDateFormatTimeOnly());
                                return `${formattedStartsAt} - ${formattedEndsAt}`;
                              })
                              .join(', ')}
                          </span>
                        </li>
                      )}
                      {isClockedIn && (
                        <li className="boss-user-summary__review-item">
                          <span className="boss-user-summary__review-label">Clocked In at: </span>
                          <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                            {safeMoment
                              .iso8601Parse(eventAt)
                              .format(utils.commonDateFormatTimeOnly())}
                          </span>
                        </li>
                      )}
                      {isOnBreak && (
                        <li className="boss-user-summary__review-item">
                          <span className="boss-user-summary__review-label">On Break at: </span>
                          <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                            {safeMoment
                              .iso8601Parse(eventAt)
                              .format(utils.commonDateFormatTimeOnly())}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
