import { fromJS } from 'immutable';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { reducer as formReducer } from 'redux-form';
import oFetch from 'o-fetch';
import * as constants from './constants';

const initialState = {
  currentVenue: null,
  accessories: [],
  accessoryRequests: [],
  accessoryRefundRequests: [],
  staffMembers: [],
  venues: [],
  pagination: {
    pageNumber: 1,
  },
  permissionsData: {
    accessoryRequests: null,
    accessoryRefundRequests: null,
  },
};

const accessoryRequestsReducer = handleActions(
  {
    [constants.LOAD_INITIAL_STATE]: (state, action) => {
      const [currentVenue, venues] = oFetch(action.payload, 'currentVenue', 'venues');
      return {
        ...state,
        currentVenue,
        venues,
      };
    },
    [constants.SET_VENUE]: (state, action) => {
      const venueId = action.payload;
      return {
        ...state,
        currentVenue: venueId,
      };
    },
    [constants.UPDATE_REQUEST]: (state, action) => {
      const newAccessoryRequest = action.payload;
      return {
        ...state,
        accessoryRequests: state.accessoryRequests.map(accessoryRequest => {
          if (oFetch(accessoryRequest, 'id') === oFetch(newAccessoryRequest, 'id')) {
            return newAccessoryRequest;
          }
          return accessoryRequest;
        }),
      };
    },
    [constants.REMOVE_REQUEST]: (state, action) => {
      const request = oFetch(action.payload, 'accessoryRequest');
      const newAccessory = oFetch(action.payload, 'accessory');
      return {
        ...state,
        accessoryRequests: state.accessoryRequests.filter(accessoryRequest => {
          return oFetch(accessoryRequest, 'id') === oFetch(request, 'id');
        }),
        accessories: state.accessories.map(accessory => {
          if (oFetch(accessory, 'id') === oFetch(newAccessory, 'id')) {
            return newAccessory;
          }
          return accessory;
        }),
      };
    },
    [constants.UPDATE_REFUND_REQUEST]: (state, action) => {
      const newRefundRequest = action.payload;
      return {
        ...state,
        accessoryRefundRequests: state.accessoryRefundRequests.map(accessoryRefuncRequest => {
          if (oFetch(accessoryRefuncRequest, 'id') === oFetch(newRefundRequest, 'id')) {
            return newRefundRequest;
          }
          return accessoryRefuncRequest;
        }),
      };
    },
    [constants.REMOVE_REFUND_REQUEST]: (state, action) => {
      const request = oFetch(action.payload, 'accessoryRefundRequest');
      const newAccessory = oFetch(action.payload, 'accessory');

      return {
        ...state,
        accessoryRefundRequests: state.accessoryRefundRequests.filter(accessoryRefuncRequest => {
          return oFetch(accessoryRefuncRequest, 'id') === oFetch(request, 'id');
        }),
        accessories: state.accessories.map(accessory => {
          if (oFetch(accessory, 'id') === oFetch(newAccessory, 'id')) {
            return newAccessory;
          }
          return accessory;
        }),
      };
    },
    [constants.REMOVE_ACCESSORY]: (state, action) => {
      const accessoryId = oFetch(action, 'payload');
      return {
        ...state,
        accessories: state.accessories.filter(accessory => {
          return oFetch(accessory, 'id') === accessoryId;
        }),
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount - 1,
        },
      };
    },
    [constants.LOAD_INITIAL_ACCESSORY_REQUESTS]: (state, action) => {
      const [
        pagination,
        accessories,
        accessoryRequests,
        accessoryRefundRequests,
        staffMembers,
        permissionsData,
      ] = oFetch(
        action.payload,
        'pagination',
        'accessories',
        'accessoryRequests',
        'accessoryRefundRequests',
        'staffMembers',
        'permissionsData',
      );

      return {
        ...state,
        pagination,
        accessories,
        accessoryRequests,
        accessoryRefundRequests,
        staffMembers,
        permissionsData,
      };
    },
    [constants.DROP_PAGE_NUMBER]: (state, action) => {
      return {
        ...state,
        accessories: [],
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
      };
    },
    [constants.LOAD_MORE]: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: parseInt(state.pagination.pageNumber) + 1,
        },
      };
    },
  },
  initialState,
);

export default combineReducers({
  accessoryRequestsPage: accessoryRequestsReducer,
  form: formReducer,
});
