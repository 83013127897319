import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import BossInput from '@/components/boss-form/boss-form-input';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

function normalizeFormFields(values, initialValues) {
  return Object.keys(initialValues).reduce((acc, key) => {
    return {
      ...acc,
      [key]: values[key] ? values[key] : null,
    };
  }, {});
}

export function UpdateBankDetailsForm(props) {
  const [initialValues, onSubmit, bankDetailsLastUpdatedAt, bankDetailsLastUpdatedBy] = oFetch(
    props,
    'initialValues',
    'onSubmit',
    'bankDetailsLastUpdatedAt',
    'bankDetailsLastUpdatedBy',
  );

  const hasLastUpdated = bankDetailsLastUpdatedAt && bankDetailsLastUpdatedBy;

  function renderLastUpdated() {
    const formattedLastUpdatedAt = safeMoment
      .iso8601Parse(bankDetailsLastUpdatedAt)
      .format(utils.commonDateFormatWithTime());
    return (
      <div className="boss-form__field">
        <p className="boss-form__text">
          Last updated at: <span className="boss-form__text-primary">{formattedLastUpdatedAt}</span> by{' '}
          <span className="boss-form__text-primary">{bankDetailsLastUpdatedBy}</span>
        </p>
      </div>
    );
  }

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={values => onSubmit(normalizeFormFields(values, initialValues))}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field
                  parse={value => value}
                  name="bankAccountNumber"
                  label="Account Number"
                  component={BossInput}
                />
                <Field parse={value => value} name="bankSortCode" label="Sort Code" component={BossInput} />
                <Field
                  parse={value => value}
                  name="altBankingFullname"
                  label="Name as on Bank Account (optional)"
                  component={BossInput}
                />
                {hasLastUpdated && renderLastUpdated()}
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="boss-button boss-form__submit"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
