import oFetch from 'o-fetch';
import React, { Fragment, useState, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import useOnClickOutside from 'use-onclickoutside';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

import { BonusFactorFormField } from './bonus-factor-form-field';

export function BonusFactorForm(props) {
  const [bonaiCentsData, onSubmit, isFutureWeek] = oFetch(
    props,
    'bonaiCentsData',
    'onSubmit',
    'isFutureWeek',
  );
  const [isEdit, setIsEdit] = useState(false);
  const [bonaiCents, bonaiCentsLastUpdatedAt] = oFetch(bonaiCentsData, 'bonaiCents', 'lastUpdatedAt');

  const ref = useRef(null);
  useOnClickOutside(ref, cancelToEdit);

  function switchToEdit() {
    setIsEdit(true);
  }

  function cancelToEdit() {
    setIsEdit(false);
  }

  function formatBonaiCents(bonaiCents) {
    if (bonaiCents === null) {
      return 'N/A';
    } else {
      return utils.moneyFormat(bonaiCents / 100);
    }
  }

  function formatBonaiLastUpdatedAt(bonaiCentsLastUpdatedAt) {
    return safeMoment.iso8601Parse(bonaiCentsLastUpdatedAt).format(utils.commonDateFormat);
  }

  return (
    <Form
      onSubmit={values => onSubmit({ values, hideEditField: cancelToEdit })}
      initialValues={{ bonaiCents: bonaiCents }}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <div className="boss-table__form">
            <div className="boss-form">
              {!isEdit && (
                <div className="boss-table__info-group">
                  <p className="boss-table__text">
                    {formatBonaiCents(bonaiCents)}
                    {!isFutureWeek && (
                      <button
                        onClick={switchToEdit}
                        className="boss-action boss-action_role_edit boss-table__text-action"
                      />
                    )}
                  </p>
                  {bonaiCentsLastUpdatedAt && (
                    <p className="boss-table__text">
                      <span className="boss-table__text-meta">
                        Last Updated at: {formatBonaiLastUpdatedAt(bonaiCentsLastUpdatedAt)}
                      </span>
                    </p>
                  )}
                </div>
              )}
              {isEdit && !isFutureWeek && (
                <div ref={ref} className="boss-form__control" style={{ backgroundColor: 'inherit' }}>
                  <Field name={'bonaiCents'} parse={value => value} component={BonusFactorFormField} />
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="boss-action boss-action_role_confirm boss-form__action"
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}
