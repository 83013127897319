import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import oFetch from 'o-fetch';
import configureStore from '../store';
import { initialLoad } from './actions';
import { initialProfileLoad } from '../profile-wrapper/actions';
import profileReducer from '../profile-wrapper/reducers';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import OwedHours from './containers/owed-hours';
import owedHoursReducers from './reducers';
import { BOSS_STAFF_MEMBER_TYPE } from '../holidays-page/constants';

export function StaffMemberOwedHoursApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    window.boss.accessToken = accessToken;
    const store = configureStore(
      combineReducers({
        profile: profileReducer,
        owedHours: owedHoursReducers,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }
  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <OwedHours
            staffMemberType={BOSS_STAFF_MEMBER_TYPE}
            lastMossHourTagId={null}
            setLastMossHourTagId={() => {}}
          />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
