import { useState } from 'react';
import oFetch from 'o-fetch';

export function useCarousel(params) {
  const [list, initialIndex] = oFetch(params, 'list', 'initialIndex');

  if (initialIndex < 0 || (list.length !== 0 && initialIndex > list.length - 1)) {
    throw new Error(`InitialIndex can't be less than 0 and greater than list length`);
  }
  const [currentMessageIndex, setCurrentMessageIndex] = useState(initialIndex);

  const currentItem = list[currentMessageIndex];

  function isLast() {
    return currentMessageIndex === list.length - 1;
  }

  function isFirst() {
    return currentMessageIndex === 0;
  }

  function handlePreviousClick() {
    if (!isLast()) {
      setCurrentMessageIndex(currentMessageIndex + 1);
    }
  }

  function handleNextClick() {
    if (!isFirst()) {
      setCurrentMessageIndex(currentMessageIndex - 1);
    }
  }

  return {
    currentItem: currentItem,
    pagination: { current: currentMessageIndex + 1, size: list.length },
    onNextClick: handleNextClick,
    onPreviousClick: handlePreviousClick,
    isLast: isLast,
    isFirst: isFirst,
  };
}
