import React, { useEffect } from 'react';
import { RekognitionTest } from './rekognition-test';

export function RekognitionTestApp(props) {
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return <RekognitionTest {...props} />;
}
