import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export function RequestFailedModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');

  return (
    <Fragment>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          There was an issue checking your SIA badge number. Please fill in the values manually and
          the check will be performed at a later date.
        </span>
      </div>
      <div className="boss-modal-window__actions">
        <button type="button" onClick={onSubmit} className="boss-button boss-modal-window__button">
          Continue
        </button>
      </div>
    </Fragment>
  );
}
