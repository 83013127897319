import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const rotaShifts = oFetch(action, 'payload.rotaShifts');
      return rotaShifts;
    },
  },
  initialState,
);
