import React from 'react';
import oFetch from 'o-fetch';
import { reduxForm, FieldArray, Fields } from 'redux-form';
import cn from 'classnames';
import { openCustomContentModal } from '@/components/modals';
import QRCodeModalContent from './qrcode-modal-content';

import ChecklistItem from './checklist-item';
import { SubmitCheckboxField } from './form-fields';

function renderItems(params) {
  const [fields, meta] = oFetch(params, 'fields', 'meta');

  return (
    <div className="boss-checklist">
      {fields.map((field, index) => {
        return (
          <Fields
            key={index}
            formName={meta.form}
            index={index}
            names={[`${field}.description`, `${field}.note`, `${field}.answer`]}
            component={SubmitCheckboxField}
          />
        );
      })}
    </div>
  );
}

function SubmitChecklistItem(props) {
  const [checklist, handleSubmit, submitting, onPrintGuid, submitChecklist] = oFetch(
    props,
    'checklist',
    'handleSubmit',
    'submitting',
    'onPrintGuid',
    'submitChecklist',
  );
  const { error } = props;
  const titleClassNames = cn('boss-board__title', { 'boss-board__title_status_failed': error });

  function openViewAssignmentsLink() {
    const viewAssignmentsUrl = oFetch(checklist, 'viewAssignmentsUrl');
    window.location = viewAssignmentsUrl;
  }

  function openQRCodeModal() {
    const guid = oFetch(checklist, 'guid');
    const checklistId = oFetch(checklist, 'id');
    const checklistName = oFetch(checklist, 'name');

    openCustomContentModal({
      submit: () => {},
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_action',
        title: 'QR Code',
        innerClose: false,
      },
      props: {
        guid,
        checklistId,
        checklistName,
        onPrintGuid,
      },
    })(QRCodeModalContent);
  }

  function renderActions() {
    return (
      <div className="boss-board__button-group boss-board__button-group_role_actions">
        <button
          onClick={openQRCodeModal}
          type="button"
          className="boss-button boss-button_type_small boss-button_role_view-qrcode boss-board__action"
        >
          QR Code
        </button>
        <button
          onClick={openViewAssignmentsLink}
          type="button"
          className="boss-button boss-button_type_small boss-button_role_view-assignments boss-board__action"
        >
          Assignments
        </button>
      </div>
    );
  }

  function renderTitle(checklist) {
    const checklistName = oFetch(checklist, 'name');
    return <h2 className={titleClassNames}>{checklistName}</h2>;
  }

  function renderBaseErrors() {
    return (
      <div className="boss-checklist__alert">
        <div className="boss-alert boss-alert_role_info">
          <p className="boss-alert__text">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <ChecklistItem checklist={checklist} titleRenderer={renderTitle} actionRenderer={renderActions}>
      {toggleCollapse => {
        return (
          <div>
            {error && renderBaseErrors()}
            <FieldArray name="items" component={renderItems} />
            <div className="boss-checklist__actions">
              <button
                onClick={handleSubmit((values, dispatch, props) =>
                  submitChecklist(values, dispatch, props, toggleCollapse),
                )}
                disabled={submitting}
                className="boss-button boss-button_role_primary boss-checklist__action"
                type="button"
              >
                {submitting ? 'Submitting ...' : 'Submit'}
              </button>
            </div>
          </div>
        );
      }}
    </ChecklistItem>
  );
}

export default reduxForm()(SubmitChecklistItem);
