import React from 'react';
import oFetch from 'o-fetch';
import pluralize from 'pluralize';

export default function ReviewNotification(props) {
  const count = oFetch(props, 'count');

  return (
    <div className="boss-page-main__group">
      <div className="boss-vetting__message">
        <div className="boss-alert">
          <p className="boss-alert__text">
            <span className="boss-alert__text-value">{count}</span> unreviewed{' '}
            {pluralize('staff member', count)}
          </p>
        </div>
      </div>
    </div>
  );
}
