import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { FOR_STAFF_MEMBERS_FILTER_SHOW_ALL, FOR_STAFF_MEMBERS_FILTER_STEWARD_ONLY } from '../security-pay-rates-hook';
import { RadioGroup, Radio } from '@/components/radio-group';

export function ForStaffMembersStaffTypeFilter(props) {
  const [selected, showAllPayRateCount, forStewardsPayRateCount, onChange] = oFetch(
    props,
    'selected',
    'showAllPayRateCount',
    'forStewardsPayRateCount',
    'onChange',
  );
  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <div className="boss-form__row boss-form__row_justify_end">
          <div className="boss-form__field boss-form__field_layout_min">
            <RadioGroup
              name={'type'}
              selectedValue={selected}
              onChange={onChange}
              className="boss-form__switcher"
            >
              <label style={{ cursor: 'pointer' }} className={'boss-form__switcher-label'}>
                <Radio value={FOR_STAFF_MEMBERS_FILTER_SHOW_ALL} className="boss-form__switcher-radio" />
                <span className="boss-form__switcher-label-text">All ({showAllPayRateCount})</span>
              </label>
              <label style={{ cursor: 'pointer' }} className={'boss-form__switcher-label'}>
                <Radio value={FOR_STAFF_MEMBERS_FILTER_STEWARD_ONLY} className="boss-form__switcher-radio" />
                <span className="boss-form__switcher-label-text">Steward Only ({forStewardsPayRateCount})</span>
              </label>
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
