export const CREATE_TASK_EVENT_TYPE = 'create_task';
export const DELETE_TASK_EVENT_TYPE = 'delete_task';
export const UPDATE_TASK_EVENT_TYPE = 'update_task';
export const GRAB_TASK_EVENT_TYPE = 'grab_task';
export const REASSIGN_TASK_EVENT_TYPE = 'reassign_task';
export const COMPLETE_TASK_EVENT_TYPE = 'complete_task';
export const CREATE_QUESTION_EVENT_TYPE = 'create_question';
export const DELETE_REQUEST_EVENT_TYPE = 'delete_task_request';
export const UPDATE_QUESTION_EVENT_TYPE = 'update_question';
export const CREATE_ANSWER_EVENT_TYPE = 'create_answer';
export const UPDATE_ANSWER_EVENT_TYPE = 'update_answer';
export const REQUESTED_CLARIFICATION_EVENT_TYPE = 'requested_clarification';
export const UPDATE_CLARIFICATION_REQUEST_EVENT_TYPE = 'update_clarification_request';
export const ACCEPT_ANSWER_EVENT_TYPE = 'accept_answer';
export const REJECT_TASK_EVENT_TYPE = 'reject_task';
export const ACCEPT_TASK_EVENT_TYPE = 'accept_task';
