import { FORM_ERROR } from 'final-form';
import { z } from 'zod';

export const EMPTY_JOB_TITLE_VALUE = '' as const;
export const CLEANER_JOB_TITLE_VALUE = 'cleaner' as const;
export const MAINTENANCE_JOB_TITLE_VALUE = 'maintenance' as const;
export const DJ_JOB_TITLE_VALUE = 'dj' as const;
export const MUSICIAN_JOB_TITLE_VALUE = 'musician' as const;
export const SOUND_ENGINEER_JOB_TITLE_VALUE = 'sound_engineer' as const;
export const OTHER_JOB_TITLE_VALUE = 'other' as const;
export const NOT_OTHER_JOB_TITLE_VALUES = [
  EMPTY_JOB_TITLE_VALUE,
  CLEANER_JOB_TITLE_VALUE,
  MAINTENANCE_JOB_TITLE_VALUE,
  DJ_JOB_TITLE_VALUE,
  MUSICIAN_JOB_TITLE_VALUE,
  SOUND_ENGINEER_JOB_TITLE_VALUE,
] as const;

const $NotOtherJobTitleValue = z.union([
  z.literal(EMPTY_JOB_TITLE_VALUE),
  z.literal(CLEANER_JOB_TITLE_VALUE),
  z.literal(MAINTENANCE_JOB_TITLE_VALUE),
  z.literal(DJ_JOB_TITLE_VALUE),
  z.literal(MUSICIAN_JOB_TITLE_VALUE),
  z.literal(SOUND_ENGINEER_JOB_TITLE_VALUE),
]);
const $OtherJobTitleValue = z.literal(OTHER_JOB_TITLE_VALUE);
const $JobTitleValue = z.union([$OtherJobTitleValue, $NotOtherJobTitleValue]);
export type JobTitleValue = z.infer<typeof $JobTitleValue>;

const $BaseFormValues = z.object({
  firstName: z.string(),
  surname: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  jobTitle: $JobTitleValue,
  avatarUrl: z.string(),
  otherJobTitle: z.string(),
  staffPartyQuizTeamId: z.number().int().positive(),
}).strict();

export const $FormValues = $BaseFormValues.extend({
  jobTitle: $OtherJobTitleValue,
  otherJobTitle: z.string(),
}).strict().or($BaseFormValues.extend({
  jobTitle: $NotOtherJobTitleValue,
  otherJobTitle: z.string().length(0),
}).strict());
export type FormValues = z.infer<typeof $FormValues>;

const $FieldValidationErrors = z.string().array().optional();

export const $RequestValidationErrors = z.object({
  base: $FieldValidationErrors,
  firstName: $FieldValidationErrors,
  surname: $FieldValidationErrors,
  phoneNumber: $FieldValidationErrors,
  email: $FieldValidationErrors,
  jobTitle: $FieldValidationErrors,
  avatarUrl: $FieldValidationErrors,
  otherJobTitle: $FieldValidationErrors,
  staffPartyQuizTeamId: $FieldValidationErrors,
}).transform((obj) => {
  const { base, ...objValues } = obj;
  if (base) {
    return {
      [FORM_ERROR]: base,
      ...objValues,
    };
  } else {
    return objValues;
  }
});

export type RequestValidationErrors = typeof $RequestValidationErrors["_input"];

export type FormValidationErrors = typeof $RequestValidationErrors["_output"];

const $JobOptionNameType = z.string();
const $JobOptionValueType = z.string();

export const $JobOption = z.object({
  name: $JobOptionNameType,
  value: $JobOptionValueType,
});

export type JobOption = z.infer<typeof $JobOption>;

const $StaffPartyQuizTeamOptionNameType = z.string();
const $StaffPartyQuizTeamOptionValueType = z.number().int().positive();

export const $StaffPartyQuizTeamOption = z.object({
  name: $StaffPartyQuizTeamOptionNameType,
  value: $StaffPartyQuizTeamOptionValueType,
});

export type StaffPartyQuizTeamOption = z.infer<typeof $StaffPartyQuizTeamOption>;

export const $OnSubmitParams = z.object({
  values: $FormValues,
});

export type OnSubmitParams = z.infer<typeof $OnSubmitParams>;

export const $FinalFormSubmissionErrors = z.record(z.never());

export const $SubmitCallBack = z
  .function()
  .args($OnSubmitParams)
  .returns(
    z.promise($FinalFormSubmissionErrors.or(z.undefined()))
  );

export type FormSubmitHandler = z.infer<typeof $SubmitCallBack>;

export const $FormCallProps = z.object({
  initialValues: $FormValues,
});

export type FormCallProps = z.infer<typeof $FormCallProps>;

const $FormProps = $FormCallProps.extend({
  onSubmit: $SubmitCallBack,
});

export type FormProps = z.infer<typeof $FormProps>;

export const $BaseStaffMemberStaffPartyPerson = z.object({
  id: z.number().int().positive(),
  guid: z.string().uuid(),
  plus1Guid: z.string().uuid(),
  fullName: z.string(),
  firstName: z.string(),
  surname: z.string(),
  jobTitleValue: $JobOptionValueType,
  jobTitleName: $JobOptionNameType,
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  avatarUrl: z.string().nullable(),
  enabled: z.boolean(),
  otherJobTitle: z.string().nullable(),
  staffPartyQuizTeamName: z.string(),
  staffPartyQuizTeamId: z.number().int().positive(),
}).strict();

export const $NonStaffMemberStaffPartyPerson = $BaseStaffMemberStaffPartyPerson.extend({
  jobTitleValue: $OtherJobTitleValue,
  otherJobTitle: z.string().min(1),
}).strict().or($BaseStaffMemberStaffPartyPerson.extend({
  jobTitleValue: $NotOtherJobTitleValue,
  otherJobTitle: z.null(),
}).strict());

export type NonStaffMemberStaffPartyPerson = z.infer<typeof $NonStaffMemberStaffPartyPerson>;

export const $PaginationData = z.object({
  totalCount: z.number().int().nonnegative(),
  currentPage: z.number().int().positive(),
  pageSize: z.number().int().positive(),
});

export const ALL_FILTER_TYPE = 'all';
export const ENABLED_FILTER_TYPE = 'enabled';

export type PaginationData = z.infer<typeof $PaginationData>;

export const $IndexFilterType = z.enum([ALL_FILTER_TYPE, ENABLED_FILTER_TYPE]);

export const $IndexPageFilterValues = z.object({
  name: z.string().min(0),
  email: z.string().min(0),
  filterType: $IndexFilterType,
}).strict();

export type IndexPageFilterValues = z.infer<typeof $IndexPageFilterValues>;

export const $IndexPageFilterOption = z.object({
  value: $IndexFilterType,
  label: z.string(),
});

export const $IndexPageFilterData = z.object({
  values: $IndexPageFilterValues,
  filtering: z.boolean(),
});

export type IndexPageFilterData = z.infer<typeof $IndexPageFilterData>;

export const $StaffPartyPeopleIndexProps = z.object({
  nonStaffMemberStaffPartyPeople: $NonStaffMemberStaffPartyPerson.array(),
  pagination: $PaginationData,
  filter: $IndexPageFilterData,
});

export type StaffPartyPeopleIndexProps = z.infer<typeof $StaffPartyPeopleIndexProps>;

export const $StaffPartyPeopleAppPageData = z.object({
  accessToken: z.string(),
  jobOptions: $JobOption.array(),
  pagination: $PaginationData,
  filter: $IndexPageFilterData,
  filterTypeOptions: $IndexPageFilterOption.array(),
  staffPartyQuizTeamOptions: $StaffPartyQuizTeamOption.array(),
  guestStaffPartyQuizTeamId: z.number().int().positive(),
});

export const $StaffPartyPeopleAppGlobals = z.object({
  filterTypeOptions: $IndexPageFilterOption.array(),
  staffPartyQuizTeamOptions: $StaffPartyQuizTeamOption.array(),
  guestStaffPartyQuizTeamId: z.number().int().positive(),
  jobOptions: $JobOption.array(),
}).strict();

export type StaffPartyPeopleAppGlobals = z.infer<typeof $StaffPartyPeopleAppGlobals>;

export const $StaffPartyPeopleAppProps = z.object({
  nonStaffMemberStaffPartyPeople: $NonStaffMemberStaffPartyPerson.array(),
  pageData: $StaffPartyPeopleAppPageData,
}).strict();

export type StaffPartyPeopleAppProps = z.infer<typeof $StaffPartyPeopleAppProps>;

export const MAIN_QR_CODE_TYPE = 'main' as const;
export const PLUS_1_QR_CODE_TYPE = 'plus_1' as const;
export const $QrCodeType = z.union([
  z.literal(MAIN_QR_CODE_TYPE),
  z.literal(PLUS_1_QR_CODE_TYPE),
]);
export type QrCodeType = z.infer<typeof $QrCodeType>;