import React, { useState } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import AssignDashboard from './assign-dashboard';
import ContentWrapper from './assign-content-wrapper';
import StaffList from './assign-staff-list';
import StaffMember from './assign-staff-member';
import { openConfirmationModal } from '@/components/modals';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import AssignConfirm from './assign-confirm';
import Graph from './graph';
import { useModal } from '@/components/hooks-components/modals';
import GraphDetails from './graph-details';

function AssignPage(props) {
  const { openModal } = useModal();

  const [error, setError] = useState(null);
  const rotaShifts = oFetch(useLegacyGlobal(), 'rotaShifts');

  const timeSelectOptions = oFetch(props, 'timeSelectOptions');
  const originalStaffMembers = oFetch(props, 'staffMembers');
  const [staffMembers, setStaffMembers] = useState(originalStaffMembers);
  const onAssignShiftRequest = oFetch(props, 'onAssignShiftRequest');
  const assigningShiftRequest = oFetch(props, 'assigningShiftRequest');
  const venueName = oFetch(assigningShiftRequest, 'venueName');
  const appType = oFetch(props, 'appType');

  function handleFilterStaffMembers(searchQuery) {
    setStaffMembers(utils.staffMemberFilterCamelCaseJS(searchQuery, originalStaffMembers));
  }

  function handleOpenConfirmationModal(args) {
    const appType = oFetch(args, 'appType');
    const avatarUrl = oFetch(args, 'avatarUrl');
    const fullName = oFetch(args, 'fullName');
    const staffMemberId = oFetch(args, 'staffMemberId');
    const rotaShifts = oFetch(args, 'rotaShifts');
    const supportsFacialRecognition = oFetch(args, 'supportsFacialRecognition');
    const isSteward = oFetch(args, 'isSteward');

    openConfirmationModal({
      submit: handleAssignRequest,
      config: { title: 'CONFIRM SHIFT ASSIGNMENT' },
      props: {
        appType,
        avatarUrl,
        fullName,
        staffMemberId,
        shiftRequest: assigningShiftRequest,
        rotaShifts,
        supportsFacialRecognition,
        isSteward,
        timeSelectOptions,
      },
    })(AssignConfirm);
  }

  function handleAssignRequest(hideModal, values) {
    const staffMemberId = oFetch(values, 'staffMemberId');
    const startsAt = oFetch(values, 'startsAt');
    const endsAt = oFetch(values, 'endsAt');
    const headDoormanHour = oFetch(values, 'headDoormanHour');
    const venueId = oFetch(values, 'venueId');
    const id = oFetch(assigningShiftRequest, 'id');

    return onAssignShiftRequest({
      values: { id, staffMemberId, startsAt, endsAt, headDoormanHour, venueId },
      onSuccess() {
        hideModal();
        oFetch(props, 'onCloseAssignPage')(venueName);
      },
      onFailure() {
        // hideModal();
        setError('There was an error assigning this shift. Please check for errors and try again');
      },
    });
  }

  function handleShiftClick(shift) {
    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: GraphDetails,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_rota',
        title: 'Security Shift Details',
      },
      props: {
        graphDetails: shift,
      },
    });
  }

  const onCloseAssignPage = oFetch(props, 'onCloseAssignPage');

  return (
    <div className="boss-page-main">
      <AssignDashboard shiftRequest={assigningShiftRequest} title="Assign Shift Request">
        <a
          onClick={() => onCloseAssignPage(venueName)}
          className="boss-button boss-button_role_cancel boss-page-dashboard__button"
        >
          Cancel
        </a>
      </AssignDashboard>
      <ContentWrapper error={error}>
        <StaffList
          onFilterStaffMembers={handleFilterStaffMembers}
          staffMembers={staffMembers}
          itemRenderer={staffMember => {
            return (
              <StaffMember
                appType={appType}
                staffMember={staffMember}
                isDisabled={staffMember.isOverlapped}
                shiftRequest={assigningShiftRequest}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
              />
            );
          }}
        />
        <Graph appType={appType} rotaShifts={rotaShifts} staffMembers={originalStaffMembers} onShiftClick={handleShiftClick} />
      </ContentWrapper>
    </div>
  );
}

export default AssignPage;
