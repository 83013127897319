import { canvasToBlob, getAsDataURL, resizeToLimit } from '@/utils/image-utils';
import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

type Props = {
  base64Image: string;
  onCancel: () => void;
  onSubmit: (base64: string | ArrayBuffer | null) => void;
};

export function BossCropper(props: Props) {
  const cropperRef = useRef<ReactCropperElement>(null);
  const { base64Image, onCancel, onSubmit } = props;

  function onRotateLeft() {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.rotate(-90);
    }
  }

  function onRotateRight() {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.rotate(90);
    }
  }

  async function onCropSubmit() {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      const blob = await canvasToBlob(canvas);
      const resizedFile = await resizeToLimit(blob, 1024 * 1024, 600);
      const resizedBase64 = await getAsDataURL(resizedFile);
      onSubmit(resizedBase64);
    }
  }

  function onCancelCrop() {
    onCancel();
  }

  return (
    <div className="boss-add-avatar-block">
      <div className="boss-edit-image-block boss-add-avatar-block_adjust_edit-image-block">
        <div className="boss-edit-image-block__cropper-block">
          <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
            <button
              type="button"
              className="boss-button boss-buttons-group_adjust_button"
              onClick={onRotateLeft}
            >
              Rotate Left
            </button>
            <button
              type="button"
              className="boss-button boss-buttons-group_adjust_button"
              onClick={onRotateRight}
            >
              Rotate Right
            </button>
          </div>

          <div className="boss-edit-image-block__cropper">
            <Cropper
              ref={cropperRef}
              zoomOnWheel={false}
              src={base64Image}
              preview="[data-avatarpreview]"
              style={{
                height: '100%',
                width: '100%',
              }}
              aspectRatio={1}
              guides={true}
            />
          </div>

          <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
            <button
              type="button"
              className="boss-button boss-buttons-group_adjust_button"
              onClick={onCropSubmit}
            >
              Ok
            </button>
            <button
              type="button"
              className="boss-button boss-buttons-group_adjust_button"
              onClick={onCancelCrop}
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          className="boss-edit-image-block__preview-section"
          data-avatarpreview
        />
      </div>
    </div>
  );
}
