import React from 'react';
import { DefaultModalWrapper } from './default-modal-wrapper';

type CloseInsideModalWrapperProps = {
  children: React.ReactNode;
  title: () => React.ReactNode | string;
  onClose: () => void;
  baseClassName?: string;
};

export function CloseInsideModalWrapper(props: CloseInsideModalWrapperProps) {
  const { children, title, onClose, baseClassName } = props;

  function renderTitle() {
    if (typeof title === 'function') {
      return title();
    }
    return <div className="boss-modal-window__header">{title}</div>;
  }

  return (
    <DefaultModalWrapper
      onRequestClose={onClose}
      baseClassName={baseClassName}
    >
      <button
        type="button"
        onClick={onClose}
        className="boss-modal-window__close-inner"
      />
      {renderTitle()}
      <div className="boss-modal-window__content">{children}</div>
    </DefaultModalWrapper>
  );
}

CloseInsideModalWrapper.defaultProps = {
  baseClassName: 'boss-modal-window boss-modal-window_role_add',
};
