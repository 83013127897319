import React from 'react';
import oFetch from 'o-fetch';

import { openContentModal, openWarningModal, MODAL_TYPE2 } from '@/components/modals';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

import AccessoriesHeader from './accessories-header';
import AccessoriesContent from './accessories-content';
import NewAccessoryRequest from './new-accessory-request';
import AccessoryRequestsList from './accessory-requests-list';
import AccessoryRequestItem from './accessory-request-item';
import AccessoriesFilter from './accessories-filter';
import EditAccessoryRequest from './edit-accessory-request';
import EditAccessoryRefundRequest from './edit-accessory-refund-request';
import ReusableModalContent from './reusable-modal-content';
import { ReturnAccessoryModalContent } from './return-accessory-modal-content';

import { appRoutes } from '@/lib/legacy-routes';

function AccessoriesPage(props) {
  const staffMemberJS = oFetch(props, 'staffMember').toJS();
  const canCreateAccessoryRequest = oFetch(props, 'canCreateAccessoryRequest');
  const getAccessoryRequestPermission = oFetch(props, 'getAccessoryRequestPermission');
  const accessoryRequestsJs = oFetch(props, 'accessoryRequests').toJS();
  const [mPayslipStartDate, mPayslipEndDate] = oFetch(props, 'mPayslipStartDate', 'mPayslipEndDate');
  const { openModal, openPromiseModal } = useModal();

  const actions = oFetch(props, 'actions');
  const [
    newAccessory,
    cancelAccessory,
    refundAccessory,
    editAccessoryRequestPayslipDate,
    editAccessoryRefundRequestPayslipDate,
    filter,
    returnAccessory,
    writeOffAccessory,
  ] = oFetch(
    actions,
    'newAccessory',
    'cancelAccessory',
    'refundAccessory',
    'editAccessoryRequestPayslipDate',
    'editAccessoryRefundRequestPayslipDate',
    'filter',
    'returnAccessory',
    'writeOffAccessory',
  );

  function handleNewRequestSubmit(closeModal, values) {
    const staffMemberId = oFetch(staffMemberJS, 'id');
    return newAccessory({ staffMemberId, values }).then(resp => {
      closeModal();
      return resp;
    });
  }

  function handleCancelRequest(accessoryRequestId) {
    const staffMemberId = oFetch(staffMemberJS, 'id');
    return new Promise((resolve, reject) => {
      openWarningModal({
        submit: (hideModal, values) =>
          cancelAccessory(values).then(() => {
            hideModal();
          }),
        config: {
          title: 'WARNING !!!',
          text: 'Are You Sure, you want to cances accessory request?',
          buttonText: 'Cancel request',
        },
        closeCallback: () => resolve(),
        props: { accessoryRequestId, staffMemberId },
      });
    });
  }

  function handleRefundRequest(accessoryRequestId) {
    const staffMemberId = oFetch(staffMemberJS, 'id');
    return new Promise((resolve, reject) => {
      openContentModal({
        submit: (handleClose, { reusable }) => {
          handleClose();
          refundAccessory({ accessoryRequestId, staffMemberId, reusable });
        },
        config: { title: 'Refund Accessory Request', type: MODAL_TYPE2 },
        props: {},
        closeCallback: () => resolve(),
      })(ReusableModalContent);
    });
  }

  function handleReturnAccessory(accessoryRequestId) {
    const staffMemberId = oFetch(staffMemberJS, 'id');
    return openPromiseModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: ReturnAccessoryModalContent,
      onSubmit(handleClose, { reusable }) {
        handleClose();
        return returnAccessory({ accessoryRequestId, staffMemberId, reusable });
      },
      props: {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_confirm',
        title() {
          return (
            <div className="boss-modal-window__header boss-modal-window__header_warning">
              RETURN ACCESSORY
            </div>
          );
        },
      },
    });
  }

  function handleWriteOffAccessory(accessoryRequestId) {
    const staffMemberId = oFetch(staffMemberJS, 'id');
    return writeOffAccessory({ accessoryRequestId, staffMemberId });
  }

  function handleEditPayslipDateSubmit(hideModal, values) {
    return editAccessoryRequestPayslipDate(values).then(() => {
      hideModal();
    });
  }

  function handleEditRefundPayslipDateSubmit(hideModal, values) {
    return editAccessoryRefundRequestPayslipDate(values).then(() => {
      hideModal();
    });
  }

  function openNewRequestModal() {
    const accessoriesOptions = oFetch(props, 'accessoriesOptions');
    openContentModal({
      submit: handleNewRequestSubmit,
      props: {
        accessoriesOptions,
      },
      config: { title: 'Add Request' },
    })(NewAccessoryRequest);
  }

  function openEditPayslipDateModal(accessoryRequest) {
    openContentModal({
      submit: handleEditPayslipDateSubmit,
      props: {
        accessoryRequest,
      },
      config: { title: 'Edit Payslip Date' },
    })(EditAccessoryRequest);
  }

  function openEditRefundPayslipDateModal(accessoryRequest) {
    openContentModal({
      submit: handleEditRefundPayslipDateSubmit,
      props: {
        accessoryRequest,
      },
      config: { title: 'Edit Refund Payslip Date' },
    })(EditAccessoryRefundRequest);
  }

  function handleFilter({ mPayslipStartDate, mPayslipEndDate }) {
    const staffMemberId = oFetch(staffMemberJS, 'id');

    const webGetUrl = appRoutes.staffMemberAccessories({
      staffMemberId,
      mPayslipStartDate,
      mPayslipEndDate,
    });
    window.history.pushState('state', 'title', `${webGetUrl}`);
    return filter({ mPayslipStartDate, mPayslipEndDate, staffMemberId });
  }

  return (
    <section className="boss-board">
      <AccessoriesHeader
        canCreateAccessoryRequest={canCreateAccessoryRequest}
        title="Accessories"
        staffMember={staffMemberJS}
        onRequest={openNewRequestModal}
      />
      <AccessoriesContent>
        <AccessoriesFilter
          mPayslipStartDate={mPayslipStartDate}
          mPayslipEndDate={mPayslipEndDate}
          onFilter={handleFilter}
        />
        <AccessoryRequestsList
          accessoryRequests={accessoryRequestsJs}
          accessoryRequestRendered={accessoryRequest => {
            const accessoryRequestId = oFetch(accessoryRequest, 'id');
            const accessoryRequestPermission = getAccessoryRequestPermission(accessoryRequestId).toJS();
            return (
              <AccessoryRequestItem
                onAccessoryCancel={handleCancelRequest}
                onAccessoryRefund={handleRefundRequest}
                onReturnAccessory={handleReturnAccessory}
                onWriteOffAccessory={handleWriteOffAccessory}
                accessoryRequest={accessoryRequest}
                accessoryRequestPermissions={accessoryRequestPermission}
                onEditPayslipDate={openEditPayslipDateModal}
                onEditRefundPayslipDate={openEditRefundPayslipDateModal}
              />
            );
          }}
        />
      </AccessoriesContent>
    </section>
  );
}

export default AccessoriesPage;
