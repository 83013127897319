import oFetch from 'o-fetch';
import _ from 'underscore';
import utils from "@/lib/utils";
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import safeMoment from '@/lib/safe-moment';

export const HOLIDAY_TYPE = 'holiday';
export const HOLIDAY_REQUEST_TYPE = 'holiday_request';

const holidaysSelector = state => {
  const stateJS = utils.callIfExists({
    obj: state,
    funcName: 'toJS',
  });

  const holidaysSection = oFetch(stateJS, 'holidays');
  const newHolidays = _.map(
    oFetch(holidaysSection, 'holidays'),
    (holiday) => {
      return {
        ...holiday,
        type: HOLIDAY_TYPE,
      };
    }
  );

  return fromJS(newHolidays);
};
const holidayRequestsSelector = state => {
  const stateJS = utils.callIfExists({
    obj: state,
    funcName: 'toJS',
  });
  const holidaysSection = oFetch(stateJS, 'holidays');
  const newHolidayRequests = _.map(
    oFetch(holidaysSection, 'holidayRequests'),
    (holidayRequest) => {
      return {
        ...holidayRequest,
        type: HOLIDAY_REQUEST_TYPE,
      };
    }
  );

  return fromJS(newHolidayRequests);
};

export const getHolidaysData = createSelector(
  holidaysSelector,
  holidayRequestsSelector,
  (
    holidays,
    holidayRequests,
  ) => {
    return fromJS(holidayRequests.toJS().concat(holidays.toJS()).sort((a, b) => {
      const mA = safeMoment.uiDateParse(oFetch(a, 'start_date'));
      const mB = safeMoment.uiDateParse(oFetch(b, 'start_date'));

      if (mA.isBefore(mB)) { return -1; }
      if (mA.isAfter(mB)) { return 1; }
      if (mA.isSame(mB)) { return 0; }
    }));
  }
);
