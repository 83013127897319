import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';
import { UploadFilesFormField } from './upload-files-form-field';
import { deleteUploadedImageFile, uploadImageFile } from '../../requests';

export function RequestForm(props) {
  const [initialValues, files] = oFetch(props, 'initialValues', 'files');
  const [onSubmit, onClose, buttonText] = oFetch(props, 'onSubmit', 'onClose', 'buttonText');

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <>
              <div className="boss-form__field">
                <Field name="text" label="Question" parse={value => value} component={BossFormTextarea} />
              </div>
              <div className="boss-form__field">
                <Field
                  name="fileIds"
                  files={files}
                  label="Files (Optional)"
                  softDelete
                  component={UploadFilesFormField}
                  onUpload={uploadImageFile}
                />
              </div>
              <div className="boss-form__field boss-form__field_role_controls">
                <button
                  onClick={onClose}
                  type="button"
                  className="boss-button boss-button_role_inactive boss-form__button boss-form__button_adjust_row"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                >
                  {buttonText}
                </button>
              </div>
            </>
          );
        }}
      />
    </>
  );
}
