import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { formattedAsTruncatedHourDecimal } from '../utils';

const WEEK_DATE_FORMAT = 'dddd DD MMMM YYYY';

function Header() {
  return (
    <div className="boss-table__row">
      <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
      <div className="boss-table__cell boss-table__cell_role_header">Time</div>
      <div className="boss-table__cell boss-table__cell_role_header">Pay Rate</div>
      <div className="boss-table__cell boss-table__cell_role_header">Hours</div>
      <div className="boss-table__cell boss-table__cell_role_header">Amount</div>
    </div>
  );
}

function Cell(props) {
  const [label, value, style] = oFetch(props, 'label', 'value', 'style');
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info" style={style}>
        <p className="boss-table__label">{label}</p>
        <p className="boss-table__text">{value}</p>
      </div>
    </div>
  );
}

Cell.defaultProps = {
  style: {},
};

function WeekDateBreakdown(props) {
  const [venueName, hours, calculating] = oFetch(props, 'venueName', 'hours', 'calculating');
  const [cents, startsAt, endsAt, description, centsPerHour, minutes] = oFetch(
    hours,
    'cents',
    'startsAt',
    'endsAt',
    'description',
    'centsPerHour',
    'minutes',
  );
  const rowClassNames = cn('boss-table__row', {
    'boss-table__row_state_pre-calculated': calculating,
  });
  const formattedStartsAt = safeMoment
    .iso8601Parse(startsAt)
    .format(utils.commonDateFormatTimeOnly());
  const formattedEndsAt = safeMoment.iso8601Parse(endsAt).format(utils.commonDateFormatTimeOnly());

  const time = `${formattedStartsAt} - ${formattedEndsAt}`;
  const moneyAmount = utils.moneyFormat(cents / 100);
  const moneyPerHour = utils.moneyFormat(centsPerHour / 100);
  const payRate = `${description} ${moneyPerHour}h`;
  const formattedHours = formattedAsTruncatedHourDecimal(minutes);

  return (
    <div className={rowClassNames}>
      <Cell style={{ marginLeft: calculating && '20px' }} label={'Venue'} value={venueName} />
      <Cell label={'Time'} value={time} />
      <Cell label={'Pay Rate'} value={payRate} />
      <Cell label={'Hours'} value={formattedHours} />
      <Cell label={'Amount'} value={moneyAmount} />
    </div>
  );
}

export function ReportModal(props) {
  const [breakdowns, weekDates, calculating] = oFetch(
    props,
    'breakdowns',
    'weekDates',
    'calculating',
  );

  function renderWeekDateBreakdowns(breakdowns) {
    return (
      <div className="boss-overview__table-content">
        <div className="boss-table boss-table_page_security-payroll-report-week-details">
          <Header />
          {breakdowns.map((breakdown, index) => {
            const [venueName, json] = oFetch(breakdown, 'venueName', 'json');
            return json
              .sort((a, b) => {
                if (a.startsAt < b.startsAt) return -1;
                if (a.startsAt > b.startsAt) return 1;
                return 0;
              })
              .map((hours, index) => {
                return (
                  <WeekDateBreakdown
                    key={index}
                    calculating={calculating}
                    venueName={venueName}
                    hours={hours}
                  />
                );
              });
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="boss-modal-window__scroll">
      <div className="boss-modal-window__overview">
        <div className="boss-overview">
          {weekDates.map(weekDate => {
            const formattedWeekDate = safeMoment.uiDateParse(weekDate).format(WEEK_DATE_FORMAT);
            const weekDateBreakdowns = breakdowns[weekDate];

            return (
              <div key={weekDate} className="boss-overview__group">
                <div className="boss-overview__table">
                  <div className="boss-overview__table-header">
                    <p className="boss-overview__table-title">{formattedWeekDate}</p>
                  </div>
                  {!weekDateBreakdowns && (
                    <div className="boss-overview__table-content">
                      <p className="boss-overview__table-placeholder">No Data To Show</p>
                    </div>
                  )}
                  {weekDateBreakdowns && renderWeekDateBreakdowns(weekDateBreakdowns)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
