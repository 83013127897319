import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export function AreYouSureUncompleteModal(props) {
  const [onClose, onSubmit] = oFetch(props, 'onClose', 'onSubmit');

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text boss-modal-window__message-text-marked">
          Undo Report Completion.
        </span>
        <span className="boss-modal-window__message-text">
          If you uncomplete this report it will be recalculated and all of its data will become
          unfrozen.
        </span>
        <span className="boss-modal-window__message-text">Do you want to continue?</span>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onClose}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          CANCEL
        </button>
        <AsyncButton
          className="boss-button boss-modal-window__button"
          text="CONFIRM"
          pendingText="PROCESSING ..."
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
