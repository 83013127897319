import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import { appRoutes } from '@/lib/legacy-routes';
import ProfileWrapper from '../../profile-wrapper';
import HistoryList from '../components/history-list';
import HistoryItem from '../components/history-item';
import HistoryFilter from '../components/history-filter';
import {
  getStaffMemberHistory,
  staffMemberCreatedAtSelector,
  staffMemberIdSelector,
  staffMemberFilterSelector,
  beganChangesSelector,
  createdBySelector,
} from '../selectors';
import { handleFilterChangeAction } from '../actions';
const ITEMS_PER_PAGE = 10;

function RevisionHistory(props) {
  const [currentPage, setCurrentPage] = useState(1);

  function getPaginatedItems(items) {
    return items.slice(0, ITEMS_PER_PAGE * currentPage);
  }

  const [items, mStaffMemberCreatedAt, staffMemberId, filter, handleFilterChange, mBeganChanges, createdBy] = oFetch(
    props,
    'staffMemberHistory',
    'mStaffMemberCreatedAt',
    'staffMemberId',
    'filter',
    'handleFilterChange',
    'mBeganChanges',
    'createdBy'
  );
  const showLoadMoreButton = ITEMS_PER_PAGE * currentPage < oFetch(items, 'length');

  return (
    <ProfileWrapper currentPage="profile">
      <section className="boss-board">
        <header className="boss-board__header">
          <h2 className="boss-board__title">History</h2>
          <div className="boss-board__button-group">
            <a
              href={appRoutes.staffMemberProfile(staffMemberId)}
              className="boss-button boss-button_role_alert"
            >
              Back to Profile
            </a>
          </div>
        </header>
        <div className="boss-board__main">
          <div className="boss-board__manager">
            <div className="boss-board__manager-group boss-board__manager-group_role_data">
              <div className="boss-board__manager-filter">
                <HistoryFilter
                  onSubmit={handleFilterChange}
                  filter={filter.toJS()}
                  staffMemberId={staffMemberId}
                />
              </div>
              <div className="boss-board__manager-timeline">
                <HistoryList
                  mStaffMemberCreatedAt={mStaffMemberCreatedAt}
                  mBeganTracking={mBeganChanges}
                  items={getPaginatedItems(items)}
                  loadMorePending={showLoadMoreButton}
                  createdBy={createdBy}
                  itemRenderer={item => <HistoryItem history={item} />}
                />
              </div>
              {showLoadMoreButton && (
                <div className="boss-board__manager-actions">
                  <button
                    type="button"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </ProfileWrapper>
  );
}

const mapStateToProps = state => {
  return {
    staffMemberHistory: getStaffMemberHistory(state),
    mStaffMemberCreatedAt: staffMemberCreatedAtSelector(state),
    staffMemberId: staffMemberIdSelector(state),
    filter: staffMemberFilterSelector(state),
    mBeganChanges: beganChangesSelector(state),
    createdBy: createdBySelector(state),
  };
};

const mapDispatchToProps = {
  handleFilterChange: handleFilterChangeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RevisionHistory);
