import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

export const questionnaireQuestionsSelector = state => oFetch(state, 'questionnaireQuestions');
export const questionnaireCategoriesSelector = state => oFetch(state, 'questionnaireCategories');
export const questionnaireAreasSelector = state => oFetch(state, 'questionnaireAreas');
export const staticDataSelector = state => oFetch(state, 'staticData');

export const getCurrentVenueId = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'venueId');
})

export const getColors = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'colors');
});

export const getQuestionnaireId = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'questionnaireId');
});

export const scaledQuestionTypeSelector = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'scaledQuestionType');
});

export const binaryQuestionTypeSelector = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'binaryQuestionType');
});

export const requiredQuestionTypeSelector = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'requiredQuestionType');
});

export const getEmptyQuestionnaireCategoriesIds = createSelector(
  [questionnaireCategoriesSelector, questionnaireQuestionsSelector],
  (questionnaireCategories, questionnaireQuestions) => {
    const categoriesWithQuestionsIds = Object.keys(
      groupBy(questionnaireQuestions, i => oFetch(i, 'questionnaireCategoryId')),
    ).map(i => parseInt(i));
    return questionnaireCategories
      .filter(questionnaireCategory => !categoriesWithQuestionsIds.includes(oFetch(questionnaireCategory, 'id')))
      .map(questionnaireCategory => oFetch(questionnaireCategory, 'id'));
  },
);

export const getEmptyQuestionnaireAreasIds = createSelector(
  [questionnaireAreasSelector, questionnaireQuestionsSelector],
  (questionnaireAreas, questionnaireQuestions) => {
    const areasWithQuestionsIds = Object.keys(
      groupBy(questionnaireQuestions, i => oFetch(i, 'questionnaireAreaId')),
    ).map(i => parseInt(i));
    return questionnaireAreas
      .filter(questionnaireArea => !areasWithQuestionsIds.includes(oFetch(questionnaireArea, 'id')))
      .map(questionnaireArea => oFetch(questionnaireArea, 'id'));
  },
);

export const getQuestionnaireCategories = createSelector(
  [questionnaireCategoriesSelector, getEmptyQuestionnaireCategoriesIds],
  (questionnaireCategories, emptyQuestionnaireCategoriesIds) => {
    return questionnaireCategories.map((questionnaireCategory, index) => {
      const questionnaireCategoryId = oFetch(questionnaireCategory, 'id');

      const isEmptyCategory = emptyQuestionnaireCategoriesIds.includes(questionnaireCategoryId);
      return {
        ...questionnaireCategory,
        index,
        number: index + 1,
        isEmpty: isEmptyCategory,
      };
    });
  },
);

export const getQuestionnaireAreas = createSelector(
  [questionnaireAreasSelector, getEmptyQuestionnaireAreasIds],
  (questionnaireAreas, emptyQuestionnaireAreasIds) => {
    return questionnaireAreas.map((questionnaireArea, index) => {
      const questionnaireAreaId = oFetch(questionnaireArea, 'id');

      const isEmptyArea = emptyQuestionnaireAreasIds.includes(questionnaireAreaId);
      return {
        ...questionnaireArea,
        index,
        isEmpty: isEmptyArea,
        number: index + 1,
      };
    });
  },
);

export const getColorsOptions = createSelector([getColors], colors => {
  return Object.values(colors).map(colorItem => {
    const [label, color] = oFetch(colorItem, 'label', 'color');
    return {
      value: color,
      label: label,
      color: color,
    };
  });
});

export const getQuestionTypesOptions = createSelector([staticDataSelector], staticData => {
  return Object.entries(oFetch(staticData, 'questionTypesOptions')).map(entry => {
    const [value, label] = entry;
    return {
      value,
      label,
    };
  });
});

export const getQuestionnaireQuestions = createSelector(
  [
    questionnaireQuestionsSelector,
    questionnaireCategoriesSelector,
    questionnaireAreasSelector,
    requiredQuestionTypeSelector,
  ],
  (
    questionnaireQuestions,
    questionnaireCategories,
    questionnaireAreas,
    requiredQuestionType,
  ) => {
    return questionnaireQuestions.map((questionnaireQuestion, index) => {
      const [questionnaireQuestionId, questionnaireCategoryId, questionnaireAreaId, type] = oFetch(
        questionnaireQuestion,
        'id',
        'questionnaireCategoryId',
        'questionnaireAreaId',
        'type',
      );
      const questionnaireCategory = questionnaireCategories.find(
        questionnaireCategory => oFetch(questionnaireCategory, 'id') === questionnaireCategoryId,
      );
      if (!questionnaireCategory) {
        throw new Error(`no record found for questionnaireCategory: ${questionnaireCategoryId}`);
      }
      const questionnaireArea = questionnaireAreas.find(
        questionnaireArea => oFetch(questionnaireArea, 'id') === questionnaireAreaId,
      );
      if (!questionnaireArea) {
        throw new Error(`no record found for questionnaireArea: ${questionnaireAreaId}`);
      }
      const [questionnaireCategoryName, categoryColor] = oFetch(questionnaireCategory, 'name', 'color');
      const questionnaireAreaName = oFetch(questionnaireArea, 'name');
      const requiredQuestion = type === requiredQuestionType;

      return {
        ...questionnaireQuestion,
        categoryName: questionnaireCategoryName,
        areaName: questionnaireAreaName,
        sortIndex: index,
        number: index + 1,
        categoryColor,
        requiredQuestion,
      };
    });
  },
);

export const getSortedQuestionnaireQuestions = createSelector(
  [getQuestionnaireQuestions],
  questionnaireQuestions => {
    const questionnaireQuestionsWithSortIndex = questionnaireQuestions.map((questionnaireQuestion, index) => {
      return {
        ...questionnaireQuestion,
        sortIndex: index,
      }
    });
    return sortBy(questionnaireQuestions, [i => oFetch(i, 'sortIndex')]);
  },
);

export const getSortedQuestionnaireCategories = createSelector(
  [getQuestionnaireCategories],
  questionnaireCategories => {
    const questionnaireCategoriesWithSortIndex = questionnaireCategories.map((questionnaireCategory, index) => {
      return {
        ...questionnaireCategory,
        sortIndex: index,
      }
    });
    return sortBy(questionnaireCategoriesWithSortIndex, [i => oFetch(i, 'sortIndex')]);
  },
);

export const getActiveQuestionnaireCategories = createSelector(
  [getQuestionnaireCategories],
  questionnaireCategories => {
    return questionnaireCategories.filter(questionnaireCategory => {
      return oFetch(questionnaireCategory, 'enabled');
    });
  }
);

export const getAddQuestionCategoriesOptions = createSelector(
  [getActiveQuestionnaireCategories],
  questionnaireCategories => {
    return questionnaireCategories.map(questionnaireCategory => {
      const [id, name] = oFetch(questionnaireCategory, 'id', 'name');
      return {
        value: id,
        label: name,
      };
    });
  },
);

export const getEditQuestionCategoriesOptions = args => {
  const questionnaireCategoryId = oFetch(args, 'questionnaireCategoryId');
  const state = oFetch(args, 'state');

  return createSelector(
    [getQuestionnaireCategories, getActiveQuestionnaireCategories],
    (allQuestionnaireCategories, activeQuestionnaireCategories) => {
      const questionnaireCategories = []
      activeQuestionnaireCategories.forEach(questionnaireCategory => {
        questionnaireCategories.push(questionnaireCategory)
      });
      const questionQuestionnaireCategoryPresent = activeQuestionnaireCategories.map(qc => oFetch(qc, 'id')).includes(questionnaireCategoryId);
      if (!questionQuestionnaireCategoryPresent) {
        matchedQuestionnaireCategory = allQuestionnaireCategories.find(qc => oFetch(qc, 'id') === questionnaireCategoryId);
        if (!matchedQuestionnaireCategory) {
          throw new Error(`No questionnaire category found matching id: ${questionnaireCategoryId}`)
        }
        questionnaireCategories.push(matchedQuestionnaireCategory);
      }

      return questionnaireCategories.map(questionnaireCategory => {
        const [id, name] = oFetch(questionnaireCategory, 'id', 'name');
        return {
          value: id,
          label: name,
        };
      });
    },
  )(state);
};

export const getActiveQuestionnaireAreas = createSelector(
  [ getQuestionnaireAreas ],
  questionnaireAreas => {
    return questionnaireAreas.filter(questionnaireArea => {
      return oFetch(questionnaireArea, 'enabled');
    })
  }
);

export const getAddQuestionAreasOptions = createSelector([getActiveQuestionnaireAreas], questionnaireAreas => {
  return questionnaireAreas.map(questionnaireArea => {
    const [id, name] = oFetch(questionnaireArea, 'id', 'name');
    return {
      value: id,
      label: name,
    };
  });
});

export const getEditQuestionAreasOptions = args => {
  const questionnaireAreaId = oFetch(args, 'questionnaireAreaId');
  const state = oFetch(args, 'state');

  return createSelector(
    [getQuestionnaireAreas, getActiveQuestionnaireAreas],
    (allQuestionnaireAreas, activeQuestionnaireAreas) => {
      const questionnaireAreas = []
      activeQuestionnaireAreas.forEach(questionnaireArea => {
        questionnaireAreas.push(questionnaireArea)
      });
      const questionQuestionnaireAreaPresent = activeQuestionnaireAreas.map(qc => oFetch(qc, 'id')).includes(questionnaireAreaId);
      if (!questionQuestionnaireAreaPresent) {
        matchedQuestionnaireArea = allQuestionnaireAreas.find(qc => oFetch(qc, 'id') === questionnaireAreaId);
        if (!matchedQuestionnaireArea) {
          throw new Error(`No questionnaire area found matching id: ${questionnaireAreaId}`)
        }
        questionnaireAreas.push(matchedQuestionnaireArea);
      }

    return questionnaireAreas.map(questionnaireArea => {
      const [id, name] = oFetch(questionnaireArea, 'id', 'name');
      return {
        value: id,
        label: name,
      };
    });
  })(state);
};


export const getSortedQuestionnaireAreas = createSelector([getQuestionnaireAreas], questionnaireAreas => {
  const questionnaireAreasWithSortIndex = questionnaireAreas.map((questionnaireArea, index) => {
    return {
      ...questionnaireArea,
      sortIndex: index,
    }
  });

  return sortBy(questionnaireAreasWithSortIndex, [i => oFetch(i, 'sortIndex')]);
});
