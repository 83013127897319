import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { AsyncButton } from 'react-async-button';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

import { SecurityPayRateCollapsibleCard } from './security-pay-rate-collapsible-card';

export function ForStaffMembersSecurityPayRateItem(props) {
  const [
    securityPayRate,
    onEditPayRate,
    onAddExceptionClick,
    onEditExceptionClick,
    onDeleteExceptionClick,
  ] = oFetch(
    props,
    'securityPayRate',
    'onEditPayRate',
    'onAddExceptionClick',
    'onEditExceptionClick',
    'onDeleteExceptionClick',
  );
  const [
    name,
    isForStandardSecurityStaff,
    isForStewardSecurityStaff,
    isAdmin,
    isCustom,
    standardHourlyRateCents,
    weekendHourlyRateCents,
    securityPayRateExceptions,
  ] = oFetch(
    securityPayRate,
    'name',
    'isForStandardSecurityStaff',
    'isForStewardSecurityStaff',
    'isAdmin',
    'isCustom',
    'standardHourlyRateCents',
    'weekendHourlyRateCents',
    'securityPayRateExceptions',
  );

  const securityPayRateExceptionsExist = securityPayRateExceptions.length > 0;

  function getSubTitle() {
    if (isForStandardSecurityStaff) {
      return `Standard${isAdmin ? ' - Admin' : ''}`;
    }
    if (isForStewardSecurityStaff) {
      return `Steward${isAdmin ? ' - Admin' : ''}`;
    }
    throw new Error('Wrong custom sub type');
  }

  function renderCardTitle() {
    if (!isCustom) {
      return name;
    }
    if (isCustom) {
      return (
        <div className="boss-check__header-group">
          <h3 className="boss-check__title">
            {name} <span className="boss-check__title-type">({getSubTitle()})</span>
          </h3>
        </div>
      );
    }
  }

  function renderCardActions() {
    if (isCustom) {
      return (
        <Fragment>
          <button
            onClick={() => onEditPayRate({ securityPayRate })}
            className="boss-button boss-button_role_edit-alt boss-button_type_small boss-check__header-action"
          >
            Edit
          </button>
        </Fragment>
      );
    }
    return null;
  }

  function renderSecurityPayRateExceptions() {
    return securityPayRateExceptions.map(securityPayRateException => {
      const [id, hourlyRateCents, startsAt, endsAt] = oFetch(
        securityPayRateException,
        'id',
        'hourlyRateCents',
        'startsAt',
        'endsAt',
      );
      const formattedStartsAt = safeMoment.iso8601Parse(startsAt).format(utils.humanDateFormatWithTime());
      const formattedEndsAt = safeMoment.iso8601Parse(endsAt).format(utils.humanDateFormatWithTime());
      // onEditExceptionClick
      return (
        <div key={id} className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__text">
                <span className="boss-table__text-important">{utils.centsFormat(hourlyRateCents)}</span>
              </p>
              <p className="boss-table__text boss-table__text_role_date">
                {formattedStartsAt} - {formattedEndsAt}
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-table__actions">
                <button
                  type="button"
                  onClick={() => onEditExceptionClick({ securityPayRateException, securityPayRate })}
                  className="boss-button boss-button_role_edit-light boss-button_type_extra-small boss-table__action"
                >
                  Edit
                </button>
                <AsyncButton
                  className="boss-button boss-button_role_cancel-light boss-button_type_extra-small boss-table__action"
                  text="Delete"
                  pendingText="Deleting ..."
                  onClick={() => onDeleteExceptionClick({ securityPayRateException, securityPayRate })}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <SecurityPayRateCollapsibleCard
      title={renderCardTitle()}
      defaultOpen={true}
      actionRender={renderCardActions}
    >
      <div className="boss-check__group boss-check__group_marked">
        <div className="boss-check__group-header">
          <p className="boss-check__group-title">Rules</p>
        </div>
        <div className="boss-check__group-content">
          <div className="boss-table boss-table_page_security-pay-rate-rules">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <p className="boss-table__text">
                    Standard -{' '}
                    <span className="boss-table__text-important">
                      {utils.centsFormat(standardHourlyRateCents)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <p className="boss-table__text">
                    Weekend -{' '}
                    <span className="boss-table__text-important">
                      {utils.centsFormat(weekendHourlyRateCents)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-check__group boss-check__group_marked">
        <div className="boss-check__group-header">
          <p className="boss-check__group-title">Exceptions</p>
          <button
            onClick={() => onAddExceptionClick({ securityPayRate })}
            type="button"
            className="boss-button boss-button_role_add boss-button_type_extra-small  boss-check__group-action"
          >
            Add Exception
          </button>
        </div>
        <div className="boss-check__group-content">
          <div className="boss-table boss-table_page_security-pay-rate-exceptions">
            {!securityPayRateExceptionsExist && (
              <p className="boss-table__text-placeholder">No Exceptions To Show</p>
            )}
            {securityPayRateExceptionsExist && renderSecurityPayRateExceptions()}
          </div>
        </div>
      </div>
    </SecurityPayRateCollapsibleCard>
  );
}
