import React from 'react';
import InputAutosize from 'react-input-autosize';

export function SelectTimeInputRenderer(props) {
  const { value: previousValue } = props;

  function handleChange(e) {
    const firstBlockRegEx = new RegExp('^(0[0-9]|1[0-9]|2[0-3])$');
    const value = e.target.value;

    if (value.length === 2 && previousValue.slice(-1) === ':') {
      props.onChange({ target: { value: value.slice(0, -1) } });
    } else {
      if (firstBlockRegEx.test(value)) {
        props.onChange({ target: { value: value + ':' } });
      } else {
        props.onChange({ target: { value: value } });
      }
    }
  }

  return (
    <InputAutosize
      {...props}
      minWidth={'5'}
      onChange={handleChange}
    />
  );
}
