export const MOSS_FINANCE_REPORT_STATUS_DONE_STATUS = 'done';
export const MOSS_FINANCE_REPORT_SHOW_ALL_FILTER_TYPE = 'show_all';
export const MOSS_FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE = 'with_owed_hours';
export const MOSS_FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE = 'with_holidays';

export const MOSS_FINANCE_REPORT_READY_STATUS = 'ready';
export const MOSS_FINANCE_REPORT_REQUIRING_UPDATE_STATUS = 'requiring_update';

export const FILTER_TABS = [
  MOSS_FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE,
  MOSS_FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE,
  MOSS_FINANCE_REPORT_SHOW_ALL_FILTER_TYPE,
];

export const FILTER_TITLES = {
  [MOSS_FINANCE_REPORT_SHOW_ALL_FILTER_TYPE]: 'Show All',
  [MOSS_FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE]: 'Owed Hours Only',
  [MOSS_FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE]: 'Paid Holidays Only',
};
