import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { batch, batching } from 'redux-batch-middleware';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

export default function configureStore(reducers, initialState) {
  const middleware = [thunk, batch];

  const store = createStore(batching(reducers), initialState, composeWithDevTools(applyMiddleware(...middleware)));

  return store;
}
