import React from 'react';
import oFetch from 'o-fetch';

export default function Stats(props) {
  const stats = oFetch(props, 'stats');
  const [level, xp, xpToNextLevel, xpPercentage] = oFetch(
    stats,
    'xp.level',
    'xp.xp',
    'xp.xpToNextLevel',
    'xp.xpPercentage',
  );
  const [totalQuizzes, quizWinRate, totalQuizzesFailed] = oFetch(
    stats,
    'totalQuizzes',
    'quizWinRate',
    'totalQuizzesFailed',
  );

  return (
    <section className="boss-board boss-board_context_stack">
      <header className="boss-board__header">
        <h2 className="boss-board__title">Stats</h2>
      </header>
      <div className="boss-board__main">
        <div className="boss-board__main-inner">
          <div className="boss-check boss-check_page_smp-quizzes-stats">
            <div className="boss-check__header">
              <div className="boss-check__header-number">
                <div className="boss-number">
                  <div className="boss-number__content">
                    <h3 className="boss-number__value boss-number__value_color_accent-blue">
                      {level} <span className="boss-number__value-meta">lv</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="boss-check__header-progress">
                <div className="boss-progress-bar">
                  <div className="boss-progress-bar__content">
                    <div className="boss-progress-bar__meter">
                      <div
                        className="boss-progress-bar__value boss-progress-bar__value_color_accent-blue boss-progress-bar__value_type_striped boss-progress-bar__value_type_animated"
                        style={{ width: `${xpPercentage}%` }}
                      />
                    </div>
                    <p className="boss-progress-bar__label boss-progress-bar__label_type_bold boss-progress-bar__label_position_after">
                      {xp} / {xpToNextLevel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-check__content">
              <div className="boss-check__box">
                <div className="boss-check__row">
                  <div className="boss-check__cell boss-check__cell_size_third boss-check__cell_delimiter_light">
                    <div className="boss-number boss-number_layout_fluid">
                      <div className="boss-number__content">
                        <h3 className="boss-number__value">{totalQuizzes}</h3>
                        <p className="boss-number__label boss-number__label_position_after">Quizzes Taken</p>
                      </div>
                    </div>
                  </div>
                  <div className="boss-check__cell boss-check__cell_size_third boss-check__cell_delimiter_light">
                    <div className="boss-number boss-number_layout_fluid">
                      <div className="boss-number__content">
                        <h3 className="boss-number__value">{totalQuizzesFailed}</h3>
                        <p className="boss-number__label boss-number__label_position_after">Quizzes Failed</p>
                      </div>
                    </div>
                  </div>
                  <div className="boss-check__cell boss-check__cell_size_third boss-check__cell_delimiter_light">
                    <div className="boss-number boss-number_layout_fluid">
                      <div className="boss-number__content">
                        <h3 className="boss-number__value">{quizWinRate}%</h3>
                        <p className="boss-number__label boss-number__label_position_after">Win Rate</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
