import React from 'react';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  mouseWheel: false,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export default function ScrollWrapper(props) {
  const children = oFetch(props, 'children');
  return (
    <ReactIScroll iScroll={iScroll} options={scrollOptions} className="boss-page-main__scroll">
      <div className="boss-page-main__scroll-content">{children}</div>
    </ReactIScroll>
  );
}
