import React from 'react';
import oFetch from 'o-fetch';

export default function CalendarBlankBlank(params) {
  const mDay = oFetch(params, 'day');
  const day = mDay.format('D');
  return (
    <div className="boss-planner__day boss-planner__day_type_other-month">
      <div className="boss-planner__day-header">
        <h3 className="boss-planner__day-date">{day}</h3>
      </div>
      <div className="boss-planner__day-content" />
    </div>
  );
}
