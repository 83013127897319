import React from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { BossFormSelectNew, Fields, BossFormYearQuarter, BossFormInput } from '@/components/boss-form';
import { ImageOption, ImageValue } from '@/components/boss-form/colored-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function PaymentForm(props) {
  const [initialValues, onSubmit, buttonText, edit] = oFetch(
    props,
    'initialValues',
    'onSubmit',
    'buttonText',
    'edit',
  );
  const [staffMembersOptions, yearsOptions, yearsQuarters] = oFetch(
    useLegacyGlobal(),
    'staffMembersOptions',
    'yearsOptions',
    'yearsQuarters',
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <div className="boss-modal-window__form">
            <div className="boss-form">
              {!edit && (
                <div className="boss-form__field boss-form__select_role_staff-member">
                  <Field
                    component={BossFormSelectNew}
                    name="staffMemberId"
                    options={staffMembersOptions}
                    parse={value => value}
                    label="Staff member"
                    clearable
                    simpleValue
                    placeholder="Select staff member ..."
                    valueComponent={ImageValue}
                    optionComponent={ImageOption}
                  />
                </div>
              )}
              <Fields
                names={['year', 'quarter']}
                yearsOptions={yearsOptions}
                yearsQuarters={yearsQuarters}
                originalRender={BossFormYearQuarter}
              />
              <Field
                label="Amount"
                parse={value => value}
                name="amountCents"
                unit="£"
                isCurrency
                component={BossFormInput}
              />
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                  type="submit"
                >
                  {!submitting ? buttonText : 'Processing ...'}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
