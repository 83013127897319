import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { Field, reduxForm } from 'redux-form';
import { BossFormTextarea } from '@/components/boss-form';
import Spinner from '@/components/spinner';

const FORM_NAME = 'edit-task-form';

class EditTaskForm extends Component {
  handleSubmit = (values, dispatch, form) => {
    return form.onSubmit(values);
  };
  renderBaseError = error => {
    return (
      <div className="boss-form__error">
        {error.map((errorItem, index) => {
          return (
            <p key={index} className="boss-form__error-text">
              <span className="boss-form__error-line">{errorItem}</span>
            </p>
          );
        })}
      </div>
    );
  };
  render() {
    const [handleSubmit, submitting] = oFetch(this.props, 'handleSubmit', 'submitting');
    const { error } = this.props;
    return (
      <div className="boss-check__header">
        <div className="boss-check__header-info">
          <div className="boss-check__header-group">
            <div className="boss-check__header-field">
              <div className="boss-form">
                {error && this.renderBaseError(error)}
                <Field name="text" placeholder="Type task name here ..." component={BossFormTextarea} />
              </div>
            </div>
          </div>
          {submitting ? (
            <Spinner />
          ) : (
            <div className="boss-check__header-actions">
              <div
                onClick={handleSubmit(this.handleSubmit)}
                className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_confirm"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default reduxForm({
  form: FORM_NAME,
})(EditTaskForm);
