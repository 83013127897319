import React, { useRef, useState } from 'react';
import { ImagePickerFunc, ImagePickerHandle } from '@/components/images-picker-func';
import { BossCropper } from './boss-cropper';

export function PhotoField(props: any) {
  const imagePickerRef = useRef<ImagePickerHandle>(null);
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [croppAvatarUrl, setCroppAvatarUrl] = useState<string>('');
  const {
    input: { value, onChange },
    meta: { error },
  } = props;

  function onAddNewAvatar(fileDataURL: string) {
    setShowCropper(true);
    setTouched(true);
    setCroppAvatarUrl(fileDataURL);
  }

  function openFileDialog() {
    if (imagePickerRef.current) {
      imagePickerRef.current.open();
    }
  }

  function handleCropperCancel() {
    setShowCropper(false);
    setCroppAvatarUrl('');
  }

  function handleCropperSubmit(base64: string | ArrayBuffer | null) {
    if (base64 === null) {
      return;
    }
    onChange(base64);
    setShowCropper(false);
    setCroppAvatarUrl('');
  }

  return (
    <div className="boss-form__field">
      {touched && error && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error}</span>
          </p>
        </div>
      )}
      {showCropper && (
        <BossCropper
          base64Image={croppAvatarUrl}
          onCancel={handleCropperCancel}
          onSubmit={handleCropperSubmit}
        />
      )}
      {!showCropper && (
        <div className="boss-add-avatar-block">
          <div
            className="boss-add-avatar-block__preview"
            style={{ position: 'relative' }}
          >
            <img
              src={value}
              alt="Avatar"
              className="boss-add-avatar-block__preview-image"
            />
          </div>
          <ImagePickerFunc
            ref={imagePickerRef}
            onPicked={onAddNewAvatar}
          />
          <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
            <button
              type="button"
              className="boss-button boss-button_role_file boss-buttons-group_adjust_button"
              onClick={openFileDialog}
            >
              Choose File
            </button>
          </div>
        </div>
      )}

    </div>
  );
}
