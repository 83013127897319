import React from 'react';
import { Field, reduxForm, Fields, change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import BossFormSelect from '@/components/boss-form/boss-form-select';
import BossFormInput from '@/components/boss-form/boss-form-input';
import { BossFormAvatarWithFacial } from '@/components/boss-form';
import { enableMossStaffMember } from '../../actions';

const validate = values => {
  const errors = {};

  return errors;
};

function submission(values, dispatch) {
  return dispatch(enableMossStaffMember(values.toJS()));
}
const Panel = ({ title, children }) => {
  return (
    <div className="boss-board boss-board_context_stack boss-board_role_panel">
      <div className="boss-board__header">
        <h2 className="boss-board__title">{title}</h2>
      </div>
      <div className="boss-board__main">
        <div className="boss-board__manager">{children}</div>
      </div>
    </div>
  );
};

let EnableProfileForm = ({
  handleSubmit,
  submitting,
  mossStaffTypes,
  venues,
  validateAvatarForCreateUrl,
  mossPayRates,
}) => {
  return (
    <form
      onSubmit={handleSubmit(submission)}
      className="boss-form boss-page-main__inner_space_large boss-page-main__inner_opaque"
    >
      <Panel title="Basic Information">
        <Field
          component={BossFormInput}
          required
          name="firstName"
          type="text"
          label="First Name"
        />
        <Field
          component={BossFormInput}
          required
          name="surname"
          type="text"
          label="Surname"
        />
      </Panel>
      <Panel title="Photo">
        <Fields
          names={['validatedAvatarGUID', 'avatar', 'setupForFacialRecognition']}
          markedRetakeAvatar={false}
          disableUpload={false}
          validateAvatarForCreateUrl={validateAvatarForCreateUrl}
          component={BossFormAvatarWithFacial}
        />
      </Panel>
      <Panel title="Venue">
        <Field
          component={BossFormSelect}
          name="mainVenue"
          required
          label="Main Venue"
          optionLabel="name"
          optionValue="id"
          placeholder="Select main venue ..."
          options={venues}
        />
      </Panel>
      <Panel title="Work">
        <Field
          component={BossFormSelect}
          required
          name="mossStaffType"
          label="Staff Type"
          optionLabel="name"
          optionValue="id"
          placeholder="Select staff type ..."
          options={mossStaffTypes}
        />
        <Field
          component={BossFormSelect}
          required
          name="mossPayRate"
          label="Pay Rate"
          optionLabel="description"
          optionValue="id"
          placeholder="Select pay rates ..."
          options={mossPayRates}
        />
        <Field
          component={BossFormInput}
          name="pinCode"
          type="text"
          label="Pin Code"
        />
      </Panel>

      <div className="boss-form__field boss-form__field_justify_center">
        <button
          className="boss-button boss-button_role_submit"
          type="submit"
          disabled={submitting}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

EnableProfileForm = reduxForm({
  form: 'enable-profile-form',
  validate,
  onChange: (values, dispatch, props) => {
    dispatch(change('enable-profile-form', 'mainVenue', values.mainVenue || props.initialValues.mainVenue));
  },
})(EnableProfileForm);

export default connect()(EnableProfileForm);
