import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import sortBy from 'lodash/sortBy';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import utils from '@/lib/utils';
import groupBy from 'lodash/groupBy';
import safeMoment from '@/lib/safe-moment';
import * as constants from '../constants';
const moment = extendMoment(Moment);

const possibleHistoryDayCountValuesLabels = {
  fifty_notes_total_pounds: '£50 > VALUE',
  foreign_fifty_notes_total_pounds: '£50 > FOREIGN',
  twenty_notes_total_pounds: '£20 > VALUE',
  foreign_twenty_notes_total_pounds: '£20 > FOREIGN',
  ten_notes_total_pounds: '£10 > VALUE',
  foreign_ten_notes_total_pounds: '£10 > FOREIGN',
  five_notes_total_pounds: '£5 > VALUE',
  foreign_five_notes_total_pounds: '£5 > FOREIGN',
  change_total_pence: 'Pence > VALUE',
  foreign_change_total_pence: 'Pence > FOREIGN',
  fifty_notes_bundle_count: '£50 Bundle count',
  twenty_notes_bundle_count: '£20 Bundle count',
  ten_notes_bundle_count: '£10 Bundle count',
  five_notes_bundle_count: '£5 Bundle count',
  loose_total_pence: 'Loose total pence',
};

const MODAL_EVENTS_LABELS = {
  [constants.CREATE_EVENT]: 'Created',
  [constants.UPDATE_EVENT]: 'Updated',
};

const DAY_COUNT_EVENT_LABELS = {
  [constants.CREATE_EVENT]: 'Created',
  [constants.UPDATE_EVENT]: 'Last Updated',
};

export function getWeekRange(params) {
  const [weekStart, weekEnd] = oFetch(params, 'weekStart', 'weekEnd');
  const mWeekStart = safeMoment.uiDateParse(weekStart);
  const mWeekEnd = safeMoment.uiDateParse(weekEnd);
  return Array.from(moment.range(mWeekStart, mWeekEnd).by('day'));
}

export function getWeekCountInvalid(weekCount) {
  if (weekCount === null) {
    return false;
  }
  const status = oFetch(weekCount, 'status');
  if (status === 'completed') {
    return false;
  }
  return true;
}

function normalizeDayCountChanges(changes, eventType) {
  const keysToIgnore = ['date', 'id', 'week_start', 'venue_id', 'created_at', 'updated_at'];
  return Object.entries(changes).reduce((acc, entry) => {
    const [key, change] = entry;
    if (keysToIgnore.includes(key)) {
      return acc;
    }
    const [from, to] = change;
    return [
      ...acc,
      {
        key: key,
        label: possibleHistoryDayCountValuesLabels[key],
        from: from,
        to: to,
      },
    ];
  }, []);
}

export const filterSelectors = state => oFetch(state, 'filter');
export const weekDaysCountsSelectors = state => oFetch(state, 'weekDaysCounts');
export const weekCountSelectors = state => oFetch(state, 'weekCount');
export const staticDataSelectors = state => oFetch(state, 'staticData');
export const editModeSelectors = state => oFetch(state, 'editMode');
export const showHistorySelectors = state => oFetch(state, 'showHistory');
export const tabsStatusesSelectors = state => oFetch(state, 'tabsStatuses');

export const weekStartSelector = createSelector([staticDataSelectors], staticData => {
  return oFetch(staticData, 'weekStart');
});

export const weekEndSelector = createSelector([staticDataSelectors], staticData => {
  return oFetch(staticData, 'weekEnd');
});

export const editAbilitySelector = createSelector([staticDataSelectors], staticData => {
  return oFetch(staticData, 'editAbility');
});

export const currentVenueIdSelector = createSelector([staticDataSelectors], staticData => {
  return oFetch(staticData, 'currentVenueId');
});

export const currentVenueNameSelector = createSelector([staticDataSelectors], staticData => {
  return oFetch(staticData, 'currentVenueName');
});

export const getWeekRangeSelector = createSelector([weekStartSelector, weekEndSelector], (weekStart, weekEnd) => {
  return getWeekRange({ weekStart, weekEnd });
});

export const getWeekRangeByUIDateSelector = createSelector([getWeekRangeSelector], weekRange => {
  return weekRange.map(day => day.format(utils.commonDateFormat));
});

export const getWeekRangeByUIDateWithAllSelector = createSelector([getWeekRangeSelector], weekRange => {
  return [...weekRange.map(day => day.format(utils.commonDateFormat)), 'all'];
});

export const getSelectedTab = createSelector([filterSelectors], filter => {
  return oFetch(filter, 'selectedTab');
});

export const getGroupedByDateWeekDaysCounts = createSelector([weekDaysCountsSelectors], weekDaysCounts => {
  return groupBy(weekDaysCounts, wc => oFetch(wc, 'date'));
});

export const getTabsStatuses = createSelector(
  [getWeekRangeByUIDateWithAllSelector, tabsStatusesSelectors, weekCountSelectors],
  (weekRangeByUIDate, tabsStatuses, weekCount) => {
    return weekRangeByUIDate.reduce((acc, uiDay) => {
      const tabStatuses = tabsStatuses[uiDay];
      const invalid = uiDay === 'all' ? getWeekCountInvalid(weekCount) : false;
      if (tabStatuses === undefined) {
        return {
          ...acc,
          [uiDay]: {
            pristine: true,
            invalid: invalid,
          },
        };
      }
      return {
        ...acc,
        [uiDay]: { ...tabStatuses, invalid: invalid },
      };
    }, {});
  },
);

export const getNormalizedWeekCount = createSelector([weekCountSelectors], weekCount => {
  if (weekCount === null) {
    return weekCount;
  }
  const normalizedWeekCountHistory = oFetch(weekCount, 'history').map(historyItem => {
    const [eventType, eventAt, changes] = oFetch(historyItem, 'eventType', 'eventAt', 'changes');
    const formattedEventAt = safeMoment.iso8601Parse(eventAt).format(utils.timeWithFullDayAndMonthFormat);
    const formattedHistoryModalEventType = MODAL_EVENTS_LABELS[eventType];
    const formattedEventType = DAY_COUNT_EVENT_LABELS[eventType];

    return {
      ...historyItem,
      normalizedChanges: normalizeDayCountChanges(changes),
      formattedEventAt,
      formattedHistoryModalEventType,
      formattedEventType,
    };
  });
  return {
    ...weekCount,
    history: normalizedWeekCountHistory,
    lastEventData: normalizedWeekCountHistory[normalizedWeekCountHistory.length - 1],
  };
});

export const getNormalizedDateCount = createSelector(
  [getGroupedByDateWeekDaysCounts, getWeekRangeByUIDateSelector],
  (groupedByDateWeekDaysCounts, weekRangeByUIDate) => {
    return weekRangeByUIDate.reduce((acc, weekDay) => {
      const dayCounts = groupedByDateWeekDaysCounts[weekDay];
      if (dayCounts === undefined) {
        return {
          ...acc,
          [weekDay]: null,
        };
      }
      if (dayCounts.length !== 1) {
        throw new Error('Only one day count should exist for date');
      }
      const dayCount = dayCounts[0];
      const normalizedDayCountHistory = oFetch(dayCount, 'history').map(historyItem => {
        const [eventType, eventAt, changes] = oFetch(historyItem, 'eventType', 'eventAt', 'changes');
        const formattedEventAt = safeMoment.iso8601Parse(eventAt).format(utils.timeWithFullDayAndMonthFormat);
        const formattedHistoryModalEventType = MODAL_EVENTS_LABELS[eventType];
        const formattedEventType = DAY_COUNT_EVENT_LABELS[eventType];

        return {
          ...historyItem,
          normalizedChanges: normalizeDayCountChanges(changes),
          formattedEventAt,
          formattedHistoryModalEventType,
          formattedEventType,
        };
      });
      return {
        ...acc,
        [weekDay]: {
          ...dayCount,
          history: normalizedDayCountHistory,
          lastEventData: normalizedDayCountHistory[normalizedDayCountHistory.length - 1],
        },
      };
    }, {});
  },
);

export const getNormalizedDateCountWithAll = createSelector(
  [getNormalizedDateCount, getNormalizedWeekCount],
  (normalizedDateCount, weekCount) => {
    return { ...normalizedDateCount, all: weekCount };
  },
);

export const editable = createSelector(
  [editModeSelectors, getSelectedTab, getNormalizedDateCount],
  (editMode, selectedTab, normalizedCounts) => {
    const countsExist = normalizedCounts[selectedTab] !== null;
    if (!countsExist) {
      return true;
    }
    return editMode;
  },
);

export const getTotalWeekCounts = createSelector([weekDaysCountsSelectors], weekDaysCounts => {
  return weekDaysCounts.reduce(
    (acc, dayCount) => {
      const [
        fiftyNotesTotalPounds,
        foreignFiftyNotesTotalPounds,
        twentyNotesTotalPounds,
        foreignTwentyNotesTotalPounds,
        tenNotesTotalPounds,
        foreignTenNotesTotalPounds,
        fiveNotesTotalPounds,
        foreignFiveNotesTotalPounds,
        changeTotalPence,
        foreignChangeTotalPence,
      ] = oFetch(
        dayCount,
        'fiftyNotesTotalPounds',
        'foreignFiftyNotesTotalPounds',
        'twentyNotesTotalPounds',
        'foreignTwentyNotesTotalPounds',
        'tenNotesTotalPounds',
        'foreignTenNotesTotalPounds',
        'fiveNotesTotalPounds',
        'foreignFiveNotesTotalPounds',
        'changeTotalPence',
        'foreignChangeTotalPence',
      );
      return {
        fiftyNotesTotalPounds: oFetch(acc, 'fiftyNotesTotalPounds') + fiftyNotesTotalPounds,
        foreignFiftyNotesTotalPounds: oFetch(acc, 'foreignFiftyNotesTotalPounds') + foreignFiftyNotesTotalPounds,
        twentyNotesTotalPounds: oFetch(acc, 'twentyNotesTotalPounds') + twentyNotesTotalPounds,
        foreignTwentyNotesTotalPounds: oFetch(acc, 'foreignTwentyNotesTotalPounds') + foreignTwentyNotesTotalPounds,
        tenNotesTotalPounds: oFetch(acc, 'tenNotesTotalPounds') + tenNotesTotalPounds,
        foreignTenNotesTotalPounds: oFetch(acc, 'foreignTenNotesTotalPounds') + foreignTenNotesTotalPounds,
        fiveNotesTotalPounds: oFetch(acc, 'fiveNotesTotalPounds') + fiveNotesTotalPounds,
        foreignFiveNotesTotalPounds: oFetch(acc, 'foreignFiveNotesTotalPounds') + foreignFiveNotesTotalPounds,
        changeTotalPence: oFetch(acc, 'changeTotalPence') + changeTotalPence,
        foreignChangeTotalPence: oFetch(acc, 'foreignChangeTotalPence') + foreignChangeTotalPence,
      };
    },
    {
      fiftyNotesTotalPounds: 0,
      foreignFiftyNotesTotalPounds: 0,
      twentyNotesTotalPounds: 0,
      foreignTwentyNotesTotalPounds: 0,
      tenNotesTotalPounds: 0,
      foreignTenNotesTotalPounds: 0,
      fiveNotesTotalPounds: 0,
      foreignFiveNotesTotalPounds: 0,
      changeTotalPence: 0,
      foreignChangeTotalPence: 0,
    },
  );
});

export const weekDaysCompleted = createSelector([getNormalizedDateCount], normalizedDateCount => {
  return Object.values(normalizedDateCount).every(dayCount => dayCount !== null);
});

export const getDataForInitialTab = createSelector(
  [weekStartSelector, weekEndSelector, weekDaysCountsSelectors],
  (weekStart, weekEnd, weekDaysCounts) => {
    return {
      weekStart,
      weekEnd,
      weekDaysCounts,
    };
  },
);
