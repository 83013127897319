import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ForSpecialSecurityHoursSecurityPayRateForm } from './for-special-security-hours-security-pay-rate-form';

export function EditForSpecialSecurityHoursSecurityPayRateModal(props) {
  const [onSubmit, customSecurityPayRate] = oFetch(props, 'onSubmit', 'customSecurityPayRate');
  const rawStandardHourlyRateCents = oFetch(customSecurityPayRate, 'standardHourlyRateCents');
  const rawWeekendHourlyRateCents = oFetch(customSecurityPayRate, 'weekendHourlyRateCents');

  const initialValues = {
    id: oFetch(customSecurityPayRate, 'id'),
    name: oFetch(customSecurityPayRate, 'name'),
    standardHourlyRateCents: new BigNumber(rawStandardHourlyRateCents).div(100),
    weekendHourlyRateCents: new BigNumber(rawWeekendHourlyRateCents).div(100),
  };

  return <ForSpecialSecurityHoursSecurityPayRateForm onSubmit={onSubmit} initialValues={initialValues} isEdit={true} />;
}
