import React, { useState } from 'react';
import { Field, Fields, reduxForm } from 'redux-form/immutable';
import StaffMemberInfo from './staff-member-info';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ErrorBlock, BossFormSelect, BossFormCheckbox, BossFormShiftTimeInput } from '@/components/boss-form';
import { ColoredSingleOption, ColoredSingleValue } from '@/components/boss-form/colored-select';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { Collapse } from 'react-collapse';
import { connect } from 'react-redux';
import _ from 'underscore';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import cn from 'classnames';
import { useLegacyGlobal } from '@/components/hooks-components/global';

const scrollOptions = {
  scrollbars: true,
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

const mapStateToProps = state => {
  const rotasJS = oFetch(state.get('page').toJS(), 'rotas');
  return { rotasJS: rotasJS };
};

function GraphDetailsForm(props) {
  const [isRotaShiftDetailsOpened, setIsRotaShiftDetailsOpened] = useState(false);
  const [isHolidaysDetailsOpened, setIsHolidaysDetailsOpened] = useState(false);
  const timeSelectOptions = oFetch(useLegacyGlobal(), 'timeSelectOptions');

  function updateShift(values, dispatch, props) {
    return props.onSubmit(values, dispatch, props, 'update');
  }

  function deleteShift(values, dispatch, props) {
    return props.onSubmit(values, dispatch, props, 'delete');
  }

  function getOrderedWeekRotaShifts(weekRotaShifts) {
    return _(weekRotaShifts).sortBy(item => item.starts_at);
  }

  function toggleShiftDetails() {
    setIsRotaShiftDetailsOpened(!isRotaShiftDetailsOpened);
  }

  function toggleHolidayDetails() {
    setIsHolidaysDetailsOpened(!isHolidaysDetailsOpened);
  }

  const { handleSubmit, staffMember, staffType, submitting, error } = props;
  const rotasJS = oFetch(props, 'rotasJS');
  const staffMemberJS = staffMember.toJS();
  const staffTypeJS = staffType.toJS();
  const holidaysInWeek = oFetch(staffMemberJS, 'holidaysOnWeek');
  const weekHolidays = oFetch(staffMemberJS, 'holidays');
  const hasHolidays = oFetch(weekHolidays, 'length') > 0;
  const hoursInWeek = oFetch(staffMemberJS, 'hoursOnWeek');
  const weekRotaShifts = oFetch(staffMemberJS, 'weekRotaShifts');
  const hasRotaShifts = oFetch(weekRotaShifts, 'length') > 0;
  const shiftDetailsOpen = isRotaShiftDetailsOpened;
  const shiftDetailsSwitchClass = shiftDetailsOpen ? 'boss-summary__switch_state_opened' : '';
  const holidayDetailsOpen = isHolidaysDetailsOpened;
  const holidayDetailsSwitchClass = holidayDetailsOpen ? 'boss-summary__switch_state_opened' : '';
  const isSteward = oFetch(staffMemberJS, 'isSteward');

  return (
    <div>
      <div className="boss-modal-window__group">
        <StaffMemberInfo
          avatarUrl={oFetch(staffMemberJS, 'avatarUrl')}
          fullName={`${oFetch(staffMemberJS, 'firstName')} ${oFetch(staffMemberJS, 'surname')}`}
          staffType={oFetch(staffTypeJS, 'name')}
          staffColor={oFetch(staffTypeJS, 'color')}
          supportsFacialRecognition={oFetch(staffMemberJS, 'supportsFacialRecognition')}
          isSteward={isSteward}
        />
      </div>
      <div className="boss-modal-window__group">
        {error && <ErrorBlock error={error} />}
        <div className="boss-form">
          <Field
            name="venueId"
            component={BossFormSelect}
            options={props.venueTypes}
            clearable={false}
            optionValue="id"
            optionLabel="name"
            multy={false}
            placeholder="Select Venue"
            label="Venue"
            optionComponent={ColoredSingleOption}
            valueComponent={ColoredSingleValue}
          />
          <Fields
            names={['startsAt', 'endsAt']}
            component={BossFormShiftTimeInput}
            startsAtFieldName="startsAt"
            endsAtFieldName="endsAt"
            options={timeSelectOptions}
          />
          <div className="boss-form__row">
            <Field
              name="shiftType"
              label="Standby"
              type="checkbox"
              format={value => value === 'standby'}
              normalize={value => (value ? 'standby' : 'normal')}
              component={BossFormCheckbox}
            />
            {!isSteward && <Field
              name="headDoorman"
              label="Head Doorman"
              component={BossFormCheckbox}
                           />}
          </div>
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              type="button"
              onClick={handleSubmit(updateShift)}
              disabled={submitting}
              className="boss-button boss-button_role_update boss-form__button_adjust_max boss-form__button_adjust_row"
            >
              Update
            </button>
            <button
              type="button"
              onClick={handleSubmit(deleteShift)}
              disabled={submitting}
              className="boss-button boss-button_role_delete boss-form__button_adjust_max"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Shifts</span>
        </p>
        <div className="boss-summary">
          <div className="boss-summary__header">
            <p className="boss-summary__text">
              Hours Scheduled <span className="boss-summary__text-marked">{hoursInWeek}h</span>
            </p>
            {hasRotaShifts && (
              <div
                onClick={toggleShiftDetails}
                className={`boss-summary__switch ${shiftDetailsSwitchClass}`}
              />
            )}
          </div>
          {hasRotaShifts && (
            <Collapse
              isOpened={shiftDetailsOpen}
              style={{ display: 'block' }}
            >
              <div
                style={{ height: '180px' }}
                className="boss-summary__content"
              >
                <ReactIScroll
                  iScroll={iScroll}
                  options={scrollOptions}
                >
                  <div className="boss-summary__content-inner">
                    <ul className="boss-summary__list">
                      {getOrderedWeekRotaShifts(weekRotaShifts).map(rotaShift => {
                        const [id, venueType] = oFetch(rotaShift, 'id', 'venueType');
                        const mStartTime = safeMoment.iso8601Parse(oFetch(rotaShift, 'startsAt'));
                        const mEndTime = safeMoment.iso8601Parse(oFetch(rotaShift, 'endsAt'));

                        const venueName = oFetch(rotaShift, 'venueName');
                        if (venueType === 'security') {
                          const date = oFetch(rotaShift, 'date');
                          const mDate = safeMoment.uiDateParse(date);
                          return (
                            <li
                              key={id}
                              className="boss-summary__item"
                            >
                              <p className="boss-summary__text">
                                {mDate.format('ddd Do MMM')} {mStartTime.format('HH:mm')} to{' '}
                                {mEndTime.format('HH:mm')}
                              </p>
                              <p className="boss-summary__text boss-summary__text_faded">({venueName})</p>
                            </li>
                          );
                        }
                        const rotaId = oFetch(rotaShift, 'rota');
                        const headDoorman = oFetch(rotaShift, 'headDoorman');
                        const rota = rotasJS.find(rota => {
                          return oFetch(rota, 'id') === rotaId;
                        });
                        if (!rota) {
                          throw new Error(`Rota not found with id: ${rotaId}`);
                        }
                        const mDate = safeMoment.uiDateParse(oFetch(rota, 'date'));
                        const shiftItemClassNames = cn('boss-summary__item', {
                          'boss-summary__item-head-doorman': headDoorman,
                        });
                        return (
                          <li
                            key={id}
                            className={shiftItemClassNames}
                          >
                            <p className="boss-summary__text">
                              {mDate.format('ddd Do MMM')} {mStartTime.format('HH:mm')} to{' '}
                              {mEndTime.format('HH:mm')}
                            </p>
                            <p className="boss-summary__text boss-summary__text_faded">({venueName})</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </ReactIScroll>
              </div>
            </Collapse>
          )}
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Holidays</span>
        </p>
        <div className="boss-summary">
          <div className="boss-summary__header">
            <p className="boss-summary__text">
              Holiday Booked <span className="boss-summary__text-marked">{holidaysInWeek}d</span>
            </p>
            {hasHolidays && (
              <div
                onClick={toggleHolidayDetails}
                className={`boss-summary__switch ${holidayDetailsSwitchClass}`}
              />
            )}
          </div>
          {hasHolidays && (
            <Collapse
              isOpened={shiftDetailsOpen}
              style={{ display: 'block' }}
            >
              <div
                className="boss-summary__content"
                style={{ touchAction: 'none' }}
              >
                <div
                  className="boss-summary__content-inner"
                  style={{ transform: 'translate(0px, 0px) translateZ(0px)' }}
                >
                  <ul className="boss-summary__list">
                    {weekHolidays.map(holiday => {
                      const id = oFetch(holiday, 'id');
                      const mStartDate = safeMoment.uiDateParse(oFetch(holiday, 'start_date'));
                      const mEndDate = safeMoment.uiDateParse(oFetch(holiday, 'end_date'));

                      return (
                        <li
                          key={id}
                          className="boss-summary__item"
                        >
                          <p className="boss-summary__text">
                            {mStartDate.format(utils.commonDateFormatCalendar())} -{' '}
                            {mEndDate.format(utils.commonDateFormatCalendar())}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div
                  className="iScrollVerticalScrollbar iScrollLoneScrollbar"
                  style={{
                    position: 'absolute',
                    zIndex: '9999',
                    width: '7px',
                    bottom: '2px',
                    top: '2px',
                    right: '1px',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="iScrollIndicator"
                    style={{
                      boxSizing: 'border-box',
                      position: 'absolute',
                      background: 'rgba(0, 0, 0, 0.5)',
                      border: '1px solid rgba(255, 255, 255, 0.9)',
                      borderRadius: '3px',
                      width: '100%',
                      transitionDuration: '0ms',
                      display: 'none',
                      height: '8px',
                      transform: 'translate(0px, -8px) translateZ(0px)',
                    }}
                  />
                </div>
              </div>
            </Collapse>
          )}
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Preferences</span>
        </p>
        <div className="boss-summary">
          <ul className="boss-summary__list">
            <li className="boss-summary__item boss-summary__item_layout_row boss-summary__item_role_header">
              <p className="boss-summary__text boss-summary__text_context_row">Weekly Hours:</p>
              <p className="boss-summary__text boss-summary__text_marked">
                {oFetch(staffMemberJS, 'preferredHours')}
              </p>
            </li>
            <li className="boss-summary__item boss-summary__item_layout_row">
              <p className="boss-summary__text boss-summary__text_context_row">Day Preferences:</p>
              <p className="boss-summary__text boss-summary__text_marked">
                {oFetch(staffMemberJS, 'preferredDays')}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

GraphDetailsForm.PropTypes = {
  staffMember: ImmutablePropTypes.map.isRequired,
  staffType: ImmutablePropTypes.map.isRequired,
  initialValues: PropTypes.object,
  rotaStatus: PropTypes.string.isRequired,
  venueTypes: PropTypes.array.isRequired,
};

export default reduxForm({
  fields: ['shiftId', 'staffMemberId'],
  form: 'graph-details-form',
})(connect(mapStateToProps)(GraphDetailsForm));
