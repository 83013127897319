import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form';

import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';
import * as types from './types';
import * as requests from './requests';
import * as selectors from './selectors';
import * as c from '../constants';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const submitFailed = createAction(types.SUBMIT_FAILED);
export const storeFlaggedStaffMembers = createAction(types.FLAGGED_STAFF_MEMBERS);
export const storeExistingStaffMembers = createAction(types.EXISTING_STAFF_MEMBERS);
export const reviewFlaggedStaffMember = createAction(types.REVIEW_FLAGGED_STAFF_MEMBER);
export const unReviewFlaggedStaffMember = createAction(types.UN_REVIEW_FLAGGED_STAFF_MEMBER);
export const changeStep = createAction(types.CHANGE_STEP);
export const startFlaggedFetching = createAction(types.START_FLAGGED_FETCHING);
export const endFlaggedFetching = createAction(types.END_FLAGGED_FETCHING);
export const updateAccessories = createAction(types.UPDATE_ACCESSORIES);

function getFields() {
  return Object.entries(c.STEPS_FIELDS)
    .map(entry => {
      const [key, value] = entry;
      return Object.keys(value);
    })
    .flat();
}

export const staffMemberGeneralFieldsValidation = params => (dispatch, getState) => {
  const [values, onSuccess, onFailure, siaBadgeNumberFailure] = oFetch(
    params,
    'values',
    'onSuccess',
    'onFailure',
    'siaBadgeNumberFailure',
  );

  const [
    staffType,
    steward,
    gender,
    siaBadgeNumber,
    siaBadgeExpiryDate,
    firstName,
    surname,
    dateOfBirth,
    email,
    nationalInsuranceNumber,
    postcode,
    phoneNumber,
    linkedMossStaffMemberId,
  ] = oFetch(
    values,
    c.STAFF_TYPE_FIELD_NAME,
    c.STEWARD_FIELD_NAME,
    c.GENDER_FIELD_NAME,
    c.SIA_BADGE_NUMBER_FIELD_NAME,
    c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME,
    c.FIRST_NAME_FIELD_NAME,
    c.SURNAME_FIELD_NAME,
    c.DATE_OF_BIRTH_FIELD_NAME,
    c.EMAIL_FIELD_NAME,
    c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME,
    c.POST_CODE_FIELD_NAME,
    c.PHONE_NUMBER_FIELD_NAME,
    c.LINK_MOSS_STAFF_MEMBER_FIELD_NAME,
  );
  dispatch(startFlaggedFetching());
  return requests.brStaffMemberGeneralFieldsValidation({
    values: {
      staffType,
      steward,
      siaBadgeNumber,
      siaBadgeExpiryDate,
      firstName,
      surname,
      dateOfBirth,
      email,
      nationalInsuranceNumber,
      gender,
      postcode,
      phoneNumber,
      linkedMossStaffMemberId,
    },
    successHandler: params => {
      onSuccess();
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      dispatch(endFlaggedFetching());
      if (statusCode === 422) {
        const errors = oFetch(params, 'errors');
        const reviewStaffMembers = oFetch(params, 'data.reviewStaffMembers');
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            '_error',
            'staffType',
            'steward',
            'siaBadgeNumber',
            'siaBadgeExpiryDate',
            'firstName',
            'surname',
            'dateOfBirth',
            'email',
            'nationalInsuranceNumber',
            'gender',
            'reviewStaffMembers',
            'postcode',
            'phoneNumber',
          ],
        });
        const errorMessages = utils.safeGet(() => errors._error);

        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        if (errors.siaBadgeNumber) {
          siaBadgeNumberFailure();
        }
        dispatch(storeFlaggedStaffMembers(reviewStaffMembers));
        const unReviewedStaffMembers = selectors.unReviewedFlaggedStaffMembers(getState());
        const hasUnreviewedStaffMembers = unReviewedStaffMembers.length > 0;

        if (hasUnreviewedStaffMembers) {
          onFailure(hasUnreviewedStaffMembers);
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const getAccessories = venueId => (dispatch, getState) => {
  return requests.brGetAccessories({
    values: { venueId },
    successHandler: params => {
      const data = oFetch(params, 'data');
      const accessories = oFetch(data, 'accessories');
      dispatch(updateAccessories({ accessories }));
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      dispatch(endFlaggedFetching());
      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'text', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const createStaffMember = (formValues, formDispatch, props) => (dispatch, getState) => {
  const {
    [c.AVATAR_FIELD_NAME]: avatar,
    [c.SETUP_FOR_FACIAL_FIELD_NAME]: setupForFacial,
    ...values
  } = formValues;

  return requests.brCreateStaffMembers({
    values,
    successHandler: params => {
      const staffMemberId = oFetch(params, 'data.staffMemberId');
      window.location.href = appRoutes.staffMemberProfile(staffMemberId);
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');

      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', ...getFields()],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
