import { connect } from 'react-redux';
import Page from '../components/page';
import {
  getStaffTypesWithMossFinanceReports,
  getWeekDates,
  getAllReady,
  getCompletedMossFinanceReportsData,
  mossFinanceReportWeekSelector,
} from '../selectors';
import oFetch from 'o-fetch';
import {
  changePayRateFilter,
  markReportCompleted,
  markReportsCompleted,
  getBankDetailsAction,
  uncompleteMossFinanceReport,
  updateMossFinanceReportNote,
  finishMossFinanceReportWeek,
  undoMossFinanceReportWeek,
} from '../redux/actions';

const mapStateToProps = state => {
  const page = oFetch(state, 'page');
  return {
    date: oFetch(page, 'date'),
    venueId: oFetch(page, 'venueId'),
    startDate: oFetch(page, 'startDate'),
    endDate: oFetch(page, 'endDate'),
    permissions: oFetch(page, 'permissions'),
    mossFinanceReportWeek: mossFinanceReportWeekSelector(state),
    staffTypesWithMossFinanceReports: getStaffTypesWithMossFinanceReports(state),
    weekDates: getWeekDates(state),
    allReady: getAllReady(state),
    completedMossFinanceReportsData: getCompletedMossFinanceReportsData(state),
    filterType: oFetch(page, 'filterType'),
    mossFinanceReportValidationErrors: oFetch(state, 'mossFinanceReportValidationErrors'),
    mossFinanceReportsOverviewData: oFetch(state, 'mossFinanceReportsOverviewData'),
  };
};

const mapDispatchToProps = {
  changePayRateFilter,
  markReportCompleted,
  markReportsCompleted,
  getBankDetailsAction,
  uncompleteMossFinanceReport,
  updateMossFinanceReportNote,
  finishMossFinanceReportWeek,
  undoMossFinanceReportWeek,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
