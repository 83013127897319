import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function RemovedRequests(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [removedRequests, renderRequest] = oFetch(props, 'requests', 'renderRequest');

  const removedRequestsCount = removedRequests.length;

  const buttonClassNames = cn('boss-task__dropdown-switch', {
    'boss-task__dropdown-switch_state_closed': !isOpened,
  });

  function toggleFilter() {
    setIsOpened(!isOpened);
  }

  function renderRequests() {
    return removedRequests.map(request => {
      const requestId = oFetch(request, 'id');
      return React.cloneElement(renderRequest(request), {
        key: requestId,
      });
    });
  }

  return (
    <div className="boss-task__dropdown">
      <div className="boss-task__dropdown-header">
        <button onClick={toggleFilter} type="button" className={buttonClassNames}>
          Removed Requests <b>({removedRequestsCount})</b>
        </button>
      </div>
      <Collapse isOpened={isOpened}>
        <div style={{ paddingBottom: 15 }} />
        <div className="boss-task__dropdown-content" style={{ display: 'block', marginTop: 0 }} />
        {renderRequests()}
      </Collapse>
    </div>
  );
}
