import React, { Component } from 'react';
import oFetch from 'o-fetch';

import loadMore from '@/components/load-more';

class StaffCardList extends Component {
  renderStaffCardList(effectiveStaffMembers, holidayRequests) {
    return effectiveStaffMembers.map(effectiveStaffMember => {
      const jsEffectiveStaffMember = effectiveStaffMember.toJS();
      const effectiveStaffMemberId = oFetch(jsEffectiveStaffMember, 'id');
      const effectiveStaffMemberHolidayRequests = holidayRequests.filter(
        holidayRequest => oFetch(holidayRequest.toJS(), 'effectiveStaffMemberId') === effectiveStaffMemberId
      );
      return React.cloneElement(this.props.itemRenderer(jsEffectiveStaffMember, effectiveStaffMemberHolidayRequests), {
        key: effectiveStaffMemberId.toString(),
      });
    });
  }

  render() {
    const effectiveStaffMembers = oFetch(this.props, 'effectiveStaffMembers');
    const holidayRequests = oFetch(this.props, 'holidayRequests');
    const hasResults = oFetch(effectiveStaffMembers, 'length') > 0;

    return (
      <>
        { !hasResults && <div>No Pending Holiday Requests</div> }
        { hasResults && this.renderStaffCardList(effectiveStaffMembers, holidayRequests) }
      </>
    );
  }
}

export default loadMore(StaffCardList);
