import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from './async-button';

export default function LoadMore(props) {
  const [totalCount, currentCount, onLoadMoreClick] = oFetch(
    props,
    'totalCount',
    'currentCount',
    'onLoadMoreClick',
  );
  const isLoadMoreShown = currentCount < totalCount;

  return (
    <Fragment>
      <p className="purple-board__counter purple-board__counter_position_above">
        Showing <span className="purple-board__counter-bold">{currentCount}</span> of{' '}
        <span className="purple-board__counter-bold">{totalCount}</span>
      </p>
      {isLoadMoreShown && (
        <div className="purple-board__actions">
          <AsyncButton
            onClick={onLoadMoreClick}
            className="purple-button purple-button_size_m purple-button_border_accent-blue purple-button_size_full-xs purple-board__action"
            loadingText="Loading More ..."
          >
            {({ loading, loadingText }) => {
              return (
                <span className="purple-button__text">{loading ? loadingText : 'Load More'}</span>
              );
            }}
          </AsyncButton>
        </div>
      )}
    </Fragment>
  );
}
