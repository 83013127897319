import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export default function AreYouSureModalContent(props) {
  const [onSubmit, onClose] = oFetch(props, 'onSubmit', 'onClose');

  return (
    <Fragment>
      <div className="purple-modal__header purple-modal__header_color_accent-red purple-modal__header_justify_center">
        <h2 className="purple-modal__title">Warning!</h2>
      </div>
      <div className="purple-modal__content purple-modal__content_justify_center">
        <div className="purple-modal__group">
          <img
            src={
              require('../../../../assets/purple/images/illustration-warning-accent-red.svg')
                .default
            }
            alt="warning image"
            className="purple-modal__image"
          />
          <p className="purple-modal__subtitle purple-modal__subtitle_size_l">
            <span className="purple-modal__subtitle-bold">Are you sure?</span>
          </p>
        </div>
      </div>
      <div className="purple-modal__footer">
        <div className="purple-modal__actions">
          <button
            onClick={onClose}
            className="purple-button purple-button_color_accent-red purple-button_icon_close purple-button_size_s purple-modal__action"
            type="button"
          >
            <span className="purple-button__text">Cancel</span>
          </button>
          <button
            onClick={onSubmit}
            className="purple-button purple-button_color_accent-green purple-button_icon_check purple-button_size_s purple-modal__action"
            type="button"
          >
            <span className="purple-button__text">Confirm</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
}
