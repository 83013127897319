import React from 'react';
import oFetch from 'o-fetch';

export function getGlobalProviders<T extends Record<string, any>>() {
  const GlobalContext = React.createContext<T | null>(null);

  const GlobalProvider = function (props: { global: T, children: React.ReactNode }) {
    const global = oFetch(props, 'global');
    const children = oFetch(props, 'children');

    return (
      <GlobalContext.Provider
        value={global}

      >
        {children}
      </GlobalContext.Provider>
    );
  };

  return {
    GlobalProvider,
    GlobalContext,
  };
}