import { http } from '@/lib/request-api';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import { BOSS_APP_TYPE, MOSS_APP_TYPE } from '@/lib/rota-date';
import { appRoutes } from '@/lib/legacy-routes';

export const createHolidayRequest = (values, appType) => {
  const [
    effectiveStaffMember,
    startDate,
    endDate,
    holidayType,
    note
  ] = oFetch(
    values,
    "effectiveStaffMember",
    "startDate",
    "endDate",
    "holidayType",
    "note"
  );
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'value');

  let createHolidayUrl = null;
  if (appType === BOSS_APP_TYPE) {
    createHolidayUrl = appRoutes.staffMemberCreateHolidayOnHolidaysPage({ staffMemberId: effectiveStaffMemberId });
  } else if (appType === MOSS_APP_TYPE) {
    createHolidayUrl = appRoutes.mossStaffMemberCreateHolidayOnHolidaysPage({ mossStaffMemberId: effectiveStaffMemberId });
  } else {
    throw new Error(`unsupported appType supplied: ${appType}`);
  }

  return http({
    successMessage: 'Holiday created successfully',
  }).post(createHolidayUrl, {
    forward: false,
    start_date: startDate ? startDate.format(utils.commonDateFormat) : null,
    end_date: endDate ? endDate.format(utils.commonDateFormat) : null,
    holiday_type: holidayType,
    note: note,
  });
};

export const createHolidayRequestRequest = (values, appType) => {
  const [
    effectiveStaffMember,
    startDate,
    endDate,
    holidayType,
    note
  ] = oFetch(
    values,
    "effectiveStaffMember",
    "startDate",
    "endDate",
    "holidayType",
    "note"
  );
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'value');

  let createHolidayRequestUrl = null;
  if (appType === BOSS_APP_TYPE) {
    createHolidayRequestUrl = `/api/v1/staff_members/${effectiveStaffMemberId}/create_holiday_request`;
  } else if (appType === MOSS_APP_TYPE) {
    createHolidayRequestUrl = `/api/v1/moss_staff_members/${effectiveStaffMemberId}/create_holiday_request`;
  } else {
    throw new Error(`unsupported appType supplied: ${appType}`);
  }

  return http({
    successMessage: 'Request created successfully. Holiday will display on this page when request is accepted',
  }).post(createHolidayRequestUrl, {
    start_date: startDate ? startDate.format(utils.commonDateFormat) : null,
    end_date: endDate ? endDate.format(utils.commonDateFormat) : null,
    holiday_type: holidayType,
    note: note,
  });
};
