import { connect } from 'react-redux';
import Page from '../components/page';
import {
  getStaffTypesWithFinanceReports,
  getWeekDates,
  getAllReady,
  getStaffPayslipsStats,
  getCompletedFinanceReportsData,
  financeReportWeekSelector,
} from '../selectors';
import oFetch from 'o-fetch';
import {
  changePayRateFilter,
  markReportCompleted,
  markReportsCompleted,
  getBankDetailsAction,
  wipeBankDetailsAction,
  uncompleteFinanceReport,
  updateFinanceReportNote,
  finishFinanceReportWeek,
  undoFinanceReportWeek,
} from '../redux/actions';

const mapStateToProps = state => {
  const stateJS = state.toJS();
  const page = oFetch(stateJS, 'page');
  return {
    date: oFetch(page, 'date'),
    venueId: oFetch(page, 'venueId'),
    startDate: oFetch(page, 'startDate'),
    endDate: oFetch(page, 'endDate'),
    permissions: oFetch(page, 'permissions'),
    financeReportWeek: financeReportWeekSelector(state),
    staffTypesWithFinanceReports: getStaffTypesWithFinanceReports(state),
    weekDates: getWeekDates(state),
    allReady: getAllReady(state),
    completedFinanceReportsData: getCompletedFinanceReportsData(state),
    filterType: oFetch(page, 'filterType'),
    paymentWeekPublished: state.get('paymentWeekPublished'),
    financeReportValidationErrors: oFetch(stateJS, 'financeReportValidationErrors'),
    financeReportsOverviewData: oFetch(stateJS, 'financeReportsOverviewData'),
    staffPayslipsStats: getStaffPayslipsStats(state),
  };
};

const mapDispatchToProps = {
  changePayRateFilter,
  markReportCompleted,
  markReportsCompleted,
  getBankDetailsAction,
  wipeBankDetailsAction,
  uncompleteFinanceReport,
  updateFinanceReportNote,
  finishFinanceReportWeek,
  undoFinanceReportWeek,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
