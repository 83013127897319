import React, { useMemo } from 'react';
import _ from 'underscore';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import { renderTooltipInfoHtml, renderTooltipTimeHtml } from './render-tooltip-html';
import RotaOverviewChartInner from './rota-overview-chart-inner';
import { BOSS_APP_TYPE, RotaDate } from '@/lib/rota-date';

const MILLISECONDS_PER_MINUTE = 60 * 1000;

export default function RotaOverviewChart(props) {
  const breakdown = useMemo(() => {
    return props.getBreakdown();
  }, []);

  function getRotaDate() {
    return RotaDate.fromDate({
      dCalendarDate: oFetch(props, 'dDateOfRota'),
      appType: BOSS_APP_TYPE,
    });
  }

  function getSelectionData(breakdown, obj) {
    const seriesName = obj.data.key;
    const index = obj.index;

    const group = getGroupsByName()[seriesName];
    const shifts = breakdown[index].shiftsByGroup[group.clientId];
    return {
      shifts,
      groupId: group.clientId,
      date: breakdown[index].date,
    };
  }

  function getGroupsByName() {
    return _(props.groups).indexBy('name');
  }

  function getChartData(breakdown) {
    const rotaDate = getRotaDate();
    const groups = props.groups;
    const series = [];
    const granularity = props.granularity;

    groups.forEach(function (group) {
      const values = _(breakdown).map(function (item) {
        return {
          value: item.shiftsByGroup[group.clientId].length,
          label: item.date.valueOf(),
        };
      });
      series.push({
        key: group.name,
        values: values,
        color: group.color,
        pointStart: rotaDate.startTime().valueOf(),
        pointInterval: granularity * MILLISECONDS_PER_MINUTE,
      });
    });
    return series;
  }

  return (
    <RotaOverviewChartInner
      chartData={getChartData(breakdown)}
      rotaDate={getRotaDate()}
      tooltipInfoGenerator={(obj) => {
        const groupsById = utils.indexByClientId(props.groups);
        const selectedGroupTitle = obj.series[0].key;
        const date = breakdown[obj.index].date;
        const breakdownAtPoint = _(breakdown).find(point => point.date.valueOf() === date.valueOf());
        const selectedGroupId = _(groupsById).find({ name: selectedGroupTitle }).clientId;

        const html = renderTooltipInfoHtml({
          shiftsByGroupId: breakdownAtPoint.shiftsByGroup,
          selectedGroupId,
          groupsById,
          shiftDates: getSelectionData(breakdown, obj),
        });
        return html;
      }}
      tooltipTimeGenerator={(obj) => {
        const groupsById = utils.indexByClientId(props.groups);
        const selectedGroupTitle = obj.series[0].key;

        const selectedGroupId = _(groupsById).find({ name: selectedGroupTitle }).clientId;

        const html = renderTooltipTimeHtml({
          selectedGroupId,
          groupsById,
          shiftDates: getSelectionData(breakdown, obj),
          staff: props.staff,
        });
        return html;
      }}
      noData={'No Shifts currently rotaed'}
    />
  );
}
