import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function AnswerItemText(props) {
  const answer = oFetch(props, 'answer');
  const [description, correct] = oFetch(answer, 'description', 'correct');
  const checkBoxClassNames = cn('boss-choice-list__option-value', {
    'boss-choice-list__option-value_type_checked': correct,
  });
  return (
    <div className="boss-choice-list__control boss-choice-list__control_type_text">
      <div className="boss-choice-list__option">
        <span className={checkBoxClassNames}>
          <span className="boss-choice-list__option-text">{description}</span>
        </span>
      </div>
    </div>
  );
}
