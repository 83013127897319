import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const tasks = oFetch(action.payload, 'tasks');
      if (tasks === null) {
        return state;
      }
      return tasks;
    },
    [commonTypes.ADD_TASK]: (state, action) => {
      const task = oFetch(action.payload, 'task');
      if (task === null) {
        return state;
      }
      return [...state, task];
    },
    [commonTypes.UPDATE_TASK]: (state, action) => {
      const task = oFetch(action.payload, 'task');
      if (task === null) {
        return state;
      }
      const id = oFetch(task, 'id');
      const stateWithoutTask = state.filter(task => oFetch(task, 'id') !== id);
      return [...stateWithoutTask, task];
    },
    [commonTypes.ADD_PARENT_TASK]: (state, action) => {
      const task = oFetch(action.payload, 'parentTask');
      if (task === null) {
        return state;
      }
      return [...state, task];
    },
    [commonTypes.DELETE_TASK]: (state, action) => {
      const id = oFetch(action.payload, 'id');
      return state.filter(event => oFetch(event, 'id') !== id);
    },
  },
  initialState,
);
