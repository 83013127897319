import React, { forwardRef, useEffect } from 'react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';

// type SwiperRef = HTMLElement & { swiper: SwiperJS; initialize: () => void };

export const Swiper = forwardRef((props: any, ref: any) => {
  const {
    children,
    className,
    ...rest
  } = props;

  useEffect(() => {
    register();

    const params = {
      ...rest
    };

    // Assign it to swiper element
    Object.assign(ref.current || {}, params);

    // initialize swiper
    ref.current?.initialize();
  }, []);

  return (
    <swiper-container
      init="false"
      ref={ref}
      class={className}
    >
      {children}
    </swiper-container>
  );
});

export const SwiperSlide = forwardRef((props: any, ref: any) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <swiper-slide
      {...rest}
      ref={ref}
    >
      {children}
    </swiper-slide>
  );
});
