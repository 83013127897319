import React from 'react';
import { openWarningModal } from '@/components/modals';
import { getOwedHourUIData } from './owed-hours-table';
import oFetch from 'o-fetch';

const OwedHourMobileItem = props => {
  const [
    owedHour,
    effectiveStaffMemberType,
    deleteOwedHours,
    openEditModal,
    isStaffMemberDisabled,
    mossHourTags,
  ] = oFetch(
    props,
    'owedHour',
    'effectiveStaffMemberType',
    'deleteOwedHours',
    'openEditModal',
    'isStaffMemberDisabled',
    'mossHourTags',
  );
  const onEdit = owedHour => {
    openEditModal(owedHour);
  };

  const onDelete = owedHourId => {
    openWarningModal({
      submit: hideModal => deleteOwedHours(owedHourId).then(hideModal),
      config: {
        title: 'Delete owed hours',
        text: 'Are You Sure?',
        buttonText: 'Delete',
      },
    });
  };

  const [
    sFormattedDate,
    sTimes,
    durationHours,
    durationMinutes,
    creator,
    created,
    note,
    editable,
    payslipDate,
    frozen,
    owedHourType,
    owedHourMossHourTags,
  ] = oFetch(
    getOwedHourUIData({ owedHour, effectiveStaffMemberType, mossHourTags }),
    'sFormattedDate',
    'sTimes',
    'durationHours',
    'durationMinutes',
    'creator',
    'created',
    'note',
    'editable',
    'payslipDate',
    'frozen',
    'owedHourType',
    'mossHourTags',
  );

  const duration = `${durationHours} hours ${durationMinutes} minutes`;
  const owedHourId = oFetch(owedHour, 'id');
  const startImageUrl = oFetch(owedHour, 'startsAtCCTVImage');
  const endImageUrl = oFetch(owedHour, 'endsAtCCTVImage');
  const hasCCTVImages = startImageUrl && endImageUrl;
  const isOwedHourNormalType = owedHourType === 'normal';
  const isMoss = effectiveStaffMemberType === 'moss';

  return (
    <div
      className={`boss-check boss-check_role_panel boss-check_page_smp-owed-hours ${
        frozen ? 'boss-check_state_frozen' : ''
      }`}
    >
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__title">{sFormattedDate}</p>
        </div>
      </div>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__text boss-check__text_role_time boss-check__text_marked">
            <span>{sTimes}&nbsp;</span>
            <span>({duration})</span>
          </p>
        </div>
      </div>
      {hasCCTVImages && (
        <div className="boss-check__row boss-check__row_marked">
          <div className="boss-check__cell">
            <p className="boss-check__text boss-check__text_marked">CCTV</p>
            <div className="boss-check__cell-content">
              <div className="boss-overview">
                <div className="boss-overview__previews">
                  <div className="boss-overview__preview boss-overview__preview_role_action">
                    <p className="boss-overview__preview-meta">Start</p>
                    <img
                      className="boss-overview__preview-image boss-overview__preview-image_size_small"
                      src={startImageUrl}
                    />
                  </div>
                  <div className="boss-overview__preview boss-overview__preview_role_action">
                    <p className="boss-overview__preview-meta">End</p>
                    <img
                      className="boss-overview__preview-image boss-overview__preview-image_size_small"
                      src={endImageUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__text boss-check__text_role_user">
            <span className="boss-check__text-label">Created by: </span>
            {creator}
          </p>
          <p className="boss-check__text boss-check__text_role_secondary">{created}</p>
        </div>
      </div>
      {note !== '-' && (
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <div className="boss-check__box">
              <p className="boss-check__text boss-check__text_role_note">{note}</p>
            </div>
          </div>
        </div>
      )}
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__text boss-check__text_role_date">
            <span className="boss-check__text-label">Payslip Date: </span>
            {payslipDate}
          </p>
        </div>
      </div>
      { isMoss && (
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <div className="boss-check__cell-content">
              <div className="boss-tag boss-tag_size_xxs boss-tag_role_key">Hours Tags:</div>
              { owedHourMossHourTags.map((mossHourTag) => {
                return (
                  <div
                    key={`tag:${mossHourTag.id}`}
                    className="boss-tag boss-tag_size_xxs boss-tag_role_label boss-tag_role_hours-tag"
                  >
                    <p className="boss-tag__text">{`${mossHourTag.name}${mossHourTag.isDisabled ? ' (Disabled)' : ''}`}</p>
                  </div>
                );
              }) }
            </div>
          </div>
        </div>
      )}
      {editable && !isStaffMemberDisabled && !frozen && isOwedHourNormalType && (
        <div className="boss-check__row boss-check__row_role_actions">
          <button
            onClick={() => onEdit(owedHour)}
            className="boss-button boss-button_role_update boss-check__action"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(owedHourId)}
            className="boss-button boss-button_role_cancel boss-check__action"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default OwedHourMobileItem;
