import { format } from 'date-fns';
import { dGetTimeAsterisk } from './rota-date';

export { sTimeNeedsAsterisk, sGetTimeAsterisk, timeNeedsAsterisk, dGetTimeAsterisk, mTimeNeedsAsterisk, mGetTimeAsterisk } from './rota-date/time-needs-asterisk';

type BossFormatDTimeArgs = {
  dDate: Date,
  dateFnsDateFormat: string,
  dateFnsTimeFormat: string,
  showTimeAsterisk: boolean,
};

export function bossDateFnsFormat(args: BossFormatDTimeArgs): string {
  const normalizedFormatStringDatePart = args.dateFnsDateFormat === "" ? args.dateFnsDateFormat : `${args.dateFnsDateFormat} `;
  const normalizedFormatStringTimePart = args.dateFnsTimeFormat === "" ? args.dateFnsTimeFormat : `${args.dateFnsTimeFormat}`;
  const normalizedFormatStringAsteriskPart = args.showTimeAsterisk ? dGetTimeAsterisk({ dTime: args.dDate }) : "";

  const normalizedFormatString = `${normalizedFormatStringDatePart || ""}${normalizedFormatStringAsteriskPart || ""}${normalizedFormatStringTimePart || ""}`;

  return format(args.dDate, normalizedFormatString);
}