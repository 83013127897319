import React, { useState, forwardRef } from 'react';
import StaffMemberInfo from './staff-member-info';
import AddShift from './add-shift';
import AddMultipleSubmitButton from './add-multiple-submit-button';
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';

import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';
import { RotaDate } from '@/lib/rota-date';
import { useLegacyGlobal } from '@/components/hooks-components/global';

const scrollOptions = {
  scrollbars: true,
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

function HolidayItem(props) {
  const dStartDate = props.item.get('start_date');
  const dEndDate = props.item.get('end_date');
  const mStartDate = safeMoment.uiDateParse(dStartDate);
  const mEndDate = safeMoment.uiDateParse(dEndDate);

  return (
    <p className="boss-summary__text">{utils.rotaCondensedHolidayDescription({ mStartDate, mEndDate })}</p>
  );
}

function RotaShiftItem(props) {
  const sStartsAt = props.item.get('starts_at');
  const sEndsAt = props.item.get('ends_at');
  const appType = oFetch(useLegacyGlobal(), 'appType');

  const mStartTime = safeMoment.iso8601Parse(sStartsAt);
  const mEndTime = safeMoment.iso8601Parse(sEndsAt);
  // Both times might be on different calendar dates but are
  // on the same rota date
  const rotaDate = RotaDate.fromTime({
    dTime: mStartTime.toDate(),
    appType,
  });
  const mRotaDate = moment(rotaDate.calendarDate());
  const venueName = props.item.get('venueName');
  return (
    <div>
      <p className="boss-summary__text">
        {utils.rotaCondensedShiftDescription({ mDate: mRotaDate, mStartTime, mEndTime })}
      </p>
      <p className="boss-summary__text boss-summary__text_faded">{`(${venueName})`}</p>
    </div>
  );
}

Summary.defaultProps = {
  className: '',
  count: 0,
  suffix: '',
  text: '',
  items: [],
};

function Summary(props) {
  const [isOpen, setIsOpen] = useState(false);
  const itemRenderer = oFetch(props, 'itemRenderer');

  function toggleSummary() {
    setIsOpen(!isOpen);
  }

  function renderItems(items) {
    return (
      <ul className="boss-summary__list">
        {items.map((item, key) => {
          const headDoorman = item.get('head_doorman_hour') === true;
          const itemClassNames = cn('boss-summary__item', { 'boss-summary__item-head-doorman': headDoorman });

          return (
            <li
              key={key}
              className={itemClassNames}
            >
              {itemRenderer(item)}
            </li>
          );
        })}
      </ul>
    );
  }

  const { className, count, suffix, text, items } = props;

  const isOpenClass = isOpen ? 'boss-summary__action_state_opened' : '';
  const isContentClosedClass = isOpen ? '' : 'boss-summary__content_state_closed';

  return (
    <div className={`boss-staff-summary__info-item ${className}`}>
      <div className="boss-summary">
        <div className="boss-summary__header">
          <p className="boss-summary__text">
            <span className="boss-summary__text-marked">
              {count}
              {suffix}
            </span>{' '}
            {text}
          </p>
        </div>
        <div
          style={{ height: '180px' }}
          className={`boss-summary__content ${isContentClosedClass}`}
        >
          <ReactIScroll
            iScroll={iScroll}
            options={scrollOptions}
          >
            <div className="boss-summary__content-inner">{renderItems(items)}</div>
          </ReactIScroll>
        </div>
        {count > 0 && (
          <button
            onClick={toggleSummary}
            className={`boss-summary__action ${isOpenClass}`}
          >
            Details
          </button>
        )}
      </div>
    </div>
  );
}

function StaffMemberItem(props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  function toggleDetails() {
    setIsOpen(!isOpen);
  }

  const staffMember = props.staffMember;
  const staffMemberJS = staffMember.toJS();
  const supportsFacialRecognition = oFetch(staffMemberJS, 'supportsFacialRecognition');
  const staffId = oFetch(staffMemberJS, 'id');
  const avatar = oFetch(staffMemberJS, 'avatar_url');
  const fullName = `${oFetch(staffMemberJS, 'first_name')} ${oFetch(staffMemberJS, 'surname')}`;
  const staffType = props.staffTypes.find(
    staffType => staffType.get('id') === staffMember.get('staff_type'),
  );
  const weeklyHours = staffMember.get('preferred_hours');
  const dayPreferences = staffMember.get('preferred_days');
  const rotaedOnThisWeek = staffMember.get('hoursOnWeek');
  const weekRotaShifts = staffMember.get('weekRotaShifts');
  const holidays = staffMember.get('holidays');
  const isOpenClass = cn('boss-staff-summary__cell boss-staff-summary__cell_role_info', { 'boss-staff-summary__cell_state_opened': isOpen });
  const isOnHoliday = oFetch(staffMemberJS, 'isOnHoliday');
  const isOnSickLeave = oFetch(staffMemberJS, 'isOnSickLeave');
  const canAddShifts = !(isOnHoliday || isOnSickLeave);
  const rowDisableStatusClass = !canAddShifts ? 'boss-staff-summary__row_status_on-holiday' : '';
  const isSteward = oFetch(staffMemberJS, 'isSteward');
  const sRotaDate = oFetch(props, 'sRotaDate');

  return (
    <div className={`boss-staff-summary__row ${rowDisableStatusClass}`}>
      {isOnHoliday && (
        <div className="boss-staff-summary__indicator boss-staff-summary__indicator_status_on-holiday">
          On Holiday
        </div>
      )}
      {isOnSickLeave && (
        <div className="boss-staff-summary__indicator boss-staff-summary__indicator_status_on-sick-leave">
          Sick
        </div>
      )}
      <div className="boss-staff-summary__cell boss-staff-summary__cell_role_name">
        <button
          onClick={toggleDetails}
          className="boss-staff-summary__toggle"
        >
          Details
        </button>
        <StaffMemberInfo
          avatarUrl={avatar}
          fullName={fullName}
          staffType={staffType.get('name')}
          staffColor={staffType.get('color')}
          supportsFacialRecognition={supportsFacialRecognition}
          isSteward={isSteward}
        />
      </div>
      <div className={isOpenClass}>
        <div className="boss-staff-summary__info">
          <Summary
            count={rotaedOnThisWeek}
            suffix={'h'}
            text="rotaed this week"
            className="boss-staff-summary__info-item_role_shifts"
            items={weekRotaShifts}
            itemRenderer={(item) => <RotaShiftItem item={item} />}
          />
          <Summary
            count={staffMember.get('holidaysOnWeek')}
            text="holiday days this week"
            className="boss-staff-summary__info-item_role_holidays"
            items={holidays}
            itemRenderer={(item) => <HolidayItem item={item} />}
          />
          <div className="boss-staff-summary__info-item boss-staff-summary__info-item_role_preferences">
            <p className="boss-staff-summary__label boss-staff-summary__label_adjust_rd">Preferences</p>
            <div className="boss-summary">
              <ul className="boss-summary__list">
                <li className="boss-summary__item boss-summary__item_layout_row boss-summary__item_role_header">
                  <p className="boss-summary__text boss-summary__text_context_row">Weekly Hours:</p>
                  <p className="boss-summary__text boss-summary__text_marked">{weeklyHours}</p>
                </li>
                <li className="boss-summary__item boss-summary__item_layout_row">
                  <p className="boss-summary__text boss-summary__text_context_row">Day Preferences:</p>
                  <p className="boss-summary__text boss-summary__text_marked">{dayPreferences}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-staff-summary__cell boss-staff-summary__cell_role_action">
        {canAddShifts &&
          (props.isMultipleShift ? (
            <AddMultipleSubmitButton staffMemberId={staffId} />
          ) : (
            <button
              onClick={() => props.onAddShiftClick(staffId)}
              className="boss-button boss-button_type_small boss-button_role_add-secondary"
            >
              Add Shift
            </button>
          ))}
        {canAddShifts && props.currentStaffId === staffId && (
          <div
            ref={ref}
            className="boss-popover boss-popover_context_rotas-daily boss-popover_state_opened"
          >
            <div className="boss-popover__inner">
              <AddShift
                sRotaDate={sRotaDate}
                staffMember={staffMember}
                rotaStatus={props.rotaStatus}
                handleAfterAdd={props.handleAfterAdd}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default forwardRef(StaffMemberItem);
