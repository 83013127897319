import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export function NotFoundModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');

  return (
    <Fragment>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          This SIA badge was not found in the database please check the value and try again.
        </span>
      </div>
      <div className="boss-modal-window__actions">
        <button
          type="button"
          onClick={onSubmit}
          className="boss-button boss-button_role_reload boss-modal-window__button"
        >
          Try Again
        </button>
      </div>
    </Fragment>
  );
}
