import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export function NonFronLineModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');

  return (
    <Fragment>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text" style={{ marginBottom: '10px' }}>
          The SIA badge number you supplied cannot be added because of <br />
          <span style={{ fontWeight: '900' }}>Non Front Line Role</span>
        </span>
      </div>
      <div className="boss-modal-window__actions">
        <button type="button" onClick={onSubmit} className="boss-button boss-modal-window__button">
          Continue
        </button>
      </div>
    </Fragment>
  );
}
