import React from 'react';
import PropTypes from 'prop-types';
import VenueSelect from '@/components/security-rota/venue-select';
import RotaFilter from '@/components/security-rota/security-rota-filter';
import RotaDayFilter from './rota-day-filter';
import oFetch from 'o-fetch';

class RotaDailyGraphFilter extends React.Component {
  render() {
    const sRotaDate = oFetch(this.props, 'sRotaDate');

    return (
      <div className="boss-rotas__graphs-filter">
        <div className="boss-form">
          <div className="boss-form__row boss-form__row_justify_space boss-form__row_desktop">
            <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
              <p className="boss-form__label boss-form__label_type_icon-filter">
                <span className="boss-form__label-text">Filter chart</span>
              </p>
              <VenueSelect
                selected={this.props.selectedTypes}
                options={this.props.venueTypes.toJS()}
                onChange={this.props.onVenueChange}
              />
            </div>
            <RotaFilter
              securityShiftRequestsCount={this.props.securityShiftRequestsCount}
              sCurrentRotaDate={sRotaDate}
              page="daily"
            />
          </div>
          <RotaDayFilter sCurrentRotaDate={sRotaDate} />
          <div className="boss-form__row boss-form__row_justify_space boss-form__row_mobile boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
              <p className="boss-form__label boss-form__label_type_icon-filter">
                <span className="boss-form__label-text">Filter chart</span>
              </p>
              <VenueSelect
                selected={this.props.selectedTypes}
                options={this.props.venueTypes.toJS()}
                onChange={this.props.onVenueChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RotaDailyGraphFilter.PropTypes = {
  onVenueChange: PropTypes.func.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  venueTypes: PropTypes.array.isRequired,
  sRotaDate: PropTypes.string.isRequired,
};

export default RotaDailyGraphFilter;
