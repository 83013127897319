import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import checklist from './checklist-reducer';
import staffTypes from './staff-types-reducer';
import assignedStaffTypes from './assigned-staff-types-reducer';

export default combineReducers({
  staffTypes,
  assignedStaffTypes,
  checklist,
  form: formReducer,
});
