import axios from 'axios';
import axiosRetry from 'axios-retry';
import notify from '@/components/global-notification';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
const DEFAULT_GLOBAL_SUCCESS_MESSAGE = 'Action was successful';
const DEFAULT_GLOBAL_ERROR_MESSAGE = 'There was a problem completing your action';
const DEFAULT_TYPE = 'boss';
const DEFAULT_GLOBAL_MESSAGE_INTERVAL_MS = 5000;

function getInstance(auth) {
  if (!auth) {
    return axios.create();
  }

  if (!window.boss.accessToken) {
    throw Error('Access token must be present !!!');
  }

  return axios.create({
    headers: { Authorization: `Token token="${window.boss.accessToken}"` },
  });
}

export function httpWithoutNotify() {
  return getInstance(true);
}

function validateKeys(params) {
  const suppliedKeys = oFetch(params, 'suppliedKeys');
  const supportedKeys = oFetch(params, 'supportedKeys');
  if (!suppliedKeys.every(key => supportedKeys.includes(key))) {
    const unsupportedKeys = suppliedKeys.filter(key => !supportedKeys.includes(key));
    throw new Error('Unsupported error keys supplied: ' + unsupportedKeys.join(', '));
  }
}

export function bossRequestHttp({ auth = true, spinner = false, ...params }) {
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  let loader;

  const instance = getInstance(auth);
  axiosRetry(instance, {
    retries: 3,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
    retryCondition(error) {
      const isResponseReceived = !!error.response;
      if (!isResponseReceived) {
        return true;
      }
      return false;
    }
  });
  const globalNotifications = {
    globalNotifierService: notify,
    showDefaultSuccessMessage: (overrides = {}) => {
      const successMessage = overrides.message || DEFAULT_GLOBAL_SUCCESS_MESSAGE;
      const interval = overrides.interval || DEFAULT_GLOBAL_MESSAGE_INTERVAL_MS;
      const type = overrides.type || DEFAULT_TYPE;
      notify(successMessage, {
        interval: interval,
        status: 'success',
        type: type,
      });
    },
    showDefaultFailureMessage: (overrides = {}) => {
      const errorMessage = overrides.message || DEFAULT_GLOBAL_ERROR_MESSAGE;
      const interval = overrides.interval || DEFAULT_GLOBAL_MESSAGE_INTERVAL_MS;
      const type = overrides.type || DEFAULT_TYPE;

      notify(errorMessage, {
        interval: interval,
        status: 'error',
        type: type,
      });
    },
  };

  const supportedKeyChecker = {
    validateKeys: validateKeys,
  };

  instance.interceptors.request.use(
    config => {
      if (spinner) {
        const bodyFirst = document.body.firstChild;
        loader = document.createElement('div');
        bodyFirst.parentNode.insertBefore(loader, bodyFirst);
        loader.classList.add('loading');
      }
      return config;
    },
    error => {
      if (spinner) {
        loader.remove();
      }
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    // Ok response
    function (httpRequestData) {
      if (spinner) {
        loader.remove();
      }
      if (typeof httpRequestData === 'undefined' || !httpRequestData.status) {
        return Promise.resolve(httpRequestData);
      }
      const statusCode = oFetch(httpRequestData, 'status');
      // Sometimes this will not be present
      const data = httpRequestData.data;

      return successHandler({
        statusCode,
        data,
        globalNotifications,
        supportedKeyChecker,
      });
    },
    // Error Response
    function (httpRequestData) {
      if (spinner) {
        loader.remove();
      }
      if (httpRequestData.response) {
        const response = oFetch(httpRequestData, 'response');
        const statusCode = oFetch(response, 'status');
        // Sometimes this will not be present
        const data = response.data;
        const errors = utils.safeGet(() => data.errors) || null;
        const errorHandled = errorHandler({
          statusCode,
          errors,
          data,
          response,
          globalNotifications,
          supportedKeyChecker,
        });
        if (!errorHandled) {
          // Promise.reject is not working properly on some safari versions
          throw response;
        } else if (utils.isPromise(errorHandled)) {
          return errorHandled;
        } else {
          return Promise.resolve(errorHandled);
        }
      } else if (httpRequestData.request) {
        // The request was made but no response was received
        throw httpRequestData;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw httpRequestData;
      }
    },
  );

  return instance;
}

export function http(messages = {}, interval) {
  const { successMessage, errorMessage, showNotification = true } = messages;

  const instance = getInstance(true);

  if (showNotification) {
    instance.interceptors.response.use(
      function (response) {
        // Do something with response data
        notify(successMessage || 'Action was successful', {
          interval: interval,
          status: 'success',
        });

        return response;
      },
      function (error) {
        // Do something with response error
        const baseErrorFromResponse = utils.safeGet(() => error.response.data.errors.base[0]);
        notify(errorMessage || baseErrorFromResponse || 'There was an error', {
          interval: interval,
          status: 'error',
        });

        throw error;
      },
    );
  }

  return instance;
}
