import React from 'react';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer, SubmissionError } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { modalRedux } from '@/components/modals';

import AccessoryForm from './accessory-form';

class AddAccessory extends React.Component {
  handleSubmit = (values, dispatch) => {
    return this.props.onSubmit(values.toJS(), dispatch).catch(resp => {
      const errors = utils.safeGet(() => resp.response.data.errors);
      if (errors) {
        let base = {};

        if (errors.base) {
          base = {
            _error: errors.base,
          };
        }
        throw new SubmissionError({ ...errors, ...base });
      }
      return resp;
    });
  };

  render() {
    const [iconsOptions, iconsColorsOptions] = oFetch(this.props, 'iconsOptions', 'iconsColorsOptions');
    const initialValues = {
      name: null,
      priceCents: null,
      accessoryType: null,
      userRequestable: false,
      size: [],
      icon: null,
      iconColor: null,
    };

    return (
      <AccessoryForm
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        iconsOptions={iconsOptions}
        iconsColorsOptions={iconsColorsOptions}
        buttonText="Add Accessory"
        buttonClass="boss-button_role_add"
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(AddAccessory);
