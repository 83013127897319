import React from 'react';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';

const scrollOptions = {
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  scrollbars: true,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export default function Notes(props) {
  const notes = oFetch(props, 'notes');

  function renderNotes() {
    return (
      <ul className="boss-notes__list">
        {notes.map(note => {
          const [noteId, noteText] = oFetch(note, 'id', 'note');
          return (
            <li key={noteId} className="boss-notes__item boss-notes__item_role_not-working">
              <a href="#" className="boss-notes__link">
                {noteText}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div className="boss-hrc__notes">
      <div className="boss-notes boss-notes_role_clock-in">
        <h4 className="boss-notes__label">Clock-In Notes</h4>
        <ReactIScroll className="boss-notes__content" iScroll={iScroll} options={scrollOptions}>
          <div className="boss-notes__content-inner">{renderNotes()}</div>
        </ReactIScroll>
      </div>
    </div>
  );
}
