import { createSelector } from 'reselect';
import Immutable from 'immutable';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export const beganChangesSelector = state => {
  let sBeganChanges = state.get('beganChanges');
  return sBeganChanges ? safeMoment.iso8601Parse(sBeganChanges) : null;
};

export const staffMemberIdSelector = state => state.getIn(['profile', 'staffMember', 'id']);
export const createdBySelector = state => state.getIn(['profile', 'staffMember', 'createdBy']);
export const staffMemberFilterSelector = state => state.get('filter');
export const staffMemberHistorySelector = state => state.get('staffMemberHistory');

export const staffMemberCreatedAtSelector = state => {
  return safeMoment.iso8601Parse(
    state.getIn(['profile', 'staffMember', 'created_at'])
  );
};

export const getStaffMemberHistory = createSelector([staffMemberHistorySelector], staffMemberHistory => {
  return staffMemberHistory
    .groupBy(x => x.get('updatedAt'))
    .map((batchedHistory, updatedAt) => {
      const updatedBy = batchedHistory.first().get('updatedBy');
      return Immutable.fromJS({
        updatedAt: safeMoment.iso8601Parse(updatedAt).format(utils.humanDateFormatWithTime()),
        updatedBy,
        keys: batchedHistory.map(history => {
          return Immutable.fromJS({
            key: history.get('key'),
            oldValue: history.get('oldValue'),
            newValue: history.get('newValue'),
          });
        }),
      });
    })
    .toArray();
});
