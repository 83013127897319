import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

import {
  AUTH_METHOD_MANAGER_PHOTO,
  AUTH_METHOD_PHOTO,
  AUTH_METHODS_TITLES,
  EVENT_TITLES,
} from './hours-chart-content';

export default function ActionModal(props) {
  const [currentEventIndex, setCurrentEventIndex] = useState(oFetch(props.event, 'index'));

  function handlePrevClick() {
    setCurrentEventIndex(currentEventIndex - 1);
  }

  function handleNextClick() {
    setCurrentEventIndex(currentEventIndex + 1);
  }

  const events = oFetch(props, 'events');
  const event = events[currentEventIndex];
  const onClose = oFetch(props, 'onClose');
  const eventType = oFetch(event, 'type');
  const eventTypeTitle = EVENT_TITLES[eventType];

  const [authenticationMethod, creatorFullName, s3AttemptUrl, takenAt, isManagerEvent] = oFetch(
    event,
    'authenticationMethod',
    'creatorFullName',
    's3AttemptUrl',
    'takenAt',
    'isManagerEvent',
  );
  const photoOverlayClassNames = cn('boss-overview__overlay', {
    'boss-overview__overlay_role_manager': isManagerEvent,
  });
  const isFirstEvent = currentEventIndex === 0;
  const isLastEvent = currentEventIndex === events.length - 1;
  const eventNumber = currentEventIndex + 1;

  function renderListItem(title, value) {
    return (
      <li className="boss-overview__list-item boss-overview__list-item_role_details">
        <p className="boss-overview__list-key">{title}</p>
        <p className="boss-overview__list-value">{value}</p>
      </li>
    );
  }

  function isPhotoAction(authenticationMethod) {
    return [AUTH_METHOD_PHOTO, AUTH_METHOD_MANAGER_PHOTO].includes(authenticationMethod);
  }

  return (
    <Fragment>
      <a
        onClick={onClose}
        className="boss-modal-window__close-inner"
      />
      <div className="boss-modal-window__header">
        {eventNumber} <span className="boss-modal-window__marked">{eventTypeTitle}</span> Event
        Details
      </div>
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__overview">
          {isPhotoAction(authenticationMethod) && (
            <div className="boss-overview__group">
              <div className={photoOverlayClassNames}>
                <img
                  src={s3AttemptUrl}
                  alt="Photo Preview"
                  className="boss-overview__image"
                />
              </div>
            </div>
          )}
          <div className="boss-overview">
            <ul className="boss-overview__list">
              {renderListItem('Authentication type:', AUTH_METHODS_TITLES[authenticationMethod])}
              {isManagerEvent && renderListItem('Taken by:', creatorFullName)}
              {renderListItem(
                'Taken at:',
                safeMoment
                  .iso8601Parse(takenAt)
                  .format(utils.humanDateFormatWithTimeAndSeconds()),
              )}
            </ul>
          </div>
          <div className="boss-modal-window__actions">
            <button
              onClick={handlePrevClick}
              type="button"
              disabled={isFirstEvent}
              className="boss-button boss-modal-window__button"
            >
              {'< Previous'}
            </button>
            <button
              onClick={handleNextClick}
              type="button"
              disabled={isLastEvent}
              className="boss-button boss-modal-window__button"
            >
              {'Next >'}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
