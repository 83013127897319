import oFetch from 'o-fetch';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import Select from '@/lib/boss-react-select';

export function AppAccessItemField(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [app, onRemove] = oFetch(props, 'app', 'onRemove');
  const [input, meta, options] = oFetch(props, 'input', 'meta', 'options');

  const onChange = oFetch(props, 'input.onChange');

  const [name, value] = oFetch(input, 'name', 'value', 'onBlur');
  const { error, touched, submitError, dirtySinceLastSubmit } = meta;
  const { label, placeholder, disabled } = props;
  const fieldLabel = label === undefined ? name : label;
  const hasError = touched && (error || submitError) && !dirtySinceLastSubmit;
  const selectClassNames = cn('boss-form__select', { 'boss-form__select_state_error': hasError });
  const fieldClassNames = cn('boss-form__field');
  const [appName, appIsMoss, appIsBoss] = oFetch(app, 'title', 'isMoss', 'isBoss');
  const isAllSelected = options.length === value.length;
  const anySelected = value.length > 0;

  useEffect(() => {
    if (hasError) {
      setIsOpen(true);
    }
  }, [hasError]);

  const venueNames = options
    .filter(option => (value || []).includes(oFetch(option, 'value')))
    .map(option => oFetch(option, 'label'))
    .join(', ');

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function handleChange(value) {
    const arrayValues = value
      .split(',')
      .filter(Boolean)
      .map(id => parseInt(id));
    return onChange(arrayValues);
  }

  function handleClearAll() {
    onChange([]);
  }

  function handleSelectAll() {
    const allValues = options.map(option => oFetch(option, 'value'));
    onChange(allValues);
  }

  return (
    <div className="boss-check_role_panel boss-check_page_smp-linked-user-app-access">
      <div className="boss-check__header">
        <div className="boss-check__mark boss-check__mark_position_left" />
        <h3 className="boss-check__title">{appName}</h3>
        <div className="boss-check__header-info">
          {!isOpen && (
            <div className="boss-check__header-meta">
              <div className="boss-check__header-meta-item boss-check__header-meta-item_role_key">
                <p className="boss-check__text boss-check__text_marked boss-check__text_role_location boss-check__text_adjust_nowrap">
                  Venues:
                </p>
              </div>
              {venueNames && (
                <div className="boss-check__header-meta-item">
                  <button
                    type="button"
                    className="boss-check__text boss-check__text_role_action"
                  >
                    <span className="boss-check__text-light">{venueNames}</span>
                  </button>
                </div>
              )}
              <div className="boss-check__header-meta-item boss-check__header-meta-item_role_actions">
                <button
                  type="button"
                  onClick={() => open()}
                  className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_edit"
                />
              </div>
            </div>
          )}
        </div>
        <div className="boss-check__header-actions">
          {!isOpen && (
            <button
              type="button"
              onClick={onRemove}
              className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove boss-check__header-action"
            />
          )}
          {isOpen && (
            <button
              type="button"
              onClick={() => close()}
              className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove boss-check__header-action open"
            />
          )}
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="boss-check__group boss-check__group_marked">
          <div className="boss-check__group-content">
            <div className={fieldClassNames}>
              <div className="boss-form__label-group">
                <label
                  htmlFor={name}
                  className="boss-form__label"
                >
                  <span className="boss-form__label-text">{fieldLabel}</span>
                </label>
                <div className="boss-form__label-actions">
                  {anySelected && (
                    <button
                      onClick={handleClearAll}
                      type="button"
                      className="boss-action boss-action_role_form boss-form__label-action"
                    >
                      <span className="boss-action__text">Clear All</span>
                    </button>
                  )}
                  {!isAllSelected && (
                    <button
                      onClick={handleSelectAll}
                      type="button"
                      className="boss-action boss-action_role_form boss-form__label-action"
                    >
                      <span className="boss-action__text">Add All</span>
                    </button>
                  )}
                </div>
              </div>
              <div className={selectClassNames}>
                <Select
                  name={name}
                  value={value}
                  onChange={handleChange}
                  clearable
                  simpleValue
                  multi
                  placeholder={placeholder}
                  options={options}
                  disabled={disabled}
                />
              </div>
              {hasError && (
                <div className="boss-form__error">
                  <p className="boss-form__error-text">
                    {(error || submitError).map((errorItem, index) => {
                      return (
                        <span
                          key={index}
                          className="boss-form__error-line"
                        >
                          {errorItem}
                        </span>
                      );
                    })}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
