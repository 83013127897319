import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IndexFilterParams } from '../types';
import debounce from 'lodash/debounce';
import { v1 as uuid } from 'uuid';
import { mossHourTagsIndex as importedApiMossHourTagsIndex } from '@/lib/api-routes';
const apiRoutes = {
  mossHourTagsIndex: importedApiMossHourTagsIndex,
} as const;
import { mossHourTagsIndex as importedAppMossHourTagsIndex } from '@/lib/app-routes';
const appRoutes = {
  mossHourTagsIndex: importedAppMossHourTagsIndex,
} as const;
import { applyBossRoute } from '@/lib/apply-boss-route';
import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';

export function MossHourTagsFilter(props: IndexFilterParams) {
  const searchQuery = props.searchQuery;
  const normalizedSearchQuery = props.normalizedSearchQuery;
  const currentNormalizedSearchQueryRef = useRef<string>(normalizedSearchQuery);
  currentNormalizedSearchQueryRef.current = normalizedSearchQuery;

  // effectiveSubmitting is used to track if any non cancelled request is in progress
  const [activeRequestId, setActiveRequestId] = useState<string | null>(null);
  const effectiveSubmitting = activeRequestId !== null;
  const activeRequestIdRef = useRef<string | null>();

  //allows callback to access the most recent requestId
  activeRequestIdRef.current = activeRequestId;

  useEffect(() => {
    if (props.forceUpdate) {
      //cancel any requests in progress if the search query changes
      if (effectiveSubmitting) {
        setActiveRequestId(null);
      }

      debouncedQueryUpdate();
    }
  }, [props.forceUpdate]);


  useEffect(() => {
    //cancel any requests in progress if the search query changes
    if (effectiveSubmitting) {
      setActiveRequestId(null);
    }

    // props.queryString is the data already being displayed on the page
    if (normalizedSearchQuery !== props.queryString) {
      debouncedQueryUpdate();
    }
  }, [searchQuery]);

  const debouncedQueryUpdate = useMemo(() => {
    return debounce(
      (): void => {
        handleSubmit();
      },
      500,
    );
  }, []);

  const handleSubmit = (): void => {
    const currentNormalizedSearchQuery = currentNormalizedSearchQueryRef.current;

    const requestId = uuid();
    setActiveRequestId(requestId);

    const route = apiRoutes.mossHourTagsIndex;

    const bossRequestInstance = bossRequestHttp({
      errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
        const globalNotifications = params.globalNotifications;
        globalNotifications.showDefaultFailureMessage();

        return false;
      },
      successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {


        if (requestId === activeRequestIdRef.current) {
          setActiveRequestId(null);

          const rawData = oFetch(params, 'data');
          const data = route.$SuccessData.parse(rawData);

          const newUrl = appRoutes.mossHourTagsIndex({
            mode: props.mode,
            searchQuery: currentNormalizedSearchQuery
          });
          window.history.pushState(
            { path: newUrl },
            "",
            newUrl
          );

          props.setMossHourTagAppData(data);
        } else {
          //request has been cancelled by new data throw result away
        }
      },
    });

    applyBossRoute({
      route,
      bossHttpRequest: bossRequestInstance,
      callParams: {},
      pathParams: {
        searchQuery: currentNormalizedSearchQuery,
        mode: props.mode,
      },
    });
  };

  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <div className="boss-form__field boss-form__field_layout_max">
          <label className="boss-form__label">
            <input
              className="boss-form__input"
              disabled={props.tagCreationInProgress}
              onChange={((args: React.ChangeEvent<HTMLInputElement>): void => {
                props.setSearchQuery(args.target.value);
              })}
              value={searchQuery}
            />

            { effectiveSubmitting && <span>
              <div className="boss-indicator__icon boss-indicator__icon_spinner" />
            </span> }
          </label>
        </div>
      </div>
    </div>
  );
}