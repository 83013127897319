import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

const accessoriesPermissionsSelector = state =>
  state.getIn(['profile', 'permissionsData', 'accessoriesTab', 'accessory_requests']);
export const canCreateAccessoryRequest = state =>
  state.getIn(['profile', 'permissionsData', 'accessoriesTab', 'canCreateAccessoryRequest']);
export const accessoriesSelector = state => state.getIn(['accessoriesPage', 'accessories']);
export const getAccessoryRequestPermission = createSelector(
  accessoriesPermissionsSelector,
  accessoryRequestsPermissions => accessoryRequestId => {
    return accessoryRequestsPermissions.get(accessoryRequestId.toString());
  },
);

export const getAccessoriesOptions = createSelector([accessoriesSelector], accessories => {
  return accessories.toJS().map(accessory => {
    return {
      value: oFetch(accessory, 'id'),
      label: oFetch(accessory, 'name'),
      icon: oFetch(accessory, 'icon'),
      color: oFetch(accessory, 'iconColor'),
      accessoryType: oFetch(accessory, 'accessoryType'),
      size: oFetch(accessory, 'size'),
    };
  });
});
