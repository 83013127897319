import Trix from 'trix';
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';
import oFetch from 'o-fetch';
import { v4 as uuidv4 } from 'uuid';

import { FileList, UPLOADING_STATE, UPLOADED_STATE } from './file-list';

Trix.config.blockAttributes.heading1 = {
  tagName: 'h1',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

BossFormTrixRteFieldWithAttachments.defaultProps = {
  label: null,
};

function normalizeFiles(files) {
  return files.reduce((acc, file) => {
    const guid = uuidv4();
    acc[guid] = {
      id: null,
      name: file.name,
      url: null,
      file: file,
      state: UPLOADING_STATE,
    };
    return acc;
  }, {});
}

BossFormTrixRteFieldWithAttachments.defaultProps = {
  files: {},
  softDelete: true,
  onDelete: null,
};

export function BossFormTrixRteFieldWithAttachments(props) {
  const [descriptionName, fileIdsName, files, softDelete] = oFetch(
    props,
    'descriptionName',
    'fileIdsName',
    'files',
    'softDelete',
  );
  const [uploadingFiles, setUploadingFiles] = useState(files);
  const [selectedFile, setSelectedFile] = useState(null);

  const [
    descriptionOnChange,
    descriptionValue,
    descriptionMeta,
    fileIdsOnChange,
    fileIdsValue,
    fileIdsMeta,
    label,
    onUpload,
    onDelete,
  ] = oFetch(
    props,
    `${descriptionName}.input.onChange`,
    `${descriptionName}.input.value`,
    `${descriptionName}.meta`,
    `${fileIdsName}.input.onChange`,
    `${fileIdsName}.input.value`,
    `${fileIdsName}.meta`,
    'label',
    'onUpload',
    'onDelete',
  );
  const [fileIds, setFileIds] = useState(fileIdsValue);

  if (!softDelete && !onDelete) {
    throw new Error('onDelete function must exist');
  }

  useEffect(() => {
    fileIdsOnChange(fileIds);
  }, [fileIds]);

  const { submitError, touched } = descriptionMeta;

  const hasError = touched && submitError;

  function handleChange(event, newValue) {
    descriptionOnChange(newValue || '');
  }

  function handleFileAccepted(event) {
    event.preventDefault();
    const { file } = event;
    const normalizedFiles = normalizeFiles([file]);
    addFilesToState(normalizedFiles);

    Object.entries(normalizedFiles).forEach(fileEntry => {
      const [guid, fileObject] = fileEntry;
      const file = oFetch(fileObject, 'file');
      const reader = new window.FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        const formData = new window.FormData();
        formData.append('upload[file]', file, file.name);

        onUpload({
          values: formData,
          onSuccess(data) {
            const [id, url, isImage] = oFetch(data, 'id', 'url', 'isImage');
            addFileIdToState(id);
            updateUploadedFileState(guid, {
              id: id,
              url: url,
              state: UPLOADED_STATE,
              isImage,
            });
          },
          onFailure(params) {
            const statusCode = oFetch(params, 'statusCode');
            const errors = oFetch(params, 'errors');
            const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

            if (statusCode === 422 && errors) {
              supportedKeyChecker.validateKeys({
                suppliedKeys: Object.keys(errors),
                supportedKeys: ['file'],
              });
              return errors;
            }
          },
        });
      };
      reader.readAsBinaryString(file);
    });
  }

  function updateUploadedFileState(updatedGuid, params) {
    const [id, url, state, isImage] = oFetch(params, 'id', 'url', 'state', 'isImage');

    setUploadingFiles(prevState => {
      return Object.entries(prevState).reduce((acc, prevFileEntry) => {
        const [guid, prevFileObject] = prevFileEntry;
        if (guid === updatedGuid) {
          acc[id] = {
            ...prevFileObject,
            id: id,
            url: url,
            state: state,
            isImage,
          };
        } else {
          acc[guid] = prevFileObject;
        }
        return acc;
      }, {});
    });
  }

  function handleDeleteFile(file) {
    const fileId = oFetch(file, 'id');
    if (softDelete) {
      setSelectedFile(null);
      deleteFileIdFromState(fileId);
      deleteFileFromState(file);
    } else {
      return onDelete({
        values: {
          id: oFetch(file, 'id'),
        },
        onSuccess() {
          setSelectedFile(null);
          deleteFileIdFromState(fileId);

          deleteFileFromState(file);
        },
        onFailure() {
          setSelectedFile(null);
          deleteFileIdFromState(fileId);
          deleteFileFromState(file);
          return true;
        },
      });
    }
  }

  function deleteFileFromState(file) {
    setUploadingFiles(prevState => {
      return Object.entries(prevState).reduce((acc, prevFileEntry) => {
        const [id, prevFile] = prevFileEntry;
        if (id !== oFetch(file, 'id').toString()) {
          acc[id] = prevFile;
        }
        return acc;
      }, {});
    });
  }

  function addFilesToState(normalizedFiles) {
    setUploadingFiles(prevState => {
      return { ...prevState, ...normalizedFiles };
    });
  }

  function addFileIdToState(fileId) {
    setFileIds(prevFileIds => {
      return [...prevFileIds, fileId];
    });
  }

  function deleteFileIdFromState(fileId) {
    setFileIds(prevFileIds => {
      return prevFileIds.filter(fId => fId !== fileId);
    });
  }

  const containerClassNames = cn('wysiwyg-editor', { 'wysiwyg-editor_state_error': hasError });

  return (
    <>
      {label && (
        <p className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </p>
      )}
      <div className={containerClassNames}>
        <ReactTrixRTEToolbar
          toolbarId="react-trix-rte-editor"
          toolbarActions={[
            'bold',
            'italic',
            'strike',
            'link',
            'quote',
            'code',
            'bullet',
            'number',
            'attachFiles',
          ]}
        />
        <ReactTrixRTEInput
          toolbarId="react-trix-rte-editor"
          defaultValue={descriptionValue}
          onChange={handleChange}
          onInitialize={event => {
            const { toolbarElement } = event.target;
            const blockTools = toolbarElement.querySelector('[data-trix-button-group=block-tools]');
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading1" title="Heading 1" tabindex="-1" data-trix-active="">H1</button>
              `;
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
              `;
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
              `;
          }}
          onFileAccepted={handleFileAccepted}
        />
      </div>
      {hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            {submitError.map((errorItem, index) => {
              return (
                <span
                  key={index}
                  className="boss-form__error-line"
                >
                  {errorItem}
                </span>
              );
            })}
          </p>
        </div>
      )}
      <FileList
        files={uploadingFiles}
        selectedFile={selectedFile}
        onFileSelect={file => setSelectedFile(file)}
        onDeleteFile={handleDeleteFile}
      />
    </>
  );
}
