import React, { useMemo, Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { useConfirmationModal } from '@/components/hooks-components/modals';

import * as constants from '../constants';

export function IssueItem(props) {
  const [processing, setProcessing] = useState(false);
  const { openWarningModal } = useConfirmationModal();
  const [issue, onEditClick, onActivateClick, onPendingClick, onDisableClick] = oFetch(
    props,
    'issue',
    'onEditClick',
    'onActivateClick',
    'onPendingClick',
    'onDisableClick',
  );
  const [
    id,
    description,
    createdByUserName,
    createdAt,
    date,
    state,
    level,
    disabledAt,
    disabledByUserName,
    permissions,
  ] = oFetch(
    issue,
    'id',
    'description',
    'createdByUserName',
    'createdAt',
    'date',
    'state',
    'level',
    'disabledAt',
    'disabledByUserName',
    'permissions',
  );

  const [canActivate, canDisable, canSetPending, canUpdate] = oFetch(
    permissions,
    'canActivate',
    'canDisable',
    'canSetPending',
    'canUpdate',
  );
  const isDisabled = disabledAt !== null;
  const isActiveState = state === constants.ACTIVE_STATE;
  const isPendingState = state === constants.PENDING_STATE;

  const levelClassNames = cn('card-holder m-b-30', {
    'card-holder__priority-high': level === constants.RED_LEVEL_KEY,
    'card-holder__priority-low': level === constants.AMBER_LEVEL_KEY,
    'card-holder__deleted': isDisabled,
  });

  const combinedButtonClassNames = cn('combined-button', {
    active: isActiveState,
    pending: isPendingState,
    processing: processing,
  });

  const combinedButtonFirstClassNames = cn(
    'boss-button boss-button_type_small boss-button_type_no-behavior',
    {
      'button--loading': processing,
    },
  );
  const canNotEditButtonClassNames = cn('boss-button boss-button_type_small boss-button_type_no-behavior', {
    active: isActiveState,
    pending: isPendingState,
  });
  const combinedButtonLastClassNames = cn(
    'boss-button boss-button_role_edit boss-button_type_small boss-check__header-action',
    {
      'boss-button_type_no-behavior': processing,
    },
  );

  const mCreatedAt = safeMoment.iso8601Parse(createdAt);
  const formattedCreatedAt = mCreatedAt.format(utils.commonDateFormatWithTime());
  const buttonTitle = useMemo(() => {
    if (isActiveState) {
      return 'Active';
    }
    if (isPendingState) {
      return 'Pending';
    }
    if (isDisabled) {
      return null;
    }
    throw new Error('Unknown site issue state');
  }, [state]);

  async function handleActivateClick() {
    setProcessing(true);
    await onActivateClick({
      id: id,
    });
    setProcessing(false);
  }

  async function handlePendingClick() {
    setProcessing(true);
    await onPendingClick({
      id: id,
    });
    setProcessing(false);
  }

  async function handleDisableClick() {
    setProcessing(true);
    await openWarningModal({
      onSubmit(closeModal) {
        closeModal();
        return onDisableClick({
          id: id,
        });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Disable',
      },
      config: {
        title: 'DISABLE SITE ISSUE',
      },
    });
    setProcessing(false);
  }

  function renderTooltipContent() {
    if (isActiveState && canSetPending) {
      return (
        <div className="boss-tooltip-portal__actions boss-tooltip-portal__actions_role_column">
          <button
            onClick={handlePendingClick}
            disabled={processing}
            className="boss-button boss-button_role_undo boss-tooltip-portal__action"
          >
            {processing ? 'Processing ...' : 'Pending'}
          </button>
        </div>
      );
    }
    if (isPendingState && canActivate) {
      return (
        <div className="boss-tooltip-portal__actions boss-tooltip-portal__actions_role_column">
          <button
            onClick={handleActivateClick}
            disabled={processing}
            className="boss-button boss-button_role_restore boss-tooltip-portal__action"
          >
            {processing ? 'Processing ...' : 'Activate'}
          </button>
        </div>
      );
    }
    return null;
  }

  function renderButtonsGroup() {
    if (isDisabled) {
      return null;
    }
    return (
      <Fragment>
        {!isActiveState && canUpdate && (
          <button
            onClick={() => onEditClick({ siteIssue: issue })}
            disabled={processing}
            className="boss-button boss-button_role_edit boss-button_type_small"
          >
            Edit
          </button>
        )}
        {canDisable && (
          <button
            onClick={handleDisableClick}
            disabled={processing}
            className="boss-button boss-button_role_cancel boss-button_type_small"
          >
            Disable
          </button>
        )}
        {(canActivate || canSetPending) && (
          <div className={combinedButtonClassNames}>
            <button className={combinedButtonFirstClassNames}>{buttonTitle}</button>
            <Tooltip
              theme="light"
              position="bottom"
              hideOnClick={true}
              interactive
              trigger="click"
              disabled={processing}
              arrow
              html={renderTooltipContent()}
            >
              <button className={combinedButtonLastClassNames} />
            </Tooltip>
          </div>
        )}
        {!canActivate && !canSetPending && (
          <button className={canNotEditButtonClassNames}>{buttonTitle}</button>
        )}
      </Fragment>
    );
  }

  function renderDisabledBlock() {
    const mDisabledAt = safeMoment.iso8601Parse(disabledAt);
    const formattedDisabledAt = mDisabledAt.format(utils.commonDateFormatWithTime());
    return (
      <Fragment>
        <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_user boss-check__text_role_edit-action float-left m-r-20">
          <strong>Disabled by: </strong>
          {disabledByUserName}
        </p>
        <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_date float-left">
          <strong>Disabled at: </strong>
          {formattedDisabledAt}
        </p>
      </Fragment>
    );
  }

  return (
    <div className={levelClassNames}>
      <div className="boss-check boss-check_role_board boss-check_page_ops-diary-index">
        <div className="boss-check__header">
          <div className="boss-check__header-info">
            <div className="boss-check__header-group">
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_user boss-check__text_role_edit-action">
                    {createdByUserName}
                  </p>
                </div>

                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_date">
                    {date}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-check__header-status" />

          <div className="buttons-group">{renderButtonsGroup()}</div>
        </div>
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <div className="boss-check__box">
              <p className="boss-check__text">{description}</p>
            </div>
            <div className="boss-check__created-on">
              {isDisabled && renderDisabledBlock()}
              <p className="boss-check__created-on-text">
                <strong>Created on</strong> {formattedCreatedAt}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
