import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import BossFormCalendar from '@/components/boss-form/boss-form-calendar';
import BossFormTimeSelect from '@/components/boss-form/boss-form-time-select-simple';
import BossFormTextArea from '@/components/boss-form/boss-form-textarea';
import { BOSS_APP_TYPE, RotaDate } from '@/lib/rota-date';

const onSubmit = (values, dispatch, props) => {
  const jsValues = values.toJS();
  const mDate = oFetch(jsValues, 'date');
  const rotaDate = RotaDate.mFromDate({
    mCalendarDate: mDate,
    appType: BOSS_APP_TYPE,
  });
  const startsAtOffsetMinutes = oFetch(jsValues, 'startsAt');
  const endsAtOffsetMinutes = oFetch(jsValues, 'endsAt');
  const mStartsAt = rotaDate.mStartTime();
  mStartsAt.add(startsAtOffsetMinutes, 'minutes');
  const mEndsAt = rotaDate.mStartTime();
  mEndsAt.add(endsAtOffsetMinutes, 'minutes');

  return props.onFormSubmit({ ...jsValues, startsAt: mStartsAt, endsAt: mEndsAt }, dispatch).catch(resp => {
    const errors = resp.response.data.errors;
    if (errors) {
      let base = {};

      if (errors.base) {
        base = {
          _error: errors.base,
        };
      }
      throw new SubmissionError({ ...errors, ...base });
    }
    return resp;
  });
};

const renderBaseError = errors => {
  return (
    <div className="boss-modal-window__alert" style={{ width: '100%' }}>
      <div className="boss-alert boss-alert_role_area boss-alert_context_above">
        {errors.map((error, index) => (
          <p key={index.toString()} className="boss-alert__text">
            {error}
          </p>
        ))}
      </div>
    </div>
  );
};

class SecurityShiftRequestForm extends React.Component {
  render() {
    const { error, startsAt, endsAt } = this.props;
    const appType = oFetch(this.props, 'appType');
    const mDate = this.props.date;

    return (
      <form onSubmit={this.props.handleSubmit} className="boss-modal-window__form boss-form">
        {error && renderBaseError(error)}
        <Field name="date" label="Date" required component={BossFormCalendar} />
        <div className="boss-form__row">
          <div className="boss-form__group boss-form__group_layout_max">
            <div className="boss-form__row boss-form__row_position_last">
              <div className="boss-form__field boss-form__field_layout_half">
                <Field
                  name="startsAt"
                  label="Starts At"
                  //dDate is wrong here expects moment
                  dDate={mDate}
                  interval={30}
                  appType={appType}
                  required
                  disabled={!mDate}
                  component={BossFormTimeSelect}
                />
              </div>
              <div className="boss-form__field boss-form__field_layout_half">
                <Field
                  name="endsAt"
                  label="Ends At"
                  //dDate is wrong here expects moment
                  dDate={mDate}
                  interval={30}
                  appType={appType}
                  required
                  disabled={!mDate}
                  component={BossFormTimeSelect}
                />
              </div>
            </div>
          </div>
        </div>
        <Field name="note" label="Note" component={BossFormTextArea} />
        <div className="boss-form__field">
          <button
            disabled={
              this.props.submitting || (!startsAt && startsAt !== 0) || (!endsAt && endsAt !== 0)
            }
            className={`boss-button boss-form__submit ${this.props.buttonClass}`}
          >
            {this.props.buttonText}
          </button>
        </div>
      </form>
    );
  }
}

SecurityShiftRequestForm.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
};

SecurityShiftRequestForm.defaultProps = {
  buttonClass: 'boss-button_role_add',
  buttonText: 'Add New',
};

const selector = formValueSelector('security-shift-request-form');

const ConnectedSecurityShiftRequestForm = connect(state => {
  return {
    date: selector(state, 'date'),
    startsAt: selector(state, 'startsAt'),
    endsAt: selector(state, 'endsAt'),
  };
})(SecurityShiftRequestForm);

export default reduxForm({
  fields: ['assignedRotaShift'],
  onSubmit: onSubmit,
  form: 'security-shift-request-form',
})(ConnectedSecurityShiftRequestForm);
