import React from 'react';
import oFetch from 'o-fetch';

export default function ErrorsSection(props) {
  const [title, children] = oFetch(props, 'title', 'children');

  return (
    <section className="boss-board boss-board_context_stack">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_role_errors boss-board__title_size_medium">
          {title}
        </h2>
      </header>
      <div className="boss-board__inner">{children}</div>
    </section>
  );
}
