import React from 'react';

export default function SimpleDashboard(props) {
  const { renderTitle, renderButtons } = props;
  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_quiz-category">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{renderTitle && renderTitle()}</h1>
            {renderButtons && renderButtons()}
          </div>
        </div>
      </div>
    </div>
  );
}
