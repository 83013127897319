import React, { Fragment } from 'react';
import { Tooltip } from 'react-tippy';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { SiteIssueTooltipContent } from './tooltip-content';

export function SiteIssueCell(props) {
  const [kpiItemData, timeClassName] = oFetch(props, 'kpiItemData', 'timeClassName');
  const [title, leftValue, leftData, leftDescription, rightValue, rightData, rightDescription] = oFetch(
    kpiItemData,
    'title',
    'leftValue',
    'leftData',
    'leftDescription',
    'rightValue',
    'rightData',
    'rightDescription',
  );

  const leftDataExist = leftData.some(item => oFetch(item, 'data') !== null);
  const rightDataExist = rightData.some(item => oFetch(item, 'data') !== null);

  return (
    <div className="boss-kpi__data-item boss-kpi__data-item_role_month">
      <div className={timeClassName}>{title}</div>
      <div className="boss-kpi__data-group">
        <div className="boss-kpi__data-area">
          {leftDataExist && (
            <Tooltip
              arrow
              theme="light"
              position="bottom"
              interactive
              html={<SiteIssueTooltipContent month={title} data={leftData} />}
            >
              <div className="boss-kpi__data-indicator">
                <span className="boss-kpi__data-indicator-value">{leftValue}</span>
              </div>
            </Tooltip>
          )}
          {!leftDataExist && (
            <div className="boss-kpi__data-indicator">
              <span className="boss-kpi__data-indicator-value">{leftValue}</span>
            </div>
          )}
          <div className="boss-kpi__data-label">{leftDescription}</div>
        </div>
        <div className="boss-kpi__data-area">
          {rightDataExist && (
            <Tooltip
              arrow
              theme="light"
              position="bottom"
              interactive
              html={<SiteIssueTooltipContent month={title} data={rightData} />}
            >
              <div className="boss-kpi__data-indicator">
                <span className="boss-kpi__data-indicator-value">{rightValue}</span>
              </div>
            </Tooltip>
          )}
          {!rightDataExist && (
            <div className="boss-kpi__data-indicator">
              <span className="boss-kpi__data-indicator-value">{rightValue}</span>
            </div>
          )}
          <div className="boss-kpi__data-label">{rightDescription}</div>
        </div>
      </div>
    </div>
  );
}
