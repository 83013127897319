import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';

export function createBonusPayment(params) {
  const createBonusPaymentPath = oFetch(apiRoutes, 'createBonusPayment.getPath')();
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [staffMemberId, year, quarter, amountCents] = oFetch(
    values,
    'staffMemberId',
    'year',
    'quarter',
    'amountCents',
  );

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'staffMemberId', 'year', 'quarter', 'amountCents'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Create bonus payment failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      const bonusPayment = oFetch(data, 'bonusPayment');
      onSuccess(bonusPayment);
    },
  }).post(createBonusPaymentPath, {
    staffMemberId,
    year,
    quarter,
    amountCents,
  });
}

export function updateBonusPayment(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [id, staffMemberId, year, quarter, amountCents] = oFetch(
    values,
    'id',
    'staffMemberId',
    'year',
    'quarter',
    'amountCents',
  );
  const updateBonusPaymentPath = oFetch(apiRoutes, 'updateBonusPayment.getPath')(id);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'staffMemberId', 'year', 'quarter', 'amountCents'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Create bonus payment failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      const bonusPayment = oFetch(data, 'bonusPayment');
      onSuccess(bonusPayment);
    },
  }).put(updateBonusPaymentPath, {
    staffMemberId,
    year,
    quarter,
    amountCents,
  });
}

export function disableBonusPayment(params) {
  const [bonusPaymentId, onSuccess] = oFetch(params, 'bonusPaymentId', 'onSuccess');
  const disableBonusPaymentPath = oFetch(apiRoutes, 'disableBonusPayment.getPath')(bonusPaymentId);

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const bonusPayment = oFetch(data, 'bonusPayment');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(bonusPayment);
    },
  }).delete(disableBonusPaymentPath);
}
