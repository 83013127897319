import React from 'react';
import oFetch from 'o-fetch';
import { ManuallyAddForm } from '../forms';

export function ManuallyAdd(props) {
  const [onSubmit, processing, venueOptions, deviceId] = oFetch(
    props,
    'onSubmit',
    'processing',
    'venueOptions',
    'deviceId',
  );

  const initialValues = {
    deviceId: deviceId,
    venueId: null,
  };

  return (
    <ManuallyAddForm
      onSubmit={onSubmit}
      processing={processing}
      venueOptions={venueOptions}
      initialValues={initialValues}
    />
  );
}
