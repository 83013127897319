import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';
import sortBy from 'lodash/sortBy';

import CollapsibleCard from '../components/collapsible-card';
import QuestionnaireFilter from '../components/questionnaire-filter';
import QuestionnaireActions from '../components/questionnaire-actions';

import { setAnswer, saveAnswers, setUpload, deleteUpload, checkAnswer } from '../actions/answers';

function mapStateToProps(state) {
  const venueHealthCheck = oFetch(state, 'venueHealthCheck').toJS();

  return {
    questionnaire: oFetch(venueHealthCheck, 'questionnaire'),
    categories: oFetch(venueHealthCheck, 'categories'),
    areas: oFetch(venueHealthCheck, 'areas'),
    questions: oFetch(venueHealthCheck, 'questions'),
    answers: oFetch(venueHealthCheck, 'answers'),
    venues: oFetch(venueHealthCheck, 'venues'),
    currentVenue: oFetch(venueHealthCheck, 'currentVenue'),
    questionnaireResponse: oFetch(venueHealthCheck, 'questionnaireResponse'),
    answerCount: oFetch(venueHealthCheck, 'answerCount'),
    uploadCount: oFetch(venueHealthCheck, 'uploadCount'),
    questionCount: oFetch(venueHealthCheck, 'questionCount'),
    frontend: oFetch(venueHealthCheck, 'frontend'),
    savedResponseId: oFetch(venueHealthCheck, 'savedResponseId'),
    uploads: oFetch(venueHealthCheck, 'uploads'),
    wrongFiles: oFetch(venueHealthCheck, 'wrongFiles'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAnswer,
      setUpload,
      saveAnswers,
      deleteUpload,
      checkAnswer,
    },
    dispatch,
  );
}

export class QuestionnaireContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      currentImage: null,
      filters: {
        section: 'any',
        display: 'unanswered',
        groupBy: 'section',
        area: 'any',
      },
    };
  }

  getSortedCategories = () => {
    const categories = oFetch(this.props, 'categories');
    const categoriesWithSortIndices = categories.map((category, index) => {
      return {
        ...category,
        sortIndex: index,
      };
    });
    return sortBy(categoriesWithSortIndices, [i => oFetch(i, 'sortIndex')]);
  };

  getSortedQuestions = () => {
    const questions = oFetch(this.props, 'questions');

    return sortBy(questions, [i => oFetch(i, 'sortIndex')]);
  };

  getSortedAreas = () => {
    const areas = oFetch(this.props, 'areas');
    const areasWithSortIndices = areas.map((area, index) => {
      return {
        ...area,
        sortIndex: index,
      };
    });
    return sortBy(areasWithSortIndices, [i => oFetch(i, 'sortIndex')]);
  };

  setModal(value) {
    this.setState({
      showModal: value,
    });
  }

  setModalImage(image) {
    this.setState({
      currentImage: image,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.frontend.saved) {
      location.href = `/venue_health_check_reports/${nextProps.savedResponseId}`;
    }
  }

  reviewMode() {
    return this.props.questionCount == this.props.answerCount;
  }

  setFilter(filter) {
    let filters = this.state.filters;
    filters = Object.assign(filters, filter);

    this.setState({
      filters: filters,
    });
  }

  commonProps() {
    let { ...props } = this.props;

    props = Object.assign(props, {
      setFilter: this.setFilter.bind(this),
      filters: this.state.filters,
      reviewMode: this.reviewMode(),
      setModal: this.setModal.bind(this),
      setModalImage: this.setModalImage.bind(this),
      currentImage: this.state.currentImage,
      showModal: this.state.showModal,
    });

    return props;
  }

  getCategoryByName(categoryName) {
    return this.getSortedCategories().find(category => {
      return category.name == categoryName;
    });
  }

  getAreaByName(areaName) {
    return this.getSortedAreas().find(area => {
      return area.name == areaName;
    });
  }

  hasAnswer(question) {
    return this.props.answers.find(answer => {
      return answer.questionnaireQuestionId == question.id && !!answer.value;
    });
  }

  filterDisplayQuestions(questions) {
    if (this.state.filters.display == 'unanswered') {
      return questions.filter(question => {
        return !this.hasAnswer(question);
      });
    } else {
      return questions;
    }
  }

  getQuestions() {
    const sectionFilter = this.state.filters.section;
    const areaFilter = this.state.filters.area;
    const currentCategory = this.getCategoryByName(sectionFilter);
    const currentArea = this.getAreaByName(areaFilter);

    const questions = this.getSortedQuestions().filter(question => {
      if (sectionFilter != 'any' && areaFilter != 'any') {
        return (
          question.questionnaireCategoryId == currentCategory.id &&
          question.questionnaireAreaId == currentArea.id
        );
      } else if (sectionFilter == 'any' && areaFilter != 'any') {
        return question.questionnaireAreaId == currentArea.id;
      } else if (sectionFilter != 'any' && areaFilter == 'any') {
        return question.questionnaireCategoryId == currentCategory.id;
      } else {
        return true;
      }
    });

    return this.filterDisplayQuestions(questions);
  }

  getQuestionsByCategoryName(categoryName) {
    const category = this.getCategoryByName(categoryName);
    return this.getQuestions().filter(question => {
      return question.questionnaireCategoryId == category.id;
    });
  }

  getQuestionsByAreaName(areaName) {
    const area = this.getAreaByName(areaName);

    return this.getQuestions().filter(question => {
      return question.questionnaireAreaId == area.id;
    });
  }

  getCategorySections() {
    const sectionFilter = this.state.filters.section;

    if (sectionFilter != 'any') {
      return this.getSortedCategories().filter(category => {
        return category.name == sectionFilter;
      });
    }
    return this.getSortedCategories();
  }

  getAreaSections() {
    const areaFilter = this.state.filters.area;

    if (areaFilter != 'any') {
      return this.getSortedAreas().filter(area => {
        return area.name == areaFilter;
      });
    }
    return this.getSortedAreas();
  }

  getResourceForCollapsibleCard() {
    const groupByFilter = this.state.filters.groupBy;

    if (groupByFilter === 'section') {
      return this.getCategorySections();
    } else if (groupByFilter === 'area') {
      return this.getAreaSections();
    } else {
      return [];
    }
  }

  renderCollapsibleCardComponent() {
    let cardProps = {};
    let categoryQuestions = [];
    const groupByFilter = this.state.filters.groupBy;

    if (groupByFilter != 'question') {
      return this.getResourceForCollapsibleCard().map(resource => {
        if (groupByFilter == 'section') {
          categoryQuestions = this.getQuestionsByCategoryName(resource.name);
        } else if (groupByFilter == 'area') {
          categoryQuestions = this.getQuestionsByAreaName(resource.name);
        }

        cardProps = Object.assign(this.commonProps(), {
          currentCategory: resource,
          categoryQuestions: categoryQuestions,
        });

        if (categoryQuestions.length == 0) return '';

        return <CollapsibleCard {...cardProps} key={resource.id} />;
      });
    } else {
      categoryQuestions = this.getQuestions();

      cardProps = Object.assign(this.commonProps(), {
        categoryQuestions: categoryQuestions,
      });
      return <CollapsibleCard {...cardProps} />;
    }
  }

  render() {
    return (
      <main className="boss-page-main">
        <div className="boss-page-main__dashboard">
          <div className="boss-page-main__inner">
            <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_questionnaire">
              <QuestionnaireActions {...this.commonProps()} />

              <QuestionnaireFilter {...this.commonProps()} />
            </div>
          </div>
        </div>

        <div className="boss-page-main__content">
          <div className="boss-page-main__inner">{this.renderCollapsibleCardComponent()}</div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireContainer);
