import React from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';
import oFetch from 'o-fetch';

import SecurityShiftRequestForm from './security-shift-request-form';
import { BOSS_APP_TYPE } from '@/lib/rota-date';

class EditSecurityShiftRequest extends React.Component {
  render() {
    const jsAccessibleVenues = oFetch(this.props, 'accessibleVenues');
    const appType = BOSS_APP_TYPE;

    return (
      <SecurityShiftRequestForm
        appType={appType}
        onFormSubmit={this.props.onSubmit}
        buttonText="Update"
        venues={jsAccessibleVenues}
        buttonClass="boss-button boss-form__submit"
        edit
        initialValues={this.props.editRequestFormInitialValues}
      />
    );
  }
}

EditSecurityShiftRequest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editRequestFormInitialValues: PropTypes.object.isRequired,
};

export default modalRedux(combineReducers({ form: formReducer }))(EditSecurityShiftRequest);
