import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';

class BossCheckRow extends React.Component {
  render() {
    const [icon, iconColor, title] = oFetch(this.props, 'icon', 'iconColor', 'title');
    return (
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__title">
            <span className="boss-check__title-indicator">
              <span className="boss-indicator">
                <span className={`boss-indicator__icon boss-indicator__icon_${icon}`} style={{ color: iconColor }} />
              </span>
            </span>
            {title}
            {!!this.props.status && <span className="boss-check__title-status">{this.props.status}</span>}
          </p>
        </div>
      </div>
    );
  }
}

BossCheckRow.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  className: PropTypes.string,
};

BossCheckRow.defaultProps = {
  className: '',
  status: '',
};

export default BossCheckRow;
