import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleDashboard from '@/components/boss-dashboards/simple-dashboard';
import ContentWrapper from '@/components/content-wrapper';
import StaffCountBlock from '../components/staff-count-block';
import oFetch from 'o-fetch';

const COLUMNS = 2;

class StaffVetting extends Component {
  render() {
    const misattributedLoginCount = oFetch(this.props, 'misattributedLoginCount');
    const {
      staffWithoutEmailCount,
      staffWithoutNiNumberCount,
      staffWithoutAddressCount,
      staffWithoutPhotoCount,
      staffOnWrongPayrateCount,
      staffWithExpiredSiaBadgeCount,
      staffMembersWithBouncedEmailCount,
      staffWithSameSageIdCount,
      staffWithoutScannableAvatarsCount,
    } = this.props;

    const canViewWithoutAddress = oFetch(this.props, 'permissions.staffWithoutAddress.canView');
    const canViewWithoutEmail = oFetch(this.props, 'permissions.staffWithoutEmail.canView');
    const canViewWithouNiNumber = oFetch(this.props, 'permissions.staffWithoutNiNumber.canView');

    const canViewWithoutPhoto = oFetch(this.props, 'permissions.staffWithoutPhoto.canView');
    const canViewOnWrongPayrate = oFetch(this.props, 'permissions.staffOnWrongPayrate.canView');
    const canViewWithExpiredSiaBadge = oFetch(this.props, 'permissions.staffWithExpiredSiaBadge.canView');
    const canViewWithBouncedEmails = oFetch(this.props, 'permissions.staffWithBouncedEmails.canView');
    const canViewWithTimeDodges = oFetch(this.props, 'permissions.staffWithWithTimeDodges.canView');
    const canViewMisattributedLogins = oFetch(this.props, 'permissions.misattributedLogins.canView');
    const canViewSameSageId = true;
    const canViewRepeatOffenders = true;

    const blocks = {
      withoutEmail: {
        title: 'Staff without Email Address',
        href: '/staff_members_without_email',
        count: staffWithoutEmailCount,
        canView: canViewWithoutEmail,
        accentColorClassName: 'boss-count_color_accent-gray',
        accentIconClassName: 'boss-count__icon_at',
      },
      withoutNI: {
        title: 'Staff without NI Number',
        href: '/staff_members_without_ni_number',
        count: staffWithoutNiNumberCount,
        canView: canViewWithouNiNumber,
        accentColorClassName: 'boss-count_color_accent-purple',
        accentIconClassName: 'boss-count__icon_umbrella',
      },
      withoutAddress: {
        title: 'Staff without Address',
        href: '/staff_members_without_address',
        count: staffWithoutAddressCount,
        canView: canViewWithoutAddress,
        accentColorClassName: 'boss-count_color_accent-blue',
        accentIconClassName: 'boss-count__icon_pin',
      },
      withoutPhoto: {
        title: 'Staff without Photo',
        href: '/staff_members_without_photo',
        count: staffWithoutPhotoCount,
        canView: canViewWithoutPhoto,
        accentColorClassName: 'boss-count_color_accent-orange',
        accentIconClassName: 'boss-count__icon_camera',
      },
      wrongPayRate: {
        title: 'Staff On Wrong Pay Rate',
        href: '/staff_members_on_wrong_payrate',
        count: staffOnWrongPayrateCount,
        canView: canViewOnWrongPayrate,
        accentColorClassName: 'boss-count_color_accent-pink',
        accentIconClassName: 'boss-count__icon_calculator',
      },
      expiredSIABadge: {
        title: 'Security Staff with Expired SIA Badge',
        href: '/staff_members_with_expired_sia_badge',
        count: staffWithExpiredSiaBadgeCount,
        canView: canViewWithExpiredSiaBadge,
        accentColorClassName: 'boss-count_color_accent-turquoise',
        accentIconClassName: 'boss-count__icon_badge',
      },
      withBouncedEmails: {
        title: 'Staff with Bounced Emails',
        href: '/staff_members_with_bounced_emails',
        count: staffMembersWithBouncedEmailCount,
        canView: canViewWithBouncedEmails,
        accentColorClassName: 'boss-count_color_accent-yellow',
        accentIconClassName: 'boss-count__icon_envelope',
      },
      withTimeDodges: {
        title: 'Time Dodgers',
        href: `/time_dodges`,
        canView: canViewWithTimeDodges,
        accentColorClassName: 'boss-count_color_accent-navy-blue',
        accentIconClassName: 'boss-count__icon_clock',
      },
      withSameSageId: {
        title: 'Staff with Duplicated Sage ID',
        href: `/duplicated_sage_id`,
        count: staffWithSameSageIdCount,
        canView: canViewSameSageId,
        accentColorClassName: 'boss-count_color_accent-lilac',
        accentIconClassName: 'boss-count__icon_qrcode',
      },
      misattributedLogins: {
        title: 'Misattributed Logins',
        href: `/misattributedLogins`,
        count: misattributedLoginCount,
        canView: canViewMisattributedLogins,
        accentColorClassName: 'boss-count_color_accent-geranium',
        accentIconClassName: 'boss-count__icon_scan-alert',
      },
      withoutScannableAvatars: {
        title: 'Staff without Scannable Avatars',
        href: `/without-scannable-avatars`,
        count: staffWithoutScannableAvatarsCount,
        canView: true,
        accentColorClassName: 'boss-count_color_accent-orchid-orange',
        accentIconClassName: 'boss-count__icon boss-count__icon_scan',
      },
    };

    const blocksArray = Object.values(blocks).reduce((acc, block) => {
      if (oFetch(block, 'canView')) {
        return [...acc, block];
      }
      return [...acc];
    }, []);

    const rowsCount =
      blocksArray.length % COLUMNS === 0 ? blocksArray.length / COLUMNS : Math.floor(blocksArray.length / COLUMNS) + 1;

    const blocksJSX = Array.from({ length: rowsCount }).map((_, index) => {
      return blocksArray.slice(index * COLUMNS, index * COLUMNS + COLUMNS).map(block => {
        const title = oFetch(block, 'title');
        return (
          <StaffCountBlock
            key={title}
            title={title}
            count={block.count}
            href={oFetch(block, 'href')}
            accentColorClassName={oFetch(block, 'accentColorClassName')}
            accentIconClassName={oFetch(block, 'accentIconClassName')}
          />
        );
      });
    });

    return (
      <main className="boss-page-main">
        <SimpleDashboard title="Staff Vetting" />
        <ContentWrapper>
          <div className="boss-page-main__group boss-page-main__group_adjust_staff-vetting">
            <div className="boss-users">
              <div className="boss-users__stats">
                {blocksJSX.map((row, index) => {
                  return (
                    <div key={index} className="boss-users__stats-group">
                      {row}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ContentWrapper>
      </main>
    );
  }
}

StaffVetting.propTypes = {
  staffWithoutEmailCount: PropTypes.number.isRequired,
  staffWithoutNiNumberCount: PropTypes.number.isRequired,
  staffWithoutAddressCount: PropTypes.number.isRequired,
  staffWithoutPhotoCount: PropTypes.number.isRequired,
  staffOnWrongPayrateCount: PropTypes.number.isRequired,
  staffWithExpiredSiaBadgeCount: PropTypes.number.isRequired,
  staffMembersWithBouncedEmailCount: PropTypes.number.isRequired,
  staffWithSameSageIdCount: PropTypes.number.isRequired,
  misattributedLoginCount: PropTypes.number.isRequired,
};

export default StaffVetting;
