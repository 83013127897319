import React from 'react';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';
import utils from '@/lib/utils';

import { CSVTable, CSVTableRow, CSVTableCell } from '../csv-table';

function TooltipContent(props) {
  const [header, error] = oFetch(props, 'header', 'error');
  return (
    <div className="boss-popover__inner">
      <p className="boss-popover__text boss-popover__text_role_primary">
        <span className="boss-popover__text-marked">{header}</span> {error}
      </p>
    </div>
  );
}

export default function SkippedInvalidPayment(props) {
  const skippedInvalidPayment = oFetch(props, 'skippedInvalidPayment');
  const [errors, rawData] = oFetch(skippedInvalidPayment, 'errors', 'rawData');
  const errorsEntries = Object.entries(errors);
  const rawDataEntries = Object.entries(rawData);

  function renderInvalidRowList() {
    return (
      <p className="boss-report__text boss-report__text_size_m boss-report__text_adjust_wrap">
        {errorsEntries.map((errorsEntry, index) => {
          const [key, errors] = errorsEntry;
          if (key === 'base') {
            return (
              <span key={index} className="boss-report__text-line">
                <span className="boss-report__text-marked">
                  {errors
                    .map(error => {
                      return utils.capitalizeFirstCharacter(error);
                    })
                    .join(', ')}
                </span>
              </span>
            );
          }
          return (
            <span key={index} className="boss-report__text-line">
              <span className="boss-report__text-marked">{key}</span> {errors}
            </span>
          );
        })}
      </p>
    );
  }

  function renderInvalidRawDataTable() {
    const headers = Object.keys(rawData);
    return (
      <CSVTable>
        <CSVTableRow isHeader>
          {headers.map((header, index) => {
            return <CSVTableCell isHeader key={index} text={header} />;
          })}
        </CSVTableRow>
        <CSVTableRow isAlert>
          {rawDataEntries.map((rawDataEntry, index) => {
            const [header, value] = rawDataEntry;
            const error = errors[header];
            const isInvalid = !!error;

            if (isInvalid) {
              return (
                <Tooltip
                  key={index}
                  html={<TooltipContent header={header} error={error} />}
                  position="bottom"
                  trigger="click"
                  arrow
                  theme="light"
                >
                  <CSVTableCell isInvalid={isInvalid} text={value} />
                </Tooltip>
              );
            }
            return <CSVTableCell isInvalid={isInvalid} key={index} text={value} />;
          })}
        </CSVTableRow>
      </CSVTable>
    );
  }

  return (
    <div className="boss-report__record">
      {renderInvalidRowList()}
      {renderInvalidRawDataTable()}
    </div>
  );
}
