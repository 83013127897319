import React from 'react';
import oFetch from 'o-fetch';

export default function Filter(props) {
  const onChange = oFetch(props, 'onChange');

  return (
    <div className="boss-page-main__filter">
      <form action="#" className="boss-form">
        <div className="boss-form__group boss-form__group_role_board">
          <div className="boss-form__row boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_layout_max">
              <div className="boss-form__search">
                <label className="boss-form__label">
                  <input
                    name="search"
                    onChange={onChange}
                    className="boss-form__input"
                    placeholder="Search..."
                    type="text"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
