import React from 'react';
import oFetch from 'o-fetch';

export default function DashboardWrapper(props) {
  const [checklistName, link] = oFetch(props, 'checklistName', 'link');

  function openLink() {
    location.href = link;
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_checklist">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text">Assignments for&nbsp;</span>
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {checklistName}
              </span>
            </h1>
            <div className="boss-page-dashboard__buttons-group">
              <button onClick={openLink} type="button" className="boss-button boss-page-dashboard__button">
                Back to Index
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
