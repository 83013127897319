import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function ReviewItem(props) {
  const [staffMember, onButtonClick] = oFetch(props, 'staffMember', 'onButtonClick');
  const [
    id,
    fullName,
    avatarUrl,
    profileUrl,
    disableReason,
    reviewed,
    disabled,
    flagged,
    matchReasons,
    staffTypeName,
    staffTypeColor,
  ] = oFetch(
    staffMember,
    'id',
    'fullName',
    'avatarUrl',
    'profileUrl',
    'disableReason',
    'reviewed',
    'disabled',
    'flagged',
    'matchReasons',
    'staffTypeName',
    'staffTypeColor',
  );

  const itemClassNames = cn('boss-user-summary boss-user-summary_role_review', {
    'boss-user-summary_state_dismissed': reviewed,
    'boss-user-summary_type_enabled': !disabled && !flagged,
    'boss-user-summary_type_disabled': disabled && !flagged,
    'boss-user-summary_type_flagged': flagged,
  });
  const buttonClassNames = cn('boss-button boss-button_type_small boss-user-summary__action', {
    'boss-button_role_undo': reviewed,
    'boss-button_role_cancel': !reviewed,
  });
  const buttonText = reviewed ? 'Undo' : 'Dismiss';

  function renderFullName() {
    if (flagged) {
      return `${fullName} - Flagged`;
    }
    return fullName;
  }

  function renderMatchReason() {
    if (matchReasons.length > 0) {
      return `${matchReasons.join(', ')}`;
    }
  }

  return (
    <div className="boss-vetting__person">
      <div className={itemClassNames}>
        <div className="boss-user-summary__side">
          <div className="boss-user-summary__avatar">
            <div className="boss-user-summary__avatar-inner">
              <img src={avatarUrl} alt={fullName} className="boss-user-summary__pic" />
            </div>
            <a
              href={profileUrl}
              target="_blank"
              rel="noreferrer"
              className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_info"
            >
              Profile
            </a>
          </div>
          <div className="boss-user-summary__controls">
            <button type="button" className={buttonClassNames} onClick={() => onButtonClick(id)}>
              {buttonText}
            </button>
          </div>
        </div>
        <div className="boss-user-summary__content">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{renderFullName()}</h2>
            <p
              className="boss-button boss-button_type_ultra-small boss-user-summary__label"
              style={{ backgroundColor: staffTypeColor }}
            >
              {staffTypeName}
            </p>
          </div>
          <ul className="boss-user-summary__review-list">
            <li className="boss-user-summary__review-item">
              <span className="boss-user-summary__review-label">Match Reason: </span>
              <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                {renderMatchReason()}
              </span>
            </li>
            {disableReason && (
              <li className="boss-user-summary__review-item">
                <span className="boss-user-summary__review-label">Disable Reason: </span>
                <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                  {disableReason}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
