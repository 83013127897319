import React from 'react';
import oFetch from 'o-fetch';

export default function QuestionItem(props) {
  const [question, onEdit, onDisable] = oFetch(props, 'question', 'onEdit', 'onDisable');
  const [id, text, categoryName, areaName, typeTitle, requiredQuestion, number, categoryColor] = oFetch(
    question,
    'id',
    'text',
    'categoryName',
    'areaName',
    'typeTitle',
    'requiredQuestion',
    'number',
    'categoryColor',
  );

  return (
    <div data-id={id} className="boss-check boss-check_role_board boss-check_page_vhcq">
      <div className="boss-check__handle boss-check__handle_position_left boss-check__handle_role_question" />
      <div className="boss-check__header">
        <div className="boss-check__header-number">
          <div className="boss-number">
            <div className="boss-number__content">
              <h3 className="boss-number__value">{number}</h3>
            </div>
          </div>
        </div>
        <div className="boss-check__header-info">
          <div className="boss-check__header-group">
            <h3 className="boss-check__title">{text}</h3>
            <div className="boss-check__header-meta">
              <div className="boss-check__header-meta-item">
                <p className="boss-tag boss-tag_size_xs" style={{ backgroundColor: categoryColor }}>
                  <span className="boss-tag__text">{categoryName}</span>
                </p>
              </div>
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-label">Area: </span>
                  <span className="boss-check__text-marked">{areaName}</span>
                </p>
              </div>
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-label">Type: </span>
                  <span className="boss-check__text-marked">{typeTitle}</span>
                  {requiredQuestion && (
                    <span className="boss-check__text-marked boss-check__text-alert"> (Required)</span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="boss-check__header-actions">
            <button
              onClick={() => onEdit(question)}
              className="boss-button boss-button_role_edit boss-button_type_small boss-check__header-action"
            >
              Edit
            </button>
            <button
              onClick={() => onDisable(question)}
              className="boss-button boss-button_role_disable boss-button_type_small boss-check__header-action"
            >
              Disable
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
