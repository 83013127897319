import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import { FILTER_SHOW_ALL, FILTER_SHOW_CURRENT } from './constants';

const filterByType = (filter, venueAlerts) => {
  const filterActions = {
    [FILTER_SHOW_ALL]() {
      return venueAlerts;
    },
    [FILTER_SHOW_CURRENT]() {
      return venueAlerts.filter(venueAlert => oFetch(venueAlert, 'isDisabled') === false);
    },
  };
  return filterActions[filter]();
};

export const activeFilterSelector = state => oFetch(state, 'filter.activeFilter');
export const redLevelTypeSelector = state => oFetch(state, 'alertLevelTypes.redLevel');
export const amberLevelTypeSelector = state => oFetch(state, 'alertLevelTypes.amberLevel');
export const venueAlertsSelector = state => oFetch(state, 'venueAlerts');
export const currentVenueIdSelector = state => oFetch(state, 'currentVenueId');

export const levelTypesSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector],
  (redLevel, amberLevel) => {
    return [redLevel, amberLevel];
  },
);

export const filteredVenueAlertsSelector = createSelector(
  [venueAlertsSelector, activeFilterSelector],
  (venueAlerts, activeFilter) => {
    return filterByType(activeFilter, venueAlerts);
  },
);

export const enabledVenueAlertsSelector = createSelector([venueAlertsSelector], venueAlerts => {
  return filterByType(FILTER_SHOW_CURRENT, venueAlerts);
});

export const redAlertsSelector = createSelector(
  [filteredVenueAlertsSelector, redLevelTypeSelector],
  (venueAlerts, redLevelType) => {
    return venueAlerts.filter(venueAlert => oFetch(venueAlert, 'level') === redLevelType);
  },
);

export const amberAlertsSelector = createSelector(
  [filteredVenueAlertsSelector, amberLevelTypeSelector],
  (venueAlerts, amberLevelType) => {
    return venueAlerts.filter(venueAlert => oFetch(venueAlert, 'level') === amberLevelType);
  },
);

export const currentLevelSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector, enabledVenueAlertsSelector],
  (redLevelType, amberLevelType, enabledVenueAlerts) => {
    if (enabledVenueAlerts.find(venueAlert => oFetch(venueAlert, 'level') === redLevelType)) {
      return redLevelType;
    }
    if (enabledVenueAlerts.find(venueAlert => oFetch(venueAlert, 'level') === amberLevelType)) {
      return amberLevelType;
    }
    return null;
  },
);
