import Promise from 'bluebird';

Promise.prototype._rejectCallback_old = Promise.prototype._rejectCallback;

// Commented until we figure out how to handle unhandled rejections
// Promise.prototype._rejectCallback =
//   function (reason, synchronous, ignoreNonErrorWarnings) {
//     if (reason.message === 'Request failed with status code 422') {
//       this._rejectCallback_old(reason, synchronous, ignoreNonErrorWarnings);
//     } else {
//       if (reason.stack) {
//         throw reason;
//       } else {
//         throw Error(reason);
//       }
//     }
//   };
import { handleUnexpectedFailure } from '@/lib/handle-unexpected-failure';

window.Promise = Promise;

import Bowser from 'bowser';
import 'core-js';
import 'cropperjs/dist/cropper.css';
import 'lightbox-react/style.css';
import './overrides.css';
import moment from 'moment';
import React from 'react';
import 'react-dates/initialize';
import { createRoot } from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import Spinner from 'react-spinner';
import 'react-spinner/react-spinner.css';
import 'react-tippy/dist/tippy.css';
import 'regenerator-runtime/runtime';
import Rollbar from 'rollbar/src/browser/rollbar';
import _ from 'underscore';
import 'whatwg-fetch';
import queryString from 'query-string';
import AppVersionChecker from '@/components/app-version-checker';
import * as selectors from '@/redux/selectors';
import { Inactivity } from '@/components/inactivity';
import './lib/load-underscore-mixins';
import './polyfills';
import './vendors/owl.carousel';
import './apps';
import { BigNumber } from 'bignumber.js';
import * as jqueryUjsInit from 'jquery-ujs';

window.jQuery = window.$ = $;
jqueryUjsInit(window.jQuery);

if (window._rollbarConfig) {
  window.Rollbar = new Rollbar(window._rollbarConfig);
}

window.onerror = (a, b, c, d, e) => {
  handleUnexpectedFailure(e);
};

window.Spinner = Spinner;
window.BigNumber = BigNumber;
window.boss = window.boss || {};
window.boss.currentLayout = window.boss.currentLayout || 'oldLayout';
window.debug = window.debug || {};
window.debug.React = React;
window.debug._ = _;
window.debug.moment = moment;
window.debug.selectors = selectors;

require('react-dates/lib/css/_datepicker.css');

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

// Add #scroll_to={ID}. It will scroll to given element ID when page loads
window.addEventListener('load', () => {
  const parsedHash = queryString.parse(window.location.hash);
  if (parsedHash.scroll_to !== undefined) {
    setTimeout(() => {
      const elmnt = document.getElementById(parsedHash.scroll_to);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }, 500);
  }
});

document.addEventListener('DOMContentLoaded', function (event) {
  const versionCheckerEl = document.createElement('div');
  const inactiveEl = document.createElement('div');
  const rootVersionCheckerEl = createRoot(versionCheckerEl);
  const rootInactiveEl = createRoot(inactiveEl);
  document.body.appendChild(versionCheckerEl);
  const fiveMinutes = 5 * 60 * 1000;
  const TEN_MINUTES_IN_SECONDS = 10 * 60;
  const countDown = 5 * 60; // In seconds
  rootVersionCheckerEl.render(<AppVersionChecker
    countdown={countDown}
    checkEvery={fiveMinutes}
                              />);
  rootInactiveEl.render(<Inactivity
    inactivitySeconds={TEN_MINUTES_IN_SECONDS}
    beforeReloadSeconds={10}
                        />);

  if (Bowser.ios) {
    document.querySelector('html').classList.add('is-ios');
  }
});
