import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { CollapsibleCard } from '@/components/hooks-components';

export default function MossPayRateGroup(props) {
  const [children, onAddClick, buttonText, title] = oFetch(
    props,
    'children',
    'onAddClick',
    'buttonText',
    'title',
  );

  const hasCreateAbility = oFetch(props, "hasCreateAbility");

  function renderTitle() {
    return (
      <Fragment>
        <h2 className="boss-board__title boss-board__title_size_medium">{title}</h2>
        { hasCreateAbility && <div className="boss-board__button-group boss-board__button-group_role_actions">
          <button
            onClick={onAddClick}
            type="button"
            className="boss-button boss-button_type_small boss-button_role_add boss-board__action"
          >
            {buttonText}
          </button>
        </div> }
      </Fragment>
    );
  }

  return (
    <CollapsibleCard className="boss-board_role_pay-rates" title={renderTitle}>
      {children}
    </CollapsibleCard>
  );
}
