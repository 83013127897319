import React, { Component } from 'react';
import oFetch from 'o-fetch';
import LoadMoreButton from '@/components/load-more/load-more-button';

const PER_PAGE = 10;

class QuestionsList extends Component {
  state = {
    currentPage: 1,
  };

  renderList = options => {
    const items = oFetch(options, 'items');
    const itemRenderer = oFetch(options, 'itemRenderer');

    const isItemsExist = items.length !== 0;

    if (!isItemsExist) {
      return <h3>No Questions found</h3>;
    }

    return items.map(item => {
      const itemId = oFetch(item, 'id');

      return React.cloneElement(itemRenderer({ item } ), {
        key: itemId.toString(),
      });
    });
  };

  getPaginatedItems = options => {
    const items = oFetch(options, 'items');
    const currentPage = oFetch(options, 'currentPage');

    return items.slice(0, PER_PAGE * currentPage);
  };

  loadMore = () => {
    this.setState(state => ({ currentPage: state.currentPage + 1 }));
  };

  render() {
    const items = oFetch(this.props, 'items');
    const currentPage = oFetch(this.state, 'currentPage');
    const paginatedItems = this.getPaginatedItems({ items, currentPage });
    const itemRenderer = oFetch(this.props, 'itemRenderer');

    return (
      <div>
        { this.renderList({
          items: paginatedItems,
          itemRenderer: itemRenderer
        }) }
        <LoadMoreButton
          onClick={this.loadMore}
          currentAmount={paginatedItems.length}
          totalAmount={items.length}
        />
      </div>
    );
  }
}

export default QuestionsList;
