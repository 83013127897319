import React from 'react';
import { initializeFailureCharts } from './d3.failures-chart';
import { initializeScoreCharts } from './d3.score-chart';
import AnswersTable from './answers-table';
import ScoreChart from './score-chart';
import oFetch from 'o-fetch';

export default class CollapsibleCard extends React.Component {
  static displayName = 'CollapsibleCard';

  constructor(props) {
    super(props);
    this.state = {
      opened: !props.currentScore.passed,
    };
  }

  componentDidMount() {
    const scoreCharts = document.getElementsByClassName('boss-chart-score');
    const failureCharts = document.getElementsByClassName('boss-chart-failures');

    for (var i = 0; i < scoreCharts.length; i++) {
      scoreCharts.item(i).innerHTML = '';
    }

    for (var i = 0; i < failureCharts.length; i++) {
      failureCharts.item(i).innerHTML = '';
    }

    initializeFailureCharts();
    initializeScoreCharts();
  }

  handleClick(e) {
    e.preventDefault();

    const $content = $(e.target).closest('.boss-board').find('.boss-board__content');
    const classToToggle = 'boss-board__content_state_opened';

    this.setState({
      opened: !this.state.opened,
    });

    $content.slideToggle().end().toggleClass(classToToggle);
  }

  renderStatusBadge() {
    const currentScore = oFetch(this.props, 'currentScore');
    if (oFetch(currentScore, 'passed')) {
      return (
        <a className="boss-button boss-button_role_success_reverse boss-button_type_small boss-board__button">
          Pass
        </a>
      );
    } else {
      return (
        <a className="boss-button boss-button_role_fail_reverse boss-button_type_small boss-board__button">
          Fail
        </a>
      );
    }
  }

  render() {
    const currentScore = oFetch(this.props, 'currentScore');
    const categoryPassed = oFetch(currentScore, 'passed');
    const iconStateClass = oFetch(this.state, 'opened') ? 'boss-board__switch_state_opened' : '';
    const sectionOpenClass = oFetch(this.state, 'opened') ? 'boss-board__content_state_opened' : '';
    const sectionPassClass = categoryPassed
      ? 'boss-board__header_status_passed'
      : 'boss-board__title_status_failed';
    const failedTitleClass = categoryPassed ? '' : 'boss-board__title_status_failed';
    const currentCategory = oFetch(this.props, 'currentCategory');
    const categoryName = oFetch(currentCategory, 'name');

    return (
      <section className="boss-board boss-board_context_stack">
        <header className={`boss-board__header ${sectionPassClass}`}>
          <h2 id={`${categoryName}-answers`} className={`boss-board__title ${failedTitleClass}`}>
            {categoryName}
          </h2>

          <div className="boss-board__button-group">
            {this.renderStatusBadge()}
            <a
              className={`boss-board__switch boss-board__switch_type_angle ${iconStateClass}`}
              href="#"
              onClick={this.handleClick.bind(this)}
            />
          </div>
        </header>

        <div className={`boss-board__content ${sectionOpenClass}`}>
          <div className="boss-board__content-inner">
            <ScoreChart {...this.props} />
            <AnswersTable {...this.props} />
          </div>
        </div>
      </section>
    );
  }
}
