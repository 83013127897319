export const INITIAL_LOAD = 'HOLIDAYS/INITIAL_LOAD';
export const UPDATE_AVATAR = 'HOLIDAYS/UPDATE_AVATAR';
export const ADD_NEW_HOLIDAY = 'HOLIDAYS/ADD_NEW_HOLIDAY';
export const CANCEL_ADD_NEW_HOLIDAY = 'HOLIDAYS/CANCEL_ADD_NEW_HOLIDAY';
export const DELETE_HOLIDAY = 'HOLIDAYS/DELETE_HOLIDAY';
export const FILTER = 'HOLIDAYS/FILTER';
export const ADD_HOLIDAY_SUCCESS = 'HOLIDAYS/ADD_HOLIDAY_SUCCESS';
export const ADD_HOLIDAY_REQUEST_SUCCESS = 'HOLIDAYS/ADD_HOLIDAY_REQUEST_SUCCESS';
export const CLOSE_HOLIDAY_MODAL = 'HOLIDAYS/CLOSE_HOLIDAY_MODAL';
export const EDIT_HOLIDAY_MODAL = 'HOLIDAYS/EDIT_HOLIDAY_MODAL';
export const CLOSE_EDIT_HOLIDAY_MODAL = 'HOLIDAYS/CLOSE_EDIT_HOLIDAYS_MODAL';
export const EDIT_HOLIDAY_SUCCESS = 'HOLIDAYS/EDIT_HOLIDAY_SUCCESS';
export const OPEN_EDIT_HOLIDAY_MODAL = 'HOLIDAYS/OPEN_EDIT_HOLIDAY_MODAL';
export const UPDATE_HOLIDAYS_COUNT = 'HOLIDAYS/UPDATE_HOLIDAYS_COUNT';
export const DELETE_HOLIDAY_REQUEST = 'HOLIDAYS/DELETE_HOLIDAY_REQUEST';
export const EDIT_HOLIDAY_REQUEST_SUCCESS = 'HOLIDAYS/EDIT_HOLIDAY_REQUEST_SUCCESS';
export const MOSS_STAFF_MEMBER_TYPE = "moss";

export const HOLIDAYS_OPTIONS = [
  {
    label: 'Paid Holiday',
    value: 'paid_holiday',
  },
  {
    label: 'Unpaid Holiday',
    value: 'unpaid_holiday',
  },
  {
    label: 'Sick leave',
    value: 'sick_leave',
  },
]
