import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';

export function DeleteRequestEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, at, question] = oFetch(event, 'userFullName', 'at', 'question');

  const formattedAt = useMemo(() => atFormat(at), [at]);

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_rejected">
      <p className="boss-overview__text">
        Request <b>{question}</b> deleted by {userFullName} @ {formattedAt}
      </p>
    </li>
  );
}
