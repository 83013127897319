import oFetch from 'o-fetch';
import React from 'react';
import * as zip from '@/lib/training-materials-zip';
import deviceImagePath from '../apple-iphone-12-mini-black.png';
import { DangerouslySetHtmlContent } from './danger-html';
import './i-phonex.css';
import { IFrame } from './iframe';
import { SectionsSelect } from './sections-select';

export function HtmlPreview(props) {
  const [title, iFrameID] = oFetch(props, 'title', 'iFrameID');
  const { html, sections = {} } = props;

  return (
    <div className="boss-preview__area">
      <div className="boss-preview__header">
        <h3 className="boss-preview__title">{title}</h3>
        <div className="boss-preview__controls">
          <div className="boss-preview__form">
            <div className="boss-form">
              <div className="boss-form__field boss-form__field_layout_min boss-form__field_role_control">
                <p className="boss-form__label boss-form__label_type_light boss-form__label_type_narrow">
                  <span className="boss-form__label-text">Jump to Section</span>
                </p>
                <SectionsSelect iFrameID={iFrameID} sections={sections} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-preview__content">
        <div className="boss-preview__device boss-preview__device_role_i12m">
          <img src={deviceImagePath} alt="iphone 12 mini" className="boss-preview__device-image" />
          <div className="boss-preview__device-screen">
            <div className="boss-preview__device-content">
              {html && (
                <IFrame id={iFrameID} frameBorder="0">
                  <DangerouslySetHtmlContent html={zip.applyExtraHtml(html)} />
                </IFrame>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
