import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const tasks = oFetch(action.payload, 'tasks');
      return tasks;
    },
  },
  initialState,
);
