import { useState } from "react";
import { WhatsappServer, WhatsappInstance } from "./types";
import * as requests from './requests';
import oFetch from "o-fetch";

export function useWhatsappServers(props: any) {
  const [whatsappServers, setWhatsappServers] = useState(oFetch(props, 'whatsappServers'));

  function addWhatsappServer(newWhatsappServer: WhatsappServer) {
    return setWhatsappServers([...whatsappServers, newWhatsappServer]);
  }

  function updateWhatsappServers(updateWhatsappServer: WhatsappServer) {
    return setWhatsappServers((oldWhatsappServers: WhatsappServer[]) => {
      return oldWhatsappServers.map((whatsappServer: WhatsappServer) => {
        if (updateWhatsappServer.id === whatsappServer.id) {
          return updateWhatsappServer;
        }
        return whatsappServer;
      });
    });
  }

  function removeWhatsappInstance(removedWhatsappServiceInstance: WhatsappInstance) {
    return setWhatsappServers((oldWhatsappServers: WhatsappServer[]) => {
      return oldWhatsappServers.map((whatsappServer: WhatsappServer) => {
        if (removedWhatsappServiceInstance.whatsappServerId === whatsappServer.id) {
          return {
            ...whatsappServer,
            instances: whatsappServer.instances.filter(
              (whatsappServiceInstance: WhatsappInstance) => whatsappServiceInstance.id !== removedWhatsappServiceInstance.id
            )
          };
        }
        return whatsappServer;
      });
    });
  }

  function createWhatsappServer(params: requests.CreateWhatsappServerParams) {
    const { onSuccess, onFailure } = params;
    return requests.createWhatsappServer({
      values: params.values,
      onSuccess(data) {
        const { whatsappServer } = data;
        addWhatsappServer(whatsappServer);
        onSuccess(data);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function createWhatsappInstance(params: requests.CreateWhatsappInstanceParams) {
    const { onSuccess, onFailure } = params;
    return requests.createWhatsappInstance({
      values: params.values,
      onSuccess(data) {
        const { whatsappServer } = data;
        updateWhatsappServers(whatsappServer);
        onSuccess(data);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function updateWhatsappInstanceCustomName(params: requests.UpdateWhatsappInstanceCustomNameParams) {
    const { onSuccess, onFailure } = params;
    return requests.updateWhatsappInstanceCustomName({
      values: params.values,
      onSuccess(data) {
        const { whatsappServer } = data;
        updateWhatsappServers(whatsappServer);
        onSuccess(data);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function restartWhatsappServer(params: requests.RestartWhatsappServerParams) {
    const { onSuccess, onFailure, whatsappServerId } = params;
    return requests.restartWhatsappServer({
      whatsappServerId,
      onSuccess(data: any) {
        const { whatsappServer } = data;
        updateWhatsappServers(whatsappServer);
        onSuccess(data);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function disableWhatsappInstance(params: requests.DisableWhatsappInstanceParams) {
    const { onSuccess, onFailure, whatsappInstance } = params;
    return requests.disableWhatsappInstance({
      whatsappInstance,
      onSuccess() {
        onSuccess();
        removeWhatsappInstance(whatsappInstance);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  return {
    whatsappServers,
    createWhatsappServer,
    createWhatsappInstance,
    updateWhatsappInstanceCustomName,
    restartWhatsappServer,
    disableWhatsappInstance
  };
}
