import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default class CategoryItem extends React.Component {
  state = {
    deleting: false,
  };

  handleDelete = args => {
    const category = oFetch(args, 'category');
    const currentVenueId = oFetch(args, 'currentVenueId');
    const onDelete = oFetch(this.props, 'onDelete');
    this.setState({ deleting: true });
    return onDelete({ category, currentVenueId });
  };

  render() {
    const category = oFetch(this.props, 'category');
    const deleting = oFetch(this.state, 'deleting');
    const currentVenueId = oFetch(this.props, 'currentVenueId');
    const [id, name, number, color, isEmpty] = oFetch(category, 'id', 'name', 'number', 'color', 'isEmpty');

    const tagClassNames = cn('boss-tag__service', {
      'boss-tag__service_role_action boss-tag__service_icon_close': !deleting,
      'boss-tag__service_icon_spinner': deleting,
    });

    return (
      <div data-id={id} style={{ backgroundColor: color }} className="boss-tag boss-tag_size_l boss-tag_type_sortable">
        <div className="boss-tag__handle boss-tag__handle_position_before" />
        <div className="boss-tag__service">{number}</div>
        <p className="boss-tag__text">{name}</p>
        {isEmpty && <div onClick={() => this.handleDelete({ category, currentVenueId })} className={tagClassNames} />}
      </div>
    );
  }
}
