import { createAction } from 'redux-actions';
import { apiRoutes, appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { SubmissionError } from 'redux-form/immutable';
import utils from '@/lib/utils';

import {
  BOSS_STAFF_MEMBER_TYPE,
  INITIAL_LOAD,
  ADD_NEW_HOLIDAY,
  CANCEL_ADD_NEW_HOLIDAY,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_REQUEST_SUCCESS,
  CLOSE_HOLIDAY_MODAL,
  DELETE_HOLIDAY,
  OPEN_EDIT_HOLIDAY_MODAL,
  EDIT_HOLIDAY_SUCCESS,
  CLOSE_EDIT_HOLIDAY_MODAL,
  FILTER,
  DELETE_HOLIDAY_REQUEST,
  EDIT_HOLIDAY_REQUEST_SUCCESS,
  MOSS_STAFF_MEMBER_TYPE,
} from './constants';
import { taxYearInfoRequest } from './ts-actions';

export const initialLoad = createAction(INITIAL_LOAD);
export const addNewHoliday = createAction(ADD_NEW_HOLIDAY);
export const cancelAddNewHoliday = createAction(CANCEL_ADD_NEW_HOLIDAY);

export const filterAction = createAction(FILTER);
export const addHolidaySuccess = createAction(ADD_HOLIDAY_SUCCESS);
export const addHolidayRequestSuccess = createAction(ADD_HOLIDAY_REQUEST_SUCCESS);
export const editHolidaySuccess = createAction(EDIT_HOLIDAY_SUCCESS);
export const editHolidayRequestSuccess = createAction(EDIT_HOLIDAY_REQUEST_SUCCESS);
export const closeHolidayModal = createAction(CLOSE_HOLIDAY_MODAL);
export const closeEditHolidayModal = createAction(CLOSE_EDIT_HOLIDAY_MODAL);
export const deleteHolidayRequestAction = createAction(DELETE_HOLIDAY_REQUEST);
export const deleteHolidayAction = createAction(DELETE_HOLIDAY);
export { taxYearInfoRequest } from './ts-actions';

export const deleteHoliday = args => (dispatch, getState) => {
  const holidayId = oFetch(args, 'holidayId');

  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const taxYear = oFetch(stateJS, 'taxYear');
  const selectedTaxYear = oFetch(taxYear, 'selectedTaxYear');
  const year = oFetch(selectedTaxYear, 'year');

  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(deleteHolidayAction(data));
      return dispatch(taxYearInfoRequest({ effectiveStaffMember, year }));
    },
  }).delete(`/api/v1/holidays/${holidayId}`);
};

export const deleteHolidayRequest = args => (dispatch, getState) => {
  const holidayRequestId = oFetch(args, 'holidayRequestId');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      dispatch(deleteHolidayRequestAction({ holidayRequestId }));
    },
  }).delete(`/api/v1/holiday-requests/${holidayRequestId}`);
};

export const editHoliday = args => (dispatch, getState) => {
  const argsJS = utils.callIfExists({
    obj: args,
    funcName: 'toJS',
  });

  const [startDate, endDate, holidayType, note, id, forward] = oFetch(
    argsJS,
    'startDate',
    'endDate',
    'holidayType',
    'note',
    'id',
    'forward',
  );

  const formateStartDate = startDate.format('DD-MM-YYYY');
  const formatedEndDate = endDate.format('DD-MM-YYYY');
  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const taxYear = oFetch(stateJS, 'taxYear');
  const selectedTaxYear = oFetch(taxYear, 'selectedTaxYear');
  const year = oFetch(selectedTaxYear, 'year');
  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType', 'payslipDate'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(editHolidaySuccess(data));
      dispatch(closeEditHolidayModal());
      dispatch(taxYearInfoRequest({ effectiveStaffMember, year }));
      return true;
    },
  }).put(`/api/v1/holidays/${id}`, {
    forward,
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const editHolidayRequest = args => (dispatch, getState) => {
  const argsJS = utils.callIfExists({
    obj: args,
    funcName: 'toJS',
  });
  const [startDate, endDate, holidayType, note, id] = oFetch(
    argsJS,
    'startDate',
    'endDate',
    'holidayType',
    'note',
    'id',
  );

  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const taxYear = oFetch(stateJS, 'taxYear');
  const selectedTaxYear = oFetch(taxYear, 'selectedTaxYear');
  const year = oFetch(selectedTaxYear, 'year');
  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');

  const formateStartDate = startDate.format('DD-MM-YYYY');
  const formatedEndDate = endDate.format('DD-MM-YYYY');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType', 'payslipDate'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(editHolidayRequestSuccess(data));
      dispatch(closeEditHolidayModal());
      return dispatch(taxYearInfoRequest({ effectiveStaffMember, year }));
    },
  }).put(`/api/v1/holiday-requests/${id}`, {
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const addHoliday = args => (dispatch, getState) => {
  const argsJS = utils.callIfExists({
    obj: args,
    funcName: 'toJS',
  });
  const [startDate, endDate, holidayType, note, forward] = oFetch(
    argsJS,
    'startDate',
    'endDate',
    'holidayType',
    'note',
    'forward',
  );
  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const taxYear = oFetch(stateJS, 'taxYear');
  const selectedTaxYear = oFetch(taxYear, 'selectedTaxYear');
  const year = oFetch(selectedTaxYear, 'year');
  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');
  const effectiveStaffType = oFetch(effectiveStaffMember, 'effective_staff_type');
  const formateStartDate = startDate ? startDate.format('DD-MM-YYYY') : null;
  const formatedEndDate = endDate ? endDate.format('DD-MM-YYYY') : null;

  let createHolidayUrl;
  if (effectiveStaffType === BOSS_STAFF_MEMBER_TYPE) {
    createHolidayUrl = `/api/v1/staff_members/${effectiveStaffMemberId}/create_holiday`;
  } else if (effectiveStaffType === MOSS_STAFF_MEMBER_TYPE) {
    createHolidayUrl = `/api/v1/moss_staff_members/${effectiveStaffMemberId}/create_holiday`;
  } else {
    throw new Error(`unsupported type: ${effectiveStaffType}`);
  }

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const newHoliday = oFetch(data, 'holiday');
      const newPermissions = oFetch(data, 'permissions');

      dispatch(addHolidaySuccess({ newHoliday, newPermissions }));
      dispatch(closeHolidayModal());
      return dispatch(taxYearInfoRequest({ effectiveStaffMember, year }));
    },
  }).post(createHolidayUrl, {
    forward,
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const addHolidayRequest = args => (dispatch, getState) => {
  const argsJS = utils.callIfExists({
    obj: args,
    funcName: 'toJS',
  });

  const [startDate, endDate, holidayType, note] = oFetch(
    argsJS,
    'startDate',
    'endDate',
    'holidayType',
    'note',
  );

  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');
  const effectiveStaffType = oFetch(effectiveStaffMember, 'effective_staff_type');

  let createHolidayRequestUrl;
  if (effectiveStaffType === BOSS_STAFF_MEMBER_TYPE) {
    createHolidayRequestUrl = `/api/v1/staff_members/${effectiveStaffMemberId}/create_holiday_request`;
  } else if (effectiveStaffType === MOSS_STAFF_MEMBER_TYPE) {
    createHolidayRequestUrl = `/api/v1/moss_staff_members/${effectiveStaffMemberId}/create_holiday_request`;
  } else {
    throw new Error(`unsupported error `);
  }

  const formateStartDate = startDate ? startDate.format('DD-MM-YYYY') : null;
  const formatedEndDate = endDate ? endDate.format('DD-MM-YYYY') : null;

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'startDate', 'endDate', 'holidayType'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const newHolidayRequest = oFetch(data, 'holiday_request');
      const newPermissions = oFetch(data, 'permissions');
      dispatch(addHolidayRequestSuccess({ newHolidayRequest, newPermissions }));
      dispatch(closeHolidayModal());
    },
  }).post(createHolidayRequestUrl, {
    start_date: formateStartDate,
    end_date: formatedEndDate,
    note: note,
    holiday_type: holidayType,
  });
};

export const filter = (sStartDate, sEndDate, sStartPayslipDate, sEndPayslipDate) => (dispatch, getState) => {
  const stateJS = utils.callIfExists({
    obj: getState(),
    funcName: 'toJS',
  });
  const holidaysData = utils.callIfExists({
    obj: oFetch(stateJS, 'holidays'),
    funcName: 'toJS',
  });

  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');
  const effectiveStaffType = oFetch(effectiveStaffMember, 'effective_staff_type');

  const mStartDate = sStartDate && safeMoment.uiDateParse(sStartDate);
  const mEndDate = sEndDate && safeMoment.uiDateParse(sEndDate);
  const mPayslipStartDate = sStartPayslipDate && safeMoment.uiDateParse(sStartPayslipDate);
  const mPayslipEndDate = sEndPayslipDate && safeMoment.uiDateParse(sEndPayslipDate);
  let getApiUrl, getWebUrl;

  if (effectiveStaffType === BOSS_STAFF_MEMBER_TYPE) {
    getApiUrl = apiRoutes.staffMemberProfileHolidaysIndex.getPath({
      staffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
    getWebUrl = appRoutes.staffMemberProfileHolidaysTab({
      staffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
  } else if (effectiveStaffType === MOSS_STAFF_MEMBER_TYPE) {
    getApiUrl = apiRoutes.mossStaffMemberProfileHolidaysIndex.getPath({
      mossStaffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
    getWebUrl = appRoutes.mossStaffMemberProfileHolidaysTab({
      mossStaffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
  } else {
    throw new Error(`unsupported effectiveStaffType: ${effectiveStaffType}`);
  }

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(filterAction(data));
      window.history.pushState('state', 'title', `${getWebUrl}`);
    },
  }).get(getApiUrl);
};

export const openEditModal = holiday => {
  return {
    type: OPEN_EDIT_HOLIDAY_MODAL,
    payload: holiday,
  };
};

export const closeEditModal = () => {
  return {
    type: CLOSE_EDIT_HOLIDAY_MODAL,
  };
};
