import React from 'react';
import { UpdateNonStaffMemberStaffPartyPersonForm } from './update-non-staff-member-staff-party-person-form';
import { UpdateFormCallProps, UpdateFormSubmitHandler } from '../types';

type UpdateModalContentProps = UpdateFormCallProps & {
  onSubmit: UpdateFormSubmitHandler,
};

export function UpdateModalContent(props: UpdateModalContentProps) {

  return (
    <UpdateNonStaffMemberStaffPartyPersonForm
      id={props.id}
      mossStaffMemberOption={props.mossStaffMemberOption}
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      staffPartyQuizTeamOptions={props.staffPartyQuizTeamOptions}
    />
  );
}