import { http, bossRequestHttp } from '@/lib/request-api';
import { SubmissionError } from 'redux-form';
import { SubmissionError as ImmutableSubmissionError } from 'redux-form/immutable';

import { normalizeReduxFormErrors } from '@/lib/utils';
import oFetch from 'o-fetch';

export const assignShiftRequestRequest = params => {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const id = oFetch(values, 'id');
  const staffMemberId = oFetch(values, 'staffMemberId');
  const startsAt = oFetch(values, 'startsAt');
  const endsAt = oFetch(values, 'endsAt');
  const headDoormanHour = oFetch(values, 'headDoormanHour');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Request Assignment Failed' });
      if (statusCode === 422) {
        const normalizedErrors = normalizeReduxFormErrors(errors)
        onFailure();
        throw new ImmutableSubmissionError(normalizedErrors)
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ message: 'Request Assigned Successfully' });
      onSuccess(data);
    },
  }).post(`/api/v1/security-shift-requests/${id}/assign`, {
    staffMemberId,
    startsAt,
    endsAt,
    headDoormanHour,
  });
};

export const loadRequestsByDateRequest = params => {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const date = oFetch(values, 'date');

  return bossRequestHttp({
    errorHandler() { },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).get(`/api/v1/security_rotas/${date}/requests`);
};

export const rejectShiftRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [id, rejectReason] = oFetch(values, 'id', 'rejectReason');
  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['rejectReason', 'base'],
        });
        const normalizedErrors = normalizeReduxFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update Permanent Permission failed' });
        throw new SubmissionError(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/security-shift-requests/${id}/reject`, { rejectReason });
};
