import React from 'react';
import oFetch from 'o-fetch';
import { RequestsHeader } from './requests-header';
import { RequestItem } from './request-item';
import { RequestList } from './request-list';

export function TaskDetailsRequests(props) {
  const [onAddRequestClick, activeRequests, removedRequests, isAcceptedTask, canCreateRequest] = oFetch(
    props,
    'onAddRequestClick',
    'activeRequests',
    'removedRequests',
    'isAcceptedTask',
    'canCreateRequest',
  );

  return (
    <div className="boss-task__group boss-task__group_role_requests">
      <RequestsHeader
        requests={activeRequests}
        onAddRequestClick={onAddRequestClick}
        isAcceptedTask={isAcceptedTask}
        canCreateRequest={canCreateRequest}
      />
      <RequestList
        activeRequests={activeRequests}
        removedRequests={removedRequests}
        isAcceptedTask={isAcceptedTask}
        renderRequest={request => <RequestItem request={request} />}
      />
    </div>
  );
}
