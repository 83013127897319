import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';

import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import { loadInitialState } from './redux/actions';
import AccessoriesPageContainer from './containers/accessories-page-container';

export function AccessoriesApp(props) {
  const [store, setStore] = useState(null);
  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(loadInitialState({ ...props }));
    require('./styles.css');
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <AccessoriesPageContainer permissions={oFetch(props, 'permissions')} />
    </Provider>
  );
}
