import React from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { BossFormSelectNew, BossFormTextarea } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { BossFormDateTimePicker } from '../fields/boss-form-date-time-picker';

interface MessageScheduleFormProps {
  onSubmit: (values: any) => void
  processing: boolean
  initialValues: any
  buttonText: string
}

export function MessageScheduleForm(props: MessageScheduleFormProps) {
  const staffMembers = oFetch(useLegacyGlobal(), 'staffMembers');
  const { onSubmit, processing, initialValues, buttonText } = props;

  return (
    <Form
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <div className="boss-modal-window__form">
            <div className="boss-form">
              <div className="boss-form__field">
                <Field
                  name="staffMemberId"
                  label="Staff Member"
                  disabled={processing}
                  component={BossFormSelectNew}
                  valueKey="id"
                  labelKey="fullName"
                  options={staffMembers}
                />
              </div>
              <Field
                name="sendAt"
                label="Send At"
                disabled={processing}
                component={BossFormDateTimePicker}
              />
              <div className="boss-form__field">
                <Field
                  name="message"
                  label="Message"
                  disabled={processing}
                  parse={value => value}
                  component={BossFormTextarea}
                />
              </div>
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  disabled={submitting || processing}
                  className="boss-button boss-button_role_confirm boss-form__submit"
                  type="button"
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
