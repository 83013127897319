import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import Select from 'react-select';
import cn from 'classnames';

export default function UISelectField(props) {
  const [
    label,
    placeholder,
    isMulti,
    value,
    onChange,
    options,
    valueKey,
    labelKey,
    name,
    errors,
  ] = oFetch(
    props,
    'label',
    'placeholder',
    'isMulti',
    'value',
    'onChange',
    'options',
    'valueKey',
    'labelKey',
    'name',
    'errors',
  );

  function getNormalizedOptions() {
    return options.map(option => {
      return {
        value: option[valueKey],
        label: option[labelKey],
      };
    });
  }

  function getNormalizedValue() {
    if (isMulti) {
      if (!value) {
        return [];
      }
      return value.map(valueItem => {
        const optionItem = options.find(
          option => option[valueKey].toString() === valueItem.toString(),
        );
        return {
          value: valueItem,
          label: optionItem[labelKey],
        };
      });
    } else {
      if (!value) {
        return value;
      }
      return options.find(option => option[valueKey] === value);
    }
  }

  function handleChange(selected) {
    if (isMulti) {
      if (!selected) {
        return onChange([]);
      }
      const normalizedSelected = selected.map(selectedItem => {
        return oFetch(selectedItem, 'value');
      });
      return onChange(normalizedSelected);
    } else {
      return onChange(selected ? oFetch(selected, 'value') : null);
    }
  }

  const selectorClassNames = cn('react-select', {
    'react-select--single': !isMulti,
    'react-select--multiple': isMulti,
  });

  const hasErrors = errors.length > 0;

  const fieldClassNames = cn('purple-form-select', { 'purple-form-select_state_error': hasErrors });

  function renderErrors(errors) {
    return (
      <div className="purple-form-error purple-form-error_position_below">
        {errors.map((error, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <div className={fieldClassNames}>
      <p className="purple-form-select__label">
        <span className="purple-form-select__label-text">{label}</span>
      </p>
      <div className="purple-form-select__component">
        <Select
          isMulti={isMulti}
          name={name}
          classNamePrefix="react-select"
          className={selectorClassNames}
          placeholder={placeholder}
          options={getNormalizedOptions()}
          value={getNormalizedValue()}
          onChange={handleChange}
        />
      </div>
      {hasErrors && renderErrors(errors)}
    </div>
  );
}

UISelectField.defaultProps = {
  label: null,
  placeholder: null,
  isMulti: false,
  valueKey: 'value',
  labelKey: 'label',
  errors: [],
};
