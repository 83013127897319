import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import { IMaskInput } from 'react-imask';

export const CurrencyInput = props => {
  const [onChange, value, className, decimalSymbol, disabled, placeholder] = oFetch(
    props,
    'onChange',
    'value',
    'className',
    'decimalSymbol',
    'disabled',
    'placeholder',
  );
  return (
    <IMaskInput
      mask={Number}
      value={value}
      disabled={disabled}
      radix={decimalSymbol}
      unmask={true}
      thousandsSeparator={''}
      className={className}
      onAccept={(value, mask) => {
        if (value === '') {
          onChange(null);
        } else {
          onChange(new BigNumber(value));
        }
      }}
      placeholder={placeholder}
    />
  );
};

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  placeholder: 'Enter money here',
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
};
