import React from 'react';
import oFetch from 'o-fetch';

export function BankDetailsListItem(props) {
  const [onEditClick, permissionsData] = oFetch(props, 'onEditClick', 'permissionsData');
  const hasBankDetails = oFetch(props, 'hasBankDetails');
  const canUpdateBankDetails = oFetch(permissionsData, 'canUpdateBankDetails');

  return (
    <li className="boss-details__item">
      <p className="boss-details__label boss-details__label boss-details__label_size_small">BD</p>
      <p className="boss-details__value">
        <span className="boss-details__value-part">
          {hasBankDetails && (
            <span className="boss-indicator">
              <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
            </span>
          )}
          {!hasBankDetails && (
            <span className="boss-indicator">
              <span className="boss-indicator__icon boss-indicator__icon_close-bold" />
            </span>
          )}
        </span>
        {canUpdateBankDetails && (
          <span className="boss-details__value-part">
            <button onClick={onEditClick} className="boss-action boss-action_role_edit" />
          </span>
        )}
      </p>
    </li>
  );
}
