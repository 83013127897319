export const INITIAL_LOAD = 'SECURITY_ROTA_DAILY/INITIAL_LOAD';
export const ADD_NEW_SHIFT = 'SECURITY_ROTA_DAILY/ADD_NEW_SHIFT';
export const CANCEL_ADD_NEW_SHIFT = 'SECURITY_ROTA_DAILY/CANCEL_ADD_NEW_SHIFT';
export const SHOW_GRAPH_DETAILS = 'SECURITY_ROTA_DAILY/SHOW_GRAPH_DETAILS';
export const CLOSE_GRAPH_DETAILS = 'SECURITY_ROTA_DAILY/CLOSE_GRAPH_DETAILS';
export const UPDATE_ROTA_SHIFT = 'SECURITY_ROTA_DAILY/UPDATE_ROTA_SHIFT';
export const DELETE_ROTA_SHIFT = 'SECURITY_ROTA_DAILY/DELETE_ROTA_SHIFT';
export const DELETE_SECURITY_VENUE_SHIFT = 'SECURITY_ROTA_DAILY/DELETE_SECURITY_VENUE_SHIFT';
export const ADD_ROTA_SHIFT = 'SECURITY_ROTA_DAILY/ADD_ROTA_SHIFT';
export const ADD_SECURITY_VENUE_SHIFT = 'SECURITY_ROTA_DAILY/ADD_SECURITY_VENUE_SHIFT';
export const OPEN_MULTIPLE_SHIFT = 'SECURITY_ROTA_DAILY/OPEN_MULTIPLE_SHIFT';
export const CLOSE_MULTIPLE_SHIFT = 'SECURITY_ROTA_DAILY/CLOSE_MULTIPLE_SHIFT';
export const SET_MULTIPLE_SHIFT_STAFF_ID =
  'SECURITY_ROTA_DAILY/SET_MULTIPLE_SHIFT_STAFF_ID';
export const SET_VENUES_FILTER = 'SECURITY_ROTA_DAILY/SET_VENUES_FILTER';
export const SET_ROTA_STATUS = 'SECURITY_ROTA_DAILY/SET_ROTA_STATUS';
export const UPDATE_STAFF_MEMBER_SHIFT_INFO =
  'SECURITY_ROTA_DAILY/UPDATE_STAFF_MEMBER_SHIFT_INFO';
