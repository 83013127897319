import React from 'react';
import { NonStaffMemberStaffPartyPersonForm } from './non-staff-member-staff-party-person-form';
import { FormSubmitHandler, FormCallProps } from '../types';

type EditModalContentProps = FormCallProps & {
  onSubmit: FormSubmitHandler,
};

export function EditModalContent(props: EditModalContentProps) {
  return (
    <NonStaffMemberStaffPartyPersonForm
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    />
  );
}