import React from 'react';
import oFetch from 'o-fetch';
import { UpdateCustomNameForm } from '../forms';

export function UpdateCustomName(props) {
  const [onSubmit, processing, device] = oFetch(props, 'onSubmit', 'processing', 'device');

  const [deviceId, customDeviceName] = oFetch(device, 'id', 'customDeviceName');

  const initialValues = {
    deviceId: deviceId,
    customDeviceName: customDeviceName,
  };

  return <UpdateCustomNameForm onSubmit={onSubmit} processing={processing} initialValues={initialValues} />;
}
