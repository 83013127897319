import React, { Component } from 'react';
import oFetch from 'o-fetch';
import DeclineForm from './decline-form';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

class DeclineTask extends Component {
  render() {
    const [taskId, message, onSubmit, showDeleteButton, deleteButtonText, onDelete, onClose] = oFetch(
      this.props,
      'id',
      'message',
      'onSubmit',
      'showDeleteButton',
      'deleteButtonText',
      'onDelete',
      'onClose',
    );

    const initialValues = {
      id: taskId,
      declineReason: null,
    };

    return (
      <DeclineForm
        initialValues={initialValues}
        message={message}
        deleteButtonText={deleteButtonText}
        showDeleteButton={showDeleteButton}
        onDelete={onDelete}
        onAccept={onSubmit}
        onClose={onClose}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(DeclineTask);
