import React from 'react';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import oFetch from 'o-fetch';
import {
  Fields,
  BossFormInput,
  BossFormSelectNew,
  BossFormCalendar,
  BossFormTrixRteFieldWithAttachments,
} from '@/components/boss-form';
import { MarketingTaskAevaAssistant } from './marketing-task-aeva-assistant';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { deleteUploadedImageFile, uploadImageFile } from '../../requests';

function renderStaticAdvice(adviceHtml) {
  return <div dangerouslySetInnerHTML={{ __html: adviceHtml }} />;
}

export function TaskForm(props) {
  const venueOptions = oFetch(useLegacyGlobal(), 'venueOptions');
  const pageOptions = oFetch(useLegacyGlobal(), 'pageOptions');
  const taskStaticAdviceText = oFetch(pageOptions, 'taskStaticAdviceText');

  const [initialValues, onSubmit, buttonText, files] = oFetch(
    props,
    'initialValues',
    'onSubmit',
    'buttonText',
    'files',
  );

  const staticAdvice = renderStaticAdvice(taskStaticAdviceText);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      className="boss-form"
      render={({ handleSubmit, submitting, submitError, values, pristine }) => {
        return (
          <div className="boss-table__form">
            <div style={{ marginBottom: '2em' }}>{staticAdvice}</div>
            {submitError && (
              <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                <p className="boss-alert__text">{submitError}</p>
              </div>
            )}
            <div className="boss-form">
              <Field name="title" parse={value => value} label="Title" component={BossFormInput} />
              <div className="boss-form__row">
                <Field
                  name="venueId"
                  label="Venue"
                  fieldClassName="boss-form__field_layout_half"
                  component={BossFormSelectNew}
                  parse={value => value}
                  options={venueOptions}
                />
                <Field
                  name="deadlineDate"
                  valueFormat={'DD-MM-YYYY'}
                  isDayBlocked={mDay => {
                    const currentMoment = moment();
                    return mDay < currentMoment;
                  }}
                  component={BossFormCalendar}
                  fieldClassName={'boss-form__field_layout_half'}
                  label="Deadline Date"
                />
              </div>
              <div className="boss-form__field">
                <Fields
                  names={['description', 'fileIds']}
                  descriptionName="description"
                  fileIdsName="fileIds"
                  label="Description"
                  files={files}
                  softDelete
                  originalRender={BossFormTrixRteFieldWithAttachments}
                  onUpload={uploadImageFile}
                  onDelete={deleteUploadedImageFile}
                />
              </div>
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitting || pristine}
                  className="boss-button boss-form__submit"
                >
                  {buttonText}
                </button>
              </div>
              <div className="boss-form__field">
                <MarketingTaskAevaAssistant values={values} venueOptions={venueOptions} />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
