import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const events = oFetch(action.payload, 'events');
      return events;
    },
    [commonTypes.ADD_EVENT]: (state, action) => {
      const event = oFetch(action.payload, 'event');
      if (event === null) {
        return state;
      }
      return [...state, event];
    },
  },
  initialState,
);
