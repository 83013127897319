import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { SubmissionError } from 'redux-form/immutable';
import { bossRequestHttp, http } from '@/lib/request-api';
import { normalizeReduxFormErrors } from '@/lib/utils';

import { apiRoutes, appRoutes } from '@/lib/legacy-routes';

import * as types from './constants';

export const initialLoad = createAction(types.INITIAL_LOAD);
export const updateMossOwedHour = createAction(types.UPDATE_OWED_HOUR);
export const addMossOwedHour = createAction(types.ADD_OWED_HOUR);
export const removeMossOwedHour = createAction(types.DELETE_OWED_HOUR);
export const filterAction = createAction(types.FILTER);

export const deleteMossOwedHour = mossOwedHourId => (dispatch, getState) => {
  const mossStaffMemberId = oFetch(getState(), 'profile.mossStaffMember.id');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      dispatch(removeMossOwedHour(mossOwedHourId));
    },
  }).delete(`/api/v1/moss_staff_members/${mossStaffMemberId}/moss_owed_hours/${mossOwedHourId}`);
};

export const editMossOwedHour = values => (dispatch, getState) => {
  const [
    id,
    startsAt,
    endsAt,
    date,
    note,
  ] = oFetch(
    values,
    'id',
    'startsAt',
    'endsAt',
    'date',
    'note',
  );
  const mossStaffMemberId = oFetch(getState(), 'profile.mossStaffMember.id');
  const formatedDate = date.format('DD-MM-YYYY');
  const formData = new FormData();
  formData.set('startsAt', startsAt);
  formData.set('endsAt', endsAt);
  formData.set('date', formatedDate);
  formData.set('note', note);

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'date',
            'endsAt',
            'note',
            'startsAt',
          ],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateMossOwedHour({ id, ...data }));
    },
  }).put(`/api/v1/moss_staff_members/${mossStaffMemberId}/moss_owed_hours/${id}`, formData);
};

export const createMossOwedHour = values => (dispatch, getState) => {
  const [
    startsAt,
    endsAt,
    date,
    note,
  ] = oFetch(
    values,
    'startsAt',
    'endsAt',
    'date',
    'note',
  );
  const mossStaffMemberId = oFetch(getState(), 'profile.mossStaffMember.id');
  const formatedDate = date ? date.format('DD-MM-YYYY') : null;
  const formData = new FormData();
  formData.set('startsAt', startsAt);
  formData.set('endsAt', endsAt);
  formData.set('date', formatedDate);
  formData.set('note', note);

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'date',
            'endsAt',
            'note',
            'startsAt',
          ],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addMossOwedHour(data));
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/moss_owed_hours`, formData);
};

export const filter = (sStartDate, sEndDate, sPayslipStartDate, sPayslipEndDate) => (
  dispatch,
  getState,
) => {
  const mossStaffMemberId = oFetch(getState(), 'profile.mossStaffMember.id');
  const mStartDate = sStartDate && safeMoment.uiDateParse(sStartDate);
  const mEndDate = sEndDate && safeMoment.uiDateParse(sEndDate);
  const mPayslipStartDate = sPayslipStartDate && safeMoment.uiDateParse(sPayslipStartDate);
  const mPayslipEndDate = sPayslipEndDate && safeMoment.uiDateParse(sPayslipEndDate);
  const foo = apiRoutes;
  const route = oFetch(apiRoutes, 'mossStaffMemberProfileMossOwedHoursIndex');
  const apiGetUrl = route.getPath({
    mossStaffMemberId: mossStaffMemberId,
    mStartDate: mStartDate,
    mEndDate: mEndDate,
    mPayslipStartDate: mPayslipStartDate,
    mPayslipEndDate: mPayslipEndDate,
  });
  const webGetUrl = appRoutes.mossStaffMemberOwedHours({
    mossStaffMemberId: mossStaffMemberId,
    mStartDate: mStartDate,
    mEndDate: mEndDate,
    mPayslipStartDate: mPayslipStartDate,
    mPayslipEndDate: mPayslipEndDate,
  });

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(filterAction(data));
      window.history.pushState('state', 'title', `${webGetUrl}`);
    },
  }).get(apiGetUrl);
};
