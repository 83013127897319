import { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import utils, { highlightResult, nestedFilter } from '@/lib/utils';
import * as requests from './requests';

const groupByVenueId = utils.groupBy('venueId');

function filterDeviceByVenue(devices, venueIds) {
  if (venueIds.length === 0) {
    return devices;
  }
  return devices.filter(device => {
    return venueIds.includes(oFetch(device, 'venueId'));
  });
}

function filterDeviceByName(devices, name) {
  return highlightResult(
    nestedFilter(devices, name, 'lastDeviceName', 'customDeviceName'),
    name,
    'lastDeviceName',
    'customDeviceName',
  );
}

export function useDevicesPage(params) {
  const [devicesFromProps, venuesFromProps] = oFetch(params, 'devices', 'venues');

  const [devices, setDevices] = useState(devicesFromProps);
  const [venueOptions, setVenueOptions] = useState(venuesFromProps);

  const existingPermissionsVenueOptions = useMemo(() => {
    const groupedByVenueId = groupByVenueId(devices);
    const devicesVenueIds = devices.map(device => oFetch(device, 'venueId'));

    return venuesFromProps
      .filter(venue => devicesVenueIds.includes(oFetch(venue, 'id')))
      .map(venue => {
        const venueId = oFetch(venue, 'id');
        const hasPendingDevices = groupedByVenueId[venueId].some(device => oFetch(device, 'isPending'));
        return {
          ...venue,
          hasPendingDevices,
        };
      });
  }, [devices]);

  const [filter, setFilter] = useState({
    venues: [],
    deviceName: '',
  });

  const filtered = useMemo(() => {
    return (
      oFetch(filter, 'venues').length > 0 ||
      (oFetch(filter, 'deviceName') !== '' && oFetch(filter, 'deviceName') !== null)
    );
  }, [filter]);

  const filteredDevices = useMemo(() => {
    const [venues, deviceName] = oFetch(filter, 'venues', 'deviceName');
    const filteredByVenue = filterDeviceByVenue(devices, venues);
    return filterDeviceByName(filteredByVenue, deviceName);
  }, [devices, filter]);

  function handleFilterDeviceNameChange(deviceName) {
    setFilter(prev => {
      return {
        ...prev,
        deviceName: deviceName,
      };
    });
  }

  function handleFilterVenuesChange(venues) {
    setFilter(prev => {
      return {
        ...prev,
        venues: venues,
      };
    });
  }

  function updateDeviceInState(newDevice) {
    setDevices(oldDevices => {
      return oldDevices.map(oldDevice => {
        if (oFetch(oldDevice, 'id') === oFetch(newDevice, 'id')) {
          return newDevice;
        }
        return oldDevice;
      });
    });
  }

  function removeDeviceFromState(deviceForRemove) {
    setDevices(oldDevices => {
      return oldDevices.filter(device => oFetch(device, 'id') !== oFetch(deviceForRemove, 'id'));
    });
  }

  function ban(params) {
    const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

    return requests.ban({
      values,
      onSuccess(device) {
        removeDeviceFromState(device);
        onSuccess();
      },
    });
  }

  function unban(params) {
    const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

    return requests.unban({
      values,
      onSuccess(device) {
        removeDeviceFromState(device);
        onSuccess();
      },
    });
  }

  function acceptPermissionRequest(params) {
    const values = oFetch(params, 'values');

    return requests.acceptPermissionRequest({
      values,
      onSuccess(device) {
        updateDeviceInState(device);
      },
    });
  }

  function disablePermission(params) {
    const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

    return requests.disablePermission({
      values,
      onSuccess(device, venues) {
        removeDeviceFromState(device);
        setVenueOptions(venues);
        onSuccess();
      },
    });
  }

  function rejectPermissionRequest(params) {
    const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

    return requests.rejectPermissionRequest({
      values,
      onSuccess(device, venues) {
        removeDeviceFromState(device);
        setVenueOptions(venues);
        onSuccess();
      },
    });
  }

  function updateCustomName(params) {
    const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');

    return requests.updateCustomName({
      values,
      onSuccess(device) {
        updateDeviceInState(device);
        onSuccess();
      },
      onFailure() {
        onFailure();
      },
    });
  }

  function addManualPermission(params) {
    const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');

    return requests.addManualPermission({
      values,
      onSuccess(device, venues) {
        removeDeviceFromState(device);
        setVenueOptions(venues);
        onSuccess();
      },
      onFailure,
    });
  }

  function changePermissionRequestVenue(params) {
    const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');

    return requests.changePermissionRequestVenue({
      values,
      onSuccess(device, venues) {
        updateDeviceInState(device);
        setVenueOptions(venues);
        onSuccess();
      },
      onFailure,
    });
  }

  return {
    devices,
    filteredDevices,
    acceptPermissionRequest,
    disablePermission,
    rejectPermissionRequest,
    updateCustomName,
    addManualPermission,
    changePermissionRequestVenue,
    venueOptions,
    existingPermissionsVenueOptions,
    ban,
    unban,
    filter,
    filtered,
    onFilterDeviceNameChange: handleFilterDeviceNameChange,
    onFilterVenuesChange: handleFilterVenuesChange,
  };
}
