import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export const loadDataRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, currentPage] = oFetch(values, 'venueId', 'currentPage');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).get(`/api/v1/accessory-requests`, {
    params: {
      venueId,
      page: currentPage,
    },
  });
};

export const acceptAccessoryRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/accept`, {
    venueId,
    accessoryId,
  });
};

export const undoAccessoryRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/undo`, {
    venueId,
    accessoryId,
  });
};

export const rejectAccessoryRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/reject`, {
    venueId,
    accessoryId,
  });
};

export const acceptAccessoryRefundRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/accept-refund`, {
    venueId,
    accessoryId,
  });
};

export const undoAccessoryRefundRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/undo-refund`, {
    venueId,
    accessoryId,
  });
};

export const rejectAccessoryRefundRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/reject-refund`, {
    venueId,
    accessoryId,
  });
};

export const completeAccessoryRequestRequest = ({ venueId, accessoryId, requestId }) => {
  return http().post(`/api/v1/accessory-requests/${requestId}/complete`, {
    venueId,
    accessoryId,
  });
};

export const markReceivedAccessoryRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId] = oFetch(values, 'venueId', 'accessoryId', 'requestId');

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/mark_received`, {
    venueId,
    accessoryId,
  });
};

export const completeAccessoryRefundRequestRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, accessoryId, requestId, reusable] = oFetch(
    values,
    'venueId',
    'accessoryId',
    'requestId',
    'reusable',
  );

  return bossRequestHttp({
    spinner: true,
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(`/api/v1/accessory-requests/${requestId}/complete-refund`, {
    venueId,
    accessoryId,
    reusable,
  });
};
