import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';
import * as commonTypes from '../../../profile-wrapper/constants';

const initialState = {
  startDate: null,
  endDate: null,
};

export default handleActions(
  {
    [commonTypes.INITIAL_LOAD]: (state, action) => {
      const [startDate, endDate] = oFetch(action, 'payload.startDate', 'payload.endDate');
      return { startDate, endDate };
    },
    [types.CHANGE_FILTER]: (state, action) => {
      const [startDate, endDate] = oFetch(action, 'payload.startDate', 'payload.endDate');
      return { startDate, endDate };
    },
  },
  initialState,
);
