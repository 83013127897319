import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { RotaDate, RotaAppType, TRotaDate } from '@/lib/rota-date';
import moment from 'moment';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const momentRange = extendMoment(Moment);

type SelectOption = {
  label: string,
  value: string,
  asterisk: boolean,
  sortIndex: number,
};
type SelectOptionByTimeString = Record<string, SelectOption>;

export class RotaDateUIUtils {
  mStartTime: moment.Moment;

  mEndTime: moment.Moment;

  dStartTime: Date;

  dEndTime: Date;

  constructor(args: { rotaDate: TRotaDate }) {
    const rotaDate = oFetch(args, 'rotaDate');
    this.dStartTime = rotaDate.startTime();
    this.dEndTime = rotaDate.endTime();

    this.mStartTime = moment(this.dStartTime);
    this.mEndTime = moment(this.dEndTime);
  }

  static sFromDate(args: { sDate: string, appType: RotaAppType }): RotaDateUIUtils {
    const sDate = oFetch(args, 'sDate');
    const appType = oFetch(args, 'appType');
    return new RotaDateUIUtils({
      rotaDate: RotaDate.sFromDate({ sCalendarDate: sDate, appType }),
    });
  }

  static fromTime(args: { dTime: Date, appType: RotaAppType }): RotaDateUIUtils {
    const dTime = oFetch(args, 'dTime');
    const appType = oFetch(args, 'appType');
    return new RotaDateUIUtils({
      rotaDate: RotaDate.fromTime({ dTime, appType }),
    });
  }

  getTimeIntervals(params: { intervalSeconds: number }): string[] {
    const intervalSeconds = oFetch(params, 'intervalSeconds');
    const range = momentRange.range(this.mStartTime, this.mEndTime);

    const intervalLength = Array.from(
      range.by('seconds', { excludeEnd: true, step: intervalSeconds }),
    ).length;

    const mClonedStartTime = this.mStartTime.clone();

    const res = Array.from({ length: intervalLength }).map(() => {
      const momentAddedIntervalSeconds = mClonedStartTime.add(intervalSeconds, 'seconds');
      return momentAddedIntervalSeconds.format();
    });

    return [this.mStartTime.format(), ...res, this.mEndTime.format()].filter(
      (value, index, self) => self.indexOf(value) === index,
    );
  }

  getSelectOptions(params: { intervalSeconds: number }): SelectOption[] {
    const intervalSeconds = oFetch(params, 'intervalSeconds');
    const timeIntervals = this.getTimeIntervals({ intervalSeconds });
    const [first, ...withLast] = timeIntervals;
    if (!first) {
      throw new Error('no first time interval found');
    }
    const [last, ...withoutFirstAndLast] = withLast.reverse();
    if (!last) {
      throw new Error('no last time interval found');
    }

    const object: SelectOptionByTimeString = withoutFirstAndLast.reverse().reduce((acc: SelectOptionByTimeString, item, index) => {
      const hm = safeMoment.iso8601Parse(item).format('HH:mm');
      if (acc[hm] !== undefined) {
        acc[`*${hm}`] = {
          label: hm,
          value: item,
          asterisk: true,
          sortIndex: index + 1,
        };
      } else {
        acc[hm] = {
          label: hm,
          value: item,
          asterisk: false,
          sortIndex: index + 1,
        };
      }
      return acc;
    }, {});

    const optionsWithSortIndex = Object.values(object);

    const sortedBySortIndex = [
      {
        label: safeMoment.iso8601Parse(first).format('HH:mm'),
        value: first,
        sortIndex: 0,
        asterisk: false,
      },
      ...optionsWithSortIndex,
      {
        label: safeMoment.iso8601Parse(last).format('HH:mm'),
        value: last,
        sortIndex: oFetch(oFetch(optionsWithSortIndex, oFetch(optionsWithSortIndex, 'length') - 1), 'sortIndex') + 1,
        asterisk: false,
      },
    ].sort((a, b) => oFetch(a, 'sortIndex') - oFetch(b, 'sortIndex'));

    return sortedBySortIndex;
  }

  getGraphXAxisHours(): string[] {
    const timeIntervals = this.getTimeIntervals({ intervalSeconds: 3600 });
    return timeIntervals.map(item => safeMoment.iso8601Parse(item).format('H'));
  }

  mGetHoursSinceStartOfDay(mAt: moment.Moment): number {
    return mAt.diff(this.mStartTime, 'minutes') / 60.0;
  }

  dGetHoursSinceStartOfDay(dAt: Date): number {
    return moment(dAt).diff(this.mStartTime, 'minutes') / 60.0;
  }
}
