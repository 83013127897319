import React, { Component } from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';

export default class BossFormVenuesSelectField extends Component {
  getOtherVenuesOptions = (masterVenueValue, options) => {
    return options.filter(option => masterVenueValue !== oFetch(option, 'id'));
  };

  handleOtherVenueChange = (value, onChange) => {
    if (value) {
      return onChange(value.split(',').map(item => parseInt(item)));
    }
    return onChange([]);
  };

  handleMasterVenueChange = (value, otherVenuesValue, onChange, otherVenuesOnChange) => {
    if (value && otherVenuesValue.includes(value)) {
      otherVenuesOnChange(otherVenuesValue.filter(item => item !== value));
    }
    onChange(value);
  };

  renderMasterVenueField = (masterVenueName, otherVenuesName) => {
    const venueOptions = oFetch(this.props, 'options.accessibleVenueOptions');
    const [masterVenue, otherVenues] = oFetch(this.props, masterVenueName, otherVenuesName);
    const otherVenuesValue = oFetch(otherVenues, 'input.value');
    const otherVenuesOnChange = oFetch(otherVenues, 'input.onChange');

    const [name, value, onChange, touched] = oFetch(
      masterVenue,
      `input.name`,
      `input.value`,
      `input.onChange`,
      `meta.touched`,
    );
    const { labelKey, valueKey } = this.props;
    const clearable = this.props[`${masterVenueName}Clearable`];
    const placeholder = this.props[`${masterVenueName}Placeholder`];
    const { error } = this.props[`${masterVenueName}`].meta;
    return (
      <div className="boss-form__field">
        <label htmlFor="select-main-venue" className="boss-form__label">
          <span className="boss-form__label-text boss-form__label-text_type_required">
            Main Venue
          </span>
        </label>
        <div
          className={`boss-form__select ${touched && error ? 'boss-form__select_state_error' : ''}`}
        >
          <Select
            name={name}
            value={value}
            valueKey={valueKey || 'value'}
            labelKey={labelKey || 'label'}
            onChange={value =>
              this.handleMasterVenueChange(value, otherVenuesValue, onChange, otherVenuesOnChange)
            }
            clearable={!!clearable}
            simpleValue
            multi={false}
            placeholder={placeholder}
            options={venueOptions}
          />
        </div>
        {touched && error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
      </div>
    );
  };

  renderOtherVenuesField = (otherVenuesName, masterVenueName) => {
    const [otherVenues, masterVenue] = oFetch(this.props, otherVenuesName, masterVenueName);

    const [name, value, onChange, touched] = oFetch(
      otherVenues,
      `input.name`,
      `input.value`,
      `input.onChange`,
      `meta.touched`,
    );
    const masterVenueValue = oFetch(masterVenue, 'input.value');
    const venueOptions = this.getOtherVenuesOptions(
      masterVenueValue,
      oFetch(this.props, 'options.venueOptions'),
    );

    const { labelKey, valueKey } = this.props;
    const clearable = this.props[`${otherVenuesName}Clearable`];
    const placeholder = this.props[`${otherVenuesName}Placeholder`];
    const { error } = this.props[`${otherVenuesName}`].meta;
    return (
      <div className="boss-form__field">
        <label htmlFor="select-main-venue" className="boss-form__label">
          <span className="boss-form__label-text boss-form__label-text_type_required">
            Other Venues
          </span>
        </label>
        <div
          className={`boss-form__select ${touched && error ? 'boss-form__select_state_error' : ''}`}
        >
          <Select
            name={name}
            value={value}
            valueKey={valueKey || 'value'}
            labelKey={labelKey || 'label'}
            onChange={value => this.handleOtherVenueChange(value, onChange)}
            multi={true}
            clearable={!!clearable}
            simpleValue
            placeholder={placeholder}
            options={venueOptions}
          />
        </div>
        {touched && error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const names = oFetch(this.props, 'names');
    const [masterVenueName, otherVenuesName] = names;

    return (
      <div className="boss-form__field">
        {this.renderMasterVenueField(masterVenueName, otherVenuesName)}
        {this.renderOtherVenuesField(otherVenuesName, masterVenueName)}
      </div>
    );
  }
}
