import React from 'react';
import { Field, Form } from 'react-final-form';
import { PhotoField } from './photo-field';

export function PhotoForm(props: any) {
  const { initialValues, onSubmit } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      className="boss-form"
      render={({ handleSubmit, submitting, submitError, values, ...rest }) => {
        return (
          <>
            <Field<string>
              name="base64"
              component={PhotoField}
            />
            <div className="boss-form__field">
              <button
                className="boss-button boss-button_role_confirm boss-form__submit"
                type="button"
                onClick={handleSubmit}
                disabled={submitting || !values['base64']}
              >
                {submitting ? `Processing ...` : `Submit`}
              </button>
            </div>
          </>
        );
      }}
    />
  );
}
