import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import uuid from 'uuid/v1';

import * as types from '../types';

const initialState = [];
export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const mossFinanceReports = oFetch(action, 'payload.mossFinanceReports');
      return mossFinanceReports.map(mossFinanceReport => {
        return {
          ...mossFinanceReport,
          frontendId: oFetch(mossFinanceReport, 'id') || uuid(),
        };
      });
    },
    [types.MARK_REPORTS_COMPLETED]: (state, action) => {
      const updatedMossFinanceReports = oFetch(action, 'payload.mossFinanceReports');

      return state.map(report => {
        const newReport = updatedMossFinanceReports.find(newReport => {
          return oFetch(newReport, 'id') === oFetch(report, 'frontendId');
        });
        if (!newReport) {
          return report;
        } else {
          return { ...newReport, frontendId: oFetch(newReport, 'id') };
        }
      });
    },
    [types.UPDATE_MOSS_FINANCE_REPORT]: (state, action) => {
      const updatedMossFinanceReport = oFetch(action, 'payload.mossFinanceReport');

      return state.map(mossFinanceReport => {
        if (oFetch(mossFinanceReport, 'id') === oFetch(updatedMossFinanceReport, 'id')) {
          return updatedMossFinanceReport;
        }
        return mossFinanceReport;
      });
    },
  },
  initialState,
);
