import React from 'react';
import { ALL_FILTER_TYPE, ENABLED_FILTER_TYPE, MAIN_QR_CODE_TYPE, MossPartyPeopleIndexProps, NonStaffMemberStaffPartyPerson, PLUS_1_QR_CODE_TYPE } from '../types';
import { Pagination } from '@/components/boss-pagination-new';
import { PaginationRangeValue } from '@/components/boss-pagination-new/use-pagination';
import {
  mossPartyPeopleIndex as importedMossPartyPeopleIndex,
} from '@/lib/app-routes';
// creating this to make calls clearer to understand
const appRoutes = {
  mossPartyPeopleIndex: importedMossPartyPeopleIndex,
};
import { useNonStaffMemberStaffPartyPeople } from '../hooks';
import { IndexPageFilter } from './index-page-filter';
import { applyBossRoute } from '@/lib/apply-boss-route';
import {
  resendStaffPartyPersonInviteEmail as importResendStaffPartyPersonInviteEmail,
} from '@/lib/api-routes';
// creating this to make it easier to see these are routes
const apiRoutes = {
  resendStaffPartyPersonInviteEmail: importResendStaffPartyPersonInviteEmail,
};
import { bossRequestHttp } from '@/lib/request-api';

const resendInviteEmail = (nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) => () => {
  const bossRequestInstance = bossRequestHttp({
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;

      globalNotifications.showDefaultFailureMessage();
      return false;
    },
    successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
      const globalNotifications = params.globalNotifications;

      globalNotifications.showDefaultSuccessMessage();
      return false;
    }
  });

  const route = apiRoutes.resendStaffPartyPersonInviteEmail;

  return applyBossRoute({
    route: route,
    callParams: {},
    pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
    bossHttpRequest: bossRequestInstance,
  });
};

export function IndexPage(props: MossPartyPeopleIndexProps) {
  const pagination = props.pagination;
  const initialFilterValues = props.filter.values;
  const filtering = props.filter.filtering;

  const partyPeopleCount = props.nonStaffMemberStaffPartyPeople.length;
  const partyPeopleExist = partyPeopleCount > 0;
  const showStatuses = props.filter.values.filterType === ALL_FILTER_TYPE;
  const filteringByEnabled = props.filter.values.filterType === ENABLED_FILTER_TYPE;

  const {
    nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal,
    openCreateModal,
    getOpenShowQRCodeModal,
    getOpenUpdateModal,
    getOpenDeleteModal,
    getEnableNonStaffMemberStaffPartyPerson,
  } = useNonStaffMemberStaffPartyPeople({
    nonStaffMemberStaffPartyPeople: props.nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal: props.pagination.totalCount,
    mossStaffMemberOptions: props.mossStaffMemberOptions,
    staffPartyQuizTeamOptions: props.staffPartyQuizTeamOptions,
  });

  const renderPartyPeople = (nonStaffMemberStaffPartyPeople: NonStaffMemberStaffPartyPerson[]) => {
    return nonStaffMemberStaffPartyPeople.map((nonStaffMemberStaffPartyPerson, index) => {
      const enabled = nonStaffMemberStaffPartyPerson.enabled;
      const hasEmail = nonStaffMemberStaffPartyPerson.email !== null;
      const quizUrl = `https://staff-party.jsmbars.co.uk/auth/${nonStaffMemberStaffPartyPerson.guid}`;
      const plus1QuizUrl = `https://staff-party.jsmbars.co.uk/auth/${nonStaffMemberStaffPartyPerson.plus1Guid}`;

      return (
        <li key={index}>
          { nonStaffMemberStaffPartyPerson.avatarUrl && <div><img src={nonStaffMemberStaffPartyPerson.avatarUrl ?? ''} /></div> }
          { enabled && <div>
            <button
              onClick={getOpenShowQRCodeModal({
                nonStaffMemberStaffPartyPerson,
                qrCodeType: MAIN_QR_CODE_TYPE,
              })}
              type="button"
              className="boss-button boss-button_type_small boss-button_role_view-qrcode boss-board__action"
            >
              QR Code
            </button>
          </div> }
          { enabled && <div>
            <button
              onClick={getOpenShowQRCodeModal({
                nonStaffMemberStaffPartyPerson,
                qrCodeType: PLUS_1_QR_CODE_TYPE,
              })}
              type="button"
              className="boss-button boss-button_type_small boss-button_role_view-qrcode boss-board__action"
            >
              +1 QR Code
            </button>
          </div> }
          { showStatuses && <div><span>Status: </span><span style={{ background: nonStaffMemberStaffPartyPerson.enabled ? 'green' : 'red', color: 'white' }}>{nonStaffMemberStaffPartyPerson.enabled ? 'Enabled' : 'disabled' }</span></div> }
          <div><span>Name: </span>{nonStaffMemberStaffPartyPerson.fullName}</div>
          <div><span>Job Title: </span> {nonStaffMemberStaffPartyPerson.jobTitleName}</div>
          { nonStaffMemberStaffPartyPerson.email && <div>
            <span>Email: </span>{nonStaffMemberStaffPartyPerson.email}
          </div> }
          { nonStaffMemberStaffPartyPerson.phoneNumber && <div>
            <span>Phone Number: </span>{nonStaffMemberStaffPartyPerson.phoneNumber}
          </div> }
          <div><span>Quiz Team: </span>{ enabled ? nonStaffMemberStaffPartyPerson.staffPartyQuizTeamName : 'N/A' }</div>
          <div><span>Quiz Link: </span> <a href={quizUrl}>{quizUrl}</a></div>
          <div><span>Plus 1 Quiz Link: </span> <a href={plus1QuizUrl}>{plus1QuizUrl}</a></div>
          { enabled && hasEmail && <div><a onClick={ resendInviteEmail(nonStaffMemberStaffPartyPerson) }>Resend Invite Email</a></div> }
          { enabled && <div>
            <button
              onClick={getOpenUpdateModal(nonStaffMemberStaffPartyPerson)}
              type="button"
              className="boss-button boss-button_type_small boss-button_role_edit boss-board__action"
            >
              Edit
            </button>
          </div> }
          { !enabled && <div>
            <button
              onClick={getEnableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson)}
              type="button"
              className={`boss-button boss-button_type_small boss-button_role_enable  boss-board__action`}
            >
              Enable
            </button>
          </div> }
          { enabled && <div>
            <button
              onClick={getOpenDeleteModal(nonStaffMemberStaffPartyPerson, filteringByEnabled)}
              type="button"
              className="boss-button boss-button_type_small boss-button_role_delete boss-board__action"
            >
              Delete
            </button>
          </div> }
        </li>
      );
    });
  };

  const onPageChange = (pageNumber: PaginationRangeValue) => {
    const effectivePaginationPage = pageNumber === '...' ? 1 : pageNumber;
    let mossPartyPeopleIndexArgs;
    if (filtering === true) {
      mossPartyPeopleIndexArgs = {
        paginationPage: effectivePaginationPage,
        filtering: filtering,
        name: initialFilterValues.name,
        email: initialFilterValues.email,
        filterType: initialFilterValues.filterType,
      };
    } else {
      mossPartyPeopleIndexArgs = {
        paginationPage: effectivePaginationPage,
        filtering: filtering,
      };
    }

    window.location.href = appRoutes.mossPartyPeopleIndex(mossPartyPeopleIndexArgs);
  };

  return (
    <div className="boss-page-main">
      <div className="boss-page-main__dashboard">
        <div className="boss-page-main__inner">
          <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_staff-members-index">
            <div className="boss-page-dashboard__group">
              <div className="boss-page-dashboard__title">
                <span className="boss-page-dashboard__title-text">Moss Party People</span>
                <span className="boss-page-dashboard__title-info">{nonStaffMemberStaffPartyPeopleTotal}</span>
              </div>

              <div className="boss-page-dashboard__buttons-group">
                <a
                  onClick={openCreateModal}
                  className="boss-button boss-button_role_add boss-page-dashboard__button"
                >Add New</a>
              </div>
            </div>
            <IndexPageFilter
              filtering={props.filter.filtering}
              initialFilterValues={initialFilterValues}
            />
          </div>
        </div>
      </div>
      <div>
        { !partyPeopleExist && <p>No Party People Exist.</p> }
        { partyPeopleExist && <>
            <ul>
              { renderPartyPeople(nonStaffMemberStaffPartyPeople) }
            </ul>
            <Pagination
              onPageChange={onPageChange}
              totalCount={nonStaffMemberStaffPartyPeopleTotal}
              pageSize={pagination.pageSize}
              currentPage={pagination.currentPage}
            />
          </> }
      </div>
  </div>
  );
}