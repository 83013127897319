import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const accessoriesIconsSelector = state => state.getIn(['page', 'accessoriesIcons']);
export const accessoriesColorsSelector = state => state.getIn(['page', 'accessoriesColors']);
export const getAccessoriesIconsOptions = createSelector([accessoriesIconsSelector], accessoriesIcons => {
  return Object.keys(accessoriesIcons.toJS()).map(key => {
    return {
      value: key,
      label: accessoriesIcons.get(key),
    };
  });
});
export const getAccessoriesColorsOptions = createSelector([accessoriesColorsSelector], accessoriesColors => {
  return Object.keys(accessoriesColors.toJS()).map(key => {
    const colorData = accessoriesColors.get(key).toJS();
    const [label, color] = oFetch(colorData, 'label', 'color');
    return {
      value: key,
      label,
      color,
    };
  });
});
