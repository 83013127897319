import React, { Component } from 'react';
import oFetch from 'o-fetch';
import Sortable from 'react-sortablejs';

export default class SortableList extends Component {
  renderItems = () => {
    const [items, eventRenderer, reminderRenderer, taskRenderer] = oFetch(
      this.props,
      'items',
      'eventRenderer',
      'reminderRenderer',
      'taskRenderer',
    );

    return items.map(item => {
      const type = oFetch(item, 'type');

      if (type === 'event') {
        const id = oFetch(item, 'id');
        return React.cloneElement(eventRenderer(item), { key: `${type}-${id}` });
      } else if (type === 'reminder-date') {
        // item is ReminderDate but we sort using handoverPlannerReminderId
        const id = oFetch(item, 'handoverPlannerReminderId');
        return React.cloneElement(reminderRenderer(item), { key: `${type}-${id}` });
      } else if (type === 'task') {
        const id = oFetch(item, 'id');
        return React.cloneElement(taskRenderer(item), { key: `${type}-${id}` });
      } else {
        throw new Error('unsupported type' + type + "encountered")
      }
    });
  };
  render() {
    const onChange = oFetch(this.props, 'onChange');
    return (
      <Sortable
        options={{
          handle: '.boss-check__handle',
          animation: 250,
        }}
        onChange={onChange}
        className="boss-page-main__sub-group"
      >
        {this.renderItems()}
      </Sortable>
    );
  }
}
