import React from 'react';
import z from 'zod';
import { Form, Field } from 'react-final-form';

export const $OnSubmitValues = z.object({
  note: z.string(),
}).strict();

export type OnSubmitValues = z.infer<typeof $OnSubmitValues>;

type NoteFormProps = {
  onSubmit: (values: OnSubmitValues) => void;
  note: string;
  edit: boolean;
};

export function NoteForm(props: NoteFormProps) {
  const { onSubmit, note, edit } = props;

  return (
    <Form<OnSubmitValues>
      onSubmit={(values: unknown) => {
        const parsedValues = $OnSubmitValues.parse(values);
        onSubmit(parsedValues);
      }}
      initialValues={{ note }}
      render={({ handleSubmit, submitting }) => {
        return (
          <div className="boss-modal-window__form">
            <form className="boss-form">
              <div className="boss-form__field">
                <Field
                  name="note"
                  parse={(value: unknown) => value}
                  component="textarea"
                  className="boss-form__textarea boss-form__textarea_size_large"
                />
              </div>
              <div className="boss-form__field">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="boss-button boss-button_role_add boss-form__submit"
                >
                  {edit ? 'Update Note' : 'Add Note'}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    />
  );
}
