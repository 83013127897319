// e.g. 02 Jan 1900
export const FINANCE_REPORT_TABLE_DATE_FORMAT = 'dd MMM yyyy' as const;
// e.g.	Tuesday
export const FINANCE_REPORT_MONTH_DATE_FORMAT = 'EEEE' as const;
// e.g. Mon 23-01-2023
export const COMMON_DATE_FORMAT = 'EEE dd MMM yyyy' as const;
// e.g. 23-01-2023
export const UI_DATE_FORMAT = 'dd-MM-yyyy' as const;
// e.g. 23:22 Sun, 14-01-2023
export const HUMAN_DATE_FORMAT_WITH_TIME = 'HH:mm EEE, dd MMM yyyy' as const;
// e.g. 14/12/2023
export const SHORT_SLASH_DATE_FORMAT = 'dd/MM/yyyy' as const;
// e.g. 13:00
export const SHORT_TIME_FORMAT = 'HH:mm' as const;
// e.g. ""
export const NULL_DATE_FORMAT = '' as const;