import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccessoriesPage from '../components/accessories-page';
import { getHistoryByAccessoryId } from '../redux/reducers';
import { getAccessoriesIconsOptions, getAccessoriesColorsOptions } from '../selectors';
import {
  loadInitialData,
  createAccessory,
  updateAccessory,
  disableAccessory,
  restoreAccessory,
  loadMoreClick,
  filter,
  updateAccessoryFreeItems,
} from '../redux/actions';

const mapStateToProps = state => {
  return {
    accessories: state.getIn(['page', 'accessories']),
    pagination: state.getIn(['page', 'pagination']),
    getHistoryByAccessoryId: getHistoryByAccessoryId(state),
    accessoriesIconsOptions: getAccessoriesIconsOptions(state),
    accessoriesColorsOptions: getAccessoriesColorsOptions(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        loadInitialData,
        createAccessory,
        updateAccessory,
        disableAccessory,
        restoreAccessory,
        loadMoreClick,
        filter,
        updateAccessoryFreeItems,
      },
      dispatch,
    ),
  };
};

function AccessoriesPageContainer(props) {
  useEffect(() => {
    props.actions.loadInitialData();
  }, []);

  const mappedProps = {
    ...props,
    accessories: props.accessories.toJS(),
    pagination: props.pagination.toJS(),
  };

  return <AccessoriesPage {...mappedProps} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesPageContainer);
