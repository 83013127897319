import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';
import { ALL_CATEGORIES } from '../../constants';

const initialState = ALL_CATEGORIES;

export default handleActions(
  {
    [types.CHANGE_CATEGORY_FILTER]: (state, action) => {
      const payload = oFetch(action, 'payload');
      return payload;
    },
  },
  initialState,
);
