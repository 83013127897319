import React from 'react';
import { Form, Field } from 'react-final-form';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { FORM_ERROR } from 'final-form';
import { $UpdateFormValues, UpdateFormProps, UpdateFormValues } from '../types';

export function UpdateNonStaffMemberStaffPartyPersonForm<T extends UpdateFormProps>(props: T) {
  // const { jobOptions, staffPartyQuizTeamOptions } = useGlobal();

  return (
    <>
      <Form<UpdateFormValues>
        onSubmit={(rawValues: unknown) => {
          const values = $UpdateFormValues.parse(rawValues);
          return props.onSubmit({
            id: props.id,
            values,
          });
        }}
        initialValues={props.initialValues}
        render={(renderProps) => {
          const canSubmit = !renderProps.submitting || !renderProps.pristine;
          const baseErrors: string[] = (renderProps.hasSubmitErrors && (renderProps.submitErrors || {})[FORM_ERROR]) || [];
          const hasBaseErrors = baseErrors.length > 0;
          const mossStaffMemberOption = props.mossStaffMemberOption;

          return (
            <>
              {hasBaseErrors && <div>Base Error: {baseErrors.join(', ')}</div>}
              <div>
                <h3>Name</h3>
                <div>{mossStaffMemberOption.mossStaffMemberName}</div>
              </div>
              <div>
                <h3>Staff Type</h3>
                <div>{mossStaffMemberOption.mossStaffTypeName}</div>
              </div>
              <Field
                name="phoneNumber"
                label="Phone Number"
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Field
                name="email"
                label="Email Address"
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Field
                clearable={false}
                name='staffPartyQuizTeamId'
                label='Staff Party Quiz Team'
                parse={(value: any) => value}
                options={props.staffPartyQuizTeamOptions}
                value={props.initialValues.staffPartyQuizTeamId}
                valueKey='value'
                labelKey='name'
                component={BossFormSelectNew}
              />

              <button
                onClick={renderProps.handleSubmit}
                disabled={!canSubmit}
              >Submit</button>
            </>
          );
        }}
      />
    </>
  );
}
