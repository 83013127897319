import React, { useState } from 'react';
import { Field, Fields, reduxForm } from 'redux-form/immutable';
import StaffMemberInfo from './staff-member-info';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import cn from 'classnames';
import ReactIScroll from 'react-iscroll';
import utils from '@/lib/utils';
import { Collapse } from 'react-collapse';
import { connect } from 'react-redux';
import _ from 'underscore';

import { ErrorBlock, BossFormShiftTimeInput, BossFormCheckbox } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

const scrollOptions = {
  scrollbars: true,
  mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

const mapStateToProps = state => {
  const rotasJS = oFetch(state.get('page').toJS(), 'rotas');
  return { rotasJS: rotasJS };
};

function GraphDetailsForm(props) {
  const [isRotaShiftDetailsOpened, setIsRotaShiftDetailsOpened] = useState(true);
  const [isHolidaysDetailsOpened, setisHolidaysDetailsOpened] = useState(true);
  const onSubmit = oFetch(props, 'onSubmit');

  function updateShift(values, dispatch, props) {
    return onSubmit(values, dispatch, props, 'update');
  }

  function deleteShift(values, dispatch, props) {
    return onSubmit(values, dispatch, props, 'delete');
  }

  function toggleShiftDetails() {
    setIsRotaShiftDetailsOpened(!isRotaShiftDetailsOpened);
  }

  function toggleHolidayDetails() {
    setisHolidaysDetailsOpened(!isHolidaysDetailsOpened);
  }

  function getOrderedWeekRotaShifts(weekRotaShifts) {
    return _(weekRotaShifts).sortBy(item => item.starts_at);
  }

  const [handleSubmit, staffMember, staffType, submitting, rotasJS] = oFetch(
    props,
    'handleSubmit',
    'staffMember',
    'staffType',
    'submitting',
    'rotasJS'
  );

  const { error } = props;
  const staffMemberJS = staffMember.toJS();
  const staffTypeJS = staffType.toJS();
  const holidaysInWeek = oFetch(staffMemberJS, 'holidaysOnWeek');
  const weekHolidays = oFetch(staffMemberJS, 'holidays');
  const hasHoldays = oFetch(weekHolidays, 'length') > 0;
  const hoursInWeek = oFetch(staffMemberJS, 'hoursOnWeek');
  const weekRotaShifts = oFetch(staffMemberJS, 'weekRotaShifts');
  const hasRotaShifts = oFetch(weekRotaShifts, 'length') > 0;
  const shiftDetailsSwitchClass = isRotaShiftDetailsOpened ? 'boss-summary__switch_state_opened' : '';
  const holidayDetailsSwitchClass = isHolidaysDetailsOpened ? 'boss-summary__switch_state_opened' : '';
  const isSteward = oFetch(staffMemberJS, 'isSteward');
  const isSecurity = oFetch(staffMemberJS, 'isSecurity');
  const timeSelectOptions = oFetch(useLegacyGlobal(), 'timeSelectOptions');

  return (
    <div>
      <div className="boss-modal-window__group">
        <StaffMemberInfo
          avatarUrl={oFetch(staffMemberJS, 'avatar_url')}
          fullName={`${oFetch(staffMemberJS, 'first_name')} ${oFetch(staffMemberJS, 'surname')}`}
          staffType={oFetch(staffTypeJS, 'name')}
          staffColor={oFetch(staffTypeJS, 'color')}
          supportsFacialRecognition={oFetch(staffMemberJS, 'supportsFacialRecognition')}
          isSteward={isSteward}
        />
      </div>
      <div className="boss-modal-window__group">
        {error && <ErrorBlock error={error} />}
        <div className="boss-form">
          <Fields
            names={['starts_at', 'ends_at']}
            component={BossFormShiftTimeInput}
            options={timeSelectOptions}
          />
          <div className="boss-form__row">
            <Field
              name="shift_type"
              label="Standby"
              type="checkbox"
              format={value => value === 'standby'}
              normalize={value => (value ? 'standby' : 'normal')}
              component={BossFormCheckbox}
            />
            {isSecurity && (
              <Field
                name="head_doorman_hour"
                label="Head Doorman"
                component={BossFormCheckbox}
              />
            )}
          </div>
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              type="button"
              onClick={handleSubmit(updateShift)}
              disabled={submitting}
              className="boss-button boss-button_role_update boss-form__button_adjust_max boss-form__button_adjust_row"
            >
              Update
            </button>
            <button
              type="button"
              onClick={handleSubmit(deleteShift)}
              disabled={submitting}
              className="boss-button boss-button_role_delete boss-form__button_adjust_max"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Shifts</span>
        </p>
        <div className="boss-summary">
          <div className="boss-summary__header">
            <p className="boss-summary__text">
              Hours Scheduled <span className="boss-summary__text-marked">{hoursInWeek}h</span>
            </p>
            {hasRotaShifts && (
              <div
                onClick={toggleShiftDetails}
                className={`boss-summary__switch ${shiftDetailsSwitchClass}`}
              />
            )}
          </div>
          {hasRotaShifts && (
            <Collapse
              isOpened={isRotaShiftDetailsOpened}
              style={{ display: 'block' }}
            >
              <div
                className="boss-summary__content"
                style={{ touchAction: 'none', height: '180px' }}
              >
                <ReactIScroll
                  iScroll={iScroll}
                  options={scrollOptions}
                >
                  <div className="boss-summary__content-inner">
                    <ul className="boss-summary__list">
                      {getOrderedWeekRotaShifts(weekRotaShifts).map(rotaShift => {
                        const id = oFetch(rotaShift, 'id');
                        const mStartTime = safeMoment.iso8601Parse(oFetch(rotaShift, 'starts_at'));
                        const mEndTime = safeMoment.iso8601Parse(oFetch(rotaShift, 'ends_at'));
                        const rotaId = oFetch(rotaShift, 'rota');
                        const rota = rotasJS.find(rota => {
                          return oFetch(rota, 'id') === rotaId;
                        });
                        const mDate = safeMoment.uiDateParse(oFetch(rota, 'date'));
                        const venueName = oFetch(rota, 'venueName');
                        const headDoorman = oFetch(rotaShift, 'head_doorman_hour');
                        const shiftItemClassNames = cn('boss-summary__item', {
                          'boss-summary__item-head-doorman': headDoorman,
                        });
                        return (
                          <li
                            key={id}
                            className={shiftItemClassNames}
                          >
                            <p className="boss-summary__text">
                              {utils.rotaCondensedShiftDescription({
                                mDate,
                                mStartTime,
                                mEndTime,
                              })}
                            </p>
                            <p className="boss-summary__text boss-summary__text_faded">({venueName})</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </ReactIScroll>
              </div>
            </Collapse>
          )}
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Holidays</span>
        </p>
        <div className="boss-summary">
          <div className="boss-summary__header">
            <p className="boss-summary__text">
              Holiday Booked <span className="boss-summary__text-marked">{holidaysInWeek}d</span>
            </p>
            {hasHoldays && (
              <div
                onClick={toggleHolidayDetails}
                className={`boss-summary__switch ${holidayDetailsSwitchClass}`}
              />
            )}
          </div>
          {hasHoldays && (
            <Collapse
              isOpened={isHolidaysDetailsOpened}
              style={{ display: 'block' }}
            >
              <div
                className="boss-summary__content"
                style={{ touchAction: 'none', height: '180px' }}
              >
                <ReactIScroll
                  iScroll={iScroll}
                  options={scrollOptions}
                >
                  <div className="boss-summary__content-inner">
                    <ul className="boss-summary__list">
                      {weekHolidays.map(holiday => {
                        const id = oFetch(holiday, 'id');
                        const mStartDate = safeMoment.uiDateParse(oFetch(holiday, 'start_date'));
                        const mEndDate = safeMoment.uiDateParse(oFetch(holiday, 'end_date'));

                        return (
                          <li
                            key={id}
                            className="boss-summary__item"
                          >
                            <p className="boss-summary__text">
                              {utils.rotaCondensedHolidayDescription({ mStartDate, mEndDate })}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </ReactIScroll>
              </div>
            </Collapse>
          )}
        </div>
      </div>
      <div className="boss-modal-window__group">
        <p className="boss-modal-window__group-label">
          <span>Preferences</span>
        </p>
        <div className="boss-summary">
          <ul className="boss-summary__list">
            <li className="boss-summary__item boss-summary__item_layout_row boss-summary__item_role_header">
              <p className="boss-summary__text boss-summary__text_context_row">Weekly Hours:</p>
              <p className="boss-summary__text boss-summary__text_marked">
                {oFetch(staffMemberJS, 'preferred_hours')}
              </p>
            </li>
            <li className="boss-summary__item boss-summary__item_layout_row">
              <p className="boss-summary__text boss-summary__text_context_row">Day Preferences:</p>
              <p className="boss-summary__text boss-summary__text_marked">
                {oFetch(staffMemberJS, 'preferred_days')}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  fields: ['shift_id', 'staff_member_id'],
  form: 'graph-details-form',
})(connect(mapStateToProps)(GraphDetailsForm));
