import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import cn from 'classnames';
import oFetch from 'o-fetch';

export function VenueCard(props) {
  const [venueName, devices, children] = oFetch(props, 'venueName', 'devices', 'children');
  const hasPendingDevices = devices.some(device => oFetch(device, 'isPending'));
  const hasHighlightedDevices = devices.some(device => oFetch(device, 'highlighted'));

  const [isOpen, setIsOpen] = useState(hasPendingDevices || hasHighlightedDevices);
  const devicesCount = devices.length;

  useEffect(() => {
    setIsOpen(hasPendingDevices || hasHighlightedDevices);
  }, [hasPendingDevices, hasHighlightedDevices]);

  function toggleCard() {
    setIsOpen(!isOpen);
  }

  const buttonClassNames = cn('boss-board__switch', { 'boss-board__switch_state_opened': isOpen });
  const indicatorClassNames = cn('boss-indicator', {
    'boss-indicator_access_granted': !hasPendingDevices,
    'boss-indicator_access_pending': hasPendingDevices,
  });
  return (
    <section className="boss-board boss-board_role_devices-venue boss-board_context_stack">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_role_venue">
          <span className="boss-board__title-text">{venueName}</span>
          <span className="boss-board__title-indicator">
            <span className={indicatorClassNames}>
              <span className="boss-indicator__marker">{devicesCount}</span>
            </span>
          </span>
        </h2>
        <div className="boss-board__button-group">
          <button onClick={toggleCard} type="button" className={buttonClassNames} />
        </div>
      </header>
      <Collapse
        isOpened={isOpen}
        className="boss-board__content boss-board__content_state_opened"
        style={{ display: 'block' }}
      >
        <div className={'boss-board__content-inner'}>{children(devices)}</div>
      </Collapse>
    </section>
  );
}
