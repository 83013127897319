import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper, LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import utils from '@/lib/utils';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import {
  PersonalDetailsForm,
  NotLinkedUserAccessDetailsForm,
  DisableUserForm,
  AddNewAppForm,
  TemporaryItemForm,
  LinkedUserRoleForm,
  VenuePermissionDetails,
  DisableAppWarning,
} from './components';
import * as requests from './requests';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export function userProfileHooks(params) {
  const [initialUser, initialAppAccessData] = oFetch(params, 'initialUser', 'initialAppAccessData');
  const initialJsmSyncEnabled = oFetch(params, 'initialJsmSyncEnabled');
  const [venues, apps] = oFetch(useLegacyGlobal(), 'venues', 'apps');
  const [user, setUser] = useState(initialUser);
  const [jsmSyncEnabled, setJsmSyncEnabled] = useState(initialJsmSyncEnabled);
  const [forceSyncInProgress, setForceSyncInProgress] = useState(false);
  const [appAccessData, setAppAccessData] = useState(initialAppAccessData);
  const [notPersistedAppAccessData, setNotPersistedAppAccessData] = useState([]);
  const { openModal } = useModal();

  function addAppAccessData(data) {
    setAppAccessData(prevAppAccessData => {
      return [...prevAppAccessData, data];
    });
  }

  function updateAppAccessData(data) {
    setAppAccessData(prevAppAccessData => {
      return prevAppAccessData.map(oldDataItem => {
        if (oFetch(oldDataItem, 'id') === oFetch(data, 'id')) {
          return data;
        }
        return oldDataItem;
      });
    });
  }

  function removeAppPermissionAccessData(permissionId) {
    setAppAccessData(prevAppAccessData => {
      return prevAppAccessData.reduce((acc, oldDataItem) => {
        if (oFetch(oldDataItem, 'id') === permissionId) {
          return acc;
        }
        return [...acc, oldDataItem];
      }, []);
    });
  }

  function replaceAppPermissions(params) {
    const [appId, permissions] = oFetch(params, 'appId', 'permissions');
    setAppAccessData(prevAppAccessData => {
      return [...prevAppAccessData.filter(dataItem => oFetch(dataItem, 'appId') !== appId), ...permissions];
    });
  }

  function replaceAppPermanentPermissions(params) {
    const [appId, backendPermanentPermissions] = oFetch(params, 'appId', 'backendPermanentPermissions');
    setAppAccessData(prevAppAccessData => {
      const anotherAppsPermissions = prevAppAccessData.filter(item => oFetch(item, 'appId') !== appId);
      const appTimedPermissions = prevAppAccessData.filter(
        item =>
          oFetch(item, 'appId') === appId &&
          (oFetch(item, 'isTemporaryUser') || oFetch(item, 'isTemporaryAi')),
      );

      return [...anotherAppsPermissions, ...appTimedPermissions, ...backendPermanentPermissions];
    });
  }

  function removeNonPersistedApp(params) {
    const appId = oFetch(params, 'appId');
    setNotPersistedAppAccessData(prevNotPersistedAppAccessData => {
      return prevNotPersistedAppAccessData.filter(
        prevNotPersistedAppAccessDataItem => oFetch(prevNotPersistedAppAccessDataItem, 'appId') !== appId,
      );
    });
  }

  const normalizedAppsPermissions = useMemo(
    () =>
      Object.entries(utils.groupBy('appId')(appAccessData)).reduce((acc, entry) => {
        const [id, permissions] = entry;
        const permanentPermissions = permissions.filter(permission => oFetch(permission, 'isPermanent'));
        const timedPermissions = permissions.filter(
          permission => oFetch(permission, 'isTemporaryUser') || oFetch(permission, 'isTemporaryAi'),
        );
        const appVenuesOnTitle = Object.entries(utils.groupBy('venueName')(permissions)).map(venueEntry => {
          const [venueName, permissions] = venueEntry;
          const hasPermanent = permissions.some(permission => oFetch(permission, 'isPermanent'));
          return {
            venueName,
            hasPermanent,
            timedPermissions: permissions.filter(
              permission => oFetch(permission, 'isTemporaryUser') || oFetch(permission, 'isTemporaryAi'),
            ),
          };
        });

        const appId = parseInt(id);
        const app = apps.find(_app => oFetch(_app, 'id') === appId);
        const data = {
          appId,
          name: oFetch(app, 'title'),
          appTypes: oFetch(app, 'appTypes'),
          appVenuesOnTitle: appVenuesOnTitle,
          permanentAccessVenues: permanentPermissions.map(permission => {
            return {
              venueId: oFetch(permission, 'venueId'),
              venueName: oFetch(permission, 'venueName'),
            };
          }),
          persisted: true,
          temporaryAccess: timedPermissions.map(timedPermission => {
            return {
              id: oFetch(timedPermission, 'id'),
              venueId: oFetch(timedPermission, 'venueId'),
              venueName: oFetch(timedPermission, 'venueName'),
              startTime: oFetch(timedPermission, 'startTime'),
              endTime: oFetch(timedPermission, 'endTime'),
              startDate: oFetch(timedPermission, 'startDate'),
              endDate: oFetch(timedPermission, 'endDate'),
              isPermanent: oFetch(timedPermission, 'isPermanent'),
              isTemporaryAi: oFetch(timedPermission, 'isTemporaryAi'),
              isTemporaryUser: oFetch(timedPermission, 'isTemporaryUser'),
              note: oFetch(timedPermission, 'note'),
              createdAt: oFetch(timedPermission, 'createdAt'),
            };
          }),
        };
        return [...acc, data];
      }, []),
    [appAccessData],
  );

  const normalizedNotPersistedAppAccessData = useMemo(() => {
    const appIds = normalizedAppsPermissions.map(item => oFetch(item, 'appId'));
    return notPersistedAppAccessData.filter(item => !appIds.includes(oFetch(item, 'appId')));
  }, [normalizedAppsPermissions, notPersistedAppAccessData]);

  const appPermissions = useMemo(() => {
    return [...normalizedNotPersistedAppAccessData, ...normalizedAppsPermissions];
  }, [normalizedNotPersistedAppAccessData, normalizedAppsPermissions]);

  const appsVenuesPermanentAccessIds = useMemo(() => {
    return appPermissions.reduce((acc, appPermission) => {
      const permanentAccessVenueIds = oFetch(appPermission, 'permanentAccessVenues').map((venueAccess) => oFetch(venueAccess, 'venueId'));
      return [...acc, ...permanentAccessVenueIds];
    }, []).filter(onlyUnique);
  }, [appPermissions]);

  const appsOptions = useMemo(() => {
    const existingApps = appPermissions.map(appAccessDataItem => oFetch(appAccessDataItem, 'appId'));
    return apps
      .filter(app => !existingApps.includes(oFetch(app, 'id')))
      .map(app => {
        const [appId, appTitle, appTypes] = oFetch(app, 'id', 'title', 'appTypes');
        return {
          value: appId,
          label: appTitle,
          appTypes,
        };
      });
  }, [appPermissions]);

  const normalizedUser = useMemo(() => {
    if (oFetch(user, 'isLinked')) {
      return user;
    } else {
      const userVenues = venues.filter(venue => oFetch(user, 'venueIds').includes(oFetch(venue, 'id')));
      const hasVenuesAccess = userVenues.length > 0;

      return {
        ...user,
        venues: userVenues,
        venuesString: hasVenuesAccess ? userVenues.map(venue => oFetch(venue, 'name')).join(', ') : 'N/A',
      };
    }
  }, [user]);

  function openPersonalDetailsEditModal() {
    const fullName = oFetch(normalizedUser, 'fullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PersonalDetailsForm,
      onSubmit: (closeModal, values) => {
        return requests.updatePersonalDetailsRequest({
          values: values,
          onSuccess(data) {
            const user = oFetch(data, 'user');
            setUser(user);
            closeModal();
          },
        });
      },
      config: {
        title: `Edit Personal Details: ${fullName}`,
      },
      props: {
        userIsLinked: oFetch(user, 'isLinked'),
        initialValues: {
          userId: oFetch(user, 'id'),
          firstName: oFetch(user, 'firstName'),
          surname: oFetch(user, 'surname'),
          email: oFetch(user, 'email'),
          bypassJsmMailbox: oFetch(user, 'bypassJsmMailbox'),
        },
      },
    });
  }

  function openAccessDetailsEditModal() {
    const fullName = oFetch(normalizedUser, 'fullName');
    const userUnlinkedApplicationTypes = oFetch(normalizedUser, 'unlinkedApplicationTypes');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: NotLinkedUserAccessDetailsForm,
      onSubmit: (closeModal, values) => {
        return requests.updateAccessDetailsRequest({
          values: values,
          onSuccess(data) {
            const user = oFetch(data, 'user');
            setUser(user);
            closeModal();
          },
        });
      },
      config: {
        title: `Edit Access Details: ${fullName}`,
      },
      props: {
        userUnlinkedApplicationTypes,
        initialValues: {
          userId: oFetch(user, 'id'),
          role: oFetch(user, 'role'),
          venues: oFetch(user, 'venueIds'),
        },
      },
    });
  }

  function openEditRoleModal() {
    const fullName = oFetch(normalizedUser, 'fullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: LinkedUserRoleForm,
      onSubmit: (closeModal, values) => {
        return requests.updateUserRoleRequest({
          values: values,
          onSuccess(data) {
            const user = oFetch(data, 'user');
            setUser(user);
            setAppAccessData([]);
            closeModal();
          },
        });
      },
      config: {
        title: `Edit Role: ${fullName}`,
      },
      props: {
        initialValues: {
          userId: oFetch(user, 'id'),
          role: oFetch(user, 'role'),
        },
      },
    });
  }

  function openDisableModal() {
    const fullName = oFetch(normalizedUser, 'fullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: DisableUserForm,
      onSubmit: (closeModal, values) => {
        return requests.disableUserRequest({
          values: values,
          onSuccess(data) {
            const user = oFetch(data, 'user');
            setUser(user);
            closeModal();
          },
        });
      },
      config: {
        title: `Disable: ${fullName}`,
      },
      props: {
        initialValues: {
          userId: oFetch(user, 'id'),
          notRehire: false,
          description: '',
        },
      },
    });
  }

  function openAddNewAppModal() {
    function addEmptyAppPermissions(appId) {
      const app = apps.find(app => oFetch(app, 'id') === appId);

      const emptyAppData = {
        appId: appId,
        name: oFetch(app, 'title'),
        persisted: false,
        appTypes: oFetch(app, 'appTypes'),
        appVenuesOnTitle: [],
        permanentAccessVenues: [],
        temporaryAccess: [],
      };
      setNotPersistedAppAccessData([emptyAppData, ...notPersistedAppAccessData]);
    }
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddNewAppForm,
      onSubmit: (closeModal, values) => {
        const [appId, venuesIds] = oFetch(values, 'appId', 'venuesIds');
        if (venuesIds.length === 0) {
          addEmptyAppPermissions(appId);
          closeModal();
        } else {
          return updateAppPermanentPermissions({
            values: { jsmBarsApplicationId: appId, venueIds: venuesIds },
            onSuccess() {
              closeModal();
            },
          });
        }
      },
      config: {
        title: `Add New App`,
      },
      props: {
        appsOptions,
        appsVenueIds: appsVenuesPermanentAccessIds,
        initialValues: {
          appId: null,
          venuesIds: [],
        },
      },
    });
  }

  function updateAppPermanentPermissions(params) {
    const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
    const [jsmBarsApplicationId, venueIds] = oFetch(values, 'jsmBarsApplicationId', 'venueIds');
    return requests.updatePermanentPermissionsRequest({
      values: {
        userId: oFetch(user, 'id'),
        jsmBarsApplicationId,
        venueIds,
      },
      onSuccess(data) {
        const appAccessData = oFetch(data, 'appAccessData');
        replaceAppPermanentPermissions({
          appId: jsmBarsApplicationId,
          backendPermanentPermissions: appAccessData,
        });
        onSuccess();
      },
    });
  }

  function openAddTemporaryPermissionModal(params) {
    const [appId, appName, venuesOptions] = oFetch(
      params,
      'appId',
      'appName',
      'venuesOptions'
    );

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: TemporaryItemForm,
      onSubmit: (closeModal, values) => {
        return requests.createTemporaryPermissionRequest({
          values: values,
          onSuccess(data) {
            const temporaryAppAccessDataItem = oFetch(data, 'temporaryAppAccessDataItem');
            addAppAccessData(temporaryAppAccessDataItem);
            closeModal();
          },
        });
      },
      config: {
        title: () => (
          <div className="boss-modal-window__header">
            <span className="boss-modal-window__marked">{appName}</span> App Add Temporary Access
          </div>
        ),
      },
      props: {
        buttonTitle: 'Add Temporary Access',
        venuesOptions,
        initialValues: {
          userId: oFetch(user, 'id'),
          jsmBarsApplicationId: appId,
          venueId: null,
          startDate: null,
          endDate: null,
          note: '',
        },
      },
    });
  }

  function openEditTemporaryPermissionModal(appName) {
    return params => {
      const temporaryPermission = oFetch(params, 'temporaryPermission');
      const userId = oFetch(normalizedUser, 'id');
      const [temporaryPermissionId, venueId, startDate, endDate, note] = oFetch(
        temporaryPermission,
        'id',
        'venueId',
        'startDate',
        'endDate',
        'note',
      );
      const venuesOptions = oFetch(params, 'venuesOptions');

      return openModal({
        ModalComponent: LegacyCloseInsideModalWrapper,
        ModalContent: TemporaryItemForm,
        onSubmit: (closeModal, values) => {
          return requests.updateTemporaryPermissionRequest({
            values: values,
            onSuccess(data) {
              const temporaryAppAccessDataItem = oFetch(data, 'temporaryAppAccessDataItem');
              updateAppAccessData(temporaryAppAccessDataItem);
              closeModal();
            },
          });
        },
        config: {
          title: () => (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{appName}</span> App Update Temporary Access
            </div>
          ),
        },
        props: {
          venuesOptions,
          buttonTitle: 'Update Temporary Access',
          initialValues: {
            userId,
            temporaryPermissionId,
            venueId,
            startDate,
            endDate,
            note,
          },
        },
      });
    };
  }

  function openVenuePermissionDetailsModal(appName) {
    return params => {
      const [venueName, timedPermissions, hasPermanent] = oFetch(
        params,
        'venueName',
        'timedPermissions',
        'hasPermanent',
      );
      return openModal({
        ModalComponent: LegacyCloseInsideModalWrapper,
        ModalContent: VenuePermissionDetails,
        onSubmit: closeModal => {
          closeModal();
        },
        config: {
          title: () => (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{appName}</span> App Venue Details:{' '}
              <span className="boss-modal-window__marked">{venueName}</span>
            </div>
          ),
        },
        props: {
          timedPermissions,
          hasPermanent,
        },
      });
    };
  }

  function disableTemporaryPermission(params) {
    const userId = oFetch(normalizedUser, 'id');
    const temporaryPermission = oFetch(params, 'temporaryPermission');
    const temporaryPermissionId = oFetch(temporaryPermission, 'id');

    return requests.disableTemporaryPermissionRequest({
      values: {
        userId,
        temporaryPermissionId,
      },
      onSuccess(data) {
        removeAppPermissionAccessData(temporaryPermissionId);
      },
    });
  }

  function disableAppPermissions(params) {
    const userId = oFetch(normalizedUser, 'id');
    const [jsmBarsApplicationId, persisted] = oFetch(params, 'jsmBarsApplicationId', 'persisted');
    if (persisted) {
      return openModal({
        ModalComponent: LegacyCloseOutsideModalWrapper,
        ModalContent: DisableAppWarning,
        onSubmit: closeModal => {
          return requests.disableAppPermissionsRequest({
            values: {
              userId,
              jsmBarsApplicationId,
            },
            onSuccess(data) {
              const appAccessData = oFetch(data, 'appAccessData');
              replaceAppPermissions({ appId: jsmBarsApplicationId, permissions: appAccessData });
              removeNonPersistedApp({ appId: jsmBarsApplicationId });
              closeModal();
            },
          });
        },
        config: {
          title: 'ARE YOU SURE?',
          baseClassName: 'boss-modal-window boss-modal-window_role_warning',
        },
      });
    } else {
      removeNonPersistedApp({ appId: jsmBarsApplicationId });
    }
  }

  function enableUser() {
    return requests.enableUserRequest({
      values: {
        userId: oFetch(normalizedUser, 'id'),
      },
      onSuccess(data) {
        const user = oFetch(data, 'user');
        setUser(user);
      },
    });
  }

  function forceSyncUser() {
    setForceSyncInProgress(true);

    return requests.forceJsmSyncRequest({
      values: {
        userId: oFetch(normalizedUser, 'id'),
      },
      onSuccess(data) {
        const user = oFetch(data, 'user');
        setUser(user);
        setForceSyncInProgress(false);
      },
      onError(params) {
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');

        const bypassMessageParts = ['Force sync failed!'];
        if (errors.base == 'Jsm sync is disabled') {
          bypassMessageParts.push('Jsm sync is disabled');
          setJsmSyncEnabled(false);
        } else {
          bypassMessageParts.push(`Error: ${JSON.stringify(errors)}`);
        }
        globalNotifications.showDefaultFailureMessage({
          message: bypassMessageParts.join(' '),
        });
        setForceSyncInProgress(false);

        return true;
      },
    });
  }

  return {
    user: normalizedUser,
    jsmSyncEnabled,
    forceSyncInProgress,
    forceSyncUser,
    openPersonalDetailsEditModal,
    openAccessDetailsEditModal,
    openDisableModal,
    enableUser,
    disableTemporaryPermission,
    disableAppPermissions,
    openAddNewAppModal,
    openAddTemporaryPermissionModal,
    updateAppPermanentPermissions,
    openEditTemporaryPermissionModal,
    openEditRoleModal,
    openVenuePermissionDetailsModal,
    appPermissions: appPermissions,
  };
}
