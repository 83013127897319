import React, { useState } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import cn from 'classnames';
import Spinner from '@/components/spinner';
import { PermissionItem } from './permission-item';
import { PermissionRequestForm } from '../forms/permission-request-form';

export function DeviceItem(props) {
  const [processing, setProcessing] = useState(false);
  const [onManuallyAdd, onChangeVenue, onCustomNameUpdateClick, onBan, onUnban] = oFetch(
    props,
    'onManuallyAdd',
    'onChangeVenue',
    'onCustomNameUpdateClick',
    'onBan',
    'onUnban',
  );
  const [device, onAcceptPermissionRequest, onDisablePermission, onRejectPermissionRequest] = oFetch(
    props,
    'device',
    'onAcceptPermissionRequest',
    'onDisablePermission',
    'onRejectPermissionRequest',
  );
  const [
    deviceId,
    deviceUniqueId,
    lastIpAddress,
    systemType,
    ramBytes,
    lastDeviceName,
    currentPermissionRequest,
    currentPermission,
    hasCurrentPermission,
    hasCurrentPermissionRequest,
    customDeviceName,
    isBanned,
    isActive,
    isPending,
  ] = oFetch(
    device,
    'id',
    'deviceUniqueId',
    'lastIpAddress',
    'systemType',
    'ramBytes',
    'lastDeviceName',
    'currentPermissionRequest',
    'currentPermission',
    'hasCurrentPermission',
    'hasCurrentPermissionRequest',
    'customDeviceName',
    'isBanned',
    'isActive',
    'isPending',
  );

  const itemClassNames = cn('boss-check boss-check_role_panel boss-check_page_devices', {
    'boss-check_state_alert': isBanned,
    'boss-check_state_access-granted': isActive && !isPending,
    'boss-check_state_access-pending': isPending,
  });

  function handleAcceptPermissionRequest(params) {
    return onAcceptPermissionRequest({
      values: { deviceId },
    });
  }

  function handleDisablePermission(params) {
    setProcessing(true);
    return onDisablePermission({
      values: { deviceId },
      onSuccess() {
        setProcessing(false);
      },
    });
  }

  function handleRejectPermissionRequest(params) {
    setProcessing(true);
    return onRejectPermissionRequest({
      values: { deviceId },
      onSuccess() {
        setProcessing(false);
      },
    });
  }

  function handleBan() {
    setProcessing(true);

    return onBan({
      values: { deviceId },
      onSuccess() {
        setProcessing(false);
      },
    });
  }

  function handleUnban() {
    setProcessing(true);

    return onUnban({
      values: { deviceId },
      onSuccess() {
        setProcessing(false);
      },
    });
  }

  function renderCurrentPermissionRequest() {
    return (
      <>
        <PermissionRequestForm
          permissionRequest={currentPermissionRequest}
          onChangeVenue={onChangeVenue}
          lastIpAddress={lastIpAddress}
          onSubmit={handleAcceptPermissionRequest}
          onReject={handleRejectPermissionRequest}
        />
      </>
    );
  }

  function renderCurrentPermission() {
    return (
      <PermissionItem
        permission={currentPermission}
        lastIpAddress={lastIpAddress}
        onDisable={handleDisablePermission}
      />
    );
  }

  const displayDeviceName =
    device.highlightCustomDeviceName || device.highlightLastDeviceName || customDeviceName || lastDeviceName;
  const displayLastDeviceName = device.highlightLastDeviceName || lastDeviceName;

  return (
    <div className={itemClassNames}>
      <div className="boss-check__header">
        <div className="boss-check__header-group">
          <h3 className="boss-check__title">
            <span dangerouslySetInnerHTML={{ __html: displayDeviceName }} />
            <button
              onClick={onCustomNameUpdateClick}
              className="boss-check__title-link boss-check__title-link_role_edit"
            >
              Edit
            </button>
          </h3>
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-light">Device ID: </span>
                <span className="boss-check__text-marked">{deviceUniqueId}</span>
              </p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-light">System: </span>
                <span className="boss-check__text-marked">{systemType}</span>
              </p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-light">RAM: </span>
                <span className="boss-check__text-marked">{ramBytes}</span>
              </p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-light">Device Name: </span>
                <span
                  className="boss-check__text-marked"
                  dangerouslySetInnerHTML={{ __html: displayLastDeviceName }}
                />
              </p>
            </div>
          </div>
        </div>
        {!isActive && (
          <div className="boss-check__header-actions">
            {isBanned && (
              <AsyncButton
                className="boss-button boss-button_role_unban boss-button_type_small boss-check__header-action"
                text="Unban"
                pendingText="Processing ..."
                onClick={handleUnban}
              />
            )}
            {!isBanned && (
              <AsyncButton
                className="boss-button boss-button_role_unban boss-button_type_small boss-check__header-action"
                text="Ban"
                pendingText="Processing ..."
                onClick={handleBan}
              />
            )}
          </div>
        )}
      </div>
      {!isBanned && (
        <div className="boss-check__group boss-check__group_marked">
          <div className="boss-check__group-content">
            <div className="boss-access boss-access_type_general">
              {!hasCurrentPermission && !hasCurrentPermissionRequest && (
                <div className="boss-access__header">
                  <h4 className="boss-access__title">Access</h4>
                  <div className="boss-access__header-actions">
                    <button
                      onClick={onManuallyAdd}
                      className="boss-button boss-button_role_add boss-button_type_small boss-access__header-action"
                    >
                      Manually Add
                    </button>
                  </div>
                </div>
              )}
              {processing && <Spinner />}
              {hasCurrentPermissionRequest && !processing && renderCurrentPermissionRequest()}
              {hasCurrentPermission && !processing && renderCurrentPermission()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
