import React from 'react';
import { format, setISODay } from 'date-fns';
import { FINANCE_REPORT_MONTH_DATE_FORMAT, FINANCE_REPORT_TABLE_DATE_FORMAT } from '@/lib/date-fns-formats';

type ReportTableHeaderProps = {
  staffTypeName: string;
  startDate: Date;
};

export function ReportTableHeader(props: ReportTableHeaderProps) {
  function renderWeekCells() {
    const staffTypeName = props.staffTypeName;
    const startDate = props.startDate;

    return [1, 2, 3, 4, 5, 6, 7].map(dayIndex => {
      const currentDate = setISODay(
        startDate,
        dayIndex,
      );

      return (
        <div
          key={`${staffTypeName}:header:${dayIndex}`}
          className="boss-table__cell boss-table__cell_role_header"
        >
          {format(currentDate, FINANCE_REPORT_TABLE_DATE_FORMAT)} <br /> {format(currentDate, FINANCE_REPORT_MONTH_DATE_FORMAT)}
        </div>
      );
    });
  }

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell boss-table__cell_role_header">Name</div>
      {renderWeekCells()}
      <div className="boss-table__cell boss-table__cell_role_header">Weekly Hours</div>
      <div className="boss-table__cell boss-table__cell_role_header">Owed Hours</div>
      <div className="boss-table__cell boss-table__cell_role_header">Pay Rate</div>
      <div className="boss-table__cell boss-table__cell_role_header">Total Hours</div>
      <div className="boss-table__cell boss-table__cell_role_header">Total</div>
      <div className="boss-table__cell boss-table__cell_role_header">Status</div>
      <div className="boss-table__cell boss-table__cell_role_header">Notes</div>
    </div>
  );
}