import { DashboardWrapper } from '@/components/dashboard';
import React from 'react';

type Props = {
  onAddNew: () => void;
};

export function Dashboard(props: Props) {
  const { onAddNew } = props;

  return (
    <DashboardWrapper classes='boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_hours-confirmation'>
      <div className="boss-page-dashboard__group">
        <h1 className="boss-page-dashboard__title">Whatsapp servers</h1>
        <div className="boss-page-dashboard__buttons-group">
          <button
            onClick={() => onAddNew()}
            type="button"
            className="boss-button boss-button_role_add boss-page-dashboard__button"
          >Add Whatsapp Server</button>
        </div>
      </div>
    </DashboardWrapper>
  );
}
