import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import { SiteIssueForm } from './site-issue-form';

export function UpdateSiteIssueModalContent(props) {
  const [onSubmit, siteIssue] = oFetch(props, 'onSubmit', 'siteIssue');

  const initialValues = {
    id: oFetch(siteIssue, 'id'),
    description: oFetch(siteIssue, 'description'),
    date: oFetch(siteIssue, 'date'),
    level: oFetch(siteIssue, 'level'),
  };

  return <SiteIssueForm onSubmit={onSubmit} initialValues={initialValues} buttonText="Update Site Issue" />;
}
