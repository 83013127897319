import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { SECURITY_TYPE_ID } from '../../constants';

import EmploymentDetailsForm from './employment-details-form';
import PersonalDetailsForm from './personal-details-form';
import ContactDetailsForm from './contact-details-form';
import humanize from 'string-humanize';

const EditProfilePage = ({
  accessibleVenues,
  staffTypes,
  accessiblePayRates,
  genderValues,
  staffMember,
  permissionsData,
  accessibleBaseStandardSecurityPayRates,
  accessibleBaseStewardSecurityPayRates,
  accessoryRequests,
  accessories,
}) => {
  const permissionsDataJS = permissionsData.toJS();
  const canEditSageId = oFetch(permissionsDataJS, 'canEditSageId');
  const canEditWorkerType = oFetch(permissionsDataJS, 'canEditWorkerType');
  const canChangePublicHolidays = oFetch(permissionsDataJS, 'canChangePublicHolidays');

  const staffMemberData = staffMember.toJS();
  const contactDetailsFormInitial = {
    email_address: oFetch(staffMemberData, 'email'),
    phone_number: oFetch(staffMemberData, 'phone_number'),
    address: oFetch(staffMemberData, 'address'),
    postcode: oFetch(staffMemberData, 'postcode'),
    country: oFetch(staffMemberData, 'country'),
    county: oFetch(staffMemberData, 'county'),
  };
  const sDateOfBirth = oFetch(staffMemberData, 'date_of_birth');
  const checkSiaNumber = oFetch(staffMemberData, 'checkSiaNumber');

  const personaletailsFormInitial = {
    first_name: oFetch(staffMemberData, 'first_name'),
    surname: oFetch(staffMemberData, 'surname'),
    gender: humanize(oFetch(staffMemberData, 'gender')),
    date_of_birth: sDateOfBirth && safeMoment.uiDateParse(sDateOfBirth),
  };
  const isSteward = oFetch(staffMemberData, 'isSteward');
  const isSecurity = oFetch(staffMemberData, 'staff_type') === SECURITY_TYPE_ID;

  const sSiaBadgeExpiryDate = oFetch(staffMemberData, 'sia_badge_expiry_date');
  const mSiaBadgeExpiryDate =
    sSiaBadgeExpiryDate &&
    !isSteward &&
    safeMoment.uiDateParse(sSiaBadgeExpiryDate).format(utils.commonDateFormat);
  const siaBadgeValid = oFetch(staffMemberData, 'siaBadgeValid');

  const employmentDetailsFormInitial = {
    other_venues: oFetch(staffMemberData, 'other_venues'),
    worker_type: oFetch(staffMemberData, 'workerType'),
    sage_id: oFetch(staffMemberData, 'sageId'),
    allow_no_sage_id: oFetch(staffMemberData, 'allowNoSageId'),
    starts_at: safeMoment.uiDateParse(oFetch(staffMemberData, 'starts_at')),
    staff_type: oFetch(staffMemberData, 'staff_type'),
    sia_badge_number: oFetch(staffMemberData, 'sia_badge_number'),
    sia_badge_expiry_date: mSiaBadgeExpiryDate,
    national_insurance_number: oFetch(staffMemberData, 'national_insurance_number'),
    day_preference: oFetch(staffMemberData, 'day_preference'),
    hours_preference: oFetch(staffMemberData, 'hours_preference'),
    baseSecurityPayRate: oFetch(staffMemberData, 'baseSecurityPayRateId'),
    pay_rate: oFetch(staffMemberData, 'pay_rate'),
    steward: isSteward,
    employment_status: oFetch(staffMemberData, 'status_statement'),
    siaValidationGUID: null,
    generatePublicHolidays: oFetch(staffMemberData, 'generatePublicHolidays')
  };

  const workerTypeOptions = oFetch(staffMemberData, 'workerTypeOptions');
  const nonStewardSecurity = isSecurity && !isSteward;
  const staffMemberId = oFetch(staffMemberData, 'id');

  return (
    <div className="boss-content-switcher">
      <Tabs
        className="boss-content-switcher__inner"
        selectedTabClassName="boss-content-switcher__nav-link_state_active"
      >
        <TabList className="boss-content-switcher__nav boss-content-switcher__side">
          <Tab className="boss-content-switcher__nav-link">Employment Details</Tab>
          <Tab className="boss-content-switcher__nav-link">Personal Details</Tab>
          <Tab className="boss-content-switcher__nav-link">Contact Details</Tab>
        </TabList>

        <section className="boss-content-switcher__chapters">
          <TabPanel
            selectedClassName="boss-content-switcher__chapter_state_visible"
            className="boss-content-switcher__chapter"
          >
            <EmploymentDetailsForm
              initialValues={employmentDetailsFormInitial}
              masterVenue={oFetch(staffMemberData, 'master_venue')}
              otherVenues={oFetch(staffMemberData, 'other_venues')}
              isStaffMemberOfficeBased={oFetch(staffMemberData, 'isOfficeBased')}
              accessoryRequests={accessoryRequests}
              staffTypes={staffTypes}
              canChangePublicHolidays={canChangePublicHolidays}
              siaBadgeValid={siaBadgeValid}
              staffMemberId={staffMemberId}
              checkSiaNumber={checkSiaNumber}
              canEditSageId={canEditSageId}
              accessories={accessories}
              accessiblePayRates={accessiblePayRates}
              accessibleVenues={accessibleVenues}
              canEditWorkerType={canEditWorkerType}
              workerTypeOptions={workerTypeOptions}
              accessibleBaseStandardSecurityPayRates={accessibleBaseStandardSecurityPayRates}
              accessibleBaseStewardSecurityPayRates={accessibleBaseStewardSecurityPayRates}
            />
          </TabPanel>
          <TabPanel
            selectedClassName="boss-content-switcher__chapter_state_visible"
            className="boss-content-switcher__chapter"
          >
            <PersonalDetailsForm
              initialValues={personaletailsFormInitial}
              venues={accessibleVenues}
              nonStewardSecurity={nonStewardSecurity}
              checkSiaNumber={checkSiaNumber}
              staffTypes={staffTypes}
              payRates={accessiblePayRates}
              genderValues={genderValues}
            />
          </TabPanel>
          <TabPanel
            selectedClassName="boss-content-switcher__chapter_state_visible"
            className="boss-content-switcher__chapter"
          >
            <ContactDetailsForm initialValues={contactDetailsFormInitial} />
          </TabPanel>
        </section>
      </Tabs>
    </div>
  );
};

export default EditProfilePage;
