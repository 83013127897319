import { BossFormInput } from '@/components/boss-form';
import React from 'react';
import { Form, Field } from 'react-final-form';

export type WhatsappServerFormValues = {
  name: string;
  baseUrl: string;
  ssh: string;
};

type Props = {
  onSubmit: () => void;
  initialValues: WhatsappServerFormValues;
  processing: boolean;
};

export function WhatsappServiceForm(props: Props) {
  const { onSubmit, initialValues, processing } = props;

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, values, form, ...rest }) => {
            const disabled = submitting || processing;
            return (
              <>
                <Field
                  name={'name'}
                  label="Whatsapp Server Name"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                />
                <Field
                  name={'baseUrl'}
                  label="Base URL"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                />
                <Field
                  name={'ssh'}
                  label="SSH"
                  parse={value => value}
                  disabled={processing}
                  component={BossFormInput}
                  description={'user@hostname'}
                />
                <div className="boss-form__field">
                  <div
                    className='boss-page-dashboard__buttons-group boss-page-dashboard_page_hours-confirmation'
                    style={{ alignItems: 'center' }}
                  >
                    <button
                      onClick={handleSubmit}
                      disabled={disabled}
                      className="boss-button boss-button_role_confirm boss-form__submit boss-page-dashboard__button"
                      type="button"
                    >
                      Create Whatsapp Server
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
