import oFetch from 'o-fetch';
import React from 'react';
import { AppPermissionsCard, AppAccessList } from './app-access';

export function LinkedUserAccessDetails(props) {
  const [
    user,
    appPermissions,
    onAddNewApp,
    onAddTemporaryAccess,
    onEditTemporaryAccess,
    onUpdatePermanentPermissions,
    onRoleEditClick,
    onDisableTemporaryAccess,
    onDisableAppPermissions,
    onVenueClick,
  ] = oFetch(
    props,
    'user',
    'appPermissions',
    'onAddNewApp',
    'onAddTemporaryAccess',
    'onEditTemporaryAccess',
    'onUpdatePermanentPermissions',
    'onRoleEditClick',
    'onDisableTemporaryAccess',
    'onDisableAppPermissions',
    'onVenueClick',
  );

  const [role, isDisabled] = oFetch(user, 'roleText', 'isDisabled');

  function renderAppItem(appPermission) {
    return (
      <AppPermissionsCard
        isDisabled={isDisabled}
        appPermission={appPermission}
        onAddTemporaryAccess={onAddTemporaryAccess}
        onEditTemporaryAccess={onEditTemporaryAccess}
        onDisableTemporaryAccess={onDisableTemporaryAccess}
        onUpdatePermanentPermissions={onUpdatePermanentPermissions}
        onDisableAppPermissions={onDisableAppPermissions}
        onVenueClick={onVenueClick}
      />
    );
  }

  return (
    <div className={'boss-page-main__isle boss-page-main__isle_adjust_full'}>
      <section className="boss-details">
        <p className="boss-details__pointer">
          <span className="boss-details__pointer-text">3</span>
        </p>
        <div className="boss-details__content">
          <h3 className="boss-details__title">
            <span className="boss-details__title-label">Access Details</span>
          </h3>
          <ul className="boss-details__list">
            <li className="boss-details__item boss-details__item_adjust_isle">
              <p className="boss-details__label boss-details__label_size_small">Role</p>
              <p className="boss-details__value">
                <span className="boss-details__value-part">{role}</span>
                {!isDisabled && (
                  <span className="boss-details__value-part">
                    <button
                      onClick={onRoleEditClick}
                      className="boss-button boss-button_role_update boss-button_type_ultra-small"
                    >
                      Edit
                    </button>
                  </span>
                )}
              </p>
            </li>
            <li className="boss-details__item boss-details__item_adjust_full">
              <p className="boss-details__label boss-details__label_size_small">
                <span className="boss-details__label-part">Accessible Apps</span>
                {!isDisabled && (
                  <span className="boss-details__label-part">
                    <button
                      className="boss-button boss-button_role_add boss-button_type_extra-small"
                      onClick={onAddNewApp}
                    >
                      Add New App
                    </button>
                  </span>
                )}
              </p>
              <AppAccessList
                appPermissions={appPermissions}
                renderItem={renderAppItem}
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
