import React, { Fragment, useState, useMemo } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import { PermanentAccessForm } from './permanent-access-form';
import { TemporaryAccess, TemporaryItemWithActions } from './temporary-access';

function AppVenuesTitle(props) {
  const [appVenuesOnTitle, onVenueClick] = oFetch(props, 'appVenuesOnTitle', 'onVenueClick');
  return (
    <Fragment>
      <div className="boss-check__header-meta-item boss-check__header-meta-item_role_key">
        <p className="boss-check__text boss-check__text_marked boss-check__text_role_location boss-check__text_adjust_nowrap">
          Venues:
        </p>
      </div>
      {appVenuesOnTitle.map(venue => {
        const [venueName, hasPermanent, timedPermissions] = oFetch(
          venue,
          'venueName',
          'hasPermanent',
          'timedPermissions',
        );
        const itemClassNames = cn('boss-check__text boss-check__text_role_action', {
          'boss-check__text_role_time': !hasPermanent,
        });
        return (
          <div
            key={venueName}
            className="boss-check__header-meta-item"
          >
            <button
              onClick={() => onVenueClick({ venueName, timedPermissions, hasPermanent })}
              type="button"
              className={itemClassNames}
            >
              <span className="boss-check__text-light">{venueName}</span>
            </button>
          </div>
        );
      })}
    </Fragment>
  );
}

export function AppPermissionsCard(props) {
  const [
    appPermission,
    onAddTemporaryAccess,
    onUpdatePermanentPermissions,
    onEditTemporaryAccess,
    onDisableTemporaryAccess,
    onDisableAppPermissions,
    onVenueClick,
    isDisabled,
  ] = oFetch(
    props,
    'appPermission',
    'onAddTemporaryAccess',
    'onUpdatePermanentPermissions',
    'onEditTemporaryAccess',
    'onDisableTemporaryAccess',
    'onDisableAppPermissions',
    'onVenueClick',
    'isDisabled',
  );
  const name = oFetch(appPermission, 'name');
  const [appId, persisted] = oFetch(appPermission, 'appId', 'persisted');
  const [isOpen, setIsOpen] = useState(!persisted);
  const permanentAccessVenues = oFetch(appPermission, 'permanentAccessVenues');
  const temporaryAccess = oFetch(appPermission, 'temporaryAccess');
  const appVenuesOnTitle = oFetch(appPermission, 'appVenuesOnTitle');
  const appVenuesOnTitleExist = appVenuesOnTitle.length > 0;

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function renderTemporaryAccessItem(args) {
    const item = oFetch(args, 'item');
    const venuesOptions = oFetch(args, 'venuesOptions');

    return (
      <TemporaryItemWithActions
        item={item}
        venuesOptions={venuesOptions}
        onEdit={onEditTemporaryAccess(name)}
        onDisable={onDisableTemporaryAccess}
      />
    );
  }

  function getAppTitle() {
    if (persisted) {
      return name;
    }
    return `${name} (New)`;
  }
  const appAppTypes = oFetch(appPermission, 'appTypes');

  const allVenuesOptions = oFetch(useLegacyGlobal(), 'venuesOptions');
  const filteredVenuesOptions = useMemo(() => {
    if (oFetch(appAppTypes, 'length') === 0) {
      return [];
    }

    return allVenuesOptions.filter(venueOption => {
      const venueAppTypes = oFetch(venueOption, 'appTypes');
      return venueAppTypes.some(venueAppType => {
        return appAppTypes.includes(venueAppType);
      });
    });
  }, appAppTypes);

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_user-profile-app-access">
      <div className="boss-check__header">
        <div className="boss-check__mark boss-check__mark_position_left" />
        <h3 className="boss-check__title">{getAppTitle()}</h3>
        <div className="boss-check__header-info">
          <div className="boss-check__header-meta">
            {appVenuesOnTitleExist && !isOpen && (
              <AppVenuesTitle
                onVenueClick={onVenueClick(name)}
                appVenuesOnTitle={appVenuesOnTitle}
              />
            )}
            <div className="boss-check__header-meta-item boss-check__header-meta-item_role_actions">
              {!isDisabled && !isOpen && (
                <button
                  type="button"
                  onClick={() => open()}
                  className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_edit"
                />
              )}
            </div>
          </div>
        </div>
        <div className="boss-check__header-actions">
          {!isDisabled && !isOpen && (
            <button
              type="button"
              onClick={() => onDisableAppPermissions({ jsmBarsApplicationId: appId, persisted })}
              className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove boss-check__header-action"
            />
          )}
          {isOpen && (
            <button
              type="button"
              onClick={() => close()}
              className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove boss-check__header-action open"
            />
          )}
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="boss-check__group boss-check__group_marked">
          <div className="boss-check__group-header">
            <h4 className="boss-check__group-title">Edit App Access</h4>
          </div>
          <div className="boss-check__group-content">
            <PermanentAccessForm
              onSubmit={values => onUpdatePermanentPermissions({ values, onSuccess() { } })}
              isPersisted={persisted}
              venuesOptions={filteredVenuesOptions}
              initialValues={{
                jsmBarsApplicationId: appId,
                venueIds: permanentAccessVenues.map(venue => oFetch(venue, 'venueId')),
              }}
            />
          </div>
          <TemporaryAccess
            temporaryAccess={temporaryAccess}
            venuesOptions={filteredVenuesOptions}
            onAddNew={() =>
              onAddTemporaryAccess({ appId, appName: name, venuesOptions: filteredVenuesOptions })
            }
            renderItem={renderTemporaryAccessItem}
          />
        </div>
      </Collapse>
    </div>
  );
}
