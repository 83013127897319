import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import AsyncButton from 'react-async-button';

import utils, { truncate } from '@/lib/utils';

function MetaItem(props) {
  const [title, text] = oFetch(props, 'title', 'text');
  const viewableText = utils.isFunction(text) ? text() : text;
  return (
    <div className="boss-check__header-meta-item">
      <p className="boss-check__text">
        <span className="boss-check__text-light">{title}: </span>
        <span className="boss-check__text-marked">{viewableText}</span>
      </p>
    </div>
  );
}

export default class QuestionItem extends Component {
  state = {
    isOpened: false,
  };

  toggleDropDown = () => {
    this.setState(state => ({ isOpened: !state.isOpened }));
  };

  renderTags = tags => {
    if (tags.length === 0) {
      return null;
    }

    return (
      <div className="boss-check__header-tags">
        <div className="boss-check__header-tags-key">
          <span className="boss-check__text">
            <span className="boss-check__text-light">Tags: </span>
          </span>
        </div>
        {tags.map(tag => {
          const [tagId, name] = oFetch(tag, 'id', 'name');
          return (
            <span key={tagId} className="boss-tag boss-tag_size_xxs boss-tag_border_gray-light boss-check__header-tag">
              <span className="boss-tag__text">{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  render() {
    const [question, children, onEdit, onDisable, onEnable, onMove, canMove] = oFetch(
      this.props,
      'question',
      'children',
      'onEdit',
      'onDisable',
      'onEnable',
      'onMove',
      'canMove',
    );
    const otherQuizCategories = oFetch(this.props, 'otherQuizCategories');
    const otherCategoriesExist = oFetch(otherQuizCategories, 'length') > 0;
    const isOpened = oFetch(this.state, 'isOpened');
    const [text, code, humanizedType, imageUrl, createdByUser, formattedCreatedAt, isDisabled, isActive, tags] = oFetch(
      question,
      'text',
      'code',
      'humanizedType',
      'imageUrl',
      'createdByUser',
      'formattedCreatedAt',
      'isDisabled',
      'isActive',
      'tags',
    );

    const arrowClassNames = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
      'boss-check__dropdown-link_state_closed': !isOpened,
    });

    const questionClassNames = cn('boss-check boss-check_role_panel boss-check_page_quiz-category', {
      'boss-check_state_alert': isDisabled,
    });

    return (
      <div className={questionClassNames}>
        <div className="boss-check__header">
          <div className="boss-check__header-group">
            <div className="boss-check__header-info">
              {imageUrl && (
                <img src={imageUrl} alt="Question Image" className="boss-check__header-image" crossOrigin="anonymous" />
              )}
              <h3 className="boss-check__title">
                {truncate(text, 80)} {isDisabled ? '(Disabled)' : ''}
              </h3>
            </div>
            <div className="boss-check__header-meta">
              <MetaItem title="Code" text={() => <code>{code}</code>} />
              <MetaItem title="Type" text={humanizedType} />
            </div>
            {this.renderTags(tags)}
          </div>
          {isActive && (
            <div className="boss-check__header-actions">
              <button
                onClick={() => onEdit(question)}
                className="boss-button boss-button_role_edit boss-button_type_extra-small boss-check__header-action"
              >
                Edit
              </button>
              { canMove && otherCategoriesExist && <button
                  onClick={ () => onMove({ question }) }
                  className="boss-button boss-button_role_move boss-button_type_extra-small boss-check__header-action"
                >
                  Move
                </button>
              }
              <button
                onClick={() => onDisable(question)}
                className="boss-button boss-button_role_delete boss-button_type_extra-small boss-check__header-action"
              >
                Disable
              </button>
            </div>
          )}
          {isDisabled && (
            <div className="boss-check__header-actions">
              <AsyncButton
                className="boss-button boss-button_role_restore boss-button_type_small boss-check__header-action"
                text="Enable"
                pendingText="Enabling ..."
                onClick={() => onEnable(question)}
              />
            </div>
          )}
          <div onClick={this.toggleDropDown} className={arrowClassNames}>
            Toggle Dropdown
          </div>
        </div>
        <Collapse isOpened={this.state.isOpened} className="boss-check__dropdown">
          <div className="boss-check__group boss-check__group_marked">
            <div className="boss-check__group-content">{children(question)}</div>
          </div>
          <div className="boss-check__group">
            <div className="boss-check__group-content">
              <div className="boss-check__meta">
                <div className="boss-check__meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Created by </span>
                    <span className="boss-check__text-marked">{createdByUser}</span>
                    <span className="boss-check__text-light"> at </span>
                    <span className="boss-check__text-marked">{formattedCreatedAt}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
