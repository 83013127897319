import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import * as constants from '../constants';

export function MonthItemBadges(props) {
  const monthCounts = oFetch(props, 'monthCounts');

  const sortedCounts = useMemo(() => {
    return constants.BADGES_ORDER.reduce((acc, item) => {
      if (monthCounts[item] !== undefined) {
        return { ...acc, [item]: monthCounts[item] };
      }
      return acc;
    }, {});
  }, [monthCounts]);
  return (
    <div className="boss-board__info-group float-left">
      {Object.entries(sortedCounts).map(countsEntry => {
        const [key, count] = countsEntry;
        const badgeClassNames = cn('boss-check__indicator boss-check__indicator_position_before', {
          'boss-check__indicator_priority_high': key === constants.ACTIVE_RED_STATE,
          'boss-check__indicator_priority_medium': key === constants.ACTIVE_AMBER_STATE,
          'boss-check__indicator_priority_pending': key === constants.PENDING_STATE,
          'boss-check__indicator_priority_deleted': key === constants.DISABLED_STATE,
        });
        return (
          <div key={key} className={badgeClassNames}>
            {count}
          </div>
        );
      })}
    </div>
  );
}
