import { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { BigNumber } from 'bignumber.js';

import utils from '@/lib/utils';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseOutsideModalWrapper, LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { AddPayment, EditPayment } from './components';
import * as requests from './requests';

export function bonusPaymentsAdminHooks(params) {
  const [initialBonusPayments, selectedYear, selectedQuarter] = oFetch(
    params,
    'initialBonusPayments',
    'selectedYear',
    'selectedQuarter',
  );
  const { openModal } = useModal();
  const [bonusPayments, setBonusPayments] = useState(initialBonusPayments);
  const [filterStates, setFilterStates] = useState(['pending', 'confirmed']);
  const [filterName, setFilterName] = useState('');

  function addBonusPaymentToStore(bonusPayment) {
    setBonusPayments([bonusPayment, ...bonusPayments]);
  }
  function updateBonusPaymentToStore(bonusPayment) {
    const bonusPaymentId = oFetch(bonusPayment, 'id');
    setBonusPayments(existingBonusPayments => {
      return existingBonusPayments.map(existingBonusPayment => {
        if (oFetch(existingBonusPayment, 'id') === bonusPaymentId) {
          return bonusPayment;
        }
        return existingBonusPayment;
      });
    });
  }

  function openAddPaymentModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddPayment,
      onSubmit: (closeModal, values) => {
        const amountCents = oFetch(values, 'amountCents');
        return requests.createBonusPayment({
          values: {
            ...values,
            amountCents:
              amountCents !== null
                ? new BigNumber(amountCents).times(100).integerValue().toNumber()
                : amountCents,
          },
          onSuccess(bonusPayment) {
            const [year, quarter] = oFetch(bonusPayment, 'year', 'quarter');
            if (selectedYear === year && selectedQuarter === quarter) {
              addBonusPaymentToStore(bonusPayment);
            }
            closeModal();
          },
        });
      },
      config: {
        title: 'Add Payment',
      },
      props: {},
    });
  }

  function openEditPaymentModal(params) {
    const bonusPayment = oFetch(params, 'bonusPayment');
    const staffMemberFullName = oFetch(bonusPayment, 'staffMemberFullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditPayment,
      onSubmit: (closeModal, values) => {
        const amountCents = oFetch(values, 'amountCents');
        return requests.updateBonusPayment({
          values: {
            ...values,
            amountCents:
              amountCents !== null
                ? new BigNumber(amountCents).times(100).integerValue().toNumber()
                : amountCents,
          },
          onSuccess(bonusPayment) {
            const [year, quarter] = oFetch(bonusPayment, 'year', 'quarter');
            if (selectedYear === year && selectedQuarter === quarter) {
              updateBonusPaymentToStore(bonusPayment);
            }
            closeModal();
          },
        });
      },
      config: {
        title: `Edit Payment: ${staffMemberFullName}`,
      },
      props: {
        bonusPayment,
      },
    });
  }

  const filteredBonusPayments = useMemo(() => {
    return bonusPayments.filter(bonusPayment => {
      let matched = false;
      if (filterStates.includes(oFetch(bonusPayment, 'status')) && !oFetch(bonusPayment, 'isDisabled')) {
        matched = true;
      }
      if (filterStates.includes('disabled') && oFetch(bonusPayment, 'isDisabled')) {
        matched = true;
      }
      return matched;
    });
  }, [filterStates, filterName, bonusPayments]);

  const filteredByNameBonusPayments = useMemo(() => {
    if (filterName === '') {
      return filteredBonusPayments;
    }
    return utils.fullFilter(filterName, filteredBonusPayments, ['staffMemberFullName']);
  }, [filterName, filterStates, bonusPayments]);

  function disableBonusPayment(bonusPaymentId) {
    return requests.disableBonusPayment({
      bonusPaymentId,
      onSuccess(bonusPayment) {
        updateBonusPaymentToStore(bonusPayment);
      },
    });
  }

  function handleFilterStatesChange(selected) {
    setFilterStates(selected);
  }

  return {
    openAddPaymentModal,
    openEditPaymentModal,
    bonusPayments: filteredByNameBonusPayments,
    disableBonusPayment,
    handleFilterStatesChange,
    filterStates,
    filterName,
    handleFilterNameChange: setFilterName,
  };
}
