import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import BossWeekPickerPopover from '@/components/react-dates/boss-week-picker-popover';
import { appRoutes } from '@/lib/legacy-routes';
import { getFormattedMoney } from './venue-wage-content';

export function ModulrReportsDashboard(props) {
  const [weekStartDate, weekEndDate, grandTotalNetWagesCents, setupForPayment] = oFetch(
    props,
    'weekStartDate',
    'weekEndDate',
    'grandTotalNetWagesCents',
    'setupForPayment',
  );
  const payrollWeek = oFetch(props, 'payrollWeek');
  const venuesWithIncompleteWeek = oFetch(payrollWeek, 'venuesWithIncompleteWeek');
  const hasVenuesWithInCompleteWeeks = oFetch(venuesWithIncompleteWeek, 'length') > 0;

  const setupForPaymentHasIssue =
    oFetch(setupForPayment, 'payableFinanceReportsWithPayslipCount') !== oFetch(setupForPayment, 'payableFinanceReportsWithPayslipAndBankDetailsCount');

  function onWeekSelect(selected) {
    const startUIDate = oFetch(selected, 'startUIDate');
    window.location.href = appRoutes.modulrReports({ startDate: startUIDate });
  }

  function renderVeneusWithIncompleteWeeks() {
    if (!hasVenuesWithInCompleteWeeks) {
      return <h3 className="boss-number__value boss-number__value_size_s">N/A</h3>;
    }
    return venuesWithIncompleteWeek.map((venueWithIncompleteWeek, index) => {
      const [id, name] = oFetch(venueWithIncompleteWeek, 'id', 'name');
      return (
        <Fragment key={id}>
          <a
            target="_blank"
            rel="noreferrer"
            href={appRoutes.financeReports({ startDate: weekStartDate, venueId: id })}
            className="boss-number__value boss-number__value_size_s"
          >
            {name}
          </a>
          <span
            className="boss-number__value boss-number__value_size_s"
            style={{ marginRight: index < venuesWithIncompleteWeek.length - 1 ? '.5rem' : '0rem' }}
          >
            {index < venuesWithIncompleteWeek.length - 1 ? ',' : ''}
          </span>
        </Fragment>
      );
    });
  }

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_modulr-reports">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Modulr Reports</h1>
        </div>
        <div className="boss-page-dashboard__group">
          <div className="boss-page-dashboard__meta">
            <BossWeekPickerPopover
              weekStartDate={weekStartDate}
              weekEndDate={weekEndDate}
              onWeekSelect={onWeekSelect}
            />
          </div>
        </div>

        <h4 className="boss-page-dashboard__subtitle">Payroll Week</h4>
        <div className="boss-page-dashboard__controls-group">
          <div className="boss-page-dashboard__group">
            <div className="boss-table boss-table_page_finance-reports-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">Status:</p>
                        <h3 className="boss-number__value boss-number__value_size_s">
                          {oFetch(payrollWeek, 'statusMessage')}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-table boss-table_page_finance-reports-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">
                          Venues with Uncompleted Weeks:
                        </p>
                        {renderVeneusWithIncompleteWeeks()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="boss-page-dashboard__subtitle">Overview</h4>
        <div className="boss-page-dashboard__controls-group">
          <div className="boss-page-dashboard__group">
            <div className="boss-table boss-table_page_finance-reports-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">Grand Total:</p>
                        <h3 className="boss-number__value boss-number__value_size_s">
                          {getFormattedMoney(grandTotalNetWagesCents)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">
                          Setup for Payment:
                        </p>
                        <h3 className="boss-number__value boss-number__value_size_s">
                          {setupForPaymentHasIssue && (
                            <span className="boss-number__value-delta">
                              {oFetch(setupForPayment, 'payableFinanceReportsWithPayslipAndBankDetailsCount')} /{' '}
                              {oFetch(setupForPayment, 'payableFinanceReportsWithPayslipCount')}
                            </span>
                          )}
                          {!setupForPaymentHasIssue && (
                            <Fragment>
                              {oFetch(setupForPayment, 'payableFinanceReportsWithPayslipAndBankDetailsCount')} /{' '}
                              {oFetch(setupForPayment, 'payableFinanceReportsWithPayslipCount')}
                            </Fragment>
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
