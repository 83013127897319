import React, { useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function YearSelect() {
  const [years, initialYear] = oFetch(useLegacyGlobal(), 'years', 'year');
  const [year, setYear] = useState(initialYear);

  const yearsOptions = useMemo(() => {
    return years.map(year => {
      return {
        label: year,
        value: year,
      };
    });
  }, [years]);

  function handleChange(selectedYear) {
    setYear(selectedYear);
    utils.applyNewPageQueryParams({ year: selectedYear });
  }

  return (
    <div className="select-year-container m-b-20">
      <div className="boss-form">
        <div className="boss-form__row">
          <div className="boss-form__field boss-form__field_layout_third">
            <label className="boss-form__label">
              <span className="boss-form__label-text">Select Year</span>
            </label>
            <div className="boss-form__select">
              <Select
                value={year}
                onChange={handleChange}
                clearable={false}
                simpleValue
                multi={false}
                placeholder="Select Year"
                options={yearsOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
