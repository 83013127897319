import React from 'react';
import { Field, Fields, reduxForm } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import { InputInlineField, InputField, DateTimeField, CheckboxField } from './form-fields';

import { BossFormRichTextField } from '@/components/boss-form';

function IncidentReportForm(props) {
  const [handleSubmit, submitting, submission] = oFetch(props, 'handleSubmit', 'submitting', 'submission');
  return (
    <form onSubmit={handleSubmit(submission)} className="boss-form">
      <div className="boss-form__group boss-form__group_role_board">
        <Fields names={['date', 'time']} label="Date and Time of Incident" component={DateTimeField} />
        <Field name="location" label="Exact Location of Incident" component={InputInlineField} />
        <Field name="description" label="Short Description" component={InputInlineField} />
        <Field name="showOnDoorBook" label="Include on Door Book" left={false} component={CheckboxField} />
      </div>
      <Field
        name="uninvolvedWitnessDetails"
        label="Details of Witnesses not directly Involved in the Incident"
        note="(Please include Name, Phone number, and Badge number if applicable)"
        component={BossFormRichTextField}
      />
      <Field
        name="involvedWitnessDetails"
        label="Details of Witness Involved in the Incident"
        note="(Please include Name, Phone number, and Badge number if applicable)"
        component={BossFormRichTextField}
      />
      <Field
        name="policeOfficerDetails"
        label="Details of Police Officers in attendance"
        note="(Please include Name, Rank, and Collar number if applicable)"
        component={BossFormRichTextField}
      />
      <Field name="recordedByName" label="CCTV Recorded By" component={InputField} />
      <Field name="cameraName" label="Cameras Recorded Incident" component={InputField} />
      <Field name="report" label="Report" component={BossFormRichTextField} />
      <div className="boss-form__field boss-form__field_justify_end-center">
        <button type="submit" disabled={submitting} className="boss-button boss-form__submit">
          Save
        </button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'incident-report-form',
  fields: ['id', 'venueId', 'incidentTime'],
})(IncidentReportForm);
