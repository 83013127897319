import React from 'react';
import DashboardWrapper from '@/components/dashboard-wrapper';

export function RekognitionDashboard(props) {
  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard__group">
        <h1 className="boss-page-dashboard__title">Rekognition test</h1>
      </div>
    </DashboardWrapper>
  );
}
