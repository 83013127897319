import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function ForStaffMembersSecurityPayRateForm(props) {
  const [onSubmit, initialValues, isEdit] = oFetch(props, 'onSubmit', 'initialValues', 'isEdit');
  const backendConstants = oFetch(useLegacyGlobal(), 'backendConstants');

  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={(formValues, ...rest) => {
          const rawStandardHourlyRateCents = oFetch(formValues, 'standardHourlyRateCents');
          const rawWeekendHourlyRateCents = oFetch(formValues, 'weekendHourlyRateCents');

          const values = {
            ...formValues,
            standardHourlyRateCents:
              rawStandardHourlyRateCents &&
              new BigNumber(rawStandardHourlyRateCents).times(100).integerValue().toNumber(),
            weekendHourlyRateCents:
              rawWeekendHourlyRateCents &&
              new BigNumber(rawWeekendHourlyRateCents).times(100).integerValue().toNumber(),
          };
          return onSubmit(values, ...rest);
        }}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, ...rest }) => {
          const accessLevelOptions = [
            {
              value: oFetch(backendConstants, 'SECURITY_PAY_RATE_GENERAL_ACCESS_LEVEL'),
              label: 'General',
            },
            {
              value: oFetch(backendConstants, 'SECURITY_PAY_RATE__ADMIN_ACCESS_LEVEL'),
              label: 'Admin',
            },
          ];

          const staffTypeOptions = [
            {
              value: oFetch(backendConstants, 'SECURITY_PAY_RATE_CUSTOM_STANDARD_SUB_TYPE'),
              label: 'Standard',
            },
            {
              value: oFetch(backendConstants, 'SECURITY_PAY_RATE_CUSTOM_STEWARD_SUB_TYPE'),
              label: 'Steward',
            },
          ];

          return (
            <div className="boss-table__form">
              <div className="boss-form">
                <Field name="name" label="Name" component={BossFormInput} />
                {!isEdit && (
                  <Field
                    name="subType"
                    label="Staff Type"
                    parse={value => value}
                    options={staffTypeOptions}
                    component={BossFormSelectNew}
                  />
                )}
                <Field
                  name="accessLevel"
                  label="Access Level"
                  options={accessLevelOptions}
                  parse={value => value}
                  component={BossFormSelectNew}
                />
                <Field
                  name="standardHourlyRateCents"
                  label="Standard Rate"
                  unit="£"
                  parse={value => value}
                  isCurrency
                  component={BossFormInput}
                />
                <Field
                  name="weekendHourlyRateCents"
                  label="Weekend Rate"
                  unit="£"
                  isCurrency
                  parse={value => value}
                  component={BossFormInput}
                />
                <div className="boss-form__field">
                  {!isEdit && (
                    <button
                      onClick={handleSubmit}
                      className="boss-button boss-button_role_add boss-form__submit"
                      disabled={submitting}
                      type="submit"
                    >
                      Create Pay Rate
                    </button>
                  )}
                  {isEdit && (
                    <button
                      onClick={handleSubmit}
                      className="boss-button boss-form__submit"
                      disabled={submitting}
                      type="submit"
                    >
                      Update Pay Rate
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
