import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import BossFormCheckbox from '@/components/boss-form/boss-form-checkbox';
import BossFormTextarea from '@/components/boss-form/boss-form-textarea';
import { AccessoriesChangeStatus } from './accessories-change-status';

const RETURNED_REUSABLE_STATUS = 'returned_reusable';
const RETURNED_NOT_REUSABLE_STATUS = 'returned_not_reusable';
const NOT_RETURNED_STATUS = 'not_returned';

const STATUS_OPTIONS = [
  {
    label: 'Returned: Reusable',
    value: RETURNED_REUSABLE_STATUS,
  },
  {
    label: 'Returned: Not Reusable',
    value: RETURNED_NOT_REUSABLE_STATUS,
  },
  {
    label: 'Not Returned',
    value: NOT_RETURNED_STATUS,
  },
];

function DisableStaffMemberForm(props) {
  const renderBaseErrors = errors => {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  const { handleSubmit, submitting, accessories, submission, error } = props;
  const hasRecevedAccessories = accessories.size > 0;

  return (
    <div className="boss-modal-window__form">
      <form className="boss-form">
        {error && renderBaseErrors(error)}
        <Field
          component={BossFormCheckbox}
          name="neverRehire"
          label="Do not rehire this person (Give reason below)"
        />
        <Field component={BossFormTextarea} required name="disableReason" label="Reason for disabling" />
        {hasRecevedAccessories && (
          <AccessoriesChangeStatus
            name={'accessoryRequests'}
            label={'Received Accessories'}
            accessories={accessories}
            statusOptions={STATUS_OPTIONS}
          />
        )}
        <div className="boss-form__field">
          <button
            type="button"
            onClick={handleSubmit(submission)}
            disabled={submitting}
            className="boss-button boss-button_role_block boss-form__submit"
          >
            Disable
          </button>
        </div>
      </form>
    </div>
  );
}

export default reduxForm({
  form: 'disable-staff-member-form',
})(DisableStaffMemberForm);
