import oFetch from 'o-fetch';
import React from 'react';

export function HtmlChangesModalContent(props) {
  const [oldHtml, newHtml] = oFetch(props, 'oldHtml', 'newHtml');
  return (
    <div className="boss-modal-window__overview">
      <p className="boss-overview__text" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <span className="old-text" dangerouslySetInnerHTML={{ __html: oldHtml }} />{' '}
        <span dangerouslySetInnerHTML={{ __html: newHtml }} />
      </p>
    </div>
  );
}
