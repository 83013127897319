import React from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function toFixedAndPounds(value) {
  try {
    return `£${value.toFixed(2)}`;
  } catch (e) {
    return value;
  }
}
export default class CountingOverviewTableRow extends React.Component {
  state = {
    isOpened: false,
  };

  toggleDropDown = () => {
    this.setState((state) => ({ isOpened: !oFetch(state, 'isOpened') }));
  };

  render() {
    const isOpened = oFetch(this.state, 'isOpened');
    const [counting, statusClassName, weekStart] = oFetch(this.props, 'counting', 'statusClassName', 'weekStart');
    const [
      venueId,
      name,
      changeTotalPence,
      fiftyNotesTotalPounds,
      fiveNotesTotalPounds,
      tenNotesTotalPounds,
      twentyNotesTotalPounds,
      total,
      createdBy,
    ] = oFetch(
      counting,
      'venueId',
      'name',
      'changeTotalPence',
      'fiftyNotesTotalPounds',
      'fiveNotesTotalPounds',
      'tenNotesTotalPounds',
      'twentyNotesTotalPounds',
      'total',
      'createdBy',
    );

    function renderCreatedBy() {
      if (createdBy) {
        const [fullName, at] = oFetch(createdBy, 'fullName', 'at');
        const formattedAt = safeMoment.iso8601Parse(at).format(utils.commonDateFormatWithTime());
        return (
          <div className="purple-table__meta-item">
            <p className="purple-meta">
              <span className="purple-meta__text">
                <span className="purple-meta__text-faded">Created at </span>
                <span className="purple-meta__text-bold">{formattedAt}</span>
                <span className="purple-meta__text-faded"> by </span>
                <span className="purple-meta__text-bold">{fullName}</span>
              </span>
            </p>
          </div>
        );
      }
      return (
        <div className="purple-table__meta-item">
          <p className="purple-meta">
            <span className="purple-meta__text">Not Submitted</span>
          </p>
        </div>
      );
    }

    return (
      <div className={statusClassName}>
        <div className="purple-table__row purple-table__row_adjust_group">
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{name}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(fiveNotesTotalPounds)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(tenNotesTotalPounds)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(twentyNotesTotalPounds)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(fiftyNotesTotalPounds)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(changeTotalPence)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <p className="purple-table__text">{toFixedAndPounds(total)}</p>
            </div>
          </div>
          <div className="purple-table__cell">
            <div className="purple-table__info">
              <div className="purple-table__actions">
                <button
                  className="purple-button purple-button_size_xxs purple-button_border_accent-blue purple-button_icon_zoom-plus purple-table__action js-table-dropdown-switch purple-button_state_active"
                  type="button"
                  onClick={this.toggleDropDown}
                >
                  <span className="purple-button__text">Details</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className="purple-table__dropdown">
            <div className="purple-table__meta">
              {renderCreatedBy()}
              <div className="purple-table__meta-item">
                <p className="purple-meta">
                  <a
                    className="purple-button purple-button_size_xxs purple-button_color_accent-purple"
                    href={`/purple/counting?venue_id=${venueId}&week_start=${weekStart}`}
                    target="_blank"
                  >
                    <span className="purple-button__text">View</span>
                  </a>
                </p>
              </div>
            </div>
            <button
              onClick={this.toggleDropDown}
              className="purple-table__dropdown-close js-table-dropdown-close"
              type="button"
            >
              Toggle Dropdown
            </button>
          </div>
        </Collapse>
      </div>
    );
  }
}
