import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';

import { EditMode, ViewMode } from './components';
import * as actions from './actions';
import * as selectors from './selectors';

class CheckListsApp extends React.Component {
  state = {
    editMode: false,
  };

  openEditMode = () => {
    this.setState({ editMode: true });
  };

  closeEditMode = () => {
    this.setState({ editMode: false });
  };

  render() {
    const editMode = oFetch(this.state, 'editMode');
    const [
      checklists,
      submitChecklist,
      createChecklist,
      deleteChecklist,
      setEditChecklistId,
      editChecklistId,
      clearEditChecklistId,
      updateChecklist,
      printGuid,
    ] = oFetch(
      this.props,
      'checklists',
      'submitChecklist',
      'createChecklist',
      'deleteChecklist',
      'setEditChecklistId',
      'editChecklistId',
      'clearEditChecklistId',
      'updateChecklist',
      'printGuid',
    );

    if (editMode) {
      return (
        <EditMode
          checklists={checklists}
          onPrintGuid={printGuid}
          onDeleteChecklist={deleteChecklist}
          onCancelEditMode={this.closeEditMode}
          onCreateChecklist={createChecklist}
          editChecklistId={editChecklistId}
          onEditChecklist={setEditChecklistId}
          onCancelEditChecklist={clearEditChecklistId}
          onUpdateChecklist={updateChecklist}
        />
      );
    }

    return <ViewMode
      onSubmit={submitChecklist}
      checklists={checklists}
      onEditMode={this.openEditMode}
      onPrintGuid={printGuid}
    />;
  }
}

const mapStateToProps = state => {
  return {
    checklists: selectors.getChecklists(state),
    editChecklistId: selectors.editChecklistIdSelector(state),
  };
};

const mapDispatchToProps = {
  printGuid: actions.printGuid,
  submitChecklist: actions.submitChecklist,
  updateChecklist: actions.updateChecklist,
  createChecklist: actions.createChecklist,
  deleteChecklist: actions.deleteChecklist,
  setEditChecklistId: actions.setEditChecklistId,
  clearEditChecklistId: actions.clearEditChecklistId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckListsApp);
