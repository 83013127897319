import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import * as constants from './constants';
import { queryParamValues } from './components/payment-filter';
import { apiRoutes } from '@/lib/legacy-routes';

export const initialPageLoad = params => (dispatch, getState) => {
  const staffMember = oFetch(params, 'staffMember');
  const accessToken = oFetch(params, 'accessToken');
  const payments = oFetch(params, 'payments');
  const paymentFilter = oFetch(params, 'paymentFilter');

  dispatch(
    loadInitialState({
      staffMember: staffMember,
      accessToken: accessToken,
      payments: payments,
      paymentFilter: paymentFilter,
    }),
  );
};

export const filterPayments = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const filterParams = oFetch(params, 'filterParams');
  const mStartDate = oFetch(filterParams, 'mStartDate');
  const mEndDate = oFetch(filterParams, 'mEndDate');
  const statusFilter = oFetch(filterParams, 'statusFilter');

  const queryStringParams = queryParamValues({
    mStartDate: mStartDate,
    mEndDate: mEndDate,
    statusFilter: statusFilter,
  });

  const apiRoute = oFetch(apiRoutes, 'staffMemberPayments');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(refreshPayments(data));
    },
  }).get(apiRoute.getPath(staffMemberId, queryStringParams));
};

export const sendPayslipEmail = params => {
  const payslipId = oFetch(params, 'payslipId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Payslip email was send successfully',
      });
    },
  }).post(`/api/v1/finance_reports/send_payslip`, {
    payslipId: payslipId,
  });
};

export const loadInitialState = createAction(constants.INITIAL_PAGE_LOAD);
export const refreshPayments = createAction(constants.REFRESH_PAYMENTS);
