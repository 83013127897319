import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

const QUIZ_PASSED = 'quiz_pass';
const QUIZ_FAILED = 'quiz_fail';
const RANK_UP = 'rank_up';
const RANK_DOWN = 'rank_down';

function RankItem(props) {
  const [formattedAt, eventType, extraData] = oFetch(props, 'formattedAt', 'eventType', 'extraData');
  const [
    beforeRankName,
    beforeRankMultiplier,
    beforeRankIconImageUrl,
    afterRankName,
    afterRankMultiplier,
    afterRankIconImageUrl,
  ] = oFetch(
    extraData,
    'beforeRankName',
    'beforeRankMultiplier',
    'beforeRankIconImageUrl',
    'afterRankName',
    'afterRankMultiplier',
    'afterRankIconImageUrl',
  );

  const itemClassNames = cn('boss-timeline__item boss-timeline__item_adjust_card', {
    'boss-timeline__item_role_down': eventType === RANK_DOWN,
    'boss-timeline__item_role_up': eventType === RANK_UP,
  });

  function renderTitle() {
    if (eventType === RANK_DOWN) {
      return 'Rank Down!';
    }
    if (eventType === RANK_UP) {
      return 'Rank Up!';
    }
  }

  return (
    <li className={itemClassNames}>
      <div className="boss-timeline__inner">
        <div className="boss-check boss-check_role_panel boss-check_page_smp-quizzes-history">
          <div className="boss-check__header">
            <div className="boss-check__header-group">
              <h3 className="boss-check__title">{renderTitle()}</h3>
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Event Date: </span>
                    <span className="boss-check__text-marked">{formattedAt}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="boss-check__header-badge">
              <div className="boss-comparison boss-comparison_page_smp-quizzes-history">
                <div className="boss-comparison__content">
                  <div className="boss-comparison__item boss-comparison__item_position_start boss-comparison__item_type_faded">
                    <div className="boss-indicator">
                      <img src={beforeRankIconImageUrl} alt="before rank" className="boss-indicator__badge_size_xs" />
                      <span className="boss-indicator__label boss-indicator__label_type_regular boss-indicator__label_size_s boss-indicator__label_position_after">
                        {beforeRankName} <span className="boss-indicator__label-faded"> x{beforeRankMultiplier}</span>
                      </span>
                    </div>
                  </div>
                  <div className="boss-comparison__delimiter" />
                  <div className="boss-comparison__item boss-comparison__item_position_end">
                    <div className="boss-indicator">
                      <img src={afterRankIconImageUrl} alt="after rank" className="boss-indicator__badge_size_xs" />
                      <span className="boss-indicator__label boss-indicator__label_type_regular boss-indicator__label_size_s boss-indicator__label_position_after">
                        {afterRankName} <span className="boss-indicator__label-faded"> x{afterRankMultiplier}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

function QuizItem(props) {
  const [formattedAt, eventType, extraData] = oFetch(props, 'formattedAt', 'eventType', 'extraData');
  const [totalQuestionCount, correctAnswerCount] = oFetch(extraData, 'totalQuestionCount', 'correctAnswerCount');

  const itemClassNames = cn('boss-timeline__item boss-timeline__item_adjust_card', {
    'boss-timeline__item_role_failed': eventType === QUIZ_FAILED,
    'boss-timeline__item_role_passed': eventType === QUIZ_PASSED,
  });

  function renderTitle() {
    if (eventType === QUIZ_FAILED) {
      return 'Quiz Failed!';
    }
    if (eventType === QUIZ_PASSED) {
      return 'Quiz Passed!';
    }
  }

  return (
    <li className={itemClassNames}>
      <div className="boss-timeline__inner">
        <div className="boss-check boss-check_role_panel boss-check_page_smp-quizzes-history">
          <div className="boss-check__header">
            <div className="boss-check__header-group">
              <h3 className="boss-check__title">{renderTitle()}</h3>
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Event Date: </span>
                    <span className="boss-check__text-marked">{formattedAt}</span>
                  </p>
                </div>
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Correct Answers: </span>
                    <span className="boss-check__text-marked">
                      {correctAnswerCount}/{totalQuestionCount}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default function HistoryItem(props) {
  const item = oFetch(props, 'item');
  const [formattedAt, eventType, extraData] = oFetch(item, 'formattedAt', 'eventType', 'extraData');

  function renderItem() {
    if (eventType === QUIZ_PASSED || eventType === QUIZ_FAILED) {
      return <QuizItem formattedAt={formattedAt} eventType={eventType} extraData={extraData} />;
    }
    if (eventType === RANK_UP || eventType === RANK_DOWN) {
      return <RankItem formattedAt={formattedAt} eventType={eventType} extraData={extraData} />;
    }
    throw new Error('Wrong history event type');
  }
  return renderItem();
}
