import oFetch from 'o-fetch';
import { http, bossRequestHttp } from '@/lib/request-api';
import { openErrorModal } from '@/components/modals';
import { apiRoutes } from '@/lib/legacy-routes';

export const finishFinanceReportWeekRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const weekStart = oFetch(values, 'weekStart');
  const venueId = oFetch(values, 'venueId');

  const path = apiRoutes.finishFinanceReportWeek.getPath();
  return bossRequestHttp({
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
  }).post(path, {
    weekStart,
    venueId,
  });
};

export const undoFinanceReportWeekRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const financeReportWeekId = oFetch(values, 'financeReportWeekId');

  const path = apiRoutes.undoFinanceReportWeek.getPath();
  return bossRequestHttp({
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
  }).post(path, {
    financeReportWeekId,
  });
};

export const markReportCompletedRequest = params => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const date = oFetch(params, 'date');
  const venueId = oFetch(params, 'venueId');

  return http({ successMessage: 'Report marked successfully' })
    .post(`/api/v1/finance_reports/${date}/complete`, {
      staffMemberId,
      venueId,
    })
    .catch(error => {
      openErrorModal();
      return Promise.reject(error);
    });
};

export const uncompleteFinanceReportRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const staffMemberId = oFetch(values, 'staffMemberId');
  const date = oFetch(values, 'date');
  const venueId = oFetch(values, 'venueId');

  return bossRequestHttp({
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
  }).post(`/api/v1/finance_reports/${date}/uncomplete`, {
    staffMemberId,
    venueId,
  });
};

export const updateFinanceReportNoteRequest = params => {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const staffMemberId = oFetch(values, 'staffMemberId');
  const date = oFetch(values, 'date');
  const venueId = oFetch(values, 'venueId');
  const note = oFetch(values, 'note');

  return bossRequestHttp({
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
    errorHandler() {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
  }).post(`/api/v1/finance_reports/${date}/update_note`, {
    staffMemberId,
    venueId,
    note,
  });
};

export const getBankDetailsRequest = params => {
  const [staffMemberId, onSuccess] = oFetch(params, 'staffMemberId', 'onSuccess');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).get(`/api/v1/staff_members/${staffMemberId}/show_bank_details`);
};

export const wipeBankDetailsRequest = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const staffMemberId = oFetch(values, 'staffMemberId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Bank details were wiped successfully',
      });
      onSuccess();
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/wipe_bank_details`);
};

export const markReportsCompletedRequest = params => {
  const staffMemberIds = oFetch(params, 'staffMemberIds');
  const date = oFetch(params, 'date');
  const venueId = oFetch(params, 'venueId');

  return http({ successMessage: 'Reports marked successfully' })
    .post(`/api/v1/finance_reports/${date}/complete_multiply`, {
      staffMemberIds,
      venueId,
    })
    .catch(error => {
      openErrorModal();
      return Promise.reject(error);
    });
};

export const sendPayslipEmail = params => {
  const [payslip, closeModal] = oFetch(params, 'payslip', 'closeModal');
  const payslipId = oFetch(payslip, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Payslip email was send successfully',
      });
      closeModal();
    },
  }).post(`/api/v1/finance_reports/send_payslip`, {
    payslipId: payslipId,
  });
};
