import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { RatingForm } from './rating-form';

export function EditRatingModalContent(props) {
  const [onSubmit, kpiDataRenderer, venueId, kpiId, year, quarter, score, ratingNote] = oFetch(
    props,
    'onSubmit',
    'kpiDataRenderer',
    'venueId',
    'kpiId',
    'year',
    'quarter',
    'score',
    'ratingNote',
  );
  const initialValues = {
    kpiId,
    venueId,
    year,
    quarter,
    rating: score,
    note: ratingNote,
  };
  return (
    <RatingForm
      kpiDataRenderer={kpiDataRenderer}
      onSubmit={onSubmit}
      initialValues={initialValues}
      submitTitle={'Update Rating'}
    />
  );
}
