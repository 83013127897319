import cn from 'classnames';
import oFetch from 'o-fetch';
import React, { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as zip from '@/lib/training-materials-zip';

export function UploadTrainingMaterialsZip(props) {
  const [onUpload, submitErrors, categoryId] = oFetch(
    props,
    'onUpload',
    'submitErrors',
    'categoryId',
  );
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState([]);
  const hasErrors = errors.length > 0;
  const hasSubmitErrors = submitErrors.length > 0;

  const fieldClassNames = cn('boss-upload', { 'boss-upload_state_error': hasErrors });

  const onDrop = useCallback(acceptedFiles => {
    setErrors([]);

    const zipFile = acceptedFiles[0];
    setUploading(true);

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const base64 = reader.result;
      const rawBase64 = base64.split('data:application/zip;base64,')[1];
      zip.processTrainingMaterialsZip(rawBase64).then(res => {
        const { errors, processed } = res;
        if (errors.length === 0) {
          onUpload({ processed, zipFile, categoryId }).finally(() => {
            setUploading(false);
          });
        } else {
          setErrors(errors);
          setUploading(false);
        }
      });
    };

    reader.readAsDataURL(zipFile);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
  });

  function renderSubmitErrors() {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text boss-form__error-text_adjust_wrap">
          {submitErrors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className="boss-page-main__group boss-page-main__group_context_stack boss-page-main__group_adjust_training-materials "
    >
      <div className="boss-form">
        <div className="boss-form__field">
          {hasSubmitErrors && renderSubmitErrors()}
          {hasErrors && (
            <div className="boss-form__error">
              <p className="boss-form__error-text boss-form__error-text_adjust_wrap">
                <span className="boss-form__error-line">
                  <span className="boss-form__error-marked">File Format Incorrect</span>
                </span>
                <span className="boss-form__error-line">
                  Zip File was not in correct format. Please ensure file has been exported from
                  Google Docs as save as HTML
                </span>
              </p>
            </div>
          )}
          <div className={fieldClassNames}>
            <div className="boss-upload__area boss-upload__area_adjust_form">
              <div className="boss-upload__area-inner">
                {!uploading && (
                  <Fragment>
                    <input {...getInputProps()} />
                    <div className="boss-upload__area-icon boss-upload__area-icon_role_add" />
                    <p className="boss-upload__area-text boss-upload__area-text_adjust_form">
                      Drag Zip file here to upload
                    </p>
                  </Fragment>
                )}
                {uploading && (
                  <div className="boss-spinner boss-spinner_size_large boss-upload__area-spinner" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
