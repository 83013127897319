import { DashboardWrapper } from '@/components/dashboard';
import { YearSelector } from '@/components/year-selector';
import oFetch from 'o-fetch';
import React from 'react';

type Props = {
  onAddClick: () => void;
  onYearSelect: (year: number) => void;
  selectedYear: number;
  years: number[];
};

export function Dashboard(props: Props) {
  const onAddClick = oFetch(props, 'onAddClick');
  const onYearSelect = oFetch(props, 'onYearSelect');
  const selectedYear = oFetch(props, 'selectedYear');
  const years = oFetch(props, 'years');

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Public Holidays</h1>
          <div className="boss-page-dashboard__buttons-group">
            <button
              onClick={onAddClick}
              className="boss-button boss-button_role_add boss-page-dashboard__button"
            >Add Public Holiday</button>
          </div>
        </div>
        <YearSelector
          selected={selectedYear}
          onChange={onYearSelect}
          options={years}
        />
      </div>
    </DashboardWrapper>
  );
}
