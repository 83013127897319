import React from 'react';
import { connect } from 'react-redux';

import MachinesRefloatsDashboard from '../components/machines-refloats-dashboard';
import MachinesRefloatsList from '../components/machines-refloats-list';
import RecordRefloat from '../components/record-refloat';

const mapStateToProps = state => {
  return {
    isRecordRefloat: state.getIn(['page', 'isRecordRefloat']),
    venueMachines: state.getIn(['page', 'venueMachines']),
    machinesRefloats: state.getIn(['page', 'machinesRefloats']),
    pageCount: state.getIn(['page', 'pagination', 'pageCount']),
    currentPage: state.getIn(['page', 'pagination', 'currentPage']),
  };
};

function MachinesRefloats(props) {
  const { isRecordRefloat, venueMachines, machinesRefloats, pageCount, currentPage } = props;

  return (
    <div>
      <MachinesRefloatsDashboard />
      {isRecordRefloat ? (
        <RecordRefloat machines={venueMachines} />
      ) : (
        <MachinesRefloatsList
          key="refloatsList"
          pageCount={pageCount}
          currentPage={currentPage}
          machinesRefloats={machinesRefloats}
          machines={venueMachines}
        />
      )}
    </div>
  );
}

export default connect(mapStateToProps)(MachinesRefloats);
