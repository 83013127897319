import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import oFetch from 'o-fetch';

export function ServiceButton(props) {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [onActivityClick, isAccepted] = oFetch(props, 'onActivityClick', 'isAccepted');

  function renderNotAcceptedTaskActions() {
    const [onEditClick, onRemoveClick, canEdit, canRemove] = oFetch(
      props,
      'onEditClick',
      'onRemoveClick',
      'canEdit',
      'canRemove',
    );

    return (
      <>
        {canEdit && (
          <button
            onClick={() => {
              onEditClick();
              setIsTooltipOpened(false);
            }}
            type="button"
            className="boss-action boss-action_role_edit-admin boss-action_size_s boss-action_adjust_row"
          >
            <span className="boss-action__text">Edit Task</span>
          </button>
        )}
        {canRemove && (
          <button
            onClick={() => {
              onRemoveClick();
              setIsTooltipOpened(false);
            }}
            type="button"
            className="boss-action boss-action_role_remove boss-action_size_s boss-action_adjust_row"
          >
            <span className="boss-action__text">Remove Task</span>
          </button>
        )}
      </>
    );
  }

  return (
    <Tooltip
      arrow
      open={isTooltipOpened}
      theme="light"
      position="left"
      onRequestClose={() => setIsTooltipOpened(false)}
      interactive
      html={
        <>
          <button
            onClick={() => {
              onActivityClick();
              setIsTooltipOpened(false);
            }}
            type="button"
            className="boss-action boss-action_role_activity boss-action_size_s boss-action_adjust_row"
          >
            <span className="boss-action__text">Activity</span>
          </button>
          {!isAccepted && renderNotAcceptedTaskActions()}
        </>
      }
    >
      <div className="boss-task__service">
        <button
          onClick={() => setIsTooltipOpened(true)}
          type="button"
          className="boss-task__service-switch js-tippy-task-service"
          aria-expanded="true"
        >
          Service
        </button>
      </div>
    </Tooltip>
  );
}
