import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { appRoutes } from '@/lib/legacy-routes';

export function UserMobileItem(props) {
  const [user, onAppDetailsClick] = oFetch(props, 'user', 'onAppDetailsClick');
  const id = oFetch(user, 'id');
  const name = oFetch(user, 'fullName');
  const status = oFetch(user, 'statusText');
  const email = oFetch(user, 'email');
  const role = oFetch(user, 'roleText');
  const isLinked = oFetch(user, 'isLinked');
  const disabled = oFetch(user, 'disabled');
  const appVenuesPermissions = oFetch(user, 'appVenuesPermissions');

  const cardClassNames = cn('boss-check boss-check_role_board boss-check_page_users-index', {
    'boss-check_state_disabled': disabled,
  });

  function renderApps() {
    if (isLinked) {
      const hasAppVenuesPermissions = appVenuesPermissions.length > 0;
      if (hasAppVenuesPermissions) {
        return (
          <span className="boss-tag boss-tag_role_label boss-tag_color_accent-green">
            <span className="boss-tag__text">N/A</span>
          </span>
        );
      }

      return appVenuesPermissions.map(appVenuesPermission => {
        const [appId, name] = oFetch(appVenuesPermission, 'appId', 'name');
        return (
          <button
            onClick={() => onAppDetailsClick({ appVenuesPermission })}
            key={appId}
            className="boss-tag boss-tag_role_label boss-tag_color_accent-green"
          >
            <span className="boss-tag__text">{name}</span>
          </button>
        );
      });
    } else {
      const appNames = oFetch(user, 'unlinkedAppNames');
      let index = 0;
      return appNames.map((appName) => {
        let key = index;
        index++;
        const nullAppVenuesPermission = { name: appName, venues: []};
        return (
          <button
            onClick={() => onAppDetailsClick({ appVenuesPermission: nullAppVenuesPermission })}
            key={key}
            className="boss-tag boss-tag_role_label boss-tag_color_accent-green"
          >
            <span className="boss-tag__text">{appName}</span>
          </button>
        )
      });
    }
  }

  return (
    <div className={cardClassNames}>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <h3 className="boss-check__title boss-check__title_role_user">
            <a href={`/users/${id}`} className="boss-table__link" style={{ fontSize: '18px' }}>
              {name}
            </a>
            {isLinked && (
              <span className="boss-check__title-indicator">
                <a
                  href={appRoutes.staffMemberProfile(oFetch(user, 'staffMemberId'))}
                  target="_blank"
                  rel="noreferrer"
                  className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                >
                  <span className="boss-indicator__icon boss-indicator__icon_size_s boss-indicator__icon_chain" />
                </a>
              </span>
            )}
          </h3>
        </div>
      </div>
      <div className="boss-check__row boss-check__row_marked">
        <div className="boss-check__cell">
          <p className="boss-check__text">{renderApps()}</p>
        </div>
      </div>
      <div className="boss-check__row boss-check__row_marked">
        <div className="boss-check__info-table">
          <div className="boss-check__info-row">
            <div className="boss-check__info-cell">
              <p className="boss-check__text">Email</p>
            </div>
            <div className="boss-check__info-cell">
              <p className="boss-check__text boss-check__text_role_primary boss-check__text_adjust_wrap">
                {email}
              </p>
            </div>
          </div>
          <div className="boss-check__info-row">
            <div className="boss-check__info-cell">
              <p className="boss-check__text">Role</p>
            </div>
            <div className="boss-check__info-cell">
              <p className="boss-check__text boss-check__text_role_primary">{role}</p>
            </div>
          </div>
          <div className="boss-check__info-row">
            <div className="boss-check__info-cell">
              <p className="boss-check__text">Status</p>
            </div>
            <div className="boss-check__info-cell">
              <p className="boss-check__text boss-check__text_role_primary">{status}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
