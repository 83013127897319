import React from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';

import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import VenueHealthCheck from './venue-health-check';
import * as actions from './redux/actions';

class VenueHealthCheckApp extends React.Component {
  state = {
    store: null,
  };

  async componentDidMount() {
    const [
      accessToken,
      venueId,
      newResponsePath,
      manageQuestionnairePath,
      canManageQuestionnaire,
      canCreateQuestionnaireResponse
    ] = oFetch(
      this.props,
      'accessToken',
      'venueId',
      'newResponsePath',
      'manageQuestionnairePath',
      'canManageQuestionnaire',
      'canCreateQuestionnaireResponse'
    );
    if (!accessToken) {
      throw new Error('Access token must be present');
    }

    window.boss.accessToken = accessToken;

    const store = configureStore(
      reducers,
      {
        staticData: {
          venueId,
          newResponsePath,
          manageQuestionnairePath,
          canManageQuestionnaire,
          canCreateQuestionnaireResponse
        }
      }
    );
    await store.dispatch(actions.loadPageData({ page: 1 }));
    this.setState({ store });
  }

  render() {
    if (!this.state.store) {
      return null;
    }

    return (
      <Provider store={this.state.store}>
        <VenueHealthCheck />
      </Provider>
    );
  }
}

export default VenueHealthCheckApp;
