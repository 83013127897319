import { combineReducers } from 'redux-immutable';
import venues from './venues-reducer';
import possibleReminderUsersAddVenue from './possible-reminder-users-add-venue-reducer';
import fruitOrderFields from './fruit-order-fields-reducer';

export default combineReducers({
  venues,
  possibleReminderUsersAddVenue,
  fruitOrderFields,
});
