import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { modalRedux } from '@/components/modals';
import AssignConfirmForm from './assign-confirm-form';

function AssignConfirm(props) {
  const appType = oFetch(props, 'appType');
  const avatarUrl = oFetch(props, 'avatarUrl');
  const staffMemberId = oFetch(props, 'staffMemberId');
  const fullName = oFetch(props, 'fullName');
  const shiftRequest = oFetch(props, 'shiftRequest');
  const onSubmit = oFetch(props, 'onSubmit');
  const rotaShifts = oFetch(props, 'rotaShifts');
  const isSteward = oFetch(props, 'isSteward');
  const supportsFacialRecognition = oFetch(props, 'supportsFacialRecognition');

  const startsAt = oFetch(shiftRequest, 'startsAt');
  const endsAt = oFetch(shiftRequest, 'endsAt');
  const venueName = oFetch(shiftRequest, 'venueName');
  const venueId = oFetch(shiftRequest, 'venueId');
  const timeSelectOptions = oFetch(props, 'timeSelectOptions');

  const initialValues = {
    staffMemberId,
    startsAt,
    endsAt,
    headDoormanHour: isSteward ? null : false,
    venueId,
  };

  return (
    <AssignConfirmForm
      appType={appType}
      avatarUrl={avatarUrl}
      fullName={fullName}
      venueName={venueName}
      supportsFacialRecognition={supportsFacialRecognition}
      startsAt={startsAt}
      endsAt={endsAt}
      onFormSubmit={onSubmit}
      initialValues={initialValues}
      rotaShifts={rotaShifts}
      isSteward={isSteward}
      timeSelectOptions={timeSelectOptions}
    />
  );
}

AssignConfirm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default modalRedux(combineReducers({ form: formReducer }))(AssignConfirm);
