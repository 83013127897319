import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { useCarousel } from '@/components/hooks-components';

export function MessageCarousel(props) {
  const messages = oFetch(props, 'messages');
  const messagesExist = messages.length > 0;

  const { currentItem, onNextClick, onPreviousClick, isLast, isFirst, pagination } = useCarousel({
    list: messages,
    initialIndex: 0,
  });

  if (!messagesExist) {
    return null;
  }

  function renderButtons() {
    return (
      <React.Fragment>
        {!isFirst && (
          <button
            className="boss-button boss-button_role_next boss-message__meta-action"
            onClick={onNextClick}
          >
            See Next
          </button>
        )}
        {!isLast && (
          <button
            className="boss-button boss-button_role_previous boss-message__meta-action"
            onClick={onPreviousClick}
          >
            See Previous
          </button>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div
        className={
          'boss-message boss-message_role_dashboard boss-message_type_accent boss-message_context_board'
        }
      >
        {messages.length > 1 && (
          <h2
            className="boss-message__title boss-message__title_accent"
            style={{ textAlign: 'right' }}
          >{`${oFetch(pagination, 'current')} / ${oFetch(pagination, 'size')}`}</h2>
        )}
        <h2 className="boss-message__title boss-message__title_accent">{oFetch(currentItem, 'title')}</h2>
        <div
          className="boss-message__content"
          dangerouslySetInnerHTML={{ __html: oFetch(currentItem, 'message') }}
        />

        <div className="boss-message__meta">
          <div className="boss-message__meta-group">
            <p className="boss-message__meta-text">
              ( Created {moment(oFetch(currentItem, 'createdAt')).format(utils.humanDateFormatWithTime())} )
            </p>

            <div className="boss-message__meta-actions">{renderButtons()}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
