import React, { Component } from 'react';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';

import { BossFormTextarea, BossFormSelectNew } from '@/components/boss-form';
import { IncludeImage } from './fields';
import Answers from './answers';

export const FORM_NAME = 'question_form';

class QuestionForm extends Component {
  handleAnswerChange = (currentIndex, event, newValue, previousValues) => {
    const [isSingleTypeSelected, dispatch, answers] = oFetch(this.props, 'isSingleTypeSelected', 'dispatch', 'answers');
    if (isSingleTypeSelected) {
      const currentCorrect = oFetch(newValue, 'correct');
      const previousCorrect = oFetch(previousValues, 'correct');
      if (currentCorrect !== previousCorrect && currentCorrect === true) {
        event.preventDefault();
        const newAnswers = answers.map((answer, index) => {
          if (currentIndex !== index) {
            return { ...answer, correct: false };
          }
          return newValue;
        });
        dispatch(change(FORM_NAME, 'answers', newAnswers));
      }
    }
  };

  renderBaseErrors = errors => {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  render() {
    const [
      questionTypesOptions,
      isQuestionTypeSelected,
      isTextTypeSelected,
      handleSubmit,
      submitting,
      questionTagsOptions,
    ] = oFetch(
      this.props,
      'questionTypesOptions',
      'isQuestionTypeSelected',
      'isTextTypeSelected',
      'handleSubmit',
      'submitting',
      'questionTagsOptions',
    );
    const { error } = this.props;
    return (
      <div className="boss-modal-window__form">
        {error && this.renderBaseErrors(error)}
        <div className="boss-form">
          <Field name="questionImage" label="Image" component={IncludeImage} />
          <Field name="questionText" label="Question" component={BossFormTextarea} />
          <Field
            name="questionType"
            label="Question Type"
            options={questionTypesOptions}
            component={BossFormSelectNew}
          />
          <Field
            name="tags"
            label="Question Tags"
            valueKey="id"
            labelKey="name"
            multi
            clearable
            options={questionTagsOptions}
            component={BossFormSelectNew}
          />
          {isQuestionTypeSelected && (
            <FieldArray
              name="answers"
              onAnswerChange={this.handleAnswerChange}
              isTextTypeSelected={isTextTypeSelected}
              component={Answers}
            />
          )}
          <div className="boss-form__field">
            <button
              onClick={handleSubmit}
              disabled={submitting}
              className="boss-button boss-button_role_add boss-form__submit"
              type="submit"
            >
              Add Question
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, ownProps) => {
  const questionTypes = oFetch(ownProps, 'questionTypes');
  const questionType = formSelector(state, 'questionType');
  const answers = formSelector(state, 'answers');
  const isQuestionTypeSelected = !!questionType;
  const isTextTypeSelected = [questionTypes.singleTextType, questionTypes.multiTextType].includes(questionType);
  const isSingleTypeSelected = [questionTypes.singleTextType, questionTypes.singleImageType].includes(questionType);
  return {
    isQuestionTypeSelected,
    isTextTypeSelected,
    isSingleTypeSelected,
    answers,
  };
};

const ConnectedQuestionForm = connect(mapStateToProps)(QuestionForm)

export default reduxForm({
  form: FORM_NAME,
  onChange(values, dispatch, props, previousValues) {
    const questionType = oFetch(values, 'questionType');
    const previousQuestionType = oFetch(previousValues, 'questionType');
    if (previousQuestionType !== null && previousQuestionType !== questionType) {
      dispatch(change(FORM_NAME, 'answers', []));
    }
  },
})(ConnectedQuestionForm);
