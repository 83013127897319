import React from 'react';
import HolidaysForm from './add-holiday-content-form';
import oFetch from 'o-fetch';

class AddHolidayContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      forward: false,
      focusedInput: null,
      startDate: props.startDate,
      endDate: props.endDate,
      holidayType: null,
      note: null,
    };
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const { onSubmit, buttonTitle } = this.props;
    const canForwardHolidays = oFetch(this.props, 'canForwardHolidays');

    const initialValues = {
      forward: false,
      note: null,
      startDate: null,
      endDate: null,
      holidayType: null,
    };

    return (
      <div className="boss-modal-window__form">
        <HolidaysForm
          canForwardHolidays={canForwardHolidays}
          onDatesChange={this.onDatesChange}
          startDate={startDate}
          endDate={endDate}
          initialValues={initialValues}
          submission={onSubmit}
          onChangeType={this.onChangeType}
          focusedInput={focusedInput}
          buttonTitle={buttonTitle}
        />
      </div>
    );
  }
}

export default AddHolidayContent;
