import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function UIInputField(props) {
  const [label, placeholder, onChange, value, name, errors] = oFetch(
    props,
    'label',
    'placeholder',
    'onChange',
    'value',
    'name',
    'errors',
  );
  const hasErrors = errors.length > 0;

  const fieldClassNames = cn('purple-form-field', { 'purple-form-field_state_error': hasErrors });

  function renderErrors(errors) {
    return (
      <div className="purple-form-error purple-form-error_position_below">
        {errors.map((error, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <div className={fieldClassNames}>
      <p className="purple-form-field__label">
        <span className="purple-form-field__label-text">{label}</span>
      </p>
      <div className="purple-form-field__input">
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
      {hasErrors && renderErrors(errors)}
    </div>
  );
}

UIInputField.defaultProps = {
  label: null,
  placeholder: null,
  errors: [],
};
