import React from 'react';
import oFetch from 'o-fetch';

export function ContentWrapper(props) {
  const children = oFetch(props, 'children');

  return (
    <div className="boss-page-main__content">
      <div className="boss-page-main__inner">{children}</div>
    </div>
  );
}
