import { combineReducers } from 'redux';
import xpReducer from './xp-reducer';
import quizzesReducer from './quizzes-reducer';
import staffMemberQuizCategoriesReducer from './staff-member-quiz-categories-reducer';
import quizCategoriesReducer from './quiz-categories';
import ranksReducer from './ranks';
import filterReducer from './filter-reducer';

export default combineReducers({
  xp: xpReducer,
  quizzes: quizzesReducer,
  staffMemberQuizCategories: staffMemberQuizCategoriesReducer,
  quizCategories: quizCategoriesReducer,
  ranks: ranksReducer,
  filter: filterReducer,
});
