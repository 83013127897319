import React from 'react';
import { NonStaffMemberStaffPartyPersonForm } from './non-staff-member-staff-party-person-form';
import { FormValues, FormSubmitHandler } from '../types';

type CreateModalContentProps = {
  guestStaffPartyQuizTeamId: number,
  onSubmit: FormSubmitHandler,
};

export function CreateModalContent(props: CreateModalContentProps) {

  const initialValues: FormValues = {
    avatarUrl: "",
    firstName: "",
    surname: "",
    phoneNumber: "",
    email: "",
    jobTitle: "",
    otherJobTitle: "",
    staffPartyQuizTeamId: props.guestStaffPartyQuizTeamId,
  };

  return (
    <NonStaffMemberStaffPartyPersonForm
      initialValues={initialValues}
      onSubmit={props.onSubmit}
    />
  );
}