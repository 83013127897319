import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import { SubmissionError } from 'redux-form/immutable';
import utils, { normalizeReduxFormErrors } from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

import { validateAvatarForUpdateUrlSelector, currentStaffMemberSelector } from './selectors';

import {
  INITIAL_LOAD,
  EDIT_PROFILE,
  CANCEL_EDIT_PROFILE,
  SHOW_DISABLE_MODAL,
  HIDE_DISABLE_MODAL,
  SHOW_EDIT_AVATAR_MODAL,
  HIDE_EDIT_AVATAR_MODAL,
  UPDATE_STAFF_MEMBER,
  UPDATE_DOWNLOAD_LINK_LAST_SENT_AT,
  SECURITY_TYPE_ID,
  UPDATE_STAFF_MEMBER_BANK_DETAILS,
  UPDATE_BASE_SECURITY_PAYRATES,
  DISABLE_STAFF_MEMBER,
} from './constants';

export const validateAvatarForUpdateAction = values => (dispatch, getState) => {
  const avatarBase64 = oFetch(values, 'avatarBase64');
  const setupForFacialRecognition = oFetch(values, 'setupForFacialRecognition');
  const allowUnscannableAvatar = !setupForFacialRecognition;

  const currentState = getState();
  const jsStaffMember = currentStaffMemberSelector(currentState).toJS();
  const validateAvatarForUpdateUrl = validateAvatarForUpdateUrlSelector(currentState);
  const [staffMemberId, venueId] = oFetch(jsStaffMember, 'id', 'master_venue');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['uploadAvatar'],
        });
        const baseErrors = utils.safeGet(() => errors.uploadAvatar.base) || [];
        const existErrors = utils.safeGet(() => errors.uploadAvatar.exist) || [];

        globalNotifications.showDefaultFailureMessage({
          message: 'Validate Avatar Failed',
        });
        throw new SubmissionError({ _error: [...baseErrors, { exist: existErrors }] });
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      return dispatch(validateAvatarForUpdateRequest(data));
    },
  }).post(validateAvatarForUpdateUrl, {
    avatarBase64,
    staffMemberId,
    venueId,
    allowUnscannableAvatar,
  });
};

export const addLinkedUserAction = params => (dispatch, getState) => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [email, role, jsmBarsApplicationPermissionData, bypassJsmMailbox] = oFetch(
    values,
    'email',
    'role',
    'jsmBarsApplicationPermissionData',
    'bypassJsmMailbox',
  );

  const currentState = getState();
  const jsStaffMember = currentStaffMemberSelector(currentState).toJS();
  const staffMemberId = oFetch(jsStaffMember, 'id');
  const createLinkedInvitePath = oFetch(apiRoutes, 'createLinkedInvite').getPath(staffMemberId);

  const requestParams = { email, role, jsmBarsApplicationPermissionData, bypassJsmMailbox };

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'email', 'role', 'jsmBarsApplicationPermissionData'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Add linked user failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const staffMember = oFetch(data, 'staffMember');
      onSuccess(data);
      return dispatch(updateStaffMember(staffMember));
    },
  }).post(createLinkedInvitePath, requestParams);
};

const validateAvatarForUpdateRequest = params => dispatch => {
  const [validatedAvatarGUID, staffMemberId] = oFetch(params, 'guid', 'staffMemberId');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'avatar'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Avatar Failed',
        });

        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');

      dispatch(hideEditAvatarModal());
      dispatch(updateStaffMember(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Avatar Updated Successfully' });
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/update_avatar`, {
    validatedAvatarGUID,
  });
};

export const markRetakeAvatar = staffMemberId => (dispatch, getState) => {
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Mark retake avatar Successfully',
      });
      return dispatch(updateStaffMember(data));
    },
  }).post(apiRoutes.markRetakeAvatar.getPath(staffMemberId));
};

export const changeMasterVenueAction = params => (dispatch, getState) => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [staffMemberId, masterVenue, otherVenues, accessoryRequests] = oFetch(
    values,
    'staffMemberId',
    'master_venue',
    'other_venues',
    'accessoryRequests',
  );
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'master_venue', 'other_venues', 'accessoryRequests'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Change master venue Failed',
        });

        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const staffMember = oFetch(data, 'staffMember');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member change master venue Successfully',
      });
      onSuccess();
      return dispatch(updateStaffMember(staffMember));
    },
  }).post(apiRoutes.changeMasterVenue.getPath(staffMemberId), {
    master_venue: masterVenue,
    other_venues: otherVenues,
    accessoryRequests: accessoryRequests,
  });
};

export const disableStaffMemberRequest = params => (dispatch, getState) => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [neverRehire, disableReason, accessoryRequests] = oFetch(
    values,
    'neverRehire',
    'disableReason',
    'accessoryRequests',
  );
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'disableReason', 'accessoryRequests'],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Disable Staff Member Failed',
        });
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const [staffMember, permissionsData, reviveFormConfig] = oFetch(
        data,
        'staffMember',
        'permissionsData',
        'reviveFormConfig',
      );
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Disabled Successfully',
      });

      dispatch(hideDisableStaffMemberModal());
      dispatch(updateStaffMember(staffMember));
      dispatch(disableStaffMember({ permissionsData, reviveFormConfig }));
      onSuccess();
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/disable`, {
    neverRehire,
    disableReason,
    accessoryRequests,
  });
};

export const enableStaffMember = params => (dispatch, getState) => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [
    staffMemberId,
    masterVenueId,
    nationalInsuranceNumber,
    payRateId,
    baseSecurityPayRateId,
    securitySteward,
    startsAt,
    canBypassSiaValidation,
    siaBadgeNumber,
    siaBadgeExpiryDate,
    siaValidationGUID,
    staffTypeId,
  ] = oFetch(
    values,
    'staffMemberId',
    'masterVenueId',
    'nationalInsuranceNumber',
    'payRateId',
    'baseSecurityPayRateId',
    'securitySteward',
    'startsAt',
    'canBypassSiaValidation',
    'siaBadgeNumber',
    'siaBadgeExpiryDate',
    'siaValidationGUID',
    'staffTypeId',
  );

  const postParams = {
    startsAt,
    masterVenueId,
    nationalInsuranceNumber,
    payRateId,
    baseSecurityPayRateId,
    securitySteward,
    canBypassSiaValidation,
    siaBadgeNumber,
    siaBadgeExpiryDate,
    siaValidationGUID,
    staffTypeId,
  };
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'staffTypeId',
            'startsAt',
            'masterVenueId',
            'nationalInsuranceNumber',
            'payRateId',
            'baseSecurityPayRateId',
            'siaBadgeNumber',
          ],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Enable Staff Member Failed',
        });
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Enabled Successfully',
      });
      onSuccess();
      dispatch(updateStaffMember(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/enable`, postParams);
};

export const updateEmploymentDetailsRequest = payload => (dispatch, getState) => {
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);
  const {
    national_insurance_number,
    sage_id,
    hours_preference,
    day_preference,
    starts_at,
    employment_status,
    pay_rate,
    other_venues,
    staff_type,
    sia_badge_expiry_date,
    sia_badge_number,
    allow_no_sage_id,
    worker_type,
    steward,
    generatePublicHolidays
  } = payload;
  let fields = {
    national_insurance_number,
    sage_id,
    hours_preference_note: hours_preference,
    day_preference_note: day_preference,
    starts_at: starts_at.format('DD-MM-YYYY'),
    employment_status,
    pay_rate_id: pay_rate,
    other_venue_ids: other_venues,
    staff_type_id: staff_type,
    allow_no_sage_id,
    steward,
    generatePublicHolidays
  };

  if (worker_type) {
    fields.worker_type = worker_type;
  }

  if (staff_type === SECURITY_TYPE_ID) {
    fields = {
      ...fields,
      master_venue_id: null,
      forStewardSecurityStaffSecurityPayRate: payload.baseSecurityPayRate,
      steward: payload.steward,
    };

    if (!steward) {
      fields = {
        ...fields,
        sia_badge_number: sia_badge_number,
        sia_badge_expiry_date: sia_badge_expiry_date,
        forStandardSecurityStaffSecurityPayRate: payload.baseSecurityPayRate,
        siaValidationGUID: payload.siaValidationGUID,
        steward: payload.steward,
      };
    }
  } else {
    fields = {
      ...fields,
      sia_badge_number: null,
      sia_badge_expiry_date: null,
    };
  }

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'employment_status',
            'other_venues',
            'staff_type',
            'pay_rate',
            'national_insurance_number',
            'sage_id',
            'hours_preference_note',
            'day_preference_note',
            'starts_at',
            'sia_badge_number',
            'sia_badge_expiry_date',
            'worker_type',
            'baseSecurityPayRate',
            'generatePublicHolidays'
          ],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Employment Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Employment Details Updated Successfully',
      });
      const [
        staffMember,
        forStandardSecurityStaffSecurityPayRates,
        forStewardSecurityStaffSecurityPayRates,
      ] = oFetch(
        data,
        'staffMember',
        'forStandardSecurityStaffSecurityPayRates',
        'forStewardSecurityStaffSecurityPayRates',
      );
      dispatch(updateStaffMember(staffMember));
      dispatch(
        updateSecurityPayRates({
          forStandardSecurityStaffSecurityPayRates,
          forStewardSecurityStaffSecurityPayRates,
        }),
      );
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/update_employment_details`, fields);
};

export const updatePersonalDetailsRequest = payload => (dispatch, getState) => {
  const profile = getState().get('profile').toJS();
  const staffMember = oFetch(profile, 'staffMember');
  const staffMemberId = oFetch(staffMember, 'id');
  const [gender, dateOfBirth, first_name, surname] = oFetch(
    payload,
    'gender',
    'date_of_birth',
    'first_name',
    'surname',
  );

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'gender', 'date_of_birth', 'first_name', 'surname'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Personal Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Personal Details Updated Successfully',
      });

      dispatch(updateStaffMember(data));
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/update_personal_details`, {
    gender: gender ? gender.toLowerCase() : null,
    date_of_birth: dateOfBirth ? dateOfBirth.format('DD-MM-YYYY') : null,
    first_name,
    surname,
  });
};

export const updateContactDetailsRequest = payload => (dispatch, getState) => {
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'phone_number',
            'address',
            'postcode',
            'country',
            'county',
            'email_address',
          ],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Contact Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Staff Member Contact Details Updated Successfully',
      });

      dispatch(updateStaffMember(data));
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/update_contact_details`, {
    ...payload,
  });
};

export const initialProfileLoad = createAction(INITIAL_LOAD);

export const editProfile = createAction(EDIT_PROFILE);
export const cancelEditProfile = createAction(CANCEL_EDIT_PROFILE);

export const showDisableStaffMemberModal = createAction(SHOW_DISABLE_MODAL);
export const hideDisableStaffMemberModal = createAction(HIDE_DISABLE_MODAL);

export const showEditAvatarModal = createAction(SHOW_EDIT_AVATAR_MODAL);
export const hideEditAvatarModal = createAction(HIDE_EDIT_AVATAR_MODAL);
export const updateStaffMember = createAction(UPDATE_STAFF_MEMBER);
export const disableStaffMember = createAction(DISABLE_STAFF_MEMBER);
export const updateSecurityPayRates = createAction(UPDATE_BASE_SECURITY_PAYRATES);
export const updateDownloadLinkLastSentAt = createAction(UPDATE_DOWNLOAD_LINK_LAST_SENT_AT);
export const updateStaffMemberBankDetailsAction = createAction(UPDATE_STAFF_MEMBER_BANK_DETAILS);
