import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import oFetch from 'o-fetch';

export default class Answer extends React.Component {
  static displayName = 'Answer';

  setOptionForQuestion = event => {
    this.props.activateAnsweredState();

    const questionnaireResponse = this.props.questionnaireResponse;
    const answers = questionnaireResponse.answers;
    const answerParams = {
      questionnaireQuestionId: this.props.currentQuestion.id,
      value: event.target.value,
    };

    this.props.setAnswer(answerParams);
  };

  renderBinaryOrScaledQuestionAnswer() {
    const question = this.props.currentQuestion;
    const possibleValues = this.props.currentQuestion.possibleValues;
    const answer = this.props.currentAnswer ? this.props.currentAnswer.value : '';
    const answerOptions = possibleValues.map((possibleValue, index) => {
      return (
        <label className="boss-question__radio-label" key={index}>
          <input
            type="radio"
            name={this.props.currentQuestion.id}
            value={possibleValue}
            className="boss-question__radio-button"
            onChange={this.setOptionForQuestion}
            defaultChecked={answer == possibleValue}
          />
          <span className="boss-question__radio-label-text">{possibleValue}</span>
        </label>
      );
    });

    return (
      <div className="boss-question__controls">
        {answerOptions}

        {this.renderIcon()}
      </div>
    );
  }

  renderIcon() {
    const answer = this.props.answers.find(
      answer => answer.questionnaireQuestionId === this.props.currentQuestion.id,
    );
    const notes = [];
    if (answer && !!answer.note) {
      notes.push(
        <button
          type="button"
          key="note"
          className="boss-question__icon boss-question__icon_role_att"
        >
          Note
        </button>,
      );
    }
    if (answer && answer.image_ids && answer.image_ids.length !== 0) {
      notes.push(
        <button
          type="button"
          key="photo"
          className="boss-question__icon boss-question__icon_role_photo"
        >
          Photo
        </button>,
      );
    }
    return notes.length ? notes : null;
  }

  renderScaleQuestionAnswer() {
    const question = oFetch(this.props, 'currentQuestion');
    const questionId = oFetch(question, 'id');
    const startValue = oFetch(question, 'startValue');
    const endValue = oFetch(question, 'endValue');
    const scaleRange = _.range(startValue, endValue + 1);
    const answer = this.props.currentAnswer ? this.props.currentAnswer.value : '';
    const answerOptions = scaleRange.map((scaleValue, index) => {
      return (
        <label className="boss-question__radio-label" key={index}>
          <input
            type="radio"
            name={questionId}
            value={scaleValue}
            className="boss-question__radio-button"
            onChange={this.setOptionForQuestion}
            defaultChecked={answer == scaleValue}
          />
          <span className="boss-question__radio-label-text">{scaleValue}</span>
        </label>
      );
    });

    return (
      <div className="boss-question__controls">
        {answerOptions}

        {this.renderIcon()}
      </div>
    );
  }

  renderAnswer() {
    const answerScaledType = oFetch(this.props, 'answerScaledType');
    const answerBinaryType = oFetch(this.props, 'answerBinaryType');
    const answerRequiredType = oFetch(this.props, 'answerRequiredType');
    const currentQuestionType = oFetch(this.props, 'currentQuestion.type');
    if (currentQuestionType === answerScaledType) {
      return this.renderScaleQuestionAnswer();
    } else if (currentQuestionType === answerBinaryType) {
      return this.renderBinaryOrScaledQuestionAnswer();
    } else if (currentQuestionType === answerRequiredType) {
      return this.renderBinaryOrScaledQuestionAnswer();
    } else {
      throw Error(
        `Wrong question type expect one of ${answerScaledType}, ${answerBinaryType}, got: ${currentQuestionType}`,
      );
    }
  }

  render() {
    return this.renderAnswer();
  }
}
