import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class DeleteRecurringReminder extends Component {
  state = {
    processing: false,
  };

  pendingFunction = (...args) => callbackPromise => {
    this.setState({ processing: true });
    return callbackPromise(...args).finally(() => {
      this.setState({ processing: false });
    });
  };

  render() {
    const [onClose, onDeleteFuture, onDeleteSingle /*, onDeleteAll */] = oFetch(
      this.props,
      'onClose',
      'onDeleteFuture',
      'onDeleteSingle',
      'onDeleteAll',
    );
    const processing = oFetch(this.state, 'processing');
    const [reminderDate, selectedDate] = oFetch(this.props, 'reminderDate', 'selectedDate');
    const reminderId = oFetch(reminderDate, 'handoverPlannerReminderId');
    const callFunction = this.pendingFunction(onClose, { reminderId, date: selectedDate });
    return (
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__message-block">
          <span className="boss-modal-window__message-text">The item you are deleting is recurring</span>
        </div>
        <div className="boss-modal-window__actions boss-modal-window__actions_adjust_column">
          <button
            disabled={processing}
            onClick={() => callFunction(onDeleteSingle)}
            type="button"
            className="boss-button boss-button_adjust_full boss-modal-window__button boss-modal-window__button_adjust_column"
          >
            DELETE JUST THIS INSTANCE
          </button>
          <button
            disabled={processing}
            onClick={() => callFunction(onDeleteFuture)}
            type="button"
            className="boss-button boss-button_adjust_full boss-modal-window__button boss-modal-window__button_adjust_column"
          >
            REMOVE THIS AND ALL FUTURE INSTANCES
          </button>
        </div>
      </div>
    );
  }
}
