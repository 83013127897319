import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';

export function receivedBonusPayment(params) {
  const [bonusPaymentId, onSuccess, path] = oFetch(params, 'bonusPaymentId', 'onSuccess', 'path');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(bonusPaymentId);
    },
  }).post(path, {
    bonusPaymentId,
  });
}

export function receivedDifferentAmountBonusPayment(params) {
  const [bonusPaymentId, amountCents, onSuccess, path] = oFetch(
    params,
    'bonusPaymentId',
    'amountCents',
    'onSuccess',
    'path',
  );

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'amountCents'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage();
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(bonusPaymentId);
    },
  }).post(path, { amountCents, bonusPaymentId });
}

export function didNotReceivedBonusPayment(params) {
  const [bonusPaymentId, onSuccess, path] = oFetch(params, 'bonusPaymentId', 'onSuccess', 'path');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(bonusPaymentId);
    },
  }).post(path, {
    bonusPaymentId,
  });
}
