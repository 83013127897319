import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { apiRoutes } from '@/lib/legacy-routes';

export const brCreateQuestion = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createQuizQuestionRoute = apiRoutes.createQuizQuestion;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createQuizQuestionRoute.getPath(), values);
};

export const brUpdateQuestion = params => {
  const [values, questionId] = oFetch(params, 'values.formData', 'values.questionId');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const updateQuizQuestionRoute = apiRoutes.updateQuizQuestion;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(updateQuizQuestionRoute.getPath(questionId), values);
};

export const brDisableQuestion = params => {
  const questionId = oFetch(params, 'values.questionId');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const disableQuizQuestionRoute = apiRoutes.disableQuizQuestion;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(disableQuizQuestionRoute.getPath(questionId));
};

export const brMoveQuestion = params => {
  const values = oFetch(params, 'values');
  const quizCategoryId = oFetch(values, 'quizCategoryId');
  const quizCategoryQuestionId = oFetch(values, 'quizCategoryQuestionId');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const moveQuizQuestionRoute = oFetch(apiRoutes, 'moveQuizQuestion');

  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(moveQuizQuestionRoute.getPath({ quizCategoryQuestionId }), { quizCategoryId });
}

export const brEnableQuestion = params => {
  const questionId = oFetch(params, 'values.questionId');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const enableQuizQuestionRoute = apiRoutes.enableQuizQuestion;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(enableQuizQuestionRoute.getPath(questionId));
};
