import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';

const MossStaffMemberCard = props => {
  const [mossStaffMember, jobType, onEditAvatar, permissionsData, onMarkRetakeAvatar] = oFetch(
    props,
    'mossStaffMember',
    'jobType',
    'onEditAvatar',
    'permissionsData',
    'onMarkRetakeAvatar',
  );
  const avatar = oFetch(mossStaffMember, 'avatar');
  const [hasLinkedUser, linkedUserId] = oFetch(mossStaffMember, 'hasLinkedUser', 'linkedUserId');
  const [hasLinkedStaffMember, linkedStaffMemberId] = oFetch(
    mossStaffMember,
    'hasLinkedStaffMember',
    'linkedStaffMemberId',
  );

  const fullName = `${oFetch(mossStaffMember, 'first_name')} ${oFetch(mossStaffMember, 'surname')}`;
  const disabled = oFetch(mossStaffMember, 'disabled');
  const supportsFacialRecognition = oFetch(mossStaffMember, 'supportsFacialRecognition');
  const disabledByUser = oFetch(mossStaffMember, 'disabled_by_user');
  const disabledAt = oFetch(mossStaffMember, 'disabled_at');
  const disabledReason = oFetch(mossStaffMember, 'disable_reason');
  const markedRetakeAvatar = oFetch(mossStaffMember, 'markedRetakeAvatar');
  const jobTypeName = oFetch(jobType, 'name');
  const jobTypeColor = oFetch(jobType, 'color');
  const canMarkRetakeAvatar = oFetch(permissionsData, 'canMarkRetakeAvatar');

  const renderFullName = (fullName, disabled = false) => {
    return disabled ? `${fullName} (Disabled)` : fullName;
  };

  const renderdisabledContent = ({ disabledByUser, disabledAt, disabledReason }) => {
    const disabledAtFormatted = safeMoment.iso8601Parse(disabledAt).format('Do MMMM YYYY');
    return (
      <ul className="boss-user-summary__review-list">
        <li className="boss-user-summary__review-item">
          <span className="boss-user-summary__review-label">Disabled by: </span>
          <span className="boss-user-summary__review-val">
            <span>
              {disabledByUser} on {disabledAtFormatted}
            </span>
          </span>
        </li>
        <li className="boss-user-summary__review-item">
          <span className="boss-user-summary__review-label">Reason for disabling: </span>
          <span className="boss-user-summary__review-val">{disabledReason}</span>
        </li>
        <li className="boss-user-summary__review-item">
          <span className="boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_exclamation boss-user-summary__label">
            Disabled
          </span>
        </li>
      </ul>
    );
  };
  const avatarContainerClasses = cn('boss-user-summary__avatar', {
    'boss-user-summary__avatar_type_scannable': supportsFacialRecognition,
  });

  return (
    <div className="boss-page-dashboard__user-summary">
      <div className="boss-user-summary">
        <div className="boss-user-summary__side">
          <div className={avatarContainerClasses}>
            <div className="boss-user-summary__avatar-inner">
              <img src={avatar} alt="avatar" />
              {!disabled && (
                <button
                  className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_edit"
                  onClick={() => onEditAvatar()}
                >
                  Edit
                </button>
              )}
              {canMarkRetakeAvatar && !markedRetakeAvatar && (
                <button
                  type="button"
                  className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_retake"
                  onClick={onMarkRetakeAvatar}
                >
                  Retake
                </button>
              )}
              {markedRetakeAvatar && (
                <div className="boss-user-summary__avatar-overlay">
                  <p className="boss-user-summary__avatar-overlay-text boss-user-summary__avatar-overlay-text_role_retake">
                    Please retake picture
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="boss-user-summary__content">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{renderFullName(fullName, disabled)}</h2>
            <span
              style={{ backgroundColor: jobTypeColor }}
              className="boss-button boss-button_type_small boss-button_type_no-behavior boss-user-summary__label"
            >
              {jobTypeName}
            </span>
          </div>
          <div className="boss-user-summary__header">
            {(hasLinkedUser || hasLinkedStaffMember) && (
              <div className="boss-user-summary__header">
                {hasLinkedUser && (
                  <div className="boss-user-summary__indicator">
                    <a
                      href={appRoutes.userProfile(linkedUserId)}
                      target="_blank"
                      rel="noreferrer"
                      className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                    >
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain"
                        style={{ marginRight: '5px' }}
                      />
                      Linked User
                    </a>
                  </div>
                )}
                {hasLinkedStaffMember && (
                  <div className="boss-user-summary__indicator">
                    <a
                      href={appRoutes.staffMemberProfile(linkedStaffMemberId)}
                      target="_blank"
                      rel="noreferrer"
                      className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                    >
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain"
                        style={{ marginRight: '5px' }}
                      />
                      Linked Staff member
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          {disabled && renderdisabledContent({ disabledByUser, disabledAt, disabledReason })}
        </div>
      </div>
    </div>
  );
};

export default MossStaffMemberCard;
