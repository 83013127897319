import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export function getMarketingTaskDetails(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const marketingTaskId = oFetch(values, 'marketingTaskId');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).get(`/api/v1/marketing_tasks/${marketingTaskId}`);
}

export function createMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['title', 'description', 'deadlineDate', 'venueId'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks`, values);
}

export function updateMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const taskId = oFetch(values, 'taskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['title', 'description', 'deadlineDate', 'venueId'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).put(`/api/v1/marketing_tasks/${taskId}`, values);
}

export function removeMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const marketingTaskId = oFetch(values, 'marketingTaskId');

  return bossRequestHttp({
    errorHandler() {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).delete(`/api/v1/marketing_tasks/${marketingTaskId}`);
}

export function createMarketingTaskRequest(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const taskId = oFetch(values, 'taskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['text'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/requests`, values);
}

export function updateMarketingTaskRequest(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [taskId, requestId] = oFetch(values, 'taskId', 'requestId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['text'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).put(`/api/v1/marketing_tasks/${taskId}/requests/${requestId}`, values);
}

export function createMarketingTaskRequestAnswer(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [taskId, requestId] = oFetch(values, 'taskId', 'requestId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['text'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/requests/${requestId}/create_answer`, values);
}

export function acceptMarketingTaskRequest(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [taskId, requestId] = oFetch(values, 'taskId', 'requestId');

  return bossRequestHttp({
    errorHandler() {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/requests/${requestId}/accept`);
}

export function removeMarketingTaskRequest(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [taskId, requestId] = oFetch(values, 'taskId', 'requestId');

  return bossRequestHttp({
    errorHandler() {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).delete(`/api/v1/marketing_tasks/${taskId}/requests/${requestId}`);
}

export function createMarketingTaskRequestClarification(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [taskId, requestId] = oFetch(values, 'taskId', 'requestId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['text'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/requests/${requestId}/clarifications`, values);
}

export function completeMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const taskId = oFetch(values, 'taskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'note'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/complete`, values);
}

export function acceptMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const marketingTaskId = oFetch(values, 'marketingTaskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${marketingTaskId}/accept`);
}

export function rejectMarketingTask(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const taskId = oFetch(values, 'taskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'text'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${taskId}/reject`, values);
}

export function grabMarketingTask(params) {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');
  const marketingTaskId = oFetch(values, 'marketingTaskId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });
        onFailure(errors);
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${marketingTaskId}/grab`);
}

export function reassingMarketingTask(params) {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');
  const [marketingTaskId, userId] = oFetch(values, 'marketingTaskId', 'userId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });
        onFailure(errors);
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/marketing_tasks/${marketingTaskId}/reassign`, { userId });
}

export function getMarketingTaskHistoryEvents(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const marketingTaskId = oFetch(values, 'marketingTaskId');

  return bossRequestHttp({
    errorHandler() {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).get(`/api/v1/marketing_tasks/${marketingTaskId}/history`);
}
export function getRequestHistoryEvents(params) {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const [requestId, marketingTaskId] = oFetch(values, 'requestId', 'marketingTaskId');

  return bossRequestHttp({
    errorHandler() {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).get(`/api/v1/marketing_tasks/${marketingTaskId}/requests/${requestId}/history`);
}

export function uploadImageFile(params) {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['file'],
        });
        onFailure(errors);
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/new_uploads`, values);
}

export function deleteUploadedImageFile(params) {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');
  const uploadedFileId = oFetch(values, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['file'],
        });
        onFailure(errors);
        return errors;
      }
      onFailure();
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).delete(`/api/v1/new_uploads/${uploadedFileId}`);
}
