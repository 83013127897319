import React from 'react';
import oFetch from 'o-fetch';

export default function CountingOverviewTotalsRow(props) {
  const [title, five, ten, twenty, fifty, change, total, hideLastCol] = oFetch(
    props,
    'title',
    'five',
    'ten',
    'twenty',
    'fifty',
    'change',
    'total',
    'hideLastCol',
  );

  return (
    <div className="purple-table__row purple-table__row_role_footer">
      <div className="purple-table__cell purple-table__cell_role_footer">{title}</div>
      {five !== null && <div className="purple-table__cell purple-table__cell_role_footer">{five}</div>}
      {ten !== null && <div className="purple-table__cell purple-table__cell_role_footer">{ten}</div>}
      {twenty !== null && <div className="purple-table__cell purple-table__cell_role_footer">{twenty}</div>}
      {fifty !== null && <div className="purple-table__cell purple-table__cell_role_footer">{fifty}</div>}
      {change !== null && <div className="purple-table__cell purple-table__cell_role_footer">{change}</div>}
      {total !== null && <div className="purple-table__cell purple-table__cell_role_footer">{total}</div>}
      {!hideLastCol && <div className="purple-table__cell purple-table__cell_role_footer" />}
    </div>
  );
}

CountingOverviewTotalsRow.defaultProps = {
  title: null,
  five: null,
  ten: null,
  twenty: null,
  fifty: null,
  change: null,
  total: null,
  hideLastCol: false,
};
