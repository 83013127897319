export enum RotaAppType {
  boss = 'boss',
  moss = 'moss'
}
export const BOSS_APP_TYPE = RotaAppType.boss;
export const MOSS_APP_TYPE = RotaAppType.moss;
export const BOSS_DAY_START_HOUR = 8;
export const MOSS_DAY_START_HOUR = 5;
export const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;
export enum RotaTypeTime {
  start = 'start',
  end = 'end',
}
export const START_TIME_TYPE = RotaTypeTime.start;
export const END_TIME_TYPE = RotaTypeTime.end;
export const TIME_TYPES = [START_TIME_TYPE, END_TIME_TYPE];