import React from 'react';
import oFetch from 'o-fetch';

export function CategoryItem(props) {
  const category = oFetch(props, 'category');
  const [name, showUrl, hasContent] = oFetch(category, 'name', 'showUrl', 'hasContent');

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_training-materials">
      <div className="boss-check__header">
        <h3 className="boss-check__title">{name}</h3>
        <div className="boss-check__header-actions">
          {hasContent && (
            <a
              href={showUrl}
              className="boss-button boss-button_role_edit-alt boss-button_type_small boss-check__header-action"
            >
              Edit
            </a>
          )}
          {!hasContent && (
            <a
              href={showUrl}
              className="boss-button boss-button_role_add boss-button_type_small boss-check__header-action"
            >
              Create
            </a>
          )}
        </div>
      </div>
      <div className="boss-check__content">
        {!hasContent && (<div className="boss-check__group">
          <div className="boss-check__group-content">
            <div className="boss-check__meta">
              <div className="boss-check__meta-item">
                <p className="boss-check__text">No Training Materials Exist</p>
              </div>
            </div>
          </div>
        </div>) }
      </div>
    </div>
  );
}
