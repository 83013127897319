import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { clockingStatusTitles, clockingStatuses } from '../../../redux/constants';

export default function RightSideHeader(props) {
  const [status, date, venueName, pageType] = oFetch(
    props,
    'status',
    'date',
    'venueName',
    'pageType',
  );
  const statusClassNames = cn('boss-button boss-button_type_small boss-hrc__status-label', {
    'boss-button_status_clocked-in': status === clockingStatuses.CLOCKED_IN_STATUS,
    'boss-button_status_clocked-out': status === clockingStatuses.CLOCKED_OUT_STATUS,
  });

  return (
    <div className="boss-hrc__header">
      <h3 className="boss-hrc__status">
        <span className="boss-hrc__status-text">Status</span>
        <span className="boss-hrc__status-text">{}</span>
        <span className={statusClassNames}>{clockingStatusTitles[status]}</span>
      </h3>
      {pageType === 'overview' && (
        <p className="boss-hrc__venue">
          <span className="boss-hrc__venue-text">{venueName}</span>
        </p>
      )}
      {pageType !== 'overview' && (
        <p className="boss-hrc__date">
          <span className="boss-hrc__date-text">{date}</span>
        </p>
      )}
    </div>
  );
}
