import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';
import utils from '@/lib/utils';
import BossInput from '@/components/boss-form/boss-form-input';

export function ImageUrlForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  const linkRef = React.createRef();
  const [showTooltip, setShowTooltop] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    (async () => {
      const imageUrl = oFetch(initialValues, 'imageUrl');
      if (imageUrl) {
        setProcessing(true);
        await onSubmit({ imageUrl });
        setProcessing(false);
      }
    })();
  }, []);

  function copyToClipboard(e) {
    setShowTooltop(true);
    const text = linkRef.current.value;
    const toClipBoard = `${window.location.origin}${window.location.pathname}?image_url=${text}`;
    utils.copyToClipboard(toClipBoard);
    setTimeout(() => {
      setShowTooltop(false);
    }, 1000);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <div className="boss-modal-window__form" style={{ width: '100%' }}>
            {submitError && (
              <div
                className="boss-alert boss-alert_role_area boss-alert_context_above"
                style={{ width: '100%' }}
              >
                <p className="boss-alert__text">{submitError}</p>
              </div>
            )}
            <Tooltip title="Coppied !!!" position="top" open={showTooltip} />
            <Field
              name="imageUrl"
              label="Face Image URL"
              parse={value => value}
              ref={linkRef}
              component={BossInput}
            />
            <button
              onClick={copyToClipboard}
              className="boss-link-control__action boss-link-control__action_role_copy"
              type="button"
            >
              Copy URL to clipboard
            </button>

            <div className="boss-form__field">
              <button
                className="boss-button boss-button_role_confirm boss-form__submit"
                type="button"
                onClick={handleSubmit}
                disabled={submitting || processing}
              >
                {submitting || processing ? `Processing ...` : `Submit`}
              </button>
            </div>
          </div>
        );
      }}
    />
  );
}
