import React from 'react';
import oFetch from 'o-fetch';
import { useDispatch } from 'react-redux';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { ActivityModalContent } from './components/task-details/activity-modal-content';
import { CompleteTaskModalContent } from './components/task-details/complete-task-modal-content';
import { RejectTaskModalContent } from './components/task-details/reject-task-modal-content';
import { AddRequestModalContent } from './components/task-details/task-details-requests/add-request-modal-content';
import { UpdateRequestModalContent } from './components/task-details/task-details-requests/update-request-modal-content';
import { RequestClarificationModalContent } from './components/task-details/task-details-requests/request-clarification-modal-content';
import { EditTaskModalContent } from './components/task-details/edit-task-modal-content';
import { AddTaskModalContent } from './components';
import { TaskDetails } from './components/task-details';
import { AddAnswerModalContent } from './components/task-details/task-details-requests/add-answer-modal-content';
import { RequestHistoryContent } from './components/task-details/task-details-requests/request-history-content';
import { HtmlChangesModalContent } from './components/task-details/activities/html-changes-modal-content';
import { AttachmentsChangesModalContent } from './components/task-details/activities/attachments-changes-modal-content';
import * as actions from './redux/actions';

export function useMarketingTaskModals() {
  const { openModal } = useModal();
  const dispatch = useDispatch();

  function openAddNewTaskModal() {
    return openModal({
      onSubmit: (handleCloseModal, values) => {
        return dispatch(
          actions.createMarketingTask({
            values,
            onSuccess() {
              handleCloseModal();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Task',
      },
      props: {},
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddTaskModalContent,
    });
  }

  function openActivityModal(marketingTask) {
    const title = oFetch(marketingTask, 'title');

    return openModal({
      onSubmit: () => {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_task-activity',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{title}</span> Activity
            </div>
          );
        },
      },
      props: {
        marketingTask,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ActivityModalContent,
    });
  }

  function openCompleteTaskModal(marketingTask, closeTaskModal) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.completeMarketingTask({
            values,
            onSuccess() {
              handleModalClose();
              closeTaskModal();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_complete',
        title: 'Complete Task',
      },
      props: {
        marketingTask: marketingTask,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: CompleteTaskModalContent,
    });
  }

  function openRejectTaskModal(marketingTask) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.rejectMarketingTask({
            values: values,
            onSuccess() {
              handleModalClose();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_reject',
        title: 'Reject Task',
      },
      props: {
        marketingTask: marketingTask,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: RejectTaskModalContent,
    });
  }

  function openAddRequestModal(marketingTask) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.createMarketingTaskRequest({
            values: values,
            onSuccess(data) {
              handleModalClose();
            },
            onFailure() {},
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
        title: 'Add Request',
      },
      props: {
        marketingTask,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddRequestModalContent,
    });
  }

  function openUpdateRequestModal(marketingTaskRequest) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.updateMarketingTaskRequest({
            values: values,
            onSuccess(data) {
              handleModalClose();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
        title: 'Add Request',
      },
      props: {
        marketingTaskRequest,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: UpdateRequestModalContent,
    });
  }

  function openEditTaskModal(marketingTask) {
    return openModal({
      onSubmit: (handleCloseModal, values) => {
        return dispatch(
          actions.updateMarketingTask({
            values,
            onSuccess(data) {
              handleCloseModal();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
        title: 'Edit Task',
      },
      props: {
        marketingTask,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditTaskModalContent,
    });
  }

  function openTaskDetailsModal(marketingTask, getTaskDetailsAction) {
    const marketingTaskId = oFetch(marketingTask, 'id');
    window.location.hash = `id=${marketingTaskId}`;
    return openModal({
      onSubmit() {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_task-details',
        title: () => null,
      },
      onClose() {
        let scrollV;
        let scrollH;
        const loc = window.location;
        if ('pushState' in window.history)
          window.history.pushState('', document.title, loc.pathname + loc.search);
        else {
          // Prevent scrolling by storing the page's current scroll offset
          scrollV = document.body.scrollTop;
          scrollH = document.body.scrollLeft;
          loc.hash = '';
          // Restore the scroll offset, should be flicker free
          document.body.scrollTop = scrollV;
          document.body.scrollLeft = scrollH;
        }
      },
      props: {
        marketingTaskId: oFetch(marketingTask, 'id'),
        getTaskDetailsAction: getTaskDetailsAction,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: TaskDetails,
    });
  }

  function openAddAnswerModal(marketingTaskRequest) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.createMarketingTaskRequestAnswer({
            values: values,
            onSuccess(data) {
              handleModalClose();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_request-answer',
        title: 'Add Answer',
      },
      props: {
        marketingTaskRequest,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddAnswerModalContent,
    });
  }

  function openRequestClarificationModal(marketingTaskRequest) {
    return openModal({
      onSubmit: (handleModalClose, values) => {
        return dispatch(
          actions.createMarketingTaskRequestClarification({
            values: values,
            onSuccess(data) {
              handleModalClose();
            },
          }),
        );
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_request-history',
        title: 'Request Clarification',
      },
      props: {
        marketingTaskRequest,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: RequestClarificationModalContent,
    });
  }

  function openRequestHistoryModal(request) {
    return openModal({
      onSubmit: () => {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_request-history',
        title: 'Request History',
      },
      props: {
        request,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: RequestHistoryContent,
    });
  }

  function openHtmlChangesModal(oldHtml, newHtml) {
    return openModal({
      onSubmit: () => {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_request-history',
        title: 'Changes',
      },
      props: {
        oldHtml,
        newHtml,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: HtmlChangesModalContent,
    });
  }

  function openAttachmentsChangesModal(oldAttachments, newAttachments) {
    return openModal({
      onSubmit: () => {},
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_request-history',
        title: 'Attachment Changes',
      },
      props: {
        oldAttachments,
        newAttachments,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttachmentsChangesModalContent,
    });
  }

  return {
    openActivityModal,
    openCompleteTaskModal,
    openEditTaskModal,
    openAddRequestModal,
    openUpdateRequestModal,
    openRejectTaskModal,
    openAddNewTaskModal,
    openTaskDetailsModal,
    openAddAnswerModal,
    openRequestHistoryModal,
    openRequestClarificationModal,
    openHtmlChangesModal,
    openAttachmentsChangesModal,
  };
}
