import { combineReducers } from 'redux-immutable';
import page from './page-options';
import staffMembers from './staff-members';
import financeReports from './finance-reports';
import paymentWeekData from './payment-week-data';
import { paymentDataByStaffMemberIdReducer } from './payment-data-by-staff-member-id';
import permissions from './permissions';

export default combineReducers({
  page,
  staffMembers,
  financeReports,
  paymentDataByStaffMemberId: paymentDataByStaffMemberIdReducer,
  paymentWeekData,
  permissions,
});
