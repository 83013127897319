import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';

export function RejectTaskEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, reason, at] = oFetch(event, 'userFullName', 'reason', 'at');

  const formattedAt = useMemo(() => atFormat(at), [at]);

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_rejected">
      <p className="boss-overview__text">
        Task marked as rejected by {userFullName} @ {formattedAt}
      </p>
      {reason && (
        <p className="boss-overview__text">
          <span className="boss-overview__text-large boss-overview__text-marked">
            Reject Reason - {reason}
          </span>
        </p>
      )}
    </li>
  );
}
