import React, { useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import { DashboardActions } from '@/components/boss-dashboards';
import { appRoutes } from '@/lib/legacy-routes';
import ContentWrapper from '@/components/content-wrapper';
import DashboardWeekSelect from '@/components/boss-dashboards/dashboard-week-select';
import SecurityShiftRequestCard from '@/components/security-shift-requests/security-shift-request-card';
import SecurityShiftRequestVenueList from './security-shift-request-venue-list';
import SecurityShiftRequestVenueCard from './security-shift-request-venue-card';
import SecurityShiftRequestItem from './security-shift-request-item';
import WeekFilter from './requests-week-filter';
import Spinner from '@/components/spinner';

function SecurityShiftRequestReviewsPage(props) {
  const counts = oFetch(props, 'counts');
  const [fetching, setFetching] = useState(false);

  function handleDateChage({ startUIDate }) {
    location.href = appRoutes.securityShiftRequestReviews({
      startDate: startUIDate,
    });
  }

  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');
  const editSecurityShiftRequest = oFetch(props, 'editSecurityShiftRequest');
  const rejectSecurityShiftRequest = oFetch(props, 'rejectSecurityShiftRequest');
  const undoSecurityShiftRequest = oFetch(props, 'undoSecurityShiftRequest');
  const acceptSecurityShiftRequest = oFetch(props, 'acceptSecurityShiftRequest');
  const pendingSecurityShiftRequests = oFetch(props, 'pendingSecurityShiftRequests');
  const completedSecurityShiftRequests = oFetch(props, 'completedSecurityShiftRequests');
  const sChosenDate = oFetch(props, 'chosenDate');
  const changeWeekDay = oFetch(props, 'changeWeekDay');

  async function handleChangeURLDate(sDate) {
    window.history.pushState('state', 'title', `/security-shift-request-reviews/${sDate}`);
    setFetching(true);
    await changeWeekDay({ sChosenDate: sDate });
    setFetching(false);
  }

  return (
    <div>
      <DashboardWeekSelect
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChage}
        title="Security Shift Request Reviews"
      />
      <ContentWrapper>
        <WeekFilter sDate={sChosenDate} disabled={fetching} onChange={handleChangeURLDate} weekDates={counts} />
        {!fetching && (
          <Fragment>
            <SecurityShiftRequestCard title="Pending">
              <SecurityShiftRequestVenueList
                securityShiftRequestsGrupedByVenue={pendingSecurityShiftRequests}
                venueCardRenderer={(securityShiftRequests, venueName) => {
                  return (
                    <SecurityShiftRequestVenueCard
                      isCompleted={false}
                      securityShiftRequests={securityShiftRequests}
                      venueName={venueName}
                      itemRenderer={securityShiftRequest => {
                        return (
                          <SecurityShiftRequestItem
                            securityShiftRequest={securityShiftRequest}
                            editSecurityShiftRequest={editSecurityShiftRequest}
                            rejectSecurityShiftRequest={rejectSecurityShiftRequest}
                            acceptSecurityShiftRequest={acceptSecurityShiftRequest}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </SecurityShiftRequestCard>
            <SecurityShiftRequestCard title="Completed">
              <SecurityShiftRequestVenueList
                securityShiftRequestsGrupedByVenue={completedSecurityShiftRequests}
                venueCardRenderer={(securityShiftRequests, venueName) => {
                  return (
                    <SecurityShiftRequestVenueCard
                      isCompleted
                      securityShiftRequests={securityShiftRequests}
                      venueName={venueName}
                      itemRenderer={securityShiftRequest => {
                        return (
                          <SecurityShiftRequestItem
                            isCompleted
                            securityShiftRequest={securityShiftRequest}
                            undoSecurityShiftRequest={undoSecurityShiftRequest}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </SecurityShiftRequestCard>
          </Fragment>
        )}
        {fetching && <Spinner />}
      </ContentWrapper>
    </div>
  );
}

export default SecurityShiftRequestReviewsPage;
