import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import HolidayRequests from './containers/holiday-requests';
import { loadInitialData } from './redux/actions';
import reducers from './redux/reducers';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

export function HolidayRequestsApp(props) {
  const [store, setStore] = useState(null);
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  const appType = oFetch(props, 'appType');
  const title = oFetch(props, 'title');

  return (
    <LegacyGlobalProvider global={{ appType, title }}>
      <Provider store={store}>
        <HolidayRequests />
      </Provider>
    </LegacyGlobalProvider>
  );
}
