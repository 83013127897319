import React, { useState } from 'react';
import oFetch from 'o-fetch';

import { BossTable } from '@/components/boss-table';
function AccessoryRequestsList(props) {
  const [requests, setRequests] = useState(
    props.paginate ? props.accessoryRequests.slice(0, props.pageSize) : props.accessoryRequests,
  );

  function renderAccessoryRequests(accessoryRequests, accessory, staffMembers) {
    const accessoryId = oFetch(accessory, 'id');

    return accessoryRequests.map(request => {
      const staffMember = staffMembers.find(
        staffMember => oFetch(staffMember, 'id') === oFetch(request, 'staffMemberId'),
      );
      if (!staffMember) {
        throw new Error('Something went wrong, staff member must present');
      }

      const avatarUrl = oFetch(staffMember, 'avatarUrl');
      const fullName = oFetch(staffMember, 'fullName');

      const accessorySize = oFetch(request, 'size') || 'N/A';
      const requestId = oFetch(request, 'id');
      const requestStatus = oFetch(request, 'status');
      const frozen = oFetch(request, 'frozen');
      const reusable = request.reusable;

      const requestData = {
        avatarUrl,
        fullName,
        accessorySize,
        requestId,
        accessoryId,
        staffMember,
        requestStatus,
        frozen,
        reusable,
      };

      return React.cloneElement(props.requestItemRenderer(requestData), {
        key: requestId,
      });
    });
  }

  function onLoadMoreClick() {
    setRequests(prevState => {
      return props.accessoryRequests.slice(0, prevState.requests.length + props.pageSize);
    });
  }

  function renderLoadMore() {
    return (
      <div className="boss-page-main__actions boss-page-main__actions_position_last">
        <button
          onClick={onLoadMoreClick}
          className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
        >
          Load more
        </button>
      </div>
    );
  }

  const { accessory, staffMembers } = props;
  const showLoadMore =
    props.paginate && requests.length > 0 && requests.length < props.accessoryRequests.length;
  return (
    <BossTable className="boss-table_page_accessory-requests-card">
      {renderAccessoryRequests(requests, accessory, staffMembers)}
      {showLoadMore && renderLoadMore()}
    </BossTable>
  );
}

AccessoryRequestsList.defaultProps = {
  pageSize: 5,
  accessoryRequests: [],
  paginate: false,
};

export default AccessoryRequestsList;
