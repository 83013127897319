import oFetch from 'o-fetch';
import { END_TIME_TYPE, TIME_TYPES } from './constants';
const MILLISECONDS_PER_SECOND = 1000;
const MILLISECONDS_PER_MINUTE = 60 * MILLISECONDS_PER_SECOND;
const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;

export function decodeRotaTimeValue(args) {
  const sEncodedTime = oFetch(args, 'sEncodedTime');
  if (typeof sEncodedTime === 'undefined' || sEncodedTime === null) {
    throw new Error(`sEncodedTime must be supplied`);
  }
  if ((sEncodedTime.match(/[0-2]?[0-9]:[0-9][0-9]/) || []).length === 0) {
    throw new Error(`sEncoded time format invalid must be hh:mm. Got: ${sEncodedTime}`);
  }

  const [hours, minutes] = sEncodedTime.split(":").map((x) => parseInt(x));
  return { hours, minutes };
}

export function incrementHours(args) {
  const dTime = oFetch(args, 'dTime');
  if (typeof dTime === 'undefined' || dTime === null) {
    throw new Error('dTime was not supplied');
  }
  const hours = oFetch(args, 'hours');
  if (typeof hours === 'undefined' || hours === null) {
    throw new Error('hours must be supplied');
  }
  if (hours < 0 || hours > 23) {
    throw new Error('hours must be between 0 and 23');
  }
  const minutes = oFetch(args, 'minutes');
  if (typeof minutes === 'undefined' || minutes === null) {
    throw new Error('minutes must be supplied');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('minutes must be between 0 and 59');
  }

  return new Date(
    dTime.valueOf() +
    (hours * MILLISECONDS_PER_HOUR) +
    (minutes * MILLISECONDS_PER_MINUTE )
  );
}

export function getSecondsBetween(args) {
  const dStartTime = oFetch(args, 'dStartTime');
  const dEndTime = oFetch(args, 'dEndTime');

  var deltaMs =  dEndTime.valueOf() - dStartTime.valueOf();
  return deltaMs / MILLISECONDS_PER_SECOND;
}

export function getMinutesBetween(args) {
  const dStartTime = oFetch(args, 'dStartTime');
  const dEndTime = oFetch(args, 'dEndTime');

  var deltaMs =  dEndTime.valueOf() - dStartTime.valueOf();
  return deltaMs / MILLISECONDS_PER_MINUTE;
}

export function getHoursBetween(args) {
  const dStartTime = oFetch(args, 'dStartTime');
  const dEndTime = oFetch(args, 'dEndTime');

  var deltaMs =  dEndTime.valueOf() - dStartTime.valueOf();
  return deltaMs / MILLISECONDS_PER_HOUR;
}

export function rotaTimesToCalendarTime(args) {
  const hours = oFetch(args, 'hours');
  if (typeof hours === 'undefined' || hours === null) {
    throw new Error('hours must be supplied');
  }
  if (hours < 0 || hours > 23) {
    throw new Error('hours must be between 0 and 23');
  }
  const minutes = oFetch(args, 'minutes');
  if (typeof minutes === 'undefined' || hours === null) {
    throw new Error('minutes must be supplied');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('minutes must be between 0 and 59');
  }
  const timeType = oFetch(args, 'timeType');
  if (!TIME_TYPES.includes(timeType)) {
    throw new Error(`timeType . got: ${timeType}`);
  }
  const rotaDate = oFetch(args, 'rotaDate');

  const timeAtBounadry = hours === rotaDate.startHour() && minutes === 0;

  var isOnNextCalendarDay = (timeAtBounadry && timeType === END_TIME_TYPE) ||
  hours < rotaDate.startHour();

  let dCalendarDate = rotaDate.calendarDate();
  if (isOnNextCalendarDay) {
    dCalendarDate.setDate(dCalendarDate.getDate() + 1);
  }

  dCalendarDate.setHours(hours, minutes, 0, 0);
  return dCalendarDate;
}
