import React from 'react';
import oFetch from 'o-fetch';

export default function CountingHistoryItem(props) {
  const [history, onDetails] = oFetch(props, 'history', 'onDetails');
  const [formattedHistoryModalEventType, eventBy, formattedEventAt] = oFetch(
    history,
    'formattedHistoryModalEventType',
    'eventBy',
    'formattedEventAt',
  );

  return (
    <li className="purple-history__item">
      <div className="purple-history__content">
        <div className="purple-panel purple-panel_role_board-group purple-panel_page_counting-history">
          <div className="purple-panel__header">
            <div className="purple-panel__header-info">
              <h3 className="purple-panel__title">
                <span className="purple-panel__title-light">{formattedEventAt}</span>
              </h3>
            </div>
            <div className="purple-panel__header-actions">
              <button
                onClick={() => onDetails(history)}
                type="button"
                className="purple-button purple-button_size_xs purple-button_border_accent-blue purple-button_icon_zoom-plus purple-panel__action"
              >
                <span className="purple-button__text">Details</span>
              </button>
            </div>
          </div>
          <div className="purple-panel__group purple-panel__group_type_marked">
            <div className="purple-panel__group-content">
              <p className="purple-panel__text">
                <span className="purple-panel__text-light">{formattedHistoryModalEventType} by </span>
                <span className="purple-panel__text-bold">{eventBy}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
