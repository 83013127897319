import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, reduxForm, formValueSelector, change } from 'redux-form';
import oFetch from 'o-fetch';
import {
  BossFormInput,
  BossFormTextarea,
  BossFormCalendar,
  BossFormSelectNew,
  BossFormMultiSelectWithSelectAll,
} from '@/components/boss-form';
import BossFormTimeInput from './fields/boss-form-time-input';
import utils from '@/lib/utils';

const FORM_NAME = 'event-form';

class EventForm extends Component {
  handleSubmit = (values, dispatch, form) => {
    return form.onSubmit(values);
  };
  render() {
    const [
      handleSubmit,
      eventTypesOptions,
      venueOptions,
      selectedEventType,
      buttonText,
      globalEventType,
      submitting,
      selectedDate,
    ] = oFetch(
      this.props,
      'handleSubmit',
      'eventTypesOptions',
      'venueOptions',
      'selectedEventType',
      'buttonText',
      'globalEventType',
      'submitting',
      'selectedDate',
    );

    const isGlobalEventType = selectedEventType === globalEventType;
    return (
      <div className="boss-form">
        <Field name="title" label="Title" placeholder="Enter title ..." component={BossFormInput} />
        <Field
          name="description"
          label="Description"
          placeholder="Enter description ..."
          component={BossFormTextarea}
        />
        <div className="boss-form__row">
          <Field
            name="date"
            label="Date"
            placeholder="Select date ..."
            disableBeforeRotaDay
            component={BossFormCalendar}
            valueFormat={utils.commonDateFormat}
            fieldClassName="boss-form__field_layout_half"
          />
          <Fields
            names={['startsAt', 'endsAt']}
            component={BossFormTimeInput}
            date={selectedDate}
          />
        </div>
        <div className="boss-form__group boss-form__group_role_board-outline">
          <Field
            name="eventType"
            label="Event Type"
            options={eventTypesOptions}
            placeholder="Select event type ..."
            component={BossFormSelectNew}
          />
          {isGlobalEventType && (
            <Field
              name="venuesIds"
              label="Venues"
              valueKey="id"
              labelKey="name"
              multi
              clearable
              options={venueOptions}
              placeholder="Select venues ..."
              component={BossFormMultiSelectWithSelectAll}
            />
          )}
        </div>
        <div className="boss-form__field">
          <button
            disabled={submitting}
            onClick={handleSubmit(this.handleSubmit)}
            className="boss-button boss-button_role_add boss-form__submit"
            type="submit"
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}
const selector = formValueSelector(FORM_NAME);

const ConnectedEventForm = connect((state, ownProps) => {
  const eventType = selector(state, 'eventType');
  const date = selector(state, 'date');

  return {
    selectedEventType: eventType || null,
    selectedDate: date || null,
  };
})(EventForm);

export default reduxForm({
  form: FORM_NAME,
  onChange: (values, dispatch, props, previousValues) => {
    const newDate = oFetch(values, 'date');
    const oldDate = oFetch(previousValues, 'date');
    if (newDate !== oldDate) {
      dispatch(change(FORM_NAME, 'startsAt', null));
      dispatch(change(FORM_NAME, 'endsAt', null));
    }
    const [currentVenueId, localEventType] = oFetch(props, 'currentVenue.id', 'localEventType');
    const eventType = oFetch(values, 'eventType');
    if (eventType === localEventType) {
      dispatch(change(FORM_NAME, 'venuesIds', [currentVenueId]));
    }
  },
})(ConnectedEventForm);
