import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form';
import utils from '@/lib/utils';
import * as types from './types';
import * as requests from './requests';
import * as selectors from './selectors';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const addTaskAction = createAction(types.ADD_TASK);
export const addParentTaskAction = createAction(types.ADD_PARENT_TASK);
export const deleteTaskAction = createAction(types.DELETE_TASK);
export const updateTaskAction = createAction(types.UPDATE_TASK);
export const addEventAction = createAction(types.ADD_EVENT);
export const deleteEventAction = createAction(types.DELETE_EVENT);
export const updateEventAction = createAction(types.UPDATE_EVENT);
export const addReminderAction = createAction(types.ADD_REMINDER);
export const deleteReminderAction = createAction(types.DELETE_REMINDER);
export const updateReminderAction = createAction(types.UPDATE_REMINDER);
export const setSortingAction = createAction(types.SORT);

export const createTask = values => (dispatch, getState) => {
  return requests.brCreateTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(addTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task created Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'text', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const deleteTask = values => (dispatch, getState) => {
  return requests.brDeleteTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const id = oFetch(values, 'id');
      dispatch(deleteTaskAction({ id }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been deleted Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const declineTask = values => (dispatch, getState) => {
  return requests.brDeclineTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been declined Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'declineReason'],
        });

        const errorMessages = utils.safeGet(() => errors._error);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const postponeTask = values => (dispatch, getState) => {
  return requests.brPostponeTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateTaskAction(data));
      dispatch(addParentTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been postpone Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors._error);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const editTask = values => (dispatch, getState) => {
  return requests.brEditTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been updated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'text', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
export const createEvent = values => (dispatch, getState) => {
  return requests.brCreateEvent({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(addEventAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Event created Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description', 'date', 'startsAt', 'endsAt', 'eventType', 'venuesIds'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const editEvent = values => (dispatch, getState) => {
  return requests.brEditEvent({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateEventAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Event has been updated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description', 'date', 'startsAt', 'endsAt', 'eventType', 'venuesIds'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const deleteEvent = values => (dispatch, getState) => {
  return requests.brDeleteEvent({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const id = oFetch(values, 'id');
      dispatch(deleteEventAction({ id }));
      globalNotifications.showDefaultSuccessMessage({ message: 'Event has been deleted Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error'],
        });

        const errorMessages = utils.safeGet(() => errors._error);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const createReminder = values => (dispatch, getState) => {
  return requests.brCreateReminder({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(addReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been created Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description', 'date', 'recurrenceType'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

function deleteErrorHandler(params, values, dispatch) {
  const statusCode = oFetch(params, 'statusCode');
  const errors = oFetch(params, 'errors');
  const globalNotifications = oFetch(params, 'globalNotifications');
  const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

  if (statusCode === 422 && errors) {
    supportedKeyChecker.validateKeys({
      suppliedKeys: Object.keys(errors),
      supportedKeys: ['_error', 'title', 'description', 'date'],
    });

    const errorMessages = utils.safeGet(() => errors.base);
    if (errorMessages) {
      globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
    }
    throw new SubmissionError(errors);
  }

  return false;
}

export const deleteReminder = values => (dispatch, getState) => {
  return requests.brDeleteReminder({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(deleteReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been deleted Successfully' });
    },
    errorHandler: deleteErrorHandler,
  });
};

export const deleteRecurringFutureReminders = values => (dispatch, getState) => {
  return requests.brDeleteRecurringFutureReminders({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been deleted Successfully' });
    },
    errorHandler: deleteErrorHandler,
  });
};

export const deleteRecurringSingleReminders = values => (dispatch, getState) => {
  return requests.brDeleteRecurringSingleReminders({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been deleted Successfully' });
    },
    errorHandler: deleteErrorHandler,
  });
};

export const deleteRecurringAllReminders = values => (dispatch, getState) => {
  return requests.brDeleteRecurringAllReminders({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(deleteReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been deleted Successfully' });
    },
    errorHandler: deleteErrorHandler,
  });
};

export const editReminder = values => (dispatch, getState) => {
  return requests.brEditReminder({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been updated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
export const completeReminder = values => (dispatch, getState) => {
  return requests.brCompleteReminder({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been completed Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
export const activateReminder = values => (dispatch, getState) => {
  return requests.brActivateReminder({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateReminderAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Reminder has been activated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'title', 'description', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
export const completeTask = values => (dispatch, getState) => {
  return requests.brCompleteTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been completed Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'text', 'venue', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};
export const activateTask = values => (dispatch, getState) => {
  return requests.brActivateTask({
    values,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const data = oFetch(params, 'data');
      dispatch(updateTaskAction(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Task has been activated Successfully' });
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error', 'text', 'venue', 'date'],
        });

        const errorMessages = utils.safeGet(() => errors.base);
        if (errorMessages) {
          globalNotifications.showDefaultFailureMessage({ message: errorMessages.join(', ') });
        }
        throw new SubmissionError(errors);
      }

      return false;
    },
  });
};

export const sortAction = sortData => (dispatch, getState) => {
  let parsedSortData;
  try {
    parsedSortData = sortData.map(JSON.parse);
  } catch (e) {
    throw new Error(
      'Wrong SortingIds format. Please check `data-id` attr in the item components or check `sortData` key selectors. Must be `JSON.stringify`',
    );
  }
  const currentVenue = selectors.currentVenueSelector(getState());
  const currentDate = selectors.selectedDateSelector(getState());
  const id = oFetch(currentVenue, 'id');
  dispatch(setSortingAction(parsedSortData));
  return requests.brSort({
    sortData: parsedSortData,
    currentVenueId: id,
    currentDate: currentDate,
    successHandler: params => {},
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');

      if (statusCode === 422) {
        return false;
      }

      return false;
    },
  });
};
