import React, { useState, useEffect, useMemo } from 'react';
import { reduxForm, FieldArray, Field, Fields, change, arrayPush } from 'redux-form';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import safeMoment from '@/lib/safe-moment';
import { MOSS_APP_TYPE, RotaDate } from '@/lib/rota-date';
import { BossFormTagInput } from '@/components/boss-form';

import BossFormCheckbox from './boss-form-checkbox';
import { FormTimeInterval } from './form-time-interval';
import * as actions from '../../../../redux/actions';
import { HeadDoorHoursArray } from './head-door-hours-array';
import { BreaksArray } from './breaks-array';
import { nonTsGetExistingMossHourTagOptions } from '@/lib/moss-hour-tags';

function HoursAcceptancePeriodForm(props) {
  const [
    appType,
    hoursAcceptancePeriod,
    submitting,
    activeSpecialSecurityPayrates,
    onDeletePeriod,
    onAccept,
    handleSubmit,
    canBypassPeriodsTimeLimits,
    isSecurity,
    headDoorHrs,
    initialValues,
    specialPayRateSecurityHours,
  ] = oFetch(
    props,
    'appType',
    'hoursAcceptancePeriod',
    'submitting',
    'activeSpecialSecurityPayrates',
    'onDeletePeriod',
    'onAccept',
    'handleSubmit',
    'canBypassPeriodsTimeLimits',
    'isSecurity',
    'headDoorHrs',
    'initialValues',
    'specialPayRateSecurityHours',
  );
  const sRotaDate = oFetch(hoursAcceptancePeriod, 'date');
  const mRotaDate = safeMoment.uiDateParse(sRotaDate);
  const rotaDate = RotaDate.fromDate({
    dCalendarDate: mRotaDate.toDate(),
    appType,
  });
  const [initialBreaks, initialSpecialPayRateSecurityHours] = oFetch(
    initialValues,
    'breaks',
    'specialPayRateSecurityHours',
  );
  const canHaveMossHourTags = appType === MOSS_APP_TYPE;

  const initialBreaksExist = initialBreaks.length > 0;
  const initialSpecialPayRateSecurityHoursExist = initialSpecialPayRateSecurityHours.length > 0;
  const specialPayRateSecurityHoursExist = specialPayRateSecurityHours.length > 0;
  const initialShowMossHourTags = canHaveMossHourTags && (oFetch(initialValues, 'mossHourTagIds').length > 0);

  const activeSpecialSecurityPayratesExist = activeSpecialSecurityPayrates.length > 0;

  const [isBreaksOpened, setIsBreaksOpened] = useState(initialBreaksExist);
  const [isHeadDoorHoursOpened, setIsHeadDoorHoursOpened] = useState(initialSpecialPayRateSecurityHoursExist);
  const [isMossHourTagsOpened, setIsMossHourTagsOpened] = useState(initialShowMossHourTags);

  useEffect(() => {
    if (specialPayRateSecurityHoursExist === true) {
      setIsHeadDoorHoursOpened(true);
    }
  }, [specialPayRateSecurityHoursExist]);

  function toggleBreaks() {
    setIsBreaksOpened(!isBreaksOpened);
  }

  function toggleHeadDoorHours() {
    setIsHeadDoorHoursOpened(!isHeadDoorHoursOpened);
  }

  function renderBaseErrors(errors) {
    return (
      <div className="boss-time-shift__error boss-time-shift__error_role_general">
        <p className="boss-time-shift__error-text boss-time-shift__error-text_role_title">
          Something went wrong!
        </p>
        {errors.map((error, index) => {
          return (
            <p
              key={index}
              className="boss-time-shift__error-text"
            >
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  const formattedTime = oFetch(hoursAcceptancePeriod, 'formattedTime');

  const periodFrozen = oFetch(hoursAcceptancePeriod, 'frozen');

  const { error: baseErrors } = props;

  const breaksClassNames = cn('boss-time-shift__break-toggle boss-time-shift__break-toggle_state_visible', {
    'boss-time-shift__break-toggle_state_opened': isBreaksOpened,
  });

  const headDoorHoursClassNames = cn('boss-time-shift__hdh-toggle', {
    'boss-time-shift__hdh-toggle_state_opened': isHeadDoorHoursOpened,
  });

  const mossHourTags = oFetch(props, 'mossHourTags');

  const currentMossHourTagIds = oFetch(hoursAcceptancePeriod, 'mossHourTagIds');
  const mossHourTagsOptions = useMemo(() => {
    return nonTsGetExistingMossHourTagOptions({
      mossHourTags,
      currentMossHourTagIds,
    });
  }, [mossHourTags, currentMossHourTagIds]);

  return (
    <div className="boss-hrc__shift">
      <div className="boss-time-shift boss-time-shift_updated">
        <div className="boss-time-shift__header">
          <h4 className="boss-time-shift__title">Shift</h4>
          {!periodFrozen && canBypassPeriodsTimeLimits && (
            <div className="boss-time-shift__checkbox">
              <Field
                name="bypassTimeLimits"
                className="boss-time-shift__checkbox"
                label="Bypass"
                type="checkbox"
                disabled={submitting}
                component={BossFormCheckbox}
              />
            </div>
          )}
          {isSecurity && activeSpecialSecurityPayratesExist && (
            <div className="boss-time-shift__checkbox boss-time-shift__checkbox_state_hdh-active">
              <Field
                name="headDoorHrs"
                className="boss-time-shift__checkbox"
                label="Head Door"
                type="checkbox"
                disabled={specialPayRateSecurityHoursExist || submitting}
                component={BossFormCheckbox}
              />
            </div>
          )}
        </div>
        <div className="boss-time-shift__form">
          {baseErrors && renderBaseErrors(baseErrors)}
          <div className="boss-time-shift__log">
            <div className="boss-time-shift__group">
              <Fields
                names={['startsAt', 'endsAt']}
                component={FormTimeInterval}
                rotaDate={rotaDate}
                granularityInMinutes={1}
              />
            </div>
            <div className="boss-time-shift__actions">
              <button
                onClick={handleSubmit(onAccept)}
                disabled={submitting}
                type="button"
                className="boss-button boss-button_role_success boss-time-shift__button boss-time-shift__button_role_accept-shift"
              >
                Accept <span className="boss-time-shift__button-count">{formattedTime}</span>
              </button>
              <button
                className="boss-button boss-button_role_cancel boss-time-shift__button boss-time-shift__button_role_delete-shift"
                disabled={submitting}
                onClick={handleSubmit(onDeletePeriod)}
              >
                Delete
              </button>
            </div>
          </div>
          {headDoorHrs && (
            <div className="boss-time-shift__hdh">
              <div className="boss-time-shift__hdh-controls">
                <button
                  onClick={toggleHeadDoorHours}
                  type="button"
                  className={headDoorHoursClassNames}
                >
                  Head Door Hours
                </button>
              </div>

              <Collapse
                isOpened={isHeadDoorHoursOpened}
                className="boss-time-shift__break-content"
                style={{ display: 'block' }}
              >
                <div className="boss-time-shift__hdh-inner">
                  <FieldArray
                    name="specialPayRateSecurityHours"
                    rotaDate={rotaDate}
                    hoursAcceptancePeriod={hoursAcceptancePeriod}
                    activeSpecialSecurityPayrates={activeSpecialSecurityPayrates}
                    component={HeadDoorHoursArray}
                  />
                </div>
              </Collapse>
            </div>
          )}
          <div className="boss-time-shift__break">
            <div className="boss-time-shift__break-controls">
              <button
                onClick={toggleBreaks}
                className={breaksClassNames}
              >
                Breaks
              </button>
            </div>
            <div
              className="boss-time-shift__hours-tags"
            >
              <Collapse
                isOpened={isBreaksOpened}
                className="boss-time-shift__break-content"
                style={{ display: 'block' }}
              >
                <div className="boss-time-shift__break-inner">
                  <FieldArray
                    name="breaks"
                    hoursAcceptancePeriod={hoursAcceptancePeriod}
                    component={BreaksArray}
                    rotaDate={rotaDate}
                  />
                </div>
              </Collapse>
            </div>
          </div>
          { canHaveMossHourTags && (
            <div className="boss-time-shift__hours-tags">
              <div className="boss-time-shift__hours-tags-controls">
                <button
                  className="boss-time-shift__hours-tags-toggle boss-time-shift__hours-tags-toggle_state_opened"
                  type="button"
                  onClick={() => setIsMossHourTagsOpened(!isMossHourTagsOpened)}
                >Hours Tags</button>
              </div>

              <Collapse
                isOpened={isMossHourTagsOpened}
                className="boss-time-shift__break-content"
                style={{ display: 'block' }}
              >
                <Field
                  name="mossHourTagIds"
                  className="boss-time-shift__hours-tags-select"
                  options={mossHourTagsOptions}
                  valueKey="id"
                  labelKey="name"
                  disabled={submitting}
                  component={BossFormTagInput}
                  clearable
                  multi
                />
              </Collapse>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  onChange(values, dispatch, props, prevProps) {
    const prevHeadDoorHrs = oFetch(prevProps, 'headDoorHrs');
    const currentHeadDoorHrs = oFetch(values, 'headDoorHrs');

    dispatch(actions.updatePeriodData(values));
    const formName = oFetch(props, 'form');
    if (
      oFetch(values, 'specialPayRateSecurityHours').length === 0 &&
      currentHeadDoorHrs === true &&
      prevHeadDoorHrs === true
    ) {
      dispatch(change(formName, 'headDoorHrs', false));
    } else {
      if (prevHeadDoorHrs === false && currentHeadDoorHrs === true) {
        const hoursAcceptancePeriod = oFetch(props, 'hoursAcceptancePeriod');
        const [frontendId, startsAt, endsAt] = oFetch(
          hoursAcceptancePeriod,
          'frontendId',
          'startsAt',
          'endsAt',
        );
        const newHeadDoorHours = {
          id: null,
          frontendId: uuid(),
          securityPayRateId: null,
          securityPayRateName: null,
          hoursAcceptancePeriod: frontendId,
          startsAt: startsAt,
          endsAt: endsAt,
        };
        dispatch(arrayPush(formName, 'specialPayRateSecurityHours', newHeadDoorHours));
      }
    }
  },
})(
  connect((state, props) => {
    const formSelector = oFetch(props, 'formSelector');
    const headDoorHrs = formSelector(state, 'headDoorHrs');
    const specialPayRateSecurityHours = formSelector(state, 'specialPayRateSecurityHours');

    return {
      headDoorHrs,
      specialPayRateSecurityHours,
    };
  })(HoursAcceptancePeriodForm),
);
