import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { getOveplappedRotaShifts } from '../../utils';
import safeMoment from '@/lib/safe-moment';
import { reduxForm, SubmissionError, Fields, Field } from 'redux-form/immutable';
import { BossFormCheckbox, BossFormShiftTimeInput } from '@/components/boss-form';

const onSubmit = (values, dispatch, props) => {
  const jsValues = values.toJS();
  return props.onFormSubmit(jsValues, dispatch);
};

const validate = (values, props) => {
  const jsValues = values.toJS();
  const errors = {};
  const startsAt = oFetch(jsValues, 'startsAt');
  const endsAt = oFetch(jsValues, 'endsAt');
  const rotaShifts = oFetch(props, 'rotaShifts');

  const overlappedRotaShifts = getOveplappedRotaShifts(rotaShifts, startsAt, endsAt);

  if (overlappedRotaShifts.length > 0) {
    const overlappingTimes = overlappedRotaShifts.map(rotaShift => {
      const mStartTime = safeMoment.iso8601Parse(rotaShift.startsAt);
      const mEndTime = safeMoment.iso8601Parse(rotaShift.endsAt);
      const formattedTime = utils.shiftRequestIntervalFormat({ mStartsAt: mStartTime, mEndsAt: mEndTime });
      return formattedTime;
    });

    errors._error = [
      `Shift overlaps existing (${overlappedRotaShifts.length}) shifts: ${overlappingTimes.join(', ')}`,
    ];
  }
  return errors;
};

const renderBaseError = errors => {
  return errors.map((error, index) => (
    <li
      key={index.toString()}
      className="boss-user-summary__review-item boss-user-summary__review-item_space_extra"
    >
      <div className="boss-alert">
        <p className="boss-alert__text">
          <span className="boss-alert__text-marked">{error}</span>
        </p>
      </div>
    </li>
  ));
};

function AssignConfirmForm(props) {
  const [isEditing, setIsEditing] = useState(false);
  const appType = oFetch(props, 'appType');

  useEffect(() => {
    if (props.submitFailed) {
      setIsEditing(true);
    }
  }, [props.submitFailed])

  function toggleEditing() {
    setIsEditing(!isEditing)
  };

  const { error, submitting } = props;
  const avatarUrl = oFetch(props, 'avatarUrl');
  const fullName = oFetch(props, 'fullName');
  const startsAt = oFetch(props, 'startsAt');
  const endsAt = oFetch(props, 'endsAt');
  const venueName = oFetch(props, 'venueName');
  const isSteward = oFetch(props, 'isSteward');
  const supportsFacialRecognition = oFetch(props, 'supportsFacialRecognition');
  const timeSelectOptions = oFetch(props, 'timeSelectOptions');

  let avatarContainerClasses = 'boss-user-summary__avatar';
  if (supportsFacialRecognition) {
    avatarContainerClasses = avatarContainerClasses + ' boss-user-summary__avatar_type_scannable';
  }

  return (
    <form onSubmit={props.handleSubmit} className={submitting ? 'submitting' : ''}>
      <div className="boss-modal-window__message-block">
        <div className="boss-user-summary boss-user-summary_role_ssr-assign-confirmation">
          <div className="boss-user-summary__side">
            <div className={avatarContainerClasses}>
              <div className="boss-user-summary__avatar-inner">
                <img src={avatarUrl} alt="John Doe" className="boss-user-summary__pic" />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">{fullName}</h2>
            </div>

            <ul className="boss-user-summary__review-list">
              {error && renderBaseError(error)}
              {!isEditing && (
                <li className="boss-user-summary__review-item boss-user-summary__review-item_space_extra boss-user-summary__review-item_role_time">
                  {utils.shiftRequestIntervalFormat({
                    mStartsAt: safeMoment.iso8601Parse(startsAt),
                    mEndsAt: safeMoment.iso8601Parse(endsAt),
                  })}
                  <button
                    onClick={toggleEditing}
                    className="boss-button boss-button_type_extra-small boss-button_role_update boss-user-summary__review-action"
                  >
                    Edit
                  </button>
                </li>
              )}
              {isEditing && (
                <li className="boss-user-summary__review-item boss-user-summary__review-item_space_extra">
                  <div className="boss-form">
                    <Fields
                      names={['startsAt', 'endsAt']}
                      component={BossFormShiftTimeInput}
                      options={timeSelectOptions}
                      startsAtFieldName="startsAt"
                      endsAtFieldName="endsAt"
                    />
                  </div>
                </li>
              )}
              {!isSteward && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Field
                    name="headDoormanHour"
                    label="Head Doorman"
                    styles={{ width: 'inherit' }}
                    component={BossFormCheckbox}
                  />
                </div>
              )}
              <li className="boss-user-summary__review-item boss-user-summary__review-item_role_date">
                {utils.shiftRequestDayFormat({
                  mStartsAt: safeMoment.iso8601Parse(startsAt),
                  appType,
                })}
              </li>
              <li className="boss-user-summary__review-item boss-user-summary__review-item_role_venue">
                {venueName}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="boss-modal-window__actions">
        <button disabled={ submitting || error } type="submit" className="boss-button boss-button_role_confirm">
          Confirm
        </button>
      </div>
    </form>
  );
}

AssignConfirmForm.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default reduxForm({
  onSubmit: onSubmit,
  form: 'assign-confirm-form',
  validate,
})(AssignConfirmForm);
