import React from 'react';
import oFetch from 'o-fetch';
import { StatusFilter } from './status-filter';

interface DashboardProps {
  title: string;
  onNewMessageClick: () => void;
  onStatusChange: (statuses: string[]) => void;
  selectedStatuses: string[];
}

export function Dashboard(props: DashboardProps) {
  const title = oFetch(props, 'title');
  const onNewMessageClick = oFetch(props, 'onNewMessageClick');
  const selectedStatuses = oFetch(props, 'selectedStatuses');
  const onStatusChange = oFetch(props, 'onStatusChange');

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_vhci">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            <div className="boss-page-dashboard__buttons-group boss-page-dashboard__buttons-group_position_last">
              <button
                onClick={onNewMessageClick}
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >New Message</button>
            </div>
          </div>
          <div className="boss-page-dashboard__filter">
            <StatusFilter
              selectedStatuses={selectedStatuses}
              onStatusChange={onStatusChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
