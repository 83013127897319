import { handleActions, combineActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const reminders = oFetch(action.payload, 'reminders');
      return reminders;
    },
    [combineActions(commonTypes.ADD_REMINDER, commonTypes.UPDATE_REMINDER)]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const reminder = oFetch(payload, 'reminder');
      if (reminder === null) {
        return state;
      }
      const id = oFetch(reminder, 'id');
      const stateWithoutReminder = state.filter(reminder => oFetch(reminder, 'id') !== id);
      return [...stateWithoutReminder, reminder];
    },
    [commonTypes.DELETE_REMINDER]: (state, action) => {
      const id = oFetch(action.payload, 'reminder.id');
      return state.filter(event => oFetch(event, 'id') !== id);
    },
  },
  initialState,
);
