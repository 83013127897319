import React, { Component } from 'react';
import oFetch from 'o-fetch';
import PostponeForm from './postpone-form';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

class PostponeTask extends Component {
  render() {
    const [id, onSubmit] = oFetch(this.props, 'id', 'onSubmit');

    const initialValues = {
      id,
      date: null,
    };

    return <PostponeForm initialValues={initialValues} onSubmit={onSubmit} />;
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(PostponeTask);
