import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const checklists = oFetch(action, 'payload.checklists');
      return checklists;
    },
    [types.ADD_CHECKLIST]: (state, action) => {
      const checklist = oFetch(action, 'payload.checklist');
      return [...state, checklist];
    },
    [types.REMOVE_CHECKLIST_FROM_STORE]: (state, action) => {
      const checklistId = oFetch(action, 'payload.checklistId');
      const checklists = state.filter(checklist => oFetch(checklist, 'id') !== checklistId);
      return checklists;
    },
    [types.UPDATE_CHECKLIST]: (state, action) => {
      const [checklistId, checklist] = oFetch(action, 'payload.checklistId', 'payload.checklist');
      const checklists = state.map(oldChecklist => {
        if (oFetch(oldChecklist, 'id') === checklistId) {
          return checklist;
        }
        return oldChecklist;
      });
      return checklists;
    },
  },
  initialState,
);
