import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const invites = oFetch(action, 'payload.invites');
      return invites;
    },
    [types.INVITE_USER]: (state, action) => {
      const invite = oFetch(action, 'payload.invite');
      return [invite, ...state];
    },
    [types.UPDATE_INVITE]: (state, action) => {
      const updateInvite = oFetch(action, 'payload.invite');
      const updateInviteId = oFetch(updateInvite, 'id');
      return state.map(invite => {
        if (oFetch(invite, 'id') === updateInviteId) {
          return updateInvite;
        }
        return invite;
      });
    },
  },
  initialState,
);
