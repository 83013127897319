import { createSelector } from 'reselect';
import { OwedHourModel, DurationModel, TimesModel, OwedHoursWeekModel } from './models';
import Immutable from 'immutable';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { RotaWeek } from '@/lib/rota-date';

momentDurationFormatSetup(moment);

export const owedHoursSelector = state => state.getIn(['owedHours', 'owedHours']);

export const getOwedHoursGrouppedByWeekDates = createSelector(owedHoursSelector, owedHours => {
  return owedHours.groupBy((owedHour) => {
    const owedHourJS = owedHour.toJS();
    const dDate = safeMoment.uiDateParse(oFetch(owedHourJS, 'date')).toDate();
    const rotaWeek = RotaWeek.fromDate({
      dCalendarDate: dDate
    });

    return [rotaWeek.startDate(), rotaWeek.endDate()];
  });
});

export const getOwedHoursWeeks = createSelector(getOwedHoursGrouppedByWeekDates, owedHoursByWeekDates => {
  const result = owedHoursByWeekDates.reduce((acc, owedHours, weekDates) => {
    const totalMinutes = owedHours.reduce((acc, owedHour) => acc + owedHour.get('minutes'), 0);
    const dWeekStartDate = weekDates[0];
    const rotaWeek = RotaWeek.fromDate({
      dCalendarDate: dWeekStartDate,
    });
    return acc.push(
      new OwedHoursWeekModel({
        rotaWeek,
        totalHours: moment.duration(totalMinutes, 'minutes').format('*hh[ hours] mm[ minutes]', { trim: 'both', useGrouping: false }),
        owedHours: owedHours
          .map(owedHour => {
            const duration = moment.duration(owedHour.get('minutes'), 'minutes');

            return new OwedHourModel({
              id: owedHour.get('id'),
              date: owedHour.get('date'),
              hasDate: owedHour.get('hasDate'),
              editable: owedHour.get('editable'),
              note: owedHour.get('note'),
              payslipDate: owedHour.get('payslipDate'),
              createdAt: owedHour.get('createdAt'),
              createdBy: owedHour.get('createdBy'),
              mossHourTagIds: owedHour.get('mossHourTagIds'),
              duration: new DurationModel({
                hours: duration.hours(),
                minutes: duration.minutes(),
              }),
              times: new TimesModel({
                startsAt: owedHour.get('startsAt'),
                endsAt: owedHour.get('endsAt'),
              }),
              frozen: owedHour.get('frozen'),
              startsAtCCTVImage: owedHour.get('startsAtCCTVImage'),
              endsAtCCTVImage: owedHour.get('endsAtCCTVImage'),
              owedHourType: owedHour.get('owedHourType'),
            });
          })
          .sortBy(owedHour => oFetch(owedHour, 'date'))
          .sortBy(owedHour => oFetch(owedHour, 'times.startsAt')),
      }),
    );
  }, Immutable.List());
  return result.sortBy(
    immutOwedWeek => {
      const owedWeekJS = immutOwedWeek.toJS();
      return oFetch(owedWeekJS, 'rotaWeek').startDate();
    },
    (a, b) => {
      return a < b;
    },
  );
});
