import React from 'react';
import oFetch from 'o-fetch';
import BossDatePicker from '@/components/react-dates/boss-date-picker';
import moment from 'moment';
import cn from 'classnames';
function BossFormCalendar(props) {
  function handleChange(value) {
    const [onChange, valueFormat] = oFetch(props, 'input.onChange', 'valueFormat');
    if (valueFormat && value) {
      onChange(value.format(valueFormat));
    } else {
      onChange(value);
    }
  }

  function getValue() {
    const [value, valueFormat] = oFetch(props, 'input.value', 'valueFormat');
    if (valueFormat && value) {
      return moment(value, valueFormat);
    }
    return value;
  }

  const {
    label,
    required,
    input: { onBlur, value, onChange, name },
    meta: { touched, error, warning, submitError },
    fieldClassName,
    isOutsideRange,
    isDayBlocked,
    disableBeforeRotaDay,
    disableYear
  } = props;

  const fieldClassNames = cn('boss-form__field', { [fieldClassName]: fieldClassName });
  const hasError = error || submitError;
  return (
    <div className={fieldClassNames}>
      {label && (
        <label className="boss-form__label">
          <span className="boss-form__label-text">{`${label} ${required ? '*' : ''}`}</span>
        </label>
      )}
      <BossDatePicker
        date={getValue()}
        isOutsideRange={isOutsideRange}
        isDayBlocked={isDayBlocked}
        onApply={handleChange}
        initialMonth={props.initialMonth}
        initialYear={props.initialYear}
        disableYear={disableYear}
        disableBeforeRotaDay={disableBeforeRotaDay}
        invalid={!!touched && !!hasError}
      />
      {touched && hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error || submitError}</span>
          </p>
        </div>
      )}
    </div>
  );
}
BossFormCalendar.defaultProps = {
  valueFormat: null,
  disableYear: false,
};
export default BossFormCalendar;
