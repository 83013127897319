import React from 'react';
import oFetch from 'o-fetch';
import { RejectTaskForm } from '../forms';

export function RejectTaskModalContent(props) {
  const marketingTask = oFetch(props, 'marketingTask');
  const onSubmit = oFetch(props, 'onSubmit');

  const initialValues = {
    taskId: oFetch(marketingTask, 'id'),
    text: '',
  };

  return (
    <div className="boss-modal-window__form">
      <RejectTaskForm initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  );
}
