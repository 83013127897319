import React, { useEffect } from "react";
import { Page } from "./components/page";
import { ModalsProvider } from "@/components/hooks-components/modals";
import { $MossHourTagReportAppProps } from "./types";

export function MossHourTagsReportApp(props: unknown): JSX.Element {
  const parsedProps = $MossHourTagReportAppProps.parse(props);
  useEffect(() => {
    const accessToken = parsedProps.accessToken;
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, []);

  return (
    <ModalsProvider>
      <Page {...parsedProps} />
    </ModalsProvider>
  );
}