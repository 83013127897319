import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HolidayRequestsPage from '../components/holiday-requests-page';
import oFetch from 'o-fetch';

import { acceptHolidayRequest, rejectHolidayRequest } from '../redux/actions';

const mapStateToProps = (state) => {
  return {
    holidayRequests: oFetch(state, 'holidayRequests'),
    permissionsData: oFetch(state, 'permissionsData'),
    effectiveStaffMembers: oFetch(state, 'effectiveStaffMembers'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ acceptHolidayRequest, rejectHolidayRequest }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayRequestsPage);
