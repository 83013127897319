export const UNIFORM_TYPE = 'uniform';
export const MISC_TYPE = 'misc';

export const UNIFORM_TYPE_LABEL = 'Uniform';
export const MISC_TYPE_LABEL = 'Misc';

export const ACCESSORY_TYPE_LABELS = {
  [UNIFORM_TYPE]: UNIFORM_TYPE_LABEL,
  [MISC_TYPE]: MISC_TYPE_LABEL,
};

export const ACCESSORY_SELECT_TYPES = [
  { value: MISC_TYPE, label: ACCESSORY_TYPE_LABELS[MISC_TYPE] },
  { value: UNIFORM_TYPE, label: ACCESSORY_TYPE_LABELS[UNIFORM_TYPE] },
];

export const ACCESSORY_REQUEST_STATUS_PENDING = 'pending';
export const ACCESSORY_REQUEST_STATUS_ACCEPTED = 'accepted';
export const ACCESSORY_REQUEST_STATUS_COMPLETED = 'completed';
export const ACCESSORY_REQUEST_STATUS_REJECTED = 'rejected';
export const ACCESSORY_REQUEST_STATUS_CANCELED = 'canceled';
export const ACCESSORY_REQUEST_STATUS_RECEIVED = 'received';
export const ACCESSORY_REQUEST_STATUS_CHARGED_LEGACY = 'charged_legacy';
export const ACCESSORY_REQUEST_STATUS_RETURNED = 'returned';
export const ACCESSORY_REQUEST_STATUS_UNRETURNED_AFTER_MOVE = 'unreturned_after_move';
export const ACCESSORY_REQUEST_STATUS_CHARGED_NOT_RETURNED = 'charged_not_returned';
export const ACCESSORY_REQUEST_STATUS_CHARGED_UNRETURNABLE = 'charged_unreturnable';
export const ACCESSORY_REQUEST_STATUS_WRITE_OFF = 'write_off';

export const ACCESSORY_REQUEST_STATUS = {
  [ACCESSORY_REQUEST_STATUS_PENDING]: 'Pending',
  [ACCESSORY_REQUEST_STATUS_ACCEPTED]: 'Accepted',
  [ACCESSORY_REQUEST_STATUS_COMPLETED]: 'Completed',
  [ACCESSORY_REQUEST_STATUS_REJECTED]: 'Rejected',
  [ACCESSORY_REQUEST_STATUS_CANCELED]: 'Request canceled',
  [ACCESSORY_REQUEST_STATUS_RECEIVED]: 'Received',
  [ACCESSORY_REQUEST_STATUS_CHARGED_NOT_RETURNED]: 'Charged Not Returned',
  [ACCESSORY_REQUEST_STATUS_CHARGED_LEGACY]: 'Charged',
  [ACCESSORY_REQUEST_STATUS_RETURNED]: 'Returned',
  [ACCESSORY_REQUEST_STATUS_UNRETURNED_AFTER_MOVE]: 'Not returned after change venue',
  [ACCESSORY_REQUEST_STATUS_CHARGED_UNRETURNABLE]: 'Charged Unreturnable',
  [ACCESSORY_REQUEST_STATUS_WRITE_OFF]: 'Written Off',
};
