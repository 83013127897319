import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import questionnaireQuestions from './questionnaire-questions-reducer';
import questionnaireCategories from './questionnaire-categories-reducer';
import questionnaireAreas from './questionnaire-areas-reducer';
import staticData from './static-reducer';

export default combineReducers({
  form: formReducer,
  questionnaireQuestions,
  questionnaireCategories,
  questionnaireAreas,
  staticData,
});
