import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialGlobalState = {};

const counts = handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const countsByWeekName = oFetch(action, 'payload.countsByWeekName');
      return countsByWeekName;
    },
    [types.INCREMENT_FOR_DAY]: (state, action) => {
      const rotaDate = oFetch(action, 'payload');

      return { ...state, [rotaDate]: { ...state[rotaDate], count: oFetch(state[rotaDate], 'count') + 1 } };
    },
    [types.DECREMENT_FOR_DAY]: (state, action) => {
      const rotaDate = oFetch(action, 'payload');

      return { ...state, [rotaDate]: { ...state[rotaDate], count: oFetch(state[rotaDate], 'count') - 1 } };
    },
  },
  initialGlobalState,
);

export default counts;
