import React from 'react';
import { combineReducers } from 'redux';
import oFetch from 'o-fetch';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

import SecurityShiftRejectRequestForm from '@/components/security-shift-requests/security-shift-reject-request-form';

function RejectSecurityShiftRequest(props) {
  return (
    <SecurityShiftRejectRequestForm
      onSubmit={oFetch(props, 'onSubmit')}
      buttonText="Reject"
      buttonClass="boss-button boss-button_role_cancel boss-form__submit"
      initialValues={oFetch(props, 'rejectRequestFormInitialValues')}
    />
  );
}

export default modalRedux(combineReducers({ form: formReducer }))(RejectSecurityShiftRequest);
