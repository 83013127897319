import { z } from 'zod';
import { $BossUiTime } from '@/lib/schema-funcs';

export const $BoundaryMossHourTag = z.object({
  id: z.number().int().positive(),
  name: z.string().min(1),
  isDisabled: z.boolean(),
  disabledAt: z.union([$BossUiTime(z), z.null()]),
  disabledByUserName: z.union([z.string().min(1), z.null()]),
}).strict();

export const $MossHourTag = $BoundaryMossHourTag.extend({
  disabledAt: z.union([z.date(), z.null()]),
}).strict();

export type MossHourTag = z.infer<typeof $MossHourTag>;

export const $DetailsModalAction = z.union([
  z.literal('disable'),
  z.literal('enable'),
]);
export type DetailsModalAction = z.infer<typeof $DetailsModalAction>;

export const $DetailModalFormValues = z.object({
  action: $DetailsModalAction,
}).strict();
export type DetailsModalFormValues = z.infer<typeof $DetailModalFormValues>;

export const $CreateHourTagModalFormValues = z.object({
  name: z.string().min(1),
}).strict();

export type CreateHourTagModalFormValues = z.infer<typeof $CreateHourTagModalFormValues>;

export const DetailsModalActions = {
  disable: 'disable' as const,
  enable: 'enable' as const,
} as const;

export const $MossTagDetailsModalContentProps = z.object({
  tag: $MossHourTag,
  processing: z.boolean(),
  onClose: z.function().returns(z.void()),
  onSubmit: z.function().returns(z.void()),
}).strict();

export type MossTagDetailsModalContentProps = z.infer<typeof $MossTagDetailsModalContentProps>;

export const $IndexFilterDataMode = z.union([
  z.literal('all'),
  z.literal('enabled'),
]);

export type IndexFilterDataMode = z.infer<typeof $IndexFilterDataMode>;

export const $IndexFilterData = z.object({
  queryString: z.string(),
  mode: $IndexFilterDataMode,
}).strict();

export type IndexFilterData = z.infer<typeof $IndexFilterData>;

export const $BoundaryMossHourTagsAppData = z.object({
  indexFilterData: $IndexFilterData,
  mossHourTags: z.array($BoundaryMossHourTag),
}).strict();
export type BoundaryMossHourTagsAppData = z.infer<typeof $BoundaryMossHourTagsAppData>;

export const $MossHourTagsAppData = $BoundaryMossHourTagsAppData.extend({
  mossHourTags: z.array($MossHourTag),
}).strict();

export type MossHourTagsAppData = z.infer<typeof $MossHourTagsAppData>;

export const $SetMossHourTagAppData = z.function()
  .args(
    z.union([
      $MossHourTagsAppData,
      z.function().args($MossHourTagsAppData).returns($MossHourTagsAppData),
    ])
  ).returns(z.void());

export const $IndexFilterParams = $IndexFilterData.extend({
  forceUpdate: z.boolean(),
  searchQuery: z.string(),
  setSearchQuery: z.function().args(z.string()).returns(z.void()),
  normalizedSearchQuery: z.string(),
  setMossHourTagAppData: $SetMossHourTagAppData,
  tagCreationInProgress: z.boolean(),
}).strict();

export type IndexFilterParams = z.infer<typeof $IndexFilterParams>;

export const $MossHourTagsAppProps = $BoundaryMossHourTagsAppData.extend({
  accessToken: z.string().min(1),
}).strict();

export type MossHourTagsAppProps = z.infer<typeof $MossHourTagsAppProps>;