import React, { useState, useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import TypeIt from 'typeit-react';
import { convert } from 'html-to-text';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { bossRequestHttp } from '@/lib/request-api';
import { AskAevaButton } from './ask-aeva-button';

const aevaUtils = {
  htmlToText: convert,
  bossUtils: utils,
  safeMoment: safeMoment,
};

function textMessageToHtml(message) {
  const htmlFormat = [
    { symbol: '*', tag: 'b' },
    { symbol: '\n', tag: 'br' },
    { symbol: '_', tag: 'em' },
    { symbol: '~', tag: 'del' },
    { symbol: '`', tag: 'code' },
  ];

  htmlFormat.forEach(({ symbol, tag }) => {
    if (!message) return;

    const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
    const match = message.match(regex);
    if (!match) return;

    match.forEach(m => {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
      }
      message = message.replace(m, formatted);
    });
  });

  return message;
}

function aevaRequest(params) {
  const [onSuccess, onFailure, values, aevaSystemPromptTemplate, aevaUserPromptTemplate] = oFetch(
    params,
    'onSuccess',
    'onFailure',
    'values',
    'aevaSystemPromptTemplate',
    'aevaUserPromptTemplate',
  );
  const promptsChangedAsAdmin = oFetch(params, 'promptsChangedAsAdmin');
  const userCanSetupAeva = oFetch(params, 'userCanSetupAeva');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      const supportedKeys = ['base'];
      if (userCanSetupAeva) {
        supportedKeys.push('aevaSystemPromptTemplateErrors');
        supportedKeys.push('aevaUserPromptTemplateErrors');
      }

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys,
        });

        // If error values are arrays react rendering will break
        supportedKeys.forEach((key) => {
          if (errors[key] && !Array.isArray(errors[key])) {
            throw new Error(`key ${key} must be array but wasn't supplied: ${errors[key]}`)
          }
        });

        onFailure({
          userCanSetupAeva,
          errors,
        });
        return true;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const assistantResult = oFetch(data, 'assistantResult');
      onSuccess(assistantResult);
    },
  }).post(`/api/v1/marketing_tasks/assistant`, { values, aevaSystemPromptTemplate, aevaUserPromptTemplate });
}

AevaAssistant.defaultProps = {
  normalize: null,
  initialAevaSystemPromptTemplate: '',
  initialAevaUserPromptTemplate: '',
};

export function AevaAssistant(props) {
  const [values, initialAevaSystemPromptTemplate, initialAevaUserPromptTemplate] = oFetch(
    props,
    'values',
    'initialAevaSystemPromptTemplate',
    'initialAevaUserPromptTemplate',
  );
  const normalize = oFetch(props, 'normalize');
  const userCanSetupAeva = oFetch(props, 'userCanSetupAeva');

  const [currentAevaSystemPromptTemplate, setCurrentAevaSystemPromptTemplate] = useState(initialAevaSystemPromptTemplate);
  const [previousAevaSystemPromptTemplate, setPreviousAevaSystemPromptTemplate] = useState(initialAevaSystemPromptTemplate);
  const [currentAevaUserPromptTemplate, setCurrentAevaUserPromptTemplate] = useState(initialAevaUserPromptTemplate);
  const [previousAevaUserPromptTemplate, setPreviousAevaUserPromptTemplate] = useState(initialAevaUserPromptTemplate);

  const [baseErrors, setBaseErrors] = useState([]);
  const [aevaSystemPromptTemplateErrors, setAevaSystemPromptTemplateErrors] = useState([]);
  const [aevaUserPromptTemplateErrors, setAevaUserPromptTemplateErrors] = useState([]);
  const [requestEverMade, setRequsetEverMade] = useState(false);

  const hasBaseErrors = (baseErrors && oFetch(baseErrors, 'length') > 0) === true;
  const hasAevaSystemPromptTemplateErrors = (aevaSystemPromptTemplateErrors && oFetch(aevaSystemPromptTemplateErrors, 'length') > 0) === true;
  const hasAevaUserPromptTemplateErrors = (aevaUserPromptTemplateErrors && oFetch(aevaUserPromptTemplateErrors, 'length') > 0) === true;

  const validation = oFetch(props, 'validation');
  const frontendErrors = useMemo(() => validation(values), [values]);
  const hasFrontendErrors = oFetch(frontendErrors, 'length') > 0;
  const contentIsEligableForAdvice = !hasFrontendErrors;

  const [settingsOpened, setSettingsOpened] = useState(false);
  const [aevaAssistantOpened, setAevaAssistantOpened] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [aevaResponse, setAevaResponse] = useState('');

  useEffect(() => {
    if (aevaAssistantOpened && !userCanSetupAeva) {
      handleSendRequest();
    }
  }, [aevaAssistantOpened]);

  const promptsChanged = useMemo(()=> {
    return currentAevaSystemPromptTemplate === previousAevaSystemPromptTemplate &&
      currentAevaUserPromptTemplate === previousAevaUserPromptTemplate;
  }, [
    currentAevaSystemPromptTemplate,
    currentAevaUserPromptTemplate,
    previousAevaSystemPromptTemplate,
    previousAevaUserPromptTemplate,
  ]);
  const promptsChangedAsAdmin = requestEverMade && userCanSetupAeva && promptsChanged;

  const aevaSystemPromptTemplateAreaClassNames = cn('boss-form__textarea', {
    'boss-form__textarea_state_error': hasAevaSystemPromptTemplateErrors,
  });
  const aevaUserPromptTemplateAreaClassNames = cn('boss-form__textarea', {
    'boss-form__textarea_state_error': hasAevaUserPromptTemplateErrors,
  });

  const normalizedValues = useMemo(() => {
    if (normalize !== null) {
      return Object.entries(normalize).reduce((acc, normalizeEntry) => {
        const [normalizeKey, normalizeFunction] = normalizeEntry;
        acc[normalizeKey] = normalizeFunction(values, aevaUtils);
        return acc;
      }, {});
    } else {
      return values;
    }
  }, [values]);

  function renderErrors(errors) {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span
                key={index}
                className="boss-form__error-line"
                dangerouslySetInnerHTML={{ __html: textMessageToHtml(error) }}
              />
            );
          })}
        </p>
      </div>
    );
  }

  function toggleSettings() {
    setSettingsOpened(!settingsOpened);
  }

  function toggleAevaAssistant() {
    setAevaAssistantOpened(!aevaAssistantOpened);
  }

  function handleAssistantMessageChange(e) {
    setCurrentAevaSystemPromptTemplate(e.target.value);
  }

  function handleUserMessageChange(e) {
    setCurrentAevaUserPromptTemplate(e.target.value);
  }

  async function handleSendRequest() {
    setRequestInProgress(true);
    setAevaResponse('');
    setIsTyping(true);

    aevaRequest({
      values: normalizedValues,
      aevaSystemPromptTemplate: currentAevaSystemPromptTemplate,
      aevaUserPromptTemplate: currentAevaUserPromptTemplate,
      promptsChangedAsAdmin,
      userCanSetupAeva,
      onSuccess(messageFromAeva) {
        setBaseErrors(null);
        setAevaSystemPromptTemplateErrors(null);
        setAevaUserPromptTemplateErrors(null);

        setPreviousAevaSystemPromptTemplate(currentAevaSystemPromptTemplate);
        setPreviousAevaUserPromptTemplate(currentAevaUserPromptTemplate);
        setAevaResponse(messageFromAeva);
        setRequsetEverMade(true);
        setIsTyping(false);
        setRequestInProgress(false);
      },
      onFailure(args) {
        const errors = oFetch(args, 'errors');
        const userCanSetupAeva = oFetch(args, 'userCanSetupAeva');

        setIsTyping(false);

        const baseErrorMessages = errors.base;
        setBaseErrors(baseErrorMessages);

        if (userCanSetupAeva) {
          const {aevaSystemPromptTemplateErrors, aevaUserPromptTemplateErrors} = errors;
          setSettingsOpened(true);
          setAevaSystemPromptTemplateErrors(aevaSystemPromptTemplateErrors);
          setAevaUserPromptTemplateErrors(aevaUserPromptTemplateErrors);
        };
        setRequestInProgress(false);
      },
    });
  }

  const aevaBackgroundColorString = 'rgba(120, 217, 101, 0.2)';

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_marketing-task">
      <div className="boss-check__header">
        {!aevaAssistantOpened && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AskAevaButton
            disabled={isTyping || !contentIsEligableForAdvice}
            onClick={toggleAevaAssistant}
          >
            Ask ÆVA
          </AskAevaButton>
        </div> }
        {aevaAssistantOpened && (
          <>
            { (aevaResponse || requestInProgress) && <div>
              <div style={{backgroundColor: aevaBackgroundColorString, borderRadius: '12px', padding: '10px'}}>
                <h5 style={{fontWeight: '800'}}>ÆVA's Advice</h5>
                { hasBaseErrors && renderErrors(baseErrors) }
                {isTyping && (
                  <>
                    <em>ÆVA is thinking ...</em>
                  </>
                )}
                {aevaResponse && (
                  <>
                    <TypeIt options={{ speed: 15 }}>
                      <div dangerouslySetInnerHTML={{ __html: textMessageToHtml(aevaResponse) }} />
                    </TypeIt>
                  </>
                )}
              </div>
            </div> }
            { userCanSetupAeva && <div>
              <hr />
              <div className="boss-form__field" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  onClick={toggleSettings}
                  className="boss-button boss-form__submit boss-form__submit_adjust_single"
                  type="button"
                >
                  Settings
                </button>
                <button
                  disabled={isTyping || promptsChangedAsAdmin}
                  onClick={handleSendRequest}
                  className="boss-button boss-form__submit boss-form__submit_adjust_single"
                  type="submit"
                >
                  Send
                </button>
              </div>
              {settingsOpened && (
                <>
                  <div className="boss-form__field" style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="boss-form__label">
                      <span className="boss-form__label-text">System Prompt</span>
                    </p>
                    {hasAevaSystemPromptTemplateErrors && renderErrors(aevaSystemPromptTemplateErrors)}
                    <div className="boss-form__field">
                      <textarea
                        name="message"
                        type="text"
                        value={currentAevaSystemPromptTemplate}
                        onChange={handleAssistantMessageChange}
                        className={aevaSystemPromptTemplateAreaClassNames}
                        style={{ height: '100px', lineHeight: 1.5 }}
                      />
                    </div>
                  </div>

                  <div className="boss-form__field" style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="boss-form__label">
                      <span className="boss-form__label-text">User Prompt</span>
                    </p>
                    {hasAevaUserPromptTemplateErrors && renderErrors(aevaUserPromptTemplateErrors)}
                    <div className="boss-form__field">
                      <textarea
                        name="message"
                        type="text"
                        value={currentAevaUserPromptTemplate}
                        onChange={handleUserMessageChange}
                        className={aevaUserPromptTemplateAreaClassNames}
                        style={{ height: '200px', lineHeight: 1.5 }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>}
          </>
        )}
      </div>
    </div>
  );
}
