import React, { useMemo } from 'react';
import oFetch from 'o-fetch';

export function DevicesList(props) {
  const [devices, filtered] = oFetch(props, 'devices', 'filtered');
  const renderDeviceItem = oFetch(props, 'renderDeviceItem');
  const devicesExist = devices.length > 0;

  function renderDevices() {
    return devices.map(device => {
      const deviceId = oFetch(device, 'id');
      return React.cloneElement(renderDeviceItem(device), {
        key: deviceId,
      });
    });
  }

  if (!devicesExist) {
    if (filtered) {
      return <h3>No devices found</h3>;
    }
    return <h3>No devices exist</h3>;
  }

  return <div className="boss-board__cards">{renderDevices()}</div>;
}
