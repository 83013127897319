import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import { TemporaryItem } from './temporary-item';
import Spinner from '@/components/spinner';

export function TemporaryItemWithActions(props) {
  const [item, onEdit, onDisable] = oFetch(props, 'item', 'onEdit', 'onDisable');
  const [processing, setProcessing] = useState(false);
  const isTemporaryAi = oFetch(item, 'isTemporaryAi');
  const venuesOptions = oFetch(props, 'venuesOptions');

  async function handleDelete() {
    setProcessing(true);
    await onDisable({ temporaryPermission: item });
    setProcessing(false);
  }

  return (
    <TemporaryItem
      item={item}
      renderActions={() => {
        if (isTemporaryAi) {
          return null;
        }
        return (
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-table__actions">
                {processing && <Spinner />}
                {!processing && (
                  <Fragment>
                    <button
                      onClick={() => onEdit({ temporaryPermission: item, venuesOptions })}
                      type="button"
                      className="boss-action boss-action_role_edit boss-table__action"
                    />
                    <button
                      onClick={() => handleDelete()}
                      type="button"
                      className="boss-action boss-action_role_remove boss-table__action"
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
