import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { Fields, BossFormSelectNew } from '@/components/boss-form';

export function ManuallyAddForm(props) {
  const [onSubmit, initialValues, processing] = oFetch(props, 'onSubmit', 'initialValues', 'processing');
  const venueOptions = oFetch(props, 'venueOptions');

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, submitError, ...rest }) => {
            const disabled = submitting || processing;

            return (
              <>
                <Field
                  name={'venueId'}
                  label="Venue"
                  options={venueOptions}
                  valueKey={'id'}
                  labelKey={'name'}
                  disabled={processing}
                  component={BossFormSelectNew}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={disabled}
                    className="boss-button boss-button_role_confirm boss-form__submit"
                    type="button"
                  >
                    Grant Access
                  </button>
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
