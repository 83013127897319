import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { appRoutes } from '@/lib/legacy-routes';
import { SimpleDashboard, DashboardActions, DashboardDateSelect } from '@/components/boss-dashboards';
import confirm from '@/lib/confirm-utils';
import { BOSS_APP_TYPE, MOSS_APP_TYPE } from '@/lib/rota-date';

export default function HoursConfirmationDashboard(props) {

  const pageOptions = oFetch(props, 'pageOptions');
  const [url, pageType, date, venueId, title, appType] = oFetch(pageOptions, 'url', 'pageType', 'date', 'venueId', 'title', 'appType');

  function handleDateChange(date) {
    if (appType === BOSS_APP_TYPE) {
      window.location.href = appRoutes.hoursConfirmationDayPage({
        venueId,
        date,
      });
    } else if (appType === MOSS_APP_TYPE) {
      window.location.href = appRoutes.mossHoursConfirmationDayPage({
        venueId,
        date,
      });
    } else {
      throw new Error(`unsupported appType encountered: ${appType}`);
    }
  }

  if (pageType === 'overview') {
    return <SimpleDashboard title={`Hours overview: ${date}`} />;
  }

  const [allSecurityPeriodsAccepted, canDownloadDoorBook] = oFetch(
    pageOptions,
    'allSecurityPeriodsAccepted',
    'canDownloadDoorBook',
  );
  const doorBookButtonClassNames = cn(
    'boss-button boss-button_adjust_indicator boss-page-dashboard__button',
    { 'boss-button_role_download-door-book-complete': allSecurityPeriodsAccepted },
    { 'boss-button_role_download-door-book-in-progress': !allSecurityPeriodsAccepted },
  );
  const doorBookIndicatorClassNames = cn('boss-indicator__icon boss-indicator__icon_size_xs', {
    'boss-indicator__icon_check-bold': allSecurityPeriodsAccepted,
    'boss-indicator__icon_hour-glass': !allSecurityPeriodsAccepted,
  });

  function handleDoorBookClick(e, location) {
    e.preventDefault();
    if (allSecurityPeriodsAccepted) {
      return confirm(
        'Are all incident reports which licensing must see for this date marked as "for door book" on the incident reports page?',
        {
          actionButtonText: 'Confirm',
          title: 'Confirm Incident Reports',
          titleClassName: 'boss-modal-window_role_success',
          buttonClassName: 'boss-button_role_success',
        },
      ).then(() => {
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = location;
        a.click();
      });
    } else {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = location;
      a.click();
    }
  }

  if (pageType === 'current') {
    return (
      <SimpleDashboard title={title} className="boss-page-dashboard_page_hours-confirmation">
        <DashboardActions>
          <a href={url} className="boss-button boss-button_role_calendar boss-page-dashboard__button">
            View by date
          </a>
          {canDownloadDoorBook && (
            <a
              target="_blank"
              rel="noreferrer"
              onClick={e =>
                handleDoorBookClick(
                  e,
                  `/hours_confirmation/download_door_book_pdf?date=${date}&venue_id=${venueId}`,
                )
              }
              href={`/hours_confirmation/download_door_book_pdf?date=${date}&venue_id=${venueId}`}
              className={doorBookButtonClassNames}
            >
              Download Door Book
              <span className="boss-button__indicator">
                <span className="boss-indicator boss-indicator_context_button">
                  <span className={doorBookIndicatorClassNames} />
                </span>
              </span>
            </a>
          )}
        </DashboardActions>
      </SimpleDashboard>
    );
  }

  if (pageType === 'daily') {
    return (
      <DashboardDateSelect
        title={title}
        date={date}
        onDateChange={handleDateChange}
        className="boss-page-dashboard_page_hours-confirmation"
      >
        <DashboardActions>
          <a href={url} className="boss-button boss-button_role_calendar boss-page-dashboard__button">
            View current
          </a>
          {canDownloadDoorBook && (
            <a
              href={`/hours_confirmation/download_door_book_pdf?date=${date}&venue_id=${venueId}`}
              className={doorBookButtonClassNames}
              onClick={e =>
                handleDoorBookClick(
                  e,
                  `/hours_confirmation/download_door_book_pdf?date=${date}&venue_id=${venueId}`,
                )
              }
              target="_blank"
              rel="noreferrer"
            >
              Download Door Book
              <span className="boss-button__indicator">
                <span className="boss-indicator boss-indicator_context_button">
                  <span className={doorBookIndicatorClassNames} />
                </span>
              </span>
            </a>
          )}
        </DashboardActions>
      </DashboardDateSelect>
    );
  }
}
