import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const events = oFetch(action.payload, 'events');
      return events;
    },
    [commonTypes.ADD_EVENT]: (state, action) => {
      const event = oFetch(action.payload, 'event');
      if (event === null) {
        return state;
      }
      return [...state, event];
    },
    [commonTypes.UPDATE_EVENT]: (state, action) => {
      const event = oFetch(action.payload, 'event');
      if (event === null) {
        return state;
      }
      const id = oFetch(event, 'id');
      const stateWithoutEvent = state.filter(event => oFetch(event, 'id') !== id);
      return [...stateWithoutEvent, event];
    },
    [commonTypes.DELETE_EVENT]: (state, action) => {
      const id = oFetch(action.payload, 'id');
      return state.filter(event => oFetch(event, 'id') !== id);
    },
  },
  initialState,
);
