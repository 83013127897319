import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { SubmissionError } from 'redux-form/immutable';
import { bossRequestHttp } from '@/lib/request-api';
import { normalizeReduxFormErrors } from '@/lib/utils';
import { fromJS } from 'immutable';
import { apiRoutes, appRoutes } from '@/lib/legacy-routes';
import { BOSS_STAFF_MEMBER_TYPE, MOSS_STAFF_MEMBER_TYPE } from './constants';
import * as types from './constants';

export const initialLoad = createAction(types.INITIAL_LOAD);
export const updateOwedHour = createAction(types.UPDATE_OWED_HOUR);
export const addOwedHour = createAction(types.ADD_OWED_HOUR);
export const removeOwedHour = createAction(types.DELETE_OWED_HOUR);
export const filterAction = createAction(types.FILTER);

export const deleteOwedHour = owedHourId => dispatch => {
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      dispatch(removeOwedHour(owedHourId));
    },
  }).delete(`/api/v1/owed_hours/${owedHourId}`);
};

export const editOwedHour = args => (dispatch, getState) => {
  const values = oFetch(args, 'values');
  const setLastMossHourTagId = oFetch(args, 'setLastMossHourTagId');

  const [
    id,
    startsAt,
    endsAt,
    date,
    note,
  ] = oFetch(
    values,
    'id',
    'startsAt',
    'endsAt',
    'date',
    'note',
  );

  const stateJS = fromJS(getState()).toJS();
  const effectiveStaffMember = oFetch(stateJS, 'owedHours.effectiveStaffMember');
  const effectiveStaffMemberType = oFetch(effectiveStaffMember, 'effective_staff_type');

  const formData = new FormData();
  formData.set('startsAt', startsAt);
  formData.set('endsAt', endsAt);
  formData.set('date', date);
  formData.set('note', note);

  if (effectiveStaffMemberType == BOSS_STAFF_MEMBER_TYPE) {
    const startsAtCCTVImage = oFetch(values, 'startsAtCCTVImage');
    formData.set('startsAtCCTVImage', startsAtCCTVImage);
    const endsAtCCTVImage = oFetch(values, 'endsAtCCTVImage');
    formData.set('endsAtCCTVImage', endsAtCCTVImage);
    const cctvImageHasChanged = oFetch(values, 'cctvImageHasChanged');
    formData.set('cctvImageHasChanged', cctvImageHasChanged);
  } else if (effectiveStaffMemberType == MOSS_STAFF_MEMBER_TYPE) {
    const mossHourTagIds = oFetch(values, 'mossHourTagIds');
    formData.set('mossHourTagIds', mossHourTagIds);
  } else {
    throw new Error(`unsupported type: ${effectiveStaffMemberType}`);
  }

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        let supportedKeys = ['base', 'date', 'endsAt', 'note', 'startsAt', 'mossHourTagIds'];

        if (effectiveStaffMemberType == BOSS_STAFF_MEMBER_TYPE) {
          supportedKeys = supportedKeys + ['endsAtCCTVImage', 'startsAtCCTVImage'];
        }

        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys,
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');

      if (effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE) {
        setLastMossHourTagId((prevLastMossHourTagId) => {
          const newLastMossHourTagId = oFetch(values, 'mossHourTagIds')[0];
          if (newLastMossHourTagId !== prevLastMossHourTagId) {
            return newLastMossHourTagId || null;
          } else {
            return prevLastMossHourTagId;
          }
        });
      }

      dispatch(updateOwedHour({ id, ...data }));
    },
  }).put(`/api/v1/owed_hours/${id}`, formData);
};

export const createOwedHour = args => (dispatch, getState) => {
  const values = oFetch(args, 'values');
  const setLastMossHourTagId = oFetch(args, 'setLastMossHourTagId');

  const [startsAt, endsAt, date, note] = oFetch(values, 'startsAt', 'endsAt', 'date', 'note');
  const stateImmut = fromJS(getState());
  const stateJS = stateImmut.toJS();
  const owedHoursJs = oFetch(stateJS, 'owedHours');
  const effectiveStaffMember = oFetch(owedHoursJs, 'effectiveStaffMember');
  const effectiveStaffMemberType = oFetch(effectiveStaffMember, 'effective_staff_type');

  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');

  const formData = new FormData();
  formData.set('startsAt', startsAt);
  formData.set('endsAt', endsAt);
  formData.set('date', date);
  formData.set('note', note);

  let createOwedHourUrl = null;
  if (effectiveStaffMemberType === BOSS_STAFF_MEMBER_TYPE) {
    formData.set('startsAtCCTVImage', oFetch(values, 'startsAtCCTVImage'));
    formData.set('endsAtCCTVImage', oFetch(values, 'endsAtCCTVImage'));
    formData.set('cctvImageHasChanged', oFetch(values, 'cctvImageHasChanged'));

    createOwedHourUrl = `/api/v1/staff_members/${effectiveStaffMemberId}/create_owed_hour`;
  } else if (effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE) {
    formData.set('mossHourTagIds', oFetch(values, 'mossHourTagIds'));

    createOwedHourUrl = `/api/v1/moss_staff_members/${effectiveStaffMemberId}/create_owed_hour`;
  } else {
    throw new Error(`unsupported type: ${effectiveStaffMemberType}`);
  }

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'date',
            'endsAt',
            'endsAtCCTVImage',
            'note',
            'startsAt',
            'startsAtCCTVImage',
            'payslipDate',
            'mossHourTagIds',
          ],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');

      if (effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE) {
        setLastMossHourTagId((prevLastMossHourTagId) => {
          const newLastMossHourTagId = oFetch(values, 'mossHourTagIds')[0];
          if (newLastMossHourTagId !== prevLastMossHourTagId) {
            return newLastMossHourTagId || null;
          } else {
            return prevLastMossHourTagId;
          }
        });
      }

      dispatch(addOwedHour(data));
    },
  }).post(createOwedHourUrl, formData);
};

export const filter = (sStartDate, sEndDate, sPayslipStartDate, sPayslipEndDate) => (dispatch, getState) => {
  const stateJS = fromJS(getState()).toJS();
  const effectiveStaffMember = oFetch(stateJS, 'owedHours.effectiveStaffMember');
  const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');
  const effectiveStaffMemberType = oFetch(effectiveStaffMember, 'effective_staff_type');
  const mStartDate = sStartDate && safeMoment.uiDateParse(sStartDate);
  const mEndDate = sEndDate && safeMoment.uiDateParse(sEndDate);
  const mPayslipStartDate = sPayslipStartDate && safeMoment.uiDateParse(sPayslipStartDate);
  const mPayslipEndDate = sPayslipEndDate && safeMoment.uiDateParse(sPayslipEndDate);

  let apiGetUrl;
  let webGetUrl;
  if (effectiveStaffMemberType === BOSS_STAFF_MEMBER_TYPE) {
    apiGetUrl = apiRoutes.staffMemberProfileOwedHoursIndex.getPath({
      staffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
    webGetUrl = appRoutes.staffMemberOwedHours({
      staffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
  } else if (effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE) {
    apiGetUrl = apiRoutes.mossStaffMemberProfileOwedHoursIndex.getPath({
      mossStaffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
    webGetUrl = appRoutes.mossStaffMemberOwedHours({
      mossStaffMemberId: effectiveStaffMemberId,
      mStartDate: mStartDate,
      mEndDate: mEndDate,
      mPayslipStartDate: mPayslipStartDate,
      mPayslipEndDate: mPayslipEndDate,
    });
  } else {
    throw new Error(`unsupported type ${effectiveStaffMemberType}`);
  }

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(filterAction(data));
      window.history.pushState('state', 'title', `${webGetUrl}`);
    },
  }).get(apiGetUrl);
};
