import React, { Fragment, useRef } from 'react';
import { Field } from 'redux-form';
import oFetch from 'o-fetch';
import { BossFormSelectNew } from '@/components/boss-form';
import { SwipeTimePicker, END_DAY_TIME_TYPE, START_DAY_TIME_TYPE } from '@/components/time-picker';

function HeadDoorTimeInterval(props) {
  const startsAtControlRef = useRef(null);
  const endsAtControlRef = useRef(null);

  const rotaDate = oFetch(props, 'rotaDate');
  const specialPayRateSecurityHoursFormProps = oFetch(oFetch(props, 'specialPayRateSecurityHours'), oFetch(props, 'index'));

  const startsAtFormProps = oFetch(specialPayRateSecurityHoursFormProps, 'startsAt');
  const endsAtFormProps = oFetch(specialPayRateSecurityHoursFormProps, 'endsAt');
  const baseErrorFormProps = oFetch(specialPayRateSecurityHoursFormProps, 'base');
  const sStartsAt = oFetch(startsAtFormProps, 'input.value');
  const sEndsAt = oFetch(endsAtFormProps, 'input.value');

  const dStartsAt = new Date(sStartsAt);
  const dEndsAt = new Date(sEndsAt);

  const onStartsAtChange = (dValue) => {
    startsAtFormProps.input.onChange(dValue.toISOString());
  };
  const onEndsAtChange = (dValue) => {
    endsAtFormProps.input.onChange(dValue.toISOString());
  };

  function onStartsAtTabKeyDown() {
    startsAtControlRef?.current?.commitAndClosePopover();
    endsAtControlRef?.current?.openPopover();
  }

  function onEndsAtTabKeyDown() {
    endsAtControlRef?.current?.commitAndClosePopover();
  }

  const { deleteRenderer } = props;
  const specialSecurityPayrates = oFetch(props, 'specialSecurityPayrates');
  const specialSecurityPayrate = props.specialPayRateSecurityHours[props.index].securityPayRateId;

  const endsAtControl = (
    <SwipeTimePicker
      controlRef={endsAtControlRef}
      value={dEndsAt}
      rotaDate={rotaDate}
      dayTimeType={END_DAY_TIME_TYPE}
      minutesStep={1}
      onChange={onEndsAtChange}
      onTabKeyDown={onEndsAtTabKeyDown}
    />
  );
  const startsAtControl = (
    <SwipeTimePicker
      controlRef={startsAtControlRef}
      value={dStartsAt}
      rotaDate={rotaDate}
      dayTimeType={START_DAY_TIME_TYPE}
      minutesStep={1}
      onChange={onStartsAtChange}
      onTabKeyDown={onStartsAtTabKeyDown}
    />
  );
  return (
    <Fragment>
      {baseErrorFormProps.meta.error && (
        <div className="boss-time-shift__error boss-time-shift__error_role_general">
          <p className="boss-time-shift__error-text boss-time-shift__error-text_role_title">
            Something went wrong!
          </p>
          {baseErrorFormProps.meta.error.map((error, index) => {
            return (
              <p key={index} className="boss-time-shift__error-text">
                {error}
              </p>
            );
          })}
        </div>
      )}
      <div className="boss-time-shift__log boss-time-shift__log_layout_hdh">
        <div className="boss-time-shift__group">
          <div className={`boss-time-shift__time`}>
            <div className="boss-time-shift__interval">
              <div className="boss-time-shift__hours">
                <p className="boss-time-shift__label">
                  <span className="boss-time-shift__label-text">Start</span>
                </p>
                <div
                  className={`boss-time-shift__select ${
                    startsAtFormProps.meta.error ? 'boss-time-shift__select_state_error' : ''
                  }`}
                >
                  {startsAtControl}
                </div>
              </div>
              <div className="boss-time-shift__delimiter" />
              <div className="boss-time-shift__hours">
                <p className="boss-time-shift__label">
                  <span className="boss-time-shift__label-text">End</span>
                </p>
                <div
                  className={`boss-time-shift__select ${
                    endsAtFormProps.meta.error ? 'boss-time-shift__select_state_error' : ''
                  }`}
                >
                  {endsAtControl}
                </div>
              </div>
            </div>
            {startsAtFormProps.meta.error || endsAtFormProps.meta.error ? (
              <div className="boss-time-shift__error" style={{ marginTop: 10 }}>
                {startsAtFormProps.meta.error
                  ? startsAtFormProps.meta.error.map((error, index) => (
                    <p key={index} className="boss-time-shift__error-text">
                      {error}
                    </p>
                  ))
                  : null}
                {endsAtFormProps.meta.error
                  ? endsAtFormProps.meta.error.map((error, index) => (
                    <p key={index} className="boss-time-shift__error-text">
                      {error}
                    </p>
                  ))
                  : null}
              </div>
            ) : null}
          </div>
          <Field
            name={specialSecurityPayrate.input.name}
            label="Rate"
            fieldClassName="boss-time-shift__rate"
            component={BossFormSelectNew}
            valueKey="id"
            labelKey="name"
            options={specialSecurityPayrates}
          />
        </div>
        {deleteRenderer()}
      </div>
    </Fragment>
  );
}

HeadDoorTimeInterval.defaultProps = {
  className: '',
};

export default HeadDoorTimeInterval;