import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import ContentWrapper from '@/components/content-wrapper';
import { UsersDashboard, UsersFilterForm, UsersList, UserItem, UserMobileItem } from './components';
import { usersHooks } from './users-hooks';

export function Users(props) {
  const { handleFilterChange, users, openAppDetailsModal } = usersHooks({
    initialUsers: oFetch(props, 'users'),
  });

  function renderFilter() {
    return <UsersFilterForm onSubmit={handleFilterChange} />;
  }

  function renderUserItem(user) {
    return <UserItem user={user} onAppDetailsClick={openAppDetailsModal} />;
  }

  function renderUserMobileItem(user) {
    return <UserMobileItem user={user} onAppDetailsClick={openAppDetailsModal} />;
  }

  return (
    <Fragment>
      <UsersDashboard renderFilter={renderFilter} />
      <ContentWrapper>
        <UsersList items={users} renderItem={renderUserItem} renderMobileItem={renderUserMobileItem} />
      </ContentWrapper>
    </Fragment>
  );
}
