import d3 from 'd3';
import oFetch from 'o-fetch';

var scharts = document.getElementsByClassName('boss-chart-score');

function ScoreChart(placeholderName, configuration) {
  this.placeholderName = placeholderName;

  var self = this; // for internal d3 functions

  this.configure = function (configuration) {
    this.config = configuration;

    this.config.size = oFetch(configuration, 'size');

    this.config.radius = oFetch(this.config, 'size') * 0.5;
    this.config.cx = oFetch(this.config, 'size') / 2;
    this.config.cy = oFetch(this.config, 'size') / 1.7;

    this.config.thresholdFailColor = '#f4c6c6';
    this.config.thresholdSuccessColor = '#c5e5c6';

    this.config.passFailColor = '#de7575';
    this.config.passSuccessColor = '#5cac41';

    this.config.current = oFetch(this.config, 'current');
    this.config.total = oFetch(this.config, 'total');
  };

  this.render = function () {
    var chartContainer = d3.select('#' + oFetch(this.config, 'id'));
    var chart = chartContainer.append('div').attr('class', 'boss-chart');
    var chartInner = chart.append('div').attr('class', 'boss-chart__inner');

    const passPercentage = oFetch(this.config, 'passPercentage');

    // Create chart svg
    this.graph = chartInner
      .append('svg:svg')
      .attr('class', 'boss-chart__graph')
      .attr('width', oFetch(this.config, 'size'))
      .attr('height', oFetch(this.config, 'size'));

    // Create chart threshold scale
    this.graph
      .append('svg:path')
      .style('fill', oFetch(this.config, 'thresholdFailColor'))
      .attr(
        'd',
        d3.svg
          .arc()
          .startAngle(this.currentToRadians(0))
          .endAngle(this.currentToRadians(oFetch(this.config, 'thresholdPercentage')))
          .innerRadius(0.7 * oFetch(this.config, 'radius'))
          .outerRadius(0.85 * oFetch(this.config, 'radius')),
      )
      .attr('transform', function () {
        return 'translate(' + self.config.cx + ', ' + self.config.cy + ') rotate(220)';
      });

    this.graph
      .append('svg:path')
      .style('fill', oFetch(this.config, 'thresholdSuccessColor'))
      .attr(
        'd',
        d3.svg
          .arc()
          .startAngle(this.currentToRadians(oFetch(this.config, 'thresholdPercentage')))
          .endAngle(this.currentToRadians(100))
          .innerRadius(0.7 * oFetch(this.config, 'radius'))
          .outerRadius(0.85 * oFetch(this.config, 'radius')),
      )
      .attr('transform', function () {
        return 'translate(' + self.config.cx + ', ' + self.config.cy + ') rotate(220)';
      });

    // Create chart progress bar
    this.graph
      .append('svg:path')
      .style(
        'fill',
        oFetch(this.config, 'passPercentage') < oFetch(this.config, 'thresholdPercentage')
          ? oFetch(this.config, 'passFailColor')
          : oFetch(this.config, 'passSuccessColor'),
      )
      .attr(
        'd',
        d3.svg
          .arc()
          .startAngle(this.currentToRadians(0))
          .endAngle(this.currentToRadians(oFetch(this.config, 'passPercentage')))
          .innerRadius(0.85 * oFetch(this.config, 'radius'))
          .outerRadius(1 * oFetch(this.config, 'radius')),
      )
      .attr('transform', function () {
        return 'translate(' + self.config.cx + ', ' + self.config.cy + ') rotate(220)';
      });

    // Create chart graph information
    var chartInfoContainer = this.graph.append('svg:g').attr('class', 'boss-chart__info');
    var chartInfoNumber = chartInfoContainer
      .append('svg:text')
      .attr('class', 'boss-chart__info-number');

    var numberFontSize = Math.round(oFetch(this.config, 'size') / 5);

    chartInfoNumber
      .text(passPercentage + '%')
      .attr('x', oFetch(this.config, 'cx'))
      .attr('y', oFetch(this.config, 'cy') + 15)
      .attr('text-anchor', 'middle')
      .style('font-size', numberFontSize + 'px')
      .style('line-height', '1')
      .style('font-weight', '600');

    if (passPercentage < oFetch(this.config, 'thresholdPercentage')) {
      chartInfoNumber.style('fill', oFetch(this.config, 'passFailColor'));
    } else {
      chartInfoNumber.style('fill', oFetch(this.config, 'passSuccessColor'));
    }

    // Create chart score
    var scoreContainer = chart.append('div').attr('class', 'boss-chart__score');
    var scoreLabel = scoreContainer.append('p').attr('class', 'boss-chart__score-label');
    var scoreValue = scoreContainer.append('p').attr('class', 'boss-chart__score-value');
    var scoreCurrent = scoreValue.append('span').attr('class', 'boss-chart__score-current');
    var scoreDelimiter = scoreValue.append('span').text(' / ');
    var scoreTotal = scoreValue.append('span').attr('class', 'boss-chart__score-total');

    scoreLabel.text('Score');

    scoreCurrent.text(oFetch(this.config, 'current'));

    scoreTotal.text(oFetch(this.config, 'total'));
  };

  this.currentToDegrees = function (value) {
    return (value / 100) * 280;
  };

  this.currentToRadians = function (value) {
    return (this.currentToDegrees(value) * Math.PI) / 180;
  };

  // initialization
  this.configure(configuration);
}

function newScoreChart(params) {
  var config = {
    size: parseInt(oFetch(params, 'size')),
    id: oFetch(params, 'id'),
    current: parseInt(oFetch(params, 'current')),
    total: parseInt(oFetch(params, 'total')),
    thresholdPercentage: parseFloat(oFetch(params, 'thresholdPercentage')),
    passPercentage: parseFloat(oFetch(params, 'passPercentage')),
  };

  var createScoreChart = new ScoreChart(oFetch(config, 'id'), config);
  createScoreChart.render();
}

function createScoreCharts() {
  Array.prototype.forEach.call(scharts, function (schart) {
    const data = oFetch(schart, 'dataset');
    newScoreChart(data);
  });
}

export function initializeScoreCharts() {
  createScoreCharts();
}
