import React, { useState, useRef } from 'react';
import oFetch from 'o-fetch';
import useOnClickOutside from 'use-onclickoutside';

export function Profile(props) {
  const [user, prefix, signOutPath] = oFetch(props, 'user', 'prefix', 'signOutPath');
  const [showDropdown, setShowDropDown] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, closeDropdown);

  function closeDropdown() {
    setShowDropDown(false);
  }

  function toggleDropwdown() {
    setShowDropDown(!showDropdown);
  }

  return (
    <div ref={ref}>
      <button
        className={`${prefix}-page-header__action ${prefix}-page-header__action_role_profile`}
        onClick={toggleDropwdown}
      >
        Profile
      </button>
      {showDropdown && (
        <div
          className={`${prefix}-page-header__dropdown ${prefix}-page-header__dropdown_role_profile ${prefix}-page-header__dropdown_state_opened`}
        >
          <nav className={`${prefix}-menu`}>
            <p className={`${prefix}-menu__label ${prefix}-menu__label_role_user`}>{oFetch(user, 'name')}</p>
            <a
              href={signOutPath}
              className={`${prefix}-menu__link ${prefix}-menu__link_role_logout`}
              data-method="delete"
            >
              Logout
            </a>
          </nav>
        </div>
      )}
    </div>
  );
}
