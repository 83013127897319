import React, { useState, useMemo } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function AlertsList(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [title, alerts, level, levels, perPage] = oFetch(
    props,
    'title',
    'alerts',
    'level',
    'levels',
    'perPage',
  );

  const sortedAlerts = useMemo(() => {
    return [...alerts].sort((a, b) => {
      const aDate = oFetch(a, 'createdAt');
      const bDate = oFetch(b, 'createdAt');
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });
  }, [alerts]);

  function handleToggle() {
    setIsOpened(!isOpened);
  }

  function handleLoadMore() {
    setCurrentPage(currentPage + 1);
  }

  function getPaginatedAlerts(alerts, perPage) {
    return alerts.slice(0, currentPage * perPage);
  }

  function renderNoAlerts() {
    return <p className="boss-board__text-placeholder">There are no alerts to display</p>;
  }

  function renderAlerts(alerts) {
    if (alerts.length === 0) {
      return renderNoAlerts();
    }
    const alertRenderer = oFetch(props, 'alertRenderer');
    return alerts.map(alert => {
      return React.cloneElement(alertRenderer(alert), {
        key: oFetch(alert, 'id'),
      });
    });
  }

  const arrowClass = cn('boss-board__switch', { 'boss-board__switch_state_opened': isOpened });
  const boxClass = cn('boss-board boss-board_context_stack', {
    'boss-board_role_venue-alert-levels-red': level === levels[0],
    'boss-board_role_venue-alert-levels-amber': level === levels[1],
  });
  const alertsCount = sortedAlerts.length;
  const paginatedAlerts = getPaginatedAlerts(sortedAlerts, perPage);
  const hasLoadMore = paginatedAlerts.length < alertsCount;

  return (
    <section className={boxClass}>
      <header className="boss-board__header">
        <h2 className="boss-board__title">
          <span className="boss-board__title-text">{title}</span>
          <span className="boss-board__title-indicator">
            <span className="boss-indicator boss-indicator_level_red">
              <span className="boss-indicator__marker">{alertsCount}</span>
            </span>
          </span>
        </h2>
        <div className="boss-board__button-group">
          <button type="button" onClick={handleToggle} className={arrowClass} />
        </div>
      </header>
      <Collapse isOpened={isOpened} className="boss-board__content" style={{ display: 'block' }}>
        <div className="boss-board__inner">
          <div className="boss-board__cards">{renderAlerts(paginatedAlerts)}</div>
          {hasLoadMore && (
            <div className="boss-board__actions">
              <button onClick={handleLoadMore} className="boss-button boss-button_primary-light">
                Load More
              </button>
            </div>
          )}
        </div>
      </Collapse>
    </section>
  );
}
