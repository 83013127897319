import React, { useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { DashboardActions } from '@/components/boss-dashboards';
import ContentWrapper from '@/components/content-wrapper';
import DashboardWeekSelect from '@/components/boss-dashboards/dashboard-week-select';
import { openContentModal } from '@/components/modals';
import { appRoutes } from '@/lib/legacy-routes';
import AddSecurityShiftRequest from './add-security-shift-request';
import SecurityShiftRequestCard from '@/components/security-shift-requests/security-shift-request-card';
import { SecurityShiftRequestsVenueList } from './security-shift-requests-venue-list';
import { SecurityShiftRequestsVenueItem } from './security-shift-requests-venue-item';
import SecurityShiftRequestList from './security-shift-request-list';
import SecurityShiftRequestItem from './security-shift-request-item';
import EditSecurityShiftRequest from './edit-security-shift-request';
import WeekFilter from './requests-week-filter';
import Spinner from '@/components/spinner';
import { RotaDate, BOSS_APP_TYPE, getMinutesBetween } from "@/lib/rota-date";

function SecurityShiftRequestsPage(props) {
  const counts = oFetch(props, 'counts');
  const [fetching, setFetching] = useState(false);

  function handleEditRequest(hideModal, values) {
    const editSecurityShiftRequest = oFetch(props, 'editSecurityShiftRequest');
    return editSecurityShiftRequest(values).then(() => {
      hideModal();
    });
  }

  function handleOpenEditSecurityShiftRequest(securityShiftRequest) {
    const id = oFetch(securityShiftRequest, 'id');
    const startsAt = oFetch(securityShiftRequest, 'startsAt');
    const endsAt = oFetch(securityShiftRequest, 'endsAt');
    const venueId = oFetch(securityShiftRequest, 'venueId');
    const note = oFetch(securityShiftRequest, 'note');
    const accessibleVenues = oFetch(props, 'accessibleVenues');
    const mStartsAt = safeMoment.iso8601Parse(startsAt);
    const mEndsAt = safeMoment.iso8601Parse(endsAt);
    const appType = BOSS_APP_TYPE;
    const rotaDate = RotaDate.mFromTime({ mTime: mStartsAt, appType });
    const startMinutes = getMinutesBetween({
      dStartTime: rotaDate.startTime(),
      dEndTime: mStartsAt.toDate(),
    });
    const endMinutes = getMinutesBetween({
      dStartTime: rotaDate.startTime(),
      dEndTime: mEndsAt.toDate(),
    });
    const editRequestFormInitialValues = {
      startsAt: startMinutes,
      endsAt: endMinutes,
      venueId,
      note,
      date: rotaDate.mCalendarDate(),
      id,
    };
    openContentModal({
      submit: handleEditRequest,
      config: { title: 'Edit Shift Request' },
      props: { editRequestFormInitialValues, accessibleVenues, appType },
    })(EditSecurityShiftRequest);
  }

  function handleDateChage({ startUIDate }) {
    location.href = appRoutes.securityShiftRequests({
      startDate: startUIDate,
    });
  }

  function handleDeleteSecurityShiftRequest(id) {
    const deleteSecurityShiftRequestAction = oFetch(props, 'deleteSecurityShiftRequest');

    return deleteSecurityShiftRequestAction(id);
  }

  function handleAddNewRequest(hideModal, values) {
    return oFetch(
      props,
      'addSecurityShiftRequest',
    )(values).then(() => {
      hideModal();
    });
  }

  function handleOpenAddNewRequest() {
    const date = oFetch(props, 'date');
    const accessibleVenues = oFetch(props, 'accessibleVenues');
    openContentModal({
      submit: handleAddNewRequest,
      config: { title: 'Add new shift request' },
      props: { date: safeMoment.parse(date, 'DD-MM-YYYY'), accessibleVenues },
    })(AddSecurityShiftRequest);
  }

  async function handleChangeURLDate(sDate) {
    window.history.pushState('state', 'title', `/security-shift-requests/${sDate}`);
    setFetching(true);
    await props.changeWeekDay({ sChosenDate: sDate });
    setFetching(false);
  }

  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');
  const pendingSecurityShiftRequests = oFetch(props, 'pendingSecurityShiftRequests');
  const completedSecurityShiftRequests = oFetch(props, 'completedSecurityShiftRequests');
  const canCreate = oFetch(props, 'canCreate');
  const sChosenDate = oFetch(props, 'sChosenDate');
  const accessibleVenues = oFetch(props, 'accessibleVenues');

  return (
    <div>
      <DashboardWeekSelect
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChage}
        title="Security Shift Requests"
      >
        {canCreate && (
          <DashboardActions>
            <button
              onClick={handleOpenAddNewRequest}
              type="button"
              className="boss-button boss-button_role_add boss-page-dashboard__button"
            >
              Add New
            </button>
          </DashboardActions>
        )}
      </DashboardWeekSelect>
      <ContentWrapper>
        <WeekFilter sDate={sChosenDate} disabled={fetching} onChange={handleChangeURLDate} weekDates={counts} />
        {!fetching && (
          <Fragment>
            <SecurityShiftRequestCard title="Pending">
              <SecurityShiftRequestsVenueList
                grouppedList={pendingSecurityShiftRequests}
                itemRender={params => {
                  const [venueName, items] = oFetch(params, 'venueName', 'items');
                  return (
                    <SecurityShiftRequestsVenueItem
                      venueName={venueName}
                      items={items}
                      requestsListRender={items => {
                        return (
                          <SecurityShiftRequestList
                            securityShiftRequests={items}
                            itemRenderer={securityShiftRequest => {
                              return (
                                <SecurityShiftRequestItem
                                  onOpenEditSecurityShiftRequest={() =>
                                    handleOpenEditSecurityShiftRequest(securityShiftRequest)
                                  }
                                  onDeleteSecurityShiftRequest={() =>
                                    handleDeleteSecurityShiftRequest(oFetch(securityShiftRequest, 'id'))
                                  }
                                  securityShiftRequest={securityShiftRequest}
                                  venue={accessibleVenues.find(
                                    venue => oFetch(venue, 'id') === oFetch(securityShiftRequest, 'venueId'),
                                  )}
                                />
                              );
                            }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </SecurityShiftRequestCard>
            <SecurityShiftRequestCard isCompleted title="Completed">
              <SecurityShiftRequestsVenueList
                grouppedList={completedSecurityShiftRequests}
                itemRender={params => {
                  const [venueName, items] = oFetch(params, 'venueName', 'items');
                  return (
                    <SecurityShiftRequestsVenueItem
                      venueName={venueName}
                      items={items}
                      requestsListRender={items => {
                        return (
                          <SecurityShiftRequestList
                            securityShiftRequests={items}
                            itemRenderer={securityShiftRequest => {
                              return (
                                <SecurityShiftRequestItem
                                  onOpenEditSecurityShiftRequest={() =>
                                    handleOpenEditSecurityShiftRequest(securityShiftRequest)
                                  }
                                  onDeleteSecurityShiftRequest={() =>
                                    handleDeleteSecurityShiftRequest(oFetch(securityShiftRequest, 'id'))
                                  }
                                  securityShiftRequest={securityShiftRequest}
                                  venue={accessibleVenues.find(
                                    venue => oFetch(venue, 'id') === oFetch(securityShiftRequest, 'venueId'),
                                  )}
                                />
                              );
                            }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </SecurityShiftRequestCard>
          </Fragment>
        )}
        {fetching && <Spinner />}
      </ContentWrapper>
    </div>
  );
}

export default SecurityShiftRequestsPage;
