import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { QRCode } from 'react-qr-svg';

const AppGuidDetailsListItem = props => {
  let textValue = null;
  const item = oFetch(props, 'item');
  const guid = oFetch(item, 'guid');
  const hasGuid = oFetch(item, 'hasGuid');

  if(!guid && hasGuid) {
    textValue = '[hidden]';
  } else if (!guid) {
    textValue = 'N/A';
  }

  const name = oFetch(item, 'name');

  const { labelClass, valueClass } = props;
  if (typeof value === 'function') {
    value = value();
  }
  const labelClassNames = cn('boss-details__label', { [labelClass]: !!labelClass });
  const valueClassNames = cn('boss-details__value', { [valueClass]: !!valueClass });
  return (
    <li className="boss-details__item">
      <div className={labelClassNames}>{name}</div>
      { textValue && <div className={valueClassNames}>{textValue}</div> }

      { !textValue && <div className={valueClassNames}><QRCode value={guid} style={{maxWidth: '200px'}}/></div> }
    </li>
  );
};

export default AppGuidDetailsListItem;
