import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import { Row, BasicCell, TextInfoCell, HeaderCell, Table, ComparisonInfoCell } from './table';

export function AccountsContent(props) {
  const modulrCompanies = oFetch(props, 'modulrCompanies');
  const weekStartDate = oFetch(props, 'weekStartDate');

  function modulrPayrollCompanyBulkPaymentsDownloadUrl(props) {
    const modulrPayrollCompany = oFetch(props, "modulrPayrollCompany");
    const modulrPayrollCompanyId = oFetch(modulrPayrollCompany, "modulrPayrollCompanyId");
    const weekStartDate = oFetch(props, 'weekStartDate');
    const mWeekStartDate = safeMoment.uiDateParse(weekStartDate);

    return appRoutes.modulrPayrollCompanyBulkPaymentsDownload({ modulrPayrollCompanyId, mWeekStartDate });
  }

  function renderCompanyBulkDownloadLink(props) {
    const modulrPayrollCompany = oFetch(props, "modulrPayrollCompany");
    const payableFinanceReportsWithPayslipAndBankDetailsCount = oFetch(modulrPayrollCompany, "payableFinanceReportsWithPayslipAndBankDetailsCount");
    const canDownloadBulkPaymentsFile = oFetch(modulrPayrollCompany, "canDownloadBulkPaymentsFile");

    return (payableFinanceReportsWithPayslipAndBankDetailsCount > 0) && canDownloadBulkPaymentsFile;
  }

  function renderRows() {
    return modulrCompanies.map(modulrPayrollCompany => {
      const [
        name,
        totalNetWagesCents,
        payableFinanceReportsWithPayslipCount,
        payableFinanceReportsWithPayslipAndBankDetailsCount,
      ] = oFetch(
        modulrPayrollCompany,
        'name',
        'totalNetWagesCents',
        'payableFinanceReportsWithPayslipCount',
        'payableFinanceReportsWithPayslipAndBankDetailsCount',
      );

      return (
        <Row key={name}>
          <TextInfoCell labelText="Company Name" text={name} />
          <ComparisonInfoCell labelText="Setup for Payment" value1={payableFinanceReportsWithPayslipAndBankDetailsCount} value2={payableFinanceReportsWithPayslipCount} />
          <TextInfoCell labelText="Total Net" text={utils.moneyFormat(totalNetWagesCents / 100.0)} />
          <BasicCell>
            {renderCompanyBulkDownloadLink({ modulrPayrollCompany }) && <div className="boss-table__info">
              <div className="boss-table__actions">
                <a className="boss-button boss-button_type_extra-small boss-button_role_download boss-table__action" href={modulrPayrollCompanyBulkPaymentsDownloadUrl({ modulrPayrollCompany, weekStartDate })}>Download Payments File</a>
              </div>
            </div>}
          </BasicCell>
        </Row>
      );
    });
  }

  return (
    <Table className="boss-table_page_modulr-reports-accounts">
      <Row>
        <HeaderCell>Company Name</HeaderCell>
        <HeaderCell>Setup For Payment</HeaderCell>
        <HeaderCell>Total Net</HeaderCell>
        <HeaderCell />
      </Row>
      {renderRows()}
    </Table>
  );
}
