import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import AsyncButton from 'react-async-button';
import { useDispatch } from 'react-redux';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { useMarketingTaskModals } from '../../marketing-task-modals';
import * as actions from '../../redux/actions';
import { NeedAttentionQuestionList } from './need-attention-question-list';

export function ReviewTaskItem(props) {
  const { openTaskDetailsModal } = useMarketingTaskModals();
  const dispatch = useDispatch();

  const task = oFetch(props, 'task');
  const [
    id,
    title,
    venueName,
    deadlineDate,
    isUpForGrabs,
    isRejected,
    assignedUser,
    isAssignedOnMe,
    isCompleted,
    requests,
    isDeadline,
    hasUnansweredRequest,
    hasRequiredReviewRequest,
    isEmergency,
    createdAt,
  ] = oFetch(
    task,
    'id',
    'title',
    'venueName',
    'deadlineDate',
    'isUpForGrabs',
    'isRejected',
    'assignedUser',
    'isAssignedOnMe',
    'isCompleted',
    'requests',
    'isDeadline',
    'hasUnansweredRequest',
    'hasRequiredReviewRequest',
    'isEmergency',
    'createdAt',
  );

  const containerClassNames = cn('boss-check boss-check_role_panel boss-check_page_marketing-task', {
    'boss-check_state_alert': isRejected,
    'boss-check_state_success': isCompleted,
  });
  const deadlineDateClassName = cn('boss-check__text', {
    'boss-check__text_role_date-alert': isDeadline,
    'boss-check__text_role_date': !isDeadline,
  });
  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatWithTime());

  function handleGrabTask() {
    return dispatch(actions.grabMarketingTask({ marketingTaskId: id }));
  }

  return (
    <div className={containerClassNames}>
      <div className="boss-check__header">
        {isEmergency && (
          <div className="boss-check__header-info">
            <p className="boss-button boss-button_type_ultra-small boss-button_role_exclamation">Emergency</p>
          </div>
        )}
        <div className="boss-check__header-info">
          <h3 className="boss-check__title">{title}</h3>
          <div className="boss-check__header-statuses">
            {hasUnansweredRequest && (
              <p className="boss-button boss-button_type_ultra-small boss-button_role_new-request boss-button_type_no-behavior boss-check__header-status">
                New Request
              </p>
            )}
            {hasRequiredReviewRequest && (
              <p className="boss-button boss-button_type_ultra-small boss-button_role_new-answer boss-button_type_no-behavior boss-check__header-status">
                New Answer
              </p>
            )}
            {isRejected && (
              <p className="boss-button boss-button_type_ultra-small boss-button_role_alert boss-button_type_no-behavior boss-check__header-status">
                Rejected
              </p>
            )}
            {isCompleted && (
              <p className="boss-button boss-button_type_ultra-small boss-button_role_ok boss-button_type_no-behavior boss-check__header-status">
                Completed
              </p>
            )}
            {isCompleted && !isAssignedOnMe && (
              <p className="boss-button boss-button_type_ultra-small boss-button_role_alert boss-button_type_no-behavior boss-check__header-status">
                Requiring Review
              </p>
            )}
          </div>
        </div>
        <div className="boss-check__header-info">
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_venue">{venueName}</p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className={deadlineDateClassName}>{deadlineDate}</p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_date">Created at: {formattedCreatedAt}</p>
            </div>
            {assignedUser && (
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text boss-check__text_role_user">
                  {oFetch(assignedUser, 'fullName')}
                </p>
              </div>
            )}
          </div>
          <div className="boss-check__header-actions">
            {isUpForGrabs && (
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-check__header-action"
                text="Grab"
                pendingText="Grabbing ..."
                onClick={handleGrabTask}
              />
            )}
            <button
              onClick={() => openTaskDetailsModal(task, actions.getMarketingTaskDetails)}
              className="boss-button boss-button_type_ultra-small boss-button_role_view-details-light boss-check__header-action"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
      <NeedAttentionQuestionList requests={requests} />
    </div>
  );
}
