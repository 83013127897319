import React from 'react';
import { Dashboard, FeatureRow, FeaturesTable } from './components';
import ContentWrapper from '@/components/content-wrapper';
import { useWhatsappFeatures } from './use-whatsapp-features';
import { useModal } from '@/components/hooks-components/modals';
import { WhatsappFeatureAssignmentForm, WhatsappFeatureAssignmentFormValues } from './components/forms/whatsapp-feature-assignment-form';
import { WhatsappFeatureAssignment } from './types';
import oFetch from 'o-fetch';

export function WhatsappFeatures(props: any) {
  const { whatsappFeatures, whatsappInstanceOptions, updateWhatsappFeatureAssignments } = useWhatsappFeatures(props);
  const { openProcessingModal } = useModal();

  function openEditWhatsappFeatureAssignmentModal(whatsappFeatureAssignment: WhatsappFeatureAssignment) {
    const whatsappFeatureAssignmentId = oFetch(whatsappFeatureAssignment, 'id');
    return openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: WhatsappFeatureAssignmentFormValues) {
        setProcessing(true);
        return updateWhatsappFeatureAssignments({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'Edit Whatsapp Feature Assignment'
      },
      props: {
        whatsappInstanceOptions,
        initialValues: {
          whatsappFeatureAssignmentId,
          mainWhatsappInstanceId: whatsappFeatureAssignment.mainWhatsappInstance ? whatsappFeatureAssignment.mainWhatsappInstance.id : null,
          fallbackWhatsappInstanceId: whatsappFeatureAssignment.fallbackWhatsappInstance ? whatsappFeatureAssignment.fallbackWhatsappInstance.id : null,
        }
      },
      ModalContent: WhatsappFeatureAssignmentForm
    });
  }

  return (
    <>
      <Dashboard />
      <ContentWrapper>
        <FeaturesTable
          whatsappFeatures={whatsappFeatures}
          renderItem={(whatsappFeature) => (
            <FeatureRow
              whatsappFeature={whatsappFeature}
              onEdit={openEditWhatsappFeatureAssignmentModal}
            />
          )}
        />
      </ContentWrapper>
    </>
  );
}
