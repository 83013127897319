import React, { Component } from 'react';
import oFetch from 'o-fetch';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';
import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export default class HistoryFilter extends Component {
  handleFilterChange = datesRange => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    const startsAt = oFetch(datesRange, 'startDate');
    const endsAt = oFetch(datesRange, 'endDate');
    onSubmit({
      startsAt: safeMoment.iso8601Parse(startsAt).format(utils.commonDateFormat),
      endsAt: safeMoment.iso8601Parse(endsAt).format(utils.commonDateFormat),
    });
  };
  render() {
    const [filter, staffMemberId] = oFetch(this.props, 'filter', 'staffMemberId');
    const startsAt = oFetch(filter, 'startsAt');
    const endsAt = oFetch(filter, 'endsAt');
    return (
      <div className="boss-form">
        <div className="boss-form__group boss-form__group_position_last">
          <h3 className="boss-form__group-title">Filter</h3>
          <div className="boss-form__row boss-form__row_position_last">
            <div className="boss-form__field boss-form__field boss-form__field_layout_max">
              <p className="boss-form__label">
                <span className="boss-form__label-text">Revision date</span>
              </p>
              <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
                <BossDateRangePicker
                  startDateId="startDate"
                  endDateId="endDate"
                  startDate={startsAt ? safeMoment.uiDateParse(startsAt) : null}
                  endDate={endsAt ? safeMoment.uiDateParse(endsAt) : null}
                  onApply={this.handleFilterChange}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label boss-form__field_justify_mobile-center">
              <a
                href={appRoutes.staffMemberHistory({ staffMemberId, startsAt, endsAt })}
                className="boss-button boss-form__submit"
                type="button"
              >
                Update
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
