import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../../profile-wrapper/constants';
const initialState = null;

export default handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      return oFetch(action.payload, 'sBeganChanges');
    },
  },
  initialState,
);
