import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';

export function markCompletedAction(params) {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const weekStart = oFetch(params, 'weekStart');
  const onSuccess = oFetch(params, 'onSuccess');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
    },
  }).post(`/api/v1/security_finance_reports/${weekStart}/complete`, {
    staffMemberId,
  });
}
