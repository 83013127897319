import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';
import * as c from '../../constants';

const initialState = {
  currentStep: 1,
  stepsValidation: Object.keys(c.STEPS).reduce((acc, key) => {
    return {
      ...acc,
      [key]: { hasSyncErrors: false, submitted: false, hasAsyncErrors: false },
    };
  }, {}),
};

export default handleActions(
  {
    [t.CHANGE_STEP]: (state, action) => {
      return {
        ...state,
        currentStep: action.payload,
      };
    },
    '@@redux-form/SET_SUBMIT_FAILED': (state, action) => {
      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [state.currentStep]: {
            ...state.stepsValidation[state.currentStep],
            submitted: true,
          },
        },
      };
    },
    '@@redux-form/SET_SUBMIT_SUCCEEDED': (state, action) => {
      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [state.currentStep - 1]: {
            ...state.stepsValidation[state.currentStep - 1],
            submitted: true,
          },
        },
      };
    },
    '@@redux-form/UPDATE_SYNC_ERRORS': (state, action) => {
      const syncErrors = oFetch(action.payload, 'syncErrors');
      const hasSyncErrors = Object.keys(syncErrors).length > 0;
      const stepsArray = Object.keys(c.STEPS);
      const lastStep = stepsArray[stepsArray.length - 1];
      const isLastStep = parseInt(lastStep) === state.currentStep;
      if (isLastStep) {
        const stepsValidation = Object.entries(c.STEPS_FIELDS).reduce((acc, entry) => {
          const [step, fields] = entry;
          if (Object.keys(fields).some(field => Object.keys(syncErrors).includes(field))) {
            return {
              ...acc,
              [step]: {
                ...state.stepsValidation[step],
                hasSyncErrors: true,
                submitted: true,
              },
            };
          }
          return acc;
        }, state.stepsValidation);
        return {
          ...state,
          stepsValidation,
        };
      }

      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [state.currentStep]: {
            ...state.stepsValidation[state.currentStep],
            hasSyncErrors,
          },
        },
      };
    },
    '@@redux-form/STOP_ASYNC_VALIDATION': (state, action) => {
      const error = action.error;
      const asyncErrors = action.payload;

      const stepsValidation = Object.entries(c.STEPS_FIELDS).reduce((acc, entry) => {
        const [step, fields] = entry;
        if (error && Object.keys(fields).some(field => Object.keys(asyncErrors).includes(field))) {
          return {
            ...acc,
            [step]: {
              ...state.stepsValidation[step],
              hasAsyncErrors: true,
            },
          };
        }
        return {
          ...acc,
          [step]: {
            ...state.stepsValidation[step],
            hasAsyncErrors: false,
          },
        };
      }, state.stepsValidation);

      return {
        ...state,
        stepsValidation,
      };
    },
  },
  initialState,
);
