import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = {};

export const staticReducer = handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const currentUserId = oFetch(action, 'payload.currentUserId');
      return {
        currentUserId,
      };
    },
  },
  initialState,
);
