import oFetch from 'o-fetch';
import React from 'react';
import { CollapsibleOverviewCard } from '@/components/hooks-components';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import PayslipModal from '../payslip-modal';
import { CSVTable, CSVTableRow, CSVTableCell } from '../csv-table';

export default function PaymentsDetailsModalItem(props) {
  const [paymentData, fullName] = oFetch(props, 'paymentData', 'fullName');
  const [payslip, payment, rawData] = oFetch(paymentData, 'payslip', 'payment', 'rawData');
  const rawDataEntries = Object.entries(rawData);
  const headers = Object.keys(rawData);

  const [amount, dateRange] = oFetch(payment, 'amount', 'dateRange');

  const { openModal } = useModal();

  function renderTitle() {
    return (
      <div className="boss-overview__dropdown-title">
        <p className="boss-overview__text boss-overview__text_faded">{dateRange}</p>
        <p className="boss-overview__text boss-overview__text_marked">{amount}</p>
      </div>
    );
  }

  function openPayslipModal() {
    return openModal({
      onSubmit() { },
      config: {
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payslip
            </div>
          );
        },
        baseClassName: 'boss-modal-window boss-modal-window_role_payslip',
      },
      props: {
        payslip: payslip,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PayslipModal,
    });
  }

  return (
    <CollapsibleOverviewCard title={renderTitle}>
      <div className="boss-overview__group">
        <div className="boss-overview__actions">
          <button
            type="button"
            onClick={openPayslipModal}
            className="boss-button boss-button_role_view-details boss-button_type_extra-small boss-overview__action"
          >
            View Payslip
          </button>
        </div>
      </div>
      <CSVTable>
        <CSVTableRow isHeader>
          {headers.map((header, index) => {
            return <CSVTableCell isHeader key={index} text={header} />;
          })}
        </CSVTableRow>
        <CSVTableRow>
          {rawDataEntries.map((rawDataEntry, index) => {
            const [header, value] = rawDataEntry;
            return <CSVTableCell key={index} text={value} />;
          })}
        </CSVTableRow>
      </CSVTable>
    </CollapsibleOverviewCard>
  );
}
