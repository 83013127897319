import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';

export function NoteForm(props) {
  const [onSubmit, note, edit] = oFetch(props, 'onSubmit', 'note', 'edit');
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ note: note }}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <div className="boss-modal-window__form">
            <form className="boss-form">
              <div className="boss-form__field">
                <Field
                  name="note"
                  parse={value => value}
                  component="textarea"
                  className="boss-form__textarea boss-form__textarea_size_large"
                />
              </div>
              <div className="boss-form__field">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="boss-button boss-button_role_add boss-form__submit"
                >
                  {edit ? 'Update Note' : 'Add Note'}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    />
  );
}
