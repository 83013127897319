import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';

export function forceJsmSyncRequest(params) {
  const [values, onSuccess, onError] = oFetch(params, 'values', 'onSuccess', 'onError');
  const userId = oFetch(values, 'userId');
  const forceJsmSyncUrl =  oFetch(apiRoutes, 'forceJsmSync').getPath({ userId });

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });
        return onError({ errors, globalNotifications });
      }
      return Promise.reject(params);
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    forceJsmSyncUrl,
    { userId }
  );
}

export function updatePersonalDetailsRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [userId, firstName, surname, email, bypassJsmMailbox] = oFetch(values, 'userId', 'firstName', 'surname', 'email', 'bypassJsmMailbox');
  const updateUserPersonalDetailsPath = oFetch(apiRoutes, 'updateUserPersonalDetails.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'firstName', 'surname', 'email'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update User personal details failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).put(updateUserPersonalDetailsPath, {
    firstName,
    surname,
    email,
    bypassJsmMailbox,
  });
}

export function createTemporaryPermissionRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  const [userId, jsmBarsApplicationId, venueId, startDate, endDate, note] = oFetch(
    values,
    'userId',
    'jsmBarsApplicationId',
    'venueId',
    'startDate',
    'endDate',
    'note',
  );
  const createTemporaryAccessPath = oFetch(apiRoutes, 'createTemporaryAccess.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'venueId', 'startDate', 'endDate', 'note'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Create Temporary Permission failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(createTemporaryAccessPath, {
    appId: jsmBarsApplicationId,
    venueId,
    startDate,
    endDate,
    note,
  });
}

export function updateTemporaryPermissionRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  const [userId, temporaryPermissionId, venueId, startDate, endDate, note] = oFetch(
    values,
    'userId',
    'temporaryPermissionId',
    'venueId',
    'startDate',
    'endDate',
    'note',
  );
  const updateTemporaryAccessPath = oFetch(apiRoutes, 'updateTemporaryAccess.getPath')(
    userId,
    temporaryPermissionId,
  );

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'venueId', 'startDate', 'endDate', 'note'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update temporary permission failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).put(updateTemporaryAccessPath, {
    venueId,
    startDate,
    endDate,
    note,
  });
}

export function disableTemporaryPermissionRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  const [userId, temporaryPermissionId] = oFetch(values, 'userId', 'temporaryPermissionId');
  const disableTemporaryAccessPath = oFetch(apiRoutes, 'disableTemporaryAccess.getPath')(
    userId,
    temporaryPermissionId,
  );

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Disable temporary permission failed' });
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).delete(disableTemporaryAccessPath);
}

export function disableAppPermissionsRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  const [userId, jsmBarsApplicationId] = oFetch(values, 'userId', 'jsmBarsApplicationId');
  const disableAppPermissionsPath = oFetch(
    apiRoutes,
    'disableAppPermissions.getPath',
  )({ userId, jsmBarsApplicationId });

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Disable app permissions failed' });
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).delete(disableAppPermissionsPath);
}

export function updatePermanentPermissionsRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  const [userId, appId, venueIds] = oFetch(values, 'userId', 'jsmBarsApplicationId', 'venueIds');
  const updatePermanentAccessPath = oFetch(apiRoutes, 'updatePermanentAccess.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'venueIds'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update Permanent Permission failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(updatePermanentAccessPath, {
    appId,
    venueIds,
  });
}

export function updateAccessDetailsRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [userId, role, venues] = oFetch(values, 'userId', 'role', 'venues');
  const updateUserAccessDetailsPath = oFetch(apiRoutes, 'updateUserAccessDetails.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'role', 'venues'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update User access details failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).put(updateUserAccessDetailsPath, {
    role,
    venues,
  });
}

export function updateUserRoleRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [userId, role] = oFetch(values, 'userId', 'role');
  const editUserRolePath = oFetch(apiRoutes, 'editUserRole.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'role'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update User role failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(editUserRolePath, {
    role,
  });
}

export function disableUserRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [userId, notRehire, description] = oFetch(values, 'userId', 'notRehire', 'description');
  const disableUserPath = oFetch(apiRoutes, 'disableUser.getPath')(userId);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'notRehire', 'description'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Disable User failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).delete(disableUserPath, {
    params: {
      notRehire,
      description,
    },
  });
}

export function enableUserRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const userId = oFetch(values, 'userId');
  const enableUserPath = oFetch(apiRoutes, 'enableUser.getPath')(userId);
  return bossRequestHttp({
    errorHandler() {
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(enableUserPath);
}
