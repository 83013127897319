import React from 'react';
import Cropper from 'react-cropper';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { SubmissionError } from 'redux-form';
import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';

import ImagesPicker, { canvasToBlob, resizeToLimit, getAsDataURL } from '@/components/images-picker';
const VALID_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

export default class BossFormAvatarWithFacial extends React.Component {
  constructor(props) {
    super(props);

    const [GUIDFieldName, avatarFieldName, setupForFacialFieldName] = oFetch(props, 'names');

    this.GUIDFieldName = GUIDFieldName;
    this.avatarFieldName = avatarFieldName;
    this.setupForFacialFieldName = setupForFacialFieldName;

    this.state = {
      markedRetakeAvatar: false,
      disableUpload: false,
      originalAvatarUrl: null,
      showCropper: false,
      croppAvatarUrl: null,
      touched: false,
      lambdaErrors: [],
      lambdaExistErrors: [],
      lambdaProcessing: false,
    };
  }

  onCropperInit = cropper => {
    this.cropper = cropper;
  };

  openFileDialog = e => {
    this.imagesPicker.open();
  };

  onRotateLeft = () => {
    this.cropper.rotate(-90);
  };

  onRotateRight = () => {
    this.cropper.rotate(90);
  };

  handleCreateFaceId = params => {
    const validateAvatarForCreateUrl = oFetch(this.props, 'validateAvatarForCreateUrl');
    const croppedImageUrl = oFetch(params, 'croppedImageUrl');
    const setupForFacialRecognition = oFetch(this.props, `${this.setupForFacialFieldName}.input.value`);
    const onAvatarChange = oFetch(this.props, `${this.avatarFieldName}.input.onChange`);
    const onChange = oFetch(this.props, `${this.GUIDFieldName}.input.onChange`);
    this.setState(state => ({ lambdaProcessing: true, lambdaErrors: [] }));

    return bossRequestHttp({
      errorHandler: params => {
        this.setState(state => ({ lambdaProcessing: false }));

        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['uploadAvatar'],
          });
          const baseErrors = utils.safeGet(() => errors.uploadAvatar.base) || [];
          const existErrors = utils.safeGet(() => errors.uploadAvatar.exist) || [];
          if (baseErrors.length > 0) {
            globalNotifications.showDefaultFailureMessage({ message: baseErrors.join(', ') });
          }
          this.setState(state => ({ lambdaErrors: baseErrors, lambdaExistErrors: existErrors }));
          return true;
        }

        return false;
      },
      successHandler: params => {
        const data = oFetch(params, 'data');
        const guid = oFetch(data, 'guid');
        this.setState(
          {
            showCropper: false,
            croppAvatarUrl: null,
            lambdaProcessing: false,
          },
          () => {
            onAvatarChange(croppedImageUrl);
            onChange(guid);
          },
        );
      },
    }).post(validateAvatarForCreateUrl, {
      avatarBase64: croppedImageUrl,
      allowUnscannableAvatar: !setupForFacialRecognition,
    });
  };

  onCropSubmit = () => {
    canvasToBlob(this.cropper.getCroppedCanvas())
      .then(blob => {
        return resizeToLimit(blob, 1024 * 1024, 600);
      })
      .then(getAsDataURL)
      .then(croppedImageUrl => {
        this.handleCreateFaceId({ croppedImageUrl });
      });
  };

  onCancelCrop = () => {
    this.setState({
      showCropper: false,
      croppAvatarUrl: null,
    });
  };

  onRestoreOriginalUrl = () => {
    const onAvatarChange = oFetch(this.props, `${this.avatarFieldName}.input.onChange`);
    onAvatarChange(this.state.originalAvatarUrl);
    this.setState({
      touched: false,
    });
  };

  renderCropperBlock() {
    const lambdaProcessing = oFetch(this.state, 'lambdaProcessing');
    return (
      <div className="boss-add-avatar-block">
        <div className="boss-edit-image-block boss-add-avatar-block_adjust_edit-image-block">
          <div className="boss-edit-image-block__cropper-block">
            <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
              <button
                type="button"
                disabled={lambdaProcessing}
                className="boss-button boss-buttons-group_adjust_button"
                onClick={this.onRotateLeft}
              >
                Rotate Left
              </button>
              <button
                type="button"
                disabled={lambdaProcessing}
                className="boss-button boss-buttons-group_adjust_button"
                onClick={this.onRotateRight}
              >
                Rotate Right
              </button>
            </div>

            <div className="boss-edit-image-block__cropper">
              <Cropper
                zoomOnWheel={false}
                src={this.state.croppAvatarUrl}
                preview="[data-avatarpreview]"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                aspectRatio={1}
                onInitialized={this.onCropperInit}
                guides={true}
              />
            </div>

            <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
              {!lambdaProcessing && (
                <button
                  type="button"
                  className="boss-button boss-buttons-group_adjust_button"
                  onClick={this.onCropSubmit}
                >
                  Ok
                </button>
              )}
              {!lambdaProcessing && (
                <button
                  type="button"
                  className="boss-button boss-buttons-group_adjust_button"
                  onClick={this.onCancelCrop}
                >
                  Cancel
                </button>
              )}
              {lambdaProcessing && (
                <button
                  type="button"
                  disabled={true}
                  className="boss-button boss-buttons-group_adjust_button"
                >
                  Create face id processing ...
                </button>
              )}
            </div>
          </div>
          <div className="boss-edit-image-block__preview-section" alt="preview" data-avatarpreview />
        </div>
      </div>
    );
  }

  onAddNewAvatar = files => {
    this.setState({
      showCropper: true,
      touched: true,
      croppAvatarUrl: files[0] || '',
    });
  };

  renderRestoreOriginalButton() {
    return this.state.touched ? (
      <button
        type="button"
        className="boss-button boss-buttons-group_adjust_button"
        onClick={this.onRestoreOriginalUrl}
      >
        Clear
      </button>
    ) : null;
  }

  checkOnChange = e => {
    const currentValue = e.target.checked;
    const onChange = oFetch(this.props, `${this.setupForFacialFieldName}.input.onChange`);
    onChange(currentValue);
  };

  renderFacialCheckbox = () => {
    const setupForFacialRecognition = oFetch(this.props, `${this.setupForFacialFieldName}.input.value`);
    return (
      <div className="boss-form__label-group">
        <label className="boss-form__checkbox-label boss-form__checkbox-label_context_label-group">
          <input
            onChange={this.checkOnChange}
            type="checkbox"
            checked={setupForFacialRecognition}
            className="boss-form__checkbox-input"
          />
          <span className="boss-form__checkbox-label-text">Setup for facial recognition</span>
        </label>
      </div>
    );
  };

  renderErrors = errors => {
    return (
      <div className="boss-form__field">
        <div className="boss-form__error">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-form__error-text">
                <span className="boss-form__error-line">{error}</span>
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  renderEffectiveStaffMemberLink = error => {
    const [id, fullName, type] = oFetch(error, 'id', 'full_name', 'type');
    let effectiveStaffMemberType;
    let effectiveRoute;

    if (type === 'staff_member') {
      effectiveStaffMemberType = 'Staff member';
      effectiveRoute = appRoutes.staffMemberProfile(id);
    } else if (type === 'moss_staff_member') {
      effectiveStaffMemberType = 'Moss staff member';
      effectiveRoute = appRoutes.mossStaffMemberProfile({ mossStaffMemberId: id });
    } else {
      throw new Error(`Unknown effective staff member type. Got: ${type}`);
    }
    return (
      <span className="boss-form__error-line">
        {`${effectiveStaffMemberType} with given avatar `}
        <a href={effectiveRoute}>{fullName}</a>
        {' already exist'}
      </span>
    );
  };

  renderLambdaExistErrors = errors => {
    return (
      <div className="boss-form__field">
        <div className="boss-form__error">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-form__error-text">
                {this.renderEffectiveStaffMemberLink(error)}
              </p>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { markedRetakeAvatar, disableUpload } = this.props;
    const [lambdaErrors, lambdaExistErrors] = oFetch(this.state, 'lambdaErrors', 'lambdaExistErrors');
    const guidFieldMeta = oFetch(this.props, `${this.GUIDFieldName}.meta`);
    const { error, touched } = guidFieldMeta;
    const hasLambdaErrors = lambdaErrors.length > 0;
    const hasLambdaExistErrors = lambdaExistErrors.length > 0;

    const avatarValue = oFetch(this.props, `${this.avatarFieldName}.input.value`);

    return (
      <div className="boss-form__field">
        {hasLambdaErrors && this.renderErrors(lambdaErrors)}
        {hasLambdaExistErrors && this.renderLambdaExistErrors(lambdaExistErrors)}
        {error && touched && this.renderErrors([error])}
        {this.renderFacialCheckbox()}
        {this.state.showCropper ? (
          this.renderCropperBlock()
        ) : (
          <div className="boss-add-avatar-block">
            <div className="boss-add-avatar-block__preview" style={{ position: 'relative' }}>
              <img src={avatarValue} alt="Avatar" className="boss-add-avatar-block__preview-image" />
              {markedRetakeAvatar && disableUpload && (
                <div
                  style={{ width: '240px', height: '240px' }}
                  className="boss-user-summary__avatar-overlay"
                >
                  <p
                    style={{ fontSize: '36px' }}
                    className="boss-user-summary__avatar-overlay-text boss-user-summary__avatar-overlay-text_role_retake"
                  >
                    Please retake picture
                  </p>
                </div>
              )}
            </div>
            <ImagesPicker
              ref={ref => {
                this.imagesPicker = ref;
              }}
              accept={VALID_FILE_TYPES}
              asDataURL={true}
              multiple={false}
              onPicked={this.onAddNewAvatar}
              bytesLimit={null}
              maximalResolution={2048}
            />
            <div className="boss-buttons-group boss-edit-image-block_adjust_buttons-group">
              <button
                type="button"
                className="boss-button boss-button_role_file boss-buttons-group_adjust_button"
                onClick={this.openFileDialog}
              >
                Choose File
              </button>
              {this.renderRestoreOriginalButton()}
            </div>
            <span className="boss-add-avatar-block__file-label">
              Drag and drop file here or click choose file to upload new photo
            </span>
          </div>
        )}
      </div>
    );
  }
}
