import React from 'react';
import { createRoot } from 'react-dom/client';

class EditIndicator extends React.PureComponent {
  componentDidMount() {
    this.editIndicator = document.createElement('div');
    this.rootEditIndicator = createRoot(this.editIndicator);
    document.body.insertBefore(this.editIndicator, document.body.firstChild);
    this.renderEditMode(this.props);
  }

  componentWillUnmount() {
    document.body.removeChild(this.editIndicator);
    this.rootEditIndicator.unmount();
  }

  renderEditMode(props) {
    this.rootEditIndicator.render(
      <div className="boss-alert boss-alert_role_page-note boss-alert_status_warning">
        <p className="boss-alert__text">Edit mode</p>
      </div>,
    );
  }

  render() {
    return null;
  }
}

export default EditIndicator;
