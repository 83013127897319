import oFetch from 'o-fetch';
import React, { useState } from 'react';

export default function StaffMembersFilter(props) {
  const [totalCount, currentCount, onChange] = oFetch(
    props,
    'totalCount',
    'currentCount',
    'onChange',
  );
  const [filterValue, setFilterValue] = useState('');

  function handleFilterValueChange(e) {
    const value = oFetch(e, 'target.value');
    setFilterValue(value);
    onChange(value);
  }

  return (
    <div className="boss-users__filter">
      <form className="boss-form">
        <div className="boss-form__row boss-form__row_position_last boss-form__row_hidden-xs">
          <div className="boss-form__field boss-form__field_role_control">
            <p className="boss-form__label boss-form__label_type_light">
              <span className="boss-form__label-text">
                Showing {currentCount} of {totalCount}
              </span>
            </p>
            <div className="boss-form__search">
              <input
                name="search"
                type="text"
                className="boss-form__input"
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterValueChange}
              />
            </div>
          </div>
        </div>
        <div className="boss-form__row boss-form__row_position_last boss-form__row_visible-xs">
          <div className="boss-form__field">
            <p className="boss-form__label boss-form__label_justify_center">
              <span className="boss-form__label-text">
                Showing {currentCount} of {totalCount}
              </span>
            </p>
            <div className="boss-form__search">
              <input
                name="search"
                type="text"
                className="boss-form__input"
                placeholder="Search..."
                value={filterValue}
                onChange={handleFilterValueChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
