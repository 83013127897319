import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Tooltip } from 'react-tippy';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { AppAccessField } from './app-access-field';
import BossFormCheckbox from '@/components/boss-form/boss-form-checkbox';
import { legacyMatchesJsmEmail } from '@/lib/jsm-email-funcs';

function addLinkedUserValidation(values) {
  const errors = {};
  if (values.jsmBarsApplicationPermissionData.length === 0) {
    errors.jsmBarsApplicationPermissionData = ['You must add at least one permission to add a linked user'];
  }
  return errors;
}

export function AddLinkedUserModal(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  const canBypassJsmMailbox = oFetch(props, 'canBypassJsmMailbox');
  const globals = useLegacyGlobal();
  const permittedRolesOptions = oFetch(globals, 'permittedRolesOptions');
  const [currentlySelectedApp, setCurrentlySelectedApp] = useState(null);
  const [currentlySelectedVenues, setCurrentlySelectedVenues] = useState([]);
  const isPermissionVenuesNotSet = currentlySelectedApp !== null && currentlySelectedVenues.length === 0;
  const isSelectedPermissionNotAdded = currentlySelectedApp !== null && currentlySelectedVenues.length > 0;

  function isPermissionsEmpty(values) {
    return values.jsmBarsApplicationPermissionData.length === 0;
  }

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p
                key={index}
                className="boss-alert__text"
              >
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  function renderSubmitButton(handleSubmit, disabled) {
    return (
      <button
        onClick={handleSubmit}
        type="submit"
        disabled={disabled}
        className="boss-button boss-button_role_add boss-form__submit"
      >
        Add Linked User
      </button>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={addLinkedUserValidation}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, submitting, submitError, values }) => {
        const showBypassCheckBox = canBypassJsmMailbox && legacyMatchesJsmEmail({ email: values.email });

        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field
                  name="email"
                  label="Email Address"
                  type="email"
                  parse={value => value}
                  component={BossFormInput}
                />
                { showBypassCheckBox && (
                    <Field
                      name="bypassJsmMailbox"
                      label="Bypass JSM Mailbox"
                      component={BossFormCheckbox}
                      type='checkbox'
                      checked={false}
                    />
                ) }
                <Field
                  name="role"
                  label="Role"
                  parse={value => value}
                  options={permittedRolesOptions}
                  component={BossFormSelectNew}
                />
                <FieldArray
                  name="jsmBarsApplicationPermissionData"
                  onAppSelect={app => setCurrentlySelectedApp(app)}
                  onVenueSelect={venueIds => setCurrentlySelectedVenues(venueIds)}
                  component={AppAccessField}
                />
                <div className="boss-form__field">
                  {isPermissionsEmpty(values) && !isPermissionVenuesNotSet && !isSelectedPermissionNotAdded && (
                    <Tooltip
                      title="You must add at least one permission to add a linked user"
                      position="top"
                    >
                      {renderSubmitButton(handleSubmit, true)}
                    </Tooltip>
                  )}
                  {isPermissionVenuesNotSet && (
                    <Tooltip
                      title={`You must add at least one venue to the ${currentlySelectedApp} app, to add a linked user`}
                      position="top"
                    >
                      {renderSubmitButton(handleSubmit, true)}
                    </Tooltip>
                  )}
                  {isSelectedPermissionNotAdded && (
                    <Tooltip
                      title={`Clear or save your venue permissions for ${currentlySelectedApp} app to continue`}
                      position="top"
                    >
                      {renderSubmitButton(handleSubmit, true)}
                    </Tooltip>
                  )}
                  {!isPermissionsEmpty(values) &&
                    !isPermissionVenuesNotSet &&
                    !isSelectedPermissionNotAdded &&
                    renderSubmitButton(handleSubmit, submitting)}
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
