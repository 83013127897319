export const FINANCE_REPORT_STATUS_DONE_STATUS = 'done';
export const FINANCE_REPORT_SHOW_ALL_FILTER_TYPE = 'show_all';
export const FINANCE_REPORT_SALARY_ONLY_FILTER_TYPE = 'salary_only';
export const FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE = 'with_owed_hours';
export const FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE = 'with_holidays';
export const FINANCE_REPORT_WITH_ACCESSORIES_FILTER_TYPE = 'with_accessories';
export const FINANCE_REPORTS_PAID_ON_SITE_FILTER_TYPE = 'paid_on_site';

export const FINANCE_REPORT_READY_STATUS = 'ready';
export const FINANCE_REPORT_REQUIRING_UPDATE_STATUS = 'requiring_update';

export const FILTER_TABS = [
  FINANCE_REPORTS_PAID_ON_SITE_FILTER_TYPE,
  FINANCE_REPORT_WITH_ACCESSORIES_FILTER_TYPE,
  FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE,
  FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE,
  FINANCE_REPORT_SALARY_ONLY_FILTER_TYPE,
  FINANCE_REPORT_SHOW_ALL_FILTER_TYPE,
];

export const FILTER_TITLES = {
  [FINANCE_REPORT_SHOW_ALL_FILTER_TYPE]: 'All',
  [FINANCE_REPORT_SALARY_ONLY_FILTER_TYPE]: 'Salary',
  [FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE]: 'Owed Hours',
  [FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE]: 'Paid Holidays',
  [FINANCE_REPORT_WITH_ACCESSORIES_FILTER_TYPE]: 'Accessories',
  [FINANCE_REPORTS_PAID_ON_SITE_FILTER_TYPE]: 'Paid On Site',
};
