import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  pageOptions: undefined,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const [
        pageOptions,
        effectiveStaffTypes,
        currentVenue,
        venues,
        allSpecialSecurityPayrates,
        activeSpecialSecurityPayrates,
        mossHourTags,
      ] = oFetch(
        payload,
        'pageOptions',
        'effectiveStaffTypes',
        'venue',
        'venues',
        'allSpecialSecurityPayrates',
        'activeSpecialSecurityPayrates',
        'mossHourTags',
      );

      return {
        pageOptions,
        effectiveStaffTypes,
        currentVenue,
        venues,
        allSpecialSecurityPayrates,
        activeSpecialSecurityPayrates,
        mossHourTags,
      };
    },
  },
  initialState,
);
