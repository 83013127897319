import { createSelector } from 'reselect';

const selectGlobal = state => state;

const makeSelectCurrentVenue = () =>
  createSelector(selectGlobal, globalState => globalState.get('currentVenue'));

const makeSelectVenues = () => createSelector(selectGlobal, globalState => globalState.get('venues'));

const makeSelectStartDate = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['filter', 'range', 'startDate']));

const qrCodeTypeSelector = createSelector(selectGlobal, globalState =>
  globalState.getIn(['types', 'qrCodeType']),
);
const computerTypeSelector = createSelector(selectGlobal, globalState =>
  globalState.getIn(['types', 'computerType']),
);

const makeSelectEndDate = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['filter', 'range', 'endDate']));

const makeSelectIsDetailsOpen = () =>
  createSelector(selectGlobal, globalState => globalState.get('isDetailsOpen'));

const makeSelectIsFilterOpen = () =>
  createSelector(selectGlobal, globalState => globalState.get('isFilterOpen'));

const makeSelectDetailedSubmission = () =>
  createSelector(selectGlobal, globalState => globalState.get('detailedSubmission'));

const answersSelector = createSelector(selectGlobal, globalState => globalState.get('answers'));
const creatorsSelector = createSelector(selectGlobal, globalState => globalState.get('creators'));
const makeSelectSubmissions = createSelector(selectGlobal, globalState => globalState.get('submissions'));
const getSubmissions = createSelector(
  [makeSelectSubmissions, answersSelector, qrCodeTypeSelector, computerTypeSelector],
  (submissions, answerItems, qrCodeType, computerType) => {
    return submissions.map(submission => {
      return submission
        .set('isQrCodeType', submission.get('type') === qrCodeType)
        .set('isComputerType', submission.get('type') === computerType)
        .update('answers', answers => {
          return answers.map(answer => {
            const checkListAnswerId = answer.get('check_list_item_id');
            const answerItem = answerItems.find(answerItem => answerItem.get('id') === checkListAnswerId);
            return answer.set('description', answerItem.get('description'));
          });
        });
    });
  },
);

const makeSelectPageCount = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['pagination', 'pageCount']));

const makeSelectCurrentPage = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['pagination', 'currentPage']));

const makeSelectCreatedBy = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['filter', 'createdBy']));

const searchValueSelector = createSelector(selectGlobal, globalState =>
  globalState.getIn(['filter', 'searchValue']),
);

const makeSelectStatus = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['filter', 'status']));

export {
  getSubmissions,
  makeSelectCurrentVenue,
  makeSelectVenues,
  makeSelectStartDate,
  makeSelectEndDate,
  makeSelectIsDetailsOpen,
  makeSelectIsFilterOpen,
  makeSelectDetailedSubmission,
  makeSelectSubmissions,
  makeSelectPageCount,
  makeSelectCurrentPage,
  makeSelectCreatedBy,
  makeSelectStatus,
  creatorsSelector,
  searchValueSelector,
};
