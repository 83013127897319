import React from 'react';

type Props = {
  instances: any[];
  renderItem: (instance: any) => React.JSX.Element
};

export function InstancesTable(props: Props) {
  const { instances, renderItem } = props;

  function renderInstances() {
    return instances.map((instance: any) => {
      const { id: instanceId } = instance;
      return React.cloneElement(renderItem(instance), { key: instanceId });
    });
  }

  return (
    <div className="boss-board__table">
      <div className="boss-board__scroll boss-board__scroll_type_native">
        <div className="boss-board__scroll-content">
          <div className="boss-table boss-table_page_staff-tracking-staff-added">
            <div className="boss-table__row">
              <div className="boss-table__cell boss-table__cell_role_header">Name</div>
              <div className="boss-table__cell boss-table__cell_role_header">Availability Check</div>
              <div className="boss-table__cell boss-table__cell_role_header">Unavailable Email Sent</div>
              <div className="boss-table__cell boss-table__cell_role_header" />
            </div>
            {renderInstances()}
          </div>
        </div>
      </div>
    </div>
  );
}
