import React, { Fragment } from 'react';
import { Tooltip } from 'react-tippy';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { WagePercentageTooltipContent } from './tooltip-content';

export function WagePercentageCell(props) {
  const [title, value, data] = oFetch(props, 'title', 'value', 'data');
  const wagePercentageData = oFetch(data, 'wagePercentageData');
  const [adjustedWagePercentageCents, thresholdPercentageCents] = oFetch(
    wagePercentageData,
    'adjustedWagePercentageCents',
    'thresholdPercentageCents',
  );
  const percentagesExist = adjustedWagePercentageCents !== null && thresholdPercentageCents !== null;
  const valid = adjustedWagePercentageCents <= thresholdPercentageCents;
  const cellClassNames = cn('boss-kpi__data-indicator', {
    'boss-kpi__data-indicator_role_pass': percentagesExist && valid,
    'boss-kpi__data-indicator_role_fail': percentagesExist && !valid,
  });

  return (
    <Tooltip
      className="boss-kpi__data-item boss-kpi__data-item_role_week"
      arrow
      theme="light"
      position="bottom"
      interactive
      html={<WagePercentageTooltipContent data={data} />}
    >
      <Fragment>
        <span className={'boss-kpi__data-number'}>{title}</span>
        <div className={cellClassNames}>
          <span className="boss-kpi__data-indicator-value">{value}</span>
        </div>
      </Fragment>
    </Tooltip>
  );
}
