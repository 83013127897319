import React, { forwardRef } from 'react';
import { CurrencyInput } from '@/components/currency-input';
import cn from 'classnames';

const BossFormInput = (
  {
    input,
    label,
    required,
    className,
    step,
    errorKey = 'error',
    isCurrency = false,
    disabled = false,
    meta: { touched, [errorKey]: error, warning, submitError },
    input: { onBlur, value, onChange: inputOnChange, name, onFocus },
    meta,
    unit = '',
    unitClassName = '',
    placeholder = '',
    autocomplete = true,
    fieldClassName,
    inputClassName,
    capitalize = false,
    unitPosition = 'left',
    description,
    renderLabelAction = () => {},
    renderCustomValidation = () => {},
    onChange = () => {},
  },
) => {
  const inputClassNames = cn('boss-form__input', {
    [inputClassName]: !!inputClassName,
    'boss-form__input_state_error': touched && (error || submitError),
  });
  const handleCurrencyInputChange = (value, floatvalue) => {
    inputOnChange(value);
  };

  const handleChange = e => {
    const value = e.target.value;
    inputOnChange(capitalize ? value.toUpperCase() : value);
    onChange(value);
  };

  const renderInputs = () => {
    const stringValue = value.toString();
    return isCurrency ? (
      <CurrencyInput
        disabled={disabled}
        value={stringValue}
        onChange={handleCurrencyInputChange}
        className={inputClassNames}
        decimalSymbol="."
        onFocus={onFocus}
      />
    ) : (
      <input
        autoComplete={autocomplete ? 'on' : 'off'}
        {...input}
        onChange={handleChange}
        step={step}
        disabled={disabled}
        placeholder={placeholder}
        className={inputClassNames}
        onFocus={onFocus}
      />
    );
  };
  const unitFieldClassName = cn('boss-form__units', { [unitClassName]: unit && unitClassName });
  const fieldClassNames = cn('boss-form__field', {
    [className]: !!className,
    [fieldClassName]: !!fieldClassName,
  });
  return (
    <div className={fieldClassNames}>
      {label && (
        <div className="boss-form__label-group">
          <label className="boss-form__label">
            <span className="boss-form__label-text">{`${label} ${required ? '*' : ''}`}</span>
          </label>
          {renderLabelAction && renderLabelAction()}
        </div>
      )}
      {unit ? (
        unitPosition === 'left' ? (
          <span className={unitFieldClassName}>
            <span className="boss-form__units-value">{unit}</span>
            {renderInputs()}
          </span>
        ) : (
          <span className={unitFieldClassName}>
            {renderInputs()}
            <span className="boss-form__units-value boss-form__units-value_position_after">{unit}</span>
          </span>
        )
      ) : (
        renderInputs()
      )}
      {description && <p className="boss-form__field-note">{description}</p>}
      {renderCustomValidation()}
      {touched && (error || submitError) && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            <span className="boss-form__error-line">{error || submitError}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default BossFormInput;
