import { z } from 'zod';
import { get$BossHttpSuccessHandlerParams } from "@/lib/schema-funcs";

const $PublicHoliday = z.object({
  id: z.number().int().nonnegative(),
  date: z.string(),
  description: z.string(),
  createdByUser: z.string(),
  month: z.string(),
  canBeDisabled: z.boolean(),
}).strict();

const $CreatePublicHolidayActionParamsData = z.object({
  publicHoliday: $PublicHoliday,
}).strict();

export type PublicHoliday = z.infer<typeof $PublicHoliday>;

export const $BossHttpSuccessHandlerParams = get$BossHttpSuccessHandlerParams($CreatePublicHolidayActionParamsData);

export type BossHttpSuccessHandlerParams = z.infer<typeof $BossHttpSuccessHandlerParams>;

export type PublicHolidayMonthGroup = {
  [key: string]: PublicHoliday[]
};

export type DeleteModalConfirmParams = {
  publicHoliday: PublicHoliday
};
