import React from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';

import { ColoredMultipleValue, ColoredSingleOption } from '@/components/boss-form/colored-select';

export function ContactsDetailsFilter(props) {
  const [staffTypesOptions, filter, onFilterChange] = oFetch(
    props,
    'staffTypesOptions',
    'filter',
    'onFilterChange',
  );
  const [name, staffType] = oFetch(filter, 'name', 'staffType');

  function handleStaffTypeChange(selected) {
    if (selected === '') {
      onFilterChange({
        ...filter,
        staffType: [],
      });
    } else {
      onFilterChange({
        ...filter,
        staffType: selected.split(',').map(item => parseInt(item)),
      });
    }
  }

  function handleNameChange(e) {
    const value = oFetch(e, 'target.value');
    onFilterChange({
      ...filter,
      name: value,
    });
  }

  return (
    <div className="boss-page-dashboard__filter">
      <div className="boss-page-dashboard__filter-header">
        <p className="boss-dropdown__label boss-dropdown__label_role_filter">Filter</p>
      </div>
      <div className="boss-page-dashboard__filter-content">
        <form action="#" className="boss-form">
          <div className="boss-form__row">
            <div className="boss-form__field boss-form__field_layout_half">
              <label className="boss-form__label">
                <span className="boss-form__label-text">Name</span>
                <input
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  type="text"
                  className="boss-form__input"
                />
              </label>
            </div>
            <div className="boss-form__field boss-form__field_layout_half">
              <label className="boss-form__label">
                <span className="boss-form__label-text">Staff Type</span>
              </label>
              <div className="boss-form__select">
                <Select
                  multi
                  options={staffTypesOptions}
                  value={staffType}
                  simpleValue
                  onChange={handleStaffTypeChange}
                  optionComponent={ColoredSingleOption}
                  valueComponent={ColoredMultipleValue}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
