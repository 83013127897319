import React from 'react';
import oFetch from 'o-fetch';
import { RequestForm } from '../../forms';

export function AddRequestModalContent(props) {
  const [onSubmit, onClose, marketingTask] = oFetch(props, 'onSubmit', 'onClose', 'marketingTask');

  const initialValues = {
    taskId: oFetch(marketingTask, 'id'),
    text: '',
    fileIds: [],
  };

  return (
    <div className="boss-modal-window__form">
      <RequestForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        files={{}}
        onClose={onClose}
        buttonText="Create"
      />
    </div>
  );
}
