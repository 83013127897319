import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import configureStore from '../store';
import { initialProfileLoad } from '../profile-wrapper/actions';
import { initialLoad } from './actions';
import { combineReducers } from 'redux-immutable';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import { MOSS_STAFF_MEMBER_TYPE } from '../../StaffMemberProfile/holidays-page/constants';

import Holidays from '../../StaffMemberProfile/holidays-page/containers/holidays';
import { holidaysReducer, taxYearReducer, pageStateReducer } from '../../StaffMemberProfile/holidays-page/reducers';
import profileReducer from '../profile-wrapper/reducers';
import { $MossStaffMemberHolidaysAppProps } from './types';

export function MossStaffMemberHolidaysApp(props) {
  const parsedProps = $MossStaffMemberHolidaysAppProps.parse(props);

  const [store, setStore] = useState(null);
  const [venues, isAdminPlus] = oFetch(parsedProps, 'venues', 'isAdminPlus');
  useEffect(() => {
    const store = configureStore(
      combineReducers({
        pageState: pageStateReducer,
        taxYear: taxYearReducer,
        holidays: holidaysReducer,
        profile: profileReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...parsedProps }));
    store.dispatch(initialLoad({ ...parsedProps }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ venues, isAdminPlus }}>
        <ModalsProvider>
          <Holidays staffMemberType={MOSS_STAFF_MEMBER_TYPE} />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}