import React from 'react';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';

class UserSummary extends React.Component {
  render() {
    const src = oFetch(this.props, 'src');
    const fullName = oFetch(this.props, 'fullName');
    const supportsFacialRecognition = oFetch(this.props, 'supportsFacialRecognition');

    let avatarContainerClasses = 'boss-user-summary__avatar';
    if (supportsFacialRecognition) {
     avatarContainerClasses = avatarContainerClasses + ' boss-user-summary__avatar_type_scannable';
    }

    return (
      <div className={`boss-user-summary ${this.props.className}`}>
        <div className="boss-user-summary__side">
          <div className={avatarContainerClasses}>
            <div className="boss-user-summary__avatar-inner">
              <img src={src} alt={this.props.alt} className="boss-user-summary__pic"/>
            </div>
          </div>
        </div>
        <div className="boss-user-summary__content boss-user-summary__content_adjust_middle">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{fullName}</h2>
          </div>
        </div>
      </div>
    )
  }
}

UserSummary.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
}

UserSummary.defaultProps = {
  className: '',
}

export default UserSummary;
