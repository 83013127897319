import oFetch from 'o-fetch';
import React from 'react';

export function VenueList(props) {
  const [venues, itemRenderer] = oFetch(props, 'venues', 'itemRenderer');

  return [...venues]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(venue => {
      const venueId = oFetch(venue, 'id');
      return React.cloneElement(itemRenderer(venue), {
        key: venueId,
      });
    });
}
