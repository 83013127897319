import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { RadioGroup, Radio } from '@/components/radio-group';

export function RatingSelectorFormField(props) {
  const [meta, input] = oFetch(props, 'meta', 'input');
  const [value, onChange, name] = oFetch(input, 'value', 'onChange', 'name');
  const { submitError } = meta;
  return (
    <div className="boss-rating">
      <RadioGroup
        name={name}
        selectedValue={value}
        onChange={onChange}
        className="boss-rating__options"
      >
        <label
          style={{ cursor: 'pointer' }}
          className={cn('boss-rating__option boss-rating__option_role_status-good', {
            'boss-rating__option_state_inactive': value !== 'good',
          })}
        >
          <Radio value={'good'} className="boss-form__switcher-radio" />
          Good
        </label>
        <label
          style={{ cursor: 'pointer' }}
          className={cn('boss-rating__option boss-rating__option_role_status-ok', {
            'boss-rating__option_state_inactive': value !== 'ok',
          })}
        >
          <Radio value={'ok'} className="boss-form__switcher-radio" />
          Ok
        </label>
        <label
          style={{ cursor: 'pointer' }}
          className={cn('boss-rating__option boss-rating__option_role_status-poor', {
            'boss-rating__option_state_inactive': value !== 'poor',
          })}
        >
          <Radio value={'poor'} className="boss-form__switcher-radio" />
          Poor
        </label>
        <label
          style={{ cursor: 'pointer' }}
          className={cn('boss-rating__option boss-rating__option_role_status-bad', {
            'boss-rating__option_state_inactive': value !== 'bad',
          })}
        >
          <Radio value={'bad'} className="boss-form__switcher-radio" />
          Bad
        </label>
      </RadioGroup>
      {submitError && (
        <div className="boss-form__error">
          {submitError.map((error, index) => {
            return (
              <p key={index} className="boss-form__error-text">
                <span className="boss-form__error-line">{error}</span>
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
}
