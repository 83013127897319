import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { VenuesAssignmentsForm } from './venues-assignments-form';

export function VenuesAssignmentsModal(props) {
  const [onSubmit, venuesOptions, specialSecurityPayRate] = oFetch(
    props,
    'onSubmit',
    'venuesOptions',
    'specialSecurityPayRate',
  );
  const [id, venuesIds] = oFetch(specialSecurityPayRate, 'id', 'venuesIds');
  const initialValues = {
    specialSecurityPayRateId: id,
    venues: venuesIds,
  };

  return (
    <VenuesAssignmentsForm onSubmit={onSubmit} initialValues={initialValues} venuesOptions={venuesOptions} />
  );
}
