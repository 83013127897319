import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';

import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import * as actions from './actions';
import CheckLists from './checklists-app';
import reducers from './reducers';

export function CheckListsApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialState(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  const hasAccessToChecklistSubmissionsPage = oFetch(props, 'hasAccessToChecklistSubmissionsPage');

  return (
    <Provider store={store}>
      <CheckLists hasAccessToChecklistSubmissionsPage={hasAccessToChecklistSubmissionsPage} />
    </Provider>
  );
}
