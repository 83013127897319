import React, { useState, useMemo, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';
import { useWindowSize } from '@uidotdev/usehooks';
import { ColumnFilter } from './column-filter';
import { TaskList } from './task-list';

export function ReviewColumn(props) {
  const [tasks, renderTask] = oFetch(props, 'tasks', 'renderTask');
  const [selectedVenues, setSelectedVenues] = useState([]);
  const windowSize = useWindowSize();
  const [isTaskListOpened, setIsTaskListOpened] = useState(null);
  const tasksExist = tasks.length > 0;
  const [isOnMobileView, setIsOnMobileView] = useState(null);

  useEffect(() => {
    if (windowSize.width !== null) {
      const defaultTaskOpened = windowSize.width > 960;
      setIsOnMobileView(!defaultTaskOpened);
      setIsTaskListOpened(defaultTaskOpened);
    }
  }, [windowSize.width]);

  const visibleTasks = useMemo(() => {
    if (selectedVenues.length === 0) {
      return tasks;
    }
    return tasks.filter(task => {
      const taskVenueId = oFetch(task, 'venueId');
      return selectedVenues.includes(taskVenueId);
    });
  }, [tasks, selectedVenues]);

  function handleFilterChange(venues) {
    setSelectedVenues(venues);
  }

  function handleToggleListOpened() {
    setIsTaskListOpened(!isTaskListOpened);
  }

  const totalTasksCount = tasks.length;
  const selectedTasksCount = visibleTasks.length;

  if (windowSize.width === null || isTaskListOpened === null || isOnMobileView === null) {
    return null;
  }

  return (
    <div className="boss-page-main__tasks-column">
      <div className="boss-tasks boss-tasks_role_marketing boss-tasks_role_review">
        <div className="boss-tasks__header">
          <ColumnFilter
            title="Review"
            showAssignOnMe={false}
            selectedVenues={selectedVenues}
            isAssignedOnMe={false}
            onFilterChange={handleFilterChange}
            onAssignOnMeChanged={() => {}}
            totalCount={totalTasksCount}
            selectedCount={selectedTasksCount}
            onToggleListOpened={handleToggleListOpened}
          />
        </div>

        {isOnMobileView && (
          <Collapse
            isOpened={isTaskListOpened}
            theme={{
              collapse: 'ReactCollapse--collapse',
              content: 'ReactCollapse--content',
            }}
            initialStyle={{ width: '100%' }}
          >
            <div className="boss-tasks boss-tasks_role_marketing">
              <div className="boss-tasks__content">
                {!tasksExist && (
                  <span className="boss-overview__text-large boss-overview__text-marked">Empty</span>
                )}
                {tasksExist && <TaskList tasks={visibleTasks} renderTask={renderTask} />}
              </div>
            </div>
          </Collapse>
        )}
        {!isOnMobileView && (
          <div className="boss-tasks__content">
            {!tasksExist && (
              <span className="boss-overview__text-large boss-overview__text-marked">Empty</span>
            )}
            {tasksExist && <TaskList tasks={visibleTasks} renderTask={renderTask} />}
          </div>
        )}
      </div>
    </div>
  );
}
