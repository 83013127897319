import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import ImageModal from './image-modal';

export default class FilePreview extends React.Component {
  static displayName = 'FilePreview';
  static contextTypes = { openModal: PropTypes.func };

  openModal = (e) => {
    const openModal = oFetch(this.context, 'openModal');

    let uploadId = parseInt(e.target.getAttribute('data-upload-id'));

    let image = this.props.uploads.find((upload) => {
      return upload.id == uploadId
    });

    openModal({
      onSubmit: () => {},
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Edit Question',
        innerClose: true,
      },
      props: {
        currentImage: image,
        deleteUpload: this.props.deleteUpload,
      },
    })(ImageModal);
  }

  openReuploadModal = (e) => {
    let uuidUploadId = e.target.getAttribute('data-upload-uuid');

    let image = this.props.uploads.find((upload) => {
      return upload.uuid == uuidUploadId
    });
    openModal({
      onSubmit: () => {},
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Edit Question',
        innerClose: true,
      },
      props: {
        currentImage: image,
        deleteUpload: this.props.deleteUpload,
      },
    })(ImageModal);
  }

  renderImages() {
    if (!this.props.currentAnswer) return;
    let uploads = this.props.uploads.filter((upload) => {
      return upload.questionnaireQuestionId == this.props.currentQuestion.id
    });

    if (!uploads.length) return;

    return uploads.map((upload, index) => {
      let uploadClass = '';

      if (!upload.id) {
        uploadClass = 'boss-question__preview-link_state_broken';
      }

      if (upload.id) {
        return (
          <button
            type="button"
            className={ `boss-question__preview-link`}
            data-modal={ index }
            key={ upload.id }
            data-upload-id={ upload.id }
            onClick={ this.openModal }
          >
            <img
              src={ upload.url }
              className="boss-question__preview-image"
              data-upload-id={ upload.id }
            />
          </button>
        );
      } else {
        const isWrong = this.props.wrongFiles.includes(upload.questionnaireQuestionId);
        return (
          <button
            type="button"
            style={{border: isWrong ? '1px solid red' : ''}}
            className={ `boss-question__preview-link boss-question__preview-link_state_broken`}
            data-modal={ index }
            key={ upload.uuid }
            data-upload-uuid={ upload.uuid }
            onClick={ this.openReuploadModal }
          >
          </button>
        );
      }
    });
  }

  render() {
    return (
      <div className="boss-question__preview">
        { this.renderImages() }
      </div>
    )
  }
}
