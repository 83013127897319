import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';

import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import Dashboard from '../components/dashboard';
import MossStaffMemberCard from '../components/moss-staff-member-card';
import MossStaffMemberProfileActions from '../components/moss-staff-member-profile-actions';
import EditProfilePage from '../components/edit-profile-page';
import EnableProfilePage from '../components/enable-profile-page';
import DashboardProfile from '../components/dashboard-profile';
import { openWarningModal } from '@/components/modals';

import ContentModal from '@/components/content-modal';
import DisableMossStaffMemberForm from '../components/disable-moss-staff-member-form';
import EditAvatarForm from '../components/edit-avatar-form';

import confirm from '@/lib/confirm-utils';

import {
  editProfile as startEditProfile,
  cancelEditProfile,
  enableProfile as startEnableProfile,
  cancelEnableProfile,
  showDisableMossStaffMemberModal,
  hideDisableMossStaffMemberModal,
  disableMossStaffMemberRequest,
  showEditAvatarModal,
  hideEditAvatarModal,
  markRetakeAvatar,
} from '../actions';

const mapStateToProps = state => {
  const profile = state.get('profile');
  const profileJS = profile.toJS();

  return {
    mossStaffMember: oFetch(profileJS, 'mossStaffMember'),
    editProfile: oFetch(profileJS, 'editProfile'),
    enableProfile: oFetch(profileJS, 'enableProfile'),
    mossStaffTypes: oFetch(profileJS, 'mossStaffTypes'),
    mossPayRates: oFetch(profileJS, 'mossPayRates'),
    disableMossStaffMemberModal: oFetch(profileJS, 'disableMossStaffMemberModal'),
    editAvatarModal: oFetch(profileJS, 'editAvatarModal'),
    venues: oFetch(profileJS, 'venues'),
    accessibleVenues: oFetch(profileJS, 'accessibleVenues'),
    validateAvatarForCreateUrl: oFetch(profileJS, 'validateAvatarForCreateUrl'),
    permissionsData: oFetch(profileJS, 'permissionsData'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        startEditProfile,
        cancelEditProfile,
        startEnableProfile,
        cancelEnableProfile,
        showDisableMossStaffMemberModal,
        hideDisableMossStaffMemberModal,
        disableMossStaffMemberRequest,
        showEditAvatarModal,
        hideEditAvatarModal,
        markRetakeAvatar,
      },
      dispatch,
    ),
  };
};

const isActive = (currentPage, page) => {
  return currentPage === page ? 'boss-button_state_active' : '';
};

function ProfileWrapper(props) {
  const [
    mossStaffMember,
    editProfile,
    enableProfile,
    accessibleVenues,
    mossStaffTypes,
    mossPayRates,
    disableMossStaffMemberModal,
    editAvatarModal,
    currentPage,
    validateAvatarForCreateUrl,
    venues,
    permissionsData,
  ] = oFetch(
    props,
    'mossStaffMember',
    'editProfile',
    'enableProfile',
    'accessibleVenues',
    'mossStaffTypes',
    'mossPayRates',
    'disableMossStaffMemberModal',
    'editAvatarModal',
    'currentPage',
    'validateAvatarForCreateUrl',
    'venues',
    'permissionsData',
  );

  const propActions = oFetch(props, 'actions');
  const children = oFetch(props, 'children');
  const startEditProfile = oFetch(propActions, 'startEditProfile');
  const cancelEditProfile = oFetch(propActions, 'cancelEditProfile');
  const startEnableProfile = oFetch(propActions, 'startEnableProfile');
  const cancelEnableProfile = oFetch(propActions, 'cancelEnableProfile');
  const markRetakeAvatar = oFetch(propActions, 'markRetakeAvatar');
  const showDisableMossStaffMemberModal = oFetch(propActions, 'showDisableMossStaffMemberModal');
  const hideDisableMossStaffMemberModal = oFetch(propActions, 'hideDisableMossStaffMemberModal');
  const showEditAvatarModal = oFetch(propActions, 'showEditAvatarModal');
  const hideEditAvatarModal = oFetch(propActions, 'hideEditAvatarModal');
  const canChangePublicHolidays = oFetch(permissionsData, 'canChangePublicHolidays');

  const masterVenue = venues.find(venue => {
    return oFetch(venue, 'id') === oFetch(mossStaffMember, 'master_venue');
  });

  const profileProps = {
    accessibleVenues,
    mossStaffTypes,
    mossStaffMember,
    validateAvatarForCreateUrl,
    mossPayRates,
    masterVenue,
    canChangePublicHolidays
  };

  const editAvatarFormInitial = {
    avatar: oFetch(mossStaffMember, 'avatar'),
    validateAvatarForCreateUrl,
    setupForFacialRecognition: true,
  };
  const jobType = mossStaffTypes.find(
    type => oFetch(type, 'id') === oFetch(mossStaffMember, 'moss_staff_type'),
  );

  function renderCardActions() {
    const mossStaffMemberId = oFetch(mossStaffMember, 'id');

    return (
      <div className="boss-page-dashboard__switches">
        <a
          href={`/moss_staff_members/${mossStaffMemberId}/profile`}
          className={`${isActive(
            currentPage,
            'profile',
          )} boss-button boss-button_type_small boss-button_role_profile boss-page-dashboard__switch`}
        >
          Profile
        </a>
        <a
          href={`/moss_staff_members/${mossStaffMemberId}/holidays`}
          className={`${isActive(
            currentPage,
            'holidays',
          )} boss-button boss-button_type_small boss-button_role_holidays boss-page-dashboard__switch`}
        >
          Holidays
        </a>
        <a
          href={`/moss_staff_members/${mossStaffMemberId}/owed_hours`}
          className={`${isActive(
            currentPage,
            'owed_hours',
          )} boss-button boss-button_type_small boss-button_role_timelog boss-page-dashboard__switch`}
        >
          Owed hours
        </a>
      </div>
    );
  }

  function getHandleDisableMossStaffMemberSubmit(mossStaffMember) {
    return values => {
      if (oFetch(mossStaffMember, 'has_user')) {
        confirm(
          'This staff member has an associated user account. Disabling here will not disable the user and the will still be able to log in.',
          {
            actionButtonText: 'Confirm',
            title: 'WARNING !!!',
          },
        ).then(() => {
          return oFetch(propActions, 'disableMossStaffMemberRequest')(values.toJS());
        });
      } else {
        return oFetch(propActions, 'disableMossStaffMemberRequest')(values.toJS());
      }
    };
  }

  function handleMarkRetakeAvatar(handleClose, { mossStaffMemberId }) {
    return markRetakeAvatar(mossStaffMemberId).finally(resp => {
      handleClose();
    });
  }

  function handleMarkRetakeAvatarModal() {
    const mossStaffMemberId = oFetch(mossStaffMember, 'id');

    openWarningModal({
      submit: handleMarkRetakeAvatar,
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Force Retake',
      },
      props: { mossStaffMemberId },
    });
  }

  return (
    <div>
      <ContentModal
        show={disableMossStaffMemberModal}
        onClose={() => hideDisableMossStaffMemberModal()}
        title="Disable staff member"
      >
        <DisableMossStaffMemberForm
          initialValues={{ neverRehire: false, reason: '' }}
          onSubmit={getHandleDisableMossStaffMemberSubmit(mossStaffMember)}
        />
      </ContentModal>
      <ContentModal show={editAvatarModal} onClose={() => hideEditAvatarModal()} title="Edit Avatar">
        <EditAvatarForm initialValues={editAvatarFormInitial} />
      </ContentModal>
      <DashboardWrapper>
        {editProfile && (
          <DashboardProfile title="Edit Profile" onCancel={cancelEditProfile} buttonText="Cancel editing" />
        )}
        {enableProfile && (
          <DashboardProfile
            title={`Enable Staff Member`}
            onCancel={cancelEnableProfile}
            buttonText="Cancel enabling"
          />
        )}
        {!editProfile && !enableProfile && (
          <Dashboard>
            <div className="boss-page-dashboard__group">
              <MossStaffMemberCard
                mossStaffMember={mossStaffMember}
                jobType={jobType}
                onEditAvatar={showEditAvatarModal}
                onMarkRetakeAvatar={handleMarkRetakeAvatarModal}
                permissionsData={permissionsData}
              />
              <MossStaffMemberProfileActions
                mossStaffMember={mossStaffMember}
                onEditProfile={() => startEditProfile()}
                onDisableMossStaffMember={() => showDisableMossStaffMemberModal()}
                onEnableProfile={() => startEnableProfile()}
              />
            </div>
            {renderCardActions()}
          </Dashboard>
        )}
      </DashboardWrapper>

      <ContentWrapper>
        {editProfile && <EditProfilePage {...profileProps} />}
        {enableProfile && <EnableProfilePage {...profileProps} />}
        {!editProfile && !enableProfile && <div className="boss-page-main__flow">{children}</div>}
      </ContentWrapper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper);
