import oFetch from 'o-fetch';
import { createAction } from 'redux-actions';
import { SubmissionError } from 'redux-form';
import { bossRequestHttp } from '@/lib/request-api';
import * as types from './types';
import { BOSS_STAFF_TYPE, MOSS_STAFF_TYPE } from './constants';
import utils from '@/lib/utils';


export const loadInitialStateAction = createAction(types.LOAD_INITIAL_STATE);
export const filterEffectiveStaffTypeAction = createAction(types.FILTER_EFFECTIVE_STAFF_TYPE);
export const filterNameAction = createAction(types.FILTER_NAME);
export const clockOutAction = createAction(types.CLOCK_OUT);
export const setHoursConfirmationPeriodStatusAction = createAction(types.SET_HOURS_ACCEPTANCE_PERIOD_STATUS);
export const acceptHoursAcceptancePeriod = createAction(types.ACCEPT_HOURS_ACCEPTANCE_PERIOD);
export const updatePeriodData = createAction(types.UPDATE_PERIOD_DATA);
export const addNewAcceptancePeriodAction = createAction(types.ADD_NEW_ACCEPTANCE_PERIOD);
export const removeHoursAcceptancePeriodAction = createAction(types.REMOVE_HOURS_ACCEPTANCE_PERIOD);
export const reEnableHoursAcceptancePeriod = createAction(types.RE_ENABLE_HOURS_ACCEPTANCE_PERIOD);

export const clockOut = params => (dispatch, getState) => {
  const [effectiveStaffMemberId, mDate, venueId, applicationType] = oFetch(
    params,
    'effectiveStaffMemberId',
    'mDate',
    'venueId',
    'applicationType',
  );
  const setClockOutErrors = oFetch(params, 'setClockOutErrors');

  let effectiveStaffMemberUrlFragment = '';
  if (applicationType === BOSS_STAFF_TYPE) {
    effectiveStaffMemberUrlFragment = 'staff_members';
  } else if (applicationType === MOSS_STAFF_TYPE) {
    effectiveStaffMemberUrlFragment = 'moss_staff_members';
  } else {
    throw new Error(`unsuppoted applicationType; ${applicationType}`);
  }
  const clockOutUrl = `/api/v1/${effectiveStaffMemberUrlFragment}/${effectiveStaffMemberId}/clock_out`;

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });

        const rawBaseErrors = oFetch(errors, 'base');
        const normalizedBaseErrors = Array.isArray(rawBaseErrors) ? rawBaseErrors : [rawBaseErrors];
        setClockOutErrors(normalizedBaseErrors);
        return true;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      setClockOutErrors(null);
      dispatch(clockOutAction(data));
    },
  }).post(clockOutUrl, {
    date: mDate.format(utils.commonDateFormat),
    venueId,
  });
};

export const unacceptHoursAcceptancePeriod = params => (dispatch, getState) => {
  const frontendId = oFetch(params, 'frontendId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error'],
        });

        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      dispatch(setHoursConfirmationPeriodStatusAction({ status: 'pending', frontendId }));
    },
  }).post(`/api/v1/hours_acceptance_periods/${frontendId}/unaccept`);
};

export const deleteHoursAcceptancePeriodAction = params => (dispatch, getState) => {
  const hoursAcceptancePeriodId = oFetch(params, 'id');
  const frontendId = oFetch(params, 'frontendId');

  if (hoursAcceptancePeriodId === null) {
    dispatch(removeHoursAcceptancePeriodAction({ frontendId }));
  } else {
    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error'],
          });

          throw new SubmissionError(errors);
        }
      },
      successHandler(params) {
        const hoursAcceptancePeriod = oFetch(params, 'data.hoursAcceptancePeriod');
        dispatch(removeHoursAcceptancePeriodAction({ hoursAcceptancePeriod }));
      },
    }).delete(`/api/v1/hours_acceptance_periods/${hoursAcceptancePeriodId}`);
  }
};

export const reEnableHoursAcceptancePeriodAction = params => (dispatch, getState) => {
  const hoursAcceptancePeriodId = oFetch(params, 'hoursAcceptancePeriod.id');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['_error'],
        });

        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const hoursAcceptancePeriod = oFetch(params, 'data.hoursAcceptancePeriod');

      dispatch(reEnableHoursAcceptancePeriod({ hoursAcceptancePeriod }));
    },
  }).post(`/api/v1/hours_acceptance_periods/${hoursAcceptancePeriodId}/re_enable`);
};

export const acceptHoursAcceptancePeriodAction = (hoursAcceptancePeriod, closeModal = () => {}) => (
  dispatch,
  getState,
) => {
  const [id, frontendId, venueId, appType] = oFetch(
    hoursAcceptancePeriod,
    'id',
    'frontendId',
    'venueId',
    'appType',
  );
  const isMoss = appType === MOSS_STAFF_TYPE;

  if (id === null) {
    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          const supportedKeys = ['_error', 'startsAt', 'endsAt', 'specialPayRateSecurityHours'];
          if (isMoss) {
            supportedKeys.push('mossHourTagIds');
          }

          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys,
          });
          closeModal();
          throw new SubmissionError(errors);
        }
        closeModal();
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const hoursAcceptancePeriod = oFetch(data, 'hoursAcceptancePeriod');
        dispatch(acceptHoursAcceptancePeriod({ hoursAcceptancePeriod, frontendId }));
        closeModal();
      },
    }).post(`/api/v1/hours_acceptance_periods`, {
      ...hoursAcceptancePeriod,
      venueId: venueId,
      status: 'accepted',
    });
  } else {
    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          const supportedKeys = ['_error', 'startsAt', 'endsAt', 'breaks', 'specialPayRateSecurityHours'];
          if (isMoss) {
            supportedKeys.push('mossHourTagIds');
          }
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys,
          });
          closeModal();
          throw new SubmissionError(errors);
        }
        closeModal();
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const [hoursAcceptancePeriod, specialPayRateSecurityHours] = oFetch(
          data,
          'hoursAcceptancePeriod',
          'specialPayRateSecurityHours',
        );
        dispatch(
          acceptHoursAcceptancePeriod({
            hoursAcceptancePeriod,
            frontendId,
            specialPayRateSecurityHours,
          }),
        );
        closeModal();
      },
    }).put(`/api/v1/hours_acceptance_periods/${id}`, {
      ...hoursAcceptancePeriod,
      status: 'accepted',
    });
  }
};
