export const AMBER_LEVEL_KEY = 'amber';
export const RED_LEVEL_KEY = 'red';

export const ACTIVE_STATE = 'active';
export const ACTIVE_RED_STATE = 'activeRed';
export const ACTIVE_AMBER_STATE = 'activeAmber';
export const PENDING_STATE = 'pending';
export const DISABLED_STATE = 'disabled';

export const BADGES_ORDER = [ACTIVE_RED_STATE, ACTIVE_AMBER_STATE, PENDING_STATE, DISABLED_STATE];
