import React, { useState, useRef } from 'react';
import oFetch from 'o-fetch';

import Select from '@/lib/boss-react-select';
import { UserWithRoleOption, UserWithRoleValue } from '@/components/boss-form/colored-select';

export function AssignFormField(props) {
  const [options, currentUserId] = oFetch(props, 'options', 'currentUserId');

  const input = oFetch(props, 'input');
  const [name, value, onChange] = oFetch(input, 'name', 'value', 'onChange');
  const [checked, setChecked] = useState(value === currentUserId);
  const previousSelected = useRef(value);

  function handleChange(selected) {
    previousSelected.current = selected;
    if (selected === currentUserId) {
      setChecked(true);
    }
    onChange(selected);
  }

  function handleCheckboxChange() {
    setChecked(prev => {
      const newCheckboxValue = !prev;
      if (newCheckboxValue) {
        onChange(currentUserId);
      } else {
        onChange(previousSelected.current);
      }
      return newCheckboxValue;
    });
  }

  return (
    <>
      <div className="boss-form__field">
        <label className={'boss-form__checkbox-label'}>
          <input
            type={'checkbox'}
            checked={checked}
            className="boss-form__checkbox-input"
            onChange={handleCheckboxChange}
          />
          <span className="boss-form__checkbox-label-text">Assign on me</span>
        </label>
      </div>
      <div className="boss-form__field">
        <div className={'boss-form__select boss-form__select_role_staff-member'}>
          <Select
            name={name}
            value={value}
            valueKey={'id'}
            labelKey={'fullName'}
            onChange={handleChange}
            clearable={false}
            valueComponent={UserWithRoleValue}
            optionComponent={UserWithRoleOption}
            simpleValue
            multi={false}
            placeholder={'Select user ...'}
            options={options}
            disabled={checked}
          />
        </div>
      </div>
    </>
  );
}
