import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import sortBy from 'lodash/sortBy';
import utils from '@/lib/utils';

export const reminderSelector = state => oFetch(state, 'reminders');

export const recurrenceTypesSelector = state => oFetch(state, 'static.recurrenceTypes');

export const singleRecurringTypeSelector = state => oFetch(state, 'static.singleRecurringType');

export const reminderDatesSelector = state => oFetch(state, 'reminderDates');

export const permissionsSelector = state => oFetch(state, 'permissions');

export const selectedDateSelector = state => oFetch(state, 'static.selectedDate');

export const viewStartDateSelector = state => oFetch(state, 'static.viewStartDate');

export const viewEndDateSelector = state => oFetch(state, 'static.viewEndDate');

export const eventsSelector = state => oFetch(state, 'events');

export const remindersSelector = state => oFetch(state, 'reminders');

export const tasksSelector = state => oFetch(state, 'tasks');

export const postponedTasksHistorySelector = state => oFetch(state, 'postponedTasksHistory');

export const globalEventTypeSelector = state => oFetch(state, 'static.globalEventType');

export const localEventTypeSelector = state => oFetch(state, 'static.localEventType');

export const currentVenueSelector = state => oFetch(state, 'static.currentVenue');

export const eventTypesOptionsSelector = state => oFetch(state, 'static.eventTypesOptions');

export const venuesSelector = state => oFetch(state, 'static.venues');

export const sortIndicesSelector = state => oFetch(state, 'sortIndices');

export const tasksSortIndicesSelector = createSelector([sortIndicesSelector], sortIndices => {
  return sortIndices.filter(sortIndex => oFetch(sortIndex, 'type') === 'task');
});

export const remindersSortIndicesSelector = createSelector([sortIndicesSelector], sortIndices => {
  return sortIndices.filter(sortIndex => oFetch(sortIndex, 'type') === 'reminder');
});

export const eventsSortIndicesSelector = createSelector([sortIndicesSelector], sortIndices => {
  return sortIndices.filter(sortIndex => oFetch(sortIndex, 'type') === 'event');
});

export const getSelectedDateTasks = createSelector(
  [tasksSelector, postponedTasksHistorySelector, tasksSortIndicesSelector, selectedDateSelector],
  (tasks, postponedTasksHistory, tasksSortIndices, selectedDate) => {
    return tasks.filter(task => oFetch(task, 'date') === selectedDate).map(task => {
      const taskGUID = oFetch(task, 'guid');
      const taskHistory = postponedTasksHistory[taskGUID];
      let taskSortIndex = tasksSortIndices.find(taskIndex => oFetch(taskIndex, 'typeId') === oFetch(task, 'id'));
      if (!taskSortIndex) {
        taskSortIndex = {
          id: null,
          sortIndex: 0,
          type: oFetch(task, 'type'),
          typeId: oFetch(task, 'id'),
        };
      }
      const [id, sortIndex, type, typeId] = oFetch(taskSortIndex, 'id', 'sortIndex', 'type', 'typeId');
      return {
        ...task,
        sortIndex: sortIndex,
        sortData: JSON.stringify({ id, type, typeId }),
        history: taskHistory ? [...taskHistory].reverse() : [],
      };
    });
  },
);

export const getSelectedDateReminderDates = createSelector(
  [
    remindersSelector,
    reminderDatesSelector,
    remindersSortIndicesSelector,
    selectedDateSelector,
    singleRecurringTypeSelector,
    recurrenceTypesSelector,
  ],
  (reminders, reminderDates, remindersSortIndices, selectedDate, singleRecurringType, recurrenceTypes) => {
    return reminderDates.filter(reminderDate => oFetch(reminderDate, 'date') === selectedDate).map(reminderDate => {
      const reminderId = oFetch(reminderDate, 'handoverPlannerReminderId');
      const reminder = reminders.find(reminder => {
        return oFetch(reminder, 'id') === reminderId;
      });
      if (!reminder) {
        throw new Error('no reminder found with id' + reminderId);
      }

      let reminderSortIndex = remindersSortIndices.find(
        reminderIndex => oFetch(reminderIndex, 'typeId') === oFetch(reminder, 'id'),
      );
      if (!reminderSortIndex) {
        reminderSortIndex = {
          id: null,
          sortIndex: 0,
          type: oFetch(reminder, 'type'),
          typeId: oFetch(reminder, 'id'),
        };
      }
      const recurrenceType = oFetch(reminderDate, 'recurrenceType');
      const [id, sortIndex, type, typeId] = oFetch(reminderSortIndex, 'id', 'sortIndex', 'type', 'typeId');
      return {
        ...reminderDate,
        isSingleType: recurrenceType === singleRecurringType,
        recurrenceTypeText: recurrenceTypes[recurrenceType],
        sortIndex: sortIndex,
        sortData: JSON.stringify({ id, type, typeId }),
      };
    });
  },
);

export const getEventTypesOptions = createSelector([eventTypesOptionsSelector], eventTypesOptions => {
  return Object.entries(eventTypesOptions).map(entry => {
    const [value, label] = entry;
    return {
      value,
      label,
    };
  });
});

export const getRecurrenceTypesOptions = createSelector([recurrenceTypesSelector], recurrenceTypes => {
  return Object.entries(recurrenceTypes).map(entry => {
    const [value, label] = entry;
    return {
      value,
      label,
    };
  });
});

export const getSelectedDateEvents = createSelector(
  [eventsSelector, venuesSelector, eventsSortIndicesSelector, selectedDateSelector],
  (events, venues, eventsSortIndices, selectedDate) => {
    return events.filter(event => oFetch(event, 'date') === selectedDate).map(event => {
      const eventVenues = venues.filter(venue => {
        return oFetch(event, 'forVenuesIds').includes(oFetch(venue, 'id'));
      });
      let eventSortIndex = eventsSortIndices.find(eventIndex => oFetch(eventIndex, 'typeId') === oFetch(event, 'id'));
      if (!eventSortIndex) {
        eventSortIndex = {
          id: null,
          sortIndex: 0,
          type: oFetch(event, 'type'),
          typeId: oFetch(event, 'id'),
        };
      }
      const [id, sortIndex, type, typeId] = oFetch(eventSortIndex, 'id', 'sortIndex', 'type', 'typeId');
      return {
        ...event,
        sortIndex: sortIndex,
        sortData: JSON.stringify({ id, type, typeId }),
        venues:
          eventVenues.length === venues.length
            ? 'All Venues'
            : eventVenues.map(eventVenue => oFetch(eventVenue, 'name')).join(', '),
      };
    });
  },
);

export const getActiveReminderDates = createSelector([reminderDatesSelector], reminderDates => {
  return reminderDates.filter(reminderDate => oFetch(reminderDate, 'state') === 'active');
});

export const getActiveTasks = createSelector([tasksSelector], tasks => {
  return tasks.filter(task => oFetch(task, 'active') === true);
});

export const getInactiveTasks = createSelector([tasksSelector], tasks => {
  return tasks.filter(
    task =>
      oFetch(task, 'completed') === true || oFetch(task, 'postponed') === true || oFetch(task, 'declined') === true,
  );
});

export const getInactiveReminderDates = createSelector([reminderDatesSelector], reminderDates => {
  return reminderDates.filter(reminderDate => oFetch(reminderDate, 'state') === 'completed');
});

export const getEventsCount = createSelector([eventsSelector], events => {
  const groupByDate = utils.groupBy('date');

  return Object.entries(groupByDate(events)).reduce((acc, entry) => {
    const [key, eventsArray] = entry;
    return {
      ...acc,
      [key]: eventsArray.length,
    };
  }, {});
});

export const getRemindersCountDataByDate = createSelector([reminderDatesSelector], reminderDates => {
  const groupByDate = utils.groupBy('date');
  return Object.entries(groupByDate(reminderDates)).reduce((reminderDateData, remindersDatesByDate) => {
    const [date, reminderDatesArray] = remindersDatesByDate;
    const remindersDatesTallyForDate = reminderDatesArray.reduce(
      (tally, reminderDate) => {
        const reminderDateStatus = oFetch(reminderDate, 'state');
        switch (reminderDateStatus) {
          case 'completed':
            return {
              ...tally,
              completedCount: oFetch(tally, 'completedCount') + 1,
            };
          case 'active':
            return {
              ...tally,
              activeCount: oFetch(tally, 'activeCount') + 1,
            };
          case 'deleted':
            return {
              ...tally,
              deletedCount: oFetch(tally, 'deletedCount') + 1,
            };
          default:
            throw new Error("Unsupport status '" + reminderDateStatus + "' encountered");
        }
      },
      {
        activeCount: 0,
        completedCount: 0,
        deletedCount: 0,
      },
    );

    return {
      ...reminderDateData,
      [date]: {
        total: reminderDatesArray.length,
        ...remindersDatesTallyForDate,
      },
    };
  }, {});
});

export const getTasksCountDataByDate = createSelector([tasksSelector], tasks => {
  const groupByDate = utils.groupBy('date');
  return Object.entries(groupByDate(tasks)).reduce((acc, entry) => {
    const [key, tasksArray] = entry;
    const tasksOnDate = tasksArray.reduce(
      (acc, task) => {
        if (oFetch(task, 'active') === true) {
          return {
            ...acc,
            active: oFetch(acc, 'active') + 1,
          };
        }
        if (oFetch(task, 'postponed') === true) {
          return {
            ...acc,
            postponed: oFetch(acc, 'postponed') + 1,
          };
        }
        if (oFetch(task, 'declined') === true) {
          return {
            ...acc,
            declined: oFetch(acc, 'declined') + 1,
          };
        }
        if (oFetch(task, 'completed') === true) {
          return {
            ...acc,
            completed: oFetch(acc, 'completed') + 1,
          };
        }
      },
      {
        active: 0,
        postponed: 0,
        declined: 0,
        completed: 0,
      },
    );
    return {
      ...acc,
      [key]: { total: tasksArray.length, ...tasksOnDate },
    };
  }, {});
});

export const getSelectedDateActiveEvents = createSelector([getSelectedDateEvents], events => {
  return events.filter(event => oFetch(event, 'completed') === false);
});

export const getSelectedDateInactiveEvents = createSelector([getSelectedDateEvents], events => {
  return events.filter(event => oFetch(event, 'completed') === true);
});

export const getSelectedDateActiveReminderDates = createSelector([getSelectedDateReminderDates], reminderDates => {
  return reminderDates.filter(reminderDate => oFetch(reminderDate, 'state') === 'active');
});

export const getSelectedDateInactiveReminderDates = createSelector([getSelectedDateReminderDates], reminderDates => {
  return reminderDates.filter(reminder => oFetch(reminder, 'state') === 'completed');
});

export const getSelectedDateActiveTasks = createSelector([getSelectedDateTasks], tasks => {
  return tasks.filter(task => oFetch(task, 'active') === true);
});

export const getSelectedDateInactiveTasks = createSelector([getSelectedDateTasks], tasks => {
  return tasks.filter(
    task =>
      oFetch(task, 'completed') === true || oFetch(task, 'postponed') === true || oFetch(task, 'declined') === true,
  );
});

export const getActiveHandoverPlannerItems = createSelector(
  [getSelectedDateActiveEvents, getSelectedDateActiveReminderDates, getSelectedDateActiveTasks, selectedDateSelector],
  (events, reminderDates, tasks, selectedDate) => {
    const items = [...events, ...reminderDates, ...tasks];
    return sortBy(items, [i => oFetch(i, 'sortIndex')]);
  },
);

export const getInactiveHandoverPlannerItems = createSelector(
  [
    getSelectedDateInactiveEvents,
    getSelectedDateInactiveReminderDates,
    getSelectedDateInactiveTasks,
    selectedDateSelector,
  ],
  (events, reminderDates, tasks, selectedDate) => {
    const items = [...events, ...reminderDates, ...tasks];
    return sortBy(items, [i => oFetch(i, 'sortIndex')]);
  },
);
