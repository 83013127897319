import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '../components/content-wrapper';
import groupBy from 'lodash/groupBy';
import { CountingOverviewDashboard, CountingOverviewTable, CountingOverviewTableRow } from './components';
import { getTotalCounts, getVenuesWeekCounts } from '../utils';

export default function CountingOverview(props) {
  const [weekStart, weekEnd, weekDaysCounts, venues, lastVisited] = oFetch(
    props,
    'weekStart',
    'weekEnd',
    'weekDaysCounts',
    'venues',
    'lastVisited',
  );
  const groupedByVenueWeekCounts = groupBy(weekDaysCounts, (w) => oFetch(w, 'venueId'));
  const normalizedWeekCounts = getVenuesWeekCounts({ venues, groupedByVenueWeekCounts, weekStart });
  const normalizedTotalCounts = getTotalCounts({ venues, groupedByVenueWeekCounts });
  const allVenuesCompleted = normalizedWeekCounts.every((weekCount) => oFetch(weekCount, 'status') === 'completed');

  function renderCounting(counting) {
    return <CountingOverviewTableRow counting={counting} weekStart={weekStart} />;
  }

  return (
    <div>
      <CountingOverviewDashboard
        title="Counting Overview"
        allVenuesCompleted={allVenuesCompleted}
        lastVisited={lastVisited}
        weekStart={weekStart}
        weekEnd={weekEnd}
      />
      <ContentWrapper>
        <CountingOverviewTable
          tableItems={normalizedWeekCounts}
          totals={normalizedTotalCounts}
          renderRow={renderCounting}
        />
      </ContentWrapper>
    </div>
  );
}
