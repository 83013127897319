import React from 'react';
import oFetch from 'o-fetch';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

export default function WarningModal(props) {
  const [onClose, onSubmit, text, buttonText, title] = oFetch(
    props,
    'onClose',
    'onSubmit',
    'text',
    'buttonText',
    'title',
  );

  return (
    <LegacyCloseOutsideModalWrapper
      onClose={onClose}
      title={title}
    >
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">{text}</span>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onSubmit}
          type="button"
          className="boss-button boss-button_role_cancel"
        >
          {buttonText}
        </button>
      </div>
    </LegacyCloseOutsideModalWrapper>
  );
}
