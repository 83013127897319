import Trix from 'trix';
import cn from 'classnames';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';
import oFetch from 'o-fetch';
import React from 'react';

Trix.config.blockAttributes.heading1 = {
  tagName: 'h1',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

BossFormTrixRteField.defaultProps = {
  label: null,
};

export function BossFormTrixRteField(props) {
  const [
    name,
    onChange,
    defaultValue,
    meta,
    label,
  ] = oFetch(
    props,
    'name',
    'onChange',
    'defaultValue',
    'meta',
    'label',
  );

  const { submitError, touched } = meta;

  const hasError = touched && submitError;

  function handleChange(event, newValue) {
    onChange(newValue);
  }

  const containerClassNames = cn('wysiwyg-editor', { 'wysiwyg-editor_state_error': hasError });

  return (
    <>
      {label && (
        <p className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </p>
      )}
      <div className={containerClassNames}>
        <ReactTrixRTEToolbar
          toolbarId="react-trix-rte-editor"
          toolbarActions={[
            'bold',
            'italic',
            'strike',
            'link',
            'quote',
            'code',
            'bullet',
            'number',
          ]}
        />
        <ReactTrixRTEInput
          toolbarId="react-trix-rte-editor"
          name={name}
          defaultValue={defaultValue}
          onChange={handleChange}
          onInitialize={event => {
            const { toolbarElement } = event.target;
            const blockTools = toolbarElement.querySelector('[data-trix-button-group=block-tools]');
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading1" title="Heading 1" tabindex="-1" data-trix-active="">H1</button>
              `;
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
              `;
            blockTools.innerHTML += `
                <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
              `;
          }}
        />
      </div>
      {hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            {submitError.map((errorItem, index) => {
              return (
                <span key={index} className="boss-form__error-line">
                  {errorItem}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </>
  );
}
