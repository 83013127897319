import oFetch from 'o-fetch';
import React from 'react';
import utils from '@/lib/utils';
import { LegacyDefaultModalWrapper } from './legacy-default-modal-wrapper';

export default function WithoutCloseModalWrapper(props) {
  const [children, title, baseClassName] = oFetch(props, 'children', 'title', 'baseClassName');

  function renderTitle() {
    if (utils.isFunction(title)) {
      return title();
    }
    return <div className="boss-modal-window__header">{title}</div>;
  }

  return (
    <LegacyDefaultModalWrapper onRequestClose={() => {}} baseClassName={baseClassName}>
      {renderTitle()}
      <div className="boss-modal-window__content">{children}</div>
    </LegacyDefaultModalWrapper>
  );
}

WithoutCloseModalWrapper.defaultProps = {
  baseClassName: 'boss-modal-window',
};
