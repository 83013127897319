import React from 'react';
import oFetch from 'o-fetch';
import VenueFilter from './requests-venue-filter';
import RotaFilter from '@/components/security-rota/security-rota-filter';
import WeekFilter from './requests-week-filter';

function RequestsFilter(props) {
  const selectedVenues = oFetch(props, 'selectedVenues');
  const onVenueChange = oFetch(props, 'onVenueChange');
  const counts = oFetch(props, 'counts');
  const totalCount = oFetch(props, 'totalCount');
  const onWeekChange = oFetch(props, 'onWeekChange');
  const sDate = oFetch(props, 'sDate');

  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <div className="boss-form__row  boss-form__row_justify_space">
          <VenueFilter onChangeSelectedVenues={onVenueChange} selectedVenues={selectedVenues} />
          <RotaFilter page="requests" securityShiftRequestsCount={totalCount} sCurrentRotaDate={sDate} />
        </div>
        <WeekFilter
          sDate={sDate}
          disabled={oFetch(props, 'disabled')}
          onChange={onWeekChange}
          counts={counts}
        />
      </div>
    </div>
  );
}

export default RequestsFilter;
