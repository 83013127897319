import { useReducer, useMemo } from 'react';
import { createAction, handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

const ADD_PAY_RATE = 'ADD_PAY_RATE';
const UPDATE_PAY_RATE = 'UPDATE_PAY_RATE';
const DELETE_PAY_RATE = 'DELETE_PAY_RATE';

const addMossPayRateAction = createAction(ADD_PAY_RATE);
const updateMossPayRateAction = createAction(UPDATE_PAY_RATE);
const deleteMossPayRateAction = createAction(DELETE_PAY_RATE);

const mossPayRatesReducer = handleActions(
  {
    [ADD_PAY_RATE]: (state, action) => {
      const mossPayRate = oFetch(action, 'payload.mossPayRate');
      return [...state, mossPayRate];
    },
    [UPDATE_PAY_RATE]: (state, action) => {
      const mossPayRateToUpdate = oFetch(action, 'payload.mossPayRate');
      return state.map((mossPayRate) => {
        const mossPayRateId = oFetch(mossPayRate, 'id');
        const mossPayRateToUpdateId = oFetch(mossPayRateToUpdate, 'id');
        if (mossPayRateId === mossPayRateToUpdateId) {
          return mossPayRateToUpdate;
        }
        return mossPayRate;
      });
    },
    [DELETE_PAY_RATE]: (state, action) => {
      const mossPayRateToDelete = oFetch(action, 'payload.mossPayRate');
      const mossPayRateToDeleteId = oFetch(mossPayRateToDelete, 'id');
      return state.filter((mossPayRate) => oFetch(mossPayRate, 'id') !== mossPayRateToDeleteId);
    },
  },
  [],
);

function normalizeMossPayRate(params) {
  const [mossPayRates, mossStaffMemberCounts] = oFetch(params, 'mossPayRates', 'mossStaffMemberCounts');
  return mossPayRates.reduce(
    (acc, mossPayRate) => {
      const mossPayRateId = oFetch(mossPayRate, 'id');
      const counts = mossStaffMemberCounts[mossPayRateId] || 0;
      const normalizedMossPayRate = {
        ...mossPayRate,
        mossStaffMemberCounts: counts,
      };

      return {
        ...acc,
        normalRates: [...acc.normalRates, normalizedMossPayRate],
      };
    },
    {
      normalRates: [],
    },
  );
}

export default function useMossPayRatesApiCalls(initialData) {
  const [mossPayRates, mossStaffMemberCounts, HOURLY_CALCULATION_TYPE] = oFetch(
    initialData,
    'mossPayRates',
    'mossStaffMemberCounts',
    'HOURLY_CALCULATION_TYPE',
  );
  const [mossPayRatesState, dispatchMossPayRates] = useReducer(mossPayRatesReducer, mossPayRates);

  const normalRates = oFetch(
    useMemo(() => normalizeMossPayRate({ mossPayRates: mossPayRatesState, mossStaffMemberCounts }), [
      mossPayRatesState,
      mossStaffMemberCounts,
    ]),
    'normalRates',
  );
  const mossPayRatesOptions = [
    { value: HOURLY_CALCULATION_TYPE, label: 'Incremental Per Hour' },
  ];

  function createMossPayRate(params) {
    const [values, handleClose] = oFetch(params, 'values', 'handleClose');

    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error', 'name', 'calculationType', 'rate'],
          });
          globalNotifications.showDefaultFailureMessage({ message: 'Create pay rate failed' });
          return Promise.resolve(errors);
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchMossPayRates(addMossPayRateAction(data));
        handleClose();
      },
    }).post(`/api/v1/moss_pay_rates`, {
      ...values,
    });
  }

  function updateMossPayRate(params) {
    const [values, handleClose] = oFetch(params, 'values', 'handleClose');
    const mossPayRateId = oFetch(values, 'id');

    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['_error', 'name', 'calculationType', 'rate'],
          });
          globalNotifications.showDefaultFailureMessage({ message: 'Create pay rate failed' });
          return Promise.resolve(errors);
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchMossPayRates(updateMossPayRateAction(data));
        handleClose();
      },
    }).put(`/api/v1/moss_pay_rates/${mossPayRateId}`, {
      ...values,
    });
  }

  function deleteMossPayRate(params) {
    const mossPayRate = oFetch(params, 'mossPayRate');
    const mossPayRateId = oFetch(mossPayRate, 'id');

    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatchMossPayRates(deleteMossPayRateAction({ mossPayRate }));
      },
    }).delete(`/api/v1/moss_pay_rates/${mossPayRateId}`);
  }

  return {
    normalRates,
    mossPayRatesOptions,
    createMossPayRate,
    deleteMossPayRate,
    updateMossPayRate,
  };
}
