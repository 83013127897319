import React from 'react';

type Props = {
  whatsappFeatures: any[];
  renderItem: (feature: any) => React.JSX.Element
};

export function FeaturesTable(props: Props) {
  const { whatsappFeatures, renderItem } = props;

  function renderFeatures() {
    return whatsappFeatures.map((feature: any) => {
      const { id: featureId } = feature;
      return React.cloneElement(renderItem(feature), { key: featureId });
    });
  }

  return (
    <div className="boss-board__table">
      <div className="boss-board__scroll boss-board__scroll_type_native">
        <div className="boss-board__scroll-content">
          <div className="boss-table boss-table_page_staff-tracking-staff-added">
            <div className="boss-table__row">
              <div className="boss-table__cell boss-table__cell_role_header">Name</div>
              <div className="boss-table__cell boss-table__cell_role_header">Main Whatsapp Instance</div>
              <div className="boss-table__cell boss-table__cell_role_header">Fallback Wahtsapp Instance</div>
              <div className="boss-table__cell boss-table__cell_role_header" />
            </div>
            {renderFeatures()}
          </div>
        </div>
      </div>
    </div>
  );
}
