import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';
import AlertForm from './alert-form';

class AddAlert extends Component {
  render() {
    const onSubmit = oFetch(this.props, 'onSubmit');
    const level = oFetch(this.props, 'level');
    const initialValues = {
      title: null,
      message: null,
      level,
    };
    return (
      <AlertForm
        submitButtonText="Create"
        buttonClassName="boss-button_role_add"
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(AddAlert);
