import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export default function MossPayRateItem(props) {
  const [mossPayRate, onEditClick, onDeleteClick, itemAbilities] = oFetch(
    props,
    'mossPayRate',
    'onEditClick',
    'onDeleteClick',
    "itemAbilities",
  );
  const [name, formattedCalculationType, formattedRate, mossStaffMemberCounts, mossStaffMembersUrl] = oFetch(
    mossPayRate,
    'name',
    'formattedCalculationType',
    'formattedRate',
    'mossStaffMemberCounts',
    'mossStaffMembersUrl',
  );
  const hasDeleteAbility = oFetch(itemAbilities, "canDelete");
  const canBeDeleted = hasDeleteAbility && mossStaffMemberCounts === 0;
  const hasEditAbility = oFetch(itemAbilities, "canEdit");
  const canBeEdited = hasEditAbility;

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Name</p>
          <p className="boss-table__text">{name}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Staff Members</p>
          <p className="boss-table__text">
            <a href={mossStaffMembersUrl} className="boss-table__link">
              {mossStaffMemberCounts}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Calculation Type</p>
          <p className="boss-table__text">{formattedCalculationType}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Rate</p>
          <p className="boss-table__text">{formattedRate}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Actions</p>
          <div className="boss-table__actions">
            { canBeEdited && (
            <button
              onClick={onEditClick}
              type="button"
              className="boss-button boss-button_role_edit-light boss-button_type_extra-small boss-table__action"
            >
              Edit
            </button> ) }
            {canBeDeleted && (
              <AsyncButton
                className="boss-button boss-button_role_cancel-light boss-button_type_extra-small boss-table__action"
                text="Delete"
                pendingText="Deleting ..."
                onClick={() => onDeleteClick(mossPayRate)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
