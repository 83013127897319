import React from 'react';
import oFetch from 'o-fetch';

export default function ChecklistList(props) {
  const [itemRenderer, checklists] = oFetch(props, 'itemRenderer', 'checklists');

  function renderList() {
    return checklists.map(checklist => {
      const checklistId = oFetch(checklist, 'id');
      return React.cloneElement(itemRenderer(checklist), {
        key: checklistId.toString(),
      });
    });
  }

  return <div>{renderList()}</div>;
}
