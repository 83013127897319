import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormInput } from '@/components/boss-form';

export function UpdateCustomNameForm(props) {
  const [onSubmit, initialValues, processing] = oFetch(props, 'onSubmit', 'initialValues', 'processing');

  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          const disabled = submitting || processing;

          return (
            <div className="boss-form__field">
              <Field name="customDeviceName" component={BossFormInput} disabled={processing} />
              <button
                onClick={handleSubmit}
                disabled={disabled}
                className="boss-button boss-button_role_confirm boss-form__submit"
                type="button"
              >
                Update
              </button>
            </div>
          );
        }}
      />
    </div>
  );
}
