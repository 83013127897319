import React, { useState } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import cn from 'classnames';
import Spinner from '@/components/spinner';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { ServiceButton } from './service-button';
import { ReassignTask } from './reassign-task';

function Status(props) {
  const text = oFetch(props, 'text');
  return (
    <div className="boss-task__status">
      <span className="boss-task__status-label">Status: </span>
      <span className="boss-task__status-text">{text}</span>
    </div>
  );
}

export function TaskDetailsHeader(props) {
  const [marketingTask, onActivityClick, onEditClick, onRemoveClick, onGrab, onComplete] = oFetch(
    props,
    'marketingTask',
    'onActivityClick',
    'onEditClick',
    'onRemoveClick',
    'onGrab',
    'onComplete',
  );
  const [
    title,
    venueName,
    deadlineDate,
    isUpForGrabs,
    isInProgress,
    isInReview,
    isRejected,
    assignedUser,
    isAccepted,
    permissions,
    isDeadline,
    isEmergency,
    createdAt,
  ] = oFetch(
    marketingTask,
    'title',
    'venueName',
    'deadlineDate',
    'isUpForGrabs',
    'isInProgress',
    'isInReview',
    'isRejected',
    'assignedUser',
    'isAccepted',
    'permissions',
    'isDeadline',
    'isEmergency',
    'createdAt',
  );
  const [processing, setProcessing] = useState(false);

  const formattedDeadlineDate = safeMoment.uiDateParse(deadlineDate).format(utils.fullDayFormat);
  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatWithTime());
  const deadlineDateClassName = cn('boss-task__meta-text boss-task__meta-text_role_date', {
    'boss-task__meta-text_state_alert': isDeadline && !isAccepted,
  });

  const [canDisable, canUpdate, canComplete, canGrabMarketingTask] = oFetch(
    permissions,
    'canDisable',
    'canUpdate',
    'canComplete',
    'canGrabMarketingTask',
  );

  async function handleRemoveClick() {
    setProcessing(true);
    await onRemoveClick();
    setProcessing(false);
  }

  function renderStatusAndAction() {
    if (isAccepted) {
      return <Status text="Accepted" />;
    }
    if (isUpForGrabs) {
      return (
        <>
          {canGrabMarketingTask && (
            <div className="boss-task__actions">
              <AsyncButton
                className="boss-button boss-task__action"
                text="Grab"
                pendingText="Grabbing ..."
                onClick={onGrab}
              />
            </div>
          )}
          <Status text="Up For Grabs" />
        </>
      );
    }
    if (isInProgress) {
      if (isRejected) {
        return (
          <>
            {canComplete && (
              <div className="boss-task__actions">
                <button
                  onClick={onComplete}
                  type="button"
                  className="boss-button boss-button_role_complete-task boss-task__action"
                >
                  Complete Task
                </button>
              </div>
            )}
            <Status text="Rejected" />
          </>
        );
      }
      if (isInReview) {
        return <Status text="Requiring Review" />;
      }
      return (
        <>
          {canComplete && (
            <div className="boss-task__actions">
              <button
                onClick={onComplete}
                type="button"
                className="boss-button boss-button_role_complete-task boss-task__action"
              >
                Complete Task
              </button>
            </div>
          )}
          <Status text="IN PROGRESS" />
        </>
      );
    }
  }

  return (
    <div className="boss-task__header">
      <div className="boss-task__header-group">
        <h2 className="boss-task__title">
          {title}
          {isEmergency && (
            <span className="boss-task__title-status">
              <span className="boss-button boss-button_type_ultra-small boss-button_role_exclamation">
                Emergency
              </span>
            </span>
          )}
        </h2>
        {renderStatusAndAction()}
      </div>
      <div className="boss-task__header-group">
        <div className="boss-task__meta">
          <div className="boss-task__meta-group">
            <div className="boss-task__meta-item">
              <p className="boss-task__meta-text boss-task__meta-text_role_venue">{venueName}</p>
            </div>
            <div className="boss-task__meta-item">
              <p className={deadlineDateClassName}>Deadline: {formattedDeadlineDate}</p>
            </div>
            <div className="boss-task__meta-item">
              <p className="boss-task__meta-text boss-task__meta-text_role_date">
                Created at: {formattedCreatedAt}
              </p>
            </div>
            {assignedUser && <ReassignTask marketingTask={marketingTask} />}
          </div>
        </div>
        {processing && <Spinner />}
        {!processing && (
          <ServiceButton
            onActivityClick={onActivityClick}
            onEditClick={() => onEditClick(marketingTask)}
            onRemoveClick={handleRemoveClick}
            isAccepted={isAccepted}
            canEdit={canUpdate}
            canRemove={canDisable}
          />
        )}
      </div>
    </div>
  );
}
