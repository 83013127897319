import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';

import configureStore from './store';
import OpsDiaries from './containers/ops-diaries';

export function OpsDiaryApp(props) {
  const [store, setStore] = useState(null);
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore();
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <OpsDiaries />
    </Provider>
  );
}
