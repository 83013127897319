import React, { Component } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

export const DropDownListItem = props => {
  const [item, color, prefix] = oFetch(props, 'item', 'color', 'prefix');

  const [description, path] = oFetch(item, 'description', 'path');
  const { highlightedDescription } = item;

  return (
    <div className={`${prefix}-alias`}>
      <a href={path} className={`${prefix}-alias__link`}>
        <span
          className={`${prefix}-alias__icon ${prefix}-alias__icon_type_solid`}
          style={{ backgroundColor: color, borderColor: color }}
        >
          {utils.generateQuickMenuAlias(description)}
        </span>
        <span
          className={`${prefix}-alias__text`}
          style={{ color }}
          dangerouslySetInnerHTML={{ __html: highlightedDescription || description }}
        />
      </a>
    </div>
  );
};

export default function DropDownList(props) {
  const [items, prefix] = oFetch(props, 'items', 'prefix');

  return (
    <div className={`${prefix}-quick-access`}>
      {items.map((item, key) => (
        <div className={`${prefix}-quick-access__group`} key={key}>
          <div className={`${prefix}-quick-access__group-header`}>
            <h4
              className={`${prefix}-quick-access__group-title`}
              dangerouslySetInnerHTML={{ __html: item.highlightedName || item.name }}
            />
          </div>
          <div className={`${prefix}-quick-access__aliases`}>
            {item.items.map((childItem, key) => (
              <div key={key} className={`${prefix}-quick-access__alias`}>
                <DropDownListItem item={childItem} color={item.color} prefix={prefix} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
