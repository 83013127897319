import { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

const backendCurrentVersion = oFetch(window, 'boss.currentVersion');

const fiveMinutes = 5 * 60 * 1000;

export function useAppVersion(options = {}) {
  const [isNewVersionExist, setIsNewVersionExist] = useState(false);
  const currentVersion = new BigNumber(backendCurrentVersion);
  const { failedLimit = 5, interval = fiveMinutes } = options;

  useEffect(() => {
    checkVersionEvery();
  }, []);

  function checkVersionFromServer(params) {
    const onSuccess = oFetch(params, 'onSuccess');
    const onFailure = oFetch(params, 'onFailure');

    return bossRequestHttp({
      auth: false,
      spinner: false,
      errorHandler(params) {
        onFailure(params);
        return true;
      },
      successHandler(params) {
        onSuccess(params);
      },
    }).get(`/api/v1/version`);
  }

  function checkVersionEvery() {
    let failedCount = 0;

    const clear = setInterval(() => {
      checkVersionFromServer({
        onSuccess(params) {
          const versionFromServer = oFetch(params, 'data.version');
          const bnVersionFromServer = new BigNumber(versionFromServer);
          failedCount = 0;
          if (isVersionMismatch(bnVersionFromServer)) {
            clearInterval(clear);
            setIsNewVersionExist(true);
          }
        },
        onFailure(params) {
          failedCount++;
          if (failedCount === failedLimit) {
            failedCount = 0;
            const response = oFetch(params, 'response');
            clearInterval(clear);
            throw response;
          }
        },
      });
    }, interval);
  }

  function isVersionMismatch(versionFromServer) {
    return !currentVersion.eq(versionFromServer);
  }

  return {
    isNewVersionExist,
  };
}
