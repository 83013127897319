import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = null;

export default handleActions(
  {
    [types.SET_EDIT_CHECKLIST_ID]: (state, action) => {
      const checklistId = oFetch(action, 'payload.checklistId');
      return checklistId;
    },
    [types.UPDATE_CHECKLIST]: (state, action) => {
      return null;
    },
  },
  initialState,
);
