import React from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';
import { PendingVenueOption, PendingVenueValue } from '@/components/boss-form/colored-select';

export function VenuesFilter(props) {
  const venues = oFetch(props, 'venues');
  const [selectedVenues, onVenuesChange] = oFetch(props, 'selectedVenues', 'onVenuesChange');

  return (
    <div className="boss-form__field">
      <div className={'boss-form__select'}>
        <Select
          value={selectedVenues}
          valueKey={'id'}
          labelKey={'name'}
          onChange={onVenuesChange}
          optionComponent={PendingVenueOption}
          valueComponent={PendingVenueValue}
          clearable
          simpleValue
          multi
          placeholder={'Select venues ...'}
          options={venues}
        />
      </div>
    </div>
  );
}
