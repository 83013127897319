import React, { Component } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default class FilterItem extends Component {
  render() {
    const [item, iconClassName, title, count, onSelect, selected] = oFetch(
      this.props,
      'item',
      'item.iconClassName',
      'item.title',
      'item.count',
      'onSelect',
      'selected',
    );
    const itemClassName = cn('boss-page-main__control', {
      [iconClassName]: !!iconClassName,
      'boss-page-main__control_state_active': selected,
    });
    return (
      <button className={itemClassName} onClick={() => onSelect(item)}>
        {title} ({count})
      </button>
    );
  }
}
