import cn from 'classnames';
import oFetch from 'o-fetch';
import React from 'react';

export function ManagersList(props) {
  const [items, renderManagerItem] = oFetch(props, 'items', 'renderManagerItem');

  function renderManagersList() {
    return items.map(staffMember => {
      return React.cloneElement(renderManagerItem(staffMember), {
        key: oFetch(staffMember, 'id'),
      });
    });
  }

  return (
    <div className="boss-table boss-table_page_bonai-staff-member-data">
      <div className="boss-table__row boss-table__row_role_header">
        <div className="boss-table__cell boss-table__cell_role_header">Avatar</div>
        <div className="boss-table__cell boss-table__cell_role_header">Name</div>
        <div className="boss-table__cell boss-table__cell_role_header">Staff Type</div>
        <div className="boss-table__cell boss-table__cell_role_header">Hours Worked</div>
        <div className="boss-table__cell boss-table__cell_role_header">Paid Holidays</div>
        <div className="boss-table__cell boss-table__cell_role_header boss-table__cell_role_api-data">
          Bonus Tier
        </div>
        <div className="boss-table__cell boss-table__cell_role_header">Bonus Factor</div>
        <div className="boss-table__cell boss-table__cell_role_header">Bonus Total</div>
        <div className="boss-table__cell boss-table__cell_role_header">Status</div>
      </div>
      {renderManagersList()}
    </div>
  );
}
