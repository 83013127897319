import { handleActions } from 'redux-actions';
import queryString from 'query-string';
import * as constants from '../../constants';
import * as types from '../types';
const filterFromQuery = queryString.parse(window.location.search).filter;

const initialState = {
  activeFilter: Object.keys(constants.FILTER_TABS).includes(filterFromQuery)
    ? filterFromQuery
    : constants.FILTER_SHOW_CURRENT,
};

export default handleActions(
  {
    [types.CHANGE_FILTER]: (state, action) => {
      return {
        activeFilter: action.payload,
      };
    },
  },
  initialState,
);
