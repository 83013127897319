import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function LinkedUserRoleForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  const permittedRolesOptions = oFetch(useLegacyGlobal(), 'permittedRolesOptions');

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field
                  name={'role'}
                  label="Role"
                  parse={value => value}
                  options={permittedRolesOptions}
                  component={BossFormSelectNew}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-form__submit boss-form__submit_adjust_single"
                    disabled={submitting}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
