import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function TabItem(props) {
  const [active, pristine, persist, label, onClick, invalid] = oFetch(
    props,
    'active',
    'pristine',
    'persist',
    'label',
    'onClick',
    'invalid',
  );

  const tabClassNames = cn('purple-tab-nav__control', {
    'purple-tab-nav__control_state_active': active,
    'purple-tab-nav__control_state_ok': persist && !invalid,
    'purple-tab-nav__control_state_alert': invalid,
  });

  return (
    <button onClick={onClick} className={tabClassNames}>
      <span className="purple-tab-nav__control-text">
        {label}
        {!pristine && ' *'}
      </span>
    </button>
  );
}
