import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import * as types from './types';

import {
  acceptSecurityShiftRequestRequest,
  rejectSecurityShiftRequestRequest,
  undoSecurityShiftRequestRequest,
  updateSecurityShiftRequestRequest,
  getSecurityShiftRequestsByDateRequest,
} from '../requests';

import * as selectors from '../selectors';

export const loadInitialData = createAction(types.INITIAL_LOAD);
export const updateSecurityShiftRequestAction = createAction(types.UPDATE_SECURITY_SHIFT_REQUEST);

const updateForCurrentWeek = resp => (dispatch, getState) => {
  const weekDates = selectors.weekDatesSelector(getState());
  const chosenDate = selectors.chosenDateSelector(getState());
  const securityShiftRequest = oFetch(resp, 'data.securityShiftRequest');
  const rotaDate = oFetch(securityShiftRequest, 'rotaDate');
  if (weekDates.includes(rotaDate)) {
    if (chosenDate === rotaDate) {
      dispatch(updateSecurityShiftRequestAction({ securityShiftRequest }));
    } else {
      dispatch(removeShiftRequestAction({ securityShiftRequest }));
      dispatch(incrementForDayAction(rotaDate));
      dispatch(decrementForDayAction(chosenDate));
    }
  } else {
    dispatch(removeShiftRequestAction({ securityShiftRequest }));
    dispatch(decrementForDayAction(chosenDate));
  }
};

export const changeWeekDay = params => (dispatch, getState) => {
  const sChosenDate = oFetch(params, 'sChosenDate');
  dispatch(changeWeekDayAction({ sChosenDate }));
  return getSecurityShiftRequestsByDateRequest({
    sDate: sChosenDate,
    onSuccess(data) {
      dispatch(changeWeekDayComplete(data));
    },
  });
};

export const editSecurityShiftRequest = values => (dispatch, getState) => {
  const startsAt = oFetch(values, 'startsAt').toISOString();
  const endsAt = oFetch(values, 'endsAt').toISOString();
  const venueId = oFetch(values, 'venueId');
  const id = oFetch(values, 'id');
  const note = oFetch(values, 'note');

  return updateSecurityShiftRequestRequest({
    startsAt,
    endsAt,
    venueId,
    id,
    note,
  }).then(response => {
    dispatch(updateForCurrentWeek(response));
    return response;
  });
};

export const rejectSecurityShiftRequest = params => (dispatch, getState) => {
  const securityShiftRequestId = oFetch(params, 'id');
  const rejectReason = oFetch(params, 'rejectReason');

  return rejectSecurityShiftRequestRequest(securityShiftRequestId, rejectReason).then(response => {
    dispatch(updateForCurrentWeek(response));
    return response;
  });
};

export const undoSecurityShiftRequest = params => (dispatch, getState) => {
  const securityShiftRequestId = oFetch(params, 'id');
  return undoSecurityShiftRequestRequest(securityShiftRequestId).then(response => {
    dispatch(updateForCurrentWeek(response));
    return response;
  });
};

export const acceptSecurityShiftRequest = params => (dispatch, getState) => {
  const securityShiftRequestId = oFetch(params, 'id');
  return acceptSecurityShiftRequestRequest(securityShiftRequestId).then(response => {
    dispatch(updateForCurrentWeek(response));
    return response;
  });
};

export const removeShiftRequestAction = createAction(types.REMOVE_SHIFT_REQUEST);
export const incrementForDayAction = createAction(types.INCREMENT_FOR_DAY);
export const decrementForDayAction = createAction(types.DECREMENT_FOR_DAY);
export const changeWeekDayAction = createAction(types.CHANGE_WEEK_DAY);
export const changeWeekDayComplete = createAction(types.CHANGE_WEEK_DAY_COMPLETE);
