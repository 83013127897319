import React from 'react';
import oFetch from 'o-fetch';

export default function NamesList(props) {
  const names = oFetch(props, 'names');

  function renderList() {
    return names.map((name, index) => {
      const { names, highlightedNames } = name;
      return (
        <div key={index} className="boss-table__row">
          <div className="boss-table__cell">
            <p
              className="boss-table__text"
              dangerouslySetInnerHTML={{ __html: highlightedNames || names }}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_names-table">
      <div className="boss-table boss-table_page_names">{renderList()}</div>
    </div>
  );
}
