import React from 'react';
import oFetch from 'o-fetch';
import { isFunction } from '../../utils';

import CustomContentModal from './custom-content-modal';

export default function SimpleModal(props) {
  const [onClose, title, children] = oFetch(props, 'onClose', 'title', 'children');

  function renderTitle() {
    if (isFunction(title)) {
      return title();
    }
    return <h2 className="purple-modal__title">{title}</h2>;
  }

  return (
    <CustomContentModal modalClass="purple-modal purple-modal_size_m-minor purple-modal_space_m">
      <button onClick={onClose} type="button" className="purple-modal__close" />
      <header className="purple-modal__header purple-modal__header_adjust_close">
        {renderTitle()}
      </header>
      <div className="purple-modal__content">{children}</div>
    </CustomContentModal>
  );
}
