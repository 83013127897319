import React from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import ContentWrapper from '@/components/content-wrapper';
import { DashboardWrapper, PanelWrapper } from '../components';
import { AssignStaffTypesForm, AssignedStaffTypes } from './components';
import * as selectors from './redux/selectors';
import * as actions from './redux/actions';

class EditChecklistsAssignments extends React.Component {
  handleAssignStaffTypes = (values, dispatch, props) => {
    const form = oFetch(props, 'form');
    const assignStaffTypes = oFetch(this.props, 'assignStaffTypes');
    return assignStaffTypes({ ...values, form });
  };

  render() {
    const [assignedStaffTypes, staffTypesOptions] = oFetch(
      this.props,
      'assignedStaffTypes',
      'staffTypesOptions',
    );

    const unAssignStaffTypes = oFetch(this.props, 'unAssignStaffTypes');
    return (
      <div>
        <DashboardWrapper checklistName="test" link="/check_lists" />
        <ContentWrapper>
          <PanelWrapper title="Assigned Staff Types">
            <AssignedStaffTypes onDelete={unAssignStaffTypes} staffTypes={assignedStaffTypes} />
            <AssignStaffTypesForm
              onSubmit={this.handleAssignStaffTypes}
              initialValues={{ staffTypes: [] }}
              options={staffTypesOptions}
            />
          </PanelWrapper>
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    staffTypesOptions: selectors.staffTypesOptions(state),
    assignedStaffTypes: selectors.assignedStaffTypesSelector(state),
  };
};

const mapDispatchToProps = {
  assignStaffTypes: actions.assignStaffTypes,
  unAssignStaffTypes: actions.unAssignStaffTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChecklistsAssignments);
