import React, { Fragment } from 'react';
import { FieldArray, Field } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';

export function AccessoryChangeStatusField(props) {
  const [input, meta, accessory, statusOptions] = oFetch(
    props,
    'input',
    'meta',
    'accessory',
    'statusOptions',
  );
  const [value, name] = oFetch(input, 'value', 'name');
  const accessoryJS = accessory.toJS();
  const { error } = meta;

  const [accessoryName, iconColor, icon] = oFetch(accessoryJS, 'name', 'iconColor', 'icon');
  const selectClassNames = cn('boss-form__select boss-form__select_type_narrow', {
    'boss-form__select_state_error': !!error,
  });
  return (
    <div className="boss-form__row boss-form__row_layout_wrap-xs">
      <div className="boss-form__field boss-form__field_layout_third">
        <p className="boss-form__text">
          <span className="boss-indicator">
            <span
              className={`boss-indicator__icon boss-indicator__icon_${icon}`}
              style={{ color: iconColor }}
            />
          </span>{' '}
          {accessoryName}
        </p>
      </div>
      <div className="boss-form__field boss-form__field_layout_max">
        <div className={selectClassNames}>
          <Select
            name={name}
            value={value}
            onChange={input.onChange}
            clearable
            simpleValue
            options={statusOptions}
          />
          {error && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                <span className="boss-form__error-line">{error}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function AccessoriesRenderItems(props) {
  const [fields, meta, accessories, statusOptions] = oFetch(
    props,
    'fields',
    'meta',
    'accessories',
    'statusOptions',
  );
  return (
    <div className="boss-form__group boss-form__group_role_board">
      {fields.map((field, index) => {
        const accessory = accessories.get(index);
        if (!accessory) {
          throw new Error('Accessory must be exist');
        }
        return (
          <Fragment key={index}>
            <Field
              accessory={accessory}
              name={`${field}.status`}
              statusOptions={statusOptions}
              component={AccessoryChangeStatusField}
            />
          </Fragment>
        );
      })}
    </div>
  );
}

export function AccessoriesChangeStatus(props) {
  const [label, accessories, name, statusOptions] = oFetch(
    props,
    'label',
    'accessories',
    'name',
    'statusOptions',
  );
  return (
    <div className="boss-form__group">
      <p className="boss-form__label">
        <span className="boss-form__label-text">{label}</span>
      </p>
      <FieldArray
        name={name}
        accessories={accessories}
        statusOptions={statusOptions}
        component={AccessoriesRenderItems}
      />
    </div>
  );
}
