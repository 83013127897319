import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { ModalsProvider } from '@/components/hooks-components/modals';

import reducers from './redux/reducers';
import { loadInitialData } from './redux/actions';
import PayrollReportsPage from './containers/page';

export default function PayrollReportsApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  const [store, setStore] = useState(null);

  useEffect(() => {
    window.boss.accessToken = accessToken;
  }, [accessToken]);

  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <ModalsProvider>
        <PayrollReportsPage />
      </ModalsProvider>
    </Provider>
  );
}
