import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import cn from 'classnames';
import queryString from 'query-string';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

function range(from, to, step = 1) {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  initialPage: PropTypes.number,
};

export default function Pagination(props) {
  const [totalRecords, pageLimit, pageNeighbours, initialPage] = oFetch(
    props,
    'totalRecords',
    'pageLimit',
    'pageNeighbours',
    'initialPage',
  );
  const totalPages = Math.ceil(totalRecords / pageLimit);
  const [currentPage, setCurrentPage] = useState(initialPage);

  function gotoPage(page) {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    setCurrentPage(currentPage);
  }

  function handleClick(page) {
    return (evt) => {
      gotoPage(page);
    };
  }

  function handleMoveLeft(evt) {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  }

  function handleMoveRight(evt) {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  }

  function fetchPageNumbers() {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <Fragment>
      <div className="boss-paginator boss-paginator_position_last">
        {pages.map((page, index) => {
          const pageButtonClassNames = cn(
            'boss-paginator__action boss-paginator__action_role_current',
            {
              'boss-paginator__action_state_active': currentPage === page,
            },
          );

          if (page === LEFT_PAGE)
            return (
              <button
                key={index}
                className="boss-paginator__action boss-paginator__action_role_prev"
                onClick={handleMoveLeft}
              >
                <span className="boss-paginator__action-link" aria-label="Previous">
                  Previous
                </span>
              </button>
            );

          if (page === RIGHT_PAGE)
            return (
              <button
                key={index}
                className="boss-paginator__action boss-paginator__action_role_next"
                onClick={handleMoveRight}
              >
                <span className="boss-paginator__action-link" aria-label="Next">
                  Next
                </span>
              </button>
            );

          const queryStringObject = queryString.parse(window.location.search);
          const url = `${window.location.pathname}?${queryString.stringify({
            ...queryStringObject,
            page: page,
          })}`;

          return (
            <a key={index} className={pageButtonClassNames} href={url} onClick={handleClick(page)}>
              <span className="boss-paginator__action-link">{page}</span>
            </a>
          );
        })}
      </div>
    </Fragment>
  );
}
