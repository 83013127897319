import React from 'react';
import oFetch from 'o-fetch';

export function SecurityPayRatesList(props) {
  const [renderItem, list] = oFetch(props, 'renderItem', 'list');

  return list.map(listItem => {
    const id = oFetch(listItem, 'id');
    const element = renderItem(listItem);
    return <React.Fragment key={id}>{element}</React.Fragment>;
  });
}
