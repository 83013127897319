import oFetch from 'o-fetch';
import React from 'react';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export function TaskRejection(props) {
  const rejection = oFetch(props, 'rejection');
  const [text, creatorFullName, createdAt] = oFetch(rejection, 'text', 'creatorFullName', 'createdAt');

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);

  return (
    <div className="boss-task__message">
      <div className="boss-message boss-message_role_task-note">
        <p className="boss-message__text">
          <span className="boss-message__text-line">
            Task marked as rejected by {creatorFullName} at {formattedCreatedAt}
          </span>
          <span className="boss-message__text-line">
            Reject Reason - <span className="boss-message__text-marked">&quot;{text}&quot;</span>
          </span>
        </p>
      </div>
    </div>
  );
}
