import React from 'react';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { ModalsProvider } from '@/components/hooks-components/modals';

import { SecurityPayrollReportsPage } from './security-payroll-report-page';

export function SecurityPayrollReportApp(props) {
  const [weekDates, accessToken] = oFetch(props, 'weekDates', 'accessToken');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  const mWeekDates = weekDates.map(sUiDate => {
    return safeMoment.uiDateParse(sUiDate);
  });

  const totalCents = oFetch(props, 'totalCents');
  const [securityFinanceReports, hoursBreakdowns, weekStart] = oFetch(
    props,
    'securityFinanceReports',
    'hoursBreakdowns',
    'weekStart',
  );
  const canDownloadCsv = oFetch(props, 'canDownloadCsv');

  return (
    <ModalsProvider>
      <SecurityPayrollReportsPage
        canDownloadCsv={canDownloadCsv}
        mWeekDates={mWeekDates}
        weekDates={weekDates}
        weekStart={weekStart}
        hoursBreakdowns={hoursBreakdowns}
        securityFinanceReports={securityFinanceReports}
        totalCents={totalCents}
      />
    </ModalsProvider>
  );
}
