import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';

import { configureStore } from '../configure-store';
import { setInitialData } from './actions/initial-load';

import venueHealthCheckReport from './reducers/venue-health-check-report';
import ReportContainer from './containers/report';

export default function VenueHealthCheckReportApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    window.boss.accessToken = oFetch(props, 'accessToken');
    const store = configureStore({ venueHealthCheckReport });
    store.dispatch(setInitialData(props));
    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <ReportContainer />
    </Provider>
  );
}
