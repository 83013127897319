import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import * as actions from './redux/actions';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { mapArrayKeys } from '@/lib/utils';
import { AcceptedMarketingTasks } from './accepted-marketing-tasks';

export function AcceptedMarketingTasksApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  const [store, setStore] = useState(null);

  const [accessibleVenues, users, currentUserId] = oFetch(
    props,
    'accessibleVenues',
    'users',
    'currentUserId',
  );

  const venueOptions = mapArrayKeys(accessibleVenues, ['id:value', 'name:label']);
  const userOptions = mapArrayKeys(users, ['id', 'fullName', 'role']);

  useEffect(() => {
    require('./styles.css');

    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <LegacyGlobalProvider global={{ venueOptions, userOptions, currentUserId }}>
      <Provider store={store}>
        <ModalsProvider>
          <AcceptedMarketingTasks {...props} />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
