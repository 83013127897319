import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import Spinner from '@/components/spinner';
import { appRoutes } from '@/lib/legacy-routes';
import EditTask from './edit-task';

export default class TaskItem extends Component {
  state = {
    isOpened: false,
    isProcessing: false,
    isEdit: false,
  };

  toggleDropdown = () => {
    this.setState(state => ({ isOpened: !oFetch(state, 'isOpened') }));
  };

  onCheckboxClick = params => {
    const [e, id] = oFetch(params, 'e', 'id');
    const checked = e.target.checked;
    const onCheckboxClick = oFetch(this.props, 'onCheckboxClick');
    this.setState({ isProcessing: true });
    return onCheckboxClick({ checked, id }).then(() => {
      this.setState({ isProcessing: false });
    });
  };

  onEditClick = () => {
    this.setState({ isEdit: true });
  };

  onUpdateTask = values => {
    const onUpdate = oFetch(this.props, 'onUpdate');
    return onUpdate(values).then(() => {
      this.setState({ isEdit: false });
    });
  };

  renderCheckboxBlock = params => {
    const [task, isProcessing] = oFetch(params, 'task', 'isProcessing');
    const completed = oFetch(task, 'completed');
    const id = oFetch(task, 'id');

    if (isProcessing) {
      return <Spinner />;
    }
    return (
      <div className="boss-check__header-checkbox">
        <label className="boss-check__checkbox-label">
          <input
            name="dismiss"
            value="yes"
            type="checkbox"
            onChange={e => this.onCheckboxClick({ e, id })}
            checked={completed}
            className="boss-check__checkbox-input"
          />
          <span className="boss-check__checkbox-label-text boss-check__checkbox-label-text_visible-s">
            Mark Completed
          </span>
        </label>
      </div>
    );
  };

  renderActions = task => {
    const [completed, declined] = oFetch(task, 'completed', 'declined');
    if (completed === true || declined) {
      return null;
    }
    const [onDeclineClick, onPostponeClick] = oFetch(this.props, 'onDeclineClick', 'onPostponeClick');
    const permissions = oFetch(this.props, 'permissions');
    const taskPermission = permissions[oFetch(task, 'id')];
    const [canEdit, canDelete] = oFetch(taskPermission, 'canEdit', 'canDelete');
    return (
      <div className="boss-check__header-actions">
        <div
          onClick={() => onPostponeClick(task)}
          className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_postpone"
        />
        {canEdit && (
          <div
            onClick={this.onEditClick}
            className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_edit"
          />
        )}
        <div
          onClick={() => onDeclineClick(task, canDelete)}
          className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove"
        />
      </div>
    );
  };

  createTitleHTML = title => {
    return title.replace(/\n/g, '<br />');
  }

  renderItemContent = params => {
    const [
      isProcessing,
      task,
      titleClassNames,
      text,
      arrowClassNames,
      declined,
      postponed,
      postponedTo,
      currentVenueId,
    ] = oFetch(
      params,
      'isProcessing',
      'task',
      'titleClassNames',
      'text',
      'arrowClassNames',
      'task.declined',
      'task.postponed',
      'task.postponedTo',
      'currentVenueId',
    );

    return (
      <div className="boss-check__header">
        {!declined && !postponed && this.renderCheckboxBlock({ task, isProcessing })}
        <div className="boss-check__header-info">
          <div className="boss-check__header-group">
            <h3 className={titleClassNames}>
              {declined &&
                !postponed && (
                  <span className="boss-check__title-indicator">
                    <span className="boss-indicator">
                      <span className="boss-indicator__icon boss-indicator__icon_close-circle boss-indicator__icon_size_extra" />
                    </span>
                  </span>
                )}
              {postponed && (
                <span className="boss-check__title-indicator">
                  <span className="boss-indicator">
                    <span className="boss-indicator__icon boss-indicator__icon_clock-snooze boss-indicator__icon_size_extra" />
                  </span>
                </span>
              )}
              <span dangerouslySetInnerHTML={ {__html: this.createTitleHTML(text)} } />
              {declined && !postponed && <span className="boss-check__title-status"> (Declined)</span>}
              {postponed && <span className="boss-check__title-status"> (Postponed to {postponedTo}) </span>}
              {postponed && (
                <a
                  href={appRoutes.handoverPlannersWeekView({
                    mDate: safeMoment.uiDateParse(postponedTo),
                    venueId: currentVenueId,
                  })}
                  className="boss-check__title-link boss-check__title-link_role_details"
                >
                  Visit
                </a>
              )}
            </h3>
          </div>
          <div className="boss-check__header-actions">
            {!postponed && this.renderActions(task)}
            <div onClick={this.toggleDropdown} className={arrowClassNames}>
              Toggle Dropdown
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPostponeHistory = history => {
    const [from, by, to, at] = oFetch(history, 'from', 'by', 'to', 'at');
    const formattedAt = safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTime());
    return (
      <div className="boss-check__activity-item boss-check__activity-item_role_postponed">
        <p className="boss-check__text">
          <span className="boss-check__text-light">Postponed from </span>
          <span className="boss-check__text-text">{from}</span>
          <span className="boss-check__text-light"> to </span>
          <span className="boss-check__text-marked">{to}</span>
          <span className="boss-check__text-light"> by </span>
          <span className="boss-check__text-marked">{by}</span>
          <span className="boss-check__text-light"> at </span>
          <span className="boss-check__text-text">{formattedAt}</span>
        </p>
      </div>
    );
  };

  renderCreatedHistory = task => {
    const [createdBy, createdAt] = oFetch(task, 'createdBy', 'createdAt');
    const formattedAt = safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime());
    return (
      <div className="boss-check__activity-item boss-check__activity-item_role_created">
        <p className="boss-check__text">
          <span className="boss-check__text-light">Created by </span>
          <span className="boss-check__text-marked">{createdBy}</span>
          <span className="boss-check__text-light"> at </span>
          <span className="boss-check__text-marked">{formattedAt}</span>
        </p>
      </div>
    );
  };

  renderDeclinedHistory = task => {
    const [declinedBy, declinedAt] = oFetch(task, 'declinedBy', 'declinedAt');
    const formattedAt = safeMoment.iso8601Parse(declinedAt).format(utils.humanDateFormatWithTime());
    return (
      <div className="boss-check__activity-item boss-check__activity-item_role_declined">
        <p className="boss-check__text">
          <span className="boss-check__text-light">Declined by </span>
          <span className="boss-check__text-marked">{declinedBy}</span>
          <span className="boss-check__text-light"> at </span>
          <span className="boss-check__text-marked">{formattedAt}</span>
        </p>
      </div>
    );
  };

  renderCompletedHistory = task => {
    const [completedBy, completedAt] = oFetch(task, 'completedBy', 'completedAt');
    const formattedAt = safeMoment.iso8601Parse(completedAt).format(utils.humanDateFormatWithTime());
    return (
      <div className="boss-check__activity-item boss-check__activity-item_role_declined">
        <p className="boss-check__text">
          <span className="boss-check__text-light">Completed by </span>
          <span className="boss-check__text-marked">{completedBy || '<Unknown>'}</span>
          <span className="boss-check__text-light"> at </span>
          <span className="boss-check__text-marked">{formattedAt}</span>
        </p>
      </div>
    );
  };

  renderHistory = task => {
    const history = oFetch(task, 'history');
    const [declined, completed] = oFetch(task, 'declined', 'completed');
    return (
      <div className="boss-check__group">
        <div className="boss-check__group-content">
          <div className="boss-check__activity">
            {completed && this.renderCompletedHistory(task)}
            {declined && this.renderDeclinedHistory(task)}
            {history.map((historyItem, index) => {
              const type = oFetch(historyItem, 'type');
              if (type === 'postponed') {
                return React.cloneElement(this.renderPostponeHistory(historyItem), { key: index });
              }
            })}
            {this.renderCreatedHistory(task)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const [isOpened, isProcessing, isEdit] = oFetch(this.state, 'isOpened', 'isProcessing', 'isEdit');
    const [task, currentVenueId] = oFetch(this.props, 'task', 'currentVenue.id');

    const [completed, text, sortData, declined, declineReason, postponed] = oFetch(
      task,
      'completed',
      'text',
      'sortData',
      'declined',
      'declineReason',
      'postponed',
    );
    const arrowClassNames = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
      'boss-check__dropdown-link_state_closed': !isOpened,
    });
    const taskClassNames = cn('boss-check boss-check_role_panel boss-check_page_handover-planner-task', {
      'boss-check_state_inactive-faded': completed,
      'boss-check_state_warning-faded': declined,
      'boss-check_state_postponed': postponed,
    });
    const titleClassNames = cn('boss-check__title', {
      'boss-check__title_adjust_crossed-out': completed,
    });
    return (
      <div data-id={sortData} className={taskClassNames}>
        <div className="boss-check__handle boss-check__handle_position_left boss-check__handle_role_task" />
        {isEdit && completed === false ? (
          <EditTask onSubmit={this.onUpdateTask} task={task} />
        ) : (
          this.renderItemContent({
            currentVenueId,
            isProcessing,
            task,
            completed,
            titleClassNames,
            text,
            arrowClassNames,
          })
        )}
        <Collapse isOpened={isOpened} className="boss-check__dropdown" style={{ display: 'block' }}>
          {declined &&
            !postponed && (
              <div className="boss-check__group boss-check__group_marked">
                <div className="boss-check__group-content">
                  <div className="boss-check__box">
                    <p className="boss-check__text">{declineReason}</p>
                  </div>
                </div>
              </div>
            )}
          {this.renderHistory(task)}
        </Collapse>
      </div>
    );
  }
}

TaskItem.defaultProps = {
  onUpdate: () => {},
};
