import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import React, { Fragment, useState } from 'react';
import ContentWrapper from '@/components/content-wrapper';
import { appRoutes } from '@/lib/legacy-routes';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import * as actions from './actions';
import {
  BonaiDashboard,
  VenueItem,
  VenueList,
  ManagersList,
  AreYouSureEligibleModalContent,
  MarkNotEligibleModalContent,
  ManagerItem,
} from './components';

export function Bonai(props) {
  const [
    weekStartDate,
    weekEndDate,
    venues,
    staffMemberDataByVenueFromProps,
    venuePayrollBreakdowns,
    errors,
    venueWagePercentages,
    isFutureWeek,
  ] = oFetch(
    props,
    'sDate',
    'sEndDate',
    'venues',
    'staffMemberDataByVenue',
    'venuePayrollBreakdowns',
    'errors',
    'venueWagePercentages',
    'isFutureWeek',
  );

  const { openModal } = useModal();
  const [staffMemberDataByVenue, setStaffMemberDataByVenue] = useState(staffMemberDataByVenueFromProps);
  const [venuesStore, setVenuesStore] = useState(venues);

  function handleOpenMarkEligible(params) {
    const [staffMember, venueId] = oFetch(params, 'staffMember', 'venueId');
    const [staffMemberId, bonaiEligibilityOverrideId] = oFetch(
      staffMember,
      'id',
      'eligibilityData.bonaiEligibilityOverrideId',
    );

    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: AreYouSureEligibleModalContent,
      onSubmit(handleClose) {
        return actions.neutraliseEligibilityOverride({
          values: { bonaiEligibilityOverrideId },
          onSuccess: () => {
            updateStaffMemberEligibilityData({
              staffMemberId,
              venueId,
              newEligibilityData: null,
            });
            handleClose();
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_confirm',
        title() {
          return (
            <div className="boss-modal-window__header boss-modal-window__header_warning">Are you sure?</div>
          );
        },
      },
      props: {},
    });
  }

  function updateStaffMemberEligibilityData(params) {
    const [staffMemberId, venueId, newEligibilityData] = oFetch(
      params,
      'staffMemberId',
      'venueId',
      'newEligibilityData',
    );
    setStaffMemberDataByVenue(prevState => {
      return {
        ...prevState,
        [venueId]: prevState[venueId].map(staffMember => {
          if (oFetch(staffMember, 'id') === staffMemberId) {
            return {
              ...staffMember,
              eligibilityData: newEligibilityData,
            };
          }
          return staffMember;
        }),
      };
    });
  }

  function updateVenueBonaiBonus(params) {
    const [bonaiBonus, venueId] = oFetch(params, 'bonaiBonus', 'venueId');
    setVenuesStore(prevState => {
      return prevState.map(venue => {
        if (oFetch(venue, 'id') === venueId) {
          return {
            ...venue,
            bonaiCentsData: bonaiBonus,
          };
        }
        return venue;
      });
    });
  }

  function handleOpenMarkNotEligible(params) {
    const [staffMember, venueId] = oFetch(params, 'staffMember', 'venueId');
    const staffMemberId = oFetch(staffMember, 'id');

    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: MarkNotEligibleModalContent,
      onSubmit(handleClose, values) {
        return actions.createBonaiEligibility({
          values,
          onSuccess: data => {
            updateStaffMemberEligibilityData({
              staffMemberId,
              venueId,
              newEligibilityData: oFetch(data, 'eligibilityData'),
            });
            handleClose();
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_confirm',
        title() {
          return (
            <div className="boss-modal-window__header boss-modal-window__header_warning">REASON REQUIRED</div>
          );
        },
      },
      props: {
        initialValues: {
          reason: '',
          weekStart: weekStartDate,
          venueId: venueId,
          staffMemberId: staffMemberId,
        },
      },
    });
  }

  function handleSelectWeek(selection) {
    const startUIDate = oFetch(selection, 'startUIDate');
    window.location.href = appRoutes.bonaiPage({ uiStartDate: startUIDate });
  }

  function handleUpdateVenueBonaiCents(params) {
    const [values, hideEditField, venueId] = oFetch(params, 'values', 'hideEditField', 'venueId');
    const bonaiCents = oFetch(values, 'bonaiCents');

    return actions.updateVnueBonaiCents({
      values: { bonaiCents, weekStart: weekStartDate, venueId: venueId },
      onSuccess(data) {
        hideEditField();
        updateVenueBonaiBonus({ bonaiBonus: data, venueId });
      },
    });
  }

  function normalizePayrollBreakdownData(payrollBreakdown) {
    const pendingUpdate = payrollBreakdown ? oFetch(payrollBreakdown, 'pendingUpdate') : true;

    const insideHourlyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'insideHourlyMinutes');
    const insideWeeklyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'insideWeeklyMinutes');

    const outsideHourlyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'outsideHourlyMinutes');
    const outsideWeeklyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'outsideWeeklyMinutes');

    const outsourcedHourlyMinutes = pendingUpdate
      ? null
      : oFetch(payrollBreakdown, 'outsourcedHourlyMinutes');
    const outsourcedWeeklyMinutes = pendingUpdate
      ? null
      : oFetch(payrollBreakdown, 'outsourcedWeeklyMinutes');

    const holidayHourlyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'holidayHourlyMinutes');
    const holidayWeeklyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'holidayWeeklyMinutes');

    const owedHourHourlyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'owedHourHourlyMinutes');
    const owedHourWeeklyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'owedHourWeeklyMinutes');

    const sickLeaveHourlyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'sickLeaveHourlyMinutes');
    const sickLeaveWeeklyMinutes = pendingUpdate ? null : oFetch(payrollBreakdown, 'sickLeaveWeeklyMinutes');

    const containsNulls =
      insideHourlyMinutes == null ||
      insideWeeklyMinutes == null ||
      outsideHourlyMinutes == null ||
      outsideWeeklyMinutes == null ||
      outsourcedHourlyMinutes == null ||
      outsourcedWeeklyMinutes == null ||
      holidayHourlyMinutes == null ||
      holidayWeeklyMinutes == null ||
      owedHourHourlyMinutes == null ||
      owedHourWeeklyMinutes == null ||
      sickLeaveHourlyMinutes == null ||
      sickLeaveWeeklyMinutes == null;

    if (containsNulls) {
      return {
        insideHours: 'N/A',
        outsideHours: 'N/A',
        outsourcedHours: 'N/A',
        holidayHours: 'N/A',
        owedHours: 'N/A',
        sickLeaveHours: 'N/A',
      };
    }

    const insideMinutes = insideWeeklyMinutes + insideHourlyMinutes;
    const insideHours = utils.floorToDecimalPlaces({ value: insideMinutes / 60.0, precision: 2 });

    const outsideMinutes = outsideWeeklyMinutes + outsideHourlyMinutes;
    const outsideHours = utils.floorToDecimalPlaces({ value: outsideMinutes / 60.0, precision: 2 });

    const outsourcedMinutes = outsourcedWeeklyMinutes + outsourcedHourlyMinutes;
    const outsourcedHours = utils.floorToDecimalPlaces({ value: outsourcedMinutes / 60.0, precision: 2 });

    const holidayMinutes = holidayWeeklyMinutes + holidayHourlyMinutes;
    const holidayHours = utils.floorToDecimalPlaces({ value: holidayMinutes / 60.0, precision: 2 });

    const owedHourMinutes = owedHourWeeklyMinutes + owedHourHourlyMinutes;
    const owedHours = utils.floorToDecimalPlaces({ value: owedHourMinutes / 60.0, precision: 2 });

    const sickLeaveMinutes = sickLeaveWeeklyMinutes + sickLeaveHourlyMinutes;
    const sickLeaveHours = utils.floorToDecimalPlaces({ value: sickLeaveMinutes / 60.0, precision: 2 });

    return {
      insideHours,
      outsideHours,
      outsourcedHours,
      holidayHours,
      owedHours,
      sickLeaveHours,
    };
  }

  function getVenuePayrollBreakdown(venue) {
    const venuePayrollBreakdown = venuePayrollBreakdowns.find(venuePayrollBreakdown => {
      const venuePayrollBreakdownVenueId = oFetch(venuePayrollBreakdown, 'venueId');
      const venueId = oFetch(venue, 'id');
      return venuePayrollBreakdownVenueId === venueId;
    });
    return normalizePayrollBreakdownData(venuePayrollBreakdown);
  }

  function getVenueWagePercentage(venue) {
    const venueId = oFetch(venue, 'id');
    const venueWagePercentage = venueWagePercentages.find(
      venueWagePercentage => oFetch(venueWagePercentage, 'venueId') === venueId,
    );
    if (!venueWagePercentage) {
      return { bonusTier: null, lastUpdatedAt: null, salesDataExists: null, pendingUpdate: false };
    }
    return venueWagePercentage;
  }

  function renderManagerItem(params) {
    const [staffMember, venue, bonusTier] = oFetch(params, 'staffMember', 'venue', 'bonusTier');
    const [venueId, bonaiCentsData] = oFetch(venue, 'id', 'bonaiCentsData');

    return (
      <ManagerItem
        staffMember={staffMember}
        onMarkNotEligible={() => handleOpenMarkNotEligible({ staffMember, venueId })}
        onMarkEligible={() => handleOpenMarkEligible({ staffMember, venueId })}
        bonaiCentsData={bonaiCentsData}
        bonusTier={bonusTier}
        isFutureWeek={isFutureWeek}
        onUpdateVenueBonai={params => handleUpdateVenueBonaiCents({ ...params, venueId })}
      />
    );
  }

  function renderVenueItem(venue) {
    const venueId = oFetch(venue, 'id');
    const venueStaffMembers = staffMemberDataByVenue[venueId] || [];
    const venueWagePercentage = getVenueWagePercentage(venue);
    const bonusTier = oFetch(venueWagePercentage, 'bonusTier');

    return (
      <VenueItem
        venue={venue}
        payrollBreakdown={getVenuePayrollBreakdown(venue)}
        venueWagePercentage={venueWagePercentage}
        renderManagersList={() => {
          return (
            <ManagersList
              items={venueStaffMembers}
              renderManagerItem={staffMember => renderManagerItem({ staffMember, venue, bonusTier })}
            />
          );
        }}
      />
    );
  }

  return (
    <Fragment>
      <BonaiDashboard
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
        onWeekSelect={handleSelectWeek}
        errors={errors}
      />
      <ContentWrapper>
        <VenueList venues={venuesStore} itemRenderer={renderVenueItem} />
      </ContentWrapper>
    </Fragment>
  );
}
