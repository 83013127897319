import React, { useRef, useState } from 'react';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';

export default function NativeAppLinkItem(props) {
  const downloadUrl = oFetch(props, 'downloadUrl');
  const linkRef = useRef(null);
  const [showTooltip, setShowTooltop] = useState(false);

  const copyToClipboard = e => {
    setShowTooltop(true);
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setTimeout(() => {
      setShowTooltop(false);
    }, 1000);
  };

  if (!downloadUrl) {
    return <div className="boss-link-control__placeholder">N/A</div>;
  }
  return (
    <div className="boss-link-control__group">
      <div className="boss-link-control__info">
        <input
          type="text"
          ref={linkRef}
          className="boss-link-control__text"
          readOnly
          value={downloadUrl}
        />
      </div>
      <div className="boss-link-control__actions">
        <Tooltip title="Coppied !!!" position="top" open={showTooltip} />
        <button
          className="boss-link-control__action boss-link-control__action_role_copy"
          type="button"
          onClick={copyToClipboard}
        >
          Copy
        </button>
        <a
          href={downloadUrl}
          target="_blank"
          rel="noreferrer"
          className="boss-link-control__action boss-link-control__action_role_visit"
        >
          Visit
        </a>
      </div>
    </div>
  );
}
