import React from 'react';
import { Iterable } from 'immutable';

export default function pureToJS(WrappedComponent) {
  return function (props) {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(props).reduce((acc, wrappedComponentProp) => {
      acc[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
        ? wrappedComponentProp[VALUE].toJS()
        : wrappedComponentProp[VALUE];
      return acc;
    }, {});
    return <WrappedComponent {...propsJS} />;
  };
}
