import React from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { modalRedux } from '@/components/modals';
import { OwedHoursForm } from '@/components/boss-form';

const AddOwedHours = props => {
  const mossHourTags = oFetch(props, 'mossHourTags');
  const mossHourTagIds = [];
  const propsLastMossHourTagId = oFetch(props, 'lastMossHourTagId');
  if (propsLastMossHourTagId && mossHourTags.some((mossHourTag) => propsLastMossHourTagId === mossHourTag.id)) {
    mossHourTagIds.push(propsLastMossHourTagId);
  }

  const initialValues = {
    date: null,
    startsAt: null,
    endsAt: null,
    note: null,
    startsAtCCTVImage: null,
    endsAtCCTVImage: null,
    cctvImageHasChanged: false,
    mossHourTagIds,
  };
  const onSubmit = oFetch(props, 'onSubmit');
  const effectiveStaffMemberType = oFetch(props, 'effectiveStaffMemberType');
  return (
    <ModalsProvider>
      <div className="boss-modal-window__form">
        <OwedHoursForm
          mossHourTags={mossHourTags}
          effectiveStaffMemberType={effectiveStaffMemberType}
          onSubmit={onSubmit}
          initialValues={initialValues}
          buttonText="Add hours"
        />
      </div>
    </ModalsProvider>
  );
};
export default modalRedux(combineReducers({ form: formReducer }))(AddOwedHours);
