import React, { useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';

export function BossAppAndVenuesSelect(props) {
  const appSelectProps = oFetch(props, 'appId');
  const venueSelectProps = oFetch(props, 'venuesIds');
  const appsVenueIds = oFetch(props, 'appsVenueIds');

  const onAppChange = oFetch(appSelectProps, 'input.onChange');
  const onVenueChange = oFetch(venueSelectProps, 'input.onChange');

  const [venuesOptions, appsOptions] = oFetch(props, 'venuesOptions', 'appsOptions');

  const [appInput, appMeta] = oFetch(appSelectProps, 'input', 'meta');
  const [venueInput, venueMeta] = oFetch(venueSelectProps, 'input', 'meta');
  const [appName, appValue] = oFetch(appInput, 'name', 'value');
  const [venueName, venueValue] = oFetch(venueInput, 'name', 'value');

  const { appError, appTouched, appSubmitError } = appMeta;
  const { venueError, venueTouched, venueSubmitError } = venueMeta;
  const appHasError = appTouched && (appError || appSubmitError);
  const venueHasError = venueTouched && (venueError || venueSubmitError);

  const appSelectClassNames = cn('boss-form__select', { 'boss-form__select_state_error': appHasError });
  const venueSelectClassNames = cn('boss-form__select', { 'boss-form__select_state_error': venueHasError });

  const normalisedVenuesOptions = useMemo(() => {
    if (!appValue) {
      return [];
    }

    const selectedApp = appsOptions.find(app => oFetch(app, 'value') === appValue);
    if (!selectedApp) {
      throw new Error('expected app not found');
    }
    const appAppTypes = oFetch(selectedApp, 'appTypes');

    return venuesOptions.filter(venueOption => {
      const venueAppTypes = oFetch(venueOption, 'appTypes');

      return venueAppTypes.some(venueAppType => {
        return appAppTypes.includes(venueAppType);
      });
    });
  }, [appValue]);

  useEffect(() => {
    const venueIds = normalisedVenuesOptions.map(option => oFetch(option, 'value')).filter((venueId) => {
      return appsVenueIds.includes(venueId);
    });
    onVenueChange(venueIds);
  }, [appValue, normalisedVenuesOptions]);

  function handleAppChange(value) {
    return onAppChange(value);
  }

  function handleVenueChange(value) {
    const arrayValues = value
      .split(',')
      .filter(Boolean)
      .map(id => parseInt(id));
    return onVenueChange(arrayValues);
  }

  function handleVenueClearAll() {
    onVenueChange([]);
  }

  function handleVenueSelectAll() {
    const allValues = normalisedVenuesOptions.map(option => oFetch(option, 'value'));
    onVenueChange(allValues);
  }

  function renderVenueClearAddAll() {
    const allValues = normalisedVenuesOptions.map(option => oFetch(option, 'value'));
    const allSelected = allValues.length === venueValue.length;
    const empty = venueValue.length === 0;

    return (
      <div className="boss-form__label-actions">
        {!empty && (
          <button
            onClick={handleVenueClearAll}
            type="button"
            className="boss-action boss-action_role_form boss-form__label-action"
          >
            <span className="boss-action__text">Clear All</span>
          </button>
        )}
        {!allSelected && (
          <button
            onClick={handleVenueSelectAll}
            type="button"
            className="boss-action boss-action_role_form boss-form__label-action"
          >
            <span className="boss-action__text">Add All</span>
          </button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="boss-form__field">
        <div className="boss-form__label-group">
          <label
            htmlFor={appName}
            className="boss-form__label"
          >
            <span className="boss-form__label-text">App</span>
          </label>
        </div>
        <div className={appSelectClassNames}>
          <Select
            name={appName}
            value={appValue}
            onChange={handleAppChange}
            clearable
            simpleValue
            placeholder={'Please select app...'}
            options={appsOptions}
          />
        </div>
        {appHasError && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              {(appError || appSubmitError).map((errorItem, index) => {
                return (
                  <span
                    key={index}
                    className="boss-form__error-line"
                  >
                    {errorItem}
                  </span>
                );
              })}
            </p>
          </div>
        )}
      </div>
      {appValue && (
        <div className="boss-form__field">
          <div className="boss-form__label-group">
            <label
              htmlFor={venueName}
              className="boss-form__label"
            >
              <span className="boss-form__label-text">Venues</span>
            </label>
            {renderVenueClearAddAll()}
          </div>
          <div className={venueSelectClassNames}>
            <Select
              name={venueName}
              value={venueValue}
              onChange={handleVenueChange}
              clearable
              simpleValue
              multi
              placeholder={'Please select venues...'}
              options={normalisedVenuesOptions}
            />
          </div>
          {venueHasError && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                {(venueError || venueSubmitError).map((errorItem, index) => {
                  return (
                    <span
                      key={index}
                      className="boss-form__error-line"
                    >
                      {errorItem}
                    </span>
                  );
                })}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
