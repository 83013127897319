import JSZip from 'jszip';
import oFetch from 'o-fetch';
import pipe from 'asyncpipe';
import cheerio from 'cheerio';

const EXTRA_JS = `
<script>
  // Scroll to top handler
  const toTopButton = document.getElementById("toTopBtn");
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      toTopButton.style.display = "block";
    } else {
      toTopButton.style.display = "none";
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // 'a' tags handler 
  function handleAlert(event) {
    event.preventDefault();
    
    const hash = event.target.hash;
    const [_, id] = hash.split('#');
    const oBookmark = document.getElementById(id);
    if (oBookmark) {
      window.scrollTo(0, oBookmark.offsetTop - 40);
    }
  };
</script>
`;

const EXTRA_STYLES = `
<style>
  #toTopBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  #toTopBtn:hover {
    background-color: #555;
  }
</style>
`;

class TrainingMaterialsZIPError {
  constructor(string) {
    this.message = string;
  }
}

export function getMimeTypeFromExt(ext) {
  if (ext === 'jpeg' || ext === 'jpg') {
    return 'image/jpeg';
  }
  if (ext === 'png') {
    return 'image/png';
  }
  if (ext === 'html') {
    return 'text/plain';
  }
}

export async function createBase64ZipFile({ files }) {
  const zip = new JSZip();

  files.forEach(createFileObject => {
    const [path, content] = oFetch(createFileObject, 'path', 'content');
    zip.file(path, content);
  });
  const zipBase64 = await zip.generateAsync({ type: 'base64' });
  return zipBase64;
}

export function loadZipFile({ data = '', isBase64 = true }) {
  const zip = new JSZip();
  return zip.loadAsync(data, { base64: isBase64 });
}

export function validateFolderCount({ zipjsObjects, errors = [], processed = {} }) {
  const files = oFetch(zipjsObjects, 'files');
  const foldersCount = Object.entries(files).reduce((acc, fileObjectEntry) => {
    const [path, fileObject] = fileObjectEntry;
    if (path.match(/^__MACOSX/) !== null) {
      return acc;
    }
    const isDir = oFetch(fileObject, 'dir');
    if (isDir) {
      return acc + 1;
    }
    return acc;
  }, 0);

  if (foldersCount > 1) {
    errors = [...errors, new TrainingMaterialsZIPError(`Only one folder must be exist`)];
  }
  return { zipjsObjects, errors, processed };
}

export function validateImageExtension({ zipjsObjects, errors = [], processed = {} }) {
  const files = oFetch(zipjsObjects, 'files');
  for (const fileObjectEntry of Object.entries(files)) {
    const [path, fileObject] = fileObjectEntry;
    const isDir = oFetch(fileObject, 'dir');
    if (path.match(/^__MACOSX/) !== null) {
      continue;
    }
    if (!isDir && path.match(/^images\//) !== null) {
      if (path.match(/^images\/[a-z]*[0-9]+\.(jpg|jpeg|png)$/) === null) {
        if (path.match(/^images\/.*\..*$/) === null) {
          errors = [
            ...errors,
            new TrainingMaterialsZIPError(
              `Wrong file name expect images/image{index}.(jpg|jpeg|png), got: ${path}`,
            ),
          ];
        } else {
          if (path.match(/^images\/[a-z]*[0-9]+\./) === null) {
            errors = [
              ...errors,
              new TrainingMaterialsZIPError(
                `Wrong image file name, image index must be present, got: ${path}`,
              ),
            ];
          }
          if (path.match(/^images\/.*\.(jpg|jpeg|png)$/) === null) {
            errors = [
              ...errors,
              new TrainingMaterialsZIPError(
                `Wrong image extension, expect (jpg|jpeg|png), got: ${path}`,
              ),
            ];
          }
        }
      }
    }
  }

  return { zipjsObjects, errors, processed };
}

export function validateHtmlFile({ zipjsObjects, errors = [], processed = {} }) {
  const files = oFetch(zipjsObjects, 'files');
  const htmlFilesCount = Object.entries(files).reduce((acc, fileObjectEntry) => {
    const [path, fileObject] = fileObjectEntry;
    const isDir = oFetch(fileObject, 'dir');
    if (path.match(/^__MACOSX/) !== null) {
      return acc;
    }
    const match = path.match(/^(.*)\.html$/);
    if (match !== null && isDir === false) {
      return acc + 1;
    }
    return acc;
  }, 0);

  if (htmlFilesCount === 0) {
    errors = [...errors, new TrainingMaterialsZIPError(`Html file must be exist`)];
  }
  if (htmlFilesCount > 1) {
    errors = [...errors, new TrainingMaterialsZIPError(`Only one html file must be exist`)];
  }
  return { zipjsObjects, errors, processed };
}

export async function processHtmlFile({ zipjsObjects, errors = [], processed = {} }) {
  const files = oFetch(zipjsObjects, 'files');
  processed.htmlFile = processed.htmlFile || {};
  for await (const fileObjectEntry of Object.entries(files)) {
    const [path, fileObject] = fileObjectEntry;
    if (path.match(/^__MACOSX/) !== null) {
      continue;
    }
    const isDir = oFetch(fileObject, 'dir');
    const match = path.match(/^(.*)\.html$/);
    if (match !== null && isDir === false) {
      const [fullFileName, fileName] = match;
      const mimeType = getMimeTypeFromExt('html');
      const uint8array = await zipjsObjects.file(fullFileName).async('uint8array');
      const content = await zipjsObjects.file(fullFileName).async('string');
      const file = new File([new Blob([content])], `${fileName}.html`, {
        type: mimeType,
        lastModified: new Date().getTime(),
      });
      processed.htmlFile = { file, content };
    }
  }

  return { zipjsObjects, errors, processed };
}

export async function processImageFiles({ zipjsObjects, errors = [], processed = {} }) {
  const files = oFetch(zipjsObjects, 'files');
  processed.images = processed.images || [];
  for await (const fileObjectEntry of Object.entries(files)) {
    const [path, fileObject] = fileObjectEntry;
    const isDir = oFetch(fileObject, 'dir');
    if (path.match(/^__MACOSX/) !== null) {
      continue;
    }
    const match = path.match(/^images\/([a-z]*[0-9]+\.(jpg|jpeg|png))$/);
    if (isDir === false && match !== null) {
      const [fullFileName, fileName, extension] = match;
      const mimeType = getMimeTypeFromExt(extension);
      const uint8array = await zipjsObjects.file(fullFileName).async('uint8array');
      const file = new Blob([uint8array.buffer], {
        type: mimeType,
        lastModified: new Date().getTime(),
      });
      processed.images = [...processed.images, { file, path }];
    }
  }
  return { zipjsObjects, errors, processed };
}

export function processImagesPath(params) {
  const [html, images] = oFetch(params, 'html', 'images');
  const ch$ = cheerio.load(html);

  ch$('img').each(function () {
    const oldSrc = ch$(this).attr('src');
    const processedImage = images.find(image => image.path === oldSrc);
    const { file } = processedImage;
    const objectURL = URL.createObjectURL(file);
    ch$(this).attr('src', objectURL);
  });
  return ch$.html();
}

export function applyExtraHtml(html) {
  const ch$ = cheerio.load(html);
  ch$('a').each(function () {
    ch$(this).attr('onclick', 'handleAlert(event)');
  });

  ch$('body').append(
    `<button onclick="topFunction()" id="toTopBtn" title="Go to top">Top</button>`,
  );
  ch$('body').append(EXTRA_JS);
  ch$('body').append(EXTRA_STYLES);

  return ch$.html();
}

export function parseHTMLSections(html) {
  const ch$ = cheerio.load(html);
  const sections = {};
  ch$('h1, h2').each(function (index, element) {
    const allKeys = Object.keys(sections);
    const name = oFetch(element, 'name');
    if (name === 'h1') {
      const id = oFetch(element, 'attribs.id');
      sections[index] = {
        title: ch$(this).text(),
        id,
        child: [],
      };
    }
    if (name === 'h2') {
      const lastId = allKeys[allKeys.length - 1];
      const id = element?.attribs?.id;

      sections[lastId] = {
        ...sections[lastId],
        child: [
          ...sections[lastId].child,
          {
            title: ch$(this).text(),
            id,
          },
        ],
      };
    }
  });

  return sections;
}

export async function processTrainingMaterialsZip(data) {
  const zipjsObjects = await loadZipFile({ data });
  const result = await pipe(
    validateFolderCount,
    validateHtmlFile,
    validateImageExtension,
    processHtmlFile,
    processImageFiles,
  )({ zipjsObjects });
  return result;
}
