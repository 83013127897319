import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as accessoryTypes from '../accessories/redux/constants';

import {
  INITIAL_LOAD,
  EDIT_PROFILE,
  CANCEL_EDIT_PROFILE,
  SHOW_DISABLE_MODAL,
  HIDE_DISABLE_MODAL,
  SHOW_EDIT_AVATAR_MODAL,
  HIDE_EDIT_AVATAR_MODAL,
  UPDATE_STAFF_MEMBER,
  UPDATE_DOWNLOAD_LINK_LAST_SENT_AT,
  UPDATE_STAFF_MEMBER_BANK_DETAILS,
  UPDATE_BASE_SECURITY_PAYRATES,
  DISABLE_STAFF_MEMBER,
} from './constants';

const initialState = fromJS({
  staffMember: {},
  accessToken: null,
  editProfile: false,
  staffTypes: [],
  venues: [],
  securityStaffTypes: [],
  payRates: [],
  securityPayRates: [],
  disableStaffMemberModal: false,
  editAvatarModal: false,
  currrentTaxYear: null,
  permissionsData: {
    canEnable: false,
    canMarkRetakeAvatar: false,
  },
  accessibleVenues: [],
});

const profileReducer = handleActions(
  {
    [INITIAL_LOAD]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const staffMember = oFetch(payload, 'staffMember');
      const accessToken = oFetch(payload, 'accessToken');
      const staffTypes = oFetch(payload, 'staffTypes');
      const venues = oFetch(payload, 'venues');
      const securityStaffTypes = oFetch(payload, 'securityStaffTypes');
      const payRates = oFetch(payload, 'payRates');
      const genderValues = oFetch(payload, 'genderValues');
      const accessibleVenueIds = oFetch(payload, 'accessibleVenueIds');
      const accessiblePayRateIds = oFetch(payload, 'accessiblePayRateIds');
      const reviveFormConfig = oFetch(payload, 'reviveFormConfig');
      const appDownloadLinks = oFetch(payload, 'appDownloadLinks');
      const permissionsData = oFetch(payload, 'permissionsData');
      const forStandardSecurityStaffSecurityPayRates = oFetch(
        payload,
        'forStandardSecurityStaffSecurityPayRates',
      );
      const forStewardSecurityStaffSecurityPayRates = oFetch(
        payload,
        'forStewardSecurityStaffSecurityPayRates',
      );
      return state
        .set('staffMember', fromJS(staffMember))
        .set('reviveFormConfig', fromJS(reviveFormConfig))
        .set('accessToken', fromJS(accessToken))
        .set('staffTypes', fromJS(staffTypes))
        .set('securityStaffTypes', fromJS(securityStaffTypes))
        .set('venues', fromJS(venues))
        .set('payRates', fromJS(payRates))
        .set('genderValues', fromJS(genderValues))
        .set(
          'accessiblePayRates',
          accessiblePayRateIds.map(id => {
            return payRates.find(payRate => {
              return oFetch(payRate, 'id') === id;
            });
          }),
        )
        .set('accessibleBaseStandardSecurityPayRates', forStandardSecurityStaffSecurityPayRates)
        .set('accessibleBaseStewardSecurityPayRates', forStewardSecurityStaffSecurityPayRates)
        .set(
          'accessibleVenues',
          accessibleVenueIds.map(id => {
            return venues.find(venue => {
              return oFetch(venue, 'id') === id;
            });
          }),
        )
        .set('appDownloadLinks', fromJS(appDownloadLinks))
        .set('permissionsData', fromJS(permissionsData));
    },
    [UPDATE_BASE_SECURITY_PAYRATES]: (state, action) => {
      const [forStandardSecurityStaffSecurityPayRates, forStewardSecurityStaffSecurityPayRates] = oFetch(
        action.payload,
        'forStandardSecurityStaffSecurityPayRates',
        'forStewardSecurityStaffSecurityPayRates',
      );
      return state
        .set('accessibleBaseStandardSecurityPayRates', forStandardSecurityStaffSecurityPayRates)
        .set('accessibleBaseStewardSecurityPayRates', forStewardSecurityStaffSecurityPayRates);
    },
    [accessoryTypes.ADD_ACCESSORY]: (state, action) => {
      const permissions = oFetch(action.payload, 'permissions');
      return Object.keys(permissions).reduce((acc, accessoryId) => {
        return acc.setIn(
          ['permissionsData', 'accessoriesTab', 'accessory_requests', accessoryId],
          fromJS(permissions[accessoryId]),
        );
      }, state);
    },
    [DISABLE_STAFF_MEMBER]: (state, action) => {
      const [permissionsData, reviveFormConfig] = oFetch(
        action.payload,
        'permissionsData',
        'reviveFormConfig',
      );
      const canEnable = oFetch(permissionsData, 'canEnable');

      return state
        .setIn(['permissionsData', 'canEnable'], canEnable)
        .set('reviveFormConfig', fromJS(reviveFormConfig));
    },
    [UPDATE_STAFF_MEMBER]: (state, action) => {
      const staffMember = action.payload;
      return state.set('staffMember', fromJS(staffMember));
    },
    [UPDATE_STAFF_MEMBER_BANK_DETAILS]: (state, action) => {
      const bankDetails = action.payload;
      const [altBankingFullname, bankDetailsLastUpdatedBy, bankDetailsLastUpdatedAt] = oFetch(
        bankDetails,
        'altBankingFullname',
        'bankDetailsLastUpdatedBy',
        'bankDetailsLastUpdatedAt',
      );
      return state
        .setIn(['staffMember', 'bankDetailsLastUpdatedBy'], bankDetailsLastUpdatedBy)
        .setIn(['staffMember', 'bankDetailsLastUpdatedAt'], bankDetailsLastUpdatedAt)
        .setIn(['staffMember', 'altBankingFullname'], altBankingFullname)
        .setIn(['staffMember', 'hasBankDetails'], true);
    },
    [UPDATE_DOWNLOAD_LINK_LAST_SENT_AT]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const mobileAppId = oFetch(payload, 'mobileAppId');
      const sentAt = oFetch(payload, 'sentAt');
      const stateData = state.toJS();

      const newAppDownloadLinks = oFetch(stateData, 'appDownloadLinks').map(appDownloadLink => {
        if (oFetch(appDownloadLink, 'mobileAppId') == mobileAppId) {
          return Object.assign(appDownloadLink, { lastSentAt: sentAt });
        } else {
          return appDownloadLink;
        }
      });

      return state.set('appDownloadLinks', newAppDownloadLinks);
    },
    [EDIT_PROFILE]: state => {
      return state.set('editProfile', true);
    },
    [CANCEL_EDIT_PROFILE]: state => {
      return state.set('editProfile', false);
    },
    [SHOW_DISABLE_MODAL]: state => {
      return state.set('disableStaffMemberModal', true);
    },
    [HIDE_DISABLE_MODAL]: state => {
      return state.set('disableStaffMemberModal', false);
    },
    [SHOW_EDIT_AVATAR_MODAL]: state => {
      return state.set('editAvatarModal', true);
    },
    [HIDE_EDIT_AVATAR_MODAL]: state => {
      return state.set('editAvatarModal', false);
    },
  },
  initialState,
);

export default profileReducer;