import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { BossTableRow, BossTableCell } from '@/components/boss-table';
import { UserSummary } from '@/components/staff-members';

function AccessoryRequestItem(props) {
  const data = oFetch(props, 'data');
  const actionsRender = oFetch(props, 'actionsRender');
  const { reusable } = data;
  const [avatarUrl, fullName, accessorySize, staffMember, frozen] = oFetch(
    data,
    'avatarUrl',
    'fullName',
    'accessorySize',
    'staffMember',
    'frozen',
  );

  const supportsFacialRecognition = oFetch(staffMember, 'supportsFacialRecognition');

  return (
    <BossTableRow>
      <BossTableCell>
        <UserSummary
          className="boss-user-summary_role_review-short"
          src={avatarUrl}
          alt={fullName}
          fullName={fullName}
          supportsFacialRecognition={supportsFacialRecognition}
        />
      </BossTableCell>
      <BossTableCell>
        <div className="boss-table__actions">
          <a
            href={`staff_members/${staffMember.id}/accessories`}
            className="boss-button boss-button_type_extra-small boss-button_role_view-report boss-table__action"
          >
            History
          </a>
        </div>
      </BossTableCell>
      <BossTableCell>
        <p className="boss-table__text">Size: {accessorySize}</p>
        {reusable === true && (
          <p className="boss-table__text boss-table__text_indicator_checkbox-checked">Reusable:</p>
        )}
      </BossTableCell>
      <BossTableCell>{!frozen && actionsRender()}</BossTableCell>
    </BossTableRow>
  );
}

export default AccessoryRequestItem;
