import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import oFetch from 'o-fetch';
import * as types from '../../profile-wrapper/constants';
const initialState = Immutable.fromJS([]);

export default handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const staffMemberHistory = oFetch(action.payload, 'staffMemberHistory');
      return Immutable.fromJS(staffMemberHistory);
    },
  },
  initialState,
);
