import React from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';
import { ModalProvider } from '@/components/modals';

import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import VenueHealthCheckQuestionnaire from './venue-health-check-questionnaire';
import * as actions from './redux/actions';

class VenueHealthCheckQuestionnaireApp extends React.Component {
  state = {
    store: null,
  };

  componentDidMount() {
    const accessToken = oFetch(this.props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialState(this.props));
    this.setState({ store });
  }

  render() {
    if (!this.state.store) {
      return null;
    }

    return (
      <Provider store={this.state.store}>
        <ModalProvider>
          <VenueHealthCheckQuestionnaire />
        </ModalProvider>
      </Provider>
    );
  }
}

export default VenueHealthCheckQuestionnaireApp;
