import React, { Fragment } from 'react';
import classNames from 'classnames';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';
import humanize from 'string-humanize';
import AsyncButton from 'react-async-button';

import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function StatusColumn(props) {
  const isAdminPlus = oFetch(useLegacyGlobal(), 'isAdminPlus');

  const [financeReport, onMarkCompleted, onUncomplete, isFinanceReportWeekFinished] = oFetch(
    props,
    'financeReport',
    'onMarkCompleted',
    'onUncomplete',
    'isFinanceReportWeekFinished',
  );
  const [staffMemberId, completionDateReached, status, completedAt, hoursPending] = oFetch(
    financeReport,
    'staffMemberId',
    'completionDateReached',
    'status',
    'completedAt',
    'hoursPending',
  );
  const total = utils.round(oFetch(financeReport, 'total'), 2);
  const isNegativeTotal = total < 0;

  const hasIncompleteHolidays = oFetch(financeReport, 'hasIncompleteHolidays');

  const reportCompletable = oFetch(financeReport, "completable");
  const dataIncomplete = oFetch(financeReport, "dataIncomplete");

  const effectiveStatus = getEffectiveStatus();
  const isRequiringUpdate = effectiveStatus === 'requiring_update';

  const statusClassName = classNames('boss-table__text', {
    'boss-table__text_role_pending-status': effectiveStatus === 'ready' || isRequiringUpdate,
    'boss-table__text_role_alert-status': dataIncomplete,
    'boss-table__text_role_success-status': status === 'done',
  });

  function getEffectiveStatus() {
    if (status === 'ready') {
      if (!completionDateReached) {
        return 'pending';
      }
      if (dataIncomplete) {
        return 'incomplete';
      }
    }
    return status;
  }

  function renderTooltip(content) {
    return (
      <Tooltip arrow theme="light" position="bottom" interactive html={content}>
        <p className="boss-table__text boss-table__text_role_action boss-table__text_role_details boss-table__text_role_success-status">
          Done
        </p>
      </Tooltip>
    );
  }

  function tooltipContent() {
    return (
      <span>
        Completed at: {safeMoment.iso8601Parse(completedAt).format(utils.humanDateFormatWithTime())}
      </span>
    );
  }

  return (
    <div className="boss-table__cell">
      {completedAt && (
        <Fragment>
          {renderTooltip(tooltipContent())}
          {isAdminPlus && !isFinanceReportWeekFinished && (
            <div className="boss-table__actions">
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-button_role_undo-light boss-table__action"
                text="Undo Completion"
                pendingText="Processing ..."
                onClick={() => onUncomplete({ staffMemberId })}
              />
            </div>
          )}
        </Fragment>
      )}
      { !completedAt && (
        <p className={statusClassName}>{humanize(effectiveStatus)}</p>
      )}
      { reportCompletable && (
        <div className="boss-table__actions">
          <AsyncButton
            className="boss-button boss-button_type_extra-small boss-button_role_confirm-light boss-table__action"
            text="Mark Completed"
            pendingText="Processing ..."
            onClick={() => onMarkCompleted({ staffMemberId, isNegativeTotal })}
          />
        </div>
      )}
    </div>
  );
}
