import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import cn from 'classnames';
import oFetch from 'o-fetch';

export default function Review(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [children, canClose] = oFetch(props, 'children', 'canClose');

  useEffect(() => {
    if (canClose === false && isOpened === false) {
      setIsOpened(true);
    }
    if (canClose === true && isOpened === true) {
      setIsOpened(false);
    }
  }, [canClose]);

  function toggleDropDown() {
    if (isOpened === true && canClose === false) {
      return;
    }
    setIsOpened(!isOpened);
  }

  const buttonClassNames = cn('boss-dropdown__switch boss-dropdown__switch_role_user-review', {
    'boss-dropdown__switch_state_opened': isOpened,
  });

  return (
    <div className="boss-page-main__group boss-page-main__group_space_extra">
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <button type="button" onClick={toggleDropDown} className={buttonClassNames}>
            Review
          </button>
        </div>
        <Collapse
          isOpened={isOpened}
          className="boss-dropdown__content"
          style={{ display: 'block' }}
        >
          <div className="boss-board__content-inner">
            <div className="boss-vetting">{children}</div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
