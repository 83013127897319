import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  currentPage: 1,
  totalPages: 0,
  totalCount: 0,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const [currentPage, totalPages, totalCount] = oFetch(
        action,
        'payload.currentPage',
        'payload.totalPages',
        'payload.totalCount',
      );
      return {
        currentPage,
        totalPages,
        totalCount,
      };
    },
  },
  initialState,
);
