import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { NoteCollapsible } from './note-collapsible';

TemporaryItem.defaultProps = {
  renderActions: () => { },
};

export function TemporaryItem(props) {
  const [item, renderActions] = oFetch(props, 'item', 'renderActions');
  const [
    venueName,
    startDate,
    startTime,
    endDate,
    endTime,
    isTemporaryAi,
    isTemporaryUser,
    note,
    createdAt,
  ] = oFetch(
    item,
    'venueName',
    'startDate',
    'startTime',
    'endDate',
    'endTime',
    'isTemporaryAi',
    'isTemporaryUser',
    'note',
    'createdAt',
  );

  function getFormattedTime() {
    if (isTemporaryAi) {
      const format = utils.commonDateFormatWithTime();
      const formattedStart = safeMoment.iso8601Parse(startTime).format(format);
      const formattedEnd = safeMoment.iso8601Parse(endTime).format(format);
      return { formattedStart, formattedEnd };
    }
    if (isTemporaryUser) {
      const format = utils.uiRotaDateFormat;
      const formattedStart = safeMoment.uiDateParse(startDate).format(format);
      const formattedEnd = safeMoment.uiDateParse(endDate).format(format);
      return { formattedStart, formattedEnd };
    }
    throw new Error('Incorrect timed type');
  }

  const formattedStartTime = oFetch(getFormattedTime(), 'formattedStart');
  const formattedEndTime = oFetch(getFormattedTime(), 'formattedEnd');

  const indicatorClassNames = cn('boss-indicator__icon', {
    'boss-indicator__icon_ai-chip': isTemporaryAi,
    'boss-indicator__icon_user-solid': isTemporaryUser,
  });

  return (
    <div className="boss-table__row">
      <div className="boss-table__column">
        <div className="boss-table__cell">
          <p className="boss-table__text">
            <span className="boss-indicator boss-table__indicator">
              <span className={indicatorClassNames} />
            </span>
          </p>
        </div>
      </div>
      <div className="boss-table__column">
        <div className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Venue</p>
              <p className="boss-table__text">
                <span
                  className="boss-tag boss-tag_size_xxs"
                  style={{ backgroundColor: '#84bef0' }}
                >
                  <span className="boss-tag__text">{venueName}</span>
                </span>
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Start Time</p>
              <p className="boss-table__text">{formattedStartTime}</p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">End Time</p>
              <p className="boss-table__text">{formattedEndTime}</p>
            </div>
          </div>
          {renderActions()}
        </div>
        <NoteCollapsible
          note={note}
          createdAt={createdAt}
        />
      </div>
    </div>
  );
}
