import React, { Fragment, useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import CreatableSelect from 'react-select/creatable';
import { useAsyncCallback } from 'react-async-hook';
import cn from 'classnames';

export default function UITagField(props) {
  const [
    label,
    placeholder,
    value,
    onChange,
    options,
    valueKey,
    labelKey,
    name,
    onCreateTag,
    errors,
  ] = oFetch(
    props,
    'label',
    'placeholder',
    'value',
    'onChange',
    'options',
    'valueKey',
    'labelKey',
    'name',
    'onCreateTag',
    'errors',
  );

  const asyncOnCreateTag = useAsyncCallback(onCreateTag);
  const isLoading = asyncOnCreateTag.loading;
  const [currentOptions, setCurrentOptions] = useState(options);

  function handleCreateTag(option) {
    return asyncOnCreateTag.execute(option).then(response => {
      const purpleContactTag = oFetch(response, 'purpleContactTag');
      const tagId = oFetch(purpleContactTag, 'id');
      setCurrentOptions(prevOptions => {
        return [...prevOptions, purpleContactTag];
      });
      return onChange([...value, tagId]);
    });
  }

  function getNormalizedOptions() {
    return currentOptions.map(option => {
      return {
        value: option[valueKey],
        label: option[labelKey],
      };
    });
  }

  function getNormalizedValue(value) {
    if (!value) {
      return [];
    }
    return value.map(valueItem => {
      const optionItem = currentOptions.find(
        option => option[valueKey].toString() === valueItem.toString(),
      );
      return {
        value: valueItem,
        label: optionItem[labelKey],
      };
    });
  }

  function handleChange(selected) {
    if (!selected) {
      return onChange([]);
    }
    const normalizedSelected = selected.map(selectedItem => {
      return oFetch(selectedItem, 'value');
    });
    return onChange(normalizedSelected);
  }

  const hasErrors = errors.length > 0;
  const fieldClassNames = cn('purple-form-select', { 'purple-form-select_state_error': hasErrors });

  function renderErrors(errors) {
    return (
      <div className="purple-form-error purple-form-error_position_below">
        {errors.map((error, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <div className={fieldClassNames}>
      <p className="purple-form-select__label">
        <span className="purple-form-select__label-text">{label}</span>
      </p>
      <div className="purple-form-select__component">
        <CreatableSelect
          isMulti
          name={name}
          classNamePrefix="react-select"
          placeholder={placeholder}
          options={getNormalizedOptions()}
          value={getNormalizedValue(value)}
          onChange={handleChange}
          isDisabled={isLoading}
          isLoading={isLoading}
          isClearable
          onCreateOption={handleCreateTag}
          className="react-select react-select--multiple"
        />
      </div>
      {hasErrors && renderErrors(errors)}
    </div>
  );
}

UITagField.defaultProps = {
  label: null,
  placeholder: null,
  valueKey: 'value',
  labelKey: 'label',
  errors: [],
};
