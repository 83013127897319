import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function HealthCheckTableRowItem(props) {
  const healthCheck = oFetch(props, 'healthCheck');
  const [passed, formattedAt, fullName, url] = oFetch(healthCheck, 'passed', 'formattedAt', 'fullName', 'url');

  const statusClassNames = cn('boss-button boss-button_type_small', {
    'boss-button_role_alert': !passed,
    'boss-button_role_secondary': passed,
  });
  const statusText = passed ? "Passed" : "Failed";

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Date</p>
          <p className="boss-table__text">
            <a href={url} className="boss-table__link">
              {formattedAt}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Created By</p>
          <p className="boss-table__text">
            <a href={url} className="boss-table__link">
              {fullName}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Status</p>
          <p className="boss-table__text">
            <a href={url} className={statusClassNames}>
              {statusText}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Action</p>
          <div className="boss-table__actions">
            <a href={url} className="boss-button boss-button_role_view-report boss-table__action">
              View Report
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
