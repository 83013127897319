import React from 'react';
import oFetch from 'o-fetch';

import DashboardWrapper from '../dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import ChecklistList from '../checklist-list';
import SubmitChecklistItem from '../submit-checklist-item';

export default function ViewMode(props) {
  const [onEditMode, checklists, onSubmit, onPrintGuid] = oFetch(
    props,
    'onEditMode',
    'checklists',
    'onSubmit',
    'onPrintGuid',
  );

  function handleChecklistSubmit(values, dispatch, props, toggleCollapse) {
    const form = oFetch(props, 'form');
    return onSubmit({ values, form }).then(() => {
      toggleCollapse();
    });
  }

  function renderItem(checklist) {
    const [checklistId, name, guid, items] = oFetch(checklist, 'id', 'name', 'guid', 'items');

    const initialValues = {
      checklistId: checklistId,
      guid: guid,
      items: items.map(item => {
        const [id, description] = oFetch(item, 'id', 'description');

        return {
          answerId: id,
          description,
          note: null,
          answer: false,
        };
      }),
    };

    const checklistFormName = `submit-checklist-${checklistId}`;

    return (
      <SubmitChecklistItem
        form={checklistFormName}
        initialValues={initialValues}
        submitChecklist={handleChecklistSubmit}
        checklist={checklist}
        onPrintGuid={onPrintGuid}
      />
    );
  }

  return (
    <div>
      <DashboardWrapper title="Check Lists">
        <button
          onClick={() => (window.location = '/checklist_submissions')}
          type="button"
          className="boss-button boss-button_role_view-submissions boss-page-dashboard__button"
        >
          View Submissions
        </button>
        <button
          onClick={onEditMode}
          type="button"
          className="boss-button boss-button_role_edit-mode boss-page-dashboard__button"
        >
          Edit Mode
        </button>
      </DashboardWrapper>
      <ContentWrapper>
        <ChecklistList onPrintGuid={onPrintGuid} itemRenderer={renderItem} checklists={checklists} />
      </ContentWrapper>
    </div>
  );
}
