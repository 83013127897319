import React, { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';

import configureStore from '@/apps/store';
import reducers from './reducers';
import { initialLoad } from './actions';

import RotaDaily from './container/rota-daily';
import { BOSS_APP_TYPE, RotaDateUIUtils } from '@/lib/rota-date';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';

export function SecurityRotaDailyApp(props) {
  const [store, setStore] = useState(null);
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  const appType = BOSS_APP_TYPE;

  const timeSelectOptions = useMemo(() => {
    const graphRotaDate = RotaDateUIUtils.sFromDate({ sDate: oFetch(props, 'date'), appType });
    return graphRotaDate.getSelectOptions({ intervalSeconds: 60 * 30 });
  }, []);

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(initialLoad({ ...props, appType }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <LegacyGlobalProvider global={{ appType, timeSelectOptions }}>
    <Provider store={store}>
      <RotaDaily />
    </Provider>
    </LegacyGlobalProvider>
  );
}
