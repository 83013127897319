import React from 'react';
import oFetch from 'o-fetch';
import { CompleteTaskForm } from '../forms';

export function CompleteTaskModalContent(props) {
  const marketingTask = oFetch(props, 'marketingTask');
  const onSubmit = oFetch(props, 'onSubmit');

  const initialValues = {
    taskId: oFetch(marketingTask, 'id'),
    fileIds: [],
    note: '',
  };

  return (
    <div className="boss-modal-window__form">
      <CompleteTaskForm initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  );
}
