import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';

import { INITIAL_LOAD, ADD_HOLIDAY, ADD_HOLIDAY_STAFF_MEMBER, CALCULATE_HOLIDAYS, CALCULATE_STAFF_MEMBERS } from './constants';

import { createHolidayRequest, createHolidayRequestRequest } from './requests';

export const initialLoad = createAction(INITIAL_LOAD);
export const addHoliday = createAction(ADD_HOLIDAY);
export const addHolidayStaffMember = createAction(ADD_HOLIDAY_STAFF_MEMBER);
export const calculateHolidays = createAction(CALCULATE_HOLIDAYS);
export const calculateStaffMembers = createAction(CALCULATE_STAFF_MEMBERS);

export const createHolidayAction = (values, hideModal, appType) => (dispatch, getState) => {
  return createHolidayRequest(values, appType).then(resp => {
    const effectiveStaffMember = oFetch(resp, 'data.effectiveStaffMember');
    hideModal();
    dispatch([
      addHolidayStaffMember(effectiveStaffMember),
      calculateHolidays(),
      calculateStaffMembers(),
    ]);
    return resp.data;
  });
};

export const createHolidayRequestAction = (values, hideModal, appType) => (dispatch, getState) => {
  return createHolidayRequestRequest(values, appType).then(resp => {
    hideModal();
    return resp.data;
  });
};
