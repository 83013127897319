import React from 'react';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';

export default class BossFormMultiSelectWithSelectAll extends React.Component {
  state = {
    selectAllChecked: false,
  };

  constructor(props) {
    super(props);
    const [options, value] = oFetch(this.props, 'options', 'input.value');
    this.state = {
      selectAllChecked: options.length === value.length,
    };
  }

  handleChange = values => {
    const onChange = oFetch(this.props, 'input.onChange');
    onChange(
      values
        .split(',')
        .map(value => utils.safeGet(() => parseInt(value)) || value)
        .filter(Boolean),
    );
  };

  handleSelectAllChange = e => {
    this.setState(
      state => ({ selectAllChecked: !state.selectAllChecked }),
      () => {
        const selectAllChecked = oFetch(this.state, 'selectAllChecked');
        const [options, valueKey] = oFetch(this.props, 'options', 'valueKey');
        if (selectAllChecked) {
          this.handleChange(options.map(option => oFetch(option, valueKey)).join(','));
        }
      },
    );
  };

  render() {
    const [input, meta, multi, clearable, valueKey, labelKey, options] = oFetch(
      this.props,
      'input',
      'meta',
      'multi',
      'clearable',
      'valueKey',
      'labelKey',
      'options',
    );
    const [name, value] = oFetch(input, 'name', 'value', 'onBlur');
    const selectAllChecked = oFetch(this.state, 'selectAllChecked');
    const { error, touched } = meta;
    const { label, placeholder } = this.props;
    const fieldLabel = label === undefined ? name : label;
    return (
      <div className="boss-form__field">
        <div className="boss-form__label-group">
          <p className="boss-form__label">
            <span className="boss-form__label-text">{fieldLabel}</span>
          </p>

          <label className="boss-form__checkbox-label boss-form__checkbox-label_context_label-group">
            <input
              onChange={this.handleSelectAllChange}
              checked={selectAllChecked}
              type="checkbox"
              className="boss-form__checkbox-input"
            />
            <span className="boss-form__checkbox-label-text">Select All</span>
          </label>
        </div>
        <div
          className={`boss-form__select ${touched && error ? 'boss-form__select_state_error' : ''}`}
        >
          <Select
            name={name}
            disabled={selectAllChecked}
            value={value}
            valueKey={valueKey}
            labelKey={labelKey}
            onChange={this.handleChange}
            clearable={clearable}
            simpleValue
            multi={multi}
            placeholder={placeholder}
            options={options}
          />
        </div>
        {touched && error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
      </div>
    );
  }
}

BossFormMultiSelectWithSelectAll.propTypes = {
  label: PropTypes.string,
  fieldClassName: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

BossFormMultiSelectWithSelectAll.defaultProps = {
  clearable: false,
  valueKey: 'value',
  labelKey: 'label',
  multi: false,
};
