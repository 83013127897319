import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';

import ErrorsSection from './errors-section';
import { CSVTable, CSVTableCell, CSVTableRow } from '../csv-table';

function TooltipContent(props) {
  const [header, error] = oFetch(props, 'header', 'error');
  return (
    <div className="boss-popover__inner">
      <p className="boss-popover__text boss-popover__text_role_primary">
        <span className="boss-popover__text-marked">{header}</span> {error}
      </p>
    </div>
  );
}

export default function ParseErrorPayments(props) {
  const [headerRowErrors, titleRowErrors, headerRows] = oFetch(
    props,
    'headerRowErrors',
    'titleRowErrors',
    'headerRows',
  );
  const issuedHeaders = Object.keys(headerRowErrors);
  const headerRowErrorsExist = issuedHeaders.length !== 0;
  const titleRowErrorsExist = Object.keys(titleRowErrors).length !== 0;

  function renderTitleRowFormatErrors() {
    return (
      <ErrorsSection title="Title row format is incorrect">
        {Object.entries(titleRowErrors).map((titleRowErrorEntry, errorIndex) => {
          const [key, errors] = titleRowErrorEntry;

          return (
            <div key={errorIndex} className="boss-board__group">
              <div className="boss-report">
                <div className="boss-report__record">
                  <p className="boss-report__text boss-report__text_size_m">
                    Table {key} {errors}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </ErrorsSection>
    );
  }

  function renderInvalidHeadersTable() {
    return (
      <CSVTable>
        <CSVTableRow isHeader isAlert>
          {headerRows.map((header, index) => {
            const error = headerRowErrors[header];
            const isInvalid = !!error;

            if (isInvalid) {
              return (
                <Tooltip
                  key={index}
                  html={<TooltipContent header={header} error={error} />}
                  position="top"
                  trigger="click"
                  arrow
                  theme="light"
                >
                  <CSVTableCell isInvalid={isInvalid} text={header} />
                </Tooltip>
              );
            }
            return <CSVTableCell isHeader key={index} text={header} />;
          })}
        </CSVTableRow>
      </CSVTable>
    );
  }

  function renderHeaderRowFormatErrors() {
    return (
      <ErrorsSection title="Header row format is incorrect">
        <div className="boss-board__group">
          <div className="boss-report">
            <div className="boss-report__record">
              {Object.entries(headerRowErrors).map((headerRowErrorEntry, errorIndex) => {
                const [key, error] = headerRowErrorEntry;
                return (
                  <p
                    key={errorIndex}
                    className="boss-report__text boss-report__text_size_m boss-report__text_adjust_wrap"
                  >
                    <span className="boss-report__text-marked">{key}</span> {error}
                  </p>
                );
              })}
              {renderInvalidHeadersTable()}
            </div>
          </div>
        </div>
      </ErrorsSection>
    );
  }
  return (
    <Fragment>
      {titleRowErrorsExist && renderTitleRowFormatErrors()}
      {headerRowErrorsExist && renderHeaderRowFormatErrors()}
    </Fragment>
  );
}
