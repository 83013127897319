import { handleActions, combineActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const sortIndices = oFetch(action.payload, 'sortIndices');
      return sortIndices;
    },
    [commonTypes.SORT]: (state, action) => {
      const sortingIds = oFetch(action, 'payload');
      return sortingIds.map((item, index) => {
        return {
          ...item,
          sortIndex: index,
        };
      });
    },
  },
  initialState,
);
