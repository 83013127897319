import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';

import { ChecklistForm } from './checklist-form';

export default class EditChecklist extends React.Component {
  handleOpen = () => {
    // not used
  };

  handleClose = () => {
    // not used
  };

  handleSubmit = (values, dispatch, props) => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    return onSubmit(values);
  };

  render() {
    const checklist = oFetch(this.props, 'checklist');
    const [checklistId, name, items] = oFetch(checklist, 'id', 'name', 'items');

    const initialValues = {
      checklistId,
      name: name,
      items: items,
    };

    return (
      <ContentWrapper>
        <ChecklistForm
          edit={true}
          form="edit-checklist-form"
          initialValues={initialValues}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          isOpened={true}
          onSubmit={this.handleSubmit}
        />
      </ContentWrapper>
    );
  }
}
