import React from 'react';
import oFetch from 'o-fetch';
import { Attachment } from '../../attachment';

export function AttachmentChangesList(props) {
  const attachments = oFetch(props, 'attachments');
  return (
    <>
      {attachments.map(attachment => {
        const id = oFetch(attachment, 'id');
        return (
          <Attachment
            key={id}
            attachment={attachment}
            isNew={oFetch(attachment, 'isAdded')}
            isDeleted={oFetch(attachment, 'isDeleted')}
          />
        );
      })}
    </>
  );
}
