import { Fields } from '@/components/boss-form';
import React from 'react';
import { Form } from 'react-final-form';
import { WhatsappFeatureInstancesField } from '../whatsapp-feature-instances-field';

export type WhatsappFeatureAssignmentFormValues = {
  whatsappFeatureAssignmentId: number;
  mainWhatsappInstanceId: number | null;
  fallbackWhatsappInstanceId: number | null;
};

type Props = {
  onSubmit: () => void;
  initialValues: WhatsappFeatureAssignmentFormValues;
  processing: boolean;
  whatsappInstanceOptions: any[];
};

export function WhatsappFeatureAssignmentForm(props: Props) {
  const { onSubmit, initialValues, processing, whatsappInstanceOptions } = props;

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => {
            const disabled = submitting || processing;
            return (
              <>
                <Fields
                  names={['mainWhatsappInstanceId', 'fallbackWhatsappInstanceId']}
                  processing={processing}
                  options={whatsappInstanceOptions}
                  originalRender={WhatsappFeatureInstancesField}
                />

                <div className="boss-form__field">
                  <div
                    className='boss-page-dashboard__buttons-group boss-page-dashboard_page_hours-confirmation'
                    style={{ alignItems: 'center' }}
                  >
                    <button
                      onClick={handleSubmit}
                      disabled={disabled}
                      className="boss-button boss-button_role_confirm boss-form__submit boss-page-dashboard__button"
                      type="button"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
