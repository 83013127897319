import React from 'react';
import ContactForm from './contact-form';
import oFetch from 'o-fetch';

export default function AddContact(props) {
  const [onSubmit, tagOptions, onCreateContactTag, permissions] = oFetch(
    props,
    'onSubmit',
    'tagOptions',
    'onCreateContactTag',
    'permissions',
  );

  const initialValues = {
    firstName: '',
    surname: '',
    image: null,
    tags: [],
  };

  const canCreateTags = oFetch(props, 'permissions.canCreateTags');

  return (
    <ContactForm
      onSubmit={onSubmit}
      tagOptions={tagOptions}
      initialValues={initialValues}
      onCreateContactTag={onCreateContactTag}
      canCreateTags={canCreateTags}
      buttonTitle="Add Contact"
    />
  );
}
