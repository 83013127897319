import React from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { SecurityPayRates } from './security-pay-rates';

export function SecurityPayRatesApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return (
    <LegacyGlobalProvider global={{ backendConstants: oFetch(props, 'backendConstants') }}>
      <ModalsProvider>
        <SecurityPayRates {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
