import React, { Component } from 'react';
import oFetch from 'o-fetch';

class SecurityShiftRequestList extends Component {
  renderItems(securityShiftRequests) {
    const itemRenderer = oFetch(this.props, 'itemRenderer');

    return securityShiftRequests.map(securityShiftRequest => {
      const securityShiftRequestId = oFetch(securityShiftRequest, 'id');

      return React.cloneElement(itemRenderer(securityShiftRequest), {
        key: securityShiftRequestId.toString(),
      });
    });
  }

  render() {
    const securityShiftRequests = oFetch(this.props, 'securityShiftRequests');

    if (securityShiftRequests.length === 0) {
      return (
        <div className="boss-board__inner">
          <div className="boss-board__cards">
            <h1 className="boss-page-main__text-placeholder">No requests found</h1>
          </div>
        </div>
      );
    }

    return (
      <div className="boss-board__inner">
        <div className="boss-board__table">
          <div
            className={`boss-table boss-table_page_ssr-${this.props.isCompleted ? 'completed' : 'pending'}`}
          >
            <div className="boss-table__row">
              <div className="boss-table__cell boss-table__cell_role_header">Requested times</div>
              <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
              <div className="boss-table__cell boss-table__cell_role_header">Note</div>
              {this.props.isCompleted && (
                <div className="boss-table__cell boss-table__cell_role_header">Rotaed Shift</div>
              )}
              <div className="boss-table__cell boss-table__cell_role_header">Status</div>
              <div className="boss-table__cell boss-table__cell_role_header">Actions</div>
            </div>
            {this.renderItems(securityShiftRequests)}
          </div>
        </div>
      </div>
    );
  }
}

export default SecurityShiftRequestList;
