import React from 'react';
import oFetch from 'o-fetch';
import * as eventTypes from '../activities/event-types';
import { CreateQuestionEventType } from '../activities/create-question-event-type';
import { UpdateQuestionEventType } from '../activities/update-question-event-type';
import { CreateAnswerEventType } from '../activities/create-answer-event-type';
import { CreateClarificationEventType } from '../activities/create-clarification-event-type';
import { AcceptAnswerEventType } from '../activities/accept-answer-event-type';
import { DeleteRequestEventType } from '../activities/delete-request-event-type';

export function ActivityList(props) {
  const events = oFetch(props, 'events');

  function renderActivities() {
    return events.map(event => {
      const [eventId, eventType] = oFetch(event, 'id', 'eventType');
      if (eventType === eventTypes.CREATE_QUESTION_EVENT_TYPE) {
        return <CreateQuestionEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.UPDATE_QUESTION_EVENT_TYPE) {
        return <UpdateQuestionEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.CREATE_ANSWER_EVENT_TYPE) {
        return <CreateAnswerEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.REQUESTED_CLARIFICATION_EVENT_TYPE) {
        return <CreateClarificationEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.ACCEPT_ANSWER_EVENT_TYPE) {
        return <AcceptAnswerEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.DELETE_REQUEST_EVENT_TYPE) {
        return <DeleteRequestEventType key={eventId} event={event} />;
      }
      return null;
    });
  }

  return <ul className="boss-overview__activity">{renderActivities()}</ul>;
}
