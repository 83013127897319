import { z } from 'zod';
import { $BossUiDate, $BossUiTime, DATE_REGEX } from '@/lib/schema-funcs';
import { $MossFinanceReportStatus } from '@/apps/MossFinanceReports/types';

export type UpdateMossFinanceReportNotesProps = {
  mossStaffMemberId: number;
  note: string;
  date: Date;
  venueId: number;
  onSuccess: () => void;
  onFailure: () => void;
};

const $MossHourTag = z.object({
  id: z.number(),
  name: z.string(),
  owedHourCents: z.number().int().nonnegative(),
  owedHourMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodCents: z.number().int().nonnegative(),
  mossFinanceReportIds: z.array(z.number().int().positive()),
  isDisabled: z.boolean(),
  disabledAt: z.union([$BossUiDate(z), z.null()]),
  disabledByUserName: z.union([z.string().min(1), z.null()]),
  disabledByUserId: z.union([z.number().int().positive(), z.null()]),
  owedHoursCalculating: z.boolean(),
  hoursAcceptancePeriodsCalculating: z.boolean(),
  isCalculating: z.boolean(),
}).strict();

export type MossHourTag = z.infer<typeof $MossHourTag>;

export const $MossFinanceReport = z.object({
  id: z.number(),
  note: z.union([z.string(), z.null()]),
  completable: z.boolean(),
  calculatedGrossPayCents: z.number().int().nonnegative(),
  mossStaffMemberId: z.number().int().positive(),
  mossStaffMemberName: z.string().min(1),
  mondayHoursCount: z.number().nonnegative(),
  tuesdayHoursCount: z.number().nonnegative(),
  wednesdayHoursCount: z.number().nonnegative(),
  thursdayHoursCount: z.number().nonnegative(),
  fridayHoursCount: z.number().nonnegative(),
  saturdayHoursCount: z.number().nonnegative(),
  sundayHoursCount: z.number().nonnegative(),
  weeklyHoursCount: z.number().nonnegative(),
  mondayHasDeletedHours: z.boolean(),
  tuesdayHasDeletedHours: z.boolean(),
  wednesdayHasDeletedHours: z.boolean(),
  thursdayHasDeletedHours: z.boolean(),
  fridayHasDeletedHours: z.boolean(),
  saturdayHasDeletedHours: z.boolean(),
  sundayHasDeletedHours: z.boolean(),
  owedHoursMinuteCount: z.number().int().nonnegative(),
  mossPayRateDescription: z.string().min(1),
  total: z.number().nonnegative(),
  totalHoursCount: z.number().nonnegative(),
  status: $MossFinanceReportStatus,
  hoursPending: z.boolean(),
  mossStaffMemberDisabled: z.boolean(),
  containsTimeShiftedOwedHours: z.boolean(),
  daysNeedingCompletion: z.record(
    z.string().regex(DATE_REGEX),
    z.array(z.string().min(1)),
  ),
  pendingCalculation: z.boolean(),
  mossStaffTypeName: z.string().min(1),
  mossStaffTypeId: z.number().int().positive(),
  mossStaffTypeUiColor: z.string().regex(/^[0-9a-fA-F]{6}$/),
  completedAt: z.union([$BossUiTime(z), z.null()]),
  noteCreatedBy: z.union([z.string().min(1), z.null()]),
  noteCreatedAt: z.union([$BossUiTime(z), z.null()]),
  dataIncomplete: z.boolean(),
  hasIncompleteHolidays: z.boolean(),
  completionDateReached: z.boolean(),
}).strict();

export type MossFinanceReport = z.infer<typeof $MossFinanceReport>;

const $Venue = z.object({
  id: z.number(),
  name: z.string(),
}).strict();

const $FilteredOutMossHourTag = z.object({
  id: z.number().int().positive(),
  name: z.string().min(1),
  isDisabled: z.boolean(),
}).strict();

export type FilteredOutMossHourTag = z.infer<typeof $FilteredOutMossHourTag>;

const $MossFinanceReportHourTagsAppPermissions = z.object({
  showPDFDownloadLink: z.boolean(),
}).strict();

export const $MossFinanceReportHourTagsAppProps = z.object({
  accessToken: z.string(),
  date: $BossUiDate(z),
  startDate: $BossUiDate(z),
  endDate: $BossUiDate(z),
  weekDates: z.array($BossUiDate(z)),
  currentFilterMossHourTagIds: z.union([
    z.array(z.number().int().positive()),
    z.null(),
  ]),
  mossHourTags: z.array($MossHourTag),
  filteredOutMossHourTags: z.array($FilteredOutMossHourTag),
  mossFinanceReports: z.array($MossFinanceReport),
  isAdminPlus: z.boolean(),
  venue: $Venue,
  permissions: $MossFinanceReportHourTagsAppPermissions,
}).strict();

export type MossFinanceReportHourTagsAppProps = z.infer<typeof $MossFinanceReportHourTagsAppProps>;

export type PageProps = MossFinanceReportHourTagsAppProps;

export type StaffTypeSectionData = {
  id: number;
  name: string;
  totalCents: number;
  uiColor: string;
  isCalculating: boolean;
  mossFinanceReports: MossFinanceReport[];
  updateMossFinanceReportNote: (props: UpdateMossFinanceReportNotesProps) => void;
};

export type StaffTypeSectionDataLookup = Record<number, StaffTypeSectionData>;