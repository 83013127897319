import React from 'react';
import oFetch from 'o-fetch';
import SkippedInvalidPayment from './skipped-invalid-payment';
import PaymentsCollapsibleCard from '../payments-collapsible-card';

export default function SkippedInvalidPaymentsList(props) {
  const skippedInvalidPayments = oFetch(props, 'skippedInvalidPayments');
  const skippedInvalidPaymentsCount = skippedInvalidPayments.length;

  function renderSkippedInvalidPaymentsList() {
    return skippedInvalidPayments.map((skippedInvalidPayment, index) => {
      return <SkippedInvalidPayment key={index} skippedInvalidPayment={skippedInvalidPayment} />;
    });
  }

  return (
    <PaymentsCollapsibleCard
      count={skippedInvalidPaymentsCount}
      title="Skipped because data invalid"
      isInvalid
    >
      <div className="boss-board__group">
        <div className="boss-report">{renderSkippedInvalidPaymentsList()}</div>
      </div>
    </PaymentsCollapsibleCard>
  );
}
