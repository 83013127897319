import { connect } from 'react-redux';
import Page from '../components/page';
import { addVenueRequested, editVenueRequested, disableVenueRequested, enableVenueRequested } from '../redux/actions';

const mapStateToProps = state => {
  return {
    possibleReminderUsersAddVenue: state.get('possibleReminderUsersAddVenue'),
    venues: state.get('venues'),
    fruitOrderFields: state.get('fruitOrderFields'),
  };
};

const mapDispatchToProps = {
  addVenueRequested,
  editVenueRequested,
  disableVenueRequested,
  enableVenueRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
