import { combineReducers } from 'redux';
import filterReducer from './filter-reducer';
import venueAlertsReducer from './venue-alerts-reducer';
import alertLevelTypesReducer from './alert-level-types-reducer';
import currentVenueReducer from './current-venue-reducer';

export default combineReducers({
  filter: filterReducer,
  venueAlerts: venueAlertsReducer,
  alertLevelTypes: alertLevelTypesReducer,
  currentVenueId: currentVenueReducer,
});
