import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const quizCategoryQuestionsTagsSelectors = state => oFetch(state, 'quizCategoryQuestionsTags');
export const quizCategoryQuestionsTagsCountSelectors = state => oFetch(state, 'quizCategoryQuestionsTagsCount');
export const getTags = createSelector(
  [quizCategoryQuestionsTagsSelectors, quizCategoryQuestionsTagsCountSelectors],
  (quizCategoryQuestionsTags, quizCategoryQuestionsTagsCount) => {
    return quizCategoryQuestionsTags.map(quizCategoryQuestionsTag => {
      const questionTagId = oFetch(quizCategoryQuestionsTag, 'id');
      const questionsTagsCount = oFetch(quizCategoryQuestionsTagsCount, questionTagId);

      return {
        ...quizCategoryQuestionsTag,
        count: questionsTagsCount,
      };
    });
  },
);
