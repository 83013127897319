import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { useModulrReports } from './use-modulr-reports';
import { ModulrReportsDashboard, ModulrReportsCard, AccountsContent, VenueWageContent } from './components';

export function ModulrReports(props) {
  const [weekStartDate, weekEndDate] = oFetch(props, 'weekStartDate', 'weekEndDate');
  const venues = oFetch(props, 'venues');
  const payrollWeek = oFetch(props, "payrollWeek")
  const {
    modulrCompanies,
    openNotesModal,
    sortedVenues,
    grandTotalNetWagesCents,
    setupForPayment,
  } = useModulrReports({
    venues: venues,
    weekStartDate,
  });

  return (
    <Fragment>
      <ModulrReportsDashboard
        payrollWeek={payrollWeek}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
        grandTotalNetWagesCents={grandTotalNetWagesCents}
        setupForPayment={setupForPayment}
      />
      <ContentWrapper>
        <ModulrReportsCard title="Accounts">
          <AccountsContent weekStartDate={weekStartDate} modulrCompanies={modulrCompanies} />
        </ModulrReportsCard>
        <ModulrReportsCard title="Venue Wages">
          <VenueWageContent
            weekStartDate={weekStartDate}
            onNotesClick={openNotesModal}
            venues={sortedVenues}
          />
        </ModulrReportsCard>
      </ContentWrapper>
    </Fragment>
  );
}
