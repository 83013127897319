import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function DashboardFilterWrapper(props) {
  const children = oFetch(props, 'children');
  const [isOpened, setIsOpened] = useState(false);
  const filterClassNames = cn(
    'purple-collapsible__label purple-collapsible__label_role_filter js-collapsible-label',
    { 'purple-collapsible__label_state_closed': !isOpened },
  );

  return (
    <div className="purple-page-main__filter">
      <div className="purple-collapsible js-collapsible">
        <div className="purple-collapsible__header">
          <p onClick={() => setIsOpened(!isOpened)} className={filterClassNames}>
            Filter
          </p>
        </div>
        <Collapse
          isOpened={isOpened}
          className="purple-collapsible__content js-collapsible-content"
        >
          <div className="purple-collapsible__inner">{children}</div>
        </Collapse>
      </div>
    </div>
  );
}
