export const MANAGER_ROLE = 'manager';
export const MARKETING_MANAGER_ROLE = 'marketing_manager';
export const BOLTON_OPS_MANGER_ROLE = 'bolton_ops_manager';
export const ANY = 'any';
export const OPEN_STATUS = 'open';
export const REVOKED_STATUS = 'revoked';
export const ACCEPTED_STATUS = 'accepted';
export const STATUSES_FILTER = [ANY, REVOKED_STATUS, ACCEPTED_STATUS, OPEN_STATUS];

export const ROLES_FILTER = [
  ANY,
  'dev',
  'admin',
  'area_manager',
  MANAGER_ROLE,
  'marketing',
  MARKETING_MANAGER_ROLE,
  'maintenance_staff',
  'ops_manager',
  'security_manager',
  'payroll_manager',
  'food_ops_manager',
  BOLTON_OPS_MANGER_ROLE,
];
