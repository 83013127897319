import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

function TextAnswer(props) {
  const [onChange, value, onRemove] = oFetch(props, 'input.onChange', 'input.value', 'onRemove');
  const [description, correct] = oFetch(value, 'description', 'correct');
  const { meta: { error, touched } } = props;
  function handleChangeText(e) {
    const description = oFetch(e, 'target.value');
    onChange({ ...value, description });
  }

  function handleChangeCheckBox(e) {
    const correct = oFetch(e, 'target.checked');
    onChange({ ...value, correct });
  }

  const fieldClassNames = cn('boss-choice-list__field-textarea', {
    'boss-choice-list__field-textarea_state_error': touched && error,
  });

  return (
    <div className="boss-choice-list__control">
      <div className="boss-choice-list__control boss-choice-list__control_type_text-editor">
        <label className="boss-choice-list__option">
          <input
            type="checkbox"
            onChange={handleChangeCheckBox}
            checked={correct}
            className="boss-choice-list__option-input"
          />
          <span className="boss-choice-list__option-control" />
        </label>
        <div className="boss-choice-list__field">
          <textarea
            onChange={handleChangeText}
            value={description === null ? '' : description}
            className={fieldClassNames}
            placeholder="Type answer name here..."
          />
          {touched &&
            error && (
              <div className="boss-choice-list__error boss-choice-list__error_position_below">
                <p className="boss-choice-list__error-text">{error}</p>
              </div>
            )}
        </div>
        <div className="boss-choice-list__actions">
          <button
            onClick={onRemove}
            className="boss-choice-list__action boss-choice-list__action_role_remove"
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export default TextAnswer;
