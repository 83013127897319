import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import checklists from './checklists-reducer';
import checklistsItems from './checklists-items-reducer';
import currentVenue from './current-venue-reducer';
import editChecklistId from './edit-checklist-id-reducer';

export default combineReducers({
  checklists,
  checklistsItems,
  currentVenue,
  editChecklistId,
  form: formReducer,
});
