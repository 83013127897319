import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import VenueSelect from '@/components/security-rota/venue-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

function RequestsVenueFilter(props) {
  const venues = oFetch(useLegacyGlobal(), 'venues');

  const selectedVenues = oFetch(props, 'selectedVenues');
  const onChangeSelectedVenues = oFetch(props, 'onChangeSelectedVenues');

  return (
    <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
      <p className="boss-form__label boss-form__label_type_icon-filter">
        <span className="boss-form__label-text">Venue</span>
      </p>
      <VenueSelect
        selected={selectedVenues}
        options={venues}
        onChange={venueIds => onChangeSelectedVenues(venueIds)}
      />
    </div>
  );
}

export default RequestsVenueFilter;
