import React from 'react';
import oFetch from 'o-fetch';
import { RadioGroup, Radio } from '@/components/radio-group';

const QUARTERS = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};

export function QuarterSelector(props) {
  const [selected, onChange, quarterNumbers] = oFetch(props, 'selected', 'onChange', 'quarterNumbers');
  const { name } = props;
  return (
    <RadioGroup
      name={name || 'quarter'}
      selectedValue={selected}
      onChange={onChange}
      className="boss-form__switcher boss-form__switcher_role_quarter"
    >
      {quarterNumbers.map((number, index) => {
        return (
          <label key={index} className="boss-form__switcher-label">
            <Radio value={number} className="boss-form__switcher-radio" />
            <span className="boss-form__switcher-label-text">{QUARTERS[number]}</span>
          </label>
        );
      })}
    </RadioGroup>
  );
}
