export const LOAD_INITIAL_STATE = 'LOAD_INITIAL_STATE';
export const CLOCK_OUT = 'CLOCK_OUT';
export const SET_HOURS_ACCEPTANCE_PERIOD_STATUS = 'SET_HOURS_ACCEPTANCE_PERIOD_STATUS';
export const ACCEPT_HOURS_ACCEPTANCE_PERIOD = 'ACCEPT_HOURS_ACCEPTANCE_PERIOD';
export const UPDATE_PERIOD_DATA = 'UPDATE_PERIOD_DATA';
export const ADD_NEW_ACCEPTANCE_PERIOD = 'ADD_NEW_ACCEPTANCE_PERIOD';
export const REMOVE_HOURS_ACCEPTANCE_PERIOD = 'REMOVE_HOURS_ACCEPTANCE_PERIOD';
export const RE_ENABLE_HOURS_ACCEPTANCE_PERIOD = 'RE_ENABLE_HOURS_ACCEPTANCE_PERIOD';
export const FILTER_EFFECTIVE_STAFF_TYPE = 'FILTER_EFFECTIVE_STAFF_TYPE';
export const FILTER_NAME = 'FILTER_NAME';
