import React from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { BossFormInput } from '@/components/boss-form';

export function DifferentAmountForm(props) {
  const [initialValues, onSubmit] = oFetch(props, 'initialValues', 'onSubmit');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <div className="boss-modal-window__form">
            <div className="boss-form">
              <Field
                label="Amount"
                name="amountCents"
                parse={value => value}
                unit="£"
                isCurrency
                component={BossFormInput}
              />
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                  type="submit"
                >
                  {!submitting ? 'Confirm' : 'Processing ...'}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
