import React from 'react';
import oFetch from 'o-fetch';
import { PaymentForm } from './payment-form';

export function AddPayment(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const initialValues = {
    staffMemberId: null,
    year: null,
    quarter: null,
    amountCents: null,
  };
  return (
    <PaymentForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      buttonText="Create Bonus Payment"
      edit={false}
    />
  );
}
