import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import useOnClickOutside from 'use-onclickoutside';

const $OptionValue = z.number().int().nonnegative();

const $Option = z.object({
  id: $OptionValue,
  name: z.string()
});

type Option = z.infer<typeof $Option>;

const $InternalOnSubmitParams = z.array($OptionValue);

const $BossFormTagInputModalProps = z.object({
  selectedOptionIds: z.array($OptionValue),
  options: z.array($Option),
  onSubmit: z.function().args($InternalOnSubmitParams).returns(z.void()),
  onClose: z.function().args().returns(z.void())
});

type BossFormTagInputModalProps = z.infer<typeof $BossFormTagInputModalProps>;

export const $OnSubmitParams = z.tuple([
  z.function(z.tuple([]), z.void()), // handleSubmit
  z.array($OptionValue), // values
]);

export type OnSubmitParams = z.infer<typeof $OnSubmitParams>;

export function BossFormTagInputModalContent(props: BossFormTagInputModalProps): React.ReactElement {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(props.selectedOptionIds);
  const normalizedSearchQuery = useMemo(() => searchQuery.trim().toLowerCase(), [searchQuery]);
  const [typingInFilter, setTypingInFilter] = useState<boolean>(false);
  const mainDivRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  useOnClickOutside(mainDivRef, props.onClose);

  //Setup focus for keyboard shortcuts
  useEffect(() => {
    if (mainDivRef.current) {
      mainDivRef.current.focus();
    }
  }, [mainDivRef.current]);

  //Focus for keyboard shortcuts when not typing in filter
  useEffect(() => {
    if (!typingInFilter && mainDivRef.current) {
      mainDivRef.current.focus();
    }
  }, [typingInFilter]);

  const { selectedTags, nonSelectedTags } = useMemo(() => {
    const selectedTags = [] as Option[];
    const nonSelectedTags = [] as Option[];
    props.options.forEach((option) => {
      if (selectedTagIds.includes(option.id)) {
        selectedTags.push(option);
      } else {
        nonSelectedTags.push(option);
      }
    });

    if (selectedTags.length !== selectedTagIds.length) {
      throw new Error('Could not find all selected tags');
    }

    return { selectedTags, nonSelectedTags };
  }, [selectedTagIds]);

  function addTagSelection(tagId: number) {
    const newSelectedTagIds = [...selectedTagIds, tagId];
    setSelectedTagIds(newSelectedTagIds);
  }

  function removeTagSelection(tagId: number) {
    const newSelectedTagIds = selectedTagIds.filter((selectedTagId) => selectedTagId !== tagId);
    setSelectedTagIds(newSelectedTagIds);
  }

  const filteredNonSelectedTags = nonSelectedTags.reduce(
    (acc, nonSelectedTag) => {
      const normalizedTagName = nonSelectedTag.name.trim().toLowerCase();
      if (searchQuery === "") {
        acc.push(nonSelectedTag);
      } else if (normalizedTagName.includes(normalizedSearchQuery)) {
        acc.push(nonSelectedTag);
      }
      return acc;
    },
    [] as Option[],
  );

  const tagsExist = props.options.length > 0;
  const tagsFound = tagsExist && filteredNonSelectedTags.length > 0;
  const filteredTagCount = filteredNonSelectedTags.length;
  const totalTagCount = nonSelectedTags.length;

  function isTextKey(keyCode: number) {
    return (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (typingInFilter) {
      return;
    }

    if (event.key === 'Enter') {
      if (selectedTagIds !== props.selectedOptionIds) {
        props.onSubmit(selectedTagIds);
      } else {
        props.onClose();
      }
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      if (selectedTagIds.length > 0) {
        const newSelectedTagIds = selectedTagIds.slice(0, selectedTagIds.length - 1);
        setSelectedTagIds(newSelectedTagIds);
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();

      if (!inputRef.current) {
        throw new Error("inputRef.current is null");
      }
      inputRef.current.focus();
    } else if (event.key === 'Escape') {
      props.onClose();
    } else if (isTextKey(event.keyCode)) {
      if (inputRef.current === null || inputRef.current === undefined) {
        throw new Error("inputRef.current is null");
      }
      inputRef.current.focus();
      inputRef.current.dispatchEvent(new KeyboardEvent('keydown', { key: event.key }));
    }
  }

  function handleFilterKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (!typingInFilter) {
      return;
    }

    if (event.key === 'Enter') {
      event.stopPropagation();
      if (filteredNonSelectedTags.length === 1) {
        const filteredTag = filteredNonSelectedTags[0];
        if (!filteredTag) {
          throw new Error("filteredTag is not defined");
        }
        addTagSelection(filteredTag.id);
        setSearchQuery("");
      }

      if (!mainDivRef.current) {
        throw new Error("mainDivRef.current is null");
      }
      mainDivRef.current.focus();
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchQuery.trim().length <= 1) {
        setTypingInFilter(false);
        if (mainDivRef.current === null || mainDivRef.current === undefined) {
          throw new Error("mainDivRef.current is null");
        }
        // Loose focus and pass on to main form
        mainDivRef.current.focus();
        mainDivRef.current.dispatchEvent(
          new KeyboardEvent('keydown', { key: event.key })
        );
        setSearchQuery("");
      }
    } else if (event.key === 'Escape') {
      props.onClose();
    } else if (event.key === 'Tab') {
      event.preventDefault();
      if (!mainDivRef.current) {
        throw new Error("mainDivRef.current is null");
      }
      mainDivRef.current.focus();
    }
  }

  return (
    <div
      ref={mainDivRef}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className="boss-modal-window__header">
        <span>Manage Hours Tags</span>
        <button
          onClick={() => props.onSubmit(selectedTagIds)}
          className="boss-button boss-button_type_small boss-modal-window__action-inner"
        >Done</button>
      </div>
      <div
        className="boss-modal-window__content"
      >
        <div
          className="boss-modal-window__tags"
        >
          <div
            className="boss-modal-window__tags-group"
          >
            {selectedTags.map((selectedTag) => {
              return (
                <div
                  key={`selected-tag:${selectedTag.id}`}
                  className="boss-tag boss-tag_size_m boss-tag_role_hours-tag boss-tag_state_selected"
                >
                  <p
                    className="boss-tag__text"
                  >{selectedTag.name}</p>
                  <div
                    className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
                    onClick={() => removeTagSelection(selectedTag.id)}
                  />
                </div>
              );
            })}
          </div>
          <div className="boss-modal-window__tags-scroll">
            <div>
              {!tagsExist && <p>No Active Tags Exist</p>}
              {tagsExist && !tagsFound && <p>No Tags Found</p>}
              {tagsFound && (filteredTagCount !== totalTagCount) && (
                <p>
                  Showing {filteredTagCount} of {totalTagCount} Tags
                </p>
              )}
              {tagsFound && (filteredTagCount === totalTagCount) && (
                <p>
                  Showing All {totalTagCount} Tags
                </p>
              )}
            </div>
            <div className="boss-modal-window__tags-group">
              {filteredNonSelectedTags.map((nonSelectedTag) => {
                return (
                  <div
                    key={`non-selected-tag:${nonSelectedTag.id}`}
                    className="boss-tag boss-tag_size_m boss-tag_role_hours-tag boss-tag_role_action"
                    onClick={() => addTagSelection(nonSelectedTag.id)}
                  >
                    <p
                      className="boss-tag__text"
                    >{nonSelectedTag.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="boss-modal-window__tags-filter">
            <form className="boss-form">
              <div className="boss-form__field boss-form__field_layout_max">
                <div className="boss-form__search boss-form__search_type_light">
                  <label className="boss-form__label">
                    <input
                      ref={inputRef}
                      name="search"
                      type="text"
                      className="boss-form__input"
                      placeholder="Filter tags by name..."
                      onFocus={() => {
                        setTypingInFilter(true);
                      }}
                      onBlur={() => {
                        setTypingInFilter(false);
                      }}
                      value={searchQuery}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleFilterKeyDown(event)}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setSearchQuery(event.target.value);
                      }}
                    />
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
