import oFetch from 'o-fetch';
import { http } from '@/lib/request-api';

export const acceptHolidayRequestRequest = (params) => {
  const holidayRequestId = oFetch(params, 'holidayRequestId');
  const forward = oFetch(params, 'forward');

  return http().post(
    `/api/v1/holiday-requests/${holidayRequestId}/accept`,
    {
      forward,
    },
  );
};

export const rejectHolidayRequestRequest = (params) => {
  const holidayRequestId = oFetch(params, 'holidayRequestId');

  return http().post(`/api/v1/holiday-requests/${holidayRequestId}/reject`);
};
