import React from 'react';
import AddHolidayContent from './add-holiday-content';
import oFetch from 'o-fetch';

const AddNewHoliday = (props) => {
  const canForwardHolidays = oFetch(props, 'canForwardHolidays');

  return (
    <AddHolidayContent
      buttonTitle={props.buttonTitle}
      startDate={props.startDate}
      endDate={props.endDate}
      onSubmit={props.onSubmit}
      canForwardHolidays={canForwardHolidays}
    />
  );
};

export default AddNewHoliday;
