import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export default function NativeAppLinkList(props) {
  const [items, itemRenderer] = oFetch(props, 'items', 'itemRenderer');

  function renderList() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(itemRenderer(item), {
        key: itemId.toString(),
      });
    });
  }
  return <Fragment>{renderList()}</Fragment>;
}
