import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { mapArrayKeys } from '@/lib/utils';
import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import * as actions from './redux/actions';
import { MarketingTasks } from './marketing-tasks';

export function MarketingTasksApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  const [accessibleVenues, users, currentUserId, permissions] = oFetch(
    props,
    'accessibleVenues',
    'users',
    'currentUserId',
    'permissions'
  );
  const pageOptions = oFetch(props, 'pageOptions');
  const venueOptions = mapArrayKeys(accessibleVenues, ['id:value', 'name:label']);
  const userOptions = mapArrayKeys(users, ['id', 'fullName', 'role']).map(uo => {
    if (oFetch(uo, 'id') === currentUserId) {
      return {
        ...uo,
        you: true,
      };
    }
    return {
      ...uo,
      you: false,
    };
  });
  const [store, setStore] = useState(null);

  useEffect(() => {
    require('./styles.css');
    $(() => {
      $('.boss-tasks__cards').hide().show();
    });
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <LegacyGlobalProvider global={{ venueOptions, userOptions, currentUserId, pageOptions, permissions }}>
      <Provider store={store}>
        <ModalsProvider>
          <MarketingTasks />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
