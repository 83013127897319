import React from 'react';
import oFetch from 'o-fetch';

export default function StaffTypes(props) {
  const staffTypes = oFetch(props, 'staffTypes');
  if (staffTypes.length === 0) {
    return (
      <div className="boss-board__group">
        <h3>No Staff Types assigned</h3>
      </div>
    );
  }
  return (
    <div className="boss-board__group">
      {staffTypes.map(staffType => {
        const [staffTypeId, name, color] = oFetch(staffType, 'id', 'name', 'color');

        return (
          <div key={staffTypeId.toString()} className="boss-tag boss-tag_size_l" style={{ backgroundColor: color }}>
            <p className="boss-tag__text">{name}</p>
          </div>
        );
      })}
    </div>
  );
}
