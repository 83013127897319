import React, { useState, useRef, useEffect } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export default function ChecklistEditItemField(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [errors, setErrors] = useState(null);
  const inputRef = useRef(null);

  const [item, onDelete, nameChanged] = oFetch(props, 'item', 'onDelete', 'nameChanged');
  const [itemId, checklistId] = oFetch(item, 'id', 'checklistId');
  const value = oFetch(item, 'description');

  useEffect(() => {
    if (nameChanged === true) {
      if (isEdit) {
        onCancelEdit();
      }
    }
  }, [nameChanged]);

  function enableEditMode() {
    setIsEdit(true);
  }

  function onDescriptionChange(e) {
    const item = oFetch(props, 'item');
    const description = oFetch(item, 'description');
    const value = inputRef.current.value;
    setDescriptionChanged(description !== value);
    setCurrentDescription(value);
  }

  function onCancelEdit() {
    const item = oFetch(props, 'item');
    const value = oFetch(item, 'description');
    inputRef.current.value = value;
    setIsEdit(false);
    setDescriptionChanged(false);
    setCurrentDescription(value);
  }

  function handleItemUpdate(params) {
    return props.onUpdate(params).catch(errors => {
      if (errors && errors.description) {
        setErrors(errors.description);
      }
    });
  }

  return (
    <div className="boss-checklist__item">
      {isEdit && (
        <div className="boss-checklist__control">
          <div className="boss-checklist__field">
            <input
              type="text"
              onChange={onDescriptionChange}
              defaultValue={value}
              ref={inputRef}
              className="boss-checklist__text-input"
            />
          </div>
          {descriptionChanged && (
            <AsyncButton
              className="boss-button boss-button_type_icon boss-button_role_confirm boss-checklist__btn"
              text="Update"
              pendingText="Updating ..."
              onClick={() =>
                handleItemUpdate({ checklistId, item: { id: itemId, description: currentDescription } })
              }
            />
          )}
          <button
            onClick={onCancelEdit}
            type="button"
            style={{ marginLeft: '10px' }}
            className="boss-button boss-button_role_cancel boss-page-dashboard__button"
          >
            Cancel
          </button>
        </div>
      )}
      {!isEdit && nameChanged && (
        <div className="boss-checklist__control">
          <p className="boss-checklist__label">
            <span className="boss-checklist__label-text">{value}</span>
          </p>
        </div>
      )}
      {!isEdit && !nameChanged && (
        <div className="boss-checklist__control">
          <p className="boss-checklist__label">
            <span className="boss-checklist__label-text">{value}</span>
          </p>
          <button
            onClick={enableEditMode}
            type="button"
            className="boss-checklist__icon boss-checklist__icon_role_edit"
          >
            Edit
          </button>
          <AsyncButton
            className="boss-checklist__icon boss-checklist__icon_role_delete"
            text="Delete"
            pendingText="Deleting ..."
            onClick={() => onDelete({ itemId, checklistId })}
          />
        </div>
      )}
      {errors && (
        <div className="boss-checklist__alert boss-checklist__alert_size_small">
          <div className="boss-alert">
            <p className="boss-alert__text">{errors}</p>
          </div>
        </div>
      )}
    </div>
  );
}
