import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ExceptionForm } from './exception-form';

export function AddExceptionModal(props) {
  const [onSubmit, securityPayRate] = oFetch(props, 'onSubmit', 'securityPayRate');

  const initialValues = {
    securityPayRateId: oFetch(securityPayRate, 'id'),
    hourlyRateCents: null,
    startsAt: null,
    endsAt: null,
  };

  return <ExceptionForm onSubmit={onSubmit} initialValues={initialValues} />;
}
