import { z } from "zod";

const $MatchesJsmEmailArgs = z.object({
  email: z.string(),
});

type MatchesJsmEmailArgs = z.infer<typeof $MatchesJsmEmailArgs>;

export function matchesJsmEmail(args: MatchesJsmEmailArgs): boolean {
  if (args.email.length === 0) {
    return false;
  }

  return /^[a-zA-Z0-9.!\#$%&'*+/=?^_`{|}~-]+@jsmbars.co.uk$/ig.test(args.email);
}

export function legacyMatchesJsmEmail(args: MatchesJsmEmailArgs): boolean {
  const parsedArgs = $MatchesJsmEmailArgs.parse(args);
  return matchesJsmEmail(parsedArgs);
}