import React from 'react';
import oFetch from 'o-fetch';

export function TemporaryList(props) {
  const [items, renderItem] = oFetch(props, 'items', 'renderItem');

  function renderItems() {
    return items.map(item => {
      const venueId = oFetch(item, 'venueId');
      return React.cloneElement(renderItem(item), { key: venueId });
    });
  }

  return (
    <div className="boss-overview__group boss-overview__group_adjust_level boss-overview__group_position_last">
      <h4 className="boss-overview__label">
        <span className="boss-overview__label-text">Temporary Access</span>
      </h4>
      <div className="boss-overview__table">
        <div className="boss-table boss-table_page_app-access-venue-details">
          <div className="boss-table__row boss-table__row_role_header">
            <div className="boss-table__column">
              <div className="boss-table__cell" />
            </div>
            <div className="boss-table__column">
              <div className="boss-table__row">
                <div className="boss-table__cell">Start</div>
                <div className="boss-table__cell">End</div>
              </div>
            </div>
          </div>
          {renderItems()}
        </div>
      </div>
    </div>
  );
}
