import React from 'react';
import oFetch from 'o-fetch';
import { UIInputField } from '../ui-fields';

export default function InputField(props) {
  const [input, meta] = oFetch(props, 'input', 'meta');
  const [label, placeholder] = oFetch(props, 'label', 'placeholder');
  const [onChange, value, name] = oFetch(input, 'onChange', 'value', 'name');
  const { submitError } = meta;
  const errors = submitError || [];

  return (
    <UIInputField
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      errors={errors}
    />
  );
}

InputField.defaultProps = {
  label: null,
  placeholder: null,
};
