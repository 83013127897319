import { useState } from 'react';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import * as constants from './constants';

export function usePaymentUploadStore() {
  const [paymentsData, setPaymentsData] = useState(null);

  function uploadCSV(params) {
    const csvFileBinary = oFetch(params, 'csvFileBinary');

    return bossRequestHttp({
      errorHandler(params) { },
      successHandler(params) {
        const data = oFetch(params, 'data');
        setPaymentsData(data);
        const resultType = oFetch(data, 'resultType');

        const globalNotifications = oFetch(params, 'globalNotifications');
        if (resultType === constants.PROCESSED_RESULT_TYPE) {
          globalNotifications.showDefaultSuccessMessage({ message: 'CSV Uploaded Successfully' });
        }
        if (resultType === constants.PARSE_ERROR_RESULT_TYPE) {
          globalNotifications.showDefaultFailureMessage({
            message: 'Upload Failed: CSV Format Invalid',
          });
        }
      },
    }).post(`/api/v1/payments/upload_csv`, {
      stringData: csvFileBinary,
    });
  }

  function onTryAgain() {
    setPaymentsData(null);
  }

  return {
    uploadCSV,
    dataLoaded: !!paymentsData,
    paymentsData,
    onTryAgain,
  };
}
