import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  venueId: null,
  newResponsePath: null,
  manageQuestionnairePath: null,
  canManageQuestionnaire: null,
};

export default handleActions({}, initialState);
