import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import configureStore from '../store';

import StaffMemberPasswordReset from './staff-member-password-reset';

export function StaffMemberPasswordResetApp(props) {
  const [store, setStore] = useState(null);

  const verificationToken = oFetch(props, 'verificationToken');
  const actionDescription = oFetch(props, 'actionDescription');
  const successPath = oFetch(props, 'successPath');
  const requestPath = oFetch(props, 'requestPath');

  useEffect(() => {
    const store = configureStore(
      combineReducers({
        form: formReducer,
      }),
    );
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <StaffMemberPasswordReset
        verificationToken={verificationToken}
        actionDescription={actionDescription}
        successPath={successPath}
        requestPath={requestPath}
      />
    </Provider>
  );
}
