import oFetch from 'o-fetch';
import { useState } from 'react';
import debounce from 'lodash/debounce';
import { useModal } from '@/components/hooks-components/modals';
import { bossRequestHttp } from '@/lib/request-api';
import { WithoutCloseModalWrapper } from '@/components/modal-wrappers';

import { NotFoundModal } from './not-found-modal';
import { InvalidValuesModal } from './invalid-values-modal';
import { RequestFailedModal } from './request-failed-modal';
import { DuplicatesModal } from './duplicates-modal';
import { NonFronLineModal } from './non-front-line-modal';

export function useSiaBadgeValidation(params) {
  const { openModal } = useModal();
  const [siaBadgeValidating, setSiaBadgeValidating] = useState(false);
  const [validationLookupStatus, setValidationLookupStatus] = useState(null);

  const [
    staffMemberId,
    onDowngradeClick,
    onNotFoundSubmit,
    onInvalidTryAgainClick,
    onRequestFailed,
    onDuplicatesModalSubmit,
    onNonFrontLineModalSubmit,
    onSiaCheckSuccess,
    onFormatInvalid,
  ] = oFetch(
    params,
    'staffMemberId',
    'onDowngradeClick',
    'onNotFoundSubmit',
    'onInvalidTryAgainClick',
    'onRequestFailed',
    'onDuplicatesModalSubmit',
    'onNonFrontLineModalSubmit',
    'onSiaCheckSuccess',
    'onFormatInvalid',
  );

  const { onValidateStart = () => {}, onValidateEnd = () => {} } = params;

  function brCheckSIANumber(params) {
    const siaNumber = oFetch(params, 'siaNumber');
    const staffMemberId = oFetch(params, 'staffMemberId');
    const errorHandler = oFetch(params, 'errorHandler');
    const successHandler = oFetch(params, 'successHandler');

    return bossRequestHttp({
      errorHandler,
      successHandler,
    }).post('/api/v1/staff_members/check_sia_number', {
      siaNumber: siaNumber,
      staffMemberId: staffMemberId,
    });
  }

  function checkSIANumberRequest(params) {
    const [siaNumber, staffMemberId, onStart, onEnd, onSuccess] = oFetch(
      params,
      'siaNumber',
      'staffMemberId',
      'onStart',
      'onEnd',
      'onSuccess',
    );
    onStart();
    return brCheckSIANumber({
      siaNumber: siaNumber,
      staffMemberId: staffMemberId,
      successHandler(result) {
        const siaBadgeNumberLookup = oFetch(result, 'data.siaBadgeNumberLookup');
        onSiaCheckSuccess(siaBadgeNumberLookup);
        onEnd();
        return onSuccess();
      },
      errorHandler(data) {
        const errors = oFetch(data, 'errors');
        handleErrors(errors);
        onEnd();
        return true;
      },
    });
  }
  function openNotFoundModal() {
    openModal({
      ModalComponent: WithoutCloseModalWrapper,
      ModalContent: NotFoundModal,
      onSubmit(closeModal) {
        onNotFoundSubmit(closeModal);
      },
      props: {},
      config: {
        title: 'SIA BADGE NOT FOUND / VALID',
        baseClassName: 'boss-modal-window boss-modal-window_role_error',
      },
    });
  }

  function openInvalidValuesModal(params) {
    const [badgeStatus, activationDate] = oFetch(params, 'badgeStatus', 'activationDate');

    function getTitle() {
      if (badgeStatus === 'suspended') {
        return 'SIA BADGE SUSPENDED';
      }
      if (badgeStatus === 'revoked') {
        return 'SIA BADGE REVOKED';
      }
      throw new Error(`Wrong badgeStatus, expected: [suspended, revoked], got: ${badgeStatus}`);
    }

    openModal({
      ModalComponent: WithoutCloseModalWrapper,
      ModalContent: InvalidValuesModal,
      onSubmit: onInvalidTryAgainClick,
      props: {
        badgeStatus,
        activationDate,
        onDowngradeClick,
      },
      config: {
        title: getTitle(),
        baseClassName: 'boss-modal-window boss-modal-window_role_error',
      },
    });
  }

  function openRequestFailedModal(params) {
    const [status, guid] = oFetch(params, 'status', 'guid');

    openModal({
      ModalComponent: WithoutCloseModalWrapper,
      ModalContent: RequestFailedModal,
      onSubmit(closeModal) {
        return onRequestFailed({ closeModal, status, guid });
      },
      props: {},
      config: {
        title: 'SIA BADGE NUMBER COULD NOT BE CHECKED',
        baseClassName: 'boss-modal-window boss-modal-window_role_error',
      },
    });
  }

  function openDuplicatesModal(additionalData) {
    openModal({
      ModalComponent: WithoutCloseModalWrapper,
      ModalContent: DuplicatesModal,
      onSubmit: onDuplicatesModalSubmit,
      props: {
        staffMembers: additionalData,
      },
      config: {
        title: 'DUPLICATE SIA BADGE NUMBER SUPPLIED',
        baseClassName: 'boss-modal-window boss-modal-window_role_error',
      },
    });
  }

  function openNonFronLineModal() {
    openModal({
      ModalComponent: WithoutCloseModalWrapper,
      ModalContent: NonFronLineModal,
      onSubmit: onNonFrontLineModalSubmit,
      props: {},
      config: {
        title: 'NON FRONTLINE ROLE',
        baseClassName: 'boss-modal-window boss-modal-window_role_error',
      },
    });
  }

  function handleErrors(data) {
    const [status, additionalData] = oFetch(data, 'status', 'additionalData');

    setValidationLookupStatus(status);

    if (status === 'fail_not_found') {
      return openNotFoundModal();
    }
    if (status === 'fail_invalid_values') {
      return openInvalidValuesModal(additionalData);
    }
    if (status === 'fail_non_front_line_role') {
      return openNonFronLineModal();
    }
    if (status === 'fail_request_failed') {
      return openRequestFailedModal(data);
    }
    if (status === 'duplicated') {
      return openDuplicatesModal(additionalData);
    }
    if (status === 'invalid_format') {
      return onFormatInvalid(additionalData);
    }
    throw new Error(`Unknown sia check error status: ${status}`);
  }

  return {
    siaBadgeValidating,
    validationLookupStatus,
    checkSIANumber(params) {
      const [siaNumber, debounceTimeout] = oFetch(params, 'siaNumber', 'debounceTimeout');
      const { onSuccess = () => {} } = params;
      return debounce(
        checkSIANumberRequest,
        debounceTimeout,
      )({
        siaNumber: siaNumber,
        staffMemberId: staffMemberId,
        onSuccess,
        onStart() {
          onValidateStart();
          setSiaBadgeValidating(true);
        },
        onEnd() {
          onValidateEnd();
          setSiaBadgeValidating(false);
        },
      });
    },
  };
}
