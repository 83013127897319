import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export const UPLOADING_STATE = 'uploading';
export const UPLOADED_STATE = 'uploaded';
export const BROKEN_STATE = 'broken';

export function FileList(props) {
  const files = oFetch(props, 'files');
  const onFileClick = oFetch(props, 'onFileClick');

  function renderFileList() {
    return Object.values(files).map((file, index) => {
      if (file.state === UPLOADING_STATE) {
        return (
          <div
            key={index}
            className="boss-upload__preview boss-upload__preview_adjust_flow boss-upload__preview_state_loading"
          />
        );
      }
      if (file.state === BROKEN_STATE) {
        return (
          <div
            key={index}
            className="boss-upload__preview boss-upload__preview_adjust_flow boss-upload__preview_state_broken"
          >
            <div className="boss-upload__preview-link" />
          </div>
        );
      }
      if (file.state === UPLOADED_STATE) {
        const [isImage, url, name] = oFetch(file, 'isImage', 'url', 'name');
        const containerClassNames = cn('boss-upload__preview boss-upload__preview_adjust_flow', {
          'boss-upload__preview_role_file': !isImage,
          'boss-upload__preview_role_image': isImage,
        });
        return (
          <button key={index} onClick={() => onFileClick(file)} type="button" className={containerClassNames}>
            <div className="boss-upload__preview-link">
              {isImage && <img alt={name} className="boss-upload__preview-image" src={url} />}
              <div className="boss-upload__preview-caption">
                <p className="boss-upload__preview-caption-text">{name}</p>
              </div>
            </div>
          </button>
        );
      }
      throw new Error('Wrong file state');
    });
  }

  return <div className="boss-upload__flow boss-upload__flow_adjust_form">{renderFileList()}</div>;
}
