import { combineReducers } from 'redux';
import securityShiftRequests from './security-shift-requests-reducer';
import pageOptions from './page-options-reducer';
import counts from './counts-reducer';

export default combineReducers({
  securityShiftRequests,
  counts,
  pageOptions,
});
