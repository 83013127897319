import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { CollapsibleFilter } from '@/components/hooks-components';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import { ColoredSingleOption, ColoredMultipleValue } from '@/components/boss-form/colored-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

const options = [
  {
    label: 'Active',
    value: 'active',
    color: '#78d965',
  },
  {
    label: 'Pending',
    value: 'pending',
    color: '#8e8e8e',
  },
  {
    label: 'Disabled',
    value: 'disabled',
    color: '#ed7f7e',
  },
];

export function DashboardFilter(props) {
  const initialFilterStates = oFetch(useLegacyGlobal(), 'filter.states');
  const [filter, setFilter] = useState(initialFilterStates);
  function handleChange(selected) {
    setFilter(selected);
  }

  function handleUpdateFilter() {
    if (filter === '') {
      utils.applyNewPageQueryParams({ state: undefined });
    } else {
      utils.applyNewPageQueryParams({ state: filter.split(',') });
    }
  }

  return (
    <CollapsibleFilter>
      <div className="boss-form">
        <div className="boss-form__row">
          <div className="boss-form__field boss-form__field_layout_full">
            <label className="boss-form__label">
              <span className="boss-form__label-text">Priority</span>
            </label>
            <div className="boss-form__select">
              <Select
                value={filter}
                onChange={handleChange}
                clearable
                valueComponent={ColoredMultipleValue}
                optionComponent={ColoredSingleOption}
                simpleValue
                multi
                placeholder="Select priority"
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="boss-form__field boss-form__field_justify_end boss-form__field_position_last">
          <button
            onClick={handleUpdateFilter}
            className="boss-button boss-form__submit boss-form__submit_adjust_single"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </CollapsibleFilter>
  );
}
