import sortBy from 'lodash/sortBy';
import oFetch from 'o-fetch';
import uuid from 'uuid/v1';

function reduceHourTypes(hourTypes) {
  const SCORES = {
    standard: 1,
    special: 10,
    break: 20,
  };
  const scoredHourTypes = hourTypes.reduce((acc, hourType) => {
    const score = SCORES[hourType.type];
    acc[score] = hourType.type;
    return acc;
  }, {});
  const topScore = Object.keys(scoredHourTypes).sort()[Object.keys(scoredHourTypes).length - 1];
  return scoredHourTypes[topScore];
}

function getSortedBoundaries(hoursAcceptancePeriods) {
  const uniqueTimes = new Set();
  return hoursAcceptancePeriods.reduce(
    (acc, hoursAcceptancePeriod) => {
      const [breaks, specialPayRateSecurityHours, startsAt, endsAt] = oFetch(
        hoursAcceptancePeriod,
        'breaks',
        'specialPayRateSecurityHours',
        'startsAt',
        'endsAt',
      );
      const hapGuid = uuid();
      acc.uniqueTimes.add(startsAt);
      acc.uniqueTimes.add(endsAt);
      acc.data[startsAt] = acc.data[startsAt] || [];
      acc.data[startsAt] = [
        ...acc.data[startsAt],
        {
          guid: hapGuid,
          boundary: 'start',
          type: 'standard',
        },
      ];
      acc.data[endsAt] = acc.data[endsAt] || [];
      acc.data[endsAt] = [
        ...acc.data[endsAt],
        {
          guid: hapGuid,
          boundary: 'end',
          type: 'standard',
        },
      ];

      breaks.forEach(_break => {
        const [breakStartsAt, breakEndsAt] = oFetch(_break, 'startsAt', 'endsAt');
        const guid = uuid();

        acc.uniqueTimes.add(breakStartsAt);
        acc.uniqueTimes.add(breakEndsAt);

        acc.data[breakStartsAt] = acc.data[breakStartsAt] || [];
        acc.data[breakStartsAt] = [
          ...acc.data[breakStartsAt],
          {
            guid: guid,
            boundary: 'start',
            type: 'break',
          },
        ];

        acc.data[breakEndsAt] = acc.data[breakEndsAt] || [];
        acc.data[breakEndsAt] = [
          ...acc.data[breakEndsAt],
          {
            guid: guid,
            boundary: 'end',
            type: 'break',
          },
        ];
      });
      specialPayRateSecurityHours.forEach(specialPayRateSecurityHoursItem => {
        const [specialPayRateSecurityHoursItemStartsAt, specialPayRateSecurityHoursItemEndsAt] = oFetch(
          specialPayRateSecurityHoursItem,
          'startsAt',
          'endsAt',
        );
        const guid = uuid();

        acc.data[specialPayRateSecurityHoursItemStartsAt] =
          acc.data[specialPayRateSecurityHoursItemStartsAt] || [];
        acc.data[specialPayRateSecurityHoursItemStartsAt] = [
          ...acc.data[specialPayRateSecurityHoursItemStartsAt],
          {
            guid: guid,
            boundary: 'start',
            type: 'special',
          },
        ];
        acc.data[specialPayRateSecurityHoursItemEndsAt] =
          acc.data[specialPayRateSecurityHoursItemEndsAt] || [];
        acc.data[specialPayRateSecurityHoursItemEndsAt] = [
          ...acc.data[specialPayRateSecurityHoursItemEndsAt],
          {
            guid: guid,
            boundary: 'end',
            type: 'special',
          },
        ];

        acc.uniqueTimes.add(specialPayRateSecurityHoursItemStartsAt);
        acc.uniqueTimes.add(specialPayRateSecurityHoursItemEndsAt);
      });

      return acc;
    },
    { uniqueTimes: uniqueTimes, data: {} },
  );
}

export function convertHoursAcceptancePeriodsToInterval(hoursAcceptancePeriods) {
  const boundaries = getSortedBoundaries(hoursAcceptancePeriods);
  const boundaryData = oFetch(boundaries, 'data');
  const uniqueTimes = oFetch(boundaries, 'uniqueTimes');

  let result = [];
  const activeStartBoundaries = {};
  let previousTime;
  Array.from(uniqueTimes)
    .sort((a, b) => new Date(a) - new Date(b))
    .forEach((time, index) => {
      const currentBoundaries = boundaryData[time];
      let addActiveBoundaries = [];
      let removeActiveBoundaries = [];

      currentBoundaries.forEach((boundary, index) => {
        const boundaryType = oFetch(boundary, 'boundary');
        if (boundaryType === 'start') {
          addActiveBoundaries = [...addActiveBoundaries, boundary];
        } else if (boundaryType === 'end') {
          removeActiveBoundaries = [...removeActiveBoundaries, boundary];
        } else {
          throw new Error('Unsupported boundary type encountered');
        }
      });

      if (index > 0) {
        const hourTypeItems = Object.entries(activeStartBoundaries).map(entry => {
          const [guid, activeBoundary] = entry;
          return { type: activeBoundary.type };
        });
        result = [
          ...result,
          {
            startsAt: previousTime,
            endsAt: time,
            hourTypes: hourTypeItems,
          },
        ];
      }
      previousTime = time;

      addActiveBoundaries.forEach(addBoundary => {
        activeStartBoundaries[oFetch(addBoundary, 'guid')] = addBoundary;
      });

      removeActiveBoundaries.forEach(removeBoundary => {
        delete activeStartBoundaries[oFetch(removeBoundary, 'guid')];
      });
    });
  return result.reduce((acc, item) => {
    if (item.hourTypes.length === 0) {
      return acc;
    }
    return [
      ...acc,
      {
        startsAt: item.startsAt,
        endsAt: item.endsAt,
        type: reduceHourTypes(item.hourTypes),
      },
    ];
  }, []);
}

export function convertClockInPeriodToIntervals(denormalizedHoursPeriod) {
  var breaksOrderedByStartTime = sortBy(denormalizedHoursPeriod.breaks, p => oFetch(p, 'startsAt'));

  var lastTime = denormalizedHoursPeriod.startsAt;
  var intervals = [];

  breaksOrderedByStartTime.forEach(function (breakItem) {
    intervals.push({
      startsAt: lastTime,
      endsAt: breakItem.startsAt,
      type: 'standard',
    });
    if (breakItem.endsAt) {
      intervals.push({
        startsAt: breakItem.startsAt,
        endsAt: breakItem.endsAt,
        type: 'break',
      });
      lastTime = breakItem.endsAt;
    }
  });

  if (denormalizedHoursPeriod.endsAt) {
    intervals.push({
      startsAt: lastTime,
      endsAt: denormalizedHoursPeriod.endsAt,
      type: 'standard',
    });
  }

  return intervals;
}
