import React from 'react';
import oFetch from 'o-fetch';
import { RadioGroup, Radio } from '@/components/radio-group';
import * as constants from '../../constants';

export default function CategoriesFilter(props) {
  const [onChange, activeFilter] = oFetch(props, 'onChange', 'activeFilter');
  return (
    <div className="boss-board__filter">
      <div className="boss-form">
        <div className="boss-form__field">
          <RadioGroup
            name="filterType"
            selectedValue={activeFilter}
            onChange={onChange}
            className="boss-form__switcher"
          >
            <label className="boss-form__switcher-label">
              <Radio value={constants.REQUIRED_ATTENTION_CATEGORIES} className="boss-form__switcher-radio" />
              <span className="boss-form__switcher-label-text">Requiring Attention</span>
            </label>
            <label className="boss-form__switcher-label">
              <Radio value={constants.ALL_CATEGORIES} className="boss-form__switcher-radio" />
              <span className="boss-form__switcher-label-text">Show All</span>
            </label>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
