import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';
import { useDispatch } from 'react-redux';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import { ReassignTaskForm } from '../forms';
import * as actions from '../../redux/actions';

export function ReassignTask(props) {
  const marketingTask = oFetch(props, 'marketingTask');
  const [marketingTaskId, assignedUser, permissions, isAccepted] = oFetch(
    marketingTask,
    'id',
    'assignedUser',
    'permissions',
    'isAccepted',
  );
  const canReassignMarketingTask = oFetch(permissions, 'canReassignMarketingTask');
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [userOptions, currentUserId] = oFetch(useLegacyGlobal(), 'userOptions', 'currentUserId');
  const dispatch = useDispatch();

  const initialValues = {
    marketingTaskId: marketingTaskId,
    userId: oFetch(assignedUser, 'id'),
  };

  async function handleSubmit(values) {
    setProcessing(true);
    await dispatch(
      actions.reassingMarketingTask({
        values,
        onSuccess() {
          setIsTooltipOpened(false);
        },
      }),
    );
    setProcessing(false);
  }

  if (!canReassignMarketingTask || isAccepted) {
    return (
      <div className="boss-check__header-meta-item">
        <p className="boss-check__text boss-check__text_role_user">{oFetch(assignedUser, 'fullName')}</p>
      </div>
    );
  }

  return (
    <Tooltip
      arrow
      open={isTooltipOpened}
      theme="light"
      position="bottom"
      onRequestClose={() => {
        if (!processing) {
          setIsTooltipOpened(false);
        }
      }}
      interactive
      unmountHTMLWhenHide={true}
      html={
        <ReassignTaskForm
          initialValues={initialValues}
          userOptions={userOptions}
          currentUserId={currentUserId}
          onSubmit={handleSubmit}
        />
      }
    >
      <div className="boss-task__meta-item">
        <button onClick={() => setIsTooltipOpened(true)} className="boss-task__meta-link">
          <p className="boss-button boss-button_role_user-light boss-button_type_extra-small">
            {oFetch(assignedUser, 'fullName')}
          </p>
        </button>
      </div>
    </Tooltip>
  );
}
