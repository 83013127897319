import React, { Component } from 'react';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';

import { BossFormSelectNew } from '@/components/boss-form';

export const FORM_NAME = 'move_question_form';

class MoveQuestionModalForm extends Component {
  render() {
    const [
      categoryOptions,
      handleSubmit,
      submitting,
    ] = oFetch(
      this.props,
      'categoryOptions',
      'handleSubmit',
      'submitting',
    );

    return (
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
          <div className="boss-modal-window__message-group">
            <p className="boss-modal-window__message-text">Please select the quiz category you'd like to move this question to</p>
          </div>
          <div className="boss-modal-window__message-group">
            <div className="boss-form">
              <Field
                name="quizCategoryId"
                label="Category"
                className="Select-control Select--single has-value"
                options={categoryOptions}
                component={BossFormSelectNew}
              />
              <div className="boss-modal-window__actions">
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="boss-button boss-modal-window__button"
                    type="submit"
                  >
                    Move
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const formSelector = formValueSelector(FORM_NAME);

export default reduxForm({
  form: FORM_NAME,
})(MoveQuestionModalForm);
