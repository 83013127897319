import React from 'react';
import { appRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import DashboardWeekSelect from '@/components/boss-dashboards/dashboard-week-select-inline';
import RequestsContent from './requests-content';
import RequestsFilter from './requests-filter';
import RequestItem from './requests-item';
import safeMoment from '@/lib/safe-moment';

function RequestsPage(props) {
  const [counts, totalCount] = oFetch(props, 'counts', 'totalCount');

  function handleDateChage(selection) {
    const mStartDate = safeMoment.uiDateParse(oFetch(selection, 'startUIDate'));
    goToSecurityRotaShiftRequestsPage({
      mStartDate: mStartDate,
    });
  }

  function goToSecurityRotaShiftRequestsPage({ mStartDate }) {
    window.location.href = appRoutes.securityRotaShiftRequests({
      mStartDate,
    });
  }

  function handleOpenAssignPage(shiftRequest) {
    const onOpenAssignPage = oFetch(props, 'onOpenAssignPage');
    onOpenAssignPage(shiftRequest);
  }

  const startDate = oFetch(props, 'startDate');
  const isFetching = oFetch(props, 'isFetching');
  const endDate = oFetch(props, 'endDate');
  const shiftRequests = oFetch(props, 'shiftRequests');
  const onWeekChange = oFetch(props, 'onWeekChange');
  const onVenueChange = oFetch(props, 'onVenueChange');

  const onRejectSecurityShiftRequest = oFetch(props, 'onRejectSecurityShiftRequest');
  const selectedVenues = oFetch(props, 'selectedVenues');
  const sSelectedDate = oFetch(props, 'sSelectedDate');
  const venuesState = oFetch(props, 'venuesState');
  const onToggleVenue = oFetch(props, 'onToggleVenue');

  return (
    <div className="boss-page-main">
      <DashboardWeekSelect
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChage}
        title="Security Rota Requests"
      />
      <RequestsContent
        shiftRequests={shiftRequests}
        isFetching={isFetching}
        selectedVenues={selectedVenues}
        venuesState={venuesState}
        onToggleVenue={onToggleVenue}
        itemRenderer={shiftRequest => {
          return (
            <RequestItem
              onOpenAssignPage={handleOpenAssignPage}
              shiftRequest={shiftRequest}
              onRejectSecurityShiftRequest={onRejectSecurityShiftRequest}
            />
          );
        }}
      >
        <RequestsFilter
          sDate={sSelectedDate}
          counts={counts}
          totalCount={totalCount}
          disabled={isFetching}
          onWeekChange={onWeekChange}
          onVenueChange={onVenueChange}
          selectedVenues={selectedVenues}
        />
      </RequestsContent>
    </div>
  );
}

export default RequestsPage;
