import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ExceptionForm } from './exception-form';

export function UpdateExceptionModal(props) {
  const [onSubmit, securityPayRate, securityPayRateException] = oFetch(
    props,
    'onSubmit',
    'securityPayRate',
    'securityPayRateException',
  );

  const initialValues = {
    securityPayRateId: oFetch(securityPayRate, 'id'),
    securityPayRateExceptionId: oFetch(securityPayRateException, 'id'),
    hourlyRateCents: oFetch(securityPayRateException, 'hourlyRateCents') / 100,
    startsAt: oFetch(securityPayRateException, 'startsAt'),
    endsAt: oFetch(securityPayRateException, 'endsAt'),
  };

  return <ExceptionForm onSubmit={onSubmit} initialValues={initialValues} />;
}
