import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { legacyMatchesJsmEmail } from '@/lib/jsm-email-funcs';
import BossFormCheckbox from '@/components/boss-form/boss-form-checkbox';

export function PersonalDetailsForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');
  const globals = useLegacyGlobal();
  const permissions = oFetch(globals, 'permissions');
  const canBypassJsmMailbox = oFetch(permissions, 'canBypassJsmMailbox');
  const userIsLinked = oFetch(props, 'userIsLinked');

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(args) => {
        const [
          handleSubmit,
          submitting,
          values,
        ] = oFetch(args, 'handleSubmit', 'submitting', 'values');
        const submitError = args.submitError;
        const showBypassCheckBox = userIsLinked && canBypassJsmMailbox && legacyMatchesJsmEmail({ email: values.email });

        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field name="firstName" label="First Name" parse={value => value} component={BossFormInput} />
                <Field name="surname" label="Surname" parse={value => value} component={BossFormInput} />
                <Field name="email" label="Email Address" parse={value => value} component={BossFormInput} />
                { showBypassCheckBox && (
                    <Field
                      name="bypassJsmMailbox"
                      label="Bypass JSM Mailbox"
                      component={BossFormCheckbox}
                      type='checkbox'
                    />
                ) }
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-form__submit boss-form__submit_adjust_single"
                    disabled={submitting}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
