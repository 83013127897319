import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ForSpecialSecurityHoursSecurityPayRateForm } from './for-special-security-hours-security-pay-rate-form';

export function AddForSpecialSecurityHoursSecurityPayRateModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const initialValues = {
    name: '',
    standardHourlyRateCents: null,
    weekendHourlyRateCents: null,
  };

  return <ForSpecialSecurityHoursSecurityPayRateForm onSubmit={onSubmit} initialValues={initialValues} isEdit={false} />;
}
