import oFetch from 'o-fetch';
import React from 'react';
import { useMarketingTaskModals } from '../../../marketing-task-modals';

AttachmentChangesDetailsButton.defaultProps = {
  text: 'Attachments changed',
};

export function AttachmentChangesDetailsButton(props) {
  const [oldAttachments, newAttachments, text] = oFetch(props, 'oldAttachments', 'newAttachments', 'text');
  const { openAttachmentsChangesModal } = useMarketingTaskModals();

  return (
    <p className="boss-overview__text">
      {text}
      <button
        onClick={() => openAttachmentsChangesModal(oldAttachments, newAttachments)}
        className="boss-button boss-button_type_ultra-small boss-button_role_view-details-light boss-check__header-action"
        style={{ marginLeft: '15px' }}
      >
        View Details
      </button>
    </p>
  );
}
