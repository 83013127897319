import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Fields } from 'redux-form';
import uuid from 'uuid/v1';
import { getHoursBetween, incrementHours } from '@/lib/rota-date';
import safeMoment from '@/lib/safe-moment';
import { FormTimeInterval } from './form-time-interval';

export function BreaksArray(props) {
  const [
    fields,
    hoursAcceptancePeriod,
    meta,
    rotaDate,
  ] = oFetch(
    props,
    'fields',
    'hoursAcceptancePeriod',
    'meta',
    'rotaDate',
  );
  const frontendId = oFetch(hoursAcceptancePeriod, 'frontendId');
  const { submitting } = meta;

  function onAddBreak(fields) {
    const shiftStartOffset = getHoursBetween({
      dStartTime: rotaDate.startTime(),
      dEndTime: safeMoment.iso8601Parse(hoursAcceptancePeriod.startsAt).toDate(),
    });

    let breakHoursOffset = shiftStartOffset + 1;

    if (breakHoursOffset > 23) {
      breakHoursOffset = 23;
    }

    const newBreak = {
      id: null,
      frontendId: uuid(),
      hoursAcceptancePeriod: frontendId,
      startsAt: safeMoment.iso8601Parse(incrementHours({
        dTime: rotaDate.startTime(),
        hours: breakHoursOffset,
        minutes: 0,
      }).toISOString()).format(),
      endsAt: safeMoment.iso8601Parse(incrementHours({
        dTime: rotaDate.startTime(),
        hours: breakHoursOffset,
        minutes: 15,
      }).toISOString()).format(),
    };
    fields.push(newBreak);
  }

  return (
    <Fragment>
      {fields.map((periodBreak, index) => {
        return (
          <div
            key={index}
            className="boss-time-shift__break-item"
          >
            <div className="boss-time-shift__log boss-time-shift__log_layout_break">
              <Fields
                names={[`${periodBreak}.startsAt`, `${periodBreak}.endsAt`, `${periodBreak}.base`]}
                component={FormTimeInterval}
                isFromBreaks={true}
                index={index}
                granularityInMinutes={1}
                rotaDate={rotaDate}
              />
              <div className="boss-time-shift__actions">
                <button
                  disabled={submitting}
                  onClick={() => fields.remove(index)}
                  type="button"
                  className="boss-button boss-button_type_icon boss-button_role_cancel boss-time-shift__button boss-time-shift__button_role_delete-break"
                >
                  <i className="fa fa-remove" />
                </button>
              </div>
            </div>
          </div>
        );
      })}
      <div className="boss-time-shift__actions">
        <button
          type="button"
          onClick={() => onAddBreak(fields)}
          className="boss-button boss-button_role_add boss-time-shift__button boss-time-shift__button_role_add-break"
        >
          Add break
        </button>
      </div>
    </Fragment>
  );
}
