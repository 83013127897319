import React, { Fragment, useMemo } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import { RotaDateUIUtils } from '@/lib/rota-date';
import { TimeSelectValue, TimeSelectOption } from '@/components/boss-form/colored-select';

import { SelectTimeInputRenderer } from '@/components/boss-form/time-input-render';
import BossDatePicker from '@/components/react-dates/boss-date-picker';

export function BossFormDateTimeIntervalField(props) {
  const dateField = oFetch(props, 'date');
  const startsAtField = oFetch(props, 'startsAt');
  const endsAtField = oFetch(props, 'endsAt');
  const appType = oFetch(props, 'appType');

  const dateValue = oFetch(dateField, 'input.value');
  const dateTouched = oFetch(dateField, 'meta.touched');
  const {
    meta: { error: dateError, submitError: dateSubmitError },
  } = dateField;
  const dateHasError = dateError || dateSubmitError;

  const startsAtValue = oFetch(startsAtField, 'input.value');
  const startsAtTouched = oFetch(startsAtField, 'meta.touched');
  const {
    meta: { error: startsAtError, submitError: startsAtSubmitError },
  } = startsAtField;
  const startsAtHasError = startsAtError || startsAtSubmitError;

  const endsAtValue = oFetch(endsAtField, 'input.value');
  const endsAtTouched = oFetch(endsAtField, 'meta.touched');
  const {
    meta: { error: endsAtError, submitError: endsAtSubmitError },
  } = endsAtField;
  const endsAtHasError = endsAtError || endsAtSubmitError;

  const dateSelected = !!dateValue;

  function handleDateChange(value) {
    if (!value) {
      return value;
    }
    return oFetch(dateField, 'input.onChange')(value.format(utils.uiRotaDateFormat));
  }

  function handleStartsAtChange(value) {
    return oFetch(startsAtField, 'input.onChange')(value);
  }

  function handleEndsAtChange(value) {
    return oFetch(endsAtField, 'input.onChange')(value);
  }

  const formattedDateValue = useMemo(() => {
    if (!dateValue) {
      return dateValue;
    }
    return safeMoment.uiDateParse(dateValue);
  }, [dateValue]);

  const timeSelectOptions = useMemo(() => {
    if (!dateValue) {
      return [];
    }
    const rotaDateUiUtils = RotaDateUIUtils.sFromDate({
      sDate: dateValue,
      appType,
    });
    return rotaDateUiUtils.getSelectOptions({ intervalSeconds: 900 });
  }, [dateValue]);

  const startsAtSelectClassNames = cn('boss-form__select', {
    'boss-form__select_state_error': startsAtTouched && startsAtHasError,
  });

  const endsAtSelectClassNames = cn('boss-form__select', {
    'boss-form__select_state_error': endsAtTouched && endsAtHasError,
  });

  return (
    <Fragment>
      <div className={'boss-form__field'}>
        <label className="boss-form__label">
          <span className="boss-form__label-text">Date *</span>
        </label>
        <BossDatePicker
          date={formattedDateValue}
          isOutsideRange={false}
          isDayBlocked={false}
          onApply={handleDateChange}
          disableBeforeRotaDay={false}
          invalid={!!dateTouched && !!dateHasError}
        />
        {dateTouched && dateHasError && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{dateError || dateSubmitError}</span>
            </p>
          </div>
        )}
      </div>
      {dateSelected && (
        <div className="boss-form__row">
          <div className="boss-form__field boss-form__field_layout_half">
            <div className="boss-form__field">
              <label
                htmlFor={'startsAt'}
                className="boss-form__label"
              >
                <span className="boss-form__label-text">Starts At *</span>
              </label>
              <div className={startsAtSelectClassNames}>
                <Select
                  options={timeSelectOptions}
                  onChange={handleStartsAtChange}
                  name={'startsAt'}
                  simpleValue
                  placeholder={'Select starts at time ...'}
                  value={startsAtValue}
                  inputRenderer={SelectTimeInputRenderer}
                  valueComponent={TimeSelectValue}
                  optionComponent={TimeSelectOption}
                />
              </div>
              {startsAtTouched && startsAtHasError && (
                <div className="boss-form__error">
                  <p className="boss-form__error-text">
                    <span className="boss-form__error-line">{startsAtError || startsAtSubmitError}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_half">
            <div className="boss-form__field">
              <label
                htmlFor={'startsAt'}
                className="boss-form__label"
              >
                <span className="boss-form__label-text">Ends At *</span>
              </label>
              <div className={endsAtSelectClassNames}>
                <Select
                  options={timeSelectOptions}
                  onChange={handleEndsAtChange}
                  name={'endsAt'}
                  simpleValue
                  placeholder={'Select ends at time ...'}
                  value={endsAtValue}
                  inputRenderer={SelectTimeInputRenderer}
                  valueComponent={TimeSelectValue}
                  optionComponent={TimeSelectOption}
                />
              </div>
              {endsAtTouched && endsAtHasError && (
                <div className="boss-form__error">
                  <p className="boss-form__error-text">
                    <span className="boss-form__error-line">{endsAtError || endsAtSubmitError}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
