import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function CSVTableCell(props) {
  const [text, isInvalid, isHeader] = oFetch(props, 'text', 'isInvalid', 'isHeader');

  if (isHeader) {
    return <div className="boss-table__cell boss-table__cell_role_header">{text}</div>;
  }

  const cellClassNames = cn('boss-table__cell', { 'boss-table__cell_state_alert': isInvalid });

  return (
    <div className={cellClassNames} data-popover="1">
      <div className="boss-table__info">
        <p className="boss-table__text">{text}</p>
      </div>
    </div>
  );
}

CSVTableCell.defaultProps = {
  isInvalid: false,
  isHeader: false,
};
