import oFetch from 'o-fetch';
import React from 'react';
import { AttachmentChangesList } from './attachment-changes-list';

function getUniq(array, key) {
  return Object.values(
    array.reduce((acc, item) => {
      acc[oFetch(item, key)] = item;
      return acc;
    }, {}),
  );
}

export function AttachmentsChangesModalContent(props) {
  const [oldAttachments, newAttachments] = oFetch(props, 'oldAttachments', 'newAttachments');
  const oldAttachmentsIds = oldAttachments.map(attachment => oFetch(attachment, 'id'));
  const newAttachmentsIds = newAttachments.map(attachment => oFetch(attachment, 'id'));

  const deleted = oldAttachments.filter(attachment => {
    return !newAttachmentsIds.includes(oFetch(attachment, 'id'));
  });

  const added = newAttachments.filter(attachment => {
    return !oldAttachmentsIds.includes(oFetch(attachment, 'id'));
  });

  const allAttachments = getUniq([...oldAttachments, ...newAttachments], 'id').map(attachment => {
    return {
      ...attachment,
      isAdded: added.map(a => oFetch(a, 'id')).includes(oFetch(attachment, 'id')),
      isDeleted: deleted.map(a => oFetch(a, 'id')).includes(oFetch(attachment, 'id')),
    };
  });
  return (
    <div className="boss-modal-window__overview">
      <p
        className="boss-overview__text"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <AttachmentChangesList attachments={allAttachments} />
      </p>
    </div>
  );
}
