import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { WarningModal, ApiKeyList, ApiKeyItem } from './components';
import { useModal } from '@/components/hooks-components/modals';
import DashboardWrapper from '@/components/dashboard-wrapper';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import ContentWrapper from '@/components/content-wrapper';
import useApiKeysApiCalls from './use-api-keys-api-calls';

export default function ApiKeys(props) {
  const { openPromiseModal } = useModal();
  const pageTitle = oFetch(props, 'pageTitle');
  const venueKeys = oFetch(props, 'venueKeys');
  const apiBasePath = oFetch(props, 'apiBasePath');
  const { state, revokeApiKey, createApiKey } = useApiKeysApiCalls(venueKeys);

  function openRevokeModal(params) {
    return openPromiseModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: WarningModal,
      onSubmit(handleClose) {
        handleClose();
        const apiKeyId = oFetch(params, 'apiKeyId');
        return revokeApiKey({ apiKeyId, apiBasePath });
      },
      config: {
        title: 'WARNING !!!',
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Delete',
      },
    });
  }

  function handleCreate(params) {
    const venueId = oFetch(params, 'venueId');
    return createApiKey({ venueId, apiBasePath });
  }

  function renderApiKeyItem(apiKey) {
    return <ApiKeyItem {...apiKey} onRevoke={openRevokeModal} onCreate={handleCreate} />;
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{pageTitle}</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <ApiKeyList apiKeys={state} itemRenderer={renderApiKeyItem} />
      </ContentWrapper>
    </Fragment>
  );
}
