import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import SecurityShiftRequestReviewsPage from '../components/security-shift-request-reviews-page';
import {
  getPendingSecurityShiftRequests,
  getCompletedSecurityShiftRequests,
  venuesSelector,
  countsSelector,
} from '../selectors';

import {
  rejectSecurityShiftRequest,
  undoSecurityShiftRequest,
  acceptSecurityShiftRequest,
  editSecurityShiftRequest,
  changeWeekDay,
} from '../redux/actions';

const mapStateToProps = state => {
  return {
    pendingSecurityShiftRequests: getPendingSecurityShiftRequests(state),
    completedSecurityShiftRequests: getCompletedSecurityShiftRequests(state),
    startDate: oFetch(state, 'pageOptions.startDate'),
    endDate: oFetch(state, 'pageOptions.endDate'),
    date: oFetch(state, 'pageOptions.date'),
    chosenDate: oFetch(state, 'pageOptions.chosenDate'),
    accessibleVenues: venuesSelector(state),
    counts: countsSelector(state),
  };
};

const mapDispatchToProps = {
  rejectSecurityShiftRequest,
  undoSecurityShiftRequest,
  acceptSecurityShiftRequest,
  editSecurityShiftRequest,
  changeWeekDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityShiftRequestReviewsPage);
