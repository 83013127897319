import React from 'react';
import oFetch from 'o-fetch';
import { useModal } from '@/components/hooks-components/modals';
import { ChangeVenue, ManuallyAdd, UpdateCustomName } from './modal-contents';

export function useDevicesModals() {
  const { openProcessingModal } = useModal();

  function openManuallyAddModal(params) {
    const [venueOptions, device, handleSubmit] = oFetch(params, 'venueOptions', 'device', 'handleSubmit');
    const [deviceId, customDeviceName, lastDeviceName] = oFetch(
      device,
      'id',
      'customDeviceName',
      'lastDeviceName',
    );
    const deviceName = customDeviceName || lastDeviceName || 'Unknown Device';

    return openProcessingModal({
      onSubmit: (handleCloseModal, setProcessing, values) => {
        setProcessing(true);
        return handleSubmit({
          values: values,
          onSuccess() {
            setProcessing(false);
            handleCloseModal();
          },
          onFailure() {
            setProcessing(false);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              Manually Grant Access for <span className="boss-modal-window__marked">{deviceName}</span>
            </div>
          );
        },
      },
      props: {
        deviceId,
        venueOptions,
      },
      ModalContent: ManuallyAdd,
    });
  }

  function openChangeVenueModal(params) {
    const [venueOptions, device, handleSubmit] = oFetch(params, 'venueOptions', 'device', 'handleSubmit');
    const [deviceId, customDeviceName, lastDeviceName] = oFetch(
      device,
      'id',
      'customDeviceName',
      'lastDeviceName',
    );
    const deviceName = customDeviceName || lastDeviceName || 'Unknown Device';

    return openProcessingModal({
      onSubmit: (handleCloseModal, setProcessing, values) => {
        setProcessing(true);
        return handleSubmit({
          values: values,
          onSuccess() {
            setProcessing(false);
            handleCloseModal();
          },
          onFailure() {
            setProcessing(false);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              Change Venue for <span className="boss-modal-window__marked">{deviceName}</span>
            </div>
          );
        },
      },
      props: {
        device,
        venueOptions,
      },
      ModalContent: ChangeVenue,
    });
  }

  function openUpdateDeviceNameModal(params) {
    const [device, handleSubmit] = oFetch(params, 'device', 'handleSubmit');
    return openProcessingModal({
      onSubmit: (handleCloseModal, setProcessing, values) => {
        setProcessing(true);
        return handleSubmit({
          values,
          onSuccess() {
            setProcessing(false);
            handleCloseModal();
          },
          onFailure() {
            setProcessing(false);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Update Custom Device Name',
      },
      props: {
        device,
      },
      ModalContent: UpdateCustomName,
    });
  }

  return {
    openManuallyAddModal,
    openUpdateDeviceNameModal,
    openChangeVenueModal,
  };
}
