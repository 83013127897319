import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export const lambdaFailureTestRequest = url => {
  return bossRequestHttp({
    auth: false,
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      return true;
    },
    successHandler() {},
  }).get(url);
};
