import { FORM_ERROR } from "final-form";
import { z } from "zod";

export type WhatsappServer = z.infer<typeof $WhatsappServer>;

export type WhatsappInstance = z.infer<typeof $WhatsappInstance>;

export const $WhatsappInstance = z.object({
  id: z.number(),
  whatsappServerId: z.number(),
  name: z.string(),
  customName: z.string().nullable(),
  instanceHash: z.string(),
  token: z.string(),
  whatsappFeatures: z.string().array(),
  isAvailable: z.boolean(),
  lastAvailabilityCheck: z.string(),
  lastUnavailableEmailSent: z.string().nullable(),
});

export const $WhatsappServer = z.object({
  id: z.number(),
  name: z.string(),
  baseUrl: z.string(),
  instances: z.array($WhatsappInstance),
  lastRestartedAt: z.string().nullable()
});

export const $CreateWhatsappServerSuccessData = z.object({
  whatsappServer: $WhatsappServer
});

export const $CreateWhatsappInstanceSuccessData = z.object({
  whatsappServer: $WhatsappServer
});
export const $RestartWhatsappServerSuccessData = z.object({
  whatsappServer: $WhatsappServer
});

export type CreateWhatsappServerSuccessData = z.infer<typeof $CreateWhatsappServerSuccessData>;
export type RestartWhatsappServerSuccessData = z.infer<typeof $RestartWhatsappServerSuccessData>;

export const $UpdateWhatsappInstanceCustomNameSuccessData = z.object({
  whatsappServer: $WhatsappServer
});

export type UpdateWhatsappInstanceCustomNameSuccessData = z.infer<typeof $UpdateWhatsappInstanceCustomNameSuccessData>;

export const $DisableWhatsappInstanceSuccessData = z.object({});

export type DisableWhatsappInstanceSuccessData = z.infer<typeof $DisableWhatsappInstanceSuccessData>;

export type CreateWhatsappInstanceSuccessData = z.infer<typeof $CreateWhatsappInstanceSuccessData>;

export type CheckWhatsappInstanceAbilitySuccessData = {
  authenticated: boolean,
  instanceName: string,
};

const $FieldValidationErrors = z.string().array().optional();

export const $CreateWhatsappServerRequestValidationErrors = z.object({
  base: $FieldValidationErrors,
  name: $FieldValidationErrors,
  baseUrl: $FieldValidationErrors,
}).transform((obj) => {
  const { base, ...objValues } = obj;
  if (base) {
    return {
      [FORM_ERROR]: base,
      ...objValues,
    };
  } else {
    return objValues;
  }
});

export const $UpdateWhatsappInstanceCustomNameRequestValidationErrors = z.object({
  base: $FieldValidationErrors,
  bossServices: $FieldValidationErrors,
}).transform((obj) => {
  const { base, ...objValues } = obj;
  if (base) {
    return {
      [FORM_ERROR]: base,
      ...objValues,
    };
  } else {
    return objValues;
  }
});

export const $CreateWhatsappInstanceRequestValidationErrors = z.object({
  base: $FieldValidationErrors,
  name: $FieldValidationErrors,
  instanceHash: $FieldValidationErrors,
  token: $FieldValidationErrors,
}).transform((obj) => {
  const { base, ...objValues } = obj;
  if (base) {
    return {
      [FORM_ERROR]: base,
      ...objValues,
    };
  } else {
    return objValues;
  }
});

export type UpdateWhatsappInstanceCustomNameRequestValidationErrors = typeof $UpdateWhatsappInstanceCustomNameRequestValidationErrors["_input"];
export type UpdateWhatsappInstanceCustomNameRequestFormValidationErrors = typeof $UpdateWhatsappInstanceCustomNameRequestValidationErrors["_output"];

export type CreateWhatsappServerRequestValidationErrors = typeof $CreateWhatsappServerRequestValidationErrors["_input"];
export type CreateWhatsappServiceFormValidationErrors = typeof $CreateWhatsappServerRequestValidationErrors["_output"];

export type CreateWhatsappInstanceRequestValidationErrors = typeof $CreateWhatsappServerRequestValidationErrors["_input"];
export type CreateWhatsappInstanceFormValidationErrors = typeof $CreateWhatsappServerRequestValidationErrors["_output"];
