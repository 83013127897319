import oFetch from 'o-fetch';
import React, { Fragment } from 'react';

export default function StaffMemberPayment(props) {
  const [staffMember, openPayslipModal, openPaymentDetailsModal] = oFetch(
    props,
    'staffMember',
    'openPayslipModal',
    'openPaymentDetailsModal',
  );
  const paymentsData = oFetch(staffMember, 'paymentsData');
  const paymentsDataCount = paymentsData.length;
  const isMoreThanOnePaymentsData = paymentsDataCount > 1;

  const [name, avatarUrl, staffTypeBadgeColor, staffTypeName, supportsFacialRecognition] = oFetch(
    staffMember,
    'name',
    'avatarUrl',
    'staffTypeBadgeColor',
    'staffTypeName',
    'supportsFacialRecognition',
  );

  function renderStaffMemberPaymentsContent() {
    if (isMoreThanOnePaymentsData) {
      return (
        <Fragment>
          <ul className="boss-user-summary__review-list">
            <li className="boss-user-summary__review-item">
              <span className="boss-user-summary__review-marked">
                {paymentsDataCount} payments updated
              </span>
            </li>
          </ul>
          <div className="boss-user-summary__footer">
            <button
              onClick={() => openPaymentDetailsModal({ payments: paymentsData, fullName: name })}
              className="boss-button boss-button_role_view-details-light boss-button_type_extra-small"
            >
              View Details
            </button>
          </div>
        </Fragment>
      );
    }
    const paymentData = paymentsData[0];
    const payment = oFetch(paymentData, 'payment');
    const payslip = oFetch(paymentData, 'payslip');

    const [amount, dateRange, sWeekStartDate] = oFetch(
      payment,
      'amount',
      'dateRange',
      'sWeekStartDate',
    );
    return (
      <Fragment>
        <ul className="boss-user-summary__review-list">
          <li className="boss-user-summary__review-item">
            <span className="boss-user-summary__review-line boss-user-summary__review-marked">
              {amount}
            </span>
            <span className="boss-user-summary__review-line">{dateRange}</span>
          </li>
        </ul>
        <div className="boss-user-summary__footer">
          <button
            onClick={() => openPayslipModal({ payslip, fullName: name })}
            className="boss-button boss-button_role_view-details-light boss-button_type_extra-small"
          >
            View Payslip
          </button>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="boss-users__flow-item boss-users__flow-item_size_third">
      <div className="boss-user-summary boss-user-summary_role_review-short">
        <div className="boss-user-summary__side">
          <div className="boss-user-summary__avatar">
            <div className="boss-user-summary__avatar-inner">
              <img src={avatarUrl} alt={name} className="boss-user-summary__pic" />
            </div>
          </div>
        </div>
        <div className="boss-user-summary__content">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{name}</h2>
            <p
              className="boss-button boss-button_type_label boss-user-summary__label"
              style={{ backgroundColor: staffTypeBadgeColor }}
            >
              {staffTypeName}
            </p>
          </div>
          {renderStaffMemberPaymentsContent()}
        </div>
      </div>
    </div>
  );
}
