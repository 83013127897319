import React, { useEffect, useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Spinner from '@/components/spinner';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function BankDetailsViewModal(props) {
  const [onGetBankDetails, staffMemberId, staffMemberName, onSubmit] = oFetch(
    props,
    'onGetBankDetails',
    'staffMemberId',
    'staffMemberName',
    'onSubmit',
  );
  const [bankDetails, setBankDetails] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [isBankDetailsHidden, setIsBankDetailsHidden] = useState(true);
  let bankDetailsClearTimeout;

  const showButtonClassNames = cn('boss-action boss-action_size_l', {
    'boss-action_role_show': isBankDetailsHidden,
    'boss-action_role_hide': !isBankDetailsHidden,
  });

  useEffect(() => {
    (async () => {
      await onGetBankDetails({
        staffMemberId: staffMemberId,
        onSuccess(data) {
          const [
            altBankingFullname,
            bankName,
            acceptsBACS,
            acceptsCHAPS,
            acceptsFasterPayments,
            branchName,
            bankSortCode,
            hiddenBankSortCode,
            bankAccountNumber,
            hiddenBankAccountNumber,
            bankDetailsLastUpdatedBy,
            bankDetailsLastUpdatedAt,
            couldntBeValidatedByBank,
          ] = oFetch(
            data,
            'altBankingFullname',
            'bankName',
            'acceptsBACS',
            'acceptsCHAPS',
            'acceptsFasterPayments',
            'branchName',
            'bankSortCode',
            'hiddenBankSortCode',
            'bankAccountNumber',
            'hiddenBankAccountNumber',
            'bankDetailsLastUpdatedBy',
            'bankDetailsLastUpdatedAt',
            'couldntBeValidatedByBank',
          );
          setBankDetails({
            staffMemberName,
            altBankingFullname,
            bankName,
            acceptsBACS,
            acceptsCHAPS,
            acceptsFasterPayments,
            branchName,
            bankSortCode,
            hiddenBankSortCode,
            bankAccountNumber,
            hiddenBankAccountNumber,
            bankDetailsLastUpdatedBy,
            bankDetailsLastUpdatedAt,
            couldntBeValidatedByBank,
          });
          setFetching(false);
        },
      });
    })();
    return () => {
      clearTimeout(bankDetailsClearTimeout);
    };
  }, []);

  function replaceAt(string, index, replacement, start = false) {
    if (start) {
      return replacement.repeat(string.length - index) + string.substr(index, string.length - index);
    }
    return string.substr(0, index) + replacement.repeat(string.length - index);
  }

  function showBankDetails() {
    if (isBankDetailsHidden === true) {
      setIsBankDetailsHidden(false);
      bankDetailsClearTimeout = setTimeout(() => {
        setIsBankDetailsHidden(true);
      }, 30000);
    } else {
      clearTimeout(bankDetailsClearTimeout);
      setIsBankDetailsHidden(true);
    }
  }

  function renderHiddenFields(originalField, hiddenField) {
    return isBankDetailsHidden ? hiddenField : originalField || 'N/A';
  }

  function renderTick(value) {
    if (value === true) {
      return (
        <span className="boss-indicator">
          <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
        </span>
      );
    }
    if (value === false) {
      return (
        <span className="boss-indicator">
          <span className="boss-indicator__icon boss-indicator__icon_close-bold" />
        </span>
      );
    }
    return 'N/A';
  }

  function renderBankDetailsContent() {
    const [
      altBankingFullname,
      staffMemberName,
      bankDetailsLastUpdatedBy,
      bankDetailsLastUpdatedAt,
      bankAccountNumber,
      hiddenBankAccountNumber,
      bankSortCode,
      hiddenBankSortCode,
      bankName,
      acceptsBACS,
      acceptsCHAPS,
      acceptsFasterPayments,
      branchName,
    ] = oFetch(
      bankDetails,
      'altBankingFullname',
      'staffMemberName',
      'bankDetailsLastUpdatedBy',
      'bankDetailsLastUpdatedAt',
      'bankAccountNumber',
      'hiddenBankAccountNumber',
      'bankSortCode',
      'hiddenBankSortCode',
      'bankName',
      'acceptsBACS',
      'acceptsCHAPS',
      'acceptsFasterPayments',
      'branchName',
    );

    const formattedBankDetailsLastUpdatedAt = safeMoment
      .iso8601Parse(bankDetailsLastUpdatedAt)
      .format(utils.commonDateFormatWithTime());
    return (
      <Fragment>
        <div className="boss-overview__group">
          <ul className="boss-overview__list">
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Official Name:</p>
              <p className="boss-overview__list-value">{altBankingFullname || staffMemberName}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Bank Name:</p>
              <p className="boss-overview__list-value">{bankName}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Branch Name:</p>
              <p className="boss-overview__list-value">{branchName}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Accepts BACS Payments:</p>
              <p className="boss-overview__list-value">{renderTick(acceptsBACS)}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Accepts CHAPS Payments:</p>
              <p className="boss-overview__list-value">{renderTick(acceptsCHAPS)}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Accepts Faster Payments:</p>
              <p className="boss-overview__list-value">{renderTick(acceptsFasterPayments)}</p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Account Number:</p>
              <p className="boss-overview__list-value">
                {renderHiddenFields(bankAccountNumber, hiddenBankAccountNumber)}
                <span className="boss-overview__list-action">
                  <button onClick={showBankDetails} className={showButtonClassNames} type="button" />
                </span>
              </p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Sort Code:</p>
              <p className="boss-overview__list-value">
                {renderHiddenFields(bankSortCode, hiddenBankSortCode)}
                <span className="boss-overview__list-action">
                  <button onClick={showBankDetails} className={showButtonClassNames} type="button" />
                </span>
              </p>
            </li>
            <li className="boss-overview__list-item boss-overview__list-item_role_details">
              <p className="boss-overview__list-key">Last updated:</p>
              <p className="boss-overview__list-value">
                {formattedBankDetailsLastUpdatedAt}
                <span className="boss-overview__list-value-light"> by </span>
                {bankDetailsLastUpdatedBy}
              </p>
            </li>
          </ul>
        </div>

        <div className="boss-overview__group boss-overview__group_position_last">
          <div className="boss-overview__actions">
            <button
              onClick={onSubmit}
              type="button"
              className="boss-button boss-button_role_cancel boss-button_type_small boss-overview__action"
            >
              Wipe these bank details
            </button>
          </div>
        </div>
      </Fragment>
    );
  }

  function renderSpinner() {
    return <Spinner />;
  }

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__overview">
        <div className="boss-overview">
          {!fetching && renderBankDetailsContent()}
          {fetching && renderSpinner()}
        </div>
      </div>
    </div>
  );
}
