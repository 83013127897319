import React from 'react';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import cn from 'classnames';

const RATING_CLASSES = {
  good: 'boss-board_role_kpi-status-good',
  poor: 'boss-board_role_kpi-status-poor',
  bad: 'boss-board_role_kpi-status-bad',
  ok: 'boss-board_role_kpi-status-ok',
};

function getColorClassName(rating) {
  if (rating === null) {
    return '';
  }
  const colorClass = RATING_CLASSES[rating];
  if (!colorClass) {
    throw new Error(
      `Unexpected rating. Expected: ${Object.keys(RATING_CLASSES).join(', ')}, got: ${rating}`,
    );
  }
  return colorClass;
}

export function KPICard(props) {
  const [kpiId, title, score, children, canHandleAction, ratedAt, ratedByUserName, note] = oFetch(
    props,
    'kpiId',
    'title',
    'score',
    'children',
    'canHandleAction',
    'ratedAt',
    'ratedByUserName',
    'note',
  );

  const colorClass = getColorClassName(score);
  const headerClasses = cn('boss-board boss-board_role_kpi', colorClass);

  function renderRatedAt() {
    const formattedRatedAt = safeMoment
      .iso8601Parse(ratedAt)
      .format(utils.commonDateFormatWithTime());
    return (
      <p className="boss-board__text boss-board__text_type_faded">
        Rated at: <span className="boss-board__text-part">{formattedRatedAt}</span>, by{' '}
        <span className="boss-board__text-part">{ratedByUserName}</span>
      </p>
    );
  }

  return (
    <div id={kpiId} className="boss-page-main__isle boss-page-main__isle_adjust_board-half">
      <section className={headerClasses}>
        <header className="boss-board__header">
          <h2 className="boss-board__title">{title}</h2>
          <p className="boss-board__status">{score || 'N/A'}</p>
        </header>
        <div className="boss-board__main">
          <div className="boss-board__main-inner">
            {children}
            {note && (
              <div className="boss-board__main-group">
                <p className="boss-board__text">
                  <span className="boss-board__text-bold">Note:</span> {note}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="boss-board__footer">
          <div className="boss-board__footer-group">{ratedAt && renderRatedAt()}</div>
          {canHandleAction && (
            <div className="boss-board__button-group boss-board__button-group_role_actions">
              <button
                type="button"
                onClick={oFetch(props, 'actionHandler')}
                className="boss-button boss-button_type_extra-small boss-button_role_rate boss-board__action"
              >
                {oFetch(props, 'actionTitle')}
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

KPICard.propTypes = {
  note: PropTypes.string,
};
