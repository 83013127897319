import React, { useEffect, useRef } from 'react';

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

var objectWithoutProperties = function (obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

export function DangerouslySetHtmlContent(props) {
  var html = props.html;
  var rest = objectWithoutProperties(props, ['html']);

  var divRef = useRef(null);

  useEffect(() => {
    if (!html) {
      return;
    }

    var slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [html]);

  return React.createElement('div', _extends({}, rest, { ref: divRef }));
}
