import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const questionnaireCategories = oFetch(action, 'payload.questionnaireCategories');
      return questionnaireCategories;
    },
    [types.ADD_CATEGORY_TO_STORE]: (state, action) => {
      const questionnaireCategory = oFetch(action, 'payload.questionnaireCategory');
      return [...state, questionnaireCategory];
    },
    [types.DELETE_CATEGORY_FROM_STORE]: (state, action) => {
      const categoryId = oFetch(action, 'payload.categoryId');
      return state.filter(category => oFetch(category, 'id') !== categoryId);
    },
  },
  initialState,
);
