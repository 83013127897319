import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import moment from 'moment';
import AsyncButton from 'react-async-button';

import { type MessageSchedule } from '@/types/MessageSchedule';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { stringToHtml } from '@/utils/string-to-html';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { useConfirmationModal } from '@/components/hooks-components/modals';
interface MessageScheduleItemProps {
  messageSchedule: MessageSchedule,
  onDisable: (params: any) => any
  onEdit: (params: any) => any
}

export function MessageScheduleItem(props: MessageScheduleItemProps) {
  const { messageSchedule, onDisable, onEdit } = props;
  const statusTitles = oFetch(useLegacyGlobal(), 'statusTitles');
  const { openWarningModal } = useConfirmationModal();

  const {
    id, createdBy, createdAt, disabledBy, disabledAt, staffMember, sendAt, message, status, isPending, isSent, isFailed, isDisabled
  } = messageSchedule;

  const { fullName: creatorFullName } = createdBy;
  const { fullName: staffMemberFullName, phoneNumber } = staffMember;

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatWithTime());
  const momentSendAt = safeMoment.iso8601Parse(sendAt);
  const current = moment();
  const sendInHumanReadable = utils.timeLeftHumanReadable(momentSendAt.diff(current) / 1000);
  const formattedSendAt = momentSendAt.format(utils.commonDateFormatWithTime());
  const currentStatusTitle = statusTitles[status];
  const currentStatusClassNames = cn(
    'boss-button boss-button_type_ultra-small',
    {
      'boss-button_role_pending': isPending,
      'boss-button_role_ok': isSent,
      'boss-button_role_alert': isFailed || isDisabled
    }
  );

  function handleDisable() {
    return openWarningModal({
      onSubmit(closeModal: any) {
        closeModal();
        return onDisable({ messageScheduleId: id });
      },
      props: {
        text: 'Are you sure ?',
        buttonText: 'Disable',
      },
      config: {
        title: 'DISABLE MESSAGE SCHEDULE',
      },
    });
  }

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_marketing-task">
      <div className="boss-check__header">
        <div className="boss-check__header-info">
          <p className={currentStatusClassNames}>{currentStatusTitle}</p>
        </div>
        <div className="boss-check__header-info">
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_date">{formattedSendAt}</p>
            </div>
            {isPending && (
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text boss-check__text_role_date">{sendInHumanReadable}</p>
              </div>
            )}
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_user">{staffMemberFullName}</p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_phone">{phoneNumber}</p>
            </div>
          </div>
          {isPending && (
            <div className="boss-check__header-actions">
              <button
                onClick={() => onEdit(messageSchedule)}
                className="boss-button boss-button_type_ultra-small boss-button_role_edit-light boss-check__header-action"
              >
                Edit
              </button>
              <AsyncButton
                className="boss-button boss-button_type_ultra-small boss-button_role_cancel-light boss-check__header-action"
                text="Disable"
                pendingText="Disabling ..."
                onClick={handleDisable}
              />
            </div>
          )}
        </div>
        <div className="boss-form__field">
          <p className="boss-form__label">
            <span className="boss-form__label-text">Message:</span>
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: stringToHtml(message) }}
          />
        </div>
      </div>
      <div className="boss-check__group boss-check__group_marked">
        <div className="boss-check__group-content">
          <div className="boss-check__meta">
            <div className="boss-check__meta-item">
              <p className="boss-check__text"><span className="boss-check__text-label">Created at: </span>{formattedCreatedAt}</p>
            </div>
            <div className="boss-check__meta-item">
              <p className="boss-check__text"><span className="boss-check__text-label">Created by: </span>{creatorFullName}</p>
            </div>
            {isDisabled && (
              <>
                <div className="boss-check__meta-item">
                  <p className="boss-check__text"><span className="boss-check__text-label">Disabled by: </span>{disabledBy?.fullName}</p>
                </div>
                <div className="boss-check__meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-label">Disabled by: </span>
                    {safeMoment.iso8601Parse(disabledAt).format(utils.commonDateFormatWithTime())}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
