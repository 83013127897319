import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form/immutable';

import configureStore from '../store';
import { initialProfileLoad } from '../profile-wrapper/actions';
import { initialLoad } from './redux/actions';
import { combineReducers } from 'redux-immutable';

import ShiftsContainer from './containers/shifts-container';
import { ModalsProvider } from '@/components/hooks-components/modals';
import oFetch from 'o-fetch';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import profileReducer from '../profile-wrapper/reducers';
import venuesReducer from './redux/reducers/venues-reducer';
import pageOptions from './redux/reducers/page-options-reducer';
import hoursAcceptancePeriods from './redux/reducers/hours-acceptance-periods-reducer';
import hoursAcceptanceBreaks from './redux/reducers/hours-acceptance-breaks-reducer';
import rotaShifts from './redux/reducers/rota-shifts-reducer';

export function StaffMemberShiftsApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    const store = configureStore(
      combineReducers({
        venues: venuesReducer,
        rotaShifts,
        pageOptions,
        hoursAcceptancePeriods,
        hoursAcceptanceBreaks,
        profile: profileReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <ShiftsContainer />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
