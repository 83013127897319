import { RotaDate, BOSS_APP_TYPE } from "@/lib/rota-date";
import createApiRequestActionCreator from "../create-api-request-action-creator";
import makeApiRequestMaker from "../make-api-request-maker";
import oFetch from "o-fetch";
import getRotaFromDateAndVenue from "@/lib/get-rota-from-date-and-venue";
import * as backendData from "@/lib/backend-data/process-backend-objects";
import { apiRoutes } from "@/lib/legacy-routes";

function confirmIfRotaIsPublished(options) {
    const question = oFetch(options, 'question');
    var rota = getRotaFromDateAndVenue({
        rotas: oFetch(options, "rotasByClientId"),
        dDateOfRota: oFetch(options, "dateOfRota"),
        venueId: oFetch(options, "venueClientId")
    });
    if (rota.status !== "published") {
        return true;
    }
    // eslint-disable-next-line no-undef
    return confirm(question);
}

export const addRotaShift = createApiRequestActionCreator({
    requestType: "ADD_SHIFT",
    makeRequest: makeApiRequestMaker({
        method: apiRoutes.addShift.method,
        path: function ({ venueServerId, starts_at }, getState) {
            var dDate = RotaDate.fromTime({
                dTime: starts_at,
                appType: BOSS_APP_TYPE,
            }).calendarDate();
            return apiRoutes.addShift.getPath(venueServerId, dDate);
        },
        data: function (options) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            var [starts_at, ends_at, staff_member_id, shift_type] = oFetch(options,
                "starts_at", "ends_at", "staffMemberServerId", "shift_type");
            return {
                starts_at,
                ends_at,
                staff_member_id,
                shift_type
            };
        },
        getSuccessActionData: function (responseData, requestOptions, getState) {
            responseData = backendData.processRotaShiftObject(responseData);

            // processRotaShiftObject will set rota.clientId based on the rota's server id
            // However, if the rota object was created on the client before it had
            // a server id, we want to use the original clientId
            var rotaDate = RotaDate.fromTime({
                dTime: responseData.starts_at,
                appType: BOSS_APP_TYPE,
            });
            var rota = getRotaFromDateAndVenue({
                rotas: getState().rotas,
                dDateOfRota: rotaDate.calendarDate(),
                venueId: requestOptions.venueClientId
            });
            responseData.rota.clientId = rota.clientId;

            return { shift: responseData };
        }
    }),
    confirm: function (requestOptions, getState) {
        var venueClientId = oFetch(requestOptions, "venueClientId");
        var dDateOfRota = RotaDate({
            dTime: oFetch(requestOptions, "starts_at"),
            appType: BOSS_APP_TYPE,
        }).calendarDate();
        return confirmIfRotaIsPublished({
            venueClientId,
            dateOfRota: dDateOfRota,
            rotasByClientId: getState().rotas,
            question: "Adding a shift to a published rota will send out email notifications. Do you want to continue?"
        })
    }
});

export const updateRotaShift = createApiRequestActionCreator({
    requestType: "UPDATE_SHIFT",
    makeRequest: makeApiRequestMaker({
        path: (options) => apiRoutes.updateShift.getPath({ shiftId: options.shiftServerId }),
        method: apiRoutes.updateShift.method,
        data: function (options, getState) {
            var shiftType = oFetch(options, "shiftType");
            var staffMemberId = getState().rotaShifts[options.shiftClientId].staff_member.serverId;
            var shift = {
                shift_id: options.shiftServerId,
                starts_at: options.starts_at,
                ends_at: options.ends_at,
                staff_member_id: staffMemberId,
                shift_type: shiftType
            }
            return shift;
        },
        getSuccessActionData(responseData) {
            var shift = backendData.processRotaShiftObject(responseData);
            return { shift };
        }
    }),
    confirm(requestOptions, getState) {
        var venueClientId = oFetch(requestOptions, "venueClientId");
        var dDateOfRota = RotaDate.fromTime({
            dTime: oFetch(requestOptions, "starts_at"),
            appType: BOSS_APP_TYPE,
        }).calendarDate();
        return confirmIfRotaIsPublished({
            venueClientId,
            dateOfRota: dDateOfRota,
            rotasByClientId: getState().rotas,
            question: "Updating a shift on a published rota will send out email notifications. Do you want to continue?"
        })
    }
});

export const deleteRotaShift = createApiRequestActionCreator({
    requestType: "DELETE_SHIFT",
    makeRequest: makeApiRequestMaker({
        method: apiRoutes.deleteShift.method,
        path: (options) => apiRoutes.deleteShift.getPath({ shiftId: oFetch(options, "shift.serverId") }),
        getSuccessActionData: function (responseData, requestOptions) {
            return { shiftClientId: requestOptions.shift.clientId };
        }
    }),
    confirm: function (requestOptions, getState) {
        var venueClientId = oFetch(requestOptions, "venueClientId");
        var dDateOfRota = RotaDate.fromTime({
            dTime: oFetch(requestOptions, "shift.starts_at"),
            appType: BOSS_APP_TYPE,
        }).calendarDate();
        return confirmIfRotaIsPublished({
            venueClientId,
            dateOfRota: dDateOfRota,
            rotasByClientId: getState().rotas,
            question: "Deleting a shift on a published rota will send out email notifications. Do you want to continue?"
        });
    }
});
