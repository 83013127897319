import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import safeMoment from '@/lib/safe-moment';
import utils, { zeroOrNot } from '@/lib/utils';
import * as constants from '../constants';
momentDurationFormatSetup(moment);
const HOURS_24 = 86400000;

function getDeadLine(mCompletionDeadline) {
  const completionDiff = mCompletionDeadline.diff(moment());
  return completionDiff;
}

export const xpSelector = state => oFetch(state.get('quizzes'), 'xp');
export const activeFilterSelector = state => oFetch(state.get('quizzes'), 'filter');
export const quizzesSelector = state => oFetch(state.get('quizzes'), 'quizzes');
export const staffMemberCategoriesSelector = state => oFetch(state.get('quizzes'), 'staffMemberQuizCategories');
export const quizCategoriesSelector = state => oFetch(state.get('quizzes'), 'quizCategories');
export const ranksSelector = state => oFetch(state.get('quizzes'), 'ranks');

export const getQuizzesStats = createSelector([xpSelector, quizzesSelector], (xpData, quizzes) => {
  const [xp, xpToNextLevel] = oFetch(xpData, 'xp', 'xpToNextLevel');
  const all = quizzes.length;
  const passed = quizzes.filter(quiz => oFetch(quiz, 'passed') === true).length;
  const failed = all - passed;
  const xpPercentage = xp / xpToNextLevel * 100;
  const quizWinRate = all === 0 ? 0 : Math.round(passed / all * 100);

  return {
    xp: {
      ...xpData,
      xpPercentage,
    },
    totalQuizzes: all,
    totalQuizzesFailed: failed,
    quizWinRate,
  };
});

export const getAllStaffMemberCategories = createSelector(
  [staffMemberCategoriesSelector, quizCategoriesSelector, ranksSelector, quizzesSelector],
  (staffMemberCategories, quizCategories, ranks, quizzes) => {
    return staffMemberCategories.map(staffMemberCategory => {
      const [
        categoryId,
        quizCategoryRankId,
        lastCompletedAt,
        completionDeadline,
        firstTime,
        firstTimeDeadline,
      ] = oFetch(
        staffMemberCategory,
        'quizCategoryId',
        'quizCategoryRankId',
        'lastCompletedAt',
        'completionDeadline',
        'firstTime',
        'firstTimeDeadline',
      );
      const categoryQuizzes = quizzes.filter(quiz => oFetch(quiz, 'quizCategoryId') === categoryId);
      const allQuizzesCount = categoryQuizzes.length;
      const passedQuizzesCount = categoryQuizzes.filter(quiz => oFetch(quiz, 'passed') === true).length;
      const failedQuizzesCount = allQuizzesCount - passedQuizzesCount;
      const passedQuizzesPercentage =
        allQuizzesCount === 0 ? 0 : Math.round(passedQuizzesCount / allQuizzesCount * 100);
      const failedQuizzesPercentage = failedQuizzesCount === 0 ? 0 : 100 - passedQuizzesPercentage;

      const quizCategory = quizCategories.find(quizCategory => oFetch(quizCategory, 'id') === categoryId);
      const rank = ranks.find(rank => oFetch(rank, 'id') === quizCategoryRankId);
      const [name, color] = oFetch(quizCategory, 'name', 'color');
      const [rankName, multiplier] = oFetch(rank, 'name', 'multiplier');

      const effectiveFirstTimeDeadline = firstTime && firstTimeDeadline;
      const effectiveCompletionDeadline = !firstTime && completionDeadline;
      const effectiveDeadline = effectiveCompletionDeadline || effectiveFirstTimeDeadline;

      const isAlertState = effectiveDeadline && (getDeadLine(safeMoment.iso8601Parse(effectiveDeadline)) <= HOURS_24);

      return {
        ...staffMemberCategory,
        name,
        color,
        rankName,
        multiplier,
        lastCompletedAt,
        allQuizzesCount,
        passedQuizzesCount,
        failedQuizzesCount,
        passedQuizzesPercentage,
        failedQuizzesPercentage,
        effectiveFirstTimeDeadline,
        effectiveCompletionDeadline,
        isAlertState,
      };
    });
  },
);

export const getFirstRank = createSelector([ranksSelector], ranks => {
  const firstRank = ranks.find(rank => oFetch(rank, 'number') === 0);
  if (!firstRank) {
    throw new Error('Rank with number 0 must be exist');
  }
  return firstRank;
});

export const getFirstTimeStaffMemberCategories = createSelector([getAllStaffMemberCategories], categories => {
  return categories.filter(category => {
    const firstTime = oFetch(category, 'firstTime');
    return firstTime;
  });
});

export const getStaffMemberCategories = createSelector(
  [getAllStaffMemberCategories, activeFilterSelector],
  (categories, activeFilter) => {
    const notFirstTimeCategories = categories.filter(category => {
      const firstTime = oFetch(category, 'firstTime');
      return !firstTime;
    });
    if (activeFilter === constants.REQUIRED_ATTENTION_CATEGORIES) {
      return notFirstTimeCategories.filter(category => {
        const isAlertState = oFetch(category, 'isAlertState');
        return isAlertState;
      });
    }
    if (activeFilter === constants.ALL_CATEGORIES) {
      return notFirstTimeCategories;
    }
    throw new Error('Wrong filter type');
  },
);
