import React, { Component } from 'react';
import oFetch from 'o-fetch';
import DropZone from '@/lib/boss-react-dropzone';
import { loadImage } from '@/components/image-resizer';
import { openContentModal } from '@/components/modals';
import cn from 'classnames';
import ImageRotation from '@/components/image-rotation';
const VALID_FILE_TYPES = '.jpg, .jpeg, .png';
const MAX_FILE_SIZE = 1000000; // 1MB

export default class OwedHoursCCTVField extends Component {
  renderErrors = errors => {
    return (
      <div className="boss-form__error">
        {errors.map((error, index) => (
          <p key={index} className="boss-form__error-text">
            <span className="boss-form__error-line">{error}</span>
          </p>
        ))}
      </div>
    );
  };

  handleApplyRotatedImage = (file, onChange, cctvImageHasChangedOnChange) => {
    onChange(file);
    cctvImageHasChangedOnChange(true);
  };

  dropAccepted = async ({ files, field }) => {
    const file = files[0];
    let resizedFile;
    if (file.size > MAX_FILE_SIZE) {
      resizedFile = await loadImage(file, {
        maxWidth: 1024,
        orientation: true,
        crossOrigin: 'anonymous',
      });
    } else {
      resizedFile = file;
    }
    const onChange = oFetch(field, 'input.onChange');
    const cctvImageHasChangedOnChange = oFetch(this.props, `cctvImageHasChanged.input.onChange`);
    openContentModal({
      submit: (modalClose, file) => {
        this.handleApplyRotatedImage(file, onChange, cctvImageHasChangedOnChange);
        modalClose();
      },
      config: { title: 'Test' },
      props: { file: resizedFile },
    })(ImageRotation);
  };

  render() {
    const { title, description } = this.props;
    const names = oFetch(this.props, 'names');
    const [leftImageName, rightImageName] = names;
    const [leftImage, rightImage] = oFetch(this.props, leftImageName, rightImageName);
    const [leftImageValue, leftImageMeta] = oFetch(leftImage, 'input.value', 'meta');
    const [rightImageValue, rightImageMeta] = oFetch(rightImage, 'input.value', 'meta');
    const { error: leftImageErrors } = leftImageMeta;
    const { error: rightImageErrors } = rightImageMeta;
    const leftImageClassNames = cn('boss-upload', { 'boss-upload_state_error': leftImageErrors });
    const rightImageClassNames = cn('boss-upload', { 'boss-upload_state_error': rightImageErrors });
    return (
      <div className="boss-form__group boss-form__group_role_board">
        {title && <p className="boss-form__group-title">{title}</p>}
        <div className="boss-form__row boss-form__row_layout_nowrap">
          <div className="boss-form__field boss-form__field_layout_half">
            <p className="boss-form__label">
              <span className="boss-form__label-text">Start Time</span>
            </p>
            <DropZone
              className={leftImageClassNames}
              accept={VALID_FILE_TYPES}
              onDropAccepted={files => this.dropAccepted({ files, field: leftImage })}
              onDropRejected={this.dropRejected}
            >
              {({ rejectedFiles }) => {
                const hasRejected = rejectedFiles.length > 0;
                if (hasRejected) {
                  return (
                    <div className="boss-upload__preview boss-upload__preview_adjust_form boss-upload__preview_state_broken">
                      <button type="button" className="boss-upload__preview-link" />
                    </div>
                  );
                }
                if (leftImageValue) {
                  return (
                    <div className="boss-upload__preview boss-upload__preview_adjust_form">
                      <button type="button" className="boss-upload__preview-link">
                        <img
                          src={leftImageValue.preview}
                          className="boss-upload__preview-image"
                          crossOrigin="anonymous"
                        />
                      </button>
                    </div>
                  );
                }
                return (
                  <div className="boss-upload__field boss-upload__field_adjust_form">
                    <p className="boss-upload__field-label boss-upload__field-label_adjust_form">
                      Add Image
                    </p>
                  </div>
                );
              }}
            </DropZone>
            {leftImageErrors && this.renderErrors(leftImageErrors)}
          </div>
          <div className="boss-form__field boss-form__field_layout_half">
            <p className="boss-form__label">
              <span className="boss-form__label-text">End Time</span>
            </p>
            <DropZone
              className={rightImageClassNames}
              accept={VALID_FILE_TYPES}
              onDropAccepted={files => this.dropAccepted({ files, field: rightImage })}
              onDropRejected={this.dropRejected}
            >
              {({ rejectedFiles }) => {
                const hasRejected = rejectedFiles.length > 0;
                if (hasRejected) {
                  return (
                    <div className="boss-upload__preview boss-upload__preview_adjust_form boss-upload__preview_state_broken">
                      <button type="button" className="boss-upload__preview-link" />
                    </div>
                  );
                }
                if (rightImageValue) {
                  return (
                    <div className="boss-upload__preview boss-upload__preview_adjust_form">
                      <button type="button" className="boss-upload__preview-link">
                        <img
                          src={rightImageValue.preview}
                          className="boss-upload__preview-image"
                          crossOrigin="anonymous"
                        />
                      </button>
                    </div>
                  );
                }
                return (
                  <div className="boss-upload__field boss-upload__field_adjust_form">
                    <p className="boss-upload__field-label boss-upload__field-label_adjust_form">
                      Add Image
                    </p>
                  </div>
                );
              }}
            </DropZone>
            {rightImageErrors && this.renderErrors(rightImageErrors)}
          </div>
        </div>
        {description && <p className="boss-form__field-note">{description}</p>}
      </div>
    );
  }
}
