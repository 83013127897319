import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class ChecklistItemField extends Component {
  state = {
    isEdit: false,
  };

  enableEditMode = () => {
    const value = oFetch(this.props, 'input.value');
    this.setState({ isEdit: true }, () => {
      this.itemInput.value = value;
    });
  };

  onEditConfirm = () => {
    const value = oFetch(this.itemInput, 'value');
    const onChange = oFetch(this.props, 'input.onChange');
    onChange(value);
    this.setState({ isEdit: false });
  };

  render() {
    const isEdit = oFetch(this.state, 'isEdit');
    const [input, meta, onDelete] = oFetch(this.props, 'input', 'meta', 'onDelete');
    const value = oFetch(input, 'value');
    const { error } = meta;

    return (
      <div className="boss-checklist__item">
        {isEdit && (
          <div className="boss-checklist__control">
            <div className="boss-checklist__field">
              <input
                type="text"
                ref={input => (this.itemInput = input)}
                name="checklist-item-label-edit"
                className="boss-checklist__text-input"
              />
            </div>
            <button
              onClick={this.onEditConfirm}
              className="boss-button boss-button_type_icon boss-button_role_confirm boss-checklist__btn"
            >
              Confirm
            </button>
          </div>
        )}
        {!isEdit && (
          <div className="boss-checklist__control">
            <p className="boss-checklist__label">
              <span className="boss-checklist__label-text">{value}</span>
            </p>
            <button
              onClick={this.enableEditMode}
              type="button"
              className="boss-checklist__icon boss-checklist__icon_role_edit"
            >
              Edit
            </button>
            <button onClick={onDelete} type="button" className="boss-checklist__icon boss-checklist__icon_role_delete">
              Delete
            </button>
          </div>
        )}
        {error && (
          <div className="boss-checklist__alert boss-checklist__alert_size_small">
            <div className="boss-alert">
              <p className="boss-alert__text">{error}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
