import React from 'react';
import cn from 'classnames';

function BossFormCheckbox(props) {
  const {
    input,
    label,
    left = true,
    leftPosition = true,
    className = '',
    type = 'checkbox',
    disabled,
    styles = {},
    meta: { touched, error },
  } = props;

  const inputClassName = cn('boss-form__checkbox-input', {
    'boss-form__checkbox-input_state_error': touched && error,
  });
  const spanClassName = cn('boss-form__checkbox-label-text', {
    'boss-form__checkbox-label-text_layout_reverse': !left,
  });
  const fieldClassName = cn('boss-form__field', { [className]: !!className });
  const labelClassName = cn('boss-form__checkbox-label', {
    'boss-form__checkbox-label_context_label-group': !leftPosition,
  });

  return (
    <div className={fieldClassName} style={styles}>
      <div className="boss-form__label-group">
        <label className={labelClassName}>
          <input
            {...input}
            type={type}
            disabled={disabled}
            placeholder={label}
            checked={input.value || input.checked}
            className={inputClassName}
          />
          <span className={spanClassName}>{label}</span>
        </label>
        {touched && error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BossFormCheckbox;