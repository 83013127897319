import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import cn from 'classnames';
import { FaSquareWhatsapp } from "react-icons/fa6";
import { WhatsappServer } from '../types';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { Tooltip } from 'react-tippy';

type Props = {
  children: React.JSX.Element;
  whatsappServer: WhatsappServer;
  onAddNewInstance: (whatsappServer: WhatsappServer) => void;
  onRestart: (whatsappServiceId: number) => void;
};

export function GroupItem(props: Props) {
  const { children, whatsappServer, onAddNewInstance, onRestart } = props;
  const { name, baseUrl, lastRestartedAt } = whatsappServer;
  const restartWhatsappServerPeriodSeconds = oFetch(useLegacyGlobal(), 'restartWhatsappServerPeriodSeconds');
  const [republishSecondsLeft, setRepublishSecondsLeft] = useState(restartWhatsappServerPeriodSeconds);
  const initialDisabled = lastRestartedAt !== null && (safeMoment.iso8601Parse(lastRestartedAt).valueOf() / 1000 + restartWhatsappServerPeriodSeconds) > (moment().valueOf() / 1000);
  const [disableRepublishButton, setDisableRepublishButton] = useState(initialDisabled);
  const [republishProcessing, setRepublishProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!lastRestartedAt) {
      return;
    }
    const mLastRestartedAt = safeMoment.iso8601Parse(lastRestartedAt).valueOf();

    const timeoutId = setInterval(() => {
      const current = moment().valueOf();
      const secondsLeft = restartWhatsappServerPeriodSeconds - (current - mLastRestartedAt) / 1000;
      setRepublishSecondsLeft(secondsLeft);
      if (secondsLeft <= 0) {
        setDisableRepublishButton(false);
        clearInterval(timeoutId);
      }
    }, 1000);

    return () => {
      clearInterval(timeoutId);
    };
  }, [lastRestartedAt]);

  function toggleCard() {
    setIsOpen(!isOpen);
  }

  async function handleRestart(whatsappServerId: number) {
    setRepublishProcessing(true);
    await onRestart(whatsappServerId);
    setRepublishProcessing(false);
    setDisableRepublishButton(true);
  }

  const buttonClassNames = cn('boss-board__switch', { 'boss-board__switch_state_opened': isOpen });

  return (
    <section className="boss-board boss-board_role_devices-venue boss-board_context_stack">
      <header className="boss-board__header">
        <h2
          className="boss-board__title"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FaSquareWhatsapp />
          <span
            className="boss-board__title-text"
            style={{ paddingLeft: '10px' }}
          >{name}</span>
          <span style={{ color: '#808080', fontSize: '14px' }}>&nbsp;({baseUrl})</span>
        </h2>
        <div
          className="whatsapp-services-actions"
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {!disableRepublishButton && (
            <button
              className="boss-button boss-button_type_small boss-button_role_reload"
              disabled={republishProcessing || disableRepublishButton}
              onClick={() => handleRestart(whatsappServer.id)}
              style={{
                marginRight: '10px',
                backgroundColor: '#ed7f7e',
                color: '#fff'
              }}
            >{republishProcessing ? 'Processing ...' : 'Restart Server'}</button>
          )}
          {disableRepublishButton && (
            <Tooltip
              arrow
              theme="dark"
              position="bottom"
              html={
                <span>
                  Can be republished in{' '}
                  {moment.duration(republishSecondsLeft * 1000).format('m [minutes] s [seconds]')}
                </span>
              }
            >
              <button
                className="boss-button boss-button_type_small boss-button_role_reload"
                disabled={republishProcessing || disableRepublishButton}
                onClick={() => handleRestart(whatsappServer.id)}
                style={{
                  marginRight: '10px',
                  backgroundColor: '#ed7f7e',
                  color: '#fff'
                }}
              >{republishProcessing ? 'Processing ...' : 'Restart Server'}</button>
            </Tooltip>
          )}

          <button
            type="button"
            onClick={() => onAddNewInstance(whatsappServer)}
            className="boss-button boss-button_type_small boss-button_role_add"
          >Add Instance</button>
        </div>
        <div className="boss-board__button-group">
          <button
            onClick={toggleCard}
            type="button"
            className={buttonClassNames}
          />
        </div>
      </header>
      <Collapse
        isOpened={isOpen}
        className="boss-board__content boss-board__content_state_opened"
        style={{ display: 'block' }}
      >
        <div className={'boss-board__content-inner'}>{children}</div>
      </Collapse>
    </section>
  );
}
