import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { CollapsibleCard } from '@/components/hooks-components';

export default function QuizRanks(props) {
  const quizRanks = oFetch(props, 'quizRanks');

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Quiz Ranks</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {quizRanks.map((quizRank) => {
          const [
            id,
            name,
            multiplier,
            promotionThreshold,
            retentionThreshold,
            flavourText,
            explanationText,
          ] = oFetch(
            quizRank,
            'id',
            'name',
            'multiplier',
            'promotionThreshold',
            'retentionThreshold',
            'flavourText',
            'explanationText',
          );
          return (
            <CollapsibleCard
              key={id}
              className="boss-check boss-check_role_board boss-check_page_quiz-rank"
              title={name}
            >
              <div className="boss-check__group-content">
                <div className="boss-overview">
                  <div className="boss-overview__group">
                    <h4 className="boss-overview__label">
                      <span className="boss-overview__label-text">Exp Multiplier</span>
                    </h4>
                    <p className="boss-overview__text">{multiplier}</p>
                  </div>
                  <div className="boss-overview__group">
                    <h4 className="boss-overview__label">
                      <span className="boss-overview__label-text">
                        Promotion Threshold (Number of quiz passes required rank up)
                      </span>
                    </h4>
                    <p className="boss-overview__text">{promotionThreshold}</p>
                  </div>
                  <div className="boss-overview__group">
                    <h4 className="boss-overview__label">
                      <span className="boss-overview__label-text">
                        Retention Threshold (Number of quiz passes required to not rank down)
                      </span>
                    </h4>
                    <p className="boss-overview__text">{retentionThreshold}</p>
                  </div>
                  <div className="boss-overview__group">
                    <h4 className="boss-overview__label">
                      <span className="boss-overview__label-text">Flavour Text</span>
                    </h4>
                    <p className="boss-overview__text">{flavourText}</p>
                  </div>
                  <div className="boss-overview__group boss-overview__group_position_last">
                    <h4 className="boss-overview__label">
                      <span className="boss-overview__label-text">Explanation Text</span>
                    </h4>
                    <p className="boss-overview__text">{explanationText}</p>
                  </div>
                </div>
              </div>
            </CollapsibleCard>
          );
        })}
      </ContentWrapper>
    </Fragment>
  );
}
