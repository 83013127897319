import React from 'react';
import oFetch from 'o-fetch';

export function SecurityShiftRequestsVenueList(props) {
  const [grouppedList, itemRender] = oFetch(props, 'grouppedList', 'itemRender');

  function renderList() {
    return Object.entries(grouppedList).map(entry => {
      const [venueName, items] = entry;
      return React.cloneElement(itemRender({ venueName, items }), {
        key: venueName,
      });
    });
  }

  return (
    <div className="boss-board__inner">
      <div className="boss-board__cards">{renderList()}</div>
    </div>
  );
}
