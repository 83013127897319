import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export function BonusPaymentsList(props) {
  const [payments, renderPayment] = oFetch(props, 'payments', 'renderPayment');
  const paymentsExist = payments.length > 0;

  if (!paymentsExist) {
    return <h3>No Pending Payments exist</h3>;
  }

  return (
    <Fragment>
      {payments.map((payment, index) => {
        return React.cloneElement(renderPayment(payment), { key: index });
      })}
    </Fragment>
  );
}
