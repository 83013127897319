import React from 'react';
import oFetch from 'o-fetch';
import DisableStaffMemberFormRedux from './disable-staff-member-form';

export function DisableStaffMemberModal(props) {
  const [onSubmit, accessories, initialValues] = oFetch(props, 'onSubmit', 'accessories', 'initialValues');

  return (
    <DisableStaffMemberFormRedux
      submission={onSubmit}
      accessories={accessories}
      initialValues={initialValues}
    />
  );
}
