import React, { Fragment, useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function NoteCollapsible(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [note, createdAt] = oFetch(props, 'note', 'createdAt');

  function toggle() {
    setIsOpen(!isOpen);
  }

  const buttonClassNames = cn('boss-table__dropdown-switch', {
    'boss-table__dropdown-switch_state_closed': isOpen,
  });

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatWithTime());

  return (
    <Fragment>
      <div className="boss-table__note">
        <p className="boss-table__text">
          <span className="boss-table__text-marked">Note:</span>
          <span className="boss-table__text-part">{note || 'N/A'}</span>
          <span className="boss-table__text-action">
            <button onClick={toggle} type="button" className={buttonClassNames} />
          </span>
        </p>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="boss-table__dropdown" style={{ display: 'block' }}>
          <div className="boss-table__meta">
            <div className="boss-table__meta-item">
              <p className="boss-table__text">
                <span className="boss-table__text-label">Created at: </span>
                <span className="boss-table__text-marked">{formattedCreatedAt}</span>
              </p>
            </div>
          </div>
        </div>
      </Collapse>
    </Fragment>
  );
}
