import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { z } from 'zod';
import { $BossUiDate } from '@/lib/schema-funcs';
import { SHORT_SLASH_DATE_FORMAT } from '@/lib/date-fns-formats';

import { clockingStatuses } from '../../../redux/constants';
import { BOSS_APP_TYPE, MOSS_APP_TYPE } from '@/lib/rota-date';

import { format } from 'date-fns';

export default function LeftSide(props) {
  const [clockingDay, pageType] = oFetch(props, 'clockingDay', 'pageType');
  const [effectiveStaffMember, venue] = oFetch(clockingDay, 'effectiveStaffMember', 'venue');
  const venueName = oFetch(venue, 'name');
  const [
    formattedClockedTime,
    formattedAcceptedHoursAcceptanceTime,
    formattedRotaedTime,
    profileUrl,
  ] = oFetch(
    clockingDay,
    'formattedClockedTime',
    'formattedAcceptedHoursAcceptanceTime',
    'formattedRotaedTime',
    'profileUrl',
  );

  const [fullName, avatarUrl, effectiveStaffTypeData] = oFetch(
    effectiveStaffMember,
    'fullName',
    'avatarUrl',
    'effectiveStaffTypeData',
  );
  const appType = oFetch(effectiveStaffMember, 'appType');
  let formattedAcceptedTimeDiff = null;
  if (appType === BOSS_APP_TYPE) {
    formattedAcceptedTimeDiff = oFetch(clockingDay, 'formattedAcceptedHoursAcceptanceTimeLeft');
  } else if (appType === MOSS_APP_TYPE) {
    formattedAcceptedTimeDiff = oFetch(clockingDay, 'formattedAcceptedVsClockedTimeDiff');
  } else {
    throw new Error(`unsupported appType encountered: ${appType}`);
  }

  const clockingStatus = oFetch(clockingDay, 'status');
  const [effectiveStaffTypeName, effectiveStaffTypeColor] = oFetch(effectiveStaffTypeData, 'name', 'color');

  const clockingStatusClassNames = cn('boss-user-badge__avatar-icon', {
    'boss-user-badge__avatar-icon_status_clocked-in':
      clockingStatus === clockingStatuses.CLOCKED_IN_STATUS,
  });
  const sDate = oFetch(clockingDay, 'date');
  const fullNameUrl =  profileUrl({ effectiveStaffMember, date: sDate });
  const shortDateText = format($BossUiDate(z).parse(sDate), SHORT_SLASH_DATE_FORMAT);

  return (
    <div className="boss-hrc__side">
      <div className="boss-hrc__user-info">
        <div className="boss-user-badge">
          <div className='boss-user-badge__profile'>
            <div className="boss-user-badge__avatar">
              <div className="boss-user-badge__avatar-inner">
                <img
                  src={avatarUrl}
                  alt="Hugo Lambert"
                  className="boss-user-badge__pic"
                />
              </div>
              <span className={clockingStatusClassNames}>Status</span>
            </div>
            <div className="boss-user-badge__info">
              <h2 className="boss-user-badge__name">
                <a href={fullNameUrl}>{fullName}</a>
              </h2>
              <span
                className="boss-button boss-button_type_small boss-user-badge__label"
                style={{ backgroundColor: effectiveStaffTypeColor }}
              >
                {effectiveStaffTypeName.toUpperCase()}
              </span>
              { pageType !== "overview" && <p className="boss-user-badge__date">{shortDateText}</p> }
              { pageType === "overview" && <p className="boss-user-badge__venue">{venueName}</p> }
            </div>
          </div>
          <ul className="boss-user-badge__meta">
            <li className="boss-user-badge__meta-item">
              <p className="boss-user-badge__time boss-user-badge__time_role_rotaed">
                <span className="boss-user-badge__time-value">{formattedRotaedTime}</span>
              </p>
              <p className="boss-user-badge__time-label">Rotaed</p>
            </li>
            <li className="boss-user-badge__meta-item">
              <p className="boss-user-badge__time boss-user-badge__time_role_clocked">
                <span className="boss-user-badge__time-value">{formattedClockedTime}</span>
              </p>
              <p className="boss-user-badge__time-label">Clocked</p>
            </li>
            <li className="boss-user-badge__meta-item">
              <div className="boss-user-badge__time boss-user-badge__time_role_accepted">
                <div className="boss-user-badge__time-value">
                  {formattedAcceptedHoursAcceptanceTime}
                </div>
                {formattedAcceptedTimeDiff && (
                  <span className="boss-user-badge__time-note">
                    <span className="boss-user-badge__time-sign">
                      {formattedAcceptedTimeDiff}
                    </span>
                  </span>
                )}
              </div>
              <p className="boss-user-badge__time-label">Accepted</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
