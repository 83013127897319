import React from 'react';
import oFetch from 'o-fetch';

export function UsersList(props) {
  const [items, renderItem, renderMobileItem] = oFetch(props, 'items', 'renderItem', 'renderMobileItem');
  const itemsExist = items.length > 0;

  if (!itemsExist) {
    return (
      <div className="boss-page-main__group boss-page-main__group_adjust_ssr-requests">
        <div className="boss-page-main__text-placeholder">There are no users to show.</div>
      </div>
    );
  }

  function renderItems() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(renderItem(item), {
        key: itemId,
      });
    });
  }

  function renderMobileItems() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(renderMobileItem(item), {
        key: itemId,
      });
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_users-table">
      <div className="boss-table boss-table_page_users-index">
        <div className="boss-table__row">
          <div className="boss-table__cell boss-table__cell_role_header">Name</div>
          <div className="boss-table__cell boss-table__cell_role_header">Apps</div>
          <div className="boss-table__cell boss-table__cell_role_header">Status</div>
          <div className="boss-table__cell boss-table__cell_role_header">Email</div>
          <div className="boss-table__cell boss-table__cell_role_header">Role</div>
        </div>
        {renderItems()}
      </div>
      {renderMobileItems()}
    </div>
  );
}
