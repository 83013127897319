import React, { Component } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';

import { SimpleDashboard, DashboardActions } from '@/components/boss-dashboards';
import utils from '@/lib/utils';
import ContentWrapper from '@/components/content-wrapper';
import { openWarningModal, openContentModal, openCustomContentModal } from '@/components/modals';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';
import {
  EventItem,
  ReminderDateItem,
  TaskItem,
  SortableList,
  WeekDayFilter,
  EditEvent,
  EditReminder,
  List,
  AddTask,
  DeclineTask,
  PostponeTask,
  PostponeSuccess,
  DeleteRecurringReminder,
} from './components';
import { Calendar, CalendarBlankItem, CalendarDayItem, CalendarToolbar, AddEvent, AddReminder } from '../components';

export class HandoverPlannersWeek extends Component {
  handleCreateEvent = (closeModal, values) => {
    const handleCreateEvent = oFetch(this.props, 'handleCreateEvent');
    return handleCreateEvent(values).then(closeModal);
  };

  handleCreateReminder = (closeModal, values) => {
    values = {
      ...values,
      responseDateRangeEnd: oFetch(this.props, 'viewEndDate'),
      responseDateRangeStart: oFetch(this.props, 'viewStartDate'),
    };

    const handleCreateReminder = oFetch(this.props, 'handleCreateReminder');
    return handleCreateReminder(values).then(closeModal);
  };

  openCreateReminderModal = () => {
    const [selectedDate, recurrenceTypesOptions] = oFetch(this.props, 'selectedDate', 'recurrenceTypesOptions');
    openContentModal({
      submit: this.handleCreateReminder,
      config: { title: 'Create Reminder' },
      props: { currentDate: selectedDate, recurrenceTypesOptions, showDateField: false },
    })(AddReminder);
  };

  openCreateEventModal = () => {
    const [eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType, selectedDate] = oFetch(
      this.props,
      'eventTypesOptions',
      'venueOptions',
      'currentVenue',
      'globalEventType',
      'localEventType',
      'selectedDate',
    );
    openContentModal({
      submit: this.handleCreateEvent,
      config: { title: 'Create Event' },
      props: { selectedDate, eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType },
    })(AddEvent);
  };

  renderDay = day => {
    const [eventsCountByDate, remindersCountDataByDate, tasksCountDataByDate, currentVenueId] = oFetch(
      this.props,
      'eventsCountByDate',
      'remindersCountDataByDate',
      'tasksCountDataByDate',
      'currentVenue.id',
    );

    const formattedDay = day.format(utils.commonDateFormat);
    const formattedCurrentDay = moment().format(utils.commonDateFormat);
    const isCurrentDay = formattedCurrentDay === formattedDay;
    const eventsCount = eventsCountByDate[formattedDay] || 0;
    const remindersCountData = remindersCountDataByDate[formattedDay] || { total: 0 };
    const tasksCountData = tasksCountDataByDate[formattedDay] || { total: 0 };
    const href = appRoutes.handoverPlannersWeekView({ mDate: day, venueId: currentVenueId });

    return (
      <CalendarDayItem
        day={day}
        showDay={false}
        isCurrentDay={isCurrentDay}
        eventsCount={eventsCount}
        remindersCountData={remindersCountData}
        tasksCountData={tasksCountData}
        href={href}
      />
    );
  };

  handleDeleteEvent = (closeModal, params) => {
    const handleDeleteEvent = oFetch(this.props, 'handleDeleteEvent');
    return handleDeleteEvent(params).then(() => {
      closeModal();
    });
  };

  openDeleteEventModal = event => {
    const id = oFetch(event, 'id');
    openWarningModal({
      submit: this.handleDeleteEvent,
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Delete',
      },
      props: { id },
    });
  };

  handleDeleteReminder = (closeModal, params) => {
    const [handleDeleteReminder, viewStartDate, viewEndDate] = oFetch(
      this.props,
      'handleDeleteReminder',
      'viewStartDate',
      'viewEndDate',
    );
    return handleDeleteReminder({ ...params, viewStartDate, viewEndDate }).then(() => {
      closeModal();
    });
  };

  handleDeleteRecurringFutureReminders = (closeModal, params) => {
    const [handleDeleteRecurringFutureReminders, viewStartDate, viewEndDate] = oFetch(
      this.props,
      'handleDeleteRecurringFutureReminders',
      'viewStartDate',
      'viewEndDate',
    );
    return handleDeleteRecurringFutureReminders({ ...params, viewStartDate, viewEndDate }).then(() => {
      closeModal();
    });
  };

  handleDeleteRecurringSingleReminders = (closeModal, params) => {
    const [handleDeleteRecurringSingleReminders, viewStartDate, viewEndDate] = oFetch(
      this.props,
      'handleDeleteRecurringSingleReminders',
      'viewStartDate',
      'viewEndDate',
    );
    return handleDeleteRecurringSingleReminders({ ...params, viewStartDate, viewEndDate }).then(() => {
      closeModal();
    });
  };

  handleDeleteRecurringAllReminders = (closeModal, params) => {
    const [handleDeleteRecurringAllReminders, viewStartDate, viewEndDate] = oFetch(
      this.props,
      'handleDeleteRecurringAllReminders',
      'viewStartDate',
      'viewEndDate',
    );
    return handleDeleteRecurringAllReminders({ ...params, viewStartDate, viewEndDate }).then(() => {
      closeModal();
    });
  };

  openDeleteReminderModal = params => {
    const reminderDate = oFetch(params, 'reminderDate');
    const selectedDate = oFetch(this.props, 'selectedDate');

    const isSingleType = oFetch(reminderDate, 'isSingleType');
    if (isSingleType) {
      const reminderId = oFetch(reminderDate, 'handoverPlannerReminderId');
      openWarningModal({
        submit: this.handleDeleteReminder,
        config: {
          title: 'WARNING !!!',
          text: 'Are You Sure?',
          buttonText: 'Delete',
        },
        props: {
          date: selectedDate,
          reminderId: reminderId,
        },
      });
    } else {
      openCustomContentModal({
        submit: this.handleDeleteReminder,
        config: {
          modalClass: 'boss-modal-window boss-modal-window_role_danger',
          titleClass: 'boss-modal-window__header_warning',
          title: 'WARNING !!!',
          innerClose: false,
        },
        props: {
          reminderDate,
          selectedDate,
          onDeleteFuture: this.handleDeleteRecurringFutureReminders,
          onDeleteSingle: this.handleDeleteRecurringSingleReminders,
          onDeleteAll: this.handleDeleteRecurringAllReminders,
        },
      })(DeleteRecurringReminder);
    }
  };

  handleDeleteTask = (closeModal, values) => {
    const handleDeleteTask = oFetch(this.props, 'handleDeleteTask');
    return handleDeleteTask(values).then(() => {
      closeModal();
    });
  };
  handleDeclineTask = (closeModal, values) => {
    const handleDeclineTask = oFetch(this.props, 'handleDeclineTask');
    return handleDeclineTask(values).then(() => {
      closeModal();
    });
  };
  handlePostponeTask = (closeModal, values) => {
    const handlePostponeTask = oFetch(this.props, 'handlePostponeTask');
    return handlePostponeTask(values).then(() => {
      closeModal();
      const date = oFetch(values, 'date');
      const venueId = oFetch(this.props, 'currentVenue.id');
      this.openPostponeSuccessModal({ postponeDate: date, venueId });
    });
  };

  openDeclineTaskModal = (task, canDelete) => {
    const id = oFetch(task, 'id');
    openCustomContentModal({
      submit: this.handleDeclineTask,
      config: {
        modalClass: 'boss-modal-window_role_confirm',
        titleClass: 'boss-modal-window__header_warning',
        title: 'REASON REQUIRED',
        innerClose: false,
      },
      props: {
        id,
        message: 'Please provide a reason for declining this task',
        deleteButtonText: 'Delete Task',
        showDeleteButton: canDelete,
        onDelete: this.handleDeleteTask,
      },
    })(DeclineTask);
  };

  openPostponeSuccessModal = params => {
    const [postponeDate, venueId] = oFetch(params, 'postponeDate', 'venueId');
    openCustomContentModal({
      submit: closeModal => closeModal(),
      config: {
        modalClass: 'boss-modal-window_role_success',
        title: 'SUCCESS!',
        innerClose: false,
      },
      props: {
        postponeDate,
        venueId,
      },
    })(PostponeSuccess);
  };

  openPostponeTaskModal = task => {
    const id = oFetch(task, 'id');
    openCustomContentModal({
      submit: this.handlePostponeTask,
      config: {
        modalClass: 'boss-modal-window_role_edit',
        title: 'Postpone Task',
      },
      props: {
        id,
      },
    })(PostponeTask);
  };

  handleEditEvent = (closeModal, values) => {
    const handleEditEvent = oFetch(this.props, 'handleEditEvent');
    return handleEditEvent(values).then(closeModal);
  };

  handleEditReminder = (closeModal, values) => {
    values = {
      ...values,
      responseDateRangeEnd: oFetch(this.props, 'viewEndDate'),
      responseDateRangeStart: oFetch(this.props, 'viewStartDate'),
    };
    const handleEditReminder = oFetch(this.props, 'handleEditReminder');
    return handleEditReminder(values).then(closeModal);
  };

  openEditEventModal = event => {
    const [eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType] = oFetch(
      this.props,
      'eventTypesOptions',
      'venueOptions',
      'currentVenue',
      'globalEventType',
      'localEventType',
    );
    openContentModal({
      submit: this.handleEditEvent,
      config: { title: 'Edit Event' },
      props: { event, eventTypesOptions, venueOptions, currentVenue, globalEventType, localEventType },
    })(EditEvent);
  };

  openEditReminderModal = reminderDate => {
    const recurrenceTypesOptions = oFetch(this.props, 'recurrenceTypesOptions');
    openContentModal({
      submit: this.handleEditReminder,
      config: { title: 'Edit Reminder' },
      props: { reminderDate, recurrenceTypesOptions },
    })(EditReminder);
  };

  renderToolbar = props => {
    const [viewStartDate, venueId] = oFetch(this.props, 'viewStartDate', 'currentVenue.id');
    const mViewStartDate = safeMoment.uiDateParse(viewStartDate);
    const rotaDate = RotaDate.fromTime({
      dTime: new Date(),
      appType: BOSS_APP_TYPE,
    });
    const mTodayDate = moment(rotaDate.calendarDate());

    return (
      <CalendarToolbar
        prevHref={appRoutes.handoverPlannersWeekPrev({ mDate: mViewStartDate, venueId })}
        nextHref={appRoutes.handoverPlannersWeekNext({ mDate: mViewStartDate, venueId })}
        todayHref={appRoutes.handoverPlannersWeekView({ mDate: mTodayDate, venueId })}
        monthHref={appRoutes.handoverPlannersMonthView({ mDate: mViewStartDate, venueId })}
        {...props}
        mTodayDate={mTodayDate}
      />
    );
  };

  renderFilterWeekDay = day => {
    const [eventsCountByDate, remindersCountDataByDate, tasksCountDataByDate, selectedDate, venueId] = oFetch(
      this.props,
      'eventsCountByDate',
      'remindersCountDataByDate',
      'tasksCountDataByDate',
      'selectedDate',
      'currentVenue.id',
    );
    const formattedDay = day.format(utils.commonDateFormat);

    const eventsCount = eventsCountByDate[formattedDay] || 0;
    const remindersCountData = remindersCountDataByDate[formattedDay] || { total: 0 };
    const reminderTotal = oFetch(remindersCountData, 'total');
    const tasksCount = tasksCountDataByDate[formattedDay] || { total: 0 };
    const totalCount = eventsCount + reminderTotal + oFetch(tasksCount, 'total');
    const isDayChecked = formattedDay === selectedDate;
    return (
      <a href={appRoutes.handoverPlannersWeekView({ mDate: day, venueId })} className="boss-form__switcher-label">
        <input
          type="radio"
          name="display"
          value="monday"
          defaultChecked={isDayChecked}
          className="boss-form__switcher-radio"
        />
        <span className="boss-form__switcher-label-text">
          {day.format('dddd')} ({totalCount})
        </span>
      </a>
    );
  };

  onReminderCheckboxClick = params => {
    const [selectedDate, viewStartDate, viewEndDate] = oFetch(
      this.props,
      'selectedDate',
      'viewStartDate',
      'viewEndDate',
    );
    const [checked, id] = oFetch(params, 'checked', 'id');
    const [handleCompleteReminder, handleActivateReminder] = oFetch(
      this.props,
      'handleCompleteReminder',
      'handleActivateReminder',
    );
    if (checked === true) {
      return handleCompleteReminder({
        id,
        selectedDate,
        responseDateRangeEnd: viewEndDate,
        responseDateRangeStart: viewStartDate,
      });
    } else {
      return handleActivateReminder({
        id,
        selectedDate,
        responseDateRangeStart: viewStartDate,
        responseDateRangeEnd: viewEndDate,
      });
    }
  };

  onTaskCheckboxClick = params => {
    const [checked, id] = oFetch(params, 'checked', 'id');
    const date = oFetch(this.props, 'selectedDate');
    const [handleCompleteTask, handleActivateTask] = oFetch(this.props, 'handleCompleteTask', 'handleActivateTask');
    if (checked === true) {
      return handleCompleteTask({ id, date });
    } else {
      return handleActivateTask({ id, date });
    }
  };

  render() {
    const [
      selectedDate,
      items,
      handleSort,
      inactiveItems,
      handleCreateTask,
      handleEditTask,
      permissions,
      currentVenue,
      viewStartDate,
      viewEndDate,
    ] = oFetch(
      this.props,
      'selectedDate',
      'items',
      'handleSort',
      'inactiveItems',
      'handleCreateTask',
      'handleEditTask',
      'permissions',
      'currentVenue',
      'viewStartDate',
      'viewEndDate',
    );
    const mSelectedDate = safeMoment.uiDateParse(selectedDate);
    const [
      canCreateEvent,
      canCreateReminder,
      canCreateTask,
      eventsPermissions,
      remindersPermissions,
      tasksPermissions,
    ] = oFetch(permissions, 'event.canCreate', 'reminder.canCreate', 'task.canCreate', 'events', 'reminders', 'tasks');

    return (
      <div>
        <SimpleDashboard className="boss-page-dashboard_page_handover-planner" title="Handover & Planner">
          <DashboardActions>
            {canCreateEvent && (
              <button onClick={this.openCreateEventModal} className="boss-button boss-button_role_add">
                Add Event
              </button>
            )}
            {canCreateReminder && (
              <button onClick={this.openCreateReminderModal} className="boss-button boss-button_role_add">
                Add Reminder
              </button>
            )}
          </DashboardActions>
        </SimpleDashboard>
        <ContentWrapper>
          <div className="boss-page-main__group boss-page-main__group_adjust_hp-calendar boss-page-main__group_context_stack">
            <Calendar
              date={mSelectedDate}
              toolbarRenderer={this.renderToolbar}
              dayRenderer={this.renderDay}
              blankRenderer={day => <CalendarBlankItem day={day} />}
              defaultView="week"
            />
          </div>

          <div className="boss-page-main__group boss-page-main__group_adjust_hp-cards boss-page-main__group_context_stack">
            <WeekDayFilter renderDay={this.renderFilterWeekDay} date={mSelectedDate} />
            {canCreateTask && <AddTask date={selectedDate} onSubmit={handleCreateTask} />}
            <SortableList
              onChange={handleSort}
              items={items}
              eventRenderer={item => (
                <EventItem
                  onEditClick={this.openEditEventModal}
                  onDeleteClick={this.openDeleteEventModal}
                  permissions={eventsPermissions}
                  event={item}
                />
              )}
              reminderRenderer={item => (
                <ReminderDateItem
                  onCheckboxClick={this.onReminderCheckboxClick}
                  onDeleteClick={this.openDeleteReminderModal}
                  onEditClick={this.openEditReminderModal}
                  permissions={remindersPermissions}
                  reminderDate={item}
                />
              )}
              taskRenderer={item => (
                <TaskItem
                  onDeclineClick={this.openDeclineTaskModal}
                  onPostponeClick={this.openPostponeTaskModal}
                  onCheckboxClick={this.onTaskCheckboxClick}
                  onUpdate={handleEditTask}
                  permissions={tasksPermissions}
                  task={item}
                  currentVenue={currentVenue}
                />
              )}
            />
            <List
              onChange={handleSort}
              items={inactiveItems}
              eventRenderer={item => <EventItem onEditClick={this.openEditEventModal} event={item} />}
              reminderRenderer={item => (
                <ReminderDateItem
                  viewStartDate={viewStartDate}
                  viewEndDate={viewEndDate}
                  onCheckboxClick={this.onReminderCheckboxClick}
                  reminderDate={item}
                />
              )}
              taskRenderer={item => (
                <TaskItem currentVenue={currentVenue} onCheckboxClick={this.onTaskCheckboxClick} task={item} />
              )}
            />
          </div>
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  eventTypesOptions: selectors.getEventTypesOptions(state),
  venueOptions: selectors.venuesSelector(state),
  currentVenue: selectors.currentVenueSelector(state),
  globalEventType: selectors.globalEventTypeSelector(state),
  localEventType: selectors.localEventTypeSelector(state),
  eventsCountByDate: selectors.getEventsCount(state),
  remindersCountDataByDate: selectors.getRemindersCountDataByDate(state),
  tasksCountDataByDate: selectors.getTasksCountDataByDate(state),
  selectedDate: selectors.selectedDateSelector(state),
  items: selectors.getActiveHandoverPlannerItems(state),
  inactiveItems: selectors.getInactiveHandoverPlannerItems(state),
  permissions: selectors.permissionsSelector(state),
  viewStartDate: selectors.viewStartDateSelector(state),
  viewEndDate: selectors.viewEndDateSelector(state),
  singleRecurringType: selectors.singleRecurringTypeSelector(state),
  recurrenceTypesOptions: selectors.getRecurrenceTypesOptions(state),
});

const mapDispatchToProps = {
  handleCreateTask: actions.createTask,
  handleEditTask: actions.editTask,
  handleCreateEvent: actions.createEvent,
  handleDeleteEvent: actions.deleteEvent,
  handleDeleteReminder: actions.deleteReminder,
  handleDeleteTask: actions.deleteTask,
  handleDeclineTask: actions.declineTask,
  handlePostponeTask: actions.postponeTask,
  handleEditEvent: actions.editEvent,
  handleCreateReminder: actions.createReminder,
  handleEditReminder: actions.editReminder,
  handleCompleteReminder: actions.completeReminder,
  handleActivateReminder: actions.activateReminder,
  handleCompleteTask: actions.completeTask,
  handleActivateTask: actions.activateTask,
  handleSort: actions.sortAction,
  handleDeleteRecurringFutureReminders: actions.deleteRecurringFutureReminders,
  handleDeleteRecurringSingleReminders: actions.deleteRecurringSingleReminders,
  handleDeleteRecurringAllReminders: actions.deleteRecurringAllReminders,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandoverPlannersWeek);
