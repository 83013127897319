import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { isFunction } from '../utils';

export default function AasyncButton(props) {
  const [onClick, className, loadingText, children] = oFetch(
    props,
    'onClick',
    'className',
    'loadingText',
    'children',
  );
  const asyncOnClick = useAsyncCallback(onClick);
  const loading = asyncOnClick.loading;

  const { loadingClassName } = props;
  const buttonClassNames = cn(className, { [loadingClassName]: loading });

  function renderChildren() {
    if (isFunction(children)) {
      return children({ loading, loadingText });
    } else {
      return loading ? loadingText : children;
    }
  }

  return (
    <button
      className={buttonClassNames}
      type="button"
      onClick={asyncOnClick.execute}
      disabled={loading}
    >
      {renderChildren()}
    </button>
  );
}
