import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class OvertimeReasons extends Component {
  renderReasonItem = reason => {
    const [formattedAcceptedTime, reasonNote, formattedAcceptedAt, acceptedBy] = oFetch(
      reason,
      'formattedAcceptedTime',
      'reasonNote',
      'formattedAcceptedAt',
      'acceptedBy',
    );
    return (
      <li className="boss-notes__item">
        <p className="boss-notes__text">
          Accepted <span className="boss-notes__text-bold">{formattedAcceptedTime}</span> by{' '}
          <span className="boss-notes__text-bold">{acceptedBy}</span> on{' '}
          <span className="boss-notes__text-bold">{formattedAcceptedAt}</span>
        </p>
        <p className="boss-notes__text">
          Reason: <span className="boss-notes__text-light">{reasonNote}</span>
        </p>
      </li>
    );
  };

  renderReasons = overtimeReasons => {
    return overtimeReasons.map((overtimeReason, index) => {
      return React.cloneElement(this.renderReasonItem(overtimeReason), {
        key: index,
      });
    });
  };
  render() {
    const overtimeReasons = oFetch(this.props, 'overtimeReasons');
    return (
      <div className="boss-hrc__reasons">
        <div className="boss-notes boss-notes_role_overtime-reasons">
          <h4 className="boss-notes__label">Overtime Reasons</h4>
          <div className="boss-notes__content">
            <div className="boss-notes__content-inner">
              <ul className="boss-notes__list">{this.renderReasons(overtimeReasons)}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
