import React from 'react';
import oFetch from 'o-fetch';
import uuid from 'uuid/v1';
import { RotaDate, incrementHours, rotaTimesToCalendarTime, END_TIME_TYPE, START_TIME_TYPE, getHoursBetween } from '@/lib/rota-date';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

import {
  LeftSide,
  RightSide,
} from './components';

export default function ClockingDayItem(props) {
  const [
    clockingDay,
    onClockOut,
    onUnaccept,
    onAddNewHoursAcceptancePeriod,
    onAccept,
    onUndoHoursAcceptancePeriod,
    pageType,
    activeSpecialSecurityPayrates,
  ] = oFetch(
    props,
    'clockingDay',
    'onClockOut',
    'onUnaccept',
    'onAddNewHoursAcceptancePeriod',
    'onAccept',
    'onUndoHoursAcceptancePeriod',
    'pageType',
    'activeSpecialSecurityPayrates',
  );
  const currentDay = oFetch(props, 'currentDay');
  const mossHourTags = oFetch(props, 'mossHourTags');
  const permissions = oFetch(props, 'permissions');
  const onDeleteHoursAcceptancePeriod = oFetch(props, 'onDeleteHoursAcceptancePeriod');
  const [
    venue,
    status,
    formattedDate,
    hoursAcceptancePeriods,
    date,
    mDate,
  ] = oFetch(
    clockingDay,
    'venue',
    'status',
    'formattedDate',
    'hoursAcceptancePeriods',
    'date',
    'mDate',
  );
  const venueId = oFetch(venue, 'id');

  const effectiveStaffMember = oFetch(clockingDay, 'effectiveStaffMember');
  const [effectiveStaffMemberId, appType] = oFetch(effectiveStaffMember, 'id', 'appType');

  const rotaDate = RotaDate.fromDate({
    dCalendarDate: mDate.toDate(),
    appType,
  });

  function getNewHoursDefaultTimes() {
    if (hoursAcceptancePeriods.length === 0) {
      return {
        startsAt: rotaTimesToCalendarTime({
          hours: 9,
          minutes: 0,
          timeType: START_TIME_TYPE,
          rotaDate,
        }),
        endsAt: rotaTimesToCalendarTime({
          hours: 10,
          minutes: 0,
          timeType: END_TIME_TYPE,
          rotaDate,
        }),
      };
    }

    const lastExitingHours = hoursAcceptancePeriods[hoursAcceptancePeriods.length - 1];

    const previousShiftHoursOffset = getHoursBetween({
      dStartTime: rotaDate.startTime(),
      dEndTime: safeMoment.iso8601Parse(lastExitingHours.endsAt).toDate(),
    });

    let newHoursStartOffset = previousShiftHoursOffset + 1;
    let newHoursEndOffset = newHoursStartOffset + 1;

    newHoursStartOffset = utils.containNumberWithinRange(newHoursStartOffset, [0, 23]);

    newHoursEndOffset = utils.containNumberWithinRange(newHoursEndOffset, [0, 23]);

    return {
      startsAt: incrementHours({
        dTime: rotaDate.startTime(),
        hours: newHoursStartOffset,
        minutes: 0,
      }),
      endsAt: incrementHours({
        dTime: rotaDate.startTime(),
        hours: newHoursEndOffset,
        minutes: 0,
      }),
    };
  }

  function handleAddHoursAcceptancePeriod() {
    const newStartsEndsTime = getNewHoursDefaultTimes();
    const newHoursAcceptancePeriod = {
      date,
      effectiveStaffMemberId,
      appType,
      newStartsEndsTime,
      bypassTimeLimits: false,
      frontendId: uuid(),
      frozen: false,
      venueId: venueId,
    };
    return onAddNewHoursAcceptancePeriod(newHoursAcceptancePeriod);
  }

  return (
    <div className="boss-hrc boss-hrc_context_stack boss-hrc_updated">
      <LeftSide
        pageType={pageType}
        clockingDay={clockingDay}
      />
      <RightSide
        currentDay={currentDay}
        status={status}
        date={formattedDate}
        pageType={pageType}
        permissions={permissions}
        clockingDay={clockingDay}
        onClockOut={onClockOut}
        onAccept={onAccept}
        onUnaccept={onUnaccept}
        handleAddHoursAcceptancePeriod={handleAddHoursAcceptancePeriod}
        onUndoHoursAcceptancePeriod={onUndoHoursAcceptancePeriod}
        activeSpecialSecurityPayrates={activeSpecialSecurityPayrates}
        mossHourTags={mossHourTags}
        onDeleteHoursAcceptancePeriod={onDeleteHoursAcceptancePeriod}
      />
    </div>
  );
}
