import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { RekognitionDashboard, ImageUrlForm, Result, PhotoForm } from './components';
import { faceIdLookup, faceIdLookupByBase64 } from './api';

export function RekognitionTest(props) {
  const [result, setResult] = useState(null);
  const imageUrl = oFetch(props, 'imageUrl');

  function handleSuccess(data) {
    setResult(data);
  }

  function handleSubmit(values) {
    setResult(null);
    return faceIdLookup({ values, onSuccess: handleSuccess });
  }

  function handleBase64Submit(values) {
    setResult(null);
    return faceIdLookupByBase64({ values, onSuccess: handleSuccess });
  }

  return (
    <Fragment>
      <RekognitionDashboard />
      <ContentWrapper>
        <div className="boss-content-switcher">
          <Tabs
            className="boss-content-switcher__inner"
            selectedTabClassName="boss-content-switcher__nav-link_state_active"
          >
            <TabList className="boss-content-switcher__nav boss-content-switcher__side">
              <Tab className="boss-content-switcher__nav-link">Image URL</Tab>
              <Tab className="boss-content-switcher__nav-link">Photo</Tab>
            </TabList>
            <section className="boss-content-switcher__chapters">
              <TabPanel
                selectedClassName="boss-content-switcher__chapter_state_visible"
                className="boss-content-switcher__chapter"
              >
                <ImageUrlForm
                  initialValues={{ imageUrl: imageUrl }}
                  onSubmit={handleSubmit}
                />
                {result !== null && <Result result={result} />}
              </TabPanel>
              <TabPanel
                selectedClassName="boss-content-switcher__chapter_state_visible"
                className="boss-content-switcher__chapter"
              >
                <PhotoForm
                  initialValues={{ base64: '' }}
                  onSubmit={handleBase64Submit}
                />
                {result !== null && <Result result={result} />}
              </TabPanel>
            </section>
          </Tabs>
        </div>
      </ContentWrapper>
    </Fragment>
  );
}
