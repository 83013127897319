import React from 'react';
import d3 from 'd3';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { openCustomModal } from '@/components/modals';

import ActionModal from './action-modal';

function makeXAxis(xScale, graphXAxisHours) {
  var xAxis = d3.svg.axis();
  xAxis
    .scale(xScale)
    .orient('top')
    .ticks(graphXAxisHours.length)
    .tickSize(110)
    .tickFormat(function (offset) {
      return graphXAxisHours[offset];
    });
  return xAxis;
}

const GRAPH_WIDTH = '470';
const GRAPH_HEIGHT = '150';

var padding = 50;

const CLOCK_IN_EVENT = 'clock_in';
const CLOCK_OUT_EVENT = 'clock_out';
const START_BREAK_EVENT = 'start_break';
const END_BREAK_EVENT = 'end_break';

export const EVENT_TITLES = {
  [CLOCK_IN_EVENT]: 'Clock In',
  [CLOCK_OUT_EVENT]: 'Clock Out',
  [START_BREAK_EVENT]: 'Start break',
  [END_BREAK_EVENT]: 'End break',
};

export const AUTH_METHOD_PIN_CODE = 'pin_code';
export const AUTH_METHOD_MANAGER_PIN = 'manager_pin';
export const AUTH_METHOD_PHOTO = 'photo';
export const AUTH_METHOD_MANAGER_PHOTO = 'manager_photo';
export const AUTH_METHOD_MANAGER = 'manager';

export const AUTH_METHODS_TITLES = {
  [AUTH_METHOD_PIN_CODE]: 'Pin code',
  [AUTH_METHOD_MANAGER_PIN]: 'Manager pin code',
  [AUTH_METHOD_PHOTO]: 'Photo',
  [AUTH_METHOD_MANAGER_PHOTO]: 'Manager photo',
  [AUTH_METHOD_MANAGER]: 'Manager force clock out',
};

const ICON_ROLE_PHOTO =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjMwIiBoZWlnaHQ9IjI4IiB2aWV3Qm94PSIwIDAgMzAgMjgiPjxnIGZpbGw9IiMwMDAwMDAiIHRyYW5zZm9ybT0ic2NhbGUoMC4wMjczNDM3NSAwLjAyNzM0Mzc1KSI+PHBhdGggZD0iTTU0OC41NzEgMzg0YzkwLjg1NyAwIDE2NC41NzEgNzMuNzE0IDE2NC41NzEgMTY0LjU3MXMtNzMuNzE0IDE2NC41NzEtMTY0LjU3MSAxNjQuNTcxLTE2NC41NzEtNzMuNzE0LTE2NC41NzEtMTY0LjU3MSA3My43MTQtMTY0LjU3MSAxNjQuNTcxLTE2NC41NzF6TTk1MC44NTcgMTQ2LjI4NmM4MC41NzEgMCAxNDYuMjg2IDY1LjcxNCAxNDYuMjg2IDE0Ni4yODZ2NTEyYzAgODAuNTcxLTY1LjcxNCAxNDYuMjg2LTE0Ni4yODYgMTQ2LjI4NmgtODA0LjU3MWMtODAuNTcxIDAtMTQ2LjI4Ni02NS43MTQtMTQ2LjI4Ni0xNDYuMjg2di01MTJjMC04MC41NzEgNjUuNzE0LTE0Ni4yODYgMTQ2LjI4Ni0xNDYuMjg2aDEyOGwyOS4xNDMtNzcuNzE0YzE0LjI4Ni0zNy43MTQgNTguODU3LTY4LjU3MSA5OC44NTctNjguNTcxaDI5Mi41NzFjNDAgMCA4NC41NzEgMzAuODU3IDk4Ljg1NyA2OC41NzFsMjkuMTQzIDc3LjcxNGgxMjh6TTU0OC41NzEgODA0LjU3MWMxNDEuMTQzIDAgMjU2LTExNC44NTcgMjU2LTI1NnMtMTE0Ljg1Ny0yNTYtMjU2LTI1Ni0yNTYgMTE0Ljg1Ny0yNTYgMjU2IDExNC44NTcgMjU2IDI1NiAyNTZ6Ii8+PC9nPjwvc3ZnPg==';
const ICON_ROLE_MANAGER_PHOTO =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjMwIiBoZWlnaHQ9IjI4IiB2aWV3Qm94PSIwIDAgMzAgMjgiPjxnIGZpbGw9IiNkZDAwMDAiIHRyYW5zZm9ybT0ic2NhbGUoMC4wMjczNDM3NSAwLjAyNzM0Mzc1KSI+PHBhdGggZD0iTTU0OC41NzEgMzg0YzkwLjg1NyAwIDE2NC41NzEgNzMuNzE0IDE2NC41NzEgMTY0LjU3MXMtNzMuNzE0IDE2NC41NzEtMTY0LjU3MSAxNjQuNTcxLTE2NC41NzEtNzMuNzE0LTE2NC41NzEtMTY0LjU3MSA3My43MTQtMTY0LjU3MSAxNjQuNTcxLTE2NC41NzF6TTk1MC44NTcgMTQ2LjI4NmM4MC41NzEgMCAxNDYuMjg2IDY1LjcxNCAxNDYuMjg2IDE0Ni4yODZ2NTEyYzAgODAuNTcxLTY1LjcxNCAxNDYuMjg2LTE0Ni4yODYgMTQ2LjI4NmgtODA0LjU3MWMtODAuNTcxIDAtMTQ2LjI4Ni02NS43MTQtMTQ2LjI4Ni0xNDYuMjg2di01MTJjMC04MC41NzEgNjUuNzE0LTE0Ni4yODYgMTQ2LjI4Ni0xNDYuMjg2aDEyOGwyOS4xNDMtNzcuNzE0YzE0LjI4Ni0zNy43MTQgNTguODU3LTY4LjU3MSA5OC44NTctNjguNTcxaDI5Mi41NzFjNDAgMCA4NC41NzEgMzAuODU3IDk4Ljg1NyA2OC41NzFsMjkuMTQzIDc3LjcxNGgxMjh6TTU0OC41NzEgODA0LjU3MWMxNDEuMTQzIDAgMjU2LTExNC44NTcgMjU2LTI1NnMtMTE0Ljg1Ny0yNTYtMjU2LTI1Ni0yNTYgMTE0Ljg1Ny0yNTYgMjU2IDExNC44NTcgMjU2IDI1NiAyNTZ6Ii8+PC9nPjwvc3ZnPg==';
const ICON_ROLE_PIN_CODE =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjU2MXB4IiBoZWlnaHQ9IjU2MXB4IiB2aWV3Qm94PSIwIDAgNTYxIDU2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTYxIDU2MTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+PGc+PHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTI4MC41LDQ1OWMtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMzMDguNTUsNDU5LDI4MC41LDQ1OXogTTEyNy41LDBjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMTU1LjU1LDAsMTI3LjUsMHogTTEyNy41LDE1M2MtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMxNTUuNTUsMTUzLDEyNy41LDE1M3ogTTEyNy41LDMwNmMtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMxNTUuNTUsMzA2LDEyNy41LDMwNnogTTQzMy41LDEwMmMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxcy0yMi45NS01MS01MS01MXMtNTEsMjIuOTUtNTEsNTFTNDA1LjQ1LDEwMiw0MzMuNSwxMDJ6IE0yODAuNSwzMDZjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMzA4LjU1LDMwNiwyODAuNSwzMDZ6IE00MzMuNSwzMDZjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTNDYxLjU1LDMwNiw0MzMuNSwzMDZ6IE00MzMuNSwxNTNjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTNDYxLjU1LDE1Myw0MzMuNSwxNTN6IE0yODAuNSwxNTNjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMzA4LjU1LDE1MywyODAuNSwxNTN6IE0yODAuNSwwYy0yOC4wNSwwLTUxLDIyLjk1LTUxLDUxczIyLjk1LDUxLDUxLDUxczUxLTIyLjk1LDUxLTUxUzMwOC41NSwwLDI4MC41LDB6Ii8+PC9nPjwvZz48L3N2Zz4NCg==';
const ICON_ROLE_MANAGER_PIN_CODE =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjU2MXB4IiBoZWlnaHQ9IjU2MXB4IiB2aWV3Qm94PSIwIDAgNTYxIDU2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTYxIDU2MTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+PGc+PHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTI4MC41LDQ1OWMtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMzMDguNTUsNDU5LDI4MC41LDQ1OXogTTEyNy41LDBjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMTU1LjU1LDAsMTI3LjUsMHogTTEyNy41LDE1M2MtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMxNTUuNTUsMTUzLDEyNy41LDE1M3ogTTEyNy41LDMwNmMtMjguMDUsMC01MSwyMi45NS01MSw1MXMyMi45NSw1MSw1MSw1MXM1MS0yMi45NSw1MS01MVMxNTUuNTUsMzA2LDEyNy41LDMwNnogTTQzMy41LDEwMmMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxcy0yMi45NS01MS01MS01MXMtNTEsMjIuOTUtNTEsNTFTNDA1LjQ1LDEwMiw0MzMuNSwxMDJ6IE0yODAuNSwzMDZjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMzA4LjU1LDMwNiwyODAuNSwzMDZ6IE00MzMuNSwzMDZjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTNDYxLjU1LDMwNiw0MzMuNSwzMDZ6IE00MzMuNSwxNTNjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTNDYxLjU1LDE1Myw0MzMuNSwxNTN6IE0yODAuNSwxNTNjLTI4LjA1LDAtNTEsMjIuOTUtNTEsNTFzMjIuOTUsNTEsNTEsNTFzNTEtMjIuOTUsNTEtNTFTMzA4LjU1LDE1MywyODAuNSwxNTN6IE0yODAuNSwwYy0yOC4wNSwwLTUxLDIyLjk1LTUxLDUxczIyLjk1LDUxLDUxLDUxczUxLTIyLjk1LDUxLTUxUzMwOC41NSwwLDI4MC41LDB6Ii8+PC9nPjwvZz48L3N2Zz4NCg==';
const ICON_ROLE_MANAGER =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjk3IDI5NyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI5NyAyOTciPgogIDxnPgogICAgPHBhdGggZD0iTTE0OC41MSwxMTcuMjE2YzMyLjMxNywwLDU4LjYwOC0yNi4yOTEsNTguNjA4LTU4LjYwOFMxODAuODI3LDAsMTQ4LjUxLDBjLTMyLjMxNywwLTU4LjYwOCwyNi4yOTEtNTguNjA4LDU4LjYwOCAgIFMxMTYuMTkzLDExNy4yMTYsMTQ4LjUxLDExNy4yMTZ6IiBmaWxsPSIjZGQwMDAwIi8+CiAgICA8cGF0aCBkPSJtMjI3LjE1NCwxNDUuNjE4Yy0wLjAyNS0wLjAwOC0wLjA3My0wLjAyNi0wLjA5OC0wLjAzMi03LjYzMS0xLjg2NC0zMC45OTktNS4xMzMtMzAuOTk5LTUuMTMzLTIuNjM4LTAuODEyLTUuNDU3LDAuNTg1LTYuNDA2LDMuMTg4bC0zNS4xNzQsOTYuNTA5Yy0yLjAyOSw1LjU2Ny05LjkwMyw1LjU2Ny0xMS45MzIsMGwtMzUuMTc0LTk2LjUwOWMtMC43NjYtMi4xMDItMi43NS0zLjQyLTQuODc2LTMuNDItMC41MDQsMC0yNC41MzEsMy4zNjktMzIuNTMsNS4zNTgtMjEuODU4LDUuNDM1LTM1LjY0NSwyNi45MjktMzUuNjQ1LDQ5LjMyOXY4MC4zMDJjMCwxMi4wMzQgOS43NTYsMjEuNzkgMjEuNzksMjEuNzloMTg0Ljc4MmMxMi4wMzQsMCAyMS43OS05Ljc1NiAyMS43OS0yMS43OXYtODAuNTY5Yy0wLjAwMS0yMi4zMDMtMTQuMzI4LTQyLjA5Ni0zNS41MjgtNDkuMDIzeiIgZmlsbD0iI2RkMDAwMCIvPgogICAgPHBhdGggZD0ibTE2MS43NzUsMTM4LjYxM2MtMS40MDQtMS41My0zLjQ1Ni0yLjI5OS01LjUzMi0yLjI5OWgtMTUuNDg1Yy0yLjA3NiwwLTQuMTI5LDAuNzctNS41MzIsMi4yOTktMi4xNzMsMi4zNjgtMi40ODksNS43ODktMC45NDYsOC40NjJsOC4yNzgsMTIuNDc5LTMuODc1LDMyLjY5IDcuNjMxLDIwLjNjMC43NDQsMi4wNDIgMy42MzEsMi4wNDIgNC4zNzUsMGw3LjYzMS0yMC4zLTMuODc1LTMyLjY5IDguMjc4LTEyLjQ3OWMxLjU0MS0yLjY3MyAxLjIyNS02LjA5NC0wLjk0OC04LjQ2MnoiIGZpbGw9IiNkZDAwMDAiLz4KICA8L2c+Cjwvc3ZnPgo=';

const ICONS = {
  [AUTH_METHOD_PIN_CODE]: ICON_ROLE_PIN_CODE,
  [AUTH_METHOD_MANAGER_PIN]: ICON_ROLE_MANAGER_PIN_CODE,
  [AUTH_METHOD_PHOTO]: ICON_ROLE_PHOTO,
  [AUTH_METHOD_MANAGER_PHOTO]: ICON_ROLE_MANAGER_PHOTO,
  [AUTH_METHOD_MANAGER]: ICON_ROLE_MANAGER,
};

export default class HoursChartUi extends React.Component {
  render() {
    return (
      <div className="boss-hrc__graph">
        <div className="hours-chart">
          <div className="hours-chart__inner" style={{ touchAction: 'none' }}>
            <div ref={el => (this.el = el)} className="hours-chart__content" />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.renderChart();
  }

  componentWillReceiveProps(newProps) {
    if (!utils.deepEqualTreatingFunctionsAsStrings(this.props, newProps)) {
      this.needsFullRerender = true;
    }
  }

  componentDidUpdate() {
    if (this.needsFullRerender) {
      this.renderChart();
      this.needsFullRerender = false;
    } else {
      this.updateChartInteractions();
    }
  }

  renderChart() {
    this.el.innerHTML = '';

    var chart = this.getChart();
    var xScale = this.getXScale();

    this.renderXAxis({ chartContent: chart, xScale });
    this.renderHoursAcceptanceIntervals({ chartContent: chart, xScale });
    this.renderClockedIntervals({ chartContent: chart, xScale });
    this.renderRotaedIntervals({ chartContent: chart, xScale });
    // this.renderLaneLabels({ chart });
    this.renderEvents({ chartContent: chart, xScale });
  }

  getXScale() {
    const graphXAxisHours = oFetch(this.props, 'graphXAxisHours');
    return d3.scale.linear().domain([0, graphXAxisHours.length]).range([0, 420]);
  }

  getChart = () => {
    const svg = d3.select(this.el).append('svg').attr('width', GRAPH_WIDTH).attr('height', GRAPH_HEIGHT);

    const chart = svg.append('g').attr('width', 430).attr('height', 130);
    return chart;
  };

  renderEvents({ chartContent, xScale }) {
    var group = chartContent.append('g');
    const events = oFetch(this.props, 'events');
    var lineContainers = group
      .selectAll('g')
      .data(events)
      .enter()
      .append('g')
      .attr('transform', function (event) {
        const x = xScale(event.timeOffset) + 20;

        return `translate(${x}, 60)`;
      });

    lineContainers
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('stroke', function (event) {
        const [type, isManagerEvent] = oFetch(event, 'type', 'isManagerEvent');
        if ((type === 'clock_out' && isManagerEvent) || (type === 'clock_in' && isManagerEvent)) {
          return '#fd554f';
        }
        return {
          clock_in: '#76db63',
          clock_out: '#76db63',
          start_break: '#f7bf14',
          end_break: '#f7bf14',
        }[type];
      })
      .attr('stroke-width', function (event) {
        return 2;
      })
      .attr('y1', event => {
        const type = oFetch(event, 'type');
        return {
          clock_in: -15,
          clock_out: -15,
          start_break: -10,
          end_break: -10,
        }[type];
      })
      .attr('y2', event => {
        const type = oFetch(event, 'type');
        return {
          clock_in: 25,
          clock_out: 25,
          start_break: 20,
          end_break: 20,
        }[type];
      });

    var clockInOutMarkers = lineContainers.selectAll('.hours-confirmation-chart__clock-in-out-markers');

    clockInOutMarkers
      .attr('x1', function (event) {
        return {
          clock_in: -1.5,
          clock_out: -6,
        }[event.type];
      })
      .attr('x2', function (event) {
        return {
          clock_in: 8,
          clock_out: 2,
        }[event.type];
      })
      .attr('stroke', '#76db63')
      .attr('stroke-width', 3);

    var eventIndicator = lineContainers.filter(() => true);

    eventIndicator.append('g').classed('hours-chart__event-indicator', true);

    var eventIndicatorMarker = eventIndicator.selectAll('.hours-chart__event-indicator');

    eventIndicatorMarker
      .append('rect')
      .attr('width', event => {
        const type = oFetch(event, 'type');
        if (['clock_out', 'clock_in'].includes(type)) {
          return 20;
        }
        return 15;
      })
      .attr('height', event => {
        const type = oFetch(event, 'type');
        if (['clock_out', 'clock_in'].includes(type)) {
          return 20;
        }
        return 15;
      })
      .attr('x', event => {
        const type = oFetch(event, 'type');
        if (type === 'clock_out') {
          return -20;
        }
        if (type === 'end_break') {
          return -15;
        }
        return 0;
      })
      .attr('y', event => {
        const type = oFetch(event, 'type');
        if (['clock_out', 'clock_in'].includes(type)) {
          return -5;
        }
        return -2.25;
      })
      .attr('fill', 'white')
      .attr('stroke', event => {
        const [type, isManagerEvent] = oFetch(event, 'type', 'isManagerEvent');
        if ((type === 'clock_out' && isManagerEvent) || (type === 'clock_in' && isManagerEvent)) {
          return '#fd554f';
        }
        return {
          clock_in: '#76db63',
          clock_out: '#76db63',
          start_break: '#f7bf14',
          end_break: '#f7bf14',
        }[type];
      })
      .attr('stroke-width', 1)
      .style('shape-rendering', 'geometricPrecision');

    var eventIndicatorIcon = eventIndicator.selectAll('.hours-chart__event-indicator');

    eventIndicatorIcon
      .append('svg:image')
      .attr('width', event => {
        const [isFirst, isLast] = oFetch(event, 'isFirst', 'isLast');
        if (!isFirst && !isLast) {
          return 10;
        }
        return 15;
      })
      .attr('height', event => {
        const [isFirst, isLast] = oFetch(event, 'isFirst', 'isLast');
        if (!isFirst && !isLast) {
          return 10;
        }
        return 15;
      })
      .attr('x', event => {
        const [type, isFirst, isLast] = oFetch(event, 'type', 'isFirst', 'isLast');
        if (['clock_out', 'end_break'].includes(type)) {
          if (!isFirst && !isLast) {
            return -12;
          }
          return -17;
        }
        return 2.5;
      })
      .attr('y', event => {
        const [isFirst, isLast] = oFetch(event, 'isFirst', 'isLast');
        if (!isFirst && !isLast) {
          return 0;
        }
        return -2;
      })
      .attr('xlink:href', function (event) {
        return ICONS[event.authenticationMethod];
      })
      .on('click', function (event) {
        const eventType = oFetch(event, 'type');
        openCustomModal({
          config: {
            title: `${EVENT_TITLES[eventType]} Event Details`,
            modalClass: 'boss-modal-window boss-modal-window_role_action-details',
          },
          props: { event, events: events },
        })(ActionModal);
      });
  }

  renderHoursAcceptanceIntervals({ chartContent, xScale }) {
    this.renderIntervals({
      chart: chartContent,
      xScale,
      intervals: this.props.hoursAcceptanceIntervals,
      lane: 'amended',
    });
  }

  renderIntervals({ chart, xScale, intervals, lane }) {
    var y = {
      amended: 100,
      clocked: 60,
      rotaed: 22,
    }[lane];

    var intervalGroup = chart
      .append('g')
      .selectAll('g')
      .data(intervals)
      .enter()
      .append('g')
      .attr('transform', function (interval, i) {
        const type = oFetch(interval, 'type');
        var x = xScale(interval.startOffsetInHours) + padding - 30;
        if (lane === 'clocked' && type === 'break') {
          return `translate(${x}, 62)`;
        }
        if (lane === 'amended' && type === 'break') {
          return `translate(${x}, 102)`;
        }
        return `translate(${x}, ${y})`;
      });

    intervalGroup
      .append('rect')
      .attr('width', function (interval, i) {
        var intervalLengthInHours = interval.endOffsetInHours - interval.startOffsetInHours;
        return xScale(intervalLengthInHours);
      })
      .attr('height', interval => {
        const type = oFetch(interval, 'type');

        if (lane === 'rotaed') {
          return 20;
        }
        if (lane === 'amended') {
          const height = {
            standard: 15,
            break: 10,
            special: 15,
          }[type];
          return height;
        }
        const height = {
          standard: 10,
          break: 5,
          special: 10,
        }[type];
        return height;
      })
      .attr('stroke', interval => {
        const type = oFetch(interval, 'type');
        if (type === 'rotaed') {
          const headDoormanHour = oFetch(interval, 'headDoormanHour') === true;
          if (headDoormanHour) {
            return '#8c7ae6';
          }
        }
      })
      .attr('stroke-width', interval => {
        const type = oFetch(interval, 'type');
        if (type === 'rotaed') {
          const headDoormanHour = oFetch(interval, 'headDoormanHour') === true;
          if (headDoormanHour) {
            return '2';
          }
        }
        return '0';
      })
      .attr('fill', interval => {
        const type = oFetch(interval, 'type');
        if (lane === 'rotaed') {
          return '#478ece';
        }
        if (lane === 'amended') {
          const color = {
            standard: '#369736',
            break: '#fbbd08',
            special: '#8c7ae6',
          }[type];
          return color;
        }
        const color = {
          standard: '#76db63',
          break: '#f7bf14',
          special: '#8c7ae6',
        }[type];
        return color;
      });

    intervalGroup
      .append('text')
      .attr('x', interval => {
        var intervalLengthInHours = interval.endOffsetInHours - interval.startOffsetInHours;
        return xScale(intervalLengthInHours) / 2;
      })
      .attr('y', 15)
      .attr('text-anchor', 'middle')
      .attr('font-size', '13')
      .attr('fill', '#ffffff')
      .text(function (interval) {
        return interval.label;
      });
  }

  renderXAxis({ chartContent, xScale }) {
    const graphXAxisHours = oFetch(this.props, 'graphXAxisHours');
    const xAxis = makeXAxis(xScale, graphXAxisHours);
    chartContent.append('g').attr('transform', 'translate(20, 130)').attr('class', 'axis').call(xAxis);

    chartContent.select('path').style({ stroke: 'none', fill: 'none' });
    chartContent.selectAll('text').style({ 'font-size': '12px', 'text-anchor': 'middle', color: '#aaa' });
  }

  renderRotaedIntervals({ chartContent, xScale }) {
    this.renderIntervals({
      chart: chartContent,
      xScale,
      intervals: this.props.rotaedIntervals,
      lane: 'rotaed',
    });
  }

  renderClockedIntervals({ chartContent, xScale }) {
    this.renderIntervals({
      chart: chartContent,
      xScale,
      intervals: this.props.clockedIntervals,
      lane: 'clocked',
    });
  }
}
