import React, { Component } from 'react';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import oFetch from 'o-fetch';
import { modalRedux } from '@/components/modals';

import { QuestionForm } from '../../components';

class AddQuestion extends Component {
  render() {
    const [onSubmit, questionTypesOptions, questionTypes, categoryId, questionTags] = oFetch(
      this.props,
      'onSubmit',
      'questionTypesOptions',
      'questionTypes',
      'categoryId',
      'questionTags',
    );

    const initialValues = {
      questionText: null,
      questionImage: null,
      questionType: null,
      answers: [],
      tags: [],
      categoryId: categoryId,
    };

    return (
      <QuestionForm
        questionTypes={questionTypes}
        questionTypesOptions={questionTypesOptions}
        questionTagsOptions={questionTags}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(AddQuestion);
