import React from 'react';
import { format } from 'date-fns';
import { $MossTagDetailsModalContentProps, DetailsModalActions, DetailsModalFormValues, MossTagDetailsModalContentProps } from '../types';
import { Form } from 'react-final-form';

export function MossTagDetailsModalContent(props: MossTagDetailsModalContentProps) {
  const parsedProps = $MossTagDetailsModalContentProps.parse(props);
  const tag = parsedProps.tag;

  function getDisabledAtText(disabledAt: Date | null): string {
    if (disabledAt) {
      return `Disabled At: ${format(disabledAt, 'HH:mm  dd/MM/yyyy')}`;
    }
    return '';
  }

  return (
    <div>
      <div>
        { tag.isDisabled && (
          <div>
            <Form
              onSubmit={() => {
                const submitParams: DetailsModalFormValues = {
                  action: DetailsModalActions.enable,
                };
                props.onSubmit(submitParams);
              }}
              initialValues={{}}
              render={(renderProps) => {
                const canSubmit = !renderProps.submitting || !renderProps.pristine;

                return (
                  <p>
                    <span>Disabled By: {tag.disabledByUserName}</span>, <span>Disabled At: {getDisabledAtText(tag.disabledAt)}</span>
                    <button
                      onClick={renderProps.handleSubmit}
                      disabled={!canSubmit}
                    >Enable</button>
                  </p>
                );
              }}
            />
          </div>
        )}
        { !tag.isDisabled && (
          <Form
            onSubmit={() => {
              const submitParams: DetailsModalFormValues = {
                action: DetailsModalActions.disable,
              };
              props.onSubmit(submitParams);
            }}
            initialValues={{}}
            render={(renderProps) => {
              const canSubmit = !renderProps.submitting || !renderProps.pristine;

              return (
                <div>
                  <p>
                    { renderProps.submitError && <span>{renderProps.submitError}</span> }

                    <span>Status: Enabled</span>
                    <button
                      onClick={renderProps.handleSubmit}
                      disabled={!canSubmit}
                    >Disable</button>
                  </p>
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
}