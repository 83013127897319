import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { Field, reduxForm, Fields, change } from 'redux-form';
import utils from '@/lib/utils';
import { BossFormVenuesSelectField, BossFormCalendar } from '@/components/boss-form';
import * as c from '../../constants';
import { venueFormValidators } from './validators';
import * as actions from '../../redux/actions';

class VenueForm extends Component {
  renderBaseErrors = errors => {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  };
  render() {
    const [handleSubmit, previousPage, valid, asyncValidating] = oFetch(
      this.props,
      'handleSubmit',
      'previousPage',
      'valid',
      'asyncValidating',
    );
    const venueOptions = oFetch(this.props, 'venueOptions');
    const accessibleVenueOptions = oFetch(this.props, 'accessibleVenueOptions');
    const { error } = this.props;

    return (
      <div className="boss-page-main__group">
        <div className="boss-form">
          {error && this.renderBaseErrors(error)}

          <Fields
            names={[c.MASTER_VENUE_FIELD_NAME, c.OTHER_VENUES_FIELD_NAME]}
            masterVenueClearable
            masterVenuePlaceholder="Select master venue ..."
            otherVenuesPlaceholder="Select other venues ..."
            valueKey="id"
            labelKey="name"
            options={{ venueOptions, accessibleVenueOptions }}
            component={BossFormVenuesSelectField}
          />
          <Field
            name={c.STARTS_AT_FIELD_NAME}
            label={c.VENUE_FORM_FIELDS_TITLES[c.STARTS_AT_FIELD_NAME]}
            placeholder="Select starts at ..."
            component={BossFormCalendar}
            valueFormat={utils.commonDateFormat}
          />
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              onClick={previousPage}
              type="button"
              className="boss-button boss-button_role_back boss-form__submit_adjust_row"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              disabled={!valid || asyncValidating}
              className="boss-button boss-form__submit boss-form__submit_adjust_single"
            >
              {asyncValidating ? `Checking for flagged or existing staff members ...` : `Continue`}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: c.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: venueFormValidators,
  shouldAsyncValidate({
    asyncErrors,
    initialized,
    trigger,
    blurredField,
    pristine,
    syncValidationPasses,
  }) {
    if (!syncValidationPasses) {
      return false;
    }
    switch (trigger) {
      case 'blur':
      case 'change':
        return true;
      case 'submit':
        return false;
      default:
        return false;
    }
  },
  onChange(values, dispatch, props, previousValues) {
    const masterVenue = oFetch(values, c.MASTER_VENUE_FIELD_NAME);
    const previousMasterVenue = oFetch(previousValues, c.MASTER_VENUE_FIELD_NAME);
    if (masterVenue !== previousMasterVenue) {
      dispatch(actions.updateAccessories({ accessories: [] }));
      dispatch(change(c.FORM_NAME, c.ACCESSORIES_FIELD_NAME, {}));
    }
  },
})(VenueForm);
