import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function ColumnFilter(props) {
  const [isOpened, setIsOpened] = useState(false);

  const [
    title,
    onFilterChange,
    selectedVenues,
    onAssignOnMeChanged,
    isAssignedOnMe,
    showAssignOnMe,
    totalCount,
    selectedCount,
    onToggleListOpened,
  ] = oFetch(
    props,
    'title',
    'onFilterChange',
    'selectedVenues',
    'onAssignOnMeChanged',
    'isAssignedOnMe',
    'showAssignOnMe',
    'totalCount',
    'selectedCount',
    'onToggleListOpened',
  );
  const venueOptions = oFetch(useLegacyGlobal(), 'venueOptions');

  const filterButtonClassNames = cn('boss-tasks__filter-switch', {
    'boss-tasks__filter-switch_state_closed': !isOpened,
  });

  function toggleFilter() {
    setIsOpened(!isOpened);
  }

  function handleChange(venues) {
    if (venues === '') {
      onFilterChange([]);
    } else {
      const normalizedVenues = venues.split(',').map(stringVenueId => parseInt(stringVenueId));
      onFilterChange(normalizedVenues);
    }
  }

  function handleIsAssignOnMeChanged() {
    onAssignOnMeChanged(!isAssignedOnMe);
  }

  return (
    <>
      <div className="boss-tasks__info">
        <h4 className="boss-tasks__title">
          {title}{' '}
          {totalCount !== 0 && (
            <span className="boss-tasks__title-status boss-tasks__title-status_role_alert">
              (Showing {selectedCount} of {totalCount})
            </span>
          )}
        </h4>
        <div className="boss-tasks__actions">
          <button type="button" onClick={toggleFilter} className={filterButtonClassNames}>
            Filter
          </button>
          <button
            type="button"
            onClick={onToggleListOpened}
            className="boss-tasks__content-switch boss-tasks__content-switch_state_closed"
          >
            Toggle Content
          </button>
        </div>
      </div>
      <Collapse isOpened={isOpened} className="boss-tasks__filter">
        <div style={{ paddingBottom: 15 }} />
        <div className="boss-tasks__filter-content" style={{ display: 'block', marginTop: 0 }}>
          <div className="boss-form">
            <div className="boss-form__row">
              <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_max">
                <p className="boss-form__label boss-form__label_type_icon-single boss-form__label_type_icon-venue">
                  <span className="boss-form__label-text" />
                </p>
                <div className="boss-form__select">
                  <Select
                    value={selectedVenues}
                    onChange={handleChange}
                    clearable
                    simpleValue
                    multi
                    placeholder=""
                    options={venueOptions}
                  />
                </div>
              </div>
              {showAssignOnMe && (
                <div className="boss-form__field boss-form__field_layout_min">
                  <label className="boss-form__checkbox-label">
                    <input
                      name="assigned-to-me"
                      checked={isAssignedOnMe}
                      className="boss-form__checkbox-input"
                      type="checkbox"
                      onChange={handleIsAssignOnMeChanged}
                    />
                    <span className="boss-form__checkbox-label-text">Assigned to me</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}
