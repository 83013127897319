import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';
import * as commonTypes from '../../../profile-wrapper/constants';

const initialState = {
  total: null,
  current: 1,
};

export default handleActions(
  {
    [commonTypes.INITIAL_LOAD]: (state, action) => {
      const pageCount = oFetch(action, 'payload.pageCount');
      return { total: pageCount, current: 1 };
    },
    [types.INCREMENT_PAGE]: (state, action) => {
      const nextPage = oFetch(action, 'payload.nextPage');
      return { ...state, current: nextPage };
    },
  },
  initialState,
);
