import React from 'react';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer, SubmissionError } from 'redux-form/immutable';
import { modalRedux } from '@/components/modals';
import oFetch from 'o-fetch';
import VenueForm from './venue-form';

class EditVenue extends React.Component {
  handleSubmit = (values, dispatch) => {
    return this.props.onSubmit(values.toJS(), dispatch).catch(resp => {
      const errors = resp.response.data.errors;
      if (errors) {
        let base = {};

        if (errors.base) {
          base = {
            _error: errors.base,
          };
        }
        throw new SubmissionError({ ...errors, ...base });
      }
      return resp;
    });
  };

  render() {
    const [
      venueId,
      name,
      bossVenue,
      mossVenue,
      safeFloatPoundValue,
      tillFloatPoundValue,
      fruitOrderFields,
      lat,
      lng,
      changeOrderSiteId,
      overheadsThresholdPercentage,
      staffThresholdPercentage,
      prThresholdPercentage,
      kitchenThresholdPercentage,
      securityThresholdPercentage,
      emergencyWhatsappGroupId,
      regularLateShiftWhatsappGroupId,
      securityLateShiftWhatsappGroupId,
    ] = oFetch(
      this.props.venue,
      'id',
      'name',
      'bossVenue',
      'mossVenue',
      'safeFloatPoundValue',
      'tillFloatPoundValue',
      'fruitOrderFields',
      'lat',
      'lng',
      'changeOrderSiteId',
      'overheadsThresholdPercentage',
      'staffThresholdPercentage',
      'prThresholdPercentage',
      'kitchenThresholdPercentage',
      'securityThresholdPercentage',
      'emergencyWhatsappGroupId',
      'regularLateShiftWhatsappGroupId',
      'securityLateShiftWhatsappGroupId',
    );
    const initialValues = {
      venueId,
      name,
      bossVenue,
      mossVenue,
      tillFloatPoundValue,
      safeFloatPoundValue,
      fruitOrderFields,
      changeOrderSiteId,
      lat,
      lng,
      overheadsThresholdPercentage,
      staffThresholdPercentage,
      prThresholdPercentage,
      kitchenThresholdPercentage,
      securityThresholdPercentage,
      emergencyWhatsappGroupId,
      regularLateShiftWhatsappGroupId,
      securityLateShiftWhatsappGroupId,
    };
    return (
      <VenueForm
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        buttonText="Update"
        fruitOrderFields={oFetch(this.props, 'fruitOrderFields')}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(EditVenue);
