import React, { Component } from 'react';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import oFetch from 'o-fetch';
import { loadImage } from '@/components/image-resizer';
import { modalRedux } from '@/components/modals';
import utils from '@/lib/utils';
import { QuestionForm } from '../../components';

class EditQuestion extends Component {
  state = {
    isLoaded: false,
    image: null,
    processedAnswers: [],
  };

  getValue = async value => {
    if (!value) {
      return null;
    }
    if (utils.isString(value)) {
      try {
        const file = await loadImage(value, { crossOrigin: 'anonymous' });
        return file;
      } catch (e) {
        return null;
      }
    }
    return value;
  };

  getAnswer = async answer => {
    const image = await this.getValue(oFetch(answer, 'imageUrl'));
    return {
      ...answer,
      image,
    };
  };

  componentDidMount = async () => {
    const [question, answers] = oFetch(this.props, 'question', 'question.answers');
    const imageUrl = oFetch(question, 'imageUrl');
    const image = await this.getValue(imageUrl);
    const processedAnswers = await Promise.all(answers.map(this.getAnswer));
    this.setState({ image, processedAnswers, isLoaded: true });
  };

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    const [onSubmit, questionTypesOptions, questionTypes, categoryId, question, questionTags] = oFetch(
      this.props,
      'onSubmit',
      'questionTypesOptions',
      'questionTypes',
      'categoryId',
      'question',
      'questionTags',
    );

    const initialValues = {
      id: oFetch(question, 'id'),
      questionText: oFetch(question, 'text'),
      questionImage: oFetch(this.state, 'image'),
      questionType: oFetch(question, 'type'),
      answers: oFetch(this.state, 'processedAnswers'),
      categoryId: categoryId,
      tags: oFetch(question, 'tagsIds'),
    };

    return (
      <QuestionForm
        questionTypes={questionTypes}
        questionTypesOptions={questionTypesOptions}
        questionTagsOptions={questionTags}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(EditQuestion);
