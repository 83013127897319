import React from 'react';
import { PublicHoliday } from '../types/public-holiday';
import oFetch from 'o-fetch';

type Props = {
  publicHoliday: PublicHoliday;
  onDelete: (publicHoliday: PublicHoliday) => void;
};

export function PublicHolidayItem(props: Props) {
  const publicHoliday = oFetch(props, 'publicHoliday');
  const onDelete = oFetch(props, 'onDelete');

  const date = oFetch(publicHoliday, 'date');
  const description = oFetch(publicHoliday, 'description');
  const createdByUser = oFetch(publicHoliday, 'createdByUser');
  const canBeDisabled = oFetch(publicHoliday, 'canBeDisabled');

  return (
    <div className="boss-table__row">
      <div
        className="boss-table__cell"
      >
        <div className="boss-table__info">
          <div className="boss-table__label">Date</div>
          <p className="boss-table__text boss-table__cell_role_header">{date}</p>
        </div>
      </div>
      <div
        className="boss-table__cell"
      >
        <div className="boss-table__info">
          <div className="boss-table__label">Description</div>
          <p className="boss-table__text">{description || 'N/A'}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <div className="boss-table__label">Created By</div>
          <p className="boss-table__text">{createdByUser}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          {!canBeDisabled && (
            <div className="boss-table__actions">
              <span className="boss-button boss-button_type_ultra-small boss-button_role_enabled-sb boss-button_type_no-behavior">Completed</span>
            </div>
          )}
          {canBeDisabled && (
            <div className="boss-table__actions">
              <button
                onClick={() => onDelete(publicHoliday)}
                className="boss-button boss-button_type_ultra-small boss-button_role_cancel-light"
              >Delete</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
