import React from 'react';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer, SubmissionError } from 'redux-form/immutable';
import { modalRedux } from '@/components/modals';
import oFetch from 'o-fetch';
import VenueForm from './venue-form';

class AddVenue extends React.Component {
  handleSubmit = (values, dispatch) => {
    return this.props.onSubmit(values.toJS(), dispatch).catch(resp => {
      const errors = resp.response.data.errors;
      if (errors) {
        let base = {};

        if (errors.base) {
          base = {
            _error: errors.base,
          };
        }
        throw new SubmissionError({ ...errors, ...base });
      }
      return resp;
    });
  };

  render() {
    const fruitOrderFields = oFetch(this.props, 'fruitOrderFields');
    const initialValues = {
      name: null,
      tillFloatPoundValue: null,
      safeFloatPoundValue: null,
      fruitOrderFields: null,
      mossVenue: false,
      bossVenue: false,
      emergencyWhatsappGroupId: null,
      regularLateShiftWhatsappGroupId: null,
      securityLateShiftWhatsappGroupId: null,
      changeOrderSiteId: null,
      lat: null,
      lng: null,
      overheadsThresholdPercentage: null,
      staffThresholdPercentage: null,
      prThresholdPercentage: null,
      kitchenThresholdPercentage: null,
      securityThresholdPercentage: null,
    };
    return (
      <VenueForm
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        buttonText="Create"
        fruitOrderFields={fruitOrderFields}
      />
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(AddVenue);
