import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { Pagination } from '@/components/boss-pagination-new';
import ContentWrapper from '@/components/content-wrapper';
import { AcceptedTasksDashboard, AcceptedTaskList, AcceptedTaskItem } from './components';

export function AcceptedMarketingTasks(props) {
  const [currentPage, pageSize, totalCount, acceptedTasks] = oFetch(
    props,
    'currentPage',
    'pageSize',
    'totalCount',
    'marketingTasks',
  );

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.remove('boss-body_adjust_marketing-tasks');
    Array.from(document.getElementsByClassName('boss-page-main')).forEach(el => {
      el.classList.remove('boss-page-main_adjust_marketing-tasks');
    });
  }, []);

  function handleFilter(filter) {
    const [assignedToId, endDate, startDate, title, venueIds] = oFetch(
      filter,
      'assignedToId',
      'endDate',
      'startDate',
      'title',
      'venueIds',
    );

    const currentQueryString = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    currentQueryString.title = title || undefined;
    currentQueryString.start_date = startDate || undefined;
    currentQueryString.end_date = endDate || undefined;
    currentQueryString.assigned_to = assignedToId || undefined;
    currentQueryString.venues = venueIds || undefined;
    currentQueryString.page = 1;

    window.location.search = queryString.stringify(currentQueryString, { arrayFormat: 'bracket' });
  }

  function handlePageChange(page) {
    const currentQueryString = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    currentQueryString.page = page;
    window.location.search = queryString.stringify(currentQueryString, { arrayFormat: 'bracket' });
  }

  function handleBackClick() {}

  return (
    <>
      <AcceptedTasksDashboard
        acceptedTasks={acceptedTasks}
        onBackClick={handleBackClick}
        onFilterSubmit={handleFilter}
      />
      <ContentWrapper>
        <AcceptedTaskList tasks={acceptedTasks} renderTask={task => <AcceptedTaskItem task={task} />} />

        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </ContentWrapper>
    </>
  );
}
