import React from 'react';
import oFetch from 'o-fetch';
import BossDatePicker from '@/components/react-dates/boss-date-picker';
import RcTimePicker from 'rc-time-picker';
import safeMoment from '@/lib/safe-moment';

export function BossFormDateTimePicker(props) {
  const { label } = props;
  const [input, meta] = oFetch(props, 'input', 'meta');
  const [value, onChange] = oFetch(input, 'value', 'onChange');

  function handleChange(value) {
    if (value === null) {
      onChange(value);
    } else {
      const isoString = value.format();
      onChange(isoString);
    }
  }

  return (
    <div className="boss-form__row boss-form__row_layout_wrap-xs">
      <div className="boss-form__field boss-form__field_layout_max">
        {label && (
          <p className="boss-form__label">
            <span className="boss-form__label-text">{label}</span>
          </p>
        )}
        <BossDatePicker
          id="date"
          date={value ? safeMoment.iso8601Parse(value) : null}
          onApply={handleChange}
          invalid={meta.touched && (meta.error || meta.submitError)}
        />

        {meta.touched && (meta.error || meta.submitError) && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{meta.error || meta.submitError}</span>
            </p>
          </div>
        )}
      </div>
      <div className="boss-form__field boss-form__field_no-label boss-form__field_layout_third">
        <RcTimePicker
          className={`rc-time-picker time-picker-input time-picker-input_type_icon ${
            meta.touched && (meta.error || meta.submitError) && 'time-picker-input_state_error'
          } false`}
          placeholder="Select time ..."
          value={value ? safeMoment.iso8601Parse(value) : null}
          hideDisabledOptions
          showSecond={false}
          allowEmpty={false}
          onChange={handleChange}
        />
        {meta.touched && (meta.error || meta.submitError) && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{meta.error || meta.submitError}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
