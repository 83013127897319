import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { apiRoutes } from '@/lib/legacy-routes';

export function brCreateforStaffMembersSecurityPayRate(params) {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createSecurityPayRateRoute = apiRoutes.createForStaffMembersSecurityPayRate;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createSecurityPayRateRoute.getPath(), values);
}

export function brCreateForSpecialSecurityHoursSecurityPayRate(params) {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createSecurityPayRateRoute = apiRoutes.createForSpecialSecurityHoursSecurityPayRate;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createSecurityPayRateRoute.getPath(), values);
}

export function brUpdateForStaffMembersSecurityPayRate(params) {
  const values = oFetch(params, 'values');
  const { id, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const updateSecurityPayRateRoute = apiRoutes.updateForStaffMembersSecurityPayRate;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(updateSecurityPayRateRoute.getPath(id), otherValues);
}

export function brUpdateForSpecialSecurityHoursSecurityPayRate(params) {
  const values = oFetch(params, 'values');
  const { id, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const updateSecurityPayRateRoute = apiRoutes.updateForSpecialSecurityHoursSecurityPayRate;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(updateSecurityPayRateRoute.getPath(id), otherValues);
}

export function brUpdateForSpecialSecurityHoursSecurityPayRateVenuesAssignments(params) {
  const values = oFetch(params, 'values');
  const { specialSecurityPayRateId, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const updateSecurityPayRateVenuesAssignmentsRoute =
    apiRoutes.updateForSpecialSecurityHoursSecurityPayRateVenuesAssignments;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).put(updateSecurityPayRateVenuesAssignmentsRoute.getPath(specialSecurityPayRateId), otherValues);
}

export function brCreateSecurityPayRateException(params) {
  const values = oFetch(params, 'values');
  const { securityPayRateId, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createSecurityPayRateExceptionRoute = apiRoutes.createSecurityPayRateException;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createSecurityPayRateExceptionRoute.getPath(securityPayRateId), otherValues);
}

export function brUpdateSecurityPayRateException(params) {
  const values = oFetch(params, 'values');
  const { securityPayRateId, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const updateSecurityPayRateExceptionRoute = apiRoutes.updateSecurityPayRateException;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(updateSecurityPayRateExceptionRoute.getPath(securityPayRateId), otherValues);
}

export function brDeleteSecurityPayRateException(params) {
  const values = oFetch(params, 'values');
  const { securityPayRateId, ...otherValues } = values;
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const deleteSecurityPayRateExceptionRoute = apiRoutes.deleteSecurityPayRateException;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(deleteSecurityPayRateExceptionRoute.getPath(securityPayRateId), otherValues);
}
