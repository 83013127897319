import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import Popover from 'react-popover';
import AsyncButton from 'react-async-button';
import { Tooltip } from 'react-tippy';

import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { openWarningModal } from '@/components/modals';
import { appRoutes } from '@/lib/legacy-routes';
import { FINANCE_REPORT_SHOW_ALL_FILTER_TYPE } from '../constants';
import FinanceReportsFilter from './finance-reports-filter';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export default function Dashboard(props) {
  const isAdminPlus = oFetch(useLegacyGlobal(), 'isAdminPlus');

  const [isCalendarOpen, setIsCalendatOpen] = useState(false);
  const completedFinanceReportsData = oFetch(props, 'completedFinanceReportsData');
  const onFinishFinanceReportWeek = oFetch(props, 'onFinishFinanceReportWeek');
  const onUndoFinanceReportWeek = oFetch(props, 'onUndoFinanceReportWeek');
  const [completedFinanceReportsCount, financeReportsCount] = oFetch(
    completedFinanceReportsData,
    'completedCount',
    'count',
  );
  const { title } = props;
  const venueId = oFetch(props, 'venueId');
  const staffPayslipsStats = oFetch(props, 'staffPayslipsStats');

  const mWeekStartDate = safeMoment.uiDateParse(oFetch(props, 'startDate'));
  const mWeekEndDate = safeMoment.uiDateParse(oFetch(props, 'endDate'));
  const formattedStartDate = mWeekStartDate.format(utils.commonDateFormatCalendar());
  const formattedEndDate = mWeekEndDate.format(utils.commonDateFormatCalendar());
  const onFilterChange = oFetch(props, 'onFilterChange');
  const filterType = oFetch(props, 'filterType');
  const popoverClass = classNames({
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
    'boss-page-dashboard__meta-item_state_opened': isCalendarOpen,
  });

  const showPDFDownloadLink = oFetch(props, 'showPDFDownloadLink');
  const showDownloadBulkPaymentsFile = oFetch(props, 'showDownloadBulkPaymentsFile');
  const disableExportToCSVDueToFilterType = filterType !== FINANCE_REPORT_SHOW_ALL_FILTER_TYPE;
  const showCSVDownloadLink = oFetch(props, 'showCSVDownloadLink') && !disableExportToCSVDueToFilterType;
  const paymentWeekPublished = oFetch(props, 'paymentWeekPublished');
  const financeReportWeek = oFetch(props, 'financeReportWeek');
  const financeReportWeekId = oFetch(financeReportWeek, 'id');
  const canFinishFinanceReportWeek = oFetch(financeReportWeek, 'canFinish');
  const payWeekStatusText = paymentWeekPublished ? 'Published' : 'Unpublished';
  const financeReportWeekStatusText = utils.capitalizeFirstCharacter(oFetch(financeReportWeek, 'uiStatus'));
  const isFinanceReportWeekFinished = oFetch(financeReportWeek, 'isFinished');
  const payWeekStatusTextClass = classNames('boss-page-dashboard__meta-text', {
    'boss-page-dashboard__meta-text-accent-green': paymentWeekPublished,
  });
  const financeReportsOverviewData = oFetch(props, 'financeReportsOverviewData');
  const [
    calculatedTotalGrossCents,
    totalGrossUploadedCents,
    totalGrossDeltaCents,
    totalNetWagesCents,
  ] = oFetch(
    financeReportsOverviewData,
    'calculatedTotalGrossCents',
    'totalGrossUploadedCents',
    'totalGrossDeltaCents',
    'totalNetWagesCents',
  );
  const calculatedTotalGross = utils.centsFormat(calculatedTotalGrossCents);
  const totalGrossUploaded = utils.centsFormat(totalGrossUploadedCents);
  const totalGrossDelta = totalGrossDeltaCents ? utils.centsFormat(totalGrossDeltaCents) : null;
  const totalGrossDeltaWithinThreshold = oFetch(financeReportsOverviewData, 'totalGrossDeltaWithinThreshold');
  const totalGrossDeltaTextClass = totalGrossDeltaWithinThreshold
    ? 'boss-number__value-meta'
    : 'boss-number__value-delta';

  const hourlyCalculatedTotalGrossCents = oFetch(
    financeReportsOverviewData,
    'hourlyCalculatedTotalGrossCents',
  );
  let hourlyCalculatedTotalGrossText = 'N/A';
  if (hourlyCalculatedTotalGrossCents) {
    hourlyCalculatedTotalGrossText = utils.moneyFormat(hourlyCalculatedTotalGrossCents / 100.0);
  }
  const hourlyTotalGrossUploadedCents = oFetch(financeReportsOverviewData, 'hourlyTotalGrossUploadedCents');
  const hourlyTotalGrossUploadedText = utils.moneyFormat(hourlyTotalGrossUploadedCents / 100.0);
  const hourlyGrossDeltaCents = oFetch(financeReportsOverviewData, 'hourlyGrossDeltaCents');
  let hourlyGrossDeltaText = null;
  if (hourlyGrossDeltaCents && hourlyGrossDeltaCents !== 0) {
    hourlyGrossDeltaText = utils.moneyFormat(hourlyGrossDeltaCents / 100.0);
  }
  const hourlyGrossDeltaWithinThreshold = oFetch(
    financeReportsOverviewData,
    'hourlyGrossDeltaWithinThreshold',
  );
  const hourlyGrossDeltaTextClass = hourlyGrossDeltaWithinThreshold
    ? 'boss-number__value-meta'
    : 'boss-number__value-delta';
  const hourlyTotalNetWagesCents = oFetch(financeReportsOverviewData, 'hourlyTotalNetWagesCents');
  const hourlyTotalNetWagesText = utils.moneyFormat(hourlyTotalNetWagesCents / 100.0);

  const salaryCalculatedTotalGrossCents = oFetch(
    financeReportsOverviewData,
    'salaryCalculatedTotalGrossCents',
  );
  let salaryCalculatedTotalGrossText = 'N/A';
  if (salaryCalculatedTotalGrossCents) {
    salaryCalculatedTotalGrossText = utils.moneyFormat(salaryCalculatedTotalGrossCents / 100.0);
  }
  const salaryTotalGrossUploadedCents = oFetch(financeReportsOverviewData, 'salaryTotalGrossUploadedCents');
  const salaryTotalGrossUploadedText = utils.moneyFormat(salaryTotalGrossUploadedCents / 100.0);
  const salaryGrossDeltaCents = oFetch(financeReportsOverviewData, 'salaryGrossDeltaCents');
  const salaryGrossDeltaWithinThreshold = oFetch(
    financeReportsOverviewData,
    'salaryGrossDeltaWithinThreshold',
  );
  const salaryGrossDeltaTextClass = salaryGrossDeltaWithinThreshold
    ? 'boss-number__value-meta'
    : 'boss-number__value-delta';
  let salaryGrossDeltaText = null;
  if (salaryGrossDeltaCents && salaryGrossDeltaCents !== 0) {
    salaryGrossDeltaText = utils.moneyFormat(salaryGrossDeltaCents / 100.0);
  }
  const salaryTotalNetWagesCents = oFetch(financeReportsOverviewData, 'salaryTotalNetWagesCents');
  const salaryTotalNetWagesText = utils.moneyFormat(salaryTotalNetWagesCents / 100.0);

  function togglePopover() {
    setIsCalendatOpen(!isCalendarOpen);
  }

  function showWrongFilterCSVDownloadModal(e) {
    e.preventDefault();
    openWarningModal({
      submit: closeModal => {
        closeModal();
      },
      config: {
        title: 'Action Disabled',
        text: 'You must be filtering by "Show All" to export data as CSV.',
        buttonText: 'Dismiss',
      },
    });
  }

  function renderCalendar() {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={mWeekStartDate.format(utils.uiRotaDateFormat)}
          onChange={selection => props.onDateChange(selection)}
          onCancelClick={togglePopover}
        />
      </div>
    );
  }

  function renderMarkFinanceReportWeekButton() {
    if (isFinanceReportWeekFinished) {
      return null;
    }
    if (!canFinishFinanceReportWeek) {
      const content = <span>Cannot mark as finished while incomplete finance reports exist.</span>;
      return (
        <Tooltip arrow theme="dark" position="bottom" interactive html={content}>
          <button
            className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
            disabled={true}
          >
            Mark Finished
          </button>
        </Tooltip>
      );
    }
    return (
      <AsyncButton
        className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
        text="Mark Finished"
        pendingText="Processing ..."
        onClick={() => onFinishFinanceReportWeek({ weekStart: oFetch(props, 'startDate'), venueId: venueId })}
      />
    );
  }

  function renderFinanceReportWeekStatus() {
    if (isFinanceReportWeekFinished) {
      const [finishedBy, finishedAt] = oFetch(financeReportWeek, 'finishedBy', 'finishedAt');
      const formattedFinishedAt = safeMoment
        .iso8601Parse(finishedAt)
        .format(utils.humanDateFormatWithTimeAndSeconds(finishedAt));

      return (
        <Fragment>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
            <span className="boss-page-dashboard__meta-text">Week Status: </span>
            <span className="boss-page-dashboard__meta-text boss-page-dashboard__meta-text-accent-green">
              &nbsp;FINISHED
            </span>
            {isAdminPlus && (
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-button_role_undo-light boss-table__action"
                style={{ marginLeft: '15px' }}
                text="Undo Completion"
                pendingText="Processing ..."
                onClick={() => onUndoFinanceReportWeek({ financeReportWeekId })}
              />
            )}
          </p>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_details">
            <span className="boss-page-dashboard__meta-text">
              Finished by {finishedBy} at {formattedFinishedAt}
            </span>
          </p>
        </Fragment>
      );
    }
    return (
      <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
        <span className="boss-page-dashboard__meta-text">Week Status: </span>
        <span className="boss-page-dashboard__meta-text">
          &nbsp;{financeReportWeekStatusText.toUpperCase()}
        </span>
      </p>
    );
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_finance-reports">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            {
              <div className="boss-page-dashboard__buttons-group">
                {showPDFDownloadLink && (
                  <a
                    href={appRoutes.financeReportsPdfDownload({ date: mWeekStartDate, venueId, filterType })}
                    className="boss-button boss-button_role_download boss-page-dashboard__button"
                  >
                    Download PDF
                  </a>
                )}
                {showCSVDownloadLink && !disableExportToCSVDueToFilterType && (
                  <Fragment>
                    <a
                      href={appRoutes.financeReportsPaymentCSVExport({
                        date: mWeekStartDate,
                        venueId,
                        filterType,
                      })}
                      className="boss-button boss-button_role_download boss-page-dashboard__button"
                    >
                      Export CSV
                    </a>
                    <a
                      href={appRoutes.financeReportsDeductionCSVExport({
                        date: mWeekStartDate,
                        venueId,
                        filterType,
                      })}
                      className="boss-button boss-button_role_download boss-page-dashboard__button"
                    >
                      Download Deductions CSV
                    </a>
                  </Fragment>
                )}
                {showCSVDownloadLink && disableExportToCSVDueToFilterType && (
                  <a
                    disabled="disabled"
                    onClick={showWrongFilterCSVDownloadModal}
                    className="boss-button boss-button_role_download boss-page-dashboard__button"
                  >
                    Export CSV
                  </a>
                )}
                {showDownloadBulkPaymentsFile && (
                  <a
                    href={appRoutes.venueBulkPaymentsDownload({ mWeekStartDate, venueId })}
                    className="boss-button boss-button_role_download boss-page-dashboard__button"
                  >
                    Download Payments File
                  </a>
                )}
              </div>
            }
          </div>

          <div
            style={{
              border: '1px solid #e5e5e5',
              borderRadius: '20px',
              padding: '10px',
            }}
          >
            <h3>
              <u>Paid by Modulr Totals</u>
            </h3>

            <h4>Hourly</h4>
            <div className="boss-page-dashboard__group">
              <div className="boss-table boss-table_page_finance-reports-overview">
                <div className="boss-table__row">
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Calculated Total Gross:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {hourlyCalculatedTotalGrossText}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Total Gross Uploaded:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {hourlyTotalGrossUploadedText}{' '}
                            {hourlyGrossDeltaText && (
                              <span className={hourlyGrossDeltaTextClass}>({hourlyGrossDeltaText})</span>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Total Net Wages:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {hourlyTotalNetWagesText}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4>Salary</h4>
            <div className="boss-page-dashboard__group">
              <div className="boss-table boss-table_page_finance-reports-overview">
                <div className="boss-table__row">
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Calculated Total Gross:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {salaryCalculatedTotalGrossText}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Total Gross Uploaded:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {salaryTotalGrossUploadedText}{' '}
                            {salaryGrossDeltaText && (
                              <span className={salaryGrossDeltaTextClass}>({salaryGrossDeltaText})</span>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Total Net Wages:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {salaryTotalNetWagesText}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4>Grand Total</h4>
            <div className="boss-page-dashboard__group">
              <div className="boss-table boss-table_page_finance-reports-overview">
                <div className="boss-table__row">
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Calculated Total Gross:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {calculatedTotalGross}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Total Gross Uploaded:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {totalGrossUploaded}{' '}
                            {totalGrossDelta && (
                              <span className={totalGrossDeltaTextClass}>({totalGrossDelta})</span>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">Net Wages:</p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {utils.centsFormat(totalNetWagesCents)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-page-dashboard__group">
              <div className="boss-table boss-table_page_finance-reports-overview">
                <div className="boss-table__row">
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">Completed:</p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {completedFinanceReportsCount} / {financeReportsCount}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Staff with Payslips:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {`${oFetch(staffPayslipsStats, 'payableReportWithPayslipCount')} / ${oFetch(
                              staffPayslipsStats,
                              'payableReportCount',
                            )}`}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__info">
                      <div className="boss-number boss-number_layout_flow">
                        <div className="boss-number__content">
                          <p className="boss-number__label boss-number__label_position_before">
                            Setup for Payment:
                          </p>
                          <h3 className="boss-number__value boss-number__value_size_s">
                            {`${oFetch(staffPayslipsStats, 'payableReportWithPayslipAndBankDetailsCount')} / ${oFetch(
                              staffPayslipsStats,
                              'payableReportWithPayslipCount',
                            )}`}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__sub-group">
              <div className="boss-page-dashboard__meta">
                <Popover
                  isOpen={isCalendarOpen}
                  body={renderCalendar()}
                  appendTarget={document.body}
                  place="below"
                  tipSize={0.01}
                  onOuterAction={togglePopover}
                  className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                  style={{ marginTop: '10px' }}
                >
                  <p className={popoverClass} onClick={togglePopover}>
                    <span className="boss-page-dashboard__meta-text">{formattedStartDate}</span>
                    {' - '}
                    <span className="boss-page-dashboard__meta-text">{formattedEndDate}</span>
                  </p>
                </Popover>
              </div>
              <div className="boss-page-dashboard__meta">
                {renderFinanceReportWeekStatus()}
                <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
                  <span className="boss-page-dashboard__meta-text">Payments Week:</span>
                  <span className={payWeekStatusTextClass}>&nbsp;{payWeekStatusText}</span>
                </p>
              </div>
            </div>
            <div className="boss-page-dashboard__buttons-group">{renderMarkFinanceReportWeekButton()}</div>
            <div className="boss-page-dashboard__controls-group">
              <FinanceReportsFilter onFilterChange={onFilterChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
