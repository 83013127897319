import React from 'react';
import oFetch from 'o-fetch';
import { RadioGroup, Radio } from '@/components/radio-group';

export default function WeekFilter(props) {
  const disabled = oFetch(props, 'disabled');

  function handleFilterChange(value) {
    const onChange = oFetch(props, 'onChange');
    onChange(value);
  }

  function renderWeekDays() {
    const weekDates = oFetch(props, 'weekDates');
    const week = Object.entries(weekDates).map(dayEntry => {
      const [uiDate, weekDay] = dayEntry;
      const [weekDayName, count] = oFetch(weekDay, 'name', 'count');
      return (
        <label key={weekDayName} className="boss-form__switcher-label">
          <Radio value={uiDate} disabled={disabled} className="boss-form__switcher-radio" />
          <span className="boss-form__switcher-label-text">{`${weekDayName} (${count})`}</span>
        </label>
      );
    });
    return week;
  }

  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <div className="boss-form__field boss-form__row_position_last">
          <RadioGroup
            name="weekFilter"
            selectedValue={oFetch(props, 'sDate')}
            onChange={handleFilterChange}
            className="boss-form__switcher boss-form__switcher_role_week"
          >
            {renderWeekDays()}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
