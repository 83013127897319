import { createAction } from 'redux-actions';
import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form/immutable';

import * as types from './types';
import * as constants from '../constants';

export const loadDisciplinariesSuceed = createAction(types.LOAD_DISCIPLINARIES_SUCCEED);
export const addDisciplinarySucceed = createAction(types.ADD_DISCIPLINARY_SUCCEED);
export const disableDisciplinarySucceed = createAction(types.DISABLE_DISCIPLINARY_SUCCEED);

export const addDisciplinary = params => (dispatch, getState) => {
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['conduct', 'consequence', 'nature', 'title'],
        });
        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addDisciplinarySucceed(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/disciplinaries`, {
    ...params,
  });
};

export const disableDisciplinary = ({ disciplinaryId }) => (dispatch, getState) => {
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);
  const show = getState().getIn(['filter', 'show']);
  const isShowDisabled = show.includes(constants.DISABLED);

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(disableDisciplinarySucceed({ ...data, isShowDisabled }));
    },
  }).delete(`/api/v1/staff_members/${staffMemberId}/disciplinaries/${disciplinaryId}`);
};

export const loadDisciplinaries = ({ queryString, changeURL }) => (dispatch, getState) => {
  const staffMemberId = getState().getIn(['profile', 'staffMember', 'id']);

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const startDate = oFetch(data, 'filter.start_date');
      const endDate = oFetch(data, 'filter.end_date');
      const disabled = oFetch(data, 'filter.show_disabled');
      const expired = oFetch(data, 'filter.show_expired');
      const show = [expired && constants.EXPIRED, disabled && constants.DISABLED].filter(value => value);
      dispatch(loadDisciplinariesSuceed(data));
      changeURL({ startDate, endDate, show });
    },
  }).get(`/api/v1/staff_members/${staffMemberId}/disciplinaries${queryString}`);
};
