import { createAction } from 'redux-actions';
import * as types from './types';

import oFetch from 'o-fetch';

import {
  markReportCompletedRequest,
  markReportsCompletedRequest,
  getBankDetailsRequest,
  wipeBankDetailsRequest,
  uncompleteFinanceReportRequest,
  updateFinanceReportNoteRequest,
  finishFinanceReportWeekRequest,
  undoFinanceReportWeekRequest,
} from '../requests';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const setReportStatusDone = createAction(types.SET_REPORT_STATUS_DONE);
export const changePayRateFilter = createAction(types.CHANGE_PAY_RATE_FILTER);
export const markReportsCompletedAction = createAction(types.MARK_REPORTS_COMPLETED);
export const updateFinanceReportAction = createAction(types.UPDATE_FINANCE_REPORT);
export const updateFinanceReportWeekAction = createAction(types.UPDATE_FINANCE_REPORT_WEEK);
export const wipeStaffMemberBankDetailsAction = createAction(types.WIPE_BANK_DETAILS);

export const markReportCompleted = options => (dispatch, getState) => {
  const pageStateJS = getState().get('page').toJS();
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const staffMemberId = oFetch(options, 'staffMemberId');

  return markReportCompletedRequest({ date, staffMemberId, venueId }).then(response => {
    dispatch(markReportsCompletedAction(response.data));
    dispatch(updateFinanceReportWeekAction(response.data));
  });
};

export const finishFinanceReportWeek = params => (dispatch, getState) => {
  return finishFinanceReportWeekRequest({
    values: params,
    onSuccess(data) {
      dispatch(updateFinanceReportWeekAction(data));
    },
  });
};

export const undoFinanceReportWeek = params => (dispatch, getState) => {
  return undoFinanceReportWeekRequest({
    values: params,
    onSuccess(data) {
      dispatch(updateFinanceReportWeekAction(data));
    },
  });
};

export const uncompleteFinanceReport = options => (dispatch, getState) => {
  const pageStateJS = getState().get('page').toJS();
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const staffMemberId = oFetch(options, 'staffMemberId');
  const onSuccess = oFetch(options, 'onSuccess');

  return uncompleteFinanceReportRequest({
    values: { date, staffMemberId, venueId },
    onSuccess(data) {
      onSuccess();
      dispatch(updateFinanceReportAction(data));
      dispatch(updateFinanceReportWeekAction(data));
    },
  });
};

export const updateFinanceReportNote = params => (dispatch, getState) => {
  const pageStateJS = getState().get('page').toJS();
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const staffMemberId = oFetch(params, 'staffMemberId');
  const note = oFetch(params, 'note');
  const onSuccess = oFetch(params, 'onSuccess');

  return updateFinanceReportNoteRequest({
    values: { date, staffMemberId, venueId, note },
    onSuccess(data) {
      onSuccess();
      dispatch(updateFinanceReportAction(data));
    },
    onFailure() {},
  });
};

export const getBankDetailsAction = params => (dispatch, getState) => {
  return getBankDetailsRequest(params);
};

export const wipeBankDetailsAction = params => (dispatch, getState) => {
  const [values, onSuccessComponentLevel] = oFetch(params, 'values', 'onSuccess');
  const staffMemberId = oFetch(values, 'staffMemberId');

  return wipeBankDetailsRequest({
    values,
    onSuccess() {
      dispatch(wipeStaffMemberBankDetailsAction({ staffMemberId }));
      onSuccessComponentLevel();
    },
  });
};

export const markReportsCompleted = options => (dispatch, getState) => {
  const pageStateJS = getState().get('page').toJS();
  const date = oFetch(pageStateJS, 'date');
  const venueId = oFetch(pageStateJS, 'venueId');
  const staffMemberIds = oFetch(options, 'staffMemberIds');

  return markReportsCompletedRequest({ date, staffMemberIds, venueId }).then(response => {
    dispatch(markReportsCompletedAction(response.data));
    dispatch(updateFinanceReportWeekAction(response.data));
  });
};
