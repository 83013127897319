import React from 'react';
import oFetch from 'o-fetch';
import { ResultJson } from './result-json';
import { MatchedStaffMemberItem } from './matched-staff-member-item';

export function Result(props) {
  const result = oFetch(props, 'result');
  const [faceDetails, staffMemberFaceMatches, imageUrl, matchedStaffMembers, faceIdsWithoutMatches] = oFetch(
    result,
    'faceDetails',
    'staffMemberFaceMatches',
    'imageUrl',
    'matchedStaffMembers',
    'faceIdsWithoutMatches',
  );
  const matchedStaffMembersExist = matchedStaffMembers.length > 0;
  const faceIdsWithoutMatchesExist = faceIdsWithoutMatches.length > 0;

  return (
    <div style={{ marginBottom: '20px', width: '100%' }}>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-evenly' }}>
        <div>
          <img src={imageUrl} width={'450px'} />
        </div>
        {!faceDetails && (
          <div>
            <div className="boss-alert boss-alert_role_area boss-alert_context_above">
              <p className="boss-alert__text">{`Face Details Not Found`}</p>
            </div>
          </div>
        )}
        {faceDetails && <ResultJson json={faceDetails} title={'Face Details'} />}
      </div>
      <div className="boss-table boss-table_page_staff-members-index" style={{ marginBottom: '20px' }}>
        <div className="boss-table__row">
          <div className="boss-table__cell boss-table__cell_role_header" />
          <div className="boss-table__cell boss-table__cell_role_header">Name</div>
          <div className="boss-table__cell boss-table__cell_role_header">Status</div>
          <div className="boss-table__cell boss-table__cell_role_header">Type</div>
          <div className="boss-table__cell boss-table__cell_role_header">Master Venue</div>
          <div className="boss-table__cell boss-table__cell_role_header">Work Venues</div>
        </div>
        {!matchedStaffMembersExist && (
          <p
            className="boss-page-main__text-placeholder"
            style={{ marginTop: '20px' }}
          >{`No Matched Staff Members Found`}</p>
        )}
        {matchedStaffMembers.map((staffMember, index) => {
          const staffMemberId = oFetch(staffMember, 'id');
          return (
            <MatchedStaffMemberItem
              key={index}
              staffMember={staffMember}
              faceMatch={staffMemberFaceMatches[staffMemberId]}
            />
          );
        })}
      </div>
      {faceIdsWithoutMatchesExist && (
        <ResultJson json={faceIdsWithoutMatches} title={'Face IDS without matches'} />
      )}
    </div>
  );
}
