import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';
import * as commonTypes from '../../../profile-wrapper/constants';

const initialState = [];

export default handleActions(
  {
    [commonTypes.INITIAL_LOAD]: (state, action) => {
      const quizCategoryHistory = oFetch(action, 'payload.quizCategoryHistory');
      return quizCategoryHistory;
    },
    [types.UPDATE_HISTORY]: (state, action) => {
      const quizCategoryHistoryEvents = oFetch(action, 'payload.quizCategoryHistoryEvents');
      return quizCategoryHistoryEvents;
    },
  },
  initialState,
);
