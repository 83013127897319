import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';

export function ban(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const deviceId = oFetch(values, 'deviceId');

  return bossRequestHttp({
    successHandler(params) {
      const device = oFetch(params, 'data.device');
      onSuccess(device);
    },
    errorHandler() {},
  }).post(`/api/v1/devices/${deviceId}/ban`);
}

export function unban(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const deviceId = oFetch(values, 'deviceId');

  return bossRequestHttp({
    successHandler(params) {
      const device = oFetch(params, 'data.device');
      onSuccess(device);
    },
    errorHandler() {},
  }).post(`/api/v1/devices/${deviceId}/unban`);
}

export function acceptPermissionRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const deviceId = oFetch(values, 'deviceId');

  return bossRequestHttp({
    successHandler(params) {
      const device = oFetch(params, 'data.device');
      onSuccess(device);
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).post(`/api/v1/devices/${deviceId}/accept_permission_request`);
}

export function disablePermission(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const deviceId = oFetch(values, 'deviceId');

  return bossRequestHttp({
    successHandler(params) {
      const [device, venues] = oFetch(params, 'data.device', 'data.venues');
      onSuccess(device, venues);
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'clockingApplicationApp'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).post(`/api/v1/devices/${deviceId}/disable_permission`);
}

export function rejectPermissionRequest(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const deviceId = oFetch(values, 'deviceId');

  return bossRequestHttp({
    successHandler(params) {
      const [device, venues] = oFetch(params, 'data.device', 'data.venues');

      onSuccess(device, venues);
    },
    errorHandler() {},
  }).post(`/api/v1/devices/${deviceId}/reject_permission_request`);
}

export function updateCustomName(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [deviceId, customDeviceName] = oFetch(values, 'deviceId', 'customDeviceName');

  return bossRequestHttp({
    successHandler(params) {
      const device = oFetch(params, 'data.device');
      onSuccess(device);
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      onFailure();

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'customDeviceName'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).put(`/api/v1/devices/${deviceId}/update_custom_name`, { customDeviceName });
}

export function addManualPermission(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [deviceId, venueId] = oFetch(values, 'deviceId', 'venueId');

  return bossRequestHttp({
    successHandler(params) {
      const [device, venues] = oFetch(params, 'data.device', 'data.venues');
      onSuccess(device, venues);
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      onFailure();

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'venueId'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).post(`/api/v1/devices/${deviceId}/create_manual_permission`, { venueId });
}

export function changePermissionRequestVenue(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [deviceId, venueId] = oFetch(values, 'deviceId', 'venueId');

  return bossRequestHttp({
    successHandler(params) {
      const [device, venues] = oFetch(params, 'data.device', 'data.venues');
      onSuccess(device, venues);
    },
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      onFailure();

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'venueId'],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
    },
  }).post(`/api/v1/devices/${deviceId}/change_venue`, { venueId });
}
