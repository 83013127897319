import React from 'react';
import ContactForm from './contact-form';
import oFetch from 'o-fetch';

export default function EditContact(props) {
  const [onSubmit, tagOptions, contact, onCreateContactTag, permissions] = oFetch(
    props,
    'onSubmit',
    'tagOptions',
    'contact',
    'onCreateContactTag',
    'permissions',
  );

  const initialValues = {
    id: oFetch(contact, 'id'),
    imageHasChanged: false,
    firstName: oFetch(contact, 'firstName'),
    surname: oFetch(contact, 'surname'),
    image: oFetch(contact, 'isDefaultImage') ? null : oFetch(contact, 'imageUrl'),
    tags: oFetch(contact, 'tagIds'),
  };
  const canCreateTags = oFetch(props, 'permissions.canCreateTags');

  return (
    <ContactForm
      onSubmit={onSubmit}
      tagOptions={tagOptions}
      initialValues={initialValues}
      buttonTitle="Update Contact"
      onCreateContactTag={onCreateContactTag}
      canCreateTags={canCreateTags}
    />
  );
}
