import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';

export function createBonaiEligibility(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [weekStart, reason, venueId, staffMemberId] = oFetch(
    values,
    'weekStart',
    'reason',
    'venueId',
    'staffMemberId',
  );
  const routePath = apiRoutes.createBonaiEligibility.getPath();
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['reason'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(routePath, {
    weekStart: weekStart,
    reason: reason,
    venueId: venueId,
    staffMemberId: staffMemberId,
  });
}

export function neutraliseEligibilityOverride(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const bonaiEligibilityOverrideId = oFetch(values, 'bonaiEligibilityOverrideId');
  const routePath = apiRoutes.neutraliseEligibilityOverride.getPath();
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(routePath, {
    bonaiEligibilityOverrideId: bonaiEligibilityOverrideId,
  });
}

export function updateVnueBonaiCents(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [bonaiCents, weekStart, venueId] = oFetch(values, 'bonaiCents', 'weekStart', 'venueId');
  const routePath = apiRoutes.updateVenueBonaiCents.getPath();

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(routePath, {
    bonaiCents,
    weekStart,
    venueId,
  });
}
