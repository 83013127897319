import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import oFetch from 'o-fetch';

import { BossFormCheckbox, BossFormTextarea, BossFormSelectNew } from '@/components/boss-form';

function renderBaseErrors(errors) {
  if (!errors) {
    return null;
  }
  return (
    <div className="boss-modal-window__alert">
      <div className="boss-alert boss-alert_role_area boss-alert_context_above">
        {errors.map((error, index) => {
          return (
            <p key={index} className="boss-alert__text">
              {error}
            </p>
          );
        })}
      </div>
    </div>
  );
}

function QuestionForm(props) {
  const submitButtonText = oFetch(props, 'submitButtonText');
  const [questionTypesOptions, questionCategoriesOptions, questionAreasOptions, handleSubmit, submitting] = oFetch(
    props,
    'questionTypesOptions',
    'questionCategoriesOptions',
    'questionAreasOptions',
    'handleSubmit',
    'submitting',
  );

  const { error } = props;

  return (
    <div className="boss-modal-window__content">
      {renderBaseErrors(error)}
      <div className="boss-modal-window__form">
        <div className="boss-form">
          <Field name="text" label="Question" component={BossFormTextarea} />
          <div className="boss-form__row">
            <Field
              name="category"
              label="Category"
              options={questionCategoriesOptions}
              component={BossFormSelectNew}
              fieldClassName="boss-form__field_layout_half"
            />
            <Field
              name="area"
              label="Area"
              options={questionAreasOptions}
              component={BossFormSelectNew}
              fieldClassName="boss-form__field_layout_half"
            />
          </div>

          <div className="boss-form__row">
            <Field
              name="type"
              label="Type"
              options={questionTypesOptions}
              component={BossFormSelectNew}
              fieldClassName="boss-form__field_layout_half"
            />
            <Field
              name="required"
              disabled
              label="Required (Failing this fails the entire category)"
              component={BossFormCheckbox}
              className="boss-form__field_layout_half boss-form__field_no-label"
            />
          </div>

          <div className="boss-form__field">
            <button
              onClick={handleSubmit}
              disabled={submitting}
              className="boss-button boss-button_role_add boss-form__submit"
              type="submit"
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  onChange(values, dispatch, props) {
    const requiredQuestionType = oFetch(props, 'requiredQuestionType');
    const formName = oFetch(props, 'form');
    const { type } = values;
    dispatch(change(formName, 'required', type === requiredQuestionType));
  },
})(QuestionForm);
