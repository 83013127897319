import { applyBossRoute } from "@/lib/apply-boss-route";
import * as apiRoutes from '@/lib/api-routes';
import { bossRequestHttp } from "@/lib/request-api";
import { WhatsappInstanceFormValues } from "./components/forms/whatsapp-instance-form";
import oFetch from "o-fetch";
import { WhatsappServerFormValues } from "./components/forms/whatsapp-server-form";
import utils from "@/lib/utils";
import { $CreateWhatsappInstanceRequestValidationErrors, $CreateWhatsappServerRequestValidationErrors, $UpdateWhatsappInstanceCustomNameRequestValidationErrors, CheckWhatsappInstanceAbilitySuccessData, CreateWhatsappInstanceRequestValidationErrors, CreateWhatsappInstanceSuccessData, CreateWhatsappServerRequestValidationErrors, CreateWhatsappServerSuccessData, RestartWhatsappServerSuccessData, UpdateWhatsappInstanceCustomNameRequestValidationErrors, UpdateWhatsappInstanceCustomNameSuccessData, WhatsappInstance } from "./types";
import { WhatsappInstanceCustomNameFormValues } from "./components/forms/whatsapp-instance-custom-name-form";

export type CheckWhatsappInstanceParams = {
  values: WhatsappInstanceFormValues;
  onSuccess: (data: CheckWhatsappInstanceAbilitySuccessData) => void;
  onFailure: () => void;
};

export type CreateWhatsappServerParams = {
  values: WhatsappServerFormValues;
  onSuccess: (data: CreateWhatsappServerSuccessData) => void;
  onFailure: () => void;
};

export type RestartWhatsappServerParams = {
  whatsappServerId: number;
  onSuccess: (data: RestartWhatsappServerSuccessData) => void;
  onFailure: () => void;
};

export type DisableWhatsappInstanceParams = {
  whatsappInstance: WhatsappInstance;
  onSuccess: () => void;
  onFailure: () => void;
};

export type UpdateWhatsappInstanceCustomNameParams = {
  values: WhatsappInstanceCustomNameFormValues;
  onSuccess: (data: UpdateWhatsappInstanceCustomNameSuccessData) => void;
  onFailure: () => void;
};

export type CreateWhatsappInstanceParams = {
  values: WhatsappInstanceFormValues;
  onSuccess: (data: CreateWhatsappInstanceSuccessData) => void;
  onFailure: () => void;
};

export function checkWhatsappInstance(params: CheckWhatsappInstanceParams) {
  const { values, onFailure, onSuccess } = params;
  const { baseUrl, instanceHash } = values;

  const route = apiRoutes.checkWhatsappServiceAvailability(baseUrl, instanceHash);

  const bossRequestInstance = bossRequestHttp({
    successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
      const rawData = oFetch(params, 'data');
      const data: CheckWhatsappInstanceAbilitySuccessData = route.$SuccessData.parse(rawData);
      onSuccess(data);
    },
    errorHandler() {
      onFailure();
      return true;
    }
  });

  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: values,
    pathParams: {},
  });
}

export function createWhatsappServer(params: CreateWhatsappServerParams) {
  const { values, onFailure, onSuccess } = params;

  const route = apiRoutes.createWhatsappServer;

  const bossRequestInstance = bossRequestHttp({
    successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
      const rawData = oFetch(params, 'data');
      const data: CreateWhatsappServerSuccessData = route.$SuccessData.parse(rawData);
      onSuccess(data);
    },
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      if (params.statusCode === 422) {
        return utils.normalizeFinalFormErrors<CreateWhatsappServerRequestValidationErrors>($CreateWhatsappServerRequestValidationErrors.parse(params.errors));
      }

      return false;
    },
  });

  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: values,
    pathParams: {},
  });
}

export function restartWhatsappServer(params: RestartWhatsappServerParams) {
  const { whatsappServerId, onFailure, onSuccess } = params;

  const route = apiRoutes.restartWhatsappServer;

  const bossRequestInstance = bossRequestHttp({
    successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
      const rawData = oFetch(params, 'data');
      const data: RestartWhatsappServerSuccessData = route.$SuccessData.parse(rawData);
      onSuccess(data);
    },
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;
      onFailure();
      if (params.statusCode === 422) {
        globalNotifications.showDefaultFailureMessage({ message: params.errors.base });
        return true;
      } else {
        globalNotifications.showDefaultFailureMessage();
      }

      return false;
    },
  });

  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: {},
    pathParams: {
      whatsappServerId
    },
  });
}

export function disableWhatsappInstance(params: DisableWhatsappInstanceParams) {
  const { whatsappInstance, onFailure, onSuccess } = params;

  const route = apiRoutes.disableWhatsappInstance;

  const bossRequestInstance = bossRequestHttp({
    successHandler() {
      onSuccess();
    },
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;
      globalNotifications.showDefaultFailureMessage();
      onFailure();

      return false;
    },
  });

  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: {},
    pathParams: {
      whatsappServerId: whatsappInstance.whatsappServerId,
      whatsappInstanceId: whatsappInstance.id,
    },
  });
}

export function updateWhatsappInstanceCustomName(params: UpdateWhatsappInstanceCustomNameParams) {
  const { values, onFailure, onSuccess } = params;

  const route = apiRoutes.updateWhatsappInstanceCustomName;

  const bossRequestInstance = bossRequestHttp({
    successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
      const rawData = oFetch(params, 'data');
      const data: CreateWhatsappServerSuccessData = route.$SuccessData.parse(rawData);
      onSuccess(data);
    },
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      if (params.statusCode === 422) {
        return utils.normalizeFinalFormErrors<UpdateWhatsappInstanceCustomNameRequestValidationErrors>($UpdateWhatsappInstanceCustomNameRequestValidationErrors.parse(params.errors));
      }

      return false;
    },
  });
  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: {
      customName: values.customName,
    },
    pathParams: {
      whatsappServerId: values.whatsappServerId,
      whatsappInstanceId: values.whatsappInstanceId
    },
  });
}

export function createWhatsappInstance(params: CreateWhatsappInstanceParams) {
  const { values, onFailure, onSuccess } = params;

  const route = apiRoutes.createWhatsappInstance;

  const bossRequestInstance = bossRequestHttp({
    successHandler(params: any) {
      const rawData = oFetch(params, 'data');
      const data: CreateWhatsappInstanceSuccessData = route.$SuccessData.parse(rawData);
      onSuccess(data);
    },
    errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
      const globalNotifications = params.globalNotifications;
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      if (params.statusCode === 422) {
        return utils.normalizeFinalFormErrors<CreateWhatsappInstanceRequestValidationErrors>(
          $CreateWhatsappInstanceRequestValidationErrors.parse(params.errors)
        );
      }

      return false;
    },
  });

  return applyBossRoute({
    bossHttpRequest: bossRequestInstance,
    route,
    callParams: values,
    pathParams: {
      whatsappServerId: values.whatsappServerId
    },
  });
}
