import React from 'react';
import oFetch from 'o-fetch';
import humanize from 'string-humanize';

class FruitOrderFields extends React.Component {
  render() {
    const fruitOrderFields = oFetch(this.props.venue, 'fruitOrderFields');
    return (
      <div className="boss-modal-window__group">
        {fruitOrderFields.map(field => (
          <div key={field} className="boss-count boss-count_adjust_tags">
            <p className="boss-count__label">{humanize(field)}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default FruitOrderFields;
