import React from 'react';
import oFetch from 'o-fetch';

export default function CategoriesHeader(props) {
  const children = oFetch(props, 'children');
  return (
    <header className="boss-board__header">
      <h2 className="boss-board__title">Categories</h2>
      {children}
    </header>
  );
}
