import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';
import { UploadFilesFormField } from './upload-files-form-field';
import { deleteUploadedImageFile, uploadImageFile } from '../../requests';

export function RequestClarificationForm(props) {
  const initialValues = oFetch(props, 'initialValues');
  const [onSubmit, onClose] = oFetch(props, 'onSubmit', 'onClose');

  return (
    <>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          <span className="boss-modal-window__message-text-line">
            A request will be made to re-answer the question in a more clear way.
          </span>
          <span className="boss-modal-window__message-text-line">
            Please consider leaving a message below to explain what is needed to correctly answer this
            question below.
          </span>
        </span>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <>
              <div className="boss-form__field">
                <Field name="text" label="Answer" parse={value => value} component={BossFormTextarea} />
              </div>
              <div className="boss-form__field">
                <Field
                  name="fileIds"
                  label="Files (Optional)"
                  component={UploadFilesFormField}
                  onUpload={uploadImageFile}
                  onDelete={deleteUploadedImageFile}
                  files={{}}
                />
              </div>
              <div className="boss-form__field boss-form__field_role_controls">
                <button
                  onClick={onClose}
                  type="button"
                  className="boss-button boss-button_role_inactive boss-form__button boss-form__button_adjust_row"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                >
                  Confirm
                </button>
              </div>
            </>
          );
        }}
      />
    </>
  );
}
