import React from 'react';
import oFetch from 'o-fetch';
import { UITagsField, UISelectField } from '../ui-fields';

export default function TagsField(props) {
  const [label, placeholder, options, valueKey, labelKey, onCreateTag, canCreateTags] = oFetch(
    props,
    'label',
    'placeholder',
    'options',
    'valueKey',
    'labelKey',
    'onCreateTag',
    'canCreateTags',
  );
  const [input, meta] = oFetch(props, 'input', 'meta');
  const [onChange, value, name] = oFetch(input, 'onChange', 'value', 'name');
  const { submitError } = meta;
  const errors = submitError || [];

  if (canCreateTags) {
    return (
      <UITagsField
        label={label}
        valueKey={valueKey}
        labelKey={labelKey}
        placeholder={placeholder}
        onChange={onChange}
        onCreateTag={tag => onCreateTag({ tag })}
        value={value}
        options={options}
        name={name}
        errors={errors}
      />
    );
  } else {
    return (
      <UISelectField
        label={label}
        valueKey={valueKey}
        labelKey={labelKey}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        isMulti
        name={name}
        errors={errors}
      />
    );
  }
}

TagsField.defaultProps = {
  label: null,
  placeholder: null,
  valueKey: 'value',
  labelKey: 'label',
};
