import React from 'react';
import oFetch from 'o-fetch';

export default function ChecklistTitleField(props) {
  const [input, meta, inputRef] = oFetch(props, 'input', 'meta', 'inputRef');

  const [value, onChange, onFocus] = oFetch(input, 'value', 'onChange', 'onFocus');

  return (
    <div className="boss-board__field">
      <input
        ref={inputRef}
        onFocus={onFocus}
        name="checklist-name"
        value={value}
        onChange={onChange}
        type="text"
        placeholder="Type checklist name here..."
      />
    </div>
  );
}
