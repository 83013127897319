import React, { useState } from 'react';
import oFetch from 'o-fetch';

export function PublishRotaModal(props) {
  const [initialTradingData, onSubmit, onClose] = oFetch(
    props,
    'tradingData',
    'onSubmit',
    'onClose',
  );
  const [tradingData, setTradingData] = useState(initialTradingData);
  const [processing, setProcessing] = useState(false);

  async function handleSubmit() {
    const normalizedTradingData = tradingData.reduce((acc, tradingDay) => {
      const [name, trading, deliveryDay] = oFetch(tradingDay, 'name', 'trading', 'deliveryDay');
      acc[name] = {
        trading,
        deliveryDay,
      };
      return acc;
    }, {});
    setProcessing(true);
    await onSubmit(normalizedTradingData);
  }

  function handleDayChange(params) {
    const [name, trading, deliveryDay] = oFetch(params, 'name', 'trading', 'deliveryDay');
    setTradingData(tradingData => {
      return tradingData.map(tradingDay => {
        if (oFetch(tradingDay, 'name') === name) {
          return {
            ...tradingDay,
            trading,
            deliveryDay
          };
        }
        return tradingDay;
      });
    });
  }

  function renderTradingDay(tradingDay) {
    const [trading, deliveryDay, name] = oFetch(tradingDay, 'trading', 'deliveryDay', 'name');
    return (
      <div key={name} className="boss-form__group boss-form__group_role_section">
        <div className="boss-form__row boss-form__row_align_center boss-form__row_layout_nowrap">
          <div className="boss-form__field boss-form__field_layout_max">
            <p className="boss-form__text">{name}</p>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <label className="boss-form__checkbox-label">
              <input
                value={trading}
                type="checkbox"
                className="boss-form__checkbox-input"
                onChange={() => handleDayChange({ trading: !trading, deliveryDay, name })}
                checked={trading}
              />
              <span className="boss-form__checkbox-label-text">Trading</span>
            </label>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <label className="boss-form__checkbox-label">
              <input
                value={deliveryDay}
                type="checkbox"
                className="boss-form__checkbox-input"
                onChange={() => handleDayChange({ trading, deliveryDay: !deliveryDay, name })}
                checked={deliveryDay}
              />
              <span className="boss-form__checkbox-label-text">Delivery Day</span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="boss-modal-window__form">
      <h3 className="boss-form__group-title">Trading Days</h3>

      <form action="#" className="boss-form">
        <div className="boss-form__group">
          {tradingData.map(tradingItem => {
            return renderTradingDay(tradingItem);
          })}
        </div>
        <div className="boss-form__field">
          <button
            onClick={handleSubmit}
            type="button"
            disabled={processing}
            className="boss-button boss-button_role_confirm"
          >
            Publish
          </button>
        </div>
      </form>
    </div>
  );
}
