import React, { Component } from 'react';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import cn from 'classnames';
import utils from '@/lib/utils';
import ScrollWrapper from './scroll-wrapper';

const moment = extendMoment(Moment);

export function Keys(params) {
  return (
    <div className="boss-planner__keys">
      <p className="boss-planner__keys-label">Key:</p>
      <ul className="boss-planner__keys-list">
        <li className="boss-planner__keys-item boss-planner__keys-item_role_event">
          <p className="boss-planner__keys-text">Event</p>
        </li>
        <li className="boss-planner__keys-item boss-planner__keys-item_role_reminder">
          <p className="boss-planner__keys-text">Reminder</p>
        </li>
        <li className="boss-planner__keys-item boss-planner__keys-item_role_task">
          <p className="boss-planner__keys-text">Task</p>
        </li>
      </ul>
    </div>
  );
}

export const MONTH_VIEW = 'month';
export const WEEK_VIEW = 'week';
export const VIEWS = [MONTH_VIEW, WEEK_VIEW];

export default class Calendar extends Component {
  state = {
    currentView: MONTH_VIEW,
    currentDate: moment(),
  };

  constructor(props) {
    super(props);
    const { defaultView } = this.props;
    if (defaultView && !VIEWS.includes(defaultView)) {
      throw new Error(`View must be in [${VIEWS.join(',')}]. Got: '${defaultView}'`);
    }
    const mDate = this.getDateFromProps();

    this.state = {
      currentView: defaultView || MONTH_VIEW,
      currentDate: mDate,
    };
  }

  getDateFromProps = () => {
    const { date, format } = this.props;
    if (date && format) {
      if (!utils.isString(date)) {
        throw new Error('Date must be a string');
      }
      if (!moment(date, format).isValid()) {
        throw new Error('Format or date are invalid');
      }
      return moment(date, format);
    } else if (date) {
      if (!moment.isMoment(date)) {
        throw new Error('Date is invalid moment object');
      }
      return date;
    } else {
      return moment();
    }
  };

  renderCurrentView = currentDate => {
    const { currentView } = this.state;
    if (currentView === MONTH_VIEW) {
      return this.renderMonthView(currentDate);
    }
    if (currentView === WEEK_VIEW) {
      return this.renderWeekView(currentDate);
    }
  };

  getMonthRange = currentDate => {
    const mMonthStart = this.getFirstDayOfMonthMoment(currentDate);
    const mMonthEnds = this.getLastDayOfMonthMoment(currentDate);
    return moment.range(mMonthStart, mMonthEnds);
  };

  getFirstDayOfMonthMoment = currentDate => {
    return currentDate.clone().startOf('month');
  };

  getLastDayOfMonthMoment = currentDate => {
    return currentDate.clone().endOf('month');
  };

  getWeekRange = currentDate => {
    const mWeekStart = this.getFirstDayOfWeekMoment(currentDate);
    const mWeekEnds = this.getLastDayOfWeekMoment(currentDate);
    return moment.range(mWeekStart, mWeekEnds);
  };

  getFirstDayOfWeekMoment = currentDate => {
    return currentDate.clone().startOf('isoWeek');
  };

  getLastDayOfWeekMoment = currentDate => {
    return currentDate.clone().endOf('isoWeek');
  };

  renderBlank = day => {
    const blankRenderer = oFetch(this.props, 'blankRenderer');
    return blankRenderer(day);
  };

  renderDay = day => {
    const dayRenderer = oFetch(this.props, 'dayRenderer');
    return dayRenderer(day);
  };

  renderBeforeBlanks = blanks => {
    return blanks.map((blank, index) => {
      return React.cloneElement(this.renderBlank(blank), { key: `before-${index}` });
    });
  };

  renderAfterBlanks = blanks => {
    return blanks.map((blank, index) => {
      return React.cloneElement(this.renderBlank(blank), { key: `after-${index}` });
    });
  };

  renderDays = days => {
    return days.map((day, index) => {
      return React.cloneElement(this.renderDay(day), { key: `day-${index}` });
    });
  };
  handleNextMonth = () => {
    const { currentDate } = this.state;
    const nextMonthDate = currentDate.clone().add(1, 'month');
    this.setState({ currentDate: nextMonthDate });
  };
  handlePrevMonth = () => {
    const { currentDate } = this.state;
    const prevMonthDate = currentDate.clone().subtract(1, 'month');
    this.setState({ currentDate: prevMonthDate });
  };
  renderMonthView = currentDate => {
    const monthRange = this.getMonthRange(currentDate);
    const beforeRange = this.getBlankRangeBefore(currentDate);
    const afterRange = this.getBlankRangeAfter(currentDate);
    const beforeRangeArray = beforeRange === null ? [] : Array.from(beforeRange.by('day'));
    const monthRangeArray = monthRange === null ? [] : Array.from(monthRange.by('day'));
    const afterRangeArray = afterRange === null ? [] : Array.from(afterRange.by('day'));

    const fullMonthView = [
      ...this.renderBeforeBlanks(beforeRangeArray),
      ...this.renderDays(monthRangeArray),
      ...this.renderAfterBlanks(afterRangeArray),
    ];
    let rows = [];
    let cells = [];
    fullMonthView.forEach((row, i) => {
      if (i % 7 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === fullMonthView.length - 1) {
        rows.push(cells);
      }
    });

    return (
      <ScrollWrapper>
        <div className="boss-planner__calendar">
          {this.renderMonthLabels()}
          <div className="boss-planner__calendar-content">
            {rows.map((row, index) => {
              return (
                <div key={`week-${index}`} className="boss-planner__week">
                  {row}
                </div>
              );
            })}
          </div>
        </div>
      </ScrollWrapper>
    );
  };

  daysInMonth = currentDate => {
    return currentDate.daysInMonth(currentDate);
  };

  getFirstDayOfMonth = currentDate => {
    return parseInt(this.getFirstDayOfMonthMoment(currentDate).format('d'));
  };

  getFirstDayOfWeek = currentDate => {
    return parseInt(this.getFirstDayOfWeekMoment(currentDate).format('d'));
  };

  getLastDayOfMonth = currentDate => {
    return parseInt(this.getLastDayOfMonthMoment(currentDate).format('d'));
  };

  getLastDayOfWeek = currentDate => {
    return parseInt(this.getLastDayOfWeekMoment(currentDate).format('d'));
  };

  renderWeekView = currentDate => {
    const weekRange = this.getWeekRange(currentDate);
    const weekRangeArray = Array.from(weekRange.by('day'));
    const fullWeekView = [...this.renderDays(weekRangeArray)];
    let rows = [];
    let cells = [];
    fullWeekView.forEach((row, i) => {
      if (i % 7 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === fullWeekView.length - 1) {
        rows.push(cells);
      }
    });

    return (
      <ScrollWrapper>
        <div className="boss-planner__calendar">
          <div className="boss-planner__calendar-labels">
            {weekRangeArray.map((day, index) => {
              return React.cloneElement(this.renderWeekLabels(day), {
                key: `week-day-${index}`,
              });
            })}
          </div>
          <div className="boss-planner__calendar-content">
            {rows.map((row, index) => {
              return (
                <div key={`week-${index}`} className="boss-planner__week">
                  {row}
                </div>
              );
            })}
          </div>
        </div>
      </ScrollWrapper>
    );
  };

  renderWeekLabels = date => {
    const formattedDate = date.format(utils.calendarWeekDayFormat);
    return (
      <div className="boss-planner__calendar-label">
        <p className="boss-planner__calendar-label-text">{formattedDate}</p>
      </div>
    );
  };

  renderMonthLabels = () => {
    return (
      <div className="boss-planner__calendar-labels">
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Mon</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Tue</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Wed</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Thu</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Fri</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Sat</p>
        </div>
        <div className="boss-planner__calendar-label">
          <p className="boss-planner__calendar-label-text">Sun</p>
        </div>
      </div>
    );
  };

  renderToolbar = ({ mDate, view }) => {
    const toolbarRenderer = oFetch(this.props, 'toolbarRenderer');
    const props = {
      mDate,
      view,
      onViewChange: this.handleViewChange,
      onNextMonth: this.handleNextMonth,
      onPrevMonth: this.handlePrevMonth,
    };
    return toolbarRenderer(props);
  };

  handleViewChange = view => {
    this.setState({ currentView: view });
  };

  getBlankRangeBefore = currentDate => {
    const firstDayOfMonth = this.getFirstDayOfMonth(currentDate);
    if (firstDayOfMonth === 1) {
      return null;
    } else {
      const firstDayOfMonthMoment = this.getFirstDayOfMonthMoment(currentDate);
      const firstDayOfWeek = this.getFirstDayOfWeekMoment(firstDayOfMonthMoment);
      const lastBlankBeforeDay = firstDayOfMonthMoment.clone().subtract(1, 'day');
      return moment.range(firstDayOfWeek, lastBlankBeforeDay);
    }
  };
  getBlankWeekRangeBefore = currentDate => {
    const monthRangeBefore = this.getBlankRangeBefore(currentDate);
    if (monthRangeBefore === null) {
      return null;
    }
    const weekRange = this.getWeekRange(currentDate);
    if (weekRange.overlaps(monthRangeBefore)) {
      return weekRange.subtract(monthRangeBefore, { excludeEnd: true });
    } else {
      return null;
    }
  };

  getBlankRangeAfter = currentDate => {
    const lastDayOfMonth = this.getLastDayOfMonth(currentDate);
    if (lastDayOfMonth === 0) {
      return null;
    } else {
      const lastDayOfMonthMoment = this.getLastDayOfMonthMoment(currentDate);
      const lastBlankAfterDay = this.getLastDayOfWeekMoment(lastDayOfMonthMoment);
      const firstBlankAfterDay = lastDayOfMonthMoment.clone().add(1, 'day');
      return moment.range(firstBlankAfterDay, lastBlankAfterDay);
    }
  };
  getBlankWeekRangeAfter = currentDate => {
    const monthRangeAfter = this.getBlankRangeAfter(currentDate);
    const weekRange = this.getWeekRange(currentDate);
    if (weekRange.overlaps(monthRangeAfter)) {
      return weekRange.subtract(monthRangeAfter, { excludeEnd: true });
    } else {
      return null;
    }
  };

  render() {
    const { keysRenderer } = this.props;
    const { currentDate, currentView } = this.state;
    return (
      <div className="boss-planner boss-planner_type_month boss-planner_page_handover-planner">
        {keysRenderer ? keysRenderer() : <Keys />}
        {this.renderToolbar({ mDate: currentDate, view: currentView })}
        {this.renderCurrentView(currentDate)}
      </div>
    );
  }
}

Calendar.propTypes = {
  keysRenderer: PropTypes.func,
  toolbarRenderer: PropTypes.func,
  defaultView: PropTypes.string,
  format: PropTypes.string,
};
