import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from './swiper';
import { SwiperRef } from 'swiper/react';
import Popover from 'react-popover';
import cn from 'classnames';
import _ from 'lodash';

const astriskChar = '*';
const numbericChars = Array.from({ length: 10 }, (_, i) => String.fromCharCode('0'.charCodeAt(0) + i));
const lowerCaseLettersChars = Array.from({ length: 26 }, (_, i) => String.fromCharCode('a'.charCodeAt(0) + i));
const upperCaseLettersChars = Array.from({ length: 26 }, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i));
const supportedCharacters = [astriskChar, ...numbericChars, ...lowerCaseLettersChars, ...upperCaseLettersChars];

export const focusSides = {
  Hours: 'hours',
  Minutes: 'minutes',
} as const;

export type FocusSide = typeof focusSides[keyof typeof focusSides];

export type BaseSwipeTimePickerValue<T extends string, U extends string> = {
  hourValue: T,
  minuteValue: U,
};

type BaseSwipeTimePickerProps<THoursValues extends readonly string[], TMinutesValues extends readonly string[], T extends THoursValues[number], U extends TMinutesValues[number]> = {
  controlRef?: React.MutableRefObject<SwiperControlFuncs | null> | undefined;
  value: BaseSwipeTimePickerValue<T, U>;
  hourValues: THoursValues;
  minuteValues: TMinutesValues;
  onChange: (value: BaseSwipeTimePickerValue<T, U>) => void;
  onTabKeyDown?: (() => void) | undefined;
  onEnterKeyDown?: (() => void) | undefined;
};

type SliderValuesLookupData =  {
  maxValueLength: number,
  lookup: SliderValuesLookup,
};

type SliderValuesLookup = {
  [key: string]: SliderValuesLookupItem,
};

type SliderValuesLookupItem = {
  chars: Set<string>,
  validKeyCodes: number[],
};

export type SwiperControlFuncs = {
  openPopover: () => void;
  commitAndClosePopover: () => void;
};

export const BaseSwipeTimePicker = <THoursValues extends readonly string[], TMinutesValues extends readonly string[], T extends THoursValues[number], U extends TMinutesValues[number]>(props: BaseSwipeTimePickerProps<THoursValues, TMinutesValues, T, U>) => {
  const { value, onChange, hourValues, minuteValues, controlRef } = props;
  const inputDivRef = useRef<HTMLDivElement>(null);
  const hoursRef = useRef<SwiperRef>(null);
  const minutesRef = useRef<SwiperRef>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [hourValue, setHourValue] = useState<T>(value.hourValue);
  const [inputHourValue, setInputHourValue] = useState<T>('' as T);
  const [minuteValue, setMinuteValue] = useState<U>(value.minuteValue);
  const [inputMinuteValue, setInputMinuteValue] = useState<U>('' as U);
  const [sideFocused, setSideFocused] = useState<FocusSide>(focusSides.Hours);

  useEffect(() => {
    if (controlRef !== undefined && controlRef !== null && controlRef.current !== undefined) {
      controlRef.current = {
        openPopover,
        commitAndClosePopover,
      };
    }
  }, [hourValue, minuteValue, controlRef]);
  const isTouchDevice = detectIsTouchDevice();

  function detectIsTouchDevice() {
    return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    ((navigator as any).msMaxTouchPoints > 0));
  }

  const openPopover = (): void => {
    setIsPopoverOpen(true);
  };

  const hoursValuesLookupData = useMemo(() => {
    return collectValuesLookupData(hourValues);
  }, [hourValues]);
  const minutesValuesLookupData = useMemo(() => {
    return collectValuesLookupData(minuteValues);
  }, [minuteValues]);

  const popoverClass = cn({
    'boss-page-dashboard__meta-item_state_opened': isPopoverOpen,
  });

  // Focus input when popover opens
  useEffect(() => {
    if (isPopoverOpen) {
      inputDivRef.current?.focus({ 'preventScroll': true });
      setSideFocused(focusSides.Hours);
    }
  }, [isPopoverOpen]);

  function validKeyCodesForChar(char: string): number[] {
    char = char.toLocaleLowerCase();
    if (char === astriskChar) {
      return [56, 106];
    } else if (numbericChars.includes(char)) {
      return [48 + parseInt(char), 96 + parseInt(char)];
    } else if (lowerCaseLettersChars.includes(char)) {
      return [65 + lowerCaseLettersChars.indexOf(char)];
    } else if (upperCaseLettersChars.includes(char)) {
      return [97 + upperCaseLettersChars.indexOf(char)];
    } else {
      throw new Error(`Unsupported character supplied: ${char}`);
    }
  }

  function getValidInputKeyCodes(): number[] {
    const displayValue = sideFocused === focusSides.Hours ? inputHourValue : inputMinuteValue;
    const lookupDataAtPos = sideFocused === focusSides.Hours ? hoursValuesLookupData.lookup[displayValue] : minutesValuesLookupData.lookup[displayValue];
    if (!lookupDataAtPos) {
      throw new Error(`Lookup data missing for ${displayValue}`);
    }

    return lookupDataAtPos.validKeyCodes;
  }

  function collectValuesLookupData(values: THoursValues | TMinutesValues): SliderValuesLookupData {
    const result: SliderValuesLookupData = {
      maxValueLength: 0,
      lookup: {},
    };
    values.forEach((val) => {
      if (result.maxValueLength < val.length) {
        result.maxValueLength = val.length;
      }

      let acc: string = '';
      val.split('').forEach((char) => {
        if (!supportedCharacters.includes(char)) {
          throw new Error(`Unsupported character supplied: ${char}`);
        }

        if (result.lookup[acc] === undefined || result.lookup[acc] === null) {
          result.lookup[acc] = {
            chars: new Set<string>(),
            validKeyCodes: [],
          };
        }

        const record = result.lookup[acc];
        if (record === undefined || record === null) {
          throw new Error('Record should have value');
        }
        record.chars.add(char);
        validKeyCodesForChar(char).forEach((keyCode) => {
          record.validKeyCodes.push(keyCode);
        });

        acc += char;
      });
    });

    return result;
  }

  function setHoursSliderToValue(value: string) {
    if (!hoursRef.current || !hoursRef.current.swiper) {
      return;
    }
    const index = hoursRef.current.swiper.slides.findIndex((slide) => {
      return slide.innerText === value;
    });
    if (index === -1) {
      throw new Error(`Invalid hour value supplied: ${value}`);
    }
    hoursRef.current.swiper.slideTo(index, 50, false);
  }

  function setMinutesSliderToValue(value: string) {
    if (!minutesRef.current || !minutesRef.current.swiper) {
      return;
    }
    const index = minutesRef.current.swiper.slides.findIndex((slide) => {
      return slide.innerText === value;
    });
    if (index === -1) {
      throw new Error(`Invalid hour value supplied: ${value}`);
    }
    minutesRef.current.swiper.slideTo(index, 50, false);
  }

  // display incomplete value until user has entered all characters then commit value
  function handleValueCharInput(char: string): void {
    const lookupValue = sideFocused === focusSides.Hours ? inputHourValue : inputMinuteValue;
    const lookupData: SliderValuesLookupItem | undefined = sideFocused === focusSides.Hours ? hoursValuesLookupData.lookup[lookupValue] : minutesValuesLookupData.lookup[lookupValue];
    if (lookupData === undefined || lookupData === null) {
      throw new Error(`Lookup data missing for next character looking up ${lookupValue}`);
    }
    const displayValueUpdateCallBack = sideFocused === focusSides.Hours ? setInputHourValue : setInputMinuteValue;
    const columnValues = sideFocused === focusSides.Hours ? hourValues : minuteValues;

    // Process value if its expected
    if (lookupData.chars.has(char)) {
      displayValueUpdateCallBack(<X extends T | U>(prevDisplayValue: X): X => {
        const nextDisplayValue: X = (prevDisplayValue + char) as X;

        const valueFound = nextDisplayValue !== '' && columnValues.includes(nextDisplayValue);

        // Commit value to state if its complete
        if (valueFound) {
          const valueSetCallBack = sideFocused === focusSides.Hours ? setHourValue : setMinuteValue;
          const moveSliderCallBack = sideFocused === focusSides.Hours ? setHoursSliderToValue : setMinutesSliderToValue;
          moveSliderCallBack(nextDisplayValue);
          valueSetCallBack(<Y extends T | U>(): Y => {
            return nextDisplayValue + '' as unknown as Y;
          });

          // Move column if needed
          if (sideFocused === focusSides.Hours) {
            setSideFocused(focusSides.Minutes);
          }
        }

        return valueFound ? '' as X : nextDisplayValue;
      });
    }
  }

  function resetControl(): void {
    // Reset all values and move focus to hours
    setSideFocused(focusSides.Hours);

    setHourValue(value.hourValue);
    setMinuteValue(value.minuteValue);

    resetInputValues();

    setHoursSliderToValue(value.hourValue);
    setMinutesSliderToValue(value.minuteValue);
  }

  function handleInputKeyDown(e: React.KeyboardEvent<HTMLDivElement>): any {
    e.preventDefault();

    // handle value input
    if (getValidInputKeyCodes().includes(e.keyCode)) {
      handleValueCharInput(e.key.toLocaleLowerCase());
      return null;
    }

    // handle special keys
    switch (e.keyCode) {
      //backspace
      case 8:
        resetControl();
        break;
      // Up arrow
      case 38:
        decreaseSliderValue();
        break;
      // Down arrow
      case 40:
        increaseSliderValue();
        break;
      // Left arrow
      case 37:
        moveSelectionLeft();
        break;
      // Right arrow
      case 39:
        moveSelectionRight();
        break;
      // Tab Key
      case 9:
        if (props.onTabKeyDown) {
          props.onTabKeyDown();
        } else {
          commitAndClosePopover();
        }
        break;
      // Enter Key
      case 13:
        if (props.onEnterKeyDown) {
          props.onEnterKeyDown();
        } else {
          commitAndClosePopover();
        }
        break;
      // Escape Key
      case 27:
        resetAndClosePopover();
        break;
      default:
        break;
    }
  }

  function resetAndClosePopover() {
    resetControl();
    setIsPopoverOpen(false);
  }

  function updateInputFromSlider(): void | boolean {
    if (!hoursRef.current || !minutesRef.current) {
      return false;
    }
    if (!hoursRef.current.swiper || !minutesRef.current.swiper) {
      return false;
    }
    const hoursIndexPresent = hoursRef.current.swiper.activeIndex !== null && hoursRef.current.swiper.activeIndex !== undefined;
    const minutesIndexPresent = minutesRef.current.swiper.activeIndex !== null && minutesRef.current.swiper.activeIndex !== undefined;
    if (!hoursIndexPresent || !minutesIndexPresent) {
      return false;
    }

    const hourValue = hoursRef.current.swiper.slides[hoursRef.current.swiper.activeIndex]?.innerText;
    if (!hourValue) {
      throw new Error('Invalid hour value supplied');
    }
    const minuteValue = minutesRef.current.swiper.slides[minutesRef.current.swiper.activeIndex]?.innerText;
    if (!minuteValue) {
      throw new Error('Invalid minute value supplied');
    }

    setHourValue(hourValue as T);
    setMinuteValue(minuteValue as U);
    resetInputValues();
  }

  function resetInputValues() {
    setInputHourValue('' as T);
    setInputMinuteValue('' as U);
  }

  function moveSelectionLeft() {
    setSideFocused((prevSideFocused) => {
      if (prevSideFocused === focusSides.Hours) {
        // Do nothing
        return prevSideFocused;
      } else if (prevSideFocused === focusSides.Minutes) {
        resetInputValues();
        return focusSides.Hours;
      } else {
        throw new Error(`Invalid side focused supplied: ${sideFocused}`);
      }
    });
  }

  function moveSelectionRight() {
    setSideFocused((prevSideFocused) => {
      if (prevSideFocused === focusSides.Hours) {
        resetInputValues();
        return focusSides.Minutes;
      } else if (prevSideFocused === focusSides.Minutes) {
        // Do nothing
        return prevSideFocused;
      } else {
        throw new Error(`Invalid side focused supplied: ${sideFocused}`);
      }
    });
  }

  function increaseSliderValue() {
    if (sideFocused === focusSides.Hours) {
      hoursRef?.current?.swiper.slideNext(50, true);
    } else if (sideFocused === focusSides.Minutes) {
      minutesRef?.current?.swiper.slideNext(50, true);
    } else {
      throw new Error(`Invalid side focused supplied: ${sideFocused}`);
    }
  }

  function decreaseSliderValue() {
    if (sideFocused === focusSides.Hours) {
      hoursRef?.current?.swiper.slidePrev(50, true);
    } else if (sideFocused === focusSides.Minutes) {
      minutesRef?.current?.swiper.slidePrev(50, true);
    } else {
      throw new Error(`Invalid side focused supplied: ${sideFocused}`);
    }
  }

  function togglePopover() {
    if (isPopoverOpen) {
      commitAndClosePopover();
    } else {
      openPopover();
    }
  }

  function commitAndClosePopover(): void {
    onChange({
      hourValue,
      minuteValue,
    });
    resetInputValues();
    setIsPopoverOpen(false);
  }

  function onInputSectionPointerDown(event: React.PointerEvent<HTMLDivElement>) {
    if (event.pointerType !== 'mouse') {
      commitAndClosePopover();
      return;
    }
  }

  function onHoursSectionPointerDown(event: React.PointerEvent<HTMLSpanElement>) {
    if (event.pointerType !== 'mouse') {
      commitAndClosePopover();
      return;
    }

    setSideFocused((prevSideFocused) => {
      if (prevSideFocused === focusSides.Hours) {
        // Do nothing
        return prevSideFocused;
      } else if (prevSideFocused === focusSides.Minutes) {
        resetInputValues();
        return focusSides.Hours;
      } else {
        throw new Error(`Invalid side focused supplied: ${sideFocused}`);
      }
    });
  }

  function onMinutesSectionPointerDown(event: React.PointerEvent<HTMLSpanElement>) {
    if (event.pointerType !== 'mouse') {
      commitAndClosePopover();
      return;
    }

    setSideFocused((prevSideFocused) => {
      if (prevSideFocused === focusSides.Minutes) {
        // Do nothing
        return prevSideFocused;
      } else if (prevSideFocused === focusSides.Hours) {
        resetInputValues();
        return focusSides.Minutes;
      } else {
        throw new Error(`Invalid side focused supplied: ${sideFocused}`);
      }
    });
  }

  function resetSlideToClosest() {
    _.debounce(() => {
      hoursRef?.current?.swiper?.slideToClosest(50, false);
      minutesRef?.current?.swiper?.slideToClosest(50, false);
    }, 3000)();
  }

  function renderTimePicker() {
    const commonSwiperProps = {
      slidesPerView: 3,
      freeMode: true,
      freeModeSticky: true,
      // momentum: false,
      // momentumBounce: true,
      freeModeMinimumVelocity: 0.5,
      mousewheel: true,
      freeModeMomentumRatio: 0.25,
      freeModeVelocityRatio: 0.25,
      loop: true,
      loopAdditionalSlides: 5,
      direction: 'vertical',
      slideToClickedSlide: true,
      centeredSlides: true,
      // breakpoints: {
      //   768: {
      //     slidesPerView: 3,
      //     spaceBetween: 10,
      //   },
      // },
      on: {
        touchEnd: () => {
          resetSlideToClosest();
        },
        slideChange: () => {
          resetInputValues();
          updateInputFromSlider();
        },
      }
    };

    let hoursTextSeletedClass: string | null = null;
    if (!isTouchDevice && (sideFocused === focusSides.Hours)) {
      hoursTextSeletedClass = 'time-control__value-part_state_selected';
    }

    let minutesTextSelectedClass: string | null = null;
    if (!isTouchDevice && (sideFocused === focusSides.Minutes)) {
      minutesTextSelectedClass = 'time-control__value-part_state_selected';
    }

    const hourIsBeingEdited = inputHourValue !== '';
    const displayHourValue = hourIsBeingEdited ? inputHourValue : hourValue;

    const minuteIsBeingEdited = inputMinuteValue !== '';
    const displayMinuteValue = minuteIsBeingEdited ? inputMinuteValue : minuteValue;

    const initialMinuteSlideIndex = minuteValues.findIndex((v) => v === displayMinuteValue);
    const initialHoursSlideIndex = hourValues.findIndex((v) => v === displayHourValue);

    return (
      <div
        ref={inputDivRef}
        tabIndex={0}
        onKeyDown={handleInputKeyDown}
        className={`time-control ${isTouchDevice && 'time-control_adjust_touch'}`}
      >
        <div
          className="input-wrapper time-control__picker"
        >
          <div className="time-control__info">
            <div
              className='time-control__value'
              onPointerDown={onInputSectionPointerDown}
            >
              <span
                className={`time-control__value-part ${hoursTextSeletedClass}`}
                onPointerDown={onHoursSectionPointerDown}
              >
                {displayHourValue}
                { hourIsBeingEdited && (
                  <span
                    className='time-control__value-underscore'
                  >_</span>
                )}
              </span>
              <span className="time-control__value-delimiter">:</span>
              <span
                className={`time-control__value-part ${minutesTextSelectedClass}`}
                onPointerDown={onMinutesSectionPointerDown}
              >
                {displayMinuteValue}
                { minuteIsBeingEdited && (
                  <span
                    className='time-control__value-underscore'
                  >_</span>
                )}
              </span>
            </div>
            { isTouchDevice && (
              <div
                className="time-control__actions"
              >
                <div
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    resetAndClosePopover();
                    e.preventDefault();
                  }}
                  className="time-control__action time-control__action_role_cancel"
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="time-control__swipers">
          <div className="time-control__swiper">
            <Swiper
              ref={hoursRef}
              className="swiper"
              initialSlide={initialHoursSlideIndex}
              {...commonSwiperProps}
            >
              {
                hourValues.map((hour: string) => {
                  return (
                    <SwiperSlide
                      key={hour}
                      class="swiper-slide"
                    >
                    {hour}
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
          <div className="time-control__swiper">
            <Swiper
              ref={minutesRef}
              className="swiper"
              initialSlide={initialMinuteSlideIndex}
              {...commonSwiperProps}
            >
              {
                minuteValues.map((minute) => {
                  return (
                    <SwiperSlide
                      key={minute}
                      class="swiper-slide"
                    >{minute}</SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </div >
      </div>
    );
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      body={renderTimePicker()}
      place="below"
      tipSize={0.01}
      onOuterAction={commitAndClosePopover}
      className="boss-popover boss-popover_state_opened"
      style={{ marginTop: '10px', width: 'auto' }}
      appendTarget={document.body}
    >
      <div
        className={`${popoverClass}`}
        onClick={togglePopover}
      >
        <input
          type="text"
          readOnly={true}
          value={`${value.hourValue}:${value.minuteValue}`}
        />
      </div>
    </Popover >
  );
};