import { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';
import type { NewMessageScheduleFormValues, MessageSchedule } from '@/types/MessageSchedule';
import * as requests from './requests';
import { $MessageSchedules } from '@/types/MessageSchedule';

type CreateMessageSchedule = {
  values: NewMessageScheduleFormValues,
  onSuccess: () => void,
  onFailure: () => void
};

type UpdateMessageSchedule = CreateMessageSchedule;

type DisableMessageSchedule = {
  messageScheduleId: number,
};

type MessageScheduleState = {
  messageSchedules: MessageSchedule[]
  selectedStatuses: any[]
  createMessageSchedule: (params: CreateMessageSchedule) => Promise<AxiosResponse<any>>
  disableMessageSchedule: (params: DisableMessageSchedule) => Promise<AxiosResponse<any>>
  updateMessageSchedule: (params: UpdateMessageSchedule) => Promise<AxiosResponse<any>>
  onStatusChange: (statuses: any[]) => void
};

type IncomingProps = {
  messageSchedules: MessageSchedule[],
};

const STATUS_ORDER: any = {
  'pending': 1,
  'sent': 2,
  'failed': 3,
  'disabled': 4
};

export function messageScheduleState(props: IncomingProps): MessageScheduleState {
  const messageSchedulesFromProps = $MessageSchedules.parse(oFetch(props, 'messageSchedules'));
  const [messageSchedules, setMessageSchedules] = useState<MessageSchedule[]>(messageSchedulesFromProps);
  const [selectedStatuses, setSelectedStatuses] = useState<any[]>(['pending']);

  const sortedMessageSchedules = useMemo(() => {
    return [...messageSchedules.sort((a, b) => {
      if (a.status !== b.status) {
        return STATUS_ORDER[a.status] - STATUS_ORDER[b.status];
      } else {
        const aMomentSendAt = safeMoment.iso8601Parse(a.sendAt);
        const bMomentSendAt = safeMoment.iso8601Parse(b.sendAt);
        const current = moment();
        return aMomentSendAt.diff(current) - bMomentSendAt.diff(current);
      }
    })];
  }, [messageSchedules, selectedStatuses]);

  const filteredMessageSchedules = useMemo(() => {
    if (selectedStatuses.length === 0) {
      return sortedMessageSchedules;
    }
    return sortedMessageSchedules.filter((sortedMessageSchedule: MessageSchedule) => {
      return selectedStatuses.includes(sortedMessageSchedule.status);
    });
  }, [sortedMessageSchedules]);

  function addMessageScheduleToState(newMessageSchedule: MessageSchedule) {
    setMessageSchedules((prevState: MessageSchedule[]): MessageSchedule[] => {
      return [...prevState, newMessageSchedule];
    });
  }

  function updateMessageScheduleInState(messageSchedule: MessageSchedule) {
    setMessageSchedules((prevState: MessageSchedule[]): MessageSchedule[] => {
      return prevState.map((existingMessageSchedule: MessageSchedule) => {
        const { id } = existingMessageSchedule;
        if (messageSchedule.id === id) {
          return messageSchedule;
        }
        return existingMessageSchedule;
      });
    });
  }

  function handleStatusChange(statuses: any[]) {
    setSelectedStatuses(statuses);
  }

  function handleCreateMessageSchedule(params: CreateMessageSchedule) {
    const { values, onSuccess, onFailure } = params;

    return requests.createMessageSchedule({
      values,
      onSuccess(messageSchedule: MessageSchedule) {
        addMessageScheduleToState(messageSchedule);
        onSuccess();
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function handleUpdateMessageSchedule(params: UpdateMessageSchedule) {
    const { values, onSuccess, onFailure } = params;

    return requests.updateMessageSchedule({
      values,
      onSuccess(messageSchedule: MessageSchedule) {
        updateMessageScheduleInState(messageSchedule);
        onSuccess();
      },
      onFailure() {
        onFailure();
      }
    });
  }

  function handleDisableMessageSchedule(params: DisableMessageSchedule) {
    const { messageScheduleId } = params;

    return requests.disableMessageSchedule({
      values: {
        messageScheduleId
      },
      onSuccess(messageSchedule: MessageSchedule) {
        updateMessageScheduleInState(messageSchedule);
      },
      onFailure() {
      }
    });
  }

  return {
    messageSchedules: filteredMessageSchedules,
    createMessageSchedule: handleCreateMessageSchedule,
    disableMessageSchedule: handleDisableMessageSchedule,
    updateMessageSchedule: handleUpdateMessageSchedule,
    selectedStatuses: selectedStatuses,
    onStatusChange: handleStatusChange,
  };
}
