import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export function sanitizeHTML(params) {
  const [htmlFileContent, onFailure, onSuccess] = oFetch(
    params,
    'htmlFileContent',
    'onFailure',
    'onSuccess',
  );
  const categoryId = oFetch(params, 'categoryId');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      if (statusCode === 422) {
        const errors = oFetch(params, 'errors');
        onFailure(errors);
        return true;
      }
    },
    successHandler(params) {
      const html = oFetch(params, 'data.html');
      onSuccess(html);
    },
  }).post(`/api/v1/training_materials/${categoryId}/sanitize_html`, {
    html: htmlFileContent,
  });
}

export function uploadMaterialsZip(params) {
  const [materialsZipFile, onSuccess, onFailure] = oFetch(
    params,
    'materialsZipFile',
    'onSuccess',
    'onFailure',
  );
  const categoryId = oFetch(params, 'category.id');
  const formData = new FormData();
  formData.set('zip', materialsZipFile);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      if (statusCode === 422) {
        const errors = oFetch(params, 'errors');
        onFailure(errors);
        return true;
      }
    },
    successHandler(params) {
      const liveHtml = oFetch(params, 'data.liveHtml');
      onSuccess(liveHtml);
    },
  }).post(`/api/v1/training_materials/${categoryId}/update_html`, formData);
}
