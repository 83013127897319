import React from 'react';
import oFetch from 'o-fetch';
import { reduxForm, Field } from 'redux-form';
import { BossFormInput } from '@/components/boss-form';

function ManageAreasForm(props) {
  const [submitting, categoryListRender, handleSubmit] = oFetch(
    props,
    'submitting',
    'categoryListRender',
    'handleSubmit',
  );

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__form">
        <div className="boss-form">
          {categoryListRender(submitting)}
          <div className="boss-form__group boss-form__group_role_board">
            <div className="boss-form__row">
              <Field
                name="name"
                label="Area name"
                component={BossFormInput}
                fieldClassName="boss-form__field boss-form__field_layout_max"
              />
              <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label">
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  type="button"
                  className="boss-button boss-button_role_add boss-button_type_icon boss-form__submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm()(ManageAreasForm);
