export function stringToHtml(string: string) {
  const htmlFormat = [
    { symbol: '*', tag: 'b' },
    { symbol: '\n', tag: 'br' },
    { symbol: '_', tag: 'em' },
    { symbol: '~', tag: 'del' },
    { symbol: '`', tag: 'code' },
  ];

  htmlFormat.forEach(({ symbol, tag }) => {
    if (!string) return;

    const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
    const match = string.match(regex);
    if (!match) return;

    match.forEach(m => {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
      }
      string = string.replace(m, formatted);
    });
  });

  return string;
}
