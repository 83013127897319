import React, { Component } from 'react';
import iScroll from 'boss-iscroll';
import oFetch from 'o-fetch';
import ReactIScroll from 'react-iscroll';
import utils from '@/lib/utils';
import HeaderDropdownList from './header-dropdown-list';

export default class HeaderDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickMenu: this.props.quickMenu,
    };

    this.scrollOptions = {
      mouseWheel: true,
      interactiveScrollbars: true,
      shrinkScrollbars: 'scale',
      fadeScrollbars: false,
      scrollbars: true,
      // prevent stop scrolling after scroll ends
      enable_ofscroll: true,
    };
  }

  UNSAFE_componentWillMount() {
    document.body.addEventListener('keydown', this.props.handleEscPress);
    document.body.addEventListener('keypress', this.keyPress);
    document.body.addEventListener('keydown', this.keyPress);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.props.handleEscPress);
    document.body.removeEventListener('keypress', this.keyPress);
    document.body.removeEventListener('keydown', this.keyPress);
    this.setState({ quickMenu: utils.quickMenuHighlightResults(this.props.quickMenu, '') });
  }

  onInuputChange = e => {
    this.setState({
      quickMenu: utils.quickMenuHighlightResults(
        utils.quickMenuFilter(e.target.value, this.props.quickMenu),
        e.target.value,
      ),
    });
  };

  keyPress = () => {
    document.getElementById('onFocusInput').focus();
  };

  render() {
    const prefix = oFetch(this.props, 'prefix');

    return (
      <div
        className={`${prefix}-page-header__dropdown ${prefix}-page-header__dropdown_role_search ${prefix}-page-header__dropdown_state_opened`}
      >
        <div className={`${prefix}-page-header__dropdown-header`}>
          <p className={`${prefix}-page-header__dropdown-label ${prefix}-page-header__dropdown-label_role_search`}>
            Search
          </p>
          <div className={`${prefix}-page-header__dropdown-filter`}>
            <form className={`${prefix}-form`}>
              <div className={`${prefix}-form__field ${prefix}-form__field_position_last`}>
                <div className={`${prefix}-form-field ${prefix}-form-field_adjust_quick-access-search`}>
                  <div className={`${prefix}-form-field__input`}>
                    <input type="text" id="onFocusInput" onChange={this.onInuputChange} />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <a
            href="#"
            onClick={this.props.closeDropdown}
            className={`${prefix}-page-header__dropdown-label ${prefix}-page-header__dropdown-label_role_action ${prefix}-page-header__dropdown-label_role_close ${prefix}-page-header__dropdown-label_type_icon`}
          >
            Close
          </a>
        </div>
        <div className={`${prefix}-page-header__dropdown-scroll`}>
          <ReactIScroll iScroll={iScroll} options={this.scrollOptions}>
            <div className={`${prefix}-page-header__dropdown-content`}>
              <HeaderDropdownList prefix={prefix} items={this.state.quickMenu} />
            </div>
          </ReactIScroll>
        </div>
      </div>
    );
  }
}
