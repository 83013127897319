import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export default function ApiKeyItem(props) {
  const [venueId, name, apiKey, apiKeyId, canRevoke, canCreate, onRevoke, onCreate] = oFetch(
    props,
    'id',
    'name',
    'apiKey',
    'apiKeyId',
    'canRevoke',
    'canCreate',
    'onRevoke',
    'onCreate',
  );

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell">
        <p className="boss-table__text">{name}</p>
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text boss-table__text_adjust_wrap">{apiKey}</p>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__actions">
          {canRevoke && (
            <AsyncButton
              className="boss-button boss-button_type_small boss-button_role_cancel boss-table__action"
              text="Revoke"
              pendingText="Revoking ..."
              onClick={() => onRevoke({ apiKeyId })}
            />
          )}
          {canCreate && (
            <AsyncButton
              className="boss-button boss-button_type_small boss-button_role_add boss-table__action"
              text="Generate"
              pendingText="Generating ..."
              onClick={() => onCreate({ venueId })}
            />
          )}
        </div>
      </div>
    </div>
  );
}
