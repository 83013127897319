import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';
import sortBy from 'lodash/sortBy';

import Header from '../components/header';
import CollapsibleCard from '../components/collapsible-card';

function mapStateToProps(state) {
  return {
    categories: state.venueHealthCheckReport.get('categories'),
    answers: state.venueHealthCheckReport.get('answers'),
    response: state.venueHealthCheckReport.get('response'),
    scores: state.venueHealthCheckReport.get('scores'),
    questions: state.venueHealthCheckReport.get('questions'),
    venues: state.venueHealthCheckReport.get('venues'),
    currentVenue: state.venueHealthCheckReport.get('currentVenue'),
    user: state.venueHealthCheckReport.get('user'),
    frontend: state.venueHealthCheckReport.get('frontend'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export class ReportContainer extends React.Component {
  getSortedCategories = () => {
    const categories = oFetch(this.props, 'categories');
    const categoriesWithSortIndices = categories.map((category, index) => {
      return {
        ...category,
        sortIndex: index,
      };
    });
    return sortBy(categoriesWithSortIndices, [i => oFetch(i, 'sortIndex')]);
  };

  getCategoryByName = categoryName => {
    return this.getSortedCategories().find(category => {
      return oFetch(category, 'name') == categoryName;
    });
  };

  getQuestionsByCategory(category) {
    return this.props.questions.filter(question => {
      return oFetch(question, 'categoryName') == oFetch(category, 'name');
    });
  }

  getScoreByCategory(category) {
    return this.props.scores.find(score => {
      return oFetch(score, 'categoryName') == oFetch(category, 'name');
    });
  }

  renderCollapsibleCardComponent = () => {
    const { ...props } = this.props;
    let categoryQuestions = [];
    let cardProps;
    let currentScore;

    return this.getSortedCategories().map(resource => {
      categoryQuestions = this.getQuestionsByCategory(resource);
      currentScore = this.getScoreByCategory(resource);

      cardProps = Object.assign(props, {
        currentCategory: resource,
        categoryQuestions: categoryQuestions,
        currentScore: currentScore,
      });

      if (categoryQuestions.length == 0) return '';

      return <CollapsibleCard {...cardProps} key={oFetch(resource, 'name')} />;
    });
  };

  render() {
    return (
      <main className="boss-page-main">
        <Header {...this.props} />

        <div className="boss-page-main__content">
          <div className="boss-page-main__inner">{this.renderCollapsibleCardComponent()}</div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
