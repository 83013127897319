import React from 'react';
import Lightbox from 'lightbox-react';
import modalDecorator from './modal-decorator';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

function openImageModal(
  { title = 'Image Modal', image },
  props = {},
  onSubmit,
  onClose,
  rootWrapper,
  closeCallback,
) {
  const whenCloseClicked = () => {
    closeCallback();
    onClose();
  };
  rootWrapper.render(
    <ReactErrorsBoundaryWrapper>
      <Lightbox mainSrc={image} onCloseRequest={whenCloseClicked} />
    </ReactErrorsBoundaryWrapper>,
  );
}

export default modalDecorator(openImageModal);
