import React from 'react';
import oFetch from 'o-fetch';

export default function ReviewList(props) {
  const [staffMembers, itemRenderer, className] = oFetch(
    props,
    'staffMembers',
    'itemRenderer',
    'className',
  );

  function renderStaffMembers() {
    return staffMembers.map(staffMember => {
      const staffMemberId = oFetch(staffMember, 'id');
      return React.cloneElement(itemRenderer(staffMember), {
        key: staffMemberId.toString(),
      });
    });
  }

  return <div className={className}>{renderStaffMembers()}</div>;
}
