import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

class TableCell extends React.Component {
  static propTypes = {
    label: PropTypes.string,
  };

  render() {
    return (
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">{this.props.label}</p>
          {this.props.children}
        </div>
      </div>
    );
  }
}

class SubmissionsListItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onDetailsClick: PropTypes.func.isRequired,
  };

  getSubmissionStatus(answers) {
    return answers.filter(item => item.get('answer')).size === answers.size;
  }

  render() {
    const { item } = this.props;
    const isQrCodeType = item.get('isQrCodeType');
    const isComputerType = item.get('isComputerType');
    const status = this.getSubmissionStatus(item.get('answers'));
    const statusCn = status ? 'boss-button_role_secondary' : 'boss-button_role_alert';
    const statusText = status ? 'OK' : 'Problem';
    const creatorName = item.get('creator_name');
    const checklistName = item.get('check_list_name');
    const createdAt = safeMoment.iso8601Parse(item.get('created_at')).format(utils.humanDateFormatWithTime('short'));
    const typeClassNames = cn('boss-indicator__icon boss-indicator__icon_adjust_table', {
      'boss-indicator__icon_qrcode': isQrCodeType,
      'boss-indicator__icon_computer': isComputerType,
    });
    return (
      <div className="boss-table__row">
        <TableCell label="Type">
          <p className="boss-table__text">
            <span className="boss-indicator">
              <span className={typeClassNames} />
            </span>
          </p>
        </TableCell>
        <TableCell label="Date">
          <p className="boss-table__text">
            <button type="button" className="boss-table__link">
              {createdAt}
            </button>
          </p>
        </TableCell>
        <TableCell label="Check List">
          <p className="boss-table__text">
            <button type="button" className="boss-table__link">
              {checklistName}
            </button>
          </p>
        </TableCell>
        <TableCell label="Created By">
          <p className="boss-table__text">
            <button type="button" className="boss-table__link">
              {creatorName}
            </button>
          </p>
        </TableCell>
        <TableCell label="Status">
          <div className="boss-table__text">
            <button
              type="button"
              className={`boss-button boss-button_type_small boss-button_type_no-behavior ${statusCn}`}
            >
              {statusText}
            </button>
          </div>
        </TableCell>
        <TableCell label="Action">
          <div className="boss-table__actions">
            <button
              onClick={this.props.onDetailsClick.bind(null, item)}
              className="boss-button boss-button_primary boss-table__action"
            >
              View Details
            </button>
          </div>
        </TableCell>
      </div>
    );
  }
}

export default SubmissionsListItem;
