import d3 from 'd3';
import oFetch from 'o-fetch';

var fcharts = document.getElementsByClassName('boss-chart-failures');

function FailureChart(placeholderName, configuration) {
  this.placeholderName = placeholderName;

  var self = this; // for internal d3 functions

  this.configure = function (configuration) {
    this.config = configuration;

    this.config.size = configuration.size || 250;

    this.config.radius = oFetch(this.config, 'size') * 0.5;
    this.config.cx = oFetch(this.config, 'size') / 2;
    this.config.cy = oFetch(this.config, 'size') / 2;

    this.config.failColor = configuration.failColor || '#de7575';
    this.config.successColor = configuration.successColor || '#5cac41';

    this.config.current = configuration.current;
    this.config.total = oFetch(configuration, 'total');
    this.config.requiredFailedQuestionCount = oFetch(configuration, 'requiredFailedQuestionCount');
  };

  this.render = function () {
    var chartContainer = d3.select('#' + oFetch(this.config, 'id'));
    var chart = chartContainer.append('div').attr('class', 'boss-chart');
    var chartInner = chart.append('div').attr('class', 'boss-chart__inner');

    // Create chart svg graph
    this.graph = chartInner
      .append('svg:svg')
      .attr('class', 'boss-chart__graph')
      .attr('width', oFetch(this.config, 'size'))
      .attr('height', oFetch(this.config, 'size'));

    const circle = oFetch(this.config, 'circle');
    const requiredFailedQuestionCount = oFetch(this.config, 'requiredFailedQuestionCount');
    if (requiredFailedQuestionCount == 0) {
      throw new Error(`Impossible value of requiredFailedQuestionCount == 0 encountered for failure graph: ${oFetch(this.config, 'id')}`)
    }
    const failColor = oFetch(self.config, 'failColor');
    const successColor = oFetch(self.config, 'successColor');

    for (var index in circle) {
      if (requiredFailedQuestionCount > 0) {
        this.drawBand(
          oFetch(circle[index], 'from'),
          oFetch(circle[index], 'to'),
          failColor,
        );
      } else {
        this.drawBand(
          oFetch(circle[index], 'from'),
          oFetch(circle[index], 'to'),
          successColor,
        );
      }
    }

    // Create chart graph information
    var chartInfoContainer = this.graph.append('svg:g').attr('class', 'boss-chart__info');
    var chartInfoNumber = chartInfoContainer
      .append('svg:text')
      .attr('class', 'boss-chart__info-number');
    var chartInfoLabel = chartInfoContainer
      .append('svg:text')
      .attr('class', 'boss-chart__info-label');

    var numberFontSize = Math.round(oFetch(this.config, 'size') / 3.5);
    var labelFontSize = Math.round(oFetch(this.config, 'size') / 17);

    chartInfoNumber
      .text(requiredFailedQuestionCount)
      .attr('x', oFetch(this.config, 'cx'))
      .attr('y', oFetch(this.config, 'cy'))
      .attr('text-anchor', 'middle')
      .style('font-size', numberFontSize + 'px')
      .style('line-height', '1')
      .style('font-weight', '600');

    chartInfoLabel
      .text('INSTANT FAILURES')
      .attr('x', oFetch(this.config, 'cx'))
      .attr('y', oFetch(this.config, 'cy') + labelFontSize * 2)
      .attr('text-anchor', 'middle')
      .style('font-size', labelFontSize + 'px')
      .style('line-height', '1')
      .style('font-weight', '400')
      .style('text-transform', 'uppercase');

    if (oFetch(this.config, 'requiredFailedQuestionCount') > 0) {
      chartInfoNumber.style('fill', failColor);
      chartInfoLabel.style('fill', failColor);
    } else {
      chartInfoNumber.style('fill', successColor);
      chartInfoLabel.style('fill', successColor);
    }

    if (this.config.total > 0) {
      // Create chart score
      var scoreContainer = chart.append('div').attr('class', 'boss-chart__score');
      var scoreLabel = scoreContainer.append('p').attr('class', 'boss-chart__score-label');
      var scoreValue = scoreContainer.append('p').attr('class', 'boss-chart__score-value');
      var scoreCurrent = scoreValue.append('span').attr('class', 'boss-chart__score-current');
      var scoreDelimiter = scoreValue.append('span').text(' / ');
      var scoreTotal = scoreValue.append('span').attr('class', 'boss-chart__score-total');

      scoreLabel.text('Scored questions');

      scoreCurrent.text(this.config.current);
      scoreTotal.text(oFetch(this.config, 'total'));
    }
  };

  this.drawBand = function (start, end, color) {
    if (end - start <= 0) {
      return;
    }

    this.graph
      .append('svg:path')
      .style('fill', color)
      .attr(
        'd',
        d3.svg
          .arc()
          .startAngle(this.currentToRadians(start))
          .endAngle(this.currentToRadians(end))
          .innerRadius(0.73 * oFetch(this.config, 'radius'))
          .outerRadius(0.9 * oFetch(this.config, 'radius')),
      )
      .attr('transform', function () {
        return 'translate(' + oFetch(self.config, 'cx') + ', ' + oFetch(self.config, 'cy') + ')';
      });
  };

  this.currentToDegrees = function (value) {
    return (value / 100) * 360;
  };

  this.currentToRadians = function (value) {
    return (this.currentToDegrees(value) * Math.PI) / 180;
  };

  // initialization
  this.configure(configuration);
}

function newFailureChart(params) {
  var config = {
    size: parseInt(oFetch(params, 'size')),
    id: oFetch(params, 'id'),
    total: parseInt(oFetch(params, 'total')),
    thresholdPercentage: parseFloat(oFetch(params, 'thresholdPercentage')),
    passPercentage: parseFloat(oFetch(params, 'passPercentage')),
    requiredFailedQuestionCount: parseInt(oFetch(params, 'requiredFailedQuestionCount')),
    current: parseInt(oFetch(params, 'current')),
  };

  config.circle = [
    {
      from: 0,
      to: 360,
    },
  ];

  var createFailureChart = new FailureChart(oFetch(config, 'id'), config);
  createFailureChart.render();
}

function createFailureCharts() {
  Array.prototype.forEach.call(fcharts, function (fchart) {
    const data = oFetch(fchart, 'dataset');
    newFailureChart(data);
  });
}

export function initializeFailureCharts() {
  createFailureCharts();
}
