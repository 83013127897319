import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = [];

export const marketingTasks = handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const marketingTasks = oFetch(action, 'payload.marketingTasks');
      return marketingTasks;
    },
    [t.ADD_MARKETING_TASK]: (state, action) => {
      const marketingTask = oFetch(action, 'payload.marketingTask');
      return [...state, marketingTask];
    },
    [t.UPDATE_MARKETING_TASK]: (state, action) => {
      const updatedMarketingTask = oFetch(action, 'payload.marketingTask');
      return state.map(marketingTask => {
        if (oFetch(marketingTask, 'id') === oFetch(updatedMarketingTask, 'id')) {
          return { ...updatedMarketingTask, loaded: !!marketingTask.loaded };
        }
        return marketingTask;
      });
    },
    [t.REMOVE_MARKETING_TASK]: (state, action) => {
      const marketingTaskId = oFetch(action, 'payload.marketingTaskId');
      return state.filter(marketingTask => {
        return oFetch(marketingTask, 'id') !== marketingTaskId;
      });
    },
    [t.UPDATE_MARKETING_TASK_DETAILS]: (state, action) => {
      const marketingTask = oFetch(action, 'payload.marketingTask');
      return state.map(existingMarketingTask => {
        if (oFetch(existingMarketingTask, 'id') === oFetch(marketingTask, 'id')) {
          return { ...marketingTask, loaded: true };
        }
        return existingMarketingTask;
      });
    },
  },
  initialState,
);
