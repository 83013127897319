import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { openCustomModal } from '@/components/modals';
import ActionModal from './action-modal';
import { EVENT_TITLES } from './hours-chart-content';

export default function EventImage(props) {
  const [event, className, events, widthPx] = oFetch(props, 'event', 'className', 'events', 'widthPx');
  const eventImageClassNames = cn('boss-hrc__scan', {
    [className]: !!className,
  });

  if (event === null) {
    return (
      <div className={eventImageClassNames}>
        <div className="boss-hrc__scan-photo" />
        <p className="boss-hrc__scan-label">N/A</p>
      </div>
    );
  }
  const [at, s3AttemptUrl, isManagerEvent, eventType] = oFetch(event, 'at', 's3AttemptUrl', 'isManagerEvent', 'eventType');

  function openActionModal() {
    openCustomModal({
      config: {
        title: `${EVENT_TITLES[eventType]} Event Details`,
        modalClass: 'boss-modal-window boss-modal-window_role_action-details',
      },
      props: { event, events: events },
    })(ActionModal);
  }

  const formattedTime = safeMoment.iso8601Parse(at).format(utils.commonDateFormatTimeOnly());
  const photoClassNames = cn('boss-hrc__scan-photo', { 'boss-hrc__scan-photo_role_manager': isManagerEvent });
  return (
    <div className={eventImageClassNames}>
      <div
className={photoClassNames}
style={{ backgroundImage: 'none', backgroundColor: '#4c4c4c' }}
      >
        {s3AttemptUrl && (
          <button onClick={openActionModal}>

          <img
            style={{
              width: `${widthPx}px`,
              objectFit: 'contain',
              height: `${widthPx}px`,
            }}
            src={s3AttemptUrl}
            className="boss-hrc__scan-image"
          />
            </button>
        )}
        {!s3AttemptUrl && <div className="boss-hrc__scan-photo" />}
      </div>
      <p className="boss-hrc__scan-label">{formattedTime}</p>
    </div>
  );
}
