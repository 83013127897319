import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from './types';
import {
  acceptHolidayRequestRequest,
  rejectHolidayRequestRequest
} from '../requests';

export const loadInitialData = createAction(types.INITIAL_LOAD);
export const removeHolidayRequest = createAction(types.REMOVE_HOLIDAY_REQUEST);
export const removeEffectiveStaffMember = createAction(types.REMOVE_STAFF_MEMBER);

export const acceptHolidayRequest = params => (dispatch, getState) => {
  const effectiveStaffMemberId = oFetch(params, 'effectiveStaffMemberId');
  const holidayRequestId = oFetch(params, 'holidayRequestId');
  const forward = oFetch(params, 'forward');
  return acceptHolidayRequestRequest({ holidayRequestId, forward }).then(() => {
    dispatch(removeHolidayRequest(holidayRequestId));
    const holidayRequests = getState().get('holidayRequests');
    const effectiveStaffMemberHolidayRequests = holidayRequests.filter(
      holidayRequest => holidayRequest.get('effectiveStaffMemberId') === effectiveStaffMemberId,
    );

    if (oFetch(effectiveStaffMemberHolidayRequests, 'length') === 0) {
      dispatch(removeEffectiveStaffMember(effectiveStaffMemberId));
    }
  })
};

export const rejectHolidayRequest = params => (dispatch, getState) => {
  const effectiveStaffMemberId = oFetch(params, 'effectiveStaffMemberId');
  const holidayRequestId = oFetch(params, 'holidayRequestId');
  return rejectHolidayRequestRequest({ holidayRequestId }).then(response => {
    dispatch(removeHolidayRequest(holidayRequestId));
    const holidayRequests = getState().get('holidayRequests');
    const effectiveStaffMemberHolidayRequests = holidayRequests.filter(
      holidayRequest => holidayRequest.get('effectiveStaffMemberId') === effectiveStaffMemberId,
    );

    if (oFetch(effectiveStaffMemberHolidayRequests, 'length') === 0) {
      dispatch(removeEffectiveStaffMember(effectiveStaffMemberId));
    }
  })
};
