import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import { useSelector } from 'react-redux';
import * as selectors from '../../redux/selectors';

export function NeedAttentionQuestionList(props) {
  const requests = oFetch(props, 'requests');

  const needAttentionRequestTexts = useMemo(() => {
    return requests
      .filter(request => {
        const [requiringAnswerFromYou, hasAnswerForYou] = oFetch(
          request,
          'requiringAnswerFromYou',
          'hasAnswerForYou',
        );
        return requiringAnswerFromYou || hasAnswerForYou;
      })
      .map(request => {
        const [requiringAnswerFromYou, hasAnswerForYou] = oFetch(
          request,
          'requiringAnswerFromYou',
          'hasAnswerForYou',
        );
        if (requiringAnswerFromYou) {
          return {
            questionAttention: true,
            answerAttention: false,
            question: oFetch(request, 'currentQuestion.text'),
          };
        }
        if (hasAnswerForYou) {
          return {
            questionAttention: false,
            answerAttention: true,
            question: oFetch(request, 'currentQuestion.text'),
          };
        }
      });
  }, [requests]);

  const needAttentionRequestTextsExist = needAttentionRequestTexts.length > 0;

  if (!needAttentionRequestTextsExist) {
    return null;
  }

  return (
    <div className="boss-check__group boss-check__group_role_requests">
      <div className="boss-check__group-content">
        {needAttentionRequestTexts.map((requestText, index) => {
          const [questionAttention, answerAttention, question] = oFetch(
            requestText,
            'questionAttention',
            'answerAttention',
            'question',
          );
          if (questionAttention) {
            return (
              <div key={index}>
                <p className="boss-check__text boss-check__text_role_request" style={{ display: 'flex' }}>
                  <span className="boss-check__text-indicator">Q</span>
                  <span style={{ whiteSpace: 'pre-line' }}>{question}</span>
                </p>
              </div>
            );
          }
          if (answerAttention) {
            return (
              <p
                key={index}
                className="boss-check__text boss-check__text_role_request"
                style={{ backgroundColor: '#e6f2fc', display: 'flex' }}
              >
                <span className="boss-check__text-indicator">Q</span>
                <span style={{ backgroundColor: '#e6f2fc', whiteSpace: 'pre-line' }}>{question}</span>
              </p>
            );
          }
        })}
      </div>
    </div>
  );
}
