import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import BossFormTextArea from '@/components/boss-form/boss-form-textarea';

const renderBaseError = errors => {
  return (
    <div className="boss-modal-window__alert" style={{ width: '100%' }}>
      <div className="boss-alert boss-alert_role_area boss-alert_context_above">
        {errors.map((error, index) => (
          <p key={index.toString()} className="boss-alert__text">
            {error}
          </p>
        ))}
      </div>
    </div>
  );
};

class SecurityShiftRejectRequestForm extends React.Component {
  render() {
    const { error, rejectReason } = this.props;
    return (
      <div className="boss-modal-window__form">
        <form onSubmit={this.props.handleSubmit} className="boss-form">
          {error && renderBaseError(error)}
          <Field name="rejectReason" required label="Reason for rejecting" component={BossFormTextArea} />
          <div className="boss-form__field">
            <div className="boss-form__field">
              <button
                disabled={this.props.submitting || !rejectReason}
                className={`boss-button boss-form__submit ${this.props.buttonClass}`}
              >
                {this.props.buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SecurityShiftRejectRequestForm.defaultProps = {
  buttonClass: 'boss-button_role_cancel',
  buttonText: 'Reject',
};

const selector = formValueSelector('security-shift-reject-request-form');

const ConnectedSecurityShiftRejectRequestForm = connect(state => {
  return {
    rejectReason: selector(state, 'rejectReason'),
  };
})(SecurityShiftRejectRequestForm);

export default reduxForm({
  fields: ['id', 'venueId'],
  form: 'security-shift-reject-request-form',
})(ConnectedSecurityShiftRejectRequestForm);
