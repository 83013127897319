import React, { useCallback, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import oFetch from 'o-fetch';

export const FILE = 'FILE';
export const FILES = 'FILES';
export const BASE64 = 'BASE64';
export const BUFFER = 'BINARY';

const handlers = {
  [BASE64]: 'readAsDataURL',
  [BUFFER]: 'readAsArrayBuffer',
};

export const wrongResultTypeErrorMessage = resultType => {
  return `Wrong resultType prop, must be one of ${Object.keys(handlers).join(
    ', ',
  )}, got: ${resultType}`;
};

const Dropzone = props => {
  const [uploading, setUploading] = useState(false);
  const onUpload = oFetch(props, 'onUpload');
  const resultType = oFetch(props, 'resultType');
  const maxSizeMB = oFetch(props, 'maxSizeMB');

  const options = {
    maxSizeMB: maxSizeMB,
  };

  function compressFiles(files) {
    setUploading(true);
    const compressedFilesPromises = files.map(file => {
      return imageCompression(file, options);
    });
    return Promise.all(compressedFilesPromises);
  }

  const onDrop = useCallback(acceptedFiles => {
    if (resultType === FILES) {
      return compressFiles(acceptedFiles).then(compressedFiles => {
        setUploading(false);
        onUpload(compressedFiles);
      });
    }
    if (resultType === FILE) {
      return compressFiles(acceptedFiles).then(compressedFiles => {
        setUploading(false);
        onUpload(compressedFiles[0]);
      });
    }
    if (!Object.keys(handlers).includes(resultType)) {
      throw new Error(wrongResultTypeErrorMessage(resultType));
    }
    return compressFiles(acceptedFiles).then(compressedFiles => {
      const file = compressedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        setUploading(false);
        onUpload(result);
      };
      const handler = handlers[resultType];
      reader[handler](file);
    });
  }, []);

  if (uploading) {
    return (
      <div className="purple-form-upload__preview">
        <p className="purple-form-upload__preview-label purple-form-upload__preview-label_icon_uploading">
          Uploading...
        </p>
      </div>
    );
  }

  return (
    <ReactDropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div {...getRootProps()}>
            <div className="purple-form-upload__preview">
              <p className="purple-form-upload__preview-label purple-form-upload__preview-label_icon_plus">
                Add Image
              </p>
            </div>
            <div className="purple-form-upload__actions">
              <button className="purple-button purple-button_color_accent-green" type="button">
                <span className="purple-button__text">Choose file</span>
              </button>
            </div>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="purple-form-upload__note">Drop the files here ...</p>
            ) : (
              <p className="purple-form-upload__note">
                Drag and drop files here or click choose file button to upload photo
              </p>
            )}
          </div>
        );
      }}
    </ReactDropzone>
  );
};

export default Dropzone;
