import React from 'react';
import { MessageScheduleForm } from '../forms/message-schedule-form';

interface NewMessageScheduleProps {
  onSubmit: (params: any) => void;
  processing: boolean;
}

export function NewMessageSchedule(props: NewMessageScheduleProps) {
  const { onSubmit, processing } = props;

  const initialValues = {
    staffMemberId: null,
    sendAt: null,
    message: null,
  };

  return (
    <MessageScheduleForm
      onSubmit={onSubmit}
      processing={processing}
      initialValues={initialValues}
      buttonText="Create"
    />
  );
}
