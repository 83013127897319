import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';

function ReportItem(props) {
  const report = oFetch(props, 'report');
  const staffMemberId = oFetch(props, 'report.staffMemberId');
  const weekDates = oFetch(props, 'weekDates');
  const mStartDate = safeMoment.uiDateParse(oFetch(props, 'startDate'));
  const mEndDate = safeMoment.uiDateParse(oFetch(props, 'endDate'));
  const reportPaymentData = oFetch(props, 'reportPaymentData');
  const paymentWeekData = oFetch(props, "paymentWeekData");
  const paymentWeekPublished = oFetch(paymentWeekData, 'paymentWeekPublished');

  function renderWeekDaysCells() {
    const mondayHoursCount = utils.round(oFetch(report, 'mondayHoursCount'), 2);
    const tuesdayHoursCount = utils.round(oFetch(report, 'tuesdayHoursCount'), 2);
    const wednesdayHoursCount = utils.round(oFetch(report, 'wednesdayHoursCount'), 2);
    const thursdayHoursCount = utils.round(oFetch(report, 'thursdayHoursCount'), 2);
    const fridayHoursCount = utils.round(oFetch(report, 'fridayHoursCount'), 2);
    const saturdayHoursCount = utils.round(oFetch(report, 'saturdayHoursCount'), 2);
    const sundayHoursCount = utils.round(oFetch(report, 'sundayHoursCount'), 2);

    return [
      mondayHoursCount,
      tuesdayHoursCount,
      wednesdayHoursCount,
      thursdayHoursCount,
      fridayHoursCount,
      saturdayHoursCount,
      sundayHoursCount,
    ].map((dayHoursCount, index) => {
      const weekDate = weekDates.get(index);

      if (dayHoursCount === 0) {
        return (
          <div key={index} className="boss-table__cell">
            <p className="boss-table__text">-</p>
          </div>
        );
      } else {
        return (
          <div key={index} className="boss-table__cell">
            <p className="boss-table__text">
              <a
                href={appRoutes.staffMemberHoursOverview(staffMemberId, weekDate)}
                className="boss-table__link"
              >
                {dayHoursCount}
              </a>
            </p>
          </div>
        );
      }
    });
  }

  function getNetWagesText(params) {
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    const wagesZero = oFetch(params, 'wagesZero');
    if (!(hasNetWages && paymentWeekPublished) || wagesZero) {
      return null;
    }
    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      return 'Pending';
    } else if (wagesReceived) {
      return 'Received';
    } else {
      throw new Error('Impossible condition encountered');
    }
  }

  function getNetWagesIconColorClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator_color_accent-red';
    } else if (wagesReceived) {
      result = 'boss-indicator_color_accent-green';
    }

    return result;
  }

  function getNetWageIconClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator__icon_hour-glass boss-indicator__icon_size_xs';
    } else if (wagesReceived) {
      result = 'boss-indicator__icon_check-bold boss-indicator__icon_size_s';
    }

    return result;
  }

  const fullName = oFetch(report, 'staffMemberName');
  const weeklyHours = utils.round(oFetch(report, 'weeklyHours'), 2);
  const owedHours = utils.round(oFetch(report, 'owedHours'), 2);

  const acessories = utils.round(oFetch(report, 'acessories'), 2);
  const acessoriesColor = utils.colorizedAmount(acessories);
  const totalHoursCount = utils.round(oFetch(report, 'totalHoursCount') + owedHours, 2);
  const paidHolidayDaysCount = oFetch(report, 'paidHolidayDaysCount');
  const sickLeaveDaysCount = oFetch(report, 'sickLeaveDaysCount');
  const payRateType = oFetch(report, 'payRateType');
  const paymentStatus = oFetch(reportPaymentData, 'status');
  const hasNetWages = paymentStatus != null;

  const pendingCalculation = oFetch(report, 'pendingCalculation');
  const staffMemberDisabled = oFetch(report, 'staffMemberDisabled');

  const wagesZero = paymentStatus === 'zero';
  const wagesPending = paymentStatus === 'pending';
  const wagesReceived = paymentStatus === 'received';

  const netWagesText = getNetWagesText({
    hasNetWages,
    paymentWeekPublished,
    wagesReceived,
    wagesPending,
    wagesZero,
  });

  const fullNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': staffMemberDisabled,
  });

  const rowClassName = classNames({
    'boss-table__row': true,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });

  const fullNameCellTextClassName = classNames({
    'boss-table__text': true,
    'boss-table__text_indicator_accessory': acessories !== 0,
  });

  const owedHoursClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': owedHours !== 0,
  });
  const paidHolidayDaysCountClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': paidHolidayDaysCount !== 0,
  });
  const netWagesSectionStylesClasses = classNames({
    'boss-table__text_role_important':
      hasNetWages && (!paymentWeekPublished || wagesZero || wagesPending || wagesReceived),
    'boss-table__text_state_alert': hasNetWages && paymentWeekPublished && wagesPending,
    'boss-table__text_state_success': hasNetWages && paymentWeekPublished && wagesReceived,
  });
  const netWagesIconAccentColorClass = getNetWagesIconColorClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });
  const netWagesIconClass = getNetWageIconClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });

  return (
    <div className={rowClassName}>
      <div className={fullNameCellClassName}>
        <p className={fullNameCellTextClassName}>
          <a
            href={appRoutes.staffMember(staffMemberId)}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {fullName}
          </a>
        </p>
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text" style={{ textTransform: 'capitalize' }}>
          {payRateType}
        </p>
      </div>
      {renderWeekDaysCells()}
      <div className="boss-table__cell">
        <p className="boss-table__text">{weeklyHours}</p>
      </div>
      {owedHours === 0 ? (
        <div className={owedHoursClassName}>
          <p className="boss-table__text">{owedHours}</p>
        </div>
      ) : (
        <div className={owedHoursClassName}>
          <a
            href={appRoutes.staffMemberOwedHours({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {owedHours}
          </a>
        </div>
      )}

      <div className="boss-table__cell">
        {acessories === 0 ? (
          <p className="boss-table__text">{utils.moneyFormat(acessories)}</p>
        ) : (
          <a
            href={appRoutes.staffMemberAccessories({ staffMemberId })}
            className="boss-table__text"
            style={{ color: acessoriesColor }}
          >
            {utils.moneyFormat(acessories)}
          </a>
        )}
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text boss-table__text_role_important">{totalHoursCount}</p>
      </div>
      {paidHolidayDaysCount === 0 && (
        <div className={paidHolidayDaysCountClassName}>
          <p className="boss-table__text">{paidHolidayDaysCount}</p>
        </div>
      )}
      {paidHolidayDaysCount !== 0 && (
        <div className={paidHolidayDaysCountClassName}>
          <a
            href={appRoutes.staffMemberProfileHolidaysTabFromFinanceReport({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {paidHolidayDaysCount}
          </a>
        </div>
      )}
      {sickLeaveDaysCount === 0 && (
        <div className="boss-table__cell">
          <p className="boss-table__text">{sickLeaveDaysCount}</p>
        </div>
      )}
      {sickLeaveDaysCount !== 0 && (
        <div className="boss-table__cell">
          <a
            href={appRoutes.staffMemberProfileHolidaysTabFromFinanceReport({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {sickLeaveDaysCount}
          </a>
        </div>
      )}
      <div className="boss-table__cell">
        {hasNetWages && (
          <p className={`boss-table__text ${netWagesSectionStylesClasses}`}>
            {paymentWeekPublished && wagesPending && (
              <span className="boss-table__text-indicator">
                <span className={`boss-indicator ${netWagesIconAccentColorClass}`}>
                  <span className={`boss-indicator__icon ${netWagesIconClass}`} />
                </span>
              </span>
            )}
            <span>{netWagesText}</span>
          </p>
        )}
      </div>
    </div>
  );
}

ReportItem.propTypes = {
  report: PropTypes.shape({
    staffMemberId: PropTypes.number.isRequired,
    mondayHoursCount: PropTypes.number.isRequired,
    tuesdayHoursCount: PropTypes.number.isRequired,
    wednesdayHoursCount: PropTypes.number.isRequired,
    thursdayHoursCount: PropTypes.number.isRequired,
    fridayHoursCount: PropTypes.number.isRequired,
    saturdayHoursCount: PropTypes.number.isRequired,
    sundayHoursCount: PropTypes.number.isRequired,
    weeklyHours: PropTypes.number.isRequired,
    owedHours: PropTypes.number.isRequired,
    totalHoursCount: PropTypes.number.isRequired,
    paidHolidayDaysCount: PropTypes.number.isRequired,
    staffMemberName: PropTypes.string.isRequired,
    acessories: PropTypes.number.isRequired,
  }).isRequired,
  reportPaymentData: PropTypes.object.isRequired,
  weekDates: ImmutablePropTypes.list.isRequired,
};

export default ReportItem;
