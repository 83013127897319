import oFetch from 'o-fetch';
import { createSelector } from 'reselect';
import { ANY } from './constants';

export const statusFilterSelector = state => oFetch(state, 'filters.status');
export const roleFilterSelector = state => oFetch(state, 'filters.role');
export const invitesSelector = state => oFetch(state, 'invites');
export const staticValuesSelector = state => oFetch(state, 'staticValues');
export const venuesSelector = createSelector(staticValuesSelector, staticValues =>
  oFetch(staticValues, 'venues'),
);
export const appsSelector = createSelector(staticValuesSelector, staticValues =>
  oFetch(staticValues, 'apps'),
);

const venuesIdNameSelector = createSelector(venuesSelector, venues => {
  return venues.reduce((acc, venue) => {
    acc[oFetch(venue, 'id')] = oFetch(venue, 'name');
    return acc;
  }, {});
});

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const inviteIdsFilteredByRole = createSelector(
  roleFilterSelector,
  invitesSelector,
  (role, invites) => {
    let filteredInvites;
    if (role && role !== ANY) {
      filteredInvites = invites.filter(invite => oFetch(invite, 'role').toLowerCase() === role.toLowerCase());
    } else {
      filteredInvites = invites;
    }
    return filteredInvites.map(invite => oFetch(invite, 'id'));
  },
);

export const inviteIdsFilteredByStatus = createSelector(
  statusFilterSelector,
  invitesSelector,
  (status, invites) => {
    let filteredInvites;
    if (status && status !== ANY) {
      filteredInvites = invites.filter(
        invite => oFetch(invite, 'currentState').toLowerCase() === status.toLowerCase(),
      );
    } else {
      filteredInvites = invites;
    }
    return filteredInvites.map(invite => oFetch(invite, 'id'));
  },
);

export const invitesFilteredByAllFilters = createSelector(
  inviteIdsFilteredByRole,
  inviteIdsFilteredByStatus,
  invitesSelector,
  venuesSelector,
  venuesIdNameSelector,
  (invitesFilteredByRole, invitesFilteredByStatus, invites, venues, venuesIdName) => {
    const invitesByRole = invites
      .filter(invite => invitesFilteredByRole.includes(oFetch(invite, 'id')));
    const invitesByRoleAndStatus = invitesByRole
      .filter(invite => invitesFilteredByStatus.includes(oFetch(invite, 'id')));

    return invitesByRoleAndStatus.map(invite => {
      return {
        ...invite,
        appsPermissions: oFetch(invite, 'invitePermissionVenues').map(invitePermission => {
          return {
            ...invitePermission,
            venueNames: oFetch(invitePermission, 'venueIds')
              .map(venueId => venuesIdName[venueId])
              .join(', '),
          };
        }),
        venueNames: oFetch(invite, 'venueIds')
          .map(venueId => venuesIdName[venueId])
          .join(', '),
      };
    });
  },
);
