import { createAction } from 'redux-actions';
import { apiRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form/immutable';
import { bossRequestHttp } from '@/lib/request-api';
import utils, { normalizeReduxFormErrors } from '@/lib/utils';

import * as constants from './constants';
export const loadInitialState = createAction(constants.LOAD_INITIAL_STATE);
export const addAccessory = createAction(constants.ADD_ACCESSORY);
export const updateAccessoryRequestInStore = createAction(constants.UPDATE_ACCESSORY_REQUEST);
export const loadAccessoryRequests = createAction(constants.LOAD_ACCESSORY_REQUESTS);

export const newAccessory = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const values = oFetch(params, 'values');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'size', 'accessoryId'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addAccessory(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/accessory-requests`, {
    size: oFetch(values, 'size'),
    accessoryId: oFetch(values, 'accessoryId'),
  });
};

export const cancelAccessory = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/accessory-requests/${accessoryRequestId}/cancel`);
};

export const refundAccessory = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');
  const reusable = oFetch(params, 'reusable');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'accessoryRequest'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/accessory-requests/${accessoryRequestId}/refund`, {
    reusable,
  });
};

export const returnAccessory = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');
  const reusable = oFetch(params, 'reusable');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'accessoryRequest'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/accessory-requests/${accessoryRequestId}/return`, {
    reusable,
  });
};

export const writeOffAccessory = params => (dispatch, getState) => {
  const staffMemberId = oFetch(params, 'staffMemberId');
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'accessoryRequest'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/accessory-requests/${accessoryRequestId}/write-off`);
};

export const filter = ({ staffMemberId, mPayslipStartDate, mPayslipEndDate }) => (dispatch, getState) => {
  const apiGetUrl = apiRoutes.staffMemberProfileAccessoriesIndex.getPath({
    staffMemberId,
    mPayslipStartDate,
    mPayslipEndDate,
  });
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(loadAccessoryRequests(data));
    },
  }).get(apiGetUrl);
};

export const editAccessoryRequestPayslipDate = params => (dispatch, getState) => {
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');
  const mPayslipDate = oFetch(params, 'payslipDate');
  const sPayslipDate = mPayslipDate.format(utils.apiDateFormat);
  const route = oFetch(apiRoutes, 'accessoryRequestUpdatePayslipDate');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'payslipDate'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(
    route.getPath({
      accessoryRequestId: accessoryRequestId,
    }),
    {
      payslipDate: sPayslipDate,
    },
  );
};

export const editAccessoryRefundRequestPayslipDate = params => (dispatch, getState) => {
  const accessoryRequestId = oFetch(params, 'accessoryRequestId');
  const mPayslipDate = oFetch(params, 'payslipDate');
  const sPayslipDate = mPayslipDate.format(utils.apiDateFormat);
  const route = oFetch(apiRoutes, 'accessoryRequestRefundUpdatePayslipDate');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'payslipDate'],
        });

        globalNotifications.showDefaultFailureMessage();
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateAccessoryRequestInStore(data));
    },
  }).post(
    route.getPath({
      accessoryRequestId: accessoryRequestId,
    }),
    {
      payslipDate: sPayslipDate,
    },
  );
};
