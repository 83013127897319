import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialGlobalState = [];

const securityShiftRequests = handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const securityShiftRequests = oFetch(action, 'payload.securityShiftRequests');
      return securityShiftRequests;
    },
    [types.CHANGE_WEEK_DAY_COMPLETE]: (state, action) => {
      const securityShiftRequests = oFetch(action, 'payload.securityShiftRequests');
      return securityShiftRequests;
    },
    [types.REMOVE_SHIFT_REQUEST]: (state, action) => {
      const updatedSecurityShiftRequest = oFetch(action, 'payload.securityShiftRequest');
      const id = oFetch(updatedSecurityShiftRequest, 'id');

      return state.filter(securityShiftRequest => oFetch(securityShiftRequest, 'id') !== id);
    },
    [types.ADD_SECURITY_SHIFT_REQUEST]: (state, action) => {
      const newShiftRequest = oFetch(action, 'payload.securityShiftRequest');

      return [...state, newShiftRequest];
    },
    [types.UPDATE_SECURITY_SHIFT_REQUEST]: (state, action) => {
      const updatedSecurityShiftRequest = oFetch(action, 'payload.securityShiftRequest');
      const id = oFetch(updatedSecurityShiftRequest, 'id');

      return state.map(shiftRequest => {
        if (oFetch(shiftRequest, 'id') === id) {
          return updatedSecurityShiftRequest;
        }
        return shiftRequest;
      });
    },
  },
  initialGlobalState,
);

export default securityShiftRequests;
