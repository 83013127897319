import _ from 'lodash';
import queryString from 'query-string';
import { format } from 'date-fns';
import { UI_DATE_FORMAT } from './date-fns-formats';
import { IndexFilterDataMode } from '@/apps/MossHourTags/types';
import { z } from 'zod';

type StaffPartyIndexArgs = {
  paginationPage: number,
  filtering: true,
  name: string,
  email: string,
  filterType: string,
} | {
  paginationPage: number,
  filtering: false,
};

type StaffPartyIndexQueryStringData = {
  page: number
} & Partial<{
  name: string,
  email: string,
  filterType: string,
}>;

export function staffPartyIndex(args: StaffPartyIndexArgs): string {
  const queryStringData: StaffPartyIndexQueryStringData = {
    page: args.paginationPage,
  };

  if (args.filtering) {
    if (!_.isNil(args.name)) {
      queryStringData.name = args.name;
    }
    if (!_.isNil(args.email)) {
      queryStringData.email = args.email;
    }
    if (!_.isNil(args.filterType)) {
      queryStringData.filterType = args.filterType;
    }
  }

  return `staff_party_people?${queryString.stringify(queryStringData)}`;
}

type MossPartyPeopleIndexArgs = {
  paginationPage: number,
  filtering: true,
  name: string,
  email: string,
  filterType: string,
} | {
  paginationPage: number,
  filtering: false,
};

type MossPartyPeopleIndexQueryStringData = {
  page: number
} & Partial<{
  name: string,
  email: string,
  filterType: string,
}>;

export function mossPartyPeopleIndex(args: MossPartyPeopleIndexArgs): string {
  const queryStringData: MossPartyPeopleIndexQueryStringData = {
    page: args.paginationPage,
  };

  if (args.filtering) {
    if (!_.isNil(args.name)) {
      queryStringData.name = args.name;
    }
    if (!_.isNil(args.email)) {
      queryStringData.email = args.email;
    }
    if (!_.isNil(args.filterType)) {
      queryStringData.filterType = args.filterType;
    }
  }

  return `moss_party_people?${queryString.stringify(queryStringData)}`;
}

export function mossStaffMemberProfile(args: { mossStaffMemberId: number }): string {
  return `/moss_staff_members/${args.mossStaffMemberId}`;
}

type MossStaffMemberOwedHourArgs = {
  mossStaffMemberId: number;
  dates: {
    start: Date,
    end: Date,
  } | null;
  payslipDates: {
    start: Date,
    end: Date,
  } | null;
};

export function mossStaffMemberHoursOverview(args: { mossStaffMemberId: number, date: Date }): string {
  return `/moss_staff_members/${args.mossStaffMemberId}/hours_overview/${format(args.date, UI_DATE_FORMAT)}`;
}

export function mossStaffMemberOwedHours(args: MossStaffMemberOwedHourArgs): string {
  let result = `/moss_staff_members/${args.mossStaffMemberId}/owed_hours`;

  if (args.dates !== null || args.payslipDates !== null) {
    result += '?';
  }

  if (args.dates !== null) {
    result += `start_date=${format(args.dates.start, UI_DATE_FORMAT)}&end=${format(args.dates.end, UI_DATE_FORMAT)}`;
  }
  if (args.payslipDates !== null) {
    result += `payslip_start_date=${format(args.payslipDates.start, UI_DATE_FORMAT)}&payslip_end=${format(args.payslipDates.end, UI_DATE_FORMAT)}`;
  }

  return result;
}

export function mossFinanceReports(args: { startDate: Date, venueId: number, extension?: 'pdf' | 'csv' | undefined }) {
  const extenstion = args.extension ? `.${args.extension}` : '';
  return `/moss_finance_reports/${format(args.startDate, UI_DATE_FORMAT)}${extenstion}?venue_id=${args.venueId}`;
}

export const $MossFinanceReportsHourTagsArgs = z.object({
  startDate: z.date(),
  venueId: z.number().int().positive(),
  mossHourTagIds: z.union([
    z.array(z.number().int().positive()),
    z.null(),
  ]),
  extension: z.literal('pdf').optional(),
});

type MossFinanceReportsHourTagsArgs = z.infer<typeof $MossFinanceReportsHourTagsArgs>;

export function mossFinanceReportsHourTags(args: MossFinanceReportsHourTagsArgs): string {
  const extenstion = args.extension ? `.${args.extension}` : '';

  let result = `/moss_finance_reports/${format(args.startDate, UI_DATE_FORMAT)}/hour_tags${extenstion}?venue_id=${args.venueId}`;

  if (args.mossHourTagIds !== null) {
    result += `&moss_hour_tag_ids=${args.mossHourTagIds.join(',')}`;
  }

  return result;
}

export function mossHourTagsIndex(args: { searchQuery: string, mode: IndexFilterDataMode }): string {
  return `/moss_hour_tags?mode=${args.mode}&query=${args.searchQuery}`;
}

export function mossFinanceReportHourTagsReport(args: { startDate: Date, mossHourTagIds: number[] }): string {
  return `/moss_hour_tag_reports?weekStart=${format(args.startDate, UI_DATE_FORMAT)}&mossHourTagIds=${args.mossHourTagIds.join(',')}`;
}

export const $MossHourTagsReportIndexArgs = z.object({
  startDate: z.date(),
  mossHourTagIds: z.union([
    z.array(z.number().int().positive()),
    z.null(),
  ]),
  extension: z.literal('pdf').optional(),
});

type MossHourTagsReportIndexArgs = z.infer<typeof $MossHourTagsReportIndexArgs>;

export function mossHourTagsReportIndex(args: MossHourTagsReportIndexArgs): string {
  const extenstion = args.extension ? `.${args.extension}` : '';

  let result = `/moss_hour_tag_reports${extenstion}?weekStart=${format(args.startDate, UI_DATE_FORMAT)}`;

  if (args.mossHourTagIds !== null) {
    result += `&mossHourTagIds=${args.mossHourTagIds.join(',')}`;
  }

  return result;
}