import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  text: '',
  effectiveStaffTypes: [],
};

export default handleActions(
  {
    [types.FILTER_EFFECTIVE_STAFF_TYPE]: (state, action) => {
      const effectiveStaffTypes = oFetch(action, 'payload.effectiveStaffTypes');
      return {
        ...state,
        effectiveStaffTypes,
      };
    },
    [types.FILTER_NAME]: (state, action) => {
      const text = oFetch(action, 'payload.text');
      return {
        ...state,
        text,
      };
    },
  },
  initialState,
);
