import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';

export function RejectTaskForm(props) {
  const initialValues = oFetch(props, 'initialValues');
  const onSubmit = oFetch(props, 'onSubmit');

  return (
    <>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          Please let the person know what they need to do to complete this task successfully
        </span>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <>
              <div className="boss-form__field">
                <Field name="text" label="Note" parse={value => value} component={BossFormTextarea} />
              </div>
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                >
                  Reject Task
                </button>
              </div>
            </>
          );
        }}
      />
    </>
  );
}
