import oFetch from 'o-fetch';
import React from 'react';

export function InviteLinkedContent(props) {
  const invite = oFetch(props, 'invite');
  const appsPermissions = oFetch(invite, 'appsPermissions');
  const hasAppsPermissions = appsPermissions.length > 0;

  return (
    <div className="boss-check__group boss-check__group_marked">
      <div className="boss-check__group-content">
        <p className="boss-check__text">
          <span className="boss-check__text-marked">App Access</span>
        </p>
        <div className="boss-check__table">
          <div className="boss-table boss-table_page_invites-index-app-access">
            {!hasAppsPermissions && <span>{'N/A'}</span>}
            {appsPermissions.map(appPermissions => {
              const [appPermissionId, appTitle, venueNames] = oFetch(
                appPermissions,
                'id',
                'appTitle',
                'venueNames',
              );
              return (
                <div className="boss-table__row" key={appPermissionId}>
                  <div className="boss-table__cell">
                    <div className="boss-table__text">{appTitle}</div>
                  </div>
                  <div className="boss-table__cell">
                    <div className="boss-table__text">{venueNames || 'N/A'}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
