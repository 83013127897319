import oFetch from 'o-fetch';
import React from 'react';
import AsyncButton from 'react-async-button';

export default class WarningModal extends React.Component {
  state = {
    reasonNote: '',
  };

  handleReasonChange = e => {
    const value = oFetch(e, 'target.value');
    this.setState({ reasonNote: value });
  };

  render() {
    const [onClose, onSubmit] = oFetch(this.props, 'onClose', 'onSubmit');
    const reasonNote = oFetch(this.state, 'reasonNote');

    return (
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
          <div className="boss-modal-window__message-group">
            <p className="boss-modal-window__message-note">
              <span className="boss-modal-window__message-line">
                The total amount of accepted hours for this staff member will be greater than was rotaed.
              </span>
              <span className="boss-modal-window__message-line">
                Please ensure supply a reason to explain the time difference.
              </span>
            </p>
          </div>
          <div className="boss-modal-window__message-group">
            <form action="#" className="boss-form">
              <div className="boss-form__field">
                <p className="boss-form__label">
                  <span className="boss-form__label-text boss-form__label-text_type_required">Reason</span>
                </p>
                <textarea
                  name="reasonNote"
                  value={reasonNote}
                  className="boss-form__textarea"
                  onChange={this.handleReasonChange}
                />
              </div>
            </form>
          </div>
          <div className="boss-modal-window__message-group">
            <p className="boss-modal-window__message-note">
              This will be reviewed later by senior management.
            </p>
          </div>
        </div>
        <div className="boss-modal-window__actions">
          <button
            onClick={onClose}
            type="button"
            className="boss-button boss-button_role_inactive boss-modal-window__button"
          >
            CANCEL
          </button>
          <AsyncButton
            className="boss-button boss-modal-window__button"
            text="ACCEPT"
            pendingText="ACCEPTING ..."
            onClick={() => onSubmit(reasonNote)}
          />
        </div>
      </div>
    );
  }
}
