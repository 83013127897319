import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from './types';
import * as requests from '../../MarketingTasks/requests';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const updateMarketingTaskDetails = createAction(types.UPDATE_MARKETING_TASK_DETAILS);

export const getMarketingTaskDetails = params => dispatch => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return requests.getMarketingTaskDetails({
    values: values,
    onSuccess(data) {
      const [marketingTask, marketingTaskRequests] = oFetch(data, 'marketingTask', 'marketingTaskRequests');
      dispatch(updateMarketingTaskDetails({ marketingTask, marketingTaskRequests }));
      onSuccess();
    },
  });
};
