import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { ModalsProvider } from '@/components/hooks-components/modals';
import configureStore from '../store';
import { initialProfileLoad } from '../profile-wrapper/actions';
import ProfilePage from './containers/profile-page';
import profileReducer from '../profile-wrapper/reducers';

function MossStaffMemberProfileDetailsApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const store = configureStore(
      combineReducers({
        profile: profileReducer,
        form: formReducer,
      }),
    );
    setStore(store);
    store.dispatch(initialProfileLoad({ ...props }));
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <ModalsProvider>
        <ProfilePage />
      </ModalsProvider>
    </Provider>
  );
}

export default MossStaffMemberProfileDetailsApp;
