import React, { useState } from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';

import DashboardWrapper from '@/components/dashboard-wrapper';
import { QuarterSelector } from '@/components/quarter-selector';
import { YearSelector } from '@/components/year-selector';

export function KPIDashboard(props) {
  const [quarter, year, years, kpiData, quarterNumbers] = oFetch(
    props,
    'quarter',
    'year',
    'years',
    'kpiData',
    'quarterNumbers',
  );
  const [selectedQuarter, setSelectedQuarter] = useState(quarter);
  const [selectedYear, setSelectedYear] = useState(year);

  function handleQuarterSelect(value) {
    setSelectedQuarter(value);
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      quarter: value,
    })}`;
    window.location.href = url;
  }

  function getKpiDataSummary() {
    return kpiData.reduce(
      (acc, item) => {
        const [score, kpiId, name] = oFetch(item, 'score', 'kpiId', 'name');
        if (score === 'poor') {
          acc.poor = [...acc.poor, { kpiId: kpiId, name }];
        }
        if (score === 'bad') {
          acc.bad = [...acc.bad, { kpiId: kpiId, name }];
        }
        if (score === null) {
          acc.unrated = [...acc.unrated, { kpiId: kpiId, name }];
        }
        return acc;
      },
      { poor: [], bad: [], unrated: [] },
    );
  }

  function handleYearSelect(value) {
    setSelectedYear(value);
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      year: value,
    })}`;
    window.location.href = url;
  }

  function renderKpis(kpis) {
    if (kpis.length === 0) {
      return 'N/A';
    }
    return kpis
      .map(poorKpi => {
        const [name, kpiId] = oFetch(poorKpi, 'name', 'kpiId');
        return (
          <a key={kpiId} href={`#${kpiId}`} className="boss-table__link">
            {name}
          </a>
        );
      })
      .reduce((prev, curr) => [prev, ', ', curr]);
  }
  const kpiDataSummary = getKpiDataSummary();
  const badKpis = oFetch(kpiDataSummary, 'bad');
  const poorKpis = oFetch(kpiDataSummary, 'poor');
  const unratedKpis = oFetch(kpiDataSummary, 'unrated');

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_kpi">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">KPI</h1>
          <div className="boss-page-dashboard__controls-group">
            <div className="boss-form">
              <div className="boss-form__row">
                <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
                  <p className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                    <span className="boss-form__label-text">Year</span>
                  </p>
                  <YearSelector selected={selectedYear} onChange={handleYearSelect} options={years} />
                </div>
                <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
                  <p className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                    <span className="boss-form__label-text">Quarter</span>
                  </p>
                  <QuarterSelector
                    selected={selectedQuarter}
                    quarterNumbers={quarterNumbers}
                    onChange={handleQuarterSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boss-page-dashboard__group">
          <div className="boss-table boss-table_page_kpi">
            <div className="boss-table__row">
              <div className="boss-table__cell boss-table__cell_state_alert" style={{ width: '85px' }}>
                <div className="boss-table__info">
                  <p className="boss-table__text">Bad</p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <p className="boss-table__text">{renderKpis(badKpis)}</p>
                </div>
              </div>
            </div>
            <div className="boss-table__row">
              <div className="boss-table__cell boss-table__cell_state_warning" style={{ width: '85px' }}>
                <div className="boss-table__info">
                  <p className="boss-table__text">Poor</p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <p className="boss-table__text">{renderKpis(poorKpis)}</p>
                </div>
              </div>
            </div>
            <div className="boss-table__row">
              <div className="boss-table__cell" style={{ width: '85px' }}>
                <div className="boss-table__info">
                  <p className="boss-table__text">Unrated</p>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <p className="boss-table__text">{renderKpis(unratedKpis)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
