import moment from 'moment';
import deepEqual from 'deep-equal';
import Fuse from 'fuse.js';
import lodash from 'lodash';
import underscore from 'underscore';
import queryString from 'query-string';
import { fromJS, Set } from 'immutable';
import numeral from 'numeral';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import { RotaDate } from '@/lib/rota-date';
import { BASE_ERROR_KEY, normalizeFinalFormErrors } from '@/lib/normalize-final-form-errors';

export const BOSS_VENUE_TYPE = 'normal';
export const SECURITY_VENUE_TYPE = 'security';
export const wait = time => new Promise(res => setTimeout(res, time));
// keysArray - ['id', 'name'] to direct map
// keysArray - ['id:value', 'name:label'] to new keys map
export function mapArrayKeys(array, keysArray) {
  const keys = keysArray.map(key => key.trim());
  return array.map(arrayItem => {
    return keys.reduce((acc, key) => {
      const [oldKey, newKey] = key.split(':');

      return {
        ...acc,
        [newKey || oldKey]: arrayItem[oldKey],
      };
    }, {});
  });
}

export const callIfExists = params => {
  const obj = oFetch(params, 'obj');
  const funcName = oFetch(params, 'funcName');
  const args = params.args;

  if (typeof obj[funcName] === 'function') {
    return obj[funcName](args);
  } else {
    return obj;
  }
};

// Sort sort by disabled status (enabled venues first) then alphabetically
export const disabledVenueSortOrder = (venueA, venueB) => {
  const venueAHash = `${oFetch(venueA, 'disabled') ? '1' : '0'}${oFetch(venueA, 'name').toLowerCase()}`;
  const venueBHash = `${oFetch(venueB, 'disabled') ? '1' : '0'}${oFetch(venueB, 'name').toLowerCase()}`;

  if (venueAHash > venueBHash) return 1;
  if (venueAHash < venueBHash) return -1;
  return 0;
};

export const normalizeReduxFormErrors = errors => {
  return Object.entries(errors).reduce((acc, errorEntry) => {
    const [key, error] = errorEntry;
    if (key === 'base') {
      return {
        ...acc,
        _error: error,
      };
    }
    return {
      ...acc,
      [key]: error,
    };
  }, {});
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function smartSearch(entries, keys, searchString) {
  const options = {
    shouldSort: true,
    includeMatches: true,
    tokenize: true,
    minMatchCharLength: 1,
    keys,
  };

  const fuse = new Fuse(entries, options);

  return fuse.search(searchString);
}

export const highlight = (fuseSearchResult, highlightClassName = 'highlight') => {
  const generateHighlightedText = (inputText, regions = []) => {
    let content = '';
    let nextUnhighlightedRegionStartingIndex = 0;
    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1;
      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<strong style="background-color:#FF9">`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</strong>',
      ].join('');

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    content += inputText.substring(nextUnhighlightedRegionStartingIndex);

    return content;
  };

  return fuseSearchResult
    .filter(({ matches }) => matches && matches.length)
    .map(({ item, matches }) => {
      const highlightedItem = { ...item };

      matches.forEach(match => {
        lodash.set(highlightedItem, match.key, generateHighlightedText(match.value, match.indices));
      });

      return highlightedItem;
    });
};

export const zeroOrNot = number => {
  if (number > 9) {
    return number.toString();
  }
  return `0${number}`;
};

numeral.register('locale', 'en-gb', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
  },
  currency: {
    symbol: '£',
  },
});

numeral.locale('en-gb');

export function highlightResult(result, searchQuery, ...keys) {
  const searchQueryFilters = searchQuery
    .replace(/[^A-Za-z0-9 ]/g, '')
    .split(' ')
    .filter(i => i);

  const uniqueFilter = searchQueryFilters.filter((v, i, a) => a.indexOf(v) === i);
  const query = new RegExp(uniqueFilter.join('|'), 'gi');
  const emptyQuery = uniqueFilter.length === 0;

  return result.map(item => {
    return keys.reduce(
      (acc, key) => {
        const highlightKey = `highlight${utils.capitalizeFirstCharacter(key)}`;
        const highlightString = emptyQuery
          ? null
          : (acc[key] || '').replace(query, matched => {
            return `<strong style="background-color:#FF9">${matched}</strong>`;
          });

        if (highlightString) {
          acc[highlightKey] = highlightString;
          acc.highlighted = true;
        }
        return acc;
      },
      { ...item, highlighted: false },
    );
  });
}

export function nestedFilter(items, query, ...keys) {
  const searchQueryFilters = query.split(' ').filter(Boolean);
  if (searchQueryFilters.length === 0) {
    return items;
  }
  const initial = [];
  return items.reduce((result, item) => {
    const anyMatch = keys.some(key => {
      const value = utils.safeGet(() => oFetch(item, key));
      return (
        value &&
        searchQueryFilters.some(filter => {
          const lowerFilter = filter.toLowerCase();
          return value.toLowerCase().indexOf(lowerFilter) >= 0;
        })
      );
    });
    if (anyMatch) {
      return [...result, item];
    }
    return result;
  }, initial);
}

export function objectToOptions(object) {
  return Object.entries(object).map(entry => {
    const [value, label] = entry;
    return { value, label };
  });
}

export function truncate(input, length) {
  if (utils.isUndefined(length)) {
    return input;
  }
  return input.length > length ? `${input.substring(0, length)}...` : input;
}

export function toColumns(items = [], columns = 2, cb) {
  const itemsLength = items.length;
  const rowsCount =
    itemsLength % columns === 0 ? itemsLength / columns : Math.floor(itemsLength / columns) + 1;
  return Array.from({ length: rowsCount }).map((lodash, index) => {
    return items.slice(index * columns, index * columns + columns).map(block => {
      if (!cb) {
        return block;
      }
      return cb(block);
    });
  });
}

export const beautySeparator = (arrayOfWords, separator = ', ', lastSeparator = ' and ') => {
  if (arrayOfWords.length <= 1) {
    return arrayOfWords.join();
  }
  const [lastWord, ...reversedWords] = [...arrayOfWords].reverse();
  const separatedWithoutLast = [...reversedWords].reverse().join(separator);
  return [separatedWithoutLast, lastWord].join(lastSeparator);
};

function replaceFunctionPropsWithStrings(obj) {
  return lodash(obj).mapValues(function (value) {
    if (typeof value === 'function') {
      return value.toString();
    }
    return value;
  });
}

function formatJSDateToUIDate(date) {
  if (!date) {
    return date;
  }
  if (!(date instanceof Date)) {
    throw new Error(`date is not instance of Date`);
  }
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
}

function parseMoneyToBigNumber(x) {
  return new BigNumber(x.replace(/,/g, ''));
}

const API_DATE_FORMAT = 'DD-MM-YYYY';
var utils = {
  centsFormat(cents) {
    return numeral(cents / 100).format('$0,0.00');
  },
  moneyFormat(amount) {
    return numeral(amount).format('$0,0.00');
  },
  colorizedAmount(amount, negative = 'red', positive = 'green', byDefault = 'black') {
    return amount < 0 ? negative : amount > 0 ? positive : byDefault;
  },
  getDaysCountFromInterval(uiStartDate, uiEndDate) {
    const mStartDate = safeMoment.uiDateParse(uiStartDate);
    const mEndDate = safeMoment.uiDateParse(uiEndDate);

    return mEndDate.diff(mStartDate, 'days') + 1;
  },
  parseHTML(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  },
  calculateStaffRotaShift: function (staffMember, shifts, rotas, venues) {
    const weekRotaShifts = shifts
      .filter(shift => shift.get('staff_member') === staffMember.get('id'))
      .map(rotaShift => {
        const rota = rotas.find(rota => rota.get('id') === rotaShift.get('rota'));
        const venue = venues.find(venue => venue.get('id') === rota.get('venue'));
        return rotaShift.set('venueName', venue.get('name'));
      });
    const hoursOnWeek = weekRotaShifts.reduce((result, shift) => {
      const startsAt = safeMoment.iso8601Parse(shift.get('starts_at'));
      const endsAt = safeMoment.iso8601Parse(shift.get('ends_at'));
      return endsAt.diff(startsAt, 'minutes') / 60 + result;
    }, 0);
    return { weekRotaShifts, hoursOnWeek };
  },
  calculateSecurityRotaShift: function (staffMember, shifts, rotas, venues) {
    const weekRotaShifts = shifts.filter(shift => shift.get('staffMemberId') === staffMember.get('id'));
    const hoursOnWeek = weekRotaShifts.reduce((result, shift) => {
      const startsAt = safeMoment.iso8601Parse(shift.get('startsAt'));
      const endsAt = safeMoment.iso8601Parse(shift.get('endsAt'));
      return endsAt.diff(startsAt, 'minutes') / 60 + result;
    }, 0);
    const weekVenueIds = weekRotaShifts.reduce((set, shift) => {
      return set.add(`${shift.get('venueType')}_${shift.get('venueId')}`);
    }, new Set());
    return { weekRotaShifts, hoursOnWeek, weekVenueIds };
  },
  rotaCondensedHolidayDescription(options) {
    const mStartDate = oFetch(options, 'mStartDate');
    const mEndDate = oFetch(options, 'mEndDate');

    if (mStartDate == mEndDate) {
      return `${mStartDate.format('ddd Do')} - ${mEndDate.format('ddd Do')}`;
    } else {
      return `${mStartDate.format('ddd Do')}`;
    }
  },
  rotaCondensedShiftDescription(options) {
    const mDate = oFetch(options, 'mDate');
    // Only time component of these are used
    const mStartTime = oFetch(options, 'mStartTime');
    const mEndTime = oFetch(options, 'mEndTime');

    return `${mDate.format('ddd')} ${mStartTime.format('HH:mm')} to ${mEndTime.format('HH:mm')}`;
  },
  insertUrlParams: function (params) {
    return Object.keys(params)
      .filter(function (k) {
        return params[k] !== undefined && params[k] !== null;
      })
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
  },
  stringEndsWith: function (string, suffix) {
    return string.slice(-suffix.length) == suffix;
  },
  stringStartsWith: function (string, prefix) {
    return string.slice(0, prefix.length) == prefix;
  },
  stringContains: function (string, maybeContainedString) {
    return string.indexOf(maybeContainedString) !== -1;
  },
  secondsToTime(secs) {
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      m: minutes,
      s: seconds,
    };
    return obj;
  },
  containNumberWithinRange(number, range) {
    var [min, max] = range;
    if (number < min) {
      number = min;
    }
    if (number > max) {
      number = max;
    }
    return number;
  },
  addDaysToDate(dDate, days) {
    const mResult = moment(dDate);
    mResult.add(days, 'days');
    return mResult.toDate();
  },
  addHoursToDate(date, hours) {
    const result = new Date();
    result.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return result;
  },
  dateIsValid(date) {
    return !isNaN(date.valueOf());
  },
  immutablyDeleteObjectItem(object, key) {
    var ret = { ...object };
    delete ret[key];
    return ret;
  },
  /**
    This function can be used inside shouldComponentUpdate. If props contain
    functions passed in from the parent deepEqual would always say the props
    have changed, so instead of their identities we compare their string
    representations.
    (Not sure if this is even necessary. Won't the updated functions be called
    even if the UI hasn't been re-rendered?)
    **/
  deepEqualTreatingFunctionsAsStrings(expected, actual) {
    return deepEqual(replaceFunctionPropsWithStrings(expected), replaceFunctionPropsWithStrings(actual));
  },
  humanDateFormatWithTime() {
    // e.g. 23:22 Sun, 14-01-2023
    return 'HH:mm ddd, DD-MM-YYYY';
  },
  humanDateFormatWithTimeAndSeconds() {
    // e.g. 23:22:00 Sun, 14-01-2023
    return 'HH:mm:ss ddd, DD-MM-YYYY';
  },
  // Deprecated: Prefer direct use of apiDateFormat
  formatRotaUrlDate(date) {
    return moment(date).format(API_DATE_FORMAT);
  },
  apiDateFormat: API_DATE_FORMAT,
  calendarWeekDayFormat: 'ddd DD/MM',
  commonDateFormat: 'DD-MM-YYYY',
  uiRotaDateFormat: 'DD-MM-YYYY',
  fullDayFormat: 'dddd, DD MMMM YYYY',
  fullDayTimeFormat: 'dddd, DD MMMM YYYY HH:mm',
  // dddd	Sunday Monday ... Friday Saturday
  monthDateFormat: 'dddd',
  // DD	01 02 ... 30 31
  // MMM	Jan Feb ... Nov Dec
  // YYYY	1970 1971 ... 2029 2030
  tableDateFormat: 'DD MMM YYYY',
  slashDateFormat: 'DD/MM/YYYY',
  timeWithFullDayAndMonthFormat: 'HH:mm Do MMMM YYYY',
  BASE_ERROR_KEY: BASE_ERROR_KEY,
  commonDateFormatWithTime() {
    return 'HH:mm DD-MM-YYYY';
  },
  commonDateFormatTimeOnly() {
    return 'HH:mm';
  },
  // e.g. Mon 23-01-2023
  commonDateFormatCalendar() {
    return 'ddd DD-MM-YYYY';
  },
  // e.g. Mon 23-01-2023
  commonDateFormatWithDay() {
    return 'ddd DD-MM-YYYY';
  },
  humanDateFormatWithDayOfWeek() {
    return 'HH:mm ddd DD-MM-YYYY';
  },
  timeLeftHumanReadable(seconds) {
    return moment.duration(seconds, 'seconds').format("d [days], h [hrs], m [min]", { trim: 'both' });
  },
  formatDeadline(mDeadline) {
    const completionDiff = mDeadline.diff(moment());
    const months = mDeadline.diff(moment(), 'months');

    if (completionDiff < 0) {
      return mDeadline.format(utils.commonDateFormat);
    }

    const completionDuration = moment.duration(completionDiff);

    if (months >= 12) {
      return completionDuration.format('y[ years] M[ months]', {
        trim: 'both',
        useGrouping: false,
        useSignificantDigits: true,
      });
    }
    if (months < 12 && months > 1) {
      return completionDuration.format('M[ months] d[ days]', {
        trim: 'both',
        useGrouping: false,
        useSignificantDigits: true,
      });
    }

    if (months === 1) {
      return completionDuration.format('d[ days] hh[ hours]', {
        trim: 'both',
        useGrouping: false,
        useSignificantDigits: true,
      });
    }

    return completionDuration.format('*d[ days] *hh[ hours] mm[ minutes]', {
      trim: 'both',
      useGrouping: false,
      useSignificantDigits: true,
    });
  },
  capitalizeFirstCharacter(str) {
    if (str.length === 0) {
      return str;
    }
    return str[0].toUpperCase() + str.slice(1);
  },
  copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  },
  formatDateForApi(date) {
    return moment(date).format('DD-MM-YYYY');
  },
  stringIsJson(string) {
    try {
      JSON.parse(string);
      return true;
    } catch (err) {
      return false;
    }
  },
  getWeekStartDate(date) {
    return moment(date).startOf('isoweek').toDate();
  },
  getWeekEndDate(date) {
    return moment(date).endOf('isoweek').toDate();
  },
  indexByClientId(array) {
    return underscore.indexBy(array, 'clientId');
  },
  // http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  formatMoney(x) {
    if (!x) {
      return null;
    }
    // add thousand separators and show 2 decimal digits
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  // truncates float value to decimal places and returns as float.
  // Note: May need to call toFixed when converting to string if trailing zeros are required
  floorToDecimalPlaces(params) {
    const value = oFetch(params, 'value');

    return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  },
  formatMoneyCents(args) {
    const cents = oFetch(args, 'cents');
    const onNull =
      args.onNull ||
      function () {
        throw new Error(
          `Invalid null value supplied. If null is correct pass an onNull handler to return the required value.`,
        );
      };

    if (cents !== 0 && !cents) {
      return onNull();
    }
    const intCents = parseInt(cents);
    const valueNegative = intCents < 0;
    const absCents = Math.abs(intCents);
    const remainderCents = absCents % 100;
    const units = (absCents - remainderCents) / 100;
    return `${valueNegative ? '-' : ''}${units}.${remainderCents.toString().padStart(2, '0')}`;
  },
  parseMoneyToBigNumber: parseMoneyToBigNumber,
  parseMoneyCents(x) {
    return parseMoneyToBigNumber(x).times(100).toNumber();
  },
  datesAreEqual(date1, date2) {
    return moment(date1).format('DD-MM-YYYY') === moment(date2).format('DD-MM-YYYY');
  },
  replaceArrayElement(array, currentElement, newElement) {
    array = lodash.clone(array);
    for (var i = 0; i < array.length; i++) {
      var value = array[i];
      if (value === currentElement) {
        array[i] = newElement;
        return array;
      }
    }
    throw Error('Element not found in array');
  },
  sum(array) {
    var count = 0;
    for (var i = 0, n = array.length; i < n; i++) {
      count += array[i];
    }
    return count;
  },
  round(number, decimals) {
    var factor = Math.pow(10, decimals);
    return Math.round(number * factor) / factor;
  },
  getStringExceptLastCharacter(str) {
    return str.slice(0, str.length - 1);
  },
  capitalize(str) {
    return str[0].toUpperCase() + str.slice(1);
  },
  toSnakeCase(string) {
    return string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  },
  makeAllCapsSnakeCase(str) {
    var parts = str.match(/([A-Z]?[a-z]*)/g);
    parts = parts.filter(part => part !== '');
    return parts.join('_').toUpperCase();
  },
  applyNewPageQueryParams(newQueryParams) {
    const queryStringObject = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    const url = `${window.location.pathname}?${queryString.stringify(
      {
        ...queryStringObject,
        ...newQueryParams,
      },
      { arrayFormat: 'bracket' },
    )}`;
    window.location.href = url;
  },
  setNewPageQueryParams(newQueryParams) {
    const queryStringObject = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    const newQueryString = queryString.stringify(
      {
        ...queryStringObject,
        ...Object.entries(newQueryParams).reduce((acc, entry) => {
          const [key, value] = entry;
          if (value === '' || value === null) {
            acc[key] = undefined;
          } else {
            acc[key] = value;
          }
          return acc;
        }, {}),
      },
      { arrayFormat: 'bracket' },
    );
    const url = newQueryString === '' ? '' : `?${newQueryString}`;
    window.history.pushState('state', 'title', `${window.location.pathname}${url}`);
  },
  getQueryParams(params) {
    const queryStringObject = queryString.parse(params || window.location.search, { arrayFormat: 'bracket' });
    return queryStringObject;
  },
  generateQuickMenuAlias(text) {
    var splitedText = text.split(' ');
    if (splitedText.length > 1) {
      return splitedText[0][0] + splitedText[1][0].toLowerCase();
    } else {
      return text.slice(0, 2);
    }
  },
  quickMenuHighlightResults(filteredQuickMenuData, searchQuery) {
    const searchQueryFilters = searchQuery
      .replace(/[^A-Za-z0-9 ]/g, '')
      .split(' ')
      .filter(i => i);

    const uniqueFilter = searchQueryFilters.filter((v, i, a) => a.indexOf(v) === i);
    const query = new RegExp(uniqueFilter.join('|'), 'gi');

    let newBossSections = oFetch(filteredQuickMenuData, 'bossSections').map(parentItem => {
      if (parentItem.highlightedName) {
        parentItem.highlightedName = parentItem.highlightedName.replace(
          /(<strong style="background-color:#FF9">|<\/strong>)/gi,
          '',
        );
      }
      parentItem.highlightedName = parentItem.name.replace(query, matched => {
        return `<strong style="background-color:#FF9">${matched}</strong>`;
      });
      const childItems = parentItem.items.map(childItem => {
        if (childItem.highlightedDescription) {
          childItem.highlightedDescription = childItem.highlightedDescription.replace(
            /(<strong style="background-color:#FF9">|<\/strong>)/gi,
            '',
          );
        }
        childItem.highlightedDescription = childItem.description.replace(query, matched => {
          return `<strong style="background-color:#FF9">${matched}</strong>`;
        });
        return childItem;
      });
      parentItem.items = childItems;
      return parentItem;
    });

    let newMossSections = oFetch(filteredQuickMenuData, 'mossSections').map(parentItem => {
      if (parentItem.highlightedName) {
        parentItem.highlightedName = parentItem.highlightedName.replace(
          /(<strong style="background-color:#FF9">|<\/strong>)/gi,
          '',
        );
      }
      parentItem.highlightedName = parentItem.name.replace(query, matched => {
        return `<strong style="background-color:#FF9">${matched}</strong>`;
      });
      const childItems = parentItem.items.map(childItem => {
        if (childItem.highlightedDescription) {
          childItem.highlightedDescription = childItem.highlightedDescription.replace(
            /(<strong style="background-color:#FF9">|<\/strong>)/gi,
            '',
          );
        }
        childItem.highlightedDescription = childItem.description.replace(query, matched => {
          return `<strong style="background-color:#FF9">${matched}</strong>`;
        });
        return childItem;
      });
      parentItem.items = childItems;
      return parentItem;
    });

    return {
      bossSections: newBossSections,
      mossSections: newMossSections,
    }
  },
  quickMenuFilter(searchQuery, quickMenuData) {
    const searchQueryFilters = searchQuery
      .replace(/[^A-Za-z0-9 ]/g, '')
      .split(' ')
      .filter(i => i);

    const filteredQuickMenu = searchQueryFilters
      .reduce((menu, filter) => {
        const lowerFilter = filter.toLowerCase();
        const oldBossSections = oFetch(menu, 'bossSections');
        const oldMossSections = oFetch(menu, 'mossSections');

        const newBossSections = oldBossSections.map(section => {
          const sectionName = oFetch(section, 'name');
          const sectionColor = oFetch(section, 'color');
          let newItems = null;

          if (sectionName.toLowerCase().indexOf(lowerFilter) < 0) {
            newItems = oFetch(section, 'items').filter(item => {
              const lowerDescription = oFetch(item, 'description').toLowerCase();
              return lowerDescription.indexOf(lowerFilter) >= 0;
            });
          }

          if (newItems) {
            return {
              name: sectionName,
              color: sectionColor,
              items: newItems,
            };
          } else {
            return section;
          }
        });

        const newMossSections = oldMossSections.map(section => {
          const sectionName = oFetch(section, 'name');
          const sectionColor = oFetch(section, 'color');
          let newItems = null;

          if (sectionName.toLowerCase().indexOf(lowerFilter) < 0) {
            newItems = oFetch(section, 'items').filter(item => {
              const lowerDescription = oFetch(item, 'description').toLowerCase();
              return lowerDescription.indexOf(lowerFilter) >= 0;
            });
          }

          if (newItems) {
            return {
              name: sectionName,
              color: sectionColor,
              items: newItems,
            };
          } else {
            return section;
          }
        });

        return {
          bossSections: newBossSections,
          mossSections: newMossSections,
        };
      },
      quickMenuData,
    )

    const bossSections = oFetch(filteredQuickMenu, 'bossSections').filter((section) => {
      return oFetch(section, 'items.length') > 0;
    });
    const mossSections = oFetch(filteredQuickMenu, 'mossSections').filter((section) => {
      return oFetch(section, 'items.length') > 0;
    });

    return {
      bossSections,
      mossSections,
    };
  },
  filterBy(searchQuery, collection, field) {
    const searchQueryFilters = searchQuery.split(' ').filter(i => i);

    return searchQueryFilters.reduce((acc, filter) => {
      const lowerFilter = filter.toLowerCase();

      return acc.reduce((result, collectionItem) => {
        const searchFieldValue = collectionItem[field];
        if (searchFieldValue.toLowerCase().indexOf(lowerFilter) >= 0) {
          return [...result, collectionItem];
        }
        return result;
      }, []);
    }, collection);
  },
  staffMemberFilter(searchQuery, staffMembers) {
    const searchQueryFilters = searchQuery.split(' ').filter(i => i);

    return searchQueryFilters.reduce((staffMembers, filter) => {
      const lowerFilter = filter.toLowerCase();
      const initial = fromJS([]);
      return staffMembers.reduce((result, staffMember) => {
        const fullName = `${staffMember.get('first_name')} ${staffMember.get('surname')}`;
        if (fullName.toLowerCase().indexOf(lowerFilter) >= 0) {
          return result.push(staffMember);
        }
        return result;
      }, initial);
    }, staffMembers);
  },
  staffMemberFilterCamelCase(searchQuery, staffMembers) {
    const searchQueryFilters = searchQuery.split(' ').filter(i => i);

    return searchQueryFilters.reduce((staffMembers, filter) => {
      const lowerFilter = filter.toLowerCase();
      const initial = fromJS([]);
      return staffMembers.reduce((result, staffMember) => {
        const fullName = `${staffMember.get('firstName')} ${staffMember.get('surname')}`;
        if (fullName.toLowerCase().indexOf(lowerFilter) >= 0) {
          return result.push(staffMember);
        }
        return result;
      }, initial);
    }, staffMembers);
  },
  staffMemberFilterCamelCaseJS(searchQuery, staffMembers) {
    const searchQueryFilters = searchQuery.split(' ').filter(Boolean);

    return searchQueryFilters.reduce((staffMembers, filter) => {
      const lowerFilter = filter.toLowerCase();
      return staffMembers.reduce((result, staffMember) => {
        const fullName = `${staffMember.firstName} ${staffMember.surname}`;
        if (fullName.toLowerCase().indexOf(lowerFilter) >= 0) {
          result.push(staffMember);
          return result;
        }
        return result;
      }, []);
    }, staffMembers);
  },
  staffMemberFilterFullName(searchQuery, staffMembers) {
    const searchQueryFilters = searchQuery.split(' ').filter(i => i);

    return searchQueryFilters.reduce((staffMembers, filter) => {
      const lowerFilter = filter.toLowerCase();
      const initial = fromJS([]);
      return staffMembers.reduce((result, staffMember) => {
        const fullName = staffMember.get('fullName');
        if (fullName.toLowerCase().indexOf(lowerFilter) >= 0) {
          return result.push(staffMember);
        }
        return result;
      }, initial);
    }, staffMembers);
  },
  staffMemberFilterFullNameJS(searchQuery, staffMembers) {
    const searchQueryFilters = searchQuery.split(' ').filter(i => i);

    return searchQueryFilters.reduce((staffMembers, filter) => {
      const lowerFilter = filter.toLowerCase();
      const initial = [];
      return staffMembers.reduce((result, staffMember) => {
        const fullName = oFetch(staffMember, 'fullName');
        if (fullName.toLowerCase().indexOf(lowerFilter) >= 0) {
          result.push(staffMember);
          return result;
        }
        return result;
      }, initial);
    }, staffMembers);
  },
  fullFilter(searchQuery, records, keys) {
    const searchQueryFilters = searchQuery.split(' ').filter(Boolean);
    return records.reduce((acc, record) => {
      const values = keys.map(key => {
        return oFetch(record, key);
      });

      const shouldBeInResult = values.some(value => {
        const stringifiedValue = value.toString();
        return searchQueryFilters.some(searchQueryFilter => {
          const lowerFilter = searchQueryFilter.toLowerCase();
          return stringifiedValue.toLowerCase().indexOf(lowerFilter) >= 0;
        });
      });
      if (shouldBeInResult) {
        return [...acc, record];
      }
      return acc;
    }, []);
  },
  recordMatch(searchQuery, record, key) {
    const searchQueryFilters = searchQuery.split(' ').filter(Boolean);
    const value = oFetch(record, key);

    const stringifiedValue = value.toString();
    const shouldBeInResult = searchQueryFilters.some(searchQueryFilter => {
      const lowerFilter = searchQueryFilter.toLowerCase();
      return stringifiedValue.toLowerCase().indexOf(lowerFilter) >= 0;
    });

    return shouldBeInResult;
  },
  round(number, precision) {
    const shift = (number, precision, reverseShift) => {
      if (reverseShift) {
        precision = -precision;
      }
      const numArray = ('' + number).split('e');
      return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
    };
    return shift(Math.round(shift(number, precision, false)), precision, true);
  },
  formatDateForHoliday(holiday) {
    const startDate = safeMoment.uiDateParse(oFetch(holiday, 'start_date'));
    const endDate = safeMoment.uiDateParse(oFetch(holiday, 'end_date'));
    let dates;

    if (startDate === endDate) {
      dates = startDate.format('ddd DD MMM YYYY');
    } else if (startDate.format('YYYY') === endDate.format('YYYY')) {
      dates = startDate.format('ddd DD MMM YYYY') + ' - ' + endDate.format('ddd DD MMM');
    } else {
      dates = startDate.format('ddd DD MMM YYYY') + ' - ' + endDate.format('ddd DD MMM YYYY');
    }

    return dates;
  },
  intervalRotaDatesFormat(args) {
    const appType = oFetch(args, 'appType');
    const mStartsAt = oFetch(args, 'mStartsAt');
    const mEndsAt = oFetch(args, 'mEndsAt');

    const dayFormat = 'ddd DD/MM/YYYY';
    const hoursFormat = 'HH:mm';
    const mRotaDate = moment(
      RotaDate.fromTime({
        dTime: mStartsAt.toDate(),
        appType,
      }).calendarDate()
    );

    return `${mRotaDate.format(dayFormat)} ${mStartsAt.format(hoursFormat)} - ${mEndsAt.format(
      hoursFormat,
    )}`;
  },
  shiftRequestIntervalFormat(args) {
    const mStartsAt = oFetch(args, 'mStartsAt');
    const mEndsAt = oFetch(args, 'mEndsAt');

    const hoursFormat = 'HH:mm';
    return `${mStartsAt.format(hoursFormat)} - ${mEndsAt.format(hoursFormat)}`;
  },
  shiftRequestDayFormat(args) {
    const mStartsAt = oFetch(args, 'mStartsAt');
    const appType = oFetch(args, 'appType');

    const dayFormat = 'ddd DD/MM/YYYY';
    const mRotaDate = moment(
      RotaDate.fromTime({
        dTime: mStartsAt.toDate(),
        appType,
      }).calendarDate()
    );
    return `${mRotaDate.format(dayFormat)}`;
  },
  addZeroToNumber(number, zeroLimit = 9) {
    return number <= zeroLimit ? `0${number}` : `${number}`;
  },
  formattedTime(timeInMs) {
    const hours = Math.trunc(timeInMs / 1000 / 60 / 60);
    const minutes = Math.trunc((timeInMs / 1000 / 60) % 60);

    if (hours === 0 && minutes === 0) {
      return 0;
    }
    return `${hours === 0 ? '' : `${hours}h`}${minutes === 0 ? '' : `${this.addZeroToNumber(minutes, 9)}m`}`;
  },
  getTimeDiff(startsAt, endsAt) {
    if (endsAt === null) return 0;
    const mStartsAt = safeMoment.iso8601Parse(startsAt).seconds(0);
    const mEndsAt = safeMoment.iso8601Parse(endsAt).seconds(0);
    return mEndsAt.diff(mStartsAt);
  },
  getStartsEndsTimeDiff(items) {
    return items.reduce((acc, item) => {
      const startsAt = oFetch(item, 'startsAt');
      const endsAt = oFetch(item, 'endsAt');
      return acc + this.getTimeDiff(startsAt, endsAt);
    }, 0);
  },
  formatJSDateToUIDate,
  safeGet(fn) {
    try {
      const result = fn();
      return result;
    } catch (e) {}
  },
  createType(prefix, constant) {
    if (!prefix && !constant) {
      throw new Error(
        `Prefix and constant must be present, got: prefix - ${prefix}, constaant - ${constant}`,
      );
    }
    return `${prefix}/${constant}`;
  },
  isString(value) {
    return typeof value === 'string' || value instanceof String;
  },
  isNumber(value) {
    return typeof value === 'number' && isFinite(value);
  },
  isFunction(value) {
    return typeof value === 'function';
  },
  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  },
  isNull(value) {
    return value === null;
  },
  isUndefined(value) {
    return typeof value === 'undefined';
  },
  isBoolean(value) {
    return typeof value === 'boolean';
  },
  isPromise(obj) {
    return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
  },
  arrayDuplicatesCount(array) {
    return array.reduce((prevValue, vote) => {
      if (prevValue[vote]) {
        prevValue[vote]++;
      } else {
        prevValue[vote] = 1;
      }
      return prevValue;
    }, {});
  },
  groupBy(key) {
    return function (array) {
      return array.reduce((objectsByKeyValue, obj) => {
        let value;
        if (typeof key === 'function') {
          value = key(obj);
        } else {
          value = oFetch(obj, key);
        }

        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
    };
  },
  normalizeFinalFormErrors,
  getFullStaffMemberName(args) {
    const staffMember = oFetch(args, 'staffMember');
    return `${oFetch(staffMember, 'first_name')} ${oFetch(staffMember, 'surname')}`;
  },
  callIfExists,
};

export default utils;
