import React from 'react';
import oFetch from 'o-fetch';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import classNames from 'classnames';
import Popover from 'react-popover';
import utils from '@/lib/utils';
import openModal from './boss-modal';
import AddHoliday from './add-holiday';
import { BOSS_APP_TYPE, MOSS_APP_TYPE } from '@/lib/rota-date';

export default class ReportsHeader extends React.Component {
  state = {
    isCalendarOpen: false,
  };

  togglePopover = () => {
    this.setState({ isCalendarOpen: !this.state.isCalendarOpen });
  };

  renderCalendar = () => {
    const pageOptions = oFetch(this.props, 'pageOptions');
    const appType = oFetch(pageOptions, 'appType');

    let appRoute = null;
    if (appType === BOSS_APP_TYPE) {
      appRoute = appRoutes.holidays;
    } else if (appType === MOSS_APP_TYPE) {
      appRoute = appRoutes.mossHolidays;
    } else {
      throw new Error(`unsupported appType supplied: ${appType}`);
    }

    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={oFetch(pageOptions, 'weekStartDate')}
          onChange={({ startUIDate }) => {
            location.href = appRoute({
              sStartDate: startUIDate,
            });
          }}
          onCancelClick={this.togglePopover}
        />
      </div>
    );
  };

  renderCsvDownloadButton() {
    const pageOptions = oFetch(this.props, 'pageOptions');
    let holidayCount = Object.values(oFetch(this.props, 'holidaysCount')).reduce((sum, dayCount) => sum + dayCount, 0);

    if (pageOptions.displayCsvLink && pageOptions.weekStartDate && holidayCount > 0) {
      const appType = oFetch(pageOptions, 'appType');
      const sDate = oFetch(pageOptions, 'weekStartDate');
      const venueId = pageOptions.currentVenueId;

      let appRoute = null;
      if (appType === BOSS_APP_TYPE) {
        appRoute = appRoutes.holidaysCsv;
      } else if (appType === MOSS_APP_TYPE) {
        appRoute = appRoutes.mossHolidaysCsv;
      } else {
        throw new Error(`unsupported appType supplied: ${appType}`);
      }

      return (
        <a
          className="boss-button boss-button_role_csv-download boss-page-dashboard__button"
          href={appRoute({
            sDate,
            venueId,
          })}
        >
          Download as CSV
        </a>
      );
    }
  }

  submit = (hideModal, values, dispatch, canCreateHoliday) => {
    const [createHolidayAction, createHolidayRequestAction] = oFetch(
      this.props,
      'actions.createHolidayAction',
      'actions.createHolidayRequestAction',
    );
    const pageOptions = oFetch(this.props, 'pageOptions');
    const appType = oFetch(pageOptions, 'appType');
    if (canCreateHoliday) {
      return createHolidayAction(values, hideModal, appType);
    }
    return createHolidayRequestAction(values, hideModal, appType);
  };

  renderAddHolidayButton() {
    const pageOptions = oFetch(this.props, 'pageOptions');

    return (
      <button
        onClick={() =>
          openModal(this.submit, {
            venueId: pageOptions.currentVenueId,
            appType: oFetch(pageOptions, 'appType'),
          })(AddHoliday)
        }
        className="boss-button boss-button_role_add boss-page-dashboard__button"
      >
        Add Holidays
      </button>
    );
  }

  render() {
    const pageOptions = oFetch(this.props, 'pageOptions');
    let accessibleVenues = _.pick(this.props.venues, (venue, clientId) => {
      return pageOptions.accessibleVenueIds.includes(venue.serverId);
    });

    let today = safeMoment.uiDateParse(pageOptions.weekStartDate).format(utils.commonDateFormatCalendar());
    let nextWeek = safeMoment
      .uiDateParse(pageOptions.weekStartDate)
      .add(6, 'days')
      .format(utils.commonDateFormatCalendar());
    const popoverClass = classNames({
      'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
      'boss-page-dashboard__meta-item_state_opened': this.state.isCalendarOpen,
    });
    return (
      <div className="boss-page-main__dashboard">
        <div className="boss-page-main__inner">
          <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_holidays-report">
            <h1 className="boss-page-dashboard__title">Holiday Reports</h1>

            <div className="boss-page-dashboard__group">
              <div className="boss-page-dashboard__meta">
                <Popover
                  isOpen={this.state.isCalendarOpen}
                  body={this.renderCalendar()}
                  place="below"
                  appendTarget={document.body}
                  tipSize={0.01}
                  onOuterAction={this.togglePopover}
                  className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                  style={{ marginTop: '10px' }}
                >
                  <p
                    className={popoverClass}
                    onClick={this.togglePopover}
                  >
                    <span className="boss-page-dashboard__meta-text">{today}</span>
                    {' - '}
                    <span className="boss-page-dashboard__meta-text">{nextWeek}</span>
                  </p>
                </Popover>
              </div>

              <div className="boss-page-dashboard__buttons-group">
                {this.renderAddHolidayButton()}
                {this.renderCsvDownloadButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
