import cn from 'classnames';
import oFetch from 'o-fetch';
import React from 'react';
import { BonusFactorForm } from './bonus-factor-form';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function ManagerItem(props) {
  const [
    staffMember,
    bonaiCentsData,
    onMarkNotEligible,
    onMarkEligible,
    onUpdateVenueBonai,
    bonusTier,
    isFutureWeek,
  ] = oFetch(
    props,
    'staffMember',
    'bonaiCentsData',
    'onMarkNotEligible',
    'onMarkEligible',
    'onUpdateVenueBonai',
    'bonusTier',
    'isFutureWeek',
  );
  const [
    staffMemberId,
    avatarUrl,
    name,
    totalHoursCount,
    paidHolidayCount,
    financeReportsCalculating,
    eligibilityData,
  ] = oFetch(
    staffMember,
    'id',
    'avatarUrl',
    'name',
    'totalHoursCount',
    'paidHolidayCount',
    'financeReportsCalculating',
    'eligibilityData',
  );
  const sMarkedAt = eligibilityData && oFetch(eligibilityData, 'markedAt');
  const formattedMarkedAt = eligibilityData && safeMoment.iso8601Parse(sMarkedAt).format(utils.commonDateFormatWithTime());

  const rowClassNames = cn('boss-table__row', {
    'boss-table__row_state_report-requiring-update': financeReportsCalculating,
    'boss-table__row_state_alert': eligibilityData,
  });

  function getBonusTotal(params) {
    const [bonusTier, bonaiCentsData] = oFetch(params, 'bonusTier', 'bonaiCentsData');
    const bonaiCents = oFetch(bonaiCentsData, 'bonaiCents');
    return bonusTier === null || bonaiCents === null ? 'N/A' : bonusTier * bonaiCents;
  }

  return (
    <div key={staffMemberId} className="boss-table__group">
      <div className={rowClassNames}>
        <div className="boss-table__cell">
          <div className="boss-table__image">
            <a href="#" className="boss-table__link">
              <img
                src={avatarUrl}
                alt={name}
                style={{ textAlign: 'center', lineHeight: '27px' }}
                className="boss-avatar boss-avatar_type_combined"
              />
            </a>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Name</p>
            <p className="boss-table__text">
              <a href="#" className="boss-table__link">
                {name}
              </a>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Staff Type</p>
            <p className="boss-table__text">
              <span
                className="boss-button boss-button_type_small boss-button_type_no-behavior"
                style={{ backgroundColor: '#f3a74d' }}
              >
                Manager
              </span>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Hours Worked</p>
            <p className="boss-table__text">
              <a href="#" className="boss-table__link">
                {totalHoursCount}
              </a>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Paid Holidays</p>
            <p className="boss-table__text">
              <a href="#" className="boss-table__link">
                {paidHolidayCount}
              </a>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Bonus Tier</p>
            <p className="boss-table__text">{bonusTier || 'N/A'}</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Bonus Factor</p>
            <BonusFactorForm
              isFutureWeek={isFutureWeek}
              bonaiCentsData={bonaiCentsData}
              onSubmit={onUpdateVenueBonai}
            />
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Bonus Total</p>
            <p className="boss-table__text">{getBonusTotal({ bonusTier, bonaiCentsData })}</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Status</p>
            {!eligibilityData && (
              <div className="boss-table__info-group">
                <p className="boss-table__text boss-table__text_role_eligible-status">Eligible</p>
                <div className="boss-table__actions">
                  <button
                    onClick={() => onMarkNotEligible(staffMember)}
                    className="boss-button boss-button_type_extra-small boss-button_role_edit boss-table__action"
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}
            {eligibilityData && (
              <div className="boss-table__info-group">
                <p className="boss-table__text boss-table__text_role_not-eligible-status">
                  Not Eligible
                </p>
                <div className="boss-table__actions">
                  <button
                    onClick={() => onMarkEligible(staffMember)}
                    className="boss-button boss-button_type_extra-small boss-button_role_edit boss-table__action"
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {eligibilityData && (
        <div className="boss-table__details boss-table__details_state_alert">
          <p className="boss-table__details-text">
            <span className="boss-table__details-text-faded">Reason: </span>{' '}
            {oFetch(eligibilityData, 'reason')}
          </p>
          <p className="boss-table__details-text">
            <span className="boss-table__details-text-faded">{'Marked "Not Eligible" by:'} </span>{' '}
            {oFetch(eligibilityData, 'markedBy')}
            <span className="boss-table__details-text-faded"> at </span>{' '}
            {formattedMarkedAt}
          </p>
        </div>
      )}
    </div>
  );
}
