import React, { useState, useMemo, useEffect } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { useWindowSize } from '@uidotdev/usehooks';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { useSelector } from 'react-redux';
import * as selectors from '../../redux/selectors';
import { ColumnFilter } from './column-filter';
import { TaskList } from './task-list';

Column.defaultProps = {
  className: null,
};

export function Column(props) {
  const [title, className, showAssignOnMe] = oFetch(props, 'title', 'className', 'showAssignOnMe');
  const [tasks, renderTask] = oFetch(props, 'tasks', 'renderTask');
  const currentUserId = oFetch(useLegacyGlobal(), 'currentUserId');
  const tickAssignedOnMe = useSelector(selectors.tickAssignedOnMeSelector);
  const canBeAssigned = useSelector(selectors.canBeAssignedSelector);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [isAssignedOnMe, setIsAssignedOnMe] = useState(canBeAssigned && tickAssignedOnMe && showAssignOnMe);
  const windowSize = useWindowSize();
  const [isTaskListOpened, setIsTaskListOpened] = useState(null);
  const [isOnMobileView, setIsOnMobileView] = useState(null);

  const columnClassNames = cn('boss-tasks boss-tasks_role_marketing', {
    [className]: !!className,
  });

  useEffect(() => {
    if (windowSize.width !== null) {
      const defaultTaskOpened = windowSize.width > 960;
      setIsOnMobileView(!defaultTaskOpened);
      setIsTaskListOpened(defaultTaskOpened);
    }
  }, [windowSize.width]);

  const tasksExist = tasks.length > 0;
  const visibleTasks = useMemo(() => {
    if (selectedVenues.length === 0) {
      return tasks;
    }
    return tasks.filter(task => {
      const taskVenueId = oFetch(task, 'venueId');
      return selectedVenues.includes(taskVenueId);
    });
  }, [tasks, selectedVenues]);

  const assignOnMeTasks = useMemo(() => {
    if (!isAssignedOnMe) {
      return visibleTasks;
    }
    return visibleTasks.filter(task => {
      if (oFetch(task, 'assignedUser')) {
        return oFetch(task, 'assignedUser.id') === currentUserId;
      }
      return false;
    });
  }, [visibleTasks, isAssignedOnMe]);

  function handleFilterChange(venues) {
    setSelectedVenues(venues);
  }

  function handleIsAssignOnMeChange(isAssignOnMe) {
    setIsAssignedOnMe(isAssignOnMe);
  }

  function handleToggleListOpened() {
    setIsTaskListOpened(!isTaskListOpened);
  }

  const totalTasksCount = tasks.length;
  const selectedTasksCount = assignOnMeTasks.length;

  if (windowSize.width === null || isTaskListOpened === null || isOnMobileView === null) {
    return null;
  }

  return (
    <div className="boss-page-main__tasks-column">
      <div className={columnClassNames}>
        <div className="boss-tasks__header">
          <ColumnFilter
            totalCount={totalTasksCount}
            selectedCount={selectedTasksCount}
            title={title}
            showAssignOnMe={showAssignOnMe}
            selectedVenues={selectedVenues}
            isAssignedOnMe={isAssignedOnMe}
            onFilterChange={handleFilterChange}
            onToggleListOpened={handleToggleListOpened}
            onAssignOnMeChanged={handleIsAssignOnMeChange}
          />
        </div>

        {isOnMobileView && (
          <Collapse
            isOpened={isTaskListOpened}
            theme={{
              collapse: 'ReactCollapse--collapse',
              content: 'ReactCollapse--content',
            }}
            initialStyle={{ width: '100%' }}
          >
            <div className="boss-tasks boss-tasks_role_marketing">
              <div className="boss-tasks__content">
                {!tasksExist && (
                  <span className="boss-overview__text-large boss-overview__text-marked">Empty</span>
                )}
                {tasksExist && <TaskList tasks={assignOnMeTasks} renderTask={renderTask} />}
              </div>
            </div>
          </Collapse>
        )}
        {!isOnMobileView && (
          <div className="boss-tasks__content">
            {!tasksExist && (
              <span className="boss-overview__text-large boss-overview__text-marked">Empty</span>
            )}
            {tasksExist && <TaskList tasks={assignOnMeTasks} renderTask={renderTask} />}
          </div>
        )}
      </div>
    </div>
  );
}
