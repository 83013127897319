import React from 'react';
import oFetch from 'o-fetch';
import { SimpleDashboard } from '@/components/boss-dashboards';
import ContentWrapper from '@/components/content-wrapper';

import StaffCardList from './staff-card-list';
import StaffCard from './staff-card';
import HolidayRequestList from './holiday-requests-list';
import HolidayRequestsItem from './holiday-requests-item';
import { BOSS_APP_TYPE, MOSS_APP_TYPE } from '@/lib/rota-date';
import { useLegacyGlobal } from '@/components/hooks-components/global';

function HolidayRequestsPage(props) {
  const globals = useLegacyGlobal();

  const permissionsData = oFetch(props, 'permissionsData');
  const holidayRequests = oFetch(props, 'holidayRequests');
  const effectiveStaffMembers = oFetch(props, 'effectiveStaffMembers');
  const appType = oFetch(globals, 'appType');
  const title = oFetch(globals, 'title');
  const actions = oFetch(props, 'actions');

  function handleAcceptClick(effectiveStaffMemberId, holidayRequestId, forward) {
    const acceptAction = oFetch(actions, 'acceptHolidayRequest');

    return acceptAction({ effectiveStaffMemberId, holidayRequestId, forward });
  }

  function handleRejectClick(effectiveStaffMemberId, holidayRequestId) {
    const rejectAction = oFetch(actions, 'rejectHolidayRequest');

    return rejectAction({ effectiveStaffMemberId, holidayRequestId });
  }

  function getViewReportUrl(effectiveStaffMember, holidayRequest, appType) {
    const date = oFetch(holidayRequest, 'startDate');
    const venueId = oFetch(effectiveStaffMember, 'venueId');

    let result = null;
    if (appType === BOSS_APP_TYPE) {
      result = `/holidays?date=${date}&venue_id=${venueId}`;
    } else if (appType === MOSS_APP_TYPE) {
      result = `/moss_holidays?date=${date}&venue_id=${venueId}`;
    } else {
      throw new Error(`unsupported appType encountered: ${appType}`);
    }

    return result;
  }



  return (
    <div>
      <SimpleDashboard title={title} />
      <ContentWrapper>
        <StaffCardList
          holidayRequests={holidayRequests}
          effectiveStaffMembers={effectiveStaffMembers}
          listObjectName="effectiveStaffMembers"
          perPage={3}
          itemRenderer={(effectiveStaffMember, effectiveStaffMemberHolidayRequests) => {
            return (
              <StaffCard effectiveStaffMember={effectiveStaffMember}>
                <HolidayRequestList
                  holidayRequests={effectiveStaffMemberHolidayRequests}
                  itemRenderer={(holidayRequest) => {
                    return (
                      <HolidayRequestsItem
                        onAcceptClick={(args) => {
                          const forward = oFetch(args, 'forward');
                          return handleAcceptClick(oFetch(effectiveStaffMember, 'id'), oFetch(holidayRequest, 'id'), forward);
                        }}
                        onRejectClick={() =>
                          handleRejectClick(oFetch(effectiveStaffMember, 'id'), oFetch(holidayRequest, 'id'))
                        }
                        holidayRequest={holidayRequest}
                        viewReportUrl={getViewReportUrl(effectiveStaffMember, holidayRequest, appType)}
                        permissionsData={permissionsData}
                      />
                    );
                  }}
                />
              </StaffCard>
            );
          }}
        />
      </ContentWrapper>
    </div>
  );
}

export default HolidayRequestsPage;
