import React from 'react';
import oFetch from 'o-fetch';
import { RequestForm } from '../../forms';
import { UPLOADED_STATE } from '../../forms/upload-files-form-field/file-list';

export function UpdateRequestModalContent(props) {
  const [onSubmit, onClose, marketingTaskRequest] = oFetch(
    props,
    'onSubmit',
    'onClose',
    'marketingTaskRequest',
  );

  const initialValues = {
    taskId: oFetch(marketingTaskRequest, 'marketingTaskId'),
    requestId: oFetch(marketingTaskRequest, 'id'),
    text: oFetch(marketingTaskRequest, 'currentQuestion.text'),
    fileIds: oFetch(marketingTaskRequest, 'currentQuestion.attachments').map(attachment =>
      oFetch(attachment, 'blob_id'),
    ),
  };
  const files = oFetch(marketingTaskRequest, 'currentQuestion.attachments').reduce((acc, attachment) => {
    acc[oFetch(attachment, 'blob_id')] = {
      id: oFetch(attachment, 'blob_id'),
      name: oFetch(attachment, 'filename'),
      url: oFetch(attachment, 'url'),
      file: null,
      state: UPLOADED_STATE,
      isImage: oFetch(attachment, 'is_image'),
      contentType: oFetch(attachment, 'content_type'),
    };
    return acc;
  }, {});

  return (
    <div className="boss-modal-window__form">
      <RequestForm
        initialValues={initialValues}
        files={files}
        onSubmit={onSubmit}
        onClose={onClose}
        buttonText="Update"
      />
    </div>
  );
}
