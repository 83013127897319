import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';

export default function modalRedux(reducers, initialState) {
  return function(Component) {
    return class extends React.Component {
      UNSAFE_componentWillMount = () => {
        this.store = configureStore(reducers, initialState);
      };

      render() {
        return (
          <Provider store={this.store}>
            <Component {...this.props} />
          </Provider>
        );
      }
    };
  };
}
