import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import { formValueSelector, getFormSubmitErrors } from 'redux-form';
import * as c from '../constants';

export const userPermissionsSelector = state => oFetch(state, 'static.userPermissions');
export const checkSiaNumberSelector = state => oFetch(state, 'static.checkSiaNumber');
export const accessoriesSelector = state => oFetch(state, 'static.options.accessories');
export const flaggedStaffMembersFetchingSelector = state =>
  oFetch(state, 'static.flaggedStaffMembersFetching');
export const stepsValidationSelector = state => oFetch(state, 'steps.stepsValidation');
export const currentStepSelector = state => oFetch(state, 'steps.currentStep');
export const flaggedStaffMembersSelector = state => oFetch(state, 'flaggedStaffMembers');
export const validateAvatarForCreateUrlSelector = state => oFetch(state, 'static.validateAvatarForCreateUrl');
export const venueOptionsSelector = state => oFetch(state, 'static.options.venues');
export const accessibleVenueOptionsSelector = createSelector([venueOptionsSelector], venues => {
  return venues.filter(venue => oFetch(venue, 'accessible'));
});
export const payRatesOptionsSelector = state => oFetch(state, 'static.options.payRates');
export const forStandardSecurityStaffSecurityPayRatesOptionsSelector = state =>
  oFetch(state, 'static.options.forStandardSecurityStaffSecurityPayRates');
export const forStewardSecurityStaffSecurityPayRatesOptionsSelector = state =>
  oFetch(state, 'static.options.forStewardSecurityStaffSecurityPayRates');
export const genderOptionsSelector = state => oFetch(state, 'static.options.genders');
export const staffTypeOptionsSelector = state => oFetch(state, 'static.options.staffTypes');
export const securityStaffTypeOptionsSelector = state =>
  oFetch(state, 'static.options.securityStaffTypes');
export const employmentStatusesOptionsSelector = state =>
  oFetch(state, 'static.options.employmentStatuses');
export const formValues = formValueSelector(c.FORM_NAME);

export const formSubmitErrorsSelector = createSelector(
  [getFormSubmitErrors(c.FORM_NAME)],
  submitErrors => {
    return submitErrors;
  },
);

export const getAccessoriesOptions = createSelector([accessoriesSelector], accessories => {
  return accessories.reduce((acc, accessory) => {
    const [id, name, size, icon, color] = oFetch(
      accessory,
      'id',
      'name',
      'size',
      'icon',
      'iconColor',
    );
    if (!size) {
      return [
        ...acc,
        {
          value: `${id}`,
          label: name,
          icon,
          color,
        },
      ];
    }
    const sizes = size.split(',');
    const accessoriesWithSizes = sizes.map(size => {
      return {
        value: `${id}_${size.trim()}`,
        label: `${name} - ${size.trim()}`,
        icon,
        color,
      };
    });
    return [...acc, ...accessoriesWithSizes];
  }, []);
});
export const staffTypeSelector = state => formValues(state, c.STAFF_TYPE_FIELD_NAME);
export const isStewardSelector = state => !!formValues(state, c.STEWARD_FIELD_NAME);
export const masterVenueSelector = state => formValues(state, c.MASTER_VENUE_FIELD_NAME);
export const siaBadgeNumberSelector = state => formValues(state, c.SIA_BADGE_NUMBER_FIELD_NAME);
export const siaBadgeCheckGUIDSelector = state => formValues(state, c.SIA_GUID_FIELD_NAME);
export const canBypassSiaValidationSelector = state =>
  formValues(state, c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME);

export const unReviewedFlaggedStaffMembers = createSelector(
  [flaggedStaffMembersSelector],
  flaggedStaffMembers => {
    return flaggedStaffMembers.filter(staffMember => oFetch(staffMember, 'reviewed') === false);
  },
);

export const reviewedFlaggedStaffMembers = createSelector(
  [flaggedStaffMembersSelector],
  flaggedStaffMembers => {
    return flaggedStaffMembers.filter(staffMember => oFetch(staffMember, 'reviewed') === true);
  },
);

export const unReviewedFlaggedStaffMembersCount = createSelector(
  [unReviewedFlaggedStaffMembers],
  flaggedStaffMembers => {
    return flaggedStaffMembers.length;
  },
);

export const reviewedFlaggedStaffMembersCount = createSelector(
  [reviewedFlaggedStaffMembers],
  flaggedStaffMembers => {
    return flaggedStaffMembers.length;
  },
);

export const getFlaggedStaffMembersFieldsSelector = state => {
  return {
    firstName: formValues(state, c.FIRST_NAME_FIELD_NAME),
    surname: formValues(state, c.SURNAME_FIELD_NAME),
    dateOfBirth: formValues(state, c.DATE_OF_BIRTH_FIELD_NAME),
    emailAddress: formValues(state, c.EMAIL_FIELD_NAME),
    nationalInsuranceNumber: formValues(state, c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME),
  };
};

export const getGenderOptions = createSelector([genderOptionsSelector], genderOptions => {
  return Object.entries(genderOptions).map((entry, index) => {
    const [value, label] = entry;
    return {
      value,
      label,
    };
  });
});

export const getEmploymentStatusesOptions = createSelector(
  [employmentStatusesOptionsSelector],
  employmentStatusesOptions => {
    return Object.entries(employmentStatusesOptions).map((entry, index) => {
      const [value, label] = entry;
      return {
        value,
        label,
      };
    });
  },
);

export const getStaffTypeOptions = createSelector([staffTypeOptionsSelector], staffTypeOptions => {
  return staffTypeOptions.map((staffType, index) => {
    const [id, name, color] = oFetch(staffType, 'id', 'name', 'color');
    return {
      value: id,
      label: name,
      color,
    };
  });
});

export const getSecurityStaffTypesIds = createSelector(
  [securityStaffTypeOptionsSelector],
  securityStaffTypeOptions => {
    return securityStaffTypeOptions.map(staffType => {
      return oFetch(staffType, 'id');
    });
  },
);

export const isSecuritySelector = createSelector(
  [getSecurityStaffTypesIds, staffTypeSelector],
  (securityStaffTypesIds, selectedStaffType) => {
    return securityStaffTypesIds.includes(selectedStaffType);
  },
);
