import React from 'react';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';
import AsyncButton from 'react-async-button';
import oFetch from 'o-fetch';
import utils from "@/lib/utils";
import Select from '@/lib/boss-react-select';

const DATE_FILTER_TYPE = 'date';
const PAYSLIP_DATE_FILTER_TYPE = 'payslipDate';

class HolidaysFilter extends React.Component {
  constructor(props) {
    super(props);

    const startPayslipDate = oFetch(props, 'startPayslipDate');
    const endPayslipDate = oFetch(props, 'endPayslipDate');
    const payslipDatesPresent = startPayslipDate && endPayslipDate;
    const filterType = payslipDatesPresent ? PAYSLIP_DATE_FILTER_TYPE : DATE_FILTER_TYPE;
    const startDate = payslipDatesPresent ? startPayslipDate : oFetch(props, 'startDate');
    const endDate = payslipDatesPresent ? endPayslipDate : oFetch(props, 'endDate');

    this.state = {
      filterType,
      startDate,
      endDate,
    };

    this.filterTypeOptions = [
      { value: DATE_FILTER_TYPE, label: 'Date' },
      { value: PAYSLIP_DATE_FILTER_TYPE, label: 'Payslip Date' },
    ];
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
  };

  onUpdate = () => {
    const { filterType, startDate, endDate } = this.state;
    if (!startDate || !endDate || !filterType) {
      return null;
    }

    let formatedStartDate, formatedEndDate, formatedStartPayslipDate, formatedEndPayslipDate;

    if (filterType === 'date') {
      formatedStartDate = startDate && startDate.format(utils.apiDateFormat);
      formatedEndDate = endDate && endDate.format(utils.apiDateFormat);
    } else if (filterType === 'payslipDate') {
      formatedStartPayslipDate = startDate && startDate.format(utils.apiDateFormat);
      formatedEndPayslipDate = endDate && endDate.format(utils.apiDateFormat);
    } else {
      throw new Error(`Unknown filter type encountered: ${filterType}`);
    }
    return this.props.filter(formatedStartDate, formatedEndDate, formatedStartPayslipDate, formatedEndPayslipDate);
  };

  render() {
    const {
      filterType,
      startDate,
      endDate,
    } = this.state;

    return (
      <div className="boss-board__manager-filter">
        <div className="boss-form">
          <div className="boss-form__group boss-form__group_position_last">
            <h3 className="boss-form__group-title">Filter</h3>
          </div>
          <div className="boss-form__row boss-form__row_align_center boss-form__row_desktop boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_layout_quarter">
              <label className="boss-form__label">
                <span className="boss-form__label-text">Type</span>
              </label>
              <div className="boss-form__select boss-form__select_role_control">
                <Select
                  clearable={false}
                  onChange={(option) => this.setState({ filterType: option.value }) }
                  options={this.filterTypeOptions}
                  value={filterType}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_max boss-form__field_no-label">
              <div className="date-control date-control_type_icon date-control_type_interval-fluid date-control_no-label">
                <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
                  <BossDateRangePicker
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={startDate}
                    endDate={endDate}
                    onApply={this.onDatesChange}
                  />
                </div>
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label">
              <AsyncButton
                className="boss-button boss-form__submit"
                text="Update"
                pendingText="Updating..."
                disabled={ !startDate || !endDate || !filterType }
                onClick={this.onUpdate}
              />
            </div>
          </div>
          <div className="boss-form__row boss-form__row_mobile boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_layout_quarter">
              <p className="boss-form__label">Date: </p>
            </div>
            <div className="boss-form__field boss-form__field_layout_max">
              <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
                <BossDateRangePicker
                  startDateId="startDate"
                  endDateId="endDate"
                  startDate={startDate}
                  endDate={endDate}
                  onApply={this.onDatesChange}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_max boss-form__field_no-label">
              <AsyncButton
                className="boss-button boss-form__submit"
                text="Update"
                disabled={ !startDate || !endDate || !filterType }
                pendingText="Updating..."
                onClick={this.onUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HolidaysFilter;
