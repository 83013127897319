import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import utils from '@/lib/utils';
import MonthElement from './month-element';
import CalendarInfo from './calendar-info';
import oFetch from 'o-fetch';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

function BossDatePicker(props) {
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState(oFetch(props, 'date'));
  const { disabled, disableYear } = props;

  useEffect(() => {
    setDate(oFetch(props, 'date'));
  }, [oFetch(props, 'date')]);

  function handleDateChange(date) {
    // clear button clicked
    if (date === null) {
      oFetch(props, 'onApply')(date);
    }
    if (typeof date !== 'undefined' && date !== null) {
      date.startOf('day');
    }
    setDate(date);
  }

  function handleFocusChange({ focused }) {
    // don't autoclose the datepicker
    if (focused) {
      setFocused(focused);
    }
  }

  function handleApplyChanges() {
    if (!date) {
      return;
    }

    setFocused(() => {
      oFetch(props, 'onApply')(date);
      return false;
    });
  }

  function handleCancelChanges() {
    setDate(oFetch(props, 'date') || null);
    setFocused(false);
  }

  function renderMonthElement({ month, onMonthSelect, onYearSelect }) {
    return (
      <MonthElement
        month={month}
        disableYear={disableYear}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    );
  }

  function renderCalendarInfo() {
    return (
      <CalendarInfo
        date={date}
        onCancel={handleCancelChanges}
        onApply={handleApplyChanges}
      />
    );
  }

  function isOutsideRange(mDay) {
    const { disableBeforeRotaDay } = props;
    if (disableBeforeRotaDay) {
      const dToday = new Date();
      dToday.setHours(0, 0);
      const dDay = mDay.clone().startOf('day').toDate();
      return dDay < dToday;
    }
    return false;
  }

  function isDayBlocked(mDay) {
    return false;
  }

  const [id, className, numberOfMonths, showClearDate, invalid] = oFetch(
    props,
    'id',
    'className',
    'numberOfMonths',
    'showClearDate',
    'invalid',
  );
  const { isOutsideRange: isOutsideRangeFromProps, isDayBlocked: isDayBlockedFromProps } = props;

  function getInitialMonthAndYear() {
    if (props.initialMonth || props.initialYear) {
      return moment().set('month', props.initialMonth || moment().get('month')).set('year', props.initialYear || moment().get('year'));
    }
    return moment();
  }

  return (
    <div className={`${className} ${invalid ? 'date-control_state_error' : ''}`}>
      <SingleDatePicker
        numberOfMonths={numberOfMonths}
        firstDayOfWeek={1}
        weekDayFormat="ddd"
        withPortal
        initialVisibleMonth={() => getInitialMonthAndYear()}
        showClearDate={showClearDate}
        isOutsideRange={isOutsideRangeFromProps || isOutsideRange}
        displayFormat={utils.commonDateFormat}
        date={date}
        onDateChange={handleDateChange}
        focused={focused}
        disabled={disabled}
        onFocusChange={handleFocusChange}
        id={id}
        isDayBlocked={isDayBlockedFromProps || isDayBlocked}
        renderMonthElement={renderMonthElement}
        renderCalendarInfo={renderCalendarInfo}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
}

BossDatePicker.propTypes = {
  id: PropTypes.string,
  date: PropTypes.oneOfType([momentPropTypes.momentObj, PropTypes.string]),
  onApply: PropTypes.func.isRequired,
  className: PropTypes.string,
  numberOfMonths: PropTypes.number,
  initialMonth: PropTypes.number,
  initialYear: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  showClearDate: PropTypes.bool,
  invalid: PropTypes.bool,
  disableBeforeRotaDay: PropTypes.bool,
  disabled: PropTypes.bool,
  disableYear: PropTypes.bool
};

BossDatePicker.defaultProps = {
  className: 'date-control date-control_type_icon',
  date: null,
  numberOfMonths: 1,
  isOutsideRange: null,
  isDayBlocked: null,
  showClearDate: false,
  invalid: false,
  disableYear: false,
  disableBeforeRotaDay: false,
  id: 'date',
  disabled: false,
};

export default BossDatePicker;
