import React, { useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { UserProfile } from './user-profile';

export function UserProfileApp(props) {
  const [permittedRolesOptions, venues, venuesAccess, apps, permissions] = oFetch(
    props,
    'permittedRolesOptions',
    'venues',
    'venuesAccess',
    'apps',
    'permissions',
  );

  useEffect(() => {
    require('./styles.sass');
    window.boss.accessToken = oFetch(props, 'accessToken');
  }, []);

  const venuesOptions = useMemo(() => {
    return venues.map(venue => {
      const [venueId, venueName, appTypes] = oFetch(venue, 'id', 'name', "appTypes");
      return {
        value: venueId,
        label: venueName,
        appTypes,
      };
    });
  }, []);

  return (
    <LegacyGlobalProvider
      global={{
        permittedRolesOptions,
        venues,
        apps,
        venuesOptions,
        venuesAccess,
        permissions,
      }}
    >
      <ModalsProvider>
        <UserProfile {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
