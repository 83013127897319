import React from 'react';
import oFetch from 'o-fetch';

export default function AnswersList(props) {
  const [question, answerRender] = oFetch(props, 'question', 'answerRender');
  const [questionText, answers, answersDescription, imageUrl] = oFetch(
    question,
    'text',
    'answers',
    'answersDescription',
    'imageUrl',
  );
  function renderAnswers() {
    return answers.map(answer => {
      const answerId = oFetch(answer, 'id');
      return React.cloneElement(answerRender(answer), {
        key: answerId.toString(),
      });
    });
  }

  return (
    <div className="boss-choice-list boss-choice-list_role_question-preview">
      <div className="boss-choice-list__ribbon-container">
        <p className="boss-choice-list__ribbon boss-choice-list__ribbon_color_accent-blue">Preview</p>
      </div>
      <div className="boss-choice-list__header">
        {imageUrl && (
          <img src={imageUrl} alt="Question Image" className="boss-choice-list__image" crossOrigin="anonymous" />
        )}
        <h3 className="boss-choice-list__title">{questionText}</h3>
        <p className="boss-choice-list__text">
          <span className="boss-choice-list__text-faded">{answersDescription}</span>
        </p>
      </div>
      <div className="boss-choice-list__content">
        <div className="boss-choice-list__controls">{renderAnswers()}</div>
      </div>
    </div>
  );
}
