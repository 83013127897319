import oFetch from 'o-fetch';
import React from 'react';
import { CurrencyInput } from '@/components/currency-input';

export function BonusFactorFormField(props) {
  const [onChange, value, submitting] = oFetch(
    props,
    'input.onChange',
    'input.value',
    'meta.submitting',
  );
  const handleCurrencyInputChange = (value, floatvalue) => {
    if (value === null) {
      onChange(null);
    } else {
      onChange(value * 100);
    }
  };

  function getValue(value) {
    if (value === null || value === '') {
      return null;
    }
    return (value / 100).toString();
  }

  return (
    <CurrencyInput
      disabled={submitting}
      value={getValue(value)}
      onChange={handleCurrencyInputChange}
      placeholder={''}
      className={'boss-form__select boss-form__select_size_narrow boss-form__select_size_full'}
      decimalSymbol="."
    />
  );
}
