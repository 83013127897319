import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import oFetch from 'o-fetch';

import EmploymentDetailsForm from './employment-details-form';
import PersonalDetailsForm from './personal-details-form';

function EditProfilePage(props) {
  const [accessibleVenues, masterVenue, mossStaffTypes, mossStaffMember, mossPayRates, canChangePublicHolidays] = oFetch(
    props,
    'accessibleVenues',
    'masterVenue',
    'mossStaffTypes',
    'mossStaffMember',
    'mossPayRates',
    'canChangePublicHolidays'
  );

  const personaletailsFormInitial = {
    first_name: oFetch(mossStaffMember, 'first_name'),
    surname: oFetch(mossStaffMember, 'surname'),
  };

  const employmentDetailsFormInitial = {
    moss_pay_rate: oFetch(mossStaffMember, 'moss_pay_rate'),
    master_venue: oFetch(mossStaffMember, 'master_venue'),
    moss_staff_type: oFetch(mossStaffMember, 'moss_staff_type'),
    generatePublicHolidays: oFetch(mossStaffMember, 'generatePublicHolidays'),
  };

  const mossStaffMemberId = oFetch(mossStaffMember, 'id');

  return (
    <div className="boss-content-switcher">
      <Tabs
        className="boss-content-switcher__inner"
        selectedTabClassName="boss-content-switcher__nav-link_state_active"
      >
        <TabList className="boss-content-switcher__nav boss-content-switcher__side">
          <Tab className="boss-content-switcher__nav-link">Employment Details</Tab>
          <Tab className="boss-content-switcher__nav-link">Personal Details</Tab>
        </TabList>

        <section className="boss-content-switcher__chapters">
          <TabPanel
            selectedClassName="boss-content-switcher__chapter_state_visible"
            className="boss-content-switcher__chapter"
          >
            <EmploymentDetailsForm
              initialValues={employmentDetailsFormInitial}
              masterVenue={masterVenue}
              mossStaffTypes={mossStaffTypes}
              mossPayRates={mossPayRates}
              canChangePublicHolidays={canChangePublicHolidays}
              mossStaffMemberId={mossStaffMemberId}
              accessibleVenues={accessibleVenues}
            />
          </TabPanel>
          <TabPanel
            selectedClassName="boss-content-switcher__chapter_state_visible"
            className="boss-content-switcher__chapter"
          >
            <PersonalDetailsForm
              initialValues={personaletailsFormInitial}
              venues={accessibleVenues}
              mossStaffTypes={mossStaffTypes}
            />
          </TabPanel>
        </section>
      </Tabs>
    </div>
  );
}

export default EditProfilePage;
