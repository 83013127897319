import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { clockingStatuses, BOSS_STAFF_TYPE, MOSS_STAFF_TYPE } from './constants';
import { BOSS_APP_TYPE } from '@/lib/rota-date';

function getEffectiveStaffMemberAcceptedPeriodsAndBreaks(params) {
  const [hoursAcceptancePeriods, hoursAcceptanceBreaks, specialPayRateSecurityHours] = oFetch(
    params,
    'hoursAcceptancePeriods',
    'hoursAcceptanceBreaks',
    'specialPayRateSecurityHours',
  );
  const acceptedHoursAcceptancePeriods = hoursAcceptancePeriods.filter(
    hoursAcceptancePeriod => oFetch(hoursAcceptancePeriod, 'status') === 'accepted',
  );
  const acceptedHoursAcceptancePeriodsIds = acceptedHoursAcceptancePeriods.map(
    acceptedHoursAcceptancePeriod => oFetch(acceptedHoursAcceptancePeriod, 'id'),
  );
  const acceptedHoursAcceptanceBreaks = hoursAcceptanceBreaks.filter(hoursAcceptanceBreak =>
    acceptedHoursAcceptancePeriodsIds.includes(oFetch(hoursAcceptanceBreak, 'hoursAcceptancePeriod')),
  );
  const acceptedSpecialPayRateSecurityHours = specialPayRateSecurityHours.filter(
    specialPayRateSecurityHoursItem =>
      acceptedHoursAcceptancePeriodsIds.includes(
        oFetch(specialPayRateSecurityHoursItem, 'hoursAcceptancePeriod'),
      ),
  );
  return {
    acceptedHoursAcceptancePeriods,
    acceptedHoursAcceptanceBreaks,
    acceptedSpecialPayRateSecurityHours,
  };
}

function getEffectiveStaffMemberClockInEventsAndBreaks(params) {
  const [clockInPeriods, clockInBreaks, clockInEvents] = oFetch(
    params,
    'clockInPeriods',
    'clockInBreaks',
    'clockInEvents',
  );

  const clockInPeriodsIds = clockInPeriods.map(cip => oFetch(cip, 'id'));

  const effectiveStaffMemberClockInBreaks = clockInBreaks.filter(clockInBreak =>
    clockInPeriodsIds.includes(oFetch(clockInBreak, 'clockInPeriod')),
  );
  const effectiveStaffMemberClockInEvents = clockInEvents.filter(cie =>
    clockInPeriodsIds.includes(oFetch(cie, 'clockInPeriod')),
  );
  return { effectiveStaffMemberClockInBreaks, effectiveStaffMemberClockInEvents };
}

function getEffectiveStaffMemberRotaShifts(params) {
  const [rotas, rotaShifts, iEffectiveStaffMemberId] = oFetch(
    params,
    'rotas',
    'rotaShifts',
    'iEffectiveStaffMemberId',
  );
  if (rotas === null) {
    return [];
  }

  const rotaIds = rotas.map(r => oFetch(r, 'id'));
  return rotaShifts.filter(
    rotaShift =>
      oFetch(rotaShift, 'effectiveStaffMember') === iEffectiveStaffMemberId &&
      rotaIds.includes(oFetch(rotaShift, 'rota')),
  );
}

export const filterTextSelector = state => oFetch(state, 'filter.text');
export const filterEffectiveStaffTypesSelector = state => oFetch(state, 'filter.effectiveStaffTypes');
export const effectiveStaffMembersSelector = state => oFetch(state, 'effectiveStaffMembers');
export const clockInPeriodsSelector = state => oFetch(state, 'clockInPeriods');
export const clockInNotesSelector = state => oFetch(state, 'clockInNotes');
export const clockInBreaksSelector = state => oFetch(state, 'clockInBreaks');
export const clockInEventsSelector = state => oFetch(state, 'clockInEvents');
export const rotaShiftsSelector = state => oFetch(state, 'rotaShifts');
export const rotasSelector = state => oFetch(state, 'rotas');
export const hoursAcceptancePeriodsSelector = state => oFetch(state, 'hoursAcceptancePeriods');
export const hoursAcceptanceBreaksSelector = state => oFetch(state, 'hoursAcceptanceBreaks');
export const specialPayRateSecurityHoursSelector = state => oFetch(state, 'specialPayRateSecurityHours');
export const staticDataSelector = state => oFetch(state, 'staticData');
export const owedHoursSelector = state => oFetch(state, 'owedHours');

export const getMossHourTags = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'mossHourTags');
});

export const getCurrentVenue = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'currentVenue');
});

export const getAllSecurityPeriodsAccepted = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'allSecurityPeriodsAccepted');
});

export const getActiveSpecialSecurityPayrates = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'activeSpecialSecurityPayrates');
});

export const getPageOptions = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'pageOptions');
});

export const permissionsSelector = createSelector([getPageOptions], pageOptions => {
  return oFetch(pageOptions, 'permissions');
});

export const getCanCreatePeriods = createSelector([permissionsSelector], permissions => {
  return oFetch(permissions, 'canCreatePeriods');
});

export const getCanAddShifts = createSelector([permissionsSelector], permissions => {
  return oFetch(permissions, 'canCreatePeriods');
});

function isClockedIn(params) {
  const status = oFetch(params, 'status');
  const clockingStatusesArray = Object.values(clockingStatuses);
  if (!clockingStatusesArray.includes(status)) {
    throw new Error(`Unsupported clocking status:${status} encountered`);
  }
  return [clockingStatuses.CLOCKED_IN_STATUS, clockingStatuses.ON_BREAK_STATUS].includes(status);
}

function getItemTimeDiff(props) {
  const item = oFetch(props, 'item');
  const [startsAt, endsAt] = oFetch(item, 'startsAt', 'endsAt');
  if (endsAt === null) return 0;
  const mStartsAt = safeMoment.iso8601Parse(startsAt);
  const mEndsAt = safeMoment.iso8601Parse(endsAt);
  return mEndsAt.diff(mStartsAt);
}

function getItemsTimeDiff(props) {
  const items = oFetch(props, 'items');
  return items.reduce((acc, item) => {
    return acc + getItemTimeDiff({ item });
  }, 0);
}

function getEffectiveStaffMember(props) {
  const [effectiveStaffMember, effectiveStaffTypes] = oFetch(
    props,
    'effectiveStaffMember',
    'effectiveStaffTypes',
  );
  const effectiveStaffTypeId = oFetch(effectiveStaffMember, 'effectiveStaffType');

  const effectiveStaffMemberEffectiveStaffType = effectiveStaffTypes.find(
    effectiveStaffType => oFetch(effectiveStaffType, 'id') === effectiveStaffTypeId,
  );
  if (!effectiveStaffMemberEffectiveStaffType) {
    throw new Error('Wrong staff member staff type');
  }
  return {
    ...effectiveStaffMember,
    effectiveStaffTypeData: effectiveStaffMemberEffectiveStaffType,
  };
}

function getStatus(clockInPeriods) {
  const sortedClockInPeriods = sortBy(clockInPeriods, 'date');
  const latestClockInPeriod = sortedClockInPeriods[sortedClockInPeriods.length - 1];

  return oFetch(latestClockInPeriod, 'status');
}

function getClockInPeriods(params) {
  const [clockInPeriods, clockInBreaks] = oFetch(params, 'clockInPeriods', 'clockInBreaks');
  return clockInPeriods.map(clockInPeriod => {
    const clockInPeriodId = oFetch(clockInPeriod, 'id');
    const clockInPeriodBreaks = clockInBreaks.filter(
      clockInBreak => oFetch(clockInBreak, 'clockInPeriod') === clockInPeriodId,
    );
    return {
      ...clockInPeriod,
      breaks: clockInPeriodBreaks,
    };
  });
}

function getHoursAcceptancePeriods(params) {
  const [hoursAcceptancePeriods, hoursAcceptanceBreaks, specialPayRateSecurityHours] = oFetch(
    params,
    'hoursAcceptancePeriods',
    'hoursAcceptanceBreaks',
    'specialPayRateSecurityHours',
  );
  return hoursAcceptancePeriods.map(hoursAcceptancePeriod => {
    const hoursAcceptancePeriodId = oFetch(hoursAcceptancePeriod, 'frontendId');
    const hoursAcceptancePeriodBreaks = hoursAcceptanceBreaks.filter(
      hoursAcceptanceBreak =>
        oFetch(hoursAcceptanceBreak, 'hoursAcceptancePeriod') === hoursAcceptancePeriodId,
    );
    const hoursAcceptancePeriodSpecialPayrateSecurityHours = specialPayRateSecurityHours.filter(
      specialPayRateSecurityHoursItem =>
        oFetch(specialPayRateSecurityHoursItem, 'hoursAcceptancePeriod') === hoursAcceptancePeriodId,
    );
    const hoursAcceptanceBreaksTime = getItemsTimeDiff({ items: hoursAcceptancePeriodBreaks });
    const hoursAcceptanceSpecialSecurityTime = getItemsTimeDiff({
      items: specialPayRateSecurityHours,
    });
    return {
      ...hoursAcceptancePeriod,
      formattedTime: getFormattedHoursAcceptancePeriodTime({
        hoursAcceptancePeriodTime: getHoursAcceptancePeriodTime({ hoursAcceptancePeriod }),
        hoursAcceptancePeriodBreaksTime: hoursAcceptanceBreaksTime,
      }),
      formattedBreaksTime: formattedTime(hoursAcceptanceBreaksTime),
      specialHoursFormattedTime: formattedTime(hoursAcceptanceSpecialSecurityTime),
      breaks: hoursAcceptancePeriodBreaks,
      specialPayRateSecurityHours: hoursAcceptancePeriodSpecialPayrateSecurityHours,
    };
  });
}

function addZeroToNumber(number, zeroLimit = 9) {
  return number <= zeroLimit ? `0${number}` : `${number}`;
}

function formattedTime(timeInMs) {
  const absTimeInMs = Math.abs(timeInMs);
  const hours = Math.trunc(absTimeInMs / 1000 / 60 / 60);
  const minutes = Math.trunc((absTimeInMs / 1000 / 60) % 60);

  if (hours === 0 && minutes === 0) {
    return 0;
  }
  return `${hours === 0 ? '' : `${hours}h`} ${minutes === 0 ? '' : `${addZeroToNumber(minutes, 9)}m`}`;
}

function getRotaedTime(params) {
  const rotaShifts = oFetch(params, 'rotaShifts');
  return getItemsTimeDiff({ items: rotaShifts });
}

function getHoursAcceptancePeriodTime(params) {
  const hoursAcceptancePeriod = oFetch(params, 'hoursAcceptancePeriod');
  return getItemTimeDiff({ item: hoursAcceptancePeriod });
}

export function getHoursAcceptanceTime(params) {
  const [hoursAcceptancePeriods, hoursAcceptanceBreaks] = oFetch(
    params,
    'hoursAcceptancePeriods',
    'hoursAcceptanceBreaks',
  );
  const hoursAcceptancePeriodsTime = getItemsTimeDiff({ items: hoursAcceptancePeriods });
  const hoursAcceptanceBreaksTime = getItemsTimeDiff({ items: hoursAcceptanceBreaks });

  return hoursAcceptancePeriodsTime - hoursAcceptanceBreaksTime;
}

function getClockedTime(params) {
  const [clockInPeriods, clockInBreaks] = oFetch(params, 'clockInPeriods', 'clockInBreaks');

  const clockInPeriodsTime = getItemsTimeDiff({ items: clockInPeriods });
  const clockInBreaksTime = getItemsTimeDiff({ items: clockInBreaks });
  return clockInPeriodsTime - clockInBreaksTime;
}

function getFormattedClockedTime(params) {
  const clockedTime = oFetch(params, 'clockedTime');
  return formattedTime(clockedTime);
}

function getHoursAcceptanceTimeLeft(params) {
  const [hoursAcceptanceTime, rotaedTime] = oFetch(params, 'hoursAcceptanceTime', 'rotaedTime');
  return rotaedTime - hoursAcceptanceTime;
}

function getFormattedRotaedTime(params) {
  const rotaedTime = oFetch(params, 'rotaedTime');
  const appType = oFetch(params, 'appType');

  if (appType == BOSS_APP_TYPE) {
    return formattedTime(rotaedTime);
  } else if (appType === MOSS_STAFF_TYPE) {
    return 'N/A';
  } else {
    throw new Error(`Unsupport appType encountered: ${appType}`);
  }
}

function getFormattedHoursAcceptanceTime(params) {
  const hoursAcceptanceTime = oFetch(params, 'hoursAcceptanceTime');
  return formattedTime(hoursAcceptanceTime);
}

function getFormattedHoursAcceptancePeriodTime(params) {
  const [hoursAcceptancePeriodTime, hoursAcceptancePeriodBreaksTime] = oFetch(
    params,
    'hoursAcceptancePeriodTime',
    'hoursAcceptancePeriodBreaksTime',
  );
  return formattedTime(hoursAcceptancePeriodTime - hoursAcceptancePeriodBreaksTime);
}

function getFormattedAcceptedTimeDiff(params) {
  const timeDiff = oFetch(params, 'timeDiff');

  if (timeDiff === 0) {
    return null;
  }
  if (timeDiff < 0) {
    return `(-${formattedTime(timeDiff)})`;
  }
  return `(+${formattedTime(timeDiff)})`;
}

function getClockingDayLastEvent(params) {
  const clockInEvents = oFetch(params, 'clockInEvents');
  const clockInEventsCount = clockInEvents.length;

  if (clockInEventsCount === 0 || clockInEventsCount === 1) {
    return null;
  }

  const lastEvent = clockInEvents[clockInEvents.length - 1];

  return lastEvent;
}

function getClockingDayFirstEvent(params) {
  const clockInEvents = oFetch(params, 'clockInEvents');
  if (clockInEvents.length === 0) {
    return null;
  }
  const firstEvent = clockInEvents[0];
  return firstEvent;
}

function getOvertimeReasons(params) {
  const hoursAcceptancePeriods = oFetch(params, 'hoursAcceptancePeriods');
  return hoursAcceptancePeriods.reduce((acc, hoursAcceptancePeriod) => {
    const [reasonNote, acceptedAt, acceptedBy] = oFetch(
      hoursAcceptancePeriod,
      'reasonNote',
      'acceptedAt',
      'acceptedBy',
    );
    if (!reasonNote) {
      return acc;
    }
    const acceptedTime = getHoursAcceptancePeriodTime({ hoursAcceptancePeriod });
    const formattedAcceptedTime = formattedTime(acceptedTime);
    const formattedAcceptedAt = safeMoment.iso8601Parse(acceptedAt).format(utils.commonDateFormat);
    return [
      ...acc,
      {
        formattedAcceptedTime: formattedAcceptedTime,
        reasonNote: reasonNote,
        formattedAcceptedAt: formattedAcceptedAt,
        acceptedBy: acceptedBy,
      },
    ];
  }, []);
}

function normalizeClockInEvent(params) {
  const clockInEvents = oFetch(params, 'clockInEvents');

  return clockInEvents.map(clockInEvent => {
    const authenticationMethod = oFetch(clockInEvent, 'authenticationMethod');
    const managerEvents = ['manager_pin', 'manager_photo', 'manager'];
    const isManagerEvent = managerEvents.includes(authenticationMethod);

    return {
      ...clockInEvent,
      isManagerEvent,
    };
  });
}

class ClockingDay {
  constructor(props) {
    const [
      effectiveStaffMember,
      clockInPeriods,
      date,
      effectiveStaffTypes,
      clockingDayId,
      clockingDayNotes,
      rotaShifts,
      clockInBreaks,
      hoursAcceptancePeriods,
      hoursAcceptanceBreaks,
      clockInEvents,
      acceptedHoursAcceptanceBreaks,
      acceptedHoursAcceptancePeriods,
      venue,
      canAddShifts,
      specialPayRateSecurityHours,
      acceptedSpecialPayRateSecurityHours,
    ] = oFetch(
      props,
      'effectiveStaffMember',
      'clockInPeriods',
      'date',
      'effectiveStaffTypes',
      'clockingDayId',
      'clockingDayNotes',
      'rotaShifts',
      'clockInBreaks',
      'hoursAcceptancePeriods',
      'hoursAcceptanceBreaks',
      'clockInEvents',
      'acceptedHoursAcceptanceBreaks',
      'acceptedHoursAcceptancePeriods',
      'venue',
      'canAddShifts',
      'specialPayRateSecurityHours',
      'acceptedSpecialPayRateSecurityHours',
    );
    const [effectiveStaffMemberId, isSecurity] = oFetch(effectiveStaffMember, 'id', 'isSecurity');
    this.effectiveStaffMember = getEffectiveStaffMember({ effectiveStaffMember, effectiveStaffTypes });
    this.isSecurity = isSecurity;
    const appType = oFetch(effectiveStaffMember, 'applicationType');
    this.hoursOverviewUrl = args => {
      const effectiveStaffMember = oFetch(args, 'effectiveStaffMember');
      const date = oFetch(args, 'date');
      const appType = oFetch(effectiveStaffMember, 'applicationType');

      let effectiveStaffMemberUrlFragment = '';
      if (appType == BOSS_STAFF_TYPE) {
        effectiveStaffMemberUrlFragment = 'staff_members';
      } else if (appType == MOSS_STAFF_TYPE) {
        effectiveStaffMemberUrlFragment = 'moss_staff_members';
      } else {
        throw new Error(`unsuppoted appType; ${appType}`);
      }

      return `/${effectiveStaffMemberUrlFragment}/${effectiveStaffMemberId}/hours_overview/${date}`;
    };
    this.profileUrl = args => {
      const effectiveStaffMember = oFetch(args, 'effectiveStaffMember');
      const appType = oFetch(effectiveStaffMember, 'applicationType');

      let effectiveStaffMemberUrlFragment = '';
      if (appType == BOSS_STAFF_TYPE) {
        effectiveStaffMemberUrlFragment = 'staff_members';
      } else if (appType == MOSS_STAFF_TYPE) {
        effectiveStaffMemberUrlFragment = 'moss_staff_members';
      } else {
        throw new Error(`unsuppoted appType; ${appType}`);
      }

      return `/${effectiveStaffMemberUrlFragment}/${effectiveStaffMemberId}`;
    };
    this.clockInPeriods = getClockInPeriods({ clockInPeriods, clockInBreaks });
    this.clockInEvents = normalizeClockInEvent({ clockInEvents });
    this.notes = clockingDayNotes;
    this.date = date;
    this.venue = venue;
    this.mDate = safeMoment.uiDateParse(date);
    this.formattedDate = this.mDate.format(utils.fullDayFormat);
    this.id = clockingDayId;
    this.status = getStatus(clockInPeriods);
    this.rotaShifts = rotaShifts;
    this.clockedTime = getClockedTime({ clockInPeriods, clockInBreaks });
    this.rotaedTime = getRotaedTime({ rotaShifts });
    this.hoursAcceptanceTime = getHoursAcceptanceTime({
      hoursAcceptancePeriods,
      hoursAcceptanceBreaks,
    });
    this.acceptedVsClockedDiff = oFetch(this, 'clockedTime') - oFetch(this, 'hoursAcceptanceTime');
    this.hoursAcceptanceTimeLeft = getHoursAcceptanceTimeLeft({
      hoursAcceptanceTime: oFetch(this, 'hoursAcceptanceTime'),
      rotaedTime: this.rotaedTime,
    });
    this.formattedClockedTime = getFormattedClockedTime({ clockedTime: this.clockedTime });
    this.formattedRotaedTime = getFormattedRotaedTime({ rotaedTime: this.rotaedTime, appType });
    this.formattedHoursAcceptanceTime = getFormattedHoursAcceptanceTime({
      hoursAcceptanceTime: this.hoursAcceptanceTime,
    });
    this.formattedHoursAcceptanceTimeLeft = getFormattedAcceptedTimeDiff({
      timeDiff: this.hoursAcceptanceTimeLeft,
    });
    this.formattedAcceptedHoursAcceptanceTime = getFormattedHoursAcceptanceTime({
      hoursAcceptanceTime: this.hoursAcceptanceTime,
    });
    this.formattedAcceptedVsClockedTimeDiff = getFormattedAcceptedTimeDiff({
      timeDiff: oFetch(this, 'acceptedVsClockedDiff'),
    });
    this.formattedAcceptedHoursAcceptanceTimeLeft = getFormattedAcceptedTimeDiff({
      timeDiff: this.hoursAcceptanceTimeLeft,
    });
    this.hoursAcceptancePeriods = getHoursAcceptancePeriods({
      hoursAcceptancePeriods,
      hoursAcceptanceBreaks,
      specialPayRateSecurityHours,
    });
    this.acceptedHoursAcceptancePeriods = getHoursAcceptancePeriods({
      hoursAcceptancePeriods: acceptedHoursAcceptancePeriods,
      hoursAcceptanceBreaks: acceptedHoursAcceptanceBreaks,
      specialPayRateSecurityHours: acceptedSpecialPayRateSecurityHours,
    });
    this.firstEvent = getClockingDayFirstEvent({ clockInEvents: this.clockInEvents });
    this.lastEvent = getClockingDayLastEvent({ clockInEvents: this.clockInEvents });
    this.isClockedIn = isClockedIn({ status: this.status });
    this.overtimeReasons = getOvertimeReasons({
      hoursAcceptancePeriods: this.acceptedHoursAcceptancePeriods,
    });
    this.canAddShifts = canAddShifts;
  }
}

export const getEffectiveStaffTypes = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'effectiveStaffTypes');
});

export const getVenues = createSelector([staticDataSelector], staticData => {
  return oFetch(staticData, 'venues');
});

export const getFilteredEffectiveStaffMembers = createSelector(
  [effectiveStaffMembersSelector, filterTextSelector, filterEffectiveStaffTypesSelector],
  (effectiveStaffMembers, text, effectiveStaffTypes) => {
    const filterByNameEffectiveStaffMembers =
      text === '' ? effectiveStaffMembers : utils.staffMemberFilterFullNameJS(text, effectiveStaffMembers);
    const filterByEffectiveStaffTypeAndNameEffectiveStaffMembers =
      effectiveStaffTypes.length === 0
        ? filterByNameEffectiveStaffMembers
        : filterByNameEffectiveStaffMembers.filter(filterByNameEffectiveStaffMember => {
          const filterByNameEffectiveStaffMemberEffectiveStaffTypeId = oFetch(
            filterByNameEffectiveStaffMember,
            'effectiveStaffType',
          );
          return effectiveStaffTypes.includes(filterByNameEffectiveStaffMemberEffectiveStaffTypeId);
        });
    return filterByEffectiveStaffTypeAndNameEffectiveStaffMembers;
  },
);

export const getClockInDays = createSelector(
  [
    getFilteredEffectiveStaffMembers,
    clockInPeriodsSelector,
    getEffectiveStaffTypes,
    clockInNotesSelector,
    clockInBreaksSelector,
    rotaShiftsSelector,
    rotasSelector,
    hoursAcceptancePeriodsSelector,
    hoursAcceptanceBreaksSelector,
    clockInEventsSelector,
    getVenues,
    getCanAddShifts,
    specialPayRateSecurityHoursSelector,
  ],
  (
    effectiveStaffMembers,
    clockInPeriods,
    effectiveStaffTypes,
    clockInNotes,
    clockInBreaks,
    rotaShifts,
    rotas,
    hoursAcceptancePeriods,
    hoursAcceptanceBreaks,
    clockInEvents,
    venues,
    canAddShifts,
    specialPayRateSecurityHours,
  ) => {
    const groupedByDateVenueAndEffectiveStaffMemberClockInPeriods = groupBy(
      clockInPeriods,
      i => `${oFetch(i, 'date')}__${oFetch(i, 'effectiveStaffMember')}__${oFetch(i, 'venue')}`,
    );
    const groupedByDateVenueAndEffectiveStaffMemberClockInNotes = groupBy(
      clockInNotes,
      i => `${oFetch(i, 'date')}__${oFetch(i, 'effectiveStaffMember')}__${oFetch(i, 'venue')}`,
    );
    const groupedByDateRotas = groupBy(rotas, i => oFetch(i, 'date'));
    const groupedByDateVenueAndEffectiveStaffMemberHoursAcceptancePeriods = groupBy(
      hoursAcceptancePeriods,
      i => `${oFetch(i, 'date')}__${oFetch(i, 'effectiveStaffMember')}__${oFetch(i, 'venueId')}`,
    );
    return Object.entries(groupedByDateVenueAndEffectiveStaffMemberClockInPeriods).reduce((acc, entry) => {
      const [key, clockInPeriods] = entry;
      const [date, sEffectiveStaffMemberId, venueId] = key.split('__');
      const venue = venues.find(venue => oFetch(venue, 'id') === parseInt(venueId));
      const iEffectiveStaffMemberId = parseInt(sEffectiveStaffMemberId);
      const effectiveStaffMember = effectiveStaffMembers.find(
        effectiveStaffMember => oFetch(effectiveStaffMember, 'id') === iEffectiveStaffMemberId,
      );
      if (!effectiveStaffMember) {
        return acc;
      }
      const dateRotas = groupedByDateRotas[date] || null;
      const clockInDayRotas =
        dateRotas && dateRotas.filter(rota => oFetch(rota, 'venue') === parseInt(venueId));
      const clockInDayRotaShifts = getEffectiveStaffMemberRotaShifts({
        rotas: clockInDayRotas,
        rotaShifts,
        iEffectiveStaffMemberId,
      });
      const [effectiveStaffMemberClockInBreaks, effectiveStaffMemberClockInEvents] = oFetch(
        getEffectiveStaffMemberClockInEventsAndBreaks({ clockInPeriods, clockInBreaks, clockInEvents }),
        'effectiveStaffMemberClockInBreaks',
        'effectiveStaffMemberClockInEvents',
      );

      const effectiveStaffMemberHoursAcceptancePeriods =
        groupedByDateVenueAndEffectiveStaffMemberHoursAcceptancePeriods[key] || [];
      const effectiveStaffMemberHoursAcceptancePeriodsIds = effectiveStaffMemberHoursAcceptancePeriods.map(
        hap => oFetch(hap, 'frontendId'),
      );
      const clockingDayNotes = groupedByDateVenueAndEffectiveStaffMemberClockInNotes[key] || [];

      const effectiveStaffMemberHoursAcceptanceBreaks = hoursAcceptanceBreaks.filter(hoursAcceptanceBreak =>
        effectiveStaffMemberHoursAcceptancePeriodsIds.includes(
          oFetch(hoursAcceptanceBreak, 'hoursAcceptancePeriod'),
        ),
      );

      const effectiveStaffMemberSpecialPayrateSecurityHours = specialPayRateSecurityHours.filter(
        specialPayRateSecurityHoursItem =>
          effectiveStaffMemberHoursAcceptancePeriodsIds.includes(
            oFetch(specialPayRateSecurityHoursItem, 'hoursAcceptancePeriod'),
          ),
      );

      const [
        acceptedHoursAcceptancePeriods,
        acceptedHoursAcceptanceBreaks,
        acceptedSpecialPayRateSecurityHours,
      ] = oFetch(
        getEffectiveStaffMemberAcceptedPeriodsAndBreaks({
          hoursAcceptancePeriods: effectiveStaffMemberHoursAcceptancePeriods,
          hoursAcceptanceBreaks: effectiveStaffMemberHoursAcceptanceBreaks,
          specialPayRateSecurityHours: effectiveStaffMemberSpecialPayrateSecurityHours,
        }),
        'acceptedHoursAcceptancePeriods',
        'acceptedHoursAcceptanceBreaks',
        'acceptedSpecialPayRateSecurityHours',
      );

      return [
        ...acc,
        new ClockingDay({
          effectiveStaffMember,
          effectiveStaffTypes,
          clockInPeriods,
          venue,
          clockInBreaks: effectiveStaffMemberClockInBreaks,
          clockInEvents: effectiveStaffMemberClockInEvents,
          rotaShifts: clockInDayRotaShifts,
          hoursAcceptancePeriods: effectiveStaffMemberHoursAcceptancePeriods,
          hoursAcceptanceBreaks: effectiveStaffMemberHoursAcceptanceBreaks,
          specialPayRateSecurityHours: effectiveStaffMemberSpecialPayrateSecurityHours,
          acceptedHoursAcceptancePeriods: acceptedHoursAcceptancePeriods,
          acceptedHoursAcceptanceBreaks: acceptedHoursAcceptanceBreaks,
          acceptedSpecialPayRateSecurityHours: acceptedSpecialPayRateSecurityHours,
          clockingDayNotes,
          date,
          clockingDayId: key,
          canAddShifts,
        }),
      ];
    }, []);
  },
);
