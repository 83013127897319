import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { loadInitialData } from './redux/actions';
import reducers from './redux/reducers';
import SecurityShiftRequestsPage from './containers/security-shift-requests-page';

export function SecurityShiftRequestsApp(props) {
  const [store, setStore] = useState(null);
  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <SecurityShiftRequestsPage />
    </Provider>
  );
}
