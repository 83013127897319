import { combineReducers } from 'redux';
import page from './page-options';
import mossFinanceReports from './moss-finance-reports';
import { mossFinanceReportValidationErrorsReducer } from './moss-finance-report-validation-errors';
import { mossFinanceReportsOverviewDataReducer } from './moss-finance-reports-overview-data';
import mossFinanceReportWeekReducer from './moss-finance-report-week';

export default combineReducers({
  page,
  mossFinanceReports,
  mossFinanceReportValidationErrors: mossFinanceReportValidationErrorsReducer,
  mossFinanceReportsOverviewData: mossFinanceReportsOverviewDataReducer,
  mossFinanceReportWeek: mossFinanceReportWeekReducer,
});
