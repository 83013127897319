export const FORM_NAME = 'add-staff-member-wizard';
export const PAGES_COUNT = 6;
export const STEPS = {
  1: 'Basic Information',
  2: 'Work',
  3: 'Venue',
  4: 'Accessories',
  5: 'Preview',
};

export const FIRST_NAME_FIELD_NAME = 'firstName';
export const SURNAME_FIELD_NAME = 'surname';
export const GENDER_FIELD_NAME = 'gender';
export const DATE_OF_BIRTH_FIELD_NAME = 'dateOfBirth';
export const SIA_GUID_FIELD_NAME = 'siaValidationGUID';

export const AVATAR_FIELD_NAME = 'avatar';
export const SETUP_FOR_FACIAL_FIELD_NAME = 'setupForFacialRecognition';
export const VALIDATED_AVATAR_GUID_FIELD_NAME = 'validatedAvatarGUID';

export const EMAIL_FIELD_NAME = 'email';
export const ADDRESS_FIELD_NAME = 'address';
export const COUNTRY_FIELD_NAME = 'country';
export const COUNTY_FIELD_NAME = 'county';
export const POST_CODE_FIELD_NAME = 'postcode';
export const PHONE_NUMBER_FIELD_NAME = 'phoneNumber';

export const STAFF_TYPE_FIELD_NAME = 'staffType';
export const PIN_CODE_FIELD_NAME = 'pinCode';
export const SIA_BADGE_NUMBER_FIELD_NAME = 'siaBadgeNumber';
export const CAN_BYPASS_SIA_VALIDATION_FIELD_NAME = 'canBypassSiaValidation';
export const SIA_BADGE_EXPIRY_DATE_FIELD_NAME = 'siaBadgeExpiryDate';
export const NATIONAL_INSURANCE_NUMBER_FIELD_NAME = 'nationalInsuranceNumber';
export const DAY_PREFERENCE_FIELD_NAME = 'dayPreference';
export const HOUR_PREFERENCE_FIELD_NAME = 'hourPreference';
export const PAY_RATE_FIELD_NAME = 'payRate';
export const STEWARD_FIELD_NAME = 'steward';
export const LINK_MOSS_STAFF_MEMBER_FIELD_NAME = 'linkedMossStaffMemberId';
export const FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME =
  'forStandardSecurityStaffSecurityPayRate';
export const FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME =
  'forStewardSecurityStaffSecurityPayRate';
export const STARTER_EMPLOYMENT_STATUS_FIELD_NAME = 'startedEmploymentStatus';

export const MASTER_VENUE_FIELD_NAME = 'masterVenue';
export const OTHER_VENUES_FIELD_NAME = 'otherVenues';
export const STARTS_AT_FIELD_NAME = 'startsAt';

export const ACCESSORIES_FIELD_NAME = 'accessories';

export const BASIC_INFORMATION_FORM_INITIAL_VALUES = {
  [STAFF_TYPE_FIELD_NAME]: null,
  [STEWARD_FIELD_NAME]: null,
  [LINK_MOSS_STAFF_MEMBER_FIELD_NAME]: null,
  [SIA_BADGE_NUMBER_FIELD_NAME]: null,
  [CAN_BYPASS_SIA_VALIDATION_FIELD_NAME]: false,
  [SIA_GUID_FIELD_NAME]: null,
  [SIA_BADGE_EXPIRY_DATE_FIELD_NAME]: null,
  [FIRST_NAME_FIELD_NAME]: null,
  [SURNAME_FIELD_NAME]: null,
  [GENDER_FIELD_NAME]: null,
  [DATE_OF_BIRTH_FIELD_NAME]: null,
  [AVATAR_FIELD_NAME]: null,
  [SETUP_FOR_FACIAL_FIELD_NAME]: true,
  [VALIDATED_AVATAR_GUID_FIELD_NAME]: null,
  [NATIONAL_INSURANCE_NUMBER_FIELD_NAME]: null,
  [EMAIL_FIELD_NAME]: null,
  [ADDRESS_FIELD_NAME]: null,
  [COUNTRY_FIELD_NAME]: null,
  [COUNTY_FIELD_NAME]: null,
  [POST_CODE_FIELD_NAME]: null,
  [PHONE_NUMBER_FIELD_NAME]: null,
};

export const WORK_FORM_INITIAL_VALUES = {
  [DAY_PREFERENCE_FIELD_NAME]: null,
  [HOUR_PREFERENCE_FIELD_NAME]: null,
  [PAY_RATE_FIELD_NAME]: null,
  [FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]: null,
  [FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]: null,
  [STARTER_EMPLOYMENT_STATUS_FIELD_NAME]: null,
  [PIN_CODE_FIELD_NAME]: null,
};

export const VENUE_FORM_INITIAL_VALUES = {
  [MASTER_VENUE_FIELD_NAME]: null,
  [OTHER_VENUES_FIELD_NAME]: [],
  [STARTS_AT_FIELD_NAME]: null,
};

export const ACCESSORIES_FORM_INITIAL_VALUES = {
  [ACCESSORIES_FIELD_NAME]: {},
};

export const BASIC_FORM_FIELDS_TITLES = {
  [STAFF_TYPE_FIELD_NAME]: 'Staff Type',
  [SIA_BADGE_NUMBER_FIELD_NAME]: 'SIA badge number',
  [SIA_BADGE_EXPIRY_DATE_FIELD_NAME]: 'SIA badge expiry date',
  [STEWARD_FIELD_NAME]: 'Steward',
  [LINK_MOSS_STAFF_MEMBER_FIELD_NAME]: 'Link Staff Member (Optional)',
  [FIRST_NAME_FIELD_NAME]: 'First Name',
  [SURNAME_FIELD_NAME]: 'Surname',
  [GENDER_FIELD_NAME]: 'Gender',
  [DATE_OF_BIRTH_FIELD_NAME]: 'Date Of Birth',
  [EMAIL_FIELD_NAME]: 'Email',
  [NATIONAL_INSURANCE_NUMBER_FIELD_NAME]: 'National Insurance Number',
  [ADDRESS_FIELD_NAME]: 'Line 1',
  [COUNTY_FIELD_NAME]: 'County',
  [COUNTRY_FIELD_NAME]: 'Country',
  [POST_CODE_FIELD_NAME]: 'Post Code',
  [PHONE_NUMBER_FIELD_NAME]: 'Phone Number',
};

export const AVATAR_FORM_FIELDS_TITLES = {
  [VALIDATED_AVATAR_GUID_FIELD_NAME]: 'Avatar',
  [SETUP_FOR_FACIAL_FIELD_NAME]: 'Setup for facial recognition',
};

export const WORK_FORM_FIELDS_TITLES = {
  [DAY_PREFERENCE_FIELD_NAME]: 'Day Preference',
  [HOUR_PREFERENCE_FIELD_NAME]: 'Hour Preference',
  [PAY_RATE_FIELD_NAME]: 'Pay Rate',
  [FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]: 'Pay Rate',
  [FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME]: 'Pay Rate',
  [STARTER_EMPLOYMENT_STATUS_FIELD_NAME]: 'Starter Employment Status',
  [PIN_CODE_FIELD_NAME]: 'PIN Code',
};

export const VENUE_FORM_FIELDS_TITLES = {
  [MASTER_VENUE_FIELD_NAME]: 'Master Venue',
  [OTHER_VENUES_FIELD_NAME]: 'Other Venues',
  [STARTS_AT_FIELD_NAME]: 'Starts At',
};

export const STEPS_FIELDS = {
  1: BASIC_FORM_FIELDS_TITLES,
  2: WORK_FORM_FIELDS_TITLES,
  3: VENUE_FORM_FIELDS_TITLES,
  4: {},
  5: {},
};
