import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import oFetch from 'o-fetch';
import { BossFormInput, BossFormTextarea, BossFormSelectNew, BossFormCalendar } from '@/components/boss-form';
import utils from '@/lib/utils';

const FORM_NAME = 'reminder-form';

class ReminderForm extends Component {
  handleSubmit = (values, dispatch, form) => {
    return form.onSubmit(values);
  };
  render() {
    const [handleSubmit, buttonText, submitting, recurrenceTypesOptions, isEditing, showDateField] = oFetch(
      this.props,
      'handleSubmit',
      'buttonText',
      'submitting',
      'recurrenceTypesOptions',
      'isEditing',
      'showDateField',
    );
    return (
      <div className="boss-form">
        <Field name="title" label="Title" placeholder="Enter title ..." component={BossFormInput} />
        <Field
          name="description"
          label="Description"
          placeholder="Enter description ..."
          component={BossFormTextarea}
        />
        {!isEditing && (
          <Field
            name="recurrenceType"
            label="Recurrence Type"
            placeholder="Select recurrence type ..."
            component={BossFormSelectNew}
            options={recurrenceTypesOptions}
          />
        )}
        {showDateField && (
          <Field
            name="date"
            label="Date"
            disableBeforeRotaDay
            placeholder="Select date ..."
            component={BossFormCalendar}
            valueFormat={utils.commonDateFormat}
          />
        )}
        <div className="boss-form__field">
          <button
            disabled={submitting}
            onClick={handleSubmit(this.handleSubmit)}
            className="boss-button boss-button_role_add boss-form__submit"
            type="submit"
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
})(ReminderForm);
