export const INITIAL_LOAD = 'PROFILE/INITIAL_LOAD';
export const UPDATE_AVATAR = 'PROFILE/UPDATE_AVATAR';
export const EDIT_PROFILE = 'PROFILE/EDIT_PROFILE';
export const CANCEL_EDIT_PROFILE = 'PROFILE/CANCEL_EDIT_PROFILE';
export const UPDATE_STAFF_MEMBER = 'PROFILE/UPDATE_STAFF_MEMBER';
export const UPDATE_DOWNLOAD_LINK_LAST_SENT_AT = 'PROFILE/UPDATE_DOWNLOAD_LINK_LAST_SENT_AT';

export const SHOW_DISABLE_MODAL = 'PROFILE/SHOW_DISABLE_MODAL';
export const HIDE_DISABLE_MODAL = 'PROFILE/HIDE_DISABLE_MODAL';
export const SHOW_EDIT_AVATAR_MODAL = 'PROFILE/SHOW_EDIT_AVATAR_MODAL';
export const HIDE_EDIT_AVATAR_MODAL = 'PROFILE/HIDE_EDIT_AVATAR_MODAL';
export const UPDATE_STAFF_MEMBER_BANK_DETAILS = 'PROFILE/UPDATE_STAFF_MEMBER_BANK_DETAILS';
export const UPDATE_BASE_SECURITY_PAYRATES = 'PROFILE/UPDATE_BASE_SECURITY_PAYRATES';
export const DISABLE_STAFF_MEMBER = 'PROFILE/DISABLE_STAFF_MEMBER';

export const SECURITY_TYPE_ID = 11;
export const OFFICE_BASED_WORKER_TYPE = 'office';
