import React from 'react';
import oFetch from 'o-fetch';

PayRatesList.defaultProps = {
  description: null,
};

export default function PayRatesList(props) {
  const [description, payRates, itemRender] = oFetch(
    props,
    'description',
    'payRates',
    'itemRender',
  );

  function renderItems() {
    return payRates.map((payRate, index) => {
      return React.cloneElement(itemRender(payRate), {
        key: index,
      });
    });
  }

  return (
    <div className="boss-board__table">
      {description && <p className="boss-board__text">{description}</p>}
      <div className="boss-table boss-table_page_pay-rates">
        <div className="boss-table__row">
          <div className="boss-table__cell boss-table__cell_role_header">Name</div>
          <div className="boss-table__cell boss-table__cell_role_header">Staff Members</div>
          <div className="boss-table__cell boss-table__cell_role_header">Calculation Type</div>
          <div className="boss-table__cell boss-table__cell_role_header">Rate</div>
          <div className="boss-table__cell boss-table__cell_role_header">Actions</div>
        </div>
        {renderItems()}
      </div>
    </div>
  );
}
