import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { Filter, NamesList } from './components';

function filter(names, query) {
  const queryWords = query.split(' ').filter(Boolean);

  return queryWords.reduce((names, queryWord) => {
    return names.reduce((acc, namesItem) => {
      const { names } = namesItem;
      if (names.toLowerCase().indexOf(queryWord) >= 0) {
        return [...acc, namesItem];
      }
      return acc;
    }, []);
  }, names);
}

function filterHighlights(names, searchQuery) {
  const searchQueryFilters = searchQuery
    .replace(/[^A-Za-z0-9 ]/g, '')
    .split(' ')
    .filter(Boolean);

  const uniqueFilter = searchQueryFilters.filter((v, i, a) => a.indexOf(v) === i);
  const query = new RegExp(uniqueFilter.join('|'), 'gi');

  if (uniqueFilter.length === 0) {
    return names;
  }

  return names.map((name) => {
    const { names } = name;
    const highlightedNames = names.replace(query, (matched) => {
      return `<strong style="background-color:#FF9">${matched}</strong>`;
    });
    return {
      ...name,
      highlightedNames: highlightedNames,
    };
  });
}

export default function NamesApp(props) {
  const names = oFetch(props, 'names');
  const [filteredNames, setNames] = useState(names);

  function handleFilterChange(e) {
    const value = oFetch(e, 'target.value');
    setNames(filterHighlights(filter(names, value), value));
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text">First Name Aliases</span>
            </h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <Filter onChange={handleFilterChange} />
        <NamesList names={filteredNames} />
      </ContentWrapper>
    </Fragment>
  );
}
