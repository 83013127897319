import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';

import reducers from './redux/reducers';
import { loadInitialData } from './redux/actions';
import Page from './containers/page';

export function SecurityVenuesApp(props) {
  const [store, setStore] = useState(null);
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <Page />
    </Provider>
  );
}
