import React from 'react';
import { SubmissionError } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';

import IncidentReportForm from '../../components/incident-report-form';

import { saveIncidentReport } from '../actions';

function EditIncidentReport({ incidentReport }) {
  const initialValues = {
    id: incidentReport.id,
    venueId: incidentReport.venueId,
    date: moment(incidentReport.incidentTime),
    time: safeMoment.iso8601Parse(incidentReport.incidentTime),
    location: incidentReport.location,
    description: incidentReport.description,
    involvedWitnessDetails: incidentReport.involvedWitnessDetails,
    recordedByName: incidentReport.recordedByName,
    cameraName: incidentReport.cameraName,
    report: incidentReport.report,
    uninvolvedWitnessDetails: incidentReport.uninvolvedWitnessDetails,
    policeOfficerDetails: incidentReport.policeOfficerDetails,
    showOnDoorBook: incidentReport.showOnDoorBook,
  };

  function submission(values, dispatch) {
    return dispatch(saveIncidentReport(values.toJS())).catch(resp => {
      const errors = resp.response.data.errors;
      if (errors) {
        if (errors.base) {
          errors._error = errors.base;
        }

        const timeErrors = errors.incidentTime;
        if (timeErrors) {
          errors.date = errors.date || [];
          errors.time = errors.time || [];

          timeErrors.forEach(error => {
            errors.date.push(error);
            errors.time.push(error);
          })
        }

        throw new SubmissionError({ ...errors });
      }
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_ir-form">
      <IncidentReportForm submission={submission} initialValues={initialValues} />
    </div>
  );
}

export default EditIncidentReport;
