import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { CSVUpload, PaymentsBoard } from './components';
import { usePaymentUploadStore } from './use-payment-upload-store';

export default function PaymentUpload() {
  const [uploadCSV, dataLoaded, paymentsData, onTryAgain] = oFetch(
    usePaymentUploadStore(),
    'uploadCSV',
    'dataLoaded',
    'paymentsData',
    'onTryAgain',
  );

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">CSV Upload</h1>
          {dataLoaded && (
            <div className="boss-page-dashboard__buttons-group">
              <button
                onClick={onTryAgain}
                type="button"
                className="boss-button boss-button_role_reload boss-page-dashboard__button"
              >
                Try Again
              </button>
            </div>
          )}
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {!dataLoaded && <CSVUpload onUpload={uploadCSV} />}
        {dataLoaded && <PaymentsBoard paymentsData={paymentsData} />}
      </ContentWrapper>
    </Fragment>
  );
}
