import oFetch from 'o-fetch';
import React from 'react';
import { useMarketingTaskModals } from '../../../marketing-task-modals';

SingleTextValueChanges.defaultProps = {
  postfix: null,
};

export function SingleTextValueChanges(props) {
  const [name, oldValue, newValue, postfix, isHtml] = oFetch(
    props,
    'name',
    'oldValue',
    'newValue',
    'postfix',
    'isHtml',
  );
  const { openHtmlChangesModal } = useMarketingTaskModals();

  if (isHtml) {
    return (
      <p className="boss-overview__text">
        {name} changed {postfix}
        <button onClick={() => openHtmlChangesModal(oldValue, newValue)} class="boss-button boss-button_type_ultra-small boss-button_role_view-details-light boss-check__header-action" style={{ marginLeft: '15px' }}>View Details</button>
      </p>
    );
  }

  return (
    <p className="boss-overview__text">
      {name} changed {postfix}
      <p className="boss-overview__text">
        <span className="boss-overview__text-large boss-overview__text-marked old-text">{oldValue}</span>{' '}
        <span className="boss-overview__text-large boss-overview__text-marked">{newValue}</span>
      </p>
    </p>
  );
}
