import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class PreviewFormGroupItem extends Component {
  render() {
    const [title, value, fieldName, errors] = oFetch(this.props, 'title', 'value', 'fieldName', 'errors');
    const error = errors[fieldName];
    return (
      <li className="boss-info-fields-block__list-item">
        <span className="boss-info-fields-block__field-name boss-info-fields-block__field-name_type_unfilled">
          {title}
        </span>
        <span className="boss-info-fields-block__field-value">{value}</span>
        {error && (
          <ul className="boss-info-fields-block__field-errors">
            <li className="boss-info-fields-block__field-error">{error}</li>
          </ul>
        )}
      </li>
    );
  }
}
