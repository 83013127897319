import { useCallback, useState } from "react";
import { updateMossFinanceReportNote as importedUpdateMossFinanceReportNote } from '@/lib/api-routes';
import { applyBossRoute } from '@/lib/apply-boss-route';
import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { MossFinanceReport, UpdateMossFinanceReportNotesProps } from "./types";
const apiRoutes = {
  updateMossFinanceReportNote: importedUpdateMossFinanceReportNote,
};

export function useMossFinanceReports(args: { initialMossFinanceReports: MossFinanceReport[] }) {
  const [mossFinanceReports, setMossFinanceReports] = useState(args.initialMossFinanceReports);

  function replaceMossFinanceReport(updatedMossFinanceReport: MossFinanceReport) {
    const updatedMossFinanceReports = mossFinanceReports.map((mossFinanceReport) => {
      if (mossFinanceReport.id === updatedMossFinanceReport.id) {
        return updatedMossFinanceReport;
      }
      return mossFinanceReport;
    });

    setMossFinanceReports(updatedMossFinanceReports);
  }

  const updateMossFinanceReportNote = useCallback((props: UpdateMossFinanceReportNotesProps): void => {
    const mossFinanceReportRecord = mossFinanceReports.find((mossFinanceReport) => mossFinanceReport.mossStaffMemberId === props.mossStaffMemberId);
    if (!mossFinanceReportRecord) {
      throw new Error('Moss finance report not found');
    }
    const route = apiRoutes.updateMossFinanceReportNote;
    const bossRequestInstance = bossRequestHttp({
      errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
        const globalNotifications = params.globalNotifications;
        globalNotifications.showDefaultFailureMessage();
        props.onFailure();

        return false;
      },
      successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
        const rawData = oFetch(params, 'data');
        const globalNotifications = params.globalNotifications;

        // ensure expected data is returned
        route.$SuccessData.parse(rawData);

        globalNotifications.showDefaultSuccessMessage();

        mossFinanceReportRecord.note = props.note;

        replaceMossFinanceReport(mossFinanceReportRecord);

        props.onSuccess();
      },
    });

    applyBossRoute({
      bossHttpRequest: bossRequestInstance,
      route,
      callParams: {
        note: props.note,
        mossStaffMemberId: props.mossStaffMemberId,
        venueId: props.venueId,
      },
      pathParams: {
        date: props.date,
      },
    });
  }, [mossFinanceReports]);

  return {
    mossFinanceReports,
    updateMossFinanceReportNote,
  };
}
