import React from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function Dashboard(props) {
  const [renderFilter, onNewIssueClick] = oFetch(props, 'renderFilter', 'onNewIssueClick');
  const canCreateSiteIssues = oFetch(useLegacyGlobal(), 'permissions.canCreateSiteIssues');

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Site Issues</h1>
          <div className="boss-page-dashboard__buttons-group">
            {canCreateSiteIssues && (
              <button
                onClick={onNewIssueClick}
                type="button"
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                New Issues
              </button>
            )}
          </div>
        </div>
        {renderFilter()}
      </div>
    </DashboardWrapper>
  );
}
