import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux-immutable';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import profileReducer from '../profile-wrapper/reducers';
import { initialProfileLoad } from '../profile-wrapper/actions';
import QuizzesReducers from './redux/reducers';
import Quizzes from './quizzes';

export function StaffMemberProfileQuizzes(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );
  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;

    const reducers = combineReducers({ profile: profileReducer, quizzes: QuizzesReducers });
    const store = configureStore(reducers);
    store.dispatch(initialProfileLoad(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <Quizzes />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
