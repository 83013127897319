import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function atFormat(at) {
  return safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTime());
}

export function singleValueChanged(arrayKeys, object) {
  let count = 0;
  let result = null;

  arrayKeys.forEach(keyEntry => {
    const [newKey, oldKey, label] = keyEntry.split(':');
    if (object[newKey] !== null) {
      count += 1;
      result = {
        oldValue: object[oldKey],
        newValue: object[newKey],
        label: label,
      };
    }
  });

  if (count === 0 || count > 1) {
    return false;
  }
  return result;
}
