import React from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';

import { HeaderWrapper } from './header-wrapper';
import { VenueSelector } from './venue-selector';
import { Profile } from './profile';
import { QuickMenu } from './quick-menu';

export function Header(props) {
  const [showGlobalVenue, venues, user, quickMenu, prefix] = oFetch(
    props,
    'showGlobalVenue',
    'venues',
    'user',
    'quickMenu',
    'prefix',
  );
  const queryStringObject = queryString.parse(window.location.search);
  const currentVenueId = queryStringObject.venue_id;

  return (
    <HeaderWrapper title="Boss" url="/" prefix={prefix}>
      <QuickMenu quickMenu={quickMenu} prefix={prefix} />
      {showGlobalVenue && currentVenueId && (
        <VenueSelector prefix={prefix} venues={venues} selected={currentVenueId} />
      )}
      <Profile user={user} prefix={prefix} />
    </HeaderWrapper>
  );
}
