import { z } from "zod";

export const ALL_FILTER_TYPE = 'all' as const;
export const ENABLED_FILTER_TYPE = 'enabled' as const;

export const MAIN_QR_CODE_TYPE = 'main' as const;
export const PLUS_1_QR_CODE_TYPE = 'plus_1' as const;

export const $QrCodeType = z.union([
  z.literal(MAIN_QR_CODE_TYPE),
  z.literal(PLUS_1_QR_CODE_TYPE),
]);
export type QrCodeType = z.infer<typeof $QrCodeType>;

export const $PaginationData = z.object({
  totalCount: z.number().int().nonnegative(),
  currentPage: z.number().int().positive(),
  pageSize: z.number().int().positive(),
});

export type PaginationData = z.infer<typeof $PaginationData>;

export const $IndexFilterType = z.enum([ALL_FILTER_TYPE, ENABLED_FILTER_TYPE]);

export const $IndexPageFilterValues = z.object({
  name: z.string().min(0),
  email: z.string().min(0),
  filterType: $IndexFilterType,
}).strict();

export type IndexPageFilterValues = z.infer<typeof $IndexPageFilterValues>;

export const $IndexPageFilterOption = z.object({
  value: $IndexFilterType,
  label: z.string(),
});

export const $IndexPageFilterData = z.object({
  values: $IndexPageFilterValues,
  filtering: z.boolean(),
});

export type IndexPageFilterData = z.infer<typeof $IndexPageFilterData>;

export const $MossPartyPeopleAppGlobals = z.object({
  filterTypeOptions: $IndexPageFilterOption.array(),
});

export type MossPartyPeopleAppGlobals = z.infer<typeof $MossPartyPeopleAppGlobals>;

const $MossStaffMemberOption = z.object({
  avatarUrl: z.string().nullable(),
  mossStaffTypeName: z.string(),
  mossStaffMemberName: z.string(),
  value: z.number().int().positive(),
});

const $StaffPartyQuizTeamOptionNameType = z.string();
const $StaffPartyQuizTeamOptionValueType = z.number().int().positive();

export const $StaffPartyQuizTeamOption = z.object({
  name: $StaffPartyQuizTeamOptionNameType,
  value: $StaffPartyQuizTeamOptionValueType,
});

export type StaffPartyQuizTeamOption = z.infer<typeof $StaffPartyQuizTeamOption>;

export type MossStaffMemberOption = z.infer<typeof $MossStaffMemberOption>;

const $BaseFormValues = z.object({
  phoneNumber: z.string(),
  email: z.string(),
}).strict();

export const $CreateFormValues = $BaseFormValues.extend({
  mossStaffMemberId: z.number().int().positive().nullable(),
}).strict();

export type CreateFormValues = z.infer<typeof $CreateFormValues>;

export const $OnSubmitCreateParams = z.object({
  values: $CreateFormValues,
});

export type OnSubmitCreateParams = z.infer<typeof $OnSubmitCreateParams>;

export const $UpdateFormValues = $BaseFormValues.extend({
  staffPartyQuizTeamId: z.number().int().positive().nullable(),
}).strict();

export type UpdateFormValues = z.infer<typeof $UpdateFormValues>;

export const $OnSubmitUpdateParams = z.object({
  values: $UpdateFormValues,
  id: z.number().int().positive(),
});

export type OnSubmitUpdateParams = z.infer<typeof $OnSubmitUpdateParams>;

export const $FinalFormSubmissionErrors = z.record(z.never());

export const $CreateSubmitCallBack = z
  .function()
  .args($OnSubmitCreateParams)
  .returns(
    z.promise($FinalFormSubmissionErrors.or(z.undefined()))
  );

export type CreateFormSubmitHandler = z.infer<typeof $CreateSubmitCallBack>;

export const $CreateFormProps = z.object({
  initialValues: $CreateFormValues,
  onSubmit: $CreateSubmitCallBack,
  mossStaffMemberOptions: $MossStaffMemberOption.array(),
});

export type CreateFormProps = z.infer<typeof $CreateFormProps>;

export const $UpdateSubmitCallBack = z
  .function()
  .args($OnSubmitUpdateParams)
  .returns(
    z.promise($FinalFormSubmissionErrors.or(z.undefined()))
  );

export type UpdateFormSubmitHandler = z.infer<typeof $UpdateSubmitCallBack>;

export const $UpdateFormCallProps = z.object({
  id: z.number().int().positive(),
  mossStaffMemberOption: $MossStaffMemberOption,
  initialValues: $UpdateFormValues,
  staffPartyQuizTeamOptions: $StaffPartyQuizTeamOption.array(),
});

export type UpdateFormCallProps = z.infer<typeof $UpdateFormCallProps>;

export const $UpdateFormProps = $UpdateFormCallProps.extend({
  onSubmit: $UpdateSubmitCallBack,
});

export type UpdateFormProps = z.infer<typeof $UpdateFormProps>;

const $FieldValidationErrors = z.string().array().optional();

export const $CreateRequestValidationErrors  = z.object({
  base: $FieldValidationErrors,
  mossStaffMemberId: $FieldValidationErrors,
  phoneNumber: $FieldValidationErrors,
  email: $FieldValidationErrors,
}).strict();

export type CreateRequestValidationErrors = z.infer<typeof $CreateRequestValidationErrors>;

export const $UpdateRequestValidationErrors  = z.object({
  base: $FieldValidationErrors,
  staffPartyQuizTeamId: $FieldValidationErrors,
  phoneNumber: $FieldValidationErrors,
  email: $FieldValidationErrors,
}).strict();

export type UpdateRequestValidationErrors = z.infer<typeof $UpdateRequestValidationErrors>;

export const $NonStaffMemberStaffPartyPerson = z.object({
  id: z.number().int().positive(),
  guid: z.string().uuid(),
  plus1Guid: z.string().uuid(),
  fullName: z.string(),
  firstName: z.string(),
  surname: z.string(),
  jobTitleName: z.string(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  avatarUrl: z.string().nullable(),
  enabled: z.boolean(),
  staffPartyQuizTeamName: z.string(),
  staffPartyQuizTeamId: z.number().int().positive(),
  mossStaffMemberOption: $MossStaffMemberOption,
}).strict();

export type NonStaffMemberStaffPartyPerson = z.infer<typeof $NonStaffMemberStaffPartyPerson>;

export const $MossPartyPeopleIndexProps = z.object({
  nonStaffMemberStaffPartyPeople: $NonStaffMemberStaffPartyPerson.array(),
  pagination: $PaginationData,
  filter: $IndexPageFilterData,
  mossStaffMemberOptions: $MossStaffMemberOption.array(),
  staffPartyQuizTeamOptions: $StaffPartyQuizTeamOption.array(),
}).strict();

export type MossPartyPeopleIndexProps = z.infer<typeof $MossPartyPeopleIndexProps>;

export const $MossPartyPeopleAppPageData = z.object({
  accessToken: z.string(),
  pagination: $PaginationData,
  filter: $IndexPageFilterData,
  filterTypeOptions: $IndexPageFilterOption.array(),
  staffPartyQuizTeamOptions: $StaffPartyQuizTeamOption.array(),
  mossStaffMemberOptions: $MossStaffMemberOption.array(),
});

export const $MossPartyPeopleAppProps = z.object({
  nonStaffMemberStaffPartyPeople: $NonStaffMemberStaffPartyPerson.array(),
  pageData: $MossPartyPeopleAppPageData,
});

export type MossPartyPeopleAppProps = z.infer<typeof $MossPartyPeopleAppProps>;