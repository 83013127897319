import React, { Component } from 'react';
import queryString from 'query-string';
import Select from '@/lib/boss-react-select';
import oFetch from 'o-fetch';
import { RadioGroup, Radio } from '@/components/radio-group';

const ENABLED_VALUE = 'enabled';

export default class Filter extends Component {
  constructor(props) {
    super(props);
    const qp = this.getQueryParams();
    this.state = {
      filter: qp.filter || ENABLED_VALUE,
      type: qp.type || null,
    };
  }

  onTypeChange = value => {
    const qp = this.getQueryParams();
    window.history.pushState(
      'state',
      'title',
      `${window.location.pathname}?${queryString.stringify({ ...qp, type: value || 'all' })}`,
    );
    this.setState({ type: value });
    document.location.reload(true);
  };

  onFilterChange = value => {
    const qp = this.getQueryParams();
    window.history.pushState(
      'state',
      'title',
      `${window.location.pathname}?${queryString.stringify({ ...qp, filter: value })}`,
    );
    this.setState({ filter: value });
    document.location.reload(true);
  };

  getQueryParams = () => {
    return queryString.parse(window.location.search);
  };

  render() {
    const [options, tagsOptions] = oFetch(this.props, 'options', 'tagsOptions');
    const [filter, type] = oFetch(this.state, 'filter', 'type');
    const [
      onTextFilterChange,
      filterTextValue,
      onTagsFilterChange,
      filterTagsIds,
      onUntaggedFilterChange,
      untaggedFilterValue,
    ] = oFetch(
      this.props,
      'onTextFilterChange',
      'filterTextValue',
      'onTagsFilterChange',
      'filterTagsIds',
      'onUntaggedFilterChange',
      'untaggedFilterValue',
    );
    return (
      <div className="boss-page-main__filter">
        <form className="boss-form">
          <div className="boss-form__row">
            <div className="boss-form__field boss-form__field_layout_max">
              <div className="boss-form__search">
                <label className="boss-form__label">
                  <input
                    onChange={onTextFilterChange}
                    name="search"
                    value={filterTextValue}
                    className="boss-form__input"
                    placeholder="Search..."
                    type="text"
                  />
                </label>
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_fifth">
              <div className="boss-form__select">
                <Select
                  value={type}
                  onChange={this.onTypeChange}
                  clearable={true}
                  simpleValue
                  placeholder="All types"
                  options={options}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_min">
              <RadioGroup
                name="filterType"
                selectedValue={filter}
                onChange={this.onFilterChange}
                className="boss-form__switcher"
              >
                <label className="boss-form__switcher-label">
                  <Radio value={ENABLED_VALUE} className="boss-form__switcher-radio" />
                  <span className="boss-form__switcher-label-text">Enabled Only</span>
                </label>
                <label className="boss-form__switcher-label">
                  <Radio value="all" className="boss-form__switcher-radio" />
                  <span className="boss-form__switcher-label-text">Show All</span>
                </label>
              </RadioGroup>
            </div>
          </div>
          <div className="boss-form__row">
            <div className="boss-form__field boss-form__field_layout_max">
              <div className="boss-form__select">
                <Select
                  value={filterTagsIds}
                  onChange={onTagsFilterChange}
                  clearable
                  disabled={untaggedFilterValue}
                  valueKey="id"
                  labelKey="name"
                  simpleValue
                  multi
                  placeholder="Tags"
                  options={tagsOptions}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_layout_min">
              <label className="boss-form__checkbox-label">
                <input
                  name="untagged"
                  checked={untaggedFilterValue}
                  onChange={onUntaggedFilterChange}
                  type="checkbox"
                  className="boss-form__checkbox-input"
                />
                <span className="boss-form__checkbox-label-text">Untagged</span>
              </label>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
