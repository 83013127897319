import { combineReducers } from 'redux';
import answersReducer from './answers-reducer';
import questionsReducer from './questions-reducer';
import categoryReducer from './category-reducer';
import staticReducer from './static-reducer';
import questionsTagsReducer from './questions-tags-reducer';
import otherQuizCategoriesReducer from './other-quiz-categories-reducer';

export default combineReducers({
  answers: answersReducer,
  questions: questionsReducer,
  category: categoryReducer,
  otherQuizCategories: otherQuizCategoriesReducer,
  static: staticReducer,
  questionsTags: questionsTagsReducer,
});
