import React, { ReactNode } from 'react';
import Modal from 'react-modal';

type DefaultModalWrapperProps = {
  children: ReactNode;
  onRequestClose: () => void;
  baseClassName?: string | undefined;
};

export function DefaultModalWrapper(props: DefaultModalWrapperProps) {
  const { children, onRequestClose, baseClassName } = props;

  return (
    <Modal
      isOpen={true}
      className={baseClassName === undefined ? '' : baseClassName }
      contentLabel="Modal"
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {children}
    </Modal>
  );
}
