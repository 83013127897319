import { BossFormInput } from '@/components/boss-form';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { z } from 'zod';

export const $WhatsappInstanceCustomNameFormValues = z.object({
  whatsappServerId: z.number(),
  whatsappInstanceId: z.number(),
  customName: z.string(),
});

export type WhatsappInstanceCustomNameFormValues = z.infer<typeof $WhatsappInstanceCustomNameFormValues>;

type Props = {
  onSubmit: () => void;
  initialValues: any;
  processing: boolean;
};

export function WhatsappInstanceCustomNameForm(props: Props) {
  const { onSubmit, initialValues, processing } = props;
  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => {
            const disabled = submitting || processing;
            return (
              <>
                <Field
                  name={'customName'}
                  label="Whatsapp Instance Custom Name"
                  disabled={processing}
                  component={BossFormInput}
                />
                <div className="boss-form__field">
                  <div
                    className='boss-page-dashboard__buttons-group boss-page-dashboard_page_hours-confirmation'
                    style={{ alignItems: 'center' }}
                  >
                    <button
                      onClick={handleSubmit}
                      disabled={disabled}
                      className="boss-button boss-button_role_confirm boss-form__submit boss-page-dashboard__button"
                      type="button"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
