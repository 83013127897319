import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function SubmitCheckboxField(props) {
  const [names, index, formName] = oFetch(props, 'names', 'index', 'formName');
  const [descriptionName, noteName, answerName] = names;
  const fields = oFetch(props, `items`);
  const [descriptionField, noteField, answerField] = oFetch(fields[index], 'description', 'note', 'answer');
  const descriptionFieldValue = oFetch(descriptionField, 'input.value');
  const [answerFieldValue, answerFieldOnChange] = oFetch(answerField, 'input.value', 'input.onChange');
  const [noteFieldValue, noteFieldOnChange] = oFetch(noteField, 'input.value', 'input.onChange');
  const { error: answerError } = oFetch(answerField, 'meta');
  const { error: noteError } = oFetch(noteField, 'meta');

  function handleAnswerClick(event) {
    answerFieldOnChange(event.target.checked);
  }

  function handleNoteChange(event) {
    noteFieldOnChange(event.target.value);
  }

  const noteClassNames = cn('boss-checklist__notes-textarea', {
    'boss-checklist__notes-textarea_state_error': noteError || answerError,
  });

  return (
    <div className="boss-checklist__item">
      <div className="boss-checklist__control">
        <label htmlFor={`${formName}.${answerName}`} className="boss-checklist__label">
          <input
            type="checkbox"
            onChange={handleAnswerClick}
            checked={answerFieldValue}
            id={`${formName}.${answerName}`}
            className="boss-checklist__checkbox-input"
          />
          <span className="boss-checklist__label-text  boss-checklist__label-text_type_checkbox">
            {descriptionFieldValue}
            {answerError && <span className="boss-checklist__label-text-error">{answerError}</span>}
          </span>
        </label>
      </div>
      {(answerError || noteFieldValue || noteError) && (
        <div className="boss-checklist__notes">
          <div className="boss-checklist__notes-inner">
            <p className="boss-checklist__notes-label">Notes</p>
            <textarea value={noteFieldValue} onChange={handleNoteChange} className={noteClassNames} />
            {noteError && (
              <div className="boss-checklist__alert boss-checklist__alert_role_notes">
                <div className="boss-alert">
                  <p className="boss-alert__text">{noteError}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
