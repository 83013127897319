import React, { Fragment } from 'react';
import classNames from 'classnames';
import oFetch from 'o-fetch';
import { Tooltip } from 'react-tippy';
import humanize from 'string-humanize';

import { MossFinanceReport } from '../types';
import { format } from 'date-fns';
import { HUMAN_DATE_FORMAT_WITH_TIME } from '@/lib/date-fns-formats';

type StatusColumnProps = {
  mossFinanceReport: MossFinanceReport;
};

export function StatusColumn(props: StatusColumnProps): JSX.Element {
  const mossFinanceReport = props.mossFinanceReport;
  const {
    completionDateReached,
    status,
    completedAt,
  } = mossFinanceReport;

  const dataIncomplete = oFetch(mossFinanceReport, "dataIncomplete");

  const effectiveStatus = getEffectiveStatus();
  const isRequiringUpdate = effectiveStatus === 'requiring_update';

  const statusClassName = classNames('boss-table__text', {
    'boss-table__text_role_pending-status': effectiveStatus === 'ready' || isRequiringUpdate,
    'boss-table__text_role_alert-status': dataIncomplete,
    'boss-table__text_role_success-status': status === 'done',
  });

  function getEffectiveStatus() {
    if (status === 'ready') {
      if (!completionDateReached) {
        return 'pending';
      }
      if (dataIncomplete) {
        return 'incomplete';
      }
    }
    return status;
  }

  function renderTooltip(content: JSX.Element): JSX.Element {
    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        interactive
        html={content}
      >
        <p className="boss-table__text boss-table__text_role_action boss-table__text_role_details boss-table__text_role_success-status">
          Done
        </p>
      </Tooltip>
    );
  }

  function tooltipContent(): JSX.Element | null {
    const completedAt = mossFinanceReport.completedAt;

    if (completedAt) {
      return (
        <span>
          Completed at: { format(completedAt, HUMAN_DATE_FORMAT_WITH_TIME) }
        </span>
      );
    } else {
      return null;
    }
  }

  const currentTooltipContent = tooltipContent();

  return (
    <div className="boss-table__cell">
      {completedAt && (
        <Fragment>
          { currentTooltipContent && renderTooltip(currentTooltipContent)}
        </Fragment>
      )}
      { !completedAt && (
        <p className={statusClassName}>{humanize(effectiveStatus)}</p>
      )}
    </div>
  );
}