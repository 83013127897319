import React, { Component } from 'react';
import Select from '@/lib/boss-react-select';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { AccessoryColoredIconOption, AccessoryColoredIconValue } from './colored-select';
export default class BossFormAccessoryIconField extends Component {
  renderErrors = errors => {
    return (
      <div className="boss-form__error">
        <p className="boss-form__error-text">
          {errors.map((error, index) => {
            return (
              <span key={index} className="boss-form__error-line">
                {error}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  render() {
    const [onChange, name, value, meta, touched, options, label] = oFetch(
      this.props,
      'input.onChange',
      'input.name',
      'input.value',
      'meta',
      'meta.touched',
      'options',
      'label',
    );

    const error = meta.error;
    const selectClassName = cn('boss-form__select', { 'boss-form__select_state_error': error });
    return (
      <div className="boss-form__field">
        <label className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </label>
        <div className={selectClassName}>
          <Select
            options={options}
            onChange={onChange}
            name={name}
            valueComponent={AccessoryColoredIconValue}
            optionComponent={AccessoryColoredIconOption}
            value={value}
            simpleValue
            multi={false}
            searchable={false}
            clearable={false}
          />
          {touched && error && this.renderErrors(error)}
        </div>
      </div>
    );
  }
}
