import React from 'react';
import oFetch from 'o-fetch';
import { TaskForm } from './forms';

export function AddTaskModalContent(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const initialValues = {
    title: '',
    venueId: null,
    deadlineDate: null,
    description: '',
    fileIds: [],
  };

  return (
    <div className="boss-modal-window__form">
      <TaskForm initialValues={initialValues} files={{}} onSubmit={onSubmit} buttonText="Create" />
    </div>
  );
}
