import React from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';

export function YearSelector(props) {
  const [selected, onChange, options] = oFetch(props, 'selected', 'onChange', 'options');

  const mappedOptions = options.map(option => {
    return {
      label: option,
      value: option,
    };
  });

  return (
    <div className="boss-form__select">
      <Select
        name={'year'}
        value={selected}
        onChange={onChange}
        clearable={false}
        simpleValue
        options={mappedOptions}
      />
    </div>
  );
}
