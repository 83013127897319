import React from 'react';
import oFetch from 'o-fetch';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

momentDurationFormatSetup(moment);

export function getPercentageFromCentsOrNA(cents) {
  if (cents === null) {
    return 'N/A';
  }
  return `${cents / 100}%`;
}

export function VenueSafeCheckTooltipContent(props) {
  const data = oFetch(props, 'data');
  return data.map((dayItem, index) => {
    const [date, count, pass] = oFetch(dayItem, 'date', 'count', 'pass');
    function getStatucColor() {
      if (pass === true) {
        return 'green';
      }
      if (pass === false) {
        return 'red';
      }
      if (pass === null) {
        return 'grey';
      }
      throw new Error(`Wrong 'pass' value, expect [true, false, null], got: ${pass}`);
    }
    const countTitle = count === null ? 'N/A' : count;
    return (
      <div
        style={{
          marginBottom: '5px',
          width: '150px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
        key={index}
      >
        <span style={{ padding: '5px' }}>{date}</span>
        <span style={{ backgroundColor: getStatucColor(), color: 'white', padding: '5px' }}>
          {countTitle}
        </span>
      </div>
    );
  });
}

function getValueOrZeroIfNaN(value) {
  if (isNaN(value)) {
    return 0;
  }
  return value;
}

export function WagePercentageTooltipContent(props) {
  const data = oFetch(props, 'data');
  const [weekStartDate, weekEndDate, payrollBreakdownData, wagePercentageData] = oFetch(
    data,
    'weekStartDate',
    'weekEndDate',
    'payrollBreakdownData',
    'wagePercentageData',
  );

  const [adjustedWagePercentage, wagePercentage, thresholdPercentage] = oFetch(
    wagePercentageData,
    'adjustedWagePercentage',
    'wagePercentage',
    'thresholdPercentage',
  );
  const sValuesLastUpdatedAt = oFetch(wagePercentageData, "lastUpdatedAt");
  const salesDataExists = oFetch(wagePercentageData, "salesDataExists");

  const [
    holidayHourlyMinutes,
    holidayWeeklyMinutes,
    insideHourlyMinutes,
    insideWeeklyMinutes,
    outsideHourlyMinutes,
    outsideWeeklyMinutes,
    outsourcedHourlyMinutes,
    outsourcedWeeklyMinutes,
    owedHourHourlyMinutes,
    owedHourWeeklyMinutes,
    sickLeaveHourlyMinutes,
    sickLeaveWeeklyMinutes,
  ] = oFetch(
    payrollBreakdownData,
    'holidayHourlyMinutes',
    'holidayWeeklyMinutes',
    'insideHourlyMinutes',
    'insideWeeklyMinutes',
    'outsideHourlyMinutes',
    'outsideWeeklyMinutes',
    'outsourcedHourlyMinutes',
    'outsourcedWeeklyMinutes',
    'owedHourHourlyMinutes',
    'owedHourWeeklyMinutes',
    'sickLeaveHourlyMinutes',
    'sickLeaveWeeklyMinutes',
  );
  const hourlyMinutes =
    insideHourlyMinutes + outsideHourlyMinutes + outsourcedHourlyMinutes + owedHourHourlyMinutes;

  const weeklyMinutes =
    insideWeeklyMinutes + outsideWeeklyMinutes + outsourcedWeeklyMinutes + owedHourWeeklyMinutes;

  const insideHourlyMinutesPercent = getValueOrZeroIfNaN((insideHourlyMinutes * 100) / hourlyMinutes).toFixed(
    2,
  );
  const outsideHourlyMinutesPercent = getValueOrZeroIfNaN(
    (outsideHourlyMinutes * 100) / hourlyMinutes,
  ).toFixed(2);
  const outsourcedHourlyMinutesPercent = getValueOrZeroIfNaN(
    (outsourcedHourlyMinutes * 100) / hourlyMinutes,
  ).toFixed(2);
  const owedHourHourlyMinutesPercent = getValueOrZeroIfNaN(
    (owedHourHourlyMinutes * 100) / hourlyMinutes,
  ).toFixed(2);

  const insideWeeklyMinutesPercent = getValueOrZeroIfNaN((insideWeeklyMinutes * 100) / weeklyMinutes).toFixed(
    2,
  );
  const outsideWeeklyMinutesPercent = getValueOrZeroIfNaN(
    (outsideWeeklyMinutes * 100) / weeklyMinutes,
  ).toFixed(2);
  const outsourcedWeeklyMinutesPercent = getValueOrZeroIfNaN(
    (outsourcedWeeklyMinutes * 100) / weeklyMinutes,
  ).toFixed(2);
  const owedHourWeeklyMinutesPercent = getValueOrZeroIfNaN(
    (owedHourWeeklyMinutes * 100) / weeklyMinutes,
  ).toFixed(2);

  const formattedHourlyHolidayDays = formatMinutesToDays(holidayHourlyMinutes);
  const formattedHourlySickLeaveDays = formatMinutesToDays(sickLeaveHourlyMinutes);
  const formattedWeeklyHolidayDays = formatMinutesToDays(holidayWeeklyMinutes);
  const formattedWeeklySickLeaveDays = formatMinutesToDays(sickLeaveWeeklyMinutes);

  const formattedHourlyInsideHours = formatMinutesToHours(insideHourlyMinutes);
  const formattedHourlyOutsideHours = formatMinutesToHours(outsideHourlyMinutes);
  const formattedHourlyOutsourcedHours = formatMinutesToHours(outsourcedHourlyMinutes);
  const formattedHourlyOwedHours = formatMinutesToHours(owedHourHourlyMinutes);

  const formattedWeeklyInsideHours = formatMinutesToHours(insideWeeklyMinutes);
  const formattedWeeklyOutsideHours = formatMinutesToHours(outsideWeeklyMinutes);
  const formattedWeeklyOutsourcedHours = formatMinutesToHours(outsourcedWeeklyMinutes);
  const formattedWeeklyOwedHours = formatMinutesToHours(owedHourWeeklyMinutes);

  function formatMinutesToHours(minutes) {
    return moment.duration(minutes, 'minutes').format('h[h] m[m]', { trim: 'both', useGrouping: false });
  }

  function formatMinutesToDays(minutes) {
    const days = minutes / 60 / 8;

    return `${minutes / 60 / 8} day${days > 2 || days === 0 ? 's' : ''}`;
  }

  const valuesLastUpdatedAtMessage = sValuesLastUpdatedAt ? safeMoment.iso8601Parse(sValuesLastUpdatedAt).format(utils.humanDateFormatWithTime()) : "N/A";
  const currentStatusMessage = wagePercentageData.currentStatusMessage || 'Pending';
  const displayNoSaleDataExistsBadge = sValuesLastUpdatedAt && !salesDataExists;

  return (
    <div className="boss-overview boss-overview_page_kpi">
      <h3 className="boss-overview__title">
        {weekStartDate} — {weekEndDate}
      </h3>
      <div className="boss-overview__group">
        <h4 className="boss-overview__label">
          <span className="boss-overview__label-text">Wage Percentage</span>
        </h4>
        { displayNoSaleDataExistsBadge && <div>! No Sales Data Exists !</div>}
        <ul className="boss-overview__list">
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Current Status:</p>
            <p className="boss-overview__list-value">{currentStatusMessage}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Last Updated At:</p>
            <p className="boss-overview__list-value">{valuesLastUpdatedAtMessage}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Adjusted wage percentage:</p>
            <p className="boss-overview__list-value">{adjustedWagePercentage}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Unadjusted Wage Percentage:</p>
            <p className="boss-overview__list-value">{wagePercentage}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Threshold:</p>
            <p className="boss-overview__list-value">{thresholdPercentage}</p>
          </li>
        </ul>
      </div>
      <div className="boss-overview__group">
        <h4 className="boss-overview__label">
          <span className="boss-overview__label-text">Hourly Data</span>
        </h4>
        <ul className="boss-overview__list boss-overview__list_adjust_label">
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Inside hours:</p>
            <p className="boss-overview__list-value">
              {formattedHourlyInsideHours} ({insideHourlyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Outside Hours:</p>
            <p className="boss-overview__list-value">
              {formattedHourlyOutsideHours} ({outsideHourlyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Outsourced Hours:</p>
            <p className="boss-overview__list-value">
              {formattedHourlyOutsourcedHours} ({outsourcedHourlyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Owed Hours:</p>
            <p className="boss-overview__list-value">
              {formattedHourlyOwedHours} ({owedHourHourlyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Holiday Days:</p>
            <p className="boss-overview__list-value">{formattedHourlyHolidayDays}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Sick Leave Days:</p>
            <p className="boss-overview__list-value">{formattedHourlySickLeaveDays}</p>
          </li>
        </ul>
      </div>
      <div className="boss-overview__group boss-overview__group_position_last">
        <h4 className="boss-overview__label">
          <span className="boss-overview__label-text">Salary Data</span>
        </h4>
        <ul className="boss-overview__list boss-overview__list_adjust_label">
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Inside hours:</p>
            <p className="boss-overview__list-value">
              {formattedWeeklyInsideHours} ({insideWeeklyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Outside Hours:</p>
            <p className="boss-overview__list-value">
              {formattedWeeklyOutsideHours} ({outsideWeeklyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Outsourced Hours:</p>
            <p className="boss-overview__list-value">
              {formattedWeeklyOutsourcedHours} ({outsourcedWeeklyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Owed Hours:</p>
            <p className="boss-overview__list-value">
              {formattedWeeklyOwedHours} ({owedHourWeeklyMinutesPercent}%)
            </p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Holiday Days:</p>
            <p className="boss-overview__list-value">{formattedWeeklyHolidayDays}</p>
          </li>
          <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
            <p className="boss-overview__list-key">Sick Leave Days:</p>
            <p className="boss-overview__list-value">{formattedWeeklySickLeaveDays}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

function venueAlertDataTooltipContentV1(args) {
  const data = oFetch(args, 'data');
  const index = oFetch(args, 'index');
  const [createdAt, endTime, creatorName, title, message, alertLevel] = oFetch(
    data,
    'createdAt',
    'endTime',
    'creatorName',
    'title',
    'message',
    'alertLevel'
  );

  const formattedCreatedAt = safeMoment
    .iso8601Parse(createdAt)
    .format(utils.humanDateFormatWithTime());

  let formattedEndTime = null;
  if (endTime) {
    formattedEndTime = safeMoment
    .iso8601Parse(endTime)
    .format(utils.humanDateFormatWithTime());
  }

  return (
    <div key={index} className="boss-overview__group">
      <h4 className="boss-overview__label">
        <span className="boss-overview__label-text">Venue Alert - {title}</span>
      </h4>
      <ul className="boss-overview__list boss-overview__list_adjust_label">
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Message:</p>
          <p className="boss-overview__list-value" style={{ textAlign: 'left' }}>
            { message }
          </p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Created:</p>
          <p className="boss-overview__list-value">{formattedCreatedAt} by {creatorName}</p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">End Time:</p>
          <p className="boss-overview__list-value">{ formattedEndTime ? formattedEndTime : "N/A" }</p>
        </li>
      </ul>
    </div>
  );
}

function siteIssueDataTooltipContentV1(args) {
  const data = oFetch(args, 'data');
  const index = oFetch(args, 'index');
  const [createdAt, creatorName, date, description] = oFetch(
    data,
    'createdAt',
    'creatorName',
    'date',
    'description',
  );
  const formattedCreatedAt = safeMoment
    .iso8601Parse(createdAt)
    .format(utils.humanDateFormatWithTime());

  const formattedDate = safeMoment
    .uiDateParse(date)
    .format(utils.commonDateFormat);

  return (
    <div key={index} className="boss-overview__group">
      <h4 className="boss-overview__label">
        <span className="boss-overview__label-text">Site Issue - {formattedDate}</span>
      </h4>
      <ul className="boss-overview__list boss-overview__list_adjust_label">
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Description:</p>
          <p className="boss-overview__list-value" style={{ textAlign: 'left' }}>
            {description}
          </p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Created:</p>
          <p className="boss-overview__list-value">{formattedCreatedAt} by {creatorName}</p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Activated:</p>
          <p className="boss-overview__list-value">Unknown</p>
        </li>
      </ul>
    </div>
  );
}

function siteIssueDataTooltipContentV2(args) {
  const data = oFetch(args, 'data');
  const index = oFetch(args, 'index');
  const [activatedAt, activatorName, createdAt, creatorName, date, description] = oFetch(
    data,
    'activatedAt',
    'activatorName',
    'createdAt',
    'creatorName',
    'date',
    'description',
  );
  const formattedCreatedAt = safeMoment
    .iso8601Parse(createdAt)
    .format(utils.humanDateFormatWithTime());
  const formattedActivatedAt = safeMoment
    .iso8601Parse(activatedAt)
    .format(utils.humanDateFormatWithTime());
  const formattedDate = safeMoment
    .uiDateParse(date)
    .format(utils.commonDateFormat);

  return (
    <div key={index} className="boss-overview__group">
      <h4 className="boss-overview__label">
        <span className="boss-overview__label-text">Site Issue - {formattedDate}</span>
      </h4>
      <ul className="boss-overview__list boss-overview__list_adjust_label">
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-value" style={{ textAlign: 'left' }}>
            {description}
          </p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Created:</p>
          <p className="boss-overview__list-value">{formattedCreatedAt} by {creatorName}</p>
        </li>
        <li className="boss-overview__list-item boss-overview__list-item_role_kpi">
          <p className="boss-overview__list-key">Activated:</p>
          <p className="boss-overview__list-value">{formattedActivatedAt} by {activatorName}</p>
        </li>
      </ul>
    </div>
  );
}

function siteIssueDataTooltipContent(args) {
  const data = oFetch(args, "data");
  const version = oFetch(args, "version");
  const index = oFetch(args, "index");

  if (version == 1) {
    return siteIssueDataTooltipContentV1({ data, index });
  } else if (version == 2) {
    return siteIssueDataTooltipContentV2({ data, index });
  } else {
    throw new Error(`Unsupported version: ${version} supplied`)
  }
}

function venueAlertDataTooltipContent(args) {
  const data = oFetch(args, "data");
  const version = oFetch(args, "version");
  const index = oFetch(args, "index");

  switch(version) {
    case 1:
      return venueAlertDataTooltipContentV1({ data, index });
      break;
    default:
      throw new Error(`Unsupported version: ${version} supplied`)
  }
}

export function SiteIssueTooltipContent(props) {
  const [month, data] = oFetch(props, 'month', 'data');
  const sortedData = [...data].sort((itemA, itemB) => {
    const { sortTime: sortTimeA } = itemA.data || {};
    const { sortTime: sortTimeB } = itemB.data || {};
    if (sortTimeA < sortTimeB) return -1;
    if (sortTimeA > sortTimeB) return 1;
    return 0;
  });

  return (
    <div className="boss-overview boss-overview_page_kpi">
      <h3 className="boss-overview__title">{month}</h3>
      {sortedData.map((dataItem, index) => {
        const [version, data, issueType] = oFetch(dataItem, 'version', 'data', 'issueType');

        if (issueType == "site_issue") {
          return siteIssueDataTooltipContent({ data, version, index });
        } else if (issueType == "venue_alert") {
          return venueAlertDataTooltipContent({ data, version, index });
        } else {
          throw new Error(`Unsupported issueType: ${issueType} supplied`)
        }
      })}
    </div>
  );
}
