import React, { Component } from 'react';
import safeMoment from '@/lib/safe-moment';
import cn from 'classnames';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { Collapse } from 'react-collapse';
import openContentModal, { MODAL_TYPE2 } from '@/components/modals/content-modal';
import MarkHandledModal from './marked-handled-modal';

const PENDING_STATUS = 'pending';
const HANDLED_STATUS = 'handled';
const STATUSES = [PENDING_STATUS, HANDLED_STATUS];

class MisattributedLoginCard extends Component {
  state = {
    isOpened: false,
  };

  toggleDropDown = () => {
    this.setState(state => {
      return { isOpened: !state.isOpened };
    });
  };

  getStatus = misattributedLogin => {
    const status = oFetch(misattributedLogin, 'status');

    if (!STATUSES.includes(status)) {
      throw new Error(`Unsupported status: ${status} encountered`);
    }
    return status;
  };

  renderMatchedStaffMemberInfo(options) {
    const misattributedLogin = oFetch(options, 'misattributedLogin');
    const correctS3AvatarUrl = oFetch(misattributedLogin, 'correctS3AvatarUrl');
    const correctStaffMemberName = oFetch(misattributedLogin, 'correctStaffMemberName');
    const correctStaffMemberStaffTypeName = oFetch(misattributedLogin, 'correctStaffMemberStaffTypeName');
    const correctStaffMemberStaffTypeUIColor = oFetch(misattributedLogin, 'correctStaffMemberStaffTypeUIColor');

    return (
      <div className="boss-comparison__item  boss-comparison__item_position_end">
        <div className="boss-user-summary boss-user-summary_role_sv-ml-end">
          <div className="boss-user-summary__side">
            <div className="boss-user-summary__avatar">
              <div className="boss-user-summary__avatar-inner">
                <img src={correctS3AvatarUrl} alt="Matched Staff Member" className="boss-user-summary__pic" />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content boss-user-summary__content_adjust_middle">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">{correctStaffMemberName}</h2>
              <p
                className="boss-button boss-button_type_extra-small boss-button_type_no-behavior boss-user-summary__label"
                style={{ backgroundColor: correctStaffMemberStaffTypeUIColor }}
              >
                {correctStaffMemberStaffTypeName}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTopRowAndAttemptImage(options) {
    const misattributedLogin = oFetch(options, 'misattributedLogin');
    const attemptS3Url = oFetch(misattributedLogin, 'attemptS3Url');

    return (
      <div className="boss-comparison__group">
        <p className="boss-comparison__delimiter boss-comparison__delimiter_position_start">
          <span className="boss-comparison__delimiter-text">Failed to Match</span>
        </p>
        <div className="boss-comparison__item boss-comparison__item_position_middle">
          <div className="boss-user-summary boss-user-summary_role_sv-ml-middle">
            <div className="boss-user-summary__avatar">
              <div className="boss-user-summary__avatar-inner">
                <img src={attemptS3Url} alt="Scanned Image" className="boss-user-summary__pic" />
              </div>
            </div>
          </div>
        </div>
        <p className="boss-comparison__delimiter boss-comparison__delimiter_position_end">
          <span className="boss-comparison__delimiter-text">Matched</span>
        </p>
      </div>
    );
  }
  renderHandledInfo = options => {
    const misattributedLogin = oFetch(options, 'misattributedLogin');
    const [reason, handledBy, handledAt] = oFetch(misattributedLogin, 'reason', 'handledBy', 'handledAt');
    const formattedHandledAt = safeMoment.iso8601Parse(handledAt).format(utils.humanDateFormatWithDayOfWeek());
    return (
      <div className="boss-check__group">
        <div className="boss-check__group">
          <div className="boss-check__group-content">
            <div className="boss-check__box">
              <p className="boss-check__text">{reason}</p>
            </div>
          </div>
        </div>
        <div className="boss-check__group">
          <div className="boss-check__group-content">
            <div className="boss-check__meta">
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Marked </span>
                  <span className="boss-check__text-marked">Handled</span>
                  <span className="boss-check__text-light"> by </span>
                  <span className="boss-check__text-marked">{handledBy}</span>
                  <span className="boss-check__text-light"> at </span>
                  <span className="boss-check__text-marked">{formattedHandledAt}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderFailedToMatchStaffMemberInfo(options) {
    const misattributedLogin = oFetch(options, 'misattributedLogin');
    const misidentifiedS3AvatarUrl = oFetch(misattributedLogin, 'misidentifiedS3AvatarUrl');
    const misidentifiedStaffMemberName = oFetch(misattributedLogin, 'misidentifiedStaffMemberName');
    const misidentifiedStaffMemberStaffTypeName = oFetch(misattributedLogin, 'misidentifiedStaffMemberStaffTypeName');
    const misidentifiedStaffMemberStaffTypeUIColor = oFetch(
      misattributedLogin,
      'misidentifiedStaffMemberStaffTypeUIColor',
    );

    return (
      <div className="boss-comparison__item boss-comparison__item_position_start">
        <div className="boss-user-summary boss-user-summary_role_sv-ml-start">
          <div className="boss-user-summary__side">
            <div className="boss-user-summary__avatar">
              <div className="boss-user-summary__avatar-inner">
                <img
                  src={misidentifiedS3AvatarUrl}
                  alt="Wrongly matched staff member image"
                  className="boss-user-summary__pic"
                />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content boss-user-summary__content_adjust_middle">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">{misidentifiedStaffMemberName}</h2>
              <p
                className="boss-button  boss-button_type_extra-small boss-button_type_no-behavior boss-user-summary__label"
                style={{ backgroundColor: misidentifiedStaffMemberStaffTypeUIColor }}
              >
                {misidentifiedStaffMemberStaffTypeName}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderComparisonGroup(options) {
    const misattributedLogin = oFetch(options, 'misattributedLogin');
    return (
      <div className="boss-check__group-content">
        <div className="boss-comparison boss-comparison_page_sv-missattributed-logins">
          <div className="boss-comparison__content">
            {this.renderFailedToMatchStaffMemberInfo({ misattributedLogin })}
            {this.renderTopRowAndAttemptImage({ misattributedLogin })}
            {this.renderMatchedStaffMemberInfo({ misattributedLogin })}
          </div>
        </div>
      </div>
    );
  }

  showMarkHandledModal = id => {
    const onMakeHandled = oFetch(this.props, 'onMakeHandled');

    openContentModal({
      submit: onMakeHandled,
      config: {
        title: 'Mark Handled',
        modalClassName: 'boss-modal-window_role_confirm',
        type: MODAL_TYPE2,
      },
      props: {
        recordId: id,
      },
    })(MarkHandledModal);
  };

  render() {
    const misattributedLogin = oFetch(this.props, 'misattributedLogin');
    const id = oFetch(misattributedLogin, 'id');
    const mCreatedAt = safeMoment.iso8601Parse(oFetch(misattributedLogin, 'createdAt'));
    const cardStatus = this.getStatus(misattributedLogin);
    const cardStatusClass = cn('boss-check__marker boss-check__marker_position_before', {
      'boss-check__marker_status_pending': cardStatus === PENDING_STATUS,
      'boss-check__marker_status_completed': cardStatus === HANDLED_STATUS,
    });
    const renderMarkHandledButton = cardStatus === PENDING_STATUS;
    const venueName = oFetch(misattributedLogin, 'venueName');
    const expandCard = this.state.isOpened;
    const dropdownToggleStateClass = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
      'boss-check__dropdown-link_state_closed': !expandCard,
    });

    return (
      <div key={id} className="boss-check boss-check_role_board boss-check_page_sv-missattributed-logins">
        <div className="boss-check__header">
          <div className="boss-check__header-info">
            <div className={cardStatusClass} />
            <div className="boss-check__header-group">
              <h3 className="boss-check__title">
                {mCreatedAt.format(utils.humanDateFormatWithDayOfWeek())}
                <span className="boss-check__title-light" /> {venueName}
              </h3>
            </div>
          </div>
          <div className="boss-check__header-actions">
            {renderMarkHandledButton && (
              <div
                className="boss-button boss-button_role_alert boss-button_type_extra-small oss-check__header-action"
                onClick={() => this.showMarkHandledModal(id)}
              >
                Mark Handled
              </div>
            )}
          </div>
          <div className={dropdownToggleStateClass} onClick={this.toggleDropDown}>
            Toggle Dropdown
          </div>
        </div>
        <Collapse isOpened={this.state.isOpened} style={{ display: 'block' }}>
          <div className="boss-check__dropdown">
            <div className="boss-check__group">{this.renderComparisonGroup({ misattributedLogin })}</div>
            {cardStatus === HANDLED_STATUS && this.renderHandledInfo({ misattributedLogin })}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default MisattributedLoginCard;
