import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialGlobalState = {
  startDate: null,
  endDate: null,
  date: null,
  weekDates: [],
};

const pageOptionsReducer = handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const date = oFetch(action, 'payload.date');
      const venues = oFetch(action, 'payload.venues');
      const startDate = oFetch(action, 'payload.startDate');
      const endDate = oFetch(action, 'payload.endDate');
      const chosenDate = oFetch(action, 'payload.date');
      const weekDates = oFetch(action, 'payload.weekUIDates');

      return {
        date,
        startDate,
        endDate,
        chosenDate,
        venues,
        weekDates,
      };
    },
    [types.CHANGE_WEEK_DAY]: (state, action) => {
      const chosenDate = oFetch(action, 'payload.sChosenDate');
      return {
        ...state,
        chosenDate,
      };
    },
  },
  initialGlobalState,
);

export default pageOptionsReducer;
