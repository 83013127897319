import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const questionnaireQuestions = oFetch(action, 'payload.questionnaireQuestions');
      return questionnaireQuestions;
    },
    [types.ADD_QUESTION_TO_STORE]: (state, action) => {
      const questionnaireQuestion = oFetch(action, 'payload.questionnaireQuestion');
      return [...state, questionnaireQuestion];
    },
    [types.UPDATE_QUESTION_IN_STORE]: (state, action) => {
      const [questionnaireQuestion, oldQuestionId] = oFetch(
        action,
        'payload.questionnaireQuestion',
        'payload.oldQuestionnaireQuestionId',
      );
      const questions = state.filter(question => oFetch(question, 'id') !== oldQuestionId);
      return [...questions, questionnaireQuestion];
    },
    [types.DELETE_QUESTION_FROM_STORE]: (state, action) => {
      const questionId = oFetch(action, 'payload.id');
      const questions = state.filter(question => oFetch(question, 'id') !== questionId);
      return questions;
    },
  },
  initialState,
);
