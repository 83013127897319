import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';

export default function CollapsibleFilter(props) {
  const children = oFetch(props, 'children');
  const { styles = {} } = props;
  const [isOpen, setOpenState] = useState(false);
  const buttonClassName = cn('boss-dropdown__switch boss-dropdown__switch_role_filter', {
    'boss-dropdown__switch_state_opened': isOpen,
  });
  function toggleCard() {
    setOpenState(!isOpen);
  }

  return (
    <div className="boss-page-dashboard__filter" style={styles}>
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <button onClick={toggleCard} className={buttonClassName}>
            Filter
          </button>
        </div>
        <Collapse isOpened={isOpen} className="boss-board__content" style={{ display: 'block' }}>
          <div className="boss-board__content-inner">{children}</div>
        </Collapse>
      </div>
    </div>
  );
}
