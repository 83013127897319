import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';

import ReminderForm from './reminder-form';

class AddReminder extends Component {
  render() {
    const [onSubmit, recurrenceTypesOptions, showDateField, currentDate] = oFetch(
      this.props,
      'onSubmit',
      'recurrenceTypesOptions',
      'showDateField',
      'currentDate',
    );
    const initialValues = {
      title: null,
      description: null,
      recurrenceType: null,
      date: currentDate,
    };
    return (
      <div className="boss-modal-window__form">
        <ReminderForm
          recurrenceTypesOptions={recurrenceTypesOptions}
          initialValues={initialValues}
          isEditing={false}
          showDateField={showDateField}
          onSubmit={onSubmit}
          buttonText="Add reminder"
        />
      </div>
    );
  }
}
export default modalRedux(combineReducers({ form: formReducer }))(AddReminder);
