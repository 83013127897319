import React from 'react';
import Modal from 'react-modal';
import oFetch from 'o-fetch';
import cn from 'classnames';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

import modalDecorator from './modal-decorator';

class CustomContentModal extends React.Component {
  render() {
    const [title, onClose, modalClass, children] = oFetch(
      this.props,
      'title',
      'onClose',
      'modalClass',
      'children',
    );
    const { titleClass, innerClose } = this.props;
    const modalClassNames = cn('ReactModal__Content ReactModal__Content--after-open boss-modal-window', {
      [modalClass]: true,
    });
    const titleClassNames = cn('boss-modal-window__header', { [titleClass]: !!titleClass });
    const closeClassNames = cn({
      'boss-modal-window__close': !innerClose,
      'boss-modal-window__close-inner': !!innerClose,
    });
    return (
      <Modal isOpen={true} contentLabel="Modal" ariaHideApp={false} className={modalClassNames}>
        <button onClick={onClose} className={closeClassNames} />
        <div className={titleClassNames}>{title}</div>
        {children}
      </Modal>
    );
  }
}

function openCustomContentModal(
  { title, modalClass, titleClass, innerClose = true },
  props = {},
  onSubmit,
  onClose,
  rootWrapper,
  closeCallback,
) {
  const whenCloseClicked = () => {
    closeCallback();
    onClose();
  };

  return function (Component) {
    rootWrapper.render(
      <ReactErrorsBoundaryWrapper>
        <CustomContentModal
          title={title}
          innerClose={innerClose}
          onClose={whenCloseClicked}
          modalClass={modalClass}
          titleClass={titleClass}
        >
          <Component onSubmit={onSubmit} onClose={whenCloseClicked} {...props} />
        </CustomContentModal>
      </ReactErrorsBoundaryWrapper>,
    );
  };
}

export default modalDecorator(openCustomContentModal);
