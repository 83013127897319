import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';

import { BossFormCheckbox, BossFormSelectNew } from '@/components/boss-form';
import { ChangeVenueButton } from './change-master-venue-button';
import { updateEmploymentDetailsRequest, changeMasterVenueAction } from '../../actions';
import oFetch from 'o-fetch';

const validate = values => {
  const errors = {};
  return errors;
};

function EmploymentDetailsFormComponent(props) {
  const [
    handleSubmit,
    submitting,
    masterVenue,
    mossStaffTypes,
    mossPayRates,
    accessibleVenues,
    mossStaffMemberId,
    changeMasterVenue,
    canChangePublicHolidays
  ] = oFetch(
    props,
    'handleSubmit',
    'submitting',
    'masterVenue',
    'mossStaffTypes',
    'mossPayRates',
    'accessibleVenues',
    'mossStaffMemberId',
    'changeMasterVenue',
    'canChangePublicHolidays'
  );

  const submission = (values, dispatch) => {
    const valuesJS = values.toJS();
    return dispatch(updateEmploymentDetailsRequest(valuesJS));
  };

  return (
    <form onSubmit={handleSubmit(submission)}>
      <header className="boss-content-switcher__header">
        <h2 className="boss-content-switcher__title">Employment Details</h2>
      </header>

      <ChangeVenueButton
        accessibleVenues={accessibleVenues}
        masterVenue={masterVenue}
        mossStaffMemberId={mossStaffMemberId}
        onChangeMasterVenue={changeMasterVenue}
      />

      <Field
        component={BossFormSelectNew}
        required
        name="moss_staff_type"
        label="Staff Type"
        labelKey="name"
        valueKey="id"
        placeholder="Select staff type ..."
        options={mossStaffTypes}
      />

      <Field
        component={BossFormSelectNew}
        required
        name="moss_pay_rate"
        label="Pay Rate"
        labelKey="name"
        valueKey="id"
        placeholder="Select pay rates ..."
        options={mossPayRates}
      />
      {canChangePublicHolidays && (
        <Field
          name="generatePublicHolidays"
          component={BossFormCheckbox}
          label="Public Holidays"
        />
      )}
      <div className="boss-form__field boss-form__field_justify_end">
        <button
          className="boss-button boss-form__submit boss-form__submit_adjust_single"
          type="submit"
          disabled={submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
}

const EmploymentDetailsForm = reduxForm({
  form: 'employment-details-form',
  validate,
})(EmploymentDetailsFormComponent);

const mapDispatchToProps = {
  changeMasterVenue: changeMasterVenueAction,
};

export default connect(null, mapDispatchToProps)(EmploymentDetailsForm);
