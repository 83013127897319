import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import _ from 'underscore';
import safeMoment from '@/lib/safe-moment';
import {
  MOSS_FINANCE_REPORT_READY_STATUS,
  MOSS_FINANCE_REPORT_SHOW_ALL_FILTER_TYPE,
  MOSS_FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE,
  MOSS_FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE,
  FILTER_TABS,
} from '../constants';

export const staffTypesSelector = state => oFetch(state, 'staffTypes');
export const mossFinanceReportsSelector = state => oFetch(state, 'mossFinanceReports');
export const filterTypeSelector = state => oFetch(state, 'page.filterType');
export const startDateSelector = state => oFetch(state, 'page.startDate');
export const permissionsSelector = state => oFetch(state, 'page.permissions');
export const mossFinanceReportWeekSelector = state => oFetch(state, 'mossFinanceReportWeek');

const filterFactory = mossFinanceReports => {
  const filter = {
    [MOSS_FINANCE_REPORT_SHOW_ALL_FILTER_TYPE]() {
      return mossFinanceReports;
    },
    [MOSS_FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE]() {
      return mossFinanceReports.filter(mossFinanceReport => {
        return oFetch(mossFinanceReport, 'owedHoursMinuteCount') > 0;
      });
    },
    [MOSS_FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE]() {
      return mossFinanceReports.filter(mossFinanceReport => {
        return (
          oFetch(mossFinanceReport, 'paidHolidayDaysCount') > 0 ||
          oFetch(mossFinanceReport, 'sickLeaveDaysCount') > 0
        );
      });
    },
  };

  return filterType => {
    return filter[filterType]();
  };
};

export const getFilteredMossFinanceReports = createSelector(
  mossFinanceReportsSelector,
  filterTypeSelector,
  (mossFinanceReports, filterType) => {
    if (!FILTER_TABS.includes(filterType)) {
      throw new Error(`Unsupported filter type ${filterType} encountered in ${JSON.stringify(FILTER_TABS)}`);
    }
    return filterFactory(mossFinanceReports)(filterType);
  },
);

export const getReportsWithCalculations = createSelector(getFilteredMossFinanceReports, mossFinanceReports =>
  mossFinanceReports.map(mossFinanceReport => {
    const owedHours = oFetch(mossFinanceReport, 'owedHoursMinuteCount') / 60;
    const weeklyHours =
      oFetch(mossFinanceReport, 'mondayHoursCount') +
      oFetch(mossFinanceReport, 'tuesdayHoursCount') +
      oFetch(mossFinanceReport, 'wednesdayHoursCount') +
      oFetch(mossFinanceReport, 'thursdayHoursCount') +
      oFetch(mossFinanceReport, 'fridayHoursCount') +
      oFetch(mossFinanceReport, 'saturdayHoursCount') +
      oFetch(mossFinanceReport, 'sundayHoursCount');
    const payRateType =
      _.last(oFetch(mossFinanceReport, 'mossPayRateDescription').split('/')) === 'h' ? 'hourly' : 'weekly';
    const total = oFetch(mossFinanceReport, 'total');
    return {
      ...mossFinanceReport,
      weeklyHours,
      owedHours,
      payRateType,
      total,
    };
  }),
);

export const completedMossFinanceReportsSelector = createSelector(
  [mossFinanceReportsSelector],
  mossFinanceReports => {
    return mossFinanceReports.filter(mossFinanceReport => {
      return oFetch(mossFinanceReport, 'completedAt');
    });
  },
);

export const getCompletedMossFinanceReportsData = createSelector(
  [completedMossFinanceReportsSelector, mossFinanceReportsSelector],
  (completedMossFinanceReports, mossFinanceReports) => {
    return { completedCount: completedMossFinanceReports.length, count: mossFinanceReports.length };
  },
);

export const getWeekDates = createSelector(startDateSelector, startDate => {
  const date = safeMoment.uiDateParse(startDate);
  return [1, 2, 3, 4, 5, 6, 7].map(weekDay => {
    const currentDate = date.isoWeekday(weekDay);
    return currentDate.format('DD-MM-YYYY');
  });
});

export const getStaffTypesWithMossFinanceReports = createSelector(
  getReportsWithCalculations,
  mossFinanceReports => {
    const staffTypesHash = mossFinanceReports.reduce((result, mossFinanceReport) => {
      const name = oFetch(mossFinanceReport, 'mossStaffTypeName');
      const id = oFetch(mossFinanceReport, 'mossStaffTypeId');
      const key = `${name}:${id}`;
      result[key] = {
        name: name,
        id: id,
      };
      return result;
    }, {});
    const staffTypes = Object.values(staffTypesHash);

    return staffTypes.map(staffType => {
      const staffTypeReports = mossFinanceReports.filter(mossFinanceReport => {
        return oFetch(mossFinanceReport, 'mossStaffTypeName') === oFetch(staffType, 'name');
      });
      const total = staffTypeReports.reduce((acc, report) => acc + oFetch(report, 'total'), 0);
      const readyReportsJS = staffTypeReports.filter(report => {
        return oFetch(report, 'status') === MOSS_FINANCE_REPORT_READY_STATUS;
      });

      const completableReadyReportsJS = [];
      const incompletableReadyReportsJS = [];
      readyReportsJS.forEach(report => {
        const completionDateReached = oFetch(report, 'completionDateReached');
        const hoursPending = oFetch(report, 'hoursPending');

        if (hoursPending || !completionDateReached) {
          incompletableReadyReportsJS.push(report);
        } else {
          completableReadyReportsJS.push(report);
        }
      });

      const staffTypeEmpty = staffTypeReports.length === 0;
      const noIncompletableReportsExist = incompletableReadyReportsJS.length === 0;
      const completeableReportsExist = completableReadyReportsJS.length > 0;
      const allReady = staffTypeEmpty || (noIncompletableReportsExist && completeableReportsExist);

      const staffTypeName = oFetch(staffType, 'name');
      const staffTypeId = oFetch(staffType, 'id');

      return {
        name: staffTypeName,
        id: staffTypeId,
        total: total,
        reports: staffTypeReports,
        allReady: allReady,
      };
    });
  },
);

export const getAllReady = createSelector(
  getStaffTypesWithMossFinanceReports,
  staffTypesWithMossFinanceReports => {
    const allReportsEmpty = staffTypesWithMossFinanceReports.every(staffType => {
      return oFetch(staffType, 'reports.length') === 0;
    });

    return (
      !allReportsEmpty &&
      staffTypesWithMossFinanceReports.every(staffType => {
        return oFetch(staffType, 'allReady') === true;
      })
    );
  },
);
