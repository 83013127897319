import React, { useMemo, Fragment } from 'react';
import oFetch from 'o-fetch';
import { Fields, reduxForm } from 'redux-form/immutable';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { AccessoriesChangeStatus } from '../accessories-change-status';
import { MasterVenueField } from './master-venue-field';

const RETURNED_REUSABLE_STATUS = 'returned_reusable';
const RETURNED_NOT_REUSABLE_STATUS = 'returned_not_reusable';
const NOT_RETURNED_STATUS = 'not_returned';

const STATUS_OPTIONS = [
  {
    label: 'Returned: Reusable',
    value: RETURNED_REUSABLE_STATUS,
  },
  {
    label: 'Returned: Not Reusable',
    value: RETURNED_NOT_REUSABLE_STATUS,
  },
  {
    label: 'Not Returned',
    value: NOT_RETURNED_STATUS,
  },
];

function ChangeVenueModal(props) {
  const [accessibleVenues, accessibleMasterVenues, accessories, handleSubmit, submitting] = oFetch(
    props,
    'accessibleVenues',
    'accessibleMasterVenues',
    'accessories',
    'handleSubmit',
    'submitting',
  );
  const { error } = props;
  const accessoriesExist = accessories.size > 0;

  function renderBaseErrors(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {error && renderBaseErrors(error)}
      <div className="boss-modal-window__form">
        <Fields
          component={MasterVenueField}
          names={['master_venue', 'other_venues']}
          accessibleMasterVenues={accessibleMasterVenues}
          accessibleVenues={accessibleVenues}
        />
        {accessoriesExist && (
          <Fragment>
            <div className="boss-modal-window__alert">
              <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                <p className="boss-alert__text">
                  The status of ALL staff member accessories for the current venue must be logged before
                  moving master venue
                </p>
              </div>
            </div>
            <AccessoriesChangeStatus
              name={'accessoryRequests'}
              label={''}
              accessories={accessories}
              statusOptions={STATUS_OPTIONS}
            />
          </Fragment>
        )}
        <div className="boss-form__field">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={submitting}
            className="boss-button boss-button_role_submit boss-form__submit"
          >
            {submitting ? 'Updating ...' : 'Update'}
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const ChangeVenueForm = reduxForm({
  form: 'change-master-venue-form',
})(ChangeVenueModal);

export function ChangeVenueButton(props) {
  const { openModal } = useModal();
  const [
    accessories,
    accessibleVenues,
    accessoryRequests,
    masterVenue,
    otherVenues,
    onChangeMasterVenue,
    staffMemberId,
    isInitialySecurity,
    isSecurityStaff,
  ] = oFetch(
    props,
    'accessories',
    'accessibleVenues',
    'accessoryRequests',
    'masterVenue',
    'otherVenues',
    'onChangeMasterVenue',
    'staffMemberId',
    'isInitialySecurity',
    'isSecurityStaff',
  );
  const masterVenueObject = useMemo(
    () => accessibleVenues.find(venue => oFetch(venue, 'id') === masterVenue),
    [masterVenue],
  );

  const masterVenueName = masterVenueObject?.name;
  const accessibleMasterVenues = useMemo(
    () => accessibleVenues.filter(venue => oFetch(venue, 'id') !== masterVenue),
    [masterVenue],
  );

  function openEditVenueModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ChangeVenueForm,
      onSubmit(closeModal, values) {
        const valuesJS = values.toJS();
        return onChangeMasterVenue({
          values: valuesJS,
          onSuccess() {
            closeModal();
          },
        });
      },
      props: {
        accessories: accessories,
        accessibleMasterVenues: accessibleMasterVenues,
        accessibleVenues: accessibleVenues,
        initialValues: {
          accessoryRequests: accessoryRequests,
          master_venue: null,
          other_venues: otherVenues,
          staffMemberId: staffMemberId,
        },
      },
      config: {
        title: 'Change Staff Member Venue',
        baseClassName: 'boss-modal-window boss-modal-window_role_edit',
      },
    });
  }

  return (
    <div className="boss-form__field">
      <p className="boss-form__label">
        <span className="boss-form__label-text">Master Venue</span>
      </p>
      <div>
        <span className="boss-form__text-primary" style={{ marginRight: '20px' }}>
          {isInitialySecurity || isSecurityStaff ? 'N/A' : masterVenueName}
        </span>
        {!isInitialySecurity && !isSecurityStaff && (
          <button
            onClick={openEditVenueModal}
            type="button"
            className="boss-button boss-button_role_edit-light boss-button_type_extra-small boss-table__action"
          >
            Change
          </button>
        )}
      </div>
    </div>
  );
}
