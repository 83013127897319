import React from 'react';
import { connect } from 'react-redux';
import safeMoment from '@/lib/safe-moment';
import humanize from 'string-humanize';
import oFetch from 'o-fetch';
import _ from 'lodash';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';
import { bindActionCreators } from 'redux';
import { Tooltip } from 'react-tippy';
import AsyncButton from 'react-async-button';

import { openCustomContentModal } from '@/components/modals';
import PasswordInformationListItem from '../components/password-information-list-item';
import DetailsListItem from '../components/details-list-item';
import AppDownloadLinkListItem from '../components/app-download-link-list-item';
import AppGuidDetailsListItem from '../components/app-guid-details-list-item';
import { BankDetailsListItem } from '../components/bank-details-list-item';
import { LinkedUserListItem } from '../components/linked-user-list-item';
import { UpdateBankDetailsModalContent } from '../components/update-bank-details-modal-content';
import { ConfirmBankDetailsModalContent } from '../components/confirm-bank-details-modal-content';
import DetailsList from '../components/details-list';
import { starterEmploymentStatusLabels } from '../../../../constants/other';
import ProfileWrapper from '../../profile-wrapper';
import {
  sendStaffPartyInviteEmail,
  sendMobileAppDownloadEmail,
  sendPasswordSetupEmail,
  resendPasswordSetupEmail,
  revokePasswordSetupEmail,
  allowClockingWithoutFacialRecognitionAction,
  disallowClockingWithoutFacialRecognitionAction,
  updateStaffMemberBankDetails,
} from '../actions';

import * as selectors from '../selectors';
import * as commonSelectors from '../../profile-wrapper/selectors';

const mapStateToProps = state => {
  return {
    staffMember: commonSelectors.currentStaffMemberSelector(state),
    staffTypes: selectors.staffTypesSelector(state),
    payRates: selectors.payRatesSelector(state),
    venues: selectors.venuesSelector(state),
    accessibleVenues: selectors.accessibleVenuesSelector(state),
    permissionsData: selectors.permissionsDataSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        sendStaffPartyInviteEmail,
        sendMobileAppDownloadEmail,
        sendPasswordSetupEmail,
        resendPasswordSetupEmail,
        revokePasswordSetupEmail,
        allowClockingWithoutFacialRecognition: allowClockingWithoutFacialRecognitionAction,
        disallowClockingWithoutFacialRecognition: disallowClockingWithoutFacialRecognitionAction,
        updateStaffMemberBankDetails,
      },
      dispatch,
    ),
  };
};
const findById = (collection, id) => {
  if (Object.prototype.toString.call(id) === '[object Array]') {
    return _(collection).keyBy('id').at(id).filter().value();
  } else {
    return collection.find(item => item.id === id);
  }
};

function renderTooltip(content) {
  return (
    <Tooltip
      style={{ display: 'inline-block' }}
      arrow
      theme="light"
      position="bottom"
      interactive
      html={content}
    >
      <span className="boss-tooltip boss-tooltip_position_bottom">
        <span className="boss-tooltip__icon" />
      </span>
    </Tooltip>
  );
}

function AllowClockingWithoutFacialRecognitionModalContent(props) {
  const [onClose, onSubmit, title, text, buttonText] = oFetch(
    props,
    'onClose',
    'onSubmit',
    'title',
    'text',
    'buttonText',
  );
  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
        <div className="boss-modal-window__message-group">
          <p className="boss-modal-window__message-text">{title}</p>
        </div>
        <div className="boss-modal-window__message-group">
          <p className="boss-modal-window__message-note">{text}</p>
        </div>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onClose}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          CANCEL
        </button>
        <button
          onClick={onSubmit}
          type="button"
          className="boss-button boss-button_role_alert boss-modal-window__button"
          style={{ textTransform: 'uppercase' }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

function ProfilePage(props) {
  function dateOfBirthMoment(rawValue) {
    return rawValue ? safeMoment.uiDateParse(rawValue) : null;
  }

  function ageDescription(age) {
    return age || 'N/A';
  }

  function openUpdateBankDetailsModal() {
    const [updateStaffMemberBankDetails, staffMember] = oFetch(
      props,
      'actions.updateStaffMemberBankDetails',
      'staffMember',
    );
    const staffMemberJS = staffMember.toJS();
    const altBankingFullname = oFetch(staffMemberJS, 'altBankingFullname');
    const bankDetailsLastUpdatedAt = oFetch(staffMemberJS, 'bankDetailsLastUpdatedAt');
    const bankDetailsLastUpdatedBy = oFetch(staffMemberJS, 'bankDetailsLastUpdatedBy');

    return openCustomContentModal({
      submit: (closeModal, data) => {
        return updateStaffMemberBankDetails({
          staffMember: staffMemberJS,
          values: data,
          onSuccess: data => {
            closeModal();
            openConfirmBankDetailsModal(data);
          },
        });
      },
      config: {
        title: 'Update Bank Details',
        modalClass: 'boss-modal-window_role_edit',
        titleClass: 'boss-modal-window__header',
      },
      props: {
        altBankingFullname,
        bankDetailsLastUpdatedAt,
        bankDetailsLastUpdatedBy,
      },
    })(UpdateBankDetailsModalContent);
  }

  function openConfirmBankDetailsModal(data) {
    const staffMember = oFetch(props, 'staffMember');
    const staffMemberJS = staffMember.toJS();
    const staffMemberName = utils.getFullStaffMemberName({ staffMember: staffMemberJS });
    const altBankingFullname = oFetch(staffMemberJS, 'altBankingFullname');

    return openCustomContentModal({
      submit: closeModal => {
        closeModal();
      },
      config: {
        title: 'Bank Details Update Successful',
        modalClass: 'boss-modal-window_role_success',
        innerClose: false,
      },
      props: {
        ...data,
        staffMemberName: staffMemberName,
      },
    })(ConfirmBankDetailsModalContent);
  }

  function openAllowClockingWithoutFacialRecognitionModal(actionHandler, title, text, buttonText) {
    return new Promise((resolve, reject) => {
      openCustomContentModal({
        closeCallback: () => resolve(),
        submit: (closeModal, data) => {
          closeModal();
          return resolve(actionHandler());
        },
        config: {
          title: 'WARNING !!!',
          modalClass: 'boss-modal-window_role_confirm',
          titleClass: 'boss-modal-window__header_warning',
        },
        props: {
          title,
          text,
          buttonText,
        },
      })(AllowClockingWithoutFacialRecognitionModalContent);
    });
  }

  function allowedWithoutFacialRecognitionTooltipContent() {
    return (
      <span className="boss-tooltip__text">
        {`The current avatar cannot be used for apps that use facial recognition. Update the avatar to enable this functionality.`}
      </span>
    );
  }

  function notAllowedWithoutFacialRecognitionTooltipContent() {
    return (
      <span className="boss-tooltip__text">
        {`The current avatar can be used for apps that use facial recognition. Update the avatar to disable this functionality.`}
      </span>
    );
  }

  function renderAvatarStatus(params) {
    const staffMember = oFetch(params, 'staffMember');
    const permissionsData = oFetch(params, 'permissionsData');
    const canBypassAvatarScanRestrictions = oFetch(permissionsData, 'canBypassAvatarScanRestrictions');
    const [
      handleAllowClockingWithoutFacialRecognition,
      handleDisallowClockingWithoutFacialRecognition,
    ] = oFetch(
      props,
      'actions.allowClockingWithoutFacialRecognition',
      'actions.disallowClockingWithoutFacialRecognition',
    );
    const [supportsFacialRecognition, allowUnscannableAvatar, allowClockingWithoutFacialRecognition] = oFetch(
      staffMember,
      'supportsFacialRecognition',
      'allowUnscannableAvatar',
      'allowClockingWithoutFacialRecognition',
    );
    return (
      <div>
        {supportsFacialRecognition && !allowUnscannableAvatar && (
          <div className="boss-details__value-line">
            <span>Setup for Facial Recognition</span>
            {renderTooltip(notAllowedWithoutFacialRecognitionTooltipContent())}
          </div>
        )}
        <div>
          {!supportsFacialRecognition && !allowClockingWithoutFacialRecognition && (
            <div className="boss-details__value-line">
              <span>Not Setup for Facial Recognition</span>
            </div>
          )}
          {!supportsFacialRecognition && allowClockingWithoutFacialRecognition && (
            <div className="boss-details__value-line">
              <span>Facial Recognition Bypassed</span>
            </div>
          )}
          {!supportsFacialRecognition &&
            canBypassAvatarScanRestrictions &&
            allowUnscannableAvatar &&
            allowClockingWithoutFacialRecognition && (
              <span className="boss-details__value-line">
                <AsyncButton
                  className="boss-button boss-button_role_exclamation boss-button_type_small"
                  text="Undo Bypass FRS"
                  pendingText="Undoing ..."
                  onClick={() =>
                    openAllowClockingWithoutFacialRecognitionModal(
                      () => handleDisallowClockingWithoutFacialRecognition(staffMember),
                      'Undo FRS bypass',
                      'This staff member will non longer appear in the clocking app until a new facial recoginition enabled avatar is supplied',
                      'Undo Bypass',
                    )
                  }
                />
              </span>
            )}
          {!supportsFacialRecognition &&
            canBypassAvatarScanRestrictions &&
            allowUnscannableAvatar &&
            !allowClockingWithoutFacialRecognition && (
              <span className="boss-details__value-line">
                <AsyncButton
                  className="boss-button boss-button_role_exclamation boss-button_type_small"
                  text="Bypass FRS"
                  pendingText="Bypassing FRS ..."
                  onClick={() =>
                    openAllowClockingWithoutFacialRecognitionModal(
                      () => handleAllowClockingWithoutFacialRecognition(staffMember),
                      'Allow staff member to bypass facial recognition',
                      'This will allow the staff member to be Clocked In from manager mode despite not having a scannable avatar',
                      'Bypass FRS',
                    )
                  }
                />
              </span>
            )}
        </div>
      </div>
    );
  }

  function getPayRateName(params) {
    const staffMember = oFetch(params, 'staffMember');
    const baseSecurityPayRateName = oFetch(staffMember, 'baseSecurityPayRateName');
    const isSecurity = oFetch(staffMember, 'is_security_staff');

    if (isSecurity) {
      return baseSecurityPayRateName || 'N/A';
    } else {
      const payRateName = oFetch(staffMember, 'payRateName');
      return payRateName || 'N/A';
    }
  }

  const staffMember = oFetch(props, 'staffMember').toJS();
  const venues = oFetch(props, 'venues').toJS();
  const staffTypes = oFetch(props, 'staffTypes').toJS();
  const [isSecurityStaff, isSteward] = oFetch(staffMember, 'is_security_staff', 'isSteward');
  const actions = oFetch(props, 'actions');
  const sageId = oFetch(staffMember, 'sageId');
  const allowNoSageId = oFetch(staffMember, 'allowNoSageId');
  const { sendPasswordSetupEmail, resendPasswordSetupEmail, revokePasswordSetupEmail } = props.actions;

  const staffMemberId = oFetch(staffMember, 'id');
  const permissionsData = oFetch(props, 'permissionsData').toJS();
  let linkIndex = 0;
  const appDownloadLinks = oFetch(props, 'appDownloadLinks').map(appDownloadLink => {
    const objectWithIndex = Object.assign({ index: linkIndex }, appDownloadLink);
    linkIndex = linkIndex + 1;
    return objectWithIndex;
  });

  const hasBankDetails = oFetch(staffMember, 'hasBankDetails');

  const masterVenueId = staffMember.master_venue;
  const masterVenueValue = masterVenueId ? oFetch(findById(venues, masterVenueId), 'name') : 'N/A';
  const idScannerAppGuid = oFetch(staffMember, 'idScannerAppGuid');
  const hasIdScannerGuid = oFetch(staffMember, 'hasIdScannerGuid');
  const sageIdValue = sageId || (!sageId && !allowNoSageId ? 'Not Set' : 'N/A');
  const onStaffPartyLinkClick = () => {
    oFetch(actions, 'sendStaffPartyInviteEmail')({ staffMemberId });
  };

  return (
    <ProfileWrapper currentPage="profile">
      <div className="boss-page-main__flow">
        <DetailsList key={1} categoryName="Employment Details" sectionNumber={1}>
          <DetailsListItem item={{ name: 'Master venue', value: masterVenueValue }} />
          <DetailsListItem
            item={{
              name: 'Other venues',
              value: findById(venues, oFetch(staffMember, 'other_venues'))
                .map(item => oFetch(item, 'name'))
                .join(', '),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Job Type',
              value: oFetch(findById(staffTypes, oFetch(staffMember, 'staff_type')), 'name'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Starts At',
              value: safeMoment.uiDateParse(oFetch(staffMember, 'starts_at')).format('DD MMMM YYYY'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Pay Rate',
              value: getPayRateName({
                staffMember,
              }),
            }}
          />
          <DetailsListItem
            item={{ name: 'Hours Preference', value: oFetch(staffMember, 'hours_preference') }}
          />
          <DetailsListItem
            item={{
              name: 'National Insurance Number',
              value: oFetch(staffMember, 'national_insurance_number'),
            }}
          />
          <DetailsListItem item={{ name: 'Sage ID', value: sageIdValue }} />
          <DetailsListItem
            item={{
              name: 'Status Statement',
              value: oFetch(staffMember, 'status_statement')
                ? oFetch(starterEmploymentStatusLabels, oFetch(staffMember, 'status_statement'))
                : '',
            }}
          />
          {isSecurityStaff && !isSteward && (
            <DetailsListItem
              item={{
                name: 'Sia badge expiry date',
                value: safeMoment
                  .uiDateParse(oFetch(staffMember, 'sia_badge_expiry_date'))
                  .format('DD MMM YYYY'),
              }}
            />
          )}
          {isSecurityStaff && !isSteward && (
            <DetailsListItem
              item={{ name: 'Sia badge number', value: oFetch(staffMember, 'sia_badge_number') }}
            />
          )}
        </DetailsList>
        <DetailsList key={2} categoryName="Account Details" sectionNumber={2}>
          <DetailsListItem
            item={{
              name: 'Created',
              value: safeMoment.iso8601Parse(oFetch(staffMember, 'created_at')).format('HH:mm DD MMMM YYYY'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Modified',
              value: safeMoment.iso8601Parse(oFetch(staffMember, 'updated_at')).format('HH:mm DD MMMM YYYY'),
            }}
          />
          <LinkedUserListItem staffMember={staffMember} />
          <BankDetailsListItem
            hasBankDetails={hasBankDetails}
            permissionsData={permissionsData}
            onEditClick={openUpdateBankDetailsModal}
          />
          <DetailsListItem
            item={{
              name: 'Worker Type',
              value: oFetch(staffMember, 'workTypeDescription'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Public Holidays',
              value: () => {
                return (
                  <>
                    {oFetch(staffMember, 'generatePublicHolidays') && (
                      <span className="boss-indicator">
                        <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
                      </span>
                    )}
                    {!oFetch(staffMember, 'generatePublicHolidays') && (
                      <span className="boss-indicator">
                        <span className="boss-indicator__icon boss-indicator__icon_close-bold" />
                      </span>
                    )}
                  </>
                )
              },
            }}
          />
          <AppGuidDetailsListItem
            item={{
              name: 'Id Scanner App Guid',
              guid: idScannerAppGuid,
              hasGuid: hasIdScannerGuid,
            }}
          />
          <PasswordInformationListItem
            key="passwordInformation"
            staffMember={staffMember}
            onSendPasswordSetupEmail={sendPasswordSetupEmail}
            onResendPasswordSetupEmail={resendPasswordSetupEmail}
            onRevokePasswordSetupEmail={revokePasswordSetupEmail}
          />
          <DetailsListItem
            labelClass="boss-details__label_size_small"
            item={{
              name: 'Avatar Status',
              value: renderAvatarStatus({ staffMember, permissionsData }),
            }}
          />
        </DetailsList>
        <DetailsList key={3} categoryName="Personal Details" sectionNumber={3}>
          <DetailsListItem
            item={{
              name: 'Name',
              value: `${oFetch(staffMember, 'first_name')} ${oFetch(staffMember, 'surname')}`,
            }}
          />
          <DetailsListItem item={{ name: 'Gender', value: humanize(oFetch(staffMember, 'gender')) }} />
          <DetailsListItem
            item={{
              name: 'Date or birth',
              value:
                dateOfBirthMoment(oFetch(staffMember, 'date_of_birth')) &&
                dateOfBirthMoment(oFetch(staffMember, 'date_of_birth')).format('DD-MM-YYYY'),
            }}
          />
          <DetailsListItem item={{ name: 'Age', value: ageDescription(oFetch(staffMember, 'age')) }} />
        </DetailsList>
        <DetailsList key={4} categoryName="Personal Details" sectionNumber={4}>
          <DetailsListItem item={{ name: 'Address', value: oFetch(staffMember, 'address') }} />
          <DetailsListItem item={{ name: 'County', value: oFetch(staffMember, 'county') }} />
          <DetailsListItem item={{ name: 'Country', value: oFetch(staffMember, 'country') }} />
          <DetailsListItem item={{ name: 'Postcode', value: oFetch(staffMember, 'postcode') }} />
          <DetailsListItem item={{ name: 'Email Address', value: oFetch(staffMember, 'email') }} />
          <DetailsListItem item={{ name: 'Phone number', value: oFetch(staffMember, 'phone_number') }} />
        </DetailsList>
        <DetailsList key={5} categoryName="Mobile Apps" sectionNumber={5}>
          {appDownloadLinks.map(appDownloadLink => {
            return (
              <AppDownloadLinkListItem
                key={oFetch(appDownloadLink, 'index')}
                index={oFetch(appDownloadLink, 'index')}
                appName={oFetch(appDownloadLink, 'appName')}
                lastSentAt={appDownloadLink.lastSentAt}
                onLinkClick={() => {
                  oFetch(actions, 'sendMobileAppDownloadEmail')(
                    oFetch(appDownloadLink, 'downloadUrl'),
                    oFetch(appDownloadLink, 'appName'),
                    oFetch(appDownloadLink, 'mobileAppId'),
                  );
                }}
              />
            );
          })}
          <li key={appDownloadLinks.length} className="boss-details__item" >
            <p className="boss-details__label">Staff Party</p>
            <p className="boss-details__value">
              <span className="boss-details__value-line">
                <a onClick={onStaffPartyLinkClick} className="boss-details__value-action">Resend Invite email</a>
              </span>
            </p>
          </li>;
        </DetailsList>
        <DetailsList key={6} categoryName="Revisions History" sectionNumber={6}>
          <li className="boss-details__item">
            <a
              href={appRoutes.staffMemberHistory({ staffMemberId })}
              className="boss-button boss-button_role_view-history boss-button_type_small"
            >
              View History
            </a>
          </li>
        </DetailsList>
      </div>
    </ProfileWrapper>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
