import React from 'react';
import oFetch from 'o-fetch';
import WeekCountForm from './week-count-form';
import { getWeekCountInvalid } from '../redux/selectors';

export default function WeekCount(props) {
  const [weekCount, onSubmit, totalWeekCounts, weekStart, weekDaysCompleted, editMode] = oFetch(
    props,
    'weekCount',
    'onSubmit',
    'totalWeekCounts',
    'weekStart',
    'weekDaysCompleted',
    'editMode',
  );
  const weekCountExist = weekCount !== null;

  const completed = weekDaysCompleted;
  const invalid = getWeekCountInvalid(weekCount);
  const editable = getEditable();

  function getEditable() {
    if (!completed) {
      return false;
    }
    if (!weekCountExist) {
      return true;
    }
    return editMode && invalid;
  }

  function getInitialValues() {
    if (!weekCountExist) {
      return {
        weekStart: weekStart,
        fiftyNotesBundleCount: 0,
        twentyNotesBundleCount: 0,
        tenNotesBundleCount: 0,
        fiveNotesBundleCount: 0,
        looseTotalPence: 0,
      };
    }
    return {
      weekStart: oFetch(weekCount, 'weekStart'),
      fiftyNotesBundleCount: oFetch(weekCount, 'fiftyNotesBundleCount'),
      twentyNotesBundleCount: oFetch(weekCount, 'twentyNotesBundleCount'),
      tenNotesBundleCount: oFetch(weekCount, 'tenNotesBundleCount'),
      fiveNotesBundleCount: oFetch(weekCount, 'fiveNotesBundleCount'),
      looseTotalPence: oFetch(weekCount, 'looseTotalPence'),
    };
  }
  const initialValues = getInitialValues();

  return (
    <WeekCountForm
      onSubmit={onSubmit}
      totalWeekCounts={totalWeekCounts}
      editable={editable}
      weekDaysCompleted={completed}
      initialValues={initialValues}
      form={`all-counting-form`}
    />
  );
}
