import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { SHORT_SLASH_DATE_FORMAT } from '@/lib/date-fns-formats';
import { Tooltip } from 'react-tippy';
import { TaxYearForm } from './tax-year-form';
import { $HolidayAllowancesSectionProps, HolidayAllowancesSectionProps, HolidayDayTotals, TaxYear, TaxYearHoliday } from '../types';
import { z } from 'zod';

const $TaxYearFormSubmitArgs = z.object({
  year: z.number().int().positive(),
}).strict();

type TaxYearFormSubmitArgs = z.infer<typeof $TaxYearFormSubmitArgs>;

function getHolidayListTooltipHtml(holidays: TaxYearHoliday[]): React.JSX.Element {
  return (
    <div className="boss-overview">
      <div className="boss-overview__group boss-overview__group_position_last">
          <ul className="boss-overview__list">
            { holidays.map((holiday) => {
              return (
                <li
                  key={`hti${holiday.startDate}-${holiday.endDate}`}
                  className="boss-overview__list-item boss-overview__list-item_role_stats-details"
                >
                  <p className="boss-overview__list-text">{format(holiday.startDate, SHORT_SLASH_DATE_FORMAT)} - {format(holiday.endDate, SHORT_SLASH_DATE_FORMAT)}</p>
                </li>
              );
            }) }

          </ul>
      </div>
    </div>
  );
}

export function HolidayAllowancesSection(props: HolidayAllowancesSectionProps) {
  const parsedProps = $HolidayAllowancesSectionProps.parse(props);
  const selectedTaxYear = parsedProps.selectedTaxYear;
  const holidayDayTotals = useMemo((): HolidayDayTotals => {
    return getHolidayDayTotals(selectedTaxYear);
  }, [selectedTaxYear]);

  async function onTaxYearFormSubmit(values: TaxYearFormSubmitArgs): Promise<unknown> {
    const parsedValues = $TaxYearFormSubmitArgs.parse(values);
    if (parsedValues === undefined || !parsedValues || !(typeof parsedValues === 'object')) {
      throw new Error('Year is required');
    }
    if (!Object.keys(parsedValues).includes('year') || !parsedValues.year || typeof parsedValues.year !== 'number') {
      throw new Error('Year is required');
    }
    return parsedProps.taxYearInfoRequest({ effectiveStaffMember: parsedProps.effectiveStaffMember, year: parsedValues.year });
  }

  const emptyTooltipHtml = <div />;
  const paidHolidaysPresent = holidayDayTotals.paidHolidays > 0;
  const paidHolidayTooltipHtml = paidHolidaysPresent ? getHolidayListTooltipHtml(selectedTaxYear.paidHolidays) : emptyTooltipHtml;
  const plusPaidHolidaysPresent = holidayDayTotals.plusPaidHolidays > 0;
  const paidHolidayPlusTooltipHtml = plusPaidHolidaysPresent ? getHolidayListTooltipHtml(selectedTaxYear.plusPaidHolidays) : emptyTooltipHtml;
  const minusPaidHolidaysPresent = holidayDayTotals.minusPaidHolidays > 0;
  const paidHolidayMinusTooltipHtml = minusPaidHolidaysPresent ? getHolidayListTooltipHtml(selectedTaxYear.minusPaidHolidays) : emptyTooltipHtml;
  const unpaidHolidaysPresent = holidayDayTotals.unpaidHolidays > 0;
  const unpaidHolidayTooltipHtml = unpaidHolidaysPresent ? getHolidayListTooltipHtml(selectedTaxYear.unpaidHolidays) : emptyTooltipHtml;
  const taxYearFormRequesting = parsedProps.taxYearFormRequesting;

  return (
    <div className="boss-board__manager-group boss-board__manager-group_role_summary">
      <div className="boss-board__manager-filter">
        <TaxYearForm
          onSubmit={onTaxYearFormSubmit}
          initialValues={{
            selectedTaxYear: selectedTaxYear,
            year: selectedTaxYear.year,
          }}
          taxYearFormRequesting={taxYearFormRequesting}
        />
      </div>
      <div className="boss-board__manager-stats boss-board__manager-stats_role_group-content boss-board__manager-stats_layout_row">
        <div className="boss-count boss-count_adjust_row">
          <div className="boss-count__group">
            <div className="boss-count__numbers">
              <p className="boss-count__number">{ holidayDayTotals.accruedHolidayEstimateDays }</p>
            </div>
            <p className="boss-count__label">Accrued Holidays</p>
          </div>
        </div>
        <div className="boss-count boss-count_adjust_row">
          <div className="boss-count__group">
            <div className="boss-count__numbers">
              { paidHolidaysPresent && (
                <Tooltip
                  theme="light"
                  arrow={true}
                  interactive
                  html={paidHolidayTooltipHtml}
                >
                  <p className="boss-count__number boss-count__number_role_action">{holidayDayTotals.paidHolidays}</p>
                </Tooltip>
              )}
              { !paidHolidaysPresent && (
                <p className="boss-count__number">{holidayDayTotals.paidHolidays}</p>
              ) }
              { plusPaidHolidaysPresent && (
                <Tooltip
                  theme="light"
                  arrow={true}
                  interactive
                  html={paidHolidayPlusTooltipHtml}
                >
                  <p
                    className="boss-count__number boss-count__number_role_addition boss-count__number_role_action js-tippy-count-number"
                  >
                    +{holidayDayTotals.plusPaidHolidays}
                  </p>
                </Tooltip>
              )}
              { minusPaidHolidaysPresent && (
                <Tooltip theme="light" arrow={true} interactive html={paidHolidayMinusTooltipHtml}>
                  <p
                    className="boss-count__number boss-count__number_role_deduction boss-count__number_role_action js-tippy-count-number"
                    aria-expanded="true"
                  >
                    -{holidayDayTotals.minusPaidHolidays}
                  </p>
                </Tooltip>
              )}
            </div>
            <p className="boss-count__label">Paid Holidays</p>
          </div>
        </div>
        <div className="boss-count boss-count_adjust_row">
          <div className="boss-count__group">
            <div className="boss-count__numbers">
              { unpaidHolidaysPresent && <Tooltip theme="light" arrow={true} interactive html={unpaidHolidayTooltipHtml}>
                <p className="boss-count__number">{holidayDayTotals.unpaidHolidays}</p>
              </Tooltip> }
              { !unpaidHolidaysPresent && (
                <p className="boss-count__number">{holidayDayTotals.unpaidHolidays}</p>
              ) }
            </div>
            <p className="boss-count__label">Unpaid Holidays</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function getHolidayDayTotals(selectedTaxYear: TaxYear): HolidayDayTotals {
  return {
    accruedHolidayEstimateDays: selectedTaxYear.estimatedAccruedHolidayDays,
    paidHolidays: selectedTaxYear.paidHolidays.reduce((acc, item) => {
      return acc + item.dayCount;
    }, 0),
    plusPaidHolidays: selectedTaxYear.plusPaidHolidays.reduce((acc, item) => {
      return acc + item.dayCount;
    }, 0),
    minusPaidHolidays: selectedTaxYear.minusPaidHolidays.reduce((acc, item) => {
      return acc + item.dayCount;
    }, 0),
    unpaidHolidays: selectedTaxYear.unpaidHolidays.reduce((acc, item) => {
      return acc + item.dayCount;
    }, 0),
  };
}