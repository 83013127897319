import React from 'react';
import oFetch from 'o-fetch';
import NativeAppLinkItem from './native-app-link-item';

export default function NativeAppLinkListItem(props) {
  const nativeAppLink = oFetch(props, 'nativeAppLink');
  const [
    name,
    description,
    androidDownloadUrl,
    iosDownloadUrl,
    androidBuildVersion,
    iosBuildVersion,
    ipaFileUrl,
    color,
  ] = oFetch(
    nativeAppLink,
    'name',
    'description',
    'androidDownloadUrl',
    'iosDownloadUrl',
    'androidBuildVersion',
    'iosBuildVersion',
    'ipaFileUrl',
    'color',
  );

  return (
    <div className="boss-check boss-check_role_board boss-check_page_native-app-links">
      <div className="boss-check__header" style={{ backgroundColor: color }}>
        <h3 className="boss-check__title">{name}</h3>
        {description && <p className="boss-check__subtitle">{description}</p>}
      </div>
      <div className="boss-check__group">
        <div className="boss-table boss-table_page_native-app-links">
          <div className="boss-table__row">
            <div className="boss-table__cell">
              <div className="boss-table__info">
                <div className="boss-table__info-group">
                  <p className="boss-table__text">
                    Android build{' '}
                    {androidBuildVersion && (
                      <span className="boss-table__text-marked">v{androidBuildVersion}</span>
                    )}
                  </p>
                  <div className="boss-table__control">
                    <div className="boss-link-control">
                      <NativeAppLinkItem downloadUrl={androidDownloadUrl} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-table__cell">
              <div className="boss-table__info">
                <div className="boss-table__info-group">
                  <p className="boss-table__text">
                    iOS build{' '}
                    {iosBuildVersion && (
                      <span className="boss-table__text-marked">v{iosBuildVersion}</span>
                    )}
                  </p>
                  <div className="boss-table__control">
                    <div className="boss-link-control">
                      <NativeAppLinkItem downloadUrl={iosDownloadUrl} />
                    </div>
                  </div>
                  {ipaFileUrl && (
                    <p className="boss-table__text">
                      <a href={ipaFileUrl} download className="boss-table__link">
                        Download IPA file
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
