import React, { useContext } from 'react';
import oFetch from 'o-fetch';
import Modal from 'react-modal';
import cn from 'classnames';
import utils from '@/lib/utils';
import { ModalsContext } from '../hooks-components/modals/modal-provider';

export function ProcessingModalComponent(props) {
  const [children, title, onClose, baseClassName] = oFetch(
    props,
    'children',
    'title',
    'onClose',
    'baseClassName',
  );
  const context = useContext(ModalsContext);

  const baseClassNames = cn({ [baseClassName]: !!baseClassName });

  function renderTitle() {
    if (utils.isFunction(title)) {
      return title();
    }
    return <div className="boss-modal-window__header">{title}</div>;
  }

  return (
    <Modal
      isOpen={true}
      className={baseClassNames}
      contentLabel="Modal"
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!context.processing}
      shouldCloseOnEsc={!context.processing}
    >
      {!context.processing && (
        <button type="button" onClick={onClose} className="boss-modal-window__close-inner" />
      )}
      {context.processing && (
        <div className="boss-modal-window__spinner-inner">
          <div className="boss-spinner" />
        </div>
      )}
      {renderTitle()}
      <div className="boss-modal-window__content">{children(context.processing)}</div>
    </Modal>
  );
}

ProcessingModalComponent.defaultProps = {
  baseClassName: 'boss-modal-window boss-modal-window_role_add',
};
