import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import CreateChecklist from './create-checklist';

export default function ChecklistList(props) {
  const [
    itemRenderer,
    checklists,
    onCreateChecklist,
    onPrintGuid,
  ] = oFetch(
    props,
    'itemRenderer',
    'checklists',
    'onCreateChecklist',
    'onPrintGuid',
  );

  function renderList() {
    return checklists.map(checklist => {
      const checklistId = oFetch(checklist, 'id');
      return React.cloneElement(itemRenderer(checklist), {
        key: `edit-${checklistId.toString()}`,
      });
    });
  }

  return (
    <ContentWrapper>
      <CreateChecklist onSubmit={onCreateChecklist} />
      {renderList()}
    </ContentWrapper>
  );
}
