import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = {
  validateAvatarForCreateUrl: null,
  userPermissions: null,
  checkSiaNumber: null,
  options: {
    payRates: [],
    genders: {},
    staffTypes: [],
    venues: [],
    accessories: [],
  },
  flaggedStaffMembersFetching: false,
};

export default handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const [
        genderOptions,
        staffTypesOptions,
        venues,
        validateAvatarForCreateUrl,
        payRates,
        forStandardSecurityStaffSecurityPayRates,
        forStewardSecurityStaffSecurityPayRates,
        securityStaffTypesOptions,
        employmentStatuses,
        permissions,
        checkSiaNumber,
      ] = oFetch(
        action.payload,
        'genderOptions',
        'staffTypesOptions',
        'venues',
        'validateAvatarForCreateUrl',
        'payRates',
        'forStandardSecurityStaffSecurityPayRates',
        'forStewardSecurityStaffSecurityPayRates',
        'securityStaffTypesOptions',
        'employmentStatuses',
        'permissions',
        'checkSiaNumber',
      );
      return {
        ...state,
        validateAvatarForCreateUrl: validateAvatarForCreateUrl,
        userPermissions: permissions,
        checkSiaNumber: checkSiaNumber,
        options: {
          genders: genderOptions,
          staffTypes: staffTypesOptions,
          venues: venues,
          payRates: payRates,
          forStandardSecurityStaffSecurityPayRates: forStandardSecurityStaffSecurityPayRates,
          forStewardSecurityStaffSecurityPayRates: forStewardSecurityStaffSecurityPayRates,
          securityStaffTypes: securityStaffTypesOptions,
          employmentStatuses: employmentStatuses,
          accessories: [],
        },
      };
    },
    [t.START_FLAGGED_FETCHING]: state => {
      return {
        ...state,
        flaggedStaffMembersFetching: true,
      };
    },
    [t.END_FLAGGED_FETCHING]: state => {
      return {
        ...state,
        flaggedStaffMembersFetching: false,
      };
    },
    [t.UPDATE_ACCESSORIES]: (state, action) => {
      const accessories = oFetch(action.payload, 'accessories');

      return {
        ...state,
        options: {
          ...state.options,
          accessories,
        },
      };
    },
  },
  initialState,
);
