import oFetch from 'o-fetch';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

class VenueList extends Component {
  renderItems(venues) {
    const itemRenderer = oFetch(this.props, 'itemRenderer');
    return venues.map(venue => {
      return React.cloneElement(itemRenderer(venue), {
        key: venue.get('id').toString(),
      });
    });
  }
  renderMobileItems(venues) {
    const mobileItemRenderer = oFetch(this.props, 'mobileItemRenderer');
    return venues.map(venue => {
      return React.cloneElement(mobileItemRenderer(venue), {
        key: venue.get('id').toString(),
      });
    });
  }

  render() {
    const venues = oFetch(this.props, 'venues');
    return (
      <div className="boss-page-main__group boss-page-main__group_adjust_venues-table">
        <div className="boss-table boss-table_page_venues-index">
          <div className="boss-table__row">
            <div className="boss-table__cell boss-table__cell_role_header">Name</div>
            <div className="boss-table__cell boss-table__cell_role_header">Type</div>
            <div className="boss-table__cell boss-table__cell_role_header">Available Boss Staff</div>
            <div className="boss-table__cell boss-table__cell_role_header">Fixed Boss Staff</div>
            <div className="boss-table__cell boss-table__cell_role_header">Safe Float</div>
            <div className="boss-table__cell boss-table__cell_role_header">Till Float</div>
            <div className="boss-table__cell boss-table__cell_role_header">Location</div>
            <div className="boss-table__cell boss-table__cell_role_header">Change Order Site ID</div>
            <div className="boss-table__cell boss-table__cell_role_header">Fruit Order Fields</div>
            <div className="boss-table__cell boss-table__cell_role_header">Actions</div>
          </div>
          {this.renderItems(venues)}
        </div>
        {this.renderMobileItems(venues)}
      </div>
    );
  }
}

VenueList.propTypes = {
  venues: ImmutablePropTypes.list.isRequired,
};

export default VenueList;
