import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { SimpleDashboard } from '../components';
import { QuizCategoriesItem, QuizCategoriesList } from './components';

export default function QuizCategoriesApp(props) {
  const [quizCategories, quizCategoriesSizes] = oFetch(props, 'quizCategories', 'quizCategoriesSizes');

  function renderCategory(category) {
    return <QuizCategoriesItem category={category} />;
  }

  return (
    <div>
      <SimpleDashboard renderTitle={() => 'Quiz Categories'} />
      <ContentWrapper>
        <QuizCategoriesList
          items={quizCategories}
          quizCategoriesSizes={quizCategoriesSizes}
          itemRender={renderCategory}
        />
      </ContentWrapper>
    </div>
  );
}
