import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../configure-store';

import MessageBoardContainer from './containers/message-board-container';
import messageBoard from './reducers/message-board';
import { setInitialData } from './actions/initial-load';

import { reducer as form } from 'redux-form/immutable';
import oFetch from 'o-fetch';

export function MessageBoardApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  const [store, setStore] = useState(null);
  window.boss.accessToken = accessToken;

  useEffect(() => {
    const store = configureStore({ messageBoard, form });
    store.dispatch(setInitialData(props));
    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <MessageBoardContainer />
    </Provider>
  );
}
