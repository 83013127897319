import React from 'react';
import oFetch from 'o-fetch';
import AddMossStaffMemberForm from './components/add-moss-staff-member-form';
import { addMossStaffMemberAction } from './actions';
import notify from '@/components/global-notification';
import renameKey from 'rename-key';

function AddMossStaffMemberUI(props) {
  function submission(values, dispatch) {
    return addMossStaffMemberAction({ values });
  }

  function pageHeader() {
    return (
      <div className={'boss-page-main__dashboard'}>
        <div className={'boss-page-main__dashboard'}>
          <div className={'boss-page-main__inner'}>
            <div
              className={'boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_profile'}
            >
              <div className={'boss-page-dashboard__group'}>
                <h1 className={'boss-page-dashboard__title'}>Add Staff Member</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const validateAvatarForCreateUrl = oFetch(props, 'validateAvatarForCreateUrl');
  const mossStaffTypes = oFetch(props, 'mossStaffTypes');
  const venues = oFetch(props, 'venues');
  const mossPayRates = oFetch(props, 'mossPayRates');
  const linkableStaffMemberOptions = oFetch(props, 'linkableStaffMemberOptions');

  const formInitialValues = {
    firstName: '',
    surname: '',
    linkStaffMemberId: null,
    mossStaffTypeId: null,
    masterVenueId: null,
    mossPayRateId: null,
    pinCode: '',
    validatedAvatarGUID: '',
    avatar: null,
    setupForFacialRecognition: true,
  };

  return (
    <div>
      {pageHeader()}
      <div className={'boss-page-main__content'}>
        <div
          className={'boss-page-main__inner boss-page-main__inner_space_large boss-page-main__inner_opaque'}
        >
          <AddMossStaffMemberForm
            initialValues={formInitialValues}
            onSubmit={submission}
            validateAvatarForCreateUrl={validateAvatarForCreateUrl}
            mossStaffTypes={mossStaffTypes}
            venues={venues}
            mossPayRates={mossPayRates}
            linkableStaffMemberOptions={linkableStaffMemberOptions}
          />
        </div>
      </div>
    </div>
  );
}

export default AddMossStaffMemberUI;
