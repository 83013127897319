import oFetch from 'o-fetch';
import React, { useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@/apps/store';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import { ModalsProvider } from '@/components/hooks-components/modals';
import HoursConfirmation from './hours-confirmation';
import { loadInitialStateAction } from './redux/actions';
import reducers from './redux/reducers';
import { RotaDateUIUtils } from '@/lib/rota-date';

export function HoursConfirmationApp(props) {
  const [store, setStore] = useState();
  const accessToken = oFetch(props, 'accessToken');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  const pageOptions = oFetch(props, 'pageOptions');
  const appType = oFetch(pageOptions, 'appType');
  const pageType = oFetch(pageOptions, 'pageType');
  const sDate = oFetch(pageOptions, 'date');

  const graphXAxisHours = useMemo(() => {
    if (!pageType === 'current') {
      const shiftGraphRotaDate = RotaDateUIUtils.sFromDate({ sDate, appType });
      return shiftGraphRotaDate.getGraphXAxisHours();
    }
    return null;
  }, []);

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(loadInitialStateAction(props));
    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <LegacyGlobalProvider
      global={{
        appType: appType,
        graphXAxisHours
      }}
    >
      <Provider store={store}>
        <ModalsProvider>
          <HoursConfirmation />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
