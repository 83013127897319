import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import BossFormSelect from './boss-form-select';
import { BossFormAccessoryIconField } from '@/components/boss-form';
import * as constants from './constants';

class NewAccessoryRequestForm extends React.Component {
  getAccessorySizes = (accessories, selectedAccessoryId) => {
    const selectedAccessory = accessories.find(item => item.value === selectedAccessoryId);
    if (selectedAccessory) {
      const sizesString = oFetch(selectedAccessory, 'size');
      if (sizesString) {
        return sizesString.split(',').map(size => ({ value: size, label: size }));
      }
      return [];
    }
    return [];
  };
  render() {
    const [accessoriesOptions, accessoryType, accessoryId, handleSubmit, isUniformType] = oFetch(
      this.props,
      'accessoriesOptions',
      'accessoryType',
      'accessoryId',
      'handleSubmit',
      'isUniformType',
    );
    const accessories = accessoriesOptions.filter(item => item.accessoryType === accessoryType);

    const accessorySizes = this.getAccessorySizes(accessories, accessoryId);

    return (
      <form onSubmit={handleSubmit} className="boss-modal-window__form boss-form">
        <Field
          name="accessoryType"
          label="Accessory type"
          placeholder="Select accessory type ..."
          options={constants.ACCESSORY_SELECT_TYPES}
          component={BossFormSelect}
        />
        <Field
          name="accessoryId"
          label="Name"
          placeholder="Select accessory name ..."
          options={accessories}
          clearable
          component={BossFormAccessoryIconField}
        />
        {isUniformType && accessorySizes.length > 0 && (
          <Field
            name="size"
            label="Size"
            placeholder="Select size ..."
            options={accessorySizes}
            component={BossFormSelect}
          />
        )}
        <div className="boss-form__field">
          <button
            disabled={this.props.submitting}
            className={`boss-button boss-form__submit ${this.props.buttonClass}`}
          >
            {this.props.buttonText}
          </button>
        </div>
      </form>
    );
  }
}

NewAccessoryRequestForm.propTypes = {};

NewAccessoryRequestForm.defaultProps = {
  buttonClass: 'boss-button_role_add',
  buttonText: 'Add Accessory',
};

const selector = formValueSelector('new-accessory-request-form');

const ConnectedNewAccessoryRequestForm = connect(state => {
  const accessoryType = selector(state, 'accessoryType') || null;
  const accessoryId = selector(state, 'accessoryId') || null;
  return {
    accessoryType: accessoryType,
    accessoryId: accessoryId,
    isUniformType: accessoryType === constants.UNIFORM_TYPE,
  };
})(NewAccessoryRequestForm);

export default reduxForm({
  values: ['id'],
  form: 'new-accessory-request-form',
})(ConnectedNewAccessoryRequestForm);
