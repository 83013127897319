import React, { useState } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

import utils from '@/lib/utils';

export function BonusPaymentItem(props) {
  const [processing, setProcessing] = useState(false);
  const [bonusPayment, onDifferentAmount, onReceive, onDidNotReceive] = oFetch(
    props,
    'bonusPayment',
    'onDifferentAmount',
    'onReceive',
    'onDidNotReceive',
  );
  const [id, year, quarter, amountCents] = oFetch(bonusPayment, 'id', 'year', 'quarter', 'amountCents');

  async function handleProcess(action, params) {
    setProcessing(true);
    await action(params);
    setProcessing(false);
  }

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_quiz-category">
      <div className="boss-check__header">
        <div
          className="boss-check__header-group"
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
        >
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-marked" style={{ fontSize: '35px' }}>
                  {year}, Quarter {quarter}
                </span>
              </p>
            </div>
          </div>
          <div className="boss-check__header-tags">
            <span
              className="boss-tag boss-tag_size_xxs boss-tag_border_accent-green boss-check__header-tag"
              style={{ margin: 0 }}
            >
              <span className="boss-tag__text" style={{ fontSize: '55px', fontWeight: '700' }}>
                {utils.centsFormat(amountCents)}
              </span>
            </span>
          </div>
        </div>
        <div
          className="boss-check__header-actions"
          style={{ flexDirection: 'column', justifyContent: 'space-between', height: '130px' }}
        >
          <AsyncButton
            className="boss-button boss-button_role_edit boss-button_type_small boss-check__header-action"
            text="I received this payment"
            style={{ width: '100%', textAlign: 'center', marginLeft: 0 }}
            disabled={processing}
            pendingText="Processing ..."
            onClick={() => handleProcess(onReceive, id)}
          />
          <button
            onClick={() => handleProcess(onDifferentAmount, id)}
            className="boss-button boss-button_role_move boss-button_type_small boss-check__header-action"
            style={{ width: '100%', textAlign: 'center', marginLeft: 0 }}
            disabled={processing}
          >
            I received a different amount
          </button>
          <AsyncButton
            className="boss-button boss-button_role_disable boss-button_type_small boss-check__header-action"
            text=" I did not receive this payment"
            style={{ width: '100%', textAlign: 'center', marginLeft: 0 }}
            disabled={processing}
            pendingText="Processing ..."
            onClick={() => handleProcess(onDidNotReceive, id)}
          />
        </div>
      </div>
    </div>
  );
}
