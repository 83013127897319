import React from 'react';
import oFetch from 'o-fetch';
import { ChecklistForm } from './checklist-form';

export default class CreateChecklist extends React.Component {
  state = {
    isOpened: false,
  };

  handleOpen = () => {
    this.setState({ isOpened: true });
  };

  handleClose = () => {
    this.setState({ isOpened: false });
  };

  handleSubmit = (values, dispatch, props) => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    const form = oFetch(props, 'form');
    return onSubmit({ values, form });
  };

  render() {
    const isOpened = oFetch(this.state, 'isOpened');

    const initialValues = {
      name: null,
      items: [],
    };

    return (
      <ChecklistForm
        edit={false}
        form="create-checklist-form"
        initialValues={initialValues}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        isOpened={isOpened}
        onSubmit={this.handleSubmit}
      />
    );
  }
}
