import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';

import {
  MainDashboard,
  MessageCarousel,
  VenueAlertsCarousel,
  WeatherWidget,
  AttendanceWidget,
} from './components';

import {
  currentLevelSelector,
  redLevelTypeSelector,
  amberLevelTypeSelector,
  currentSiteIssuesAndVenueAlertsSelector,
} from './selectors';

export function VenueDashboard(props) {
  const attendance = oFetch(props, 'attendance');
  const attendanceLastUpdatedAt = oFetch(props, 'attendanceLastUpdatedAt');
  const staffTypes = oFetch(props, 'staffTypes');
  const redLevelType = redLevelTypeSelector(props);
  const amberLevelType = amberLevelTypeSelector(props);
  const currentLevelType = currentLevelSelector(props);
  const currentSiteIssuesAndVenueAlerts = currentSiteIssuesAndVenueAlertsSelector(props);
  const currentVenue = oFetch(props, 'currentVenue');
  const renderWeatherWidget = oFetch(props, 'renderWeatherWidget');
  const messages = oFetch(props, 'messages');

  return (
    <React.Fragment>
      <MainDashboard currentLevel={currentLevelType} levelTypes={{ redLevelType, amberLevelType }} />
      <ContentWrapper className={'boss-page-main__inner_opaque boss-page-main__inner_space_large'}>
        <VenueAlertsCarousel
          messages={currentSiteIssuesAndVenueAlerts}
          levelTypes={{ redLevelType, amberLevelType }}
        />
        <MessageCarousel messages={messages} />
        <AttendanceWidget data={attendance} staffTypes={staffTypes} lastUpdatedAt={attendanceLastUpdatedAt} />
        <WeatherWidget renderWeatherWidget={renderWeatherWidget} venueId={oFetch(currentVenue, 'id')} />
      </ContentWrapper>
    </React.Fragment>
  );
}
