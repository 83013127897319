import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import React from 'react';
import RotaOverviewChart from '@/components/rota-overview-chart';
import getGroupedShiftBreakdownByTime from '@/lib/get-grouped-shift-breakdown-by-time';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';

const GRANULARITY = 30;

VenueRotaOverviewChart.propTypes = {
  staff: PropTypes.array.isRequired,
  shifts: PropTypes.array.isRequired,
  dDateOfRota: PropTypes.instanceOf(Date).isRequired,
  staffTypes: PropTypes.object.isRequired,
  onHoverShiftsChange: PropTypes.func.isRequired,
  onSelectionShiftsChange: PropTypes.func.isRequired,
};

export default function VenueRotaOverviewChart(props) {
  const dDateOfRota = oFetch(props, 'dDateOfRota');
  const rotaDate = RotaDate.fromDate({ dCalendarDate: dDateOfRota, appType: BOSS_APP_TYPE });

  function getBreakdown() {
    var { shifts, staff, staffTypes } = props;
    return getGroupedShiftBreakdownByTime({
      shifts: Object.values(shifts),
      granularityInMinutes: GRANULARITY,
      rotaDate,
      groupsById: [...Object.keys(staffTypes), 'head_doorman_hour'],
      getGroupFromShift(shift) {
        const headDoormanHour = oFetch(shift, 'head_doorman_hour');
        if (headDoormanHour === true) {
          return `head_doorman_hour`;
        }
        return shift.staff_member.get(staff).staff_type.clientId;
      },
    });
  }
  const groups = [
    ...Object.values(props.staffTypes),
    {
      clientId: 'head_doorman_hour',
      name: 'Head Doorman',
      color: '#8c7ae6',
    },
  ];

  return (
    <RotaOverviewChart
      staff={props.staff}
      shifts={Object.values(props.shifts)}
      dDateOfRota={dDateOfRota}
      onHoverShiftsChange={props.onHoverShiftsChange}
      onSelectionShiftsChange={props.onSelectionShiftsChange}
      getBreakdown={getBreakdown}
      granularity={GRANULARITY}
      groups={groups}
    />
  );
}
