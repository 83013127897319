import React from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function UsersList(props) {
  const [userReports, renderUser] = oFetch(props, 'userReports', 'renderUser');

  function renderUsers() {
    return Object.entries(userReports).map(userEntry => {
      const [userId, userReports] = userEntry;
      const reportsCount = userReports.length;
      const lastReport = userReports[userReports.length - 1];
      const lastReportAt = safeMoment
        .iso8601Parse(oFetch(lastReport, 'at'))
        .format(utils.humanDateFormatWithTimeAndSeconds());
      const [fullName, userRole] = oFetch(lastReport, 'userFullName', 'userRole');

      return React.cloneElement(
        renderUser({ userId, reportsCount, lastReportAt, fullName, userRole, userReports }),
        { key: userId },
      );
    });
  }

  return (
    <div className="boss-table boss-table_page_holidays-report">
      <div className="boss-table__group">{renderUsers()}</div>
    </div>
  );
}
