import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import filterReducer from './filter-reducer';
import weekDaysCountsReducer from './week-days-counts-reducer';
import weekCountReducer from './week-count-reducer';
import staticDataReducer from './static-data-reducer';
import editModeReducer from './edit-mode-reducer';
import showHistoryReducer from './show-history-reducer';
import tabsStatusesReducer from './tabs-statuses-reducer';

export default combineReducers({
  form: formReducer,
  filter: filterReducer,
  weekDaysCounts: weekDaysCountsReducer,
  weekCount: weekCountReducer,
  staticData: staticDataReducer,
  editMode: editModeReducer,
  showHistory: showHistoryReducer,
  tabsStatuses: tabsStatusesReducer,
});
