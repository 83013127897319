import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { useUserActivity } from '../hooks-components/use-user-activity';
import { InactivityModal } from './inactivity-modal';

export function Inactivity(props) {
  const [modal, setModal] = useState(null);
  const [inactivitySeconds, beforeReloadSeconds] = oFetch(props, 'inactivitySeconds', 'beforeReloadSeconds');

  useUserActivity({
    inactivitySeconds,
    onInactive() {
      if (!modal) {
        showModal(
          <InactivityModal
            countdownSeconds={beforeReloadSeconds}
            onCountdownEnd={() => {
              window.location.reload(true);
            }}
          />,
        );
      }
    },
    onEvent() {
      closeModal();
    },
  });

  function showModal(modalComponent) {
    setModal(React.cloneElement(modalComponent));
  }

  function closeModal() {
    setModal(null);
  }

  return modal;
}
