import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import oFetch from 'o-fetch';
import BossFormCalendar from '@/components/boss-form/boss-form-calendar';
import BossFormTimeSelect from '@/components/boss-form/boss-form-time-select-simple';
import BossFormTextArea from '@/components/boss-form/boss-form-textarea';
import VenueSelectFormField from './venue-select-form-field';
import { BOSS_APP_TYPE, RotaDate } from '@/lib/rota-date';

const onSubmit = (values, dispatch, props) => {
  const jsValues = values;

  const mDate = oFetch(jsValues, 'date');
  const rotaDate = RotaDate.mFromDate({
    mCalendarDate: mDate,
    appType: BOSS_APP_TYPE,
  });

  const startsAtOffsetMinutes = oFetch(jsValues, 'startsAt');
  const endsAtOffsetMinutes = oFetch(jsValues, 'endsAt');
  const mStartsAt = rotaDate.mStartTime();
  mStartsAt.add(startsAtOffsetMinutes, 'minutes');
  const mEndsAt = rotaDate.mStartTime();
  mEndsAt.add(endsAtOffsetMinutes, 'minutes');

  return props.onFormSubmit({ ...jsValues, startsAt: mStartsAt, endsAt: mEndsAt }, dispatch).catch(resp => {
    const errors = resp.response.data.errors;
    if (errors) {
      let base = {};

      if (errors.base) {
        base = {
          _error: errors.base,
        };
      }
      throw new SubmissionError({ ...errors, ...base });
    }
    return resp;
  });
};

const renderBaseError = errors => {
  return (
    <div
      className="boss-modal-window__alert"
      style={{ width: '100%' }}
    >
      <div className="boss-alert boss-alert_role_area boss-alert_context_above">
        {errors.map((error, index) => (
          <p
            key={index.toString()}
            className="boss-alert__text"
          >
            {error}
          </p>
        ))}
      </div>
    </div>
  );
};

function SecurityShiftRequestForm(props) {
  const { error, startsAt, endsAt, venueId, venues, edit } = props;
  const appType = oFetch(props, 'appType');
  const mDate = props.date;

  return (
    <form
      onSubmit={props.handleSubmit}
      className="boss-modal-window__form boss-form"
    >
      {error && renderBaseError(error)}
      <div className="boss-form__row">
        <div className="boss-form__group boss-form__group_layout_max">
          <div className="boss-form__row boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_layout_half">
              <Field
                name="venueId"
                label="Venue"
                clearable={false}
                required
                venues={venues}
                component={VenueSelectFormField}
              />
            </div>
            <div className="boss-form__field boss-form__field_layout_half">
              <Field
                name="date"
                label="Date"
                required
                component={BossFormCalendar}
              />
            </div>
          </div>
        </div>
      </div>
      {!mDate && (
        <div className="boss-form__row">
          <h2>Please select date first.</h2>
        </div>
      )}
      {mDate && (
        <div className="boss-form__row">
          <div className="boss-form__group boss-form__group_layout_max">
            <div className="boss-form__row boss-form__row_position_last">
              <div className="boss-form__field boss-form__field_layout_half">
                <Field
                  name="startsAt"
                  label="Starts At"
                  dDate={mDate}
                  interval={30}
                  appType={appType}
                  required
                  disabled={!mDate}
                  component={BossFormTimeSelect}
                />
              </div>
              <div className="boss-form__field boss-form__field_layout_half">
                <Field
                  name="endsAt"
                  label="Ends At"
                  dDate={mDate}
                  interval={30}
                  appType={appType}
                  required
                  disabled={!mDate}
                  component={BossFormTimeSelect}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Field
        name="note"
        label="Note"
        component={BossFormTextArea}
      />
      <div className="boss-form__field">
        <button
          disabled={props.submitting || !mDate}
          className={`boss-button boss-form__submit ${props.buttonClass}`}
        >
          {props.buttonText}
        </button>
      </div>
    </form>
  );
}

SecurityShiftRequestForm.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
};

SecurityShiftRequestForm.defaultProps = {
  buttonClass: 'boss-button_role_add',
  buttonText: 'Add New',
};

const selector = formValueSelector('security-shift-request-form');

const ConnectedSecurityShiftRequestForm = connect(state => {
  const mDate = selector(state, 'date');
  if (mDate) {
    //this value is supplied as a time at 12:00
    mDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }

  return {
    date: mDate,
    startsAt: selector(state, 'startsAt'),
    endsAt: selector(state, 'endsAt'),
  };
})(SecurityShiftRequestForm);

export default reduxForm({
  fields: ['assignedRotaShift'],
  onSubmit: onSubmit,
  form: 'security-shift-request-form',
})(ConnectedSecurityShiftRequestForm);
