import React from 'react';
import AsyncButton from 'react-async-button';
import oFetch from 'o-fetch';

export default function ClockOutButton(props) {
  const onClockOut = oFetch(props, 'onClockOut');

  return (
    <div className="boss-hrc__action">
      <AsyncButton
        className="boss-button boss-button_type_small boss-button_role_exclamation boss-hrc__action-btn"
        text="Clock Out"
        pendingText="Clocking Out ..."
        onClick={onClockOut}
      />
      <p className="boss-hrc__action-text">Clock out to edit hours.</p>
    </div>
  );
}
