import React from 'react';
import oFetch from 'o-fetch';
import {
  ContentWrapper,
  DevicesDashboard,
  DevicesList,
  DeviceItem,
  PageFilter,
  VenueCardList,
  VenueCard,
} from './components';
import { useDevicesModals } from './devices-modals';
import { useDevicesPage } from './use-devices-page';

export function Devices(props) {
  const currentPage = oFetch(props, 'currentPage');
  const { openManuallyAddModal, openUpdateDeviceNameModal, openChangeVenueModal } = useDevicesModals();
  const {
    devices,
    filteredDevices,
    acceptPermissionRequest,
    disablePermission,
    updateCustomName,
    rejectPermissionRequest,
    addManualPermission,
    changePermissionRequestVenue,
    venueOptions,
    existingPermissionsVenueOptions,
    ban,
    unban,
    filter,
    filtered,
    onFilterDeviceNameChange,
    onFilterVenuesChange,
  } = useDevicesPage(props);

  const currentPageIsActivePage = currentPage === 'active';
  const currentPageIsInactivePage = currentPage === 'inactive';
  const currentPageIsBannedPage = currentPage === 'banned';

  return (
    <>
      <DevicesDashboard devicesCount={devices.length} currentPage={currentPage} />
      <ContentWrapper>
        {currentPageIsActivePage && (
          <PageFilter
            filter={filter}
            venues={existingPermissionsVenueOptions}
            onFilterDeviceNameChange={onFilterDeviceNameChange}
            onFilterVenuesChange={onFilterVenuesChange}
          />
        )}
        {currentPageIsActivePage && (
          <VenueCardList
            devices={filteredDevices}
            filtered={filtered}
            venueCardItemRender={params => (
              <VenueCard venueName={oFetch(params, 'venueName')} devices={oFetch(params, 'venueDevices')}>
                {venueDevices => {
                  return (
                    <DevicesList
                      devices={venueDevices}
                      filtered={filtered}
                      renderDeviceItem={venueDevice => (
                        <DeviceItem
                          device={venueDevice}
                          onAcceptPermissionRequest={acceptPermissionRequest}
                          onRejectPermissionRequest={rejectPermissionRequest}
                          onDisablePermission={disablePermission}
                          onBan={ban}
                          onUnban={unban}
                          onChangeVenue={() => {
                            openChangeVenueModal({
                              device: venueDevice,
                              venueOptions,
                              handleSubmit: changePermissionRequestVenue,
                            });
                          }}
                          onManuallyAdd={() =>
                            openManuallyAddModal({
                              device: venueDevice,
                              venueOptions,
                              handleSubmit: addManualPermission,
                            })
                          }
                          onCustomNameUpdateClick={() =>
                            openUpdateDeviceNameModal({ device: venueDevice, handleSubmit: updateCustomName })
                          }
                        />
                      )}
                    />
                  );
                }}
              </VenueCard>
            )}
          />
        )}
        {(currentPageIsBannedPage || currentPageIsInactivePage) && (
          <DevicesList
            devices={devices}
            filtered={filtered}
            renderDeviceItem={device => (
              <DeviceItem
                device={device}
                onAcceptPermissionRequest={acceptPermissionRequest}
                onRejectPermissionRequest={rejectPermissionRequest}
                onDisablePermission={disablePermission}
                onBan={ban}
                onUnban={unban}
                onChangeVenue={() => {
                  openChangeVenueModal({
                    device: device,
                    venueOptions,
                    handleSubmit: changePermissionRequestVenue,
                  });
                }}
                onManuallyAdd={() =>
                  openManuallyAddModal({
                    device: device,
                    venueOptions,
                    handleSubmit: addManualPermission,
                  })
                }
                onCustomNameUpdateClick={() =>
                  openUpdateDeviceNameModal({ device: device, handleSubmit: updateCustomName })
                }
              />
            )}
          />
        )}
      </ContentWrapper>
    </>
  );
}
