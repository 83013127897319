import { bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';
import { apiRoutes } from '@/lib/legacy-routes';

export const brStaffMemberGeneralFieldsValidation = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const staffMemberGeneralFieldsValidationRoute = apiRoutes.staffMemberGeneralFieldsValidation;

  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(staffMemberGeneralFieldsValidationRoute.getPath(), {
    ...values,
    withMossStaffMemberLink: false,
  });
};

export const brCreateStaffMembers = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const createStaffMemberRoute = apiRoutes.createStaffMember;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(createStaffMemberRoute.getPath(), values);
};

export const brGetAccessories = params => {
  const values = oFetch(params, 'values');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');
  const venueAccessoriesRoute = apiRoutes.venueAccessories;
  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).get(venueAccessoriesRoute.getPath(), { params: { ...values } });
};
