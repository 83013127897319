import utils from '@/lib/utils';
const APP_PREFIX = 'HANDOVER_PLANNERS';

export const LOAD_INITIAL_DATA = utils.createType(APP_PREFIX, 'LOAD_INITIAL_DATA');
export const ADD_TASK = utils.createType(APP_PREFIX, 'ADD_TASK');
export const ADD_PARENT_TASK = utils.createType(APP_PREFIX, 'ADD_PARENT_TASK');
export const DELETE_TASK = utils.createType(APP_PREFIX, 'DELETE_TASK');
export const UPDATE_TASK = utils.createType(APP_PREFIX, 'UPDATE_TASK');
export const ADD_EVENT = utils.createType(APP_PREFIX, 'ADD_EVENT');
export const DELETE_EVENT = utils.createType(APP_PREFIX, 'DELETE_EVENT');
export const UPDATE_EVENT = utils.createType(APP_PREFIX, 'UPDATE_EVENT');
export const ADD_REMINDER = utils.createType(APP_PREFIX, 'ADD_REMINDER');
export const DELETE_REMINDER = utils.createType(APP_PREFIX, 'DELETE_REMINDER');
export const UPDATE_REMINDER = utils.createType(APP_PREFIX, 'UPDATE_REMINDER');
export const SORT = utils.createType(APP_PREFIX, 'SORT');
