import React, { useMemo } from 'react';
import { Field, Fields, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import {
  BossFormInput,
  BossFormSelect,
  BossFormSelectNew,
  BossFormAvatarWithFacial,
} from '@/components/boss-form';
import { StaffMemberFullValue, StaffMemberFullOption } from '@/components/boss-form/colored-select';
import oFetch from 'o-fetch';

const validate = values => {
  const errors = {};
  if (values.linkStaffMemberId === null && !values.validatedAvatarGUID) {
    errors.validatedAvatarGUID = ['You must provide avatar'];
  }
  return errors;
};

const AddMossStaffMemberForm = ({
  mossPayRates,
  venues,
  mossStaffTypes,
  handleSubmit,
  submitting,
  validateAvatarForCreateUrl,
  selectedStaffMemberId,
  linkableStaffMemberOptions,
}) => {
  const selectedLinkableStaffMemberOption = useMemo(() => {
    if (!selectedStaffMemberId) {
      return null;
    }
    return linkableStaffMemberOptions.find(linkableStaffMember => {
      return oFetch(linkableStaffMember, 'id') === selectedStaffMemberId;
    });
  }, [selectedStaffMemberId]);

  const canLinkStaffMember = oFetch(linkableStaffMemberOptions, 'length') > 0;
  // should be false for null and undefined
  const hasSelectedLinkStaffMemberOption = selectedLinkableStaffMemberOption != null;

  return (
    <form className="boss-form" onSubmit={handleSubmit}>
      <div className="boss-board boss-board_context_stack boss-board_role_panel">
        <div className="boss-board__header">
          <h2 className="boss-board__title">Basic Information</h2>
        </div>
        <div className="boss-board__main">
          <div className="boss-board__manager">
            {canLinkStaffMember && (
              <div className="boss-form__field boss-form__select_role_staff-member">
                <Field
                  label={'Link Staff Member (Optional)'}
                  name={'linkStaffMemberId'}
                  valueKey="id"
                  labelKey="fullName"
                  options={linkableStaffMemberOptions}
                  valueComponent={StaffMemberFullValue}
                  optionComponent={StaffMemberFullOption}
                  clearable
                  component={BossFormSelectNew}
                  placeholder="Create from existing Boss Staff Member ..."
                />
              </div>
            )}
            {!hasSelectedLinkStaffMemberOption && (
              <Field
                component={BossFormInput}
                type="text"
                name="firstName"
                label="First Name"
                required={true}
                autocomplete={false}
                disabled={submitting}
                normalize={value => (value.trim() === '' ? null : value.trim())}
              />
            )}
            {hasSelectedLinkStaffMemberOption && (
              <div>
                <label>First Name</label>
                <p>{`${oFetch(
                  selectedLinkableStaffMemberOption,
                  'firstName',
                )} (taken from linked Staff Member)`}</p>
              </div>
            )}
            {!hasSelectedLinkStaffMemberOption && (
              <Field
                component={BossFormInput}
                type="text"
                name="surname"
                label="Surname"
                required={true}
                autocomplete={false}
                disabled={submitting}
                normalize={value => (value.trim() === '' ? null : value.trim())}
              />
            )}
            {hasSelectedLinkStaffMemberOption && (
              <div>
                <label>Surname</label>
                <p>{`${oFetch(selectedLinkableStaffMemberOption, 'surname')} (from linked Staff Member)`}</p>
              </div>
            )}
            <Field
              component={BossFormSelect}
              name="mossStaffTypeId"
              required
              label="Staff Type"
              disabled={submitting}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Staff Type ..."
              options={mossStaffTypes}
            />
            <Field
              component={BossFormSelect}
              name="masterVenueId"
              required
              label="Main Venue"
              disabled={submitting}
              optionLabel="name"
              optionValue="id"
              placeholder="Select venue ..."
              options={venues}
            />
            <Field
              component={BossFormSelect}
              name="mossPayRateId"
              required
              label="Pay Rate"
              disabled={submitting}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Pay Rate ..."
              options={mossPayRates}
            />
            {!hasSelectedLinkStaffMemberOption && (
              <Field component={BossFormInput} name="pinCode" required label="Pin Code" />
            )}
            {hasSelectedLinkStaffMemberOption && (
              <div>
                <label>Pin Code</label>
                <p>from Linked Staff Member</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="boss-board boss-board_context_stack boss-board_role_panel">
        <div className="boss-board__header">
          <h2 className="boss-board__title">Photo</h2>
        </div>
        <div className="boss-board__main">
          <div className="boss-board__manager">
            {!selectedLinkableStaffMemberOption && (
              <Fields
                names={['validatedAvatarGUID', 'avatar', 'setupForFacialRecognition']}
                markedRetakeAvatar={false}
                disableUpload={false}
                validateAvatarForCreateUrl={validateAvatarForCreateUrl}
                component={BossFormAvatarWithFacial}
              />
            )}
            {selectedLinkableStaffMemberOption && (
              <div>
                <p>Taken from Linked Staff Member</p>
                <img src={oFetch(selectedLinkableStaffMemberOption, 'imageUrl')} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="boss-form__field boss-form__field_justify_center">
        <button disabled={submitting} type="submit" className="boss-button boss-button_role_submit">
          Submit
        </button>
      </div>
    </form>
  );
};

const wrappedAddMossStaffMemberForm = reduxForm({
  form: 'add-moss-staff-member-form',
  validate,
})(AddMossStaffMemberForm);

const mapStateToProps = state => {
  const form = state.form['add-moss-staff-member-form'];
  const values = form && oFetch(form, 'values');
  const selectedStaffMemberId = values ? oFetch(values, 'linkStaffMemberId') : null;

  return {
    selectedStaffMemberId,
  };
};

export default connect(mapStateToProps)(wrappedAddMossStaffMemberForm);
