import _ from 'underscore';
import { RotaDate } from '@/lib/rota-date';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { getHoursBetween } from '../../../lib/rota-date/minutes-to-date';
import oFetch from 'o-fetch';

const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;

/**
 * Many venues ony operate at certain times, so we detect the times where there are
 * shifts and only show those.
 * @return {object} Object with "start" and "end" values that can be passed into the rota chart.
 */
export default function calculateChartBoundaries(args) {
  const rotaShifts = oFetch(args, 'rotaShifts');
  const appType = oFetch(args, 'appType');
  if (!_.isArray(rotaShifts)) {
    throw new Error('calculateChartBoundaries needs array');
  }

  // Values indicating how many hours we're into the day
  var startOffset = 23; // means 7am based on an 8am start
  var endOffset = 1; // means 9am based on an 8am start

  var rotaDate;
  if (_.isEmpty(rotaShifts)) {
    startOffset = 0;
    endOffset = 24;
    rotaDate = RotaDate.fromTime({
      dTime: new Date(),
      appType,
    });
  } else {
    rotaDate = RotaDate.fromTime({
      dTime: safeMoment.iso8601Parse(rotaShifts[0].startsAt).toDate(),
      appType,
    });

    // Adjust offset range everytime we find a shift that's not contained inside it
    rotaShifts.forEach(function(rotaShift) {
      var shiftStartOffset = getHoursBetween({
        dStartTime: rotaDate.startTime(),
        dEndTime: safeMoment.iso8601Parse(
          oFetch(rotaShift, 'startsAt')
        ).toDate(),
      });

      var shiftEndOffset = getHoursBetween({
        dStartTime: rotaDate.startTime(),
        dEndTime: safeMoment.iso8601Parse(
          oFetch(rotaShift, 'endsAt')
        ).toDate(),
      });

      if (shiftStartOffset < startOffset) {
        startOffset = Math.floor(shiftStartOffset);
      }
      if (shiftEndOffset > endOffset) {
        endOffset = Math.ceil(shiftEndOffset);
      }
    });

    startOffset -= 1;
    startOffset = utils.containNumberWithinRange(startOffset, [0, 24]);
    endOffset += 1;
    endOffset = utils.containNumberWithinRange(endOffset, [0, 24]);
  }

  var boundaries = {
    start: new Date(
      rotaDate.startTime().valueOf() + startOffset * MILLISECONDS_PER_HOUR,
    ),
    end: new Date(
      rotaDate.startTime().valueOf() + endOffset * MILLISECONDS_PER_HOUR,
    ),
  };
  return boundaries;
}