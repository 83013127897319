import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import {
  BossFormTextarea,
  BossFormInput,
} from '@/components/boss-form';
import {
  BossFormTrixRteField
} from '@/components/boss-form/boss-form-trix-rte-field';

export function MarketingTasksPageOptionsForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');

  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <div className="boss-form__field">
              {submitError && (
                <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                  <p className="boss-alert__text">{submitError}</p>
                </div>
              )}
              <div className="boss-form__field">
                <Field name='taskStaticAdviceText' required>
                  {props => (
                    <BossFormTrixRteField
                      name={oFetch(props, 'input.name')}
                      onChange={oFetch(props, 'input.onChange')}
                      defaultValue={oFetch(props, 'input.value')}
                      meta={oFetch(props, 'meta')}
                      label="Static Advice - formatted ÆVA like advice displayed at the top of every marketing task form."
                    />
                  )}
                </Field>
              </div>
              <Field
                name='adviceWordCountThreshold'
                required
                type='number'
                parse={value => value}
                component={BossFormInput}
                label='Advice Word Count Threshold - Number of words in description above which users can ask ÆVA'
              />
              <Field
                name="aevaSystemPromptTemplate"
                parse={value => value}
                component={BossFormTextarea}
                label="ÆVA System Prompt - Text explaining ÆVA's role used to answer the user prompt"
              />
              <Field
                name="aevaUserPromptTemplate"
                parse={value => value}
                component={BossFormTextarea}
                label="ÆVA User Prompt - Text used to generate ÆVA's output"
              />
              <button
                onClick={handleSubmit}
                disabled={submitting}
                className="boss-button boss-button_role_confirm boss-form__submit"
                type="button"
              >
                Update
              </button>
              <div style={{ marginTop: "20px"}}>
                <h4>Variables</h4>
                <p>These will be replaced in the prompts to allow ÆVA to get access to the form information being reviewed</p>
                <dl>
                  <dt>{"{{title}}"}</dt>
                  <dd>Supplied title of the task (e.g. "Create new cocktail menus") </dd>
                  <dt>{"{{venue}}"}</dt>
                  <dd>Full venue name task is for (e.g. "Blind Tiger")</dd>
                  <dt>{"{{deadline}}"}</dt>
                  <dd>Text version of task deadline date (e.g. ???)</dd>
                  <dt>{"{{text}}"}</dt>
                  <dd>Unstyled \ Text only version of task description (note: includes whitespace and line breaks)</dd>
                  <dt>{"{{html}}"}</dt>
                  <dd>Html version (text with tags for styling) of task description (note: tags may cause issues and we may have to word the prompt carefully to have ÆVA do the right thing)</dd>
                  <dt>{"{{attachment_info}}"}</dt>
                  <dd>
                    <p>Text descrioption of attached files. Including name, file type and size in bytes</p>
                    <p/>
                    <p>If no file exists will output something like "No files have been attached"</p>
                  </dd>
                </dl>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
