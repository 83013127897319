import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

import CountingOverviewTotalsRow from './counting-overview-totals-row';
import { toFixedAndPounds } from './counting-overview-table-row';

export default function CountingOverviewTable(props) {
  const [tableItems, renderRow, totals] = oFetch(props, 'tableItems', 'renderRow', 'totals');
  const [
    five,
    ten,
    twenty,
    fifty,
    changePence,
    total,
    loosePence,
    fiveBundles,
    tenBundles,
    twentyBundles,
    fiftyBundles,
    totalBundles,
  ] = oFetch(
    totals,
    'five',
    'ten',
    'twenty',
    'fifty',
    'changePence',
    'total',
    'loosePence',
    'fiveBundles',
    'tenBundles',
    'twentyBundles',
    'fiftyBundles',
    'totalBundles',
  );
  function renderRows() {
    return tableItems.map((tableItem) => {
      const [venueId, status] = oFetch(tableItem, 'venueId', 'status');
      const statusClassName = cn('purple-table__group js-table-group', {
        'purple-table__group_highlight_accent-green-light': status === 'completed',
      });
      return React.cloneElement(renderRow(tableItem), {
        statusClassName,
        key: venueId,
      });
    });
  }

  return (
    <div className="purple-board">
      <div className="purple-board__inner">
        <div className="purple-board__group">
          <div className="purple-board__scroll purple-board__scroll_type_horizontal">
            <div className="purple-table purple-table_page_counting-overview">
              <div className="purple-table__row purple-table__row_role_header">
                <div className="purple-table__cell purple-table__cell_role_header">Venue</div>
                <div className="purple-table__cell purple-table__cell_role_header">£5</div>
                <div className="purple-table__cell purple-table__cell_role_header">£10</div>
                <div className="purple-table__cell purple-table__cell_role_header">£20 Notes</div>
                <div className="purple-table__cell purple-table__cell_role_header">£50 Notes</div>
                <div className="purple-table__cell purple-table__cell_role_header">Change</div>
                <div className="purple-table__cell purple-table__cell_role_header">Total</div>
                <div className="purple-table__cell purple-table__cell_role_header" />
              </div>
              {renderRows()}
              <CountingOverviewTotalsRow
                title="Totals"
                five={toFixedAndPounds(five)}
                ten={toFixedAndPounds(ten)}
                twenty={toFixedAndPounds(twenty)}
                fifty={toFixedAndPounds(fifty)}
                change={toFixedAndPounds(changePence / 100)}
                total={toFixedAndPounds(total)}
              />
              <CountingOverviewTotalsRow
                title="Bundles"
                five={fiveBundles}
                ten={tenBundles}
                twenty={twentyBundles}
                fifty={fiftyBundles}
                change={'-'}
                total={totalBundles}
              />
            </div>
          </div>
        </div>
        <div className="purple-board__group">
          <p className="purple-board__text purple-board__text_size_l">
            <span className="purple-board__text-bold">Loose: {toFixedAndPounds(loosePence / 100)}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
