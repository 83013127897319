import React, { useState } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { bossRequestHttp } from '@/lib/request-api';
import { ReportForm } from './report-form';
import { somethingWentWrongImageBase64 } from './something-went-wrong-image';

export function SomethingWentWrongPage(props) {
  const [errorId, timestamp] = oFetch(props, 'errorId', 'timestamp');
  const [submited, setSubmited] = useState(false);

  const currentUser = utils.safeGet(() => window.boss.rollbarData.currentUser);
  const hasCurrentUser = !!currentUser;

  function handleFormSubmit(values) {
    return bossRequestHttp({
      auth: false,
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: ['reportText', 'base'],
          });
          return errors;
        }
      },
      successHandler(params) {
        setSubmited(true);
      },
    }).post(`/api/v1/error_reports/submit`, {
      ...values,
    });
  }

  if (submited) {
    return (
      <div className="boss-error boss-error_role_modal">
        <div className="boss-error__group">
          <div className="boss-error__icon">
            <img src="/assets/illustration-warning-alert.svg" alt="Warning" className="boss-error__image" />
          </div>
          <div className="boss-error__info">
            <h1 className="boss-error__title">
              <span className="boss-error__title-code">Your request was submited</span>
            </h1>
            <p className="boss-error__text">
              The tech team have been notified and will look into this ASAP. Someone may contact you for
              information.
            </p>
            <div className="boss-error__actions">
              <button
                type="button"
                className="boss-button boss-button_role_reload-page boss-error__button"
                onClick={() => window.location.reload(true)}
              >
                Reload page
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="boss-page-main_full">
      <div className="boss-page-main__content">
        <div className="boss-page-main__inner">
          <div className="boss-error">
            <h1 className="boss-error__title">Something has gone horribly wrong</h1>
            <img
              src={somethingWentWrongImageBase64}
              alt="Something went wrong"
              className="boss-error__image"
              style={{ maxWidth: '400px', maxHeight: '400px' }}
            />
            {errorId && <p className="boss-error__text">Error ID: {errorId}</p>}
            {timestamp && <p className="boss-error__text">Timestamp: {timestamp}</p>}
            {hasCurrentUser && (
              <p className="boss-error__text">
                Hi <b>{oFetch(currentUser, 'name')}</b>
              </p>
            )}
            <p className="boss-error__text">There was an error in the code on this page.</p>
            {hasCurrentUser && (
              <>
                <ReportForm
                  initialValues={{
                    userId: oFetch(currentUser, 'id'),
                    rollbarGuid: oFetch(currentUser, 'rollbar_guid'),
                    reportText: '',
                    context: `${window.location.pathname}${window.location.search}`,
                    uuid: errorId,
                  }}
                  onSubmit={handleFormSubmit}
                />
              </>
            )}
            {!hasCurrentUser && (
              <button
                type="button"
                className="boss-button boss-button_role_reload-page boss-error__button"
                onClick={() => window.location.reload(true)}
              >
                Reload page
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
