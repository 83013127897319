import React, { useMemo } from 'react';
import { TRotaDate } from '@/lib/rota-date';
import { BaseSwipeTimePicker, BaseSwipeTimePickerValue, SwiperControlFuncs } from './base-swipe-time-picker';
import { z } from 'zod';

export const END_DAY_TIME_TYPE = 'end';
export const START_DAY_TIME_TYPE = 'start';
type DayTimeType = typeof END_DAY_TIME_TYPE | typeof START_DAY_TIME_TYPE;
const supportedMinutesSteps = [1, 5, 10, 15, 20, 30, 60] as const;

type SwipeTimePickerProps = {
  controlRef?: React.MutableRefObject<SwiperControlFuncs | null> | undefined,
  value: Date,
  rotaDate: TRotaDate,
  dayTimeType: DayTimeType,
  minutesStep: typeof supportedMinutesSteps[number],
  onChange: (value: Date) => void,
  onTabKeyDown?: (() => void) | undefined,
  onEnterKeyDown?: (() => void ) | undefined,
};

function pad(v: number): string {
  return v > 9 ? String(v) : "0" + String(v);
}

export const SwipeTimePicker = (props: SwipeTimePickerProps) => {
  const { value, rotaDate, onChange, minutesStep, controlRef, dayTimeType } = props;
  const startOfDay = rotaDate.startTime();
  const endOfDay = rotaDate.endTime();

  if (!Number.isInteger(minutesStep)) {
    throw new Error(`minutesStep must be interger: ${minutesStep}`);
  } else if (!supportedMinutesSteps.includes(minutesStep)) {
    throw new Error(`minutesStep must be supported got: ${minutesStep}`);
  }

  function getMinuteValues(args: { dTime: Date, minutesStep: typeof supportedMinutesSteps[number] }): { minutesValues: string[], startMinutesValue: string } {
    const { dTime, minutesStep } = args;

    let startMinutesValue: string | null = null;
    const minutesValues: string[] = [];

    Array.from({ length: 60 }).forEach((_, i) => {
      const validValue = i % minutesStep === 0;

      const timeMatches = dTime.getMinutes() === i;
      if (timeMatches && !validValue) {
        throw new Error(`Minute value of supplied time was not valid: ${dTime}`);
      }

      if (validValue) {
        const paddedValue = pad(i);
        minutesValues.push(paddedValue);

        if (timeMatches) {
          startMinutesValue = paddedValue;
        }
      }
    });
    if (startMinutesValue === null || startMinutesValue === undefined) {
      throw new Error('Start minutes value not found');
    }

    return {
      minutesValues,
      startMinutesValue,
    };
  }

  function getHoursValues(): { hoursValues: string[], hourTimes: Date[] } {
    let previousHourValue: string | null = null;
    let currentTime = startOfDay;
    const hoursValues: string[] = [];
    const hourTimes: Date[] = [startOfDay];

    while (currentTime < endOfDay) {
      let currentHourValue = pad(currentTime.getHours());
      if (currentHourValue === previousHourValue) {
        currentHourValue = `*${currentTime.getHours()}}`;
      }
      hoursValues.push(currentHourValue);

      previousHourValue = currentHourValue;
      currentTime = new Date(currentTime.getTime() + 60 * 60 * 1000);
      hourTimes.push(currentTime);
    }

    return {
      hoursValues,
      hourTimes,
    };
  }

  function getStartHoursValue(args: { hourTimes: Date[], hoursValues: string[], dTime: Date }): string {
    const { hourTimes, hoursValues, dTime } = args;
    let startHoursValue: string | null = null;

    let previousTime: Date | null = null;
    hourTimes.forEach((currentTime, i) => {
      if ((previousTime !== null) && (dTime.getTime() >= previousTime.getTime() && dTime.getTime() < currentTime.getTime())) {
        startHoursValue = hoursValues[i - 1] || null;
      }
      previousTime = currentTime;
    });
    if (dTime.getTime() === endOfDay.getTime()) {
      startHoursValue = hoursValues[0] || null;
    }
    if (!startHoursValue) {
      throw new Error('Start hours value not found');
    }

    return startHoursValue;
  }

  const dParsedValue = useMemo(() => {
    const dParsedVal  = z.date().parse(value);
    if (dParsedVal.getSeconds() !== 0 || dParsedVal.getMilliseconds() !== 0) {
      throw new Error(`Invalid date supplied must be minute: ${dParsedVal}`);
    }
    return dParsedVal;
  }, [value]);

  const { hoursValues, hourTimes } = useMemo(() => {
    return getHoursValues();
  }, [rotaDate.calendarDate()]);

  const hoursValue = useMemo(() => {
    return getStartHoursValue({ hourTimes, hoursValues, dTime: dParsedValue });
  }, [dParsedValue]);

  const { minutesValues, startMinutesValue: minutesValue } = useMemo(() => {
    return getMinuteValues({ minutesStep, dTime: dParsedValue });
  }, [minutesStep, dParsedValue]);

  const getOutputValueFromBaseValue = function<THoursValues extends typeof hoursValue, TMinutesValues extends typeof minutesValue>(value: BaseSwipeTimePickerValue<THoursValues[number], TMinutesValues[number]>): Date {
    const { hourValue, minuteValue } = value;

    const minuteValuesIndex = minutesValues.findIndex((v) => v === minuteValue);
    if (minuteValuesIndex === -1) {
      throw new Error(`Invalid minute value supplied: ${minuteValue}`);
    }

    let hourValueIndex = hoursValues.findIndex((v) => v === hourValue);
    if (hourValueIndex === -1) {
      throw new Error(`Invalid hour value supplied: ${hourValue}`);
    } else if (dayTimeType === END_DAY_TIME_TYPE && hourValueIndex === 0 && minuteValuesIndex === 0) {
      hourValueIndex = 24;
    }

    return new Date(startOfDay.getTime() + (hourValueIndex * 60 * 60 * 1000) + (minuteValuesIndex * 60 * 1000));
  };

  return (
    <BaseSwipeTimePicker
      controlRef={controlRef}
      value={{
        hourValue: hoursValue,
        minuteValue: minutesValue,
      }}
      hourValues={hoursValues}
      minuteValues={minutesValues}
      onChange={function (value): void {
        onChange(getOutputValueFromBaseValue(value));
      }}
      onEnterKeyDown={props.onEnterKeyDown}
      onTabKeyDown={props.onTabKeyDown}
    />
  );
};