import React, { useCallback } from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';

import { useModal } from '@/components/hooks-components/modals';
import utils from '@/lib/utils';
import { WarningModal, SimpleModal, AreYouSureModalContent, CustomContentModal } from '../components/modals';
import EditIndicator from '../components/edit-indicator';

import {
  CountingDashboard,
  WeekDays,
  DayCounting,
  CountingHistoryDashboard,
  CountingHistory,
  CountingHistoryItem,
  CountingHistoryModal,
  TabItem,
  WeekCount,
} from './components';
import * as selectors from './redux/selectors';
import * as actions from './redux/actions';

function Counting(props, context) {
  const [
    weekStart,
    weekEnd,
    selectedTab,
    normalizedDateCount,
    weekRangeByDate,
    editMode,
    editAbility,
    showHistory,
    tabsStatuses,
    totalWeekCounts,
    weekDaysCompleted,
    currentVenueName,
  ] = oFetch(
    props,
    'weekStart',
    'weekEnd',
    'selectedTab',
    'normalizedDateCount',
    'weekRangeByDate',
    'editMode',
    'editAbility',
    'showHistory',
    'tabsStatuses',
    'totalWeekCounts',
    'weekDaysCompleted',
    'currentVenueName',
  );

  const [
    setFilter,
    createDayCount,
    toggleEditMode,
    toggleHistory,
    createWeekCount,
    updateDayCount,
    updateWeekCount,
    editModeOff,
  ] = oFetch(
    props,
    'setFilter',
    'createDayCount',
    'toggleEditMode',
    'toggleHistory',
    'createWeekCount',
    'updateDayCount',
    'updateWeekCount',
    'editModeOff',
  );
  const selectedTabCount = getSelectedTabCount();
  const weekCount = normalizedDateCount.all;
  const dayCount = getDayCount();
  const { openModal } = useModal();

  function getSelectedTabCount() {
    return normalizedDateCount[selectedTab];
  }

  function getDayCount() {
    if (selectedTab === 'all') {
      return null;
    }
    return normalizedDateCount[selectedTab];
  }

  function handleTabClick(tab) {
    setFilter({ selectedTab: tab });
    editModeOff();
  }

  function renderTab(tab) {
    if (tab === 'all') {
      const weekCount = normalizedDateCount.all;
      const isDayExist = weekCount !== null;
      const tabStatus = tabsStatuses.all;
      const invalid = oFetch(tabStatus, 'invalid');
      const pristine = oFetch(tabStatus, 'pristine');
      const active = selectedTab === 'all';

      return (
        <TabItem
          onClick={() => handleTabClick('all')}
          active={active}
          invalid={invalid}
          label={'All'}
          pristine={pristine}
          persist={isDayExist}
        />
      );
    }
    const formattedDayOfWeek = tab.format(utils.monthDateFormat);
    const uiDay = tab.format(utils.commonDateFormat);
    const dayCount = normalizedDateCount[uiDay];
    const isDayExist = dayCount !== null;
    const tabStatus = tabsStatuses[uiDay];
    const invalid = oFetch(tabStatus, 'invalid');
    const pristine = oFetch(tabStatus, 'pristine');
    const active = uiDay === selectedTab;

    return (
      <TabItem
        onClick={() => handleTabClick(uiDay)}
        active={active}
        invalid={invalid}
        label={formattedDayOfWeek}
        pristine={pristine}
        persist={isDayExist}
      />
    );
  }

  function renderActions() {
    if (!editAbility || selectedTabCount === null) {
      return null;
    }
    const showEditButton =
      selectedTab !== 'all' ? !editMode : !editMode && oFetch(tabsStatuses.all, 'invalid');

    return (
      <div className="purple-page-main__info-group-area">
        <div className="purple-page-main__info-group-actions">
          {showEditButton && (
            <button
              onClick={toggleEditMode}
              className="purple-button purple-button_color_accent-orange purple-button_icon_pencil purple-button_size_m purple-page-main__info-group-action"
            >
              <span className="purple-button__text">Edit</span>
            </button>
          )}
          {editMode && (
            <button
              onClick={toggleEditMode}
              className="purple-button purple-button_color_accent-red purple-button_icon_close purple-button_size_m purple-page-main__info-group-action"
            >
              <span className="purple-button__text">Cancel</span>
            </button>
          )}
          <button
            onClick={toggleHistory}
            className="purple-button purple-button_color_accent-primary purple-button_icon_text-paper purple-button_size_m purple-page-main__info-group-action"
          >
            <span className="purple-button__text">History</span>
          </button>
        </div>
      </div>
    );
  }

  function renderHistoryDayCountItem(history) {
    return <CountingHistoryItem onDetails={openHistoryModal} history={history} />;
  }

  function renderHistoryTitle(history) {
    const [formattedHistoryModalEventType, eventBy, formattedEventAt] = oFetch(
      history,
      'formattedHistoryModalEventType',
      'eventBy',
      'formattedEventAt',
    );

    return (
      <h2 className="purple-modal__title">
        {formattedHistoryModalEventType} by <span className="purple-modal__title-bold">{eventBy}</span> on{' '}
        <span className="purple-modal__title-bold">{formattedEventAt}</span>
      </h2>
    );
  }

  function openHistoryModal(history) {
    const [normalizedChanges, eventType] = oFetch(history, 'normalizedChanges', 'eventType');
    openModal({
      onSubmit: () => {},
      config: {
        baseClassName: 'purple-modal purple-modal_size_m-minor purple-modal_type_full-height',
        title: () => renderHistoryTitle(history),
      },
      props: {
        normalizedChanges,
        eventType,
      },
      ModalComponent: SimpleModal,
      ModalContent: CountingHistoryModal,
    });
  }

  function dayCountSubmitAction(values, closeModal) {
    if (dayCount === null) {
      return createDayCount(values);
    }
    return updateDayCount(values);
  }

  function weekCountSubmitAction(values) {
    if (weekCount === null) {
      return createWeekCount(values);
    }
    return updateWeekCount(values);
  }

  function openValuesEmptyWarningModal(values) {
    return openModal({
      onSubmit: closeModal => {
        closeModal();
        return dayCountSubmitAction(values);
      },
      config: {
        baseClassName: 'purple-modal_size_xs purple-modal_space_l',
      },
      ModalComponent: CustomContentModal,
      ModalContent: AreYouSureModalContent,
    });
  }

  function handleDayCountSubmit(values, dispatch, props) {
    const isValuesEmpty = oFetch(props, 'isValuesEmpty');
    if (isValuesEmpty) {
      return openValuesEmptyWarningModal(values);
    }
    return dayCountSubmitAction(values);
  }

  function renderTabContent() {
    if (selectedTab === 'all') {
      return (
        <WeekCount
          key={selectedTab}
          weekCount={weekCount}
          weekStart={weekStart}
          weekDaysCompleted={weekDaysCompleted}
          totalWeekCounts={totalWeekCounts}
          editMode={editMode}
          onSubmit={weekCountSubmitAction}
        />
      );
    }
    return (
      <DayCounting
        key={selectedTab}
        selectedTab={selectedTab}
        dayCount={selectedTabCount}
        editMode={editMode}
        onSubmit={handleDayCountSubmit}
      />
    );
  }

  return (
    <main className="purple-page-main purple-page-main_page_counting">
      {editMode && <EditIndicator />}
      {showHistory && (
        <CountingHistoryDashboard
          weekCount={weekCount}
          onBack={toggleHistory}
          weekStart={weekStart}
          weekEnd={weekEnd}
          currentVenueName={currentVenueName}
        />
      )}
      {showHistory && (
        <div className="purple-page-main__content">
          <div className="purple-page-main__inner">
            <CountingHistory dayCount={selectedTabCount} renderItem={renderHistoryDayCountItem} />
          </div>
        </div>
      )}
      {!showHistory && (
        <CountingDashboard
          weekCount={weekCount}
          weekStart={weekStart}
          renderActions={renderActions}
          weekEnd={weekEnd}
          title={`Counting - ${currentVenueName}`}
        />
      )}
      {!showHistory && (
        <div className="purple-page-main__dashboard purple-page-main__dashboard_role_secondary">
          <div className="purple-page-main__inner">
            <WeekDays weekRange={weekRangeByDate} renderTab={renderTab} />
          </div>
        </div>
      )}
      {!showHistory && (
        <div className="purple-page-main__content">
          <div className="purple-page-main__inner">
            <div className="purple-board">
              <div className="purple-board__inner">{renderTabContent()}</div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

const mapStateToProps = state => {
  return {
    selectedTab: selectors.getSelectedTab(state),
    normalizedDateCount: selectors.getNormalizedDateCountWithAll(state),
    weekRangeByDate: selectors.getWeekRangeSelector(state),
    weekRangeByUIDate: selectors.getWeekRangeByUIDateSelector(state),
    editMode: selectors.editModeSelectors(state),
    editAbility: selectors.editAbilitySelector(state),
    showHistory: selectors.showHistorySelectors(state),
    tabsStatuses: selectors.getTabsStatuses(state),
    totalWeekCounts: selectors.getTotalWeekCounts(state),
    weekDaysCompleted: selectors.weekDaysCompleted(state),
    currentVenueName: selectors.currentVenueNameSelector(state),
  };
};

const mapDispatchToProps = {
  setFilter: actions.setFilter,
  createDayCount: actions.createDayCount,
  updateDayCount: actions.updateDayCount,
  createWeekCount: actions.createWeekCount,
  updateWeekCount: actions.updateWeekCount,
  toggleEditMode: actions.toggleEditMode,
  toggleHistory: actions.toggleHistory,
  editModeOff: actions.editModeOff,
};

export default connect(mapStateToProps, mapDispatchToProps)(Counting);
