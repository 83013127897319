import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import cn from 'classnames';

import { BossFormInput, BossFormTextarea } from '@/components/boss-form';
import oFetch from 'o-fetch';

class AlertForm extends Component {
  handleSubmit = values => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    return onSubmit(values).catch(error => {
      if (error.response && error.response.data) {
        const errors = error.response.data.errors;
        if (errors) {
          let base = {};

          if (errors.base) {
            base = {
              _error: errors.base,
            };
          }
          throw new SubmissionError({ ...errors, ...base });
        }
      }
      throw error;
    });
  };

  render() {
    const [submitButtonText, submitting] = oFetch(this.props, 'submitButtonText', 'submitting');
    const { buttonClassName } = this.props;
    const buttonClass = cn('boss-button boss-form__submit', buttonClassName);
    return (
      <form className="boss-modal-window__form boss-form" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Field label="Title" name="title" component={BossFormInput} />
        <Field label="Message" name="message" component={BossFormTextarea} />
        <div className="boss-form__field">
          <button disabled={submitting} className={buttonClass} type="submit">
            {submitting ? 'Processing ...' : submitButtonText}
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'alert-form',
})(AlertForm);
