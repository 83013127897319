import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import humanize from 'string-humanize';

export function DuplicateStaffMember(props) {
  const staffMember = oFetch(props, 'staffMember');
  const [
    id,
    fullName,
    avatarUrl,
    profileUrl,
    disableReason,
    disabled,
    staffTypeName,
    staffTypeColor,
  ] = oFetch(
    staffMember,
    'id',
    'fullName',
    'avatarUrl',
    'profileUrl',
    'disableReason',
    'disabled',
    'staffTypeName',
    'staffTypeColor',
  );
  const itemClassNames = cn('boss-user-summary boss-user-summary_role_review', {
    'boss-user-summary_type_enabled': !disabled,
    'boss-user-summary_type_disabled': disabled,
  });

  return (
    <div className="boss-vetting__person" style={{ width: '100%' }}>
      <div className={itemClassNames}>
        <div className="boss-user-summary__side">
          <div className="boss-user-summary__avatar">
            <div className="boss-user-summary__avatar-inner">
              <img src={avatarUrl} alt={fullName} className="boss-user-summary__pic" />
            </div>
            <a
              href={profileUrl}
              target="_blank"
              rel="noreferrer"
              className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_info"
            >
              Profile
            </a>
          </div>
        </div>
        <div className="boss-user-summary__content">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{fullName}</h2>
            <p
              className="boss-button boss-button_type_ultra-small boss-user-summary__label"
              style={{ backgroundColor: staffTypeColor }}
            >
              {staffTypeName}
            </p>
          </div>
          <ul className="boss-user-summary__review-list">
            {disableReason && (
              <li className="boss-user-summary__review-item">
                <span className="boss-user-summary__review-label">Disable Reason: </span>
                <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                  {disableReason}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
