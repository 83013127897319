import React from 'react';
import oFetch from 'o-fetch';

export function ReturnAccessoryModalContent(props) {
  return (
    <div className="boss-modal-window__message-block">
      <div className="boss-modal-window__message-group">
        <div className="boss-modal-window__message-text">What state is the returned accessory in?</div>
      </div>
      <div className="boss-modal-window__message-group">
        <div className="boss-modal-window__form">
          <div className="boss-form">
            <div className="boss-form__row">
              <div className="boss-form__field boss-form__field_layout_half">
                <button
                  onClick={() => oFetch(props, 'onSubmit')({ reusable: true })}
                  className="boss-button boss-button_type_panel boss-button_color_accent-red"
                >
                  <span className="boss-button__number">+1</span>
                  <span className="boss-button__text">Item is Reusable</span>
                </button>
              </div>
              <div className="boss-form__field boss-form__field_layout_half">
                <button
                  onClick={() => oFetch(props, 'onSubmit')({ reusable: false })}
                  className="boss-button boss-button_type_panel boss-button_color_accent-red"
                >
                  <span className="boss-button__number" />
                  <span className="boss-button__text">Item is Not Reusable</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
