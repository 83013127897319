import React, { useMemo } from 'react';
import Select, { Async as SelectAsync } from '@/lib/boss-react-select';
import oFetch from 'o-fetch';
import { SelectTimeInputRenderer } from '@/components/boss-form/time-input-render';
import { TimeSelectOption, TimeSelectValue } from './colored-select';
import safeMoment from '@/lib/safe-moment';

export function BossFormShiftTimeInput(props: any) {
  const options = oFetch(props, 'options');
  const startsAt = oFetch(props, oFetch(props, 'startsAtFieldName'));
  const endsAt = oFetch(props, oFetch(props, 'endsAtFieldName'));
  const timeStartsAt = startsAt.input.value;
  const timeEndsAt = endsAt.input.value;

  function getStartsAtOptions(_input: any, callback: any) {
    callback(null, {
      options: options,
      complete: true,
    });
  }

  const endsAtOptions = useMemo(() => {
    if (timeStartsAt) {
      return options.filter((option: any) => {
        return safeMoment.iso8601Parse(option.value).isAfter(safeMoment.iso8601Parse(timeStartsAt));
      });
    }
    return options;
  }, [timeStartsAt]);

  function updateStartsTime(newValue: string) {
    if (!newValue) {
      return;
    }
    startsAt.input.onChange(newValue);
  }

  function updateEndsTime(newValue: string) {
    if (!newValue) {
      return;
    }
    endsAt.input.onChange(newValue);
  }

  return (
    <div className={`boss-form__field ${props.className}`}>
      <div className="boss-form__interval">
        <div className="boss-form__interval-item">
          <p className="boss-form__label">
            <span className="boss-form__label-text">Start</span>
          </p>
          <div
            className={`boss-form__select boss-form__select_role_time ${startsAt.meta.touched && startsAt.meta.error && 'boss-form__select_state_error'
              }`}
          >
            <SelectAsync
              value={timeStartsAt}
              loadOptions={getStartsAtOptions}
              inputRenderer={SelectTimeInputRenderer}
              simpleValue
              clearable={false}
              searchable={true}
              matchPos="start"
              onChange={updateStartsTime}
              valueComponent={TimeSelectValue}
              optionComponent={TimeSelectOption}
            />
          </div>
          {startsAt.meta.touched && startsAt.meta.error && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                <span className="boss-form__error-line">{startsAt.meta.error}</span>
              </p>
            </div>
          )}
        </div>
        <div className="boss-form__interval-delimiter" />
        <div className="boss-form__interval-item">
          <p className="boss-form__label">
            <span className="boss-form__label-text">End</span>
          </p>
          <div
            className={`boss-form__select boss-form__select_role_time ${endsAt.meta.touched && endsAt.meta.error && 'boss-form__select_state_error'
              }`}
          >
            <Select
              value={timeEndsAt}
              options={endsAtOptions}
              inputRenderer={SelectTimeInputRenderer}
              valueComponent={TimeSelectValue}
              optionComponent={TimeSelectOption}
              simpleValue
              matchPos="start"
              clearable={false}
              searchable={true}
              onChange={updateEndsTime}
            />
          </div>
          {endsAt.meta.touched && endsAt.meta.error && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                <span className="boss-form__error-line">{endsAt.meta.error}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BossFormShiftTimeInput.defaultProps = {
  granularityInMinutes: 30,
  className: '',
  startsAtFieldName: 'starts_at',
  endsAtFieldName: 'ends_at',
};
