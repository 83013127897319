import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export const updateRatingAction = params => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['rating', 'note'],
        });
        return errors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      onSuccess(data);
    },
  }).post(`/api/v1/kpis/update_rating`, {
    ...values,
  });
};
