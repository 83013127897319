import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import {
  processHolidayAppViewData,
  processHolidayObjectV2,
  processStaffMemberObject,
} from '@/lib/backend-data/process-backend-objects';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';
import { extendMoment } from 'moment-range';

import { INITIAL_LOAD, ADD_HOLIDAY, ADD_HOLIDAY_STAFF_MEMBER, CALCULATE_HOLIDAYS, CALCULATE_STAFF_MEMBERS } from './constants';

const initialState = {
  effectiveStaffTypes: {},
  effectiveStaffMembers: {},
  venues: {},
  pageOptions: {},
  holidaysCount: {},
  effectiveStaffMemberCount: null,
};

export default handleActions(
  {
    [INITIAL_LOAD]: (state, action) => {
      const data = processHolidayAppViewData(action.payload);
      const effectiveStaffTypes = oFetch(data, 'effectiveStaffTypes');
      const effectiveStaffMembers = oFetch(data, 'effectiveStaffMembers');
      const venues = oFetch(data, 'venues');
      const pageData = oFetch(data, 'pageData');
      const holidaysCount = oFetch(data, 'holidaysCount');
      const effectiveStaffMemberCount = oFetch(data, 'effectiveStaffMemberCount');
      return {
        effectiveStaffTypes: utils.indexByClientId(effectiveStaffTypes),
        effectiveStaffMembers: utils.indexByClientId(effectiveStaffMembers),
        venues: utils.indexByClientId(venues),
        pageOptions: pageData,
        holidaysCount,
        effectiveStaffMemberCount,
      };
    },
    [ADD_HOLIDAY]: (state, action) => {
      const momentRange = extendMoment(moment);
      const { weekStartDate, weekEndDate } = state.pageOptions;
      const holiday = oFetch(action.payload, 'holiday');
      const startDate = oFetch(holiday, 'start_date');
      const isDateBetween = momentRange
        .range(safeMoment.uiDateParse(weekStartDate), safeMoment.uiDateParse(weekEndDate))
        .contains(safeMoment.uiDateParse(startDate));
      if (isDateBetween) {
        const processedHoliday = processHolidayObjectV2(holiday);
        const updatedEffectiveStaffMember = oFetch(state, 'effectiveStaffMembers')[`CLIENT_ID_${oFetch(processedHoliday, 'staff_member_id')}`];
        updatedEffectiveStaffMember.holidays.push(processedHoliday);

        return {
          ...state,
          effectiveStaffMembers: {
            ...state.effectiveStaffMembers,
            ...utils.indexByClientId([updatedEffectiveStaffMember]),
          },
        };
      }

      return state;
    },
    [ADD_HOLIDAY_STAFF_MEMBER]: (state, action) => {
      const effectiveStaffMember = action.payload;
      if (!(`CLIENT_ID_${oFetch(effectiveStaffMember, 'id')}` in oFetch(state, 'effectiveStaffMembers'))) {
        return {
          ...state,
          effectiveStaffMembers: {
            ...state.effectiveStaffMembers,
            ...utils.indexByClientId([processStaffMemberObject(effectiveStaffMember)]),
          },
        };
      } else {
        const newEffectiveStaffMemberHolidays = oFetch(effectiveStaffMember, 'holidays');
        const effectiveStaffMemberInState = state.effectiveStaffMembers[`CLIENT_ID_${oFetch(effectiveStaffMember, 'id')}`];
        const currentEffectiveStaffMemberHolidays = oFetch(effectiveStaffMemberInState, 'holidays');
        const effectiveStaffMemberClientId = `CLIENT_ID_${oFetch(effectiveStaffMember, 'id')}`;
        return {
          ...state,
          effectiveStaffMembers: {
            ...state.effectiveStaffMembers,
            [effectiveStaffMemberClientId]: {
              ...effectiveStaffMemberInState,
              holidays: [
                ...currentEffectiveStaffMemberHolidays,
                ...newEffectiveStaffMemberHolidays
              ]
            }
          },
        };
      }
    },
    [CALCULATE_HOLIDAYS]: (state, action) => {
      const holidays = Object.values(state.effectiveStaffMembers).reduce((acc, effectiveStaffMember) => {
        const holidays = oFetch(effectiveStaffMember, 'holidays');
        return [...acc, ...holidays];
      }, []);

      let holidaysCount = {};

      holidays.forEach(holiday => {
        const startDateWeekday = safeMoment.uiDateParse(oFetch(holiday, 'start_date')).isoWeekday();
        const endDateWeekday = safeMoment.uiDateParse(oFetch(holiday, 'end_date')).isoWeekday();

        for (let i = startDateWeekday; i <= endDateWeekday; i++) {
          if (holidaysCount[i] === undefined) {
            holidaysCount[i] = 0;
          }
          holidaysCount[i] = holidaysCount[i] + 1;
        }
      });

      return {
        ...state,
        holidaysCount: holidaysCount,
      };
    },
    [CALCULATE_STAFF_MEMBERS]: (state, action) => {
      const effectiveStaffMemberCount = Object.values(state.effectiveStaffMembers).length;
      return {
        ...state,
        effectiveStaffMemberCount,
      };
    },
  },
  initialState,
);
