import React from 'react';
import _ from 'underscore';
import Select from '@/lib/boss-react-select';
import { ColoredMultipleValue, ColoredSingleOption } from '@/components/boss-form/colored-select';
import HolidayRow from './holiday-row';
import HolidaysWeekFilter from './holidays-week-filter';
import URLSearchParams from 'url-search-params';

export default class ReportsBody extends React.Component {
  renderPeople() {
    return this.state.effectiveStaffMemberCollection.map((effectiveStaffMember, key) => {
      return (
        <HolidayRow
          key={key}
          staffMember={effectiveStaffMember}
          effectiveStaffTypes={this.props.effectiveStaffTypes}
          holidays={this.props.holidays}
          venues={this.props.venues}
          hasCurrentVenue={!!this.props.pageOptions.currentVenueId}
        />
      );
    });
  }

  constructor(props) {
    super(props);
    const selectedVenue =
      this.venueOptions().find(venue => venue.value === props.pageOptions.currentVenueId) ||
      this.venueOptions()[0];

    this.state = {
      filterByText: '',
      filterByStaffType: [],
      effectiveStaffMemberCollection: Object.values(this.props.effectiveStaffMembers),
      selectedVenue: selectedVenue,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ effectiveStaffMemberCollection: Object.values(newProps.effectiveStaffMembers) });
  }

  effectiveStaffTypeOptions() {
    return _.map(Object.values(this.props.effectiveStaffTypes), effectiveStaffType => {
      return {
        value: effectiveStaffType.clientId,
        label: effectiveStaffType.name,
        color: effectiveStaffType.color,
      };
    });
  }

  venueOptions = () => {
    const options = _.map(this.props.accessibleVenues, venue => {
      return {
        value: venue.serverId,
        label: venue.name,
      };
    });

    return [{ value: 'all', label: 'All' }, ...options];
  };

  setTextFilter(event) {
    this.setState({ filterByText: event.target.value.toLowerCase() });

    this.filterByText(event.target.value.toLowerCase());
  }

  filterByText(value, collection) {
    let filteredStaffMembers;
    const effectiveStaffMembers = collection || this.state.effectiveStaffMemberCollection;

    if (value.length) {
      filteredStaffMembers = _.filter(effectiveStaffMembers, effectiveStaffMember => {
        return (
          (effectiveStaffMember.first_name + ' ' + effectiveStaffMember.surname).toLowerCase().indexOf(value) > -1
        );
      });
    } else if (collection) {
      filteredStaffMembers = collection;
    } else {
      filteredStaffMembers = this.filterByStaffType(this.state.filterByStaffType);
    }

    this.setState({
      effectiveStaffMemberCollection: filteredStaffMembers,
    });
  }

  setStaffTypeFilter = options => {
    this.setState({ filterByStaffType: options });

    this.filterByStaffType(options);
  };

  setVenueFilter = venue => {
    this.setState({ selectedVenue: venue }, () => {
      const queryString = new URLSearchParams(window.location.search);
      venue.value === 'all'
        ? queryString.delete('venue_id')
        : queryString.set('venue_id', venue.value);
      const link = `${window.location.href.split('?')[0]}?${queryString.toString()}`;
      window.location.href = link;
    });
  };

  filterByStaffType(options) {
    let filteredStaffMembers;
    const effectiveStaffMembers = Object.values(this.props.effectiveStaffMembers);
    const effectiveStaffTypeNames = options.map(option => {
      return option.label;
    });

    if (effectiveStaffTypeNames.length) {
      filteredStaffMembers = _.filter(effectiveStaffMembers, effectiveStaffMember => {
        const effectiveStaffType = this.props.effectiveStaffTypes['CLIENT_ID_' + effectiveStaffMember.effective_staff_type.serverId];

        return effectiveStaffTypeNames.indexOf(effectiveStaffType.name) > -1;
      });
    } else {
      filteredStaffMembers = Object.values(this.props.effectiveStaffMembers);
    }

    this.setState({
      effectiveStaffMemberCollection: filteredStaffMembers,
    });

    this.filterByText(this.state.filterByText, filteredStaffMembers);

    return filteredStaffMembers;
  }

  renderFilter() {
    return (
      <div className="boss-page-main__filter">
        <form className="boss-form">
          <div className="boss-form__row boss-form__row_justify_space">
            <div className="boss-form__field boss-form__field_layout_max">
              <div className="boss-form__search">
                <label className="boss-form__label">
                  <input
                    id="text-search"
                    name="search"
                    type="text"
                    className="boss-form__input"
                    placeholder="Search..."
                    onKeyUp={this.setTextFilter.bind(this)}
                  />
                </label>
              </div>
            </div>

            <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
              <p className="boss-form__label">
                <span className="boss-form__label-text">Venue</span>
              </p>

              <div className="boss-form__select">
                <Select
                  value={this.state.selectedVenue}
                  options={this.venueOptions()}
                  onChange={this.setVenueFilter}
                  clearable={false}
                />
              </div>
            </div>
            <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
              <p className="boss-form__label">
                <span className="boss-form__label-text">Staff Type</span>
              </p>

              <div className="boss-form__select">
                <Select
                  value={this.state.filterByStaffType}
                  options={this.effectiveStaffTypeOptions()}
                  valueComponent={ColoredMultipleValue}
                  optionComponent={ColoredSingleOption}
                  multi={true}
                  onChange={this.setStaffTypeFilter}
                />
              </div>
            </div>
          </div>
          <HolidaysWeekFilter
            staffMembers={this.props.effectiveStaffMembers}
            holidaysCount={this.props.holidaysCount}
            holidays={this.props.holidays}
            weekStartDate={this.props.pageOptions.weekStartDate}
            effectiveStaffMemberCount={this.props.effectiveStaffMemberCount}
          />
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="boss-page-main__content">
        <div className="boss-page-main__inner">
          {this.renderFilter()}

          <div className="boss-table boss-table_page_holidays-report">{this.renderPeople()}</div>
        </div>
      </div>
    );
  }
}
