import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';

function StaffMemberInfo(зкщзы) {
  const [supportsFacialRecognition, avatarUrl, fullName, staffType, staffColor, isSteward] = oFetch(
    зкщзы,
    'supportsFacialRecognition',
    'avatarUrl',
    'fullName',
    'staffType',
    'staffColor',
    'isSteward',
  );

  let avatarContainerClasses = 'boss-user-summary__avatar';
  if (supportsFacialRecognition) {
    avatarContainerClasses = avatarContainerClasses + ' boss-user-summary__avatar_type_scannable';
  }

  return (
    <div className="boss-user-summary boss-user-summary_role_rotas-daily">
      <div className="boss-user-summary__side">
        <div className={avatarContainerClasses}>
          <div className="boss-user-summary__avatar-inner">
            {/* <img src={avatarUrl} alt="" className="boss-user-summary__pic" /> */}
          </div>
        </div>
      </div>
      <div className="boss-user-summary__content">
        <div className="boss-user-summary__header">
          <h2 className="boss-user-summary__name">{fullName}</h2>
          <p
            style={{ backgroundColor: staffColor }}
            className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
          >
            {staffType}
          </p>
          {isSteward && (
            <p className="boss-user-summary__label boss-user-summary__label_role_text">(Steward)</p>
          )}
        </div>
      </div>
    </div>
  );
}

StaffMemberInfo.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  staffType: PropTypes.string.isRequired,
  staffColor: PropTypes.string.isRequired,
};

export default StaffMemberInfo;
