import { Dispatch, SetStateAction, useState } from "react";
import oFetch from "o-fetch";
import { $CreateRequestValidationErrors, MossStaffMemberOption, NonStaffMemberStaffPartyPerson, OnSubmitCreateParams, CreateRequestValidationErrors, PLUS_1_QR_CODE_TYPE, MAIN_QR_CODE_TYPE, QrCodeType, UpdateFormValues, OnSubmitUpdateParams, UpdateRequestValidationErrors, $UpdateRequestValidationErrors, UpdateFormCallProps, StaffPartyQuizTeamOption } from "./types";
import { useModal } from "@/components/hooks-components/modals";
import { LegacyCloseInsideModalWrapper } from "@/components/modal-wrappers";
import { bossRequestHttp } from "@/lib/request-api";
import {
  createMossNonStaffMemberStaffPartyPerson as importedCreateMossNonStaffMemberStaffPartyPerson,
  disableMossNonStaffMemberStaffPartyPerson as importDisableMossNonStaffMemberStaffPartyPerson,
  enableMossNonStaffMemberStaffPartyPerson as importEnableMossNonStaffMemberStaffPartyPerson,
  printNonStaffMemberStaffPartyPersonQrCode as importPrintNonStaffMemberStaffPartyPersonQrCode,
  updateMossNonStaffMemberStaffPartyPerson as importUpdateMossNonStaffMemberStaffPartyPerson,
} from "@/lib/api-routes";
// creating this to make it easier to see these are routes
const apiRoutes = {
  disableMossNonStaffMemberStaffPartyPerson: importDisableMossNonStaffMemberStaffPartyPerson,
  createMossNonStaffMemberStaffPartyPerson: importedCreateMossNonStaffMemberStaffPartyPerson,
  enableMossNonStaffMemberStaffPartyPerson: importEnableMossNonStaffMemberStaffPartyPerson,
  printNonStaffMemberStaffPartyPersonQrCode: importPrintNonStaffMemberStaffPartyPersonQrCode,
  updateMossNonStaffMemberStaffPartyPerson: importUpdateMossNonStaffMemberStaffPartyPerson,
} as const;
import { applyBossRoute } from "@/lib/apply-boss-route";
import utils from "@/lib/utils";
import { CreateModalContent } from "./components/create-modal-content";
import QRCodeModalContent from "../StaffPartyPeople/qr-code-modal-content";
import { openCustomContentModal, openWarningModal } from "@/components/modals";
import { UpdateModalContent } from "./components/update-modal-content";

type UseNonStaffMemberStaffPartyPeopleParams = {
  nonStaffMemberStaffPartyPeople: NonStaffMemberStaffPartyPerson[],
  nonStaffMemberStaffPartyPeopleTotal: number,
  mossStaffMemberOptions: MossStaffMemberOption[],
  staffPartyQuizTeamOptions: StaffPartyQuizTeamOption[],
};

type AddStaffMemberStaffPartyPersonArgs = {
  nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson,
};

type GetStateFuncsParams = {
  nonStaffMemberStaffPartyPeople: NonStaffMemberStaffPartyPerson[],
  nonStaffMemberStaffPartyPeopleTotal: number,
  setNonStaffMemberStaffPartyPeople: Dispatch<SetStateAction<NonStaffMemberStaffPartyPerson[]>>,
  setNonStaffMemberStaffPartyPeopleTotal: Dispatch<SetStateAction<number>>,
  setMossStaffMemberOptions: Dispatch<SetStateAction<MossStaffMemberOption[]>>,
};

function getStateFuncs(args: GetStateFuncsParams) {
  const {
    setNonStaffMemberStaffPartyPeople,
    setNonStaffMemberStaffPartyPeopleTotal,
    setMossStaffMemberOptions,
    nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal,
  } = args;

  const addNonStaffMemberStaffPartyPerson = (args: AddStaffMemberStaffPartyPersonArgs) => {
    setNonStaffMemberStaffPartyPeopleTotal(
      nonStaffMemberStaffPartyPeopleTotal + 1
    );

    // remove moss staff member option
    setMossStaffMemberOptions((previousState: MossStaffMemberOption[]) => {
      return previousState.reduce<MossStaffMemberOption[]>((acc, mossStaffMemberOption) => {
        if (mossStaffMemberOption.value !== args.nonStaffMemberStaffPartyPerson.mossStaffMemberOption.value) {
          acc.push(mossStaffMemberOption);
        }
        return acc;
      }, []);
    });

    setNonStaffMemberStaffPartyPeople([
      ...nonStaffMemberStaffPartyPeople,
      args.nonStaffMemberStaffPartyPerson
    ]);
  };

  const getUpdateNonStaffMemberStaffPartyPersonInPlace = (nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) => {
    return (previousState: NonStaffMemberStaffPartyPerson[]): NonStaffMemberStaffPartyPerson[] => {
      return previousState.map((innerNonStaffMemberStaffPartyPerson) => {
        if (innerNonStaffMemberStaffPartyPerson.id === nonStaffMemberStaffPartyPerson.id) {
          return nonStaffMemberStaffPartyPerson;
        } else {
          return innerNonStaffMemberStaffPartyPerson;
        }
      });
    };
  };

  const updateNonStaffMemberStaffPartyPerson = (nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) => {
    const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
    setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
  };

  const deleteNonStaffMemberStaffPartyPerson = (nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, filteringByEnabled: boolean): void => {
    if (filteringByEnabled) {
      // Delete staff member from state and Remove from count
      //////////////////////////////////
      setNonStaffMemberStaffPartyPeopleTotal(nonStaffMemberStaffPartyPeopleTotal - 1);

      setNonStaffMemberStaffPartyPeople((previousState: NonStaffMemberStaffPartyPerson[]) => {
        return previousState.reduce<NonStaffMemberStaffPartyPerson[]>((acc, innerNonStaffMemberStaffPartyPerson) => {
          if (innerNonStaffMemberStaffPartyPerson.id !== nonStaffMemberStaffPartyPerson.id) {
            acc.push(innerNonStaffMemberStaffPartyPerson);
          }
          return acc;
        }, []);
      });
    } else {
      // Disabled records are being displayed so just update In Place
      ////////////////////////////////////////////////////////
      const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
      setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
    }
  };

  const enableNonStaffMemberStaffPartyPerson = (nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson): void => {
    const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
    setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
  };

  return {
    addNonStaffMemberStaffPartyPerson,
    updateNonStaffMemberStaffPartyPerson,
    deleteNonStaffMemberStaffPartyPerson,
    enableNonStaffMemberStaffPartyPerson,
  };
}


function getOpenShowQRCodeModal(params: { nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, qrCodeType: QrCodeType }) {
  const { nonStaffMemberStaffPartyPerson, qrCodeType } = params;
  const [guid, title] = (() => {
    let guid = '';
    let title = '';
    if (qrCodeType === MAIN_QR_CODE_TYPE) {
      guid = nonStaffMemberStaffPartyPerson.guid;
      title = 'Party Person QR Code';
    } else if (qrCodeType === PLUS_1_QR_CODE_TYPE) {
      guid = nonStaffMemberStaffPartyPerson.plus1Guid;
      title = 'Plus 1 QR Code';
    } else {
      throw new Error(`Unknown QR Code Type supplied: ${qrCodeType}`);
    }

    return [guid, title];
  })();

  const onPrintGuid = () => {
    const route = apiRoutes.printNonStaffMemberStaffPartyPersonQrCode;
    const bossRequestInstance = bossRequestHttp({
      errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
        const { globalNotifications } = params;
        globalNotifications.showDefaultFailureMessage();
        return false;
      },
      successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
        const rawData = oFetch(params, 'data');
        const data = route.$SuccessData.parse(rawData);
        const { image, filename } = data;

        const base64Url = image;
        const a = document.createElement('a');
        a.href = base64Url;
        a.download = filename;

        // Click handler that releases the object URL after the element has been clicked
        // This is required for one-off downloads of the blob content
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(base64Url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };

        // Add the click event listener on the anchor element
        a.addEventListener('click', clickHandler, false);

        a.click();
      },
    });

    return applyBossRoute({
      route,
      bossHttpRequest: bossRequestInstance,
      callParams: {},
      pathParams: {
        nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id,
        qrCodeType
      },
    });
  };

  return () => {
    return openCustomContentModal({
      submit: () => { },
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_action',
        title: title,
        innerClose: false,
      },
      props: {
        guid: guid,
        onPrintGuid,
      },
    })(QRCodeModalContent);
  };
}

export function useNonStaffMemberStaffPartyPeople(params: UseNonStaffMemberStaffPartyPeopleParams) {
  const [nonStaffMemberStaffPartyPeople, setNonStaffMemberStaffPartyPeople] = useState(params.nonStaffMemberStaffPartyPeople);
  const [nonStaffMemberStaffPartyPeopleTotal, setNonStaffMemberStaffPartyPeopleTotal] = useState(params.nonStaffMemberStaffPartyPeopleTotal);
  const [staffPartyQuizTeamOptions] = useState(params.staffPartyQuizTeamOptions);
  const [mossStaffMemberOptions, setMossStaffMemberOptions] = useState(params.mossStaffMemberOptions);
  const stateFuncs = getStateFuncs({
    nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal,
    setNonStaffMemberStaffPartyPeople,
    setNonStaffMemberStaffPartyPeopleTotal,
    setMossStaffMemberOptions,
  });

  const { openModal } = useModal();

  function openCreateModal() {
    return openModal({
      onSubmit: (
        closeModal: () => void,
        onSumbitParams: OnSubmitCreateParams
      ) => {
        const createRoute = apiRoutes.createMossNonStaffMemberStaffPartyPerson;
        const createBossRequestInstance = bossRequestHttp({
          errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
            const globalNotifications = params.globalNotifications;
            globalNotifications.showDefaultFailureMessage();

            if (params.statusCode === 422) {
              return utils.normalizeFinalFormErrors<CreateRequestValidationErrors>($CreateRequestValidationErrors.parse(params.errors));
            }
            return false;
          },
          successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
            const globalNotifications = oFetch(params, 'globalNotifications');
            const rawData = oFetch(params, 'data');
            const data = createRoute.$SuccessData.parse(rawData);
            const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

            stateFuncs.addNonStaffMemberStaffPartyPerson({
              nonStaffMemberStaffPartyPerson,
            });

            globalNotifications.showDefaultSuccessMessage();
            closeModal();
          },
        });

        return applyBossRoute({
          route: createRoute,
          bossHttpRequest: createBossRequestInstance,
          callParams: onSumbitParams.values,
          pathParams: {},
        });
      },
      config: {
        title: 'Add Moss Party Person',
      },
      props: {
        mossStaffMemberOptions,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: CreateModalContent,
    });
  }

  function getOpenUpdateModal(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    const initialValues: UpdateFormValues = {
      phoneNumber: nonStaffMemberStaffPartyPerson.phoneNumber || "",
      email: nonStaffMemberStaffPartyPerson.email || "",
      staffPartyQuizTeamId: nonStaffMemberStaffPartyPerson.staffPartyQuizTeamId,
    };
    const updateFormProps: UpdateFormCallProps = {
      id: nonStaffMemberStaffPartyPerson.id,
      mossStaffMemberOption: nonStaffMemberStaffPartyPerson.mossStaffMemberOption,
      staffPartyQuizTeamOptions,
      initialValues,
    };

    return () => {
      return openModal({
        onSubmit: (
          closeModal: () => void,
          onSumbitParams: OnSubmitUpdateParams
        ) => {
          const route = apiRoutes.updateMossNonStaffMemberStaffPartyPerson;
          const bossRequestInstance = bossRequestHttp({
            errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
              const globalNotifications = params.globalNotifications;
              globalNotifications.showDefaultFailureMessage();

              if (params.statusCode === 422) {
                return utils.normalizeFinalFormErrors<UpdateRequestValidationErrors>($UpdateRequestValidationErrors.parse(params.errors));
              }
              return false;
            },
            successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
              const globalNotifications = params.globalNotifications;

              const rawData = oFetch(params, 'data');
              const data = route.$SuccessData.parse(rawData);
              const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

              stateFuncs.updateNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson);

              globalNotifications.showDefaultSuccessMessage();
              closeModal();
            },
          });

          return applyBossRoute({
            route,
            bossHttpRequest: bossRequestInstance,
            callParams: onSumbitParams.values,
            pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
          });
        },
        config: {
          title: 'Edit Party Person',
        },
        props: updateFormProps,
        ModalComponent: LegacyCloseInsideModalWrapper,
        ModalContent: UpdateModalContent,
      });
    };
  }

  function getOpenDeleteModal(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, filteringByEnabled: boolean) {
    return () => {
      function handleDisable(closeModal: () => void) {
        const route = apiRoutes.disableMossNonStaffMemberStaffPartyPerson;
        const bossRequestInstance = bossRequestHttp({
          errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
            const globalNotifications = params.globalNotifications;
            globalNotifications.showDefaultFailureMessage();

            return false;
          },
          successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
            const rawData = oFetch(params, 'data');
            const data = route.$SuccessData.parse(rawData);
            const globalNotifications = params.globalNotifications;
            const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

            stateFuncs.deleteNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson, filteringByEnabled);

            globalNotifications.showDefaultSuccessMessage();
            closeModal();
          },
        });

        return applyBossRoute({
          route,
          bossHttpRequest: bossRequestInstance,
          callParams: {},
          pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
        });
      }

      openWarningModal({
        submit: handleDisable,
        config: {
          title: 'WARNING !!!',
          text: 'Are You Sure?',
          buttonText: 'Disable',
        },
        props: { nonStaffMemberStaffPartyPerson },
      });
    };
  }

  function getEnableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    return () => {
      const route = apiRoutes.enableMossNonStaffMemberStaffPartyPerson;
      const bossRequestInstance = bossRequestHttp({
        errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
          const globalNotifications = params.globalNotifications;
          globalNotifications.showDefaultFailureMessage();

          return false;
        },
        successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
          const rawData = oFetch(params, 'data');
          const globalNotifications = params.globalNotifications;

          const data = route.$SuccessData.parse(rawData);
          const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

          stateFuncs.enableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson);

          globalNotifications.showDefaultSuccessMessage();
        },
      });

      return applyBossRoute({
        bossHttpRequest: bossRequestInstance,
        route,
        callParams: {},
        pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
      });
    };
  }

  return {
    nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal,
    openCreateModal,
    getOpenShowQRCodeModal,
    getOpenUpdateModal,
    getOpenDeleteModal,
    getEnableNonStaffMemberStaffPartyPerson,
  };
}
