import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import BossFormInput from '@/components/boss-form/boss-form-input';

import { updatePersonalDetailsRequest } from '../../actions';

const validate = values => {
  const errors = {};

  return errors;
};

const PersonalDetailsForm = ({ handleSubmit, submitting, onSubmissionComplete, ...props }) => {
  const submission = (values, dispatch) => {
    return dispatch(updatePersonalDetailsRequest(values.toJS()));
  };

  return (
    <form onSubmit={handleSubmit(submission)}>
      <header className="boss-content-switcher__header">
        <h2 className="boss-content-switcher__title">Personal Details</h2>
      </header>

      <Field component={BossFormInput} required name="first_name" type="text" label="First Name" />
      <Field component={BossFormInput} required name="surname" type="text" label="Surname" />

      <div className="boss-form__field boss-form__field_justify_end">
        <button
          className="boss-button boss-form__submit boss-form__submit_adjust_single"
          type="submit"
          disabled={submitting}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'personal-details-form',
  validate,
})(PersonalDetailsForm);
