import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as commonTypes from '../../../profile-wrapper/constants';

const initialState = {
  quizCategoryId: undefined,
  quizCategoryName: undefined,
};

export default handleActions(
  {
    [commonTypes.INITIAL_LOAD]: (state, action) => {
      const [quizCategoryId, quizCategoryName] = oFetch(action, 'payload.quizCategoryId', 'payload.quizCategoryName');
      return { quizCategoryId, quizCategoryName };
    },
  },
  initialState,
);
