import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import Immutable from 'immutable';
import _ from 'underscore';
import safeMoment from '@/lib/safe-moment';
import {
  FINANCE_REPORT_READY_STATUS,
  FINANCE_REPORT_REQUIRING_UPDATE_STATUS,
  FINANCE_REPORT_SHOW_ALL_FILTER_TYPE,
  FINANCE_REPORT_SALARY_ONLY_FILTER_TYPE,
  FINANCE_REPORT_WITH_ACCESSORIES_FILTER_TYPE,
  FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE,
  FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE,
  FINANCE_REPORTS_PAID_ON_SITE_FILTER_TYPE,
  FILTER_TABS,
} from '../constants';

export const staffTypesSelector = state => state.get('staffTypes');
export const financeReportsSelector = state => state.get('financeReports');
export const filterTypeSelector = state => state.getIn(['page', 'filterType']);
export const startDateSelector = state => state.getIn(['page', 'startDate']);
export const permissionsSelector = state => state.getIn(['page', 'permissions']);
export const financeReportWeekSelector = state => state.get('financeReportWeek');

const filterFactory = financeReports => {
  const filter = {
    [FINANCE_REPORT_SHOW_ALL_FILTER_TYPE]() {
      return financeReports;
    },
    [FINANCE_REPORT_SALARY_ONLY_FILTER_TYPE]() {
      return financeReports.filter(financeReport => {
        const financeReportJS = financeReport.toJS();
        const payRateType =
          _.last(oFetch(financeReportJS, 'payRateDescription').split('/')) === 'h' ? 'hourly' : 'weekly';
        return payRateType === 'weekly';
      });
    },
    [FINANCE_REPORT_WITH_OWED_HOURS_FILTER_TYPE]() {
      return financeReports.filter(financeReport => {
        return oFetch(financeReport.toJS(), 'owedHoursMinuteCount') > 0;
      });
    },
    [FINANCE_REPORT_WITH_HOLIDAYS_FILTER_TYPE]() {
      return financeReports.filter(financeReport => {
        const financeReportJS = financeReport.toJS();
        return (
          oFetch(financeReportJS, 'paidHolidayDaysCount') > 0 ||
          oFetch(financeReportJS, 'sickLeaveDaysCount') > 0
        );
      });
    },
    [FINANCE_REPORT_WITH_ACCESSORIES_FILTER_TYPE]() {
      return financeReports.filter(financeReport => oFetch(financeReport.toJS(), 'accessoriesCents') !== 0);
    },
    [FINANCE_REPORTS_PAID_ON_SITE_FILTER_TYPE]() {
      return financeReports.filter(financeReport => {
        return oFetch(financeReport.toJS(), 'paidExternally');
      });
    },
  };

  return filterType => {
    return filter[filterType]();
  };
};

export const getFilteredFinanceReports = createSelector(
  financeReportsSelector,
  filterTypeSelector,
  (financeReports, filterType) => {
    if (!FILTER_TABS.includes(filterType)) {
      throw new Error(`Unsupported filter type ${filterType} encountered in ${JSON.stringify(FILTER_TABS)}`);
    }
    return filterFactory(financeReports)(filterType);
  },
);

export const getReportsWithCalculations = createSelector(getFilteredFinanceReports, financeReports =>
  financeReports.map(financeReport => {
    const owedHours = financeReport.get('owedHoursMinuteCount') / 60;
    const weeklyHours =
      financeReport.get('mondayHoursCount') +
      financeReport.get('tuesdayHoursCount') +
      financeReport.get('wednesdayHoursCount') +
      financeReport.get('thursdayHoursCount') +
      financeReport.get('fridayHoursCount') +
      financeReport.get('saturdayHoursCount') +
      financeReport.get('sundayHoursCount');
    const payRateType =
      _.last(financeReport.get('payRateDescription').split('/')) === 'h' ? 'hourly' : 'weekly';
    const payRateAmount = _.first(financeReport.get('payRateDescription').split('/')).slice(1);
    const acessories = financeReport.get('accessoriesCents') / 100;
    const total = financeReport.get('total');
    return financeReport
      .set('weeklyHours', weeklyHours)
      .set('owedHours', owedHours)
      .set('acessories', acessories)
      .set('payRateType', payRateType)
      .set('total', total);
  }),
);

export const completedFinanceReportsSelector = createSelector([financeReportsSelector], financeReports => {
  return financeReports.filter(financeReport => {
    return financeReport.get('completedAt');
  });
});

export const getCompletedFinanceReportsData = createSelector(
  [completedFinanceReportsSelector, financeReportsSelector],
  (completedFinanceReports, financeReports) => {
    return { completedCount: completedFinanceReports.size, count: financeReports.size };
  },
);

export const getStaffPayslipsStats = createSelector(
  [completedFinanceReportsSelector, financeReportsSelector],
  (completedFinanceReports, financeReports) => {
    return financeReports.map((financeReport) => {
      return financeReport.toJS();
    }).reduce(
      (acc, financeReport) => {
        const financeReportIsNonZero = oFetch(financeReport, 'calculatedGrossPayCents') > 0;
        const payslip = oFetch(financeReport, 'payslip');
        const hasNonZeroPayslip = (payslip ? oFetch(payslip, 'netPayCents') : 0) > 0;
        const staffMemberHasBankDetails = oFetch(financeReport, 'staffMemberHasBankDetails');

        if (hasNonZeroPayslip || financeReportIsNonZero) {
          acc.payableReportCount = oFetch(acc, 'payableReportCount') + 1;
          if (hasNonZeroPayslip) {
            acc.payableReportWithPayslipCount = oFetch(acc, 'payableReportWithPayslipCount') + 1;
            if (staffMemberHasBankDetails) {
              acc.payableReportWithPayslipAndBankDetailsCount = oFetch(acc, 'payableReportWithPayslipAndBankDetailsCount') + 1;
            }
          }
        }

        return acc;
      },
      {
        reportCount: financeReports.size,
        payableReportCount: 0,
        payableReportWithPayslipCount: 0,
        payableReportWithPayslipAndBankDetailsCount: 0,
      },
    );
  },
);

export const getWeekDates = createSelector(startDateSelector, startDate => {
  const date = safeMoment.uiDateParse(startDate);
  return Immutable.List([1, 2, 3, 4, 5, 6, 7]).map(weekDay => {
    const currentDate = date.isoWeekday(weekDay);
    return currentDate.format('DD-MM-YYYY');
  });
});

export const getStaffTypesWithFinanceReports = createSelector(getReportsWithCalculations, financeReports => {
  const financeReportsJS = financeReports.toJS();
  const staffTypesHash = financeReportsJS.reduce((result, financeReport) => {
    const name = oFetch(financeReport, 'staffTypeName');
    const id = oFetch(financeReport, 'staffTypeId');
    const key = `${name}:${id}`;
    result[key] = {
      name: name,
      id: id,
    };
    return result;
  }, {});
  const staffTypes = Object.values(staffTypesHash);

  return Immutable.List(
    staffTypes.map(staffType => {
      const staffTypeReportsJS = financeReportsJS.filter(financeReport => {
        return oFetch(financeReport, 'staffTypeName') == oFetch(staffType, 'name');
      });
      const total = staffTypeReportsJS.reduce((acc, report) => acc + oFetch(report, 'total'), 0);
      const readyReportsJS = staffTypeReportsJS.filter(report => {
        return oFetch(report, 'status') === FINANCE_REPORT_READY_STATUS;
      });
      const requiringUpdateReportsJS = staffTypeReportsJS.filter(report => {
        return oFetch(report, 'status') === FINANCE_REPORT_REQUIRING_UPDATE_STATUS;
      });

      const completableReadyReportsJS = [];
      const incompletableReadyReportsJS = [];
      readyReportsJS.forEach(report => {
        const completionDateReached = oFetch(report, 'completionDateReached');
        const hoursPending = oFetch(report, 'hoursPending');
        const total = oFetch(report, 'total');

        if (hoursPending || !completionDateReached) {
          incompletableReadyReportsJS.push(report);
        } else {
          completableReadyReportsJS.push(report);
        }
      });

      const staffTypeEmpty = staffTypeReportsJS.length == 0;
      const noIncompletableReportsExist = incompletableReadyReportsJS.length === 0;
      const completeableReportsExist = completableReadyReportsJS.length > 0;
      const allReady = staffTypeEmpty || (noIncompletableReportsExist && completeableReportsExist);

      const staffTypeName = oFetch(staffType, 'name');
      const staffTypeId = oFetch(staffType, 'id');

      return Immutable.Map({
        name: staffTypeName,
        id: staffTypeId,
        total: total,
        reports: staffTypeReportsJS,
        allReady: allReady,
      });
    }),
  );
});

export const getAllReady = createSelector(getStaffTypesWithFinanceReports, staffTypesWithFinanceReports => {
  const allReportsEmpty = staffTypesWithFinanceReports.toJS().every(staffType => {
    return oFetch(staffType, 'reports.length') == 0;
  });

  return (
    !allReportsEmpty &&
    staffTypesWithFinanceReports.toJS().every(staffType => {
      return oFetch(staffType, 'allReady') == true;
    })
  );
});
