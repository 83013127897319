import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const reminderDates = oFetch(action.payload, 'handoverPlannerReminderDates');
      return reminderDates;
    },
    [commonTypes.ADD_REMINDER]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const reminder = oFetch(payload, 'reminder');
      if (reminder === null) {
        return state;
      }
      const reminderDates = oFetch(payload, 'handoverPlannerReminderDates');
      return [...state, ...reminderDates];
    },
  },
  initialState,
);
