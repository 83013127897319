import React from 'react';
import Modal from 'react-modal';
import modalDecorator from './modal-decorator';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';

class InfoModal extends React.Component {
  render() {
    const { show, title, text, onClose } = this.props;

    return (
      <ReactErrorsBoundaryWrapper>
        <Modal
          isOpen={show}
          contentLabel="Modal"
          className={{
            base: `ReactModal__Content boss-modal-window boss-modal-window_role_note`,
            afterOpen: 'ReactModal__Content--after-open',
          }}
        >
          <button onClick={onClose} className="boss-modal-window__close" />
          <div className="boss-modal-window__header boss-modal-window__header_info">{title}</div>
          <div className="boss-modal-window__content">
            <div className="boss-modal-window__message-block">
              <span className="boss-modal-window__message-text">{text}</span>
            </div>
          </div>
        </Modal>
      </ReactErrorsBoundaryWrapper>
    );
  }
}

function openInfoModal({ title = '', text = '' }, props = {}, onSubmit, onClose, rootWrapper) {
  rootWrapper.render(<InfoModal show={true} title={title} text={text} onClose={onClose} />);
}

export default modalDecorator(openInfoModal);
