import React, { Component } from 'react';
import { modalRedux } from '@/components/modals';
import { reducer as formReducer } from 'redux-form';
import { default as MoveQuestionModalForm } from  './move-question-modal-form';
import { combineReducers } from 'redux';
import oFetch from 'o-fetch';

class MoveQuestionModal extends Component {
  render() {
    const question = oFetch(this.props, 'question');
    const onSubmit = oFetch( this.props, 'onSubmit');
    const categories = oFetch(this.props, 'categories');
    const categoryOptions = categories.map((category) => {
      const label = oFetch(category, 'name');
      const value = oFetch(category, 'id');
       return { label, value }
    })

    const initialValues = {
      quizCategoryQuestionId: oFetch(question, 'id'),
      quizCategoryId: null,
    };

    return (
      <MoveQuestionModalForm
        initialValues={initialValues}
        categoryOptions={categoryOptions}
        onSubmit={onSubmit}
      />
    )
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(MoveQuestionModal);
