import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { BossFormQuarterSelect } from './boss-form-quarter-select';
import BossFormSelectNew from './boss-form-select-new';

export function BossFormYearQuarter(props) {
  const [yearField, quarterField, yearsOptions, yearsQuarters] = oFetch(
    props,
    'year',
    'quarter',
    'yearsOptions',
    'yearsQuarters',
  );
  const yearValue = oFetch(yearField, 'input.value');
  const prevYearValue = useRef(yearValue);
  const quarterOnChange = oFetch(quarterField, 'input.onChange');

  const quarterOptions = useMemo(() => {
    if (!yearValue) {
      return [];
    }
    return yearsQuarters[yearValue];
  }, [yearValue]);

  useEffect(() => {
    if (prevYearValue.current !== yearValue) {
      quarterOnChange(null);
    }
  }, [yearValue]);

  return (
    <Fragment>
      <Field label="Year" name="year" options={yearsOptions} component={BossFormSelectNew} />
      {yearValue && <Field name="quarter" quarters={quarterOptions} component={BossFormQuarterSelect} />}
    </Fragment>
  );
}
