import React, { useEffect, useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import utils from '@/lib/utils';
import { ErrorReports } from './error-reports';

export function ErrorReportsApp(props) {
  const [processing, setProcessing] = useState(false);
  const errorReportsFromProps = oFetch(props, 'errorReports');
  const [errorReports, setErrorReports] = useState(errorReportsFromProps);

  const groupByUserId = utils.groupBy('userId');
  const groupedByUserId = groupByUserId(errorReports);

  const sortedErrorReports = useMemo(() => {
    return [...errorReports].sort((a, b) =>
      oFetch(b, 'at') < oFetch(a, 'at') ? -1 : oFetch(b, 'at') > oFetch(a, 'at') ? 1 : 0,
    );
  }, [errorReports]);

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    window.boss.accessToken = accessToken;
    require('./style.css');
    const lsErrorReports = window.localStorage.getItem('errorReports');
    if (lsErrorReports === null) {
      window.localStorage.setItem('errorReports', JSON.stringify(errorReports.map(i => oFetch(i, 'id'))));
    } else {
      const oldReportIds = JSON.parse(window.localStorage.getItem('errorReports'));
      const normalizedReports = errorReports.map(errorReport => {
        const errorReportId = oFetch(errorReport, 'id');
        if (!oldReportIds.includes(errorReportId)) {
          return {
            ...errorReport,
            new: true,
          };
        } else {
          return {
            ...errorReport,
            new: false,
          };
        }
      });
      setErrorReports(normalizedReports);
      window.localStorage.setItem('errorReports', JSON.stringify(errorReports.map(i => oFetch(i, 'id'))));
    }
  }, []);

  if (processing) {
    return null;
  }

  return (
    <ModalsProvider>
      <ErrorReports reports={sortedErrorReports} userReports={groupedByUserId} />
    </ModalsProvider>
  );
}
