import React from 'react';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { appRoutes } from '@/lib/legacy-routes';
import { Tooltip } from 'react-tippy';

import bouncedEmailModal from '@/components/bounced-email-modal';

const StaffMemberCard = props => {
  const [
    staffMember,
    jobType,
    onEditAvatar,
    venues,
    permissionsData,
    onMarkRetakeAvatar,
    accessoriesInPossession,
  ] = oFetch(
    props,
    'staffMember',
    'jobType',
    'onEditAvatar',
    'venues',
    'permissionsData',
    'onMarkRetakeAvatar',
    'accessoriesInPossession',
  );
  const accessoriesInPossessionJS = accessoriesInPossession.toJS();
  const [hasLinkedUser, linkedUserId] = oFetch(staffMember, 'hasLinkedUser', 'linkedUserId');
  const [hasLinkedMossStaffMember, linkedMossStaffMemberId] = oFetch(
    staffMember,
    'hasLinkedMossStaffMember',
    'linkedMossStaffMemberId',
  );
  const avatar = oFetch(staffMember, 'avatar');
  const fullName = `${oFetch(staffMember, 'first_name')} ${oFetch(staffMember, 'surname')}`;
  const masterVenueId = oFetch(staffMember, 'master_venue');
  const masterVenue =
    masterVenueId &&
    venues.find(venue => {
      return oFetch(venue, 'id') === masterVenueId;
    });
  const email = oFetch(staffMember, 'email');
  const phoneNumber = oFetch(staffMember, 'phone_number');
  const disabled = oFetch(staffMember, 'disabled');
  const supportsFacialRecognition = oFetch(staffMember, 'supportsFacialRecognition');
  const isFlagged = oFetch(staffMember, 'is_flagged');
  const disabledByUser = oFetch(staffMember, 'disabled_by_user');
  const disabledAt = oFetch(staffMember, 'disabled_at');
  const disabledReason = oFetch(staffMember, 'disable_reason');
  const jobTypeName = oFetch(jobType, 'name');
  const jobTypeColor = oFetch(jobType, 'color');
  const bouncedEmail = oFetch(staffMember, 'bounced_email');
  const markedRetakeAvatar = oFetch(staffMember, 'markedRetakeAvatar');
  const [canMarkRetakeAvatar, canAccessUserPage] = oFetch(
    permissionsData.toJS(),
    'canMarkRetakeAvatar',
    'canAccessUserPage',
  );
  const siaBadgeValid = oFetch(staffMember, 'siaBadgeValid');
  const isNonStewardSecurity = oFetch(staffMember, 'isNonStewardSecurity');

  const renderPhoneNumber = phoneNumber => {
    return phoneNumber ? (
      <a href={`tel:${phoneNumber}`} className="boss-user-summary__link boss-user-summary__link_role_phone">
        {phoneNumber}
      </a>
    ) : null;
  };

  const renderEmail = email => {
    return email ? renderEmailOrBounced(email) : null;
  };

  const showBouncedModal = bouncedEmail => {
    bouncedEmailModal(bouncedEmail);
  };

  const renderEmailOrBounced = email => {
    return bouncedEmail ? (
      <p
        onClick={() => showBouncedModal(bouncedEmail)}
        className="boss-user-summary__link boss-user-summary__link_role_alert-action"
      >
        {email}
      </p>
    ) : (
      <a href={`mailto:${email}`} className="boss-user-summary__link boss-user-summary__link_role_email">
        {email}
      </a>
    );
  };

  const renderFullName = (fullName, disabled = false) => {
    return disabled ? `${fullName} (Disabled)` : fullName;
  };

  const renderMasterVenue = masterVenue => {
    return (
      <ul className="boss-user-summary__review-list">
        <li className="boss-user-summary__review-item boss-user-summary__review-item_role_venue">
          <span className="boss-user-summary__review-marked">{oFetch(masterVenue, 'name')}</span>
        </li>
      </ul>
    );
  };

  const renderCardContacts = (email, phoneNumber) => {
    return (
      <div className="boss-user-summary__contacts">
        {renderEmail(email)}
        {renderPhoneNumber(phoneNumber)}
      </div>
    );
  };

  const renderDisabledContent = ({ isFlagged, disabledByUser, disabledAt, disabledReason }) => {
    const disabledAtFormatted = safeMoment.iso8601Parse(disabledAt).format('Do MMMM YYYY');
    return (
      <ul className="boss-user-summary__review-list">
        <li className="boss-user-summary__review-item">
          <span className="boss-user-summary__review-label">Disabled by: </span>
          <span className="boss-user-summary__review-val">
            <span>
              {disabledByUser} on {disabledAtFormatted}
            </span>
          </span>
        </li>
        <li className="boss-user-summary__review-item">
          <span className="boss-user-summary__review-label">Reason for disabling: </span>
          <span className="boss-user-summary__review-val">{disabledReason}</span>
        </li>
        <li className="boss-user-summary__review-item">
          <span className="boss-button boss-button_type_small boss-button_type_no-behavior boss-button_role_exclamation boss-user-summary__label">
            {isFlagged ? 'Flagged' : 'Disabled'}
          </span>
        </li>
      </ul>
    );
  };

  const avatarContainerClasses = cn('boss-user-summary__avatar', {
    'boss-user-summary__avatar_type_scannable': supportsFacialRecognition,
    'boss-user-summary__avatar_type_verification-fail': isNonStewardSecurity && !siaBadgeValid,
  });

  const isAccessoriesInPossessionExists = accessoriesInPossession.size > 0;

  function renderAccessoriesInPossession(accessoriesInPossessionJS) {
    return (
      <div className="boss-user-summary__indicators">
        {accessoriesInPossessionJS.map(accessoryData => {
          const accessory = oFetch(accessoryData, 'accessory');
          const accessoriesCount = oFetch(accessoryData, 'count');
          const [id, name, icon, iconColor, venueName] = oFetch(
            accessory,
            'id',
            'name',
            'icon',
            'iconColor',
            'venueName',
          );
          return (
            <div key={id} className="boss-indicator boss-indicator_adjust_tooltip boss-indicator_adjust_flow">
              <div
                className={`boss-indicator__icon boss-indicator__icon_${icon}`}
                style={{ color: iconColor }}
              />
              {accessoriesCount > 1 && <div className="boss-indicator__counter">{accessoriesCount}</div>}
              <div className="boss-indicator__tooltip">
                <span className="boss-indicator__tooltip-line">
                  <span className="boss-indicator__tooltip-marked">{name}</span>
                  <span className="boss-indicator__tooltip-line">{venueName}</span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function renderStaffType() {
    if (!isNonStewardSecurity || siaBadgeValid) {
      return (
        <span
          className="boss-button boss-button_type_small boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: jobTypeColor }}
        >
          {jobTypeName}
        </span>
      );
    }
    return (
      <span className="boss-user-summary__label-box boss-user-summary__label-box_role_alert">
        <span
          className="boss-button boss-button_type_small boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: jobTypeColor }}
        >
          {jobTypeName}
        </span>
        <Tooltip
          arrow
          theme="dark"
          position="bottom"
          interactive
          html={`SIA badge number was not properly verified for this staff member. Please re-enter the SIA badge number to verify the staff member.`}
        >
          <span className="boss-user-summary__label-indicator">
            <span
              className="boss-indicator boss-indicator_color_accent-red boss-indicator_role_action js-tippy"
              aria-expanded="false"
            >
              <span className="boss-indicator__icon boss-indicator__icon_exclamation-circle-solid boss-indicator__icon_size_xl" />
            </span>
          </span>
        </Tooltip>
      </span>
    );
  }

  return (
    <div className="boss-page-dashboard__user-summary">
      <div className="boss-user-summary">
        <div className="boss-user-summary__side">
          <div className={avatarContainerClasses}>
            <div className="boss-user-summary__avatar-inner">
              <img src={avatar} alt="avatar" />
              {!disabled && (
                <button
                  className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_edit"
                  onClick={() => onEditAvatar()}
                >
                  Edit
                </button>
              )}
              {canMarkRetakeAvatar && !markedRetakeAvatar && (
                <button
                  type="button"
                  className="boss-user-summary__avatar-icon boss-user-summary__avatar-icon_role_retake"
                  onClick={onMarkRetakeAvatar}
                >
                  Retake
                </button>
              )}
              {markedRetakeAvatar && (
                <div className="boss-user-summary__avatar-overlay">
                  <p className="boss-user-summary__avatar-overlay-text boss-user-summary__avatar-overlay-text_role_retake">
                    Please retake picture
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="boss-user-summary__content">
          <div className="boss-user-summary__header">
            <h2 className="boss-user-summary__name">{renderFullName(fullName, disabled)}</h2>
            {renderStaffType()}
          </div>
          {(hasLinkedUser || hasLinkedMossStaffMember) && (
            <div className="boss-user-summary__header">
              {hasLinkedUser && (
                <div className="boss-user-summary__indicator">
                  {!canAccessUserPage && (
                    <span className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain" />
                  )}
                  {canAccessUserPage && (
                    <a
                      href={appRoutes.userProfile(linkedUserId)}
                      target="_blank"
                      rel="noreferrer"
                      className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                    >
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain"
                        style={{ marginRight: '5px' }}
                      />
                      Linked User
                    </a>
                  )}
                </div>
              )}
              {hasLinkedMossStaffMember && (
                <div className="boss-user-summary__indicator">
                  <a
                    href={appRoutes.mossStaffMemberProfile({ mossStaffMemberId: linkedMossStaffMemberId })}
                    target="_blank"
                    rel="noreferrer"
                    className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                  >
                    <span
                      className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain"
                      style={{ marginRight: '5px' }}
                    />
                    Linked Moss staff member
                  </a>
                </div>
              )}
            </div>
          )}

          {disabled &&
            renderDisabledContent({
              isFlagged,
              disabledByUser,
              disabledAt,
              disabledReason,
            })}
          {isAccessoriesInPossessionExists && renderAccessoriesInPossession(accessoriesInPossessionJS)}
          {masterVenue && renderMasterVenue(masterVenue)}
          {renderCardContacts(email, phoneNumber)}
        </div>
      </div>
    </div>
  );
};

export default StaffMemberCard;
