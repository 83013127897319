import React from 'react';
import { Entries } from 'type-fest';
import { Dashboard, MonthCard, PublicHolidayForm, PublicHolidayItem, PublicHolidayList, PublicHolidaysFormValues } from './components';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { usePublicHolidays } from './use-public-holidays';
import { PublicHolidaysAppProps } from '.';
import { openWarningModal } from '@/components/modals';
import { DeleteModalConfirmParams, PublicHoliday } from './types/public-holiday';
import oFetch from 'o-fetch';

export function PublicHolidays(props: PublicHolidaysAppProps) {
  const { openModal } = useModal();
  const { createPublicHoliday, deletePublicHoliday, groupedPublicHolidaysByMonth, selectedYear, years, onYearSelect } = usePublicHolidays(props);
  const publicHolidaysExist = Object.keys(groupedPublicHolidaysByMonth).length > 0;

  function openAddPublicHolidayModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PublicHolidayForm,
      onSubmit: (closeModal: any, values: PublicHolidaysFormValues) => {
        return createPublicHoliday(
          {
            values,
            onSuccess() {
              closeModal();
            }
          }
        );
      },
      config: {
        title: 'New Public Holiday',
      },
      props: {
        initialValues: {
          date: '',
          description: '',
        },
      },
    });
  }

  function handleDeletePublicHoliday(publicHoliday: PublicHoliday) {
    openWarningModal({
      submit: (closeModal: () => void, params: DeleteModalConfirmParams) => {
        const publicHoliday = oFetch(params, 'publicHoliday');
        const publicHolidayId = oFetch(publicHoliday, 'id');
        return deletePublicHoliday({
          publicHolidayId,
          onSuccess() {
            closeModal();
          }
        });
      },
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Disable',
      },
      props: { publicHoliday },
    });
  }

  return (
    <>
      <Dashboard
        onAddClick={openAddPublicHolidayModal}
        selectedYear={selectedYear}
        onYearSelect={onYearSelect}
        years={years}
      />
      <ContentWrapper>
        {!publicHolidaysExist && <h1>No public holidays exist</h1>}
        {publicHolidaysExist && (Object.entries(groupedPublicHolidaysByMonth) as Entries<typeof groupedPublicHolidaysByMonth>).map((publicHolidayEntry) => {
          const [month, publicHolidays] = publicHolidayEntry;
          return (
            <MonthCard
              key={month}
              title={month as string}
            >
              <PublicHolidayList
                publicHolidays={publicHolidays}
                renderItem={(publicHoliday) => (
                  <PublicHolidayItem
                    publicHoliday={publicHoliday}
                    onDelete={handleDeletePublicHoliday}
                  />
                )}
              />
            </MonthCard>
          );
        })}
      </ContentWrapper>
    </>
  );
}
