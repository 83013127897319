import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import ModalWrapper from './modal-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import RotaDailyGraphFilter from './rota-daily-graph-filter';
import RotaGraph from './rota-graph';
import AddShifts from './add-shifts';
import GraphDetails from './graph-details';
import { BOSS_VENUE_TYPE } from '@/lib/utils';
import oFetch from 'o-fetch';

import {
  showGraphDetails,
  closeGraphDetails,
  openMultipleShift,
  closeMultipleShift,
  setVenuesFilter,
} from '../actions';

import {
  appTypeSelector,
  rotasSelector,
  venueIdsForCurrentDaySelector,
  venuesSelector,
  staffTypesSelector,
  sRotaDateSelector,
  rotaShiftsSelector,
  staffMembersSelector,
  venuesFilterIdsSelector,
  getVenueTypes,
  getRotaShifts,
} from '../selectors';

const mapStateToProps = state => {
  return {
    appType: appTypeSelector(state),
    rotas: rotasSelector(state),
    venueIdsForCurrentDay: venueIdsForCurrentDaySelector(state),
    venues: venuesSelector(state),
    staffTypes: staffTypesSelector(state),
    sRotaDate: sRotaDateSelector(state),
    rotaShifts: rotaShiftsSelector(state),
    filteredRotaShifts: getRotaShifts(state),
    staffMembers: staffMembersSelector(state),
    isAddingNewShift: state.getIn(['page', 'isAddingNewShift']),
    isGraphDetailsOpen: state.getIn(['page', 'isGraphDetailsOpen']),
    graphDetails: state.getIn(['page', 'graphDetails']),
    isMultipleShift: state.getIn(['page', 'isMultipleShift']),
    venuesFilterIds: venuesFilterIdsSelector(state),
    venueTypes: getVenueTypes(state),
    securityShiftRequestsCount: state.getIn(['page', 'securityShiftRequestsCount']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        showGraphDetails,
        closeGraphDetails,
        openMultipleShift,
        closeMultipleShift,
        setVenuesFilter,
      },
      dispatch,
    ),
  };
};

class RotaDailyContent extends React.Component {
  handleGraphVenueChange = venueIds => {
    this.props.actions.setVenuesFilter(venueIds);
  };

  handleShiftClick = shift => {
    this.props.actions.showGraphDetails(shift);
  };

  closeGraphDetails = () => {
    this.props.actions.closeGraphDetails();
  };

  getVenueTypes() {
    return this.props.venueTypes.map(venue => venue.set('id', `${venue.get('type')}_${venue.get('id')}`));
  }

  render() {
    const {
      rotas,
      isAddingNewShift,
      staffTypes,
      staffMembers,
      isGraphDetailsOpen,
      graphDetails,
      isMultipleShift,
      venues,
      actions: { openMultipleShift, closeMultipleShift },
      filteredRotaShifts,
      securityShiftRequestsCount,
    } = this.props;
    const sRotaDate = oFetch(this.props, 'sRotaDate');

    const rotaGraphClassName = isAddingNewShift ? 'boss-rotas__graphs_state_mobile-hidden' : '';
    const addShiftsClassName = !isAddingNewShift ? 'boss-rotas__manager_state_mobile-hidden' : '';
    const rotaStatus =
      graphDetails && graphDetails.getIn(['originalShiftObject', 'venueType']) === BOSS_VENUE_TYPE
        ? rotas.find(r => r.get('id') === graphDetails.getIn(['originalShiftObject', 'rota'])).status
        : '';
    return (
      <ContentWrapper>
        <div className="boss-rotas">
          <div className={`boss-rotas__graphs ${rotaGraphClassName}`}>
            <ModalWrapper
              show={isGraphDetailsOpen}
              onClose={this.closeGraphDetails}
            >
              {isGraphDetailsOpen && (
                <GraphDetails
                  rotaShift={graphDetails.get('originalShiftObject')}
                  staffMember={graphDetails.get('staff')}
                  rotaStatus={rotaStatus}
                  staffTypes={staffTypes}
                  venueTypes={this.getVenueTypes()}
                />
              )}
            </ModalWrapper>
            <RotaDailyGraphFilter
              selectedTypes={this.props.venuesFilterIds.toJS()}
              venueTypes={this.getVenueTypes()}
              securityShiftRequestsCount={securityShiftRequestsCount}
              sRotaDate={sRotaDate}
              onVenueChange={this.handleGraphVenueChange}
            />
            <RotaGraph
              rotaShifts={filteredRotaShifts}
              totalRotaShifts={this.props.rotaShifts.size}
              staffTypes={staffTypes.toJS()}
              staffMembers={staffMembers.toJS()}
              onShiftClick={this.handleShiftClick}
              venueTypes={this.getVenueTypes()}
            />
          </div>
          <AddShifts
            venueTypes={this.getVenueTypes()}
            venues={venues}
            staffTypes={staffTypes}
            staffMembers={this.props.staffMembers}
            rotas={rotas}
            className={addShiftsClassName}
            isMultipleShift={isMultipleShift}
            onOpenMultipleShift={openMultipleShift}
            onCloseMultipleShift={closeMultipleShift}
          />
        </div>
      </ContentWrapper>
    );
  }
}

RotaDailyContent.propTypes = {
  rotas: ImmutablePropTypes.list.isRequired,
  venueIdsForCurrentDay: ImmutablePropTypes.set.isRequired,
  venues: ImmutablePropTypes.list.isRequired,
  staffTypes: ImmutablePropTypes.list.isRequired,
  sRotaDate: PropTypes.string.isRequired,
  rotaShifts: ImmutablePropTypes.list.isRequired,
  staffMembers: ImmutablePropTypes.list.isRequired,
  venuesFilterIds: ImmutablePropTypes.list.isRequired,
  isAddingNewShift: PropTypes.bool.isRequired,
  isGraphDetailsOpen: PropTypes.bool.isRequired,
  isMultipleShift: PropTypes.bool.isRequired,
  graphDetails: ImmutablePropTypes.map,
  actions: PropTypes.object.isRequired,
  venueTypes: PropTypes.array.isRequired,
  filteredRotaShifts: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RotaDailyContent);
