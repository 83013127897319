import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';

import {
  updateStaffMember,
  updateDownloadLinkLastSentAt,
  updateStaffMemberBankDetailsAction,
} from '../profile-wrapper/actions';

export const updateStaffMemberBankDetails = params => (dispatch, getState) => {
  const [staffMember, values, onSuccess] = oFetch(params, 'staffMember', 'values', 'onSuccess');
  const staffMemberId = oFetch(staffMember, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [utils.BASE_ERROR_KEY, 'bankAccountNumber', 'bankSortCode', 'altBankingFullname'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({
          message: 'There was a problem updating bank details',
        });
        return normalizedErrors;
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      dispatch(updateStaffMemberBankDetailsAction(data));
      globalNotifications.showDefaultSuccessMessage({
        message: 'Updating bank details successful',
      });
      onSuccess(data);
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/update_bank_details`, { ...values });
};

export const sendStaffPartyInviteEmail = (args) => () => {
  const staffMemberId = oFetch(args, 'staffMemberId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({
        message: 'There was a problem sending the invite email',
      });
      return true;
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');

      globalNotifications.showDefaultSuccessMessage({
        message: 'Sending invite email successful',
      });
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/send_staff_party_invite_email`);
};

export const sendMobileAppDownloadEmail = (endpointUrl, appName, mobileAppId) => (
  dispatch,
  getState,
) => {
  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({
        message: 'There was a problem sending the download email for ' + appName,
      });
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const sentAt = oFetch(data, 'sentAt');

      dispatch(
        updateDownloadLinkLastSentAt({
          mobileAppId: mobileAppId,
          sentAt: sentAt,
        }),
      );
      globalNotifications.showDefaultSuccessMessage({
        message: 'Download Email Sent Successfully for ' + appName,
      });
    },
  }).post(endpointUrl, { mobileAppId: mobileAppId });
};

export const sendPasswordSetupEmail = staffMember => (dispatch, getState) => {
  const staffMemberId = oFetch(staffMember, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({
        message: 'There was a problem sending the setup email',
      });
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');

      dispatch(updateStaffMember(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Sending setup email successful' });
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/send_verification`);
};

export const resendPasswordSetupEmail = staffMember => (dispatch, getState) => {
  const staffMemberId = oFetch(staffMember, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({
        message: 'There was a problem resending the setup email',
      });
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');

      dispatch(updateStaffMember(data));
      globalNotifications.showDefaultSuccessMessage({
        message: 'Resending setup email successful',
      });
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/resend_verification`);
};

export const revokePasswordSetupEmail = staffMember => (dispatch, getState) => {
  const staffMemberId = oFetch(staffMember, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({
        message: 'There was a problem revoking the setup email successful',
      });
      return true;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');

      dispatch(updateStaffMember(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Revoking setup email successful' });
    },
  }).post(`/api/v1/staff_members/${staffMemberId}/revoke_verification`);
};

export const allowClockingWithoutFacialRecognitionAction = staffMember => (dispatch, getState) => {
  const staffMemberId = oFetch(staffMember, 'id');
  const path = apiRoutes.allowClockingWithoutFacialRecognition.getPath(staffMemberId);

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');

      dispatch(updateStaffMember(data));
    },
  }).post(path);
};

export const disallowClockingWithoutFacialRecognitionAction = staffMember => (
  dispatch,
  getState,
) => {
  const staffMemberId = oFetch(staffMember, 'id');
  const path = apiRoutes.disallowClockingWithoutFacialRecognition.getPath(staffMemberId);

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');

      dispatch(updateStaffMember(data));
    },
  }).post(path);
};
