import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';
import {
  Dashboard,
  ContentWrapper,
  ColumnsGroup,
  TaskItem,
  Column,
  ReviewTaskItem,
  ReviewColumn,
} from './components';
import { useMarketingTaskModals } from './marketing-task-modals';

export function MarketingTasks(props) {
  const tasksByStatus = useSelector(selectors.tasksByStatusSelector);

  const { openAddNewTaskModal, openTaskDetailsModal } = useMarketingTaskModals();

  function handleHashChange() {
    const parsedHash = queryString.parse(window.location.hash);

    if (parsedHash.id) {
      const allTasks = [
        ...oFetch(tasksByStatus, 'upForGrabs'),
        ...oFetch(tasksByStatus, 'inProgress'),
        ...oFetch(tasksByStatus, 'inReview'),
      ];
      const taskFromHash = allTasks.find(task => oFetch(task, 'id') === parseInt(parsedHash.id));
      if (!taskFromHash) {
        let scrollV;
        let scrollH;
        const loc = window.location;
        if ('pushState' in window.history)
          window.history.pushState('', document.title, loc.pathname + loc.search);
        else {
          // Prevent scrolling by storing the page's current scroll offset
          scrollV = document.body.scrollTop;
          scrollH = document.body.scrollLeft;
          loc.hash = '';
          // Restore the scroll offset, should be flicker free
          document.body.scrollTop = scrollV;
          document.body.scrollLeft = scrollH;
        }
      } else {
        openTaskDetailsModal(taskFromHash, actions.getMarketingTaskDetails);
      }
    }
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('boss-body_adjust_marketing-tasks');
    Array.from(document.getElementsByClassName('boss-page-main')).forEach(el => {
      el.classList.add('boss-page-main_adjust_marketing-tasks');
    });
    handleHashChange();
  }, []);

  return (
    <>
      <Dashboard onAddNewClick={openAddNewTaskModal} />
      <ContentWrapper>
        <ColumnsGroup>
          <Column
            className="boss-tasks_role_up-for-grabs"
            title="Up for Grabs"
            showAssignOnMe={false}
            tasks={oFetch(tasksByStatus, 'upForGrabs')}
            renderTask={task => <TaskItem task={task} />}
          />
          <Column
            className="boss-tasks_role_in-progress"
            title="In Progress"
            showAssignOnMe={true}
            tasks={oFetch(tasksByStatus, 'inProgress')}
            renderTask={task => <TaskItem task={task} />}
          />
          <ReviewColumn
            tasks={oFetch(tasksByStatus, 'inReview')}
            renderTask={task => <ReviewTaskItem task={task} />}
          />
        </ColumnsGroup>
      </ContentWrapper>
    </>
  );
}
