import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export default class EventItem extends Component {
  state = {
    isOpened: false,
  };
  toggleDropdown = () => {
    this.setState(state => ({ isOpened: !oFetch(state, 'isOpened') }));
  };
  renderActions = params => {
    const [event, completed] = oFetch(params, 'event', 'event.completed');
    if (completed === true) {
      return null;
    }
    const [onEditClick, onDeleteClick] = oFetch(this.props, 'onEditClick', 'onDeleteClick');
    const permissions = oFetch(this.props, 'permissions');
    const eventPermission = permissions[oFetch(event, 'id')];
    const [canEdit, canDelete] = oFetch(eventPermission, 'canEdit', 'canDelete');
    return (
      <div className="boss-check__header-actions">
        {canEdit && (
          <div
            onClick={() => onEditClick(event)}
            className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_edit"
          />
        )}
        {canDelete && (
          <div
            onClick={() => onDeleteClick(event)}
            className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove"
          />
        )}
      </div>
    );
  };
  render() {
    const isOpened = oFetch(this.state, 'isOpened');
    const event = oFetch(this.props, 'event');
    const [id, title, description, sortData, venues, createdBy, createdAt, completed, startsAt, endsAt] = oFetch(
      event,
      'id',
      'title',
      'description',
      'sortData',
      'venues',
      'createdBy',
      'createdAt',
      'completed',
      'startsAt',
      'endsAt',
    );
    const arrowClassNames = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
      'boss-check__dropdown-link_state_closed': !isOpened,
    });
    const formattedStartsAt = safeMoment.iso8601Parse(startsAt).format(utils.commonDateFormatTimeOnly());
    const formattedEndsAt = safeMoment.iso8601Parse(endsAt).format(utils.commonDateFormatTimeOnly());
    const eventClassNames = cn('boss-check boss-check_role_panel boss-check_page_handover-planner-event', {
      'boss-check_state_inactive-faded': completed,
    });
    const titleClassNames = cn('boss-check__title', {
      'boss-check__title_adjust_crossed-out': completed,
    });
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime());
    return (
      <div data-id={sortData} className={eventClassNames}>
        <div className="boss-check__handle boss-check__handle_position_left boss-check__handle_role_event" />
        <div className="boss-check__header">
          <div className="boss-check__header-info">
            <div className="boss-check__header-group">
              <h3 className={titleClassNames}>{title}</h3>
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_time">
                    {`${formattedStartsAt} - ${formattedEndsAt}`}
                  </p>
                </div>
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text boss-check__text_role_meta boss-check__text_role_venue">{venues}</p>
                </div>
              </div>
            </div>
            <div className="boss-check__header-actions">
              {this.renderActions({ event })}
              <div onClick={this.toggleDropdown} className={arrowClassNames}>
                Toggle Dropdown
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpened={isOpened} className="boss-check__dropdown" style={{ display: 'block' }}>
          <div className="boss-check__group boss-check__group_marked">
            <div className="boss-check__group-content">
              <div className="boss-check__box">
                <p className="boss-check__text">{description}</p>
              </div>
            </div>
          </div>
          <div className="boss-check__group">
            <div className="boss-check__group-content">
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Created by </span>
                    <span className="boss-check__text-marked">{createdBy}</span>
                    <span className="boss-check__text-light"> at </span>
                    <span className="boss-check__text-marked">{formattedCreatedAt}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
