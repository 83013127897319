import React from 'react';
import utils from '@/lib/utils';
import { modalRedux } from '@/components/modals';
import { combineReducers } from 'redux';
import { reducer as formReducer, SubmissionError } from 'redux-form';
import MarkMisattributedLoginHandledForm from './mark-misattributed-login-handled-form';

class MarkHandledModal extends React.Component {
  render() {
    const initialValues = { id: this.props.recordId, reason: null };

    return <MarkMisattributedLoginHandledForm initialValues={initialValues} onSubmit={this.props.onSubmit} />;
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(MarkHandledModal);
