import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import oFetch from 'o-fetch';

import configureStore from '../store';

import AddMossStaffMemberUI from './add-moss-staff-member-ui';

export function AddMossStaffMemberApp(props) {
  const [store, setStore] = useState(null);
  const accessToken = oFetch(props, 'accessToken');
  const mossStaffTypes = oFetch(props, 'mossStaffTypes');
  const venues = oFetch(props, 'venues');
  const validateAvatarForCreateUrl = oFetch(props, 'validateAvatarForCreateUrl');
  const mossPayRates = oFetch(props, "mossPayRates");
  const linkableStaffMemberOptions = oFetch(props, 'linkableStaffMemberOptions');

  useEffect(() => {
    const store = configureStore(combineReducers({ form: formReducer }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <AddMossStaffMemberUI
        accessToken={accessToken}
        venues={venues}
        validateAvatarForCreateUrl={validateAvatarForCreateUrl}
        mossStaffTypes={mossStaffTypes}
        mossPayRates={mossPayRates}
        linkableStaffMemberOptions={linkableStaffMemberOptions}
      />
    </Provider>
  );
}