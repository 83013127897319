import React, { Fragment } from 'react';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { WarningModalContent } from '@/components/modal-content';
import { LegacyCloseInsideModalWrapper, LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

import {
  MossPayRateGroup,
  MossPayRatesList,
  MossPayRateItem,
  AddMossPayRateModal,
  EditMossPayRateModal,
} from './components';
import useMossPayRatesApiCalls from './use-moss-pay-rates-api-calls';
import oFetch from 'o-fetch';

export default function MossPayRates(props) {
  const { openModal } = useModal();
  const [
    normalRates,
    mossPayRatesOptions,
    createMossPayRate,
    updateMossPayRate,
    deleteMossPayRate,
  ] = oFetch(
    useMossPayRatesApiCalls(props),
    'normalRates',
    'mossPayRatesOptions',
    'createMossPayRate',
    'updateMossPayRate',
    'deleteMossPayRate',
  );
  const abilities = oFetch(props, "abilities");

  function renderMossPayRate(args) {
    const mossPayRate = oFetch(args, 'mossPayRate');
    const itemAbilities = oFetch(args, "itemAbilities");

    const onDeleteClick = oFetch(itemAbilities, "canDelete") ? onDeleteMossPayRate : () => {};
    const onEditClick = oFetch(itemAbilities, "canEdit") ? () => openEditMossPayRateModal(mossPayRate) : () => {};

    return (
      <MossPayRateItem
        mossPayRate={mossPayRate}
        config
        itemAbilities={itemAbilities}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    );
  }

  function openEditMossPayRateModal(mossPayRate) {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditMossPayRateModal,
      onSubmit(handleClose, values) {
        return updateMossPayRate({ values, handleClose });
      },
      config: {
        title: 'Update Pay Rate',
      },
      props: {
        mossPayRatesOptions: mossPayRatesOptions,
        mossPayRate: mossPayRate,
      },
    });
  }

  function onDeleteMossPayRate(mossPayRate) {
    return openModal({
      ModalContent: WarningModalContent,
      ModalComponent: LegacyCloseOutsideModalWrapper,
      onSubmit(handleClose) {
        handleClose();
        return deleteMossPayRate({ mossPayRate });
      },
      config: {
        title: 'WARNING !!!',
      },
      props: {
        foo: 'bar',
        text: 'Are you sure ?',
        buttonText: 'Delete',
      },
    });
  }

  function openAddNewMossPayRateModal() {
    const initalOption = oFetch(mossPayRatesOptions, '0');
    const initialCalculationType = oFetch(initalOption, 'value');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddMossPayRateModal,
      onSubmit(handleClose, values) {
        return createMossPayRate({ values, handleClose });
      },
      config: {
        title: 'Add Moss Pay Rate',
      },
      props: {
        mossPayRatesOptions,
        initialCalculationType,
      },
    });
  }

  const normalMossPayRateAbilities = {
    canDelete: oFetch(abilities, "canRemoveMossPayRates"),
    canEdit: oFetch(abilities, "canEditMossPayRates"),
  }

  const canCreateNormalMossPayRates = oFetch(abilities, "canCreateMossPayRates");
  const normalOnAddClick = canCreateNormalMossPayRates ? openAddNewMossPayRateModal : () => {};

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Moss Pay Rates</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <MossPayRateGroup hasCreateAbility={canCreateNormalMossPayRates} onAddClick={normalOnAddClick} title="Pay Rates" buttonText="Add New">
          <MossPayRatesList
            mossPayRates={normalRates}
            itemRender={mossPayRate => renderMossPayRate({
              mossPayRate,
              itemAbilities: normalMossPayRateAbilities,
            })}
            description="These pay rates will be visible and selectable by all managers"
          />
        </MossPayRateGroup>
      </ContentWrapper>
    </Fragment>
  );
}
