import { connect } from 'react-redux';

import SecurityShiftRequestsPage from '../components/security-shift-requests-page';
import {
  getPendingSecurityShiftRequests,
  getCompletedSecurityShiftRequests,
  venuesSelector,
  countsSelector,
} from '../selectors';
import {
  addSecurityShiftRequest,
  editSecurityShiftRequest,
  deleteSecurityShiftRequest,
  changeWeekDay,
  getSecurityShiftRequestsByDate,
} from '../redux/actions';
import oFetch from 'o-fetch';

const mapStateToProps = state => {
  return {
    pendingSecurityShiftRequests: getPendingSecurityShiftRequests(state),
    completedSecurityShiftRequests: getCompletedSecurityShiftRequests(state),
    startDate: oFetch(state, 'pageOptions.startDate'),
    endDate: oFetch(state, 'pageOptions.endDate'),
    date: oFetch(state, 'pageOptions.date'),
    sChosenDate: oFetch(state, 'pageOptions.chosenDate'),
    canCreate: oFetch(state, 'pageOptions.canCreate'),
    accessibleVenues: venuesSelector(state),
    counts: countsSelector(state),
  };
};

const mapDispatchToProps = {
  addSecurityShiftRequest,
  editSecurityShiftRequest,
  deleteSecurityShiftRequest,
  changeWeekDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityShiftRequestsPage);
