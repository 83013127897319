import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = false;
export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const paymentWeekPublished = oFetch(action, 'payload.paymentWeekPublished');
      return paymentWeekPublished;
    },
  },
  initialState,
);
