import oFetch from 'o-fetch';
import { http } from '@/lib/request-api';

export const addVenueRequest = params => {
  return http().post(`/api/v1/venues/`, params);
};

export const editVenueRequest = params => {
  const venueId = oFetch(params, 'venueId');
  return http().put(`/api/v1/venues/${venueId}`, params);
};

export const disableVenueRequest = params => {
  const venueId = oFetch(params, 'venueId');
  return http().post(`api/v1/venues/${venueId}/disable`);
};

export const enableVenueRequest = params => {
  const venueId = oFetch(params, 'venueId');
  return http().post(`api/v1/venues/${venueId}/enable`)
}
