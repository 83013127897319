import oFetch from 'o-fetch';
import React from 'react';
import { openImageModal, openWarningModal } from '@/components/modals';
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';
import { mGetTimeAsterisk } from '@/lib/rota-date';
import { MOSS_STAFF_MEMBER_TYPE } from '../constants';

export const getOwedHourUIData = args => {
  const mossHourTags = oFetch(args, 'mossHourTags');
  const owedHourJS = oFetch(args, 'owedHour');
  const hasDate = oFetch(owedHourJS, 'hasDate');
  const owedHourType = oFetch(owedHourJS, 'owedHourType');
  const sFormattedDate = safeMoment.uiDateParse(oFetch(owedHourJS, 'date')).format('ddd DD MMM YYYY');
  let sTimes = 'N/A';
  if (hasDate) {
    const mStartsAt = safeMoment
      .iso8601Parse(oFetch(owedHourJS, 'times.startsAt'));
    const mEndsAt = safeMoment
      .iso8601Parse(oFetch(owedHourJS, 'times.endsAt'));

    sTimes = `${mGetTimeAsterisk({ mTime: mStartsAt })}${mStartsAt.format('HH:mm')} - ${ mGetTimeAsterisk({ mTime: mEndsAt })}${mEndsAt.format('HH:mm')}`;
  }
  const effectiveStaffMemberType = oFetch(args, 'effectiveStaffMemberType');
  const isMoss = effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE;
  let owedHourMossHourTags = [];
  if (isMoss) {
    const mossHourTagIds = oFetch(owedHourJS, 'mossHourTagIds');
    mossHourTags.forEach((mossHourTag) => {
      if (mossHourTagIds.includes(mossHourTag.id) === true) {
        owedHourMossHourTags.push(mossHourTag);
      }
    });
  }

  const durationHours = oFetch(owedHourJS, 'duration.hours');
  const durationMinutes = oFetch(owedHourJS, 'duration.minutes');
  const note = owedHourJS.note || '-';
  const creator = oFetch(owedHourJS, 'createdBy');
  const created = safeMoment
    .iso8601Parse(oFetch(owedHourJS, 'createdAt'))
    .utcOffset(oFetch(owedHourJS, 'createdAt'))
    .format('Do MMMM YYYY - HH:mm');
  const editable = oFetch(owedHourJS, 'editable');
  const payslipDate = oFetch(owedHourJS, 'payslipDate');
  const frozen = oFetch(owedHourJS, 'frozen');
  const startsAtCCTVImage = oFetch(owedHourJS, 'startsAtCCTVImage');
  const endsAtCCTVImage = oFetch(owedHourJS, 'endsAtCCTVImage');

  return {
    hasDate,
    sFormattedDate,
    sTimes,
    durationHours,
    durationMinutes,
    note,
    creator,
    created,
    editable,
    payslipDate,
    frozen,
    startsAtCCTVImage,
    endsAtCCTVImage,
    owedHourType,
    mossHourTags: owedHourMossHourTags,
  };
};

const ActionsCell = props => {
  const [
    label,
    owedHourId,
    deleteOwedHours,
    openEditModal,
    owedHour,
    editable,
    isStaffMemberDisabled,
    owedHourPermissions,
    frozen,
    mossHourTags,
  ] = oFetch(
    props,
    'label',
    'owedHourId',
    'deleteOwedHours',
    'openEditModal',
    'owedHour',
    'editable',
    'isStaffMemberDisabled',
    'owedHourPermissions',
    'frozen',
    'mossHourTags',
  );

  const onDelete = owedHourId => {
    openWarningModal({
      submit: hideModal => deleteOwedHours(owedHourId).then(hideModal),
      config: {
        title: 'Delete owed hours',
        text: 'Are You Sure?',
        buttonText: 'Delete',
      },
    });
  };

  const isEditable = oFetch(owedHourPermissions, 'isEditable');
  const isDeletable = oFetch(owedHourPermissions, 'isDeletable');

  return (
    <div className="boss-table__cell">
      {editable && !isStaffMemberDisabled && !frozen && (
        <div className="boss-table__info">
          <p className="boss-table__label">{label}</p>
          <p className="boss-table__actions">
            {isEditable && (
              <button
                onClick={() => openEditModal(owedHour, mossHourTags)}
                className="boss-button boss-button_type_small boss-button_role_update boss-table__action"
              >
                Edit
              </button>
            )}
            {isDeletable && (
              <button
                onClick={() => onDelete(owedHourId)}
                className="boss-button boss-button_type_small boss-button_role_cancel boss-table__action"
              >
                Delete
              </button>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

const SimpleCell = ({ label, text }) => {
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        <p className="boss-table__text">{text}</p>
      </div>
    </div>
  );
};

const CreatedByCell = ({ label, creator, created }) => {
  return (
    <div className="boss-table__cell">
      <div className="boss-table__info">
        <p className="boss-table__label">{label}</p>
        <p className="boss-table__text">
          <span className="boss-table__text-line">{creator}</span>
          <span className="boss-table__text-meta">{created}</span>
        </p>
      </div>
    </div>
  );
};

export const Row = (props) => {
  const effectiveStaffMemberType = oFetch(props, 'effectiveStaffMemberType');
  const owedHour = oFetch(props, 'owedHour');
  const deleteOwedHours = oFetch(props, 'deleteOwedHours');
  const openEditModal = oFetch(props, 'openEditModal');
  const isStaffMemberDisabled = oFetch(props, 'isStaffMemberDisabled');
  const owedHourPermissions = oFetch(props, 'owedHourPermissions');
  const mossHourTags = oFetch(props, 'mossHourTags');

  const [
    sFormattedDate,
    sTimes,
    durationHours,
    durationMinutes,
    creator,
    created,
    note,
    editable,
    payslipDate,
    frozen,
    startsAtCCTVImage,
    endsAtCCTVImage,
    owedHourType,
    owedHourMossHourTags,
  ] = oFetch(
    getOwedHourUIData({ owedHour, effectiveStaffMemberType, mossHourTags }),
    'sFormattedDate',
    'sTimes',
    'durationHours',
    'durationMinutes',
    'creator',
    'created',
    'note',
    'editable',
    'payslipDate',
    'frozen',
    'startsAtCCTVImage',
    'endsAtCCTVImage',
    'owedHourType',
    'mossHourTags',
  );

  const hasCCTVImages = startsAtCCTVImage && endsAtCCTVImage;
  const isOwedHourNormalType = owedHourType === 'normal';
  const isMoss = effectiveStaffMemberType === MOSS_STAFF_MEMBER_TYPE;

  return (
    <div className="boss-table__group">
      <div className={`boss-table__row ${frozen ? 'boss-table__row_state_frozen' : ''}`}>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">CCTV</p>
            {hasCCTVImages && (
              <div className="boss-table__info-group">
                <div className="boss-overview">
                  <div className="boss-overview__previews">
                    <div className="boss-overview__preview boss-overview__preview_role_action">
                      <p className="boss-overview__preview-meta">Start</p>
                      <img
                        className="boss-overview__preview-image boss-overview__preview-image_size_small"
                        src={startsAtCCTVImage}
                        onClick={() => openImageModal({ config: { image: startsAtCCTVImage } })}
                      />
                    </div>
                    <div className="boss-overview__preview boss-overview__preview_role_action">
                      <p className="boss-overview__preview-meta">End</p>
                      <img
                        className="boss-overview__preview-image boss-overview__preview-image_size_small"
                        src={endsAtCCTVImage}
                        onClick={() => openImageModal({ config: { image: endsAtCCTVImage } })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!hasCCTVImages && <p className="boss-table__text">N/A</p>}
          </div>
        </div>

        <SimpleCell label="Date" text={sFormattedDate} />
        <SimpleCell label="Times" text={sTimes} />
        <SimpleCell label="Duration (hours)" text={durationHours} />
        <SimpleCell label="Duration (minutes)" text={durationMinutes} />
        <CreatedByCell label="CreatedBy" creator={creator} created={created} />
        <SimpleCell label="Note" text={note} />
        <SimpleCell label="Payslip Date" text={payslipDate} />
        {/* { isMoss && (
          <SimpleCell
            label="Tags"
            text={owedHourMossHourTags.map(owedHourTag => `${owedHourTag.name}${owedHourTag.isDisabled ? ' (Disabled)' : ''}`).join(', ')}
          />
        )} */}
        <ActionsCell
          mossHourTags={mossHourTags}
          frozen={frozen}
          editable={editable && isOwedHourNormalType}
          label="Actions"
          owedHourId={oFetch(owedHour, 'id')}
          deleteOwedHours={deleteOwedHours}
          openEditModal={openEditModal}
          owedHour={owedHour}
          isStaffMemberDisabled={isStaffMemberDisabled}
          owedHourPermissions={owedHourPermissions}
        />
      </div>

      { isMoss && <div className="boss-table__details">
          <div className="boss-tag boss-tag_size_xxs boss-tag_role_key">Hours Tags:</div>
          { owedHourMossHourTags.map(mossHourTag => {
            return (
              <div
                key={`tag:${mossHourTag.id}`}
                className="boss-tag boss-tag_size_xxs boss-tag_role_label boss-tag_role_hours-tag"
              >
                <p className="boss-tag__text">{`${mossHourTag.name}${mossHourTag.isDisabled ? ' (Disabled)' : ''}`}</p>
              </div>
            );
          })}
      </div> }
    </div>
  );
};

const Header = () => {
  return (
    <div className="boss-table__row">
      <div className="boss-table__cell boss-table__cell_role_header">CCTV</div>
      <div className="boss-table__cell boss-table__cell_role_header">Date</div>
      <div className="boss-table__cell boss-table__cell_role_header">Times</div>
      <div className="boss-table__cell boss-table__cell_role_header">Duration (hours)</div>
      <div className="boss-table__cell boss-table__cell_role_header">Duration (minuts)</div>
      <div className="boss-table__cell boss-table__cell_role_header">Created By</div>
      <div className="boss-table__cell boss-table__cell_role_header">Note</div>
      <div className="boss-table__cell boss-table__cell_role_header">Payslip Date</div>
      <div className="boss-table__cell boss-table__cell_role_header" />
    </div>
  );
};

const OwedStats = (args) => {
  const rotaWeek = oFetch(args, 'rotaWeek');
  const sStartDate = moment(rotaWeek.startDate()).format('ddd DD MMM YYYY');
  const sEndDate = moment(rotaWeek.endDate()).format('ddd DD MMM YYYY');
  const totalHours = oFetch(args, 'totalHours');
  return (
    <div>
      <div className="boss-count boss-count_adjust_flow boss-count_type_solid">
        <p className="boss-count__label boss-count__label_role_date">
          {sStartDate} - {sEndDate}
        </p>
      </div>
      <div className="boss-count boss-count_adjust_flow boss-count_type_solid">
        <p className="boss-count__label boss-count__label_role_time">Total: {totalHours}</p>
      </div>
    </div>
  );
};

const OwedHoursTable = props => {
  const owedHourWeeks = oFetch(props, 'owedHourWeeks');
  const effectiveStaffMemberType = oFetch(props, 'effectiveStaffMemberType');

  const renderOwedHourRows = (owedHours, deleteOwedHours, openEditModal) => {
    return owedHours.map(owedHour => {
      const owedHourId = oFetch(owedHour, 'id');

      return React.cloneElement(props.owedHourRenderer(owedHour), {
        key: owedHourId,
      });
    });
  };

  const renderMobileItems = (owedHours, deleteOwedHours, openEditModal) => {
    return owedHours.map(owedHour => {
      const owedHourId = oFetch(owedHour, 'id');

      return React.cloneElement(props.owedHourMobileRenderer(owedHour), {
        key: owedHourId,
      });
    });
  };

  const renderOwedhours = args => {
    const owedHourWeeks = oFetch(args, 'owedHourWeeks');

    return owedHourWeeks.map((owedHourWeek, index) => {
      return (
        <div
          key={index}
          className="boss-board__manager-group boss-board__manager-group_context_stack"
        >
          <div className="boss-board__manager-stats boss-board__manager-stats_role_group-header">
            <OwedStats
              rotaWeek={oFetch(owedHourWeek, 'rotaWeek')}
              totalHours={oFetch(owedHourWeek, 'totalHours')}
            />
          </div>
          <div className="boss-board__manager-table">
            <div className="boss-table boss-table_page_smp-owed-hours">
              <Header />
              {renderOwedHourRows(oFetch(owedHourWeek, 'owedHours'))}
            </div>
            {renderMobileItems(oFetch(owedHourWeek, 'owedHours'))}
          </div>
        </div>
      );
    });
  };

  const hasOwedHours = !!oFetch(owedHourWeeks, 'length');

  return (
    <div>
      {hasOwedHours ? (
        renderOwedhours({ owedHourWeeks: owedHourWeeks })
      ) : (
        <h1 className="boss-table__cell boss-table__cell_role_header">NO OWED HOURS FOUND</h1>
      )}
    </div>
  );
};

export default OwedHoursTable;