import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class List extends Component {
  renderItems = () => {
    const [items, eventRenderer, reminderRenderer, taskRenderer] = oFetch(
      this.props,
      'items',
      'eventRenderer',
      'reminderRenderer',
      'taskRenderer',
    );
    return items.map(item => {
      const type = oFetch(item, 'type');

      if (type === 'event') {
        const id = oFetch(item, 'id');
        return React.cloneElement(eventRenderer(item), { key: `${type}-${id}` });
      } else if (type === 'reminder-date') {
        //Reminder dates are sorted based on reminder id
        const id = oFetch(item, 'handoverPlannerReminderId');
        return React.cloneElement(reminderRenderer(item), { key: `${type}-${id}` });
      } else if (type === 'task') {
        const id = oFetch(item, 'id');
        return React.cloneElement(taskRenderer(item), { key: `${type}-${id}` });
      } else {
        throw new Error("unsupported type " + type + " encountered")
      }
    });
  };

  render() {
    return <div className="boss-page-main__sub-group">{this.renderItems()}</div>;
  }
}
