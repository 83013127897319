import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';
import { AttachmentChangesDetailsButton } from './attachment-changes-details-button';

export function CreateAnswerEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, answer, attachments, at] = oFetch(
    event,
    'userFullName',
    'answer',
    'attachments',
    'at',
  );

  const formattedAt = useMemo(() => atFormat(at), [at]);
  const hasAttachments = attachments.length > 0;

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
      <p className="boss-overview__text">
        Answer created by {userFullName} @ {formattedAt}
      </p>
      <p className="boss-overview__text">
        <span className="boss-overview__text-large boss-overview__text-marked">{answer}</span>
      </p>
      {hasAttachments && (
        <AttachmentChangesDetailsButton
          text="Attachments added"
          oldAttachments={[]}
          newAttachments={attachments}
        />
      )}
    </li>
  );
}
