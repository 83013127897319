import React from 'react';
import oFetch from 'o-fetch';
import PayRatesForm from './pay-rates-form';

export default function EditPayRateModal(props) {
  const [onSubmit, payRateType, payRatesOptions, payRate] = oFetch(
    props,
    'onSubmit',
    'payRateType',
    'payRatesOptions',
    'payRate',
  );
  const initialValues = {
    id: oFetch(payRate, 'id'),
    name: oFetch(payRate, 'name'),
    calculationType: oFetch(payRate, 'calculationType'),
    rate: oFetch(payRate, 'rate'),
  };

  return (
    <PayRatesForm
      onSubmit={onSubmit}
      payRatesOptions={payRatesOptions}
      initialValues={initialValues}
      payRateType={payRateType}
      buttonTitle="Update Pay Rate"
    />
  );
}
