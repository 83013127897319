import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export function BonusPaymentsList(props) {
  const [bonusPayments, renderBonusPayment] = oFetch(props, 'bonusPayments', 'renderBonusPayment');
  const bonusPaymentsExist = bonusPayments.length > 0;

  if (!bonusPaymentsExist) {
    return <h3>No Bonus payments exist</h3>;
  }

  return (
    <div className="boss-board__content-inner boss-board__content-cards" style={{ flexDirection: 'column' }}>
      {bonusPayments.map(bonusPayment => {
        return React.cloneElement(renderBonusPayment(bonusPayment), { key: oFetch(bonusPayment, 'id') });
      })}
    </div>
  );
}
