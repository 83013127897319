import { useEffect } from 'react';

export function useUserActivity(params) {
  const ONE_SECOND = 1000;
  const {
    mouseup = true,
    keydown = true,
    scroll = true,
    mousemove = true,
    inactivitySeconds = 20,
    onInactive = () => {},
    onEvent = () => {},
  } = params;

  const predefinedEvents = ['mouseup', 'keydown', 'scroll', 'mousemove'];
  let clickCount = 0;
  let keypressCount = 0;
  let scrollCount = 0;
  let mouseMovementCount = 0;
  let linkClickCount = 0;
  let currentInactivitySeconds = 0;

  useEffect(() => {
    const interval = setInterval(function () {
      currentInactivitySeconds = currentInactivitySeconds + 1;
      if (currentInactivitySeconds === inactivitySeconds) {
        onInactive(interval);
      }
    }, ONE_SECOND);

    predefinedEvents.forEach(function (predefinedEvent) {
      document.addEventListener(predefinedEvent, function (e) {
        if (predefinedEvent === 'mouseup') {
          clickCount++;
          if (e.target.nodeName === 'A') {
            linkClickCount++;
          }
          if (mouseup) {
            currentInactivitySeconds = 0;
            onEvent('mouseup');
          }
        } else if (predefinedEvent === 'keydown') {
          keypressCount++;
          if (keydown) {
            currentInactivitySeconds = 0;
            onEvent('keydown');
          }
        } else if (predefinedEvent === 'scroll') {
          scrollCount++;

          if (scroll) {
            currentInactivitySeconds = 0;
            onEvent('scroll');
          }
        } else if (predefinedEvent === 'mousemove') {
          mouseMovementCount++;

          if (mousemove) {
            currentInactivitySeconds = 0;
            onEvent('mousemove');
          }
        }
      });
    });
  }, []);
}
