import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat, singleValueChanged } from './utils';
import { Attachments } from './attachments';
import { TextValueChanges } from './text-value-changes';
import { SingleTextValueChanges } from './single-text-value-changes';
import { AttachmentChangesDetailsButton } from './attachment-changes-details-button';

export function UpdateTaskEventType(props) {
  const event = oFetch(props, 'event');
  const [
    userFullName,
    oldTitle,
    oldVenueName,
    oldDescription,
    oldDeadlineDate,
    oldAttachments,
    newTitle,
    newVenueName,
    newDescription,
    newDeadlineDate,
    newAttachments,
    at,
  ] = oFetch(
    event,
    'userFullName',
    'oldTitle',
    'oldVenueName',
    'oldDescription',
    'oldDeadlineDate',
    'oldAttachments',
    'newTitle',
    'newVenueName',
    'newDescription',
    'newDeadlineDate',
    'newAttachments',
    'at',
  );

  const formattedAt = useMemo(() => atFormat(at), [at]);
  const newAttachmentsExist = newAttachments.length > 0;

  const singleChanges = singleValueChanged(
    [
      'newTitle:oldTitle:Title',
      'newVenueName:oldVenueName:Venue',
      'newDescription:oldDescription:Description',
      'newDeadlineDate:oldDeadlineDate:Deadline date',
    ],
    event,
  );

  if (singleChanges !== false) {
    return (
      <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
        <SingleTextValueChanges
          name={oFetch(singleChanges, 'label')}
          oldValue={oFetch(singleChanges, 'oldValue')}
          newValue={oFetch(singleChanges, 'newValue')}
          postfix={` by ${userFullName} @ ${formattedAt}`}
          isHtml={oFetch(singleChanges, 'label') === 'Description'}
        />
      </li>
    );
  }

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
      <p className="boss-overview__text">
        Updated by {userFullName} @ {formattedAt}
      </p>
      {!newAttachmentsExist && !newTitle && !newVenueName && !newDeadlineDate && !newDescription && (
        <p className="boss-overview__text">
          Nothing changed
        </p>
      )}
      {newTitle && <TextValueChanges name="Title" oldValue={oldTitle} newValue={newTitle} />}
      {newVenueName && <TextValueChanges name="Venue" oldValue={oldVenueName} newValue={newVenueName} />}
      {newDeadlineDate && (
        <TextValueChanges name="Deadline" oldValue={oldDeadlineDate} newValue={newDeadlineDate} />
      )}
      {newDescription && (
        <TextValueChanges isHtml name="Description" oldValue={oldDescription} newValue={newDescription} />
      )}
      {newAttachmentsExist && <AttachmentChangesDetailsButton oldAttachments={oldAttachments} newAttachments={newAttachments} />}
    </li>
  );
}
