// from https://stackoverflow.com/questions/4187146/truncate-number-to-two-decimal-places-without-rounding
function truncToFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export function formattedAsTruncatedHourDecimal(totalMinutes) {
  if (totalMinutes === 0) {
    return '0';
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutesOnly = totalMinutes - (hours * 60);
  if (minutesOnly === 0) {
    return `${hours}`;
  }

  return truncToFixed(totalMinutes / 60.0, 2);
}
