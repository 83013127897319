import PropTypes from 'prop-types';
import React from 'react';
import oFetch from 'o-fetch';

const ThemeContext = React.createContext({ radioGroup: {} });
export function RadioGroup(props) {
  const { Component, name, selectedValue, onChange, children, ...rest } = props;

  return (
    <ThemeContext.Provider
      value={{
        radioGroup: {
          selectedValue,
          onChange,
          name,
        },
      }}
    >
      <Component role="radiogroup" {...rest}>
        {children}
      </Component>
    </ThemeContext.Provider>
  );
}

export function Radio(props) {
  return (
    <ThemeContext.Consumer>
      {params => {
        const [name, selectedValue, onChange] = oFetch(
          params,
          'radioGroup.name',
          'radioGroup.selectedValue',
          'radioGroup.onChange',
        );
        const optional = {};
        if (selectedValue !== undefined) {
          optional.checked = props.value === selectedValue;
        }
        if (typeof onChange === 'function') {
          optional.onChange = onChange.bind(null, props.value);
        }
        return (
          <input
            {...props}
            role="radio"
            aria-checked={optional.checked}
            type="radio"
            name={name}
            {...optional}
          />
        );
      }}
    </ThemeContext.Consumer>
  );
}

Radio.contextTypes = {
  radioGroup: PropTypes.object,
};

RadioGroup.defaultProps = {
  Component: 'div',
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};
