import React, { useState } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

ConfirmButtons.defaultProps = {
  yesText: 'Yes',
  noText: 'No',
};

export function ConfirmButtons(props) {
  const [text, onYesClick, onNoClick, canNoClick, canYesClick, yesText, noText] = oFetch(
    props,
    'text',
    'onYesClick',
    'onNoClick',
    'canNoClick',
    'canYesClick',
    'yesText',
    'noText',
  );

  const [processing, setProcessing] = useState(false);

  async function handleClick(action) {
    setProcessing(true);
    await action();
    setProcessing(false);
  }

  return (
    <div className="boss-task__confirmation">
      <p className="boss-task__confirmation-label" style={{ whiteSpace: 'pre-line' }}>
        {text}
      </p>
      <div className="boss-task__confirmation-actions">
        {canYesClick && (
          <AsyncButton
            onClick={() => handleClick(onYesClick)}
            text={yesText}
            className="boss-button boss-button_type_small boss-button_role_ok boss-task__confirmation-action"
            disabled={processing}
          />
        )}
        {canNoClick && (
          <AsyncButton
            onClick={() => handleClick(onNoClick)}
            text={noText}
            className="boss-button boss-button_type_small boss-button_role_alert boss-task__confirmation-action"
            disabled={processing}
          />
        )}
      </div>
    </div>
  );
}
