import React, { useState, useEffect } from 'react';
import MachinesRefloats from './containers/machines-refloats';
import { Provider } from 'react-redux';

import configureStore from '../store';
import reducers from './reducers';
import { initialLoad } from './actions';

export function MachinesRefloatsApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <MachinesRefloats />
    </Provider>
  );
}
