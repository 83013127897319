import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { modalRedux } from '@/components/modals';
import AlertForm from './alert-form';

class EditAlert extends Component {
  render() {
    const onSubmit = oFetch(this.props, 'onSubmit');
    const venueAlert = oFetch(this.props, 'venueAlert');
    const initialValues = {
      id: oFetch(venueAlert, 'id'),
      title: oFetch(venueAlert, 'title'),
      message: oFetch(venueAlert, 'message'),
      level: oFetch(venueAlert, 'level'),
    };
    return <AlertForm submitButtonText="Update" onSubmit={onSubmit} initialValues={initialValues} />;
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(EditAlert);
