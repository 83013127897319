import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import oFetch from 'o-fetch';
import { Field, reduxForm } from 'redux-form';
import AccessoriesFormField from '../fields/accessories-form-field';
import * as c from '../../constants';
import * as selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';

class AccessoriesForm extends Component {
  render() {
    const [
      handleSubmit,
      previousPage,
      valid,
      accessories,
      masterVenue,
      getAccessories,
      isSecurity,
    ] = oFetch(
      this.props,
      'handleSubmit',
      'previousPage',
      'valid',
      'accessories',
      'masterVenue',
      'getAccessories',
      'isSecurity',
    );
    const hasMasterVenue = !!masterVenue;
    const showOverlay = !hasMasterVenue || isSecurity;
    const formGroupClassNames = cn('boss-form__group', {
      'boss-form__group_adjust_overlay-xxl': showOverlay,
    });
    return (
      <div className="boss-page-main__group">
        <div className="boss-form">
          <div className={formGroupClassNames}>
            {showOverlay && (
              <div className="boss-banner boss-banner_size_xxl boss-banner_color_dark boss-banner_role_overlay">
                <div className="boss-banner__content">
                  {!hasMasterVenue && !isSecurity && (
                    <p className="boss-banner__text">
                      Select a master venue from venue tab to be able assign accessories.
                    </p>
                  )}
                  {isSecurity && (
                    <p className="boss-banner__text">
                      Security Staff Members can't have Accessories
                    </p>
                  )}
                </div>
              </div>
            )}
            <Field
              name={c.ACCESSORIES_FIELD_NAME}
              accessories={accessories}
              label="Accessories"
              disabled={showOverlay}
              getAccessories={getAccessories}
              venueId={masterVenue}
              component={AccessoriesFormField}
            />
          </div>
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              onClick={previousPage}
              type="button"
              className="boss-button boss-button_role_back boss-form__submit_adjust_row"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              disabled={!valid}
              className="boss-button boss-form__submit boss-form__submit_adjust_single"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accessories: selectors.getAccessoriesOptions(state),
    masterVenue: selectors.masterVenueSelector(state),
    isSecurity: selectors.isSecuritySelector(state),
  };
};

const mapDispatchToProps = {
  getAccessories: actions.getAccessories,
};

export default reduxForm({
  form: c.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(AccessoriesForm));
