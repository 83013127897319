import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

function StaffMember(props) {
  const staffMember = oFetch(props, 'staffMember');
  const appType = oFetch(props, 'appType');
  const staffMemberId = oFetch(staffMember, 'id');
  const rotaShifts = oFetch(staffMember, 'rotaShifts');
  const avatarUrl = oFetch(staffMember, 'avatarUrl');
  const firstName = oFetch(staffMember, 'firstName');
  const surname = oFetch(staffMember, 'surname');
  const isSteward = oFetch(staffMember, 'isSteward');
  const supportsFacialRecognition = oFetch(staffMember, 'supportsFacialRecognition');
  const { isDisabled } = props;
  const fullName = `${firstName} ${surname}`;

  const avatarContainerClasses = cn('boss-user-summary__avatar', {
    'boss-user-summary__avatar_type_scannable': supportsFacialRecognition,
  });

  return (
    <div className={`boss-table__row ${isDisabled && 'boss-table__row_faded'}`}>
      <div className="boss-table__cell">
        <div className="boss-user-summary boss-user-summary_role_ssr-assign">
          <div className="boss-user-summary__side">
            <div className={avatarContainerClasses}>
              <div className="boss-user-summary__avatar-inner">
                <img src={avatarUrl} alt="user avatar" className="boss-user-summary__pic" loading="lazy" />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content boss-user-summary__content_adjust_middle">
            <div className="boss-user-summary__header">
              <h3 className="boss-user-summary__name">{fullName}</h3>
              {isSteward && <h3 className="boss-user-summary__name">(Steward)</h3>}
            </div>
          </div>
        </div>
      </div>

      <div className="boss-table__cell">
        <button
          onClick={() =>
            props.handleOpenConfirmationModal({
              appType,
              avatarUrl,
              fullName,
              staffMemberId,
              rotaShifts,
              supportsFacialRecognition,
              isSteward,
            })
          }
          className="boss-button boss-button_type_extra-small boss-button_role_confirm"
        >
          Assign
        </button>
      </div>
    </div>
  );
}

export default StaffMember;
