import oFetch from 'o-fetch';
import React from 'react';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function LinkedUserListItem(props) {
  const staffMember = oFetch(props, 'staffMember');
  const hasLinkedUser = oFetch(staffMember, 'hasLinkedUser');
  const hasInvite = oFetch(staffMember, 'hasInvite');

  function renderHasLinkedUser() {
    const linkedUserCreatedAt = oFetch(staffMember, 'linkedUserCreatedAt');
    const formattedLinkedUserCreatedAt = safeMoment
      .iso8601Parse(linkedUserCreatedAt)
      .format(utils.commonDateFormatWithTime());
    return (
      <p className="boss-details__value">
        <span className="boss-details__value-part">Created at {formattedLinkedUserCreatedAt}</span>
      </p>
    );
  }

  function renderNoLinkedUserExist() {
    if (hasInvite) {
      const inviteCreatedAt = oFetch(staffMember, 'inviteCreatedAt');
      const inviteSentAt = oFetch(staffMember, 'inviteSentAt');
      const formattedInviteCreatedAt = safeMoment
        .iso8601Parse(inviteCreatedAt)
        .format(utils.commonDateFormatWithTime());
      const formattedInviteSentAt = safeMoment
        .iso8601Parse(inviteSentAt)
        .format(utils.commonDateFormatWithTime());
      if (formattedInviteCreatedAt === formattedInviteSentAt) {
        return (
          <p className="boss-details__value">
            <span className="boss-details__value-line">Invite created on {formattedInviteCreatedAt}</span>
          </p>
        );
      }
      return (
        <p className="boss-details__value">
          <span className="boss-details__value-line">Invite created on {formattedInviteCreatedAt}</span>
          <span className="boss-details__value-line">Sent at {formattedInviteSentAt}</span>
        </p>
      );
    }
    return <p className="boss-details__value">No Linked User Exists</p>;
  }

  return (
    <li className="boss-details__item">
      <p className="boss-details__label boss-details__label_size_small">Linked User</p>
      {hasLinkedUser && renderHasLinkedUser()}
      {!hasLinkedUser && renderNoLinkedUserExist()}
    </li>
  );
}
