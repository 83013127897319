import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError, reset } from 'redux-form';
import { bossRequestHttp } from '@/lib/request-api';
import * as types from './types';
import * as selectors from './selectors';

function getParsedSortData(sortData) {
  try {
    return sortData.map(JSON.parse);
  } catch (e) {
    throw new Error(
      'Wrong SortingIds format. Please check `data-id` attr in the item components or check `sortData` key selectors. Must be `JSON.stringify`',
    );
  }
}

export const loadInitialState = createAction(types.LOAD_INITIAL_STATE);
export const addAreaToStore = createAction(types.ADD_AREA_TO_STORE);
export const addCategoryToStore = createAction(types.ADD_CATEGORY_TO_STORE);
export const addQuestionToStore = createAction(types.ADD_QUESTION_TO_STORE);
export const updateQuestionInStore = createAction(types.UPDATE_QUESTION_IN_STORE);
export const deleteQuestionFromStore = createAction(types.DELETE_QUESTION_FROM_STORE);
export const deleteCategoryFromStore = createAction(types.DELETE_CATEGORY_FROM_STORE);
export const deleteAreaFromStore = createAction(types.DELETE_AREA_FROM_STORE);

export const createCategory = (closeModal, values, _, props) => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const form = oFetch(props, 'form');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addCategoryToStore(data));
      dispatch(reset(form));
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      closeModal();
    },
  }).post(`/api/v1/venue-health-checks/${questionnaireId}/categories`, {
    ...values,
  });
};

export const createArea = (closeModal, values, _, props) => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const form = oFetch(props, 'form');
  const name = oFetch(values, 'name');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addAreaToStore(data));
      dispatch(reset(form));
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      closeModal();
    },
  }).post(`/api/v1/venue-health-checks/${questionnaireId}/areas`, {
    name: name,
    venueId: currentVenueId,
  });
};

export const createQuestion = (closeModal, values) => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['text', 'area', 'category'],
        });
        globalNotifications.showDefaultFailureMessage({ message: 'Create question failed' });
        throw new SubmissionError(errors);
      }

      return false;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(addQuestionToStore(data));
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      closeModal();
    },
  }).post(`/api/v1/venue-health-checks/${questionnaireId}/questions`, {
    venueId: currentVenueId,
    ...values,
  });
};

export const updateQuestion = (closeModal, values) => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const questionId = oFetch(values, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(updateQuestionInStore(data));
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      closeModal();
    },
  }).put(`/api/v1/venue-health-checks/${questionnaireId}/questions/${questionId}`, {
    venueId: currentVenueId,
    ...values,
  });
};

export const deleteQuestion = params => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const [questionId, closeModal] = oFetch(params, 'id', 'closeModal');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      dispatch(deleteQuestionFromStore({ id: questionId }));
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      closeModal();
    },
  }).delete(
    `/api/v1/venue-health-checks/${questionnaireId}/questions/${questionId}`,
    { data: { venueId: currentVenueId } }
  );
};

export const deleteCategory = params => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const category = oFetch(params, 'category');
  const categoryId = oFetch(category, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      dispatch(deleteCategoryFromStore({ categoryId }));
      globalNotifications.showDefaultSuccessMessage();
    },
  }).delete(
    `/api/v1/venue-health-checks/${questionnaireId}/categories/${categoryId}`,
    { data: { venueId: currentVenueId } }
  );
};

export const deleteArea = params => (dispatch, getState) => {
  const questionnaireId = selectors.getQuestionnaireId(getState());
  const currentVenueId = selectors.getCurrentVenueId(getState());
  const area = oFetch(params, 'area');
  const areaId = oFetch(area, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      dispatch(deleteAreaFromStore({ areaId }));
      globalNotifications.showDefaultSuccessMessage();
    },
  }).delete(
    `/api/v1/venue-health-checks/${questionnaireId}/areas/${areaId}`,
    { data: { venueId: currentVenueId } }
  );
};
