import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import cn from 'classnames';
import ReactIScroll from 'react-iscroll';
import { Tooltip } from 'react-tippy';
import { appRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import { Row, BasicCell, HeaderCell, Table, ComparisonCell } from './table';
import { MoneyDiffCell } from './money-diff-cell';
import safeMoment from '@/lib/safe-moment';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  mouseWheel: false,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export function getFormattedMoney(cents) {
  if (!cents && cents !== 0) {
    return 'N/A';
  }
  return utils.moneyFormat(cents / 100.0);
}

function ErrorTooltipItem(props) {
  const [fullName, errors, venueId, date, financeReportId] = oFetch(
    props,
    'fullName',
    'errors',
    'venueId',
    'date',
    'financeReportId',
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
      <span>
        <a
          href={appRoutes.financeReportsScrollTo({
            venueId: venueId,
            startDate: date,
            financeReportId: financeReportId,
          })}
          target="_blank"
          rel="noreferrer"
          style={{ whiteSpace: 'nowrap', marginRight: '5px' }}
        >
          {fullName}:{' '}
        </a>
      </span>
      <span style={{ textAlign: 'left' }}>{errors.join('. ')}</span>
    </div>
  );
}

export function VenueWageContent(props) {
  const [venues, onNotesClick, weekStartDate] = oFetch(props, 'venues', 'onNotesClick', 'weekStartDate');
  const mWeekStartDate = safeMoment.uiDateParse(weekStartDate);

  function renderErrors(params) {
    const financeReportErrors = oFetch(params, 'financeReportErrors');
    const venueId = oFetch(params, 'venueId');

    return (
      <Tooltip
        className="boss-kpi__data-item boss-kpi__data-item_role_week"
        arrow
        theme="light"
        position="bottom"
        trigger="click"
        interactive
        html={financeReportErrors.map((errorItem, index) => {
          return (
            <ErrorTooltipItem
              key={index}
              fullName={oFetch(errorItem, 'full_name')}
              errors={oFetch(errorItem, 'errors')}
              financeReportId={oFetch(errorItem, 'finance_report_id')}
              venueId={venueId}
              date={weekStartDate}
            />
          );
        })}
      >
        <Fragment>
          <span className="boss-table__tooltip">
            <span
              style={{
                borderRadius: '50%',
                backgroundColor: '#ed7f7e',
                color: 'white',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <span style={{ fontWeight: '700' }}>{financeReportErrors.length}</span>
            </span>
          </span>
        </Fragment>
      </Tooltip>
    );
  }

  function renderRows() {
    return venues.map(venue => {
      const [
        id,
        name,
        modulrPayrollCompanyName,
        hasFinanceReportErrors,
        financeReportValidationErrors,
        payableFinanceReportsWithPayslipAndBankDetailsCount,
        canDownloadBulkPaymentsFile,
        financeReportsCount,
        financeReportsCompletedCount,
        notes,
        payableFinanceReportCount,
        payableFinanceReportsWithPayslipCount,
        isFinanceReportWeekFinished,
        salaryCalculatedTotalGrossCents,
        salaryTotalGrossUploadedCents,
        salaryGrossDeltaCents,
        salaryGrossDeltaWithinThreshold,
        salaryTotalNetWagesCents,
        hourlyCalculatedTotalGrossCents,
        hourlyTotalGrossUploadedCents,
        hourlyGrossDeltaCents,
        hourlyGrossDeltaWithinThreshold,
        hourlyTotalNetWagesCents,
        totalNetWagesCents,
        calculatedTotalGrossCents,
        totalGrossUploadedCents,
        totalGrossDeltaCents,
        totalGrossDeltaWithinThreshold,
      ] = oFetch(
        venue,
        'id',
        'name',
        'modulrPayrollCompanyName',
        'hasFinanceReportErrors',
        'financeReportValidationErrors',
        'payableFinanceReportsWithPayslipAndBankDetailsCount',
        'canDownloadBulkPaymentsFile',
        'financeReportsCount',
        'financeReportsCompletedCount',
        'notes',
        'payableFinanceReportCount',
        'payableFinanceReportsWithPayslipCount',
        'isFinanceReportWeekFinished',
        'financeReportTotals.salaryCalculatedTotalGrossCents',
        'financeReportTotals.salaryTotalGrossUploadedCents',
        'financeReportTotals.salaryGrossDeltaCents',
        'financeReportTotals.salaryGrossDeltaWithinThreshold',
        'financeReportTotals.salaryTotalNetWagesCents',
        'financeReportTotals.hourlyCalculatedTotalGrossCents',
        'financeReportTotals.hourlyTotalGrossUploadedCents',
        'financeReportTotals.hourlyGrossDeltaCents',
        'financeReportTotals.hourlyGrossDeltaWithinThreshold',
        'financeReportTotals.hourlyTotalNetWagesCents',
        'financeReportTotals.totalNetWagesCents',
        'financeReportTotals.calculatedTotalGrossCents',
        'financeReportTotals.totalGrossUploadedCents',
        'financeReportTotals.totalGrossDeltaCents',
        'financeReportTotals.totalGrossDeltaWithinThreshold',
      );

      const hasNotes = notes.length > 0;
      const venueFinanceReportUrl = appRoutes.financeReports({
        startDate: weekStartDate,
        venueId: id,
      });
      const downloadBulkPayments = appRoutes.venueBulkPaymentsDownload({
        mWeekStartDate: mWeekStartDate,
        venueId: id,
      });
      const rowClassName = cn({ 'has-errors': hasFinanceReportErrors });
      return (
        <Row key={id} className={rowClassName}>
          <BasicCell>
            {isFinanceReportWeekFinished && (
              <span
                className="boss-indicator boss-indicator_role_action boss-table__indicator js-tippy-status"
                aria-expanded="false"
              >
                <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
              </span>
            )}
          </BasicCell>
          <BasicCell>
            <a href={venueFinanceReportUrl} target="_blank" rel="noreferrer">
              {name}
            </a>
          </BasicCell>
          <BasicCell>{modulrPayrollCompanyName}</BasicCell>
          <ComparisonCell value1={financeReportsCompletedCount} value2={financeReportsCount} />
          <ComparisonCell value1={payableFinanceReportsWithPayslipCount} value2={payableFinanceReportCount} />
          <ComparisonCell value1={payableFinanceReportsWithPayslipAndBankDetailsCount} value2={payableFinanceReportsWithPayslipCount} />
          <MoneyDiffCell
            withitThreshold={hourlyGrossDeltaWithinThreshold}
            delta={hourlyGrossDeltaCents}
            value1={hourlyCalculatedTotalGrossCents}
            value2={hourlyTotalGrossUploadedCents}
          />
          <MoneyDiffCell
            withitThreshold={salaryGrossDeltaWithinThreshold}
            delta={salaryGrossDeltaCents}
            value1={salaryCalculatedTotalGrossCents}
            value2={salaryTotalGrossUploadedCents}
          />
          <MoneyDiffCell
            withitThreshold={totalGrossDeltaWithinThreshold}
            delta={totalGrossDeltaCents}
            value1={calculatedTotalGrossCents}
            value2={totalGrossUploadedCents}
          />
          <BasicCell>{getFormattedMoney(hourlyTotalNetWagesCents)}</BasicCell>
          <BasicCell>{getFormattedMoney(salaryTotalNetWagesCents)}</BasicCell>
          <BasicCell>{getFormattedMoney(totalNetWagesCents)}</BasicCell>
          <BasicCell>
            {hasFinanceReportErrors &&
              renderErrors({ financeReportErrors: financeReportValidationErrors, venueId: id })}
          </BasicCell>
          <BasicCell>
            {hasNotes && (
              <button
                onClick={() => onNotesClick({ notes, venueName: name, venueId: id })}
                className="boss-tag boss-tag_size_xxs boss-tag_color_accent-green boss-tag_role_action"
              >
                <span className="boss-tag__text">Notes</span>
                <span className="boss-tag__service">{notes.length}</span>
              </button>
            )}
          </BasicCell>
          <div className="boss-table__cell">
            {payableFinanceReportsWithPayslipAndBankDetailsCount > 0 && canDownloadBulkPaymentsFile && (
              <div className="boss-table__actions">
                <a
                  href={downloadBulkPayments}
                  className="boss-button boss-button_type_extra-small boss-button_role_download boss-table__action"
                >
                  Download Payments File
                </a>
              </div>
            )}
          </div>
        </Row>
      );
    });
  }

  return (
    <ReactIScroll
      iScroll={iScroll}
      options={scrollOptions}
      className="boss-board__scroll boss-board__scroll_type_native"
    >
      <div className="boss-board__scroll-content">
        <Table className="boss-table_page_modulr-reports-venue-wages">
          <Row>
            <HeaderCell />
            <HeaderCell>Venue Name</HeaderCell>
            <HeaderCell>Account Name</HeaderCell>
            <HeaderCell>Completed</HeaderCell>
            <HeaderCell>Staff with Payslips</HeaderCell>
            <HeaderCell>Setup For Payments</HeaderCell>
            <HeaderCell>Hourly Gross Calc/Uploaded</HeaderCell>
            <HeaderCell>Salary Gross Calc/Uploaded</HeaderCell>
            <HeaderCell>Total Gross Calc/Uploaded</HeaderCell>
            <HeaderCell>Hourly Net</HeaderCell>
            <HeaderCell>Salary Net</HeaderCell>
            <HeaderCell>Grand Total</HeaderCell>
            <HeaderCell>Errors</HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell>Downloads</HeaderCell>
          </Row>
          {renderRows()}
        </Table>
      </div>
    </ReactIScroll>
  );
}
