import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { useCarousel } from '@/components/hooks-components';

export function VenueAlertsCarousel(props) {
  const [messages, levelTypes] = oFetch(props, 'messages', 'levelTypes');
  const messagesExist = messages.length > 0;
  const { currentItem, onNextClick, onPreviousClick, isLast, isFirst, pagination } = useCarousel({
    list: messages,
    initialIndex: 0,
  });
  if (!messagesExist) {
    return null;
  }
  const currentItemLevel = oFetch(currentItem, 'level');
  function renderButtons() {
    return (
      <React.Fragment>
        {!isFirst() && (
          <button
            className="boss-button boss-button_role_next boss-message__meta-action"
            onClick={onNextClick}
          >
            See Next
          </button>
        )}
        {!isLast() && (
          <button
            className="boss-button boss-button_role_previous boss-message__meta-action"
            onClick={onPreviousClick}
          >
            See Previous
          </button>
        )}
      </React.Fragment>
    );
  }

  const messageClass = cn(
    'boss-message boss-message_role_dashboard boss-message_type_accent boss-message_context_board',
    {
      'boss-message_level_red': currentItemLevel === oFetch(levelTypes, 'redLevelType'),
      'boss-message_level_amber': currentItemLevel === oFetch(levelTypes, 'amberLevelType'),
    },
  );

  return (
    <div className={messageClass}>
      <h2
        className="boss-message__title boss-message__title_accent"
        style={{ textAlign: 'right' }}
      >{`${oFetch(pagination, 'current')} / ${oFetch(pagination, 'size')}`}</h2>

      {currentItem.title && (
        <h2 className="boss-message__title boss-message__title_accent">{currentItem.title}</h2>
      )}
      {(currentItem.message || currentItem.description) && (
        <div
          className="boss-message__content"
          dangerouslySetInnerHTML={{ __html: currentItem.message || currentItem.description }}
        />
      )}
      <div className="boss-message__meta">
        <div className="boss-message__meta-group">
          <p className="boss-message__meta-text">
            ( Created {moment(oFetch(currentItem, 'createdAt')).format(utils.humanDateFormatWithTime())} )
          </p>

          <div className="boss-message__meta-actions">{renderButtons()}</div>
        </div>
      </div>
    </div>
  );
}
