import React from 'react';
import { Field, Fields, reduxForm, SubmissionError } from 'redux-form';
import { BossFormInput, BossFormAppRoleVenueSelect } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';

const submission = (values, dispatch, props) => {
  return props.onSubmit(values, dispatch).catch(resp => {
    if (resp.response && resp.response.data) {
      const errors = resp.response.data.errors;
      if (errors) {
        let base = {};

        if (errors.base) {
          base = {
            _error: errors.base,
          };
        }
        throw new SubmissionError({ ...errors, ...base });
      }
    }
    throw resp;
  });
};

function InviteUserForm(props) {
  const { error, venues, submitting } = props;
  const [permittedRolesOptions, venuesAccess] = oFetch(
    useLegacyGlobal(),
    'permittedRolesOptions',
    'venuesAccess',
  );

  const inviteTypeOptions = [{"label": 'Moss', "value": 'moss'}, {label: 'Boss', value: 'boss'}];

  return (
    <div className="boss-modal-window__form">
      <form onSubmit={props.handleSubmit(submission)} className="boss-form">
        <Field name="firstName" label="First Name" required component={BossFormInput} />
        <Field name="surname" label="Surname" required component={BossFormInput} />
        <Field name="email" label="Email" type="email" required component={BossFormInput} />

        <Fields
          names={['inviteType', 'role', 'venueIds']}
          required
          roleOptions={permittedRolesOptions}
          inviteTypeOptions={inviteTypeOptions}
          venuesAccess={venuesAccess}
          venues={venues}
          component={BossFormAppRoleVenueSelect}
        />

        <div className="boss-form__row boss-form__row_position_last">
          <div className="boss-form__field boss-form__field_justify_center">
            <button
              disabled={submitting}
              className="boss-button boss-button_role_add boss-form__submit"
              type="submit"
            >
              Invite New User
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default reduxForm({
  form: 'invite-user-form',
})(InviteUserForm);
