import React from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';

import configureStore from '@/apps/store';
import reducers from './redux/reducers';
import { loadInitialStateAction } from './redux/actions';
import VenueAlertLevels from './containers/venue-alert-levels';

class VenueAlertsApp extends React.Component {
  UNSAFE_componentWillMount() {
    window.boss.accessToken = oFetch(this.props, 'accessToken');
    this.store = configureStore(reducers);
    this.store.dispatch(loadInitialStateAction({ ...this.props }));
  }

  render() {
    return (
      <Provider store={this.store}>
        <VenueAlertLevels />
      </Provider>
    );
  }
}

export default VenueAlertsApp;
