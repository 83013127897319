import React from 'react';
import oFetch from 'o-fetch';
import { CollapsibleCard } from '@/components/hooks-components';

export default function StaffTrackingItem(props) {
  const [count, title, tableHeader, itemRenderer, items] = oFetch(
    props,
    'count',
    'title',
    'tableHeader',
    'itemRenderer',
    'items',
  );

  function renderItems() {
    return items.map((item, index) => {
      return React.cloneElement(itemRenderer(item), {
        key: index,
      });
    });
  }

  function renderTitle() {
    return (
      <h2 className="boss-board__title boss-board__title_size_medium">
        <span className="boss-board__title-indicator">
          <span className="boss-indicator boss-indicator_role_info-primary">
            <span className="boss-indicator__marker">{count}</span>
          </span>
        </span>
        <span className="boss-board__title-text">{title}</span>
      </h2>
    );
  }
  return (
    <CollapsibleCard title={renderTitle}>
      <div className="boss-board__table">
        <div className="boss-board__scroll boss-board__scroll_type_native">
          <div className="boss-board__scroll-content">
            <div className="boss-table boss-table_page_staff-tracking-staff-added">
              {tableHeader()}
              {renderItems()}
            </div>
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}
