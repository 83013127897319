import React, { Fragment, useState } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { KPIDashboard, KPICardList, AddRatingModalContent, EditRatingModalContent } from './components';

import * as actions from './actions';

export function KPI(props) {
  const [currentVenueId, quarter, year, years, quarterNumbers, isPastQuarter, isAdminPlus] = oFetch(
    props,
    'currentVenueId',
    'quarter',
    'year',
    'years',
    'quarterNumbers',
    'isPastQuarter',
    'isAdminPlus',
  );
  const [kpiData, setKpiData] = useState(oFetch(props, 'kpiData'));
  const { openModal } = useModal();

  function updateKpiData(newKpiData) {
    const [kpiId, ratedAt, ratedByUserName, ratingNote, score] = oFetch(
      newKpiData,
      'kpiId',
      'ratedAt',
      'ratedByUserName',
      'ratingNote',
      'score',
    );
    setKpiData(oldKpiData => {
      return oldKpiData.map(kpiDataItem => {
        if (oFetch(kpiDataItem, 'kpiId') === kpiId) {
          return {
            ...kpiDataItem,
            ratedAt,
            ratedByUserName,
            ratingNote,
            score,
          };
        }
        return kpiDataItem;
      });
    });
  }

  function openUpdateRatingModal(params) {
    const [kpiDataRenderer, kpiId, score, ratingNote] = oFetch(
      params,
      'kpiDataRenderer',
      'kpiId',
      'score',
      'ratingNote',
    );
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditRatingModalContent,
      onSubmit(handleClose, values) {
        return actions.updateRatingAction({
          values,
          onSuccess(data) {
            updateKpiData(oFetch(data, 'kpi'));
            handleClose();
          },
        });
      },
      config: {
        title: 'Update Rating',
      },
      props: {
        kpiDataRenderer,
        venueId: currentVenueId,
        kpiId,
        year,
        quarter,
        score,
        ratingNote,
      },
    });
  }

  function openAddRatingModal(params) {
    const [kpiDataRenderer, kpiId] = oFetch(params, 'kpiDataRenderer', 'kpiId');
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddRatingModalContent,
      onSubmit(handleClose, values) {
        return actions.updateRatingAction({
          values,
          onSuccess(data) {
            updateKpiData(oFetch(data, 'kpi'));
            handleClose();
          },
        });
      },
      config: {
        title: 'Add Rating',
      },
      props: {
        kpiDataRenderer,
        venueId: currentVenueId,
        kpiId,
        year,
        quarter,
      },
    });
  }

  return (
    <Fragment>
      <KPIDashboard
        kpiData={kpiData}
        quarter={quarter}
        quarterNumbers={quarterNumbers}
        year={year}
        years={years}
      >
        <h1>KPI Page</h1>
      </KPIDashboard>
      <ContentWrapper>
        <KPICardList
          canHandleAction={isAdminPlus && isPastQuarter}
          kpiDataList={kpiData}
          onAddRating={openAddRatingModal}
          onEditRating={openUpdateRatingModal}
        />
      </ContentWrapper>
    </Fragment>
  );
}
