import React, { Component } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default class AssignedStaffType extends Component {
  state = {
    unassigning: false,
  };

  onDelete = () => {
    const [id, onDelete] = oFetch(this.props, 'staffType.id', 'onDelete');
    this.setState({ unassigning: true });
    return onDelete({ staffTypeId: id });
  };

  render() {
    const [name, color] = oFetch(this.props, 'staffType.name', 'staffType.color');
    const unassigning = oFetch(this.state, 'unassigning');

    const iconClassNames = cn(
      'boss-tag__service',
      { 'boss-tag__service_role_action boss-tag__service_icon_close': !unassigning },
      { 'boss-tag__service_icon_spinner': unassigning },
    );

    return (
      <div className="boss-tag boss-tag_size_l" style={{ backgroundColor: color }}>
        <p className="boss-tag__text">{name}</p>
        <div onClick={this.onDelete} className={iconClassNames} />
      </div>
    );
  }
}
