import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import utils from "@/lib/utils";
import { fromJS } from 'immutable';
import { formValueSelector, getFormSubmitErrors } from 'redux-form/immutable';

export const formValues = formValueSelector('enable-profile-form');
export const currentStaffMemberSelector = state => {
  const stateJS = utils.callIfExists({
    obj: state,
    funcName: 'toJS',
  });

  return fromJS(oFetch(stateJS, 'profile.staffMember'));
};
export const validateAvatarForUpdateUrlSelector = createSelector(currentStaffMemberSelector, staffMember => {
  return staffMember.get('validateAvatarForUpdateUrl');
});

export const hasLinkedUserSelector = createSelector(currentStaffMemberSelector, staffMember => {
  return staffMember.get('hasLinkedUser');
});

export const getAccessoriesInPossession = createSelector([currentStaffMemberSelector], staffMember => {
  return staffMember.get('accessoriesInPossession');
});

export const getDisableFormAccessories = createSelector([currentStaffMemberSelector], staffMember => {
  return staffMember.get('accessoriesForDisableForm');
});

export const staffTypesSelector = state => {
  const stateJS = utils.callIfExists({
    obj: state,
    funcName: 'toJS',
  });

  return fromJS(oFetch(stateJS, 'profile.staffTypes'));
};

export const securityStaffTypesSelector = state => {
  const stateJS = utils.callIfExists({
    obj: state,
    funcName: 'toJS',
  });

  return fromJS(oFetch(stateJS, 'profile.securityStaffTypes'));
};

export const getSecurityStaffTypesIds = createSelector([securityStaffTypesSelector], securityStaffTypes => {
  return securityStaffTypes.map(staffType => {
    return staffType.get('id');
  });
});
export const selectedEnableFormStaffTypeSelector = state => formValues(fromJS(state), 'staffTypeId');
export const isEnableSecurityStewardSelector = state => formValues(fromJS(state), 'securitySteward');

export const isEnableSecuritySelector = createSelector(
  [getSecurityStaffTypesIds, selectedEnableFormStaffTypeSelector],
  (securityStaffTypesIds, selectedStaffType) => {
    return securityStaffTypesIds.includes(selectedStaffType);
  },
);

export const isEnableStaffTypeSelectedSelector = state => !!formValues(fromJS(state), 'staffTypeId');

export const getDisableFormAccessoriesFlatList = createSelector(
  [getDisableFormAccessories],
  disableFormAccessories => {
    const disableFormAccessoriesJS = disableFormAccessories.toJS();
    return fromJS(
      disableFormAccessoriesJS.reduce((acc, staffMemberAccessory) => {
        const [requesIds, accessory] = oFetch(staffMemberAccessory, 'requestIds', 'accessory');
        requesIds.forEach(requestId => {
          acc = [
            ...acc,
            {
              requestId: requestId,
              ...accessory,
            },
          ];
        });
        return acc;
      }, []),
    );
  },
);

export const getAccessoriesInPossessionFlatList = createSelector(
  [getAccessoriesInPossession],
  accessoriesInPossession => {
    const accessoriesInPossessionJS = accessoriesInPossession.toJS();
    return fromJS(
      accessoriesInPossessionJS.reduce((acc, staffMemberAccessory) => {
        const [requesIds, accessory] = oFetch(staffMemberAccessory, 'requestIds', 'accessory');
        requesIds.forEach(requestId => {
          acc = [
            ...acc,
            {
              requestId: requestId,
              ...accessory,
            },
          ];
        });
        return acc;
      }, []),
    );
  },
);
