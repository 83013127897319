import React, { useMemo } from 'react';
import { z } from 'zod';
import { useModal } from '../hooks-components/modals';
import { DefaultModalWrapper } from '../modal-wrappers';
import { $OnSubmitParams, BossFormTagInputModalContent } from './boss-form-tag-input-modal-content';

export const $TagOptionId = z.number().int().nonnegative();

export const $TagOption = z.object({
  id: $TagOptionId,
  name: z.string(),
}).strict();

const $FinalFormInput = z.object({
  value: z.array($TagOptionId),
  onChange: z.function().args(z.array($TagOptionId)).returns(z.void()),
});

const $InputProps = z.object({
  className: z.string().optional(),
  input: $FinalFormInput,
  meta: z.object({
    error: z.union([
      z.undefined(),
      z.record(z.string(), z.unknown()),
      z.array(z.string()),
    ]),
  }).passthrough(),
  options: z.array($TagOption),
  valueKey: z.literal('id'),
  labelKey: z.literal('name'),
  disabled: z.boolean().optional(),
  clearable: z.boolean().optional(),
  multi: z.boolean().optional(),
}).strict();

export function BossFormTagInput(props: any): React.ReactElement {
  const { openModal } = useModal();
  const parsedProps = useMemo(() => $InputProps.parse(props), [props]);

  const outerClassName = parsedProps.className ? `${parsedProps.className.trim()} ` : '';

  const selectedTags = parsedProps.input.value.map((selectedOptionId) => {
    const tag = parsedProps.options.find((option) => option.id === selectedOptionId);
    if (!tag) {
      throw new Error(`Could not find tag with id ${selectedOptionId}`);
    }
    return tag;
  });

  function showModal() {
    openModal({
      onSubmit: (...rawOnSubmitParams: unknown[]) => {
        const [handleSubmit, values] = $OnSubmitParams.parse(rawOnSubmitParams);
        parsedProps.input.onChange(values);
        handleSubmit();
      },
      props: {
        selectedOptionIds: parsedProps.input.value,
        options: parsedProps.options,
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_type_full-height-fixed boss-modal-window_role_hours-tags',
      },
      onClose: () => {},
      ModalComponent: DefaultModalWrapper,
      ModalContent: BossFormTagInputModalContent,
    });
  }

  function removeTag(tagId: number) {
    const values = parsedProps.input.value.filter((selectedOptionId) => selectedOptionId !== tagId);
    parsedProps.input.onChange(values);
  }


  function normalizeImmutableErrors(obj: Record<string, unknown> | string[]): string[] {
    if (Array.isArray(obj)) {
      return obj;
    }

    if (obj['toJS'] !== undefined && typeof obj['toJS'] === 'function') {
      return obj['toJS']();
    } else {
      throw new Error('Could not normalize immutable errors');
    }
  }

  const normalizedErrors = parsedProps.meta.error ? normalizeImmutableErrors(parsedProps.meta.error) : null;
  const hasErrors = normalizedErrors !== null;
  const topLevelErrorClass = hasErrors ? "boss-time-shift__hours-tags-select_state_error" : "";

  return (
    <div className="boss-time-shift__hours-tags-inner">
      { hasErrors && <div className="boss-time-shift__error boss-time-shift__error_role_general">
        <p className="boss-time-shift__error-text boss-time-shift__error-text_role_title">{normalizedErrors}</p>
      </div> }

      <div className={`${outerClassName}${topLevelErrorClass}`}>
        <div
          style={{ overflow: 'hidden' }}
          className="Select Select--multi is-clearable is-searchable has-value is-disable"
        >
          {selectedTags.length === 0 && (
            <div
              className="Select-control"
              onClick={() => showModal()}
            >
              <span className="Select-multi-value-wrapper">
                <div className="Select-placeholder">Add Tags...</div>

                <div
                  className="Select-input"
                  style={{
                    display: "inline-block"
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      visibility: 'hidden',
                      height: '0px',
                      overflow: 'scroll',
                      whiteSpace: 'pre',
                    }}
                  />
                </div>
              </span>
            </div>
          )}
          {selectedTags.length > 0 && (
            <div
              className="Select-control"
              onClick={() => showModal()}
            >
              <span className="Select-multi-value-wrapper">
                {selectedTags.map((selectedTag, index) => {
                  return (
                    <div
                      key={`tag:${index}`}
                      className="Select-value"
                      style={{ backgroundColor: '#fdc4e6' }}
                      onClick={() => showModal()}
                    >
                      <span
                        className="Select-value-icon"
                        onClick={(event: React.MouseEvent) => {
                          event.stopPropagation();
                          removeTag(selectedTag.id);
                        }}
                      >×</span>
                      <span
                        className="Select-value-label"
                        style={{ lineHeight: '20px', color: '#4c4c4c' }}
                      >{selectedTag.name}<span className="Select-aria-only">&nbsp;</span>
                      </span>
                    </div>
                  );
                })}

                <div
                  className="Select-input"
                  style={{ display: "inline-block" }}
                >
                  <div style={{ position: 'absolute', top: '0px', left: '0px', visibility: 'hidden', height: '0px', overflow: 'scroll', whiteSpace: 'pre' }} />
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
