import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ForStaffMembersSecurityPayRateForm } from './for-staff-members-security-pay-rate-form';

export function AddForStaffMembersSecurityPayRateModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const initialValues = {
    name: '',
    subType: null,
    accessLevel: null,
    standardHourlyRateCents: null,
    weekendHourlyRateCents: null,
  };

  return <ForStaffMembersSecurityPayRateForm onSubmit={onSubmit} initialValues={initialValues} isEdit={false} />;
}
