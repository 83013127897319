import oFetch from 'o-fetch';
import React from 'react';
import { DetailsCard } from './details-card';

export function PersonalDetails(props) {
  const [user, onEditClick] = oFetch(props, 'user', 'onEditClick');

  const [fullName, email, isDisabled, emailSyncState] = oFetch(user, 'fullName', 'email', 'isDisabled', 'emailSyncState');
  const emailTextParts = [email];
  switch (emailSyncState) {
    case "sync_bypassed":
      emailTextParts.push(" (Mailbox Bypassed)");
      break;
    case "synced":
      emailTextParts.push(" (Mailbox)");
      break;
    case "synced_fake":
      emailTextParts.push(" (Fake Mailbox)");
      break;
    case "sync_bypassed_fake":
      emailTextParts.push(" (Fake Mailbox Bypassed)");
      break;
    case "not_synced":
      // do nothing
      break;
    default:
      throw new Error(`Unknown email sync state: ${emailSyncState}`);
  }



  return (
    <DetailsCard number={1} title="Personal Details" onEditClick={!isDisabled && onEditClick}>
      <ul className="boss-details__list">
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">Name</p>
          <p className="boss-details__value">{fullName}</p>
        </li>
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">Email Address</p>
          <p className="boss-details__value boss-details__value_adjust_wrap">{emailTextParts.join('')}</p>
        </li>
      </ul>
    </DetailsCard>
  );
}
