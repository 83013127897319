import React, { Component } from 'react';
import DropZone from '@/lib/boss-react-dropzone';
import oFetch from 'o-fetch';
import cn from 'classnames';

import { loadImage } from '@/components/image-resizer';
import { openContentModal } from '@/components/modals';
import ImageRotation from '@/components/image-rotation';

const VALID_FILE_TYPES = '.jpg, .jpeg, .png';
const MAX_FILE_SIZE = 1000000; // 1MB

class ImageAnswer extends Component {
  dropRejected = () => {};
  dropAccepted = async files => {
    const file = files[0];
    let resizedFile;
    if (file.size > MAX_FILE_SIZE) {
      resizedFile = await loadImage(file, {
        maxWidth: 1024,
        orientation: true,
        crossOrigin: true,
      });
    } else {
      resizedFile = file;
    }
    const onChange = oFetch(this.props, 'input.onChange');
    const value = oFetch(this.props, 'input.value');
    openContentModal({
      submit: (modalClose, file) => {
        onChange({ ...value, image: file });
        modalClose();
      },
      config: { title: 'Test' },
      props: { file: resizedFile },
    })(ImageRotation);
  };

  renderPickImage = () => {
    const image = oFetch(this.props, 'input.value.image');
    const {
      meta: { error, touched },
    } = this.props;
    const fieldClassNames = cn('boss-choice-list__upload-field', {
      'boss-choice-list__upload-field_state_error': touched && error,
    });
    return (
      <DropZone
        className="some"
        accept={VALID_FILE_TYPES}
        onDropAccepted={this.dropAccepted}
        onDropRejected={this.dropRejected}
      >
        {({ rejectedFiles }) => {
          const hasRejected = rejectedFiles.length > 0;
          if (hasRejected) {
            return (
              <div className="boss-upload__preview boss-upload__preview_adjust_form boss-upload__preview_state_broken">
                <button type="button" className="boss-upload__preview-link" />
              </div>
            );
          }
          if (image) {
            return (
              <div className="boss-choice-list__upload-preview">
                <img
                  src={image.preview}
                  alt="Answer Image"
                  className="boss-choice-list__upload-preview-image"
                  crossOrigin="anonymous"
                />
              </div>
            );
          }
          return (
            <div>
              <div className={fieldClassNames}>
                <p className="boss-choice-list__upload-label">Add Image</p>
              </div>
              {touched && error && (
                <div className="boss-choice-list__error boss-choice-list__error_position_below">
                  <p className="boss-choice-list__error-text">{error}</p>
                </div>
              )}
            </div>
          );
        }}
      </DropZone>
    );
  };

  handleChangeCheckBox = e => {
    const onChange = oFetch(this.props, 'input.onChange');
    const value = oFetch(this.props, 'input.value');
    const checked = oFetch(e.target, 'checked');
    onChange({ ...value, correct: checked });
  };

  render() {
    const onRemove = oFetch(this.props, 'onRemove');
    const correct = oFetch(this.props, 'input.value.correct');

    return (
      <div className="boss-choice-list__control boss-choice-list__control_type_image-editor">
        <label className="boss-choice-list__option">
          <input
            onChange={this.handleChangeCheckBox}
            checked={correct}
            type="checkbox"
            className="boss-choice-list__option-input"
          />
          <span className="boss-choice-list__option-control" />
        </label>
        <div className="boss-choice-list__upload">{this.renderPickImage()}</div>
        <div className="boss-choice-list__actions">
          <button
            onClick={onRemove}
            className="boss-choice-list__action boss-choice-list__action_role_remove"
            type="button"
          />
        </div>
      </div>
    );
  }
}

export default ImageAnswer;
