import { useState } from 'react';
import oFetch from 'o-fetch';

export function contactsDetailsHooks(params) {
  const [staffMembers, staffTypes, defaultFilter] = oFetch(params, 'staffMembers', 'staffTypes', 'filter');
  const [filter, setFilter] = useState(defaultFilter);

  const staffTypesOptions = staffTypes.map(staffType => {
    const [id, name, color] = oFetch(staffType, 'id', 'name', 'color');
    return {
      value: id,
      label: name,
      color: color,
    };
  });

  function onFilterChange(filter) {
    setFilter(filter);
  }

  function filterStaffMembers() {
    const [name, selectedStaffType] = oFetch(filter, 'name', 'staffType');
    const selectedStaffTypeExist = selectedStaffType.length > 0;
    return staffMembers.filter(staffMember => {
      let matches = true;
      if (name) {
        let containsQuery = false;

        const fullName = oFetch(staffMember, 'fullName');
        if (fullName.toLowerCase().includes(name.toLowerCase())) {
          containsQuery = true;
        }
        if (!containsQuery) {
          matches = false;
        }
      }
      if (selectedStaffTypeExist) {
        let containsQuery = false;
        const staffTypeId = oFetch(staffMember, 'staffTypeId');
        if (selectedStaffType.includes(staffTypeId)) {
          containsQuery = true;
        }
        if (!containsQuery) {
          matches = false;
        }
      }
      return matches;
    });
  }

  return {
    filteredStaffMembers: filterStaffMembers(),
    staffTypesOptions: staffTypesOptions,
    filter: filter,
    onFilterChange: onFilterChange,
  };
}
