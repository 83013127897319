import { combineReducers } from 'redux-immutable';
import effectiveStaffMembers from './staff-members-reducer';
import holidayRequests from './holiday-requests-reducer';
import permissionsData from './permissions-reducer';

export default combineReducers({
  effectiveStaffMembers,
  holidayRequests,
  permissionsData
});
