import React from 'react';
import oFetch from 'o-fetch';

export function NonEditableSIAField(props) {
  const { label } = props;
  const value = oFetch(props, 'input.value');

  return (
    <div className="boss-form__field">
      <p className="boss-form__label">
        <span className="boss-form__label-text">{label}</span>
      </p>
      <p className="boss-form__text">
        <span className="boss-form__text-primary boss-form__text-extra-large">{value}</span>
        <span className="boss-form__text-indicator">
          <span className="boss-indicator">
            <span className="boss-indicator__icon boss-indicator__icon_lock" />
          </span>
        </span>
      </p>
    </div>
  );
}
