import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function ReportItem(props) {
  const [report, onUserClick] = oFetch(props, 'report', 'onUserClick');

  const [userId, userFullName, context, reportText, at, uuid] = oFetch(
    report,
    'userId',
    'userFullName',
    'context',
    'reportText',
    'at',
    'uuid',
  );
  const reportAt = safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTimeAndSeconds());
  const firstCellClassNames = cn('boss-table__cell', { new: !!report.new });

  return (
    <div className="boss-table__row">
      <div className={firstCellClassNames}>
        <div className="boss-table__info">
          <p className="boss-table__label">Created At</p>
          <p className="boss-table__text">
            <span className="boss-table__text-line">{reportAt}</span>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Created By</p>
          <a
            href={`/users/${userId}`}
            target="_blank"
            rel="noreferrer"
            className="boss-table__text boss-action__text"
          >
            {userFullName}
          </a>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Context</p>
          <p className="boss-table__text">{context}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Report</p>
          <p className="boss-table__text">{reportText}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Rollbar Item Link</p>
          <a
            href={`https://rollbar.com/item/uuid/?uuid=${uuid}`}
            target="_blank"
            rel="noreferrer"
            className="boss-table__text boss-action__text"
          >
            Link
          </a>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Rollbar Occurrences Link</p>
          <button
            onClick={() => onUserClick(userId, userFullName)}
            className="boss-table__text boss-action__text"
          >
            All Items
          </button>
        </div>
      </div>
    </div>
  );
}
