import { z } from "zod";
import { $NonStaffMemberStaffPartyPerson as $MossPartyPersonNonStaffMemberStaffPartyPerson } from "@/apps/MossPartyPeople/types";
import { $NonStaffMemberStaffPartyPerson as $StaffPartyPersonNonStaffMemberStaffPartyPerson, $QrCodeType } from "@/apps/StaffPartyPeople/types";
import { format } from "date-fns";
import { UI_DATE_FORMAT } from "./date-fns-formats";
import { $MossFinanceReport } from "@/apps/MossFinanceReports/types";
import { $BoundaryMossHourTag, $IndexFilterData, $IndexFilterDataMode } from "@/apps/MossHourTags/types";
import { createZodObject } from "@/utils/type-to-zod";
import { WhatsappInstanceFormValues } from "@/apps/WhatsappServers/components/forms/whatsapp-instance-form";
import { WhatsappServerFormValues } from "@/apps/WhatsappServers/components/forms/whatsapp-server-form";
import { $CreateWhatsappServerSuccessData, $DisableWhatsappInstanceSuccessData, $RestartWhatsappServerSuccessData, $UpdateWhatsappInstanceCustomNameSuccessData, $WhatsappServer, CheckWhatsappInstanceAbilitySuccessData } from "@/apps/WhatsappServers/types";
import { $UpdateWhatsappFeatureAssignmentsSuccessData } from "@/apps/WhatsappFeatures/types";

export const Methods = {
  POST_METHOD: 'POST' as const,
  PATCH_METHOD: 'PATCH' as const,
  DELETE_METHOD: 'DELETE' as const,
  PUT_METHOD: 'PUT' as const,
  GET_METHOD: 'GET' as const,
};

const $CreateNonStaffMemberStaffPartyPersonPathFuncParams = z.object({});

const $CreateNonStaffMemberStaffPartyPersonCallParams = z.object({
  firstName: z.string(),
  surname: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  jobTitle: z.string(),
  avatarUrl: z.string(),
  otherJobTitle: z.string(),
  staffPartyQuizTeamId: z.number().int().positive().nullable(),
});

export const createNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $CreateNonStaffMemberStaffPartyPersonPathFuncParams>): string {
    $CreateNonStaffMemberStaffPartyPersonPathFuncParams.parse(params);
    return '/api/v1/non_staff_member_staff_party_people';
  },
  method: Methods.POST_METHOD,
  $CallParams: $CreateNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $CreateNonStaffMemberStaffPartyPersonPathFuncParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $StaffPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $CreateMossNonStaffMemberStaffPartyPersonPathFuncParams = z.object({});
const $CreateMossNonStaffMemberStaffPartyPersonCallParams = z.object({
  mossStaffMemberId: z.number().int().positive().nullable(),
  phoneNumber: z.string(),
  email: z.string(),
});

export const createMossNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $CreateMossNonStaffMemberStaffPartyPersonPathFuncParams>): string {
    $CreateMossNonStaffMemberStaffPartyPersonPathFuncParams.parse(params);
    return '/api/v1/non_staff_member_staff_party_people/moss';
  },
  method: Methods.POST_METHOD,
  $CallParams: $CreateMossNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $CreateMossNonStaffMemberStaffPartyPersonPathFuncParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $MossPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $UpdateNonStaffMemberStaffPartyPersonPathParams = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().nonnegative(),
});

const $UpdateNonStaffMemberStaffPartyPersonCallParams = $CreateNonStaffMemberStaffPartyPersonCallParams;

export const updateNonStaffMemberStaffPartyPerson = {
  getPath(rawParams: z.infer<typeof $UpdateNonStaffMemberStaffPartyPersonPathParams>): string {
    const params = $UpdateNonStaffMemberStaffPartyPersonPathParams.parse(
      rawParams
    );
    return `/api/v1/non_staff_member_staff_party_people/${params.nonStaffMemberStaffPartyPersonId}`;
  },
  method: Methods.PUT_METHOD,
  $CallParams: $UpdateNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $UpdateNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $StaffPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $UpdateMossNonStaffMemberStaffPartyPersonPathParams = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().nonnegative(),
});

const $UpdateMossNonStaffMemberStaffPartyPersonCallParams = z.object({
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  staffPartyQuizTeamId: z.number().int().positive().nullable(),
});

export const updateMossNonStaffMemberStaffPartyPerson = {
  getPath(rawParams: z.infer<typeof $UpdateMossNonStaffMemberStaffPartyPersonPathParams>): string {
    const params = $UpdateMossNonStaffMemberStaffPartyPersonPathParams.parse(
      rawParams
    );
    return `/api/v1/non_staff_member_staff_party_people/${params.nonStaffMemberStaffPartyPersonId}/update_moss`;
  },
  method: Methods.PUT_METHOD,
  $CallParams: $UpdateMossNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $UpdateMossNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $MossPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $DisableNonStaffMemberStaffPartyPersonCallParams: z.ZodSchema = z.object({});

const $DisableNonStaffMemberStaffPartyPersonPathParams: z.ZodSchema = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
});

export const disableNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $DisableNonStaffMemberStaffPartyPersonPathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}`;
  },
  method: Methods.DELETE_METHOD,
  $CallParams: $DisableNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $DisableNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $StaffPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $DisableMossNonStaffMemberStaffPartyPersonPathParams = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
});

const $DisableMossNonStaffMemberStaffPartyPersonCallParams: z.ZodSchema = z.record(z.never());

export const disableMossNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $DisableMossNonStaffMemberStaffPartyPersonPathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}/moss`;
  },
  method: Methods.DELETE_METHOD,
  $CallParams: $DisableMossNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $DisableMossNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $MossPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $EnableNonStaffMemberStaffPartyPersonCallParams: z.ZodSchema = z.record(z.never());

const $EnableNonStaffMemberStaffPartyPersonPathParams: z.ZodSchema = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
});

export const enableNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $EnableNonStaffMemberStaffPartyPersonPathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}/enable`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $EnableNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $EnableNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $StaffPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $EnableMossNonStaffMemberStaffPartyPersonCallParams: z.ZodSchema = z.record(z.never());

const $EnableMossNonStaffMemberStaffPartyPersonPathParams: z.ZodSchema = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
});

export const enableMossNonStaffMemberStaffPartyPerson = {
  getPath(params: z.infer<typeof $EnableMossNonStaffMemberStaffPartyPersonPathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}/moss_enable`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $EnableMossNonStaffMemberStaffPartyPersonCallParams,
  $PathParams: $EnableMossNonStaffMemberStaffPartyPersonPathParams,
  $SuccessData: z.object({
    nonStaffMemberStaffPartyPerson: $MossPartyPersonNonStaffMemberStaffPartyPerson
  }),
};

const $PrintNonStaffMemberStaffPartyPersonQRCodePathParams = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
  qrCodeType: $QrCodeType,
});

export const printNonStaffMemberStaffPartyPersonQrCode = {
  getPath(params: z.infer<typeof $PrintNonStaffMemberStaffPartyPersonQRCodePathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}/qr_code?qrCodeType=${params.qrCodeType}`;
  },
  method: Methods.GET_METHOD,
  $CallParams: z.record(z.never()),
  $PathParams: $PrintNonStaffMemberStaffPartyPersonQRCodePathParams,
  $SuccessData: z.object({
    image: z.string().url(),
    filename: z.string(),
  }),
};

const $ResendNonStaffMemberStaffPartyPersonInviteEmailPathParams = z.object({
  nonStaffMemberStaffPartyPersonId: z.number().int().positive(),
});

export const resendStaffPartyPersonInviteEmail = {
  getPath(params: z.infer<typeof $ResendNonStaffMemberStaffPartyPersonInviteEmailPathParams>): string {
    const nonStaffMemberStaffPartyPersonId = params.nonStaffMemberStaffPartyPersonId;
    return `/api/v1/non_staff_member_staff_party_people/${nonStaffMemberStaffPartyPersonId}/resend_invite_email`;
  },
  method: Methods.POST_METHOD,
  $CallParams: z.record(z.never()),
  $PathParams: $ResendNonStaffMemberStaffPartyPersonInviteEmailPathParams,
  $SuccessData: z.record(z.never()),
};

const $CreateMossHourTagPathParams = z.record(z.never());

const $CreateMossHoutTagCallParams = z.object({
  name: z.string(),
});

export const createMossHourTag = {
  getPath(): string {
    return `api/v1/moss_hour_tags`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $CreateMossHoutTagCallParams,
  $PathParams: $CreateMossHourTagPathParams,
  $SuccessData: z.object({ mossHourTag: $BoundaryMossHourTag }),
} as const;

const $EnableMossHourTagPathParams = z.object({
  tagId: z.number().int().positive(),
});

type EnableMossHourTagPathParams = z.infer<typeof $EnableMossHourTagPathParams>;

export const enableMossHourTag = {
  getPath: (params: EnableMossHourTagPathParams) => `/api/v1/moss_hour_tags/${params.tagId}/enable`,
  method: Methods.POST_METHOD,
  $CallParams: z.record(z.never()),
  $PathParams: $EnableMossHourTagPathParams,
  $SuccessData: z.object({
    mossHourTag: $BoundaryMossHourTag
  }),
};

const $DisableMossHourTagPathParams = z.object({
  tagId: z.number().int().positive(),
});

type DisableMossHourTagPathParams = z.infer<typeof $DisableMossHourTagPathParams>;

export const disableMossHourTag = {
  getPath: (params: DisableMossHourTagPathParams) => `/api/v1/moss_hour_tags/${params.tagId}/disable`,
  method: Methods.POST_METHOD,
  $CallParams: z.record(z.never()),
  $PathParams: $DisableMossHourTagPathParams,
  $SuccessData: z.object({ mossHourTag: $BoundaryMossHourTag }),
};

const $UpdateMossFinanceReportNotePathParams = z.object({
  date: z.date(),
});

type UpdateMossFinanceReportNotePathParams = z.infer<typeof $UpdateMossFinanceReportNotePathParams>;

const $UpdateMossFinanceReportNoteCallParams = z.object({
  note: z.string(),
  mossStaffMemberId: z.number().int().positive(),
  venueId: z.number().int().positive(),
});

export const updateMossFinanceReportNote = {
  getPath: (args: UpdateMossFinanceReportNotePathParams) => `/api/v1/moss_finance_reports/${format(args.date, UI_DATE_FORMAT)}/update_note`,
  method: Methods.POST_METHOD,
  $CallParams: $UpdateMossFinanceReportNoteCallParams,
  $PathParams: $UpdateMossFinanceReportNotePathParams,
  $SuccessData: z.object({ mossFinanceReport: $MossFinanceReport }),
};

const $MossHourTagsIndexPathParams = z.object({
  searchQuery: z.string(),
  mode: $IndexFilterDataMode,
});

type MossHourTwagsIndexPathParams = z.infer<typeof $MossHourTagsIndexPathParams>;

export const mossHourTagsIndex = {
  getPath: (pathParams: MossHourTwagsIndexPathParams) => {
    return `api/v1/moss_hour_tags?mode=${pathParams.mode}&query=${pathParams.searchQuery}`;
  },
  method: Methods.GET_METHOD,
  $CallParams: z.record(z.never()),
  $PathParams: $MossHourTagsIndexPathParams,
  $SuccessData: z.object({
    indexFilterData: $IndexFilterData,
    mossHourTags: z.array($BoundaryMossHourTag),
  }),
};

const $CheckWhatsappInstanceAvailabilityCallParams = createZodObject<WhatsappInstanceFormValues>({
  whatsappServerId: z.number(),
  name: z.string(),
  customName: z.string().nullable(),
  instanceHash: z.string(),
  token: z.string(),
  baseUrl: z.string(),
  lastAvailabilityCheck: z.string(),
  isAvailable: z.boolean(),
});

const $CheckWhatsappInstanceAvailabilityPathFuncParams = z.object({});
const $CheckWhatsappInstanceAbilitySuccessData = createZodObject<CheckWhatsappInstanceAbilitySuccessData>({
  authenticated: z.boolean(),
  instanceName: z.string()
});

export function checkWhatsappServiceAvailability(baseUrl: string, instanceHash: string) {
  return {
    getPath: () => `${baseUrl}/api/${instanceHash}/check-availability`,
    method: Methods.POST_METHOD,
    $CallParams: $CheckWhatsappInstanceAvailabilityCallParams,
    $PathParams: $CheckWhatsappInstanceAvailabilityPathFuncParams,
    $SuccessData: $CheckWhatsappInstanceAbilitySuccessData,
  };
}

const $CreateWhatsappServerCallParams = createZodObject<WhatsappServerFormValues>({
  name: z.string(),
  baseUrl: z.string(),
  ssh: z.string(),
});

const $CreateWhatsappServerFuncParams = z.object({});

export const createWhatsappServer = {
  getPath: () => `/api/v1/whatsapp_services/whatsapp_servers`,
  method: Methods.POST_METHOD,
  $CallParams: $CreateWhatsappServerCallParams,
  $PathParams: $CreateWhatsappServerFuncParams,
  $SuccessData: $CreateWhatsappServerSuccessData,
};

const $CreateWhatsappInstanceCallParams = createZodObject<WhatsappInstanceFormValues>({
  whatsappServerId: z.number(),
  name: z.string(),
  customName: z.string().nullable(),
  instanceHash: z.string(),
  token: z.string(),
  baseUrl: z.string(),
  lastAvailabilityCheck: z.string(),
  isAvailable: z.boolean(),
});

const $CreateWhatsappInstanceFuncParams = z.object({
  whatsappServerId: z.number(),
});

const $CreateWhatsappInstanceSuccessData = z.object({
  whatsappServer: $WhatsappServer
});

type CreateWhatsappInstanceFuncParams = z.infer<typeof $CreateWhatsappInstanceFuncParams>;

export const createWhatsappInstance = {
  getPath: (params: CreateWhatsappInstanceFuncParams) => {
    return `/api/v1/whatsapp_services/whatsapp_servers/${params.whatsappServerId}/whatsapp_instances`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $CreateWhatsappInstanceCallParams,
  $PathParams: $CreateWhatsappInstanceFuncParams,
  $SuccessData: $CreateWhatsappInstanceSuccessData,
};

const $UpdateWhatsappInstanceCustomNameCallParams = z.object({
  customName: z.string().nullable(),
});

const $UpdateWhatsappInstanceCustomNameFuncParams = z.object({
  whatsappServerId: z.number(),
  whatsappInstanceId: z.number(),
});
type UpdateWhatsappInstanceCustomNameFuncParams = z.infer<typeof $UpdateWhatsappInstanceCustomNameFuncParams>;

export const updateWhatsappInstanceCustomName = {
  getPath: (params: UpdateWhatsappInstanceCustomNameFuncParams) => {
    return `/api/v1/whatsapp_services/whatsapp_servers/${params.whatsappServerId}/whatsapp_instances/${params.whatsappInstanceId}/update_custom_name`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $UpdateWhatsappInstanceCustomNameCallParams,
  $PathParams: $UpdateWhatsappInstanceCustomNameFuncParams,
  $SuccessData: $UpdateWhatsappInstanceCustomNameSuccessData,
};

const $DisableWhatsappInstanceCallParams = z.object({});

const $DisableWhatsappInstanceFuncParams = z.object({
  whatsappServerId: z.number(),
  whatsappInstanceId: z.number(),
});
type DisableWhatsappInstanceFuncParams = z.infer<typeof $DisableWhatsappInstanceFuncParams>;

export const disableWhatsappInstance = {
  getPath: (params: DisableWhatsappInstanceFuncParams) => {
    return `/api/v1/whatsapp_services/whatsapp_servers/${params.whatsappServerId}/whatsapp_instances/${params.whatsappInstanceId}`;
  },
  method: Methods.DELETE_METHOD,
  $CallParams: $DisableWhatsappInstanceCallParams,
  $PathParams: $DisableWhatsappInstanceFuncParams,
  $SuccessData: $DisableWhatsappInstanceSuccessData,
};

const $RestartWhatsappServerCallParams = z.object({});

const $RestartWhatsappServerFuncParams = z.object({
  whatsappServerId: z.number(),
});
type RestartWhatsappServerFuncParams = z.infer<typeof $RestartWhatsappServerFuncParams>;

export const restartWhatsappServer = {
  getPath: (params: RestartWhatsappServerFuncParams) => {
    return `/api/v1/whatsapp_services/whatsapp_servers/${params.whatsappServerId}/restart`;
  },
  method: Methods.POST_METHOD,
  $CallParams: $RestartWhatsappServerCallParams,
  $PathParams: $RestartWhatsappServerFuncParams,
  $SuccessData: $RestartWhatsappServerSuccessData,
};

const $UpdateWhatsappFeatureAssignmentsCallParams = z.object({
  mainWhatsappInstanceId: z.number().nullable(),
  fallbackWhatsappInstanceId: z.number().nullable(),
});

const $UpdateWhatsappFeatureAssignmentsPathFuncParams = z.object({});

export function updateWhatsappFeatureAssignments(whatsappFeatureAssignmentId: number) {
  return {
    getPath: () => `/api/v1/whatsapp_services/whatsapp_feature_assignments/${whatsappFeatureAssignmentId}`,
    method: Methods.PUT_METHOD,
    $CallParams: $UpdateWhatsappFeatureAssignmentsCallParams,
    $PathParams: $UpdateWhatsappFeatureAssignmentsPathFuncParams,
    $SuccessData: $UpdateWhatsappFeatureAssignmentsSuccessData,
  };
}
