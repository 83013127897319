import oFetch from 'o-fetch';
import React from 'react';

export function VenueHours(props) {
  const payrollBreakdown = oFetch(props, 'payrollBreakdown');
  const [
    insideHours,
    outsideHours,
    outsourcedHours,
    holidayHours,
    owedHours,
    sickLeaveHours,
  ] = oFetch(
    payrollBreakdown,
    'insideHours',
    'outsideHours',
    'outsourcedHours',
    'holidayHours',
    'owedHours',
    'sickLeaveHours',
  );
  return (
    <div className="boss-board__header-group">
      <div className="boss-table boss-table_page_bonai-venue-hour-data">
        <div className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Inside Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {insideHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Outside Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {outsideHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Outsourced Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {outsourcedHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Holiday Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {holidayHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Owed Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {owedHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <div className="boss-number boss-number_layout_row">
                <div className="boss-number__content">
                  <p className="boss-number__label boss-number__label_position_before">
                    Sick Leave Hours:
                  </p>
                  <h3 className="boss-number__value boss-number__value_size_xs boss-number__value_color_gray-lighten">
                    {sickLeaveHours}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
