import moment from 'moment';
import oFetch from 'o-fetch';
import safeMoment from '../safe-moment';

export function sTimeNeedsAsterisk(args: { sTime: string }): boolean {
  const sTime = oFetch(args, 'sTime');
  return mTimeNeedsAsterisk({ mTime: safeMoment.iso8601Parse(sTime) });
}

export function sGetTimeAsterisk(args: { sTime: string }): string {
  const sTime = oFetch(args, 'sTime');
  return mGetTimeAsterisk({ mTime: safeMoment.iso8601Parse(sTime) });
}

export function timeNeedsAsterisk(args: { dTime: Date }): boolean {
  const dTime = oFetch(args, 'dTime');
  return mTimeNeedsAsterisk({ mTime: moment(dTime) });
}

export function dGetTimeAsterisk(args: { dTime: Date }): string {
  const dTime = oFetch(args, 'dTime');
  return mGetTimeAsterisk({ mTime: moment(dTime) });
}

export function mTimeNeedsAsterisk(args: { mTime: moment.Moment }): boolean {
  const mTime = oFetch(args, 'mTime');
  const mOneHourAgo = mTime.clone().subtract(1, 'hours');
  return mTime.hours() === mOneHourAgo.hours();
}

export function mGetTimeAsterisk(args: { mTime: moment.Moment }): string {
  const timeNeedsAsterisk = mTimeNeedsAsterisk({ mTime: oFetch(args, 'mTime') });
  return timeNeedsAsterisk ? '*' : '';
}