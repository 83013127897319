import React from 'react';
import oFetch from 'o-fetch';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { CountingBundleField, CountingLooseField } from './form-fields';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

function DenominationRow(props) {
  const [label, value, foreignValue, pence, sign, totalSign] = oFetch(
    props,
    'label',
    'value',
    'foreignValue',
    'pence',
    'sign',
    'totalSign',
  );

  function getTotal() {
    const sum = value + foreignValue;
    if (pence) {
      return sum / 100;
    }
    return sum;
  }

  function getValue(value) {
    if (pence) {
      return value / 100;
    }
    return value;
  }

  return (
    <div className="purple-table__group">
      <div className="purple-table__row">
        <div className="purple-table__cell">
          <div className="purple-table__info">
            <p className="purple-table__text">{label}</p>
          </div>
        </div>
        <div className="purple-table__cell">
          <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_justify_center purple-form-field_adjust_table-cell">
            <div className="purple-form-field__box purple-form-field__box_size_l">
              {sign}
              {getValue(value)}
            </div>
          </div>
        </div>
        <div className="purple-table__cell">
          <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_justify_center purple-form-field_adjust_table-cell">
            <div className="purple-form-field__box purple-form-field__box_size_l">
              {sign}
              {getValue(foreignValue)}
            </div>
          </div>
        </div>
        <div className="purple-table__cell">
          <div className="purple-form-field purple-form-field_justify_center purple-form-field_adjust_table-cell">
            <div className="purple-form-field__value">
              {totalSign}
              {getTotal()}
            </div>
          </div>
        </div>
      </div>
      <div className="purple-table__panel">
        <div className="purple-panel purple-panel_role_board-group">
          <div className="purple-panel__group">
            <div className="purple-panel__group-content">
              <div className="purple-panel__title">{label}</div>
              <div className="purple-panel__group-line">
                <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_role_control">
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Value</span>
                  </p>
                  <div className="purple-form-field__box purple-form-field__box_size_l">
                    {sign}
                    {getValue(value)}
                  </div>
                </div>
              </div>
              <div className="purple-panel__group-line">
                <div className="purple-form-field purple-form-field_highlight_gray-light purple-form-field_role_control">
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Foreign</span>
                  </p>
                  <div className="purple-form-field__box purple-form-field__box_size_l">
                    {sign}
                    {getValue(foreignValue)}
                  </div>
                </div>
              </div>
              <div className="purple-panel__group-line">
                <div className="purple-form-field purple-form-field_role_control">
                  <p className="purple-form-field__label">
                    <span className="purple-form-field__label-text">Total</span>
                  </p>
                  <div className="purple-form-field__value">
                    {totalSign}
                    {getTotal()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DenominationRow.defaultProps = {
  pence: false,
  sign: '£',
  totalSign: '£',
};

function WeekCountForm(props) {
  const [submitting, handleSubmit, totalWeekCounts, editable, weekDaysCompleted] = oFetch(
    props,
    'submitting',
    'handleSubmit',
    'totalWeekCounts',
    'editable',
    'weekDaysCompleted',
  );

  const { error } = props;
  const disabled = !editable || submitting;

  const [
    fiftyNotesTotalPounds,
    foreignFiftyNotesTotalPounds,
    twentyNotesTotalPounds,
    foreignTwentyNotesTotalPounds,
    tenNotesTotalPounds,
    foreignTenNotesTotalPounds,
    fiveNotesTotalPounds,
    foreignFiveNotesTotalPounds,
    changeTotalPence,
    foreignChangeTotalPence,
  ] = oFetch(
    totalWeekCounts,
    'fiftyNotesTotalPounds',
    'foreignFiftyNotesTotalPounds',
    'twentyNotesTotalPounds',
    'foreignTwentyNotesTotalPounds',
    'tenNotesTotalPounds',
    'foreignTenNotesTotalPounds',
    'fiveNotesTotalPounds',
    'foreignFiveNotesTotalPounds',
    'changeTotalPence',
    'foreignChangeTotalPence',
  );

  function renderDenominationHeader() {
    return (
      <div className="purple-table__row purple-table__row_role_header">
        <div className="purple-table__cell purple-table__cell_role_header">Denomination</div>
        <div className="purple-table__cell purple-table__cell_role_header">Value</div>
        <div className="purple-table__cell purple-table__cell_role_header">Foreign</div>
        <div className="purple-table__cell purple-table__cell_role_header">Total</div>
      </div>
    );
  }

  function renderBundlesHeader() {
    return (
      <div className="purple-table__row purple-table__row_role_header">
        <div className="purple-table__cell purple-table__cell_role_header">Number of Bundles</div>
        <div className="purple-table__cell purple-table__cell_role_header" />
      </div>
    );
  }

  function renderBaseError() {
    if (!error) {
      return null;
    }
    return (
      <div className="purple-form-error purple-form-error_role_general purple-form-error_position_above">
        {error.map((errorMessage, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {errorMessage}
            </p>
          );
        })}
      </div>
    );
  }

  function renderUncompletedError() {
    return (
      <div className="purple-form-error purple-form-error_role_general purple-form-error_position_above">
        <p className="purple-form-error__text">
          All days of the week must be completed before submitting bundle totals
        </p>
      </div>
    );
  }

  return (
    <div className="purple-form">
      {!weekDaysCompleted && renderUncompletedError()}
      {error && renderBaseError()}
      <div className="purple-form__row purple-form__row_adjust_wrap-m-minor">
        <div className="purple-form__group purple-form__group_size_max">
          <div className="purple-form__group">
            <div className="purple-table purple-table_page_counting-totals">
              {renderDenominationHeader()}
              <DenominationRow
                label={'£50'}
                value={fiftyNotesTotalPounds}
                foreignValue={foreignFiftyNotesTotalPounds}
              />
              <DenominationRow
                label={'£20'}
                value={twentyNotesTotalPounds}
                foreignValue={foreignTwentyNotesTotalPounds}
              />
              <DenominationRow label={'£10'} value={tenNotesTotalPounds} foreignValue={foreignTenNotesTotalPounds} />
              <DenominationRow label={'£5'} value={fiveNotesTotalPounds} foreignValue={foreignFiveNotesTotalPounds} />
              <DenominationRow label={'Change'} value={changeTotalPence} pence foreignValue={foreignChangeTotalPence} />
            </div>
          </div>
        </div>
        <div className="purple-form__group purple-form__group_size_fourth purple-form__group_position_last">
          <div className="purple-form__group">
            <div className="purple-table purple-table_page_counting-bundles">
              {renderBundlesHeader()}
              <Field name="fiftyNotesBundleCount" label={'£50'} disabled={disabled} component={CountingBundleField} />
              <Field name="twentyNotesBundleCount" label={'£20'} disabled={disabled} component={CountingBundleField} />
              <Field name="tenNotesBundleCount" label={'£10'} disabled={disabled} component={CountingBundleField} />
              <Field name="fiveNotesBundleCount" label={'£5'} disabled={disabled} component={CountingBundleField} />
            </div>
          </div>
          <div className="purple-form__group purple-form__group_position_last">
            <div className="purple-table purple-table_page_counting-loose">
              <div className="purple-table__row purple-table__row_role_header">
                <div className="purple-table__cell purple-table__cell_role_header">Loose</div>
              </div>
              <Field name="looseTotalPence" disabled={disabled} component={CountingLooseField} />
            </div>
          </div>
        </div>
      </div>
      <div className="purple-form__row purple-form__row_justify_end purple-form__row_align_middle purple-form__row_position_last">
        {editable && (
          <div className="purple-form__field purple-form__field_size_min purple-form__field_position_last">
            <div className="purple-form__actions purple-form__actions_justify_center">
              <button
                onClick={handleSubmit}
                className="purple-button purple-button_color_accent-primary purple-button_icon_check purple-form__action"
                type="button"
                disabled={disabled}
              >
                <span className="purple-button__text">Submit</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  onChange(newValues, dispatch, props, oldValue) {
    const pristine = oFetch(props, 'pristine');
    dispatch(actions.setTabStatus({ all: { pristine } }));
  },
})(WeekCountForm);
