import cn from 'classnames';
import oFetch from 'o-fetch';
import React, { useState, Fragment } from 'react';
import { Collapse } from 'react-collapse';
import utils from '@/lib/utils';

CollapsibleCard.defaultProps = {
  className: null,
  defaultOpen: false,
  additionalGroups: null,
  renderButtonGroup: null,
  innerClassName: '',
};

export default function CollapsibleCard(props) {
  const [
    title,
    children,
    className,
    defaultOpen,
    additionalGroups,
    renderButtonGroup,
    innerClassName,
  ] = oFetch(
    props,
    'title',
    'children',
    'className',
    'defaultOpen',
    'additionalGroups',
    'renderButtonGroup',
    'innerClassName',
  );
  const [isOpen, setOpenState] = useState(defaultOpen);
  const arrowClassNames = cn('boss-board__switch', { 'boss-board__switch_state_opened': isOpen });
  const cardClassNames = cn('boss-board boss-board_context_stack', { [className]: !!className });
  const innerClassNames = cn('boss-board__content-inner', { [innerClassName]: !!innerClassName });
  function toggleCard() {
    setOpenState(!isOpen);
  }

  function renderTitle() {
    if (utils.isFunction(title)) {
      return title();
    }
    return (
      <h2 className="boss-board__title boss-board__title_size_medium">
        <span className="boss-board__title-text">{title}</span>
      </h2>
    );
  }

  return (
    <section className={cardClassNames}>
      <header className="boss-board__header">
        {additionalGroups && (
          <Fragment>
            <div className="boss-board__header-group">
              {renderTitle()}
              <div className="boss-board__button-group">
                {renderButtonGroup && renderButtonGroup()}
                <button onClick={toggleCard} type="button" className={arrowClassNames} />
              </div>
            </div>
            {additionalGroups && additionalGroups()}
          </Fragment>
        )}
        {!additionalGroups && (
          <Fragment>
            {renderTitle()}
            <div className="boss-board__button-group">
              {renderButtonGroup && renderButtonGroup()}
              <button onClick={toggleCard} type="button" className={arrowClassNames} />
            </div>
          </Fragment>
        )}
      </header>
      <Collapse isOpened={isOpen} className="boss-board__content" style={{ display: 'block' }}>
        <div className={innerClassNames}>{children}</div>
      </Collapse>
    </section>
  );
}
