import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import configureStore from '../store';
import { initialProfileLoad } from '../profile-wrapper/actions';
import ProfilePage from './containers/profile-page';
import profileReducer from '../profile-wrapper/reducers';

export function StaffMemberProfileDetailsApp(props) {
  const [store, setStore] = useState(null);
  const appDownloadLinks = oFetch(props, 'appDownloadLinks');
  const [permittedRolesOptions, apps, venues, isAdminPlus] = oFetch(
    props,
    'permittedRolesOptions',
    'apps',
    'venues',
    'isAdminPlus',
  );

  useEffect(() => {
    const store = configureStore(
      combineReducers({
        profile: profileReducer,
        form: formReducer,
      }),
    );
    store.dispatch(initialProfileLoad({ ...props }));
    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, apps, venues, isAdminPlus }}>
        <ModalsProvider>
          <ProfilePage appDownloadLinks={appDownloadLinks} />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
