import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import AsyncButton from 'react-async-button';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';

import { FileList, UPLOADING_STATE, BROKEN_STATE, UPLOADED_STATE } from './file-list';

UploadFilesFormField.propTypes = {
  label: PropTypes.string,
};

UploadFilesFormField.defaultProps = {
  onDelete: null,
  softDelete: true,
};

export function UploadFilesFormField(props) {
  const [onChange, value, meta, files, softDelete] = oFetch(
    props,
    'input.onChange',
    'input.value',
    'meta',
    'files',
    'softDelete',
  );
  const [uploadingFiles, setUploadingFiles] = useState(files);
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileIds, setFileIds] = useState(value);
  const [onUpload, onDelete] = oFetch(props, 'onUpload', 'onDelete');
  const { label } = props;

  if (!softDelete && !onDelete) {
    throw new Error('onDelete function must exist');
  }

  useEffect(() => {
    onChange(fileIds);
  }, [fileIds]);

  function addFileIdToState(fileId) {
    setFileIds(prevFileIds => {
      return [...prevFileIds, fileId];
    });
  }

  function deleteFileIdFromState(fileId) {
    setFileIds(prevFileIds => {
      return prevFileIds.filter(fId => fId !== fileId);
    });
  }

  function handleDeleteFile(file) {
    const fileId = oFetch(file, 'id');
    if (softDelete) {
      setSelectedFile(null);
      deleteFileIdFromState(fileId);
      deleteFileFromState(file);
    } else {
      return onDelete({
        values: {
          id: oFetch(file, 'id'),
        },
        onSuccess() {
          setSelectedFile(null);
          deleteFileIdFromState(fileId);
          deleteFileFromState(file);
        },
        onFailure() {
          setSelectedFile(null);
          const newValues = value.filter(value => value !== fileId);
          onChange(newValues);
          deleteFileFromState(file);
          return true;
        },
      });
    }
  }

  function deleteFileFromState(file) {
    setUploadingFiles(prevState => {
      return Object.entries(prevState).reduce((acc, prevFileEntry) => {
        const [id, prevFile] = prevFileEntry;
        if (id !== oFetch(file, 'id').toString()) {
          acc[id] = prevFile;
        }
        return acc;
      }, {});
    });
  }

  function addFilesToState(normalizedFiles) {
    setUploadingFiles(prevState => {
      return { ...prevState, ...normalizedFiles };
    });
  }

  function updateUploadedFileState(updatedGuid, params) {
    const [id, url, state, isImage, contentType] = oFetch(
      params,
      'id',
      'url',
      'state',
      'isImage',
      'contentType',
    );

    setUploadingFiles(prevState => {
      return Object.entries(prevState).reduce((acc, prevFileEntry) => {
        const [guid, prevFileObject] = prevFileEntry;
        if (guid === updatedGuid) {
          acc[id] = {
            ...prevFileObject,
            id: id,
            url: url,
            state: state,
            isImage: isImage,
            contentType: contentType,
          };
        } else {
          acc[guid] = prevFileObject;
        }
        return acc;
      }, {});
    });
  }

  function normalizeFiles(files) {
    return files.reduce((acc, file) => {
      const guid = uuidv4();
      acc[guid] = {
        id: null,
        name: file.name,
        url: null,
        file: file,
        state: UPLOADING_STATE,
      };
      return acc;
    }, {});
  }

  function handleFileClick(file) {
    setSelectedFile(file);
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const normalizedFiles = normalizeFiles(acceptedFiles);

    addFilesToState(normalizedFiles);

    Object.entries(normalizedFiles).forEach(fileEntry => {
      const [guid, fileObject] = fileEntry;
      const file = oFetch(fileObject, 'file');
      const reader = new window.FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        const formData = new window.FormData();
        formData.append('upload[file]', file, file.name);

        onUpload({
          values: formData,
          onSuccess(data) {
            const [id, url, isImage, contentType] = oFetch(data, 'id', 'url', 'isImage', 'contentType');
            addFileIdToState(id);
            updateUploadedFileState(guid, {
              id: id,
              url: url,
              state: UPLOADED_STATE,
              isImage: isImage,
              contentType: contentType,
            });
          },
          onFailure(params) {
            const statusCode = oFetch(params, 'statusCode');
            const errors = oFetch(params, 'errors');
            const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

            if (statusCode === 422 && errors) {
              supportedKeyChecker.validateKeys({
                suppliedKeys: Object.keys(errors),
                supportedKeys: ['file'],
              });
              return errors;
            }
          },
        });
      };
      reader.readAsBinaryString(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dropboxContainerClassNames = cn('boss-upload__field boss-upload__field_adjust_form', {
    'dropbox-drag-active': isDragActive,
  });

  return (
    <div className="boss-form__field">
      {label && (
        <p className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </p>
      )}
      <div className="boss-upload">
        <div {...getRootProps()} className={dropboxContainerClassNames}>
          <input {...getInputProps()} className="boss-upload__field-input" />
          {isDragActive ? (
            <p className="boss-upload__field-label boss-upload__field-label_adjust_form">
              Drop file here to upload
            </p>
          ) : (
            <p className="boss-upload__field-label boss-upload__field-label_adjust_form">
              {"Drag 'n' drop some files here, or click to select files"}
            </p>
          )}
        </div>
        {selectedFile && (
          <div className="boss-upload__full boss-upload__full_adjust_form">
            <button onClick={() => setSelectedFile(null)} type="button" className="boss-upload__full-close">
              Close
            </button>
            {selectedFile.isImage && (
              <img src={selectedFile.url} alt="attachment-1" className="boss-upload__full-image" />
            )}
            <div className="boss-upload__full-actions">
              <AsyncButton
                className="boss-button boss-button_role_delete boss-button_type_small"
                text="Delete"
                pendingText="Deleting ..."
                onClick={() => handleDeleteFile(selectedFile)}
              />
            </div>
          </div>
        )}
        <FileList files={uploadingFiles} onFileClick={handleFileClick} />
      </div>
    </div>
  );
}
