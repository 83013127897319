import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import Page from '../components/page';
import { getStaffTypesWithFinanceReports, getWeekDates } from '../selectors';
import { changePayRateFilter } from '../redux/actions';

const mapStateToProps = state => {
  const stateJS = state.toJS();
  return {
    date: state.getIn(['page', 'date']),
    venueId: state.getIn(['page', 'venueId']),
    startDate: state.getIn(['page', 'startDate']),
    endDate: state.getIn(['page', 'endDate']),
    staffTypesWithFinanceReports: getStaffTypesWithFinanceReports(state),
    paymentDataByStaffMemberId: oFetch(stateJS, 'paymentDataByStaffMemberId'),
    weekDates: getWeekDates(state),
    filterType: state.getIn(['page', 'filterType']),
    paymentWeekData: oFetch(stateJS, 'paymentWeekData'),
    permissions: state.get('permissions'),
  };
};

const mapDispatchToProps = {
  changePayRateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
