import { combineReducers } from 'redux-immutable';
import page from './page-options';
import financeReports from './finance-reports';
import paymentWeekPublished from './payment-week-published';
import { financeReportValidationErrorsReducer } from './finance-report-validation-errors';
import { financeReportsOverviewDataReducer } from './finance-reports-overview-data';
import financeReportWeekReducer from './finance-report-week';

export default combineReducers({
  page,
  financeReports,
  paymentWeekPublished,
  financeReportValidationErrors: financeReportValidationErrorsReducer,
  financeReportsOverviewData: financeReportsOverviewDataReducer,
  financeReportWeek: financeReportWeekReducer,
});
