import React, { Component } from 'react';
import oFetch from 'o-fetch';

import EditTaskForm from './edit-task-form';

export default class EditTask extends Component {
  onSubmit = (values, dispatch, form) => {
    const onSubmit = oFetch(this.props, 'onSubmit');
    return onSubmit(values);
  };
  render() {
    const task = oFetch(this.props, 'task');
    const [id, text] = oFetch(task, 'id', 'text');
    const initialValues = {
      id: id,
      text: text,
    };
    return <EditTaskForm initialValues={initialValues} onSubmit={this.onSubmit} />;
  }
}
