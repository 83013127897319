import React from 'react';
import oFetch from 'o-fetch';
import { SimpleDashboard, DashboardActions } from '@/components/boss-dashboards';

import VenueSelect from './venue-select';

import AccessoryList from './accessory-list';
import AccessoryListItem from './accessory-list-item';

function AccessoryRequestsPage(props) {
  function handleVenueChange(venue) {
    props.actions.changeVenue(venue);
  }

  function handleLoadMore() {
    props.actions.loadMoreClick();
  }

  function getAccessories() {
    const {
      accessories,
      pagination: { pageNumber, perPage },
    } = props;
    const slice = pageNumber * perPage;
    if (accessories.length) {
      return accessories.slice(0, slice);
    }
    return [];
  }

  const accessories = getAccessories();
  const isShowLoadMore = accessories.length < props.pagination.totalCount;
  const venues = oFetch(props, 'venues');
  const currentVenue = oFetch(props, 'currentVenue');
  const accessoryRequests = oFetch(props, 'accessoryRequests');
  const accessoryRefundRequests = oFetch(props, 'accessoryRefundRequests');
  const staffMembers = oFetch(props, 'staffMembers');
  const getAccessoryRequestPermission = oFetch(props, 'getAccessoryRequestPermission');
  const getAccessoryRefundRequestPermission = oFetch(props, 'getAccessoryRefundRequestPermission');

  return (
    <div>
      <SimpleDashboard title="Accessory Requests">
        <DashboardActions>
          <VenueSelect venues={venues} selected={currentVenue} onChange={handleVenueChange} />
        </DashboardActions>
      </SimpleDashboard>
      <AccessoryList
        accessories={getAccessories()}
        accessoryRequests={accessoryRequests}
        venue={props.venues.find(venue => oFetch(venue, 'id') === props.currentVenue)}
        accessoryRefundRequests={accessoryRefundRequests}
        staffMembers={staffMembers}
        onLoadMoreClick={handleLoadMore}
        totalCount={props.pagination.totalCount}
        isShowLoadMore={isShowLoadMore}
        accessoryItemRenderer={data => (
          <AccessoryListItem
            getAccessoryRequestPermission={getAccessoryRequestPermission}
            getAccessoryRefundRequestPermission={getAccessoryRefundRequestPermission}
            actions={props.actions}
            data={data}
          />
        )}
      />
    </div>
  );
}

export default AccessoryRequestsPage;
