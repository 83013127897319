import React, { useRef, useState } from 'react';
import d3 from 'd3'; // nvd3 relies on global D3
import NVD3Chart from 'react-nvd3';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
window.d3 = d3;

const HOVER_INDICATOR_WIDTH = 10;

const MILLISECONDS_PER_HOURS = 60 * 60 * 1000;

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  eventPassthrough: true,
};

export default function RotaOverviewChartInner(props) {
  const [rotasTime, setRotasTime] = useState(true);
  const [rotasInfo, setRotasInfo] = useState(false);
  const svgRef = useRef();

  function highlightControls() {
    if (props.chartData.length > 0) {
      return (
        <div className="rota-overview-chart__controls">
          <span className="rota-overview-chart__controls-label">Highlight:&nbsp;</span>
          <button
            className={`boss-button boss-button_type_small boss-button_role_highlight rota-overview-chart__controls-button ${rotasTime ? 'boss-button_state_active' : ''
            }`}
            onClick={() => {
              setRotasTime(true);
              setRotasInfo(false);
            }}
          >
            Shift Info
          </button>
          <button
            style={{ marginRight: '10px' }}
            className={`boss-button boss-button_type_small boss-button_role_highlight rota-overview-chart__controls-button ${rotasInfo ? 'boss-button_state_active' : ''
            }`}
            onClick={() => {
              setRotasTime(false);
              setRotasInfo(true);
            }}
          >
            Staff Counts
          </button>
        </div>
      );
    }
    return null;
  }

  function getTickValues() {
    var tickValues = [];
    var startTime = props.rotaDate.startTime();
    for (var i = 0; i < 25; i++) {
      var date = new Date(startTime.valueOf() + i * MILLISECONDS_PER_HOURS);
      tickValues.push(date.valueOf());
      tickValues.push(date.valueOf() + MILLISECONDS_PER_HOURS * 0.5);
    }
    tickValues.pop(); // remove the last one at 8:30 that's duplicated
    return tickValues;
  }

  var datum = props.chartData;
  const tooltipGenerator = rotasInfo
    ? props.tooltipInfoGenerator
    : props.tooltipTimeGenerator;
  const noDataMessage = props.noData || 'There is no Data to display';
  var options = {
    margin: {},
    stacked: true,
    showControls: false,
    yAxis: {
      tickFormat: d3.format('d'),
    },
    xAxis: {
      tickValues: getTickValues(),
      tickFormat: function (xValue) {
        return new Date(xValue).getHours();
      },
      axisLabel: 'Time',
    },
    tooltip: {
      classes: 'rota-overview-chart-tooltip',
      contentGenerator: tooltipGenerator,
    },
    reduceXTicks: false,
    noData: noDataMessage,
  };

  return (
    <div className="rota-overview-chart">
      {highlightControls()}
      <ReactIScroll
        iScroll={iScroll}
        options={scrollOptions}
      >
        <NVD3Chart
          ref={svgRef}
          options={options}
          type="multiBarChart"
          datum={datum}
          x="label"
          y="value"
          margin={{}}
        />
      </ReactIScroll>
    </div>
  );
}
