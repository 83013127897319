import React from 'react';
import oFetch from 'o-fetch';

import ContentWrapper from '@/components/content-wrapper';

function AccessoryList(props) {
  function renderAccessories() {
    const [accessories, accessoryRequests, accessoryRefundRequests, staffMembers] = oFetch(
      props,
      'accessories',
      'accessoryRequests',
      'accessoryRefundRequests',
      'staffMembers',
    );
    const accessoriesRequestsExist = accessoryRequests.length > 0;
    const accessoriesRefundRequestsExist = accessoryRefundRequests.length > 0;

    if (!accessoriesRequestsExist && !accessoriesRefundRequestsExist) {
      return <h3>No Requests exist</h3>;
    }

    return accessories.map((accessory, index) => {
      const name = oFetch(accessory, 'name');
      const freeItems = oFetch(accessory, 'freeItems');
      const accessoryId = oFetch(accessory, 'id');
      const requests = accessoryRequests.filter(
        item => oFetch(item, 'accessoryId') === oFetch(accessory, 'id'),
      );
      const refundRequests = accessoryRefundRequests.filter(
        item => oFetch(item, 'accessoryId') === oFetch(accessory, 'id'),
      );
      const requestsCount = requests.length;
      const refundRequestsCount = refundRequests.length;

      const accessoryData = {
        name,
        requests,
        refundRequests,
        requestsCount,
        refundRequestsCount,
        staffMembers,
        accessory,
        freeItems,
        venue: props.venue,
      };

      if (requestsCount === 0 && refundRequestsCount === 0) {
        return null;
      }

      return React.cloneElement(props.accessoryItemRenderer(accessoryData), {
        key: accessoryId,
      });
    });
  }

  const showAccessoryList = !!props.accessories.length;
  return (
    <ContentWrapper>
      {renderAccessories()}
      {showAccessoryList && (
        <div className="boss-page-main__count boss-page-main__count_space_large">
          <span className="boss-page-main__count-text">Showing </span>
          <span className="boss-page-main__count-text boss-page-main__count-text_marked">
            {props.accessories.length}
          </span>
          <span className="boss-page-main__count-text"> of </span>
          <span className="boss-page-main__count-text boss-page-main__count-text_marked">
            {props.totalCount}
          </span>
        </div>
      )}
      {props.isShowLoadMore && (
        <div className="boss-page-main__actions boss-page-main__actions_position_last">
          <button
            onClick={props.onLoadMoreClick}
            className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
          >
            Load more
          </button>
        </div>
      )}
    </ContentWrapper>
  );
}

export default AccessoryList;
