import oFetch from 'o-fetch';
import React from 'react';

export function ReportsList(props) {
  const [reports, reportRender] = oFetch(props, 'reports', 'reportRender');

  function renderReports() {
    return reports.map(report => {
      const reportId = oFetch(report, 'id');

      return React.cloneElement(reportRender(report), { key: reportId });
    });
  }

  return (
    <div className="boss-table boss-table_page_error-reports">
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header">Created At</div>
        <div className="boss-table__cell boss-table__cell_role_header">Created By</div>
        <div className="boss-table__cell boss-table__cell_role_header">Context</div>
        <div className="boss-table__cell boss-table__cell_role_header">Report</div>
        <div className="boss-table__cell boss-table__cell_role_header">Rollbar Item Link</div>
        <div className="boss-table__cell boss-table__cell_role_header">Rollbar Occurrences Link</div>
      </div>

      {renderReports()}
    </div>
  );
}
