import { z } from 'zod';
import { $BossUiDate } from '@/lib/schema-funcs';

// must match backend type
const $MossVenueReportStatus = z.union([
  // nothing to show
  z.literal('na'),
  // too early to complete
  z.literal('pending'),
  // contains reports that have data or hours that has not been accepted, still clocked in etc
  z.literal('incomplete'),
  // contains at least one report requiring update or is in the process of being updated
  z.literal('requiring_update'),
  // all reports are up to date but some are not done
  z.literal('pending_completion'),
  // all reports are done but week is not completed
  z.literal('week_incomplete'),
  // all reports are done and week is completed
  z.literal('done'),
]);

export type MossVenueReportStatus = z.infer<typeof $MossVenueReportStatus>;

const $MossVenueReport = z.object({
  venueId: z.number().int().positive(),
  venueName: z.string().min(1),
  owedHourCents: z.number().int().nonnegative(),
  owedHourMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodCents: z.number().int().nonnegative(),
  isCalculating: z.boolean(),
  owedHoursCalculating: z.boolean(),
  hoursAcceptancePeriodsCalculating: z.boolean(),
  status: $MossVenueReportStatus,
});

export type MossVenueReport = z.infer<typeof $MossVenueReport>;

const $MossHourTagCompositeStatus = $MossVenueReportStatus;

export type MossHourTagCompositeStatus = z.infer<typeof $MossHourTagCompositeStatus>;

const $MossHourTag = z.object({
  id: z.number(),
  name: z.string(),
  totalCents: z.number().int().nonnegative(),
  owedHourCents: z.number().int().nonnegative(),
  owedHourMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodMinutes: z.number().int().nonnegative(),
  hoursAcceptancePeriodCents: z.number().int().nonnegative(),
  mossVenueReports: z.array($MossVenueReport),
  isDisabled: z.boolean(),
  disabledAt: z.union([$BossUiDate(z), z.null()]),
  disabledByUserName: z.union([z.string().min(1), z.null()]),
  disabledByUserId: z.union([z.number().int().positive(), z.null()]),
  owedHoursCalculating: z.boolean(),
  hoursAcceptancePeriodsCalculating: z.boolean(),
  isCalculating: z.boolean(),
  compositeStatus: $MossHourTagCompositeStatus,
}).strict();

export type MossHourTag = z.infer<typeof $MossHourTag>;

const $FilteredOutMossHourTag = z.object({
  id: z.number().int().positive(),
  name: z.string().min(1),
  isDisabled: z.boolean(),
}).strict();

export type FilteredOutMossHourTag = z.infer<typeof $FilteredOutMossHourTag>;

export type MossHourTagsSectionProps = {
  startDate: Date;
  mossHourTags: MossHourTag[];
  filteredOutMossHourTags: FilteredOutMossHourTag[];
  highlightedTagId: number | null;
  setHighlightedTagId: (tagId: number | null) => void;
};


export const $MossHourTagReportAppProps = z.object({
  accessToken: z.string(),
  startDate: $BossUiDate(z),
  endDate: $BossUiDate(z),
  mossHourTags: z.array($MossHourTag),
  filteredOutMossHourTags: z.array($FilteredOutMossHourTag),
  isAdminPlus: z.boolean(),
  permissions: z.object({
    showPDFDownloadLink: z.boolean(),
  }),
}).strict();

export type MossHourTagReportAppProps = z.infer<typeof $MossHourTagReportAppProps>;

export type PageProps = MossHourTagReportAppProps;

export type MossHourTagsReportHeaderProps = {
  startDate: Date;
  endDate: Date;
  mossHourTags: MossHourTag[];
};

export type MossHourTagInfoSectionProps = {
  startDate: Date;
  mossHourTagId: number | null;
  mossHourTags: MossHourTag[];
};