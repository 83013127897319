import React, { useRef } from 'react';
import oFetch from 'o-fetch';
import { SwipeTimePicker, END_DAY_TIME_TYPE, START_DAY_TIME_TYPE } from '@/components/time-picker';

FormTimeInterval.defaultProps = {
  isFromBreaks: false,
  globalError: false,
  granularityInMinutes: 1,
  className: '',
};

export function FormTimeInterval(props) {
  const startsAtControlRef = useRef(null);
  const endsAtControlRef = useRef(null);

  const { isFromBreaks, rotaDate, globalErrors } = props;

  let startsAt, endsAt;
  if (isFromBreaks) {
    startsAt = props.breaks[props.index].startsAt;
    endsAt = props.breaks[props.index].endsAt;
  } else {
    startsAt = oFetch(props, 'startsAt');
    endsAt = oFetch(props, 'endsAt');
  }

  const sStartsAt = startsAt.input.value;
  const sEndsAt = endsAt.input.value;
  const dStartsAt = new Date(sStartsAt);
  const dEndsAt = new Date(sEndsAt);

  const onStartsAtChange = (dValue) => {
    startsAt.input.onChange(dValue.toISOString());
  };
  const onEndsAtChange = (dValue) => {
    endsAt.input.onChange(dValue.toISOString());
  };

  function onStartsAtTabKeyDown() {
    startsAtControlRef?.current?.commitAndClosePopover();
    endsAtControlRef?.current?.openPopover();
  }

  function onEndsAtTabKeyDown() {
    endsAtControlRef?.current?.commitAndClosePopover();
  }

  return (
    <div className={`boss-time-shift__time`}>
      <div className="boss-time-shift__interval">
        <div className="boss-time-shift__hours">
          <p className="boss-time-shift__label">
            <span className="boss-time-shift__label-text">Start</span>
          </p>
          <div
            className={`boss-time-shift__select ${startsAt.meta.error ? 'boss-time-shift__select_state_error' : ''
            }`}
          >
            <SwipeTimePicker
              name="startsAt"
              controlRef={startsAtControlRef}
              value={dStartsAt}
              rotaDate={rotaDate}
              dayTimeType={START_DAY_TIME_TYPE}
              minutesStep={props.granularityInMinutes}
              onChange={onStartsAtChange}
              onTabKeyDown={onStartsAtTabKeyDown}
            />
          </div>
        </div>
        <div className="boss-time-shift__delimiter" />
        <div className="boss-time-shift__hours">
          <p className="boss-time-shift__label">
            <span className="boss-time-shift__label-text">End</span>
          </p>
          <div
            className={`boss-time-shift__select ${endsAt.meta.error ? 'boss-time-shift__select_state_error' : ''
            }`}
          >
            <SwipeTimePicker
              name="endsAt"
              controlRef={endsAtControlRef}
              value={dEndsAt}
              minutesStep={props.granularityInMinutes}
              rotaDate={rotaDate}
              dayTimeType={END_DAY_TIME_TYPE}
              onChange={onEndsAtChange}
              onTabKeyDown={onEndsAtTabKeyDown}
            />
          </div>
        </div>
      </div>
      {!globalErrors && (startsAt.meta.error || endsAt.meta.error) ? (
        <div
          className="boss-time-shift__error"
          style={{ marginTop: 10 }}
        >
          {startsAt.meta.error
            ? startsAt.meta.error.map((error, index) => (
              <p
                key={`startsAt-${index}`}
                className="boss-time-shift__error-text"
              >
                {`Start Time ${error}`}
              </p>
            ))
            : null}
          {endsAt.meta.error
            ? endsAt.meta.error.map((error, index) => (
              <p
                key={`endsAt-${index}`}
                className="boss-time-shift__error-text"
              >
                {`End time ${error}`}
              </p>
            ))
            : null}
        </div>
      ) : null}
    </div>
  );
}