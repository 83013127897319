import { useState } from "react";
import { $RequestValidationErrors, FormCallProps, FormValues, MAIN_QR_CODE_TYPE, NonStaffMemberStaffPartyPerson, OnSubmitParams, PLUS_1_QR_CODE_TYPE, QrCodeType, RequestValidationErrors } from "./types";
import { useModal } from "@/components/hooks-components/modals";
import { bossRequestHttp } from "@/lib/request-api";
import { CreateModalContent } from "./components/create-modal-content";
import { LegacyCloseInsideModalWrapper } from "@/components/modal-wrappers";
import utils from "@/lib/utils";
import { useGlobal } from "./globals";
import oFetch from "o-fetch";
import { EditModalContent } from "./components/edit-modal-content";
import { openCustomContentModal, openWarningModal } from '@/components/modals';
import { applyBossRoute } from "@/lib/apply-boss-route";
import {
  createNonStaffMemberStaffPartyPerson as importedCreateNonStaffMemberStaffPartyPerson,
  updateNonStaffMemberStaffPartyPerson as importedUpdateNonStaffMemberStaffPartyPerson,
  disableNonStaffMemberStaffPartyPerson as
    importDisableNonStaffMemberStaffPartyPerson,
  enableNonStaffMemberStaffPartyPerson as importEnableNonStaffMemberStaffPartyPerson,
  printNonStaffMemberStaffPartyPersonQrCode as importPrintNonStaffMemberStaffPartyPersonQrCode,
} from "@/lib/api-routes";
// creating this to make it easier to see these are routes
const apiRoutes = {
  createNonStaffMemberStaffPartyPerson: importedCreateNonStaffMemberStaffPartyPerson,
  updateNonStaffMemberStaffPartyPerson: importedUpdateNonStaffMemberStaffPartyPerson,
  disableNonStaffMemberStaffPartyPerson: importDisableNonStaffMemberStaffPartyPerson,
  enableNonStaffMemberStaffPartyPerson: importEnableNonStaffMemberStaffPartyPerson,
  printNonStaffMemberStaffPartyPersonQrCode: importPrintNonStaffMemberStaffPartyPersonQrCode,
} as const;
import QRCodeModalContent from "./qr-code-modal-content";

type UseNonStaffMemberStaffPartyPeopleParams = {
  nonStaffMemberStaffPartyPeople: NonStaffMemberStaffPartyPerson[],
  nonStaffMemberStaffPartyPeopleTotal: number,
};

export function useNonStaffMemberStaffPartyPeople(params: UseNonStaffMemberStaffPartyPeopleParams) {
  const [nonStaffMemberStaffPartyPeople, setNonStaffMemberStaffPartyPeople] = useState(params.nonStaffMemberStaffPartyPeople);
  const [nonStaffMemberStaffPartyPeopleTotal, setNonStaffMemberStaffPartyPeopleTotal] = useState(params.nonStaffMemberStaffPartyPeopleTotal);

  const { openModal } = useModal();
  const { jobOptions, staffPartyQuizTeamOptions, guestStaffPartyQuizTeamId } = useGlobal();

  function addNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    setNonStaffMemberStaffPartyPeopleTotal(nonStaffMemberStaffPartyPeopleTotal + 1);
    setNonStaffMemberStaffPartyPeople([...nonStaffMemberStaffPartyPeople, nonStaffMemberStaffPartyPerson]);
  }

  function getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    return (previousState: NonStaffMemberStaffPartyPerson[]) => {
      return previousState.map((innerNonStaffMemberStaffPartyPerson) => {
        if (innerNonStaffMemberStaffPartyPerson.id === nonStaffMemberStaffPartyPerson.id) {
          return nonStaffMemberStaffPartyPerson;
        } else {
          return innerNonStaffMemberStaffPartyPerson;
        }
      });
    };
  }

  function updateNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
    setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
  }

  function deleteNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, filteringByEnabled: boolean) {
    if (filteringByEnabled) {
      // Delete staff member from state and Remove from count
      //////////////////////////////////
      setNonStaffMemberStaffPartyPeopleTotal(nonStaffMemberStaffPartyPeopleTotal - 1);

      setNonStaffMemberStaffPartyPeople((previousState: NonStaffMemberStaffPartyPerson[]) => {
        return previousState.reduce<NonStaffMemberStaffPartyPerson[]>((acc, innerNonStaffMemberStaffPartyPerson) => {
          if (innerNonStaffMemberStaffPartyPerson.id !== nonStaffMemberStaffPartyPerson.id) {
            acc.push(innerNonStaffMemberStaffPartyPerson);
          }
          return acc;
        }, []);
      });
    } else {
      // Disabled records are being displayed so just update In Place
      ////////////////////////////////////////////////////////
      const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
      setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
    }
  }

  function enableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    const updateNonStaffMemberStaffPartyPersonInPlace = getUpdateNonStaffMemberStaffPartyPersonInPlace(nonStaffMemberStaffPartyPerson);
    setNonStaffMemberStaffPartyPeople(updateNonStaffMemberStaffPartyPersonInPlace);
  }

  function openCreateModal() {
    return openModal({
      onSubmit: (
        closeModal: () => void,
        onSumbitParams: OnSubmitParams
      ) => {
        const route = apiRoutes.createNonStaffMemberStaffPartyPerson;
        const bossRequestInstance = bossRequestHttp({
          errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
            const globalNotifications = params.globalNotifications;
            globalNotifications.showDefaultFailureMessage();

            if (params.statusCode === 422) {
              return utils.normalizeFinalFormErrors<RequestValidationErrors>($RequestValidationErrors.parse(params.errors));
            }
            return false;
          },
          successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
            const globalNotifications = oFetch(params, 'globalNotifications');
            const rawData = oFetch(params, 'data');
            const data = route.$SuccessData.parse(rawData);
            const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

            addNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson);

            globalNotifications.showDefaultSuccessMessage();
            closeModal();
          },
        });

        return applyBossRoute({
          route,
          bossHttpRequest: bossRequestInstance,
          callParams: onSumbitParams.values,
          pathParams: {},
        });
      },
      config: {
        title: 'Add Party Person',
      },
      props: {
        jobOptions,
        staffPartyQuizTeamOptions,
        guestStaffPartyQuizTeamId,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: CreateModalContent,
    });
  }

  function getOpenUpdateModal(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    const initialValues: FormValues = {
      avatarUrl: nonStaffMemberStaffPartyPerson.avatarUrl || "",
      firstName: nonStaffMemberStaffPartyPerson.firstName || "",
      surname: nonStaffMemberStaffPartyPerson.surname || "",
      phoneNumber: nonStaffMemberStaffPartyPerson.phoneNumber || "",
      email: nonStaffMemberStaffPartyPerson.email || "",
      jobTitle: nonStaffMemberStaffPartyPerson.jobTitleValue,
      otherJobTitle: nonStaffMemberStaffPartyPerson.otherJobTitle || '',
      staffPartyQuizTeamId: nonStaffMemberStaffPartyPerson.staffPartyQuizTeamId,
    };
    const updateFormProps: FormCallProps = { initialValues };

    return () => {
      return openModal({
        onSubmit: (
          closeModal: () => void,
          onSumbitParams: OnSubmitParams
        ) => {
          const route = apiRoutes.updateNonStaffMemberStaffPartyPerson;
          const bossRequestInstance = bossRequestHttp({
            errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
              const globalNotifications = params.globalNotifications;
              globalNotifications.showDefaultFailureMessage();

              if (params.statusCode === 422) {
                return utils.normalizeFinalFormErrors<RequestValidationErrors>($RequestValidationErrors.parse(params.errors));
              }
              return false;
            },
            successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
              const globalNotifications = params.globalNotifications;

              const rawData = oFetch(params, 'data');
              const data = route.$SuccessData.parse(rawData);
              const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

              updateNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson);

              globalNotifications.showDefaultSuccessMessage();
              closeModal();
            },
          });

          return applyBossRoute({
            route,
            bossHttpRequest: bossRequestInstance,
            callParams: onSumbitParams.values,
            pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
          });
        },
        config: {
          title: 'Edit Party Person',
        },
        props: updateFormProps,
        ModalComponent: LegacyCloseInsideModalWrapper,
        ModalContent: EditModalContent,
      });
    };
  }

  function getOpenShowQRCodeModal(params: { nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, qrCodeType: QrCodeType }) {
    const { nonStaffMemberStaffPartyPerson, qrCodeType } = params;
    const [guid, title] = (() => {
      let guid = '';
      let title = '';
      if (qrCodeType === MAIN_QR_CODE_TYPE) {
        guid = nonStaffMemberStaffPartyPerson.guid;
        title = 'Party Person QR Code';
      } else if (qrCodeType === PLUS_1_QR_CODE_TYPE) {
        guid = nonStaffMemberStaffPartyPerson.plus1Guid;
        title = 'Plus 1 QR Code';
      } else {
        throw new Error(`Unknown QR Code Type supplied: ${qrCodeType}`);
      }

      return [guid, title];
    })();

    const onPrintGuid = () => {
      const route = apiRoutes.printNonStaffMemberStaffPartyPersonQrCode;
      const bossRequestInstance = bossRequestHttp({
        errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
          const { globalNotifications } = params;
          globalNotifications.showDefaultFailureMessage();
          return false;
        },
        successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
          const rawData = oFetch(params, 'data');
          const data = route.$SuccessData.parse(rawData);
          const { image, filename } = data;

          const base64Url = image;
          const a = document.createElement('a');
          a.href = base64Url;
          a.download = filename;

          // Click handler that releases the object URL after the element has been clicked
          // This is required for one-off downloads of the blob content
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(base64Url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };

          // Add the click event listener on the anchor element
          a.addEventListener('click', clickHandler, false);

          a.click();
        },
      });

      return applyBossRoute({
        route,
        bossHttpRequest: bossRequestInstance,
        callParams: {},
        pathParams: {
          nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id,
          qrCodeType
        },
      });
    };

    return () => {
      return openCustomContentModal({
        submit: () => { },
        config: {
          modalClass: 'boss-modal-window boss-modal-window_role_action',
          title: title,
          innerClose: false,
        },
        props: {
          guid: guid,
          onPrintGuid,
        },
      })(QRCodeModalContent);
    };
  }

  function getOpenDeleteModal(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson, filteringByEnabled: boolean) {
    return () => {
      function handleDisable(closeModal: () => void) {
        const route = apiRoutes.disableNonStaffMemberStaffPartyPerson;
        const bossRequestInstance = bossRequestHttp({
          errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
            const globalNotifications = params.globalNotifications;
            globalNotifications.showDefaultFailureMessage();

            return false;
          },
          successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
            const rawData = oFetch(params, 'data');
            const data = route.$SuccessData.parse(rawData);
            const globalNotifications = params.globalNotifications;
            const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

            deleteNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson, filteringByEnabled);

            globalNotifications.showDefaultSuccessMessage();
            closeModal();
          },
        });

        return applyBossRoute({
          route,
          bossHttpRequest: bossRequestInstance,
          callParams: {},
          pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
        });
      }

      openWarningModal({
        submit: handleDisable,
        config: {
          title: 'WARNING !!!',
          text: 'Are You Sure?',
          buttonText: 'Disable',
        },
        props: { nonStaffMemberStaffPartyPerson },
      });
    };
  }

  function getEnableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson: NonStaffMemberStaffPartyPerson) {
    return () => {
      const route = apiRoutes.enableNonStaffMemberStaffPartyPerson;
      const bossRequestInstance = bossRequestHttp({
        errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
          const globalNotifications = params.globalNotifications;
          globalNotifications.showDefaultFailureMessage();

          return false;
        },
        successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
          const rawData = oFetch(params, 'data');
          const globalNotifications = params.globalNotifications;

          const data = route.$SuccessData.parse(rawData);
          const nonStaffMemberStaffPartyPerson = data.nonStaffMemberStaffPartyPerson;

          enableNonStaffMemberStaffPartyPerson(nonStaffMemberStaffPartyPerson);

          globalNotifications.showDefaultSuccessMessage();
        },
      });

      return applyBossRoute({
        bossHttpRequest: bossRequestInstance,
        route,
        callParams: {},
        pathParams: { nonStaffMemberStaffPartyPersonId: nonStaffMemberStaffPartyPerson.id },
      });
    };
  }

  return {
    nonStaffMemberStaffPartyPeople,
    nonStaffMemberStaffPartyPeopleTotal,
    openCreateModal,
    getOpenUpdateModal,
    getOpenDeleteModal,
    getEnableNonStaffMemberStaffPartyPerson,
    getOpenShowQRCodeModal,
  };
}
