import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const editChecklistIdSelector = state => oFetch(state, 'editChecklistId');
export const checklistsSelector = state => oFetch(state, 'checklists');
export const checklistsItemsSelector = state => oFetch(state, 'checklistsItems');
export const getChecklists = createSelector(
  [checklistsSelector, checklistsItemsSelector],
  (checklists, checklistsItems) => {
    return checklists.map(checklist => {
      const checklistId = oFetch(checklist, 'id');
      const checklistItems = checklistsItems.
        filter(
          checklistsItem => oFetch(checklistsItem, 'checklistId') === checklistId,
        ).sort((itemA, itemB) => {
          const sortIndexA = oFetch(itemA, 'id');
          const sortIndexB = oFetch(itemB, 'id');
          if (sortIndexA < sortIndexB) return -1;
          if (sortIndexA > sortIndexB) return 1;
          return 0;
        });

      return {
        ...checklist,
        items: checklistItems,
      };
    });
  },
);
export const currentVenueSelector = state => oFetch(state, 'currentVenue');
