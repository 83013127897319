import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

import * as actions from './redux/actions';
import * as selectors from './redux/selectors';

import ContentWrapper from '@/components/content-wrapper';
import { DashboardWrapper, HealthCheckList } from './components';

function VenueHealthCheck(props) {
  const [
    questionnaireResponses,
    totalCount,
    loadMoreVisible,
    newResponsePath,
    manageQuestionnairePath,
    canManageQuestionnaire,
    canCreateQuestionnaireResponse,
  ] = oFetch(
    props,
    'questionnaireResponses',
    'totalCount',
    'loadMoreVisible',
    'newResponsePath',
    'manageQuestionnairePath',
    'canManageQuestionnaire',
    'canCreateQuestionnaireResponse'
  );
  const loadMore = oFetch(props, 'loadMore');

  const questionnaireResponsesCount = questionnaireResponses.length;
  const questionnaireResponsesExist = questionnaireResponsesCount !== 0;

  return (
    <div>
      <DashboardWrapper title="Venue Health Checks">
        { canCreateQuestionnaireResponse && <a href={newResponsePath} className="boss-button boss-button_role_add boss-page-dashboard__button">
          New Response
        </a>
        }
        { canManageQuestionnaire && <a href={manageQuestionnairePath} className="boss-button boss-button_role_edit-alt boss-page-dashboard__button">
          Edit Questionnaire
        </a> }
      </DashboardWrapper>
      <ContentWrapper>
        <HealthCheckList list={questionnaireResponses} />
        <div className="boss-page-main__count boss-page-main__count_space_large">
          <span className="boss-page-main__count-text">Showing </span>
          <span className="boss-page-main__count-text boss-page-main__count-text_marked">
            {questionnaireResponsesCount}
          </span>
          <span className="boss-page-main__count-text"> of </span>
          <span className="boss-page-main__count-text boss-page-main__count-text_marked">{totalCount}</span>
        </div>
        {loadMoreVisible && (
          <div className="boss-page-main__actions boss-page-main__actions_position_last">
            <AsyncButton
              className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile"
              text="Load More"
              pendingText="Loading More ..."
              onClick={loadMore}
            />
          </div>
        )}
      </ContentWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    questionnaireResponses: selectors.getQuestionnaireResponses(state),
    totalCount: selectors.getTotalCount(state),
    loadMoreVisible: selectors.loadMoreVisible(state),
    newResponsePath: selectors.getNewResponsePath(state),
    manageQuestionnairePath: selectors.getManageQuestionnairePath(state),
    canCreateQuestionnaireResponse: selectors.getCanCreateQuestionnaireRepsonse(state),
    canManageQuestionnaire: selectors.getCanManageQuestionnaire(state),
  };
};
const mapDispatchToProps = {
  loadMore: actions.loadMore,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueHealthCheck);
