import React from 'react';
import oFetch from 'o-fetch';
import { QRCode } from 'react-qr-svg';

export default function QRCodeModalContent(props) {
  const checklistId = oFetch(props, 'checklistId');
  const checklistName = oFetch(props, 'checklistName');
  const guid = oFetch(props, 'guid');
  const onPrintGuid = oFetch(props, 'onPrintGuid');

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block">
        <QRCode value={guid} />
      </div>

      <div className="boss-image-recognition__actions">
        <button type="button" className="boss-button boss-button_role_print boss-image-recognition__button" onClick={() => {onPrintGuid({ checklistId, checklistName})} }>
          Print QR Code
        </button>
      </div>
    </div>
  );
}
