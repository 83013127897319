import React, { useEffect } from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { IndexPage } from './components/index-page';
import { $StaffPartyPeopleAppProps, StaffPartyPeopleAppProps } from './types';
import { GlobalProvider } from './globals';

export function StaffPartyPeopleApp(props: unknown) {
  const appProps: StaffPartyPeopleAppProps = $StaffPartyPeopleAppProps.parse(props);

  useEffect(() => {
    window.boss.accessToken = appProps.pageData.accessToken;
  }, []);

  return (
    <>
      <GlobalProvider global={{
        filterTypeOptions: appProps.pageData.filterTypeOptions,
        jobOptions: appProps.pageData.jobOptions,
        staffPartyQuizTeamOptions: appProps.pageData.staffPartyQuizTeamOptions,
        guestStaffPartyQuizTeamId: appProps.pageData.guestStaffPartyQuizTeamId,
      }} >
        <ModalsProvider>
          <IndexPage
            nonStaffMemberStaffPartyPeople={appProps.nonStaffMemberStaffPartyPeople}
            pagination={appProps.pageData.pagination}
            filter={appProps.pageData.filter}
          />
        </ModalsProvider>
      </GlobalProvider>
    </>
  );
}