import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function Row(props) {
  const children = oFetch(props, 'children');
  const { className } = props;
  const classNames = cn('boss-table__row', { [className]: !!className });
  return <div className={classNames}>{children}</div>;
}

export function BasicCell(props) {
  return (
    <div className="boss-table__cell">
       {props.children}
    </div>
  );
}

function InfoCell(props) {
  const labelText = oFetch(props, "labelText");

  return (
    <BasicCell>
      <div className="boss-table__info">
        <div className="boss-table__label">{labelText}</div>
        {props.children}
      </div>
    </BasicCell>
  );
}

export function TextInfoCell(props) {
  const labelText = oFetch(props, "labelText");
  const text = oFetch(props, "text");

  return (
    <InfoCell labelText={labelText}>
      <div className="boss-table__text">{text}</div>
    </InfoCell>
  );
}

export function TextCell(props) {
  const text = oFetch(props, "text");

  return (
    <BasicCell>
      <div className="boss-table__text">{text}</div>
    </BasicCell>
  );
}

export function HeaderCell(props) {
  return <div className="boss-table__cell boss-table__cell_role_header">{props.children}</div>;
}

export function Table(props) {
  const children = oFetch(props, 'children');
  const classNames = cn('boss-table', { [props.className]: !!props.className });
  return <div className={classNames}>{children}</div>;
}

export function ComparisonCell(props) {
  const [value1, value2] = oFetch(props, 'value1', 'value2');
  const valueMismatch = value1 !== value2;
  const textDivClassNames = cn('boss-table__text', { 'boss-table__text_state_alert': valueMismatch });

  return (
    <BasicCell>
      <div className={textDivClassNames}>
        {value1} / {value2}
      </div>
    </BasicCell>
  );
}

export function ComparisonInfoCell(props) {
  const labelText = oFetch(props, 'labelText');
  const [value1, value2] = oFetch(props, 'value1', 'value2');
  const valueMismatch = value1 !== value2;
  const textDivClassNames = cn('boss-table__text', { 'boss-table__text_state_alert': valueMismatch });

  return (
    <InfoCell labelText={labelText}>
      <div className={textDivClassNames}>
        {value1} / {value2}
      </div>
    </InfoCell>
  );
}
