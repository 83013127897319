import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { Dashboard, AddStaffMemberWizard, Steps, Step, FlaggedModal } from './components';
import { Review, ReviewList, ReviewItem, ReviewNotification } from './components/review';
import * as actions from './redux/actions';

import { STEPS } from './constants';
import * as sel from './redux/selectors';

function AddStaffMember(props) {
  const handleChangeStep = oFetch(props, 'handleChangeStep');
  const currentStep = oFetch(props, 'currentStep');
  const [isFlaggedModalOpen, setIsFlaggedModalOpen] = useState(false);
  const unReviewedFlaggedStaffMembers = oFetch(props, 'unReviewedFlaggedStaffMembers');

  function openFlaggedModal() {
    setIsFlaggedModalOpen(true);
  }

  function closeFlaggedModal() {
    setIsFlaggedModalOpen(false);
  }

  useEffect(() => {
    if (unReviewedFlaggedStaffMembers.length === 0) {
      closeFlaggedModal();
    }
  }, [unReviewedFlaggedStaffMembers]);

  const [
    genderOptions,
    payRateOptions,
    forStandardSecurityStaffSecurityPayRatesOptions,
    forStewardSecurityStaffSecurityPayRatesOptions,
    accessibleVenueOptions,
    venueOptions,
    staffTypeOptions,
    employmentStatusesOptions,
    isSecurity,
    isSteward,
    reviewedFlaggedStaffMembers,
    handleReviewFlaggedStaffMember,
    handleUnReviewFlaggedStaffMember,
    unReviewedFlaggedStaffMembersCount,
    reviewedFlaggedStaffMembersCount,
    stepsValidation,
    handleCreateStaffMember,
    submitErrors,
    staffMemberGeneralFieldsValidation,
    securityStaffTypesIds,
  ] = oFetch(
    props,
    'genderOptions',
    'payRateOptions',
    'forStandardSecurityStaffSecurityPayRatesOptions',
    'forStewardSecurityStaffSecurityPayRatesOptions',
    'accessibleVenueOptions',
    'venueOptions',
    'staffTypeOptions',
    'employmentStatusesOptions',
    'isSecurity',
    'isSteward',
    'reviewedFlaggedStaffMembers',
    'handleReviewFlaggedStaffMember',
    'handleUnReviewFlaggedStaffMember',
    'unReviewedFlaggedStaffMembersCount',
    'reviewedFlaggedStaffMembersCount',
    'stepsValidation',
    'handleCreateStaffMember',
    'submitErrors',
    'staffMemberGeneralFieldsValidation',
    'securityStaffTypesIds',
  );

  function selectPage(page) {
    if (currentStep !== 1) {
      handleChangeStep(page);
    } else {
      if (page !== currentStep) {
        window.alert("You can't go directly to this step, please use continue button");
      }
    }
  }

  function nextPage() {
    const nextStep = currentStep + 1;
    if (currentStep === 1) {
      handleChangeStep(nextStep);
    } else {
      selectPage(nextStep);
    }
  }

  function previousPage() {
    const previousStep = currentStep - 1;
    selectPage(previousStep);
  }

  function handleStaffMemberGeneralFieldsValidation(params) {
    const [values, siaBadgeNumberFailure] = oFetch(params, 'values', 'siaBadgeNumberFailure');
    return staffMemberGeneralFieldsValidation({
      values,
      onSuccess() {
        nextPage();
      },
      onFailure(hasUnreviewedStaffMembers) {
        if (hasUnreviewedStaffMembers) {
          openFlaggedModal();
        }
      },
      siaBadgeNumberFailure,
    });
  }
  return (
    <Fragment>
      <FlaggedModal
        notReviewedStaffMembers={unReviewedFlaggedStaffMembers}
        reviewedStaffMembers={reviewedFlaggedStaffMembers}
        onReview={handleReviewFlaggedStaffMember}
        onUndo={handleUnReviewFlaggedStaffMember}
        isOpened={isFlaggedModalOpen}
        onClose={() => {
          Object.keys(submitErrors).length === 0 && nextPage();
        }}
      />
      <Dashboard title="Add Staff Member">
        <Steps
          steps={STEPS}
          stepRenderer={() => <Step currentPage={currentStep} onSelectPage={selectPage} />}
          currentPage={currentStep}
          stepsValidation={stepsValidation}
          submitErrors={submitErrors}
        />
      </Dashboard>
      <ContentWrapper>
        <AddStaffMemberWizard
          options={{
            securityStaffTypesIds,
            genderOptions,
            payRateOptions,
            forStandardSecurityStaffSecurityPayRatesOptions,
            forStewardSecurityStaffSecurityPayRatesOptions,
            accessibleVenueOptions,
            venueOptions,
            staffTypeOptions,
            employmentStatusesOptions,
            isSecurity,
            isSteward,
          }}
          onNextPage={nextPage}
          onPreviousPage={previousPage}
          currentPage={currentStep}
          staffMemberGeneralFieldsValidation={handleStaffMemberGeneralFieldsValidation}
          onSubmit={handleCreateStaffMember}
        />
        {unReviewedFlaggedStaffMembersCount > 0 && (
          <ReviewNotification count={unReviewedFlaggedStaffMembersCount} />
        )}
        <Review canClose={unReviewedFlaggedStaffMembersCount === 0}>
          {unReviewedFlaggedStaffMembersCount > 0 && (
            <ReviewList
              className="boss-vetting__review"
              staffMembers={unReviewedFlaggedStaffMembers}
              itemRenderer={staffMember => (
                <ReviewItem onButtonClick={handleReviewFlaggedStaffMember} staffMember={staffMember} />
              )}
            />
          )}
          {reviewedFlaggedStaffMembersCount > 0 && (
            <ReviewList
              className="boss-vetting__dismiss"
              staffMembers={reviewedFlaggedStaffMembers}
              itemRenderer={staffMember => (
                <ReviewItem onButtonClick={handleUnReviewFlaggedStaffMember} staffMember={staffMember} />
              )}
            />
          )}
        </Review>
      </ContentWrapper>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    genderOptions: sel.getGenderOptions(state),
    payRateOptions: sel.payRatesOptionsSelector(state),
    forStandardSecurityStaffSecurityPayRatesOptions: sel.forStandardSecurityStaffSecurityPayRatesOptionsSelector(
      state,
    ),
    forStewardSecurityStaffSecurityPayRatesOptions: sel.forStewardSecurityStaffSecurityPayRatesOptionsSelector(
      state,
    ),
    venueOptions: sel.venueOptionsSelector(state),
    accessibleVenueOptions: sel.accessibleVenueOptionsSelector(state),
    staffTypeOptions: sel.getStaffTypeOptions(state),
    isSecurity: sel.isSecuritySelector(state),
    isSteward: sel.isStewardSelector(state),
    employmentStatusesOptions: sel.employmentStatusesOptionsSelector(state),
    unReviewedFlaggedStaffMembers: sel.unReviewedFlaggedStaffMembers(state),
    reviewedFlaggedStaffMembers: sel.reviewedFlaggedStaffMembers(state),
    unReviewedFlaggedStaffMembersCount: sel.unReviewedFlaggedStaffMembersCount(state),
    reviewedFlaggedStaffMembersCount: sel.reviewedFlaggedStaffMembersCount(state),
    currentStep: sel.currentStepSelector(state),
    stepsValidation: sel.stepsValidationSelector(state),
    submitErrors: sel.formSubmitErrorsSelector(state),
    securityStaffTypesIds: sel.getSecurityStaffTypesIds(state),
  };
};

const mapDispatchToProps = {
  handleReviewFlaggedStaffMember: actions.reviewFlaggedStaffMember,
  handleUnReviewFlaggedStaffMember: actions.unReviewFlaggedStaffMember,
  handleChangeStep: actions.changeStep,
  handleCreateStaffMember: actions.createStaffMember,
  staffMemberGeneralFieldsValidation: actions.staffMemberGeneralFieldsValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStaffMember);
