import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';

export function TaskList(props) {
  const [tasks, renderTask] = oFetch(props, 'tasks', 'renderTask');
  const tasksExist = tasks.length > 0;

  const sortedTasks = useMemo(() => {
    return [...tasks].sort(
      (a, b) => safeMoment.uiDateParse(a.deadlineDate) - safeMoment.uiDateParse(b.deadlineDate),
    );
  }, [tasks]);

  function renderTasks() {
    if (!tasksExist) {
      return <span className="boss-overview__text-large boss-overview__text-marked">Not found</span>;
    }
    return sortedTasks.map((task, index) => {
      const taskId = oFetch(task, 'id');
      return React.cloneElement(renderTask(task), {
        key: taskId,
      });
    });
  }

  return <div className="boss-tasks__cards">{renderTasks()}</div>;
}
