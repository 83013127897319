import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {};

export const mossFinanceReportsOverviewDataReducer = handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const calculatedTotalGrossCents = oFetch(payload, 'calculatedTotalGrossCents');

      return {
        calculatedTotalGrossCents: calculatedTotalGrossCents,
      };
    },
  },
  initialState,
);
