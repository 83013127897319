import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormCheckbox, BossFormTextArea } from '@/components/boss-form';

export function DisableUserForm(props) {
  const [onSubmit, initialValues] = oFetch(props, 'onSubmit', 'initialValues');

  function renderError(error) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          <p className="boss-alert__text">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field
                  name="notRehire"
                  type="checkbox"
                  parse={value => value}
                  label="Do not rehire this person (Give reason below)"
                  component={BossFormCheckbox}
                />
                <Field
                  name="description"
                  parse={value => value}
                  label="Description"
                  required
                  component={BossFormTextArea}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-button_role_block boss-form__submit"
                    type="submit"
                    disabled={submitting}
                  >
                    Disable
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
