import React from 'react';
import oFetch from 'o-fetch';
import { reduxForm, Field } from 'redux-form';
import { BossFormSelectNew, BossFormInput } from '@/components/boss-form';
import { ColoredSingleOption, ColoredSingleValue } from '@/components/boss-form/colored-select';

function ManageCategoriesForm(props) {
  const [submitting, categoryListRender, handleSubmit, colorsOptions] = oFetch(
    props,
    'submitting',
    'categoryListRender',
    'handleSubmit',
    'colorsOptions',
  );

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__form">
        <div className="boss-form">
          {categoryListRender(submitting)}
          <div className="boss-form__group boss-form__group_role_board">
            <div className="boss-form__row">
              <Field
                name="name"
                label="Category name"
                component={BossFormInput}
                fieldClassName="boss-form__field boss-form__field_layout_max"
              />
              <Field
                name="color"
                label="Category Badge Color"
                options={colorsOptions}
                optionComponent={ColoredSingleOption}
                valueComponent={ColoredSingleValue}
                component={BossFormSelectNew}
                fieldClassName="boss-form__field boss-form__field_layout_quarter"
              />
              <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label">
                <button
                  disabled={submitting}
                  onClick={handleSubmit}
                  type="button"
                  className="boss-button boss-button_role_add boss-button_type_icon boss-form__submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm()(ManageCategoriesForm);
