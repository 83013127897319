import React from 'react';
import oFetch from 'o-fetch';

export default function ApiKeyList(props) {
  const [apiKeys, itemRenderer] = oFetch(props, 'apiKeys', 'itemRenderer');

  function renderHeader() {
    return (
      <div className="boss-table__row boss-table__row_role_header">
        <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
        <div className="boss-table__cell boss-table__cell_role_header">Key</div>
        <div className="boss-table__cell boss-table__cell_role_header" />
      </div>
    );
  }

  function renderList() {
    return apiKeys.map((apiKey) => {
      const venueId = oFetch(apiKey, 'id');
      return React.cloneElement(itemRenderer(apiKey), {
        key: venueId,
      });
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_api-keys-table">
      <div className="boss-table boss-table_page_api-keys">
        {renderHeader()}
        {renderList()}
      </div>
    </div>
  );
}
