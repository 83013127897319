import { getIn } from 'final-form';

export const imageChangedDecorator = form => {
  let previousImage;
  return form.subscribe(
    ({ values }) => {
      const nextImage = values && getIn(values, 'image');
      if (previousImage !== undefined) {
        if (previousImage !== nextImage) {
          form.change('imageHasChanged', true);
        }
      }
      previousImage = nextImage;
    },
    { values: true },
  );
};
