import React from 'react';
import oFetch from 'o-fetch';
import { connect } from 'react-redux';
import AreaItem from './area-item';
import SortableCategoryList from './sortable-category-list';
import { ManageAreasForm } from './forms';
import * as selectors from '../redux/selectors';
import * as actions from '../redux/actions';

function renderArea(params) {
  const [area, submitting, deleteArea] = oFetch(params, 'area', 'submitting', 'deleteArea');
  return <AreaItem area={area} onDelete={deleteArea} submitting={submitting} />;
}

function ManageAreas(props) {
  const [questionnaireAreas, onSubmit, deleteArea] = oFetch(
    props,
    'questionnaireAreas',
    'onSubmit',
    'deleteArea',
  );

  function renderAreaList(submitting) {
    return (
      <SortableCategoryList
        onChange={() => {}}
        items={questionnaireAreas}
        itemRender={area => renderArea({ area, submitting, deleteArea })}
      />
    );
  }

  const initialValues = {
    name: null,
  };

  return (
    <ManageAreasForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      categoryListRender={renderAreaList}
      form="manage-areas-form"
    />
  );
}

const mapStateToProps = state => {
  return {
    questionnaireAreas: selectors.getActiveQuestionnaireAreas(state),
  };
};

const mapDispatchToProps = {
  deleteArea: actions.deleteArea,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAreas);
