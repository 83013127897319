import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { fromJS } from 'immutable';

import {
  INITIAL_LOAD,
  EDIT_PROFILE,
  CANCEL_EDIT_PROFILE,
  ENABLE_PROFILE,
  CANCEL_ENABLE_PROFILE,
  SHOW_DISABLE_MODAL,
  HIDE_DISABLE_MODAL,
  SHOW_EDIT_AVATAR_MODAL,
  HIDE_EDIT_AVATAR_MODAL,
  UPDATE_MOSS_STAFF_MEMBER,
} from './constants';

const initialState = fromJS({
  mossStaffMember: {},
  accessToken: null,
  editProfile: false,
  enableProfile: false,
  mossStaffTypes: [],
  mossPayRates: [],
  venues: [],
  disableMossStaffMemberModal: false,
  editAvatarModal: false,
  validateAvatarForCreateUrl: null,
  mossHourTags: [],
  permissionsData: {
    canEnable: false,
    canMarkRetakeAvatar: false,
  },
});

const profileReducer = handleActions(
  {
    [INITIAL_LOAD]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const [
        mossStaffMember,
        accessToken,
        mossStaffTypes,
        mossPayRates,
        venues,
        accessibleVenueIds,
        validateAvatarForCreateUrl,
      ] = oFetch(
        payload,
        'mossStaffMember',
        'accessToken',
        'mossStaffTypes',
        'mossPayRates',
        'venues',
        'accessibleVenueIds',
        'validateAvatarForCreateUrl',
      );
      const permissionsData = oFetch(payload, 'permissionsData');

      return state
        .set('mossStaffMember', fromJS(mossStaffMember))
        .set('accessToken', accessToken)
        .set('mossStaffTypes', fromJS(mossStaffTypes))
        .set('mossPayRates', fromJS(mossPayRates))
        .set('venues', fromJS(venues))
        .set('validateAvatarForCreateUrl', validateAvatarForCreateUrl)
        .set(
          'accessibleVenues',
          fromJS(
            accessibleVenueIds.map(id => {
              return venues.find(venue => {
                return oFetch(venue, 'id') === id;
              });
            }),
          ),
        )
        .set('permissionsData', fromJS(permissionsData));
    },
    [UPDATE_MOSS_STAFF_MEMBER]: (state, action) => {
      const mossStaffMember = oFetch(action, 'payload');
      return state.set('mossStaffMember', mossStaffMember);
    },
    [EDIT_PROFILE]: state => {
      return state.set('editProfile', true);
    },
    [CANCEL_EDIT_PROFILE]: state => {
      return state.set('editProfile', false);
    },
    [ENABLE_PROFILE]: state => {
      return state.set('enableProfile', true);
    },
    [CANCEL_ENABLE_PROFILE]: state => {
      return state.set('enableProfile', false);
    },
    [SHOW_DISABLE_MODAL]: state => {
      return state.set('disableMossStaffMemberModal', true);
    },
    [HIDE_DISABLE_MODAL]: state => {
      return state.set('disableMossStaffMemberModal', false);
    },
    [SHOW_EDIT_AVATAR_MODAL]: state => {
      return state.set('editAvatarModal', true);
    },
    [HIDE_EDIT_AVATAR_MODAL]: state => {
      return state.set('editAvatarModal', false);
    },
  },
  initialState,
);

export default profileReducer;