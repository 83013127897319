import { useContext } from 'react';
import { getGlobalProviders } from '@/components/hooks-components/global';
import { StaffPartyPeopleAppGlobals } from './types';

const { GlobalProvider: GGlobalProvider, GlobalContext } = getGlobalProviders<StaffPartyPeopleAppGlobals>();

export const GlobalProvider = GGlobalProvider;

export function useGlobal(): StaffPartyPeopleAppGlobals {
  const context = useContext<StaffPartyPeopleAppGlobals | null>(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used inside the GlobalProvider');
  }
  return context;
}