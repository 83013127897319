import React from 'react';
import cn from 'classnames';

const ContentWrapper = ({ children, className = '' }) => {
  const innerClassName = cn('boss-page-main__inner', { [className]: !!className });

  return (
    <div className="boss-page-main__content">
      <div className={innerClassName}>{children}</div>
    </div>
  );
};

export default ContentWrapper;
