import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { BonusPayments } from './bonus-payments';
export { BonusPaymentsHeader } from './components';

export function BonusPaymentsApp(props) {
  useEffect(() => {
    window.boss.accessToken = oFetch(props, 'accessToken');
    require('./styles.css');
  }, []);

  return (
    <ModalsProvider>
      <BonusPayments {...props} />
    </ModalsProvider>
  );
}
