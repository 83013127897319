import React, { useEffect } from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { MarketingTasksPageOptions } from './marketing-tasks-page-options';
import oFetch from 'o-fetch';

export function MarketingTasksPageOptionsApp(props) {
  const accessToken = oFetch(props, 'accessToken');

  useEffect(() => {
    window.boss.accessToken = accessToken;
  }, []);

  return (
    <LegacyGlobalProvider global={{}}>
      <ModalsProvider>
        <MarketingTasksPageOptions {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
