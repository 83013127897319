import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { DashboardWrapper, ContentWrapper, ContentBoardWrapper, LoadMore } from '../components';
import { Filter, ContactList, AddContact, ContactItem, EditContact } from './components';
import { useModal } from '@/components/hooks-components/modals';
import { SimpleModal, AreYouSureModalContent, CustomContentModal } from '../components/modals';
import useContactsStore from './use-contacts-store';

export default function Contacts(props) {
  const [statuses, filterParams, permissions] = oFetch(props, 'statuses', 'filterParams', 'permissions');
  const { openModal } = useModal();
  const {
    contacts,
    tags,
    totalCount,
    createContact,
    updateContact,
    disableContact,
    enableContact,
    createContactTag,
    loadMoreContacts,
  } = useContactsStore(props);

  const canCreateContact = oFetch(permissions, 'canCreateContact');
  const contactsCount = contacts.length;

  function renderContactItem(contact) {
    return (
      <ContactItem
        contact={contact}
        onEditClick={openEditContactModal}
        onDisalbleClick={openDisableContactModal}
        onEnableClick={enableContact}
        permissions={permissions}
      />
    );
  }

  function openAddContactModal() {
    openModal({
      onSubmit(closeModal, values) {
        return createContact({ values, closeModal });
      },
      config: {
        title: 'Add Contact',
      },
      props: {
        tagOptions: tags,
        onCreateContactTag: createContactTag,
        permissions: permissions,
      },
      ModalComponent: SimpleModal,
      ModalContent: AddContact,
    });
  }

  function openDisableContactModal(contact) {
    return openModal({
      onSubmit: closeModal => {
        closeModal();
        return disableContact({ contact });
      },
      config: {
        baseClassName: 'purple-modal_size_xs purple-modal_space_l',
      },
      ModalComponent: CustomContentModal,
      ModalContent: AreYouSureModalContent,
    });
  }

  function openEditContactModal(contact) {
    openModal({
      onSubmit(closeModal, values) {
        return updateContact({ values, closeModal });
      },
      config: {
        title: 'Edit Contact',
      },
      props: {
        contact: contact,
        tagOptions: tags,
        onCreateContactTag: createContactTag,
        permissions: permissions,
      },
      ModalComponent: SimpleModal,
      ModalContent: EditContact,
    });
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="purple-page-main__info-group">
          <h1 className="purple-page-main__title">Contacts</h1>
          <div className="purple-page-main__info-group-actions">
            {canCreateContact && (
              <button
                onClick={openAddContactModal}
                className="purple-button purple-button_color_accent-primary purple-button_size_m purple-button_icon_plus purple-page-main__info-group-action"
              >
                <span className="purple-button__text">Add Contanct</span>
              </button>
            )}
          </div>
        </div>
        <Filter filterParams={filterParams} tagOptions={tags} statuses={statuses} />
      </DashboardWrapper>
      <ContentWrapper>
        <ContentBoardWrapper>
          <ContactList items={contacts} itemRenderer={renderContactItem} />
          <LoadMore
            totalCount={totalCount}
            currentCount={contactsCount}
            onLoadMoreClick={() => loadMoreContacts(filterParams)}
          />
        </ContentBoardWrapper>
      </ContentWrapper>
    </Fragment>
  );
}
