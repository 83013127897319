import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import humanize from 'string-humanize';

import { BossFormInput, BossFormSelect, BossFormCheckbox } from '@/components/boss-form';

const renderBaseError = error => {
  return (
    <div className="boss-alert boss-alert_role_area boss-alert_context_above">
      <p className="boss-alert__text">{error}</p>
    </div>
  );
};

class VenueForm extends React.Component {
  render() {
    const fruitOrderFields = oFetch(this.props, 'fruitOrderFields');

    const fruitOrderFieldsOptions = fruitOrderFields.map(field => {
      return {
        value: field,
        label: humanize(field),
      };
    });
    const { error } = this.props;
    return (
      <div>
        {error && renderBaseError(error)}
        <form onSubmit={this.props.handleSubmit} className="boss-modal-window__form boss-form">
          <Field name="name" label="Name" component={BossFormInput} />
          <Field name="bossVenue" label="Boss" component={BossFormCheckbox} />
          <Field name="mossVenue" label="Moss" component={BossFormCheckbox} />
          <Field
            name="emergencyWhatsappGroupId"
            label="Emergency Whatsapp Group ID"
            description="e.g. 14155552671-441234567890@g.us"
            component={BossFormInput}
          />
          <Field
            name="regularLateShiftWhatsappGroupId"
            label="Regular Staff Member Late Shift Whatsapp Group ID"
            description="e.g. 14155552671-441234567890@g.us"
            component={BossFormInput}
          />
          <Field
            name="securityLateShiftWhatsappGroupId"
            label="Security Staff Member Late Shift Whatsapp Group ID"
            description="e.g. 14155552671-441234567890@g.us"
            component={BossFormInput}
          />
          <Field
            name="tillFloatPoundValue"
            label="Till Float"
            unit="£"
            type="number"
            component={BossFormInput}
          />
          <Field
            name="safeFloatPoundValue"
            label="Safe Float"
            unit="£"
            type="number"
            component={BossFormInput}
          />

          <Field
            name="fruitOrderFields"
            label="Fruit order fields"
            placeholder=""
            multi
            clearable
            options={fruitOrderFieldsOptions}
            component={BossFormSelect}
          />
          <Field name="changeOrderSiteId" label="Change Order Site ID" component={BossFormInput} />

          <div className="boss-form__group boss-form__group_role_board">
            <h3 className="boss-form__group-title">Location</h3>
            <Field name="lat" step={0.000001} label="Latitude" type="number" component={BossFormInput} />
            <Field name="lng" step={0.000001} label="Longitude" type="number" component={BossFormInput} />
          </div>
          <div className="boss-form__group boss-form__group_role_board">
            <h3 className="boss-form__group-title">Forecast Thresholds</h3>
            <div className="boss-form__row boss-form__row_layout_flow-fluid">
              <Field
                name="overheadsThresholdPercentage"
                step={0.01}
                label="Overheads"
                unit="%"
                unitPosition="right"
                unitClassName="boss-form__units_size_compact"
                fieldClassName="boss-form__field_layout_flow-fluid"
                inputClassName="boss-form__input_type_compact boss-form__input_size_nano"
                type="number"
                component={BossFormInput}
              />
              <Field
                name="staffThresholdPercentage"
                step={0.01}
                label="Staff"
                unit="%"
                unitPosition="right"
                unitClassName="boss-form__units_size_compact"
                fieldClassName="boss-form__field_layout_flow-fluid"
                inputClassName="boss-form__input_type_compact boss-form__input_size_nano"
                type="number"
                component={BossFormInput}
              />
              <Field
                name="prThresholdPercentage"
                step={0.01}
                label="PRs"
                unit="%"
                unitPosition="right"
                unitClassName="boss-form__units_size_compact"
                fieldClassName="boss-form__field_layout_flow-fluid"
                inputClassName="boss-form__input_type_compact boss-form__input_size_nano"
                type="number"
                component={BossFormInput}
              />
              <Field
                name="kitchenThresholdPercentage"
                step={0.01}
                label="Kitchen"
                unit="%"
                unitPosition="right"
                unitClassName="boss-form__units_size_compact"
                fieldClassName="boss-form__field_layout_flow-fluid"
                inputClassName="boss-form__input_type_compact boss-form__input_size_nano"
                type="number"
                component={BossFormInput}
              />
              <Field
                name="securityThresholdPercentage"
                step={0.01}
                label="Security"
                unit="%"
                unitPosition="right"
                unitClassName="boss-form__units_size_compact"
                fieldClassName="boss-form__field_layout_flow-fluid"
                inputClassName="boss-form__input_type_compact boss-form__input_size_nano"
                type="number"
                component={BossFormInput}
              />
            </div>
          </div>
          <div className="boss-form__field">
            <button
              disabled={this.props.submitting}
              className={`boss-button boss-form__submit ${this.props.buttonClass}`}
            >
              {this.props.buttonText}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

VenueForm.propTypes = {};

VenueForm.defaultProps = {
  buttonClass: '',
  buttonText: 'Create',
};

export default reduxForm({
  form: 'venue-form',
})(VenueForm);
