import React from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';

export function UsersDashboard(props) {
  const renderFilter = oFetch(props, 'renderFilter');

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Users</h1>
          <div className="boss-page-dashboard__buttons-group">
            <a href="/invites" className="boss-button boss-page-dashboard__button">
              Manage Invites
            </a>
          </div>
        </div>
        {renderFilter()}
      </div>
    </DashboardWrapper>
  );
}
