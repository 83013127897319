import React from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function StatusFilter(props: any) {
  const selectedStatuses = oFetch(props, 'selectedStatuses');
  const onStatusChange = oFetch(props, 'onStatusChange');
  const statusOptions = oFetch(useLegacyGlobal(), 'statusOptions');
  return (
    <div className="boss-form__field">
      <div className={'boss-form__select'}>
        <Select
          value={selectedStatuses}
          onChange={onStatusChange}
          clearable
          simpleValue
          multi
          placeholder={'Select statuses ...'}
          options={statusOptions}
        />
      </div>
    </div>
  );
}
