import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { CollapsibleFilter } from '@/components/hooks-components';

export const STATUS_OPTIONS = [
  { value: '', label: 'Any' },
  { value: 'enabled', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
];

export function UsersFilterForm(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const [initialFiterValues, roles] = oFetch(useLegacyGlobal(), 'filter', 'roles');
  const rolesOptions = [{ value: '', label: 'Any' }, ...roles];

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFiterValues}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <CollapsibleFilter>
            <div className="boss-form">
              <div className="boss-form__row boss-form__row_position_last">
                <div className="boss-form__group boss-form__group_layout_half">
                  <Field name="name" label="Name" parse={value => value || ''} component={BossFormInput} />
                  <Field
                    name="status"
                    label="Status"
                    options={STATUS_OPTIONS}
                    parse={value => value || ''}
                    clearable
                    component={BossFormSelectNew}
                  />
                </div>
                <div className="boss-form__group boss-form__group_layout_half">
                  <Field name="email" label="Email" parse={value => value || ''} component={BossFormInput} />
                  <Field
                    name="role"
                    label="Role"
                    options={rolesOptions}
                    clearable
                    parse={value => value || ''}
                    component={BossFormSelectNew}
                  />
                  <div className="boss-form__field boss-form__field_justify_end">
                    <button
                      onClick={handleSubmit}
                      disabled={submitting}
                      className="boss-button boss-form__submit boss-form__submit_adjust_single"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CollapsibleFilter>
        );
      }}
    />
  );
}

// class UsersFilterForm extends Component {
//   render() {
//     return (
//       <form onSubmit={this.props.handleSubmit} className="boss-form">
//         <div className="boss-form__row boss-form__row_position_last">
//           <div className="boss-form__group boss-form__group_layout_half">
//             <Field name="name" label="Name" component={BossFormInput} />
//             <Field
//               name="status"
//               label="Status"
//               options={STATUS_OPTIONS}
//               clearable
//               component={BossFormSelect}
//             />
//           </div>
//           <div className="boss-form__group boss-form__group_layout_half">
//             <Field name="email" label="Email" component={BossFormInput} />
//             <Field name="role" label="Role" options={ROLE_OPTIONS} clearable component={BossFormSelect} />

//             <div className="boss-form__field boss-form__field_justify_end">
//               <button
//                 disabled={this.props.submitting}
//                 className="boss-button boss-form__submit boss-form__submit_adjust_single"
//               >
//                 Update
//               </button>
//             </div>
//           </div>
//         </div>
//       </form>
//     );
//   }
// }
