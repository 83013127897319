import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { ANY } from '../../constants';
import * as types from '../types';

const initialState = {
  status: ANY,
  role: ANY,
};

export default handleActions(
  {
    [types.SET_INITIAL_FILTERS]: (state, action) => {
      const status = oFetch(action, 'payload.status');
      const role = oFetch(action, 'payload.role');
      return { status, role };
    },
    [types.CHANGE_ROLE_FILTER]: (state, action) => {
      const role = oFetch(action, 'payload.role');
      return { ...state, role };
    },
    [types.CHANGE_STATUS_FILTER]: (state, action) => {
      const status = oFetch(action, 'payload.status');
      return { ...state, status };
    },
  },
  initialState,
);
