/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import { DashboardActions } from '@/components/boss-dashboards';
import DashboardWeekSelect from '@/components/boss-dashboards/dashboard-week-select';
import SecurityRotaContent from './security-rota-content';
import SecurityRotaWeekDays from './security-rota-week-days';
import SecurityRotaOverviewChart from '@/components/security-rota-overview-chart';
import SecurityRotaStaffCount from './security-rota-staff-count';
import { GRANULARITY } from '../selectors';
import oFetch from 'o-fetch';

function SecurityRotaOverviewPage(props) {
  function handleDateChage(selection) {
    goToOverviewPage({
      sStartDate: selection.startUIDate,
    });
  }

  function handleRotaDayClick(sDate) {
    props.getSecurityRotaDayDataAction({ sDate });
  }

  function goToOverviewPage(args) {
    const sStartDate = oFetch(args, 'sStartDate');
    window.location.href = appRoutes.securityRotaOverview({
      sStartDate,
    });
  }

  const {
    startDate,
    endDate,
    staffMembers,
    rotaShifts,
    breakdown,
    isLoading,
    groups,
    securityShiftRequestsCount,
  } = props;
  const sDate = oFetch(props, 'sDate');
  const mDate = safeMoment.uiDateParse(sDate);
  const jsSecurityShiftRequestsCount = securityShiftRequestsCount.toJS();
  const sRotaEditUrlDate = mDate.format('DD-MM-YYYY');
  const sRotaDate = mDate.format('dddd, D MMMM YYYY');

  const totalRequestsCount = useMemo(() => {
    return Object.values(jsSecurityShiftRequestsCount).reduce((acc, item) => {
      return acc + item;
    });
  }, [jsSecurityShiftRequestsCount]);

  return (
    <div className="boss-page-main">
      <DashboardWeekSelect
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChage}
        title="Security Rota"
      >
        <DashboardActions>
          <a
            href={appRoutes.securityRotaTxtDownload({ sDate })}
            className="boss-button boss-button_role_download"
          >
            Download as Text
          </a>
        </DashboardActions>
      </DashboardWeekSelect>
      <SecurityRotaContent
        isLoading={isLoading}
        sRotaEditUrlDate={sRotaEditUrlDate}
        sRotaDate={sRotaDate}
        totalRequestsCount={totalRequestsCount}
        rotaShiftsLength={rotaShifts.size}
        rotaWeekDaysRenderer={() => (
          <SecurityRotaWeekDays
            securityShiftRequestsCount={jsSecurityShiftRequestsCount}
            sDate={sDate}
            onDateChange={handleRotaDayClick}
          />
        )}
        leftSideRenderer={() => (
          <SecurityRotaOverviewChart
            staff={staffMembers.toJS()}
            shifts={rotaShifts.toJS()}
            dDateOfRota={mDate.toDate()}
            breakdown={breakdown}
            granularity={GRANULARITY}
            groups={groups}
          />
        )}
        rightSideRenderer={() => <SecurityRotaStaffCount venueStaffCountList={props.venueStaffCountList} />}
      />
    </div>
  );
}

SecurityRotaOverviewPage.propTypes = {
  sDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  getSecurityRotaDayDataAction: PropTypes.func.isRequired,
  venueStaffCountList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ).isRequired,
  staffMembers: PropTypes.array.isRequired,
  rotaShifts: PropTypes.array.isRequired,
  venues: PropTypes.array.isRequired,
  rotas: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  breakdown: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
};

export default SecurityRotaOverviewPage;
