import oFetch from 'o-fetch';
import React from 'react';
import utils from '@/lib/utils';
import { LegacyDefaultModalWrapper } from './legacy-default-modal-wrapper';

export default function LegacyCloseOutsideModalWrapper(props) {
  const [children, title, onClose, baseClassName] = oFetch(
    props,
    'children',
    'title',
    'onClose',
    'baseClassName',
  );

  function renderTitle() {
    if (utils.isFunction(title)) {
      return title();
    }
    return <div className="boss-modal-window__header">{title}</div>;
  }

  return (
    <LegacyDefaultModalWrapper
      onRequestClose={onClose}
      baseClassName={baseClassName}
    >
      <button
        type="button"
        onClick={onClose}
        className="boss-modal-window__close"
      />
      { renderTitle() }
      <div className="boss-modal-window__content">{children}</div>
    </LegacyDefaultModalWrapper>
  );
}

LegacyCloseOutsideModalWrapper.defaultProps = {
  baseClassName: 'boss-modal-window boss-modal-window_role_danger',
};