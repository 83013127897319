import React from 'react';
import oFetch from 'o-fetch';

export function UserItem(props) {
  const [userId, reportsCount, lastReportAt, fullName, userRole, userReports] = oFetch(
    props,
    'userId',
    'reportsCount',
    'lastReportAt',
    'fullName',
    'userRole',
    'userReports',
  );

  return (
    <div className="boss-table__row">
      <div className="boss-table__cell" style={{ width: 'auto' }}>
        <div className="boss-user-summary boss-user-summary_role_report">
          <div className="boss-user-summary__content">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">{fullName}</h2>
              <p
                className="boss-button boss-button_type_label boss-user-summary__label"
                style={{ backgroundColor: '#84bef0' }}
              >
                {userRole}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Last Error Report</p>
          <p className="boss-table__text boss-table__text_type_faded">
            <span>{lastReportAt}</span>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Reports today</p>
          <p className="boss-table__text">2</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Error Reports Count</p>
          <p className="boss-table__text">{reportsCount}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <div className="boss-table__actions">
            <button className="boss-button boss-button_type_small boss-button_role_details-show boss-button_state_hidden">
              Details
            </button>
            <button className="boss-button boss-button_type_small boss-button_role_details-hide">Hide</button>
          </div>
        </div>
      </div>
    </div>
  );
}
