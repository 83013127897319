import React from 'react';
import oFetch from 'o-fetch';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import { ReportTableHeader } from './report-table-header';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  mouseWheel: false,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
};

export function ReportList(props) {
  const color = '#000111';
  const name = 'Security';
  const [mStartDate, itemRenderer] = oFetch(props, 'mStartDate', 'itemRenderer');

  const renderItems = function () {
    const securityFinanceReports = oFetch(props, 'securityFinanceReports');

    return securityFinanceReports
      .sort((a, b) => {
        if (oFetch(a, 'staffMemberName') < oFetch(b, 'staffMemberName')) {
          return -1;
        }
        if (oFetch(a, 'staffMemberName') > oFetch(b, 'staffMemberName')) {
          return 1;
        }
        return 0;
      })
      .map((securityFinanceReport, index) => {
        const financeReportId = oFetch(securityFinanceReport, 'id');

        return React.cloneElement(itemRenderer(securityFinanceReport), {
          key: financeReportId,
        });
      });
  };

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_payroll-report">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">
          <span
            className="boss-button boss-button_type_small boss-button_type_no-behavior boss-board__title-label"
            style={{ backgroundColor: color }}
          >
            {name}
          </span>
        </h2>
      </header>
      <div className="boss-board__content-inner">
        <div className="boss-board__table">
          <ReactIScroll
            iScroll={iScroll}
            options={scrollOptions}
            className="boss-board__scroll boss-board__scroll_type_native"
          >
            <div className="boss-board__scroll-content">
              <div className="boss-table boss-table_page_security-payroll-report">
                <ReportTableHeader staffTypeName={name} mStartDate={mStartDate} />
                {renderItems()}
              </div>
            </div>
          </ReactIScroll>
        </div>
      </div>
    </section>
  );
}
