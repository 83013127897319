import oFetch from 'o-fetch';
import React from 'react';
import ContentWrapper from '@/components/content-wrapper';
import { DashboardWrapper } from '@/components/dashboard';
import { MarketingTasksPageOptionsForm } from './marketing-tasks-page-options-form';
import { updateOptions } from './requests';

export function MarketingTasksPageOptions(props) {
  const [
    taskStaticAdviceText,
    adviceWordCountThreshold,
    aevaSystemPromptTemplate,
    aevaUserPromptTemplate
  ] = oFetch(
    props,
    'taskStaticAdviceText',
    'adviceWordCountThreshold',
    'aevaSystemPromptTemplate',
    'aevaUserPromptTemplate'
  );

  const initialFormValues = {
    taskStaticAdviceText,
    adviceWordCountThreshold,
    aevaSystemPromptTemplate,
    aevaUserPromptTemplate,
  };

  return (
    <>
      <DashboardWrapper>
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">
            <span className="boss-page-dashboard__title-text">Marketing Tasks Page Options</span>
          </h1>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <div className="boss-page-main__group boss-page-main__group_adjust_co-form">
          <MarketingTasksPageOptionsForm onSubmit={updateOptions} initialValues={initialFormValues} />
        </div>
      </ContentWrapper>
    </>
  );
}
