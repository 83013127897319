import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';

import AccessoryRequestsPage from '../components/accessory-requests-page';
import { getAccessoryRefundRequestPermission, getAccessoryRequestPermission } from '../selectors';

import {
  changeVenue,
  loadInitialData,
  loadMoreClick,
  acceptAccessoryRequest,
  rejectAccessoryRequest,
  acceptAccessoryRefundRequest,
  rejectAccessoryRefundRequest,
  undoAccessoryRequest,
  undoAccessoryRefundRequest,
  completeAccessoryRequest,
  completeAccessoryRefundRequest,
  markReceivedAccessoryRequest,
} from '../redux/actions';

const mapStateToProps = state => {
  return {
    venues: oFetch(state, 'accessoryRequestsPage.venues'),
    currentVenue: oFetch(state, 'accessoryRequestsPage.currentVenue'),
    accessories: oFetch(state, 'accessoryRequestsPage.accessories'),
    accessoryRequests: oFetch(state, 'accessoryRequestsPage.accessoryRequests'),
    accessoryRefundRequests: oFetch(state, 'accessoryRequestsPage.accessoryRefundRequests'),
    staffMembers: oFetch(state, 'accessoryRequestsPage.staffMembers'),
    pagination: oFetch(state, 'accessoryRequestsPage.pagination'),
    getAccessoryRequestPermission: getAccessoryRequestPermission(state),
    getAccessoryRefundRequestPermission: getAccessoryRefundRequestPermission(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        changeVenue,
        loadInitialData,
        loadMoreClick,
        acceptAccessoryRequest,
        rejectAccessoryRequest,
        acceptAccessoryRefundRequest,
        rejectAccessoryRefundRequest,
        undoAccessoryRequest,
        undoAccessoryRefundRequest,
        completeAccessoryRequest,
        completeAccessoryRefundRequest,
        markReceivedAccessoryRequest,
      },
      dispatch,
    ),
  };
};

function AccessoryRequestsContainer(props) {
  useEffect(() => {
    oFetch(props, 'actions.loadInitialData')();
  }, []);

  return <AccessoryRequestsPage {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryRequestsContainer);
