import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function CSVRableRow(props) {
  const [isHeader, isAlert, children] = oFetch(props, 'isHeader', 'isAlert', 'children');

  const rowClassNames = cn('boss-table__row', {
    'boss-table__row_role_header': isHeader,
    'boss-table__row_state_alert': isAlert,
  });

  return <div className={rowClassNames}>{children}</div>;
}

CSVRableRow.defaultProps = {
  isHeader: false,
  isAlert: false,
};
