import React, { Component } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import { z } from 'zod';
import { $BossUiDate } from '@/lib/schema-funcs';
import { mossFinanceReportsHourTags as importedMossFinanceReportsHourTags, $MossFinanceReportsHourTagsArgs } from '@/lib/app-routes';
const appRoutes = {
  mossFinanceReportsHourTags: importedMossFinanceReportsHourTags,
};

class CardList extends Component {
  renderItems(staffTypesWithMossFinanceReports) {
    const itemRenderer = oFetch(this.props, 'itemRenderer');

    return staffTypesWithMossFinanceReports.map(staffType => {
      if (oFetch(staffType, 'reports').length === 0) return null;
      const staffTypeId = oFetch(staffType, 'id');
      return React.cloneElement(itemRenderer(staffType), { key: staffTypeId });
    });
  }

  renderEmptyReports() {
    return (
      <div className="boss-page-main__note">
        <p className="boss-page-main__text-placeholder">There are no reports to show.</p>
      </div>
    );
  }

  renderMarkAllComplete() {
    const onMarkAllPageCompleted = oFetch(this.props, 'onMarkAllPageCompleted');

    return (
      <div className="boss-page-main__note boss-page-main__note_role_info">
        <h3 className="boss-page-main__note-title">Complete All Reports</h3>
        <p className="boss-page-main__note-text">All Reports on this page are completable</p>
        <p className="boss-page-main__note-text">
          Clicking here will complete all reports on this week after which no edits will be possible.
        </p>
        <p className="boss-page-main__note-text boss-page-main__note-text_role_important">
          Do not click unless you are sure the numbers are correct.
        </p>
        <div className="boss-page-main__note-actions">
          <button
            onClick={onMarkAllPageCompleted}
            type="button"
            className="boss-button boss-button_role_confirm"
          >
            Mark All Complete
          </button>
        </div>
      </div>
    );
  }

  linkToTagReport(args) {
    const venueId = oFetch(args, 'venueId');
    const dStartDate = oFetch(args, 'dStartDate');

    const url = appRoutes.mossFinanceReportsHourTags(
      $MossFinanceReportsHourTagsArgs.parse({
        startDate: dStartDate,
        venueId,
        mossHourTagIds: [],
      })
    );
    window.location.href = url;
  }

  render() {
    const staffTypesWithMossFinanceReports = oFetch(this.props, 'staffTypesWithMossFinanceReports');
    const allReady = oFetch(this.props, 'allReady');
    const reports = this.renderItems(staffTypesWithMossFinanceReports);
    const hasReports = reports.filter(report => report !== null).length !== 0;
    const venueId = oFetch(this.props, 'venueId');
    const dStartDate = $BossUiDate(z).parse(oFetch(this.props, 'sStartDate'));

    return (
      <div className="boss-page-main__content">
        <div className="boss-page-main__inner">
          <div className="boss-page-main__controls boss-page-main__controls_justify_end">

            <button
              type="button"
              className="boss-page-main__control boss-page-main__control_role_tags"
              onClick={() => this.linkToTagReport({ venueId, dStartDate })}
            >Tag Report</button>
          </div>
          {hasReports ? reports : this.renderEmptyReports()}
          {allReady && hasReports && this.renderMarkAllComplete()}
        </div>
      </div>
    );
  }
}

CardList.propTypes = {
  staffTypesWithMossFinanceReports: PropTypes.array.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  allReady: PropTypes.bool.isRequired,
  onMarkAllPageCompleted: PropTypes.func.isRequired,
};

export default CardList;
