import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export function getRollbarItems(params) {
  const [userId, onSuccess] = oFetch(params, 'userId', 'onSuccess');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const items = oFetch(params, 'data.items.result.items');
      onSuccess(items);
    },
  }).get(`/api/v1/error_reports/rollbar_items`, {
    params: { userId },
  });
}
