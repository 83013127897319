import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

export function BonusPaymentStatus(props) {
  const [status, isDisabled, isMarkedNotReceived, reportedReceivedCents] = oFetch(
    props,
    'status',
    'isDisabled',
    'isMarkedNotReceived',
    'reportedReceivedCents',
  );
  if (isDisabled) {
    return (
      <button className="boss-button boss-button_type_no-behavior boss-button-status__rejected boss-button_type_small">
        DISABLED
      </button>
    );
  }
  if (status === 'pending') {
    return (
      <Fragment>
        <button className="boss-button boss-check__button boss-button_type_no-behavior boss-button-status__pending boss-button_type_small">
          PENDING
        </button>
        {isMarkedNotReceived && (
          <button className="boss-button boss-button_type_no-behavior boss-button-status__wrong_amount boss-button_type_small">
            MARKED NOT RECEIVED
          </button>
        )}
      </Fragment>
    );
  }
  if (status === 'confirmed') {
    return (
      <button className="boss-button boss-button_type_no-behavior boss-button-status__confirmed boss-button_type_small">
        CONFIRMED
      </button>
    );
  }
  if (status === 'rejected_wrong_amount') {
    return (
      <Fragment>
        <button className="boss-button boss-check__button boss-button_type_no-behavior boss-button-status__wrong_amount boss-button_type_small">
          REJECTED, WRONG AMOUNT
        </button>
        <button
          className="boss-tag boss-tag_size_xxs boss-button_type_no-behavior boss-tag_border_accent-red boss-check__header-tag"
          style={{ margin: 0 }}
        >
          <span className="boss-tag__text">{utils.centsFormat(reportedReceivedCents)}</span>
        </button>
      </Fragment>
    );
  }
  throw new Error('Unknown bonus payment status');
}
