import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import oFetch from 'o-fetch';
import { BossFormTextarea } from '@/components/boss-form';

const FORM_NAME = 'task-form';

class TaskForm extends Component {
  handleSubmit = (values, dispatch, form) => {
    return form.onSubmit(values, dispatch, form);
  };
  renderBaseError = error => {
    return (
      <div className="boss-form__error">
        {error.map((errorItem, index) => {
          return (
            <p key={index} className="boss-form__error-text">
              <span className="boss-form__error-line">{errorItem}</span>
            </p>
          );
        })}
      </div>
    );
  };
  render() {
    const [handleSubmit, buttonText, submitting] = oFetch(this.props, 'handleSubmit', 'buttonText', 'submitting');
    const { error } = this.props;
    return (
      <div className="boss-form">
        {error && this.renderBaseError(error)}
        <div className="boss-form__group boss-form__group_role_board">
          <div className="boss-form__row boss-form__row_align_center boss-form__row_layout_nowrap">
            <div className="boss-form__field boss-form__field_layout_max">
              <Field name="text" label="" placeholder="Type task name here ..." component={BossFormTextarea} />
            </div>
            <div className="boss-form__field boss-form__field_layout_min">
              <button
                disabled={submitting}
                onClick={handleSubmit(this.handleSubmit)}
                className="boss-button boss-button_role_add boss-button_type_icon-fluid boss-form__submit"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
})(TaskForm);
