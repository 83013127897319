import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { DashboardWrapper } from '@/components/dashboard';

export default class Dashboard extends Component {
  render() {
    const [title, children] = oFetch(this.props, 'title', 'children');
    return (
      <div>
        <DashboardWrapper classes="boss-page-dashboard_updated boss-page-dashboard_page_add-staff-member">
          <div className="boss-page-dashboard__group boss-page-dashboard__group_spaced">
            <h1 className="boss-page-dashboard__title">{title}</h1>
          </div>
          {children}
        </DashboardWrapper>
      </div>
    );
  }
}
