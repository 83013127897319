import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  venueId: undefined,
  questionnaireId: undefined,
  questionTypesOptions: undefined,
  binaryQuestionType: undefined,
  requiredQuestionType: undefined,
  scaledQuestionType: undefined,
  colors: undefined,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const [
        venueId,
        questionnaireId,
        questionTypesOptions,
        binaryQuestionType,
        requiredQuestionType,
        scaledQuestionType,
        colors,
      ] = oFetch(
        action,
        'payload.venueId',
        'payload.questionnaireId',
        'payload.questionTypesOptions',
        'payload.binaryQuestionType',
        'payload.requiredQuestionType',
        'payload.scaledQuestionType',
        'payload.colors',
      );

      return {
        venueId,
        questionnaireId,
        questionTypesOptions,
        binaryQuestionType,
        requiredQuestionType,
        scaledQuestionType,
        colors,
      };
    },
  },
  initialState,
);
