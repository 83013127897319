import React from 'react';
import oFetch from 'o-fetch';

export function ModulrReportsCard(props) {
  const [title, children] = oFetch(props, 'title', 'children');
  return (
    <section className="boss-board boss-board_context_stack boss-board_role_finance-report">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">{title}</h2>
      </header>
      <div className="boss-board__main">
        <div className="boss-board__main-inner">{children}</div>
      </div>
    </section>
  );
}
