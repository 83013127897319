import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = Immutable.List();
export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const venues = oFetch(action, 'payload.venues');
      return Immutable.fromJS(venues);
    },
    [types.ADD_VENUE]: (state, action) => {
      const venue = oFetch(action, 'payload.venue');
      return state.push(Immutable.fromJS(venue));
    },
    [types.UPDATE_VENUE]: (state, action) => {
      const venue = oFetch(action, 'payload.venue');
      const venueId = oFetch(venue, 'id');
      const venueIndex = state.findIndex(v => v.get('id') === venueId);
      return state.set(venueIndex, Immutable.fromJS(venue));
    },
    [types.ENABLE_VENUE]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const venue = oFetch(payload, 'venue');
      const venueId = oFetch(venue, 'id');
      const venueIndex = state.findIndex(v => v.get('id') === venueId);
      return state.set(venueIndex, Immutable.fromJS(venue));
    },
    [types.DISABLE_VENUE]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const venue = oFetch(payload, 'venue');
      const venueId = oFetch(venue, 'id');
      const venueIndex = state.findIndex(v => v.get('id') === venueId);
      return state.set(venueIndex, Immutable.fromJS(venue));
    },
  },
  initialState,
);
