import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { Form } from 'react-final-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { Fields } from '@/components/boss-form';
import { BossAppAndVenuesSelect } from './boss-app-and-venues-select';

export function AddNewAppForm(props) {
  const [onSubmit, initialValues, appsOptions, appsVenueIds] = oFetch(props, 'onSubmit', 'initialValues', 'appsOptions', 'appsVenueIds');
  const venuesOptions = oFetch(useLegacyGlobal(), 'venuesOptions');

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p
                key={index}
                className="boss-alert__text"
              >
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => {
        const errors = {};
        if (!values.appId) {
          errors.appId = ['Required'];
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Fields
                  names={["appId", "venuesIds"]}
                  venuesOptions={venuesOptions}
                  appsOptions={appsOptions}
                  originalRender={BossAppAndVenuesSelect}
                  appsVenueIds={appsVenueIds}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-button_role_add boss-form__submit"
                    disabled={submitting}
                    type="submit"
                  >
                    Add New App
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
