import oFetch from 'o-fetch';
import React, { useEffect, useState } from 'react';
import { CreateCategory, UpdateCategory } from './components';

export function TrainingMaterialsCategoryApp(props) {
  const category = oFetch(props, 'liveTrainingMaterialCategory');
  const [currentCategoryHtml, setCurrentCategoryHtml] = useState(oFetch(props, 'liveHtml'));

  useEffect(() => {
    const { accessToken } = props;
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, []);

  if (currentCategoryHtml) {
    return (
      <UpdateCategory
        updateCategoryHtml={setCurrentCategoryHtml}
        html={currentCategoryHtml}
        category={category}
      />
    );
  }

  return <CreateCategory updateCategoryHtml={setCurrentCategoryHtml} category={category} />;
}
