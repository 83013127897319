import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';

import setInitial from './actions/initial-load';
import Submissions from './containers/submissions';
import submissionsReducer from './reducers';
import configureStore from '@/apps/store';

function SubmissionsApp(props) {
  const [store, setStore] = useState(null);
  useEffect(() => {
    require('./submissions.css');
    const store = configureStore(submissionsReducer);
    store.dispatch(setInitial({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <Submissions />
    </Provider>
  );
}

export default SubmissionsApp;
