import oFetch from 'o-fetch';
import React from 'react';

export function AppVenuesList(props) {
  const [venues, onVenueClick] = oFetch(props, 'venues', 'onVenueClick');

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview boss-overview_page_app-access-venues">
        {venues.map(venue => {
          const [venueName, hasPermanent, timedPermissions] = oFetch(
            venue,
            'venueName',
            'hasPermanent',
            'timedPermissions',
          );
          const timedPermissionsCount = timedPermissions.length;
          const hasTimedPermissions = timedPermissionsCount > 0;

          return (
            <div
              key={venueName}
              className="boss-check boss-check_role_panel boss-check_page_users-app-access-venues"
            >
              <div className="boss-check__header">
                <div className="boss-check__mark boss-check__mark_position_left">
                  <div className="boss-indicator">
                    <span className="boss-indicator__icon boss-indicator__icon_pin-solid boss-indicator__icon_size_s" />
                  </div>
                </div>
                <h3 className="boss-check__title">{venueName}</h3>
                <div className="boss-check__header-info">
                  {hasPermanent && (
                    <div className="boss-check__header-indicator">
                      <div className="boss-indicator">
                        <span className="boss-indicator__badge boss-indicator__badge_color_accent-green boss-indicator__badge_size_xxs">
                          <span className="boss-indicator__icon boss-indicator__icon_check-bold boss-indicator__icon_size_xxs" />
                        </span>
                        <span className="boss-indicator__label boss-indicator__label_color_gray boss-indicator__label_size_xs boss-indicator__label_position_after">
                          Permanent
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="boss-check__header-number">
                    <div className="boss-number boss-number_layout_row">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_color_gray boss-number__label_type_bold boss-number__label_position_before">
                          Temporary:
                        </p>
                        <h3 className="boss-number__value boss-number__value_size_xs">
                          {timedPermissionsCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boss-check__header-actions">
                  <button
                    onClick={() => onVenueClick(venue)}
                    type="button"
                    className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_details boss-check__header-action"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
