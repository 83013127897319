import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Provider } from 'react-redux';
import { ModalsProvider } from '@/components/hooks-components/modals';
import configureStore from '@/apps/store';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import AddStaffMember from './add-staff-member';
import * as actions from './redux/actions';
import reducers from './redux/reducers';

export function AddStaffMemberPage(props) {
  const [store, setStore] = useState(null);
  const linkableStaffMemberOptions = oFetch(props, 'linkableStaffMemberOptions');

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(actions.loadInitialData(props));

    setStore(store);
  }, []);

  if (!store) {
    return null;
  }

  return (
    <LegacyGlobalProvider global={{ linkableStaffMemberOptions }}>
      <Provider store={store}>
        <ModalsProvider>
          <AddStaffMember />
        </ModalsProvider>
      </Provider>
    </LegacyGlobalProvider>
  );
}
