import React, { Fragment, useMemo, useEffect } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { Field, Fields, reduxForm } from 'redux-form/immutable';

import Select from '@/lib/boss-react-select';
import BossFormSelect from '@/components/boss-form/boss-form-select';

export function MasterVenueField(props) {
  const [accessibleVenues, accessibleMasterVenues] = oFetch(
    props,
    'accessibleVenues',
    'accessibleMasterVenues',
  );
  const [masterVenueField, otherVenuesField] = oFetch(props, 'master_venue', 'other_venues');
  const selectedMasterVenue = oFetch(masterVenueField, 'input.value');
  useEffect(() => {
    const [onOtherVenuesChange, otherVenuesValue] = oFetch(otherVenuesField, 'input.onChange', 'input.value');
    const newOtherVenues = otherVenuesValue.filter(value => value !== selectedMasterVenue);
    onOtherVenuesChange(newOtherVenues);
  }, [selectedMasterVenue]);

  const accessibleOtherVenues = useMemo(() => {
    return accessibleVenues.filter(venue => oFetch(venue, 'id') !== selectedMasterVenue);
  }, [selectedMasterVenue]);

  return (
    <Fragment>
      <Field
        component={BossFormSelect}
        name="master_venue"
        clearable={false}
        required
        label="Master Venue"
        optionLabel="name"
        optionValue="id"
        placeholder="Select main venue ..."
        options={accessibleMasterVenues}
      />
      <Field
        component={BossFormSelect}
        name="other_venues"
        label="Other Venues"
        optionLabel="name"
        optionValue="id"
        multi
        placeholder="Select other venues ..."
        options={accessibleOtherVenues}
      />
    </Fragment>
  );
}
