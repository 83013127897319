import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

export function DisableAppWarning(props) {
  return (
    <Fragment>
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          Removing access to this app will also remove access to all venues.
        </span>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={oFetch(props, 'onClose')}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          CANCEL
        </button>
        <AsyncButton
          className="boss-button boss-modal-window__button"
          text="CONTINUE"
          pendingText="PROCESSING ..."
          onClick={oFetch(props, 'onSubmit')}
        />
      </div>
    </Fragment>
  );
}
