import React from 'react';
import oFetch from 'o-fetch';
import { DashboardWrapper } from '@/components/dashboard';

export function ContactsDetailsDashboard(props) {
  const [title, children] = oFetch(props, 'title', 'children');

  return (
    <DashboardWrapper classes="boss-page-dashboard_updated boss-page-dashboard_page_staff-members-index">
      <div className="boss-page-dashboard__group">
        <h1 className="boss-page-dashboard__title">
          <span className="boss-page-dashboard__title-text">{title}</span>
        </h1>
      </div>
      {children}
    </DashboardWrapper>
  );
}
