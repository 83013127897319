import React, { Fragment, useEffect } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormSelectNew, BossFormCalendar, BossFormTextarea } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import utils from '@/lib/utils';

export function TemporaryItemForm(props) {
  const { initialValues, onSubmit, buttonTitle, venuesOptions } = props;

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-alert__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values => {
        const errors = {};
        return errors;
      }}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError && renderBaseError(submitError)}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <div className="boss-form__row">
                  <Field
                    name="venueId"
                    label="Venue"
                    parse={value => value}
                    fieldClassName={'boss-form__field_layout_third'}
                    options={venuesOptions}
                    component={BossFormSelectNew}
                  />
                  <Field
                    name="startDate"
                    label="Start"
                    parse={value => value}
                    fieldClassName={'boss-form__field_layout_third'}
                    valueFormat={utils.commonDateFormat}
                    component={BossFormCalendar}
                  />
                  <Field
                    name="endDate"
                    label="End"
                    parse={value => value}
                    fieldClassName={'boss-form__field_layout_third'}
                    valueFormat={utils.commonDateFormat}
                    component={BossFormCalendar}
                  />
                </div>
                <Field
                  name="note"
                  label="Note"
                  parse={value => value}
                  fieldClassName={'boss-form__field_layout_third'}
                  component={BossFormTextarea}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    className="boss-button boss-button_role_add boss-form__submit"
                    disabled={submitting}
                    type="submit"
                  >
                    {buttonTitle}
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
