import React from 'react';
import oFetch from 'o-fetch';

export function DeleteConfirmationModal(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  return (
    <div className="boss-modal-window__actions">
      <button type="button" onClick={onSubmit} className="boss-button boss-button_role_cancel">
        Delete
      </button>
    </div>
  );
}
