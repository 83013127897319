import React from 'react';
import oFetch from 'o-fetch';
import { useAppVersion } from '@/use-app-version';
import NewVersionNotification from './new-version-notification';

export default function AppVersionChecker(props) {
  const checkEvery = oFetch(props, 'checkEvery');

  const { isNewVersionExist } = useAppVersion({
    interval: checkEvery,
  });

  if (!isNewVersionExist) {
    return null;
  }

  return <NewVersionNotification countdown={oFetch(props, 'countdown')} />;
}
