import { BossFormCalendar, BossFormTextArea } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import utils from '@/lib/utils';
import moment, { Moment } from 'moment';
import oFetch from 'o-fetch';
import React from 'react';
import { Form, Field } from 'react-final-form';

export type PublicHolidaysFormValues = {
  date: string;
  description: string;
};

type Props = {
  initialValues: PublicHolidaysFormValues;
  onSubmit: (values: PublicHolidaysFormValues) => void;
};

export function PublicHolidayForm(props: Props) {
  const initialValues = oFetch(props, 'initialValues');
  const onSubmit = oFetch(props, 'onSubmit');
  const selectedYear = oFetch(useLegacyGlobal(), 'selectedYear');

  return (
    <div className='boss-form'>
      <Form
        onSubmit={(values: PublicHolidaysFormValues) => onSubmit(values)}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <div className="boss-form__field">
                  <Field
                    name="date"
                    label="Public holiday date"
                    valueFormat={utils.commonDateFormat}
                    initialYear={selectedYear}
                    component={BossFormCalendar}
                    isDayBlocked={(mDay: Moment) => {
                      const mCurrentWeekStart = moment().startOf('isoWeek');
                      return mDay.valueOf() <= mCurrentWeekStart.valueOf();
                    }}
                  />
                </div>
                <Field
                  name="description"
                  label="Description"
                  component={BossFormTextArea}
                />
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting || pristine}
                    className="boss-button boss-button_role_confirm boss-form__submit"
                    type="button"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
