import React, { useMemo } from 'react';
import oFetch from 'o-fetch';

export function RequestsHeader(props) {
  const [onAddRequestClick, requests, isAcceptedTask, canCreateRequest] = oFetch(
    props,
    'onAddRequestClick',
    'requests',
    'isAcceptedTask',
    'canCreateRequest',
  );

  const counts = useMemo(() => {
    return requests.reduce(
      (acc, request) => {
        const [isRequiringAnswer, isRequiringReview] = oFetch(
          request,
          'isRequiringAnswer',
          'isRequiringReview',
        );
        if (isRequiringAnswer) {
          acc.requiringAnswerCount = acc.requiringAnswerCount + 1;
        }
        if (isRequiringReview) {
          acc.requiringReviewCount = acc.requiringReviewCount + 1;
        }
        return acc;
      },
      { requiringAnswerCount: 0, requiringReviewCount: 0 },
    );
  }, [requests]);

  const requestsCount = requests.length;

  return (
    <div className="boss-task__group-header">
      <div className="boss-task__group-info">
        <h3 className="boss-task__group-title boss-task__group-title_state_alert">
          <span className="boss-task__group-title-number">{requestsCount}</span> Requests
        </h3>
        {!isAcceptedTask && (
          <div className="boss-task__group-tags">
            <div
              className="boss-tag boss-tag_role_task-counter boss-task__group-tag"
              style={{ backgroundColor: '#fceddb' }}
            >
              <p className="boss-tag__text">
                <span className="boss-tag__text-number">{oFetch(counts, 'requiringAnswerCount')}</span>{' '}
                <span className="boss-tag__text-light">Requiring Answer</span>
              </p>
            </div>
            <div
              className="boss-tag boss-tag_role_task-counter boss-task__group-tag"
              style={{ backgroundColor: '#e6f2fc' }}
            >
              <p className="boss-tag__text">
                <span className="boss-tag__text-number">{oFetch(counts, 'requiringReviewCount')}</span>{' '}
                <span className="boss-tag__text-light">Requiring Review</span>
              </p>
            </div>
          </div>
        )}
      </div>
      {!isAcceptedTask && canCreateRequest && (
        <div className="boss-task__group-actions">
          <button
            onClick={onAddRequestClick}
            type="button"
            className="boss-action boss-action_role_add boss-action_size_m boss-task__group-action"
          >
            <span className="boss-action__text">Add Request</span>
          </button>
        </div>
      )}
    </div>
  );
}
