import React, { useState } from 'react';
import oFetch from 'o-fetch';
import EffectiveStaffTypeSelect from './effective-staff-type-select';

export default function Filter(props) {
  const [effectiveStaffTypes, onFilterEffectiveStaffType, onFilterName] = oFetch(
    props,
    'effectiveStaffTypes',
    'onFilterEffectiveStaffType',
    'onFilterName',
  );
  const [textValue, setTextValue] = useState('');
  const [text, selectedEffectiveStaffTypes] = oFetch(props, 'filterText', 'filterEffectiveStaffTypes');

  function handleTextChange(e) {
    const value = oFetch(e, 'target.value');
    setTextValue(prev => {
      onFilterName({ text: value });
      return value;
    });
    // onFilter({ effectiveStaffTypes: selectedEffectiveStaffTypes, text: textValue });
  }

  function handleEffectiveStaffTypeChange(value) {
    onFilterEffectiveStaffType({ effectiveStaffTypes: value });
  }

  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <div className="boss-form__row boss-form__row_justify_space">
          <div className="boss-form__field boss-form__field_layout_max">
            <div className="boss-form__search">
              <label className="boss-form__label">
                <input
                  name="search"
                  type="text"
                  value={textValue}
                  onChange={handleTextChange}
                  className="boss-form__input"
                  placeholder="Search..."
                />
              </label>
            </div>
          </div>
          <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min-half">
            <p className="boss-form__label">
              <span className="boss-form__label-text">Staff Type</span>
            </p>
            <div className="boss-form__select">
              <EffectiveStaffTypeSelect
                selectedTypes={selectedEffectiveStaffTypes}
                effectiveStaffTypes={effectiveStaffTypes}
                label="name"
                value="id"
                onChange={handleEffectiveStaffTypeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
