import React from 'react';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';

export default function RotaDayFilter(props) {
  const sCurrentRotaDate = oFetch(props, 'sCurrentRotaDate');
  const desktop = props.desktop === true;

  const sCurrentDayLabel = safeMoment.uiDateParse(sCurrentRotaDate).format('dddd, DD MMMM YYYY');
  const sPreviousDayDate = safeMoment.uiDateParse(sCurrentRotaDate).subtract(1, 'day').format('DD-MM-YYYY')
  const sNextDayDate = safeMoment.uiDateParse(sCurrentRotaDate).add(1, 'day').format('DD-MM-YYYY');

  const additionalClassName = desktop ? "boss-form__row_desktop" : ""

  return (
    <div className={`boss-form__row boss-form__row_justify_space boss-form__row_position_last ${additionalClassName}`}>
      <div className="boss-form__field boss-form__field_position_last">
        <div className="boss-form__pagination">
          <a href={`/rotas/${sPreviousDayDate}`} className="boss-form__pagination-control boss-form__pagination-control_prev">Previous</a>
          <p className="boss-form__pagination-label">{sCurrentDayLabel}</p>
          <a href={`/rotas/${sNextDayDate}`} className="boss-form__pagination-control boss-form__pagination-control_next">Next</a>
        </div>
      </div>
    </div>
  )
}
