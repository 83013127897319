import React from 'react';
import oFetch from 'o-fetch';

export default function CSVTable(props) {
  const children = oFetch(props, 'children');

  return (
    <div className="boss-report__table js-popover-container">
      <div className="boss-report__scroll js-popover-scroll">
        <div className="boss-report__scroll-content">
          <div className="boss-table boss-table_page_csv-upload">{children}</div>
        </div>
      </div>
    </div>
  );
}
