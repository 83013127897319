import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import utils from '@/lib/utils';
import { SomethingWentWrongPage } from '@/components/something-went-wrong-page';

export const throwErrorPage = params => {
  const normalisedParams = params || {};
  const errorId = normalisedParams.errorId || 'N/A';
  const mTimestamp = moment();
  document.body.innerHTML = '';

  const errorPageEl = document.createElement('main');
  document.body.appendChild(errorPageEl);

  errorPageEl.classList.add('boss-page-main');

  const rootErrorPageEl = createRoot(errorPageEl);

  rootErrorPageEl.render(
    <SomethingWentWrongPage
      errorId={errorId}
      timestamp={mTimestamp.format(utils.humanDateFormatWithTimeAndSeconds())}
    />,
  );
};
