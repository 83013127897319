import React, { Fragment } from 'react';
import AsyncButton from 'react-async-button';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';

import * as actions from './actions';

export function DevTests(props) {
  const lambdaFailureTestUrl = oFetch(props, 'lambdaFailureTestUrl');
  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Dev Tests</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <div className="boss-check boss-check_role_board boss-check_page_accessory-requests">
          <div className="boss-check__row">
            <div className="boss-check__cell">
              <p className="boss-check__title">Lambda</p>
            </div>
          </div>
          <div className="boss-check__row boss-check__row_marked">
            <div className="boss-check__info-table" style={{ padding: 20 }}>
              <div className="boss-page-dashboard__buttons-group">
                <AsyncButton
                  className="boss-button boss-page-dashboard__button"
                  text="Call lambda failure test"
                  pendingText="Processing ..."
                  onClick={() => actions.lambdaFailureTestRequest(lambdaFailureTestUrl)}
                />
              </div>
            </div>
          </div>
          <div className="boss-check__row boss-check__row_role_buttons" />
        </div>
      </ContentWrapper>
    </Fragment>
  );
}
