import oFetch from 'o-fetch';
import React, { useState } from 'react';
import DashboardWrapper from '@/components/dashboard-wrapper';
import BossWeekPickerPopover from '@/components/react-dates/boss-week-picker-popover';

export function BonaiDashboard(props) {
  const [
    weekStartDate,
    weekEndDate,
    onWeekSelect,
    errors,
  ] = oFetch(
    props,
    'weekStartDate',
    'weekEndDate',
    'onWeekSelect',
    'errors',
  );
  const hasErrors = errors.length > 0;
  const [processing, setProcessing] = useState(false);

  async function handleAction(action) {
    setProcessing(true);
    await action();
    window.location.reload();
  }

  function renderErrors(errors) {
    return (
      <div className="boss-page-dashboard__message">
        <div className="boss-message boss-message_role_bonai-note">
          {errors.map((error, index) => {
            return (
              <p key={index} className="boss-message__text">
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_bonai">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">
            <span className="boss-page-dashboard__title-text">BONAI</span>
          </h1>
        </div>
        <div className="boss-page-dashboard__group">
          <BossWeekPickerPopover
            weekStartDate={weekStartDate}
            weekEndDate={weekEndDate}
            onWeekSelect={onWeekSelect}
          />
          <div className="boss-page-dashboard__buttons-group">
          </div>
        </div>
        {hasErrors && renderErrors(errors)}
      </div>
    </DashboardWrapper>
  );
}
