import React from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { ModulrReports } from './modulr-reports';
import './style.css';

export function ModulrReportsApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <ModulrReports {...props} />
    </ModalsProvider>
  );
}
