import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
class DashboardActions extends React.Component {
  renderChildrens() {
    return React.Children.map(this.props.children, (child, i) => {
      if (child) {
        return React.cloneElement(child, {
          className: `${child.props.className} boss-page-dashboard__button`,
        });
      }
    });
  }

  render() {
    const { className } = this.props;
    const classNames = cn('boss-page-dashboard__buttons-group', { [className]: className });
    return <div className={classNames}>{this.props.children && this.renderChildrens()}</div>;
  }
}

DashboardActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

DashboardActions.defaultProps = {
  className: '',
};

export default DashboardActions;
