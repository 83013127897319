export default {
  INITIAL_LOAD: 'INITIAL_LOAD',
  QUERY_MAINTENANCE_TASKS: 'QUERY_MAINTENANCE_TASKS',
  GET_MAINTENANCE_REQUEST: 'GET_MAINTENANCE_REQUEST',
  GET_MAINTENANCE_RECEIVE: 'GET_MAINTENANCE_RECEIVE',
  GET_MAINTENANCE_FAILURE: 'GET_MAINTENANCE_FAILURE',
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',
  SET_FRONTEND_STATE: 'SET_FRONTEND_STATE',
  SET_MAINTENANCE_TASK: 'SET_MAINTENANCE_TASK',
  POST_CHANGE_STATUS_REQUEST: 'POST_CHANGE_STATUS_REQUEST',
  POST_CHANGE_STATUS_RECEIVE: 'POST_CHANGE_STATUS_RECEIVE',
  POST_CHANGE_STATUS_FAILURE: 'POST_CHANGE_STATUS_FAILURE',
  UPDATE_MAINTENANCE_TASK: 'UPDATE_MAINTENANCE_TASK',
  POST_ADD_NOTE_REQUEST: 'POST_ADD_NOTE_REQUEST',
  POST_ADD_NOTE_RECEIVE: 'POST_ADD_NOTE_RECEIVE',
  POST_ADD_NOTE_FAILURE: 'POST_ADD_NOTE_FAILURE',
  POST_CREATE_MAINTENANCE_TASK_REQUEST: 'POST_CREATE_MAINTENANCE_TASK_REQUEST',
  POST_CREATE_MAINTENANCE_TASK_RECEIVE: 'POST_CREATE_MAINTENANCE_TASK_RECEIVE',
  POST_CREATE_MAINTENANCE_TASK_FAILURE: 'POST_CREATE_MAINTENANCE_TASK_FAILURE',
  DELETE_MAINTENANCE_TASK_REQUEST: 'DELETE_MAINTENANCE_TASK_REQUEST',
  DELETE_MAINTENANCE_TASK_RECEIVE: 'DELETE_MAINTENANCE_TASK_RECEIVE',
  DELETE_MAINTENANCE_TASK_FAILURE: 'DELETE_MAINTENANCE_TASK_FAILURE',
  EDIT_MAINTENANCE_TASK_REQUEST: 'EDIT_MAINTENANCE_TASK_REQUEST',
  EDIT_MAINTENANCE_TASK_RECEIVE: 'EDIT_MAINTENANCE_TASK_RECEIVE',
  EDIT_MAINTENANCE_TASK_FAILURE: 'EDIT_MAINTENANCE_TASK_FAILURE',
  SET_MAINTENANCE_TASK_IMAGE_UPLOAD: 'SET_MAINTENANCE_TASK_IMAGE_UPLOAD',
  DELETE_MAINTENANCE_TASK_IMAGE_REQUEST: 'DELETE_MAINTENANCE_TASK_IMAGE_REQUEST',
  DELETE_MAINTENANCE_TASK_IMAGE_RECEIVE: 'DELETE_MAINTENANCE_TASK_IMAGE_RECEIVE',
  DELETE_MAINTENANCE_TASK_IMAGE_FAILURE: 'DELETE_MAINTENANCE_TASK_IMAGE_FAILURE',
  REPUBLISH_MAINTENANCE_TASK_MESSAGE_REQUEST: 'REPUBLISH_MAINTENANCE_TASK_MESSAGE_REQUEST',
  REPUBLISH_MAINTENANCE_TASK_MESSAGE_RECEIVE: 'REPUBLISH_MAINTENANCE_TASK_MESSAGE_RECEIVE',
  REPUBLISH_MAINTENANCE_TASK_MESSAGE_FAILURE: 'REPUBLISH_MAINTENANCE_TASK_MESSAGE_FAILURE',
};
