import React from 'react';
import classnames from 'classnames';

import FileUploadService from '../services/file-upload';

export default class ImageModal extends React.Component {
  static displayName = 'ImageModal';

  state = {
    uploading: false,
  };

  deleteUploadFromAnswer(e) {
    e.preventDefault();

    this.props.deleteUpload(this.props.currentImage);
    this.cancel();
  }

  cancel = () => {
    this.props.onClose();
  }

  reuploadImage(e) {
    e.preventDefault();

    this.setState({
      uploading: true,
    });

    FileUploadService.perform(this.props.currentImage.file)
      .then(response => {
        this.setState({
          uploading: false,
        });
        this.props.deleteUpload(this.props.currentImage);
        this.saveImageToAnswer(response);
        this.cancel();
      })
      .catch(error => {
        this.setState({
          uploading: false,
        });
        alert('Image couldnt be uploaded, please try again');
        this.cancel();
      });
  }

  saveImageToAnswer(upload) {
    this.props.setUpload({
      questionnaireQuestionId: this.props.currentImage.questionnaireQuestionId,
      url: upload.url,
      id: upload.id,
    });
  }

  imageURL() {
    if (this.props.currentImage.id) return this.props.currentImage.url;

    let reader = new FileReader();
    let imageUuid = this.props.currentImage.uuid;

    reader.onload = e => {
      $('[data-image-id=' + imageUuid + ']').attr('src', e.target.result);
    };

    reader.readAsDataURL(this.props.currentImage.file);
  }

  uploadState() {
    if (this.state.uploading) {
      return 'Uploading';
    } else {
      return 'Reupload';
    }
  }

  renderButton() {
    if (this.props.currentImage.id) {
      return (
        <div className="boss-modal-window__actions">
          <button
            type="button"
            className="boss-button boss-button_role_delete boss-modal-window__button"
            onClick={this.deleteUploadFromAnswer.bind(this)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div className="boss-modal-window__actions">
          <button
            type="button"
            className="boss-button boss-button_role_reload boss-modal-window__button"
            onClick={this.reuploadImage.bind(this)}
          >
            {this.uploadState()}
          </button>
          <button
            type="button"
            className="boss-button boss-button_role_cancel boss-modal-window__button"
            onClick={this.deleteUploadFromAnswer.bind(this)}
          >
            Remove
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__form">
          <div className="boss-modal-window__message-block">
            <img
              src={this.imageURL()}
              className="boss-modal-window__message-image"
              data-image-id={this.props.currentImage.id || this.props.currentImage.uuid}
            />
          </div>

          {this.renderButton()}
        </div>
      </div>
    );
  }
}
