import React from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';
import { Tooltip } from 'react-tippy';
import pureToJs from '@/hocs/pure-to-js';

import { PaidHolidaysColumn } from './paid-holidays-column';
import { StatusColumn } from './status-column';
const cellStyle = { flexDirection: 'row', alignItems: 'center' };

function ReportItem(props) {
  const isMossFinanceReportWeekFinished = oFetch(props, 'isMossFinanceReportWeekFinished');

  function renderWeekDaysCells() {
    const report = oFetch(props, 'report');
    const mondayHoursCount = utils.round(oFetch(report, 'mondayHoursCount'), 2);
    const tuesdayHoursCount = utils.round(oFetch(report, 'tuesdayHoursCount'), 2);
    const wednesdayHoursCount = utils.round(oFetch(report, 'wednesdayHoursCount'), 2);
    const thursdayHoursCount = utils.round(oFetch(report, 'thursdayHoursCount'), 2);
    const fridayHoursCount = utils.round(oFetch(report, 'fridayHoursCount'), 2);
    const saturdayHoursCount = utils.round(oFetch(report, 'saturdayHoursCount'), 2);
    const sundayHoursCount = utils.round(oFetch(report, 'sundayHoursCount'), 2);

    const mondayHasDeletedHours = oFetch(report, 'mondayHasDeletedHours');
    const tuesdayHasDeletedHours = oFetch(report, 'tuesdayHasDeletedHours');
    const wednesdayHasDeletedHours = oFetch(report, 'wednesdayHasDeletedHours');
    const thursdayHasDeletedHours = oFetch(report, 'thursdayHasDeletedHours');
    const fridayHasDeletedHours = oFetch(report, 'fridayHasDeletedHours');
    const saturdayHasDeletedHours = oFetch(report, 'saturdayHasDeletedHours');
    const sundayHasDeletedHours = oFetch(report, 'sundayHasDeletedHours');

    const mossStaffMemberId = oFetch(props, 'report.mossStaffMemberId');
    const weekDates = oFetch(props, 'weekDates');
    const hoursPending = oFetch(report, 'hoursPending');
    const daysNeedingCompletion = oFetch(report, 'daysNeedingCompletion');

    return [
      [mondayHoursCount, mondayHasDeletedHours],
      [tuesdayHoursCount, tuesdayHasDeletedHours],
      [wednesdayHoursCount, wednesdayHasDeletedHours],
      [thursdayHoursCount, thursdayHasDeletedHours],
      [fridayHoursCount, fridayHasDeletedHours],
      [saturdayHoursCount, saturdayHasDeletedHours],
      [sundayHoursCount, sundayHasDeletedHours],
    ].map((dayHours, index) => {
      const [dayHoursCount, hasDeletedHours] = dayHours;

      const weekDate = weekDates[index];
      if (hoursPending === true && daysNeedingCompletion[weekDate]) {
        const tooltipContent = (
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={appRoutes.mossStaffMemberHoursOverview(mossStaffMemberId, weekDate)}
            >
              {daysNeedingCompletion[weekDate].join(', ')}
            </a>
          </span>
        );
        return (
          <div key={index} className={getCellClassName({ alertStyles: true })} style={cellStyle}>
            <a
              href={appRoutes.mossStaffMemberHoursOverview(mossStaffMemberId, weekDate)}
              className={`${getTextClassName({ alertStyles: true })} boss-table__link`}
            >
              {dayHoursCount}
            </a>
            {renderQuestionMarkTooltip(tooltipContent)}
          </div>
        );
      } else {
        return (
          <div key={index} className={getCellClassName({ alertStyles: false })} style={cellStyle}>
            <p style={{ marginBottom: 0 }} className={getTextClassName({ alertStyles: false })}>
              <a
                href={appRoutes.mossStaffMemberHoursOverview(mossStaffMemberId, weekDate)}
                className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
              >
                {dayHoursCount}
              </a>
            </p>
            {hasDeletedHours && (
              <div className="boss-corner boss-corner_position_bottom-left boss-corner_color_accent-red-saturated">
                <span className="boss-corner__icon boss-corner__icon_trash" />
              </div>
            )}
          </div>
        );
      }
    });
  }

  function getCellClassName(params) {
    const alertStyles = oFetch(params, 'alertStyles');
    return classNames({
      'boss-table__cell': true,
      'boss-table__cell_state_alert': alertStyles,
    });
  }

  function getTextClassName(params) {
    const alertStyles = oFetch(params, 'alertStyles');
    return classNames({
      'boss-boss-table__text': true,
      'boss-table__text_state_alert': alertStyles,
    });
  }

  function renderQuestionMarkTooltip(content) {
    return (
      <Tooltip arrow theme="light" position="right" interactive html={content}>
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderNoteTooltip(note) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        trigger="click"
        interactive
        html={
          <div className="boss-overview">
            <p className="boss-overview__text" style={{ whiteSpace: 'pre-line' }}>
              {note}
            </p>
          </div>
        }
      >
        <span className="boss-table__tooltip">
          <p className="boss-table__text boss-table__text_role_action">
            <span className="boss-indicator boss-table__indicator">
              <span className="boss-indicator__icon boss-indicator__icon_envelope" />
            </span>
          </p>
        </span>
      </Tooltip>
    );
  }

  const startDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');

  const report = oFetch(props, 'report');
  const mossFinanceReportId = oFetch(report, 'id');
  const onNoteClick = oFetch(props, 'onNoteClick');
  const note = oFetch(report, 'note');
  const onUncomplete = oFetch(props, 'onUncomplete');
  const mStartDate = safeMoment.uiDateParse(startDate);
  const mEndDate = safeMoment.uiDateParse(endDate);
  const validationErrors = oFetch(props, 'validationErrors');
  const fullName = oFetch(report, 'mossStaffMemberName');
  const weeklyHours = utils.round(oFetch(report, 'weeklyHours'), 2);
  const owedHours = utils.round(oFetch(report, 'owedHours'), 2);

  const payRateDescription = oFetch(report, 'mossPayRateDescription');
  const totalHoursCount = utils.round(oFetch(report, 'totalHoursCount') + owedHours, 2);
  const total = utils.round(oFetch(report, 'total'), 2);
  const sickLeaveDaysCount = oFetch(report, 'sickLeaveDaysCount');
  const onMarkCompleted = oFetch(props, 'onMarkCompleted');
  const mossStaffMemberId = oFetch(report, 'mossStaffMemberId');
  const containsTimeShiftedOwedHours = oFetch(report, 'containsTimeShiftedOwedHours');
  const pendingCalculation = oFetch(report, 'pendingCalculation');
  const mossStaffMemberDisabled = oFetch(report, 'mossStaffMemberDisabled');
  const hoursPending = oFetch(report, 'hoursPending');
  const hasIncompleteHolidays = oFetch(report, 'hasIncompleteHolidays');

  const validationErrorsExist = oFetch(validationErrors, 'length') > 0;

  const fullNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': mossStaffMemberDisabled,
  });
  const fullNameCellTextClassName = classNames({
    'boss-table__text': true,
  });
  const rowClassName = classNames({
    'boss-table__row': true,
    'boss-table__row_state_alert': hoursPending || validationErrorsExist || hasIncompleteHolidays,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });
  const owedHoursClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': owedHours !== 0 && containsTimeShiftedOwedHours,
  });
  const sickLeaveDaysCountClassName = classNames({
    'boss-table__cell': true,
  });

  return (
    <div id={mossFinanceReportId} className={rowClassName}>
      <div className={fullNameCellClassName}>
        <p className={fullNameCellTextClassName}>
          <a
            href={appRoutes.mossStaffMember(mossStaffMemberId)}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {fullName}
          </a>
        </p>
      </div>
      {renderWeekDaysCells()}
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })}>{weeklyHours}</p>
      </div>
      {owedHours === 0 ? (
        <div className={owedHoursClassName} style={cellStyle}>
          <p className={getTextClassName({ alertStyles: false })}>{owedHours}</p>
        </div>
      ) : (
        <div className={owedHoursClassName} style={cellStyle}>
          <a
            href={appRoutes.mossStaffMemberOwedHours({
              mossStaffMemberId: mossStaffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
          >
            {owedHours}
          </a>
        </div>
      )}

      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })}>{payRateDescription}</p>
      </div>
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p
          className={`${getTextClassName({
            alertStyles: false,
          })} boss-table__text_role_important`}
        >
          {totalHoursCount}
        </p>
      </div>
      <PaidHolidaysColumn mossFinanceReport={report} startDate={startDate} endDate={endDate} />
      {sickLeaveDaysCount === 0 && (
        <div className={sickLeaveDaysCountClassName} style={cellStyle}>
          <p className={getTextClassName({ alertStyles: false })}>{sickLeaveDaysCount}</p>
        </div>
      )}
      {sickLeaveDaysCount !== 0 && (
        <div className={sickLeaveDaysCountClassName} style={cellStyle}>
          <a
            href={appRoutes.mossStaffMemberProfileHolidaysTabFromMossFinanceReport({
              mossStaffMemberId: mossStaffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className={`${getTextClassName({ alertStyles: false })} boss-table__link`}
          >
            {sickLeaveDaysCount}
          </a>
        </div>
      )}
      <div className={getCellClassName({ alertStyles: false })} style={cellStyle}>
        <p className={getTextClassName({ alertStyles: false })} style={total < 0 ? { color: 'red' } : {}}>
          {utils.moneyFormat(total)}
        </p>
      </div>
      <StatusColumn
        isMossFinanceReportWeekFinished={isMossFinanceReportWeekFinished}
        onMarkCompleted={onMarkCompleted}
        onUncomplete={onUncomplete}
        mossFinanceReport={report}
      />
      <div className="boss-table__cell">
        {note && renderNoteTooltip(note)}
        <div className="boss-table__actions">
          {note && (
            <button
              onClick={() => onNoteClick({ mossFinanceReport: report, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Edit Note
            </button>
          )}
          {!note && (
            <button
              onClick={() => onNoteClick({ mossFinanceReport: report, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Add Note
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

ReportItem.propTypes = {
  report: PropTypes.shape({
    mossStaffMemberId: PropTypes.number.isRequired,
    mondayHoursCount: PropTypes.number.isRequired,
    tuesdayHoursCount: PropTypes.number.isRequired,
    wednesdayHoursCount: PropTypes.number.isRequired,
    thursdayHoursCount: PropTypes.number.isRequired,
    fridayHoursCount: PropTypes.number.isRequired,
    saturdayHoursCount: PropTypes.number.isRequired,
    sundayHoursCount: PropTypes.number.isRequired,
    weeklyHours: PropTypes.number.isRequired,
    owedHours: PropTypes.number.isRequired,
    totalHoursCount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    paidHolidayDaysCount: PropTypes.number.isRequired,

    mossStaffMemberName: PropTypes.string.isRequired,
    mossPayRateDescription: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  weekDates: PropTypes.array.isRequired,
  onOpenPayslip: PropTypes.func.isRequired,
  onMarkCompleted: PropTypes.func.isRequired,
};

export default ReportItem;

export const PureJSReportItem = pureToJs(ReportItem);
