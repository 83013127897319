import React from 'react';
import oFetch from 'o-fetch';
import { RemovedRequests } from './removed-requests';
import { RemovedRequestItem } from './removed-request-item';

export function RequestList(props) {
  const [activeRequests, removedRequests, renderRequest, isAcceptedTask] = oFetch(
    props,
    'activeRequests',
    'removedRequests',
    'renderRequest',
    'isAcceptedTask',
  );

  function renderRequests() {
    return activeRequests.map(request => {
      const requestId = oFetch(request, 'id');
      return React.cloneElement(renderRequest(request), {
        key: requestId,
        isAcceptedTask: isAcceptedTask,
      });
    });
  }

  return (
    <div className="boss-task__group-content">
      {renderRequests()}
      <RemovedRequests
        requests={removedRequests}
        renderRequest={request => <RemovedRequestItem isAcceptedTask={isAcceptedTask} request={request} />}
      />
    </div>
  );
}
