import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export const filterSelector = state => oFetch(state.toJS(), 'quizzesHistory.filter');
export const historySelector = state => oFetch(state.toJS(), 'quizzesHistory.history');
export const pagesSelector = state => oFetch(state.toJS(), 'quizzesHistory.pages');
export const staticSelector = state => oFetch(state.toJS(), 'quizzesHistory.static');

export const quizCategoryName = createSelector(staticSelector, staticData => {
  return oFetch(staticData, 'quizCategoryName');
});

export const getFilter = createSelector(filterSelector, filter => {
  const [startDate, endDate] = oFetch(filter, 'startDate', 'endDate');
  return {
    mStartDate: safeMoment.uiDateParse(startDate),
    mEndDate: safeMoment.uiDateParse(endDate),
  };
});

export const showLoadMore = createSelector(pagesSelector, pages => {
  const [total, current] = oFetch(pages, 'total', 'current');
  return current < total;
});

export const getHistory = createSelector(historySelector, history => {
  return history.map(historyItem => {
    const at = oFetch(historyItem, 'at');
    const formattedAt = safeMoment.iso8601Parse(at).format(utils.humanDateFormatWithTime());
    return {
      ...historyItem,
      formattedAt,
    };
  });
});
