import React from 'react';
import oFetch from 'o-fetch';
import PaymentsCollapsibleCard from './payments-collapsible-card';
import { StaffMemberPaymentList, StaffMemberPayment } from './staff-member-payment-list';

export default function StaffMemberPayments(props) {
  const [title, payments, openPayslipModal, openPaymentDetailsModal] = oFetch(
    props,
    'title',
    'payments',
    'openPayslipModal',
    'openPaymentDetailsModal',
  );
  const paymentsCount = payments.length;

  function renderStaffMemberPayment(staffMember) {
    return (
      <StaffMemberPayment
        staffMember={staffMember}
        openPayslipModal={openPayslipModal}
        openPaymentDetailsModal={openPaymentDetailsModal}
      />
    );
  }

  return (
    <PaymentsCollapsibleCard count={paymentsCount} title={title}>
      <StaffMemberPaymentList
        payments={payments}
        staffMemberPaymentRenderer={renderStaffMemberPayment}
      />
    </PaymentsCollapsibleCard>
  );
}
