import React from 'react';
import oFetch from 'o-fetch';
import { formValueSelector } from 'redux-form';
import HoursAcceptancePeriodForm from './hours-acceptance-period-form';
import { MOSS_APP_TYPE } from '@/lib/rota-date';

export function PendingHoursAcceptancePeriodItem(props) {
  const [
    hoursAcceptancePeriod,
    onDeletePeriod,
    onAccept,
    canBypassPeriodsTimeLimits,
    clockingDay,
    activeSpecialSecurityPayrates,
    mossHourTags,
  ] = oFetch(
    props,
    'hoursAcceptancePeriod',
    'onDeletePeriod',
    'onAccept',
    'canBypassPeriodsTimeLimits',
    'clockingDay',
    'activeSpecialSecurityPayrates',
    'mossHourTags',
  );
  const [frontendId, startsAt, endsAt, breaks, date, effectiveStaffMember, specialPayRateSecurityHours, appType, mossHourTagIds] = oFetch(
    hoursAcceptancePeriod,
    'frontendId',
    'startsAt',
    'endsAt',
    'breaks',
    'date',
    'effectiveStaffMember',
    'specialPayRateSecurityHours',
    'appType',
    'mossHourTagIds',
  );
  const [isSecurity, venueId] = oFetch(clockingDay, 'isSecurity', 'venue.id');
  const venueActiveSpecialSecurityPayrates = activeSpecialSecurityPayrates.filter(
    activeSpecialSecurityPayrate => oFetch(activeSpecialSecurityPayrate, 'venueIds').includes(venueId),
  );
  const hoursAcceptancePeriodId = oFetch(hoursAcceptancePeriod, 'id');
  const canHaveSpecialSecurityHours = venueActiveSpecialSecurityPayrates.length > 0;

  let initialValues = {
    id: hoursAcceptancePeriodId,
    frontendId: frontendId,
    startsAt,
    effectiveStaffMember: effectiveStaffMember,
    endsAt,
    date,
    bypassTimeLimits: false,
    breaks: breaks,
    specialPayRateSecurityHours: canHaveSpecialSecurityHours ? specialPayRateSecurityHours : [],
    headDoorHrs: canHaveSpecialSecurityHours ? specialPayRateSecurityHours.length > 0 : false,
    mossHourTagIds,
  };
  const canHaveMossHourTags = appType === MOSS_APP_TYPE;
  if (canHaveMossHourTags) {
    initialValues[mossHourTagIds] = mossHourTagIds;
  }
  const selector = formValueSelector(`clocking-period-${hoursAcceptancePeriodId}`);
  return (
    <HoursAcceptancePeriodForm
      effectiveStaffMember={effectiveStaffMember}
      mossHourTags={mossHourTags}
      appType={appType}
      formSelector={selector}
      hoursAcceptancePeriod={hoursAcceptancePeriod}
      form={`clocking-period-${hoursAcceptancePeriodId}`}
      initialValues={initialValues}
      onDeletePeriod={onDeletePeriod}
      onAccept={onAccept}
      isSecurity={isSecurity}
      activeSpecialSecurityPayrates={venueActiveSpecialSecurityPayrates}
      canBypassPeriodsTimeLimits={canBypassPeriodsTimeLimits}
    />
  );
}
