import React from 'react';
import EventImage from './events-graph/event-image';
import oFetch from 'o-fetch';

type NormalizedClockInEvent = {
  at: string;
};

type RightSideInfoSectionProps = {
  formattedAcceptedHoursAcceptanceTime: string;
  formattedRotaedTime: string;
  formattedClockedTime: string;
  formattedAcceptedTimeDiff: string | null;
  normalizedClockInEvents: NormalizedClockInEvent[]
};

const NEUTRAL_FORMATTED_ACCEPTED_TIME_DIFF_TYPE = 'neutral' as const;
const POSITIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE = 'positive' as const;
const NEGATIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE = 'negative' as const;
type FormattedAcceptedTimeDiffType =
  typeof NEUTRAL_FORMATTED_ACCEPTED_TIME_DIFF_TYPE | typeof POSITIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE | typeof NEGATIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE;

// Used to determine the style of the accepted time diff
function invalidFormattedAcceptedTimeDiffType(formattedAcceptedTimeDiffType: never): never {
  throw new Error(`Invalid formattedAcceptedTimeDiffType: ${formattedAcceptedTimeDiffType}`);
}

function getFormattedAcceptedTimeDiffType(formattedAcceptedTimeDiff: string): FormattedAcceptedTimeDiffType {
  const normalizedFormattedAcceptedTimeDiff = formattedAcceptedTimeDiff.replace(/[() ]/ig, '');
  if (normalizedFormattedAcceptedTimeDiff[0] === '+') {
    return POSITIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE;
  } else if (normalizedFormattedAcceptedTimeDiff[0] === '-') {
    return NEGATIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE;
  } else {
    return NEUTRAL_FORMATTED_ACCEPTED_TIME_DIFF_TYPE;
  }
}

export function RightSideInfoSection(props: RightSideInfoSectionProps) {
  const formattedAcceptedHoursAcceptanceTime = oFetch(props, 'formattedAcceptedHoursAcceptanceTime');
  const formattedRotaedTime = oFetch(props, 'formattedRotaedTime');
  const formattedClockedTime = oFetch(props, 'formattedClockedTime');
  const formattedAcceptedTimeDiff = oFetch(props, 'formattedAcceptedTimeDiff') || '';
  const normalizedFormattedAcceptedTimeDiff = formattedAcceptedTimeDiff.replace(' ', '');
  const showFormattedAcceptedTimeDiff = !['(+0)', '(-0)'].includes(normalizedFormattedAcceptedTimeDiff);
  const normalizedClockInEvents = oFetch(props, 'normalizedClockInEvents');
  const normalizedClockInEventsLength = oFetch(normalizedClockInEvents, 'length');
  const firstClockInEvent = normalizedClockInEvents[0] || null;
  let lastClockInEvent: NormalizedClockInEvent | null = null;
  if (normalizedClockInEventsLength > 1) {
    const event = normalizedClockInEvents[oFetch(normalizedClockInEvents, 'length') - 1];
    if (event === undefined) {
      throw new Error('index error event not found');
    }
    lastClockInEvent = event;
  }

  function getAcceptedTimeDiffStyle(formattedAcceptedTimeDiff: string): React.CSSProperties {
    const formattedAcceptedTimeDiffType = getFormattedAcceptedTimeDiffType(formattedAcceptedTimeDiff);
    switch (formattedAcceptedTimeDiffType) {
      case NEUTRAL_FORMATTED_ACCEPTED_TIME_DIFF_TYPE:
        return {};
      case POSITIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE:
        return { color: '#4caf50' };
      case NEGATIVE_FORMATTED_ACCEPTED_TIME_DIFF_TYPE:
        return { color: '#f44336' };
      default:
        invalidFormattedAcceptedTimeDiffType(formattedAcceptedTimeDiffType);
    }
  }

  const acceptedTimeDiffStyle = getAcceptedTimeDiffStyle(normalizedFormattedAcceptedTimeDiff);

  return (
    <div className="boss-hrc__summary">
      <ul className="boss-hrc__stats-list">
        <li className="boss-hrc__stats-item">
          <p className="boss-hrc__stats-time boss-hrc__stats-time_role_rotaed">
            <span className="boss-hrc__stats-time-value">{formattedRotaedTime}</span>
          </p>
          <p className="boss-hrc__stats-time-label">Rotaed</p>
        </li>
        <li className="boss-hrc__stats-item">
          <p className="boss-hrc__stats-time boss-hrc__stats-time_role_clocked">
            <span className="boss-hrc__stats-time-value">{formattedClockedTime}</span>
          </p>
          <p className="boss-hrc__stats-time-label">Clocked</p>
        </li>
        <li className="boss-hrc__stats-item">
          <p className="boss-hrc__stats-time boss-hrc__stats-time_role_accepted">
            <span className="boss-hrc__stats-time-value">{formattedAcceptedHoursAcceptanceTime}</span>
            {showFormattedAcceptedTimeDiff && (
              <span
                className="boss-hrc__stats-time-note"
                style={acceptedTimeDiffStyle}
              >
                {normalizedFormattedAcceptedTimeDiff}
              </span>
            )}
          </p>
          <p className="boss-hrc__stats-time-label">Accepted</p>
        </li>
      </ul>
      <div className="boss-hrc__scans">
        <div className="boss-hrc__scan">
          <EventImage
            event={firstClockInEvent}
            events={normalizedClockInEvents}
            widthPx={40}
            className=""
          />
        </div>
        <div className="boss-hrc__scan">
          <EventImage
            event={lastClockInEvent}
            events={normalizedClockInEvents}
            widthPx={40}
            className=""
          />
        </div>
      </div>
    </div>
  );
}
