import { throwErrorPage } from '@/lib/error-handlers';
import { rollbarPresent, getRollbarPayload } from '@/lib/rollbar-helpers';
import utils from '@/lib/utils';

const handleUnexpectedFailureCallback = e => {
  let errorId = null;
  function getErrorId(error) {
    const errorResult = Rollbar.error(error);
    errorId = errorResult.uuid;
    return errorId;
  }

  if (rollbarPresent()) {
    Rollbar.configure({ payload: getRollbarPayload() });
    // error format as raised by bluebird lib
    if (utils.safeGet(() => e.detail.reason)) {
      errorId = getErrorId(JSON.stringify(e.detail.reason));
      // error format as raised by bluebird lib
    } else if (utils.safeGet(() => e.reason)) {
      errorId = getErrorId(JSON.stringify(e.reason));
    } else {
      errorId = getErrorId(JSON.stringify(e));
    }
  }

  throwErrorPage({
    errorId: errorId,
  });
};

export const handleUnexpectedFailure = handleUnexpectedFailureCallback;
