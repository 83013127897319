import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import { SubmissionError } from 'redux-form/immutable';
import utils, { normalizeReduxFormErrors } from '@/lib/utils';
import { validateAvatarForUpdateUrlSelector } from './selectors';

import {
  INITIAL_LOAD,
  EDIT_PROFILE,
  ENABLE_PROFILE,
  CANCEL_EDIT_PROFILE,
  CANCEL_ENABLE_PROFILE,
  SHOW_DISABLE_MODAL,
  HIDE_DISABLE_MODAL,
  SHOW_EDIT_AVATAR_MODAL,
  HIDE_EDIT_AVATAR_MODAL,
  UPDATE_MOSS_STAFF_MEMBER,
} from './constants';

export const validateAvatarForUpdateAction = values => (dispatch, getState) => {
  const avatarBase64 = oFetch(values, 'avatarBase64');
  const setupForFacialRecognition = oFetch(values, 'setupForFacialRecognition');
  const allowUnscannableAvatar = !setupForFacialRecognition;

  const currentState = getState().toJS();
  const profile = oFetch(currentState, 'profile');
  const jsMossStaffMember = oFetch(profile, 'mossStaffMember');
  const validateAvatarForUpdateUrl = validateAvatarForUpdateUrlSelector(currentState);
  const [mossStaffMemberId, venueId] = oFetch(jsMossStaffMember, 'id', 'master_venue');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['uploadAvatar'],
        });
        const baseErrors = utils.safeGet(() => errors.uploadAvatar.base) || [];
        const existErrors = utils.safeGet(() => errors.uploadAvatar.exist) || [];

        globalNotifications.showDefaultFailureMessage({
          message: 'Validate Avatar Failed',
        });

        throw new SubmissionError({ _error: [...baseErrors, { exist: existErrors }] });
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      dispatch(hideEditAvatarModal());
      return dispatch(validateAvatarForUpdateRequest(data));
    },
  }).post(validateAvatarForUpdateUrl, {
    avatarBase64,
    mossStaffMemberId,
    venueId,
    allowUnscannableAvatar,
  });
};

const validateAvatarForUpdateRequest = params => dispatch => {
  const [validatedAvatarGUID, mossStaffMemberId] = oFetch(params, 'guid', 'mossStaffMemberId');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'avatar'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Avatar Failed',
        });

        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');

      dispatch(hideEditAvatarModal());
      dispatch(updateMossStaffMember(data));
      globalNotifications.showDefaultSuccessMessage({ message: 'Avatar Updated Successfully' });
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/update_avatar`, {
    validatedAvatarGUID,
  });
};

export const markRetakeAvatar = mossStaffMemberId => (dispatch, getState) => {
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Mark retake avatar Successfully',
      });
      return dispatch(updateMossStaffMember(data));
    },
  }).post(apiRoutes.mossMarkRetakeAvatar.getPath(mossStaffMemberId));
};

export const changeMasterVenueAction = params => (dispatch, getState) => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [mossStaffMemberId, masterVenue] = oFetch(values, 'mossStaffMemberId', 'master_venue');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'master_venue'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Change master venue Failed',
        });

        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const mossStaffMember = oFetch(data, 'staffMember');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member change master venue Successfully',
      });
      onSuccess();
      return dispatch(updateMossStaffMember(mossStaffMember));
    },
  }).post(apiRoutes.changeMossMasterVenue.getPath(mossStaffMemberId), {
    master_venue: masterVenue,
  });
};

export const disableMossStaffMemberRequest = params => (dispatch, getState) => {
  const [neverRehire, reason] = oFetch(params, 'neverRehire', 'reason');
  const mossStaffMemberId = oFetch(getState().toJS(), 'profile.mossStaffMember.id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['reason'],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Disable Moss Staff Member Failed',
        });
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Disabled Successfully',
      });

      dispatch(hideDisableMossStaffMemberModal());
      dispatch(updateMossStaffMember(data));
      window.scrollTo(0, 0);
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/disable`, {
    never_rehire: neverRehire,
    disable_reason: reason,
  });
};

export const enableMossStaffMember = params => (dispatch, getState) => {
  const [mossStaffMemberId, firstName, surname, mainVenue, mossStaffType, pinCode, mossPayRate, validatedAvatarGUID, setupForFacialRecognition] = oFetch(
    params,
    'mossStaffMemberId',
    'firstName',
    'surname',
    'mainVenue',
    'mossStaffType',
    'pinCode',
    'mossPayRate',
    'validatedAvatarGUID',
    'setupForFacialRecognition'
  );

  const postParams = {
    firstName,
    surname,
    mainVenue,
    mossStaffType,
    pinCode,
    mossPayRate,
    validatedAvatarGUID,
    setupForFacialRecognition
  };
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['mossStaffType', 'masterVenue', 'mossPayRate'],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Enable Moss Staff Member Failed',
        });
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Enabled Successfully',
      });
      dispatch(updateMossStaffMember(data));
      dispatch(cancelEnableProfile());
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/enable`, postParams);
};

export const updateEmploymentDetailsRequest = payload => (dispatch, getState) => {
  const mossStaffMemberId = oFetch(getState().toJS(), 'profile.mossStaffMember.id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'master_venue', 'moss_staff_type'],
        });

        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Employment Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Employment Details Updated Successfully',
      });
      dispatch(updateMossStaffMember(data));
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/update_employment_details`, {
    ...payload,
    mossStaffMemberId,
  });
};

export const updatePersonalDetailsRequest = payload => (dispatch, getState) => {
  const profile = oFetch(getState().toJS(), 'profile');
  const mossStaffMember = oFetch(profile, 'mossStaffMember');
  const mossStaffMemberId = oFetch(mossStaffMember, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'first_name', 'surname'],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Personal Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Personal Details Updated Successfully',
      });

      dispatch(updateMossStaffMember(data));
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/update_personal_details`, {
    ...payload,
    mossStaffMemberId,
  });
};

export const updateContactDetailsRequest = payload => (dispatch, getState) => {
  const mossStaffMemberId = oFetch(getState().toJS(), 'profile.mossStaffMember.id');
  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, errors, globalNotifications, supportedKeyChecker] = oFetch(
        params,
        'statusCode',
        'errors',
        'globalNotifications',
        'supportedKeyChecker',
      );
      if (statusCode === 422) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'phone_number',
            'address',
            'postcode',
            'country',
            'county',
            'email_address',
          ],
        });
        globalNotifications.showDefaultFailureMessage({
          message: 'Updating Contact Details Failed',
        });
        window.scrollTo(0, 0);
        throw new SubmissionError(normalizeReduxFormErrors(errors));
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({
        message: 'Moss Staff Member Contact Details Updated Successfully',
      });

      dispatch(updateMossStaffMember(data));
      dispatch(cancelEditProfile());
    },
  }).post(`/api/v1/moss_staff_members/${mossStaffMemberId}/update_contact_details`, {
    ...payload,
  });
};

export const initialProfileLoad = createAction(INITIAL_LOAD);

export const editProfile = createAction(EDIT_PROFILE);
export const cancelEditProfile = createAction(CANCEL_EDIT_PROFILE);

export const showDisableMossStaffMemberModal = createAction(SHOW_DISABLE_MODAL);
export const hideDisableMossStaffMemberModal = createAction(HIDE_DISABLE_MODAL);

export const showEditAvatarModal = createAction(SHOW_EDIT_AVATAR_MODAL);
export const hideEditAvatarModal = createAction(HIDE_EDIT_AVATAR_MODAL);
export const updateMossStaffMember = createAction(UPDATE_MOSS_STAFF_MEMBER);
export const enableProfile = createAction(ENABLE_PROFILE);
export const cancelEnableProfile = createAction(CANCEL_ENABLE_PROFILE);
