import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default class AreaItem extends React.Component {
  state = {
    deleting: false,
  };

  handleDelete = area => {
    const onDelete = oFetch(this.props, 'onDelete');
    this.setState({ deleting: true });
    return onDelete({ area });
  };

  render() {
    const area = oFetch(this.props, 'area');
    const deleting = oFetch(this.state, 'deleting');
    const [id, name, number, isEmpty] = oFetch(area, 'id', 'name', 'number', 'isEmpty');

    const tagClassNames = cn('boss-tag__service', {
      'boss-tag__service_role_action boss-tag__service_icon_close': !deleting,
      'boss-tag__service_icon_spinner': deleting,
    });

    return (
      <div
        data-id={id}
        style={{ backgroundColor: '#95afc0' }}
        className="boss-tag boss-tag_size_l boss-tag_type_sortable"
      >
        <div className="boss-tag__handle boss-tag__handle_position_before" />
        <div className="boss-tag__service">{number}</div>
        <p className="boss-tag__text">{name}</p>
        {isEmpty && <div onClick={() => this.handleDelete(area)} className={tagClassNames} />}
      </div>
    );
  }
}
