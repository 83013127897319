import moment from 'moment';
import safeMoment from '../safe-moment';
import { RotaAppType, BOSS_DAY_START_HOUR, MOSS_DAY_START_HOUR } from './constants';
import oFetch from 'o-fetch';

export type TRotaDate = {
  calendarDate: () => Date,
  mCalendarDate: () => moment.Moment,
  startTime: () => Date,
  mStartTime: () => moment.Moment,
  endTime: () => Date,
  mEndTime: () => moment.Moment,
  startHour: () => number,
};

export const startHour = (appType: RotaAppType): number => {
  if (appType === RotaAppType.boss) {
    return BOSS_DAY_START_HOUR;
  } else if (appType === RotaAppType.moss) {
    return MOSS_DAY_START_HOUR;
  } else {
    throw new Error(`invalid appType supplied: ${appType}`);
  }
};

const fromDate = (args: { dCalendarDate: Date, appType: RotaAppType }): TRotaDate => {
  const dCalendarDate = oFetch(args, 'dCalendarDate');
  if (!dCalendarDate) {
    throw new Error(`dCalendarDate must be supplied`);
  }
  // Version of dDate that definately represents an actual date
  const dDateOfDate = new Date(dCalendarDate);
  dDateOfDate.setHours(0, 0, 0, 0);
  if (dCalendarDate.getTime() !== dDateOfDate.getTime()) {
    throw new Error(`supplied dCalendarDate should be a date but was a time`);
  }
  const appType = oFetch(args, 'appType');

  const calendarDate = () => {
    return new Date(dCalendarDate);
  };

  const mCalendarDate = () => moment(calendarDate());

  const startTime = () => {
    return new Date(
      dCalendarDate.getFullYear(),
      dCalendarDate.getMonth(),
      dCalendarDate.getDate(),
      startHour(appType),
      0,
    );
  };

  const mStartTime = () => moment(startTime());

  const endTime = () => {
    // This will increment the month automatically if day number is
    // more than specified month contains
    const nextDay = dCalendarDate.getDate() + 1;
    return new Date(
      dCalendarDate.getFullYear(),
      dCalendarDate.getMonth(),
      nextDay,
      startHour(appType),
      0,
    );
  };

  const mEndTime = () => moment(endTime());

  return {
    calendarDate,
    mCalendarDate,
    startTime,
    mStartTime,
    endTime,
    mEndTime,
    startHour: () => startHour(appType),
  };
};

const mFromDate = (args: { mCalendarDate: moment.Moment, appType: RotaAppType }): TRotaDate => {
  const mCalendarDate = oFetch(args, 'mCalendarDate');
  const appType = oFetch(args, 'appType');

  return fromDate({
    dCalendarDate: mCalendarDate.toDate(),
    appType,
  });
};

const sFromDate = (args: { sCalendarDate: string, appType: RotaAppType }): TRotaDate => {
  const mCalendarDate = safeMoment.uiDateParse(oFetch(args, 'sCalendarDate'));
  const appType = oFetch(args, 'appType');

  return fromDate({
    dCalendarDate: mCalendarDate.toDate(),
    appType,
  });
};

const fromTime = (args: { dTime: Date, appType: RotaAppType }): TRotaDate => {
  const dTime = oFetch(args, 'dTime');
  if (!dTime) {
    throw new Error(`dTime must be supplied`);
  }
  const appType = oFetch(args, 'appType');

  const dCalendarDate = new Date(dTime.toDateString());
  dCalendarDate.setHours(0, 0, 0, 0);
  const dRotaDateStartTime = new Date(
    dCalendarDate.getFullYear(),
    dCalendarDate.getMonth(),
    dCalendarDate.getDate(),
    startHour(appType),
    0,
  );

  const dDate = dCalendarDate;
  if (dTime < dRotaDateStartTime) {
    //set dDate to previous day
    dDate.setDate(dDate.getDate() - 1);
  }

  return fromDate({ dCalendarDate: dDate, appType });
};

const mFromTime = (args: { mTime: moment.Moment, appType: RotaAppType }): TRotaDate => {
  const mTime = oFetch(args, 'mTime');
  const appType = oFetch(args, 'appType');

  return fromTime({
    dTime: mTime.toDate(),
    appType,
  });
};

const sFromTime = (args: { sTime: string, appType: RotaAppType }): TRotaDate => {
  const mTime = safeMoment.iso8601Parse(oFetch(args, 'sTime'));
  const appType = oFetch(args, 'appType');

  return fromTime({
    dTime: mTime.toDate(),
    appType,
  });
};

const RotaDate = {
  //Actual constructor
  fromDate,
  mFromDate,
  sFromDate,
  //constructs using fromDate
  fromTime,
  mFromTime,
  sFromTime,
};

export {
  RotaDate,
};
