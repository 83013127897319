import React from 'react';
import oFetch from 'o-fetch';

export function ContactsList(props) {
  const [items, itemRender] = oFetch(props, 'items', 'itemRender');

  return items.map(item => {
    const id = oFetch(item, 'id');
    const element = itemRender(item);

    return <React.Fragment key={id}>{element}</React.Fragment>;
  });
}
