import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = {
  eventTypesOptions: null,
  accessToken: null,
  venues: null,
  currentVenue: null,
  globalEventType: null,
  localEventType: null,
  singleRecurringType: null,
  selectedDate: null,
  recurrenceTypes: null,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const [
        eventTypesOptions,
        accessToken,
        venues,
        currentVenue,
        globalEventType,
        localEventType,
        selectedDate,
        viewStartDate,
        viewEndDate,
        recurrenceTypes,
        singleRecurringType,
      ] = oFetch(
        action.payload,
        'eventTypesOptions',
        'accessToken',
        'venues',
        'currentVenue',
        'globalEventType',
        'localEventType',
        'selectedDate',
        'viewStartDate',
        'viewEndDate',
        'recurrenceTypes',
        'singleRecurringType',
      );

      return {
        eventTypesOptions,
        accessToken,
        venues,
        currentVenue,
        globalEventType,
        localEventType,
        selectedDate,
        viewStartDate,
        viewEndDate,
        recurrenceTypes,
        singleRecurringType,
      };
    },
  },
  initialState,
);
