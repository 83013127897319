import React from 'react';
import oFetch from 'o-fetch';
import { RequestAnswerForm } from '../../forms';

export function AddAnswerModalContent(props) {
  const [onSubmit, onClose, marketingTaskRequest] = oFetch(
    props,
    'onSubmit',
    'onClose',
    'marketingTaskRequest',
  );

  const initialValues = {
    taskId: oFetch(marketingTaskRequest, 'marketingTaskId'),
    requestId: oFetch(marketingTaskRequest, 'id'),
    text: '',
    fileIds: [],
  };

  return (
    <div className="boss-modal-window__form">
      <RequestAnswerForm initialValues={initialValues} files={{}} onSubmit={onSubmit} onClose={onClose} />
    </div>
  );
}
