import React from 'react';
import oFetch from 'o-fetch';
import { TemporaryList, TemporaryItem } from './temporary-access';

export function VenuePermissionDetails(props) {
  const [timedPermissions, hasPermanent] = oFetch(props, 'timedPermissions', 'hasPermanent');

  function renderItem(params) {
    const [item, venuesOptions] = oFetch(params, 'item', 'venuesOptions');
    return (
      <TemporaryItem
        item={item}
        venuesOptions={venuesOptions}
      />
    );
  }

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview boss-overview_page_app-access-venue-details">
        {hasPermanent && (
          <div className="boss-overview__group">
            <h4 className="boss-overview__label">
              <span className="boss-overview__label-text">Permanent Access</span>
            </h4>
            <div className="boss-indicator">
              <span className="boss-indicator__badge boss-indicator__badge_color_accent-green boss-indicator__badge_size_xxs">
                <span className="boss-indicator__icon boss-indicator__icon_check-bold boss-indicator__icon_size_xxs" />
              </span>
              <span className="boss-indicator__label boss-indicator__label_type_light boss-indicator__label_color_gray boss-indicator__label_size_xs boss-indicator__label_position_after">
                Has Permanent Access
              </span>
            </div>
          </div>
        )}
        <div className="boss-overview__group boss-overview__group_position_last">
          <h4 className="boss-overview__label">
            <span className="boss-overview__label-text">Temporary Access</span>
          </h4>
          <TemporaryList
            items={timedPermissions}
            renderItem={renderItem}
            venuesOptions={[]}
          />
        </div>
      </div>
    </div>
  );
}
