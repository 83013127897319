import React, { ReactElement } from 'react';
import oFetch from 'o-fetch';
import type { MessageSchedule } from '@/types/MessageSchedule';

interface MessageScheduleListProps {
  messageSchedules: MessageSchedule[],
  renderItem: (messageSchedule: MessageSchedule) => ReactElement
}

export function MessageScheduleList(props: MessageScheduleListProps) {
  const { messageSchedules, renderItem } = props;

  return (
    <>
      {messageSchedules.map((messageSchedule: any) => {
        const messageScheduleId = oFetch(messageSchedule, 'id');
        return React.cloneElement(renderItem(messageSchedule), { key: messageScheduleId });
      })}
    </>
  );
}
