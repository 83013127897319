import React, { Fragment, useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import { Field, Fields, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  StaffMemberFullValue,
  StaffMemberFullOption,
  ColoredSingleValue,
  ColoredSingleOption,
} from '@/components/boss-form/colored-select';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import {
  BossFormInput,
  BossFormSelectNew,
  BossFormCalendar,
  BossFormAvatarWithFacial,
  BossFormCheckbox,
} from '@/components/boss-form';
import utils from '@/lib/utils';
import { useSiaBadgeValidation } from '@/components/hooks-components/use-sia-badge-validation';

import * as c from '../../constants';
import { SIAFormField, NonEditableSIAField } from '../fields/sia-form-field';
import * as selectors from '../../redux/selectors';
import { basicInformationFormValidators } from './validators';
import AvatarDescription from './avatar-description';

function BasicInformationForm(props) {
  const [
    handleSubmit,
    valid,
    submitting,
    validateAvatarForCreateUrl,
    isSecurity,
    isSteward,
    staffTypeOptions,
    canBypassSiaValidation,
    siaBadgeCheckGUID,
    change,
    checkSiaNumber,
    onSubmit,
    selectedMossStaffMemberId,
  ] = oFetch(
    props,
    'handleSubmit',
    'valid',
    'submitting',
    'validateAvatarForCreateUrl',
    'isSecurity',
    'isSteward',
    'staffTypeOptions',
    'canBypassSiaValidation',
    'siaBadgeCheckGUID',
    'change',
    'checkSiaNumber',
    'onSubmit',
    'selectedMossStaffMemberId',
  );
  const { error } = props;
  const genderOptions = oFetch(props, 'genderOptions');

  const linkableStaffMemberOptions = oFetch(useLegacyGlobal(), 'linkableStaffMemberOptions');
  const selectedLinkableMossStaffMemberOption = useMemo(() => {
    if (!selectedMossStaffMemberId) {
      return null;
    }
    return linkableStaffMemberOptions.find(linkableStaffMember => {
      return oFetch(linkableStaffMember, 'id') === selectedMossStaffMemberId;
    });
  }, [selectedMossStaffMemberId]);

  const hasSelectedLinkStaffMemberOption = selectedLinkableMossStaffMemberOption != null;

  const { checkSIANumber, siaBadgeValidating } = useSiaBadgeValidation({
    staffMemberId: null,
    onDowngradeClick: handleSIADowngradeClick,
    onNotFoundSubmit: handleSIANotFoundSubmit,
    onInvalidTryAgainClick: handleInvalidSIATryAgainClick,
    onRequestFailed: handleCheckSIARequestFailed,
    onDuplicatesModalSubmit(closeModal) {
      change(c.SIA_BADGE_NUMBER_FIELD_NAME, '');
      closeModal();
    },
    onNonFrontLineModalSubmit(closeModal) {
      change(c.SIA_BADGE_NUMBER_FIELD_NAME, '');
      closeModal();
    },
    onSiaCheckSuccess: handleSiaCheckSuccess,
    onFormatInvalid() {},
  });

  function handleValidation(values) {
    return onSubmit({
      values,
      siaBadgeNumberFailure() {
        if (siaBadgeCheckGUID) {
          change(c.SIA_GUID_FIELD_NAME, '');
          change(c.FIRST_NAME_FIELD_NAME, '');
          change(c.SURNAME_FIELD_NAME, '');
          change(c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME, '');
          change(c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME, false);
        }
      },
    });
  }

  function handleSiaCheckSuccess(data) {
    const [guid, expiryDate, firstName, surname] = oFetch(data, 'guid', 'expiryDate', 'firstName', 'surname');

    change(c.SIA_GUID_FIELD_NAME, guid);
    change(c.FIRST_NAME_FIELD_NAME, firstName);
    change(c.SURNAME_FIELD_NAME, surname);
    change(c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME, expiryDate);
    change(c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME, false);
  }

  function clearCheckSIAFields() {
    change(c.SIA_GUID_FIELD_NAME, null);
    change(c.FIRST_NAME_FIELD_NAME, null);
    change(c.SURNAME_FIELD_NAME, null);
    change(c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME, null);
  }

  function handleSIANotFoundSubmit(closeModal) {
    clearCheckSIAFields();
    change(c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME, false);
    closeModal();
  }

  function handleSIADowngradeClick(closeModal) {
    change(c.STEWARD_FIELD_NAME, true);
    closeModal();
  }

  function handleInvalidSIATryAgainClick(closeModal) {
    clearCheckSIAFields();
    change(c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME, false);
    closeModal();
  }

  function handleCheckSIARequestFailed(params) {
    const [closeModal, guid] = oFetch(params, 'closeModal', 'guid');
    change(c.CAN_BYPASS_SIA_VALIDATION_FIELD_NAME, true);
    change(c.SIA_GUID_FIELD_NAME, guid);
    closeModal();
  }
  const nonStewardSecurity = isSecurity && !isSteward;
  const canBypassValidation = canBypassSiaValidation || !checkSiaNumber;
  const canShowSiaBadgeExpiryDateField = nonStewardSecurity && canBypassValidation;
  const canShowNamesFields = !checkSiaNumber || !nonStewardSecurity || canBypassValidation;
  const canLinkStaffMember = oFetch(linkableStaffMemberOptions, 'length') > 0;

  function renderBaseError(errors) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => (
            <p key={index} className="boss-alert__text">
              {error}
            </p>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="boss-page-main__group">
      <div className="boss-form">
        {error && renderBaseError(error)}
        {isSecurity && (
          <div>
            <div className="boss-form__row boss-form__row_justify_end boss-form__row_layout_nowrap">
              <Field
                component={BossFormCheckbox}
                className="boss-form__field_layout_actual"
                name={c.STEWARD_FIELD_NAME}
                disabled={siaBadgeValidating}
                left={false}
                label={c.BASIC_FORM_FIELDS_TITLES[c.STEWARD_FIELD_NAME]}
              />
            </div>
          </div>
        )}
        {canLinkStaffMember && (
          <div className="boss-form__field boss-form__select_role_staff-member">
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.LINK_MOSS_STAFF_MEMBER_FIELD_NAME]}
              name={c.LINK_MOSS_STAFF_MEMBER_FIELD_NAME}
              valueKey="id"
              labelKey="fullName"
              options={linkableStaffMemberOptions}
              valueComponent={StaffMemberFullValue}
              optionComponent={StaffMemberFullOption}
              clearable
              component={BossFormSelectNew}
              placeholder="Create from existing Moss Staff Member ..."
            />
          </div>
        )}
        <Field
          label={c.BASIC_FORM_FIELDS_TITLES[c.STAFF_TYPE_FIELD_NAME]}
          name={c.STAFF_TYPE_FIELD_NAME}
          options={staffTypeOptions}
          valueComponent={ColoredSingleValue}
          disabled={siaBadgeValidating}
          optionComponent={ColoredSingleOption}
          component={BossFormSelectNew}
        />
        {isSecurity && !isSteward && (
          <Field
            label={c.BASIC_FORM_FIELDS_TITLES[c.SIA_BADGE_NUMBER_FIELD_NAME]}
            name={c.SIA_BADGE_NUMBER_FIELD_NAME}
            staffMemberId={null}
            siaBadgeValidating={siaBadgeValidating}
            validateSiaNumber={checkSiaNumber}
            checkSIANumber={checkSIANumber}
            component={SIAFormField}
          />
        )}
        {!canShowSiaBadgeExpiryDateField && siaBadgeCheckGUID && checkSiaNumber && (
          <Fragment>
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME]}
              name={c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME}
              component={NonEditableSIAField}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.FIRST_NAME_FIELD_NAME]}
              name={c.FIRST_NAME_FIELD_NAME}
              component={NonEditableSIAField}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.SURNAME_FIELD_NAME]}
              name={c.SURNAME_FIELD_NAME}
              component={NonEditableSIAField}
            />
          </Fragment>
        )}
        {canShowSiaBadgeExpiryDateField && (
          <Field
            label={c.BASIC_FORM_FIELDS_TITLES[c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME]}
            name={c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME}
            valueFormat={utils.commonDateFormat}
            component={BossFormCalendar}
          />
        )}
        {canShowNamesFields && !hasSelectedLinkStaffMemberOption && (
          <Fragment>
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.FIRST_NAME_FIELD_NAME]}
              name={c.FIRST_NAME_FIELD_NAME}
              component={BossFormInput}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.SURNAME_FIELD_NAME]}
              name={c.SURNAME_FIELD_NAME}
              component={BossFormInput}
            />
          </Fragment>
        )}
        {hasSelectedLinkStaffMemberOption && (
          <div>
            <label>First Name</label>
            <p>{`${oFetch(
              selectedLinkableMossStaffMemberOption,
              'firstName',
            )} (taken from linked Moss Staff Member)`}</p>
          </div>
        )}
        {hasSelectedLinkStaffMemberOption && (
          <div>
            <label>Surname</label>
            <p>{`${oFetch(
              selectedLinkableMossStaffMemberOption,
              'surname',
            )} (from linked Moss Staff Member)`}</p>
          </div>
        )}
        <Field
          label={c.BASIC_FORM_FIELDS_TITLES[c.GENDER_FIELD_NAME]}
          name={c.GENDER_FIELD_NAME}
          options={genderOptions}
          component={BossFormSelectNew}
          disabled={siaBadgeValidating}
        />
        <Field
          name={c.DATE_OF_BIRTH_FIELD_NAME}
          label={c.BASIC_FORM_FIELDS_TITLES[c.DATE_OF_BIRTH_FIELD_NAME]}
          placeholder="Select date of birth ..."
          component={BossFormCalendar}
          disabled={siaBadgeValidating}
          valueFormat={utils.commonDateFormat}
        />
        {!hasSelectedLinkStaffMemberOption && (
          <>
            <Fields
              names={[c.VALIDATED_AVATAR_GUID_FIELD_NAME, c.AVATAR_FIELD_NAME, c.SETUP_FOR_FACIAL_FIELD_NAME]}
              markedRetakeAvatar={false}
              disableUpload={false}
              validateAvatarForCreateUrl={validateAvatarForCreateUrl}
              component={BossFormAvatarWithFacial}
            />
            <AvatarDescription />
          </>
        )}
        {hasSelectedLinkStaffMemberOption && (
          <div>
            <p>Taken from Linked Moss Staff Member</p>
            <img src={oFetch(selectedLinkableMossStaffMemberOption, 'avatarUrl')} />
          </div>
        )}
        <Field
          label={c.BASIC_FORM_FIELDS_TITLES[c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME]}
          name={c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME}
          component={BossFormInput}
          disabled={siaBadgeValidating}
        />
        <div className="boss-form__group boss-form__group_role_board">
          <h3 className="boss-form__group-title">Contact Details</h3>
          <Field
            label={c.BASIC_FORM_FIELDS_TITLES[c.EMAIL_FIELD_NAME]}
            type="email"
            name={c.EMAIL_FIELD_NAME}
            component={BossFormInput}
            disabled={siaBadgeValidating}
          />
          <Field
            label={c.BASIC_FORM_FIELDS_TITLES[c.PHONE_NUMBER_FIELD_NAME]}
            name={c.PHONE_NUMBER_FIELD_NAME}
            component={BossFormInput}
          />
          <h4 className="boss-form__group-subtitle">Address</h4>
          <div className="boss-form__group boss-form__group_role_section">
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.ADDRESS_FIELD_NAME]}
              name={c.ADDRESS_FIELD_NAME}
              component={BossFormInput}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.COUNTY_FIELD_NAME]}
              name={c.COUNTY_FIELD_NAME}
              component={BossFormInput}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.COUNTRY_FIELD_NAME]}
              name={c.COUNTRY_FIELD_NAME}
              component={BossFormInput}
            />
            <Field
              label={c.BASIC_FORM_FIELDS_TITLES[c.POST_CODE_FIELD_NAME]}
              name={c.POST_CODE_FIELD_NAME}
              component={BossFormInput}
            />
          </div>
        </div>

        <div className="boss-form__field boss-form__field_role_controls">
          <button
            onClick={handleSubmit(handleValidation)}
            type="button"
            disabled={!valid || submitting || siaBadgeValidating}
            className="boss-button boss-form__submit boss-form__submit_adjust_single"
          >
            {submitting ? `Processing ...` : `Continue`}
          </button>
        </div>
      </div>
    </div>
  );
}

const formSelector = formValueSelector(c.FORM_NAME);

const mapStateToProps = state => {
  const selectedMossStaffMemberId = formSelector(state, c.LINK_MOSS_STAFF_MEMBER_FIELD_NAME);
  return {
    validateAvatarForCreateUrl: selectors.validateAvatarForCreateUrlSelector(state),
    siaBadgeNumber: selectors.siaBadgeNumberSelector(state),
    checkSiaNumber: selectors.checkSiaNumberSelector(state),
    canBypassSiaValidation: selectors.canBypassSiaValidationSelector(state),
    siaBadgeCheckGUID: selectors.siaBadgeCheckGUIDSelector(state),
    selectedMossStaffMemberId,
  };
};

export default reduxForm({
  form: c.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: basicInformationFormValidators,
  onChange(values, dispatch, props, previousValues) {
    if (Object.keys(previousValues).length === 0) {
      return;
    }
    const [isSecurity, securityStaffTypesIds] = oFetch(props, 'isSecurity', 'securityStaffTypesIds');
    const previousStaffType = oFetch(previousValues, c.STAFF_TYPE_FIELD_NAME);
    const previousStaffTypeIsSecurity = securityStaffTypesIds.includes(previousStaffType);
    const stewardValue = oFetch(values, c.STEWARD_FIELD_NAME);
    const oldStewardValue = oFetch(previousValues, c.STEWARD_FIELD_NAME);
    if ((previousStaffTypeIsSecurity && !isSecurity) || (!previousStaffTypeIsSecurity && isSecurity)) {
      dispatch(change(c.FORM_NAME, c.SIA_GUID_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SIA_BADGE_NUMBER_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.FIRST_NAME_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SURNAME_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME, null));
    }
    if (oldStewardValue !== stewardValue) {
      dispatch(change(c.FORM_NAME, c.SIA_GUID_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SIA_BADGE_NUMBER_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.FIRST_NAME_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SURNAME_FIELD_NAME, null));
      dispatch(change(c.FORM_NAME, c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME, null));
    }
    if (isSecurity) {
      dispatch(change(c.FORM_NAME, c.ACCESSORIES_FIELD_NAME, {}));
    }
  },
  shouldAsyncValidate({ asyncErrors, initialized, trigger, blurredField, pristine, syncValidationPasses }) {
    if (!syncValidationPasses) {
      return false;
    }
    switch (trigger) {
      case 'blur':
      case 'change':
        return true;
      case 'submit':
        return false;
      default:
        return false;
    }
  },
})(connect(mapStateToProps)(BasicInformationForm));
