import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Spinner from '@/components/spinner';

import Dashboard from '../components/dashboard';
import MainContent from '../components/main-content';
import SubmissionsList from '../components/submissions-list';
import SubmissionsListMobile from '../components/submissions-list.mobile';
import BossDetailsModal from '@/components/boss-details-modal';
import ModalDetailsContent from '../components/modal-details-content';
import Pagination from '../components/pagination';
import { openDetailsModal, closeDetailsModal } from '../actions/details-modal-actions.js';
import { changeVenue } from '../actions/venue-actions';
import {
  toggleFilter,
  setFilterDateRange,
  search,
  setFilterCreatetBy,
  setFilterSubmissionStatus,
  setFilterSearchValueAction,
} from '../actions/filter-actions';
import { changePage } from '../actions/pagination-actions';
import {
  makeSelectIsDetailsOpen,
  makeSelectIsFilterOpen,
  makeSelectDetailedSubmission,
  getSubmissions,
  makeSelectPageCount,
  makeSelectCurrentVenue,
  makeSelectVenues,
  makeSelectStartDate,
  makeSelectEndDate,
  makeSelectCurrentPage,
  makeSelectCreatedBy,
  makeSelectStatus,
  creatorsSelector,
  searchValueSelector,
} from '../selectors';

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        openDetailsModal,
        closeDetailsModal,
        changePage,
        toggleFilter,
        changeVenue,
        setFilterCreatetBy,
        setFilterSubmissionStatus,
        setFilterDateRange,
        search,
        setFilterSearchValue: setFilterSearchValueAction,
      },
      dispatch,
    ),
  };
};

function Submissions(props) {
  const [fetching, setFetching] = useState(false);

  const [isDetailsOpen, detailedSubmission, submissions, pageCount, currentPage] = oFetch(
    props,
    'isDetailsOpen',
    'detailedSubmission',
    'submissions',
    'pageCount',
    'currentPage',
  );
  const [openDetailsModal, closeDetailsModal, changePage, search, setFilterSearchValue] = oFetch(
    props,
    'openDetailsModal',
    'closeDetailsModal',
    'changePage',
    'search',
    'setFilterSearchValue',
  );

  async function handleChangePage(page) {
    setFetching(true);
    await changePage(page);
    setFetching(false);
  }

  async function handleSearch(page) {
    setFetching(true);
    await search(page);
    setFetching(false);
  }

  const contentClassNames = cn('boss-page-main__group boss-page-main__group_adjust_checklist-table', {
    spinning: fetching,
  });

  return (
    <div>
      {isDetailsOpen && (
        <BossDetailsModal
          isOpen={true}
          onCloseClick={closeDetailsModal}
          className="boss-modal-window boss-modal-window_role_details"
        >
          <ModalDetailsContent submission={detailedSubmission} />
        </BossDetailsModal>
      )}
      <Dashboard
        {...props}
        onSearchValueChange={setFilterSearchValue}
        onSearch={handleSearch}
        title="Check List Submissions"
      />
      <MainContent>
        {fetching && (
          <Spinner
            style={{
              top: 'calc(50% - 20px)',
              left: 'calc(50% - 20px)',
              position: 'absolute',
            }}
          />
        )}
        <div className={contentClassNames}>
          <SubmissionsList onDetailsClick={openDetailsModal} items={submissions} />
          <SubmissionsListMobile onDetailsClick={openDetailsModal} items={submissions} />
        </div>
        {pageCount > 1 && (
          <Pagination
            pageCount={pageCount}
            fetching={fetching}
            initialPage={currentPage}
            onPageChange={handleChangePage}
          />
        )}
      </MainContent>
    </div>
  );
}

export default connect(
  createStructuredSelector({
    isDetailsOpen: makeSelectIsDetailsOpen(),
    isFilterOpen: makeSelectIsFilterOpen(),
    detailedSubmission: makeSelectDetailedSubmission(),
    submissions: getSubmissions,
    creators: creatorsSelector,
    pageCount: makeSelectPageCount(),
    venues: makeSelectVenues(),
    currentVenue: makeSelectCurrentVenue(),
    startDate: makeSelectStartDate(),
    endDate: makeSelectEndDate(),
    currentPage: makeSelectCurrentPage(),
    createdBy: makeSelectCreatedBy(),
    status: makeSelectStatus(),
    searchValue: searchValueSelector,
  }),
  mapDispatchToProps,
)(Submissions);
