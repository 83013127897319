import React, { Component } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import Modal from 'react-modal';
import cn from 'classnames';
import ReactErrorsBoundaryWrapper from '@/lib/react-errors-boundary-wrapper';
import utils from '@/lib/utils';

class CustomContentModal extends React.Component {
  render() {
    const [title, onClose, modalClass, children] = oFetch(
      this.props,
      'title',
      'onClose',
      'modalClass',
      'children',
    );
    const { titleClass, innerClose } = this.props;
    const modalClassNames = cn(
      'ReactModal__Content ReactModal__Content--after-open boss-modal-window',
      {
        [modalClass]: true,
      },
    );
    const titleClassNames = cn('boss-modal-window__header', { [titleClass]: !!titleClass });
    const closeClassNames = cn({
      'boss-modal-window__close': !innerClose,
      'boss-modal-window__close-inner': !!innerClose,
    });

    function renderTitle(title) {
      if (utils.isFunction(title)) {
        return title();
      }
      return <div className={titleClassNames}>{title}</div>;
    }

    return (
      <Modal
        isOpen={true}
        contentLabel="Modal"
        ariaHideApp={false}
        className={modalClassNames}
      >
        <button
          onClick={onClose}
          className={closeClassNames}
        />
        {renderTitle(title)}
        {children}
      </Modal>
    );
  }
}

class ModalProvider extends Component {
  state = {
    modalData: null,
  };

  getChildContext() {
    return {
      openModal: this.openModal,
    };
  }

  openModal = ({ onSubmit = () => { }, config = {}, props, closeCallback = () => { } }) => {
    return Component => {
      return new Promise((resolve, reject) => {
        this.setState({
          modalData: {
            onSubmit: this.handleSubmit(onSubmit, resolve, reject),
            config,
            props,
            Component,
            onClose: this.handleClose(resolve),
          },
        });
      });
    };
  };

  handleClose = resolve => {
    return () => {
      this.setState({ modalData: null });
      resolve();
    };
  };

  handleSubmit = (submitFn, resolve, reject) => {
    return (...args) => {
      return submitFn(() => this.setState({ modalData: null }), ...args).then(() => {
        resolve();
      });
    };
  };

  renderModal = modalData => {
    const [Component, config, onSubmit, props, onClose] = oFetch(
      modalData,
      'Component',
      'config',
      'onSubmit',
      'props',
      'onClose',
    );
    const { title, innerClose, modalClass, titleClass } = config;

    return (
      <ReactErrorsBoundaryWrapper>
        <CustomContentModal
          title={title}
          innerClose={innerClose}
          onClose={onClose}
          modalClass={modalClass}
          titleClass={titleClass}
        >
          <Component
            onSubmit={onSubmit}
            onClose={onClose}
            {...props}
          />
        </CustomContentModal>
      </ReactErrorsBoundaryWrapper>
    );
  };

  render() {
    const children = oFetch(this.props, 'children');
    const modalData = oFetch(this.state, 'modalData');

    return (
      <>
        {modalData && this.renderModal(modalData)}
        {children}
      </>
    );
  }
}

ModalProvider.childContextTypes = {
  openModal: PropTypes.func,
};

export default ModalProvider;
