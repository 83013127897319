import { fromJS, Map, List } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import { reducer as formReducer } from 'redux-form/immutable';
import safeMoment from '@/lib/safe-moment';

import {
  INITIAL_LOAD,
  ADD_NEW_HOLIDAY,
  CANCEL_ADD_NEW_HOLIDAY,
  DELETE_HOLIDAY,
  ADD_HOLIDAY_SUCCESS,
  CLOSE_HOLIDAY_MODAL,
  OPEN_EDIT_HOLIDAY_MODAL,
  CLOSE_EDIT_HOLIDAY_MODAL,
  EDIT_HOLIDAY_SUCCESS,
  FILTER,
  DELETE_HOLIDAY_REQUEST,
  EDIT_HOLIDAY_REQUEST_SUCCESS,
  ADD_HOLIDAY_REQUEST_SUCCESS,
  UPDATE_TAX_YEAR_INFO,
  REQUEST_TAX_YEAR_INFO,
} from './constants';

const initialHolidaysReducerState = fromJS({
  effectiveStaffMember: {},
  accessToken: null,
  holidays: [],
  holidayRequests: [],
  holidayStartDate: null,
  holidayEndDate: null,
  newHoliday: false,
  editHoliday: false,
  editedHoliday: {},
  isAdminPlus: null,
  startPayslipDate: null,
  endPayslipDate: null,
  permissionsData: {},
});

const holidaysReducer = handleActions(
  {
    [INITIAL_LOAD]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const [
        effectiveStaffMember,
        accessToken,
        holidays,
        holidayRequests,
        holidayStartDate,
        holidayEndDate,
        isAdminPlus,
        startPayslipDate,
        endPayslipDate,
      ] = oFetch(
        payload,
        "effectiveStaffMember",
        "accessToken",
        "holidays",
        "holidayRequests",
        "holidayStartDate",
        "holidayEndDate",
        "isAdminPlus",
        "startPayslipDate",
        "endPayslipDate",
      );
      window.boss.accessToken = accessToken;
      const permissionsData = oFetch(payload, 'permissionsData');

      return state
        .set('effectiveStaffMember', fromJS(effectiveStaffMember))
        .set('accessToken', fromJS(accessToken))
        .set('holidays', fromJS(holidays))
        .set('holidayRequests', fromJS(holidayRequests))
        .set('holidayStartDate', holidayStartDate ? safeMoment.uiDateParse(holidayStartDate) : null)
        .set('holidayEndDate', holidayEndDate ? safeMoment.uiDateParse(holidayEndDate) : null)
        .set('isAdminPlus', isAdminPlus)
        .set('permissionsData', fromJS(permissionsData))
        .set('startPayslipDate', startPayslipDate ? safeMoment.uiDateParse(startPayslipDate) : null)
        .set('endPayslipDate', endPayslipDate ? safeMoment.uiDateParse(endPayslipDate) : null);
    },
    [ADD_NEW_HOLIDAY]: state => {
      return state.set('newHoliday', true);
    },
    [CANCEL_ADD_NEW_HOLIDAY]: state => {
      return state.set('newHoliday', false);
    },
    [DELETE_HOLIDAY]: (state, action) => {
      const id = oFetch(action, 'payload.id');

      return state.update('holidays', holidays => holidays.filter(holiday => oFetch(holiday.toJS(), 'id') !== id));
    },
    [DELETE_HOLIDAY_REQUEST]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const holidayRequestId = oFetch(payload, 'holidayRequestId');

      return state.update('holidayRequests', holidayRequests =>
        holidayRequests.filter(item => oFetch(item.toJS(), 'id') !== holidayRequestId),
      );
    },
    [EDIT_HOLIDAY_SUCCESS]: (state, action) => {
      const payloadJS = oFetch(action, 'payload');
      const holidayJS = oFetch(payloadJS, 'holiday');
      const holidayId = oFetch(holidayJS, 'id');
      const permissions = oFetch(payloadJS, 'permissions');
      const index = state.get('holidays').findIndex(item => oFetch(item.toJS(), 'id') === holidayId);

      return state
        .setIn(['holidays', index], fromJS(holidayJS))
        .updateIn(['permissionsData', 'holidaysTab', 'holidays'], holidayRequestsPermissions =>
          holidayRequestsPermissions.set(holidayId, fromJS(permissions)),
        );
    },
    [EDIT_HOLIDAY_REQUEST_SUCCESS]: (state, action) => {
      const payloadJS = action.payload;
      const editedHolidayRequestJS = oFetch(payloadJS, 'holidayRequest');
      const newHolidayRequestPermissions = oFetch(payloadJS, 'permissions');
      const holidayRequestId = oFetch(editedHolidayRequestJS, 'id');
      const index = state
        .get('holidayRequests')
        .findIndex(item => item.get('id') === holidayRequestId);

      return state
        .setIn(['holidayRequests', index], fromJS(editedHolidayRequestJS))
        .updateIn(
          ['permissionsData', 'holidaysTab', 'holidayRequests'],
          holidayRequestsPermissions =>
            holidayRequestsPermissions.set(holidayRequestId, fromJS(newHolidayRequestPermissions)),
        );
    },
    [ADD_HOLIDAY_SUCCESS]: (state, action) => {
      const newHoliday = oFetch(action, 'payload.newHoliday');
      const newPermissions = oFetch(action, 'payload.newPermissions');
      const newHolidayId = oFetch(newHoliday, 'id');

      return state
        .update('holidays', holidays => holidays.push(fromJS(newHoliday)))
        .updateIn(['permissionsData', 'holidaysTab', 'holidays'], holidayRequestsPermissions =>
          holidayRequestsPermissions.set(newHolidayId, fromJS(newPermissions)),
        );
    },
    [ADD_HOLIDAY_REQUEST_SUCCESS]: (state, action) => {
      const newHolidayRequest = oFetch(action, 'payload.newHolidayRequest');
      const newPermissions = oFetch(action, 'payload.newPermissions');
      const newHolidayRequestId = oFetch(newHolidayRequest, 'id');

      return state
        .update('holidayRequests', holidays => holidays.push(fromJS(newHolidayRequest)))
        .updateIn(
          ['permissionsData', 'holidaysTab', 'holidayRequests'],
          holidayRequestsPermissions =>
            holidayRequestsPermissions.set(newHolidayRequestId, fromJS(newPermissions)),
        );
    },
    [CLOSE_HOLIDAY_MODAL]: state => {
      return state.set('newHoliday', false);
    },
    [OPEN_EDIT_HOLIDAY_MODAL]: (state, action) => {
      return state.set('editHoliday', true).set('editedHoliday', fromJS(action.payload));
    },
    [CLOSE_EDIT_HOLIDAY_MODAL]: state => {
      return state.set('editHoliday', false).set('editedHoliday', fromJS({}));
    },
    [CLOSE_HOLIDAY_MODAL]: state => {
      return state.set('newHoliday', false);
    },
    [FILTER]: (state, action) => {
      const {
        holidays,
        holiday_start_date,
        holiday_end_date,
        start_payslip_date,
        end_payslip_date,
        holiday_requests,
        permissions_data,
      } = action.payload;

      return state
        .set('holidays', fromJS(holidays))
        .set('holidayRequests', fromJS(holiday_requests))
        .set(
          'holidayStartDate',
          holiday_start_date ? safeMoment.uiDateParse(holiday_start_date) : null,
        )
        .set('holidayEndDate', holiday_end_date ? safeMoment.uiDateParse(holiday_end_date) : null)
        .set(
          'startPayslipDate',
          start_payslip_date ? safeMoment.uiDateParse(start_payslip_date) : null,
        )
        .set('endPayslipDate', end_payslip_date ? safeMoment.uiDateParse(end_payslip_date) : null)
        .set('permissionsData', fromJS(permissions_data));
    },
  },
  initialHolidaysReducerState,
);

const initialTaxYearReducerState = fromJS({
  selectedTaxYear: null,
});

const taxYearReducer = handleActions(
  {
    [INITIAL_LOAD]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const selectedTaxYear = oFetch(payload, 'selectedTaxYear');
      return state.set('selectedTaxYear', selectedTaxYear);
    },
    [UPDATE_TAX_YEAR_INFO]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const selectedTaxYear = oFetch(payload, 'taxYear');
      return state.set('selectedTaxYear', selectedTaxYear);
    },
  },
  initialTaxYearReducerState,
);

const initialPageStateReducerState = fromJS({
  taxYearFormRequesting: false,
});

const pageStateReducer = handleActions(
  {
    [REQUEST_TAX_YEAR_INFO]: (state) => {
      return state.set('taxYearFormRequesting', true);
    },
    [UPDATE_TAX_YEAR_INFO]: (state) => {
      return state.set('taxYearFormRequesting', false);
    }
  },
  initialPageStateReducerState,
);

export {
  holidaysReducer,
  taxYearReducer,
  pageStateReducer,
};