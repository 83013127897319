export default {
  INITIAL_LOAD: 'INITIAL_LOAD',
  TABLE_COLUMNS: ['Dates', 'Created By', 'Title', 'Status', 'Action'],
  CREATE_DASHBOARD_MESSAGE: 'CREATE_DASHBOARD_MESSAGE',
  UPDATE_DASHBOARD_MESSAGE: 'UPDATE_DASHBOARD_MESSAGE',
  SET_FRONTEND_STATE: 'SET_FRONTEND_STATE',
  SET_SELECTED_MESSAGE: 'SET_SELECTED_MESSAGE',
  GET_DASHBOARD_MESSAGE_REQUEST: 'GET_DASHBOARD_MESSAGE_REQUEST',
  GET_DASHBOARD_MESSAGE_RECEIVE: 'GET_DASHBOARD_MESSAGE_RECEIVE',
  GET_DASHBOARD_MESSAGE_FAILURE: 'GET_DASHBOARD_MESSAGE_FAILURE',
  DISABLE_DASHBOARD_MESSAGE_REQUEST: 'DISABLE_DASHBOARD_MESSAGE_REQUEST',
  DISABLE_DASHBOARD_MESSAGE_RECEIVE: 'DISABLE_DASHBOARD_MESSAGE_RECEIVE',
  DISABLE_DASHBOARD_MESSAGE_FAILURE: 'DISABLE_DASHBOARD_MESSAGE_FAILURE',
  RESTORE_DASHBOARD_MESSAGE_REQUEST: 'RESTORE_DASHBOARD_MESSAGE_REQUEST',
  RESTORE_DASHBOARD_MESSAGE_RECEIVE: 'RESTORE_DASHBOARD_MESSAGE_RECEIVE',
  RESTORE_DASHBOARD_MESSAGE_FAILURE: 'RESTORE_DASHBOARD_MESSAGE_FAILURE',
};
