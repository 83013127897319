import React, { useState } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import AsyncButton from 'react-async-button';
import Spinner from '@/components/spinner';
import { stringToHtml } from '@/utils/string-to-html';

import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { RequestItemServiceButtons } from './request-item-service-buttons';

import { Attachment } from '../../attachment';
import { useMarketingTaskModals } from '../../../marketing-task-modals';
import * as actions from '../../../redux/actions';
import { ConfirmButtons } from '../../confirm-buttons';

export function RequestItem(props) {
  const {
    openAddAnswerModal,
    openRequestHistoryModal,
    openUpdateRequestModal,
    openRequestClarificationModal,
  } = useMarketingTaskModals();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [request, isAcceptedTask] = oFetch(props, 'request', 'isAcceptedTask');
  const [
    id,
    marketingTaskId,
    currentQuestion,
    creatorFullName,
    createdAt,
    isRequiringAnswer,
    isRequiringReview,
    isAccepted,
    permissions,
  ] = oFetch(
    request,
    'id',
    'marketingTaskId',
    'currentQuestion',
    'creatorFullName',
    'createdAt',
    'isRequiringAnswer',
    'isRequiringReview',
    'isAccepted',
    'permissions',
  );
  const [text, questionAttachments, currentAnswer] = oFetch(
    currentQuestion,
    'text',
    'attachments',
    'currentAnswer',
  );

  const [
    canCreateRequestClarification,
    canUpdateRequestQuestion,
    canAccept,
    canCreateRequestQuestionAnswer,
    canDisable,
  ] = oFetch(
    permissions,
    'canCreateRequestClarification',
    'canUpdateRequestQuestion',
    'canAccept',
    'canCreateRequestQuestionAnswer',
    'canDisable',
  );

  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);
  const hasAttachments = questionAttachments.length > 0;
  const hasAnswer = currentAnswer !== null;
  const requestContainerClassNames = cn('boss-task__request', {
    'boss-task__request_state_requiring-answer': isRequiringAnswer,
    'boss-task__request_state_requiring-review': isRequiringReview,
  });

  function handleAcceptRequest() {
    return dispatch(
      actions.acceptMarketingTaskRequest({
        values: {
          taskId: marketingTaskId,
          requestId: id,
        },
      }),
    );
  }

  async function handleRemoveClick() {
    setProcessing(true);
    await dispatch(actions.removeMarketingTaskRequest({ taskId: marketingTaskId, requestId: id }));
    setProcessing(false);
  }

  function renderAttachments(attachments) {
    return attachments.map(attachment => {
      const attachmentId = oFetch(attachment, 'id');
      return <Attachment key={attachmentId} attachment={attachment} />;
    });
  }

  function renderAnswer(currentAnswer) {
    const [text, attachments, creatorFullName, createdAt] = oFetch(
      currentAnswer,
      'text',
      'attachments',
      'creatorFullName',
      'createdAt',
    );
    const hasAttachments = attachments.length > 0;
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);
    return (
      <div className="boss-task__request-group boss-task__request-group_role_answer">
        <div className="boss-task__request-indicator">A</div>
        <div className="boss-task__request-info">
          <p
            className="boss-task__request-text boss-task__request-text_role_primary"
            style={{ whiteSpace: 'pre-line' }}
          >
            {text}
          </p>
          {hasAttachments && (
            <div className="boss-task__request-attachments">{renderAttachments(attachments)}</div>
          )}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
    );
  }

  function renderClarification(currentAnswer) {
    const currentClarification = oFetch(currentAnswer, 'currentClarification');
    if (!currentClarification) {
      return null;
    }

    const [text, attachments, creatorFullName, createdAt] = oFetch(
      currentClarification,
      'text',
      'attachments',
      'creatorFullName',
      'createdAt',
    );
    const hasAttachments = attachments.length > 0;
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.fullDayTimeFormat);

    return (
      <div className="boss-task__request-group boss-task__request-group_role_clarification">
        <div className="boss-task__request-info">
          <p className="boss-task__request-text boss-task__request-text_role_secondary">
            User left the following clarification:
          </p>
          <p className="boss-task__request-text boss-task__request-text_role_primary">{text}</p>
          {hasAttachments && renderAttachments(attachments)}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
    );
  }

  function renderConfirmation() {
    if (isRequiringAnswer && canCreateRequestQuestionAnswer) {
      return (
        <div className="boss-task__request-group boss-task__request-group_role_confirmation">
          <p className="boss-task__request-label">This question requires an answer!</p>
          <div className="boss-task__request-actions">
            <button
              onClick={() => openAddAnswerModal(request)}
              className="boss-button boss-button_type_small boss-task__request-action"
            >
              Answer Now
            </button>
          </div>
        </div>
      );
    }
    if (canAccept || canCreateRequestClarification) {
      return (
        <div className="boss-task__request-group boss-task__request-group_role_confirmation">
          <ConfirmButtons
            text={`Did this answer the question?`}
            onYesClick={handleAcceptRequest}
            onNoClick={() => openRequestClarificationModal(request)}
            canNoClick={canCreateRequestClarification}
            canYesClick={canAccept}
            yesText="Yes"
            noText="No"
          />
        </div>
      );
    }
    return null;
  }
  return (
    <div className={requestContainerClassNames}>
      {!processing && (
        <RequestItemServiceButtons
          isAccepted={isAccepted}
          onEditClick={() => openUpdateRequestModal(request)}
          onHistoryClick={() => openRequestHistoryModal(request)}
          onRemoveClick={handleRemoveClick}
          isRemoved={false}
          isAcceptedTask={isAcceptedTask}
          canEdit={canUpdateRequestQuestion}
          canRemove={canDisable}
        />
      )}
      {processing && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Spinner style={{ width: '25px', height: '25px', margin: '10px' }} />
        </div>
      )}
      <div className="boss-task__request-group boss-task__request-group_role_question">
        <div className="boss-task__request-indicator">Q</div>
        <div className="boss-task__request-info">
          <p
            className="boss-task__request-text boss-task__request-text_role_primary"
            style={{ whiteSpace: 'pre-line' }}
          >
            {text}
          </p>
          {hasAttachments && (
            <div className="boss-task__request-attachments">{renderAttachments(questionAttachments)}</div>
          )}
          <p className="boss-task__request-text boss-task__request-text_role_meta">
            {creatorFullName} @ {formattedCreatedAt}
          </p>
        </div>
      </div>
      {hasAnswer && renderAnswer(currentAnswer)}
      {hasAnswer && renderClarification(currentAnswer)}
      {!isAccepted && !isAcceptedTask && renderConfirmation()}
    </div>
  );
}
