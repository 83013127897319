import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import { SiteIssueForm } from './site-issue-form';

export function CreateSiteIssueModalContent(props) {
  const onSubmit = oFetch(props, 'onSubmit');

  const initialValues = {
    description: '',
    date: null,
    level: null,
  };

  return <SiteIssueForm onSubmit={onSubmit} initialValues={initialValues} buttonText="Create New Issue" />;
}
