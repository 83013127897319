import oFetch from "o-fetch";

type MossHourTag = {
  isDisabled: boolean,
  id: number,
  name: string,
};

type GetExistingMossHourTagOptionsArgs = {
  mossHourTags: MossHourTag[],
  currentMossHourTagIds: number[],
};

type MossHourTagOption = {
  id: number,
  name: string,
};

// js version of code that uses oFetch use getExistingMossHourTagOptions from typescript code instead of this
export function nonTsGetExistingMossHourTagOptions(args: any): unknown {
  const mossHourTags = oFetch(args, 'mossHourTags');
  const currentMossHourTagIds = oFetch(args, 'currentMossHourTagIds');

  return mossHourTags.reduce((acc: any, mossHourTag: any) => {
    if (oFetch(mossHourTag, 'isDisabled') === false) {
      acc.push({
        id: oFetch(mossHourTag, 'id'),
        name: oFetch(mossHourTag, 'name'),
      });
    } else if (currentMossHourTagIds.includes(oFetch(mossHourTag, 'id')) === true) {
      acc.push({
        id: oFetch(mossHourTag, 'id'),
        name: `${oFetch(mossHourTag, 'name')} (Disabled)`,
      });
    }
    return acc;
  }, []);
}

// Version of getHourTags relies on typescript type safety instead of oFetch, don't use from js code
export function getExistingMossHourTagOptions(args: GetExistingMossHourTagOptionsArgs): MossHourTagOption[] {
  const mossHourTags = args.mossHourTags;
  const currentMossHourTagIds = args.currentMossHourTagIds;

  return mossHourTags.reduce((acc, mossHourTag) => {
    if (mossHourTag.isDisabled === false) {
      acc.push({
        id: mossHourTag.id,
        name: mossHourTag.name,
      });
    } else if (currentMossHourTagIds.includes(mossHourTag.id) === true) {
      acc.push({
        id: mossHourTag.id,
        name: `${mossHourTag.name} (Disabled)`,
      });
    }
    return acc;
  }, [] as MossHourTagOption[]);
}