import React from 'react';
import oFetch from 'o-fetch';

export default function ContentWrapper(props) {
  const children = oFetch(props, 'children');
  return (
    <div className="purple-page-main__content">
      <div className="purple-page-main__inner">{children}</div>
    </div>
  );
}
