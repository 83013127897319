import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import URLSearchParams from 'url-search-params';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';

import reducers from './redux/reducers';
import { loadInitialData, setInitialFilters } from './redux/actions';
import { changeQueryString } from './helpers';
import { STATUSES_FILTER } from './constants';
import Page from './containers/page';

export function InvitesApp(props) {
  const [store, setStore] = useState(null);
  const [permittedRolesOptions, allRolesOptions, allRoles, venuesAccess, venues] = oFetch(
    props,
    'permittedRolesOptions',
    'allRolesOptions',
    'allRoles',
    'venuesAccess',
    'venues',
  );
  const allRolesWithAnyOptions = [{ value: 'any', label: 'Any' }, ...allRolesOptions];
  const { accessToken } = props;
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  useEffect(() => {
    window.boss.accessToken = accessToken;
    const queryString = new URLSearchParams(window.location.search);

    const statusFromQuery = queryString.get('status') || 'open';
    const roleFromQuery = queryString.get('role') || 'any';
    const status = STATUSES_FILTER.includes(statusFromQuery) ? statusFromQuery : 'any';
    const role = allRoles.includes(roleFromQuery) ? roleFromQuery : 'any';
    if (status !== statusFromQuery || role !== roleFromQuery) {
      changeQueryString({ status, role });
    }
    const store = configureStore(reducers);
    store.dispatch(setInitialFilters({ status, role }));
    store.dispatch(loadInitialData(props));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }
  return (
    <Provider store={store}>
      <LegacyGlobalProvider global={{ permittedRolesOptions, allRolesWithAnyOptions, venuesAccess }}>
        <ModalsProvider>
          <Page />
        </ModalsProvider>
      </LegacyGlobalProvider>
    </Provider>
  );
}
