import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';

import { NativeAppLinkListItem, NativeAppLinkList } from './components';

export default function NativeAppLinks(props) {
  const nativeApps = oFetch(props, 'nativeApps');

  function renderNativeAppLinkItem(item) {
    return <NativeAppLinkListItem nativeAppLink={item} />;
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Native App Links</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <NativeAppLinkList items={nativeApps} itemRenderer={renderNativeAppLinkItem} />
      </ContentWrapper>
    </Fragment>
  );
}
