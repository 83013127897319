import React from 'react';
import oFetch from 'o-fetch';
import DayCountingForm from './day-counting-form';

export default function DayCounting(props) {
  const [selectedTab, onSubmit, dayCount, editMode] = oFetch(props, 'selectedTab', 'onSubmit', 'dayCount', 'editMode');
  const dayCountExist = dayCount !== null;
  const editable = editMode || !dayCountExist;

  function getDayCountLastEventData() {
    if (!dayCountExist) {
      return null;
    }
    return oFetch(dayCount, 'lastEventData');
  }

  function getInitialValues() {
    if (!dayCountExist) {
      return {
        date: selectedTab,
        fiftyNotesTotalPounds: 0,
        foreignFiftyNotesTotalPounds: 0,
        twentyNotesTotalPounds: 0,
        foreignTwentyNotesTotalPounds: 0,
        tenNotesTotalPounds: 0,
        foreignTenNotesTotalPounds: 0,
        fiveNotesTotalPounds: 0,
        foreignFiveNotesTotalPounds: 0,
        changeTotalPence: 0,
        foreignChangeTotalPence: 0,
      };
    }
    return {
      id: oFetch(dayCount, 'id'),
      date: oFetch(dayCount, 'date'),
      fiftyNotesTotalPounds: oFetch(dayCount, 'fiftyNotesTotalPounds'),
      foreignFiftyNotesTotalPounds: oFetch(dayCount, 'foreignFiftyNotesTotalPounds'),
      twentyNotesTotalPounds: oFetch(dayCount, 'twentyNotesTotalPounds'),
      foreignTwentyNotesTotalPounds: oFetch(dayCount, 'foreignTwentyNotesTotalPounds'),
      tenNotesTotalPounds: oFetch(dayCount, 'tenNotesTotalPounds'),
      foreignTenNotesTotalPounds: oFetch(dayCount, 'foreignTenNotesTotalPounds'),
      fiveNotesTotalPounds: oFetch(dayCount, 'fiveNotesTotalPounds'),
      foreignFiveNotesTotalPounds: oFetch(dayCount, 'foreignFiveNotesTotalPounds'),
      changeTotalPence: oFetch(dayCount, 'changeTotalPence'),
      foreignChangeTotalPence: oFetch(dayCount, 'foreignChangeTotalPence'),
    };
  }
  const initialValues = getInitialValues();

  return (
    <DayCountingForm
      lastEventData={getDayCountLastEventData()}
      onSubmit={onSubmit}
      editable={editable}
      initialValues={initialValues}
      form={`${selectedTab}-counting-form`}
    />
  );
}
