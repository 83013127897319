import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import utils, { objectToOptions } from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export const questionActiveStateSelector = state => oFetch(state, 'static.questionActiveState');
export const questionDisabledStateSelector = state => oFetch(state, 'static.questionDisabledState');
export const singleTextTypeSelector = state => oFetch(state, 'static.singleTextType');
export const multiTextTypeSelector = state => oFetch(state, 'static.multiTextType');
export const singleImageTypeSelector = state => oFetch(state, 'static.singleImageType');
export const multiImageTypeSelector = state => oFetch(state, 'static.multiImageType');
export const quizCategoryQuestionTagsSelector = state => oFetch(state, 'static.quizCategoryQuestionTags');
export const questionsTagsSelector = state => oFetch(state, 'questionsTags');

export const getQuestionTypes = createSelector(
  [singleTextTypeSelector, multiTextTypeSelector, singleImageTypeSelector, multiImageTypeSelector],
  (singleTextType, multiTextType, singleImageType, multiImageType) => {
    return {
      singleTextType,
      multiTextType,
      singleImageType,
      multiImageType,
    };
  },
);

export const categorySelector = state => oFetch(state, 'category');

export const otherQuizCategoriesSelector = state => oFetch(state, 'otherQuizCategories');

export const questionsSelector = state => oFetch(state, 'questions');

export const answersSelector = state => oFetch(state, 'answers');

export const questionTypesSelector = state => oFetch(state, 'static.questionTypes');

export const getQuestionTypesOptions = createSelector([questionTypesSelector], questionTypes => {
  return objectToOptions(questionTypes);
});

export const getPermissions = state => oFetch(state, 'static.permissions');

export const getCategory = createSelector([categorySelector, questionsSelector], (category, questions) => {
  return {
    ...category,
    questionsCount: questions.length,
  };
});

function getQuestionTags(tagsIds, tags) {
  return tagsIds.map(tagId => {
    const tag = tags.find(tag => oFetch(tag, 'id') === tagId);
    if (!tag) {
      throw Error('Tag must be exist');
    }
    return tag;
  });
}

export const getQuestions = createSelector(
  [
    questionsSelector,
    answersSelector,
    singleTextTypeSelector,
    questionActiveStateSelector,
    questionDisabledStateSelector,
    questionsTagsSelector,
    quizCategoryQuestionTagsSelector,
  ],
  (
    questions,
    answers,
    singleType,
    questionActiveState,
    questionDisabledState,
    questionsTags,
    quizCategoryQuestionTags,
  ) => {
    return questions.map(question => {
      const [questionId, type, createdAt, state] = oFetch(question, 'id', 'type', 'createdAt', 'state');
      const questionAnswers = answers.filter(answer => oFetch(answer, 'questionId') === questionId);
      const questionTagsIds = questionsTags[questionId] || [];
      const questionTags = getQuestionTags(questionTagsIds, quizCategoryQuestionTags);
      return {
        ...question,
        tags: questionTags,
        tagsIds: questionTagsIds,
        isDisabled: state === questionDisabledState,
        isActive: state === questionActiveState,
        answers: questionAnswers,
        formattedCreatedAt: safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime()),
        answersDescription: type === singleType ? 'You can tick one answer' : 'You can tick multiple answers',
      };
    });
  },
);
