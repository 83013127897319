import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDiaries, createDiary, enableDiary, disableDiary, updateDiary } from '../reducers/actions';
import OpsDiariesPage from '../components/ops-diaries-page';
import { getInitialFilterData } from '../components/ops-diaries-filter/utils';

const mapStateToProps = state => {
  return {
    users: state.get('users'),
    venues: state.get('venues'),
    diaries: state.get('diaries'),
    isLoaded: state.getIn(['page', 'isLoaded']),
    perPage: state.getIn(['page', 'perPage']),
    totalCount: state.getIn(['page', 'totalCount']),
    page: state.get('page'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getDiaries,
        createDiary,
        enableDiary,
        disableDiary,
        updateDiary,
      },
      dispatch,
    ),
  };
};

function OpsDiaries(props) {
  useEffect(() => {
    props.actions.getDiaries({
      data: getInitialFilterData(),
    });
  }, []);

  if (!props.isLoaded) {
    return <span>Loading .....</span>;
  }
  return <OpsDiariesPage {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(OpsDiaries);
