import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

const accessoryRequestsPermissionsSelector = state =>
  oFetch(state, 'accessoryRequestsPage.permissionsData.accessoryRequests');
const accessoryRefundRequestsPermissionsSelector = state =>
  oFetch(state, 'accessoryRequestsPage.permissionsData.accessoryRefundRequests');

export const getAccessoryRequestPermission = createSelector(
  accessoryRequestsPermissionsSelector,
  accessoryRequestsPermissions => accessoryRequestId => {
    const permission = accessoryRequestsPermissions[accessoryRequestId];
    if (!permission) {
      throw new Error(`Accessory refund request permission for ${accessoryRequestId} should be exist`);
    }
    return permission;
  },
);

export const getAccessoryRefundRequestPermission = createSelector(
  accessoryRefundRequestsPermissionsSelector,
  accessoryRefundRequestsPermissions => accessoryRefundRequestId => {
    const permission = accessoryRefundRequestsPermissions[accessoryRefundRequestId];
    if (!permission) {
      throw new Error(`Accessory refund request permission for ${accessoryRefundRequestId} should be exist`);
    }
    return permission;
  },
);
