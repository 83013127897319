import { createAction } from 'redux-actions';
import * as types from './types';
import { addVenueRequest, editVenueRequest, disableVenueRequest, enableVenueRequest } from '../requests';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const addVenue = createAction(types.ADD_VENUE);
export const updateVenue = createAction(types.UPDATE_VENUE);
export const disableVenue = createAction(types.DISABLE_VENUE)
export const enableVenue = createAction(types.ENABLE_VENUE);

export const addVenueRequested = params => dispatch => {
  return addVenueRequest(params).then(response => dispatch(addVenue(response.data)));
};

export const editVenueRequested = params => dispatch => {
  return editVenueRequest(params).then(response => dispatch(updateVenue(response.data)));
};

export const disableVenueRequested = params => dispatch => {
  return disableVenueRequest(params).then(response => dispatch(disableVenue(response.data)));
}

export const enableVenueRequested = params => dispatch => {
  return enableVenueRequest(params).then(response => dispatch(enableVenue(response.data)))
}
