import React from 'react';
import oFetch from 'o-fetch';
import RotaChart from '@/components/security-rota/rota-chart';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import RotaGraphLegend from '@/components/security-rota/rota-graph-legend';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { BOSS_APP_TYPE } from '../../../../lib/rota-date/constants';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  // mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  eventPassthrough: true,
  click: true,
};

function RotaGraph(props) {
  const venues = oFetch(useLegacyGlobal(), 'venues');
  const appType = oFetch(props, 'appType');

  return (
    <div className="boss-board__graph boss-board__graph_layout_reverse boss-board__graph_role_daily">
      <div className="boss-board__graph-inner">
        <div className="rota-chart">
          <RotaGraphLegend venues={venues} />
          <div className="rota-chart__inner">
            <ReactIScroll iScroll={iScroll} options={scrollOptions}>
              <RotaChart
                appType={appType}
                rotaShifts={props.rotaShifts}
                staff={props.staffMembers}
                onShiftClick={props.onShiftClick}
              />
            </ReactIScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RotaGraph;
