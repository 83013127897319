import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import AsyncButton from 'react-async-button';
import { Tooltip } from 'react-tippy';
import safeMoment from '@/lib/safe-moment';
import { appRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import DashboardWrapper from '@/components/dashboard-wrapper';
import z from 'zod';
import { $BossUiTime } from '@/lib/schema-funcs';
import { format } from 'date-fns';
import { HUMAN_DATE_FORMAT_WITH_TIME } from '@/lib/date-fns-formats';

import { REQUIRING_SYNC_SYNC_STATE, SYNCED_SYNC_STATE, SYNC_FAILED_SYNC_STATE, NOT_SYNCABLE_SYNC_STATE } from './constants';

export function UserProfileDashboard(props) {
  const permissions = oFetch(props, 'permissions');
  const forceSyncInProgress = oFetch(props, 'forceSyncInProgress');
  const jsmSyncEnabled = oFetch(props, 'jsmSyncEnabled');
  const onForceSyncClick = oFetch(props, 'onForceSyncClick');
  const [user, onDisableClick, onEnableClick] = oFetch(props, 'user', 'onDisableClick', 'onEnableClick');
  const isLinked = oFetch(user, 'isLinked');
  const [avatarUrl, fullName, isDisabled, disabledByUser, disableReason, disabledAt] = oFetch(
    user,
    'avatarUrl',
    'fullName',
    'isDisabled',
    'disabledByUser',
    'disableReason',
    'disabledAt',
  );
  const jsmSyncState = oFetch(user, 'jsmSyncState');

  function renderForceSyncButton() {
    const canForceSync = oFetch(permissions, 'canForceSync');
    if (!canForceSync || !isLinked || isDisabled) {
      return null;
    }
    return (
      <div>
        <button
          className='boss-button boss-button_type_ultra-small boss-button_role_exclamation'
          disabled={forceSyncInProgress || !jsmSyncEnabled}
          onClick={onForceSyncClick}
        >Force Sync</button>
        { forceSyncInProgress && <div className="boss-indicator__icon boss-indicator__icon_spinner" /> }
      </div>
    );
  }


  const rawJsmSyncRequestedAt = oFetch(user, 'jsmSyncRequestedAt');
  const dJsmSyncRequestedAt = rawJsmSyncRequestedAt ? $BossUiTime(z).parse(rawJsmSyncRequestedAt) : null;
  const rawLastJsmSyncAttemptAt = oFetch(user, 'lastJsmSyncAttemptAt');
  const dLastJsmSyncAttemptAt = rawLastJsmSyncAttemptAt ? $BossUiTime(z).parse(rawLastJsmSyncAttemptAt) : null;
  const rawLastJsmSyncSuccessAt = oFetch(user, 'lastJsmSyncSuccessAt');
  const dLastJsmSyncSuccessAt = rawLastJsmSyncSuccessAt ? $BossUiTime(z).parse(rawLastJsmSyncSuccessAt) : null;

  function renderSyncState() {
    const showForceSyncButton = jsmSyncState === REQUIRING_SYNC_SYNC_STATE || jsmSyncState === SYNC_FAILED_SYNC_STATE;
    const showRequiringSyncStatus = dJsmSyncRequestedAt != null;
    const isFailedSync = dLastJsmSyncAttemptAt > dLastJsmSyncSuccessAt;
    const syncSuccesStyle = isFailedSync ? { color: 'white', background: '#ed7f7e' } : null;

    const lastSyncSuccessText = dLastJsmSyncSuccessAt ? format(dLastJsmSyncSuccessAt, HUMAN_DATE_FORMAT_WITH_TIME) : 'N/A';
    const lastSyncAttemptText = dLastJsmSyncAttemptAt ? format(dLastJsmSyncAttemptAt, HUMAN_DATE_FORMAT_WITH_TIME) : 'N/A';

    return (
      <div className="boss-user-summary__indicator">
        { showRequiringSyncStatus && (
          <div>
            <span style={{ color: 'white', background: '#ed7f7e' }}>Requiring Sync:</span>
             Since {format(dJsmSyncRequestedAt, HUMAN_DATE_FORMAT_WITH_TIME)}
          </div>
        )}
        <div>Last Jsm Sync Attempt at: {lastSyncAttemptText}</div>
        <div>Last Jsm Sync Success at: <span style={syncSuccesStyle}>{lastSyncSuccessText}</span></div>
        { showForceSyncButton && renderForceSyncButton() }
        { !jsmSyncEnabled && <span>&nbsp;Syncing is currently disabled</span> }
      </div>
    );
  }

  const dashboardClassName = cn(
    'boss-page-dashboard boss-page-dashboard_updated',
    { 'boss-page-dashboard_page_user-profile-disabled': isDisabled },
    { 'boss-page-dashboard_page_user-profile': !isDisabled },
  );

  function renderDisabledEnabledButton() {
    if (isDisabled) {
      if (isLinked) {
        return (
          <Tooltip
            arrow
            theme="dark"
            position="bottom"
            interactive
            html={<span>To enable this user, go to the Staff Member profile and reinvite</span>}
          >
            <button
              onClick={() => {}}
              disabled
              className="boss-button boss-button_role_unblock boss-page-dashboard__button"
            >
              Enable
            </button>
          </Tooltip>
        );
      }
      return (
        <AsyncButton
          className="boss-button boss-button_role_unblock boss-page-dashboard__button"
          text="Enable"
          pendingText="Processing ..."
          onClick={onEnableClick}
        />
      );
    }
    //User is enabled
    return (
      <button
        onClick={onDisableClick}
        className="boss-button boss-button_role_block boss-page-dashboard__button"
      >
        Disable
      </button>
    );
  }

  return (
    <DashboardWrapper>
      <div className={dashboardClassName}>
        <div className="boss-page-dashboard__group">
          <div className="boss-page-dashboard__user-summary">
            <div className="boss-user-summary boss-user-summary_role_user-profile">
              <div className="boss-user-summary__side">
                <div className="boss-user-summary__avatar">
                  <div className="boss-user-summary__avatar-inner">
                    <img src={avatarUrl} alt={fullName} className="boss-user-summary__pic" />
                  </div>
                </div>
              </div>
              <div className="boss-user-summary__content">
                <div className="boss-user-summary__header">
                  <h2 className="boss-user-summary__name">
                    {fullName} {isDisabled && <span>(Disabled)</span>}
                  </h2>
                  {isLinked && (
                    <div className="boss-user-summary__indicator">
                      <a
                        href={appRoutes.staffMemberProfile(oFetch(user, 'staffMemberId'))}
                        target="_blank"
                        rel="noreferrer"
                        className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                      >
                        <span className="boss-indicator__icon boss-indicator__icon_size_l boss-indicator__icon_chain" />
                      </a>
                    </div>
                  )}
                </div>
                { renderSyncState() }
                {isDisabled && (
                  <ul className="boss-user-summary__review-list">
                    <li className="boss-user-summary__review-item">
                      <span className="boss-user-summary__review-label">Disabled by: </span>
                      <span className="boss-user-summary__review-val">
                        <span>{disabledByUser}</span> on{' '}
                        <span>
                          {safeMoment.iso8601Parse(disabledAt).format(utils.humanDateFormatWithTime())}
                        </span>
                      </span>
                    </li>
                    {disableReason && (
                      <li className="boss-user-summary__review-item">
                        <span className="boss-user-summary__review-label">Reason for disabling: </span>
                        <span className="boss-user-summary__review-val">{disableReason}</span>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="boss-page-dashboard__buttons-group">{renderDisabledEnabledButton()}</div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
