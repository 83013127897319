import oFetch from 'o-fetch';

import { addMossStaffMemberRequest } from './requests';

export const addMossStaffMemberAction = params => {
  const formValues = oFetch(params, 'values');

  const linkStaffMemberId = oFetch(formValues, 'linkStaffMemberId');
  const linkedStaffMemberPresent = linkStaffMemberId != null;

  let values = {
    masterVenueId: oFetch(formValues, 'masterVenueId'),
    mossStaffTypeId: oFetch(formValues, 'mossStaffTypeId'),
    mossPayRateId: oFetch(formValues, 'mossPayRateId'),
  };

  if (!linkedStaffMemberPresent) {
    values = {
      ...values,
      withStaffMemberLink: false,
      firstName: oFetch(formValues, 'firstName'),
      surname: oFetch(formValues, 'surname'),
      pinCode: oFetch(formValues, 'pinCode'),
      validatedAvatarGUID: oFetch(formValues, 'validatedAvatarGUID'),
    };
  } else {
    values = {
      ...values,
      withStaffMemberLink: true,
      linkedStaffMemberId: linkStaffMemberId,
    };
  }

  return addMossStaffMemberRequest({
    values,
  });
};
