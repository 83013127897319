import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class PreviewFormGroup extends Component {
  renderFields = () => {
    const children = oFetch(this.props, 'children');
    return <ul className="boss-info-fields-block">{children}</ul>;
  };

  render() {
    const [index, title] = oFetch(this.props, 'index', 'title');
    return (
      <div className="boss-info-block boss-info-block_type_with-index boss-forms-block_adjust_info-block">
        <div className="boss-info-block__index">{index}</div>
        <h3 className="boss-info-block__header">{title}</h3>
        {this.renderFields()}
      </div>
    );
  }
}
