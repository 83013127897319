import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function MainDashboard(props) {
  const currentLevel = oFetch(props, 'currentLevel');
  const redLevelType = oFetch(props, 'levelTypes.redLevelType');
  const amberLevelType = oFetch(props, 'levelTypes.amberLevelType');

  const levelClass = cn('boss-page-dashboard__status', {
    'boss-page-dashboard__status_level_red': currentLevel === redLevelType,
    'boss-page-dashboard__status_level_amber': currentLevel === amberLevelType,
  });

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Venue Dashboard</h1>
            {currentLevel && <div className={levelClass}>This venue is at {currentLevel} Alert</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
