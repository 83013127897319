import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const redLevelTypeSelector = state => oFetch(state, 'alertLevelTypes.redLevel');
export const amberLevelTypeSelector = state => oFetch(state, 'alertLevelTypes.amberLevel');
export const venueAlertsSelector = state => oFetch(state, 'venueAlerts');
export const siteIssuesSelector = state => oFetch(state, 'siteIssues');

export const currentAlertsLevelSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector, venueAlertsSelector],
  (redLevelType, amberLevelType, enabledVenueAlerts) => {
    if (enabledVenueAlerts.find(venueAlert => oFetch(venueAlert, 'level') === redLevelType)) {
      return redLevelType;
    }
    if (enabledVenueAlerts.find(venueAlert => oFetch(venueAlert, 'level') === amberLevelType)) {
      return amberLevelType;
    }
    return null;
  },
);

export const currentSiteIssuesLevelSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector, siteIssuesSelector],
  (redLevelType, amberLevelType, siteIssues) => {
    if (siteIssues.find(siteIssue => oFetch(siteIssue, 'level') === redLevelType)) {
      return redLevelType;
    }
    if (siteIssues.find(siteIssue => oFetch(siteIssue, 'level') === amberLevelType)) {
      return amberLevelType;
    }
    return null;
  },
);

export const currentLevelSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector, currentAlertsLevelSelector, currentSiteIssuesLevelSelector],
  (redLevelType, amberLevelType, currentAlertsLevel, currentSiteIssuesLevel) => {
    if (currentAlertsLevel === redLevelType || currentSiteIssuesLevel === redLevelType) {
      return redLevelType;
    }
    if  (currentAlertsLevel === amberLevelType || currentSiteIssuesLevel === amberLevelType) {
      return amberLevelType;
    }
    return null;
  },
);

function byCreatedAt(a, b) {
  return new Date(oFetch(b, 'createdAt')) - new Date(oFetch(a, 'createdAt'));
}

export const currentAlertsSelector = createSelector(
  [currentAlertsLevelSelector, venueAlertsSelector],
  (currentLevel, venueAlerts) => {
    if (!currentLevel) {
      return [];
    }
    return [...venueAlerts.sort(byCreatedAt)];
  },
);

export const currentSiteIssuesSelector = createSelector(
  [currentSiteIssuesLevelSelector, siteIssuesSelector],
  (currentLevel, siteIssues) => {
    if (!currentLevel) {
      return [];
    }
    return [...siteIssues.sort(byCreatedAt)];
  },
);

export const currentSiteIssuesAndVenueAlertsSelector = createSelector(
  [redLevelTypeSelector, amberLevelTypeSelector, currentSiteIssuesSelector, currentAlertsSelector],
  (redLevelType, amberLevelType, siteIssues, venueAlerts) => {
    return [
      ...[...siteIssues, ...venueAlerts]
        .filter(item => oFetch(item, 'level') === redLevelType)
        .sort(byCreatedAt),
      ...[...siteIssues, ...venueAlerts]
        .filter(item => oFetch(item, 'level') === amberLevelType)
        .sort(byCreatedAt),
    ];
  },
);
