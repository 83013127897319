import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import ContentWrapper from '@/components/content-wrapper';
import { openWarningModal } from '@/components/modals';
import * as selectors from './redux/selectors';
import * as actions from './redux/actions';

import {
  DashboardWrapper,
  SortableQuestionsList,
  QuestionItem,
  AddQuestion,
  EditQuestion,
  ManageCategories,
  ManageAreas,
} from './components';

function VenueHealthCheckQuestionnaire(props, context) {
  const questionnaireQuestions = oFetch(props, 'questionnaireQuestions');
  const openModal = oFetch(context, 'openModal');
  const [deleteQuestion, updateQuestion, createArea, createCategory, createQuestion] = oFetch(
    props,
    'deleteQuestion',
    'updateQuestion',
    'createArea',
    'createCategory',
    'createQuestion'
  );
  const currentVenueId = oFetch(props, 'currentVenueId');
  function openManageCategoriesModal() {
    openModal({
      onSubmit: createCategory,
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Manage Categories',
        innerClose: true,
      },
      props: { currentVenueId },
    })(ManageCategories);
  }

  function openManageAreasModal() {
    openModal({
      onSubmit: createArea,
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Manage Areas',
        innerClose: true,
      },
      props: {},
    })(ManageAreas);
  }

  function getOpenEditQuestionModalFunction(args) {
    const currentVenueId = oFetch(args, 'currentVenueId');
    return (question) => openEditQuestionModal({ currentVenueId }, question);
  }

  function openEditQuestionModal(curriedArgs, question) {
    const currentVenueId = oFetch(curriedArgs, 'currentVenueId');

    openModal({
      onSubmit: updateQuestion,
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Edit Question',
        innerClose: true,
      },
      props: {
        question,
        currentVenueId
      },
    })(EditQuestion);
  }

  function getOpenAddQuestionModalFunction(args) {
    const currentVenueId = oFetch(args, 'currentVenueId');
    return () => openAddQuestionModal({ currentVenueId });
  }

  function openAddQuestionModal(args) {
    const currentVenueId = oFetch(args, 'currentVenueId');

    openModal({
      onSubmit: createQuestion,
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Question',
        innerClose: true,
      },
      props: { currentVenueId },
    })(AddQuestion);
  }

  function openDeleteQuestionModal(question) {
    const id = oFetch(question, 'id');
    openWarningModal({
      submit: closeModal => deleteQuestion({ id, closeModal }),
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Delete',
      },
      props: { id },
    });
  }

  function renderQuestionItem(args) {
    const item = oFetch(args, 'item');
    const currentVenueId = oFetch(args, 'currentVenueId');
    return <QuestionItem question={item} onEdit={getOpenEditQuestionModalFunction({ currentVenueId })} onDisable={openDeleteQuestionModal} />;
  }

  return (
    <div>
      <DashboardWrapper title="Venue Health Check Questions">
        <button onClick={getOpenAddQuestionModalFunction({ currentVenueId })} className="boss-button boss-button_role_add boss-page-dashboard__button">
          Add Question
        </button>
        <button
          onClick={openManageCategoriesModal}
          className="boss-button boss-button_role_manage-categories boss-page-dashboard__button"
        >
          Manage Categories
        </button>
        <button
          onClick={openManageAreasModal}
          className="boss-button boss-button_role_manage-areas boss-page-dashboard__button"
        >
          Manage Areas
        </button>
      </DashboardWrapper>
      <ContentWrapper>
        <SortableQuestionsList
          currentVenueId={currentVenueId}
          items={questionnaireQuestions}
          onChange={() => {}}
          itemRender={renderQuestionItem}
        />
      </ContentWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentVenueId: selectors.getCurrentVenueId(state),
    questionnaireQuestions: selectors.getSortedQuestionnaireQuestions(state),
  };
};

const mapDispatchToProps = {
  deleteQuestion: actions.deleteQuestion,
  updateQuestion: actions.updateQuestion,
  createArea: actions.createArea,
  createCategory: actions.createCategory,
  createQuestion: actions.createQuestion,
};

VenueHealthCheckQuestionnaire.contextTypes = { openModal: PropTypes.func };

export default connect(mapStateToProps, mapDispatchToProps)(VenueHealthCheckQuestionnaire);
