import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import { WithoutCloseModalWrapper } from '@/components/modal-wrappers';

const ONE_SECOND = 1000;

export function InactivityModal(props) {
  const [countdownSeconds, onCountdownEnd] = oFetch(props, 'countdownSeconds', 'onCountdownEnd');

  const [countdown, setCountdown] = useState(countdownSeconds);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 0) {
          clearInterval(interval);
          onCountdownEnd();
          setFinished(true);
          return prevCountdown;
        } else {
          return prevCountdown - 1;
        }
      });
      return () => {
        clearInterval(interval);
      };
    }, ONE_SECOND);
  }, []);

  return (
    <WithoutCloseModalWrapper
      title="Inactivity Warning"
      baseClassName="boss-modal-window boss-modal-window_role_warning"
    >
      {!finished && (
        <>
          <h3 style={{ textAlign: 'center' }}>Interact with this page or it will be reloaded in</h3>
          <h1 style={{ textAlign: 'center' }}>
            <b>{countdown}</b>
          </h1>
          <h3 style={{ textAlign: 'center' }}>seconds</h3>
        </>
      )}
      {finished && (
        <>
          <h3 style={{ textAlign: 'center' }}>
            <b>Reloading ...</b>
          </h3>
        </>
      )}
    </WithoutCloseModalWrapper>
  );
}
