import React from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { KPI } from './kpi';

export function KPIApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }

  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <KPI {...props} />
    </ModalsProvider>
  );
}
