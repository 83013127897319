import React from 'react';
import { MessageScheduleForm } from '../forms/message-schedule-form';
import { MessageSchedule } from '@/types/MessageSchedule';

interface NewMessageScheduleProps {
  onSubmit: (params: any) => void;
  processing: boolean;
  messageSchedule: MessageSchedule;
}

export function EditMessageSchedule(props: NewMessageScheduleProps) {
  const { onSubmit, processing, messageSchedule } = props;

  const initialValues = {
    id: messageSchedule.id,
    staffMemberId: messageSchedule.staffMember.id,
    sendAt: messageSchedule.sendAt,
    message: messageSchedule.message,
  };

  return (
    <MessageScheduleForm
      onSubmit={onSubmit}
      processing={processing}
      initialValues={initialValues}
      buttonText="Update"
    />
  );
}
