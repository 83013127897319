import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';

const groupByVenueName = utils.groupBy('venueName');

export function VenueCardList(props) {
  const [devices, filtered] = oFetch(props, 'devices', 'filtered');
  const venueCardItemRender = oFetch(props, 'venueCardItemRender');
  const devicesExist = devices.length > 0;

  const grouppedByVenueDevices = useMemo(() => {
    return groupByVenueName(devices);
  }, [devices]);

  function renderVenueCards() {
    return Object.entries(grouppedByVenueDevices).map(entry => {
      const [venueName, venueDevices] = entry;
      return React.cloneElement(venueCardItemRender({ venueName, venueDevices }), { key: venueName });
    });
  }

  if (!devicesExist) {
    if (filtered) {
      return <h3>No devices found</h3>;
    }
    return <h3>No devices exist</h3>;
  }

  return <>{renderVenueCards()}</>;
}
