import React, { useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import Select from '@/lib/boss-react-select';
import Spinner from '@/components/spinner';

const DELIVERY_DAY_OPTIONS = [
  {
    value: true,
    label: 'Delivery Day',
  },
  {
    value: false,
    label: 'No Delivery',
  },
];

export function DeliveryDayForm(props) {
  const isDeliveryDay = oFetch(props, 'isDeliveryDay');
  const onUpdate = oFetch(props, 'onUpdate');

  const [isDeliveryDayValue, setIsDelivertDayValue] = useState(isDeliveryDay);
  const [editable, setEditable] = useState(false);
  const [updating, setUpdating] = useState(false);

  const label = isDeliveryDayValue === true ? 'Delivery Day' : 'No Deliveries';
  const labelClassName = cn('boss-indicator', {
    'boss-indicator_color_accent-red': !isDeliveryDayValue,
    'boss-indicator_color_accent-green': isDeliveryDayValue,
  });
  function startEditing() {
    setEditable(true);
  }

  function finishEditing() {
    setEditable(false);
  }

  function handleChange(item) {
    setIsDelivertDayValue(item);
  }

  async function handleUpdate() {
    setUpdating(true);

    await onUpdate({
      deliveryDay: isDeliveryDayValue,
      onSuccess() {
        finishEditing();
        setUpdating(false);
      },
      onFailure() {
        setIsDelivertDayValue(isDeliveryDay);
        finishEditing();
        setUpdating(false);
      },
    });
  }

  function renderSelect() {
    return (
      <Fragment>
        <div className="boss-page-dashboard__status-select">
          <div className="boss-form">
            <div className="boss-form__select boss-form__select_type_narrow">
              <Select
                value={isDeliveryDayValue}
                onChange={handleChange}
                simpleValue
                clearable={false}
                multi={false}
                options={DELIVERY_DAY_OPTIONS}
              />
            </div>
          </div>
        </div>
        <button
          className="boss-button boss-button_role_secondary-light boss-button_type_extra-small boss-page-dashboard__status-action"
          type="button"
          onClick={handleUpdate}
        >
          Save
        </button>
      </Fragment>
    );
  }

  function renderView() {
    return (
      <Fragment>
        <div className="boss-page-dashboard__status-indicator">
          <div className={labelClassName}>
            <div className="boss-indicator__label boss-indicator__label_size_l">{label}</div>
          </div>
        </div>
        <button
          onClick={startEditing}
          className="boss-button boss-button_role_primary-light boss-button_type_extra-small boss-page-dashboard__status-action"
          type="button"
        >
          Update
        </button>
      </Fragment>
    );
  }

  return (
    <div className="boss-page-dashboard__status-group">
      {updating && <Spinner />}
      {editable && !updating && renderSelect()}
      {!editable && !updating && renderView()}
    </div>
  );
}
