import React, { useState } from 'react';
import { appRoutes } from '@/lib/legacy-routes';
import { connect } from 'react-redux';
import safeMoment from '@/lib/safe-moment';
import _ from 'underscore';
import utils from '@/lib/utils';
import { selectStaffTypesWithShifts } from '@/redux/selectors';
import WeeklyRotaForecast from './containers/weekly-rota-forecast';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import RotaHeader from './components/rota-header';
import RotaCurrentDay from './components/rota-current-day';
import actionCreators from '@/redux/actions';
import oFetch from 'o-fetch';
import { getRotaWeekDayIndex } from '@/utils/dates';

const ROTA_PUBLISHED_STATUS = 'published';

function RotaOverviewPage(props) {
  const venue = oFetch(props, 'venue');
  const tradingData = oFetch(props, 'tradingData');

  const [highlightDate, setHighlightDate] = useState(
    utils.formatJSDateToUIDate(oFetch(props, 'storeRotas.date')),
  );

  function goToOverviewPage({ startDate, venueClientId }) {
    window.location.href = appRoutes.rotaOverview({
      venueId: venueClientId,
      startDate,
    });
  }

  function generateWeek(startDate) {
    const startOfWeek = safeMoment.uiDateParse(startDate).startOf('isoweek');
    const endOfWeek = safeMoment.uiDateParse(startDate).endOf('isoweek');

    const days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.format(utils.commonDateFormat));
      day = day.clone().add(1, 'd');
    }

    return days;
  }

  function renderDays(week) {
    return <div className="boss-paginator boss-paginator_size_full">{renderTabList(week)}</div>;
  }

  function renderTabList(week) {
    const mHighlightDate = safeMoment.uiDateParse(highlightDate);

    return week.map((item, index) => {
      const dayTradingData = oFetch(tradingData, index);
      const isDeliveryDay = oFetch(dayTradingData, 'deliveryDay');
      const isNotTrading = !oFetch(dayTradingData, 'trading');
      const showStatusIcon = isDeliveryDay || isNotTrading;

      const modifiedItem = safeMoment.uiDateParse(item);
      const tabClassName = mHighlightDate.isSame(modifiedItem, 'days')
        ? 'boss-paginator__action_state_active'
        : '';
      const formattedDay = modifiedItem.format('D');
      const formattedMonth = modifiedItem.format('MMMM');

      return (
        <button
          key={index}
          onClick={() => loadDayRota(index, week)}
          className={`boss-paginator__action boss-paginator__action_type_light ${tabClassName}`}
        >
          {formattedDay}
          <span className="boss-paginator__action-details">{formattedMonth}</span>
          { showStatusIcon ? <span className='boss-paginator__action-status'>
              { isDeliveryDay ? <span className="boss-paginator__action-indicator">
                  <span className="boss-indicator boss-indicator_color_accent-green">
                    <span className="boss-indicator__icon boss-indicator__icon_adjust_paginator boss-indicator__icon_delivery boss-indicator__icon_size_xl" />
                  </span>
                </span> : null }
              { isNotTrading ? <span className="boss-paginator__action-indicator">
                  <span className="boss-indicator boss-indicator_color_accent-red">
                    <span className="boss-indicator__icon boss-indicator__icon_adjust_paginator boss-indicator__icon_not-trading" />
                  </span>
                </span> : null }
            </span> : null }
        </button>
      );
    });
  }

  function loadDayRota(index, week) {
    const date = week[index];
    const venueId = oFetch(venue, 'id');
    setHighlightDate(date);
    oFetch(props, 'getRotaWeeklyDay')(date, venueId);
  }

  const storeRota = oFetch(props, 'storeRotas');
  const rotaDetailsObject = oFetch(props, 'rotaDetailsObject');

  const pdfHref = appRoutes.rotaOverviewPdfDownload({
    venueId: oFetch(props, 'venue.id'),
    mHighlightDate: safeMoment.uiDateParse(highlightDate),
  });

  var rotaDetails = storeRota;

  var staffTypesWithShifts = selectStaffTypesWithShifts({
    staffTypes: utils.indexByClientId(rotaDetailsObject.staff_types),
    rotaShifts: utils.indexByClientId(rotaDetailsObject.rota_shifts),
    staff: utils.indexByClientId(rotaDetailsObject.staff_members),
  });

  const currentWeek = generateWeek(utils.formatJSDateToUIDate(rotaDetails.date));
  const rota = oFetch(rotaDetailsObject, 'rota');
  const dDateOfRota = oFetch(rota, 'date');

  const currentDayIndex = getRotaWeekDayIndex({ dDate: dDateOfRota });
  const currentDayTradingData = oFetch(tradingData, currentDayIndex);
  const currentDayIsTrading = oFetch(currentDayTradingData, 'trading');
  const currentDayIsDeliveryDay = oFetch(currentDayTradingData, 'deliveryDay');

  return (
    <div className="boss-page-main">
      <RotaHeader
        startDate={oFetch(props, 'startDate')}
        venue={oFetch(props, 'venue')}
        rota={oFetch(props, 'storeRotas')}
        endDate={oFetch(props, 'endDate')}
        pdfHref={pdfHref}
        tradingData={tradingData}
      />
      <div className="boss-page-main__content">
        <div className="boss-page-main__inner">
          <div className="boss-rotas">
            <div className="boss-rotas__summary">
              <section className="boss-board boss-board_layout_double">
                <div className="boss-board__calendar">
                  <div className="boss-board__calendar-inner">
                    <BossWeekPicker
                      selectionStartUIDate={utils.formatJSDateToUIDate(rotaDetails.date)}
                      onChange={selection => {
                        goToOverviewPage({
                          startDate: selection.startUIDate,
                          venueClientId: oFetch(props, 'venue.id'),
                        });
                      }}
                      onCancelClick={() => {}}
                    />
                  </div>
                  {storeRota.status === ROTA_PUBLISHED_STATUS && (
                    <div className="boss-board__calendar-note">
                      <div className="boss-message boss-message_role_calendar-note">
                        <p className="boss-message__text"> This week's rotas have been published.</p>
                        <p className="boss-message__text">
                          {' '}
                          Changes to them will send out email notifications.{' '}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="boss-board__info">
                  <header className="boss-board__header">
                    <h2 className="boss-board__title"> Weekly Forecast </h2>
                  </header>
                  <div className="boss-board__main">
                    <WeeklyRotaForecast
                      serverVenueId={oFetch(props, 'storeRotas.venue.serverId')}
                      startOfWeek={utils.getWeekStartDate(oFetch(props, 'storeRotas.date'))}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="boss-rotas__days">
            <div className="boss-rotas__days-nav">{renderDays(currentWeek)}</div>
            <RotaCurrentDay
              isLoading={oFetch(props, 'requestsInProgress')}
              staff={oFetch(rotaDetailsObject, 'staff_members')}
              shifts={oFetch(rotaDetailsObject, 'rota_shifts')}
              rota={oFetch(rotaDetailsObject, 'rota')}
              dateOfRota={dDateOfRota}
              staffTypesWithShifts={utils.indexByClientId(staffTypesWithShifts)}
              rotaForecast={oFetch(props, 'rotaForecast')}
              isTrading={currentDayIsTrading}
              isDeliveryDay={currentDayIsDeliveryDay}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getRotaWeeklyDay: function (date, venueId) {
      dispatch(
        actionCreators().getRotaWeeklyDay({
          serverVenueId: venueId,
          date: date,
        }),
      );
    },
  };
}

function mapStateToProps(props) {
  return {
    storeRotas: props.rotaWeeklyDay.rota,
    rotaDetailsObject: props.rotaWeeklyDay,
    endDate: props.pageOptions.endDate,
    startDate: props.pageOptions.startDate,
    venues: props.venues,
    rotaForecast: props.rotaWeeklyDay.rota_forecast,
    requestsInProgress: _.some(props.apiRequestsInProgress.GET_ROTA_WEEKLY_DAY),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RotaOverviewPage);
