import React from 'react';
import { Field } from 'react-final-form';

export function Fields({ names, subscription, fieldsState = {}, children, originalRender, ...otherProps }: any) {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <Field name={name} subscription={subscription} parse={value => value}>
      {fieldState => (
        <Fields
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState, ...otherProps }}
          {...otherProps}
        />
      )}
    </Field>
  );
}
