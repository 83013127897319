import cn from 'classnames';
import oFetch from 'o-fetch';
import React, { useState } from 'react';
import Popover from 'react-popover';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export default function BossWeekPickerPopover(props) {
  const [weekStartDate, weekEndDate, onWeekSelect] = oFetch(
    props,
    'weekStartDate',
    'weekEndDate',
    'onWeekSelect',
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const mWeekStartDate = safeMoment.uiDateParse(weekStartDate);
  const mWeekEndDate = safeMoment.uiDateParse(weekEndDate);

  const popoverClass = cn({
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
    'boss-page-dashboard__meta-item_state_opened': showCalendar,
  });

  function toggleCalendar() {
    setShowCalendar(!showCalendar);
  }

  function renderCalendar() {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={weekStartDate}
          onChange={selection => onWeekSelect(selection)}
          onCancelClick={toggleCalendar}
        />
      </div>
    );
  }

  return (
    <Popover
      isOpen={showCalendar}
      body={renderCalendar()}
      place="below"
      appendTarget={document.body}
      tipSize={0.01}
      onOuterAction={toggleCalendar}
      className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
      sylte={{ marginTop: '10px' }}
    >
      <p className={popoverClass} onClick={toggleCalendar}>
        <span className="boss-page-dashboard__meta-text">
          {mWeekStartDate.format(utils.commonDateFormat)}
        </span>
        {' — '}
        <span className="boss-page-dashboard__meta-text">{mWeekEndDate.format(utils.commonDateFormat)}</span>
      </p>
    </Popover>
  );
}
