import React from 'react';
import oFetch from 'o-fetch';
import Select from 'react-select';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export function SectionsSelect(props) {
  const [sections, iFrameID] = oFetch(props, 'sections', 'iFrameID');
  function getGrouppedOptions() {
    return Object.entries(sections).map(entry => {
      const [id, section] = entry;
      const options = section.child.map(subSection => {
        const { id, title } = subSection;
        return {
          value: id,
          label: title,
        };
      });
      return {
        label: section.title,
        options,
      };
    });
  }

  function handleChange(selected) {
    showBookmark(selected.value);
  }

  function showNode(oNode) {
    const iFrame = document.getElementById(iFrameID).contentWindow;
    iFrame.scrollTo(0, oNode.offsetTop - 40);
  }

  function showBookmark(sBookmark) {
    const iFrame = document.getElementById(iFrameID).contentWindow;
    var oBookmark = iFrame.document.getElementById(sBookmark);
    if (oBookmark) {
      showNode(oBookmark);
    }
  }

  return (
    <div className="boss-form__select boss-form__select_type_narrow">
      <Select
        isMulti={false}
        options={getGrouppedOptions()}
        onChange={handleChange}
        classNamePrefix="react-select"
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
}
