import React from 'react';
import { connect } from 'react-redux';

import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import DetailsListItem from '../components/details-list-item';
import DetailsList from '../components/details-list';
import ProfileWrapper from '../../profile-wrapper';

const mapStateToProps = state => {
  const stateJS = state.toJS();
  return {
    mossStaffMember: oFetch(stateJS, 'profile.mossStaffMember'),
    mossStaffTypes: oFetch(stateJS, 'profile.mossStaffTypes'),
    venues: oFetch(stateJS, 'profile.venues'),
    accessibleVenues: oFetch(stateJS, 'profile.accessibleVenues'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

const findById = (collection, id) => {
  if (Object.prototype.toString.call(id) === '[object Array]') {
    return _(collection).keyBy('id').at(id).filter().value();
  } else {
    return collection.find(item => item.id === id);
  }
};

class ProfilePage extends React.PureComponent {
  render() {
    function getMossPayRateName(params) {
      const mossStaffMember = oFetch(params, 'mossStaffMember');

      const mossPayRateName = oFetch(mossStaffMember, 'mossPayRateName');
      return mossPayRateName || 'N/A';
    }
    const mossStaffMember = oFetch(this.props, 'mossStaffMember');
    const venues = oFetch(this.props, 'venues');
    const mossStaffTypes = oFetch(this.props, 'mossStaffTypes');

    const masterVenueId = oFetch(mossStaffMember, 'master_venue');
    const masterVenueValue = masterVenueId && oFetch(findById(venues, masterVenueId), 'name');

    return (
      <ProfileWrapper currentPage="profile">
        <DetailsList key={1} categoryName="Employment Details" sectionNumber={1}>
          <DetailsListItem item={{ name: 'Master Venue', value: masterVenueValue }} />
          <DetailsListItem
            item={{
              name: 'Job Type',
              value: oFetch(findById(mossStaffTypes, oFetch(mossStaffMember, 'moss_staff_type')), 'name'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Pay Rate',
              value: getMossPayRateName({
                mossStaffMember,
              }),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Public Holidays',
              value: () => {
                return (
                  <>
                    {oFetch(mossStaffMember, 'generatePublicHolidays') && (
                      <span className="boss-indicator">
                        <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
                      </span>
                    )}
                    {!oFetch(mossStaffMember, 'generatePublicHolidays') && (
                      <span className="boss-indicator">
                        <span className="boss-indicator__icon boss-indicator__icon_close-bold" />
                      </span>
                    )}
                  </>
                )
              },
            }}
          />
        </DetailsList>
        <DetailsList key={2} categoryName="Account Details" sectionNumber={2}>
          <DetailsListItem
            item={{
              name: 'Created',
              value: safeMoment
                .iso8601Parse(oFetch(mossStaffMember, 'created_at'))
                .format('HH:mm DD MMMM YYYY'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Modified',
              value: safeMoment
                .iso8601Parse(oFetch(mossStaffMember, 'updated_at'))
                .format('HH:mm DD MMMM YYYY'),
            }}
          />
          <DetailsListItem
            item={{
              name: 'Name',
              value: `${oFetch(mossStaffMember, 'first_name')} ${oFetch(mossStaffMember, 'surname')}`,
            }}
          />
        </DetailsList>
      </ProfileWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
