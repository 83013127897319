import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { RatingForm } from './rating-form';

export function AddRatingModalContent(props) {
  const [onSubmit, kpiDataRenderer, venueId, kpiId, year, quarter] = oFetch(
    props,
    'onSubmit',
    'kpiDataRenderer',
    'venueId',
    'kpiId',
    'year',
    'quarter',
  );
  const initialValues = {
    kpiId,
    venueId,
    year,
    quarter,
    rating: '',
    note: '',
  };
  return (
    <RatingForm
      kpiDataRenderer={kpiDataRenderer}
      onSubmit={onSubmit}
      initialValues={initialValues}
      submitTitle={'Add Rating'}
    />
  );
}
