import React from 'react';
import oFetch from 'o-fetch';

export default function WeekDays(props) {
  const [weekRange, renderTab] = oFetch(props, 'weekRange', 'renderTab');

  function renderWeek() {
    return weekRange.map((day, index) => {
      return (
        <li key={index} className="purple-tab-nav__item">
          {React.cloneElement(renderTab(day))}
        </li>
      );
    });
  }

  return (
    <div className="purple-page-main__tab-nav">
      <div className="purple-tab-nav purple-tab-nav_adjust_week-all">
        <ul className="purple-tab-nav__list">
          {renderWeek()}
          <li key="all" className="purple-tab-nav__item">
            {React.cloneElement(renderTab('all'))}
          </li>
        </ul>
      </div>
    </div>
  );
}
