import React from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { BonusPaymentItem, BonusPaymentsList } from './components';
import { bonusPaymentsHooks } from './bonus-payments-hooks';

export function BonusPayments(props) {
  const {
    bonusPayments,
    openWrongAmountModal,
    receivedBonusPayment,
    didNotReceiveBonusPayment,
  } = bonusPaymentsHooks({
    initialBonusPayments: oFetch(props, 'bonusPayments'),
    paths: oFetch(props, 'paths'),
  });

  function renderPayment(bonusPayment) {
    return (
      <BonusPaymentItem
        bonusPayment={bonusPayment}
        onReceive={receivedBonusPayment}
        onDifferentAmount={openWrongAmountModal}
        onDidNotReceive={didNotReceiveBonusPayment}
      />
    );
  }

  return (
    <ContentWrapper className={'boss-page-main__inner_opaque boss-page-main__inner_space_large'}>
      <BonusPaymentsList payments={bonusPayments} renderPayment={renderPayment} />
    </ContentWrapper>
  );
}
