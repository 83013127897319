import oFetch from 'o-fetch';
import React, { useState } from 'react';
import { bossRequestHttp } from '@/lib/request-api';

import cn from 'classnames';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import Spinner from '@/components/spinner';

async function toDataURL(blobId, contentType) {
  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const data = oFetch(params, 'data');
      return data;
    },
  }).get(`/api/v1/downloads/${blobId}`, { responseType: 'blob' });
}

async function download(blobId, filename, contentType) {
  const binary = await toDataURL(blobId, contentType);
  const file = new File([binary], filename);
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function ImagePreviewModalContent(props) {
  const [processing, setProcessing] = useState(false);
  const [preview, filename, url, contentType, blobId] = oFetch(
    props,
    'preview',
    'filename',
    'url',
    'content_type',
    'blobId',
  );

  async function handleDownload() {
    setProcessing(true);
    await download(blobId, filename, contentType);
    setProcessing(false);
  }

  return (
    <div className="boss-modal-window__message-block">
      <img src={preview} alt={filename} className="boss-modal-window__message-image" />
      <p className="boss-modal-window__message-text" style={{ marginBottom: '10px' }}>
        {filename}
      </p>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <button
          onClick={handleDownload}
          type="button"
          disabled={processing}
          className="boss-button boss-button_role_complete-task boss-task__action"
        >
          Download
        </button>
      </div>
    </div>
  );
}

Attachment.defaultProps = {
  fromBlob: false,
  isNew: false,
  isDeleted: false,
};

export function Attachment(props) {
  const { openModal } = useModal();
  const [processing, setProcessing] = useState(false);

  const [attachment, fromBlob, isNew, isDeleted] = oFetch(
    props,
    'attachment',
    'fromBlob',
    'isNew',
    'isDeleted',
  );
  const [imageLoaded, setImageLoaded] = useState(false);

  const [filename, isImage, url, content_type, blobId] = oFetch(
    attachment,
    'filename',
    'is_image',
    'url',
    'content_type',
    'blob_id',
  );
  function handleImagePreviewModalOpen() {
    return openModal({
      onSubmit: null,
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_preview',
        title: () => {},
      },
      props: {
        preview: fromBlob ? url : oFetch(attachment, 'preview'),
        filename,
        url,
        content_type,
        blobId,
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ImagePreviewModalContent,
    });
  }

  function handleImageLoaded() {
    setImageLoaded(true);
  }

  async function handleDownload() {
    setProcessing(true);
    await download(blobId, filename, content_type);
    setProcessing(false);
  }

  if (isImage) {
    const imageClassNames = cn('boss-task__attachment-preview', {
      'boss-upload__preview_state_loading': !imageLoaded,
    });

    return (
      <button
        onClick={handleImagePreviewModalOpen}
        type="button"
        className="boss-task__attachment boss-task__attachment_role_action deleted-attachment"
      >
        <div
          className={imageClassNames}
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isDeleted && <span className="deleted-badge">Deleted</span>}
          {isNew && <span className="added-badge">New</span>}
          <img
            src={fromBlob ? url : oFetch(attachment, 'thumb')}
            alt={filename}
            loading="lazy"
            onLoad={handleImageLoaded}
            className="boss-task__attachment-image"
          />
        </div>
        <p className="boss-task__attachment-caption">{filename}</p>
      </button>
    );
  }
  return (
    <button
      disabled={processing}
      onClick={handleDownload}
      className="boss-task__attachment boss-task__attachment_role_action"
    >
      <div className="boss-task__attachment-link">
        {isDeleted && <span className="deleted-badge">Deleted</span>}
        {isNew && <span className="added-badge">New</span>}
        <div
          className="boss-task__attachment-preview boss-task__attachment-preview_role_file"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {processing && <Spinner />}
        </div>
        <div className="boss-task__attachment-caption">{filename}</div>
      </div>
    </button>
  );
}
