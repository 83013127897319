import React, { Component } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import humanize from 'string-humanize';
import {
  getFormValues,
  reduxForm,
  registerField,
  getFormSyncErrors,
  stopSubmit,
  setSubmitFailed,
  getFormAsyncErrors,
} from 'redux-form';
import * as c from '../../constants';
import { beautySeparator } from '@/lib/utils';
import * as selectors from '../../redux/selectors';
import PreviewFormGroup from './preview-form-group';
import PreviewFormGroupItem from './preview-form-group-item';
import { previewFormValidation } from './validators';

function AccessoryItem(props) {
  const [accessoryName, icon, color] = oFetch(props, 'accessoryName', 'icon', 'color');
  return (
    <div className="boss-indicator boss-indicator_adjust_tooltip boss-indicator_adjust_flow">
      <div className={`boss-indicator__icon boss-indicator__icon_${icon}`} style={{ color }} />
      <div className="boss-indicator__tooltip">
        <span className="boss-indicator__tooltip-line">
          <span className="boss-indicator__tooltip-marked">{accessoryName}</span>
        </span>
      </div>
    </div>
  );
}

class PreviewForm extends Component {
  getFields = () => {
    return Object.entries(c.STEPS_FIELDS)
      .map(entry => {
        const [key, value] = entry;
        return Object.keys(value);
      })
      .flat();
  };

  getSimpleValue = (values, key) => {
    if (values[key] === false) {
      return 'not set';
    }
    if (values[key] === true) {
      return 'set';
    }
    if (!values[key]) {
      return '';
    }
    return values[key];
  };

  componentDidMount() {
    const allFields = this.getFields();
    allFields.forEach(field => {
      this.props.handleRegisterField(c.FORM_NAME, field, 'Field');
    });
    this.props.handleSetSubmitFailed(c.FORM_NAME, ...allFields);
  }

  getVenuesNames = (formValues, venueOptions) => {
    const venuesIds = formValues[c.OTHER_VENUES_FIELD_NAME];
    if (!venuesIds || venuesIds.length === 0) {
      return '';
    }
    const venues = venueOptions.filter(venue => venuesIds.includes(oFetch(venue, 'id')));
    return venues.map(venue => oFetch(venue, 'name')).join(', ');
  };

  getVenueName = (formValues, venueOptions) => {
    const venueId = formValues[c.MASTER_VENUE_FIELD_NAME];
    if (!venueId) {
      return '';
    }
    const venue = venueOptions.find(venue => oFetch(venue, 'id') === venueId);
    return oFetch(venue, 'name');
  };

  renderAccessories = (selectedAccessories, accessories) => {
    return Object.entries(selectedAccessories).reduce((acc, item) => {
      const [id, sizes] = item;
      const accessory = accessories.find(accessory => oFetch(accessory, 'id') === parseInt(id));
      if (!accessory) {
        throw new Error(`Accessory must be exist`);
      }
      const [name, icon, color] = oFetch(accessory, 'name', 'icon', 'iconColor');
      if (sizes.length === 0) {
        return [...acc, <AccessoryItem key={id} accessoryName={name} icon={icon} color={color} />];
      }
      const withSizes = sizes.map(size => {
        return (
          <AccessoryItem
            key={`${id}_${size}`}
            accessoryName={`${name} - ${size}`}
            icon={icon}
            color={color}
          />
        );
      });
      return [...acc, ...withSizes];
    }, []);
  };

  getGenderValue = (formValues, gendersOptions) => {
    const gender = formValues[c.GENDER_FIELD_NAME];
    if (!gender) {
      return '';
    }
    return gendersOptions[gender];
  };

  getEmploymentStatusValue = (formValues, employmentStatuses) => {
    const employmentStatus = formValues[c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME];
    if (!employmentStatus) {
      return '';
    }
    return employmentStatuses[employmentStatus];
  };

  getStaffTypeValue = (formValues, staffTypes) => {
    const staffTypeId = formValues[c.STAFF_TYPE_FIELD_NAME];
    if (!staffTypeId) {
      return '';
    }
    const staffType = staffTypes.find(staffType => oFetch(staffType, 'id') === staffTypeId);
    return oFetch(staffType, 'name');
  };

  getPayRateValue = (formValues, payRates) => {
    const payRateId = formValues[c.PAY_RATE_FIELD_NAME];
    if (!payRateId) {
      return '';
    }
    const payRate = payRates.find(payRate => oFetch(payRate, 'id') === payRateId);
    return oFetch(payRate, 'name');
  };

  getBaseStewardPayRateValue = (formValues, payRates) => {
    const payRateId = formValues[c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME];
    if (!payRateId) {
      return '';
    }
    const payRate = payRates.find(payRate => oFetch(payRate, 'id') === payRateId);
    return oFetch(payRate, 'name');
  };

  getBaseStandardPayRateValue = (formValues, payRates) => {
    const payRateId = formValues[c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME];
    if (!payRateId) {
      return '';
    }
    const payRate = payRates.find(payRate => oFetch(payRate, 'id') === payRateId);
    return oFetch(payRate, 'name');
  };

  renderAccessoryPermissionMessage = permissions => {
    const canAcceptAccessories = oFetch(permissions, 'can_accept_accessories');
    if (canAcceptAccessories) {
      return <h3>Pending Approval</h3>;
    }
    return null;
  };

  render() {
    const [
      handleSubmit,
      previousPage,
      submitting,
      formValues,
      venueOptions,
      gendersOptions,
      employmentStatuses,
      isSecurity,
      staffTypes,
      payRates,
      syncErrors,
      valid,
      unReviewedFlaggedStaffMembersCount,
      submitErrors,
      asyncErrors,
      asyncValidating,
      accessories,
      userPermissions,
      forStandardSecurityStaffSecurityPayRatesOptions,
      forStewardSecurityStaffSecurityPayRatesOptions,
      isSteward,
    ] = oFetch(
      this.props,
      'handleSubmit',
      'previousPage',
      'submitting',
      'formValues',
      'venueOptions',
      'gendersOptions',
      'employmentStatuses',
      'isSecurity',
      'staffTypes',
      'payRates',
      'syncErrors',
      'valid',
      'unReviewedFlaggedStaffMembersCount',
      'submitErrors',
      'asyncErrors',
      'asyncValidating',
      'accessories',
      'userPermissions',
      'forStandardSecurityStaffSecurityPayRatesOptions',
      'forStewardSecurityStaffSecurityPayRatesOptions',
      'isSteward',
    );
    const hasUnReviewedStaffMembers = unReviewedFlaggedStaffMembersCount > 0;
    const errors = { ...syncErrors, ...asyncErrors, ...submitErrors };
    const selectedAccessories = formValues[c.ACCESSORIES_FIELD_NAME];
    const hasSelectedAccessories = Object.keys(selectedAccessories).length > 0;
    return (
      <div className="boss-page-main__group">
        <div className="boss-form">
          <PreviewFormGroup index="1" title="Basic Information">
            <PreviewFormGroupItem
              fieldName={c.FIRST_NAME_FIELD_NAME}
              errors={errors}
              title="First Name"
              value={this.getSimpleValue(formValues, c.FIRST_NAME_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.SURNAME_FIELD_NAME}
              errors={errors}
              title="Surname"
              value={this.getSimpleValue(formValues, c.SURNAME_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.GENDER_FIELD_NAME}
              errors={errors}
              title="Gender"
              value={this.getGenderValue(formValues, gendersOptions)}
            />
            <PreviewFormGroupItem
              fieldName={c.DATE_OF_BIRTH_FIELD_NAME}
              errors={errors}
              title="Date of birth"
              value={this.getSimpleValue(formValues, c.DATE_OF_BIRTH_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.VALIDATED_AVATAR_GUID_FIELD_NAME}
              errors={errors}
              title="Avatar"
              value={this.getSimpleValue(formValues, c.VALIDATED_AVATAR_GUID_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.SETUP_FOR_FACIAL_FIELD_NAME}
              errors={errors}
              title="Setup for facial recognition"
              value={this.getSimpleValue(formValues, c.SETUP_FOR_FACIAL_FIELD_NAME)}
            />
            {isSecurity && (
              <PreviewFormGroupItem
                fieldName={c.SIA_BADGE_NUMBER_FIELD_NAME}
                errors={errors}
                title="Sia Badge Number"
                value={this.getSimpleValue(formValues, c.SIA_BADGE_NUMBER_FIELD_NAME)}
              />
            )}
            {isSecurity && (
              <PreviewFormGroupItem
                fieldName={c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME}
                errors={errors}
                title="Sia Badge Expiry Date"
                value={this.getSimpleValue(formValues, c.SIA_BADGE_EXPIRY_DATE_FIELD_NAME)}
              />
            )}
            <PreviewFormGroupItem
              fieldName={c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME}
              errors={errors}
              title="National Insurance Number"
              value={this.getSimpleValue(formValues, c.NATIONAL_INSURANCE_NUMBER_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.EMAIL_FIELD_NAME}
              errors={errors}
              title="Email"
              value={this.getSimpleValue(formValues, c.EMAIL_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.ADDRESS_FIELD_NAME}
              errors={errors}
              title="Address"
              value={this.getSimpleValue(formValues, c.ADDRESS_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.COUNTRY_FIELD_NAME}
              errors={errors}
              title="Country"
              value={this.getSimpleValue(formValues, c.COUNTRY_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.POST_CODE_FIELD_NAME}
              errors={errors}
              title="Post Code"
              value={this.getSimpleValue(formValues, c.POST_CODE_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.PHONE_NUMBER_FIELD_NAME}
              errors={errors}
              title="Phone Number"
              value={this.getSimpleValue(formValues, c.PHONE_NUMBER_FIELD_NAME)}
            />
          </PreviewFormGroup>
          <PreviewFormGroup index="2" title="Work">
            <PreviewFormGroupItem
              fieldName={c.STAFF_TYPE_FIELD_NAME}
              errors={errors}
              title="Staff Type"
              value={this.getStaffTypeValue(formValues, staffTypes)}
            />

            <PreviewFormGroupItem
              fieldName={c.PIN_CODE_FIELD_NAME}
              errors={errors}
              title="Pin Code"
              value={this.getSimpleValue(formValues, c.PIN_CODE_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.DAY_PREFERENCE_FIELD_NAME}
              errors={errors}
              title="Day Preference"
              value={this.getSimpleValue(formValues, c.DAY_PREFERENCE_FIELD_NAME)}
            />
            <PreviewFormGroupItem
              fieldName={c.HOUR_PREFERENCE_FIELD_NAME}
              errors={errors}
              title="Hours Preference"
              value={this.getSimpleValue(formValues, c.HOUR_PREFERENCE_FIELD_NAME)}
            />
            {!isSecurity && (
              <PreviewFormGroupItem
                fieldName={c.PAY_RATE_FIELD_NAME}
                errors={errors}
                title="Pay Rate"
                value={this.getPayRateValue(formValues, payRates)}
              />
            )}
            {isSecurity && isSteward && (
              <PreviewFormGroupItem
                fieldName={c.FOR_STEWARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME}
                errors={errors}
                title="Pay Rate"
                value={this.getBaseStewardPayRateValue(
                  formValues,
                  forStewardSecurityStaffSecurityPayRatesOptions,
                )}
              />
            )}
            {isSecurity && !isSteward && (
              <PreviewFormGroupItem
                fieldName={c.FOR_STANDARD_SECURITY_STAFF_SECURITY_PAY_RATE_FIELD_NAME}
                errors={errors}
                title="Pay Rate"
                value={this.getBaseStandardPayRateValue(
                  formValues,
                  forStandardSecurityStaffSecurityPayRatesOptions,
                )}
              />
            )}
            <PreviewFormGroupItem
              fieldName={c.STARTER_EMPLOYMENT_STATUS_FIELD_NAME}
              errors={errors}
              title="Starter Employment Status"
              value={this.getEmploymentStatusValue(formValues, employmentStatuses)}
            />
          </PreviewFormGroup>
          <PreviewFormGroup index="3" title="Venues">
            <PreviewFormGroupItem
              fieldName={c.MASTER_VENUE_FIELD_NAME}
              errors={errors}
              title="Master Venue"
              value={this.getVenueName(formValues, venueOptions)}
            />
            <PreviewFormGroupItem
              fieldName={c.OTHER_VENUES_FIELD_NAME}
              errors={errors}
              title="Other Venues"
              value={this.getVenuesNames(formValues, venueOptions)}
            />
            <PreviewFormGroupItem
              fieldName={c.STARTS_AT_FIELD_NAME}
              errors={errors}
              title="Starts At"
              value={this.getSimpleValue(formValues, c.STARTS_AT_FIELD_NAME)}
            />
          </PreviewFormGroup>
          <PreviewFormGroup index="4" title="Accessories">
            {hasSelectedAccessories && this.renderAccessories(selectedAccessories, accessories)}
            {hasSelectedAccessories && this.renderAccessoryPermissionMessage(userPermissions)}
          </PreviewFormGroup>
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              onClick={previousPage}
              type="button"
              className="boss-button boss-button_role_back boss-form__submit_adjust_row"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              disabled={submitting || !valid || hasUnReviewedStaffMembers || asyncValidating}
              className="boss-button boss-form__submit boss-form__submit_adjust_single"
            >
              {asyncValidating ? `Checking for flagged or existing staff members ...` : `Create Staff Member`}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    formValues: getFormValues(c.FORM_NAME)(state),
    syncErrors: getFormSyncErrors(c.FORM_NAME)(state),
    asyncErrors: getFormAsyncErrors(c.FORM_NAME)(state) || {},
    venueOptions: selectors.venueOptionsSelector(state),
    gendersOptions: selectors.genderOptionsSelector(state),
    employmentStatuses: selectors.employmentStatusesOptionsSelector(state),
    staffTypes: selectors.staffTypeOptionsSelector(state),
    payRates: selectors.payRatesOptionsSelector(state),
    unReviewedFlaggedStaffMembersCount: selectors.unReviewedFlaggedStaffMembersCount(state),
    submitErrors: selectors.formSubmitErrorsSelector(state),
    accessories: selectors.accessoriesSelector(state),
    userPermissions: selectors.userPermissionsSelector(state),
    forStandardSecurityStaffSecurityPayRatesOptions: selectors.forStandardSecurityStaffSecurityPayRatesOptionsSelector(
      state,
    ),
    forStewardSecurityStaffSecurityPayRatesOptions: selectors.forStewardSecurityStaffSecurityPayRatesOptionsSelector(
      state,
    ),
  };
};

const mapDispatchToProps = {
  handleRegisterField: registerField,
  handleStopSubmit: stopSubmit,
  handleSetSubmitFailed: setSubmitFailed,
};

export default reduxForm({
  form: c.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate: previewFormValidation,
  shouldAsyncValidate({ asyncErrors, initialized, trigger, blurredField, pristine, syncValidationPasses }) {
    if (!syncValidationPasses) {
      return false;
    }
    switch (trigger) {
      case 'blur':
      case 'change':
        return true;
      case 'submit':
        return false;
      default:
        return false;
    }
  },
})(connect(mapStateToProps, mapDispatchToProps)(PreviewForm));
