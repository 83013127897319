import React from 'react';
import oFetch from 'o-fetch';
import { VenuesFilter } from './venues-filter';
import { DeviceNameFilter } from './device-name-filter';

export function PageFilter(props) {
  const [filter, onFilterDeviceNameChange, onFilterVenuesChange, venues] = oFetch(
    props,
    'filter',
    'onFilterDeviceNameChange',
    'onFilterVenuesChange',
    'venues',
  );
  const [selectedVenues, deviceName] = oFetch(filter, 'venues', 'deviceName');

  return (
    <div className="boss-page-main__filter">
      <div className="boss-form">
        <VenuesFilter venues={venues} selectedVenues={selectedVenues} onVenuesChange={onFilterVenuesChange} />
        <DeviceNameFilter onFilterDeviceNameChange={onFilterDeviceNameChange} value={deviceName} />
      </div>
    </div>
  );
}
