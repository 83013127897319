import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { withRouter, Link } from 'react-router-dom';
import { RadioGroup, Radio } from '@/components/radio-group';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import queryString from 'query-string';
import { appRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';
import LoadMoreButton from '@/components/load-more/load-more-button';
import Spinner from '@/components/spinner';
import MisattributedLoginCard from '../components/misattributed-logins/mistattributed-login-card';
import { getMisattributedLogins } from '../requests';
import { markHandledAction } from '../actions';

const FILTER_ALL_SETTING = 'all';
const FILTER_UNHANDLED_SETTING = 'unhandled';

class MisattributedLogins extends Component {
  state = {
    misattributedLogins: [],
    currentPage: 1,
    currentFilter: FILTER_UNHANDLED_SETTING,
    isLoaded: false,
    isLoadingMore: false,
    search: '',
    isSearching: false,
  };

  debouncedSearchChange = debounce(value => {
    const filter = oFetch(this.state, 'currentFilter');
    const currentPage = oFetch(this.state, 'currentPage');
    this.changeUrl({ filter, search: value });
    this.setState({ isSearching: true });
    getMisattributedLogins({ currentPage, filter, search: value }, false).then(res => {
      const misattributedLogins = oFetch(res, 'data.misattributedLogins');
      this.setState({
        misattributedLogins: misattributedLogins,
        isLoaded: true,
        isSearching: false,
      });
    });
  }, 2000);

  handleSearchChange = e => {
    this.setState({ search: e.target.value });
    this.debouncedSearchChange(e.target.value);
  };

  changeUrl = ({ filter, search }) => {
    window.history.pushState(
      'state',
      'title',
      `${window.location.pathname}#/misattributedLogins?${queryString.stringify({
        filter: filter || undefined,
        search: search || undefined,
      })}`,
    );
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const currentPage = oFetch(this.state, 'currentPage');
    const filter = query.filter || oFetch(this.state, 'currentFilter');
    const search = query.search || '';

    this.setState({ currentFilter: filter, search });
    getMisattributedLogins({ currentPage, filter, search }).then(res => {
      const misattributedLogins = oFetch(res, 'data.misattributedLogins');
      this.changeUrl({ filter, search });
      this.setState({
        misattributedLogins: misattributedLogins,
        isLoaded: true,
      });
    });
  }
  handleLoadMoreClick = () => {
    const query = queryString.parse(this.props.location.search);
    const currentPage = oFetch(this.state, 'currentPage');
    const nextPage = currentPage + 1;
    const filter = query.filter;
    const search = oFetch(this.state, 'search');

    this.setState({ isLoadingMore: true });
    getMisattributedLogins({ currentPage: nextPage, filter, search }, false).then(res => {
      const misattributedLogins = oFetch(res, 'data.misattributedLogins');

      this.setState({
        misattributedLogins: [...this.state.misattributedLogins, ...misattributedLogins],
        currentPage: nextPage,
        isLoadingMore: false,
      });
    });
  };
  handleMarkHandled = (closeModal, values) => {
    return markHandledAction(values, handledMisattributedLogin => {
      this.setState(state => {
        const misattributedLogins = oFetch(state, 'misattributedLogins');
        const updatedMisattributedLogins = misattributedLogins.map(misattributedLogin => {
          if (oFetch(misattributedLogin, 'id') === oFetch(handledMisattributedLogin, 'id')) {
            return handledMisattributedLogin;
          }
          return misattributedLogin;
        });
        return {
          ...state,
          misattributedLogins: updatedMisattributedLogins,
        };
      });
      closeModal();
    });
  };

  renderCards(options) {
    const misattributedLogins = oFetch(options, 'misattributedLogins');

    return misattributedLogins.map(misattributedLogin => {
      const id = oFetch(misattributedLogin, 'id');
      return (
        <MisattributedLoginCard
          key={id}
          onMakeHandled={this.handleMarkHandled}
          misattributedLogin={misattributedLogin}
        />
      );
    });
  }
  handleFilterChange = value => {
    this.setState({ currentFilter: value });
    const search = oFetch(this.state, 'search');
    getMisattributedLogins({ currentPage: 1, filter: value, search }).then(res => {
      const misattributedLogins = oFetch(res, 'data.misattributedLogins');
      this.changeUrl({ filter: value, search });
      this.setState({
        misattributedLogins: misattributedLogins,
        currentPage: 1,
      });
    });
  };
  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    const [misattributedLogins, isLoadingMore, currentFilter, search, isSearching] = oFetch(
      this.state,
      'misattributedLogins',
      'isLoadingMore',
      'currentFilter',
      'search',
      'isSearching',
    );
    const count = oFetch(misattributedLogins, 'length');

    const inputClassNames = cn('boss-form__search', {
      'boss-form__search_state_processing': isSearching,
    });
    return (
      <main className="boss-page-main">
        <div className="boss-page-main__dashboard">
          <div className="boss-page-main__inner">
            <div className="boss-page-dashboard boss-page-dashboard_updated">
              <div className="boss-page-dashboard__group">
                <h1 className="boss-page-dashboard__title">
                  <span className="boss-page-dashboard__title-text">Missattributed Logins</span>
                  <span className="boss-page-dashboard__title-info">{count}</span>
                </h1>
                <div className="boss-page-dashboard__buttons-group">
                  <a
                    href={appRoutes.staffVettingIndex()}
                    className="boss-button  boss-button_role_primary boss-page-dashboard__button"
                  >
                    Return to Staff Vetting Index
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="boss-page-main__content">
          <div className="boss-page-main__inner">
            <div className="boss-page-main__filter">
              <div className="boss-form">
                <div className="boss-form__row">
                  <div className="boss-form__field boss-form__field_layout_max">
                    <div className={inputClassNames}>
                      <label className="boss-form__label">
                        <input
                          onChange={this.handleSearchChange}
                          name="search"
                          value={search}
                          className="boss-form__input"
                          placeholder="Search..."
                          type="text"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="boss-form__field boss-form__field_layout_min">
                    <RadioGroup
                      name="payRatesFilter"
                      selectedValue={currentFilter}
                      onChange={this.handleFilterChange}
                      className="boss-form__switcher"
                    >
                      <label className="boss-form__switcher-label">
                        <Radio
                          value={FILTER_UNHANDLED_SETTING}
                          className="boss-form__switcher-radio"
                        />
                        <span className="boss-form__switcher-label-text">Unhandled Only</span>
                      </label>
                      <label className="boss-form__switcher-label">
                        <Radio value={FILTER_ALL_SETTING} className="boss-form__switcher-radio" />
                        <span className="boss-form__switcher-label-text">Show All</span>
                      </label>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>

            {count === 0 && (
              <p className="boss-page-main__text-placeholder">There are no items to show.</p>
            )}
            {count > 0 && this.renderCards({ misattributedLogins })}
            {count > 0 &&
              (isLoadingMore ? (
                <Spinner />
              ) : (
                <LoadMoreButton
                  onClick={this.handleLoadMoreClick}
                  totalAmount={count}
                  currentAmount={misattributedLogins.length}
                />
              ))}
          </div>
        </div>
      </main>
    );
  }
}

export default MisattributedLogins;
