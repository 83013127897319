import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';

export default function PhotoUploadResult(props) {
  const [image, onReupload, onClear] = oFetch(props, 'image', 'onReupload', 'onClear');

  return (
    <Fragment>
      <div className="purple-form-upload__preview">
        <div className="purple-form-upload__preview-image">
          <img src={image} alt="John Doe" />
        </div>
        {onClear && (
          <div
            onClick={onClear}
            className="purple-form-upload__preview-action purple-form-upload__preview-action_role_clear"
          />
        )}
      </div>
      <div className="purple-form-upload__actions">
        <button
          onClick={onReupload}
          className="purple-button purple-button_color_accent-green purple-button_icon_refresh"
          type="button"
        >
          <span className="purple-button__text">Reupload</span>
        </button>
      </div>
    </Fragment>
  );
}

PhotoUploadResult.propTypes = {
  onClear: PropTypes.func,
};

PhotoUploadResult.defaultProps = {
  onClear: null,
};
