import React, { Fragment } from 'react';
import oFetch from 'o-fetch';

export default function ContactList(props) {
  const [items, itemRenderer] = oFetch(props, 'items', 'itemRenderer');

  function renderItems() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(itemRenderer(item), {
        key: itemId.toString(),
      });
    });
  }

  if (items.length === 0) {
    return <h2>There is no contacts to show</h2>;
  }

  return <Fragment>{renderItems()}</Fragment>;
}
