import React from 'react';
import { $TaxYearFormProps, TaxYearFormProps } from '../types';
import { format } from 'date-fns';
import { COMMON_DATE_FORMAT } from '@/lib/date-fns-formats';
import { Field, Form } from 'react-final-form';
import { BossFormSelectNew } from '@/components/boss-form';

type TaxFormValues = {
  year: number,
};

const START_YEAR = 2000;
const END_YEAR = 2070;
const YEARS = ((start: number, stop: number): number[] => {
  return Array.from({ length: stop - start + 1 }, (_, i) => start + i);
})(START_YEAR, END_YEAR);
const YEAR_OPTIONS = YEARS.map((year) => {
  return {
    value: year,
    label: `${(year).toString()}/${((year + 1) - 2000).toString()}`,
  };
});

export function TaxYearForm(props: TaxYearFormProps): React.JSX.Element {
  const parsedProps = $TaxYearFormProps.parse(props);
  const selectedTaxYear = parsedProps.initialValues.selectedTaxYear;
  const taxYearFormRequesting = parsedProps.taxYearFormRequesting;

  return (
    <Form<TaxFormValues>
      onSubmit={parsedProps.onSubmit}
      initialValues={{
        year: selectedTaxYear.year,
      }}
      render={(renderProps) => {
        return (
          <form
            onSubmit={renderProps.handleSubmit}
            className="boss-form"
          >
            <div className="boss-form__group boss-form__group_position_last">
              <div>
                <h3 className="boss-form__group-title">
                  Holiday Allowances
                  { taxYearFormRequesting && (
                    <div style={{ display: "inline-flex" }}>
                      <span className="boss-spinner" />
                    </div>
                  )}
                </h3>
              </div>
              <div className="boss-form__row boss-form__row_desktop boss-form__row_position_last">
                <div className="boss-form__field boss-form__field_layout_max">
                  <Field
                    name="year"
                    label="Tax Year"
                    disabled={taxYearFormRequesting}
                    value={selectedTaxYear.year}
                    clearable={false}
                    component={BossFormSelectNew}
                    simpleValue={true}
                    options={YEAR_OPTIONS}
                  />
                  <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label">
                    <button
                      className="boss-button boss-form__submit"
                      type="submit"
                      disabled={taxYearFormRequesting || renderProps.pristine}
                    >Update</button>
                  </div>
                </div>
              </div>

              <p className="boss-form__field-note">
                <span>{format(selectedTaxYear.startDate, COMMON_DATE_FORMAT)}</span>
                  <span> - </span>
                <span>{format(selectedTaxYear.endDate, COMMON_DATE_FORMAT)}</span>
              </p>
            </div>
          </form>
        );
      }}
    />
  );
}