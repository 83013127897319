import oFetch from 'o-fetch';
import React, { Component } from 'react';
import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { loadImage } from '@/components/image-resizer';
import { modalRedux } from '@/components/modals';
import { OwedHoursForm } from '@/components/boss-form';
import { ModalsProvider } from '@/components/hooks-components/modals';

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

class EditNewOwedHours extends Component {
  state = {
    isLoaded: false,
    startsAtCCTVImageBlob: null,
    endsAtCCTVImageBlob: null,
  };

  componentDidMount = async () => {
    const owedHour = oFetch(this.props, 'owedHour');
    const startsAtCCTVImage = oFetch(owedHour, 'startsAtCCTVImage');
    const endsAtCCTVImage = oFetch(owedHour, 'endsAtCCTVImage');
    const startsAtCCTVImageBlob = await this.getValue(startsAtCCTVImage);
    const endsAtCCTVImageBlob = await this.getValue(endsAtCCTVImage);
    this.setState({ startsAtCCTVImageBlob, endsAtCCTVImageBlob, isLoaded: true });
  };

  getValue = async value => {
    if (isString(value)) {
      try {
        const file = await loadImage(value, { crossOrigin: 'anonymous' });
        return file;
      } catch (e) {
        return null;
      }
    }
    return value;
  };

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    const [owedHour, onSubmit] = oFetch(this.props, 'owedHour', 'onSubmit');
    const initialValues = {
      note: oFetch(owedHour, 'note'),
      startsAt: owedHour.times.startsAt,
      endsAt: owedHour.times.endsAt,
      id: oFetch(owedHour, 'id'),
      date: oFetch(owedHour, 'date'),
      startsAtCCTVImage: oFetch(this.state, 'startsAtCCTVImageBlob'),
      endsAtCCTVImage: oFetch(this.state, 'endsAtCCTVImageBlob'),
      cctvImageHasChanged: false,
      mossHourTagIds: oFetch(owedHour, 'mossHourTagIds'),
    };
    const effectiveStaffMemberType = oFetch(this.props, 'effectiveStaffMemberType');
    const mossHourTags = oFetch(this.props, 'mossHourTags');

    return (
      <ModalsProvider>
        <div className="boss-modal-window__form">
          <OwedHoursForm
            mossHourTags={mossHourTags}
            effectiveStaffMemberType={effectiveStaffMemberType}
            onSubmit={onSubmit}
            initialValues={initialValues}
            buttonText="Update hours"
            edit
          />
        </div>
      </ModalsProvider>
    );
  }
}

export default modalRedux(combineReducers({ form: formReducer }))(EditNewOwedHours);
