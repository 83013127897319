import oFetch from 'o-fetch';
import React from 'react';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function PermissionItem(props) {
  const [permission, lastIpAddress, onDisable] = oFetch(props, 'permission', 'lastIpAddress', 'onDisable');

  const at = oFetch(permission, 'at');
  const formattedAt = safeMoment.iso8601Parse(at).format(utils.commonDateFormatWithTime());

  return (
    <div className="boss-access__permission boss-access__permission_role_granted">
      <div className="boss-access__permission-header">
        <h3 className="boss-access__permission-title">Access</h3>
      </div>
      <div className="boss-access__permission-group">
        <div className="boss-access__permission-info">
          <div className="boss-access__permission-meta">
            <div className="boss-access__permission-meta-item">
              <p className="boss-access__permission-meta-text">
                <span className="boss-access__permission-meta-text-light">Requested at: </span>
                <span className="boss-access__permission-meta-text-bold">{formattedAt}</span>
              </p>
            </div>
            <div className="boss-access__permission-meta-item">
              <p className="boss-access__permission-meta-text">
                <span className="boss-access__permission-meta-text-light">IP: </span>
                <span className="boss-access__permission-meta-text-bold">{lastIpAddress}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="boss-access__permission-actions">
          <button
            onClick={onDisable}
            className="boss-button boss-button_role_cancel boss-button_type_icon boss-button_type_small boss-access__permission-action"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
