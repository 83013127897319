import React from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { PhotoUploadField, InputField, TagsField } from '../../components/form-fields';
import { imageChangedDecorator } from './image-changed-decorator';

export default function ContactForm(props) {
  const [onSubmit, initialValues, buttonTitle, onCreateContactTag, canCreateTags] = oFetch(
    props,
    'onSubmit',
    'initialValues',
    'buttonTitle',
    'onCreateContactTag',
    'canCreateTags',
  );
  const tagOptions = oFetch(props, 'tagOptions');

  function renderBaseError(errors) {
    return (
      <div className="purple-note purple-note_color_accent-red purple-note_icon_alert">
        {errors.map((error, index) => {
          return (
            <p key={index} className="purple-note__text">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[imageChangedDecorator]}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <div className="purple-form">
            {submitError && renderBaseError(submitError)}
            <div className="purple-form__field">
              <Field name="image" parse={value => value} component={PhotoUploadField} />
            </div>
            <div className="purple-form__field">
              <Field
                name="firstName"
                label="Title / First Name"
                placeholder="Title / First Name"
                component={InputField}
              />
            </div>
            <div className="purple-form__field">
              <Field
                name="surname"
                label="Surname (optional)"
                parse={value => value}
                placeholder="Surname"
                component={InputField}
              />
            </div>
            <div className="purple-form__field">
              <Field
                name="tags"
                label="Tags"
                placeholder="Tags"
                component={TagsField}
                options={tagOptions}
                canCreateTags={canCreateTags}
                parse={value => value}
                onCreateTag={onCreateContactTag}
                valueKey="id"
                labelKey="name"
              />
            </div>
            <div className="purple-form__field purple-form__field_justify_center purple-form__field_position_last">
              <button
                className="purple-button purple-button_color_accent-primary purple-button_icon_plus"
                type="button"
                onClick={handleSubmit}
                disabled={submitting}
              >
                <span className="purple-button__text">{buttonTitle}</span>
              </button>
            </div>
          </div>
        );
      }}
    />
  );
}
