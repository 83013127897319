import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';

import { TemporaryList } from './temporary-list';

export function TemporaryAccess(props) {
  const [renderItem, temporaryAccess, onAddNew] = oFetch(props, 'renderItem', 'temporaryAccess', 'onAddNew');
  const [selectedVenue, setSelectedVenue] = useState(null);
  const venuesOptions = oFetch(props, 'venuesOptions');

  const filteredTemporaryAccess = useMemo(() => {
    if (selectedVenue === null) {
      return temporaryAccess;
    }
    return temporaryAccess.filter(
      temporaryAccessItem => oFetch(temporaryAccessItem, 'venueId') === selectedVenue,
    );
  }, [temporaryAccess, selectedVenue]);

  function handleVenueSelect(selectedVenue) {
    setSelectedVenue(selectedVenue);
  }

  return (
    <div className="boss-check__group-content">
      <div className="boss-check__group-area">
        <div
          className="boss-form"
        >
          <div className="boss-form__row">
            <div className="boss-form__field boss-form__field_role_label-small">
              <p className="boss-form__label">
                <span className="boss-form__label-text boss-form__label-text_type_narrow-inline-fluid">
                  Temporary Access
                </span>
              </p>
            </div>
            <div className="boss-form__field boss-form__field_layout_third">
              <div className="boss-form__select boss-form__select_type_narrow">
                <Select
                  value={selectedVenue}
                  valueKey={'venueId'}
                  labelKey={'venueName'}
                  onChange={handleVenueSelect}
                  clearable={true}
                  simpleValue
                  multi={false}
                  placeholder={'Select Venue'}
                  options={temporaryAccess}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-check__group-area">
        <TemporaryList
          items={filteredTemporaryAccess}
          venuesOptions={venuesOptions}
          onAddNew={onAddNew}
          renderItem={renderItem}
        />
        <div className="boss-check__buttons">
          <button
            type="button"
            onClick={onAddNew}
            className="boss-button boss-button_role_add boss-button_type_ultra-small boss-check__button"
          >
            Add New
          </button>
        </div>
      </div>
    </div>
  );
}
