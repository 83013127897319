import React, { useState, useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper, LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

import { Dashboard, ReportList, PayrollReportItem, ReportModal, WarningModal } from './components';
import * as actions from './actions';

export function SecurityPayrollReportsPage(props) {
  const { openModal } = useModal();
  const [mWeekDates, initialSecurityFinanceReports, hoursBreakdowns, weekDates, weekStart] = oFetch(
    props,
    'mWeekDates',
    'securityFinanceReports',
    'hoursBreakdowns',
    'weekDates',
    'weekStart',
  );
  const [securityFinanceReports, setSecurityFinanceReports] = useState(initialSecurityFinanceReports);

  const totalCents = oFetch(props, 'totalCents');
  const mStartDate = mWeekDates[0].clone();
  const mEndDate = mWeekDates[oFetch(mWeekDates, 'length') - 1].clone();
  const canDownloadCsv = oFetch(props, 'canDownloadCsv');
  const groupByFinanceReport = utils.groupBy('securityFinanceReportId');
  const groupByDate = utils.groupBy('date');

  const grouppedByFinanceReports = useMemo(() => {
    return Object.entries(groupByFinanceReport(hoursBreakdowns)).reduce((acc, byFinanceReportEntry) => {
      const [financeReportId, byFinanceReportBreakdowns] = byFinanceReportEntry;
      acc[financeReportId] = groupByDate(byFinanceReportBreakdowns);
      return acc;
    }, {});
  }, [hoursBreakdowns]);

  function openWarningModal(params) {
    const staffMemberId = oFetch(params, 'staffMemberId');
    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: WarningModal,
      onSubmit(handleClose, params) {
        return actions.markCompletedAction({
          staffMemberId,
          weekStart,
          onSuccess(data) {
            const updatedSecurityFinanceReports = oFetch(data, 'securityFinanceReports');
            const updatedSecurityFinanceReportsIds = updatedSecurityFinanceReports.map(report =>
              oFetch(report, 'id'),
            );
            setSecurityFinanceReports(oldSecurityFinanceReports => {
              const filteredOldSecurityFinanceReports = oldSecurityFinanceReports.filter(
                report => !updatedSecurityFinanceReportsIds.includes(oFetch(report, 'id')),
              );
              return [...filteredOldSecurityFinanceReports, ...updatedSecurityFinanceReports];
            });
            handleClose();
          },
        });
      },
      config: {
        title: 'WARNING !!!',
        baseClassName: 'boss-modal-window boss-modal-window_role_warning',
      },
    });
  }

  function openReportModal(params) {
    const [staffMemberName, financeReportId, status] = oFetch(
      params,
      'staffMemberName',
      'financeReportId',
      'status',
    );
    const isRequiringUpdate = status === 'requiring_update';

    const breakdowns = grouppedByFinanceReports[financeReportId];
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: ReportModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title: () => (
          <div
            className="boss-modal-window__header"
            style={{ backgroundColor: isRequiringUpdate && '#ed7f7e', color: 'white' }}
          >
            <span className="boss-modal-window__marked">{staffMemberName}</span> Week Details{' '}
            {isRequiringUpdate && '(Requiring Update)'}
          </div>
        ),
        baseClassName:
          'boss-modal-window boss-modal-window_type_full-height boss-modal-window_role_week-details',
      },
      props: {
        breakdowns,
        weekDates,
        calculating: isRequiringUpdate,
      },
    });
  }

  function goToSecurityPayrollReportsPage(args) {
    const mWeekStartDate = safeMoment.uiDateParse(oFetch(args, 'sUiWeekStartDate'));
    window.location.href = appRoutes.securityPayrollReports({ mWeekStartDate });
  }

  function handleDateChange(selection) {
    goToSecurityPayrollReportsPage({
      sUiWeekStartDate: oFetch(selection, 'startUIDate'),
    });
  }

  function renderReportItem(securityFinanceReport) {
    return (
      <PayrollReportItem
        securityFinanceReport={securityFinanceReport}
        mWeekDates={mWeekDates}
        onShowReportModal={openReportModal}
        onMarkComplete={openWarningModal}
      />
    );
  }

  return (
    <div className="boss-page-main boss-page-main_adjust_payroll-reports">
      <Dashboard
        title="Security Payroll Report"
        totalCents={totalCents}
        mStartDate={mStartDate}
        mEndDate={mEndDate}
        onDateChange={handleDateChange}
        canDownloadCsv={canDownloadCsv}
      />

      <ReportList
        securityFinanceReports={securityFinanceReports}
        mStartDate={mStartDate}
        itemRenderer={renderReportItem}
      />
    </div>
  );
}
