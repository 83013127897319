import oFetch from 'o-fetch';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import xorWith from 'lodash/xorWith';
import { FORM_ERROR } from 'final-form';

export const BASE_ERROR_KEY = 'base';

export function normalizeFinalFormErrors(errors) {
  if (!errors) {
    return {};
  }
  return Object.entries(errors).reduce((acc, errorEntry) => {
    const [key, value] = errorEntry;
    if (key === BASE_ERROR_KEY) {
      return {
        ...acc,
        [FORM_ERROR]: value,
      };
    } else {
      return {
        ...acc,
        [key]: value,
      };
    }
  }, {});
}

export function getTotalCounts(params) {
  const [venues, groupedByVenueWeekCounts] = oFetch(params, 'venues', 'groupedByVenueWeekCounts');
  return venues.reduce(
    (acc, venue) => {
      const venueId = oFetch(venue, 'id');
      const venueWeekDayCounts = groupedByVenueWeekCounts[venueId];
      if (venueWeekDayCounts === undefined) {
        return acc;
      }

      const venueWeekDayCount = venueWeekDayCounts[0];

      const [
        changeTotalPence,
        fiftyNotesTotalPounds,
        fiveNotesTotalPounds,
        tenNotesTotalPounds,
        twentyNotesTotalPounds,
        looseTotalPence,
        fiftyNotesBundleCount,
        fiveNotesBundleCount,
        tenNotesBundleCount,
        twentyNotesBundleCount,
      ] = oFetch(
        venueWeekDayCount,
        'weekDaysTotals.change_total_pence',
        'weekDaysTotals.fifty_notes_total_pounds',
        'weekDaysTotals.five_notes_total_pounds',
        'weekDaysTotals.ten_notes_total_pounds',
        'weekDaysTotals.twenty_notes_total_pounds',
        'looseTotalPence',
        'fiftyNotesBundleCount',
        'fiveNotesBundleCount',
        'tenNotesBundleCount',
        'twentyNotesBundleCount',
      );
      const totalBundles =
        fiftyNotesBundleCount + fiveNotesBundleCount + tenNotesBundleCount + twentyNotesBundleCount;
      const total =
        fiveNotesTotalPounds +
        tenNotesTotalPounds +
        twentyNotesTotalPounds +
        fiftyNotesTotalPounds +
        changeTotalPence / 100;
      return {
        five: acc.five + fiveNotesTotalPounds,
        ten: acc.ten + tenNotesTotalPounds,
        twenty: acc.twenty + twentyNotesTotalPounds,
        fifty: acc.fifty + fiftyNotesTotalPounds,
        changePence: acc.changePence + changeTotalPence,
        total: acc.total + total,
        loosePence: acc.loosePence + looseTotalPence,
        fiveBundles: acc.fiveBundles + fiveNotesBundleCount,
        tenBundles: acc.tenBundles + tenNotesBundleCount,
        twentyBundles: acc.twentyBundles + twentyNotesBundleCount,
        fiftyBundles: acc.fiftyBundles + fiftyNotesBundleCount,
        totalBundles: acc.totalBundles + totalBundles,
      };
    },
    {
      five: 0,
      ten: 0,
      twenty: 0,
      fifty: 0,
      changePence: 0,
      total: 0,
      loosePence: 0,
      fiveBundles: 0,
      tenBundles: 0,
      twentyBundles: 0,
      fiftyBundles: 0,
      totalBundles: 0,
    },
  );
}

export function getVenuesWeekCounts(params) {
  const [venues, groupedByVenueWeekCounts] = oFetch(params, 'venues', 'groupedByVenueWeekCounts');
  return venues.map(venue => {
    const [venueId, name] = oFetch(venue, 'id', 'name');
    const venueWeekDayCounts = groupedByVenueWeekCounts[venueId];

    if (venueWeekDayCounts === undefined) {
      return {
        venueId,
        name,
        changeTotalPence: 'N/A',
        fiftyNotesTotalPounds: 'N/A',
        fiveNotesTotalPounds: 'N/A',
        tenNotesTotalPounds: 'N/A',
        twentyNotesTotalPounds: 'N/A',
        total: 0,
        status: 'invalid',
        createdBy: null,
      };
    }
    const venueWeekDayCount = venueWeekDayCounts[0];

    const [
      changeTotalPence,
      fiftyNotesTotalPounds,
      fiveNotesTotalPounds,
      tenNotesTotalPounds,
      twentyNotesTotalPounds,
      status,
      createdBy,
    ] = oFetch(
      venueWeekDayCount,
      'weekDaysTotals.change_total_pence',
      'weekDaysTotals.fifty_notes_total_pounds',
      'weekDaysTotals.five_notes_total_pounds',
      'weekDaysTotals.ten_notes_total_pounds',
      'weekDaysTotals.twenty_notes_total_pounds',
      'status',
      'createdBy',
    );

    return {
      venueId,
      name,
      changeTotalPence: changeTotalPence / 100,
      fiftyNotesTotalPounds: fiftyNotesTotalPounds,
      twentyNotesTotalPounds: twentyNotesTotalPounds,
      tenNotesTotalPounds: tenNotesTotalPounds,
      fiveNotesTotalPounds: fiveNotesTotalPounds,
      status: status,
      createdBy,
      total:
        changeTotalPence / 100 +
        fiftyNotesTotalPounds +
        fiveNotesTotalPounds +
        twentyNotesTotalPounds +
        tenNotesTotalPounds,
    };
  });
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function isArrayEqual(arr1, arr2) {
  return isEmpty(xorWith(arr1, arr2, isEqual));
}

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
