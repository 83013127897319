import React, { useMemo } from 'react';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { CollapsibleCard } from '@/components/hooks-components';
import { MonthItemBadges } from './month-item-badges';
import * as constants from '../constants';

export function MonthItem(props) {
  const [issues, label, renderIssue] = oFetch(props, 'issues', 'label', 'renderIssue');
  const filterStates = oFetch(useLegacyGlobal(), 'filter.states');

  const monthCounts = useMemo(() => {
    const initialStates = filterStates.reduce((acc, state) => {
      if (state === constants.DISABLED_STATE) {
        acc[constants.DISABLED_STATE] = 0;
      } else if (state === constants.ACTIVE_STATE) {
        acc[constants.ACTIVE_RED_STATE] = 0;
        acc[constants.ACTIVE_AMBER_STATE] = 0;
      } else {
        acc[state] = 0;
      }

      return acc;
    }, {});
    return issues
      .filter(issue => filterStates.includes(oFetch(issue, 'state')))
      .reduce((acc, issue) => {
        const state = oFetch(issue, 'state');
        const level = oFetch(issue, 'level');
        if (state === constants.DISABLED_STATE) {
          return { ...acc, disabled: oFetch(acc, constants.DISABLED_STATE) + 1 };
        }
        if (state === constants.ACTIVE_STATE) {
          if (level === constants.RED_LEVEL_KEY) {
            return { ...acc, activeRed: oFetch(acc, constants.ACTIVE_RED_STATE) + 1 };
          }
          if (level === constants.AMBER_LEVEL_KEY) {
            return { ...acc, activeAmber: oFetch(acc, constants.ACTIVE_AMBER_STATE) + 1 };
          }
        }
        if (state === constants.PENDING_STATE) {
          return { ...acc, pending: oFetch(acc, constants.PENDING_STATE) + 1 };
        }

        throw new Error('Unexpected states');
      }, initialStates);
  }, [issues]);

  function renderIssues() {
    if (issues.length === 0) {
      return <h3>Congratulations! You have no Issues for this month!</h3>;
    }
    return issues
      .filter(issue => filterStates.includes(oFetch(issue, 'state')))
      .map(issue => {
        const issueId = oFetch(issue, 'id');
        return React.cloneElement(renderIssue(issue), {
          key: issueId,
        });
      });
  }

  return (
    <CollapsibleCard
      title={label}
      innerClassName="boss-board__content-cards"
      renderButtonGroup={() => <MonthItemBadges monthCounts={monthCounts} />}
    >
      {renderIssues()}
    </CollapsibleCard>
  );
}
