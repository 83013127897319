import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = null;

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const mossFinanceReportWeek = oFetch(action, 'payload.mossFinanceReportWeek');
      return mossFinanceReportWeek;
    },
    [types.UPDATE_MOSS_FINANCE_REPORT_WEEK]: (state, action) => {
      const mossFinanceReportWeek = oFetch(action, 'payload.mossFinanceReportWeek');
      return mossFinanceReportWeek;
    },
  },
  initialState,
);
