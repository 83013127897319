import cn from 'classnames';
import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';

export function MarkNotEligibleModalContent(props) {
  const [onClose, onSubmit, initialValues] = oFetch(props, 'onClose', 'onSubmit', 'initialValues');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, ...rest }) => {
        return (
          <Fragment>
            <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
              <div className="boss-modal-window__message-group">
                <p className="boss-modal-window__message-note">
                  <span className="boss-modal-window__message-line">
                    Please ensure supply an explanation for marking this staff member as not eligible for
                    receiving this bonus
                  </span>
                </p>
              </div>
              <div className="boss-modal-window__message-group">
                <div className="boss-form">
                  <Field
                    name="reason"
                    label="Reason"
                    parse={value => value}
                    required
                    component={BossFormTextarea}
                  />
                </div>
              </div>
            </div>
            <div className="boss-modal-window__actions">
              <button
                onClick={onClose}
                type="button"
                className="boss-button boss-button_role_inactive boss-modal-window__button"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                disabled={submitting}
                className="boss-button boss-button_role_alert boss-modal-window__button"
              >
                {'Mark "Not Eligible"'}
              </button>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
