import React from 'react';
import oFetch from 'o-fetch';
import Spinner from '@/components/spinner';
import { SecurityShiftRequestsVenueList } from './security-shift-requests-venue-list';
import { SecurityShiftRequestsVenueItem } from './security-shift-requests-venue-item';

function RequestsContent(props) {
  const [shiftRequests, isFetching, selectedVenues, venuesState, onToggleVenue] = oFetch(
    props,
    'shiftRequests',
    'isFetching',
    'selectedVenues',
    'venuesState',
    'onToggleVenue',
  );

  const venuesFilterSelected = selectedVenues.length > 0;

  function renderItems(items) {
    const itemRenderer = oFetch(props, 'itemRenderer');

    return items.map(shiftRequest => {
      const shiftRequestId = oFetch(shiftRequest, 'id');

      return React.cloneElement(itemRenderer(shiftRequest), {
        key: shiftRequestId.toString(),
      });
    });
  }

  const filteredCount = Object.values(shiftRequests).reduce((acc, items) => {
    const count = items.length;
    return acc + count;
  }, 0);

  return (
    <div className="boss-page-main__content">
      <div className="boss-page-main__inner">
        {props.children}
        {!isFetching && (
          <div className="boss-page-main__group boss-page-main__group_adjust_ssr-requests">
            {venuesFilterSelected && <h3>Filtered count: {filteredCount}</h3>}
            <SecurityShiftRequestsVenueList
              grouppedList={shiftRequests}
              itemRender={params => {
                const [venueName, items] = oFetch(params, 'venueName', 'items');
                return (
                  <SecurityShiftRequestsVenueItem
                    venueName={venueName}
                    items={items}
                    isOpen={venuesState[venueName] || false}
                    onToggleVenue={onToggleVenue}
                    requestsListRender={items => {
                      if (items.length === 0) {
                        return (
                          <div className="boss-page-main__text-placeholder">
                            There are no requests to show.
                          </div>
                        );
                      }
                      return renderItems(items);
                    }}
                  />
                );
              }}
            />
          </div>
        )}
        {isFetching && <Spinner />}
      </div>
    </div>
  );
}

export default RequestsContent;
