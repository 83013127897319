import React from 'react';
import oFetch from 'o-fetch';
import { HeaderWrapper } from '@/components/boss-header/header-wrapper';
import { Profile } from './profile';

export function BonusPaymentsHeader(props) {
  const [user, signOutPath] = oFetch(props, 'user', 'signOutPath');
  return (
    <HeaderWrapper title="Bonus Payments" url="/" prefix="boss">
      <Profile user={user} prefix="boss" signOutPath={signOutPath} />
    </HeaderWrapper>
  );
}
