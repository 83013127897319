import React from 'react';
import { WhatsappFeatureAssignment } from '../types';
import { WhatsappInstanceAvailability } from '@/apps/WhatsappServers/components/forms/whatsapp-instance-form';

type Props = {
  whatsappFeature: WhatsappFeatureAssignment;
  onEdit: (whatsappFeature: WhatsappFeatureAssignment) => void;
};

export function FeatureRow(props: Props) {
  const { whatsappFeature, onEdit } = props;

  return (
    <div className="boss-table__row">
      <div
        className="boss-table__cell"
        style={{
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <p
          className="boss-table__text"
          style={{ marginRight: '10px' }}
        >
          <span
            className="boss-table__link"
          >
            {whatsappFeature.whatsappFeatureTitle}
          </span>
        </p>
        <WhatsappInstanceAvailability
          isAvailable={whatsappFeature.isEnabled}
          displayText={true}
          text={whatsappFeature.isEnabled ? '(enabled)' : '(disabled)'}
        />
      </div>
      <div
        className="boss-table__cell"
      >
        {!whatsappFeature.mainWhatsappInstance && (
          <p className="boss-table__text">
            Not Assigned
          </p>
        )}
        {whatsappFeature.mainWhatsappInstance && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                className="boss-table__text"
                style={{ marginRight: '10px' }}
              >
                <span
                  className="boss-table__link"
                >
                  {whatsappFeature.mainWhatsappInstance.name}
                </span>
              </p>
              <WhatsappInstanceAvailability
                isAvailable={whatsappFeature.mainWhatsappInstance.isAvailable}
              />
            </div>
            <span style={{ color: 'rgb(128, 128, 128)' }}>{whatsappFeature.mainWhatsappInstance.customName ? ` (${whatsappFeature.mainWhatsappInstance.customName})` : ''}</span>
          </>
        )}
      </div>
      <div
        className="boss-table__cell"
      >
        {!whatsappFeature.fallbackWhatsappInstance && (
          <p className="boss-table__text">
            Not Assigned
          </p>
        )}
        {whatsappFeature.fallbackWhatsappInstance && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p
                className="boss-table__text"
                style={{ marginRight: '10px' }}
              >
                <span
                  className="boss-table__link"
                >
                  {whatsappFeature.fallbackWhatsappInstance.name}
                </span>
              </p>
              <WhatsappInstanceAvailability
                isAvailable={whatsappFeature.fallbackWhatsappInstance.isAvailable}
              />
            </div>
            <span style={{ color: 'rgb(128, 128, 128)' }}>{whatsappFeature.fallbackWhatsappInstance.customName ? ` (${whatsappFeature.fallbackWhatsappInstance.customName})` : ''}</span>
          </>
        )}
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <div className="boss-table__actions">
            <button
              onClick={() => onEdit(whatsappFeature)}
              className="boss-button boss-button_type_small boss-button_role_update boss-table__action"
            >Edit</button>
          </div>
        </div>
      </div>
    </div>
  );
}
