import oFetch from 'o-fetch';
import React from 'react';

export function DevicesDashboard(props) {
  const [devicesCount, currentPage] = oFetch(props, 'devicesCount', 'currentPage');

  function renderButtons() {
    if (currentPage === 'active') {
      return (
        <>
          <a
            href="/devices/inactive"
            className="boss-button boss-button_role_inactive-devices boss-page-dashboard__button"
          >
            Inactive Devices
          </a>
          <a
            href="/devices/banned"
            className="boss-button boss-button_role_banned-devices boss-page-dashboard__button"
          >
            Banned Devices
          </a>
        </>
      );
    }
    if (currentPage === 'inactive' || currentPage === 'banned') {
      return (
        <a
          href="/devices"
          className="boss-button boss-button_role_back-alt boss-page-dashboard__button"
          type="button"
        >
          Back
        </a>
      );
    }
  }

  function renderTitle() {
    if (currentPage === 'active') {
      return 'Devices';
    }
    if (currentPage === 'inactive') {
      return 'Inactive Devices';
    }
    if (currentPage === 'banned') {
      return 'Banned Devices';
    }
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_devices">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text">{renderTitle()}</span>
              <span className="boss-page-dashboard__title-info">{devicesCount}</span>
            </h1>
            <div className="boss-page-dashboard__buttons-group">{renderButtons()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
