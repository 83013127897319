import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import React from 'react';
import oFetch from 'o-fetch';
import { EmploymentStatusLabels } from '@/lib/employment-status-labels';

import { openImageModal } from '@/components/modals';

const ENABLED_STATUS_ENUM_VALUE = 'enabled';

const KEY_HANDLERS = {
  email: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Email'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  furloughed: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Furloughed'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  phone_number: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Furloughed'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  address: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');
    const oldValueJson = oldValue ? JSON.parse(oldValue) : null;
    const newValueJson = newValue ? JSON.parse(newValue) : null;

    let oldValueDescription = null;
    if (oldValueJson) {
      const description = [];
      description.push(`Address: ${oFetch(oldValueJson, 'address')}`);
      description.push(`Postcode: ${oFetch(oldValueJson, 'postcode')}`);
      description.push(`Country: ${oFetch(oldValueJson, 'country')}`);
      description.push(`County: ${oFetch(oldValueJson, 'county')}`);
      oldValueDescription = description.join(', ');
    } else {
      oldValueDescription = 'N/A';
    }
    let newValueDescription = null;
    if (newValueJson) {
      const description = [];
      description.push(`Address: ${oFetch(newValueJson, 'address')}`);
      description.push(`Postcode: ${oFetch(newValueJson, 'postcode')}`);
      description.push(`Country: ${oFetch(newValueJson, 'country')}`);
      description.push(`County: ${oFetch(newValueJson, 'county')}`);
      newValueDescription = description.join(', ');
    } else {
      newValueDescription = 'N/A';
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Address'}
        oldValue={oldValueDescription}
        newValue={newValueDescription}
      />
    );
  },
  legacy_address: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Address'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  sia_badge_details: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');
    const oldValueJson = oldValue ? JSON.parse(oldValue) : null;
    const newValueJson = newValue ? JSON.parse(newValue) : null;

    let oldValueDescription = null;
    if (oldValueJson) {
      const description = [];
      description.push(`Badge Number: ${oFetch(oldValueJson, 'sia_badge_number')}`);
      const formattedExpiryDate = safeMoment
        .uiDateParse(oFetch(oldValueJson, 'sia_badge_expiry_date'))
        .format(utils.commonDateFormatCalendar());
      description.push(`Badge Expiry Date: ${formattedExpiryDate}`);
      oldValueDescription = description.join('\n');
    } else {
      oldValueDescription = 'N/A';
    }

    let newValueDescription = null;
    if (newValueJson) {
      const description = [];
      description.push(`Badge Number: ${oFetch(newValueJson, 'sia_badge_number')}`);
      const formattedExpiryDate = safeMoment
        .uiDateParse(oFetch(newValueJson, 'sia_badge_expiry_date'))
        .format(utils.commonDateFormatCalendar());
      description.push(`Badge Expiry Date: ${formattedExpiryDate}`);
      newValueDescription = description.join('\n');
    } else {
      newValueDescription = 'N/A';
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Sia Badge Details'}
        oldValue={oldValueDescription}
        newValue={newValueDescription}
      />
    );
  },
  sage_id: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Sage ID'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  name: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Name'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  date_of_birth: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    let normalisedOldValue = oldValue;
    if (normalisedOldValue) {
      normalisedOldValue = safeMoment
        .uiDateParse(oldValue)
        .format(utils.commonDateFormatCalendar());
    }
    let normalisedNewValue = newValue;
    if (normalisedNewValue) {
      normalisedNewValue = safeMoment
        .uiDateParse(newValue)
        .format(utils.commonDateFormatCalendar());
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Start Date'}
        oldValue={normalisedOldValue}
        newValue={normalisedNewValue}
      />
    );
  },
  gender: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Gender'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  master_venue: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    let normalisedOldValue = null;
    if (oldValue) {
      normalisedOldValue = oFetch(JSON.parse(oldValue), 'name');
    } else {
      normalisedOldValue = 'N/A';
    }
    let normalisedNewValue = null;
    if (newValue) {
      normalisedNewValue = oFetch(JSON.parse(newValue), 'name');
    } else {
      normalisedNewValue = 'N/A';
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Master Venue'}
        oldValue={normalisedOldValue}
        newValue={normalisedNewValue}
      />
    );
  },
  staff_type: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Staff Type'}
        oldValue={oFetch(JSON.parse(oldValue), 'name')}
        newValue={oFetch(JSON.parse(newValue), 'name')}
      />
    );
  },
  worker_type: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Pay Rate'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  pay_rate: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Pay Rate'}
        oldValue={oFetch(JSON.parse(oldValue), 'name')}
        newValue={oFetch(JSON.parse(newValue), 'name')}
      />
    );
  },
  security_pay_rate: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Pay Rate'}
        oldValue={oFetch(JSON.parse(oldValue), 'name')}
        newValue={oFetch(JSON.parse(newValue), 'name')}
      />
    );
  },
  avatar: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <AvatarFromTo
        key={index}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  work_venues: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    const oldValueJSON = JSON.parse(oldValue);
    const newValueJSON = JSON.parse(newValue);

    return (
      <SimpleFromTo
        key={index}
        keyName={'Work Venues'}
        oldValue={oldValueJSON.map(venue => oFetch(venue, 'name')).join(', ') || 'N/A'}
        newValue={newValueJSON.map(venue => oFetch(venue, 'name')).join(', ') || 'N/A'}
      />
    );
  },
  starts_at: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    let normalisedOldValue = oldValue;
    if (normalisedOldValue) {
      normalisedOldValue = safeMoment
        .uiDateParse(oldValue)
        .format(utils.commonDateFormatCalendar());
    }
    let normalisedNewValue = newValue;
    if (normalisedNewValue) {
      normalisedNewValue = safeMoment
        .uiDateParse(newValue)
        .format(utils.commonDateFormatCalendar());
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Start Date'}
        oldValue={normalisedOldValue}
        newValue={normalisedNewValue}
      />
    );
  },
  hours_preference_note: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Hours Perference Note'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  national_insurance_number: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'National Insurance Number'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  status: params => {
    const oldValue = oFetch(params, 'oldValue');

    const eventText = oldValue === ENABLED_STATUS_ENUM_VALUE ? 'Disabled' : 'Enabled';

    return <SingleActionHistoryEvent eventText={eventText} />;
  },
  bank_account_details_last_updated_at: params => {
    return <SingleActionHistoryEvent eventText={'Bank details were updated'} />;
  },
  day_perference_note: params => {
    const index = oFetch(params, 'index');
    const oldValue = oFetch(params, 'oldValue');
    const newValue = oFetch(params, 'newValue');

    return (
      <SimpleFromTo
        key={index}
        keyName={'Day Preference Note'}
        oldValue={oldValue}
        newValue={newValue}
      />
    );
  },
  employment_status: params => {
    const index = oFetch(params, 'index');
    const oldValue = JSON.parse(oFetch(params, 'oldValue'));
    const newValue = JSON.parse(oFetch(params, 'newValue'));

    let oldValueDescription = null;
    if (oFetch(oldValue, 'length') === 0) {
      oldValueDescription = '<No Venues>';
    } else {
      oldValueDescription = oldValue.map(value => oFetch(EmploymentStatusLabels, value)).join(', ');
    }
    let newValueDescription = null;
    if (oFetch(newValue, 'length') === 0) {
      newValueDescription = '<No Venues>';
    } else {
      newValueDescription = newValue.map(value => oFetch(EmploymentStatusLabels, value)).join(', ');
    }

    return (
      <SimpleFromTo
        key={index}
        keyName={'Employment Status'}
        oldValue={oldValueDescription}
        newValue={newValueDescription}
      />
    );
  },
};

function SingleActionHistoryEvent(props) {
  const eventText = oFetch(props, 'eventText');
  return (
    <li className="boss-timeline__info-item">
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-marked">{eventText}</span>
      </p>
    </li>
  );
}

function SimpleFromTo(props) {
  const [keyName, oldValue, newValue] = oFetch(props, 'keyName', 'oldValue', 'newValue');
  return (
    <li className="boss-timeline__info-item">
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-marked">{keyName}</span>
        <span className="boss-timeline__text-faded"> was updated from </span>
        <span className="boss-timeline__text-marked">{oldValue || '<Unspecified>'}</span>
        <span className="boss-timeline__text-faded"> to </span>
        <span className="boss-timeline__text-marked">{newValue || '<Unspecified>'}</span>
      </p>
    </li>
  );
}

function AvatarFromTo(props) {
  const [oldValue, newValue] = oFetch(props, 'oldValue', 'newValue');
  return (
    <li className="boss-timeline__info-item">
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-marked">Avatar photo changed</span>
      </p>
      <div className="boss-timeline__previews">
        <div className="boss-timeline__preview boss-timeline__preview_role_action">
          <p className="boss-timeline__preview-meta">Before</p>
          <div className="boss-timeline__preview-content">
            <button
              type="button"
              onClick={() => openImageModal({ image: oldValue })}
              className="boss-avatar boss-avatar_type_small"
            >
              <img
                src={oldValue}
                className="boss-avatar__image"
              />
            </button>
          </div>
        </div>
        <div className="boss-timeline__preview boss-timeline__preview_role_action">
          <p className="boss-timeline__preview-meta">After</p>
          <div className="boss-timeline__preview-content">
            <button
              type="button"
              onClick={() => openImageModal({ config: { image: newValue } })}
              className="boss-avatar boss-avatar_type_small"
            >
              <img
                src={newValue}
                className="boss-avatar__image"
              />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

export default function HistoryItem(props) {
  const history = oFetch(props, 'history');
  const [updatedAt, updatedBy, keys] = oFetch(history, 'updatedAt', 'updatedBy', 'keys');

  return (
    <li className="boss-timeline__item boss-timeline__item_role_card">
      <div className="boss-timeline__inner boss-timeline__inner_role_card">
        <div className="boss-timeline__header boss-timeline__header_role_card">
          <h3 className="boss-timeline__title">
            <span className="boss-timeline__title-light">{updatedAt}</span>
          </h3>
        </div>
        <div className="boss-timeline__group boss-timeline__group_role_card boss-timeline__group_marked">
          <ul className="boss-timeline__info-list">
            {keys.map((key, index) => {
              const [keyName, oldValue, newValue] = oFetch(key, 'key', 'oldValue', 'newValue');
              return oFetch(KEY_HANDLERS, keyName)({ oldValue, newValue, index });
            })}
          </ul>
        </div>
        <div className="boss-timeline__group boss-timeline__group_role_card">
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">Updated by</span>
            <span className="boss-timeline__text-marked"> {updatedBy}</span>
          </p>
        </div>
      </div>
    </li>
  );
}
