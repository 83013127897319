import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = {
  canCreateTask: false,
  canBeAssigned: false,
  tickAssignedOnMe: false,
  isMarketingManager: false,
  marketingTasks: {},
  marketingTaskRequests: {},
};

export const permissionsReducer = handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const permissions = oFetch(action, 'payload.permissions');
      const [
        canCreateTask,
        canBeAssigned,
        tickAssignedOnMe,
        marketingTasks,
        marketingTaskRequests,
        isMarketingManager,
      ] = oFetch(
        permissions,
        'canCreateTask',
        'canBeAssigned',
        'tickAssignedOnMe',
        'marketingTasks',
        'marketingTaskRequests',
        'isMarketingManager',
      );
      return {
        canCreateTask,
        canBeAssigned,
        tickAssignedOnMe,
        marketingTasks,
        marketingTaskRequests,
        isMarketingManager,
      };
    },
    [t.ADD_MARKETING_TASK]: (state, action) => {
      const permissions = oFetch(action, 'payload.permissions');

      return {
        ...state,
        marketingTasks: {
          ...oFetch(state, 'marketingTasks'),
          ...permissions,
        },
      };
    },
    [t.ADD_MARKETING_TASK_REQUEST]: (state, action) => {
      const permissions = oFetch(action, 'payload.permissions');

      return {
        ...state,
        marketingTaskRequests: {
          ...oFetch(state, 'marketingTaskRequests'),
          ...permissions,
        },
      };
    },
    [t.UPDATE_MARKETING_TASK]: (state, action) => {
      const permissions = oFetch(action, 'payload.permissions');
      const { requestsPermissions = {} } = action.payload;
      return {
        ...state,
        marketingTasks: {
          ...oFetch(state, 'marketingTasks'),
          ...permissions,
        },
        marketingTaskRequests: {
          ...oFetch(state, 'marketingTaskRequests'),
          ...requestsPermissions,
        },
      };
    },
    [t.UPDATE_MARKETING_TASK_REQUEST]: (state, action) => {
      const permissions = oFetch(action, 'payload.permissions');

      return {
        ...state,
        marketingTaskRequests: {
          ...oFetch(state, 'marketingTaskRequests'),
          ...permissions,
        },
      };
    },
  },
  initialState,
);
