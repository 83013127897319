import React from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import ApiKeys from './api-keys';

export function ApiKeysApp(props) {
  const accessToken = oFetch(props, 'accessToken');
  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <ApiKeys {...props} />
    </ModalsProvider>
  );
}
