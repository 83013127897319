import React from 'react';
import cn from 'classnames';

export function CheckboxField(props) {
  const {
    input,
    label,
    required,
    disabled,
    meta: { touched, error, warning },
  } = props;

  const inputClassName = cn('boss-form__checkbox-input', {
    'boss-form__checkbox-input_state_error': touched && error,
  });

  return (
    <div className="boss-form__row">
      <div className="boss-form__field boss-form__field_role_label-small boss-form__field_position_last">
        <p className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </p>
      </div>
      <div className="boss-form__field boss-form__field_layout_max">
        <label className="boss-form__checkbox-label">
          <input
            {...input}
            type="checkbox"
            disabled={disabled}
            placeholder={label}
            checked={input.value}
            className={inputClassName}
          />
          <span className="boss-form__checkbox-label-text" />
        </label>
        {touched && error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
