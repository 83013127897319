import React, { Component } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import debounce from 'lodash/debounce';

import WithoutScannableAvatars from './without-scannable-avatars';
import { getWithoutScannableAvatars } from '../../requests';

function getFilterItems(venues, staffMembers) {
  const groupByVenue = utils.groupBy('venueId');
  return Object.entries(groupByVenue(staffMembers)).map((entry, index) => {
    const [key, staffMembers] = entry;
    const count = staffMembers.length;
    if (key === 'null') {
      return {
        iconClassName: 'boss-page-main__control_role_staff-type',
        count: count,
        title: 'Security',
        index: index,
        id: null,
      };
    }
    const venue = venues.find(venue => oFetch(venue, 'id') === parseInt(key));
    if (!venue) {
      throw new Error('Venue must be exist');
    }
    return {
      iconClassName: 'boss-page-main__control_role_venue',
      count: count,
      title: oFetch(venue, 'name'),
      index: index,
      id: oFetch(venue, 'id'),
    };
  });
}

class WithoutScannableAvatarsPage extends Component {
  state = {
    count: 0,
    initialFetching: false,
    staffMembers: [],
    venues: [],
    filteredStaffMembers: [],
    selectedFilterIndices: [],
    filterText: '',
  };

  componentDidMount = async () => {
    this.setState({ initialFetching: true });
    const response = await getWithoutScannableAvatars();
    const [venues, staffMembers] = oFetch(response, 'data.venues', 'data.staffMembers');
    const count = staffMembers.length;
    const sortedByFirstNameStaffMembers = staffMembers.sort(function(a, b) {
      const aFirstName = oFetch(a, 'firstName').toLowerCase();
      const bFirstName = oFetch(b, 'firstName').toLowerCase();

      if (aFirstName < bFirstName) {
        return -1;
      }
      if (aFirstName > bFirstName) {
        return 1;
      }
      return 0;
    });

    this.setState({
      initialFetching: false,
      count: count,
      staffMembers: sortedByFirstNameStaffMembers,
      venues: venues,
      filteredStaffMembers: sortedByFirstNameStaffMembers,
    });
  };

  filter = debounce(() => {
    const [filterText, selectedFilterIndices, staffMembers] = oFetch(
      this.state,
      'filterText',
      'selectedFilterIndices',
      'staffMembers',
    );
    const filteredByTab =
      selectedFilterIndices.length === 0
        ? staffMembers
        : staffMembers.filter(staffMember => {
            return selectedFilterIndices.includes(staffMember.venueId);
          });
    const filteredStaffMembers = utils.staffMemberFilterCamelCaseJS(filterText, filteredByTab);
    this.setState({ filteredStaffMembers: filteredStaffMembers });
  }, 200);

  handleTextFilterChange = value => {
    this.setState({ filterText: value }, () => {
      this.filter();
    });
  };

  handleFilterSelect = item => {
    const index = oFetch(item, 'id');
    const selectedFilterIndices = oFetch(this.state, 'selectedFilterIndices');
    if (selectedFilterIndices.includes(index)) {
      this.setState(
        state => ({ selectedFilterIndices: state.selectedFilterIndices.filter(i => i !== index) }),
        () => {
          this.filter();
        },
      );
    } else {
      this.setState(
        state => ({ selectedFilterIndices: [...state.selectedFilterIndices, index] }),
        () => {
          this.filter();
        },
      );
    }
  };

  render() {
    const [count, initialFetching, staffMembers, venues, filteredStaffMembers, selectedFilterIndices] = oFetch(
      this.state,
      'count',
      'initialFetching',
      'staffMembers',
      'venues',
      'filteredStaffMembers',
      'selectedFilterIndices',
    );
    if (initialFetching) {
      return null;
    }

    const filterItems = getFilterItems(venues, staffMembers);
    return (
      <WithoutScannableAvatars
        onTextFilterChange={this.handleTextFilterChange}
        tabFilterSelections={selectedFilterIndices}
        onTabFilterSelect={this.handleFilterSelect}
        staffMembers={filteredStaffMembers}
        filterItems={filterItems}
        count={count}
      />
    );
  }
}

export default WithoutScannableAvatarsPage;
