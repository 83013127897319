import React from 'react';
import { SubmissionError } from 'redux-form/immutable';
import IncidentReportForm from '../../components/incident-report-form';

import { createIncidentReport } from '../actions';

function AddIncidentReport() {
  const initialValues = {
    date: null,
    time: null,
    location: '',
    description: '',
    involvedWitnessDetails: '',
    recordedByName: '',
    cameraName: '',
    report: '',
    uninvolvedWitnessDetails: '',
    policeOfficerDetails: '',
    showOnDoorBook: false,
  };

  function submission(values, dispatch) {
    return dispatch(createIncidentReport(values.toJS())).catch(resp => {
      const errors = resp.response.data.errors;
      if (errors) {
        if (errors.base) {
          errors._error = errors.base;
        }

        const timeErrors = errors.incidentTime;
        if (timeErrors) {
          errors.date = errors.date || [];
          errors.time = errors.time || [];

          timeErrors.forEach(error => {
            errors.date.push(error);
            errors.time.push(error);
          })
        }

        throw new SubmissionError({ ...errors });
      }
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_ir-form">
      <IncidentReportForm submission={submission} initialValues={initialValues} />
    </div>
  );
}

export default AddIncidentReport;
