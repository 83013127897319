import React, { useState } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';

function ShiftRequestCard(props) {
  const [isOpened, setIsOpened] = useState(true);

  function toggleDropDown() {
    setIsOpened(!isOpened);
  }

  return (
    <section className="boss-board boss-board_context_stack">
      <header className="boss-board__header">
        <h2 className="boss-board__title">{oFetch(props, 'title')}</h2>
        <div className="boss-board__button-group">
          <button
            type="button"
            className={`boss-board__switch ${isOpened ? 'boss-board__switch_state_opened' : ''}`}
            onClick={toggleDropDown}
          />
        </div>
      </header>
      <Collapse
        isOpened={isOpened}
        className={`boss-board__content ${isOpened ? 'boss-board__content_state_opened' : ''}`}
        style={{ display: 'block' }}
      >
        {oFetch(props, 'children')}
      </Collapse>
    </section>
  );
}

ShiftRequestCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};

ShiftRequestCard.defaultProps = {
  className: '',
};

export default ShiftRequestCard;
