import { http, bossRequestHttp } from '@/lib/request-api';
import oFetch from 'o-fetch';

export function updateTradingStatusRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [trading, venueId, date] = oFetch(values, 'trading', 'venueId', 'date');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    `/api/v1/venues/${venueId}/update_trading_status`,
    {
      trading,
      date,
    }
  );
}

export function updateDeliveryDayStatusRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [isDeliveryDay, venueId, date] = oFetch(values, 'isDeliveryDay', 'venueId', 'date');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    `/api/v1/venues/${venueId}/update_delivery_day_status`,
    {
      isDeliveryDay,
      date,
    }
  );
}

export const updateStaffMemberShiftRequest = values => {
  const { shift_id } = values;
  return http({ successMessage: 'Rota Shift Updated Successfully' }).patch(
    `/api/v1/rota_shifts/${shift_id}`,
    {
      ...values,
    },
  );
};

export const deleteStaffMemberShiftRequest = shift_id => {
  return http({ successMessage: 'Rota Shift Deleted Successfully' }).delete(
    `/api/v1/rota_shifts/${shift_id}`,
  );
};

export const addShiftRequest = (values, venueId, rotaDate) => {
  return http({ successMessage: 'Rota Shift Added Successfully' }).post(
    `/api/v1/venues/${venueId}/rotas/${rotaDate}/rota_shifts`,
    {
      ...values,
    },
  );
};

export const setRotaStatusRequest = (status, venueId, rotaDate) => {
  if (!['in_progress', 'finished'].includes(status)) {
    throw Error('Wrong status');
  }
  return http().post(`/api/v1/venues/${venueId}/rotas/${rotaDate}/mark_${status}`);
};
