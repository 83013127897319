import React from 'react';
import oFetch from 'o-fetch';

export function TaskDetailsLoadingHeader(props) {
  const marketingTask = oFetch(props, 'marketingTask');
  const [title, isEmergency] = oFetch(marketingTask, 'title', 'isEmergency');

  return (
    <div className="boss-task__header">
      <div className="boss-task__header-group">
        <h2 className="boss-task__title">
          {title}
          {isEmergency && (
            <span className="boss-task__title-status">
              <span className="boss-button boss-button_type_ultra-small boss-button_role_exclamation">
                Emergency
              </span>
            </span>
          )}
        </h2>
      </div>
    </div>
  );
}
