import React from 'react';
import oFetch from 'o-fetch';
import Sortable from 'react-sortablejs';

export default function SortableQuestionsList(props) {
  const [onChange, items, itemRender, currentVenueId] = oFetch(props, 'onChange', 'items', 'itemRender', 'currentVenueId');

  function renderItems() {
    return items.map(item => {
      const itemId = oFetch(item, 'id');
      return React.cloneElement(
        itemRender({ item, currentVenueId }),
        {
          key: itemId.toString(),
        }
      );
    });
  }

  return (
    <Sortable
      options={{
        handle: '.boss-check__handle',
        animation: 250,
      }}
      onChange={onChange}
    >
      {renderItems()}
    </Sortable>
  );
}
