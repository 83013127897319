import { handleActions, combineActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = [];

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const reminderDates = oFetch(action.payload, 'handoverPlannerReminderDates');
      return reminderDates;
    },
    [combineActions(commonTypes.ADD_REMINDER, commonTypes.UPDATE_REMINDER, commonTypes.DELETE_REMINDER)]: (
      state,
      action,
    ) => {
      const payload = oFetch(action, 'payload');
      const reminder = oFetch(payload, 'reminder');
      if (reminder === null) {
        return state;
      }
      const reminderDates = oFetch(payload, 'handoverPlannerReminderDates');
      const reminderId = oFetch(reminder, 'id');
      const stateWithoutReminderDates = state.filter(
        reminderDate => oFetch(reminderDate, 'handoverPlannerReminderId') !== reminderId,
      );
      return [...stateWithoutReminderDates, ...reminderDates];
    },
  },
  initialState,
);
