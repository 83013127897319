import oFetch from 'o-fetch';
import React from 'react';
import { TemporaryList } from './temporary-list';
import { TemporaryItem } from './temporary-item';

export function AppVenue(props) {
  const [venue, onBackClick] = oFetch(props, 'venue', 'onBackClick');
  const [hasPermanent, venueName, timedPermissions] = oFetch(
    venue,
    'hasPermanent',
    'venueName',
    'timedPermissions',
  );
  const hasTimedPermissions = timedPermissions.length > 0;

  function renderItem(timedPermission) {
    return <TemporaryItem item={timedPermission} />;
  }

  return (
    <div className="boss-modal-window__overview">
      <div className="boss-overview boss-overview_page_app-access-venue-details">
        <div className="boss-overview__group">
          <button onClick={onBackClick} className="boss-overview__link boss-overview__link_role_prev">
            Back
          </button>
          <h3 className="boss-check__title" style={{ marginTop: '20px' }}>
            {venueName}
          </h3>
        </div>
        {hasPermanent && hasTimedPermissions && (
          <div className="boss-overview__group boss-overview__group_adjust_level">
            <h4 className="boss-overview__label">
              <span className="boss-overview__label-text">Permanent Access</span>
            </h4>
            <div className="boss-indicator">
              <span className="boss-indicator__badge boss-indicator__badge_color_accent-green boss-indicator__badge_size_xxs">
                <span className="boss-indicator__icon boss-indicator__icon_check-bold boss-indicator__icon_size_xxs" />
              </span>
              <span className="boss-indicator__label boss-indicator__label_type_light boss-indicator__label_color_gray boss-indicator__label_size_xs boss-indicator__label_position_after">
                Has Permanent Access
              </span>
            </div>
          </div>
        )}
        {hasPermanent && !hasTimedPermissions && (
          <div className="boss-overview__group boss-overview__group_adjust_level boss-overview__group_position_last">
            <div className="boss-indicator">
              <span className="boss-indicator__badge boss-indicator__badge_color_accent-green">
                <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
              </span>
              <span className="boss-indicator__label boss-indicator__label_type_light boss-indicator__label_size_xxl boss-indicator__label_position_after">
                Has Permanent Access
              </span>
            </div>
          </div>
        )}
        {hasTimedPermissions && <TemporaryList items={timedPermissions} renderItem={renderItem} />}
      </div>
    </div>
  );
}
