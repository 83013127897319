import React, { useState } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import classNames from 'classnames';
import Popover from 'react-popover';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import PayrollReportsFilter from './payroll-reports-filter';
import { appRoutes } from '@/lib/legacy-routes';

function Dashboard(props) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  function togglePopover() {
    setIsCalendarOpen(!isCalendarOpen);
  }

  function renderCalendar() {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={props.startDate}
          onChange={selection => props.onDateChange(selection)}
          onCancelClick={togglePopover}
        />
      </div>
    );
  }

  const { title } = props;
  const venueId = oFetch(props, 'venueId');
  const date = safeMoment.uiDateParse(oFetch(props, 'date'));
  const startDate = safeMoment
    .uiDateParse(props.startDate)
    .format(utils.commonDateFormatCalendar());
  const endDate = safeMoment.uiDateParse(props.endDate).format(utils.commonDateFormatCalendar());
  const onFilterChange = oFetch(props, 'onFilterChange');
  const filterType = oFetch(props, 'filterType');
  const popoverClass = classNames({
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
    'boss-page-dashboard__meta-item_state_opened': isCalendarOpen,
  });
  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            <div className="boss-page-dashboard__buttons-group">
              <a
                href={appRoutes.payrollReportsPdfDownload({ date, venueId, filterType })}
                className="boss-button boss-button_role_download boss-page-dashboard__button"
              >
                Download PDF
              </a>
            </div>
          </div>
          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__meta">
              <Popover
                isOpen={isCalendarOpen}
                body={renderCalendar()}
                place="below"
                tipSize={0.01}
                appendTarget={document.body}
                onOuterAction={togglePopover}
                className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                style={{ marginTop: '10px' }}
              >
                <p className={popoverClass} onClick={togglePopover}>
                  <span className="boss-page-dashboard__meta-text">{startDate}</span>
                  {' - '}
                  <span className="boss-page-dashboard__meta-text">{endDate}</span>
                </p>
              </Popover>
            </div>
            <div className="boss-page-dashboard__controls-group">
              <PayrollReportsFilter onFilterChange={onFilterChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default Dashboard;
