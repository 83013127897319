import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import { AttendanceModal } from './attendance-modal';

export function AttendanceWidget(props) {
  const [data, staffTypes, lastUpdatedAt] = oFetch(props, 'data', 'staffTypes', 'lastUpdatedAt');
  const groupByStaffType = utils.groupBy('staffTypeId');
  const formattedLastUpdatedAt = safeMoment
    .iso8601Parse(lastUpdatedAt)
    .format(utils.commonDateFormatWithTime());
  const grouppedByStaffType = groupByStaffType(data);
  const { openModal } = useModal();

  const rotaedClockedIn = data.filter(dataItem => {
    const [isRotaed, isClockedIn] = oFetch(dataItem, 'isRotaed', 'isClockedIn');
    return isRotaed && isClockedIn;
  });

  const notRotaedClockedIn = data.filter(dataItem => {
    const [isRotaed, isClockedIn] = oFetch(dataItem, 'isRotaed', 'isClockedIn');
    return !isRotaed && isClockedIn;
  });

  const rotaedNotClockedIn = data.filter(dataItem => {
    const [isRotaed, isClockedIn, isClockedOut] = oFetch(
      dataItem,
      'isRotaed',
      'isClockedIn',
      'isClockedOut',
    );
    return isRotaed && !isClockedIn && isClockedOut;
  });

  const onBreak = data.filter(dataItem => {
    const isOnBreak = oFetch(dataItem, 'isOnBreak');
    return isOnBreak;
  });

  function openRotaedClockedInModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttendanceModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title: 'Rotaed Staff Currently Clocked In',
        baseClassName: 'boss-modal-window boss-modal-window_role_attendance',
      },
      props: {
        items: rotaedClockedIn,
      },
    });
  }

  function openNotRotaedClockedInModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttendanceModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title: 'Non Rotaed Staff Currently Clocked In',
        baseClassName: 'boss-modal-window boss-modal-window_role_attendance',
      },
      props: {
        items: notRotaedClockedIn,
      },
    });
  }

  function openRotaedClockedOutModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttendanceModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title: 'Rotaed Staff Not Currently Clocked In',
        baseClassName: 'boss-modal-window boss-modal-window_role_attendance',
      },
      props: {
        items: rotaedNotClockedIn,
      },
    });
  }

  function openOnBreakModal() {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttendanceModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title: 'Staff Currently On Break',
        baseClassName: 'boss-modal-window boss-modal-window_role_attendance',
      },
      props: {
        items: onBreak,
      },
    });
  }

  function openStaffTypeClockedInModal(staffTypeName, staffMembers) {
    openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AttendanceModal,
      onSubmit(handleClose) {
        handleClose();
      },
      config: {
        title() {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{staffTypeName}</span> Staff Currently
              Clocked In
            </div>
          );
        },
        baseClassName: 'boss-modal-window boss-modal-window_role_attendance',
      },
      props: {
        items: staffMembers,
      },
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_panel boss-board_role_venue-dashboard-attendance">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_small">Attendance</h2>
        <div className="boss-board__meta">
          <p className="boss-board__meta-text">Updated at {formattedLastUpdatedAt}</p>
        </div>
      </header>
      <div className="boss-board__main">
        <div className="boss-board__manager">
          <div className="boss-board__manager-attendance">
            <div className="boss-board__manager-group boss-board__manager-group_role_section boss-board__manager-group_context_stack">
              <div className="boss-check boss-check_page_venue-dashboard-attendance">
                <div className="boss-check__content">
                  <div className="boss-check__row">
                    <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_no-border">
                      <div className="boss-number boss-number_layout_row boss-number_role_action">
                        <div className="boss-number__content">
                          <h3 className="boss-number__value">{rotaedClockedIn.length}</h3>
                          <button
                            onClick={openRotaedClockedInModal}
                            type="button"
                            className="boss-number__label boss-number__label_position_after boss-number__label_role_details"
                          >
                            Rotaed Staff Currently Clocked In
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_no-border">
                      <div className="boss-number boss-number_layout_row boss-number_role_action">
                        <div className="boss-number__content">
                          <h3 className="boss-number__value">{rotaedNotClockedIn.length}</h3>
                          <button
                            onClick={openRotaedClockedOutModal}
                            type="button"
                            className="boss-number__label boss-number__label_position_after boss-number__label_role_details"
                          >
                            Rotaed Staff Not Currently Clocked In
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_no-border">
                      <div className="boss-number boss-number_layout_row boss-number_role_action">
                        <div className="boss-number__content">
                          <h3 className="boss-number__value">{notRotaedClockedIn.length}</h3>
                          <button
                            onClick={openNotRotaedClockedInModal}
                            type="button"
                            className="boss-number__label boss-number__label_position_after boss-number__label_role_details"
                          >
                            Non Rotaed Staff Currently Clocked In
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_no-border">
                      <div className="boss-number boss-number_layout_row boss-number_role_action">
                        <div className="boss-number__content">
                          <h3 className="boss-number__value">{onBreak.length}</h3>
                          <button
                            onClick={openOnBreakModal}
                            type="button"
                            className="boss-number__label boss-number__label_position_after boss-number__label_role_details"
                          >
                            Staff Currently on Break
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-board__manager-group boss-board__manager-group_role_section boss-board__manager-group_context_stack">
              {Object.entries(grouppedByStaffType).map(entry => {
                const [staffTypeIdString, staffMembers] = entry;
                const staffType = staffTypes.find(
                  staffType => oFetch(staffType, 'id') === parseInt(staffTypeIdString),
                );

                if (!staffType) {
                  throw new Error(`Staff type doesn't exist`);
                }

                const staffMembersClockedIn = staffMembers.filter(
                  staffMember => oFetch(staffMember, 'isClockedIn') === true,
                );
                const staffMembersClockedInCount = staffMembersClockedIn.length;
                if (staffMembersClockedInCount === 0) {
                  return null;
                }
                const [staffTypeName, color] = oFetch(staffType, 'name', 'color');
                return (
                  <button
                    key={staffTypeIdString}
                    onClick={() =>
                      openStaffTypeClockedInModal(staffTypeName, staffMembersClockedIn)
                    }
                    className="boss-tag boss-tag_size_m boss-tag_role_action"
                    style={{ backgroundColor: color }}
                  >
                    <div className="boss-tag__service">{staffMembersClockedInCount}</div>
                    <p className="boss-tag__text">{staffTypeName}</p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
