import React, { useState, useEffect, useMemo } from 'react';
import { useSecurityShiftRequests } from './security-rota-shift-requests-hooks';
import RequestsPage from './components/requests-page/requests-page';
import AssignPage from './components/assign-page/assign-page';
import { BOSS_APP_TYPE, RotaDateUIUtils } from '@/lib/rota-date';

export function SecurityRotaShiftRequestsPage(props) {
  const appType = BOSS_APP_TYPE;
  const {
    startDate,
    endDate,
    selectedDate,
    onWeekChange,
    counts,
    shiftRequests,
    onVenueChange,
    selectedVenues,
    onRejectSecurityShiftRequest,
    assigningShiftRequest,
    onOpenAssignPage,
    onCloseAssignPage,
    onAssignShiftRequest,
    staffMembers,
    isFetching,
    totalCount,
  } = useSecurityShiftRequests(props);
  const sSelectedDate = selectedDate;
  const [venuesState, setVenuesState] = useState([]);
  const timeSelectOptions = useMemo(() => {
    const graphRotaDate = RotaDateUIUtils.sFromDate({ sDate: sSelectedDate, appType });
    return graphRotaDate.getSelectOptions({ intervalSeconds: 60 * 30 });
  }, [sSelectedDate]);

  useEffect(() => {
    setVenuesState(prevState => {
      return Object.keys(shiftRequests).reduce((acc, item) => {
        acc[item] = prevState[item] || false;
        return acc;
      }, {});
    });
  }, [shiftRequests]);

  function handleToggleVenue(toggleVenueName) {
    setVenuesState(prevState => {
      return { ...prevState, [toggleVenueName]: !prevState[toggleVenueName] };
    });
  }

  if (assigningShiftRequest === null) {
    return (
      <RequestsPage
        counts={counts}
        startDate={startDate}
        endDate={endDate}
        totalCount={totalCount}
        sSelectedDate={sSelectedDate}
        onWeekChange={onWeekChange}
        venuesState={venuesState}
        onToggleVenue={handleToggleVenue}
        isFetching={isFetching}
        shiftRequests={shiftRequests}
        onVenueChange={onVenueChange}
        selectedVenues={selectedVenues}
        onOpenAssignPage={onOpenAssignPage}
        onRejectSecurityShiftRequest={onRejectSecurityShiftRequest}
      />
    );
  }

  if (assigningShiftRequest) {
    return (
      <AssignPage
        appType={appType}
        timeSelectOptions={timeSelectOptions}
        sSelectedDate={sSelectedDate}
        assigningShiftRequest={assigningShiftRequest}
        onAssignShiftRequest={onAssignShiftRequest}
        onCloseAssignPage={onCloseAssignPage}
        staffMembers={staffMembers}
      />
    );
  }
}
