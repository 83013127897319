import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  redLevel: null,
  amberLevel: null,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const [redLevel, amberLevel] = oFetch(action.payload, 'alertLevelTypes');
      return {
        redLevel,
        amberLevel,
      };
    },
  },
  initialState,
);
