import React from 'react';
import { DashboardWrapper } from '@/components/dashboard';

export function Dashboard() {
  return (
    <DashboardWrapper classes='boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_hours-confirmation'>
      <div className="boss-page-dashboard__group">
        <h1 className="boss-page-dashboard__title">Whatsapp Features</h1>
      </div>
    </DashboardWrapper>
  );
}
