import React from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { AssignFormField } from './assign-form-field';

export function ReassignTaskForm(props) {
  const initialValues = oFetch(props, 'initialValues');
  const currentUserId = oFetch(props, 'currentUserId');
  const userOptions = oFetch(props, 'userOptions');
  const onSubmit = oFetch(props, 'onSubmit');

  return (
    <div style={{ width: '400px', padding: '25px' }}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, submitError, values, ...rest }) => {
          return (
            <>
              <Field
                name="userId"
                currentUserId={currentUserId}
                options={userOptions}
                component={AssignFormField}
              />
              <div className="boss-form__field">
                <button
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitting}
                  className="boss-button boss-form__submit"
                >
                  Reassing
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}
