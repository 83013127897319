import React from 'react';
import oFetch from 'o-fetch';

export function WarningModalContent(props) {
  const [onSubmit, text, buttonText] = oFetch(props, 'onSubmit', 'text', 'buttonText');

  return (
    <div className="boss-modal-window__content">
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">{text}</span>
      </div>
      <div className="boss-modal-window__actions">
        <button onClick={onSubmit} type="button" className="boss-button boss-button_role_cancel">
          {buttonText}
        </button>
      </div>
    </div>
  );
}

