export default {
  INITIAL_LOAD: 'INITIAL_LOAD',
  SET_ANSWER: 'SET_ANSWER',
  SET_UPLOAD: 'SET_UPLOAD',
  REMOVE_UPLOAD: 'REMOVE_UPLOAD',
  SAVE_ANSWERS: 'SAVE_ANSWERS',
  SAVE_ANSWERS_REQUEST: 'SAVE_ANSWERS_REQUEST',
  SAVE_ANSWERS_RECEIVE: 'SAVE_ANSWERS_RECEIVE',
  SET_WRONG_FILES: 'SET_WRONG_FILES',
  REMOVE_FROM_WRONG_FILES: 'REMOVE_FROM_WRONG_FILES',
  SAVE_ANSWERS_FAILURE: 'SAVE_ANSWERS_FAILURE',
};
