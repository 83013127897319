import RotaChartInner from './rota-chart-inner';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import calculateChartBoundaries from './calculate-chart-boundaries';
import _ from 'underscore';
import oFetch from 'o-fetch';

/**
This is a wrapper around the D3 rota chart that handles small state changes
like hover highlighting that don't work well with a full re-render of the chart
(due to e.g. mouseenter being re-triggered when a bar is replaced while under the cursor
... which in turn would cause a re-render).
 */
export default function RotaChart(props) {
  const rotaShifts = oFetch(props, 'rotaShifts');
  const appType = oFetch(props, 'appType');
  var chartBoundaries = calculateChartBoundaries({
    rotaShifts,
    appType,
  });

  useEffect(() => {
    require('./style.css');
  }, []);

  return (
    <RotaChartInner
      appType={appType}
      rotaShifts={_(props.rotaShifts).sortBy(item => item.startsAt)}
      startTime={chartBoundaries.start}
      endTime={chartBoundaries.end}
      staff={props.staff}
      onShiftClick={props.onShiftClick}
    />
  );
}

RotaChart.propTypes = {
  rotaShifts: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  onShiftClick: PropTypes.func.isRequired,
};
