import React from 'react';
import oFetch from 'o-fetch';

export function AppAccessList(props) {
  const [appPermissions, renderItem] = oFetch(props, 'appPermissions', 'renderItem');

  function renderList() {
    return appPermissions.map(appPermission => {
      const appId = oFetch(appPermission, 'appId');
      return React.cloneElement(renderItem(appPermission), { key: appId });
    });
  }

  return <div className="boss-details__value">{renderList()}</div>;
}
