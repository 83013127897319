import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import Spinner from '@/components/spinner';

export default class ReminderDateItem extends Component {
  state = {
    isOpened: false,
    isProcessing: false,
  };
  toggleDropdown = () => {
    this.setState(state => ({ isOpened: !oFetch(state, 'isOpened') }));
  };
  onCheckboxClick = params => {
    const [e, id] = oFetch(params, 'e', 'id');
    const checked = e.target.checked;
    const onCheckboxClick = oFetch(this.props, 'onCheckboxClick');
    this.setState({ isProcessing: true });
    return onCheckboxClick({
      checked,
      id,
    }).then(() => {
      this.setState({ isProcessing: false });
    });
  };
  renderCheckbox = params => {
    const [reminderDate, checked] = oFetch(params, 'reminderDate', 'checked');
    const id = oFetch(reminderDate, 'handoverPlannerReminderId');
    return (
      <input
        name="dismiss"
        value="yes"
        type="checkbox"
        onChange={e => this.onCheckboxClick({ e, id })}
        checked={checked}
        className="boss-check__checkbox-input"
      />
    );
  };
  renderActions = params => {
    const [reminderDate, checked] = oFetch(params, 'reminderDate', 'checked');
    if (checked === true) {
      return null;
    }
    const [onEditClick, onDeleteClick] = oFetch(this.props, 'onEditClick', 'onDeleteClick');
    const permissions = oFetch(this.props, 'permissions');
    const reminderDatePermission = permissions[oFetch(reminderDate, 'handoverPlannerReminderId')];

    const [canEdit, canDelete] = oFetch(reminderDatePermission, 'canEdit', 'canDelete');
    return (
      <div className="boss-check__header-actions">
        {canEdit && (
          <div
            onClick={() => onEditClick(reminderDate)}
            className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_edit"
          />
        )}
        {canDelete && (
          <div
            onClick={() => onDeleteClick({ reminderDate })}
            className="boss-check__action-link boss-check__action-link_type_icon boss-check__action-link_role_remove"
          />
        )}
      </div>
    );
  };
  render() {
    const [isOpened, isProcessing] = oFetch(this.state, 'isOpened', 'isProcessing');
    const reminderDate = oFetch(this.props, 'reminderDate');
    const reminderDateState = oFetch(reminderDate, 'state');
    const checked = reminderDateState === 'completed';
    const [title, description, sortData, createdBy, createdAt, recurrenceTypeText, isSingleType] = oFetch(
      reminderDate,
      'title',
      'description',
      'sortData',
      'createdBy',
      'createdAt',
      'recurrenceTypeText',
      'isSingleType',
    );

    const arrowClassNames = cn('boss-check__dropdown-link boss-check__dropdown-link_type_icon', {
      'boss-check__dropdown-link_state_closed': !isOpened,
    });
    const reminderDateClassNames = cn('boss-check boss-check_role_panel boss-check_page_handover-planner-reminder', {
      'boss-check_state_inactive-faded': checked,
    });
    const titleClassNames = cn('boss-check__title', {
      'boss-check__title_adjust_crossed-out': checked,
    });
    const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.humanDateFormatWithTime());

    return (
      <div data-id={sortData} className={reminderDateClassNames}>
        <div className="boss-check__handle boss-check__handle_position_left boss-check__handle_role_reminder" />
        <div className="boss-check__header">
          {isProcessing ? (
            <Spinner />
          ) : (
            <div className="boss-check__header-checkbox">
              <label className="boss-check__checkbox-label">
                {this.renderCheckbox({ reminderDate, checked })}
                <span className="boss-check__checkbox-label-text boss-check__checkbox-label-text_visible-s">
                  Mark Completed
                </span>
              </label>
            </div>
          )}
          <div className="boss-check__header-info">
            <div className="boss-check__header-group">
              <h3 className={titleClassNames}>
                {title}
                {!isSingleType && (
                  <span className="boss-button boss-button_type_icon boss-button_type_ultra-small boss-button_role_repeat boss-button_type_no-behavior boss-check__title-label" />
                )}
              </h3>
            </div>
            <div className="boss-check__header-actions">
              {this.renderActions({ reminderDate, checked })}
              <div onClick={this.toggleDropdown} className={arrowClassNames}>
                Toggle Dropdown
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpened={isOpened} className="boss-check__dropdown" style={{ display: 'block' }}>
          <div className="boss-check__group boss-check__group_marked">
            <div className="boss-check__group-content">
              {!isSingleType && (
                <div className="boss-check__meta">
                  <div className="boss-check__header-meta-item">
                    <p className="boss-check__text">
                      <span className="boss-check__text-label">Recurrence Type: </span>
                      {recurrenceTypeText}
                    </p>
                  </div>
                </div>
              )}
              <div className="boss-check__box">
                <p className="boss-check__text">{description}</p>
              </div>
            </div>
          </div>
          <div className="boss-check__group">
            <div className="boss-check__group-content">
              <div className="boss-check__header-meta">
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Created by </span>
                    <span className="boss-check__text-marked">{createdBy}</span>
                    <span className="boss-check__text-light"> at </span>
                    <span className="boss-check__text-marked">{formattedCreatedAt}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
