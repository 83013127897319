import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import { BossFormInput } from '@/components/boss-form';

export function ForSpecialSecurityHoursSecurityPayRateForm(props) {
  const [onSubmit, initialValues, isEdit] = oFetch(props, 'onSubmit', 'initialValues', 'isEdit');
  return (
    <div className="boss-modal-window__form">
      <Form
        onSubmit={(formValues, ...rest) => {
          const rawStandardHourlyRateCents = oFetch(formValues, 'standardHourlyRateCents');
          const rawWeekendHourlyRateCents = oFetch(formValues, 'weekendHourlyRateCents');

          const values = {
            ...formValues,
            standardHourlyRateCents:
              rawStandardHourlyRateCents &&
              new BigNumber(rawStandardHourlyRateCents).times(100).integerValue().toNumber(),
            weekendHourlyRateCents:
              rawWeekendHourlyRateCents &&
              new BigNumber(rawWeekendHourlyRateCents).times(100).integerValue().toNumber(),
          };

          return onSubmit(values, ...rest);
        }}
        initialValues={initialValues}
        className="boss-form"
        render={({ handleSubmit, submitting, ...rest }) => {
          return (
            <div className="boss-table__form">
              <div className="boss-form">
                <Field name="name" parse={value => value} label="Name" component={BossFormInput} />
                <Field
                  name="standardHourlyRateCents"
                  label="Standard Rate"
                  unit="£"
                  parse={value => value}
                  isCurrency
                  component={BossFormInput}
                />
                <Field
                  name="weekendHourlyRateCents"
                  label="Weekend Rate"
                  unit="£"
                  parse={value => value}
                  isCurrency
                  component={BossFormInput}
                />
                <div className="boss-form__field">
                  {!isEdit && (
                    <button
                      onClick={handleSubmit}
                      className="boss-button boss-button_role_add boss-form__submit"
                      disabled={submitting}
                      type="submit"
                    >
                      Create Special Pay Rate
                    </button>
                  )}
                  {isEdit && (
                    <button
                      onClick={handleSubmit}
                      className="boss-button boss-form__submit"
                      disabled={submitting}
                      type="submit"
                    >
                      Update Special Pay Rate
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
