import { createSelector } from 'reselect';
import oFetch from 'o-fetch';

export const currentChecklistSelector = state => oFetch(state, 'checklist');
export const staffTypesSelector = state => oFetch(state, 'staffTypes');
export const assignedStaffTypesSelector = state => oFetch(state, 'assignedStaffTypes');

export const staffTypesOptions = createSelector(
  [staffTypesSelector, assignedStaffTypesSelector],
  (staffTypes, assignedStaffTypes) => {
    const assignedStaffTypesIds = assignedStaffTypes.map(assignedStaffType => oFetch(assignedStaffType, 'id'));
    return staffTypes.reduce((acc, staffType) => {
      const [staffTypeId, name, color] = oFetch(staffType, 'id', 'name', 'color');
      if (assignedStaffTypesIds.includes(staffTypeId)) {
        return acc;
      }
      return [
        ...acc,
        {
          value: staffTypeId,
          label: name,
          color,
        },
      ];
    }, []);
  },
);
