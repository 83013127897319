import React, { Component } from 'react';
import Select from '@/lib/boss-react-select';
import oFetch from 'o-fetch';

export function VenuesAssignmentsFormField(props) {
  const [input, meta, options] = oFetch(props, 'input', 'meta', 'options');
  const [tags, onChange] = oFetch(input, 'value', 'onChange');
  const tagsExist = tags.length > 0;

  function getOptions() {
    return options.filter(option => {
      return !tags.includes(oFetch(option, 'value'));
    });
  }

  function handleChange(selected) {
    onChange([...tags, selected]);
  }

  function handleDelete(deleteTag) {
    const newTags = tags.filter(tag => tag !== deleteTag);
    onChange(newTags);
  }

  function renderTag(tag) {
    const optionItem = options.find(option => oFetch(option, 'value') === tag);
    const label = oFetch(optionItem, 'label');

    return (
      <div key={tag} className="boss-tag boss-tag_size_s boss-tag_color_accent-blue">
        <p className="boss-tag__text">{label}</p>
        <button
          onClick={() => handleDelete(tag)}
          className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
        />
      </div>
    );
  }

  function renderTags() {
    return (
      <div className="boss-form__tags">
        {tags.map(tag => {
          return renderTag(tag);
        })}
      </div>
    );
  }

  return (
    <div className="boss-form__field">
      {tagsExist && renderTags()}
      <div className="boss-form__select boss-form__select_type_empty-value">
        <Select options={getOptions()} onChange={handleChange} simpleValue />
      </div>
    </div>
  );
}
