import React, { useState } from 'react';
import Cropper from 'react-cropper';
import oFetch from 'o-fetch';
import imageCompression from 'browser-image-compression';

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1024,
};

export default function PhotoCropper(props) {
  const [cropper, setCropper] = useState();
  const [processing, setProcessing] = useState(false);
  const [source, onSubmit, onClose] = oFetch(props, 'source', 'onSubmit', 'onClose');
  const isCropperInitialized = !!cropper;
  const isButtonDisabled = !isCropperInitialized || processing;

  function handleRotateRight() {
    cropper.rotate(90);
  }

  function handleRotateLeft() {
    cropper.rotate(-90);
  }

  function handleCrop() {
    setProcessing(true);
    setTimeout(async () => {
      const croppedPhoto = cropper.getCroppedCanvas().toDataURL();
      const file = await imageCompression.getFilefromDataUrl(croppedPhoto, 'photo.jpg');
      const compressedFile = await imageCompression(file, options);
      const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
      return onSubmit(base64);
    }, 500);
  }

  return (
    <div className="purple-form">
      <div className="purple-form__field purple-form__field_justify_center purple-form__field_position_last">
        <div className="purple-image-editor">
          <div className="purple-image-editor__cropper-area">
            <div className="purple-image-editor__cropper">
              <Cropper
                zoomOnWheel={false}
                src={source}
                viewMode={1}
                aspectRatio={1}
                responsive={true}
                checkOrientation={false}
                movable={false}
                preview=".purple-image-editor__preview"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                initialAspectRatio={1}
                guides={true}
                onInitialized={setCropper}
              />
            </div>
            <div className="purple-image-editor__cropper-actions">
              <button
                type="button"
                onClick={handleRotateLeft}
                disabled={isButtonDisabled}
                className="purple-button purple-button_size_xs purple-button_color_gray-light purple-button_icon_rotate-left purple-image-editor__cropper-action"
              >
                Rotate Left
              </button>
              <button
                type="button"
                disabled={isButtonDisabled}
                onClick={handleRotateRight}
                className="purple-button purple-button_size_xs purple-button_color_gray-light purple-button_icon_rotate-right purple-image-editor__cropper-action"
              >
                Rotate Right
              </button>
            </div>
          </div>
          <div className="purple-image-editor__preview-area">
            <h3 className="purple-image-editor__preview-title">Your photo</h3>
            <div
              className="purple-image-editor__preview"
              alt="preview"
              style={{ width: '200px', height: '200px' }}
            />
            <div className="purple-image-editor__preview-actions">
              <button
                type="button"
                className="purple-button purple-button_size_l purple-button_color_accent-red purple-button_icon_close purple-image-editor__preview-action"
                disabled={isButtonDisabled}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={isButtonDisabled}
                onClick={handleCrop}
                className="purple-button purple-button_size_l purple-button_color_accent-green purple-button_icon_check purple-image-editor__preview-action"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
