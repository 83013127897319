import React from 'react';
import AddMultipleShiftForm from './add-multiple-shift-form';
import oFetch from 'o-fetch';

class AddMultipleShift extends React.Component {
  render() {
    const sRotaDate = oFetch(this.props, 'sRotaDate');

    const initialValues = {
      starts_at: null,
      ends_at: null,
      shift_type: "normal",
    };

    return (
      <AddMultipleShiftForm
        initialValues={initialValues}
        sRotaDate={sRotaDate}
        rotaStatus={this.props.rotaStatus}
      />
    );
  }
}

export default AddMultipleShift;
