import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import EditIndicator from '@/components/edit-indicator';
import DashboardWrapper from '../dashboard-wrapper';
import ChecklistList from './checklist-list';
import ChecklistItem from '../checklist-item';
import EditChecklist from './edit-checklist';
import { openCustomContentModal } from '@/components/modals';
import QRCodeModalContent from '../qrcode-modal-content';

export default class EditMode extends React.Component {
  renderChecklistTitle = checklist => {
    const checklistName = oFetch(checklist, 'name');
    return <h2 className="boss-board__title">{checklistName}</h2>;
  };

  renderChecklistItem = (checklist, index) => {
    const itemDescription = oFetch(checklist, 'description');

    return (
      <div key={index} className="boss-checklist__item">
        <div className="boss-checklist__control">
          <p className="boss-checklist__label">
            <span className="boss-checklist__label-text">{itemDescription}</span>
          </p>
        </div>
      </div>
    );
  };

  renderItem = checklist => {
    const items = oFetch(checklist, 'items');
    return (
      <ChecklistItem
        checklist={checklist}
        actionRenderer={this.renderActions}
        titleRenderer={this.renderChecklistTitle}
      >
        <div className="boss-checklist__items">{items.map(this.renderChecklistItem)}</div>
      </ChecklistItem>
    );
  };

  openEditAssignments = checklist => {
    const editAssignmentsUrl = oFetch(checklist, 'editAssignmentsUrl');
    window.location = editAssignmentsUrl;
  };

  openQRCodeModal = (checklist, onPrintGuid) => {
    const guid = oFetch(checklist, 'guid');
    const checklistId = oFetch(checklist, 'id');
    const checklistName = oFetch(checklist, 'name');

    openCustomContentModal({
      submit: null,
      config: {
        modalClass: 'boss-modal-window boss-modal-window_role_action',
        title: 'QR Code',
        innerClose: false,
      },
      props: {
        guid,
        checklistId,
        checklistName,
        onPrintGuid,
      },
    })(QRCodeModalContent);
  };

  renderActions = checklist => {
    const [
      onDeleteChecklist,
      onEditChecklist,
      onPrintGuid,
    ] = oFetch(
      this.props,
      'onDeleteChecklist',
      'onEditChecklist',
      'onPrintGuid',
    );
    const checklistId = oFetch(checklist, 'id');

    return (
      <div className="boss-board__button-group boss-board__button-group_role_actions">
        <div className="boss-board__button-group boss-board__button-group_role_edit">
          <button
            onClick={() => this.openQRCodeModal(checklist, onPrintGuid)}
            type="button"
            className="boss-button boss-button_type_small boss-button_role_view-qrcode boss-board__action"
          >
            QR Code
          </button>
          <button
            onClick={() => this.openEditAssignments(checklist)}
            type="button"
            className="boss-button boss-button_type_small boss-button_role_view-assignments boss-board__action"
          >
            Assignments
          </button>
          <button
            onClick={() => onEditChecklist({ checklistId })}
            type="button"
            className="boss-button boss-button_type_small boss-button_role_edit-mode boss-board__action"
          >
            Edit
          </button>
          <AsyncButton
            className="boss-button boss-button_type_small boss-button_role_cancel boss-board__action"
            text="Delete"
            pendingText="Deleting ..."
            onClick={() => onDeleteChecklist({ checklistId })}
          />
        </div>
      </div>
    );
  };

  renderEditChecklist = editChecklistItemId => {
    const [checklists, onUpdateChecklist] = oFetch(this.props, 'checklists', 'onUpdateChecklist');
    const checklist = checklists.find(checklist => oFetch(checklist, 'id') === editChecklistItemId);
    if (!checklist) {
      throw new Error('Checklist must be present');
    }
    return <EditChecklist onSubmit={onUpdateChecklist} checklist={checklist} />;
  };

  render() {
    const [
      onCancelEditMode,
      checklists,
      onCreateChecklist,
      onCancelEditChecklist,
      onPrintGuid,
    ] = oFetch(
      this.props,
      'onCancelEditMode',
      'checklists',
      'onCreateChecklist',
      'onCancelEditChecklist',
      'onPrintGuid',
    );
    const editChecklistItemId = oFetch(this.props, 'editChecklistId');

    return (
      <div>
        <EditIndicator />
        <DashboardWrapper title="Checklist">
          {editChecklistItemId === null && (
            <button
              onClick={onCancelEditMode}
              type="button"
              className="boss-button boss-button_role_cancel boss-page-dashboard__button"
            >
              Cancel
            </button>
          )}
          {editChecklistItemId !== null && (
            <button
              onClick={onCancelEditChecklist}
              type="button"
              className="boss-button boss-button_role_cancel boss-page-dashboard__button"
            >
              Cancel edit checklist
            </button>
          )}
        </DashboardWrapper>
        {editChecklistItemId === null && (
          <ChecklistList
            onPrintGuid={onPrintGuid}
            onCreateChecklist={onCreateChecklist}
            itemRenderer={this.renderItem}
            checklists={checklists}
          />
        )}
        {editChecklistItemId !== null && this.renderEditChecklist(editChecklistItemId)}
      </div>
    );
  }
}
