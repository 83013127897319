import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { apiRoutes } from '@/lib/legacy-routes';
import utils from '@/lib/utils';

export function createSiteIssue(params) {
  const createSiteIssuePath = oFetch(apiRoutes, 'createSiteIssue.getPath')();
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [venueId, description, date, level] = oFetch(values, 'venueId', 'description', 'date', 'level');

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'description', 'date', 'level'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Create site issue failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(createSiteIssuePath, {
    venueId,
    description,
    date,
    level,
  });
}

export function updateSiteIssue(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const [id, description, date, level] = oFetch(values, 'id', 'description', 'date', 'level');
  const updateSiteIssuePath = oFetch(apiRoutes, 'updateSiteIssue.getPath')(id);

  return bossRequestHttp({
    errorHandler(params) {
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: ['base', 'description', 'date', 'level'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({ message: 'Update site issue failed' });
        return Promise.resolve(normalizedErrors);
      }
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).put(updateSiteIssuePath, {
    description,
    date,
    level,
  });
}

export function activateSiteIssue(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const id = oFetch(values, 'id');
  const activateSiteIssuePath = oFetch(apiRoutes, 'activateSiteIssue.getPath')(id);

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Activate site issue failed' });
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(activateSiteIssuePath);
}

export function setPendingSiteIssue(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const id = oFetch(values, 'id');
  const setPendingSiteIssuePath = oFetch(apiRoutes, 'setPendingSiteIssue.getPath')(id);

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Set Pending Site Issue failed' });
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(setPendingSiteIssuePath);
}

export function disableSiteIssue(params) {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');
  const id = oFetch(values, 'id');
  const disableSiteIssuePath = oFetch(apiRoutes, 'disableSiteIssue.getPath')(id);

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage({ message: 'Disable site issue failed' });
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).delete(disableSiteIssuePath);
}
