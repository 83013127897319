import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../../../profile-wrapper/constants';

const initialState = [];

export default handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const staffMemberQuizCategories = oFetch(action, 'payload.staffMemberQuizCategories');
      return staffMemberQuizCategories;
    },
  },
  initialState,
);
