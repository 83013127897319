import React, { Component } from 'react';
import { appRoutes } from '@/lib/legacy-routes';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';

export default class PostponeSuccess extends Component {
  render() {
    const [onSubmit, postponeDate, venueId] = oFetch(this.props, 'onSubmit', 'postponeDate', 'venueId');
    const mPostponeDate = safeMoment.uiDateParse(postponeDate);
    return (
      <div
        className="boss-modal-window__content boss-modal-window__content_role_success"
        data-test-marker-pin-modal="true"
      >
        <div className="boss-modal-window__message-block">
          <p className="boss-modal-window__message-text">
            <span className="boss-modal-window__message-line">Task postponed successfully to</span>
            <span className="boss-modal-window__message-line">{postponeDate}</span>
            <span className="boss-modal-window__message-line">
              <a
                href={appRoutes.handoverPlannersWeekView({ mDate: mPostponeDate, venueId: venueId })}
                className="boss-modal-window__action boss-modal-window__action_role_details"
              >
                View on Calendar
              </a>
            </span>
          </p>
        </div>

        <div className="boss-modal-window__actions">
          <button onClick={onSubmit} type="button" className="boss-button boss-modal-window__button">
            Dismiss
          </button>
        </div>
      </div>
    );
  }
}
