import oFetch from 'o-fetch';
import React from 'react';
import { DetailsCard } from './details-card';

export function NotLinkedUserAccessDetails(props) {
  const [user, onEditClick] = oFetch(props, 'user', 'onEditClick');

  const [role, venuesString, isDisabled, appNames] = oFetch(user, 'roleText', 'venuesString', 'isDisabled', 'unlinkedAppNames');

  function renderVenues() {
    return venuesString || 'N/A';
  }

  return (
    <DetailsCard
      number={3}
      title="Access Details"
      baseClassName={'boss-page-main__isle_adjust_full'}
      onEditClick={!isDisabled && onEditClick}
    >
      <ul className="boss-details__list">
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">App</p>
          <p className="boss-details__value">{appNames.join(' & ')}</p>
        </li>
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">Role</p>
          <p className="boss-details__value">{role}</p>
        </li>
        <li className="boss-details__item">
          <p className="boss-details__label boss-details__label_size_small">Venue Access</p>
          <p className="boss-details__value">{renderVenues()}</p>
        </li>
      </ul>
    </DetailsCard>
  );
}
