import Immutable from 'immutable';
import constants from '../constants';
import oFetch from 'o-fetch';

const initialState = Immutable.Map({
  questions: [],
  categories: [],
  venues: [],
  answers: [],
  frontend: {
    loading: true,
    saving: false,
    saved: false,
    failed: false,
  },
});

const venueHealthCheckReport = (state = initialState, action) => {
  switch (action.type) {
    case constants.INITIAL_LOAD:
      const initialData = oFetch(action, 'initialData');
      return state
        .set('questions', oFetch(initialData, 'questions'))
        .set('response', oFetch(initialData, 'response'))
        .set('answers', oFetch(initialData, 'answers'))
        .set('user', oFetch(initialData, 'user'))
        .set('categories', oFetch(initialData, 'categories'))
        .set('venues', oFetch(initialData, 'venues'))
        .set('scores', oFetch(initialData, 'scores'))
        .set('currentVenue', oFetch(initialData, 'currentVenue'))
        .set('frontend', Object.assign({}, state.get('frontend'), { loading: false }));
    default:
      return state;
  }
};

export default venueHealthCheckReport;
