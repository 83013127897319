import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import {
  formValueSelector,
  change,
  Field,
  Fields,
  reduxForm,
  SubmissionError,
  setSubmitFailed,
  stopSubmit,
} from 'redux-form/immutable';
import { ModalsProvider } from '@/components/hooks-components/modals';
import BossFormSelect from '@/components/boss-form/boss-form-select';
import BossFormInput from '@/components/boss-form/boss-form-input';
import utils from '@/lib/utils';
import { BossFormCheckbox } from '@/components/boss-form';
import BossFormCalendar from '@/components/boss-form/boss-form-calendar';
import BossFormEmployementStatus from '@/components/boss-form/boss-form-employement-status';
import { useSiaBadgeValidation } from '@/components/hooks-components/use-sia-badge-validation';
import { ChangeVenueButton } from './change-venue-button';

import {
  SIAFormField,
  NonEditableSIAField,
} from '../../../../AddStaffMember/components/fields/sia-form-field';
import { updateEmploymentDetailsRequest, changeMasterVenueAction } from '../../actions';
import { OFFICE_BASED_WORKER_TYPE, SECURITY_TYPE_ID } from '../../constants';

const selector = formValueSelector('employment-details-form');

const validate = values => {
  let errors = {};
  if (!values.get('sia_badge_number')) {
    errors = { ...errors, sia_badge_number: [`Can't be blank`] };
  }
  return errors;
};

const EmploymentDetailsForm = ({
  error,
  handleSubmit,
  form,
  submitting,
  staffTypes,
  accessiblePayRates,
  accessibleBaseStandardSecurityPayRates,
  accessibleBaseStewardSecurityPayRates,
  accessibleVenues,
  onSubmissionComplete,
  isSecurityStaff,
  isSteward,
  dispatch,
  canEditSageId,
  allowNoSageId,
  canEditWorkerType,
  workerTypeOptions,
  siaValidationGUID,
  canChangePublicHolidays,
  checkSiaNumber,
  change: formChange,
  siaBadgeExpiryDate,
  staffMemberId,
  siaBadgeValid,
  siaBadgeNumber,
  valid,
  accessories,
  masterVenue,
  accessoryRequests,
  changeMasterVenue,
  otherVenues,
  isInitialySecurity,
  ...other
}) => {
  const { checkSIANumber, siaBadgeValidating, validationLookupStatus } = useSiaBadgeValidation({
    staffMemberId,
    onDowngradeClick: handleSIADowngradeClick,
    onNotFoundSubmit: handleSIANotFoundSubmit,
    onInvalidTryAgainClick: handleInvalidSiaTryAgain,
    onRequestFailed: handleCheckSIARequestFailed,
    onDuplicatesModalSubmit(closeModal) {
      formChange('sia_badge_number', '');
      closeModal();
    },
    onNonFrontLineModalSubmit(closeModal) {
      formChange('sia_badge_number', '');
      closeModal();
    },
    onSiaCheckSuccess: handleSiaCheckSuccess,
    onFormatInvalid(data) {
      const message = oFetch(data, 'message');
      dispatch(
        stopSubmit(form, {
          sia_badge_number: [message],
        }),
      );
      dispatch(setSubmitFailed(form, 'sia_badge_number'));
    },
  });
  const nonStewardSecurity = isSecurityStaff && !isSteward;
  const validateSIANumberWhenSubmit =
    nonStewardSecurity && !siaBadgeValid && checkSiaNumber && !siaValidationGUID;
  const canShowSiaBadgeExpiryDateField =
    nonStewardSecurity &&
    (!checkSiaNumber || !siaBadgeValid || validationLookupStatus === 'fail_request_failed') &&
    !(validationLookupStatus !== 'fail_request_failed' && siaValidationGUID);

  function submitFormWithSIAValidation(e) {
    e.preventDefault();
    if (validateSIANumberWhenSubmit) {
      return checkSIANumber({
        siaNumber: siaBadgeNumber,
        debounceTimeout: 0,
        onSuccess() {
          return handleSubmit(submission)();
        },
      });
    } else {
      return handleSubmit(submission)();
    }
  }

  const submission = (values, dispatch, ...args) => {
    const jsValues = values.toJS();
    return dispatch(updateEmploymentDetailsRequest(jsValues));
  };

  function handleInvalidSiaTryAgain(closeModal) {
    closeModal();
    formChange('sia_badge_number', null);
  }

  function handleSiaCheckSuccess(data) {
    const [guid, expiryDate] = oFetch(data, 'guid', 'expiryDate');

    formChange('siaValidationGUID', guid);
    formChange('sia_badge_expiry_date', expiryDate);
  }

  function handleSIADowngradeClick(closeModal) {
    formChange('steward', true);
    closeModal();
  }

  function clearCheckSIAFields() {
    formChange('sia_badge_number', null);
    formChange('siaValidationGUID', null);
    formChange('sia_badge_expiry_date', null);
  }

  function handleCheckSIARequestFailed(params) {
    const [closeModal, guid] = oFetch(params, 'closeModal', 'guid');
    formChange('siaValidationGUID', guid);
    closeModal();
  }

  function handleSIANotFoundSubmit(closeModal) {
    clearCheckSIAFields();
    closeModal();
  }

  const renderSecurityStaffFields = () => {
    return (
      <Fragment>
        {nonStewardSecurity && (
          <Field
            label={'SIA Badge Number'}
            name={'sia_badge_number'}
            staffMemberId={staffMemberId}
            siaBadgeValidating={siaBadgeValidating}
            validateSiaNumber={checkSiaNumber}
            checkSIANumber={checkSIANumber}
            component={SIAFormField}
          />
        )}
        {!canShowSiaBadgeExpiryDateField && siaBadgeExpiryDate && checkSiaNumber && (
          <Field
            label={'SIA badge expiry date'}
            name={'sia_badge_expiry_date'}
            component={NonEditableSIAField}
          />
        )}
        {canShowSiaBadgeExpiryDateField && (
          <Field
            label={'SIA badge expiry date'}
            name={'sia_badge_expiry_date'}
            valueFormat={utils.commonDateFormat}
            component={BossFormCalendar}
            disabled={siaBadgeValidating}
          />
        )}
      </Fragment>
    );
  };

  function renderSecurityPayRatesField() {
    if (isSteward) {
      return (
        <Field
          component={BossFormSelect}
          required
          name="baseSecurityPayRate"
          label="Pay Rate"
          optionLabel="name"
          optionValue="id"
          placeholder="Select pay rate ..."
          options={accessibleBaseStewardSecurityPayRates}
        />
      );
    }
    return (
      <Field
        component={BossFormSelect}
        required
        name="baseSecurityPayRate"
        label="Pay Rate"
        optionLabel="name"
        optionValue="id"
        placeholder="Select pay rate ..."
        options={accessibleBaseStandardSecurityPayRates}
        disabled={siaBadgeValidating}
      />
    );
  }

  return (
    <ModalsProvider>
      <form onSubmit={submitFormWithSIAValidation}>
        {error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error.join(', ')}</span>
            </p>
          </div>
        )}

        <header className="boss-content-switcher__header">
          <h2 className="boss-content-switcher__title">Employment Details</h2>
        </header>
        <ChangeVenueButton
          isInitialySecurity={isInitialySecurity}
          isSecurityStaff={isSecurityStaff}
          accessories={accessories}
          accessibleVenues={accessibleVenues}
          accessoryRequests={accessoryRequests}
          masterVenue={masterVenue}
          otherVenues={otherVenues}
          staffMemberId={staffMemberId}
          onChangeMasterVenue={changeMasterVenue}
        />

        {canEditWorkerType && (
          <Field
            name="worker_type"
            component={BossFormSelect}
            required
            label="Worker Type"
            optionLabel="description"
            optionValue="value"
            placeholder="Select Worker Type ..."
            options={workerTypeOptions}
            disabled={siaBadgeValidating}
          />
        )}
        {canChangePublicHolidays && (
          <Field
            name="generatePublicHolidays"
            component={BossFormCheckbox}
            label="Public Holidays"
          />
        )}
        <Field
          component={BossFormSelect}
          name="other_venues"
          label="Other Venues"
          optionLabel="name"
          optionValue="id"
          multi
          placeholder="Select other venues ..."
          options={accessibleVenues}
          disabled={siaBadgeValidating}
        />
        {isSecurityStaff && (
          <div>
            <div className="boss-form__row boss-form__row_justify_end boss-form__row_layout_nowrap">
              <Field
                component={BossFormCheckbox}
                className="boss-form__field_layout_actual"
                name="steward"
                left={false}
                label="Steward"
                disabled={siaBadgeValidating}
              />
            </div>
          </div>
        )}
        <Field
          component={BossFormSelect}
          required
          name="staff_type"
          label="Staff Type"
          optionLabel="name"
          optionValue="id"
          placeholder="Select staff type ..."
          options={staffTypes.toJS()}
          disabled={siaBadgeValidating}
        />

        {isSecurityStaff && !isSteward && renderSecurityStaffFields()}

        <Field
          name="starts_at"
          disabled={siaBadgeValidating}
          component={BossFormCalendar}
          label="Start Date"
          required
        />

        {!isSecurityStaff && (
          <Field
            component={BossFormSelect}
            required
            name="pay_rate"
            label="Pay Rate"
            optionLabel="name"
            optionValue="id"
            placeholder="Select pay rate ..."
            options={accessiblePayRates}
          />
        )}

        {isSecurityStaff && renderSecurityPayRatesField()}

        <Field name="day_preference" component={BossFormInput} type="text" label="Day Preference" />

        <Field
          name="hours_preference"
          component={BossFormInput}
          type="text"
          label="Hours Preference"
          disabled={siaBadgeValidating}
        />

        <Field
          component={BossFormInput}
          required
          capitalize
          name="national_insurance_number"
          type="text"
          label="National Insurance Number"
          disabled={siaBadgeValidating}
        />
        {canEditSageId && (
          <div className="boss-form__group boss-form__group_role_board-outline js-sage-id">
            <div className="boss-form__row boss-form__row_justify_end boss-form__row_layout_nowrap">
              <Field
                component={BossFormCheckbox}
                className="boss-form__field_layout_actual"
                name="allow_no_sage_id"
                left={false}
                label="This staff member does not require a Sage ID"
                disabled={siaBadgeValidating}
              />
            </div>
            <Field
              component={BossFormInput}
              required={!allowNoSageId}
              disabled={allowNoSageId || siaBadgeValidating}
              name="sage_id"
              type="text"
              label="Sage ID"
            />
          </div>
        )}
        <Field
          component={BossFormEmployementStatus}
          required
          name="employment_status"
          label="Starter Employement Status Statement"
          disabled={siaBadgeValidating}
        />
        <div className="boss-form__field boss-form__field_justify_end">
          <button
            className="boss-button boss-form__submit boss-form__submit_adjust_single"
            type="submit"
            disabled={submitting || siaBadgeValidating || !valid}
          >
            Save
          </button>
        </div>
      </form>
    </ModalsProvider>
  );
};

const mapStateToProps = (state, props) => {
  const initialValues = oFetch(props, 'initialValues');
  const isInitialySecurity = initialValues.get('staff_type') === SECURITY_TYPE_ID;
  const siaValidationGUID = selector(state, 'siaValidationGUID');
  const siaBadgeExpiryDate = selector(state, 'sia_badge_expiry_date');
  const siaBadgeNumber = selector(state, 'sia_badge_number');

  return {
    isSecurityStaff: selector(state, 'staff_type') === SECURITY_TYPE_ID,
    allowNoSageId: !!selector(state, 'allow_no_sage_id'),
    isSteward: !!selector(state, 'steward'),
    siaValidationGUID: siaValidationGUID,
    siaBadgeExpiryDate: siaBadgeExpiryDate,
    siaBadgeNumber: siaBadgeNumber,
    isInitialySecurity: isInitialySecurity,
  };
};

const mapDispatchToProps = {
  changeMasterVenue: changeMasterVenueAction,
};

const ConnectedEmploymentDetailsForm = connect(mapStateToProps, mapDispatchToProps)(EmploymentDetailsForm);

export default reduxForm({
  form: 'employment-details-form',
  onChange: (values, dispatch, props, previousValues) => {
    const isSecurity = values.get('staff_type') === SECURITY_TYPE_ID;
    const stewardValue = values.get('steward');
    const workerType = values.get('worker_type');
    const oldStewardValue = previousValues.get('steward');

    if (oldStewardValue !== stewardValue) {
      dispatch(change('employment-details-form', 'siaValidationGUID', null));
      dispatch(change('employment-details-form', 'sia_badge_number', null));
      dispatch(change('employment-details-form', 'sia_badge_expiry_date', null));
    }
    if (isSecurity) {
      dispatch(change('employment-details-form', 'master_venue', null));
    }
    if (values.get('allow_no_sage_id') === true) {
      dispatch(change('employment-details-form', 'sage_id', null));
    }
  },
  validate,
})(ConnectedEmploymentDetailsForm);
