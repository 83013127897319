import { bossRequestHttp } from "@/lib/request-api";
import { BOSS_STAFF_MEMBER_TYPE, MOSS_STAFF_MEMBER_TYPE } from "./constants";
import { createAction } from "redux-actions";
import { UPDATE_TAX_YEAR_INFO, REQUEST_TAX_YEAR_INFO } from "./constants";
import { AxiosResponse } from "axios";
import { $BossHttpErrorHandlerParams, $BossHttpSuccessHandlerParams, BossHttpErrorHandlerParams, BossHttpSuccessHandlerParams, DispatchFunc, UpdateTaxYearInfoActionParamsData, TaxYearInfoRquestArgs } from "./types";

export const updateTaxYearInfoAction = createAction<UpdateTaxYearInfoActionParamsData>(UPDATE_TAX_YEAR_INFO);
export const taxYearInfoRequestAction = createAction(REQUEST_TAX_YEAR_INFO);

export const taxYearInfoRequest = (args: TaxYearInfoRquestArgs) => {
  return (dispatch: DispatchFunc): Promise<AxiosResponse<any>> => {
    const effectiveStaffMember = args.effectiveStaffMember;

    let url;
    if (effectiveStaffMember.effective_staff_type === BOSS_STAFF_MEMBER_TYPE) {
      url = `/api/v1/staff_members/${effectiveStaffMember.id}/holiday_tax_years?year=${args.year}`;
    } else if (effectiveStaffMember.effective_staff_type === MOSS_STAFF_MEMBER_TYPE) {
      url = `/api/v1/moss_staff_members/${effectiveStaffMember.id}/holiday_tax_years?year=${args.year}`;
    } else {
      throw new Error(`unsupported type: ${effectiveStaffMember.effective_staff_type}`);
    }

    dispatch(taxYearInfoRequestAction());

    return bossRequestHttp({
      errorHandler(params: BossHttpErrorHandlerParams) {
        const parsedParams = $BossHttpErrorHandlerParams.parse(params);
        parsedParams.globalNotifications.showDefaultFailureMessage({});
        return true;
      },
      successHandler(params: BossHttpSuccessHandlerParams) {
        const parsedParams = $BossHttpSuccessHandlerParams.parse(params);
        dispatch(updateTaxYearInfoAction(parsedParams.data));
        return true;
      },
    }).get(url);
  };
};