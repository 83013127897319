import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import ContentWrapper from '@/components/content-wrapper';

import { Dashboard, DashboardFilter, MonthItem, MonthList, IssueItem, YearSelect } from './components';
import { useSiteIssuesStates } from './site-issues-states';

export function SiteIssues(props) {
  const [
    months,
    activateSiteIssue,
    setPendingSiteIssue,
    disableSiteIssue,
    openCreateSiteIssueModal,
    openEditSiteIssueModal,
  ] = oFetch(
    useSiteIssuesStates(props),
    'months',
    'activateSiteIssue',
    'setPendingSiteIssue',
    'disableSiteIssue',
    'openCreateSiteIssueModal',
    'openEditSiteIssueModal',
  );

  function renderMonthItem(params) {
    const [monthLabel, monthIssues] = oFetch(params, 'monthLabel', 'monthIssues');
    return <MonthItem label={monthLabel} issues={monthIssues} renderIssue={renderIssueItem} />;
  }

  function renderIssueItem(issue) {
    return (
      <IssueItem
        issue={issue}
        onEditClick={openEditSiteIssueModal}
        onActivateClick={activateSiteIssue}
        onPendingClick={setPendingSiteIssue}
        onDisableClick={disableSiteIssue}
      />
    );
  }

  return (
    <Fragment>
      <Dashboard onNewIssueClick={openCreateSiteIssueModal} renderFilter={() => <DashboardFilter />} />
      <ContentWrapper>
        <YearSelect />
        <MonthList months={months} renderItem={renderMonthItem} />
      </ContentWrapper>
    </Fragment>
  );
}
