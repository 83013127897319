import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import Immutable from 'immutable';
import { Tooltip } from 'react-tippy';
import { appRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import { openConfirmationModal, openWarningModal } from '@/components/modals';
import { LegacyCloseOutsideModalWrapper, LegacyCloseInsideModalWrapper } from '@/components/modal-wrappers';
import Dashboard from './dashboard';
import CardList from './card-list';
import ReportList from './report-list';
import { PureJSReportItem } from './report-item';
import Confirm from './confirm';
import PayslipModal from './payslip-modal';
import { NoteForm } from './note-form';
import { AreYouSureUncompleteModal } from './are-you-sure-uncomplete-modal';
import { useModal } from '@/components/hooks-components/modals';
import * as _ from 'lodash';
import { MOSS_FINANCE_REPORT_READY_STATUS } from '../constants';
import { sendPayslipEmail } from '../requests';

function Page(props) {
  const { openModal } = useModal();
  const mossFinanceReportWeek = oFetch(props, 'mossFinanceReportWeek');

  function handleDateChange(selection) {
    goToMossFinanceReportsPage({
      startDate: oFetch(selection, 'startUIDate'),
      venueId: oFetch(props, 'venueId'),
    });
  }

  function goToMossFinanceReportsPage({ startDate, venueId }) {
    window.location.href = appRoutes.mossFinanceReports({
      startDate,
      venueId,
    });
  }

  function handleFilterChange(filter) {
    const changePayRateFilter = oFetch(props, 'changePayRateFilter');
    changePayRateFilter({ filter });
  }

  function handleOpenMarkCompletedModal(params) {
    const isNegativeTotal = oFetch(params, 'isNegativeTotal');
    if (isNegativeTotal) {
      openWarningModal({
        submit: handleMarkComplete,
        config: {
          title: 'Are You Sure?',
          text: [
            'You are attempting to complete a finance report with a negative total.',
            'If you choose to proceed all accessory requests will be removed and moved to the next available finance report.',
          ],
          buttonText: 'Continue and move Accessory Requests',
        },
        props: params,
      });
    } else {
      openConfirmationModal({
        submit: handleMarkComplete,
        config: { title: 'WARNING !!!' },
        props: { params },
      })(Confirm);
    }
  }

  function handleMarkComplete(hideModal, values) {
    const markReportCompleted = oFetch(props, 'markReportCompleted');

    return markReportCompleted(values).then(hideModal).catch(hideModal);
  }

  function handleOpenMarkAllCompletedModal(params) {
    const isNegativeTotal = oFetch(params, 'isNegativeTotal');
    if (isNegativeTotal) {
      openWarningModal({
        submit: handleMarkAllComplete,
        config: {
          title: 'Are You Sure?',
          text: [
            'You are attempting to complete a finance report with a negative total.',
            'If you choose to proceed all accessory requests will be removed and moved to the next available finance report.',
          ],
          buttonText: 'Continue and move Accessory Requests',
        },
        props: params,
      });
    } else {
      openConfirmationModal({
        submit: handleMarkAllComplete,
        config: { title: 'WARNING !!!' },
        props: { params },
      })(Confirm);
    }
  }

  function handleMarkAllComplete(hideModal, values) {
    const markReportsCompleted = oFetch(props, 'markReportsCompleted');

    return markReportsCompleted(values).then(hideModal).catch(hideModal);
  }

  function openPayslipModal(params) {
    const [payslip, fullName] = oFetch(params, 'payslip', 'fullName');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: PayslipModal,
      onSubmit: closeModal => {
        return sendPayslipEmail({ payslip, closeModal });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_payslip',
        title: () => {
          return (
            <div className="boss-modal-window__header">
              <span className="boss-modal-window__marked">{fullName}</span> Payslip
            </div>
          );
        },
      },
      props: {
        payslip,
      },
    });
  }

  function openUpdateNoteModal(params) {
    const [mossFinanceReport, edit] = oFetch(params, 'mossFinanceReport', 'edit');
    const [note, mossStaffMemberId] = oFetch(mossFinanceReport, 'note', 'mossStaffMemberId');

    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: NoteForm,
      onSubmit: (closeModal, values) => {
        const note = oFetch(values, 'note');
        return updateMossFinanceReportNote({
          mossStaffMemberId,
          note,
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: edit ? 'Edit Note' : 'Add Note',
      },
      props: {
        note: note || '',
        edit: edit,
      },
    });
  }

  function openAreYouSureUncompleteModal(params) {
    const mossStaffMemberId = oFetch(params, 'mossStaffMemberId');

    return openModal({
      ModalComponent: LegacyCloseOutsideModalWrapper,
      ModalContent: AreYouSureUncompleteModal,
      onSubmit: closeModal => {
        return uncompleteMossFinanceReport({
          mossStaffMemberId,
          onSuccess() {
            closeModal();
          },
        });
      },
      config: {
        title: 'ARE YOU SURE?',
        baseClassName: 'boss-modal-window boss-modal-window_role_warning',
      },
      props: {},
    });
  }

  const sStartDate = oFetch(props, 'startDate');
  const endDate = oFetch(props, 'endDate');
  const date = oFetch(props, 'date');
  const venueId = oFetch(props, 'venueId');
  const filterType = oFetch(props, 'filterType');
  const weekDates = oFetch(props, 'weekDates');
  const allReady = oFetch(props, 'allReady');
  const permissions = oFetch(props, 'permissions');
  const finishMossFinanceReportWeek = oFetch(props, 'finishMossFinanceReportWeek');
  const undoMossFinanceReportWeek = oFetch(props, 'undoMossFinanceReportWeek');
  const showPDFDownloadLink = oFetch(permissions, 'showPDFDownloadLink');
  const staffTypesWithMossFinanceReports = oFetch(props, 'staffTypesWithMossFinanceReports');
  const uncompleteMossFinanceReport = oFetch(props, 'uncompleteMossFinanceReport');
  const updateMossFinanceReportNote = oFetch(props, 'updateMossFinanceReportNote');
  const mossFinanceReportValidationErrors = oFetch(props, 'mossFinanceReportValidationErrors');
  const mossFinanceReportsOverviewData = oFetch(props, 'mossFinanceReportsOverviewData');
  const mossStaffMemberIds = staffTypesWithMossFinanceReports.reduce((acc, staffType) => {
    return acc.concat(
      0,
      oFetch(staffType, 'reports')
        .filter(report => oFetch(report, 'status') === MOSS_FINANCE_REPORT_READY_STATUS)
        .map(report => oFetch(report, 'mossStaffMemberId')),
    );
  }, Immutable.List());

  const reportsTotals = staffTypesWithMossFinanceReports.reduce((acc, staffType) => {
    return acc.concat(
      0,
      oFetch(staffType, 'reports')
        .filter(report => oFetch(report, 'status') === MOSS_FINANCE_REPORT_READY_STATUS)
        .map(report => oFetch(report, 'total')),
    );
  }, []);
  const isNegativeTotal = !!reportsTotals.find(total => total < 0);
  const completedMossFinanceReportsData = oFetch(props, 'completedMossFinanceReportsData');

  return (
    <div className="boss-page-main boss-page-main_adjust_finance-reports">
      <Dashboard
        title="Moss Finance Reports"
        date={date}
        mossFinanceReportWeek={mossFinanceReportWeek}
        startDate={sStartDate}
        endDate={endDate}
        completedMossFinanceReportsData={completedMossFinanceReportsData}
        venueId={venueId}
        filterType={filterType}
        onFinishMossFinanceReportWeek={finishMossFinanceReportWeek}
        onUndoMossFinanceReportWeek={undoMossFinanceReportWeek}
        onDateChange={handleDateChange}
        onFilterChange={handleFilterChange}
        showPDFDownloadLink={showPDFDownloadLink}
        mossFinanceReportsOverviewData={mossFinanceReportsOverviewData}
      />
      <CardList
        venueId={venueId}
        sStartDate={sStartDate}
        staffTypesWithMossFinanceReports={staffTypesWithMossFinanceReports}
        onMarkAllPageCompleted={() =>
          handleOpenMarkAllCompletedModal({ mossStaffMemberIds, isNegativeTotal })
        }
        allReady={allReady}
        itemRenderer={staffType => {
          const reports = oFetch(staffType, 'reports');

          const mossStaffMemberIds = reports
            .filter(report => {
              return oFetch(report, 'status') === 'ready';
            })
            .map(report => oFetch(report, 'mossStaffMemberId'));

          const isNegativeTotal = !!reports.find(report => oFetch(report, 'total') < 0);
          const isMossFinanceReportWeekFinished = oFetch(mossFinanceReportWeek, 'isFinished');

          return (
            <ReportList
              staffType={staffType}
              startDate={sStartDate}
              onMarkAllCompleted={() =>
                handleOpenMarkAllCompletedModal({ mossStaffMemberIds, isNegativeTotal })
              }
              itemRenderer={report => {
                const reportId = oFetch(report, 'id');
                const mossStaffMemberId = oFetch(report, 'mossStaffMemberId');
                const currentReportValidationErrors = oFetch(
                  mossFinanceReportValidationErrors,
                  mossStaffMemberId,
                );

                return (
                  <PureJSReportItem
                    onMarkCompleted={handleOpenMarkCompletedModal}
                    onUncomplete={openAreYouSureUncompleteModal}
                    weekDates={weekDates}
                    report={report}
                    isMossFinanceReportWeekFinished={isMossFinanceReportWeekFinished}
                    validationErrors={currentReportValidationErrors}
                    startDate={sStartDate}
                    endDate={endDate}
                    onOpenPayslip={openPayslipModal}
                    key={reportId}
                    onNoteClick={openUpdateNoteModal}
                  />
                );
              }}
            />
          );
        }}
      />
    </div>
  );
}

Page.propTypes = {
  date: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  filterType: PropTypes.string.isRequired,
  staffTypesWithMossFinanceReports: PropTypes.array.isRequired,
  weekDates: PropTypes.array.isRequired,
  changePayRateFilter: PropTypes.func.isRequired,
  allReady: PropTypes.bool.isRequired,
  markReportCompleted: PropTypes.func.isRequired,
  markReportsCompleted: PropTypes.func.isRequired,
  uncompleteMossFinanceReport: PropTypes.func.isRequired,
};

export default Page;
