import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import invites from './invites';
import filters from './filters';
import staticValues from './static-values';

export default combineReducers({
  invites,
  filters,
  staticValues,
  form: formReducer,
});
