import { createAction } from 'redux-actions';
import URLSearchParams from 'url-search-params';
import oFetch from 'o-fetch';
import * as constants from './constants';

import {
  loadDataRequest,
  acceptAccessoryRequestRequest,
  rejectAccessoryRequestRequest,
  acceptAccessoryRefundRequestRequest,
  rejectAccessoryRefundRequestRequest,
  undoAccessoryRequestRequest,
  undoAccessoryRefundRequestRequest,
  completeAccessoryRequestRequest,
  completeAccessoryRefundRequestRequest,
  markReceivedAccessoryRequestRequest,
} from '../requests';

export const loadInitialState = createAction(constants.LOAD_INITIAL_STATE);
export const loadInitialAccessoryRequests = createAction(constants.LOAD_INITIAL_ACCESSORY_REQUESTS);
export const setVenue = createAction(constants.SET_VENUE);
export const dropPageNumber = createAction(constants.DROP_PAGE_NUMBER);
export const loadMore = createAction(constants.LOAD_MORE);
export const updateRequestInStore = createAction(constants.UPDATE_REQUEST);
export const removeRequestFromStore = createAction(constants.REMOVE_REQUEST);
export const updateRefundRequestInStore = createAction(constants.UPDATE_REFUND_REQUEST);
export const removeRefundRequestFromStore = createAction(constants.REMOVE_REFUND_REQUEST);
export const removeAccessoryFromStore = createAction(constants.REMOVE_ACCESSORY);

const checkRequestsCountEmpty = accessoryId => (dispatch, getState) => {
  const requestsCount = oFetch(getState(), 'accessoryRequestsPage.accessoryRequests').filter(
    item => oFetch(item, 'accessoryId') === accessoryId,
  ).length;
  const requestsRefundsCount = oFetch(getState(), 'accessoryRequestsPage.accessoryRefundRequests').filter(
    item => oFetch(item, 'accessoryId') === accessoryId,
  ).length;

  return requestsCount === 0 && requestsRefundsCount === 0;
};

export const changeVenue = venueId => (dispatch, getState) => {
  const queryString = new URLSearchParams(window.location.search);
  queryString.set('venue_id', venueId);
  window.history.pushState('state', 'title', `accessory-requests?${queryString}`);
  dispatch([setVenue(venueId), dropPageNumber()]);
  return dispatch(
    loadData({
      onSuccess(data) {
        dispatch(loadInitialAccessoryRequests(data));
      },
    }),
  );
};

export const loadInitialData = () => (dispatch, getState) => {
  return dispatch(
    loadData({
      onSuccess(data) {
        dispatch(loadInitialAccessoryRequests(data));
      },
    }),
  );
};

export const loadData = params => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');
  const currentPage = parseInt(oFetch(getState(), 'accessoryRequestsPage.pagination.pageNumber'));
  const onSuccess = oFetch(params, 'onSuccess');

  return loadDataRequest({
    values: {
      venueId: currentVenue,
      currentPage: currentPage,
    },
    onSuccess(data) {
      onSuccess(data);
    },
  });
};

export const updatePages = () => (dispatch, getState) => {
  return dispatch(
    loadData({
      onSuccess(data) {
        dispatch(loadInitialAccessoryRequests(data));
      },
    }),
  );
};

export const acceptAccessoryRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return acceptAccessoryRequestRequest({
    values: {
      venueId: currentVenue,
      accessoryId,
      requestId,
    },
    onSuccess(data) {
      dispatch(updateRequestInStore(data));
    },
  });
};

export const undoAccessoryRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return undoAccessoryRequestRequest({
    values: {
      venueId: currentVenue,
      accessoryId,
      requestId,
    },
    onSuccess(data) {
      dispatch(updateRequestInStore(data));
    },
  });
};

export const rejectAccessoryRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return rejectAccessoryRequestRequest({
    values: {
      venueId: currentVenue,
      accessoryId,
      requestId,
    },
    onSuccess(data) {
      const accessoryRequest = oFetch(data, 'accessoryRequest');
      dispatch(updateRequestInStore(accessoryRequest));
    },
  });
};

export const completeAccessoryRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return completeAccessoryRequestRequest({
    venueId: currentVenue,
    accessoryId,
    requestId,
  }).then(completeResponse => {
    dispatch(removeRequestFromStore(completeResponse.data));
    if (dispatch(checkRequestsCountEmpty(accessoryId))) {
      dispatch(removeAccessoryFromStore(accessoryId));
      dispatch(updatePages());
    }
  });
};

export const markReceivedAccessoryRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return markReceivedAccessoryRequestRequest({
    values: {
      venueId: currentVenue,
      accessoryId,
      requestId,
    },
    onSuccess(data) {
      const accessoryRequest = oFetch(data, 'accessoryRequest');
      dispatch(updateRequestInStore(accessoryRequest));
    },
  });
};

export const acceptAccessoryRefundRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return acceptAccessoryRefundRequestRequest({
    values: { venueId: currentVenue, accessoryId, requestId },
    onSuccess(data) {
      dispatch(updateRefundRequestInStore(data));
    },
  });
};

export const undoAccessoryRefundRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return undoAccessoryRefundRequestRequest({
    values: { venueId: currentVenue, accessoryId, requestId },
    onSuccess(data) {
      dispatch(updateRefundRequestInStore(data));
    },
  });
};

export const rejectAccessoryRefundRequest = ({ requestId, accessoryId }) => (dispatch, getState) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return rejectAccessoryRefundRequestRequest({
    values: { venueId: currentVenue, accessoryId, requestId },
    onSuccess(data) {
      const accessoryRefundRequest = oFetch(data, 'accessoryRefundRequest');
      dispatch(updateRefundRequestInStore(accessoryRefundRequest));
    },
  });
};

export const completeAccessoryRefundRequest = ({ requestId, accessoryId, reusable }) => (
  dispatch,
  getState,
) => {
  const currentVenue = oFetch(getState(), 'accessoryRequestsPage.currentVenue');

  return completeAccessoryRefundRequestRequest({
    values: { venueId: currentVenue, accessoryId, requestId, reusable },
    onSuccess(data) {
      const accessoryRefundRequest = oFetch(data, 'accessoryRefundRequest');
      dispatch(updateRefundRequestInStore(accessoryRefundRequest));
    },
  });
};

export const loadMoreClick = () => (dispatch, getState) => {
  dispatch(loadMore());
  return dispatch(
    loadData({
      onSuccess(data) {
        dispatch(loadInitialAccessoryRequests(data));
      },
    }),
  );
};
