import React from 'react';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';

function RotaGraphLegend(props) {
  const venues = oFetch(props, 'venues');

  return (
    <div className="rota-chart__legend">
      <div className="boss-rotas-info boss-rotas-info_layout_row">
        <ul className="boss-rotas-info__list">
          {venues.map(venue => (
            <li key={venue.id} className="boss-rotas-info__item">
              <span className="boss-rotas-info__pointer" style={{ backgroundColor: venue.color }} />
              <p className="boss-rotas-info__text">
                <span>{venue.name}</span>: <span>{venue.count}</span>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

RotaGraphLegend.propTypes = {
  venueTypes: PropTypes.array.isRequired,
};

export default RotaGraphLegend;
