import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { Users } from './users';

export function UsersApp(props) {
  const [filter, roles, apps] = oFetch(props, 'filter', 'roles', 'apps');

  useEffect(() => {
    window.boss.accessToken = oFetch(props, 'accessToken');
  }, []);

  return (
    <LegacyGlobalProvider
      global={{
        filter,
        roles,
        apps,
      }}
    >
      <ModalsProvider>
        <Users {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
