import { z } from 'zod';
import React, { useEffect } from 'react';
import { BossFormInput, BossFormSwitcher } from '@/components/boss-form';
import { Form, Field } from 'react-final-form';
import { $IndexPageFilterValues, IndexPageFilterValues } from '../types';
import { useGlobal } from '../globals';
import {
  mossPartyPeopleIndex as importedMossPartyPeopleIndex,
} from '@/lib/app-routes';
// creating this to make calls clearer to understand
const appRoutes = {
  mossPartyPeopleIndex: importedMossPartyPeopleIndex,
};

export const $IndexPageFilterParams = z.object({
  filtering: z.boolean(),
  initialFilterValues: $IndexPageFilterValues,
}).strict();

export type IndexPageFilterParams = z.infer<typeof $IndexPageFilterParams>;

export function IndexPageFilter(params: IndexPageFilterParams): React.ReactElement {
  const globals = useGlobal();

  useEffect(() => {
    const filter = $('.boss-dropdown') as JQuery<HTMLElement>;

    filter.each(function () {
      const filterSwitch = $(this).find('.boss-dropdown__switch');
      const filterContent = $(this).find('.boss-dropdown__content');

      function toggleFilter(e: Event) {
        e.preventDefault();
        filterSwitch.toggleClass('boss-dropdown__switch_state_opened');
        filterContent.slideToggle().end().toggleClass('boss-dropdown__content_state_opened');
      }

      filterSwitch.on('click', toggleFilter);
    });
  }, []);

  const initialFilterValues = params.initialFilterValues;
  const filterTypeOptions = globals.filterTypeOptions;
  const filterOpen = params.filtering;

  const handleSubmit = (rawSubmitParams: unknown): void => {
    const submitParams = $IndexPageFilterValues.parse(rawSubmitParams);

    const staffPartyIndexArgs = {
      paginationPage: 1,
      filtering: true,
      name: submitParams.name,
      email: submitParams.email,
      filterType: submitParams.filterType,
    };
    window.location.href = appRoutes.mossPartyPeopleIndex(staffPartyIndexArgs);
  };

  return (
    <div className="boss-page-dashboard__filter">
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <a
          href="#"
          className="boss-dropdown__switch boss-dropdown__switch_role_filter"
          >Filter</a>
        </div>
        <div
          className="boss-dropdown__content"
          style={{ display:  filterOpen ? "block" : "none" }}
        >
          <div className="boss-dropdown__content-inner">
            <Form<IndexPageFilterValues>
              onSubmit={handleSubmit}
              initialValues={initialFilterValues}
              // className="boss-form"
              render={(formRenderProps) => {
                const canSubmit = !formRenderProps.pristine && !formRenderProps.submitting;

                return (
                  <>
                    <div className="boss-form__row">
                      <Field
                        name='name'
                        label="Name"
                        parse={value => value}
                        component={BossFormInput}
                      />
                      <Field
                        name="email"
                        label="Email"
                        parse={value => value}
                        component={BossFormInput}
                      />
                      <Field
                        name="filterType"
                        label="Type"
                        options={filterTypeOptions}
                        component={BossFormSwitcher}
                      />
                    </div>
                    <div className="boss-form__field boss-form__field_justify_end boss-form__field_position_last">
                      <button
                        disabled={!canSubmit}
                        className="boss-button boss-form__submit boss-form__submit_adjust_single"
                        onClick={formRenderProps.handleSubmit}
                      >Update</button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}