import React, { useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import getVenueColor from '@/lib/get-venue-color';
import { SecurityRotaShiftRequestsPage } from './security-rota-shift-requests-page';

export function SecurityRotaShiftRequestsApp(props) {
  const { accessToken, ...otherProps } = props;
  const venues = oFetch(props, 'venues');
  const staffTypes = oFetch(props, 'staffTypes');
  const dayRotaShifts = oFetch(props, 'dayRotaShifts');

  const venuesOptions = useMemo(() => {
    return venues.map(venue => {
      return {
        id: oFetch(venue, 'id'),
        name: oFetch(venue, 'name'),
        color: getVenueColor(oFetch(venue, 'id')),
      };
    });
  }, venues);

  useEffect(() => {
    require('./style.css');

    if (!accessToken) {
      throw new Error('Access token must be present');
    }
    window.boss.accessToken = accessToken;
  }, []);

  return (
    <LegacyGlobalProvider global={{ venues: venuesOptions, staffTypes, rotaShifts: dayRotaShifts }}>
      <ModalsProvider>
        <SecurityRotaShiftRequestsPage {...otherProps} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
