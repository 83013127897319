import React from 'react';
import oFetch from 'o-fetch';

export function ContactsItem(props) {
  const [staffMember, currentVenue] = oFetch(props, 'staffMember', 'currentVenue');
  const [staffMemberId, avatarUrl, fullName, masterVenue, staffType, staffTypeColor, workVenues] = oFetch(
    staffMember,
    'id',
    'avatarUrl',
    'fullName',
    'masterVenue',
    'staffType',
    'staffTypeColor',
    'workVenues',
  );

  const workVenuesExist = workVenues.length > 0;

  return (
    <div className="boss-check boss-check_role_board boss-check_page_contact-details">
      <div className="boss-check__header">
        <div className="boss-check__header-info">
          <div className="boss-user-summary boss-user-summary_role_contact-details">
            <div className="boss-user-summary__side">
              <div className="boss-user-summary__avatar">
                <div className="boss-user-summary__avatar-inner">
                  {/* <img src={avatarUrl} alt={fullName} loading="lazy" className="boss-user-summary__pic" /> */}
                </div>
              </div>
            </div>
            <div className="boss-user-summary__content">
              <div className="boss-user-summary__header">
                <h2 className="boss-user-summary__name">{fullName}</h2>
                <span
                  style={{ backgroundColor: staffTypeColor }}
                  className="boss-button boss-button_type_extra-small boss-button_type_label boss-user-summary__label"
                >
                  {staffType}
                </span>
              </div>
              <ul className="boss-user-summary__review-list">
                <li className="boss-user-summary__review-item">
                  <span className="boss-user-summary__review-label">Master Venue: </span>
                  <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                    {masterVenue || 'N/A'}
                  </span>
                </li>
                <li className="boss-user-summary__review-item">
                  <span className="boss-user-summary__review-label">Work Venues: </span>
                  <span className="boss-user-summary__review-val boss-user-summary__review-val_marked">
                    {workVenuesExist ? workVenues.map(venue => oFetch(venue, 'name')).join(', ') : 'N/A'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="boss-check__header-actions">
          <a
            href={`/contact_details/download_vcard?staff_member_id=${staffMemberId}&venue_id=${currentVenue}`}
            className="boss-button boss-button_role_vcard-download boss-button_type_extra-small boss-check__header-action"
          >
            Download vCard
          </a>
        </div>
      </div>
    </div>
  );
}
