import React, { useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { BossSelectHighlight } from '@/components/boss-select-highlight';

function ValueComponent(props) {
  return (
    <React.Fragment>
      <div
        className="Select-value react-select__value-container"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className="react-select__single-value">{props.value.label}</div>
      </div>
    </React.Fragment>
  );
}

export function VenueSelector(props) {
  const [venues, selected, prefix] = oFetch(props, 'venues', 'selected', 'prefix');
  const [selectedVenue, setSelectedVenue] = useState(selected);
  const venuesOptions = useMemo(() => {
    return venues.map(venue => {
      return {
        value: oFetch(venue, 'id'),
        label: oFetch(venue, 'name'),
      };
    });
  }, [venues.length]);

  function handleChange(selected) {
    setSelectedVenue(selected);
    if (!selected) {
      return;
    }
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      venue_id: selected,
    })}`;
    window.location.href = url;
  }

  return (
    <div className={`${prefix}-page-header__select`}>
      <BossSelectHighlight
        options={venuesOptions}
        value={selectedVenue}
        onChange={handleChange}
        controlClassName="react-select__control"
        menuClassName="react-select__menu"
        valueComponent={ValueComponent}
      />
    </div>
  );
}
