import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export default function AnswerItemImage(props) {
  const answer = oFetch(props, 'answer');
  const [imageUrl, correct] = oFetch(answer, 'imageUrl', 'correct');
  const checkBoxClassNames = cn('boss-choice-list__option-value', {
    'boss-choice-list__option-value_type_checked': correct,
  });
  return (
    <div className="boss-choice-list__control boss-choice-list__control_type_image">
      <div className="boss-choice-list__option">
        <span className={checkBoxClassNames}>
          <img src={imageUrl} alt="Answer Image" className="boss-choice-list__option-image" crossOrigin="anonymous" />
        </span>
      </div>
    </div>
  );
}
