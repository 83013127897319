import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '../store';
import reducers from './reducers';
import { initialLoad } from './actions';

import IncidentReportsShowPage from './containers/incident-reports-show-page';

export function IncidentReportsShowApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const accessToken = oFetch(props, 'accessToken');
    window.boss.accessToken = accessToken;
    const store = configureStore(reducers);
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <IncidentReportsShowPage />
    </Provider>
  );
}

export default IncidentReportsShowApp;
