import React from 'react';
import { Form, Field } from 'react-final-form';
import { BossFormInput, BossFormAvatar, Fields, BossFormSelectNew } from '@/components/boss-form';
import { FormValues, $FormValues, FormProps, OTHER_JOB_TITLE_VALUE } from '../types';
import { FORM_ERROR } from 'final-form';
import { useGlobal } from '../globals';
import JobTitleFields from './job-title-fields';

export function NonStaffMemberStaffPartyPersonForm<T extends FormProps>(props: T) {
  const { jobOptions, staffPartyQuizTeamOptions } = useGlobal();

  return (
    <>
      <Form<FormValues>
        onSubmit={(rawValues: Record<string, any>) => {
          // we need to do this because the otherJobTitle feild doesn't exist for these job titles
          let normalizedValues;
          if (rawValues['jobTitle'] === OTHER_JOB_TITLE_VALUE) {
            normalizedValues = rawValues;
          } else {
            normalizedValues = { ...rawValues, otherJobTitle: '' };
          }
          const values = $FormValues.parse(normalizedValues);
          return props.onSubmit({ values });
        }}
        initialValues={props.initialValues}
        render={(renderProps) => {
          const canSubmit = !renderProps.submitting || !renderProps.pristine;
          const baseErrors: string[] = (renderProps.hasSubmitErrors && (renderProps.submitErrors || {})[FORM_ERROR]) || [];
          const hasBaseErrors = baseErrors.length > 0;

          return (
            <>
              {hasBaseErrors && <div>Base Error: {baseErrors.join(', ')}</div>}
              <Field
                name="avatarUrl"
                label="Avatar"
                parse={(value: any) => value}
                component={BossFormAvatar}
              />
              <Field
                name="firstName"
                label="First Name"
                required
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Field
                name="surname"
                label="Surname"
                required
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Field
                name="phoneNumber"
                label="Phone Number"
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Field
                name="email"
                label="Email Address"
                parse={(value: any) => value}
                component={BossFormInput}
              />
              <Fields
                names={['jobTitle', 'otherJobTitle']}
                initialOtherJobTitleValue={props.initialValues.otherJobTitle}
                jobTitleFieldName="jobTitle"
                otherJobTitleFieldName="otherJobTitle"
                jobOptions={jobOptions}
                originalRender={JobTitleFields}
              />
              <Field
                name="staffPartyQuizTeamId"
                label="Quiz Team"
                parse={(value: any) => value}
                value={props.initialValues.staffPartyQuizTeamId}
                options={staffPartyQuizTeamOptions}
                clearable={false}
                labelKey="name"
                valueKey="value"
                component={BossFormSelectNew}
              />

              <button
                onClick={renderProps.handleSubmit}
                disabled={!canSubmit}
              >Submit</button>
            </>
          );
        }}
      />
    </>
  );
}
