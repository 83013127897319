import { createAction } from 'redux-actions';
import { reset } from 'redux-form';

import oFetch from 'o-fetch';

import { bossRequestHttp } from '@/lib/request-api';
import * as types from './types';
import * as selectors from './selectors';

export const loadInitialState = createAction(types.LOAD_INITIAL_STATE);
export const addStaffTypes = createAction(types.ADD_STAFF_TYPES);
export const removeStaffType = createAction(types.REMOVE_STAFF_TYPE);

export const unAssignStaffTypes = params => (dispatch, getState) => {
  const checklist = selectors.currentChecklistSelector(getState());
  const checklistId = oFetch(checklist, 'id');
  const staffTypeId = oFetch(params, 'staffTypeId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      dispatch(removeStaffType({ staffTypeId }));
    },
  }).delete(`/api/v1/check_lists/${checklistId}/assignments/unassign_staff_type`, { params: { staffTypeId } });
};

export const assignStaffTypes = params => (dispatch, getState) => {
  const checklist = selectors.currentChecklistSelector(getState());
  const checklistId = oFetch(checklist, 'id');
  const [staffTypes, form] = oFetch(params, 'staffTypes', 'form');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
    },
    successHandler(params) {
      const [data, globalNotifications] = oFetch(params, 'data', 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      dispatch(addStaffTypes(data));
      dispatch(reset(form));
    },
  }).post(`/api/v1/check_lists/${checklistId}/assignments/assign_staff_types`, { staffTypes });
};
