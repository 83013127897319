import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import HolidayReportView from './holiday-report-view';
import reducers from './reducers';
import configureStore from '@/apps/store';
import oFetch from 'o-fetch';

import { initialLoad } from './actions';

export function HolidayReportApp(props) {
  const [store, setStore] = useState(null);

  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(initialLoad(getViewData()));
    setStore(store);
  }, []);

  function getViewData() {
    return props.viewData || oFetch(window, 'boss.store');
  }

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <HolidayReportView />
    </Provider>
  );
}
