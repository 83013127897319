import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = [];

export const marketingTaskRequests = handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const marketingTaskRequests = oFetch(action, 'payload.requests');
      return marketingTaskRequests;
    },
    [t.UPDATE_MARKETING_TASK_DETAILS]: (state, action) => {
      const marketingTaskRequests = oFetch(action, 'payload.marketingTaskRequests');
      return state.map(existingMarketingTaskRequest => {
        const marketingTaskRequest = marketingTaskRequests.find(
          marketingTaskRequest =>
            oFetch(existingMarketingTaskRequest, 'id') === oFetch(marketingTaskRequest, 'id'),
        );
        if (marketingTaskRequest) {
          return marketingTaskRequest;
        }
        return existingMarketingTaskRequest;
      });
    },
  },
  initialState,
);
