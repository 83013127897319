import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { appRoutes } from '@/lib/legacy-routes';
import { Tooltip } from 'react-tippy';

export function UserItem(props) {
  const [user, onAppDetailsClick] = oFetch(props, 'user', 'onAppDetailsClick');
  const id = oFetch(user, 'id');
  const name = oFetch(user, 'fullName');
  const status = oFetch(user, 'statusText');
  const email = oFetch(user, 'email');
  const role = oFetch(user, 'roleText');
  const isLinked = oFetch(user, 'isLinked');
  const disabled = oFetch(user, 'disabled');
  const appVenuesPermissions = oFetch(user, 'appVenuesPermissions');
  const unlinkedAppNames = oFetch(user, 'unlinkedAppNames');

  const requiringJsmSync = oFetch(user, 'requringJsmSync');
  const jsmSyncFailed = oFetch(user, 'jsmSyncFailed');

  const rowClassNames = cn('boss-table__row', { 'boss-table__row_state_disabled': disabled });

  function renderApps() {
    if (isLinked) {
      const hasAppVenuesPermissions = appVenuesPermissions.length > 0;
      if (!hasAppVenuesPermissions) {
        return (
          <span className="boss-tag boss-tag_role_label boss-tag_color_accent-green">
            <span className="boss-tag__text">N/A</span>
          </span>
        );
      }

      return appVenuesPermissions.map(appVenuesPermission => {
        const [appId, name] = oFetch(appVenuesPermission, 'appId', 'name');
        return (
          <button
            onClick={() => onAppDetailsClick({ appVenuesPermission })}
            key={appId}
            className="boss-tag boss-tag_role_label boss-tag_color_accent-green"
          >
            <span className="boss-tag__text">{name}</span>
          </button>
        );
      });
    } else {
      let index = 0;
      return unlinkedAppNames.map((appName) => {
        let key = index;
        index++;
        const nullAppVenuesPermission = { name: appName, venues: []};
        return (
          <button
            onClick={() => onAppDetailsClick({ appVenuesPermission: nullAppVenuesPermission })}
            key={key}
            className="boss-tag boss-tag_role_label boss-tag_color_accent-green"
          >
            <span className="boss-tag__text">{appName}</span>
          </button>
        )
      });
    };
  };

  function syncStatusTooptipHtml(args) {
    const [requiringJsmSync, jsmSyncFailed] = oFetch(args, 'requiringJsmSync', 'jsmSyncFailed');

    if (!requiringJsmSync && !jsmSyncFailed) {
      return null;
    }

    const innerHtml = [
      requiringJsmSync ? 'JSM Sync Required' : null,
      jsmSyncFailed ? 'JSM Syncing Failed' : null,
    ].filter(Boolean).map((message, index) => {
      return <li key={`jsmSyncMessage:${index}`}>{message}</li>;
    });

    return (
      <ul>
        { innerHtml}
      </ul>
    );
  }

  return (
    <div className={rowClassNames}>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Name</p>
          <p className="boss-table__text">
            { (requiringJsmSync || jsmSyncFailed) && (
              <Tooltip
                arrow
                theme="light"
                position="bottom"
                interactive
                html={syncStatusTooptipHtml({ requiringJsmSync, jsmSyncFailed })}
              >
                <span className="boss-table__indicator">
                  <span
                    style={{ color: 'red' }}
                    className="boss-indicator boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_alert"
                  />
                </span>
              </Tooltip>
            )}
            <a href={`/users/${id}`} className="boss-table__link">
              {` ${name}`}
            </a>
            {isLinked && (
              <span className="boss-table__indicator">
                <a
                  href={appRoutes.staffMemberProfile(oFetch(user, 'staffMemberId'))}
                  target="_blank"
                  rel="noreferrer"
                  className="boss-indicator boss-indicator_role_action boss-indicator_color_accent-blue"
                >
                  <span className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_chain" />
                </a>
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Apps</p>
          <p className="boss-table__text">{renderApps()}</p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Status</p>
          <p className="boss-table__text">
            <a href={`/users/${id}`} className="boss-table__link">
              {status}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Email</p>
          <p className="boss-table__text boss-table__text_adjust_wrap">
            <a href={`/users/${id}`} className="boss-table__link">
              {email}
            </a>
          </p>
        </div>
      </div>
      <div className="boss-table__cell">
        <div className="boss-table__info">
          <p className="boss-table__label">Role</p>
          <p className="boss-table__text">
            <a href={`/users/${id}`} className="boss-table__link">
              {role}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
