import React from 'react';
import oFetch from 'o-fetch';
import Popover from 'react-popover';
import queryString from 'query-string';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { DashboardWrapper } from '../../components/dashboard';
import WeekPicker from '../../components/react-dates/boss-week-picker';

export default class CountingDashboard extends React.Component {
  state = {
    isCalendarOpen: false,
  };

  renderCalendar = () => {
    const weekStart = oFetch(this.props, 'weekStart');

    return (
      <WeekPicker
        onCancelClick={this.togglePopover}
        selectionStartUIDate={weekStart}
        onChange={this.handleWeekSelect}
      />
    );
  };

  handleWeekSelect = selection => {
    const startUIDate = oFetch(selection, 'startUIDate');
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      week_start: startUIDate,
    })}`;
    location.href = url;
  };

  togglePopover = () => {
    this.setState(state => ({ isCalendarOpen: !oFetch(state, 'isCalendarOpen') }));
  };

  render() {
    const [title, weekStart, weekEnd, lastVisited, allVenuesCompleted] = oFetch(
      this.props,
      'title',
      'weekStart',
      'weekEnd',
      'lastVisited',
      'allVenuesCompleted',
    );
    const formattedLastVisited = safeMoment
      .iso8601Parse(lastVisited)
      .format(utils.commonDateFormatWithTime());
    const isCalendarOpen = oFetch(this.state, 'isCalendarOpen');

    return (
      <DashboardWrapper>
        <div className="purple-page-main__info-group">
          <div className="purple-page-main__info-group-area">
            <h1 className="purple-page-main__title">
              Counting Overview
              {allVenuesCompleted && (
                <span className="purple-page-main__title-indicator">
                  <span className="purple-indicator purple-indicator_color_accent-green">
                    <span className="purple-indicator__icon purple-indicator__icon_size_extra purple-indicator__icon_check-bold" />
                  </span>
                </span>
              )}
            </h1>
            <div className="purple-page-main__info-group-meta">
              <p className="purple-meta purple-meta_size_xl">
                <span className="purple-meta__text">
                  <span className="purple-meta__text-faded">
                    Page last visited at {formattedLastVisited}
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className="purple-page-main__info-group-controls">
            <div className="purple-page-main__info-group-controls">
              <div className="purple-form">
                <div className="purple-form__row purple-form__row_adjust_wrap-s-major purple-form__row_position_last">
                  <div className="purple-form__field purple-form__field_size_min purple-form__field_position_last">
                    <div className="purple-form-field purple-form-field_role_control">
                      <p className="purple-form-field__label purple-form-field__label_type_border">
                        <span className="purple-form-field__label-text">Date</span>
                      </p>
                      <div className="purple-form-field__component">
                        <div className="date-control date-control_adjust_control">
                          <div className="DateRangePicker DateRangePicker_1 WeekPicker">
                            <Popover
                              isOpen={isCalendarOpen}
                              body={this.renderCalendar()}
                              appendTarget={document.body}
                              place="below"
                              tipSize={0.01}
                              onOuterAction={this.togglePopover}
                              style={{ marginTop: '10px' }}
                            >
                              <button
                                onClick={this.togglePopover}
                                className="DateRangePickerInput DateRangePickerInput_1 DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput__showClearDates DateRangePickerInput__showClearDates_3"
                              >
                                <div className="DateInput DateInput_1">
                                  <span
                                    className="DateInput_input DateInput_input_1"
                                    style={{ top: 'inherit' }}
                                  >
                                    {weekStart}
                                  </span>
                                </div>
                                <div
                                  className="DateRangePickerInput_arrow DateRangePickerInput_arrow_1"
                                  aria-hidden="true"
                                  role="presentation"
                                >
                                  <svg
                                    className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1"
                                    viewBox="0 0 1000 1000"
                                  >
                                    <path d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z" />
                                  </svg>
                                </div>
                                <div className="DateInput DateInput_1">
                                  <span
                                    className="DateInput_input DateInput_input_1"
                                    style={{ top: 'inherit' }}
                                  >
                                    {weekEnd}
                                  </span>
                                </div>
                              </button>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    );
  }
}
