import React from 'react';
import oFetch from 'o-fetch';
import EditHolidayContent from './edit-holiday-content';

const EditHoliday = props => {
  const [canForwardHolidays, holiday, buttonTitle, onSubmit] = oFetch(props, 'canForwardHolidays', 'holiday', 'buttonTitle', 'onSubmit');

  return (
    <EditHolidayContent
      canForwardHolidays={canForwardHolidays}
      holiday={holiday}
      buttonTitle={buttonTitle}
      onSubmit={onSubmit}
    />
  );
};

export default EditHoliday;
