import { createSelector } from 'reselect';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';

export const marketingTasksSelector = state => oFetch(state, 'marketingTasks');
export const marketingTaskRequestsSelector = state => oFetch(state, 'marketingTaskRequests');
export const currentUserIdSelector = state => oFetch(state, 'staticReducer.currentUserId');
export const permissionsSelector = state => oFetch(state, 'permissions');
export const canCreateTaskSelector = state => oFetch(state, 'permissions.canCreateTask');
export const canBeAssignedSelector = state => oFetch(state, 'permissions.canBeAssigned');
export const tickAssignedOnMeSelector = state => oFetch(state, 'permissions.tickAssignedOnMe');
export const isMarketingManagerSelector = state => oFetch(state, 'permissions.isMarketingManager');

function normalizeMarketingTaskPermissions(taskPermissions) {
  return {
    canCreateRequestQuestion: oFetch(taskPermissions, 'can_create_request_question'),
    canDisable: oFetch(taskPermissions, 'can_disable'),
    canUpdate: oFetch(taskPermissions, 'can_update'),
    canComplete: oFetch(taskPermissions, 'can_complete'),
    canRejectCompletion: oFetch(taskPermissions, 'can_reject_completion'),
    canAccept: oFetch(taskPermissions, 'can_accept'),
    canGrabMarketingTask: oFetch(taskPermissions, 'can_grab_marketing_task'),
    canReassignMarketingTask: oFetch(taskPermissions, 'can_reassing_marketing_task'),
  };
}

function normalizeMarketingTaskRequestPermissions(requestPermissions) {
  return {
    canCreateRequestClarification: oFetch(requestPermissions, 'can_create_request_clarification'),
    canUpdateRequestQuestion: oFetch(requestPermissions, 'can_update_request_question'),
    canAccept: oFetch(requestPermissions, 'can_accept'),
    canCreateRequestQuestionAnswer: oFetch(requestPermissions, 'can_create_request_question_answer'),
    canDisable: oFetch(requestPermissions, 'can_disable'),
  };
}

export const normalizedTaskRequests = createSelector(
  [marketingTaskRequestsSelector, permissionsSelector, isMarketingManagerSelector],
  (marketingTaskRequests, permissions, isMarketingManager) => {
    return marketingTaskRequests.map(marketingTaskRequest => {
      const [requestId, isRequiringAnswer, isRequiringReview, isRemoved, isFromManager] = oFetch(
        marketingTaskRequest,
        'id',
        'isRequiringAnswer',
        'isRequiringReview',
        'isRemoved',
        'isFromManager',
      );
      const normalizedPermissions = normalizeMarketingTaskRequestPermissions(
        oFetch(permissions, 'marketingTaskRequests')[requestId],
      );
      const [canCreateRequestQuestionAnswer, canAccept, canCreateRequestClarification] = oFetch(
        normalizedPermissions,
        'canCreateRequestQuestionAnswer',
        'canAccept',
        'canCreateRequestClarification',
      );
      const requiringAnswerFromYou =
        !isRemoved &&
        isRequiringAnswer &&
        (canCreateRequestQuestionAnswer || (isFromManager && isMarketingManager));

      const hasAnswerForYou =
        !isRemoved &&
        isRequiringReview &&
        (canAccept || canCreateRequestClarification || (!isFromManager && isMarketingManager));

      return {
        ...marketingTaskRequest,
        hasUnansweredRequest: isRequiringAnswer,
        hasRequiredReviewRequest: isRequiringReview,
        requiringAnswerFromYou: requiringAnswerFromYou,
        hasAnswerForYou: hasAnswerForYou,
        permissions: normalizedPermissions,
      };
    });
  },
);

export const normalizedTasksSelector = createSelector(
  [
    marketingTasksSelector,
    normalizedTaskRequests,
    currentUserIdSelector,
    permissionsSelector,
    isMarketingManagerSelector,
  ],
  (marketingTasks, marketingTaskRequests, currentUserId, permissions, isMarketingManager) => {
    return marketingTasks.map(marketingTask => {
      const [marketingTaskId, assignedUser, isRejected] = oFetch(
        marketingTask,
        'id',
        'assignedUser',
        'isRejected',
      );
      const taskRequests = marketingTaskRequests.filter(
        request => oFetch(request, 'marketingTaskId') === oFetch(marketingTask, 'id'),
      );
      const hasRequests = taskRequests.length > 0;
      const isTaskAssigned = !!assignedUser;
      const isAssignedOnMe = isTaskAssigned && oFetch(assignedUser, 'id') === currentUserId;
      const isCompleted = oFetch(marketingTask, 'isInReview');
      const isAccepted = oFetch(marketingTask, 'isAccepted');
      const normalizedMarketingTaskPermissions = normalizeMarketingTaskPermissions(
        oFetch(permissions, 'marketingTasks')[marketingTaskId],
      );

      const canAccept = oFetch(normalizedMarketingTaskPermissions, 'canAccept');

      const requiringAnswerFromYou = taskRequests.some(request => {
        return oFetch(request, 'requiringAnswerFromYou');
      });
      const hasAnswerForYou = taskRequests.some(request => {
        return oFetch(request, 'hasAnswerForYou');
      });
      const hasUnansweredRequest = taskRequests.some(request => {
        return oFetch(request, 'hasUnansweredRequest');
      });
      const hasRequiredReviewRequest = taskRequests.some(request => {
        return oFetch(request, 'hasRequiredReviewRequest');
      });

      const displayInReview =
        (isCompleted && (canAccept || isMarketingManager)) ||
        (isRejected && isMarketingManager) ||
        requiringAnswerFromYou ||
        hasAnswerForYou;

      return {
        ...marketingTask,
        requests: [...taskRequests].sort(
          (a, b) => safeMoment.iso8601Parse(b.createdAt) - safeMoment.iso8601Parse(a.createdAt),
        ),
        hasRequests,
        isTaskAssigned,
        isAssignedOnMe,
        isCompleted,
        isAccepted,
        requiringAnswerFromYou,
        hasAnswerForYou,
        hasUnansweredRequest,
        hasRequiredReviewRequest,
        displayInReview,
        permissions: normalizedMarketingTaskPermissions,
      };
    });
  },
);

export const acceptedTasksSelector = createSelector([normalizedTasksSelector], normalizedTasks => {
  return [
    ...normalizedTasks.filter(task => {
      return oFetch(task, 'isAccepted');
    }),
  ].sort((a, b) => safeMoment.iso8601Parse(b.acceptedAt) - safeMoment.iso8601Parse(a.acceptedAt));
});

export const activeTasksSelector = createSelector([normalizedTasksSelector], normalizedTasks => {
  return normalizedTasks.filter(task => {
    return !oFetch(task, 'isAccepted');
  });
});

export const tasksByStatusSelector = createSelector([activeTasksSelector], activeTasks => {
  return activeTasks.reduce(
    (acc, task) => {
      const [isUpForGrabs, isInProgress, displayInReview] = oFetch(
        task,
        'isUpForGrabs',
        'isInProgress',
        'displayInReview',
      );

      if (displayInReview) {
        acc.inReview = [...acc.inReview, task];
      } else {
        if (isUpForGrabs) {
          acc.upForGrabs = [...acc.upForGrabs, task];
        } else if (isInProgress) {
          acc.inProgress = [...acc.inProgress, task];
        } else {
          throw new Error('Wrong marketing task status');
        }
      }
      return acc;
    },
    {
      upForGrabs: [],
      inProgress: [],
      inReview: [],
    },
  );
});

export const marketingTaskSelector = id =>
  createSelector([normalizedTasksSelector], marketingTasks => {
    const marketingTask = marketingTasks.find(marketingTask => {
      return oFetch(marketingTask, 'id') === id;
    });
    return marketingTask;
  });
