import React from 'react';
import oFetch from 'o-fetch';

function AccessoriesHeader(props) {
  const canCreateAccessoryRequest = oFetch(props, 'canCreateAccessoryRequest');
  const staffMember = oFetch(props, 'staffMember');
  const disabled = oFetch(staffMember, 'disabled');
  const title = oFetch(props, 'title');
  const onRequest = oFetch(props, 'onRequest');
  return (
    <header className="boss-board__header">
      <h2 className="boss-board__title">{title}</h2>
      <div className="boss-board__button-group">
        {canCreateAccessoryRequest && !disabled && (
          <button onClick={onRequest} className="boss-button boss-button_role_add">
            Add Accessory
          </button>
        )}
      </div>
    </header>
  );
}

export default AccessoriesHeader;
