import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as t from '../types';

const initialState = [];

export const marketingTaskRequests = handleActions(
  {
    [t.LOAD_INITIAL_DATA]: (state, action) => {
      const marketingTaskRequests = oFetch(action, 'payload.requests');
      return marketingTaskRequests;
    },
    [t.ADD_MARKETING_TASK_REQUEST]: (state, action) => {
      const marketingTaskRequest = oFetch(action, 'payload.marketingTaskRequest');
      return [...state, marketingTaskRequest];
    },
    [t.UPDATE_MARKETING_TASK_REQUEST]: (state, action) => {
      const updatedMarketingTaskRequest = oFetch(action, 'payload.marketingTaskRequest');
      return state.map(marketingTaskRequest => {
        if (oFetch(marketingTaskRequest, 'id') === oFetch(updatedMarketingTaskRequest, 'id')) {
          return updatedMarketingTaskRequest;
        }
        return marketingTaskRequest;
      });
    },
    [t.REPLACE_MARKETING_TASK_REQUEST]: (state, action) => {
      const [newMarketingTaskRequest, oldMarketingTaskRequestId] = oFetch(
        action,
        'payload.marketingTaskRequest',
        'payload.oldMarketingTaskRequestId',
      );
      return state.map(marketingTaskRequest => {
        if (oFetch(marketingTaskRequest, 'id') === oldMarketingTaskRequestId) {
          return newMarketingTaskRequest;
        }
        return marketingTaskRequest;
      });
    },
    [t.UPDATE_MARKETING_TASK_DETAILS]: (state, action) => {
      const marketingTaskRequests = oFetch(action, 'payload.marketingTaskRequests');
      return state.map(existingMarketingTaskRequest => {
        const marketingTaskRequest = marketingTaskRequests.find(
          marketingTaskRequest =>
            oFetch(existingMarketingTaskRequest, 'id') === oFetch(marketingTaskRequest, 'id'),
        );
        if (marketingTaskRequest) {
          return marketingTaskRequest;
        }
        return existingMarketingTaskRequest;
      });
    },
  },
  initialState,
);
