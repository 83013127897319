import { z } from 'zod';
import { parseISO, parse } from 'date-fns';

export const get$BossHttpSuccessHandlerParams = <ItemType extends z.ZodTypeAny>(itemSchema: ItemType) => {
  return z.object({
    statusCode: z.number().int().positive().gte(100).lt(600),
    data: itemSchema,
    globalNotifications: z.object({
      showDefaultFailureMessage: z.function().args(z.unknown()).returns(z.unknown()),
      showDefaultSuccessMessage: z.function().args(z.unknown()).returns(z.unknown()),
    }).passthrough(),
    supportedKeyChecker: z.object({}).passthrough(),
  }).strict();
};

export const get$BossHttpErrorHandlerParams = <ItemType extends z.ZodTypeAny>(itemSchema: ItemType) => {
  return get$BossHttpSuccessHandlerParams(itemSchema)
    .extend({
      errors: z.record(z.unknown()),
      data: z.unknown(),
    })
    .passthrough();
};

export const DATE_REGEX = /^[0-3][0-9]-(0[0-9]|1[0-2])-[1-2][0-9][0-9][0-9]$/;

export function $BossUiDate(zodBuilder: typeof z) {
  return zodBuilder.string().regex(
    DATE_REGEX,
    {
      message: 'Invalid date format should be dd-mm-yyyy'
    }
  ).transform((str: unknown) => {
    return parse(
      str as string,
      'dd-MM-yyyy',
      new Date(),
    );
  });
}

export function $BossUiTime(zodBuilder: typeof z) {
  return zodBuilder.string().datetime({ offset: true })
    .transform((str: unknown) => parseISO(str as string));
}