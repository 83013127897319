import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { atFormat } from './utils';
import { Attachments } from './attachments';

export function AcceptAnswerEventType(props) {
  const event = oFetch(props, 'event');

  const [userFullName, questionText, answerText, at] = oFetch(
    event,
    'userFullName',
    'questionText',
    'answerText',
    'at',
  );

  const formattedAt = useMemo(() => atFormat(at), [at]);

  return (
    <li className="boss-overview__activity-item boss-overview__activity-item_role_created">
      <p className="boss-overview__text">
        Accepted answer <b>{answerText}</b> for <b>{questionText}</b> question by {userFullName} @{' '}
        {formattedAt}
      </p>
    </li>
  );
}
