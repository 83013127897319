import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import Popover from 'react-popover';
import AsyncButton from 'react-async-button';
import { Tooltip } from 'react-tippy';

import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { openWarningModal } from '@/components/modals';
import { appRoutes } from '@/lib/legacy-routes';
import MossFinanceReportsFilter from './moss-finance-reports-filter';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export default function Dashboard(props) {
  const isAdminPlus = oFetch(useLegacyGlobal(), 'isAdminPlus');

  const [isCalendarOpen, setIsCalendatOpen] = useState(false);
  const completedMossFinanceReportsData = oFetch(props, 'completedMossFinanceReportsData');
  const onFinishMossFinanceReportWeek = oFetch(props, 'onFinishMossFinanceReportWeek');
  const onUndoMossFinanceReportWeek = oFetch(props, 'onUndoMossFinanceReportWeek');
  const [completedMossFinanceReportsCount, mossFinanceReportsCount] = oFetch(
    completedMossFinanceReportsData,
    'completedCount',
    'count',
  );
  const { title } = props;
  const venueId = oFetch(props, 'venueId');

  const mWeekStartDate = safeMoment.uiDateParse(oFetch(props, 'startDate'));
  const mWeekEndDate = safeMoment.uiDateParse(oFetch(props, 'endDate'));
  const formattedStartDate = mWeekStartDate.format(utils.commonDateFormatCalendar());
  const formattedEndDate = mWeekEndDate.format(utils.commonDateFormatCalendar());
  const onFilterChange = oFetch(props, 'onFilterChange');
  const filterType = oFetch(props, 'filterType');
  const popoverClass = classNames({
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
    'boss-page-dashboard__meta-item_state_opened': isCalendarOpen,
  });

  const showPDFDownloadLink = oFetch(props, 'showPDFDownloadLink');
  const mossFinanceReportWeek = oFetch(props, 'mossFinanceReportWeek');
  const mossFinanceReportWeekId = oFetch(mossFinanceReportWeek, 'id');
  const canFinishMossFinanceReportWeek = oFetch(mossFinanceReportWeek, 'canFinish');
  const mossFinanceReportWeekStatusText = utils.capitalizeFirstCharacter(
    oFetch(mossFinanceReportWeek, 'uiStatus'),
  );
  const isMossFinanceReportWeekFinished = oFetch(mossFinanceReportWeek, 'isFinished');
  const mossFinanceReportsOverviewData = oFetch(props, 'mossFinanceReportsOverviewData');
  const calculatedTotalGrossCents = oFetch(mossFinanceReportsOverviewData, 'calculatedTotalGrossCents');
  const calculatedTotalGross = utils.centsFormat(calculatedTotalGrossCents);

  function togglePopover() {
    setIsCalendatOpen(!isCalendarOpen);
  }

  function showWrongFilterCSVDownloadModal(e) {
    e.preventDefault();
    openWarningModal({
      submit: closeModal => {
        closeModal();
      },
      config: {
        title: 'Action Disabled',
        text: 'You must be filtering by "Show All" to export data as CSV.',
        buttonText: 'Dismiss',
      },
    });
  }

  function renderCalendar() {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={mWeekStartDate.format(utils.uiRotaDateFormat)}
          onChange={selection => props.onDateChange(selection)}
          onCancelClick={togglePopover}
        />
      </div>
    );
  }

  function renderMarkMossFinanceReportWeekButton() {
    if (isMossFinanceReportWeekFinished) {
      return null;
    }
    if (!canFinishMossFinanceReportWeek) {
      const content = <span>Cannot mark as finished while incomplete finance reports exist.</span>;
      return (
        <Tooltip arrow theme="dark" position="bottom" interactive html={content}>
          <button
            className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
            disabled={true}
          >
            Mark Finished
          </button>
        </Tooltip>
      );
    }
    return (
      <AsyncButton
        className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
        text="Mark Finished"
        pendingText="Processing ..."
        onClick={() =>
          onFinishMossFinanceReportWeek({ weekStart: oFetch(props, 'startDate'), venueId: venueId })
        }
      />
    );
  }

  function renderMossFinanceReportWeekStatus() {
    if (isMossFinanceReportWeekFinished) {
      const [finishedBy, finishedAt] = oFetch(mossFinanceReportWeek, 'finishedBy', 'finishedAt');
      const formattedFinishedAt = safeMoment
        .iso8601Parse(finishedAt)
        .format(utils.humanDateFormatWithTimeAndSeconds(finishedAt));

      return (
        <Fragment>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
            <span className="boss-page-dashboard__meta-text">Week Status: </span>
            <span className="boss-page-dashboard__meta-text boss-page-dashboard__meta-text-accent-green">
              &nbsp;FINISHED
            </span>
            {isAdminPlus && (
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-button_role_undo-light boss-table__action"
                style={{ marginLeft: '15px' }}
                text="Undo Completion"
                pendingText="Processing ..."
                onClick={() => onUndoMossFinanceReportWeek({ mossFinanceReportWeekId })}
              />
            )}
          </p>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_details">
            <span className="boss-page-dashboard__meta-text">
              Finished by {finishedBy} at {formattedFinishedAt}
            </span>
          </p>
        </Fragment>
      );
    }
    return (
      <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
        <span className="boss-page-dashboard__meta-text">Week Status: </span>
        <span className="boss-page-dashboard__meta-text">
          &nbsp;{mossFinanceReportWeekStatusText.toUpperCase()}
        </span>
      </p>
    );
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_finance-reports">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            {
              <div className="boss-page-dashboard__buttons-group">
                {showPDFDownloadLink && (
                  <a
                    href={appRoutes.mossFinanceReportsPdfDownload({
                      date: mWeekStartDate,
                      venueId,
                      filterType,
                    })}
                    className="boss-button boss-button_role_download boss-page-dashboard__button"
                  >
                    Download PDF
                  </a>
                )}
              </div>
            }
          </div>

          <h4>Grand Total</h4>
          <div className="boss-page-dashboard__group">
            <div className="boss-table boss-table_page_finance-reports-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">
                          Calculated Total Gross:
                        </p>
                        <h3 className="boss-number__value boss-number__value_size_s">
                          {calculatedTotalGross}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-page-dashboard__group">
            <div className="boss-table boss-table_page_finance-reports-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">Completed:</p>
                        <h3 className="boss-number__value boss-number__value_size_s">
                          {completedMossFinanceReportsCount} / {mossFinanceReportsCount}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__sub-group">
              <div className="boss-page-dashboard__meta">
                <Popover
                  isOpen={isCalendarOpen}
                  body={renderCalendar()}
                  appendTarget={document.body}
                  place="below"
                  tipSize={0.01}
                  onOuterAction={togglePopover}
                  className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                  style={{ marginTop: '10px' }}
                >
                  <p className={popoverClass} onClick={togglePopover}>
                    <span className="boss-page-dashboard__meta-text">{formattedStartDate}</span>
                    {' - '}
                    <span className="boss-page-dashboard__meta-text">{formattedEndDate}</span>
                  </p>
                </Popover>
              </div>
              <div className="boss-page-dashboard__meta">{renderMossFinanceReportWeekStatus()}</div>
            </div>
            <div className="boss-page-dashboard__buttons-group">
              {renderMarkMossFinanceReportWeekButton()}
            </div>
            <div className="boss-page-dashboard__controls-group">
              <MossFinanceReportsFilter onFilterChange={onFilterChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
