import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const staffTypes = oFetch(action, 'payload.staffTypes');
      return staffTypes;
    },
  },
  initialState,
);
