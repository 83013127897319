import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import { AsyncButton } from '../../components';

export default function ContactItem(props) {
  const [contact, onEditClick, onDisalbleClick, onEnableClick, permissions] = oFetch(
    props,
    'contact',
    'onEditClick',
    'onDisalbleClick',
    'onEnableClick',
    'permissions',
  );
  const [firstName, surname, imageUrl, tags, isActive] = oFetch(
    contact,
    'firstName',
    'surname',
    'imageUrl',
    'tags',
    'isActive',
  );
  const isTagsExist = tags.length > 0;
  const itemClassNames = cn(
    'purple-panel purple-panel_role_board-group purple-panel_page_contacts-index',
    { 'purple-panel_highlight_accent-red-light purple-panel_type_faded': !isActive },
  );
  const canEditContact = oFetch(props, 'permissions.canEditContact');

  function getFullName() {
    if (firstName && surname) {
      return `${firstName} ${surname}`;
    }
    return firstName || surname;
  }

  function renderTags() {
    if (!isTagsExist) {
      return null;
    }

    return (
      <ul className="purple-profile__info">
        <li className="purple-profile__info-item">
          <p className="purple-profile__text">
            <span className="purple-profile__text-faded">Tags: </span>
            {tags.map(tag => {
              const [tagId, name] = oFetch(tag, 'id', 'name');
              return (
                <span
                  key={tagId}
                  className="purple-label purple-label_size_xxs purple-label_color_accent-pink-medium"
                >
                  <span className="purple-label__text">{name}</span>
                </span>
              );
            })}
          </p>
        </li>
      </ul>
    );
  }

  function renderActions() {
    if (isActive) {
      return (
        <Fragment>
          <button
            className="purple-button purple-button_size_xs purple-button_color_accent-orange purple-button_icon_pencil purple-panel__header-action"
            type="button"
            onClick={() => onEditClick(contact)}
          >
            <span className="purple-button__text">Edit</span>
          </button>
          <AsyncButton
            className="purple-button purple-button_size_xs purple-button_color_accent-red purple-button_icon_power purple-panel__header-action"
            loadingText="Disabling ..."
            onClick={() => onDisalbleClick(contact)}
          >
            {({ loading, loadingText }) => {
              return (
                <span className="purple-button__text">{loading ? loadingText : 'Disable'}</span>
              );
            }}
          </AsyncButton>
        </Fragment>
      );
    } else {
      return (
        <AsyncButton
          className="purple-button purple-button_size_xs purple-button_color_accent-red purple-button_icon_power purple-panel__header-action"
          loadingText="Enabling ..."
          onClick={() => onEnableClick({ contact })}
        >
          {({ loading, loadingText }) => {
            return <span className="purple-button__text">{loading ? loadingText : 'Enable'}</span>;
          }}
        </AsyncButton>
      );
    }
  }

  return (
    <div className={itemClassNames}>
      <div className="purple-panel__header">
        <div className="purple-panel__header-info">
          <div className="purple-profile purple-profile_page_contacts-index">
            <div className="purple-profile__service">
              <div className="purple-profile__avatar">
                <div className="purple-profile__avatar-inner">
                  <img
                    src={imageUrl}
                    alt={getFullName()}
                    className="purple-profile__avatar-image"
                  />
                </div>
              </div>
            </div>
            <div className="purple-profile__content">
              <div className="purple-profile__header">
                <h2 className="purple-profile__name purple-profile__name_size_l">
                  {getFullName()}
                </h2>
              </div>
              {renderTags()}
            </div>
          </div>
        </div>
        <div className="purple-panel__header-actions">{canEditContact && renderActions()}</div>
      </div>
    </div>
  );
}
