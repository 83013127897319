import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { ForStaffMembersSecurityPayRateForm } from './for-staff-members-security-pay-rate-form';

export function EditForStaffMembersSecurityPayRateModal(props) {
  const [onSubmit, customSecurityPayRate] = oFetch(props, 'onSubmit', 'customSecurityPayRate');
  const initialValues = {
    id: oFetch(customSecurityPayRate, 'id'),
    name: oFetch(customSecurityPayRate, 'name'),
    accessLevel: oFetch(customSecurityPayRate, 'accessLevel'),
    standardHourlyRateCents: (
      new BigNumber(oFetch(customSecurityPayRate, 'standardHourlyRateCents'))
    ).div(100).decimalPlaces(2),
    weekendHourlyRateCents: (
      new BigNumber(oFetch(customSecurityPayRate, 'weekendHourlyRateCents'))
    ).div(100).decimalPlaces(2),
  };

  return <ForStaffMembersSecurityPayRateForm onSubmit={onSubmit} initialValues={initialValues} isEdit />;
}
