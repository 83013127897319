import React from 'react';
import ReactJson from 'react-json-view';
import oFetch from 'o-fetch';

export function ResultJson(props) {
  const [json, title] = oFetch(props, 'json', 'title');
  return (
    <div>
      <ReactJson
        name={title}
        src={json}
        displayObjectSize={false}
        displayDataTypes={false}
        collapsed
        displayArrayKey={false}
        enableClipboard={false}
      />
    </div>
  );
}
