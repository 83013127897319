import React from 'react';
import oFetch from 'o-fetch';
import PropTypes from 'prop-types';
import Select from '@/lib/boss-react-select';
import cn from 'classnames';
import { List } from 'immutable';

export default function BossFormSelectNew(props) {
  const onChange = oFetch(props, 'input.onChange');

  const [
    input,
    meta,
    multi,
    clearable,
    valueKey,
    labelKey,
    options,
    fieldClassName,
    selectClassName,
    errorKey,
    selectAll,
  ] = oFetch(
    props,
    'input',
    'meta',
    'multi',
    'clearable',
    'valueKey',
    'labelKey',
    'options',
    'fieldClassName',
    'selectClassName',
    'errorKey',
    'selectAll',
  );
  const externalOnChange = props.onChange;
  const [name, value] = oFetch(input, 'name', 'value', 'onBlur');

  // layer to allow Select component which only works with js arrays to work with immutable.js
  function normalizeValue(value) {
    if (List.isList(value)) {
      return value.toJS();
    } else if (Array.isArray(value)) {
      return value;
    } else if (value === null || value === '' || value === undefined) {
      return [];
    } else {
      return value;
    }
  }

  const { [errorKey]: error, touched, submitError } = meta;
  const { label, placeholder, valueComponent, optionComponent, disabled, extraProps } = props;
  const fieldLabel = label === undefined ? name : label;
  const hasError = touched && (error || submitError);
  const selectClassNames = cn('boss-form__select', {
    'boss-form__select_state_error': hasError,
    [selectClassName]: !!selectClassName,
  });
  const fieldClassNames = cn('boss-form__field', { [fieldClassName]: !!fieldClassName });

  function handleChange(rawValue) {
    let values = rawValue;
    if (multi) {
      const arrayValues = rawValue
        .split(',')
        .filter(Boolean)
        .map(id => Number.isNaN(parseInt(id)) ? id : parseInt(id));
      values = arrayValues;
    }

    if (externalOnChange) {
      externalOnChange(values);
    }
    onChange(values);
  }

  function handleClearAll() {
    onChange([]);
  }

  function handleSelectAll() {
    const allValues = options.map(option => oFetch(option, valueKey));
    onChange(allValues);
  }

  function renderClearAddAll() {
    if (selectAll && multi) {
      const allValues = options.map(option => oFetch(option, valueKey));
      const allSelected = allValues.length === value.length;
      const empty = value.length === 0;

      return (
        <div className="boss-form__label-actions">
          {!empty && (
            <button
              onClick={handleClearAll}
              type="button"
              className="boss-action boss-action_role_form boss-form__label-action"
            >
              <span className="boss-action__text">Clear All</span>
            </button>
          )}
          {!allSelected && (
            <button
              onClick={handleSelectAll}
              type="button"
              className="boss-action boss-action_role_form boss-form__label-action"
            >
              <span className="boss-action__text">Add All</span>
            </button>
          )}
        </div>
      );
    }
    return null;
  }

  return (
    <div className={fieldClassNames}>
      <div className="boss-form__label-group">
        {fieldLabel && (
          <label
htmlFor={name}
className="boss-form__label"
          >
            <span className="boss-form__label-text">{fieldLabel}</span>
          </label>
        )}
        {renderClearAddAll()}
      </div>
      <div className={selectClassNames}>
        <Select
          name={name}
          value={normalizeValue(value)}
          valueKey={valueKey}
          labelKey={labelKey}
          onChange={handleChange}
          clearable={clearable}
          valueComponent={valueComponent}
          optionComponent={optionComponent}
          simpleValue
          multi={multi}
          placeholder={placeholder}
          options={options}
          disabled={disabled}
          extraProps={extraProps}
        />
      </div>
      {hasError && (
        <div className="boss-form__error">
          <p className="boss-form__error-text">
            {(error || submitError).map((errorItem, index) => {
              return (
                <span
key={index}
className="boss-form__error-line"
                >
                  {errorItem}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </div>
  );
}

BossFormSelectNew.propTypes = {
  label: PropTypes.string,
  valueComponent: PropTypes.func,
  optionComponent: PropTypes.func,
  fieldClassName: PropTypes.string,
  selectName: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
};

BossFormSelectNew.defaultProps = {
  clearable: false,
  valueKey: 'value',
  labelKey: 'label',
  multi: false,
  fieldClassName: '',
  selectClassName: '',
  errorKey: 'error',
  disabled: false,
  selectAll: false,
};
