import React from 'react';
import oFetch from 'o-fetch';

export function SecurityShiftRequestsVenueList(props) {
  const [grouppedList, itemRender] = oFetch(props, 'grouppedList', 'itemRender');
  const itemsExist = Object.keys(grouppedList).length > 0;

  function renderList() {
    return Object.entries(grouppedList).map(entry => {
      const [venueName, items] = entry;
      return React.cloneElement(itemRender({ venueName, items }), {
        key: venueName,
      });
    });
  }

  return (
    <div className="boss-board__inner">
      <div className="boss-board__cards">
        {itemsExist && renderList()}
        {!itemsExist && (
          <div className="boss-page-main__text-placeholder">There are no requests to show.</div>
        )}
      </div>
    </div>
  );
}
