import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';

import VoucherUsages from './containers/voucher-usages';
import configureStore from '@/apps/store';
import reducers from './reducers';
import { initialLoad } from './actions';

function VouchersUsageApp(props) {
  const [store, setStore] = useState(null);
  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }

  return (
    <Provider store={store}>
      <VoucherUsages />
    </Provider>
  );
}

export default VouchersUsageApp;
