import React from 'react';
import oFetch from 'o-fetch';

export default function HoursAcceptancePeriodList(props) {
  const [
    hoursAcceptancePeriods,
    acceptedPeriodRenderer,
    pendingPeriodRenderer,
    deletedPeriodRenderer,
    permissions,
    currentDay,
  ] = oFetch(
    props,
    'hoursAcceptancePeriods',
    'acceptedPeriodRenderer',
    'pendingPeriodRenderer',
    'deletedPeriodRenderer',
    'permissions',
    'currentDay',
  );

  function renderHoursAcceptancePeriods(params) {
    const permissions = oFetch(params, 'permissions');
    const currentDay = oFetch(params, 'currentDay');

    return hoursAcceptancePeriods.map(hoursAcceptancePeriod => {
      const [hoursAcceptancePeriodId, status] = oFetch(
        hoursAcceptancePeriod,
        'frontendId',
        'status',
      );

      if (status === 'accepted') {
        return React.cloneElement(
          acceptedPeriodRenderer({ hoursAcceptancePeriod, permissions, currentDay }),
          {
            key: hoursAcceptancePeriodId,
          },
        );
      }
      if (status === 'pending') {
        return React.cloneElement(
          pendingPeriodRenderer({ hoursAcceptancePeriod, permissions, currentDay }),
          {
            key: hoursAcceptancePeriodId,
          },
        );
      }
      if (status === 'deleted') {
        return React.cloneElement(
          deletedPeriodRenderer({ hoursAcceptancePeriod, permissions, currentDay }),
          {
            key: hoursAcceptancePeriodId,
          },
        );
      }
      throw new Error(`Wrong status, expected: [accepted, pending, deleted], got: ${status} `);
    });
  }

  return (
    <div className="boss-hrc__shifts">
      {renderHoursAcceptancePeriods({ permissions, currentDay })}
    </div>
  );
}
