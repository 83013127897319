import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { BossFormTextarea } from '@/components/boss-form';

class MarkMisattributedLoginHandledForm extends React.Component {
  render() {
    return (
      <div>
        <p className="boss-modal-window__message-note">Please provide an explaination of how this was handled below</p>
        <Field label="" name="reason" component={BossFormTextarea} />
        <div className="boss-modal-window__actions">
          <button
            onClick={this.props.handleSubmit}
            disabled={this.props.submitting}
            className="boss-button boss-button_role_alert boss-modal-window__button"
          >
            Mark Handled
          </button>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  values: ['id'],
  form: 'mark-misattributed-login-handled-form',
})(MarkMisattributedLoginHandledForm);
