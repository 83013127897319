import { createRoot } from 'react-dom/client';

export default function modalDecorator(openModalFn) {
  return function ({ submit, config = {}, props, closeCallback = () => {} }) {
    const handleClose = () => {
      setTimeout(() => {
        wrapper.remove();
        rootWrapper.unmount();
      }, 50);
    };
    const bodyFirst = document.body.firstChild;
    const wrapper = document.createElement('div');
    const rootWrapper = createRoot(wrapper);
    bodyFirst.parentNode.insertBefore(wrapper, bodyFirst);

    const handleSubmit = (...args) => {
      return Promise.resolve(submit(handleClose, ...args));
    };
    return openModalFn(config, props, handleSubmit, handleClose, rootWrapper, closeCallback);
  };
}
