import React from 'react';
import ProfileWrapper from '../../profile-wrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccessoriesPage from '../components/accessories-page';
import {
  canCreateAccessoryRequest,
  getAccessoryRequestPermission,
  getAccessoriesOptions,
} from '../selectors';
import { currentStaffMemberSelector } from '../../profile-wrapper/selectors';
import {
  newAccessory,
  cancelAccessory,
  refundAccessory,
  filter,
  editAccessoryRequestPayslipDate,
  editAccessoryRefundRequestPayslipDate,
  returnAccessory,
  writeOffAccessory,
} from '../redux/actions';

const mapStateToProps = state => {
  return {
    accessories: state.getIn(['accessoriesPage', 'accessories']),
    accessoryRequests: state.getIn(['accessoriesPage', 'accessoryRequests']),
    staffMember: currentStaffMemberSelector(state),
    mPayslipStartDate: state.getIn(['accessoriesPage', 'mPayslipStartDate']),
    mPayslipEndDate: state.getIn(['accessoriesPage', 'mPayslipEndDate']),
    getAccessoryRequestPermission: getAccessoryRequestPermission(state),
    canCreateAccessoryRequest: canCreateAccessoryRequest(state),
    accessoriesOptions: getAccessoriesOptions(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        newAccessory,
        cancelAccessory,
        refundAccessory,
        filter,
        editAccessoryRequestPayslipDate,
        editAccessoryRefundRequestPayslipDate,
        returnAccessory,
        writeOffAccessory,
      },
      dispatch,
    ),
  };
};

function AccessoriesContainer(props) {
  return (
    <ProfileWrapper currentPage="accessories">
      <AccessoriesPage {...props} />
    </ProfileWrapper>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesContainer);
