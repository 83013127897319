import { handleActions, combineActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as commonTypes from '../../../redux/types';

const initialState = {};

export default handleActions(
  {
    [commonTypes.LOAD_INITIAL_DATA]: (state, action) => {
      const history = oFetch(action.payload, 'handoverPlannerTasksHistory');
      return { ...history };
    },
    [commonTypes.UPDATE_TASK]: (state, action) => {
      const [task, history] = oFetch(action.payload, 'task', 'history');
      const taskGUID = oFetch(task, 'guid');
      return { ...state, [taskGUID]: history };
    },
  },
  initialState,
);
