import React from 'react';
import oFetch from 'o-fetch';

type Props = {
  title: string;
  children: React.ReactElement;
};

export function MonthCard(props: Props) {
  const title = oFetch(props, 'title');
  const children = oFetch(props, 'children');

  return (
    <div className='boss-board boss-board_context_stack'>
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">{title}</h2>
      </header>
      <div className='boss-board__main'>
        <div className='boss-board__main-inner'>
          {children}
        </div>
      </div>
    </div>
  );
}
