import React from 'react';
import oFetch from 'o-fetch';

export default function DashboardWrapper(props) {
  const children = oFetch(props, 'children');

  return (
    <div className="purple-page-main__dashboard purple-page-main__dashboard_role_primary">
      <div className="purple-page-main__inner">{children}</div>
    </div>
  );
}
