import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form';
import { bossRequestHttp } from '@/lib/request-api';
import { appRoutes } from '@/lib/legacy-routes';
import { normalizeReduxFormErrors } from '@/lib/utils';

export function addMossStaffMemberRequest(params) {
  const values = oFetch(params, 'values');

  return bossRequestHttp({
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ message: 'Add Moss Staff Member Success' });
      const mossStaffMemberId = oFetch(params, 'data.moss_staff_member_id');
      window.location = appRoutes.mossStaffMemberProfile({ mossStaffMemberId });
    },
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage({ message: 'Add Moss Staff Member Failed' });
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'firstName',
            'surname',
            'masterVenueId',
            'mossPayRateId',
            'mossStaffTypeId',
            'pinCode',
          ],
        });
        const normalizedErrors = normalizeReduxFormErrors(errors);
        throw new SubmissionError(normalizedErrors);
      }
    },
  }).post(`/api/v1/moss_staff_members`, { ...values });
}
