import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import { CollapsibleCard } from '@/components/hooks-components';
import { MissingVenueWagePercentageSection, VenueHours, WagePercentage } from './';

export function VenueItem(props) {
  const [venue, payrollBreakdown, venueWagePercentage, renderManagersList] = oFetch(
    props,
    'venue',
    'payrollBreakdown',
    'venueWagePercentage',
    'renderManagersList',
  );
  const venueName = oFetch(venue, 'name');
  const salesDataExists = oFetch(venueWagePercentage, 'salesDataExists');

  function renderTitle() {
    return (
      <div className="boss-board__info-group">
        <h2 className="boss-board__title boss-board__title_size_medium boss-board__title_role_venue">
          {venueName}
        </h2>
      </div>
    );
  }

  function renderAdditionalGroups() {
    return (
      <Fragment>
        { salesDataExists && <WagePercentage venueWagePercentage={venueWagePercentage} />}
        { !salesDataExists && <MissingVenueWagePercentageSection venueWagePercentage={venueWagePercentage} />}
        <VenueHours payrollBreakdown={payrollBreakdown} />
      </Fragment>
    );
  }

  return (
    <CollapsibleCard
      className={'boss-board boss-board_role_bonai'}
      title={renderTitle}
      defaultOpen={true}
      additionalGroups={renderAdditionalGroups}
    >
      {renderManagersList()}
    </CollapsibleCard>
  );
}
