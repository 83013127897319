import oFetch from 'o-fetch';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { BossFormTextarea } from '@/components/boss-form';

export function ReportForm(props) {
  const [initialValues, onSubmit] = oFetch(props, 'initialValues', 'onSubmit');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <>
            <h3>
              <b>Please explain the steps to Reproduce the error (if you haven’t already)</b>
            </h3>
            <Field name="reportText" parse={value => value} component={BossFormTextarea} />
            <div className="boss-form__field">
              <button
                disabled={submitting || !values.reportText}
                onClick={handleSubmit}
                className="boss-button boss-form__submit"
                type="submit"
              >
                Submit report
              </button>
            </div>
          </>
        );
      }}
    />
  );
}
