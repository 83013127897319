import { fromJS, Map, List } from 'immutable';
import { handleActions } from 'redux-actions';
import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';

import * as types from './constants';

const initialState = fromJS({
  effectiveStaffMember: fromJS({}),
  accessToken: null,
  owedHours: fromJS([]),
  newOwedHour: false,
  editOwedHour: false,
  editedOwedHours: fromJS({}),
  startDate: null,
  endDate: null,
  payslipStartDate: null,
  payslipEndDate: null,
  permissionsData: fromJS({}),
});

const owedHoursReducer = handleActions(
  {
    [types.INITIAL_LOAD]: (state, action) => {
      const [
        effectiveStaffMember,
        owedHours,
        startDate,
        endDate,
        payslipStartDate,
        payslipEndDate,
        mossHourTags,
      ] = oFetch(
        action.payload,
        'effectiveStaffMember',
        'owedHours',
        'startDate',
        'endDate',
        'payslipStartDate',
        'payslipEndDate',
        'mossHourTags',
      );
      const permissionsData = oFetch(action.payload, 'permissionsData');
      const canEnable = oFetch(permissionsData, 'canEnable');
      const owedHoursPermissions = oFetch(permissionsData, 'owedHoursTab.owed_hours');
      const canCreateOwedHours = oFetch(permissionsData, 'owedHoursTab.canCreateOwedHours');

      return state
        .set('effectiveStaffMember', fromJS(effectiveStaffMember))
        .set('owedHours', fromJS(owedHours))
        .set('startDate', startDate ? safeMoment.uiDateParse(startDate) : null)
        .set('endDate', endDate ? safeMoment.uiDateParse(endDate) : null)
        .set('payslipStartDate', payslipStartDate ? safeMoment.uiDateParse(payslipStartDate) : null)
        .set('payslipEndDate', payslipEndDate ? safeMoment.uiDateParse(payslipEndDate) : null)
        .set('permissionsData', fromJS({ canEnable, owedHoursPermissions, canCreateOwedHours }))
        .set('mossHourTags', fromJS(mossHourTags));
    },
    [types.ADD_OWED_HOUR]: (state, action) => {
      const owedHour = oFetch(action, 'payload.owedHour');
      const owedHourId = oFetch(owedHour, 'id');
      const permissions = oFetch(action, 'payload.permissions');

      return state
        .update('owedHours', owedHours => owedHours.push(fromJS(owedHour)))
        .updateIn(['permissionsData', 'owedHoursPermissions'], owedHoursPermissions =>
          owedHoursPermissions.set(owedHourId, fromJS(permissions)),
        );
    },
    [types.UPDATE_OWED_HOUR]: (state, action) => {
      const oldOwedHourId = oFetch(action, 'payload.id');
      const owedHour = oFetch(action, 'payload.owedHour');
      const owedHourId = oFetch(owedHour, 'id');
      if (oldOwedHourId === owedHourId) {
        return state;
      }
      const permissions = oFetch(action, 'payload.permissions');

      return state
        .update('owedHours', owedHours => {
          return owedHours.filter(owedHour => owedHour.get('id') !== oldOwedHourId).push(fromJS(owedHour));
        })
        .updateIn(['permissionsData', 'owedHoursPermissions'], owedHoursPermissions =>
          owedHoursPermissions.set(owedHourId, fromJS(permissions)),
        );
    },
    [types.DELETE_OWED_HOUR]: (state, action) => {
      const owedHourId = action.payload;

      return state.update('owedHours', owedHours =>
        owedHours.filter(owedHour => owedHour.get('id') !== owedHourId),
      );
    },
    [types.FILTER]: (state, action) => {
      const { owedHours, startDate, endDate, payslipStartDate, payslipEndDate } = action.payload;

      const permissionsData = oFetch(action.payload, 'permissionsData');
      const canEnable = oFetch(permissionsData, 'canEnable');
      const owedHoursPermissions = oFetch(permissionsData, 'owedHoursTab.owed_hours');
      const canCreateOwedHours = oFetch(permissionsData, 'owedHoursTab.canCreateOwedHours');

      return state
        .set('owedHours', fromJS(owedHours || []))
        .set('startDate', startDate ? safeMoment.uiDateParse(startDate) : null)
        .set('endDate', endDate ? safeMoment.uiDateParse(endDate) : null)
        .set('payslipStartDate', payslipStartDate ? safeMoment.uiDateParse(payslipStartDate) : null)
        .set('payslipEndDate', payslipEndDate ? safeMoment.uiDateParse(payslipEndDate) : null)
        .set('permissionsData', fromJS({ canEnable, owedHoursPermissions, canCreateOwedHours }));
    },
  },
  initialState,
);

export default owedHoursReducer;
