import React from 'react';
import { createRoot } from 'react-dom/client';
import Transition from 'react-transition-group/Transition';
import oFetch from 'o-fetch';

const BOSS_TYPE = 'boss';
const PURPLE_TYPE = 'purple';

const TYPES = [PURPLE_TYPE, BOSS_TYPE];

const BOSS_STATUSES = {
  success: 'boss-alert_status_success',
  error: 'boss-alert_status_danger',
};

const PURPLE_STATUSES = {
  success: 'purple-note_color_accent-green purple-note_icon_check',
  error: 'purple-note_color_accent-red purple-note_icon_alert-circle',
};

const BLOCK_CLASSES = {
  [BOSS_TYPE]: 'boss-alert boss-alert_role_page-note boss-alert_position_fixed',
  [PURPLE_TYPE]: 'purple-note purple-note_adjust_action',
};

const TITLE_CLASSES = {
  [BOSS_TYPE]: 'boss-alert__text',
  [PURPLE_TYPE]: 'purple-note__text',
};

const CLOSE_CLASSES = {
  [BOSS_TYPE]: 'boss-alert__button-close',
  [PURPLE_TYPE]: 'purple-note__action-dismiss',
};

const STATUSES = {
  [BOSS_TYPE]: BOSS_STATUSES,
  [PURPLE_TYPE]: PURPLE_STATUSES,
};

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    setTimeout(() => {
      this.setState(state => ({ show: true }));
    }, 50);

    setTimeout(() => {
      this.setState(state => ({ show: false }));
    }, props.interval - duration);
  }

  handleHide = status => {
    this.setState(state => ({ show: !state.show }));
  };

  render() {
    const type = oFetch(this.props, 'type');
    return (
      <Transition in={this.state.show} timeout={duration} unmountOnExit={true}>
        {state => {
          return (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              className={`${BLOCK_CLASSES[type]} ${this.props.status}`}
            >
              <p className={TITLE_CLASSES[type]}>{this.props.confirmation}</p>
              <button onClick={this.handleHide} className={CLOSE_CLASSES[type]} />
            </div>
          );
        }}
      </Transition>
    );
  }
}

export default (confirmation, options = {}) => {
  const bodyFirst = document.body.firstChild;
  const wrapper = document.createElement('div');
  const rootWrapper = createRoot(wrapper);
  const type = options.type || 'boss';
  if (!TYPES.includes(type)) {
    throw new Error(`Must be one of ${TYPES.join(', ')} type`);
  }
  bodyFirst.parentNode.insertBefore(wrapper, bodyFirst);
  const status = STATUSES[type][options.status];

  const close = () => {
    clearInterval(clear);
    removeComponent();
  };

  const removeComponent = () => {
    wrapper.remove();
    rootWrapper.unmount();
  };

  const interval = options.interval || 5000;

  const clear = setTimeout(() => {
    removeComponent();
  }, interval);

  rootWrapper.render(
    <Notification
      onClose={() => close()}
      confirmation={confirmation}
      status={status}
      type={type}
      interval={interval}
    />,
  );
};
