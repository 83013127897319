import React, { useState } from 'react';
import Popover from 'react-popover';
import queryString from 'query-string';
import oFetch from 'o-fetch';
import cn from 'classnames';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import WeekPicker from '@/components/react-dates/boss-week-picker';

export default function StaffTrackingWeekPicker(props) {
  const [weekStart, weekEnd] = oFetch(props, 'weekStart', 'weekEnd');
  const [isCalendarOpen, setCalendarOpenState] = useState(false);
  const arrowClassNames = cn(
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover',
    { 'boss-popover_state_opened': isCalendarOpen },
  );

  const formattedWeekStart = safeMoment
    .uiDateParse(weekStart)
    .format(utils.commonDateFormatCalendar());
  const formattedWeekEnd = safeMoment.uiDateParse(weekEnd).format(utils.commonDateFormatCalendar());

  function selectWeek(selection) {
    const startUIDate = oFetch(selection, 'startUIDate');
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      week_start: startUIDate,
    })}`;
    location.href = url;
  }

  function toggleCalendar() {
    setCalendarOpenState(!isCalendarOpen);
  }

  function renderCalendar() {
    return (
      <WeekPicker
        onCancelClick={toggleCalendar}
        selectionStartUIDate={weekStart}
        onChange={selectWeek}
      />
    );
  }
  return (
    <Popover
      isOpen={isCalendarOpen}
      body={renderCalendar()}
      place="below"
      appendTarget={document.body}
      tipSize={0.01}
      onOuterAction={toggleCalendar}
      style={{ marginTop: '10px' }}
    >
      <button onClick={toggleCalendar} type="button" className="boss-page-dashboard__meta">
        <p className={arrowClassNames} data-popover="1">
          <span className="boss-page-dashboard__meta-text">{formattedWeekStart}</span>
          {' — '}
          <span className="boss-page-dashboard__meta-text">{formattedWeekEnd}</span>
        </p>
      </button>
    </Popover>
  );
}
