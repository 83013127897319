import React, { useState } from 'react';
import oFetch from 'o-fetch';
const ModalsContext = React.createContext();

function ModalsProvider(props) {
  const [modals, setModals] = useState([]);
  const [processing, setProcessing] = useState(false);
  const children = oFetch(props, 'children');

  const showModal = modalComponent => {
    setModals(previousModalsState => {
      const prevNewModals = previousModalsState.map(component => {
        return React.cloneElement(component);
      });
      return [...prevNewModals, modalComponent];
    });
  };

  const closeModal = () => {
    setModals(previousModalsState => {
      return previousModalsState.slice(0, -1);
    });
  };

  return (
    <ModalsContext.Provider value={{ showModal, closeModal, setProcessing, processing }}>
      {modals}
      {children}
    </ModalsContext.Provider>
  );
}

export { ModalsContext, ModalsProvider };
