import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = {
  weekStart: null,
  weekEnd: null,
  editAbility: false,
  currentVenueId: null,
  currentVenueName: null,
};

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const [weekStart, weekEnd, editAbility, currentVenueId, currentVenueName] = oFetch(
        action,
        'payload.weekStart',
        'payload.weekEnd',
        'payload.editAbility',
        'payload.currentVenueId',
        'payload.currentVenueName',
      );
      return {
        weekStart,
        weekEnd,
        editAbility,
        currentVenueId,
        currentVenueName,
      };
    },
  },
  initialState,
);
