import React, { Component } from 'react';
import PropTypes from 'prop-types';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { appRoutes } from '@/lib/legacy-routes';

class VenueMobileItem extends Component {
  render() {
    const venue = oFetch(this.props, 'venue');
    const [
      venueId,
      name,
      bossVenue,
      mossVenue,
      venueEnabled,
      availableBossStaffCount,
      fixedBossStaffCount,
      safeFloatPoundValue,
      tillFloatPoundValue,
      lat,
      lng,
      changeOrderSiteId,
    ] = oFetch(
      venue,
      'id',
      'name',
      "bossVenue",
      "mossVenue",
      'enabled',
      'availableBossStaffCount',
      'fixedBossStaffCount',
      'safeFloatPoundValue',
      'tillFloatPoundValue',
      'lat',
      'lng',
      'changeOrderSiteId',
    );
    const [onEditVenue, onOpenFruitOrderFields, onDisableVenue, onEnableVenue] = oFetch(
      this.props,
      'onEditVenue',
      'onOpenFruitOrderFields',
      'onDisableVenue',
      'onEnableVenue',
    );
    const venueTypeStringParts = [bossVenue && "Boss", mossVenue && "Moss"]
      //remove empty values
      .filter((el) => el);
    const venueTypeString = venueTypeStringParts.join(", ")

    return (
      <div className="boss-check boss-check_role_board boss-check_page_venues-index">
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <h3 className="boss-check__title boss-check__title_adjust_wrap">{name}</h3>
          </div>
        </div>
        <div className="boss-check__row boss-check__row_marked">
          <div className="boss-check__info-table">
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Type</p>
              </div>
              <div className="boss-check__info-cell">
                <p className="boss-check__text boss-check__text_role_primary">
                  {venueTypeString}
                </p>
              </div>
            </div>
            { bossVenue && <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Available Boss Staff</p>
              </div>
              <div className="boss-check__info-cell">
                <p className="boss-check__text boss-check__text_role_primary">
                  <a href={oFetch(appRoutes, 'staffMembersIndex')({ venueId })} className="boss-check__link">
                    {availableBossStaffCount}
                  </a>
                </p>
              </div>
            </div> }
            { bossVenue && <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Fixed Boss Staff</p>
              </div>
              <div className="boss-check__info-cell">
                <p className="boss-check__text boss-check__text_role_primary">{fixedBossStaffCount}</p>
              </div>
            </div> }
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Safe Float</p>
              </div>
              <div className="boss-check__info-cell">
                { bossVenue && <p className="boss-check__text boss-check__text_role_primary">
                  {utils.moneyFormat(safeFloatPoundValue)}
                  </p> }
              </div>
            </div>
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Till Float</p>
              </div>
              <div className="boss-check__info-cell">
                { bossVenue && <p className="boss-check__text boss-check__text_role_primary">
                  {utils.moneyFormat(tillFloatPoundValue)}
                </p> }
              </div>
            </div>
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Location</p>
              </div>
              <div className="boss-check__info-cell">
                { bossVenue && <p className="boss-check__text">
                  {lat}, {lng}
                </p> }
              </div>
            </div>
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Change Order Site ID</p>
              </div>
              <div className="boss-check__info-cell">
                { bossVenue && <p className="boss-check__text boss-check__text_adjust_wrap">{changeOrderSiteId}</p> }
              </div>
            </div>
            <div className="boss-check__info-row">
              <div className="boss-check__info-cell">
                <p className="boss-check__text">Fruit Order Fields</p>
              </div>
              <div className="boss-check__info-cell">
                { bossVenue && <p className="boss-check__text boss-check__text_role_primary">
                  <button
                    onClick={() => onOpenFruitOrderFields(venue)}
                    type="button"
                    className="boss-button boss-button_role_details boss-button_type_extra-small boss-table__action"
                  >
                    Details
                  </button>
                </p> }
              </div>
            </div>
          </div>
        </div>
        { venueEnabled && <div className="boss-check__row boss-check__row_role_buttons">
            <button
              onClick={() => onEditVenue(venue)}
              type="button"
              className="boss-button boss-button_role_edit-light-alt boss-button_type_small boss-check__button"
            >
              Edit
            </button>
            <button
              onClick={() => onDisableVenue({ venue }) }
              type="button"
              className="boss-button boss-button_role_cancel-light boss-button_type_small boss-table__action"
            >
             Disable
            </button>
          </div> }
        { !venueEnabled && <div className="boss-check__row boss-check__row_role_buttons">
            <button
              onClick={ () => onEnableVenue({ venue }) }
              type="button"
              className="boss-button boss-button_role_confirm-light boss-button_type_small boss-table__action">
              Enable
            </button>
          </div> }
      </div>
    );
  }
}

VenueMobileItem.propTypes = {
  venue: PropTypes.object.isRequired,
};

export default VenueMobileItem;
