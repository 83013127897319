import React from 'react';
import oFetch from 'o-fetch';
import { HealthCheckTableRowItem } from './health-check-table-row-item';
import { HealthCheckIndexMobileCard } from './health-check-index-mobile-card';

export default function HealthCheckList(props) {
  const list = oFetch(props, 'list');

  function renderTableRows() {
    const listExists = list.length !== 0;
    if (!listExists) {
      return <h3>No Venue Health Checks</h3>;
    }
    return list.map(listItem => {
      const itemId = oFetch(listItem, 'id');
      return <HealthCheckTableRowItem healthCheck={listItem} key={itemId} />;
    });
  }

  function renderMobileItems() {
    const listExists = list.length !== 0;
    if (!listExists) {
      return <h3>No Venue Health Checks</h3>;
    }
    return list.map(listItem => {
      const itemId = oFetch(listItem, 'id');
      return <HealthCheckIndexMobileCard healthCheck={listItem} key={itemId} />;
    });
  }

  return (
    <div className="boss-page-main__group boss-page-main__group_adjust_vhci-table">
      <div className="boss-table boss-table_page_vhci">{ renderTableRows() }</div>
      { renderMobileItems() }
    </div>
  );
}
