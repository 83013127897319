import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';

import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const answers = oFetch(action.payload, 'answers');
      return answers;
    },
    [types.ADD_QUESTION]: (state, action) => {
      const answers = oFetch(action.payload, 'answers');
      return [...state, ...answers];
    },
    [types.ADD_QUESTION_AND_DELETE_OLD]: (state, action) => {
      const answers = oFetch(action.payload, 'answers');
      const questionId = oFetch(action.payload, 'questionId');
      const newAnswers = state.filter(answer => oFetch(answer, 'questionId') !== questionId);
      return [...newAnswers, ...answers];
    },
  },
  initialState,
);
