import React, { useState } from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { useLegacyGlobal } from '@/components/hooks-components/global';

import DashboardWrapper from '@/components/dashboard-wrapper';
import { QuarterSelector } from '@/components/quarter-selector';
import { YearSelector } from '@/components/year-selector';

import { DashboardFilter } from './dashboard-filter';

export function BonusPaymentsDashboard(props) {
  const renderFilter = oFetch(props, 'renderFilter');
  const [quarter, year, years, quarterNumbers] = oFetch(
    useLegacyGlobal(),
    'quarter',
    'year',
    'years',
    'quarterNumbers',
  );
  const [selectedQuarter, setSelectedQuarter] = useState(quarter);
  const [selectedYear, setSelectedYear] = useState(year);

  function handleQuarterSelect(value) {
    setSelectedQuarter(value);
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      quarter: value,
    })}`;
    window.location.href = url;
  }

  function handleYearSelect(value) {
    setSelectedYear(value);
    const queryStringObject = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...queryStringObject,
      year: value,
    })}`;
    window.location.href = url;
  }

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_kpi">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Bonus Payments</h1>
          <div className="boss-page-dashboard__controls-group">
            <div className="boss-form">
              <div className="boss-form__row">
                <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
                  <p className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                    <span className="boss-form__label-text">Year</span>
                  </p>
                  <YearSelector selected={selectedYear} onChange={handleYearSelect} options={years} />
                </div>
                <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_min">
                  <p className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                    <span className="boss-form__label-text">Quarter</span>
                  </p>
                  <QuarterSelector
                    name="dashboard_quarter"
                    selected={selectedQuarter}
                    quarterNumbers={quarterNumbers}
                    onChange={handleQuarterSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boss-page-dashboard__group">{renderFilter()}</div>
      </div>
    </DashboardWrapper>
  );
}
