import React from 'react';
import oFetch from 'o-fetch';
import { Attachment } from '../attachment';

export function TaskDetailsContent(props) {
  const [description, attachments] = oFetch(props, 'description', 'attachments');
  const hasAttachments = attachments.length > 0;

  return (
    <div className="boss-task__group boss-task__group_role_description">
      <div className="boss-task__group-header">
        <div className="boss-task__group-info">
          <h3 className="boss-task__group-title">Description</h3>
        </div>
      </div>
      <div className="boss-task__group-content" style={{ marginBottom: 10 }}>
        <div className="boss-task__description" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {hasAttachments && (
        <div className="boss-task__group-header">
          <div
            className="boss-task__group-info"
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <h3 className="boss-task__group-title" style={{ marginBottom: 10 }}>
              Referenced Files
            </h3>
            <div>
              {attachments.map(attachment => {
                const attachmentId = oFetch(attachment, 'id');
                return <Attachment key={attachmentId} attachment={attachment} />;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
