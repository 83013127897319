import oFetch from 'o-fetch';
import React, { useState } from 'react';
import BossWeekPickerPopover from '@/components/react-dates/boss-week-picker-popover';
import queryString from 'query-string';

// Version of BossWeekPickerPopover that can be used from a
// rails view on picking the week page will be redirected to
// url with dateQueryParamKey query param replaced with the new date

export default function StaticBossWeekPickerPopover(props) {
  const [weekStartDate, weekEndDate, dateQueryParamKey] = oFetch(
    props,
    'weekStartDate',
    'weekEndDate',
    'dateQueryParamKey',
  );

  const onWeekSelect = (args) => {
    const startUIDate = oFetch(args, "startUIDate");
    const location = window.location;
    const pathStart = location.toString().replace(location.search, "")

    let queryParams = queryString.parse(window.location.search)
    queryParams[dateQueryParamKey] = startUIDate;

    window.location.href = `${pathStart}?${queryString.stringify(queryParams)}`
  };

  return (
    <BossWeekPickerPopover weekStartDate={weekStartDate} weekEndDate={weekEndDate} onWeekSelect={onWeekSelect} />
  );
}