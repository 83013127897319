export const DIARIES_FETCH_REQUESTED = 'DIARIES/DIARIES_FETCH_REQUESTED';
export const DIARIES_FETCH_SUCCEEDED = 'DIARIES/DIARIES_FETCH_SUCCEEDED';
export const DIARIES_FETCH_FAILED = 'DIARIES/DIARIES_FETCH_FAILED';

export const DIARY_CREATE_REQUESTED = 'DIARIES/DIARY_CREATE_REQUESTED';
export const DIARY_CREATE_SUCCEEDED = 'DIARIES/DIARY_CREATE_SUCCEEDED';
export const DIARY_CREATE_FAILED = 'DIARIES/DIARY_CREATE_FAILED';

export const DIARY_ENABLE_REQUESTED = 'DIARIES/DIARY_ENABLE_REQUESTED';
export const DIARY_ENABLE_SUCCEEDED = 'DIARIES/DIARY_ENABLE_SUCCEEDED';
export const DIARY_ENABLE_FAILED = 'DIARIES/DIARY_ENABLE_FAILED';

export const DIARY_DISABLE_REQUESTED = 'DIARIES/DIARY_DISABLE_REQUESTED';
export const DIARY_DISABLE_SUCCEEDED = 'DIARIES/DIARY_DISABLE_SUCCEEDED';
export const DIARY_DISABLE_FAILED = 'DIARIES/DIARY_DISABLE_FAILED';

export const DIARY_UPDATE_REQUESTED = 'DIARIES/DIARY_UPDATE_REQUESTED';
export const DIARY_UPDATE_SUCCEEDED = 'DIARIES/DIARY_UPDATE_SUCCEEDED';
export const DIARY_UPDATE_FAILED = 'DIARIES/DIARY_UPDATE_FAILED';
