import { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import { isShiftRequestOverlapped } from './utils';
import * as requests from './requests';

export function useSecurityShiftRequests(props) {
  const [
    initialShiftRequests,
    startDate,
    endDate,
    date,
    initialCountsByWeekName,
    staffMembersFromProps,
    initialRotaShifts,
    initialWeekRotas,
  ] = oFetch(
    props,
    'dayShiftRequests',
    'startDate',
    'endDate',
    'date',
    'countsByWeekName',
    'staffMembers',
    'dayRotaShifts',
    'weekRotas',
  );

  const [selectedDate, setSelectedDate] = useState(date);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [assigningShiftRequest, setAssigningShiftRequest] = useState(null);
  const [shiftRequests, setShiftRequests] = useState(initialShiftRequests);
  const [rotaShifts, setRotaShifts] = useState(initialRotaShifts);
  const [weekRotas, setWeekRotas] = useState(initialWeekRotas);
  const [countsByWeekName, setCountsByWeekName] = useState(initialCountsByWeekName);
  const grouppedByVenue = utils.groupBy('venueName');

  const selectedVenuesExist = selectedVenues.length > 0;

  const normalizedCountsByWeekName = useMemo(() => {
    return Object.entries(countsByWeekName).reduce((acc, entry) => {
      const [uiDate, counts] = entry;
      const venues = selectedVenuesExist
        ? oFetch(counts, 'venues').filter(venueItem => selectedVenues.includes(oFetch(venueItem, 'id')))
        : oFetch(counts, 'venues');

      acc[uiDate] = {
        ...counts,
        count: venues.reduce((acc, venueItem) => {
          const count = oFetch(venueItem, 'count');
          return acc + count;
        }, 0),
      };
      return acc;
    }, {});
  }, [countsByWeekName, selectedVenues]);

  const totalCount = useMemo(() => {
    return Object.values(normalizedCountsByWeekName).reduce((acc, item) => {
      const count = oFetch(item, 'count');
      return acc + count;
    }, 0);
  }, [normalizedCountsByWeekName]);

  const staffMembers = useMemo(() => {
    if (!assigningShiftRequest) {
      return [];
    }
    const shiftRequestStartDate = new Date(oFetch(assigningShiftRequest, 'startsAt'));
    const shiftRequestEndDate = new Date(oFetch(assigningShiftRequest, 'endsAt'));

    return staffMembersFromProps.map(staffMember => {
      const staffMemberId = oFetch(staffMember, 'id');

      const isOverlapped = isShiftRequestOverlapped({
        rotaShifts,
        staffMemberId,
        shiftRequestStartDate,
        shiftRequestEndDate,
      });

      const ownRotaShifts = rotaShifts.filter(
        rotaShift => oFetch(rotaShift, 'staffMemberId') === staffMemberId,
      );
      return { ...staffMember, isOverlapped: isOverlapped, rotaShifts: ownRotaShifts };
    });
  }, [staffMembersFromProps, assigningShiftRequest]);

  const grouppedSecurityShiftRequest = useMemo(() => {
    if (selectedVenues.length === 0) {
      return grouppedByVenue(shiftRequests);
    }
    return grouppedByVenue(
      shiftRequests.filter(shiftRequest => selectedVenues.includes(oFetch(shiftRequest, 'venueId'))),
    );
  }, [shiftRequests, selectedVenues]);

  function weekRotaExist(rota) {
    return weekRotas.some(weekRota => oFetch(weekRota, 'id') === oFetch(rota, 'id'));
  }

  function decrementCounts(date, venueId) {
    setCountsByWeekName(prev => {
      return {
        ...prev,
        [date]: {
          ...prev[date],
          venues: oFetch(prev[date], 'venues').map(item => {
            const itemVenueId = oFetch(item, 'id');
            if (itemVenueId === venueId) {
              return {
                ...item,
                count: oFetch(item, 'count') - 1,
              };
            }
            return item;
          }),
        },
      };
    });
  }

  function addRotaShift(rotaShift) {
    setRotaShifts([...rotaShifts, rotaShift]);
  }

  function addRota(rota) {
    setWeekRotas([...weekRotas, rota]);
  }

  function removeShiftRequest(id) {
    setShiftRequests(prev => {
      return prev.filter(shiftRequest => oFetch(shiftRequest, 'id') !== id);
    });
  }

  function handleWeekChange(selectedDate) {
    setIsFetching(true);
    setSelectedDate(selectedDate);
    return requests.loadRequestsByDateRequest({
      values: { date: selectedDate },
      onSuccess(data) {
        const securityShiftRequests = oFetch(data, 'securityShiftRequests');
        const rotaShifts = oFetch(data, 'rotaShifts');
        setShiftRequests(securityShiftRequests);
        setRotaShifts(rotaShifts);
        setIsFetching(false);
      },
      onFailure() {},
    });
  }

  function handleVenueChange(selected) {
    setSelectedVenues(selected.map(i => parseInt(i)));
  }
  function handleOpenAssignPage(assigningShiftRequest) {
    setAssigningShiftRequest(assigningShiftRequest);
  }
  function handleCloseAssignPage(venueName) {
    setAssigningShiftRequest(null);
    setTimeout(() => {
      const elmnt = document.getElementById(utils.toSnakeCase(venueName));
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }, 500);
  }
  function handleRejectSecurityShiftRequest(values) {
    return requests.rejectShiftRequestRequest({
      values: values,
      onSuccess(data) {
        const securityShiftRequest = oFetch(data, 'securityShiftRequest');
        const securityShiftRequestId = oFetch(securityShiftRequest, 'id');
        removeShiftRequest(securityShiftRequestId);
        decrementCounts(selectedDate);
      },
    });
  }

  function handleAssignShiftRequest(params) {
    const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
    const venueId = oFetch(values, 'venueId');

    return requests.assignShiftRequestRequest({
      values: values,
      onSuccess(data) {
        const securityShiftRequestId = oFetch(values, 'id');
        const [rotaShift, rota] = oFetch(data, 'rotaShift', 'rota');
        onSuccess();
        addRotaShift(rotaShift);
        removeShiftRequest(securityShiftRequestId);
        decrementCounts(selectedDate, venueId);
        if (weekRotaExist(rota)) {
          addRota(rota);
        }
      },
      onFailure,
    });
  }
  return {
    shiftRequests: grouppedSecurityShiftRequest,
    startDate,
    endDate,
    selectedDate,
    isFetching,
    onWeekChange: handleWeekChange,
    counts: normalizedCountsByWeekName,
    onVenueChange: handleVenueChange,
    onRejectSecurityShiftRequest: handleRejectSecurityShiftRequest,
    onOpenAssignPage: handleOpenAssignPage,
    onCloseAssignPage: handleCloseAssignPage,
    selectedVenues: selectedVenues,
    assigningShiftRequest: assigningShiftRequest,
    onAssignShiftRequest: handleAssignShiftRequest,
    staffMembers: staffMembers,
    totalCount: totalCount,
  };
}
