import { handleActions } from 'redux-actions';
import * as types from '../types';
import oFetch from 'o-fetch';

const initialState = {};
export default handleActions(
  {
    [types.LOAD_INITIAL_DATA]: (state, action) => {
      const payload = oFetch(action, 'payload');
      const permissions = oFetch(payload, 'permissions');
      return permissions;
    },
  },
  initialState,
);
