import React from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { modalRedux } from '@/components/modals';
import oFetch from 'o-fetch';

import SecurityShiftRequestForm from '@/components/security-shift-requests/security-shift-request-form';


class EditSecurityShiftRequest extends React.Component {
  render() {
    const initialValues = oFetch(this.props, 'editRequestFormInitialValues');
    const appType = oFetch(initialValues, 'appType');

    return (
      <SecurityShiftRequestForm
        appType={appType}
        onFormSubmit={this.props.onSubmit}
        buttonText="Update"
        buttonClass="boss-button boss-form__submit"
        initialValues={initialValues}
      />
    );
  }
}

EditSecurityShiftRequest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  editRequestFormInitialValues: PropTypes.object.isRequired,
};

export default modalRedux(combineReducers({ form: formReducer }))(
  EditSecurityShiftRequest,
);
