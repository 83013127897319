import React, { useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { Form, Field } from 'react-final-form';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { BossFormDaterangePicker, BossFormSelectNew, BossFormInput, Fields } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function AcceptedTasksFilter(props) {
  const [onSubmit, acceptedTasks, initialValues] = oFetch(
    props,
    'onSubmit',
    'acceptedTasks',
    'initialValues',
  );
  const [isOpened, setIsOpened] = useState(false);
  const [venueOptions, userOptions] = oFetch(useLegacyGlobal(), 'venueOptions', 'userOptions');

  const filterButtonClassNames = cn('boss-dropdown__switch boss-dropdown__switch_role_filter', {
    'boss-dropdown__switch_state_opened': isOpened,
  });

  function toggleFilter() {
    setIsOpened(!isOpened);
  }

  return (
    <div className="boss-page-dashboard__filter">
      <div className="boss-dropdown">
        <div className="boss-dropdown__header">
          <button onClick={toggleFilter} className={filterButtonClassNames}>
            Filtering
          </button>
        </div>
        <Collapse isOpened={isOpened}>
          <div style={{ paddingBottom: 15 }} />
          <div className="boss-dropdown__content" style={{ display: 'block', marginTop: 0 }}>
            <div className="boss-dropdown__content-inner">
              <Form
                onSubmit={values => onSubmit(values)}
                initialValues={initialValues}
                initialValuesEqual={() => true}
                className="boss-form"
                render={({ handleSubmit }) => {
                  return (
                    <>
                      <div className="boss-form__row">
                        <Field
                          name="title"
                          label="Title"
                          fieldClassName="boss-form__field_layout_half"
                          parse={value => value}
                          component={BossFormInput}
                        />
                        <Fields
                          names={['startDate', 'endDate']}
                          fieldClassName="boss-form__field_layout_half"
                          label="Date"
                          format={'DD-MM-YYYY'}
                          originalRender={BossFormDaterangePicker}
                        />
                      </div>
                      <Field
                        name="venueIds"
                        label="Venue"
                        multi
                        clearable
                        component={BossFormSelectNew}
                        parse={value => value}
                        options={venueOptions}
                      />
                      <Field
                        name="assignedToId"
                        label="Assigned To"
                        valueKey="id"
                        labelKey="fullName"
                        component={BossFormSelectNew}
                        clearable
                        options={userOptions}
                      />
                      <div className="boss-form__field boss-form__field_justify_end boss-form__field_position_last">
                        <button
                          onClick={handleSubmit}
                          className="boss-button boss-form__submit boss-form__submit_adjust_single"
                          type="submit"
                        >
                          Update
                        </button>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
