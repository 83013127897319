import { useReducer } from 'react';
import { createAction, handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

const UPDATE_VENUE = 'UPDATE_VENUE';

const updateVenueAction = createAction(UPDATE_VENUE);

const reducer = handleActions(
  {
    [UPDATE_VENUE]: (state, action) => {
      const venue = oFetch(action, 'payload.venue');
      const venueId = oFetch(venue, 'id');
      return state.map((item) => {
        const venueInStateId = oFetch(item, 'id');
        if (venueInStateId === venueId) {
          return venue;
        }
        return item;
      });
    },
  },
  [],
);

export default function useApiKeysApiCalls(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  function revokeApiKey(params) {
    const apiKeyId = oFetch(params, 'apiKeyId');
    const apiBasePath = oFetch(params, 'apiBasePath');

    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatch(updateVenueAction(data));
      },
    }).delete(`${apiBasePath}/${apiKeyId}`);
  }

  function createApiKey(params) {
    const venueId = oFetch(params, 'venueId');
    const apiBasePath = oFetch(params, 'apiBasePath');

    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage();
        dispatch(updateVenueAction(data));
      },
    }).post(apiBasePath, {
      venueId: venueId,
    });
  }

  return {
    state,
    revokeApiKey,
    createApiKey,
  };
}
