import oFetch from 'o-fetch';
import React from 'react';
import { Form, Field } from 'react-final-form';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export function PermissionRequestForm(props) {
  const [onSubmit, onReject, permissionRequest, lastIpAddress, onChangeVenue] = oFetch(
    props,
    'onSubmit',
    'onReject',
    'permissionRequest',
    'lastIpAddress',
    'onChangeVenue',
  );
  const at = oFetch(permissionRequest, 'at');

  const formattedAt = safeMoment.iso8601Parse(at).format(utils.commonDateFormatWithTime());

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      className="boss-form"
      render={({ handleSubmit, submitting, submitError, ...rest }) => {
        return (
          <div className="boss-access__permission boss-access__permission_role_pending">
            <div className="boss-access__permission-header">
              <h3 className="boss-access__permission-title">
                <div className="clocking-application-app">Access</div>
              </h3>
            </div>
            <div className="boss-access__permission-group">
              <div className="boss-access__permission-info">
                <div className="boss-access__permission-meta">
                  <div className="boss-access__permission-meta-item">
                    <p className="boss-access__permission-meta-text">
                      <span className="boss-access__permission-meta-text-light">Requested at: </span>
                      <span className="boss-access__permission-meta-text-bold">{formattedAt}</span>
                    </p>
                  </div>
                  <div className="boss-access__permission-meta-item">
                    <p className="boss-access__permission-meta-text">
                      <span className="boss-access__permission-meta-text-light">IP: </span>
                      <span className="boss-access__permission-meta-text-bold">{lastIpAddress}</span>
                    </p>
                  </div>
                  <div className="boss-access__permission-meta-item">
                    <button
                      type="button"
                      onClick={onChangeVenue}
                      className="boss-button boss-button_role_edit-light-alt boss-button_type_ultra-small"
                    >
                      Change Venue
                    </button>
                  </div>
                </div>
              </div>
              <div className="boss-access__permission-actions">
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="boss-button boss-button_role_accept boss-button_type_icon boss-button_type_small boss-access__permission-action"
                >
                  Confirm
                </button>
                <button
                  onClick={onReject}
                  disabled={submitting}
                  className="boss-button boss-button_role_cancel boss-button_type_icon boss-button_type_small boss-access__permission-action"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
