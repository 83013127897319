import React from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import oFetch from 'o-fetch';
import { staffMemberProfileHolidaysPermissions } from '@/lib/permissions';
import utils from "@/lib/utils";

import { HOLIDAY_REQUEST_TYPE, HOLIDAY_TYPE, getHolidaysData } from '../selectors';
import { ProfileWrapper as BossProfileWrapper } from '../../profile-wrapper';
import { ProfileWrapper as MossProfileWrapper } from '../../../MossStaffMemberProfile/profile-wrapper';
import HolidayMobileItems from '../components/holiday-mobile-items';
import { HolidayAllowancesSection } from '../components/holiday-allowances-section.tsx';

import {
  addNewHoliday,
  cancelAddNewHoliday,
  deleteHoliday,
  openEditModal,
  closeEditModal,
  filter,
  addHolidayRequest,
  addHoliday,
  deleteHolidayRequest,
  editHolidayRequest,
  editHoliday,
  taxYearInfoRequest,
} from '../actions';

import Stats from '../components/stats';
import HolidaysHeader from '../components/holidays-header';
import HolidaysFilter from '../components/holidays-filter';
import HolidaysTable from '../components/holidays-table';
import AddNewHoliday from '../components/add-new-holiday';
import ContentModal from '@/components/content-modal';
import EditHoliday from '../components/edit-holiday';
import { BOSS_STAFF_MEMBER_TYPE, MOSS_STAFF_MEMBER_TYPE } from '../constants';

const mapStateToProps = state => {
  const stateImmut = fromJS(state);
  const stateJS = stateImmut.toJS();

  const holidaysData = oFetch(stateJS, 'holidays');
  const taxYearData = oFetch(stateJS, 'taxYear');
  const effectiveStaffMember = oFetch(holidaysData, 'effectiveStaffMember');
  const selectedTaxYear = oFetch(taxYearData, 'selectedTaxYear');

  const pageStateData = oFetch(stateJS, 'pageState');
  const taxYearFormRequesting = oFetch(pageStateData, 'taxYearFormRequesting');

  return {
    staffMember: fromJS(effectiveStaffMember),
    effectiveStaffMemberJs: effectiveStaffMember,
    holidays: getHolidaysData(stateImmut),
    holidayStartDate: fromJS(oFetch(holidaysData, 'holidayStartDate')),
    holidayEndDate: fromJS(oFetch(holidaysData, 'holidayEndDate')),
    startPayslipDate: fromJS(oFetch(holidaysData, 'startPayslipDate')),
    endPayslipDate: fromJS(oFetch(holidaysData, 'endPayslipDate')),
    newHoliday: fromJS(oFetch(holidaysData, 'newHoliday')),
    editHoliday: fromJS(oFetch(holidaysData, 'editHoliday')),
    editedHoliday: fromJS(oFetch(holidaysData, 'editedHoliday')),
    disabled: fromJS(oFetch(effectiveStaffMember, 'disabled')),
    isAdminPlus: fromJS(oFetch(holidaysData, 'isAdminPlus')),
    permissionsData: fromJS(oFetch(holidaysData, 'permissionsData')),
    selectedTaxYear,
    taxYearFormRequesting,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        addNewHoliday,
        addHoliday,
        cancelAddNewHoliday,
        deleteHoliday,
        openEditModal,
        closeEditModal,
        filter,
        addHolidayRequest,
        deleteHolidayRequest,
        editHolidayRequest,
        editHoliday,
        taxYearInfoRequest,
      },
      dispatch,
    ),
  };
};

class Holidays extends React.PureComponent {
  onAddNew = () => {
    this.props.actions.addNewHoliday();
  };

  onAddNewRequest = () => {
    this.props.actions.addNewHoliday();
  };

  onCancelAddNew = () => {
    this.props.actions.cancelAddNewHoliday();
  };

  onOpenEdit = () => {
    this.props.actions.openEditModal();
  };

  onCloseEdit = () => {
    this.props.actions.closeEditModal();
  };

  handleNewHolidaySubmit = (values, dispatch) => {
    return this.props.actions.addHoliday(values);
  };

  handleNewHolidayRequestSubmit = (values, dispatch) => {
    return this.props.actions.addHolidayRequest(values);
  };

  handleEditHolidayRequestSubmit = (values, dispatch) => {
    return this.props.actions.editHolidayRequest(values);
  };

  handleEditHolidaySubmit = (values, dispatch) => {
    return this.props.actions.editHoliday(values);
  };

  handleEditSubmit = (values, dispatch) => {
    const type = this.props.editedHoliday.get('type');
    if (type === HOLIDAY_TYPE) {
      return this.handleEditHolidaySubmit(values, dispatch);
    } else {
      return this.handleEditHolidayRequestSubmit(values, dispatch);
    }
  };

  handleDeleteHoliday = holiday => {
    const effectiveHolidayJS = utils.callIfExists({
      obj: holiday,
      funcName: 'toJS',
    });

    const effectiveHolidayType = oFetch(effectiveHolidayJS, 'type');
    const effectiveHolidayId = oFetch(effectiveHolidayJS, 'id');

    if (effectiveHolidayType === HOLIDAY_TYPE) {
      return this.props.actions.deleteHoliday({ holidayId: effectiveHolidayId });
    } else if (effectiveHolidayType === HOLIDAY_REQUEST_TYPE) {
      return this.props.actions.deleteHolidayRequest({ holidayRequestId: effectiveHolidayId });
    } else {
      throw new Error(`unsuppored effectiveHolidayType: ${effectiveHolidayType}`)
    }
  };

  render() {
    const {
      paidHolidays,
      unpaidHolidays,
      estimatedAccruedHolidayDays,
      holidayStartDate,
      holidayEndDate,
      holidays,
      newHoliday,
      editHoliday,
      editedHoliday,
      disabled,
      startPayslipDate,
      endPayslipDate,
    } = this.props;
    const actions = oFetch(this.props, 'actions');
    const [openEditModal, filter, taxYearInfoRequest] = oFetch(actions, 'openEditModal', 'filter', 'taxYearInfoRequest');
    const taxYearFormRequesting = oFetch(this.props, 'taxYearFormRequesting');
    const effectiveStaffMemberJs = oFetch(this.props, 'effectiveStaffMemberJs');
    const permissionsData = oFetch(this.props, 'permissionsData');
    const canCreateHolidays = oFetch(
      staffMemberProfileHolidaysPermissions,
      'canCreateHolidays',
    )({
      permissionsData: permissionsData,
    });
    const hasHolidays = !!holidays.size;
    const staffMemberType = oFetch(this.props, 'staffMemberType');

    const canForwardHolidays = oFetch(
      staffMemberProfileHolidaysPermissions,
      'canForwardHolidays',
    )({
      permissionsData: permissionsData,
    });


    let EffectiveProfileWrapper = undefined;
    if (staffMemberType === BOSS_STAFF_MEMBER_TYPE) {
      EffectiveProfileWrapper = BossProfileWrapper;
    } else if (staffMemberType === MOSS_STAFF_MEMBER_TYPE) {
      EffectiveProfileWrapper = MossProfileWrapper
    } else {
      throw new Error(`unsupported staffMemberType: ${staffMemberType}`)
    }

    const onAddNewAction = canCreateHolidays ? this.onAddNew : this.onAddNewRequest;
    const selectedTaxYear = oFetch(this.props, 'selectedTaxYear');

    return (
      <EffectiveProfileWrapper currentPage="holidays">
        <section className="boss-board">
          <ContentModal
            show={newHoliday}
            onClose={() => this.onCancelAddNew()}
            title={canCreateHolidays ? 'Add Holiday' : 'Request Holiday'}
          >
            <AddNewHoliday
              canForwardHolidays={canForwardHolidays}
              buttonTitle={canCreateHolidays ? 'Add Holiday' : 'Request Holiday'}
              onSubmit={(values) => {
                return canCreateHolidays ? this.handleNewHolidaySubmit(values) : this.handleNewHolidayRequestSubmit(values);
              }}
              startDate={holidayStartDate}
              endDate={holidayEndDate}
            />
          </ContentModal>
          {editHoliday && (
            <ContentModal
              show={true}
              onClose={() => this.onCloseEdit()}
              title={editedHoliday.get('type') === HOLIDAY_TYPE ? 'Edit holiday' : 'Edit holiday request'}
            >
              <EditHoliday
                canForwardHolidays={canForwardHolidays}
                buttonTitle={
                  editedHoliday.get('type') === HOLIDAY_TYPE ? 'Update holiday' : 'Update holiday request'
                }
                onSubmit={this.handleEditSubmit}
                holiday={editedHoliday}
              />
            </ContentModal>
          )}
          <HolidaysHeader
            isStaffMemberDisabled={disabled}
            buttonText="Add Holiday"
            title="Holidays and Holiday Requests"
            onAddNew={onAddNewAction}
          />

          <div className="boss-board__main">
            <div className="boss-board__manager">
              <HolidayAllowancesSection
                effectiveStaffMember={effectiveStaffMemberJs}
                selectedTaxYear={selectedTaxYear}
                taxYearInfoRequest={taxYearInfoRequest}
                taxYearFormRequesting={taxYearFormRequesting}
              />
              <div className="boss-board__manager-group boss-board__manager-group_role_data">
                <HolidaysFilter
                  startDate={holidayStartDate}
                  endDate={holidayEndDate}
                  filter={filter}
                  startPayslipDate={startPayslipDate}
                  endPayslipDate={endPayslipDate}
                />
                {hasHolidays ? (
                  [
                    <HolidaysTable
                      key="desktop"
                      isStaffMemberDisabled={disabled}
                      holidays={holidays}
                      deleteHoliday={this.handleDeleteHoliday}
                      onEditHoliday={openEditModal}
                      permissionsData={permissionsData}
                    />,
                    <HolidayMobileItems
                      key="mobile"
                      isStaffMemberDisabled={disabled}
                      holidays={holidays}
                      deleteHoliday={this.handleDeleteHoliday}
                      onEditHoliday={openEditModal}
                      permissionsData={permissionsData}
                    />,
                  ]
                ) : (
                  <h1 className="boss-table__cell boss-table__cell_role_header">NO HOLIDAYS FOUND</h1>
                )}
              </div>
            </div>
          </div>
        </section>
      </EffectiveProfileWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
