import React from 'react';
import oFetch from 'o-fetch';

export default function CountingHistory(props) {
  const [history, renderItem] = oFetch(props, 'dayCount.history', 'renderItem');

  function renderHistoryList() {
    return history.map(historyItem => {
      return React.cloneElement(renderItem(historyItem));
    });
  }

  return (
    <div className="purple-board">
      <div className="purple-board__inner">
        <div className="purple-history purple-history_type_panel">
          <ul className="purple-history__list">{renderHistoryList()}</ul>
        </div>
      </div>
    </div>
  );
}
