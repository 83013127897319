import utils from '@/lib/utils';
import * as requests from './requests';
import { useMemo, useState } from 'react';
import oFetch from 'o-fetch';
import { PublicHolidaysFormValues } from './components';
import { PublicHoliday, PublicHolidayMonthGroup } from './types/public-holiday';
import { PublicHolidaysAppProps } from '.';
import safeMoment from '@/lib/safe-moment';

type CreatePublicHolidayModalParams = {
  values: PublicHolidaysFormValues;
  onSuccess: () => void;
};

type DeletePublicHolidayModalParams = {
  publicHolidayId: number;
  onSuccess: () => void;
};


export function usePublicHolidays(props: PublicHolidaysAppProps) {
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>(oFetch(props, 'publicHolidays'));
  const [selectedYear, setSelectedYear] = useState<number>(oFetch(props, 'selectedYear'));
  const months = oFetch(props, 'months');

  function handleYearSelect(year: number) {
    setSelectedYear(year);
    window.location.href = `/public_holidays/${year}`;
  }

  function createPublicHoliday(params: CreatePublicHolidayModalParams) {
    const values = oFetch(params, 'values');
    const onSuccess = oFetch(params, 'onSuccess');
    return requests.createPublicHoliday({
      values,
      onSuccess(publicHoliday) {
        addNewPublicHoliday(publicHoliday);
        onSuccess();
      },
    });
  }

  function deletePublicHoliday(params: DeletePublicHolidayModalParams) {
    const publicHolidayId = oFetch(params, 'publicHolidayId');
    const onSuccess = oFetch(params, 'onSuccess');
    return requests.deletePublicHoliday({
      publicHolidayId,
      onSuccess(publicHoliday) {
        removeDeletedPublicHoliday(publicHoliday);
        onSuccess();
      },
    });
  }

  function addNewPublicHoliday(newPublicHoliday: any) {
    setPublicHolidays([...publicHolidays, newPublicHoliday]);
  }

  function removeDeletedPublicHoliday(deletedPublicHoliday: PublicHoliday) {
    const deletedPublicHolidayId = oFetch(deletedPublicHoliday, 'id');
    setPublicHolidays((oldState: PublicHoliday[]) => {
      return oldState.filter(publicHolidayInState => {
        const oldPublicHolidayId = oFetch(publicHolidayInState, 'id');
        return deletedPublicHolidayId !== oldPublicHolidayId;
      });
    });
  }

  const unsortedGroupedPublicHolidaysByMonth: PublicHolidayMonthGroup = useMemo(() => {
    return utils.groupBy('month')(publicHolidays);
  }, [publicHolidays]);

  const sortedGroupedPublicHolidaysByMonth: PublicHolidayMonthGroup = useMemo(() => {
    return Object.keys(unsortedGroupedPublicHolidaysByMonth).sort((a: string, b: string) => {
      return months.indexOf(a) - months.indexOf(b);
    }).reduce((obj: any, key) => {
      return {
        ...obj,
        [key]: [...unsortedGroupedPublicHolidaysByMonth[key] || []].sort((a, b) => {
          const mA = safeMoment.uiDateParse(a.date);
          const mB = safeMoment.uiDateParse(b.date);
          return mA.valueOf() - mB.valueOf();
        })
      };
    }, {});
  }, [unsortedGroupedPublicHolidaysByMonth]);

  return {
    createPublicHoliday,
    deletePublicHoliday,
    groupedPublicHolidaysByMonth: sortedGroupedPublicHolidaysByMonth,
    addNewPublicHoliday,
    selectedYear,
    onYearSelect: handleYearSelect,
    years: oFetch(props, 'years'),
  };
}
