import React from 'react'
import { FaMagic } from 'react-icons/fa';
import oFetch from 'o-fetch';

export function AskAevaButton(props) {
  const children = oFetch(props, 'children');
  const onClick = oFetch(props, 'onClick');
  const disabled = oFetch(props, 'disabled');

  return (
    <button className="boss-button boss-button_role_accepted" style={{display: 'flex', backgroundColor: '#DEFFD9', color: '#4c4c4c'}} onClick={onClick} disabled={disabled}>
      <FaMagic style={{marginRight: '5px'}}></FaMagic>
      <span>{children}</span>
    </button>
  )
}