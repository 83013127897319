import React, { useState, Fragment } from 'react';
import oFetch from 'o-fetch';
import Select from '@/lib/boss-react-select';

import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { CollapsibleFilter } from '@/components/hooks-components';
import { Pagination } from '@/components/boss-pagination';

export default function MossStaffMembersOnMossPayRatesFilter(props) {
  const [
    mossPayRateName,
    mossStaffMembersCount,
    venues,
    mossStaffTypes,
    selectedVenueId,
    selectedMossStaffTypeId,
    mossStaffMembers,
    currentPage,
    goBackUrl,
  ] = oFetch(
    props,
    'mossPayRateName',
    'mossStaffMembersCount',
    'venues',
    'mossStaffTypes',
    'selectedVenueId',
    'selectedMossStaffTypeId',
    'mossStaffMembers',
    'currentPage',
    'goBackUrl',
  );
  const mossStaffMemberExist = mossStaffMembers.length !== 0;
  const [selectedMossStaffType, setMossStaffType] = useState(selectedMossStaffTypeId);
  const [selectedVenue, setVenue] = useState(selectedVenueId);

  function renderMossStaffMembers() {
    return mossStaffMembers.map(mossStaffMember => {
      const [mossStaffMemberId, fullName, mossStaffType, url, venueName] = oFetch(
        mossStaffMember,
        'id',
        'fullName',
        'mossStaffType',
        'url',
        'venueName',
      );
      return (
        <div key={mossStaffMemberId} className="boss-table__row">
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Name</p>
              <p className="boss-table__text">
                <a href={url} className="boss-table__link">
                  {fullName}
                </a>
              </p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Staff Type</p>
              <p className="boss-table__text">{mossStaffType}</p>
            </div>
          </div>
          <div className="boss-table__cell">
            <div className="boss-table__info">
              <p className="boss-table__label">Venue</p>
              <p className="boss-table__text">{venueName}</p>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              Staff Members on{' '}
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {mossPayRateName}
              </span>{' '}
              Pay Rate <span className="boss-page-dashboard__title-info">+{mossStaffMembersCount}</span>
            </h1>
            <div className="boss-page-dashboard__buttons-group">
              <a href={goBackUrl} className="boss-button boss-page-dashboard__button">
                Back to Pay Rates
              </a>
            </div>
          </div>

          <CollapsibleFilter>
            <form action="#" className="boss-form">
              <div className="boss-form__row">
                <div className="boss-form__group boss-form__group_layout_max">
                  <div className="boss-form__row">
                    <div className="boss-form__field boss-form__field_layout_half">
                      <p className="boss-form__label">
                        <span className="boss-form__label-text">Staff Type</span>
                      </p>
                      <div className="boss-form__select">
                        <Select
                          name="pay_rate_staff_members_page_filter[staff_type]"
                          value={selectedMossStaffType}
                          simpleValue
                          onChange={setMossStaffType}
                          options={mossStaffTypes}
                        />
                      </div>
                    </div>
                    <div className="boss-form__field boss-form__field_layout_half">
                      <p className="boss-form__label">
                        <span className="boss-form__label-text">Venue</span>
                      </p>
                      <div className="boss-form__select">
                        <Select
                          className="boss-form__select"
                          name="pay_rate_staff_members_page_filter[venue]"
                          value={selectedVenue}
                          simpleValue
                          onChange={setVenue}
                          options={venues}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boss-form__field boss-form__field_layout_min boss-form__field_no-label">
                  <button
                    className="boss-button boss-form__submit boss-form__submit_adjust_single"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </CollapsibleFilter>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {!mossStaffMemberExist && <h3>No associated staff members found</h3>}
        {mossStaffMemberExist && (
          <div className="boss-page-main__group boss-page-main__group_adjust_pay-rates-table">
            <div className="boss-table boss-table_page_pay-rates-staff-members">
              <div className="boss-table__row">
                <div className="boss-table__cell boss-table__cell_role_header">Name</div>
                <div className="boss-table__cell boss-table__cell_role_header">Staff Type</div>
                <div className="boss-table__cell boss-table__cell_role_header">Venue</div>
              </div>
              {renderMossStaffMembers()}
            </div>
          </div>
        )}
      </ContentWrapper>
      <Pagination
        totalRecords={mossStaffMembersCount}
        pageLimit={20}
        pageNeighbours={1}
        initialPage={currentPage}
      />
    </Fragment>
  );
}
