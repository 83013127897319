import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class Filter extends Component {
  handleChange = e => {
    const onChange = oFetch(this.props, 'onChange');
    const value = oFetch(e, 'target.value');
    onChange(value);
  };

  render() {
    const [showingCount, fullCount] = oFetch(this.props, 'showingCount', 'fullCount');
    return (
      <div className="boss-users__filter">
        <form className="boss-form">
          <div className="boss-form__row boss-form__row_position_last boss-form__row_hidden-xs">
            <div className="boss-form__field boss-form__field_role_control">
              <p className="boss-form__label boss-form__label_type_light">
                <span className="boss-form__label-text">
                  Showing {showingCount} of {fullCount}
                </span>
              </p>
              <div className="boss-form__search">
                <input
                  onChange={this.handleChange}
                  name="search"
                  type="text"
                  className="boss-form__input"
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>
          <div className="boss-form__row boss-form__row_position_last boss-form__row_visible-xs">
            <div className="boss-form__field">
              <p className="boss-form__label boss-form__label_justify_center">
                <span className="boss-form__label-text">
                  Showing {showingCount} of {fullCount}
                </span>
              </p>
              <div className="boss-form__search">
                <input
                  name="search"
                  onChange={this.handleChange}
                  type="text"
                  className="boss-form__input"
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
