import React from 'react';
import { PublicHoliday } from '../types/public-holiday';
import oFetch from 'o-fetch';

type Props = {
  publicHolidays: PublicHoliday[];
  renderItem: (publicHoliday: PublicHoliday) => React.ReactElement;
};

export function PublicHolidayList(props: Props) {
  const publicHolidays = oFetch(props, 'publicHolidays');
  const renderItem = oFetch(props, 'renderItem');

  function renderPublicHolidays() {
    return publicHolidays.map(publicHoliday => {
      const publicHolidayId = oFetch(publicHoliday, 'id');
      return React.cloneElement(renderItem(publicHoliday), { key: publicHolidayId });
    });
  }

  return (
    <div className='boss-table boss-table_page_fruit-order-reports-history'>
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header">Date</div>
        <div className="boss-table__cell boss-table__cell_role_header">Description</div>
        <div className="boss-table__cell boss-table__cell_role_header">Created By</div>
        <div className="boss-table__cell boss-table__cell_role_header" />
      </div>
      {renderPublicHolidays()}
    </div>
  );
}
