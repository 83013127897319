import React, { Component } from 'react';
import Select from '@/lib/boss-react-select';
import oFetch from 'o-fetch';

import { AccessoryEmptyValue, AccessoryColoredIconOption, AccessoryIconOption } from '@/components/boss-form';

export const sleep = (ms, result) => new Promise(resolve => setTimeout(resolve, ms, result));

export default class AccessoriesFormField extends Component {
  state = {
    options: [],
    isLoading: false,
  };

  static defaultProps = {
    label: null,
  };

  componentDidMount = () => {
    const [accessories, getAccessories, venueId, disabled] = oFetch(
      this.props,
      'accessories',
      'getAccessories',
      'venueId',
      'disabled',
    );
    if (accessories.length === 0 && !disabled) {
      this.setState({ isLoading: true });
      getAccessories(venueId).then(() => {
        this.setState({ isLoading: false });
      });
    }
  };
  removeItem = item => {
    const [id, sizeToRemove] = item.split('_');
    const [value, onChange] = oFetch(this.props, 'input.value', 'input.onChange');
    const newSizes = value[id].filter(size => size !== sizeToRemove);
    if (newSizes.length === 0) {
      const { [id]: idToRemove, ...otherValues } = value;
      onChange(otherValues);
    } else {
      onChange({
        ...value,
        [id]: newSizes,
      });
    }
  };
  renderSelectedAccessories = value => {
    return value.map((item, index) => {
      const accessory = oFetch(this.props, 'accessories').find(option => oFetch(option, 'value') === item);
      if (!accessory) {
        throw new Error(`Accessory must be present`);
      }
      const [label, color, icon] = oFetch(accessory, 'label', 'color', 'icon');
      return (
        <div key={index} className="boss-tag boss-tag_size_m" style={{ backgroundColor: color }}>
          <p className="boss-tag__text">
            <span className={`boss-tag__text-icon boss-tag__text-icon_position_before boss-tag__text-icon_${icon}`} />{' '}
            {label}
          </p>
          <div
            onClick={() => this.removeItem(item)}
            className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
          />
        </div>
      );
    });
  };

  handleChange = value => {
    const mappedValue = value
      .split(',')
      .filter(Boolean)
      .reduce((acc, item) => {
        const [id, size] = item.split('_');
        if (!size) {
          return {
            ...acc,
            [id]: [],
          };
        }
        const sizes = acc[id] ? [...acc[id], size] : [size];
        return {
          ...acc,
          [id]: sizes,
        };
      }, []);
    const onChange = oFetch(this.props, 'input.onChange');
    onChange(mappedValue);
  };

  getValue = value => {
    return Object.entries(value).reduce((acc, entry) => {
      const [id, sizes] = entry;
      if (sizes.length === 0) {
        return [...acc, id];
      }
      const ids = sizes.map(size => {
        return `${id}_${size}`;
      });
      return [...acc, ...ids];
    }, []);
  };

  render() {
    const [value, name, accessories, disabled, label] = oFetch(
      this.props,
      'input.value',
      'input.name',
      'accessories',
      'disabled',
      'label',
    );
    return (
      <div className="boss-form__field">
        {label && (
          <label htmlFor="staffType" className="boss-form__label">
            <span className="boss-form__label-text">{label}</span>
          </label>
        )}
        {!disabled && this.renderSelectedAccessories(this.getValue(value))}
        <div className="boss-form__row boss-form__row_layout_nowrap boss-form__row_align_center">
          <div className="boss-form__field boss-form__field_layout_max">
            <div className="boss-form__select boss-form__select_type_empty-value">
              <Select
                name={name}
                options={accessories}
                simpleValue
                multi
                disabled={disabled}
                placeholder={this.state.isLoading ? 'Loading ...' : 'Search ...'}
                clearable={false}
                valueComponent={AccessoryEmptyValue}
                optionComponent={AccessoryColoredIconOption}
                isLoading={this.state.isLoading}
                value={this.getValue(value)}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
