import http from './http';
import { apiRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';

export const getStaffMembersWithoutAddress = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-without-address`);
};

export const getStaffMembersWithSameSageId = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-with-same-sage-id`);
};

export const getStaffMembersWithoutNINumber = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-without-ni-number`);
};

export const getStaffMembersWithoutEmail = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-without-email`);
};

export const getStaffMembersWithoutPhoto = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-without-photo`);
};

export const getStaffMembersWithExpiredSIABadge = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-with-expired-sia-badge`);
};

export const getStaffMembersOnWrongPayrate = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-on-wrong-payrate`);
};

export const getStaffMembersWithBouncedEmails = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/staff-with-bounced-email`);
};

export const getTimeDodgersRequest = date => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/time-dodgers`, {
    params: {
      date,
    },
  });
};

export const getRepeatOffendersRequest = () => {
  return http({ notify: false, globalLoader: true }).get(`/api/v1/staff_vetting/repeat-offenders`);
};

export const getMisattributedLogins = (params, globalLoader = true) => {
  const [page, filter] = oFetch(params, 'currentPage', 'filter');
  const { search } = params;
  return http({ notify: false, globalLoader: globalLoader }).get(apiRoutes.getMisattributedLogins.getPath(), {
    params: { page, filter, search: search || undefined },
  });
};

export function markHandledRequest(params) {
  const misattributedLoginId = oFetch(params, 'misattributedLoginId');
  const reason = oFetch(params, 'reason');
  const errorHandler = oFetch(params, 'errorHandler');
  const successHandler = oFetch(params, 'successHandler');

  return bossRequestHttp({
    errorHandler,
    successHandler,
  }).post(apiRoutes.markMisattributedLoginHandled.getPath(misattributedLoginId), {
    reason: reason,
  });
}

export const markRepeatOffenderRequest = params => {
  return http().post(apiRoutes.markRepeatOffender.getPath(), params);
};

export const getWithoutScannableAvatars = () => {
  return http({ notify: false, globalLoader: true }).get(apiRoutes.withoutScannableAvatars.getPath());
};
