import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { Collapse } from 'react-collapse';

export function SecurityShiftRequestsVenueItem(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [venueName, items, requestsListRender] = oFetch(props, 'venueName', 'items', 'requestsListRender');
  const itemsCount = items.length;

  function toggle() {
    setIsOpened(!isOpened);
  }

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_security-shift-requests">
      <div className="boss-check__header">
        <div className="boss-check__header-group">
          <h3 className="boss-check__title boss-check__title_role_venue">
            {venueName}
            <span className="boss-check__title-indicator">
              <span className="boss-indicator boss-indicator_role_info-primary">
                <span className="boss-indicator__marker">{itemsCount}</span>
              </span>
            </span>
          </h3>
        </div>
        <button
          onClick={toggle}
          className="boss-check__dropdown-link boss-check__dropdown-link_type_icon boss-check__dropdown-link_state_closed"
        >
          Toggle Dropdown
        </button>
      </div>
      <Collapse isOpened={isOpened} className="boss-check__dropdown" style={{ display: 'block' }}>
        {requestsListRender(items)}
      </Collapse>
    </div>
  );
}
