import React, { Component } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export const IS_FIRST_STEP = 'first';
export const IS_MIDDLE_STEP = 'middle';
export const IS_LAST_STEP = 'last';

const OPACITY = 0.3;

export default function Step(props) {
  const [page, onSelectPage, title, selected, position, hasErrors, submitted, currentPage] = oFetch(
    props,
    'page',
    'onSelectPage',
    'title',
    'selected',
    'position',
    'hasErrors',
    'submitted',
    'currentPage',
  );
  const isOnFirstPage = currentPage === 1;
  const isFirstStep = position === IS_FIRST_STEP;
  const isLastStep = position === IS_LAST_STEP;
  const isMiddleStep = position === IS_MIDDLE_STEP;
  const titleClassNames = cn('boss-steps__step-title', {
    'boss-steps__step-title_state_active': selected,
  });
  const indexClassNames = cn('boss-steps__step-index', {
    'boss-steps__step-index_state_with-error': hasErrors,
    'boss-steps__step-index_state_completed': !hasErrors && submitted,
  });
  const beforeClassNames = cn('boss-steps__step-line boss-steps__step-line_before', {
    'boss-steps__step-line_state_completed': !hasErrors && submitted,
  });
  const afterClassNames = cn('boss-steps__step-line boss-steps__step-line_after', {
    'boss-steps__step-line_state_completed': !hasErrors && submitted,
  });
  return (
    <div
      style={{ opacity: isOnFirstPage && !isFirstStep && OPACITY }}
      className="boss-steps__step"
      onClick={() => onSelectPage(page)}
    >
      {(isLastStep || isMiddleStep) && <div className={beforeClassNames} />}
      <div className={indexClassNames}>{page}</div>
      <div className={titleClassNames}>{title}</div>
      {(isFirstStep || isMiddleStep) && (
        <div style={{ opacity: isOnFirstPage && isFirstStep && OPACITY }} className={afterClassNames} />
      )}
    </div>
  );
}
