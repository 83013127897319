import { z } from 'zod';
import { $BossUiDate } from '@/lib/schema-funcs';
import { RotaAppType } from '@/lib/rota-date';
import { get$BossHttpErrorHandlerParams, get$BossHttpSuccessHandlerParams } from "@/lib/schema-funcs";

const $BoundaryTaxYearHoliday = z.object({
  dayCount: z.number().int().nonnegative(),
  type: z.union([
    z.literal(RotaAppType.boss),
    z.literal(RotaAppType.moss),
  ]),
  startDate: $BossUiDate(z),
  endDate: $BossUiDate(z),
}).strict();

const $TaxYearHoliday = z.object({
  dayCount: z.number().int().nonnegative(),
  type: z.union([
    z.literal(RotaAppType.boss),
    z.literal(RotaAppType.moss),
  ]),
  startDate: z.date(),
  endDate: z.date(),
}).strict();

const $EffectiveStaffMember = z.object({
  id: z.number().int().positive(),
  effective_staff_type: z.union([
    z.literal(RotaAppType.boss),
    z.literal(RotaAppType.moss),
  ]),
}).passthrough();

export type TaxYearHoliday = z.infer<typeof $TaxYearHoliday>;

export const $BoundaryTaxYear = z.object({
  year: z.number().gte(1000).lte(9999),
  startDate: $BossUiDate(z),
  endDate: $BossUiDate(z),
  paidHolidays: z.array($BoundaryTaxYearHoliday),
  plusPaidHolidays: z.array($BoundaryTaxYearHoliday),
  minusPaidHolidays: z.array($BoundaryTaxYearHoliday),
  unpaidHolidays: z.array($BoundaryTaxYearHoliday),
  estimatedAccruedHolidayDays: z.number().int().nonnegative(),
}).strict();


const $TaxYear = z.object({
  year: z.number().gte(1000).lte(9999),
  startDate: z.date(),
  endDate: z.date(),
  paidHolidays: z.array($TaxYearHoliday),
  plusPaidHolidays: z.array($TaxYearHoliday),
  minusPaidHolidays: z.array($TaxYearHoliday),
  unpaidHolidays: z.array($TaxYearHoliday),
  estimatedAccruedHolidayDays: z.number().int().nonnegative(),
}).strict();

export type TaxYear = z.infer<typeof $TaxYear>;

const $TaxYearInfoRequestArgs = z.object({
  effectiveStaffMember: $EffectiveStaffMember,
  year: z.number().int().positive(),
}).strict();

export type TaxYearInfoRquestArgs = z.infer<typeof $TaxYearInfoRequestArgs>;

const $TaxYearInfoRequestFunc = z.function().args($TaxYearInfoRequestArgs).returns(z.promise(z.unknown()));

export const $HolidayAllowancesSectionProps = z.object({
  effectiveStaffMember: $EffectiveStaffMember,
  selectedTaxYear: $TaxYear,
  taxYearInfoRequest: $TaxYearInfoRequestFunc,
  taxYearFormRequesting: z.boolean(),
}).strict();

export type HolidayAllowancesSectionProps = z.infer<typeof $HolidayAllowancesSectionProps>;

export type HolidayDayTotals = {
  accruedHolidayEstimateDays: number,
  paidHolidays: number,
  plusPaidHolidays: number,
  minusPaidHolidays: number,
  unpaidHolidays: number,
};

export const $TaxYearFormProps = z.object({
  onSubmit: z.function().args(z.object({ year: z.number().int().positive() })).returns(z.promise(z.unknown())),
  initialValues: z.object({
    selectedTaxYear: $TaxYear,
    year: z.number().gte(1000).lte(9999),
  }),
  taxYearFormRequesting: z.boolean(),
}).strict();

export type TaxYearFormProps = z.infer<typeof $TaxYearFormProps>;

export type DispatchFunc = (...arg0: any[]) => Promise<any>;

export const $UpdateTaxYearInfoActionParamsData = z.object({
  taxYear: $TaxYear,
}).strict();

const $BoundaryUpdateTaxYearInfoActionParamsData = z.object({
  taxYear: $BoundaryTaxYear,
}).strict();

export type UpdateTaxYearInfoActionParamsData = z.infer<typeof $UpdateTaxYearInfoActionParamsData>;

export const $BossHttpSuccessHandlerParams = get$BossHttpSuccessHandlerParams($BoundaryUpdateTaxYearInfoActionParamsData);

export const $BossHttpErrorHandlerParams = get$BossHttpErrorHandlerParams($BoundaryUpdateTaxYearInfoActionParamsData);

export type BossHttpSuccessHandlerParams = z.infer<typeof $BossHttpSuccessHandlerParams>;

export type BossHttpErrorHandlerParams = z.infer<typeof $BossHttpErrorHandlerParams>;

export const $StaffMemberHolidaysAppProps = z.object({
  selectedTaxYear: $BoundaryTaxYear,
}).passthrough();