import { z } from "zod";

export type WhatsappFeatureAssignment = z.infer<typeof $WhatsappFeatureAssignment>;

export type WhatsappInstance = z.infer<typeof $WhatsappInstance>;

export const $WhatsappInstance = z.object({
  id: z.number(),
  name: z.string(),
  customName: z.string().nullable(),
  isAvailable: z.boolean(),
});

export const $WhatsappFeatureAssignment = z.object({
  id: z.number(),
  whatsappFeatureTitle: z.string(),
  whatsappFeatureName: z.string(),
  mainWhatsappInstance: $WhatsappInstance.nullable(),
  fallbackWhatsappInstance: $WhatsappInstance.nullable(),
  isEnabled: z.boolean()
});

export const $UpdateWhatsappFeatureAssignmentsSuccessData = z.object({
  whatsappFeatureAssignment: $WhatsappFeatureAssignment,
});

export type UpdateWhatsappFeatureAssignmentsSuccessData = z.infer<typeof $UpdateWhatsappFeatureAssignmentsSuccessData>;
