import React from 'react';
import oFetch from 'o-fetch';
import { useSelector } from 'react-redux';
import * as selectors from '../redux/selectors';

export function Dashboard(props) {
  const onAddNewClick = oFetch(props, 'onAddNewClick');
  const canCreateTask = useSelector(selectors.canCreateTaskSelector);

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_marketing-tasks">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Marketing Tasks</h1>
          <div className="boss-page-dashboard__buttons-group">
            {canCreateTask && (
              <button
                onClick={onAddNewClick}
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                Add New Task
              </button>
            )}
            <a
              href="/marketing_tasks/accepted"
              className="boss-button boss-button_role_accepted-tasks boss-page-dashboard__button"
            >
              Accepted Tasks
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
