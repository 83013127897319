import React, { Component } from 'react';
import { Field, Fields, reduxForm } from 'redux-form/immutable';
import oFetch from 'o-fetch';
import {
  BossFormTextarea,
  OwedHoursCCTVField,
  BossFormDateTimeIntervalField,
  ImmutableBossFormTagInput,
} from '@/components/boss-form';
import { RotaAppType } from '@/lib/rota-date';
import { nonTsGetExistingMossHourTagOptions } from '@/lib/moss-hour-tags';

class OwedHoursFormComponent extends Component {
  onSubmit = (values, dispatch, form) => {
    const jsValues = values.toJS();
    return form.onSubmit(jsValues);
  };

  renderBaseError = error => {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          <p className="boss-alert__text">{error}</p>
        </div>
      </div>
    );
  };

  render() {
    const { error, handleSubmit, submitting, buttonText, edit = false } = this.props;

    const effectiveStaffMemberType = oFetch(this.props, 'effectiveStaffMemberType');

    const initialValuesJS = oFetch(this.props, 'initialValues').toJS();
    const currentMossHourTagIds = oFetch(initialValuesJS, 'mossHourTagIds');

    const mossHourTags = oFetch(this.props, 'mossHourTags');
    const mossHourTagOptions = nonTsGetExistingMossHourTagOptions({
      mossHourTags,
      currentMossHourTagIds,
    });
    const isBoss = effectiveStaffMemberType === RotaAppType.boss;

    return (
      <div className="boss-form">
        {error && this.renderBaseError(error)}
        <Fields
          names={['date', 'startsAt', 'endsAt']}
          appType={effectiveStaffMemberType}
          component={BossFormDateTimeIntervalField}
        />
        {isBoss && (
          <Fields
          names={['startsAtCCTVImage', 'endsAtCCTVImage', 'cctvImageHasChanged']}
          title="CCTV"
          description="Note: Please make sure the person's face is showing. Date and time must be visible."
          component={OwedHoursCCTVField}
          />
        )}
        <Field
          component={BossFormTextarea}
          name="note"
          label="Note"
          required
        />
        { !isBoss && (
          <div className="boss-form__field">
            <p className="boss-form__label">
              <span className="boss-form__label-text">Hours Tags</span>
            </p>

            <Field
              className="boss-form__select"
              name="mossHourTagIds"
              component={ImmutableBossFormTagInput}
              options={mossHourTagOptions}
              labelKey="name"
              valueKey="id"
              multi
              clearable
            />
          </div>
        ) }
        <div className="boss-form__field">
          <button
            type="submit"
            onClick={handleSubmit(this.onSubmit)}
            disabled={submitting}
            className="boss-button boss-button_role_add boss-form__submit"
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}

export const OwedHoursForm = reduxForm({ form: 'owed-hours-form' })(OwedHoursFormComponent);