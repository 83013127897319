import React, { useState } from 'react';
import classNames from 'classnames';
import Popover from 'react-popover';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import { appRoutes } from '@/lib/legacy-routes';

export function Dashboard(props) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  function togglePopover() {
    setIsCalendarOpen(!isCalendarOpen);
  }

  function renderCalendar(params) {
    const mStartDate = oFetch(params, 'mStartDate');
    const onDateChange = oFetch(params, 'onDateChange');

    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={mStartDate.format(utils.uiRotaDateFormat)}
          onChange={selection => onDateChange(selection)}
          onCancelClick={togglePopover}
        />
      </div>
    );
  }

  function handleDownloadCsvClick(params) {
    const mStartDate = oFetch(params, 'mStartDate');
    window.location.href = appRoutes.securityPayrollReportsCsv({ mWeekStartDate: mStartDate });
  }

  const title = oFetch(props, 'title');
  const mStartDate = oFetch(props, 'mStartDate');
  const mEndDate = oFetch(props, 'mEndDate');
  const popoverClass = classNames(
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover',
    {
      'boss-page-dashboard__meta-item_state_opened': isCalendarOpen,
    },
  );
  const onDateChange = oFetch(props, 'onDateChange');
  const canDownloadCsv = oFetch(props, 'canDownloadCsv');

  const totalCents = oFetch(props, "totalCents");
  const formattedTotalWithSign = utils.moneyFormat(totalCents / 100.0);

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__dashboard">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_payroll-reports">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{title}</h1>
            {canDownloadCsv && (
              <div className="boss-page-dashboard__buttons-group">
                <a
                  onClick={() => handleDownloadCsvClick({ mStartDate })}
                  className="boss-button boss-button_role_download boss-page-dashboard__button"
                >
                  Download CSV
                </a>
              </div>
            )}
          </div>
          <div className="boss-page-dashboard__group">
            <div className="boss-table boss-table_page_security-payroll-report-overview">
              <div className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <div className="boss-number boss-number_layout_flow">
                      <div className="boss-number__content">
                        <p className="boss-number__label boss-number__label_position_before">Total:</p>
                        <h3 className="boss-number__value boss-number__value_size_s">{ formattedTotalWithSign }</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__meta">
              <Popover
                isOpen={isCalendarOpen}
                body={renderCalendar({ mStartDate, onDateChange })}
                place="below"
                tipSize={0.01}
                appendTarget={document.body}
                onOuterAction={togglePopover}
                className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                style={{ marginTop: '10px' }}
              >
                <p className={popoverClass} onClick={togglePopover}>
                  <span className="boss-page-dashboard__meta-text">
                    {mStartDate.format(utils.commonDateFormatCalendar())}
                  </span>
                  {' - '}
                  <span className="boss-page-dashboard__meta-text">
                    {mEndDate.format(utils.commonDateFormatCalendar())}
                  </span>
                </p>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
