import React, { useState } from 'react';
import oFetch from 'o-fetch';

export function RequestActions(props) {
  const [requestPending, setRequestPending] = useState(false);

  const [status, permissions] = oFetch(props, 'status', 'permissions');
  const [onMarkReceived, onAccept, onReject, onUndo] = oFetch(
    props,
    'onMarkReceived',
    'onAccept',
    'onReject',
    'onUndo',
  );
  const [canAccept, canReject, canComplete, canUndo, canMarkReceived] = oFetch(
    permissions,
    'canAccept',
    'canReject',
    'canComplete',
    'canUndo',
    'canMarkReceived',
  );

  function handleAction(action) {
    return async () => {
      setRequestPending(true);
      await action();
      setRequestPending(false);
    };
  }

  if (requestPending) {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_success-status">
          Processing ...
        </p>
      </div>
    );
  }

  if (status === 'undo') {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_success-status">
          {requestPending ? 'Undoing ...' : ''}
        </p>
      </div>
    );
  }
  if (status === 'completed') {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_success-status">
          Completed
        </p>
      </div>
    );
  }
  if (status === 'received') {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_success-status">
          RECEIVED
        </p>
      </div>
    );
  }
  if (status === 'pending') {
    return (
      <div className="boss-table__actions">
        {canAccept && (
          <button
            className="boss-button boss-button_type_small boss-button_role_success boss-table__action"
            onClick={handleAction(onAccept)}
          >
            Accept
          </button>
        )}
        {canReject && (
          <button
            className="boss-button boss-button_type_small boss-button_role_cancel boss-table__action"
            onClick={handleAction(onReject)}
          >
            Reject
          </button>
        )}
      </div>
    );
  }
  if (status === 'accepted') {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_success-status">
          {requestPending ? 'Accepting ...' : 'Accepted'}
        </p>
        {!requestPending && (
          <div key="actions" className="boss-table__actions">
            {canMarkReceived && (
              <button
                className="boss-button boss-button_type_extra-small boss-button_role_confirm-light boss-table__action"
                onClick={handleAction(onMarkReceived)}
              >
                Mark Received
              </button>
            )}
            {canUndo && (
              <button
                className="boss-button boss-button_type_extra-small boss-button_role_cancel-light boss-table__action"
                onClick={handleAction(onUndo)}
              >
                Undo
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
  if (status === 'rejected') {
    return (
      <div>
        <p key="status" className="boss-table__text boss-table__text_role_alert-status">
          Rejected
        </p>
      </div>
    );
  }
  return null;
}
