import React from 'react';
import { useModal } from '@/components/hooks-components/modals';
import ContentWrapper from '@/components/content-wrapper';
import { Dashboard, NewMessageSchedule, MessageScheduleList, MessageScheduleItem, EditMessageSchedule } from './components';
import { messageScheduleState } from './message-schedule-state';
import type { MessageSchedule as MessageScheduleType, NewMessageScheduleFormValues } from '@/types/MessageSchedule';

interface MessageScheduleProps {
  messageSchedules: [MessageScheduleType]
}

export function MessageSchedule(props: MessageScheduleProps) {
  const { openProcessingModal } = useModal();
  const {
    messageSchedules,
    createMessageSchedule,
    disableMessageSchedule,
    updateMessageSchedule,
    selectedStatuses,
    onStatusChange
  } = messageScheduleState(props);

  function handleOpenNewMessageModal() {
    openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: NewMessageScheduleFormValues) {
        setProcessing(true);
        return createMessageSchedule({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'New Message Schedule'
      },
      ModalContent: NewMessageSchedule
    });
  }

  function handleEditNewMessageModal(messageSchedule: MessageScheduleType) {
    openProcessingModal({
      onSubmit(handleClose: any, setProcessing: any, values: NewMessageScheduleFormValues) {
        setProcessing(true);
        return updateMessageSchedule({
          values,
          onSuccess() {
            setProcessing(false);
            handleClose();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      props: {
        messageSchedule
      },
      config: {
        title: 'Edit Message Schedule'
      },
      ModalContent: EditMessageSchedule
    });
  }

  return (
    <>
      <Dashboard
        title="Messages Schedule"
        onNewMessageClick={handleOpenNewMessageModal}
        selectedStatuses={selectedStatuses}
        onStatusChange={onStatusChange}
      />
      <ContentWrapper>
        <MessageScheduleList
          messageSchedules={messageSchedules}
          renderItem={(messageSchedule) => (
            <MessageScheduleItem
              messageSchedule={messageSchedule}
              onDisable={disableMessageSchedule}
              onEdit={handleEditNewMessageModal}
            />
          )}
        />
      </ContentWrapper>
    </>
  );
}
