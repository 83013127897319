import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const hoursAcceptanceBreaks = oFetch(action, 'payload.hoursAcceptanceBreaks');
      return hoursAcceptanceBreaks.map(hoursAcceptanceBreak => {
        const id = oFetch(hoursAcceptanceBreak, 'id');
        return {
          ...hoursAcceptanceBreak,
          frontendId: id,
        };
      });
    },
    [types.CLOCK_OUT]: (state, action) => {
      const hoursAcceptanceBreaks = oFetch(action, 'payload.hoursAcceptanceBreaks');
      const hoursAcceptanceBreaksWithFrontendId = hoursAcceptanceBreaks.map(
        hoursAcceptanceBreak => {
          const hoursAcceptanceBreakId = oFetch(hoursAcceptanceBreak, 'id');
          return { ...hoursAcceptanceBreak, frontendId: hoursAcceptanceBreakId };
        },
      );
      return [...state, ...hoursAcceptanceBreaksWithFrontendId];
    },
    [types.UPDATE_PERIOD_DATA]: (state, action) => {
      const breaks = oFetch(action, 'payload.breaks');
      const frontendId = oFetch(action, 'payload.frontendId');

      return [
        ...state.filter(periodBreak => oFetch(periodBreak, 'hoursAcceptancePeriod') !== frontendId),
        ...breaks,
      ];
    },
  },
  initialState,
);
