import React, { useEffect, useState } from 'react';
import oFetch from 'o-fetch';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@/components/spinner';

import { TaskDetailsHeader } from './task-details-header';
import { TaskDetailsLoadingHeader } from './task-details-loading-header';
import { TaskDetailsContent } from './task-details-content';
import { TaskDetailsRequests } from './task-details-requests';
import { TaskDetailsReview } from './task-details-review';
import { useMarketingTaskModals } from '../../marketing-task-modals';
import * as selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';

export function TaskDetails(props) {
  const [marketingTaskId, onClose, getTaskDetailsAction] = oFetch(
    props,
    'marketingTaskId',
    'onClose',
    'getTaskDetailsAction',
  );

  const marketingTask = useSelector(selectors.marketingTaskSelector(marketingTaskId));

  if (!marketingTask) {
    return null;
  }

  const {
    openActivityModal,
    openEditTaskModal,
    openCompleteTaskModal,
    openAddRequestModal,
  } = useMarketingTaskModals();

  const [fetching, setFetching] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (marketingTask.loaded) {
      setFetching(false);
    } else {
      dispatch(
        getTaskDetailsAction({
          values: { marketingTaskId },
          onSuccess() {
            setFetching(false);
          },
        }),
      );
    }
  }, []);

  if (fetching) {
    return (
      <div className="boss-task boss-task_role_marketing">
        <TaskDetailsLoadingHeader marketingTask={marketingTask} />
        <div className="boss-task__content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner style={{ margin: 'unset' }} />
            <span>Loading task details ...</span>
          </div>
        </div>
      </div>
    );
  }

  const [id, description, attachments, requests, isAccepted, permissions] = oFetch(
    marketingTask,
    'id',
    'description',
    'attachments',
    'requests',
    'isAccepted',
    'permissions',
  );

  const canCreateRequestQuestion = oFetch(permissions, 'canCreateRequestQuestion');

  const normalizedRequests = requests.reduce(
    (acc, request) => {
      const isRemoved = oFetch(request, 'isRemoved');
      if (isRemoved) {
        acc.removed = [...acc.removed, request];
      } else {
        acc.active = [...acc.active, request];
      }
      return acc;
    },
    { active: [], removed: [] },
  );

  function handleGrabTask() {
    return dispatch(actions.grabMarketingTask({ marketingTaskId: id }));
  }

  function handleRemoveClick() {
    return dispatch(
      actions.removeMarketingTask({
        marketingTaskId: id,
        onSuccess() {
          onClose();
        },
      }),
    );
  }

  return (
    <div className="boss-task boss-task_role_marketing">
      <TaskDetailsHeader
        onActivityClick={() => openActivityModal(marketingTask)}
        onEditClick={openEditTaskModal}
        onRemoveClick={() => handleRemoveClick()}
        onGrab={handleGrabTask}
        onComplete={() => openCompleteTaskModal(marketingTask, onClose)}
        marketingTask={marketingTask}
      />
      <div className="boss-task__content">
        <TaskDetailsContent description={description} attachments={attachments} />
        <TaskDetailsRequests
          activeRequests={oFetch(normalizedRequests, 'active')}
          removedRequests={oFetch(normalizedRequests, 'removed')}
          onAddRequestClick={() => openAddRequestModal(marketingTask)}
          isAcceptedTask={isAccepted}
          canCreateRequest={canCreateRequestQuestion}
        />
        <TaskDetailsReview marketingTask={marketingTask} />
      </div>
    </div>
  );
}
