import React from 'react';
import oFetch from 'o-fetch';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import { useMarketingTaskModals } from '../../MarketingTasks/marketing-task-modals';
import * as actions from '../redux/actions';

export function AcceptedTaskItem(props) {
  const task = oFetch(props, 'task');
  const { openTaskDetailsModal } = useMarketingTaskModals();

  const [title, venueName, deadlineDate, assignedUser, completedAt, acceptedAt, isEmergency] = oFetch(
    task,
    'title',
    'venueName',
    'deadlineDate',
    'assignedUser',
    'completedAt',
    'acceptedAt',
    'isEmergency',
  );
  const formattedCompletedAt = safeMoment.iso8601Parse(completedAt).format(utils.fullDayTimeFormat);
  const formattedAcceptedAt = safeMoment.iso8601Parse(acceptedAt).format(utils.fullDayTimeFormat);

  return (
    <div className="boss-check boss-check_role_panel boss-check_page_marketing-task">
      <div className="boss-check__header">
        {isEmergency && (
          <div className="boss-check__header-info">
            <p className="boss-button boss-button_type_ultra-small boss-button_role_exclamation">Emergency</p>
          </div>
        )}
        <div className="boss-check__header-info">
          <h3 className="boss-check__title">{title}</h3>
        </div>
        <div className="boss-check__header-info">
          <div className="boss-check__header-meta">
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_venue">{venueName}</p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_date">{deadlineDate}</p>
            </div>
            <div className="boss-check__header-meta-item">
              <p className="boss-check__text boss-check__text_role_user">
                {oFetch(assignedUser, 'fullName')}
              </p>
            </div>
          </div>
          <div className="boss-check__header-actions">
            <button
              onClick={() => openTaskDetailsModal(task, actions.getMarketingTaskDetails)}
              className="boss-button boss-button_type_ultra-small boss-button_role_view-details-light boss-check__header-action"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
      <div className="boss-check__group boss-check__group_marked">
        <div className="boss-check__group-content">
          <div className="boss-check__meta">
            <div className="boss-check__meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-label">Finished at: </span>
                {formattedCompletedAt}
              </p>
            </div>
            <div className="boss-check__meta-item">
              <p className="boss-check__text">
                <span className="boss-check__text-label">Accepted at: </span>
                {formattedAcceptedAt}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
