import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';
import * as commonSelectors from '../../profile-wrapper/selectors';

import * as types from './types';
import * as selectors from './selectors';

export const changeFilter = createAction(types.CHANGE_FILTER);
export const incrementPage = createAction(types.INCREMENT_PAGE);
export const updateHistory = createAction(types.UPDATE_HISTORY);

export const loadMore = () => (dispatch, getState) => {
  const pages = selectors.pagesSelector(getState());
  const staticData = selectors.staticSelector(getState());
  const filter = selectors.filterSelector(getState());
  const currentStaffMember = commonSelectors.currentStaffMemberSelector(getState()).toJS();

  const [total, current] = oFetch(pages, 'total', 'current');
  const quizCategoryId = oFetch(staticData, 'quizCategoryId');
  const nextPage = Math.min(total, current + 1);
  const staffMemberId = oFetch(currentStaffMember, 'id');
  const [startDate, endDate] = oFetch(filter, 'startDate', 'endDate');

  return bossRequestHttp({
    errorHandler(params) {},
    successHandler(params) {
      const quizCategoryHistoryEvents = oFetch(params, 'data.quizCategoryHistoryEvents');
      dispatch(incrementPage({ nextPage }));
      dispatch(updateHistory({ quizCategoryHistoryEvents }));
    },
  }).get(`/api/v1/staff_members/${staffMemberId}/quizzes/${quizCategoryId}/category-history`, {
    params: {
      page: nextPage,
      startDate,
      endDate,
    },
  });
};

export const handleFilter = params => dispatch => {
  const [uiStartDate, uiEndDate] = oFetch(params, 'uiStartDate', 'uiEndDate');
  dispatch(changeFilter({ startDate: uiStartDate, endDate: uiEndDate }));
};
