import oFetch from "o-fetch";
import { useMemo, useState } from "react";
import { WhatsappFeatureAssignment, WhatsappInstance } from "./types";
import * as requests from "./requests";

export function useWhatsappFeatures(props: any) {
  const [whatsappFeatures, setWhatsappFeatures] = useState(oFetch(props, 'whatsappFeatures'));
  const whatsappInstances = oFetch(props, 'whatsappInstances');

  const whatsappInstanceOptions = useMemo(() => {
    return whatsappInstances.map((whatsappInstance: WhatsappInstance) => {
      const whatsappInstanceId = oFetch(whatsappInstance, 'id');
      const whatsappInstanceName = oFetch(whatsappInstance, 'name');
      const whatsappInstanceAvailable = oFetch(whatsappInstance, 'isAvailable');
      return {
        value: whatsappInstanceId,
        label: whatsappInstanceName,
        disabled: !whatsappInstanceAvailable,
      };
    });
  }, [whatsappInstances]);

  // function addWhatsappServer(newWhatsappServer: WhatsappServer) {
  //   return setWhatsappServers([...whatsappServers, newWhatsappServer]);
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function updateWhatsappFeatureAssignmentsInState(updateWhatsappFeature: WhatsappFeatureAssignment) {
    return setWhatsappFeatures((oldWhatsappFeatures: WhatsappFeatureAssignment[]) => {
      return oldWhatsappFeatures.map((whatsappFeature: WhatsappFeatureAssignment) => {
        if (updateWhatsappFeature.id === whatsappFeature.id) {
          return updateWhatsappFeature;
        }
        return whatsappFeature;
      });
    });
  }

  function updateWhatsappFeatureAssignments(params: requests.UpdateWhatsappFeatureAssignmentParams) {
    const { onSuccess, onFailure } = params;
    return requests.updateWhatsappFeatureAssignments({
      values: params.values,
      onSuccess(data) {
        const { whatsappFeatureAssignment } = data;
        updateWhatsappFeatureAssignmentsInState(whatsappFeatureAssignment);
        onSuccess(data);
      },
      onFailure() {
        onFailure();
      }
    });
  }

  // function removeWhatsappInstance(removedWhatsappServiceInstance: WhatsappInstance) {
  //   return setWhatsappServers((oldWhatsappServers: WhatsappServer[]) => {
  //     return oldWhatsappServers.map((whatsappServer: WhatsappServer) => {
  //       if (removedWhatsappServiceInstance.whatsappServerId === whatsappServer.id) {
  //         return {
  //           ...whatsappServer,
  //           instances: whatsappServer.instances.filter(
  //             (whatsappServiceInstance: WhatsappInstance) => whatsappServiceInstance.id !== removedWhatsappServiceInstance.id
  //           )
  //         };
  //       }
  //       return whatsappServer;
  //     });
  //   });
  // }

  // function createWhatsappServer(params: requests.CreateWhatsappServerParams) {
  //   const { onSuccess, onFailure } = params;
  //   return requests.createWhatsappServer({
  //     values: params.values,
  //     onSuccess(data) {
  //       const { whatsappServer } = data;
  //       addWhatsappServer(whatsappServer);
  //       onSuccess(data);
  //     },
  //     onFailure() {
  //       onFailure();
  //     }
  //   });
  // }

  // function createWhatsappInstance(params: requests.CreateWhatsappInstanceParams) {
  //   const { onSuccess, onFailure } = params;
  //   return requests.createWhatsappInstance({
  //     values: params.values,
  //     onSuccess(data) {
  //       const { whatsappServer } = data;
  //       updateWhatsappServers(whatsappServer);
  //       onSuccess(data);
  //     },
  //     onFailure() {
  //       onFailure();
  //     }
  //   });
  // }

  // function updateWhatsappFeatures(params: requests.UpdateWhatsappFeaturesParams) {
  //   const { onSuccess, onFailure } = params;
  //   return requests.updateWhatsappFeatures({
  //     values: params.values,
  //     onSuccess(data) {
  //       const { whatsappServer } = data;
  //       updateWhatsappServers(whatsappServer);
  //       onSuccess(data);
  //     },
  //     onFailure() {
  //       onFailure();
  //     }
  //   });
  // }

  // function restartWhatsappServer(params: requests.RestartWhatsappServerParams) {
  //   const { onSuccess, onFailure, whatsappServerId } = params;
  //   return requests.restartWhatsappServer({
  //     whatsappServerId,
  //     onSuccess(data: any) {
  //       const { whatsappServer } = data;
  //       updateWhatsappServers(whatsappServer);
  //       onSuccess(data);
  //     },
  //     onFailure() {
  //       onFailure();
  //     }
  //   });
  // }

  // function disableWhatsappInstance(params: requests.DisableWhatsappInstanceParams) {
  //   const { onSuccess, onFailure, whatsappInstance } = params;
  //   return requests.disableWhatsappInstance({
  //     whatsappInstance,
  //     onSuccess() {
  //       onSuccess();
  //       removeWhatsappInstance(whatsappInstance);
  //     },
  //     onFailure() {
  //       onFailure();
  //     }
  //   });
  // }

  return {
    whatsappFeatures,
    updateWhatsappFeatureAssignments,
    whatsappInstanceOptions
  };
}
