import React from 'react';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { VenueDashboard } from './venue-dashboard';

export function VenueDashboardApp(props) {
  const { accessToken, ...otherProps } = props;

  if (!accessToken) {
    throw new Error('Access token must be present');
  }
  window.boss.accessToken = accessToken;

  return (
    <ModalsProvider>
      <VenueDashboard {...otherProps} />
    </ModalsProvider>
  );
}
