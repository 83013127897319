import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const weekDaysCounts = oFetch(action, 'payload.weekDaysCounts');
      return weekDaysCounts;
    },
    [types.ADD_DAY_COUNT]: (state, action) => {
      const weekDayCount = oFetch(action, 'payload.weekDayCount');
      return [...state, weekDayCount];
    },
    [types.UPDATE_DAY_COUNT]: (state, action) => {
      const weekDayCount = oFetch(action, 'payload.weekDayCount');
      return state.map(inStoreDayCount => {
        const dayCountId = oFetch(weekDayCount, 'id');
        const inStoreDayCountId = oFetch(inStoreDayCount, 'id');
        if (dayCountId === inStoreDayCountId) {
          return weekDayCount;
        }
        return inStoreDayCount;
      });
    },
  },
  initialState,
);
