import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import oFetch from 'o-fetch';

export function RequestItemServiceButtons(props) {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [
    onEditClick,
    onHistoryClick,
    onRemoveClick,
    isAccepted,
    isRemoved,
    isAcceptedTask,
    canEdit,
    canRemove,
  ] = oFetch(
    props,
    'onEditClick',
    'onHistoryClick',
    'onRemoveClick',
    'isAccepted',
    'isRemoved',
    'isAcceptedTask',
    'canEdit',
    'canRemove',
  );

  return (
    <Tooltip
      className="boss-task__request-service"
      arrow
      open={isTooltipOpened}
      theme="light"
      position="left"
      onRequestClose={() => setIsTooltipOpened(false)}
      interactive
      html={
        <>
          {!isAccepted && !isRemoved && !isAcceptedTask && canEdit && (
            <button
              onClick={() => {
                onEditClick();
                setIsTooltipOpened(false);
              }}
              type="button"
              className="boss-action boss-action_role_edit-admin boss-action_size_s boss-action_adjust_row"
            >
              <span className="boss-action__text">Edit Question</span>
            </button>
          )}
          <button
            onClick={() => {
              onHistoryClick();
              setIsTooltipOpened(false);
            }}
            type="button"
            className="boss-action boss-action_role_history boss-action_size_s boss-action_adjust_row"
          >
            <span className="boss-action__text">History</span>
          </button>
          {!isRemoved && !isAcceptedTask && canRemove && (
            <button
              type="button"
              onClick={() => {
                onRemoveClick();
                setIsTooltipOpened(false);
              }}
              className="boss-action boss-action_role_remove boss-action_size_s boss-action_adjust_row"
            >
              <span className="boss-action__text">Remove Request</span>
            </button>
          )}
        </>
      }
    >
      <button
        onClick={() => setIsTooltipOpened(true)}
        type="button"
        className="boss-task__request-service-switch js-tippy-request-service"
        aria-expanded="true"
      >
        Service
      </button>
    </Tooltip>
  );
}
