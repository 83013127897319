import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';

export function InvalidValuesModal(props) {
  const [onClose, onSubmit, badgeStatus, activationDate, onDowngradeClick] = oFetch(
    props,
    'onClose',
    'onSubmit',
    'badgeStatus',
    'activationDate',
    'onDowngradeClick',
  );

  function getTitle() {
    const formattedDate = safeMoment.uiDateParse(activationDate).format('DD MMMM YYYY');
    if (badgeStatus === 'suspended') {
      return (
        <div className="boss-modal-window__message-text">
          This SIA badge suspended on{' '}
          <div className="boss-modal-window__message-text-marked">{formattedDate}</div>
        </div>
      );
    }
    if (badgeStatus === 'revoked') {
      return (
        <div className="boss-modal-window__message-text">
          This SIA badge revoked on{' '}
          <div className="boss-modal-window__message-text-marked">{formattedDate}</div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="boss-modal-window__message-block">{getTitle()}</div>
      <div className="boss-modal-window__actions boss-modal-window__actions_layout_wrap-xs">
        <button
          type="button"
          onClick={onSubmit}
          className="boss-button boss-button_role_reload boss-modal-window__button"
        >
          Try Again
        </button>
        <button
          type="button"
          className="boss-button boss-button_role_alert boss-modal-window__button"
          onClick={() => onDowngradeClick(onClose)}
        >
          Downgrade to Steward
        </button>
      </div>
    </Fragment>
  );
}
