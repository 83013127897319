import React from 'react';
import { FilteredOutMossHourTag, MossHourTag } from '../types';
import { formatAsCurrency } from '@/lib/currency-helpers';
import {
  mossFinanceReportsHourTags as importedMossFinanceReportsHourTags,
} from '@/lib/app-routes';
import Select from '@/lib/boss-react-select';
import Popover from 'react-popover';
import { minutesToFormattedUiHours } from '@/lib/hours-helpers';
const appRoutes = {
  mossFinanceReportHourTags: importedMossFinanceReportsHourTags,
};

type MossHourTagsSectionProps = {
  currentFilterMossHourTagIds: number[] | null;
  venueId: number;
  startDate: Date;
  enabledTags: MossHourTag[];
  disabledTags: MossHourTag[];
  filteredOutMossHourTags: FilteredOutMossHourTag[];
};

type PopOverDataItem = {
  isOpen: boolean;
};

export function MossHourTagsSection(props: MossHourTagsSectionProps): JSX.Element {
  const [filterTags, setFilterTags] = React.useState<number[]>([]);
  const [popOverData, setPopOverData] = React.useState<Record<number, PopOverDataItem>>(() => {
    const result = {} as Record<number, PopOverDataItem>;
    props.enabledTags.forEach((mossHourTag) => {
      result[mossHourTag.id] = { isOpen: false };
    });
    props.disabledTags.forEach((mossHourTag) => {
      result[mossHourTag.id] = { isOpen: false };
    });
    return result;
  });

  const filteredMossHourTagCount = props.disabledTags.length + props.enabledTags.length;
  const totalMossHourTagCount = filteredMossHourTagCount + props.filteredOutMossHourTags.length;
  const showingAll = totalMossHourTagCount === filteredMossHourTagCount;

  function handleTagChange(selectedOptions: string): void {
    const arrayValues = selectedOptions
      .split(',')
      .filter(Boolean)
      .map((id) => parseInt(id));
    setFilterTags(arrayValues);
  }

  function addTags(): void {
    const mossHourTagIds = [
      ...props.enabledTags.map((m) => m.id),
      ...props.disabledTags.map((m) => m.id),
      ...filterTags,
    ];

    window.location.href = appRoutes
      .mossFinanceReportHourTags({
        venueId: props.venueId,
        startDate: props.startDate,
        mossHourTagIds: mossHourTagIds,
      });
  }

  function handleShowAllClick(): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: null,
    });
  }

  function handleShowOnlyClick(mossHourTagId: number): void {
    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: [mossHourTagId],
    });
  }

  function handleRemoveTagClick(mossHourTagId: number): void {
    let mossHourTagIds: number[] = [];
    if (props.currentFilterMossHourTagIds === null) {
      // Need all tags except this one
      props.disabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
      props.enabledTags.forEach((mossHourTag) => {
        if (mossHourTag.id !== mossHourTagId) {
          mossHourTagIds.push(mossHourTag.id);
        }
      });
    } else if ((props.currentFilterMossHourTagIds.length === 1) && (props.currentFilterMossHourTagIds[0] === mossHourTagId)) {
      // need all tags so do nothing
    } else {
      // need current tags except this one
      mossHourTagIds = props.currentFilterMossHourTagIds.filter((id) => id !== mossHourTagId);
    }

    window.location.href = appRoutes.mossFinanceReportHourTags({
      venueId: props.venueId,
      startDate: props.startDate,
      mossHourTagIds: mossHourTagIds.length === 0 ? null : mossHourTagIds,
    });
  }

  function renderMossHourTags(mossHourTags: MossHourTag[]): JSX.Element[] {
    return mossHourTags.map((mossHourTag) => {
      const totalCents = mossHourTag.owedHourCents + mossHourTag.hoursAcceptancePeriodCents;
      const tagPopOverData = popOverData[mossHourTag.id];
      if (!tagPopOverData) {
        throw new Error(`No popover data found for mossHourTagId: ${mossHourTag.id}`);
      }
      const isOpen = tagPopOverData.isOpen;

      const togglePopOver = () => {
        setPopOverData((prevPopOverData) => {
          const oldRecord = prevPopOverData[mossHourTag.id];
          if (!oldRecord) {
            throw new Error(`No popover data found for mossHourTagId: ${mossHourTag.id}`);
          }

          return {
            ...prevPopOverData,
            [mossHourTag.id]: {
              isOpen: !oldRecord.isOpen,
            },
          };
        });
      };

      const renderPopOverContent = (mossHourTag: MossHourTag) => {
        return (
          <div
            className="boss-overview boss-overview_page_frht"
            style={{
              padding: '10px',
              backgroundColor: 'white',
            }}
          >
            <div className="boss-overview__group">
              <ul className="boss-overview__list">
                <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                  <p className="boss-overview__list-key">
                    {mossHourTag.isCalculating && <span className="boss-indicator">
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                      />
                    </span>}
                    Hours</p>
                  <p className="boss-overview__list-value">{minutesToFormattedUiHours({ minutes: mossHourTag.hoursAcceptancePeriodMinutes })}</p>
                </li>
                <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                  <p
                    className="boss-overview__list-key"
                  >
                    {mossHourTag.isCalculating && <span className="boss-indicator">
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                      />
                    </span>}
                    Hours Total</p>
                  <p
                    className="boss-overview__list-value"
                  >{formatAsCurrency({ cents: mossHourTag.hoursAcceptancePeriodCents })}</p>
                </li>
              </ul>
            </div>
            <div className="boss-overview__group boss-overview__group_position_last">
              <ul className="boss-overview__list">
                <li className="boss-overview__list-item boss-overview__list-item_role_tag-details">
                  <p
                    className="boss-overview__list-key"
                  >
                    {mossHourTag.isCalculating && <span className="boss-indicator">
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                      />
                    </span>}
                    Owed Hours</p>
                  <p
                    className="boss-overview__list-value"
                  >{minutesToFormattedUiHours({ minutes: mossHourTag.owedHourMinutes })}
                  </p>
                </li>
                <li
                  className="boss-overview__list-item boss-overview__list-item_role_tag-details"
                >
                  <p
                    className="boss-overview__list-key"
                  >
                    {mossHourTag.isCalculating && <span className="boss-indicator">
                      <span
                        className="boss-indicator__icon boss-indicator__icon_size_xs boss-indicator__icon_calculator"
                      />
                    </span>}
                    Owed Hours Total</p>
                  <p className="boss-overview__list-value">{formatAsCurrency({ cents: mossHourTag.owedHourCents })}</p>
                </li>
              </ul>
            </div>
          </div>
        );
      };

      return (
        <Popover
          key={`mossHourTag:${mossHourTag.id}`}
          body={renderPopOverContent(mossHourTag)}
          appendTarget={document.body}
          preferPlace='below'
          isOpen={isOpen}
          onOuterAction={togglePopOver}
          // style={{ marginTop: '10px' }}
          tipSize={0.01}
          className="boss-popover boss-popover_state_opened"
        >
          <div
            onClick={togglePopOver}
            key={`mossHourTag:${mossHourTag.id}`}
            className={[
              'boss-tag boss-tag_size_xl',
              'boss-tag_role_hours-tag',
              'boss-tag_role_action',
              'js-tippy-tag',
              mossHourTag.isDisabled ? 'boss-tag_state_disabled' : null,
            ].filter(Boolean).join(' ')}
            aria-expanded="false"
          >
            {mossHourTag.isCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
              <span className="boss-corner__icon boss-corner__icon_calculator" />
            </div>}
            <p className="boss-tag__text">
              {`${mossHourTag.name} `}
              <span className="boss-tag__text-bold">{formatAsCurrency({ cents: totalCents })}</span>
            </p>
            <div
              className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_hide boss-tag__service_type_hidden"
              onClick={() => handleShowOnlyClick(mossHourTag.id)}
            />
            <div
              className="boss-tag__service boss-tag__service_role_action boss-tag__service_icon_close"
              onClick={() => handleRemoveTagClick(mossHourTag.id)}
            />
          </div>
        </Popover>
      );
    });
  }

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_hours-tags">
      <div className="boss-board__main">
        <div className="boss-board__main-inner">
          <div className="boss-board__filter">
            <div
              className="boss-form"
            >
              <div className="boss-form__row boss-form__row_align_center">
                <div className="boss-form__field boss-form__field_layout_min">
                  <p className="boss-form__text">
                    <span>Showing {filteredMossHourTagCount} of {totalMossHourTagCount}</span>
                    {!showingAll && (
                      <button
                        type="button"
                        className="boss-button boss-button_type_extra-small boss-form__action"
                        onClick={handleShowAllClick}
                      >Show All</button>
                    )}
                  </p>
                </div>

                <div className="boss-form__field boss-form__field_layout_max">
                  <div className="boss-form__select">
                    <Select
                      disabled={props.filteredOutMossHourTags.length === 0}
                      placeholder={"Filtered Out Tags..."}
                      value={filterTags}
                      label={null}
                      simpleValue
                      options={props.filteredOutMossHourTags.map((mossHourTag) => {
                        return {
                          label: mossHourTag.name,
                          value: mossHourTag.id
                        };
                      })}
                      multi
                      clearable={true}
                      onChange={handleTagChange}
                    />
                  </div>
                </div>
                <button
                  disabled={filterTags.length === 0}
                  onClick={() => addTags()}
                  className="boss-button"
                >Update</button>
              </div>
            </div>
          </div>

          <div className="boss-board__tags">
            {props.enabledTags.length === 0 && props.disabledTags.length === 0 && (
              <div className="boss-board__tags-group">
                <div className="boss-board__tags-group-inner">
                  <div className="boss-board__tags-group-text">No tags found</div>
                </div>
              </div>
            )}

            {props.enabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {renderMossHourTags(props.enabledTags)}
              </div>
            )}

            {props.disabledTags.length > 0 && (
              <div className="boss-board__tags-group">
                {renderMossHourTags(props.disabledTags)}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
