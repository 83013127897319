import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import DashboardWrapper from '@/components/dashboard-wrapper';
import { RadioGroup, Radio } from '@/components/radio-group';
import { FOR_STAFF_MEMBERS_TYPE, FOR_SPECIAL_SECURITY_HOURS_TYPE } from '../security-pay-rates-hook';

export function SecurityPayRatesDashboard(props) {
  const [
    selected,
    onChange,
    forStaffMembersPayRatesCount,
    forSpecialSecurityHoursSecurityPayRateCount,
    onAddForStaffMembersPayrateClick,
    onAddForSpecialSecurityHoursPayrateClick,
  ] = oFetch(
    props,
    'selected',
    'onChange',
    'forStaffMembersPayRatesCount',
    'forSpecialSecurityHoursSecurityPayRateCount',
    'onAddForStaffMembersPayrateClick',
    'onAddForSpecialSecurityHoursPayrateClick',
  );
  const isForStaffMembers = selected === FOR_STAFF_MEMBERS_TYPE;
  const isForSpecialSecurityHours = selected === FOR_SPECIAL_SECURITY_HOURS_TYPE;

  return (
    <DashboardWrapper>
      <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_security-pay-rates">
        <div className="boss-page-dashboard__group">
          <h1 className="boss-page-dashboard__title">Security Pay Rates</h1>
          <div className="boss-page-dashboard__controls-group">
            <form className="boss-form">
              <div className="boss-form__field boss-form__field_layout_fluid">
                <RadioGroup
                  name={'type'}
                  selectedValue={selected}
                  onChange={onChange}
                  className="boss-form__switcher boss-form__switcher_role_dashboard"
                >
                  <label style={{ cursor: 'pointer' }} className={'boss-form__switcher-label'}>
                    <Radio value={FOR_STAFF_MEMBERS_TYPE} className="boss-form__switcher-radio" />
                    <span className="boss-form__switcher-label-text">For Staff Members ({forStaffMembersPayRatesCount})</span>
                  </label>
                  <label style={{ cursor: 'pointer' }} className={'boss-form__switcher-label'}>
                    <Radio value={FOR_SPECIAL_SECURITY_HOURS_TYPE} className="boss-form__switcher-radio" />
                    <span className="boss-form__switcher-label-text">For Special Security Hours ({forSpecialSecurityHoursSecurityPayRateCount})</span>
                  </label>
                </RadioGroup>
              </div>
            </form>
          </div>
        </div>
        <div className="boss-page-dashboard__group">
          <div className="boss-page-dashboard__buttons-group">
            {isForStaffMembers && (
              <button
                onClick={onAddForStaffMembersPayrateClick}
                type="button"
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                Add Security Pay Rate
              </button>
            )}
            {isForSpecialSecurityHours && (
              <button
                onClick={onAddForSpecialSecurityHoursPayrateClick}
                type="button"
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                Add Security Pay Rate
              </button>
            )}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
