import React, { Component } from 'react';
import { RadioGroup, Radio } from '@/components/radio-group';
import oFetch from 'o-fetch';

export default class DashboardFilter extends Component {
  renderFilterTabs = () => {
    const tabs = oFetch(this.props, 'tabs');
    return Object.keys(tabs).map((tab, index) => {
      return (
        <label key={index} className="boss-form__switcher-label">
          <Radio value={tab} className="boss-form__switcher-radio" />
          <span className="boss-form__switcher-label-text">{tabs[tab]}</span>
        </label>
      );
    });
  };

  render() {
    const [activeFilter, onTabChange] = oFetch(this.props, 'activeFilter', 'onTabChange');

    return (
      <div className="boss-dropdown__header-group">
        <form className="boss-form">
          <div className="boss-form__row boss-form__row_justify_end boss-form__row_position_last">
            <div className="boss-form__field boss-form__field_layout_fluid">
              <RadioGroup
                name="filter"
                selectedValue={activeFilter}
                onChange={onTabChange}
                className="boss-form__switcher"
              >
                {this.renderFilterTabs()}
              </RadioGroup>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
