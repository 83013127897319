import React, { useEffect, useState, useMemo } from 'react';
import oFetch from 'o-fetch';
import { reduxForm } from 'redux-form';
import { Collapse } from 'react-collapse';
import cn from 'classnames';

import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

import StaticTimeInterval from './static-time-interval';
import HeadDoorStaticTimeInterval from './head-door-static-time-interval';
import { MOSS_APP_TYPE } from '@/lib/rota-date';

function AcceptedHoursAcceptancePeriodForm(props) {
  const [
    handleSubmit,
    submitting,
    specialPayRateSecurityHours,
    hasSpecialSecurityHours,
    mossHourTags,
  ] = oFetch(
    props,
    'handleSubmit',
    'submitting',
    'specialPayRateSecurityHours',
    'hasSpecialSecurityHours',
    'mossHourTags',
  );

  const { error } = props;

  const hoursAcceptancePeriod = oFetch(props, 'hoursAcceptancePeriod');
  const [
    acceptedBy,
    acceptedAt,
    startsAt,
    endsAt,
    formattedTime,
    breaks,
    frozen,
    specialHoursFormattedTime,
    formattedBreaksTime,
    mossHourTagIds,
    hoursAcceptancePeriodId,
    appType,
  ] = oFetch(
    hoursAcceptancePeriod,
    'acceptedBy',
    'acceptedAt',
    'startsAt',
    'endsAt',
    'formattedTime',
    'breaks',
    'frozen',
    'specialHoursFormattedTime',
    'formattedBreaksTime',
    'mossHourTagIds',
    'id',
    'appType',
  );
  const [specialHoursOpened, setSpecialHoursOpened] = useState(hasSpecialSecurityHours);
  const [breaksOpened, setBreaksOpened] = useState(oFetch(breaks, 'length') > 0);
  const [mossHourTagsOpened, setMossHourTagsOpened] = useState(oFetch(mossHourTagIds, 'length') > 0);

  const canHaveMossHourTags = appType === MOSS_APP_TYPE;

  useEffect(() => {
    if (mossHourTagIds.length > 0) {
      setMossHourTagsOpened(true);
    }
  }, []);

  const currentMossHourTags = useMemo(() => {
    return mossHourTags.filter(mossHourTag => mossHourTagIds.includes(mossHourTag.id));
  }, [mossHourTags, mossHourTagIds]);

  const hasBreaks = breaks.length > 0;
  const formattedAcceptedAt = safeMoment
    .iso8601Parse(acceptedAt)
    .format(utils.commonDateFormatWithTime());

  function getButtonText() {
    return submitting ? `Unaccepting` : `Unaccept`;
  }

  function renderBaseErrors() {
    return (
      <div className="boss-time-shift__error boss-time-shift__error_role_general">
        <p className="boss-time-shift__error-text boss-time-shift__error-text_role_title">
          Something went wrong!
        </p>
        {error.map((errorItem, index) => {
          return (
            <p
              key={index}
              className="boss-time-shift__error-text"
            >
              {errorItem}
            </p>
          );
        })}
      </div>
    );
  }

  function toggleBreaksBlock() {
    setBreaksOpened(!breaksOpened);
  }

  function toggleMossHourTagsBlock() {
    setMossHourTagsOpened(!mossHourTagsOpened);
  }

  function toggleSpecialHoursBlock() {
    setSpecialHoursOpened(!specialHoursOpened);
  }

  function renderBreaks(breaks) {
    return breaks.map(breakItem => {
      const [breakId, startsAt, endsAt] = oFetch(breakItem, 'id', 'startsAt', 'endsAt');

      return (
        <div
          key={breakId}
          className="boss-time-shift__break-item"
        >
          <div className="boss-time-shift__log boss-time-shift__log_layout_break boss-time-shift__log_state_accepted">
            <div className="boss-time-shift__group">
              <StaticTimeInterval
                startsAt={startsAt}
                endsAt={endsAt}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  function renderMossHourTags(args) {
    const hoursAcceptancePeriodId = oFetch(args, 'hoursAcceptancePeriodId');
    const currentMossHourTags = oFetch(args, 'currentMossHourTags');

    return currentMossHourTags.map(mossHourTag => {
      return (
        <div
          key={`${hoursAcceptancePeriodId}mossHourTag:${oFetch(mossHourTag, 'id')}`}
          className="boss-tag boss-tag_size_xxs boss-tag_role_label boss-tag_role_hours-tag"
        >
          <p
            className="boss-tag__text"
          >
            { `${oFetch(mossHourTag, 'name')}${oFetch(mossHourTag, 'isDisabled') ? ' (Disabled)' : ''}` }
          </p>
        </div>
      );
    });
  }

  function renderSpecialHours(specialHours) {
    return specialHours.map(specialHoursItem => {
      const [specialHoursId, startsAt, endsAt, secutiryPayRateName] = oFetch(
        specialHoursItem,
        'id',
        'startsAt',
        'endsAt',
        'secutiryPayRateName',
      );

      return (
        <div
          key={specialHoursId}
          className="boss-time-shift__hdh-item"
        >
          <div className="boss-time-shift__log boss-time-shift__log_layout_hdh boss-time-shift__log_state_accepted">
            <HeadDoorStaticTimeInterval
              startsAt={startsAt}
              endsAt={endsAt}
              specialSecurityPayRateName={secutiryPayRateName}
            />
          </div>
        </div>
      );
    });
  }

  function renderBreaksBlock(breaks) {
    const arrowClassNames = cn(
      'boss-time-shift__break-toggle boss-time-shift__break-toggle_state_visible',
      {
        'boss-time-shift__break-toggle_state_opened': breaksOpened,
      },
    );

    const breaksCount = breaks.length;

    return (
      <div className="boss-time-shift__break">
        <div className="boss-time-shift__break-controls">
          <button
            onClick={toggleBreaksBlock}
            type="button"
            className={arrowClassNames}
          >
            {`Breaks(${breaksCount})`}
          </button>
        </div>
        <Collapse
          isOpened={breaksOpened}
          className="boss-time-shift__break-content"
          style={{ display: 'block' }}
        >
          <div className="boss-time-shift__break-inner">{renderBreaks(breaks)}</div>
        </Collapse>
      </div>
    );
  }

  function renderMossHourTagsBlock(currentMossHourTags) {
    const arrowClassNames = cn(
      'boss-time-shift__break-toggle boss-time-shift__break-toggle_state_visible',
      {
        'boss-time-shift__break-toggle_state_opened': mossHourTagsOpened,
      },
    );

    const mossHourTagsCount = oFetch(currentMossHourTags, 'length');

    return (
      <div className="boss-time-shift__break">
        <div className="boss-time-shift__break-controls">
          <button
            onClick={toggleMossHourTagsBlock}
            type="button"
            className={arrowClassNames}
          >
            {`Moss Hour Tags (${mossHourTagsCount})`}
          </button>
        </div>
        <Collapse
          isOpened={mossHourTagsOpened}
          className="boss-time-shift__break-content"
          style={{ display: 'block' }}
        >
          <div className="boss-time-shift__break-inner">
            {renderMossHourTags({
              currentMossHourTags,
              hoursAcceptancePeriodId,
            })}
          </div>
        </Collapse>
      </div>
    );
  }

  function renderSpecialHoursBlock(specialHours) {
    const arrowClassNames = cn('boss-time-shift__hdh-toggle', {
      'boss-time-shift__hdh-toggle_state_opened': specialHoursOpened,
    });
    const specialHoursCount = specialHours.length;

    return (
      <div className="boss-time-shift__hdh">
        <div className="boss-time-shift__hdh-controls">
          <button
            onClick={toggleSpecialHoursBlock}
            type="button"
            className={arrowClassNames}
          >
            {`Head Door Hrs (${specialHoursCount})`}
          </button>
        </div>
        <Collapse
          isOpened={specialHoursOpened}
          className="boss-time-shift__hdh-content"
          style={{ display: 'block' }}
        >
          <div className="boss-time-shift__hdh-inner">{renderSpecialHours(specialHours)}</div>
        </Collapse>
      </div>
    );
  }

  return (
    <div className="boss-time-shift__form">
      {error && renderBaseErrors()}
      <div className="boss-time-shift__log boss-time-shift__log_state_accepted">
        <div className="boss-time-shift__group">
          <StaticTimeInterval
            startsAt={startsAt}
            endsAt={endsAt}
          />
        </div>

        <div className="boss-time-shift__actions">
          <p className="boss-time-shift__status boss-time-shift__status_state_visible">
            <span className="boss-time-shift__status-count">{formattedTime} Accepted</span>
            {hasBreaks && (
              <span className="boss-time-shift__status-count boss-time-shift__status-count_role_breaks">
                {formattedBreaksTime} Breaks
              </span>
            )}
            {hasSpecialSecurityHours && (
              <span className="boss-time-shift__status-count boss-time-shift__status-count_role_hdh">
                {specialHoursFormattedTime} as Head Doorman Accepted
              </span>
            )}
            <span className="boss-time-shift__status-meta">
              by {acceptedBy} at {formattedAcceptedAt}
            </span>
          </p>
          {!frozen && (
            <button
              onClick={handleSubmit}
              disabled={submitting}
              type="button"
              className="boss-button boss-button_role_cancel boss-time-shift__button boss-time-shift__button_role_unaccept-shift boss-time-shift__button_state_visible"
            >
              {getButtonText()}
            </button>
          )}
        </div>
      </div>
      {hasSpecialSecurityHours && renderSpecialHoursBlock(specialPayRateSecurityHours)}
      {renderBreaksBlock(breaks)}
      {canHaveMossHourTags && renderMossHourTagsBlock(currentMossHourTags)}
    </div>
  );
}

export default reduxForm()(AcceptedHoursAcceptancePeriodForm);
