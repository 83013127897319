import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

export function HealthCheckIndexMobileCard(props) {
  const healthCheck = oFetch(props, 'healthCheck');
  const [passed, formattedAt, fullName, url] = oFetch(healthCheck, 'passed', 'formattedAt', 'fullName', 'url');

  const statusClassNames = cn('boss-button boss-button_type_small', {
    'boss-button_role_alert': !passed,
    'boss-button_role_secondary': passed,
  });

  const statusCellClass = passed ? "boss-check__cell_status_pass" : "boss-check__cell_status_fail";
  const statusTextClass = passed ? "boss-check__text_status_pass" : "boss-check__text_status_fail";
  const statusLabel = passed ? "Passed" : "Failed";

  return (
    <div className="boss-check boss-check_role_board boss-check_page_vhci">
      <div className="boss-check__row">
        <div className={`boss-check__cell ${statusCellClass}`}>
          <p className={`boss-check__text ${statusTextClass}`}>{ statusLabel }</p>
        </div>
      </div>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__text boss-check__text_role_date"><a href={url} className="boss-check__link">{ formattedAt }</a></p>
        </div>
      </div>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <p className="boss-check__text boss-check__text_role_user"><a href={url} className="boss-check__link">{ fullName }</a></p>
        </div>
      </div>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <a href={url} className="boss-button boss-button_role_view-report boss-table__action">View Report</a>
        </div>
      </div>
    </div>
  );
}
