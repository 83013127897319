import moment from 'moment';
import utils from '@/lib/utils';

function isValidUIDate(str) {
  return str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
}

const parse = function (input, dateFormat) {
  if (arguments.length < 2) {
    throw new Error('Invalid arguments error: must supply input and dateFormat');
  }
  if (dateFormat === undefined || dateFormat === null) {
    throw new Error('Must supply valid dateFormat');
  }

  const result = moment(input, dateFormat, true);
  if (!result.isValid()) {
    throw new Error(`invalid date ${input} supplied`);
  }

  return result;
};

function iso8601Parse(input) {
  return parse(input, moment.ISO_8601, true);
}

function uiDateParse(input) {
  if (typeof input !== 'string') {
    throw new Error(`invalid uiDate ${input} supplied, not match ${utils.commonDateFormat}`);
  }
  if (!isValidUIDate(input)) {
    throw new Error(`invalid uiDate ${input} supplied, not match ${utils.commonDateFormat}`);
  }
  return parse(input, utils.commonDateFormat, true);
}

export default {
  parse: parse,
  iso8601Parse: iso8601Parse,
  uiDateParse: uiDateParse,
};
