import React, { useState } from 'react';
import { PageProps, StaffTypeSectionDataLookup } from '../types';
import Popover from 'react-popover';
import { format } from 'date-fns';
import { COMMON_DATE_FORMAT, UI_DATE_FORMAT } from '@/lib/date-fns-formats';
import { MossHourTag } from '../types';
import { formatAsCurrency } from '@/lib/currency-helpers';
import { minutesToFormattedUiHours } from '@/lib/hours-helpers';
import { StaffTypeSection } from './staff-type-section';
import {
  mossFinanceReportsHourTags as importedMossFinanceReportsHourTags,
  mossFinanceReports as importedMossFinanceReports,
} from '@/lib/app-routes';
const appRoutes = {
  mossFinanceReportHourTags: importedMossFinanceReportsHourTags,
  mossFinanceReports: importedMossFinanceReports,
} as const;
import { $BossUiDate } from '@/lib/schema-funcs';
import BossWeekPicker from '@/components/react-dates/boss-week-picker';
import { z } from 'zod';
import { MossHourTagsSection } from './moss-hour-tags-section';
import { useMossFinanceReports } from '../hooks';

export function Page(props: PageProps): JSX.Element {
  const parsedProps = props;
  const { mossFinanceReports, updateMossFinanceReportNote } = useMossFinanceReports({ initialMossFinanceReports: parsedProps.mossFinanceReports });
  const [isCalendarOpen, setIsCalendatOpen] = useState(false);

  function togglePopover(): void {
    setIsCalendatOpen(_isCalendarOpen => !_isCalendarOpen);
  }

  function onDateChange(date: Date): void {
    window.location.href = appRoutes
      .mossFinanceReportHourTags({
        startDate: date,
        venueId: parsedProps.venue.id,
        mossHourTagIds: parsedProps.currentFilterMossHourTagIds,
      });
  }

  function renderCalendar(): JSX.Element {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={format(parsedProps.startDate,  UI_DATE_FORMAT)}
          onChange={(selection: unknown) => {
            onDateChange(
              z.object({ startUIDate: $BossUiDate(z) }).parse(selection).startUIDate
            );
          }}
          onCancelClick={() => setIsCalendatOpen(false)}
        />
      </div>
    );
  }

  function renderStaffMemberSections(lookupData: StaffTypeSectionDataLookup): JSX.Element {
    if (Object.values(lookupData).length === 0) {
      return (
        <div className="boss-page-main__note">
          <p className="boss-page-main__text-placeholder">No reports to show.</p>
        </div>
      );
    } else {
      return (
        <div>
            {Object.values(lookupData).map((staffTypeSectionData) => {
              return (
                <StaffTypeSection
                  key={`staffTypeSection:${staffTypeSectionData.id}`}
                  venueId={parsedProps.venue.id}
                  startDate={parsedProps.startDate}
                  endDate={parsedProps.endDate}
                  weekDates={parsedProps.weekDates}
                  staffTypeSectionData={staffTypeSectionData}
                />
              );
            })
          }
        </div>
      );
    }
  }

  const enabledTags = [] as MossHourTag[];
  const disabledTags = [] as MossHourTag[];
  let hoursAcceptancePeriodsCalculating = false;
  let owedHoursCalculating = false;
  let totalHourMinutes = 0;
  let totalOwedHourMinutes = 0;
  let totalHoursCents = 0;
  let totalOwedHourCents = 0;
  parsedProps.mossHourTags.forEach((mossHourTag) => {
    if (mossHourTag.isDisabled) {
      disabledTags.push(mossHourTag);
    } else {
      enabledTags.push(mossHourTag);
    }

    owedHoursCalculating = owedHoursCalculating || mossHourTag.owedHoursCalculating;
    hoursAcceptancePeriodsCalculating = hoursAcceptancePeriodsCalculating || mossHourTag.hoursAcceptancePeriodsCalculating;
    totalHourMinutes += mossHourTag.hoursAcceptancePeriodMinutes;
    totalOwedHourMinutes += mossHourTag.owedHourMinutes;
    totalHoursCents += mossHourTag.hoursAcceptancePeriodCents;
    totalOwedHourCents += mossHourTag.owedHourCents;
  });
  const anythingCalculating = owedHoursCalculating || hoursAcceptancePeriodsCalculating;
  const totalCents = totalHoursCents + totalOwedHourCents;

  const mossStaffTypeIds: number[] = [];
  const staffTypeSectionsByMossStaffTypeId: StaffTypeSectionDataLookup = {};
  mossFinanceReports.forEach((mossFinanceReport) => {
    if (!staffTypeSectionsByMossStaffTypeId.hasOwnProperty(mossFinanceReport.mossStaffTypeId)) {
      mossStaffTypeIds.push(mossFinanceReport.mossStaffTypeId);

      staffTypeSectionsByMossStaffTypeId[mossFinanceReport.mossStaffTypeId] = {
        name: mossFinanceReport.mossStaffTypeName,
        id: mossFinanceReport.mossStaffTypeId,
        uiColor: mossFinanceReport.mossStaffTypeUiColor,
        totalCents: 0,
        isCalculating: false,
        mossFinanceReports: [],
        updateMossFinanceReportNote,
      };
    }

    const staffTypeSection = staffTypeSectionsByMossStaffTypeId[mossFinanceReport.mossStaffTypeId];
    if (!staffTypeSection) {
      throw new Error(`No staff type section found for mossStaffTypeId: ${mossFinanceReport.mossStaffTypeId}`);
    }

    staffTypeSection.totalCents += mossFinanceReport.calculatedGrossPayCents;
    staffTypeSection.isCalculating = staffTypeSection.isCalculating || mossFinanceReport.pendingCalculation ;
    staffTypeSection.mossFinanceReports.push(mossFinanceReport);
  });

  function visitMossFinanceReportPage(): void {
    window.location.href = appRoutes.mossFinanceReports({
      venueId: props.venue.id,
      startDate: props.startDate,
    });
  }

  return (
    <div className='boss-page-main boss-page-main_adjust_finance-reports-hours-tags'>
      <div className='boss-page-main__dashboard'>
        <div className='boss-page-main__inner'>
          <div className='boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_finance-reports-hours-tags'>
            <div className='boss-page-dashboard__group'>
              <div className='boss-page-dashboard__sub-group'>
                <h1 className="boss-page-dashboard__title">Finance Reports Hours Tags</h1>
              </div>

              <div className="boss-page-dashboard__sub-group">
                { props.permissions.showPDFDownloadLink && <div className="boss-page-dashboard__buttons-group">
                  <a
                    href={appRoutes.mossFinanceReportHourTags({
                      startDate: parsedProps.startDate,
                      venueId: parsedProps.venue.id,
                      mossHourTagIds: parsedProps.currentFilterMossHourTagIds,
                      extension: 'pdf',
                    })}
                    rel="noreferrer"
                    target="_blank"
                    className="boss-button boss-button_role_download boss-page-dashboard__button"
                  >Download PDF</a>
                </div> }
              </div>
            </div>

            <div className="boss-page-dashboard__group">
              <div className="boss-page-dashboard__sub-group">
                <div className="boss-page-dashboard__meta">
                  <Popover
                    isOpen={isCalendarOpen}
                    body={renderCalendar()}
                    appendTarget={document.body}
                    place="below"
                    tipSize={0.01}
                    onOuterAction={togglePopover}
                    className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                    style={{ marginTop: '10px' }}
                  >
                    <p
                      className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover"
                      onClick={togglePopover}
                    >
                      <span className="boss-page-dashboard__meta-text">
                        {format(parsedProps.startDate, COMMON_DATE_FORMAT)}
                      </span>
                      {' - '}
                      <span className="boss-page-dashboard__meta-text">
                        {format(parsedProps.endDate, COMMON_DATE_FORMAT)}
                      </span>
                    </p>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-page-main__content boss-body_adjust_finance-reports-hours-tags">
        <div className="boss-page-main__inner">
          <div
            className="boss-page-main__controls boss-page-main__controls_justify_end"
          >
            <button
              type="button"
              className="boss-page-main__control boss-page-main__control_role_report"
              onClick={() => visitMossFinanceReportPage()}
            >Finance Report</button>
          </div>

          <MossHourTagsSection
            currentFilterMossHourTagIds={parsedProps.currentFilterMossHourTagIds}
            venueId={parsedProps.venue.id}
            startDate={parsedProps.startDate}
            enabledTags={enabledTags}
            disabledTags={disabledTags}
            filteredOutMossHourTags={parsedProps.filteredOutMossHourTags}
          />

          <section className="boss-board boss-board_context_stack boss-board_role_finance-report-overview">
            <header className="boss-board__header">
              <h2 className="boss-board__title boss-board__title_size_medium">Summary</h2>
            </header>

            <div className="boss-board__main">
              <div className="boss-board__main-inner">
                <div className="boss-board__main-group">
                  { anythingCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                    <span className="boss-corner__icon boss-corner__icon_calculator" />
                  </div> }
                  <div className="boss-table boss-table_page_finance-reports-hours-tags-overview">
                    <div className="boss-table__row">
                      <div className="boss-table__cell">
                        <div className="boss-table__info">
                          <div className="boss-number boss-number_layout_flow">
                            <div className="boss-number__content">
                              <p className="boss-number__label boss-number__label_position_before">Total: </p>
                              <h3 className="boss-number__value boss-number__value_size_s">{formatAsCurrency({ cents: totalCents })}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="boss-board__main-group">
                  <div className="boss-table boss-table_page_finance-reports-hours-tags-overview">
                    <div className="boss-table__row">
                      <div className="boss-table__cell">
                        { hoursAcceptancePeriodsCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                          <span className="boss-corner__icon boss-corner__icon_calculator" />
                        </div> }
                        <div className="boss-table__info">
                          <div className="boss-number boss-number_layout_flow">
                            <div className="boss-number__content">
                              <p className="boss-number__label boss-number__label_position_before">Hours: </p>
                              <h3 className="boss-number__value boss-number__value_size_s">
                                { minutesToFormattedUiHours({ minutes: totalHourMinutes }) }
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="boss-table__cell">
                        { hoursAcceptancePeriodsCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                          <span className="boss-corner__icon boss-corner__icon_calculator" />
                        </div> }

                        <div className="boss-table__info">
                          <div className="boss-number boss-number_layout_flow">
                            <div className="boss-number__content">
                              <p className="boss-number__label boss-number__label_position_before">Hours Total: </p>
                              <h3 className="boss-number__value boss-number__value_size_s">{ formatAsCurrency({ cents: totalHoursCents }) }</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="boss-board__main-group">
                  <div className="boss-table boss-table_page_finance-reports-hours-tags-overview">
                    <div className="boss-table__row">
                      <div className="boss-table__cell">
                        { owedHoursCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                          <span className="boss-corner__icon boss-corner__icon_calculator" />
                        </div> }

                        <div className="boss-table__info">
                          <div className="boss-number boss-number_layout_flow">
                            <div className="boss-number__content">
                              <p className="boss-number__label boss-number__label_position_before">Owed Hours: </p>
                              <h3 className="boss-number__value boss-number__value_size_s">
                                { minutesToFormattedUiHours({ minutes: totalOwedHourMinutes }) }
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="boss-table__cell">
                        { owedHoursCalculating && <div className="boss-corner boss-corner_position_top-left boss-corner_color_accent-red">
                          <span className="boss-corner__icon boss-corner__icon_calculator" />
                        </div> }

                        <div className="boss-table__info">
                          <div className="boss-number boss-number_layout_flow">
                            <div className="boss-number__content">
                              <p className="boss-number__label boss-number__label_position_before">Owed Hours Total: </p>
                              <h3 className="boss-number__value boss-number__value_size_s">{ formatAsCurrency({ cents: totalOwedHourCents }) }</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          { renderStaffMemberSections(staffTypeSectionsByMossStaffTypeId) }
        </div>
      </div>
    </div>
  );
}