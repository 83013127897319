import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import ContentWrapper from '@/components/content-wrapper';
import { contactsDetailsHooks } from './contacts-details-hoks';
import { ContactsList, ContactsItem, ContactsDetailsDashboard, ContactsDetailsFilter } from './components';

export function ContactDetails(props) {
  const [staffMembers, staffTypes, currentVenue, showDownloadCSVButton] = oFetch(
    props,
    'staffMembers',
    'staffTypes',
    'currentVenue',
    'showDownloadCSVButton',
  );

  const { filteredStaffMembers, staffTypesOptions, filter, onFilterChange } = contactsDetailsHooks({
    staffMembers,
    staffTypes,
    filter: {
      name: '',
      staffType: [],
    },
  });
  const filteredStaffMembersExist = filteredStaffMembers.length > 0;

  function renderStaffMemberItem(staffMember) {
    return <ContactsItem currentVenue={currentVenue} staffMember={staffMember} />;
  }

  return (
    <Fragment>
      <ContactsDetailsDashboard title="Contact Details">
        <ContactsDetailsFilter
          filter={filter}
          onFilterChange={onFilterChange}
          staffTypesOptions={staffTypesOptions}
        />
      </ContactsDetailsDashboard>
      <ContentWrapper>
        {!filteredStaffMembersExist && <h3>No staff members found</h3>}
        {filteredStaffMembersExist && (
          <Fragment>
            {showDownloadCSVButton && (
              <div className="boss-page-main__actions boss-page-main__actions_position_first boss-page-main__actions_justify_end">
                <a
                  href={`/contact_details/download_email_csv?venue_id=${currentVenue}`}
                  type="submit"
                  className="boss-button boss-button_role_contacts-download boss-page-main__action"
                >
                  Download All as CSV
                </a>
              </div>
            )}
            <ContactsList items={filteredStaffMembers} itemRender={renderStaffMemberItem} />
          </Fragment>
        )}
      </ContentWrapper>
    </Fragment>
  );
}
