import React from 'react';
import moment from 'moment';
import safeMoment from '@/lib/safe-moment';
import { RotaDate, BOSS_APP_TYPE } from '@/lib/rota-date';
import { appRoutes } from '@/lib/legacy-routes';
import oFetch from 'o-fetch';
import _ from 'lodash';

export default class Header extends React.Component {
  static displayName = 'Header';

  venueName() {
    return this.props.currentVenue ? _.capitalize(this.props.currentVenue.name) : '';
  }

  failedCategoryScores() {
    const allScores = oFetch(this.props, 'scores');

    return allScores.filter(categoryScore => {
      return !oFetch(categoryScore, 'passed');
    });
  }

  renderFailedCategories() {
    if (this.frontendLoading()) {
      return null;
    }

    const failedCategoryScores = this.failedCategoryScores();
    const failedCategoryTags = failedCategoryScores.map(failedCategoryScore => {
      const categoryName = oFetch(failedCategoryScore, 'categoryName');
      return (
        <a
          className="boss-page-dashboard__meta-link"
          key={`${categoryName}-anchor-link`}
          href={`#${categoryName}-answers`}
        >
          {categoryName}
        </a>
      );
    });

    if (failedCategoryScores.length > 0) {
      return (
        <span>
          <span className="boss-page-dashboard__meta-text">Failed sections:</span>
          {failedCategoryTags}
        </span>
      );
    } else {
      return <span />;
    }
  }

  renderUser() {
    if (this.frontendLoading()) {
      return null;
    }
    const user = oFetch(this.props, 'user');

    return (
      <p className="boss-page-dashboard__meta-item  boss-page-dashboard__meta-item_role_user">
        <span className="boss-page-dashboard__meta-text">Checked by </span>
        <a
          className="boss-page-dashboard__meta-link boss-page-dashboard__meta-link_role_name"
          href={`/users/${this.props.user.id}`}
        >
          {oFetch(user, 'name')}
        </a>
      </p>
    );
  }

  renderDate() {
    if (this.frontendLoading()) {
      return null;
    }
    const response = oFetch(this.props, 'response');

    const sCreatedAt = oFetch(response, 'created_at');
    if (!sCreatedAt) return;

    const dCreatedAt = safeMoment.
      iso8601Parse(sCreatedAt)
      .toDate();
    const dCreatedAtDate = RotaDate.fromTime({
      dTime: dCreatedAt,
      appType: BOSS_APP_TYPE,
    }).calendarDate();

    return (
      <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_date">
        <span className="boss-page-dashboard__meta-text">
          {moment(dCreatedAtDate).format('MMMM Do YYYY')}
        </span>
      </p>
    );
  }

  frontendLoading() {
    const frontendObj = oFetch(this.props, 'frontend');
    return oFetch(frontendObj, 'loading');
  }

  statusBadge() {
    if (this.frontendLoading()) {
      return null;
    }

    const failedCategoryScores = this.failedCategoryScores();
    if (oFetch(failedCategoryScores, 'length') > 0) {
      return (
        <a
          href="#"
          className="boss-button boss-button_role_fail_reverse boss-page-dashboard__button"
        >
          Fail
        </a>
      );
    } else {
      return (
        <a
          href="#"
          className="boss-button boss-button_role_success_reverse boss-page-dashboard__button"
        >
          Pass
        </a>
      );
    }
  }

  backLink() {
    return <a href={appRoutes.venueHealthChecksIndex()}>Back To Venue Health Checks</a>;
  }

  render() {
    return (
      <div className="boss-page-main__dashboard">
        <div className="boss-page-main__inner">
          <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_report">
            <div className="boss-page-dashboard__group">
              <h1 className="boss-page-dashboard__title">{this.venueName()} Health Check Report</h1>

              <div className="boss-page-dashboard__buttons-group boss-page-dashboard__buttons-group_desktop">
                {this.statusBadge()}
              </div>
            </div>

            <div className="boss-page-dashboard__group">
              <div className="boss-page-dashboard__meta">
                {this.renderUser()}

                {this.renderDate()}
              </div>

              <div className="boss-page-dashboard__buttons-group boss-page-dashboard__buttons-group_mobile">
                {this.statusBadge()}
              </div>

              {this.backLink()}

              <div className="boss-page-dashboard__meta">
                <p className="boss-page-dashboard__meta-item">{this.renderFailedCategories()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
