import oFetch from 'o-fetch';
import React, { Fragment } from 'react';
import cn from 'classnames';
import { CollapsibleCard } from '@/components/hooks-components';

export default function PaymentsCollapsibleCard(props) {
  const [count, title, children, isInvalid] = oFetch(
    props,
    'count',
    'title',
    'children',
    'isInvalid',
  );

  const paymentsExist = count !== 0;

  const countStatusClassNames = cn('boss-indicator boss-board__indicator', {
    'boss-indicator_status_success': !isInvalid,
    'boss-indicator_status_error': isInvalid,
  });

  function renderTitle() {
    return (
      <Fragment>
        <div className={countStatusClassNames}>
          <span className="boss-indicator__marker">{count}</span>
        </div>
        <h2 className="boss-board__title boss-board__title_size_medium">{title}</h2>
      </Fragment>
    );
  }

  return (
    <CollapsibleCard title={renderTitle} defaultOpen={paymentsExist}>
      <div className="boss-board__group">
        {!paymentsExist && <p className="boss-board__text-placeholder">Nothing to display</p>}
        {paymentsExist && children}
      </div>
    </CollapsibleCard>
  );
}

PaymentsCollapsibleCard.defaultProps = {
  isInvalid: false,
};
