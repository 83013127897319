import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

function CategoriesList(props) {
  const [renderHeader, renderCategory, categories, firstTime] = oFetch(
    props,
    'renderHeader',
    'renderCategory',
    'categories',
    'firstTime',
  );
  function renderCategories() {
    if (categories.length === 0) {
      return <h3>No Categories found.</h3>;
    }
    return categories.map(category => {
      const id = oFetch(category, 'id');
      return React.cloneElement(renderCategory(category), {
        key: id.toString(),
      });
    });
  }

  const categoriesListClassNames = cn(
    'boss-board boss-board_role_profile-quizzes-categories boss-board_context_stack',
    {
      'boss-board_role_profile-quizzes-categories-novice': firstTime,
    },
  );

  return (
    <section className={categoriesListClassNames}>
      {renderHeader()}
      <div className="boss-board__main">
        <div className="boss-board__main-inner">{renderCategories()}</div>
      </div>
    </section>
  );
}

export default CategoriesList;
