import React, { useMemo, Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { BossFormInput, BossFormTextarea, BossFormSelectNew, BossFormCalendar } from '@/components/boss-form';
import {
  ColoredSingleOption,
  ColoredMultipleValue,
  ColoredSingleValue,
} from '@/components/boss-form/colored-select';
import utils from '@/lib/utils';

export function SiteIssueForm(props) {
  const [onSubmit, initialValues, buttonText] = oFetch(props, 'onSubmit', 'initialValues', 'buttonText');
  const [siteIssuesLevels, siteIssuesLevelsColor] = oFetch(
    useLegacyGlobal(),
    'enums.siteIssuesLevels',
    'enums.siteIssuesLevelsColor',
  );
  const levelOptions = useMemo(() => {
    return Object.entries(siteIssuesLevels).map(entry => {
      const [key, label] = entry;
      return {
        value: key,
        label: label,
        color: siteIssuesLevelsColor[key],
      };
    });
  }, [siteIssuesLevels, siteIssuesLevelsColor]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values, submitError, ...rest }) => {
        return (
          <Fragment>
            {submitError && (
              <div className="boss-modal-window__alert">
                <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                  <p className="boss-alert__text">{submitError}</p>
                </div>
              </div>
            )}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Field name="description" label="Description" component={BossFormTextarea} />
                <div className="boss-form__row">
                  <Field
                    name="date"
                    parse={value => value}
                    label="Date"
                    fieldClassName="boss-form__field_layout_half"
                    component={BossFormCalendar}
                    valueFormat={utils.commonDateFormat}
                  />
                  <Field
                    name="level"
                    label="Level"
                    parse={value => value}
                    fieldClassName="boss-form__field_layout_half"
                    options={levelOptions}
                    valueComponent={ColoredSingleValue}
                    optionComponent={ColoredSingleOption}
                    component={BossFormSelectNew}
                  />
                </div>
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="boss-button boss-button_role_add boss-form__submit"
                    type="submit"
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
