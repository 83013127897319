import oFetch from 'o-fetch';
import React, { Fragment, useState } from 'react';
import ContentWrapper from '@/components/content-wrapper';
import DashboardWrapper from '@/components/dashboard-wrapper';
import * as zip from '@/lib/training-materials-zip';
import * as actions from '../actions';
import { HtmlPreview } from './';
import { UploadTrainingMaterialsZip } from './upload-training-materials-zip';

export function UpdateCategory(props) {
  const [category, updateCategoryHtml] = oFetch(props, 'category', 'updateCategoryHtml');
  const name = oFetch(category, 'name');

  const oldHtml = oFetch(props, 'html');
  const [html, setHTML] = useState(null);
  const [sections, setSections] = useState({});
  const [materialsZipFile, setMaterialsZipFile] = useState(null);
  const [showZipUpload, setShowZipUpload] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);

  async function handleHTMLUpload(params) {
    setHTML(null);
    const [processed, zipFile] = oFetch(params, 'processed', 'zipFile');
    const htmlFileContent = oFetch(processed, 'htmlFile.content');
    const categoryId = oFetch(params, 'categoryId');
    await actions.sanitizeHTML({
      categoryId,
      htmlFileContent,
      onFailure(errors) {
        setSubmitErrors(errors);
      },
      onSuccess: sanitizedContent => {
        const images = oFetch(processed, 'images');
        const htmlWithProcessedImages = zip.processImagesPath({ html: sanitizedContent, images });
        const sections = zip.parseHTMLSections(htmlWithProcessedImages);

        setMaterialsZipFile(zipFile);
        setSections(sections);
        setHTML(htmlWithProcessedImages);
        setShowZipUpload(false);
      },
    });
  }

  function clearHtmlAndSections() {
    setHTML(null);
    setSections({});
    setMaterialsZipFile(null);
  }

  function cancelUpload() {
    clearHtmlAndSections();
    setShowZipUpload(false);
  }

  async function handleSave() {
    setSaving(true);
    await actions.uploadMaterialsZip({
      materialsZipFile,
      category,
      onSuccess: handleCategoryHtmlUpdate,
      onFailure: setSubmitErrors,
    });
    setSaving(false);
  }

  function handleCategoryHtmlUpdate(liveHtml) {
    updateCategoryHtml(liveHtml);
    clearHtmlAndSections();
  }

  function renderButtons() {
    if (materialsZipFile) {
      return (
        <Fragment>
          <button
            type="button"
            disabled={saving}
            className="boss-button boss-button_role_success boss-page-dashboard__button"
            onClick={handleSave}
          >
            {saving ? 'Updating ...' : 'Update'}
          </button>
          <button
            type="button"
            disabled={saving}
            className="boss-button boss-button_role_reload boss-page-dashboard__button"
            onClick={() => setShowZipUpload(true)}
          >
            Change Zip File
          </button>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {!showZipUpload && (
          <button
            type="button"
            className="boss-button boss-button_role_reload boss-page-dashboard__button"
            onClick={() => setShowZipUpload(true)}
          >
            Upload Zip File
          </button>
        )}
        {showZipUpload && (
          <button
            type="button"
            disabled={saving}
            className="boss-button boss-button_role_cancel boss-page-dashboard__button"
            onClick={cancelUpload}
          >
            Cancel
          </button>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_training-materials">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {name}
              </span>{' '}
              Training Materials
            </h1>
            <div className="boss-page-dashboard__buttons-group">{renderButtons()}</div>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        {showZipUpload && (
          <UploadTrainingMaterialsZip
            categoryId={oFetch(category, 'id')}
            onUpload={handleHTMLUpload}
            submitErrors={submitErrors}
          />
        )}
        <div className="boss-page-main__group boss-page-main__group_context_stack boss-page-main__group_adjust_training-materials">
          <div className="boss-preview">
            <div className="boss-preview__group">
              <HtmlPreview
                iFrameID={'live'}
                html={oldHtml}
                title={'Live'}
                sections={zip.parseHTMLSections(oldHtml)}
              />
              {html && (
                <HtmlPreview
                  iFrameID={'preview'}
                  html={html}
                  title={'Preview'}
                  sections={sections}
                />
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Fragment>
  );
}
