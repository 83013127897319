import React, { useEffect, useMemo, useState } from 'react';
import { MossHourTagsFilter } from './components/moss-hour-tags-filter';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { $DetailModalFormValues, $BoundaryMossHourTagsAppData, DetailsModalActions, DetailsModalFormValues, MossHourTag, $SetMossHourTagAppData } from './types';
import { useModal } from '@/components/hooks-components/modals';
import { MossTagDetailsModalContent } from './components/moss-tag-details-modal-content';
import { LegacyCloseInsideModalWrapper } from "@/components/modal-wrappers";
import { bossRequestHttp } from '@/lib/request-api';
import { applyBossRoute } from '@/lib/apply-boss-route';
import {
  enableMossHourTag as importedEnableMossHourTag,
  disableMossHourTag as importedDisableMossHourTag,
  createMossHourTag as importedCreateMossHourTag,
} from '@/lib/api-routes';
// creating this to make it easier to see these are routes
const apiRoutes = {
  createMossHourTag: importedCreateMossHourTag,
  enableMossHourTag: importedEnableMossHourTag,
  disableMossHourTag: importedDisableMossHourTag,
} as const;
import { mossHourTagsIndex as importedAppRoutes } from '@/lib/app-routes';
const appRoutes = {
  mossHourTagsIndex: importedAppRoutes,
} as const;
import { normalizeFinalFormErrors } from '@/lib/normalize-final-form-errors';
import oFetch from 'o-fetch';
import { z } from 'zod';

const $MossHourTagIndexPageProps = z.object({
  mossHourTagAppData: $BoundaryMossHourTagsAppData,
  setMossHourTagAppData: $SetMossHourTagAppData,
}).strict();

type MossHourTagIndexPageProps = z.infer<typeof $MossHourTagIndexPageProps>;

export function MossHourTagsIndexPage(props: MossHourTagIndexPageProps) {
  const { openProcessingModal } = useModal();
  const [selectedTagId, setSelectedTagId] = React.useState<number | null>(null);
  const [tagCreationInProgress, setTagCreationInProgress] = React.useState<boolean>(false);
  const pageData = props.mossHourTagAppData;
  // const [forceFilterUpdate, setForceFilterUpdate] = useState<boolean>(false);
  // const [searchQuery, setSearchQuery] = useState<string>(props.mossHourTagAppData.indexFilterData.queryString);
  const [filterState, setFilterState] = useState({
    forceFilterUpdate: false,
    searchQuery: props.mossHourTagAppData.indexFilterData.queryString,
  });
  const normalizedSearchQuery = filterState.searchQuery.toLowerCase().trim();

  function setSearchQuery(searchQuery: string): void {
    setFilterState(prevState => {
      return { ...prevState, searchQuery };
    });
  }

  useEffect(() => {
    if (tagCreationInProgress) {
      createTag(normalizedSearchQuery);
    }
  }, [tagCreationInProgress]);

  const tagsById = useMemo(() => {
    return pageData.mossHourTags.reduce((acc, mossHourTag) => {
      acc[mossHourTag.id] = mossHourTag;
      return acc;
    }, {} as Record<number, MossHourTag>);
  }, [pageData.mossHourTags]);

  function createTag(tagName: string): void {
    const route = apiRoutes.createMossHourTag;

    const bossRequestInstance = bossRequestHttp({
      errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {
        const globalNotifications = params.globalNotifications;
        globalNotifications.showDefaultFailureMessage();

        return false;
      },
      successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
        const rawData = oFetch(params, 'data');
        const data = route.$SuccessData.parse(rawData);

        props.setMossHourTagAppData({
          ...pageData,
          mossHourTags: [...pageData.mossHourTags, data.mossHourTag],
        });
        setTagCreationInProgress(false);
        setFilterState({
          searchQuery: '',
          forceFilterUpdate: true,
        });
      },
    });

    applyBossRoute({
      route,
      bossHttpRequest: bossRequestInstance,
      callParams: { name: tagName },
      pathParams: {},
    });
  }

  function renderTagModal(tagId: number) {
    const tag = tagsById[tagId];
    if (!tag) {
      throw new Error(`Tag with id ${tagId} not found`);
    }

    const title = `${tag.name}${ tag.isDisabled ? ' (Disabled)' : '' } Details`;

    openProcessingModal({
      onSubmit: (handleClose: any, setProcessing: any, values: DetailsModalFormValues) => {
        const parsedValues = $DetailModalFormValues.parse(values);
        setProcessing(true);

        switch (parsedValues.action) {
          case DetailsModalActions.disable:
            const disableRoute = apiRoutes.disableMossHourTag;

            const disableBossRequestInstance = bossRequestHttp({
              errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {

                const statusCode = oFetch(params, 'statusCode');
                const errors = oFetch(params, 'errors');

                if (statusCode === 422 && errors) {
                  const globalNotifications = oFetch(params, 'globalNotifications');
                  globalNotifications.showDefaultFailureMessage();

                  const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
                  supportedKeyChecker.validateKeys({
                    suppliedKeys: Object.keys(errors),
                    supportedKeys: ['base'],
                  });

                  return normalizeFinalFormErrors(errors);
                }

                return false;
              },
              successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
                const globalNotifications = params.globalNotifications;

                const data = oFetch(params, 'data');
                const updatedTag = disableRoute.$SuccessData.parse(data).mossHourTag;

                props.setMossHourTagAppData({
                  ...props.mossHourTagAppData,
                  mossHourTags: props.mossHourTagAppData.mossHourTags.map((mossHourTag) => {
                    if (mossHourTag.id === updatedTag.id) {
                      return updatedTag;
                    }
                    return mossHourTag;
                  }),
                });
                setSelectedTagId(null);

                globalNotifications.showDefaultSuccessMessage();
                handleClose();

                return false;
              }
            });

            applyBossRoute({
              route: disableRoute,
              callParams: {},
              pathParams: { tagId: tag.id },
              bossHttpRequest: disableBossRequestInstance,
            });

            break;
          case DetailsModalActions.enable:
            const enableRoute = apiRoutes.enableMossHourTag;

            const enableBossRequestInstance = bossRequestHttp({
              errorHandler(params: RequestApi.BossRequestHttpErrorHandler) {

                const statusCode = oFetch(params, 'statusCode');
                const errors = oFetch(params, 'errors');

                if (statusCode === 422 && errors) {
                  const globalNotifications = oFetch(params, 'globalNotifications');
                  globalNotifications.showDefaultFailureMessage();

                  const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
                  supportedKeyChecker.validateKeys({
                    suppliedKeys: Object.keys(errors),
                    supportedKeys: ['base'],
                  });

                  return normalizeFinalFormErrors(errors);
                }

                return false;
              },
              successHandler(params: RequestApi.BossRequestHttpSuccessHandler) {
                const globalNotifications = params.globalNotifications;

                const data = oFetch(params, 'data');
                const updatedTag = enableRoute.$SuccessData.parse(data).mossHourTag;

                props.setMossHourTagAppData({
                  ...props.mossHourTagAppData,
                  mossHourTags: props.mossHourTagAppData.mossHourTags.map((mossHourTag) => {
                    if (mossHourTag.id === updatedTag.id) {
                      return updatedTag;
                    }
                    return mossHourTag;
                  }),
                });
                setSelectedTagId(null);

                globalNotifications.showDefaultSuccessMessage();
                handleClose();

                return false;
              }
            });

            applyBossRoute({
              route: enableRoute,
              callParams: {},
              pathParams: { tagId: tag.id },
              bossHttpRequest: enableBossRequestInstance,
            });
            break;
          default:
            throw new Error(`Invalid action: ${parsedValues.action}`);
        }
      },
      props: {
        tag,
      },
      config: {
        title,
      },
      onClose: () => {
        setSelectedTagId(null);
      },
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: MossTagDetailsModalContent,
    });
  }

  function handleTagClick(tagId: number): void {
    if (!selectedTagId) {
      setSelectedTagId(tagId);
      renderTagModal(tagId);
    }
  }

  const noTagsToShow = pageData.mossHourTags.length === 0 && pageData.indexFilterData.queryString === '';
  const queryTag = pageData.indexFilterData.queryString !== '' ?
    pageData.mossHourTags.find((mossHourTag) => {
      return mossHourTag.name.toLowerCase() === normalizedSearchQuery;
    }) :
    undefined;

  const enabledTags = pageData.mossHourTags.filter((mossHourTag) => {
    if (queryTag !== undefined && mossHourTag.name === queryTag.name) {
      return false;
    }
    return !mossHourTag.isDisabled;
  });
  const disabledTags = [] as MossHourTag[];
  if (props.mossHourTagAppData.indexFilterData.mode === 'all') {
    pageData.mossHourTags.forEach((mossHourTag) => {
      const isQueryTag = queryTag !== undefined && mossHourTag.name === queryTag.name;
      if (!isQueryTag && mossHourTag.isDisabled) {
        disabledTags.push(mossHourTag);
      }
    });
  }

  return (
    <ModalsProvider>
      <div className="boss-page-main">
        <div className="boss-page-main__dashboard">
          <div className="boss-page-main__inner">
            <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_hours-tags">
              <div className="boss-page-dashboard__group">
                <h1 className="boss-page-dashboard__title">Hours Tags <span className="boss-page-dashboard__title-info">{ props.mossHourTagAppData.mossHourTags.length }</span></h1>

                <div className="boss-page-dashboard__controls-group">
                  <form className="boss-form">
                    <div className="boss-form__field boss-form__field_layout_fluid">
                      <div className="boss-form__switcher boss-form__switcher_role_dashboard">
                        <label className="boss-form__switcher-label">
                          <input
                            type="radio"
                            value="enabled"
                            className="boss-form__switcher-radio"
                            checked={props.mossHourTagAppData.indexFilterData.mode === 'enabled'}
                            onChange={() => {
                              const newUrl = appRoutes.mossHourTagsIndex({
                                searchQuery: props.mossHourTagAppData.indexFilterData.queryString,
                                mode: 'enabled',
                              });
                              window.location.href = newUrl;
                              return false;
                            }}
                          />
                          <span className="boss-form__switcher-label-text">Enabled Only</span>
                        </label>
                        <label className="boss-form__switcher-label">
                          <input
                            checked={props.mossHourTagAppData.indexFilterData.mode === 'all'}
                            type="radio"
                            value="all"
                            className="boss-form__switcher-radio"
                            onChange={() => {
                              const newUrl = appRoutes.mossHourTagsIndex({
                                searchQuery: props.mossHourTagAppData.indexFilterData.queryString,
                                mode: 'all',
                              });
                              window.location.href = newUrl;
                              return false;
                            }}
                          />
                          <span className="boss-form__switcher-label-text">Show All</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boss-page-main__content">
          <div className="boss-page-main__inner">
            <MossHourTagsFilter
              forceUpdate={filterState.forceFilterUpdate}
              tagCreationInProgress={tagCreationInProgress}
              searchQuery={filterState.searchQuery}
              setSearchQuery={setSearchQuery}
              normalizedSearchQuery={normalizedSearchQuery}
              queryString={pageData.indexFilterData.queryString}
              mode={pageData.indexFilterData.mode}
              setMossHourTagAppData={props.setMossHourTagAppData}
            />

            <div className="boss-page-main__note">
              { noTagsToShow && <h3 className="boss-page-main__note-title">No tags to show.</h3> }
              { !noTagsToShow && (queryTag !== undefined) && (
                <div>
                  <h3>
                    Exact match <div
                        key={`tag:${queryTag.id}`}
                        className={
                          [
                            "boss-tag boss-tag_size_xl", "boss-tag_role_hours-tag", "boss-tag_role_action", queryTag.isDisabled ? "boss-tag_state_disabled" : null,
                          ].filter(Boolean).join(" ")
                        }
                        onClick={() => handleTagClick(queryTag.id)}
                      >
                        <p className="boss-tag__text">{ queryTag.name }</p>
                      </div>
                  </h3>
                </div>
              )}
              { !noTagsToShow && normalizedSearchQuery === "" && (queryTag === undefined) && !tagCreationInProgress && (
                <h3 className="boss-page-main__note-title">
                  Showing All Tags
                </h3>
              )}
              { !noTagsToShow && normalizedSearchQuery !== "" && (queryTag === undefined) && !tagCreationInProgress && (
                <h3 className="boss-page-main__note-title">
                  No Tag Exists called <span className="boss-page-main__note-title-important">{ normalizedSearchQuery }</span>
                  <button
                    disabled={tagCreationInProgress}
                    type="button"
                    className="boss-button boss-button_role_add boss-button_type_small boss-page-main__note-action"
                    onClick={() => setTagCreationInProgress(true) }
                  >Create</button>
                </h3>
              )}
              { !noTagsToShow && (queryTag === undefined) && tagCreationInProgress && (
                <h3 className="boss-page-main__note-title">
                <span className="boss-page-main__note-spinner">
                    <span className="boss-spinner" />
                </span>
                <span className="boss-page-main__note-title-important">{normalizedSearchQuery}</span> tag creation in progress...
            </h3>
              )}
            </div>

            <div className="boss-page-main__group boss-page-main__group_adjust_hours-tags">
              { enabledTags.length > 0 && (
                <div className="boss-page-main__sub-group">
                  { enabledTags.map((mossHourTag) => {
                    return (
                      <div
                        key={`tag:${mossHourTag.id}`}
                        className="boss-tag boss-tag_size_xl boss-tag_role_hours-tag boss-tag_role_action"
                        onClick={() => handleTagClick(mossHourTag.id)}
                      >
                        <p className="boss-tag__text">{ mossHourTag.name }</p>
                      </div>
                    );
                  }) }
                </div>
              )}
              { disabledTags.length > 0 && (
                <div className="boss-page-main__sub-group">
                  { disabledTags.map((mossHourTag) => {
                    return (
                      <div
                        key={`tag:${mossHourTag.id}`}
                        className="boss-tag boss-tag_size_xl boss-tag_role_hours-tag boss-tag_role_action boss-tag_state_disabled"
                        onClick={() => handleTagClick(mossHourTag.id)}
                      >
                        <p className="boss-tag__text">{ mossHourTag.name }</p>
                      </div>
                    );
                  }) }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalsProvider>
  );
}