import React from 'react';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import oFetch from 'o-fetch';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'color'];

export default function RichTextField(props) {
  const { containerClasses, label, labelClasses, required, placeholder, classes, note } = props;
  const containerClassNames = cn('boss-form__field', { [containerClasses]: !!containerClasses });
  const labelClassNames = cn('boss-form__label-text', { [labelClasses]: !!labelClasses });

  const [input, meta] = oFetch(props, 'input', 'meta');
  const [value, onChange] = oFetch(input, 'value', 'onChange');
  const { touched, error } = meta;
  const hasError = !!error;
  const classNames = cn('boss-form__textarea', { [classes]: !!classes, 'boss-form__textarea_state_error': hasError });

  return (
    <div className={containerClassNames}>
      {label && (
        <label className="boss-form__label">
          <span className={labelClassNames}>{`${label} ${required ? '*' : ''}`}</span>
        </label>
      )}
      <ReactQuill
        modules={modules}
        formats={formats}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        className={classNames}
      />
      {note && <p className="boss-form__field-note">{note}</p>}
      {touched &&
        error && (
          <div className="boss-form__error">
            <p className="boss-form__error-text">
              <span className="boss-form__error-line">{error}</span>
            </p>
          </div>
        )}
    </div>
  );
}
