import React, { Component } from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import { smartSearch } from '@/lib/utils';
import queryString from 'query-string';
import ContentWrapper from '@/components/content-wrapper';
import { openWarningModal, openContentModal, openCustomContentModal } from '@/components/modals';
import * as selectors from './selectors';
import { SimpleDashboard } from '../components';
import {
  AddQuestion,
  EditQuestion,
  MoveQuestionModal,
  QuestionsList,
  QuestionItem,
  AnswersList,
  AnswerItemText,
  AnswerItemImage,
  Filter,
} from './components';
import * as actions from './redux/actions';

function safeParseArray(possibleArray) {
  const array = Array.isArray(possibleArray) ? possibleArray : [possibleArray];
  const parsedArray = array.reduce((acc, item) => {
    try {
      const parsedItem = parseInt(item);
      return [...acc, parsedItem].filter(Boolean);
    } catch (e) {}
    return acc;
  }, []);
  return parsedArray;
}

class QuizCategoryQuestions extends Component {
  constructor(props) {
    super(props);

    const qp = this.getQueryParams();

    const untaggedFilter = qp.untagged === 'true';

    this.state = {
      filterTextValue: '',
      currentPage: 1,
      filterTagsIds: safeParseArray(qp.tags),
      untaggedFilter: untaggedFilter,
    };
  }

  getQueryParams = () => {
    return queryString.parse(window.location.search);
  };

  renderDashboardTitle = (options) => {
    const filteredQuestions = oFetch(options, 'filteredQuestions');
    const questionsCount = oFetch(filteredQuestions, 'length');

    return () => {
      const category = oFetch(this.props, 'category');
      const [name, color] = oFetch(category, 'name', 'color');

      function getQuestionsCount() {
        if (questionsCount === 0) {
          return `0`;
        }
        return `+${questionsCount}`;
      }

      return (
        <div>
          <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">{name}</span>
          <span className="boss-page-dashboard__title-info" style={{ backgroundColor: color }}>
            {getQuestionsCount()}
          </span>
        </div>
      );
    }
  };

  renderAddButton = () => {
    const manageTagsUrl = oFetch(this.props, 'manageTagsUrl');

    return (
      <div className="boss-page-dashboard__buttons-group">
        <button
          onClick={this.openAddQuestionModal}
          type="button"
          className="boss-button boss-button_role_add boss-page-dashboard__button"
        >
          Add Question
        </button>

        <a href={manageTagsUrl} className="boss-button boss-button_role_manage-categories boss-page-dashboard__button">
          Manage Tags
        </a>
      </div>
    );
  };

  handleCreateQuestion = (closeModal, values) => {
    const createQuestion = oFetch(this.props, 'createQuestion');
    return createQuestion(values).then(closeModal);
  };

  handleUpdateQuestion = (closeModal, values) => {
    const updateQuestion = oFetch(this.props, 'updateQuestion');
    return updateQuestion(values).then(closeModal);
  };

  handleMoveQuestion = (closeModal, values) => {
    const moveQuestion = oFetch(this.props, 'moveQuestion');
    const quizCategoryId = oFetch(values, 'quizCategoryId');

    //Only send request if category is chosen
    if (quizCategoryId) {
      return moveQuestion(values).then(closeModal);
    } else {
      return closeModal();
    }
  }

  openAddQuestionModal = () => {
    const [questionTypesOptions, questionTypes, category, questionTags] = oFetch(
      this.props,
      'questionTypesOptions',
      'questionTypes',
      'category',
      'questionTags',
    );

    const categoryId = oFetch(category, 'id');

    openContentModal({
      submit: this.handleCreateQuestion,
      config: { title: 'Add Question' },
      props: { questionTypesOptions, questionTypes, categoryId, questionTags },
    })(AddQuestion);
  };

  handleDisableQuestion = (closeModal, params) => {
    const question = oFetch(params, 'question');
    const disableQuestion = oFetch(this.props, 'disableQuestion');
    return disableQuestion(question).then(closeModal);
  };

  handleEnableQuestion = question => {
    const enableQuestion = oFetch(this.props, 'enableQuestion');
    return enableQuestion(question);
  };

  openEditQuestionModal = question => {
    const [questionTypesOptions, questionTypes, category, questionTags] = oFetch(
      this.props,
      'questionTypesOptions',
      'questionTypes',
      'category',
      'questionTags',
    );

    const categoryId = oFetch(category, 'id');

    openContentModal({
      submit: this.handleUpdateQuestion,
      config: { title: 'Edit Question' },
      props: { questionTypesOptions, questionTypes, categoryId, question, questionTags },
    })(EditQuestion);
  };

  openOnDisableQuestionModal = question => {
    openWarningModal({
      submit: this.handleDisableQuestion,
      config: {
        title: 'WARNING !!!',
        text: 'Are You Sure?',
        buttonText: 'Disable',
      },
      props: { question },
    });
  };

  openOnMoveQuestionModal = curriedOptions => {
    const otherQuizCategories = oFetch(curriedOptions, 'otherQuizCategories');
    return options => {
      const question = oFetch(options, 'question');
      const questionCode = oFetch(question, 'code');

      openCustomContentModal({
        submit: this.handleMoveQuestion,
        config: {
          modalClass: 'boss-modal-window boss-modal-window_role_warning',
          titleClass: 'boss-modal-window__header',
          title: `Move Question ${questionCode}?`,
          innerClose: false,
        },
        props: { question, categories: otherQuizCategories },
      })(MoveQuestionModal);
    }
  }

  renderQuestionItem =  curriedOptions => {
    const canMove = oFetch(curriedOptions, 'canMove');
    const otherQuizCategories = oFetch(curriedOptions, 'otherQuizCategories');

    return options => {
      const question = oFetch(options, 'item');

      return (
        <QuestionItem
          onEnable={this.handleEnableQuestion}
          onEdit={this.openEditQuestionModal}
          onDisable={this.openOnDisableQuestionModal}
          onMove={this.openOnMoveQuestionModal({ otherQuizCategories })}
          canMove={canMove}
          question={question}
          otherQuizCategories={otherQuizCategories}
        >
          {question => this.renderAnswers(question)}
        </QuestionItem>
      );
    }
  };

  renderAnswers = question => {
    const questionTypes = oFetch(this.props, 'questionTypes');
    const questionType = oFetch(question, 'type');

    const isTextTypeSelected = [questionTypes.singleTextType, questionTypes.multiTextType].includes(questionType);
    const AnswerComponent = isTextTypeSelected ? AnswerItemText : AnswerItemImage;
    return <AnswersList question={question} answerRender={answer => <AnswerComponent answer={answer} />} />;
  };

  filterByText = (questions, value) => {
    if (!value) {
      return questions;
    }
    return smartSearch(questions, ['code', 'text'], value).map(search => oFetch(search, 'item'));
  };

  filterByTags = (questions, filterTagsIds, untaggedFilter) => {
    const isFilterTagsExists = filterTagsIds.length !== 0;

    if (untaggedFilter) {
      return questions.filter(question => {
        const questionTagsIds = oFetch(question, 'tagsIds');
        const isQuestionTagsIds = questionTagsIds.length !== 0;
        return !isQuestionTagsIds;
      });
    }

    if (!isFilterTagsExists) {
      return questions;
    }

    return questions.filter(question => {
      const questionTagsIds = oFetch(question, 'tagsIds');
      const isTagExist = questionTagsIds.some(tagId => filterTagsIds.includes(tagId));
      return isTagExist;
    });
  };

  handleTagsFilterChange = value => {
    const arrayValues = value
      .split(',')
      .filter(Boolean)
      .map(id => parseInt(id));
    const qp = this.getQueryParams();
    window.history.pushState(
      'state',
      'title',
      `${window.location.pathname}?${queryString.stringify({ ...qp, tags: arrayValues })}`,
    );
    this.setState({ filterTagsIds: arrayValues });
  };

  getFilteredQuestions = () => {
    const questions = oFetch(this.props, 'questions');
    const [filterTextValue, filterTagsIds, untaggedFilter] = oFetch(
      this.state,
      'filterTextValue',
      'filterTagsIds',
      'untaggedFilter',
    );

    const filteredByText = this.filterByText(questions, filterTextValue);
    const filteredByTagsAndText = this.filterByTags(filteredByText, filterTagsIds, untaggedFilter);

    return filteredByTagsAndText;
  };

  handleTextFilterChange = e => {
    const value = oFetch(e, 'target.value');
    this.setState({ filterTextValue: value });
  };

  handleUntaggedFilterChange = () => {
    this.setState(
      state => ({ untaggedFilter: !oFetch(state, 'untaggedFilter') }),
      () => {
        const qp = this.getQueryParams();
        const untaggedFilter = oFetch(this.state, 'untaggedFilter');
        window.history.pushState(
          'state',
          'title',
          `${window.location.pathname}?${queryString.stringify({
            ...qp,
            untagged: untaggedFilter,
          })}`,
        );
      },
    );
  };

  render() {
    const [
      questionTypesOptions,
      questionTags,
      otherQuizCategories,
    ] = oFetch(
      this.props,
      'questionTypesOptions',
      'questionTags',
      'otherQuizCategories',
    );
    const [
      filterTextValue,
      filterTagsIds,
      untaggedFilter
    ] = oFetch(
      this.state,
      'filterTextValue',
      'filterTagsIds',
      'untaggedFilter',
    );

    const permissions = oFetch(this.props, 'permissions');
    const canMove = oFetch(permissions, 'canMoveQuestions');

    const renderDashboardButtons = oFetch(permissions, 'canViewMangeTagsPage') ? this.renderAddButton : null;
    const filteredQuestions = this.getFilteredQuestions(filterTextValue);


    return (
      <div>
        <SimpleDashboard renderTitle={this.renderDashboardTitle({filteredQuestions: filteredQuestions})} renderButtons={renderDashboardButtons} />
        <ContentWrapper>
          <Filter
            filterTextValue={filterTextValue}
            onTextFilterChange={this.handleTextFilterChange}
            onTagsFilterChange={this.handleTagsFilterChange}
            onUntaggedFilterChange={this.handleUntaggedFilterChange}
            filterTagsIds={filterTagsIds}
            untaggedFilterValue={untaggedFilter}
            options={questionTypesOptions}
            tagsOptions={questionTags}
          />
          <QuestionsList
            items={filteredQuestions}
            itemRenderer={this.renderQuestionItem({ canMove, otherQuizCategories })}
          />
        </ContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    permissions: selectors.getPermissions(state),
    category: selectors.getCategory(state),
    otherQuizCategories: selectors.otherQuizCategoriesSelector(state),
    questionTypesOptions: selectors.getQuestionTypesOptions(state),
    questionTypes: selectors.getQuestionTypes(state),
    questions: selectors.getQuestions(state),
    questionTags: selectors.quizCategoryQuestionTagsSelector(state),
  };
};

const mapDispatchToProps = {
  createQuestion: actions.createQuestion,
  updateQuestion: actions.updateQuestion,
  moveQuestion: actions.moveQuestion,
  disableQuestion: actions.disableQuestion,
  enableQuestion: actions.enableQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizCategoryQuestions);
