import oFetch from 'o-fetch';
import { SubmissionError } from 'redux-form';
import utils from '@/lib/utils';
import { markHandledRequest } from './requests';

export const markHandledAction = (options, cb) => {
  const id = oFetch(options, 'id');
  const reason = oFetch(options, 'reason');

  return markHandledRequest({
    misattributedLoginId: id,
    reason: reason,
    successHandler: params => {
      const globalNotifications = oFetch(params, 'globalNotifications');
      const handledMisattributedLogin = oFetch(params, 'data.handledMisattributedLogin');
      cb(handledMisattributedLogin);
    },
    errorHandler: params => {
      const statusCode = oFetch(params, 'statusCode');
      const globalNotifications = oFetch(params, 'globalNotifications');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');
      const errors = utils.safeGet(() => oFetch(params, 'errors'));
      if (errors) {
        throw new SubmissionError(errors);
      }
      return false;
    },
  });
};
