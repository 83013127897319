import z from 'zod';
import { Methods } from './api-routes';
import { AxiosInstance, AxiosResponse } from 'axios';

type CallParams = object;
type PathParams = object;
type GetPathFunc<TPath extends PathParams> = (params: TPath) => string;
type RouteMethod = typeof Methods.GET_METHOD | typeof Methods.POST_METHOD | typeof Methods.DELETE_METHOD | typeof Methods.PATCH_METHOD | typeof Methods.PUT_METHOD;
type Route<TPath extends PathParams, TCall extends CallParams> = {
  getPath: GetPathFunc<TPath>,
  method: RouteMethod,
  $CallParams: z.ZodSchema<TCall>;
  $PathParams: z.ZodSchema<TPath>;
};

// this signature provides static type checking ensuring switch is complete
// while the implementation below ensures failure at runtime. Comment out a switch case to see how it works
function throwBadMethod(method: RouteMethod): never {
  throw new Error(`unsuppored value supplied for route method: ${method}`);
}

type InferRouteTypes<T extends Route<any, any>> = {
  pathParams: T["$PathParams"]["_input"],
  callParams: T["$CallParams"]["_input"],
};

type ApplyBossRouteParams <T extends Route<any, any>> = {
  bossHttpRequest: AxiosInstance,
  route: T,
} & InferRouteTypes<T>;

export function applyBossRoute<T extends Route<any, any>>(params: ApplyBossRouteParams<T>): Promise<AxiosResponse<T["$CallParams"]["_input"]>> {
  const {
    route,
    bossHttpRequest,
  } = params;
  const pathParams: z.infer<typeof params.pathParams> = route.$PathParams.parse(params.pathParams);
  const callParams: z.infer<typeof params.callParams> = route.$CallParams.parse(params.callParams);

  switch (route.method) {
    case Methods.GET_METHOD:
      return bossHttpRequest.get(
        route.getPath(pathParams),
        callParams,
      );
    case Methods.POST_METHOD:
      return bossHttpRequest.post(
        route.getPath(pathParams),
        callParams,
      );
    case Methods.PATCH_METHOD:
      return bossHttpRequest.patch(
        route.getPath(pathParams),
        callParams,
      );
    case Methods.DELETE_METHOD:
      return bossHttpRequest.delete(
        route.getPath(pathParams),
        callParams,
      );
    case Methods.PUT_METHOD:
      return bossHttpRequest.put(
        route.getPath(pathParams),
        callParams,
      );
    default:
      throwBadMethod(route.method);
  }
}