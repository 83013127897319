import React, { useEffect } from 'react';
import oFetch from 'o-fetch';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { PublicHolidays } from './public-holidays';

export type PublicHolidaysAppProps = {
  accessToken: string;
  months: string[];
  publicHolidays: any[];
  selectedYear: number;
  years: number[];
};

export function PublicHolidaysApp(props: PublicHolidaysAppProps) {
  const accessToken = oFetch(props, 'accessToken');
  const months = oFetch(props, 'months');
  const selectedYear = oFetch(props, 'selectedYear');

  useEffect(() => {
    require('./styles.css');
    window.boss.accessToken = accessToken;
  }, []);


  return (
    <LegacyGlobalProvider global={{ months, selectedYear }}>
      <ModalsProvider>
        <PublicHolidays {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
