import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { SubmissionError, reset } from 'redux-form';

import { bossRequestHttp } from '@/lib/request-api';

import * as types from './types';
import * as selectors from './selectors';

export const loadInitialState = createAction(types.LOAD_INITIAL_STATE);
export const addChecklist = createAction(types.ADD_CHECKLIST);
export const removeChecklistFromStore = createAction(types.REMOVE_CHECKLIST_FROM_STORE);
export const updateChecklistAction = createAction(types.UPDATE_CHECKLIST);
export const setEditChecklistId = createAction(types.SET_EDIT_CHECKLIST_ID);
export const clearEditChecklistId = () => setEditChecklistId({ checklistId: null });

export const printGuid = params => (dispatch, getState) => {
  const checklistName = oFetch(params, 'checklistName');
  const checklistId = oFetch(params, 'checklistId');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const [image, filename] = oFetch(params, 'data.image', 'data.filename');

      // const url = URL.createObjectURL(blob);
      const url = image;
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      // Click handler that releases the object URL after the element has been clicked
      // This is required for one-off downloads of the blob content
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      // Add the click event listener on the anchor element
      a.addEventListener('click', clickHandler, false);

      a.click();
    },
  }).get(`/api/v1/check_lists/${checklistId}/qr_code`);
};

export const updateChecklist = params => (dispatch, getState) => {
  const [checklistId, name, items] = oFetch(params, 'checklistId', 'name', 'items');
  const currentVenue = selectors.currentVenueSelector(getState());
  const currentVenueId = oFetch(currentVenue, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const [data, globalNotifications] = oFetch(params, 'data', 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      const [checklist, checklistItems] = oFetch(data, 'checklist', 'checklistItems');
      const newChecklistId = oFetch(checklist, 'id');
      if (checklistId !== newChecklistId) {
        dispatch(updateChecklistAction({ checklistId, checklist, checklistItems }));
      }
    },
  }).put(`/api/v1/check_lists/${checklistId}`, {
    venueId: currentVenueId,
    items,
    name,
  });
};

export const deleteChecklist = params => (dispatch, getState) => {
  const checklistId = oFetch(params, 'checklistId');
  const currentVenue = selectors.currentVenueSelector(getState());
  const currentVenueId = oFetch(currentVenue, 'id');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      dispatch(removeChecklistFromStore({ checklistId }));
    },
  }).delete(`/api/v1/check_lists/${checklistId}`, {
    params: {
      venueId: currentVenueId,
    },
  });
};

export const submitChecklist = params => (dispatch, getState) => {
  const [values, form] = oFetch(params, 'values', 'form');
  const currentVenue = selectors.currentVenueSelector(getState());
  const currentVenueId = oFetch(currentVenue, 'id');
  const [items, checklistId, guid] = oFetch(values, 'items', 'checklistId', 'guid');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      dispatch(reset(form));
    },
  }).post(`/api/v1/check_lists/${checklistId}/submit`, {
    venueId: currentVenueId,
    items,
    guid,
  });
};

export const createChecklist = params => (dispatch, getState) => {
  const [values, form] = oFetch(params, 'values', 'form');
  const currentVenue = selectors.currentVenueSelector(getState());
  const currentVenueId = oFetch(currentVenue, 'id');
  const [items, name] = oFetch(values, 'items', 'name');

  return bossRequestHttp({
    errorHandler(params) {
      const [statusCode, globalNotifications] = oFetch(params, 'statusCode', 'globalNotifications');
      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage();
      if (statusCode === 422) {
        throw new SubmissionError(errors);
      }
    },
    successHandler(params) {
      const [data, globalNotifications] = oFetch(params, 'data', 'globalNotifications');
      dispatch(addChecklist(data));
      dispatch(reset(form));
      globalNotifications.showDefaultSuccessMessage();
    },
  }).post(`/api/v1/check_lists`, {
    venueId: currentVenueId,
    name,
    items,
  });
};
