import { useReducer, useMemo } from 'react';
import { createAction, handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import { bossRequestHttp } from '@/lib/request-api';
import { normalizeFinalFormErrors, BASE_ERROR_KEY } from '../utils';

const CREATE_CONTACT = 'CREATE_CONTACT';
const UPDATE_CONTACT = 'UPDATE_CONTACT';
const CREATE_TAG = 'CREATE_TAG';
const LOADMORE_CONTACTS = 'LOADMORE_CONTACTS';

const createContactAction = createAction(CREATE_CONTACT);
const updateContactAction = createAction(UPDATE_CONTACT);
const createTagAction = createAction(CREATE_TAG);
const loadMoreAction = createAction(LOADMORE_CONTACTS);

const contactsReducer = handleActions(
  {
    [CREATE_CONTACT]: (state, action) => {
      const contact = oFetch(action, 'payload.purpleContact');
      return [...state, contact];
    },
    [UPDATE_CONTACT]: (state, action) => {
      const contact = oFetch(action, 'payload.purpleContact');
      return state.map(contactInStore => {
        const contactId = oFetch(contact, 'id');
        const contactInStoreId = oFetch(contactInStore, 'id');
        if (contactInStoreId === contactId) {
          return contact;
        }
        return contactInStore;
      });
    },
    [LOADMORE_CONTACTS]: (state, action) => {
      const contacts = oFetch(action, 'payload.contacts');
      return contacts;
    },
  },
  [],
);

const paginationReducer = handleActions(
  {
    [LOADMORE_CONTACTS]: (state, action) => {
      const [totalCount, currentPage] = oFetch(action, 'payload.totalCount', 'payload.currentPage');

      return {
        currentPage,
        totalCount,
      };
    },
  },
  [],
);

const tagsReducer = handleActions(
  {
    [CREATE_TAG]: (state, action) => {
      const purpleContactTag = oFetch(action, 'payload.purpleContactTag');
      return [...state, purpleContactTag];
    },
  },
  [],
);

export default function useContactsStore(initialData) {
  const [contacts, tags, totalCount] = oFetch(initialData, 'contacts', 'tags', 'totalCount');
  const [contactsState, dispatchContacts] = useReducer(contactsReducer, contacts);
  const [tagsState, dispatchTags] = useReducer(tagsReducer, tags);
  const [paginationState, dispatchPagination] = useReducer(paginationReducer, {
    currentPage: 1,
    totalCount: totalCount,
  });

  const normalizedContacts = useMemo(() => {
    return contactsState.map(contact => {
      const tagIds = oFetch(contact, 'tagIds');
      const contactTags = tagsState.filter(tag => tagIds.includes(oFetch(tag, 'id')));
      return {
        ...contact,
        tags: contactTags,
      };
    });
  }, [contactsState, tagsState]);

  function createContact(params) {
    const [values, closeModal] = oFetch(params, 'values', 'closeModal');
    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: [BASE_ERROR_KEY, 'firstName'],
          });
          const normalizedErrors = normalizeFinalFormErrors(errors);
          globalNotifications.showDefaultFailureMessage({ type: 'purple' });
          return normalizedErrors;
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
        dispatchContacts(createContactAction(data));
        closeModal();
      },
    }).post(`/api/v1/purple/contacts`, values);
  }

  function updateContact(params) {
    const [values, closeModal] = oFetch(params, 'values', 'closeModal');
    const contactId = oFetch(values, 'id');
    return bossRequestHttp({
      errorHandler(params) {
        const statusCode = oFetch(params, 'statusCode');
        const errors = oFetch(params, 'errors');
        const globalNotifications = oFetch(params, 'globalNotifications');
        const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

        if (statusCode === 422 && errors) {
          supportedKeyChecker.validateKeys({
            suppliedKeys: Object.keys(errors),
            supportedKeys: [BASE_ERROR_KEY, 'firstName'],
          });
          const normalizedErrors = normalizeFinalFormErrors(errors);
          globalNotifications.showDefaultFailureMessage({ type: 'purple' });
          return normalizedErrors;
        }
      },
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
        dispatchContacts(updateContactAction(data));
        closeModal();
      },
    }).put(`/api/v1/purple/contacts/${contactId}`, values);
  }

  function disableContact(params) {
    const contact = oFetch(params, 'contact');
    const contactId = oFetch(contact, 'id');
    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
        dispatchContacts(updateContactAction(data));
      },
    }).post(`/api/v1/purple/contacts/${contactId}/disable`);
  }

  function enableContact(params) {
    const contact = oFetch(params, 'contact');
    const contactId = oFetch(contact, 'id');
    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
        dispatchContacts(updateContactAction(data));
      },
    }).post(`/api/v1/purple/contacts/${contactId}/enable`);
  }

  function createContactTag(params) {
    const tagName = oFetch(params, 'tag');

    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        const purpleContactTag = oFetch(data, 'purpleContactTag');
        const globalNotifications = oFetch(params, 'globalNotifications');
        globalNotifications.showDefaultSuccessMessage({ type: 'purple' });
        dispatchTags(createTagAction(data));
        return { purpleContactTag };
      },
    }).post(`/api/v1/purple/contacts/tags`, { tagName });
  }

  function loadMoreContacts(filterParams) {
    const [name, status, tags] = oFetch(filterParams, 'name', 'status', 'tags');

    const currentPage = oFetch(paginationState, 'currentPage');
    const nextPage = currentPage + 1;
    return bossRequestHttp({
      errorHandler(params) {},
      successHandler(params) {
        const data = oFetch(params, 'data');
        dispatchPagination(loadMoreAction(data));
        dispatchContacts(loadMoreAction(data));
      },
    }).get(`/api/v1/purple/contacts`, { params: { page: nextPage, name, status, tags } });
  }

  return {
    contacts: normalizedContacts,
    tags: tagsState,
    totalCount: oFetch(paginationState, 'totalCount'),
    loadMoreContacts,
    createContact,
    updateContact,
    disableContact,
    enableContact,
    createContactTag,
  };
}
