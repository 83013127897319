import { Component } from 'react';
import { handleUnexpectedFailure } from '@/lib/handle-unexpected-failure';

export default class ReactErrorsBoundaryWrapper extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    handleUnexpectedFailure(error);
  }

  render() {
    const { children } = this.props;

    if (this.state.hasError) {
      return null;
    }

    return children;
  }
}
