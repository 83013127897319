import React from 'react';
import PropTypes from 'prop-types';
import safeMoment from "@/lib/safe-moment";
import oFetch from 'o-fetch';

const CheckListItemNote = ({note}) => {
  return (
    <div className="boss-checklist__notes">
      <div className="boss-checklist__notes-inner">
        <p className="boss-checklist__notes-label">
          Note
        </p>
        <p className="boss-checklist__notes-text">
          {note}
        </p>
      </div>
    </div>
  )
}

const CheckListItem = ({item}) => {
  const checkedCn = item.get('answer')
    ? 'boss-checklist__label-text_state_checked'
    : '';

  return (
    <div className="boss-checklist__item">
      <div className="boss-checklist__control">
        <p className="boss-checklist__label">
          <span className={`boss-checklist__label-text boss-checklist__label-text_type_checkbox ${checkedCn}`}>
            {item.get('description')}
          </span>
        </p>
      </div>
      {!item.get('answer') && <CheckListItemNote note={item.get('note')} />}
    </div>
  )
}

const CheckList = ({items}) => {
  const renderItems = (items) => {
    return items.map((item, key) => {
      return <CheckListItem item={item} />
    })
  }

  return (
    <div className="boss-checklist__content">
      <div className="boss-checklist__items">
        {renderItems(items)}
      </div>
    </div>
  )
}

class ModalDetailsContent extends React.Component {
  static propTypes = {
    submission: PropTypes.object.isRequired,
  }

  getSubmissionStatus(answers) {
    const filteredItems = answers.filter(item => {
      return oFetch(item, 'answer')
    })
    return oFetch(filteredItems, 'length') === oFetch(answers, 'length');
  }

  render() {
    const submission = oFetch(this.props, 'submission');
    const submissionJS = submission.toJS();
    const answers = oFetch(submissionJS, 'answers');
    const status = this.getSubmissionStatus(answers);
    const checkListName = oFetch(submissionJS, 'check_list_name');
    const venueName = oFetch(submissionJS, 'venue_name');
    const statusCn = status
      ? 'boss-modal-window__header_status_success'
      : 'boss-modal-window__header_status_fail';
    const sCreatedAt = oFetch(submissionJS, 'created_at');
    const mCreatedAt = safeMoment.iso8601Parse(sCreatedAt);
    const formattedCreatedAt = mCreatedAt.format('HH:mm DD/MM/YYYY')
    const creatorName = oFetch(submissionJS, 'creator_name');

    return (
      <div>
        <div className={`boss-modal-window__header ${statusCn}`}>
          {checkListName}
        </div>
        <div className="boss-modal-window__content">
          <div className="boss-stats">
            <div className="boss-stats__meta">
              <p className="boss-stats__label boss-stats__label_role_venue">
                {venueName}
              </p>
              <p className="boss-stats__label boss-stats__label_role_date">
                {formattedCreatedAt}
              </p>
              <p className="boss-stats__label boss-stats__label_role_user">
                {creatorName}
              </p>
            </div>
            <div className="boss-stats__checklist">
              <CheckList items={submission.get('answers')}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalDetailsContent;
