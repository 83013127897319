import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import cn from 'classnames';
import Modal from 'react-modal';

const WarningModal = ({ show, confirmation, options, proceed, dismiss, cancel }) => {
  const { title, actionButtonText, titleClassName, buttonClassName } = options;
  const titleClassNames = cn(
    'ReactModal__Content boss-modal-window ReactModal__Content--after-open',
    { 'boss-modal-window_role_danger': !titleClassName },
    { [titleClassName]: titleClassName },
  );

  const buttonClassNames = cn(
    'boss-button',
    { 'boss-button_role_cancel': !buttonClassName },
    { [buttonClassName]: buttonClassName },
  );

  return (
    <Modal isOpen={show} contentLabel="Modal" className={titleClassNames} ariaHideApp={false}>
      <button onClick={dismiss} className="boss-modal-window__close" />
      <div className="boss-modal-window__header">{title}</div>
      <div className="boss-modal-window__content">
        <div className="boss-modal-window__message-block">
          <span className="boss-modal-window__message-text">{confirmation}</span>
        </div>
        <div className="boss-modal-window__actions">
          <button className={buttonClassNames} onClick={proceed}>
            {actionButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default confirmable(WarningModal);
