import React from 'react';
import { z } from 'zod';
import { BossFormTagInput, $TagOptionId, $TagOption } from './boss-form-tag-input';
import { fromJS } from 'immutable';

const $ImmutableTagOptionIdArray = z.object({
  toJS: z.function().args().returns(
    z.array($TagOptionId)
  ),
}).passthrough();

const $InputProps = z.object({
  className: z.string().optional(),
  input: z.object({
    value: $ImmutableTagOptionIdArray,
    onChange: z.function().args($ImmutableTagOptionIdArray).returns(z.void()),
  }).passthrough(),
  meta: z.unknown(),
  options: z.array($TagOption),
  valueKey: z.literal('id'),
  labelKey: z.literal('name'),
  disabled: z.boolean().optional(),
  clearable: z.boolean().optional(),
  multi: z.boolean().optional(),
}).strict();

export function ImmutableBossFormTagInput(props: unknown): React.ReactElement {
  const parsedProps = $InputProps.parse(props);

  const immutableInputDecorator = {
    value: parsedProps.input.value.toJS(),
    onChange: (args: number[]) => {
      parsedProps.input.onChange(fromJS(args));
    },
  };

  const normalizedProps = {
    ...parsedProps,
    input: immutableInputDecorator,
  };

  return (
    <BossFormTagInput {...normalizedProps} />
  );
}