import React, { Component } from 'react';
import oFetch from 'o-fetch';
import { appRoutes } from "@/lib/legacy-routes";
import {
  BOSS_STAFF_TYPE,
  MOSS_STAFF_TYPE,
} from './constants';

class StaffCard extends Component {
  render() {
    const effectiveStaffMember = oFetch(this.props, 'effectiveStaffMember');
    const effectiveStaffMemberType = oFetch(effectiveStaffMember, 'type');
    const effectiveStaffMemberId = oFetch(effectiveStaffMember, 'id');
    const avatarUrl = oFetch(effectiveStaffMember, 'avatarUrl');
    const fullName = oFetch(effectiveStaffMember, 'fullName');

    let avatarContainerClasses = 'boss-user-summary__avatar';
    if (oFetch(effectiveStaffMember, 'supportsFacialRecognition')) {
     avatarContainerClasses = avatarContainerClasses + ' boss-user-summary__avatar_type_scannable';
    }

    let effectiveStaffMemberProfileHolidaysTabUrl = undefined
    if (effectiveStaffMemberType === BOSS_STAFF_TYPE) {
      effectiveStaffMemberProfileHolidaysTabUrl = appRoutes.staffMemberProfileHolidaysTab({ staffMemberId: effectiveStaffMemberId });
    } else if (effectiveStaffMemberType === MOSS_STAFF_TYPE) {
      effectiveStaffMemberProfileHolidaysTabUrl = appRoutes.mossStaffMemberProfileHolidaysTab({ mossStaffMemberId: effectiveStaffMemberId });
    } else {
      throw new Error(`unsupported type: ${effectiveStaffMemberType}`);
    }


    return (
      <div className="boss-check boss-check_role_board boss-check_page_accessory-requests">
        <div className="boss-check__row">
          <div className="boss-check__cell">
            <div className="boss-check__user-summary">
              <div className="boss-user-summary boss-user-summary_role_card-header-short">
                <div className="boss-user-summary__side">
                  <div className={avatarContainerClasses}>
                    <div className="boss-user-summary__avatar-inner">
                      <img src={avatarUrl} alt={fullName} />
                    </div>
                  </div>
                </div>
                <div className="boss-user-summary__content">
                  <div className="boss-user-summary__header">
                    <h4 className="boss-user-summary__name">{fullName}</h4>
                  </div>
                  <div className="boss-user-summary__footer">
                    <a
                      href={ effectiveStaffMemberProfileHolidaysTabUrl }
                      className="boss-button boss-button_role_view-details-light boss-button_type_extra-small"
                    >
                      View All Holidays
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default StaffCard;
