import React from 'react';
import oFetch from 'o-fetch';

export function TemporaryList(props) {
  const [items, renderItem, venuesOptions] = oFetch(props, 'items', 'renderItem', 'venuesOptions');

  function renderItems() {
    return items.map((item, index) => {
      return React.cloneElement(renderItem({ item, venuesOptions }), { key: index });
    });
  }

  return (
    <div className="boss-table boss-table_page_app-access-temporary">
      <div className="boss-table__row boss-table__row_role_header">
        <div className="boss-table__column">
          <div className="boss-table__cell" />
        </div>
        <div className="boss-table__column">
          <div className="boss-table__row">
            <div className="boss-table__cell">Venue</div>
            <div className="boss-table__cell">Start</div>
            <div className="boss-table__cell">End</div>
            <div className="boss-table__cell" />
          </div>
        </div>
      </div>
      {renderItems()}
    </div>
  );
}
