import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field, Fields, reduxForm } from 'redux-form/immutable';
import getVenueColor from '@/lib/get-venue-color';
import { ErrorBlock, BossFormCheckbox, BossFormSelect, BossFormShiftTimeInput } from '@/components/boss-form';
import { ColoredSingleOption, ColoredSingleValue } from '@/components/boss-form/colored-select';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';

function AddShiftForm(props) {
  const timeSelectOptions = oFetch(useLegacyGlobal(), 'timeSelectOptions');

  const {
    handleSubmit,
    submittion,
    submitting,
    error,
    handleAfterAdd,
  } = props;

  return (
    <form
      onSubmit={handleSubmit(submittion)}
      className="boss-form"
    >
      <Field
        name="venueId"
        component={BossFormSelect}
        options={props.venues
          .map(v => v.set('color', getVenueColor(v.get('id').split(`_`)[1])))
          .toJS()}
        optionValue="id"
        optionLabel="name"
        multy={false}
        placeholder="Select Venue"
        label="Venue"
        clearable={false}
        optionComponent={ColoredSingleOption}
        valueComponent={ColoredSingleValue}
      />
      <Fields
        names={['startsAt', 'endsAt']}
        startsAtFieldName="startsAt"
        endsAtFieldName="endsAt"
        component={BossFormShiftTimeInput}
        options={timeSelectOptions}
      />
      {error && <ErrorBlock error={error} />}
      <Field
        name="shiftType"
        label="Standby"
        type="checkbox"
        format={value => (value === 'standby' ? true : false)}
        normalize={value => (value ? 'standby' : 'normal')}
        component={BossFormCheckbox}
      />
      <div className="boss-form__field boss-form__field_role_controls">
        <button
          type="button"
          onClick={handleAfterAdd}
          className="boss-button boss-button_role_inactive boss-form__button_adjust_max boss-form__button_adjust_row"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={submitting}
          className="boss-button boss-button_role_add boss-form__button_adjust_max"
        >
          Add Shift Hours
        </button>
      </div>
    </form>
  );
}

AddShiftForm.PropTypes = {
  submittion: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  rotaDate: PropTypes.string.isRequired,
  rotas: ImmutablePropTypes.list.isRequired,
  handleAfterAdd: PropTypes.func.isRequired,
  venues: ImmutablePropTypes.list.isRequired,
};

export default reduxForm({
  fields: ['staffMemberId'],
  form: 'add-shift-form',
})(AddShiftForm);
