import React, { Fragment } from 'react';
import DashboardWrapper from '@/components/dashboard-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { WarningModalContent } from '@/components/modal-content';
import { LegacyCloseInsideModalWrapper, LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';

import {
  PayRateGroup,
  PayRatesList,
  PayRateItem,
  AddPayRateModal,
  EditPayRateModal,
} from './components';
import usePayRatesApiCalls from './use-pay-rates-api-calls';
import oFetch from 'o-fetch';

export default function PayRates(props) {
  const { openModal } = useModal();
  const [
    normalRates,
    adminRates,
    payRatesOptions,
    createPayRate,
    createAdminPayRate,
    updatePayRate,
    deletePayRate,
  ] = oFetch(
    usePayRatesApiCalls(props),
    'normalRates',
    'adminRates',
    'payRatesOptions',
    'createPayRate',
    'createAdminPayRate',
    'updatePayRate',
    'deletePayRate',
  );
  const abilities = oFetch(props, "abilities");

  function renderPayRate(args) {
    const payRate = oFetch(args, 'payRate');
    const type = oFetch(args, 'type');
    const itemAbilities = oFetch(args, "itemAbilities");

    const onDeleteClick = oFetch(itemAbilities, "canDelete") ? onDeletePayRate : () => {};
    const onEditClick = oFetch(itemAbilities, "canEdit") ? () => openEditPayRateModal(payRate, type) : () => {};

    return (
      <PayRateItem
        payRate={payRate}
        config
        itemAbilities={itemAbilities}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    );
  }

  function openEditPayRateModal(payRate, type) {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: EditPayRateModal,
      onSubmit(handleClose, values) {
        return updatePayRate({ values, handleClose });
      },
      config: {
        title: 'Update Pay Rate',
      },
      props: {
        payRateType: type,
        payRatesOptions: payRatesOptions,
        payRate: payRate,
      },
    });
  }

  function onDeletePayRate(payRate) {
    return openModal({
      ModalContent: WarningModalContent,
      ModalComponent: LegacyCloseOutsideModalWrapper,
      onSubmit(handleClose) {
        handleClose();
        return deletePayRate({ payRate });
      },
      config: {
        title: 'WARNING !!!',
      },
      props: {
        foo: 'bar',
        text: 'Are you sure ?',
        buttonText: 'Delete',
      },
    });
  }

  function openAddNewPayRateModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddPayRateModal,
      onSubmit(handleClose, values) {
        return createPayRate({ values, handleClose });
      },
      config: {
        title: 'Add Pay Rate',
      },
      props: {
        payRateType: 'Named',
        payRatesOptions: payRatesOptions,
      },
    });
  }

  function openAddNewAdminPayRateModal() {
    return openModal({
      ModalComponent: LegacyCloseInsideModalWrapper,
      ModalContent: AddPayRateModal,
      onSubmit(handleClose, values) {
        return createAdminPayRate({ values, handleClose });
      },
      config: {
        title: 'Add Admin Pay Rate',
      },
      props: {
        payRateType: 'Admin',
        payRatesOptions: payRatesOptions,
      },
    });
  }

  const normalPayRateAbilities = {
    canDelete: oFetch(abilities, "canRemovePayRates"),
    canEdit: oFetch(abilities, "canEditPayRates"),
  }

  const adminPayRateAbilities = {
    canDelete: oFetch(abilities, "canRemoveAdminPayRates"),
    canEdit: oFetch(abilities, "canEditAdminPayRates"),
  }

  const canCreateNormalPayRates = oFetch(abilities, "canCreatePayRates");
  const normalOnAddClick = canCreateNormalPayRates ? openAddNewPayRateModal : () => {};
  const canCreateAdminPayRates = oFetch(abilities, "canCreateAdminPayRates");
  const adminOnAddClick = canCreateAdminPayRates ? openAddNewAdminPayRateModal : () => {};

  return (
    <Fragment>
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">Pay Rates</h1>
          </div>
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <PayRateGroup hasCreateAbility={canCreateNormalPayRates} onAddClick={normalOnAddClick} title="Pay Rates" buttonText="Add Rate">
          <PayRatesList
            payRates={normalRates}
            itemRender={payRate => renderPayRate({
              payRate,
              type: 'Named',
              itemAbilities: normalPayRateAbilities,
            })}
            description="These pay rates will be visible and selectable by all managers"
          />
        </PayRateGroup>
        <PayRateGroup
          hasCreateAbility={canCreateAdminPayRates}
          onAddClick={openAddNewAdminPayRateModal}
          title="Admin Pay Rates"
          buttonText="Add Admin Rate"
        >
          <PayRatesList
            payRates={adminRates}
            itemRender={payRate => renderPayRate({
              payRate,
              type: 'Admin',
              itemAbilities: adminPayRateAbilities,
            })}
            description="These will only be visible if you have the correct permissions"
          />
        </PayRateGroup>
      </ContentWrapper>
    </Fragment>
  );
}
