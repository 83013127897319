import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

class CardList extends Component {
  renderItems(staffTypesWithFinanceReports) {
    const itemRenderer = oFetch(this.props, 'itemRenderer');

    return staffTypesWithFinanceReports.map(staffType => {
      if (staffType.get('reports').size === 0) return null;
      return React.cloneElement(itemRenderer(staffType), {
        key: staffType.get('id').toString(),
      });
    });
  }

  renderEmptyReports() {
    return (
      <div className="boss-page-main__note">
        <p className="boss-page-main__text-placeholder">There are no reports to show.</p>
      </div>
    );
  }

  renderPaymentWeekSection(options) {
    const paymentWeekData = oFetch(options, 'paymentWeekData');
    const paymentWeekPublished = oFetch(paymentWeekData, 'paymentWeekPublished');
    const paymentWeekPublishTime = oFetch(paymentWeekData, 'paymentWeekPublishTime');
    const mPaymentWeekPublishTime = safeMoment.iso8601Parse(paymentWeekPublishTime);

    return (
      <div className="boss-page-main__note boss-page-main__note_role_info boss-page-main__note_context_stack">
        <div className="boss-page-main__note-actions">
          {paymentWeekPublished
            ? <div><h3>Payments: Published</h3><p>Puslished at {mPaymentWeekPublishTime.format(utils.commonDateFormatWithTime())}</p><p>Staff Members will be notified of any changes to payments for this week</p></div>
            : <div><h3>Payments: Unpublished</h3><div>Scheduled for publishing at {mPaymentWeekPublishTime.format(utils.commonDateFormatWithTime())}</div></div>
          }
        </div>
      </div>
    );
  }

  render() {
    const staffTypesWithFinanceReports = oFetch(this.props, 'staffTypesWithFinanceReports');
    const reports = this.renderItems(staffTypesWithFinanceReports);
    const hasReports = reports.filter(report => report !== null).size !== 0;
    const paymentWeekData = oFetch(this.props, 'paymentWeekData');

    return (
      <div className="boss-page-main__content">
        <div className="boss-page-main__inner">
          {this.renderPaymentWeekSection({ paymentWeekData })}
          {hasReports ? reports : this.renderEmptyReports()}
        </div>
      </div>
    );
  }
}

CardList.propTypes = {
  staffTypesWithFinanceReports: ImmutablePropTypes.list.isRequired,
  itemRenderer: PropTypes.func.isRequired,
};

export default CardList;
