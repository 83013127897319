import React from 'react';
import oFetch from 'o-fetch';
import * as eventTypes from './event-types';
import { CreateTaskEventType } from './create-task-event-type';
import { UpdateTaskEventType } from './update-task-event-type';
import { GrabTaskEventType } from './grab-task-event-type';
import { ReassignTaskEventType } from './reassign-task-event-type';
import { DeleteTaskEventType } from './delete-task-event-type';
import { CreateQuestionEventType } from './create-question-event-type';
import { UpdateQuestionEventType } from './update-question-event-type';
import { CreateAnswerEventType } from './create-answer-event-type';
import { CreateClarificationEventType } from './create-clarification-event-type';
import { AcceptAnswerEventType } from './accept-answer-event-type';
import { RejectTaskEventType } from './reject-task-event-type';
import { AcceptTaskEventType } from './accept-task-event-type';
import { CompleteTaskEventType } from './complete-task-event-type';
import { DeleteRequestEventType } from './delete-request-event-type';

export function ActivityList(props) {
  const events = oFetch(props, 'events');

  function renderActivities() {
    return events.map(event => {
      const [eventId, eventType] = oFetch(event, 'id', 'eventType');
      if (eventType === eventTypes.CREATE_TASK_EVENT_TYPE) {
        return <CreateTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.UPDATE_TASK_EVENT_TYPE) {
        return <UpdateTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.GRAB_TASK_EVENT_TYPE) {
        return <GrabTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.REASSIGN_TASK_EVENT_TYPE) {
        return <ReassignTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.COMPLETE_TASK_EVENT_TYPE) {
        return <CompleteTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.DELETE_TASK_EVENT_TYPE) {
        return <DeleteTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.CREATE_QUESTION_EVENT_TYPE) {
        return <CreateQuestionEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.UPDATE_QUESTION_EVENT_TYPE) {
        return <UpdateQuestionEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.CREATE_ANSWER_EVENT_TYPE) {
        return <CreateAnswerEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.REQUESTED_CLARIFICATION_EVENT_TYPE) {
        return <CreateClarificationEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.ACCEPT_ANSWER_EVENT_TYPE) {
        return <AcceptAnswerEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.REJECT_TASK_EVENT_TYPE) {
        return <RejectTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.ACCEPT_TASK_EVENT_TYPE) {
        return <AcceptTaskEventType key={eventId} event={event} />;
      }
      if (eventType === eventTypes.DELETE_REQUEST_EVENT_TYPE) {
        return <DeleteRequestEventType key={eventId} event={event} />;
      }
      return null;
    });
  }

  return <ul className="boss-overview__activity">{renderActivities()}</ul>;
}
