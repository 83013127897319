import React, { Fragment } from 'react';
import oFetch from 'o-fetch';
import { BossCheckCard, BossCheckCardCollapsibleGroup, BossCheckRow2 } from '@/components/boss-check-card';
import { openContentModal } from '@/components/modals';

import { BossTable } from '@/components/boss-table';

import AccessoryRequestsList from './accessory-requests-list';
import AccessoryRequestItem from './accessory-request-item';
import ReusableModalContent from './reusable-modal-content';
import { RefundActions } from './refund-actions';
import { RequestActions } from './request-actions';

function AccessoryListItem(props) {
  const actions = oFetch(props, 'actions');
  const data = oFetch(props, 'data');
  const getAccessoryRequestPermission = oFetch(props, 'getAccessoryRequestPermission');
  const getAccessoryRefundRequestPermission = oFetch(props, 'getAccessoryRefundRequestPermission');

  const [
    name,
    requests,
    staffMembers,
    refundRequests,
    accessory,
    requestsCount,
    refundRequestsCount,
    freeItems,
    venue,
  ] = oFetch(
    data,
    'name',
    'requests',
    'staffMembers',
    'refundRequests',
    'accessory',
    'requestsCount',
    'refundRequestsCount',
    'freeItems',
    'venue',
  );

  const [
    acceptAccessoryRequest,
    rejectAccessoryRequest,
    acceptAccessoryRefundRequest,
    rejectAccessoryRefundRequest,
    undoAccessoryRequest,
    undoAccessoryRefundRequest,
    completeAccessoryRequest,
    completeAccessoryRefundRequest,
    markReceivedAccessoryRequest,
  ] = oFetch(
    actions,
    'acceptAccessoryRequest',
    'rejectAccessoryRequest',
    'acceptAccessoryRefundRequest',
    'rejectAccessoryRefundRequest',
    'undoAccessoryRequest',
    'undoAccessoryRefundRequest',
    'completeAccessoryRequest',
    'completeAccessoryRefundRequest',
    'markReceivedAccessoryRequest',
  );
  const [icon, iconColor] = oFetch(accessory, 'icon', 'iconColor');

  function renderRemainingItems(amount) {
    return (
      <span>
        <span className={`boss-check__counter ${amount < 0 ? 'boss-check__counter_state_alert' : ''}`}>
          {amount}
        </span>{' '}
        Remaining
      </span>
    );
  }

  function renderRequestActions(params) {
    const [status, permissions, requestId, accessoryId] = oFetch(
      params,
      'status',
      'permissions',
      'requestId',
      'accessoryId',
    );
    return (
      <Fragment>
        <RequestActions
          status={status}
          permissions={permissions}
          onMarkReceived={() => markReceivedAccessoryRequest({ requestId, accessoryId })}
          onReject={() => rejectAccessoryRequest({ requestId, accessoryId })}
          onAccept={() => acceptAccessoryRequest({ requestId, accessoryId })}
          onUndo={() => undoAccessoryRequest({ requestId, accessoryId })}
          onComplete={() => completeAccessoryRequest({ requestId, accessoryId })}
        />
      </Fragment>
    );
  }

  function renderRefundActions(params) {
    const [status, permissions, requestId, accessoryId] = oFetch(
      params,
      'status',
      'permissions',
      'requestId',
      'accessoryId',
    );
    const { reusable } = params;
    return (
      <RefundActions
        status={status}
        permissions={permissions}
        onReject={() => rejectAccessoryRefundRequest({ requestId, accessoryId })}
        onAccept={() => acceptAccessoryRefundRequest({ requestId, accessoryId })}
        onUndo={() => undoAccessoryRefundRequest({ requestId, accessoryId })}
        onComplete={() => completeAccessoryRefundRequest({ requestId, accessoryId, reusable })}
      />
    );
  }

  return (
    <BossCheckCard
      title={name}
      icon={icon}
      iconColor={iconColor}
      className="boss-check__title_role_accessory"
    >
      <BossCheckRow2
        className1="boss-check__text_role_venue"
        halfOneRenderer={() => oFetch(venue, 'name')}
        halfTwoRenderer={() => renderRemainingItems(freeItems)}
      />
      <BossCheckCardCollapsibleGroup title="Requests" text={requestsCount} showCaret={requestsCount !== 0}>
        <AccessoryRequestsList
          accessory={accessory}
          accessoryRequests={requests}
          staffMembers={staffMembers}
          paginate
          pageSize={5}
          requestItemRenderer={data => {
            const [requestId, accessoryId, requestStatus] = oFetch(
              data,
              'requestId',
              'accessoryId',
              'requestStatus',
            );
            const permissions = getAccessoryRequestPermission(requestId);

            return (
              <AccessoryRequestItem
                permissions={permissions}
                data={data}
                actionsRender={() =>
                  renderRequestActions({
                    permissions,
                    requestId,
                    accessoryId,
                    status: requestStatus,
                  })
                }
              />
            );
          }}
        />
      </BossCheckCardCollapsibleGroup>
      <BossCheckCardCollapsibleGroup
        title="Refunds"
        text={refundRequestsCount}
        showCaret={refundRequestsCount !== 0}
      >
        <BossTable className="boss-table_page_accessory-requests-card">
          <AccessoryRequestsList
            accessory={accessory}
            accessoryRequests={refundRequests}
            staffMembers={staffMembers}
            paginate
            pageSize={5}
            requestItemRenderer={data => {
              const [requestId, accessoryId, requestStatus] = oFetch(
                data,
                'requestId',
                'accessoryId',
                'requestStatus',
              );
              const { reusable } = data;
              const permissions = getAccessoryRefundRequestPermission(requestId);

              return (
                <AccessoryRequestItem
                  data={data}
                  actionsRender={() =>
                    renderRefundActions({
                      reusable,
                      permissions,
                      requestId,
                      accessoryId,
                      status: requestStatus,
                    })
                  }
                />
              );
            }}
          />
        </BossTable>
      </BossCheckCardCollapsibleGroup>
    </BossCheckCard>
  );
}

export default AccessoryListItem;
