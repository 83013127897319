import React, { useEffect, useState } from 'react';
import oFetch from 'o-fetch';
import Spinner from '@/components/spinner';
import moment from 'moment';
import utils from '@/lib/utils';

import { getRollbarItems } from '../requests';

export function RollbarItemsModalContent(props) {
  const userId = oFetch(props, 'userId');
  const [fetching, setFetching] = useState(true);
  const [rollbarItems, setRollbarItems] = useState([]);

  useEffect(() => {
    (async () => {
      await getRollbarItems({
        userId,
        onSuccess(items) {
          setRollbarItems(items);
          setFetching(false);
        },
      });
    })();
  }, []);

  function getImageByFramework(framework) {
    if (framework === 'browser-js') {
      return <img src="/assets/javascript.svg" style={{ width: '32px', height: '32px' }} />;
    }
    if (framework === 'rails') {
      return <img src="/assets/rails.svg" style={{ width: '32px', height: '32px' }} />;
    }
    return framework;
  }

  if (fetching) {
    return <Spinner />;
  }

  return (
    <div className="boss-modal-window__form">
      <div className="boss-table boss-table_page_error-reports-rollbars">
        <div className="boss-table__row">
          <div className="boss-table__cell boss-table__cell_role_header">At</div>
          <div className="boss-table__cell boss-table__cell_role_header">FW</div>
          <div className="boss-table__cell boss-table__cell_role_header">Title</div>
          <div className="boss-table__cell boss-table__cell_role_header">Link</div>
        </div>
        {[...rollbarItems]
          .sort((a, b) => oFetch(b, 'last_occurrence_timestamp') - oFetch(a, 'last_occurrence_timestamp'))
          .map(item => {
            const [id, framework, counter, lastOccurrenceTimestamp, title] = oFetch(
              item,
              'id',
              'framework',
              'counter',
              'last_occurrence_timestamp',
              'title',
            );
            return (
              <div key={id} className="boss-table__row">
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <p className="boss-table__label">At</p>
                    <p className="boss-table__text">
                      <span className="boss-table__text-line">
                        {moment
                          .unix(lastOccurrenceTimestamp)
                          .format(utils.humanDateFormatWithTimeAndSeconds())}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <p className="boss-table__label">Framework</p>
                    <p className="boss-table__text">{getImageByFramework(framework)}</p>
                  </div>
                </div>
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <p className="boss-table__label">Title</p>
                    <p className="boss-table__text">{title}</p>
                  </div>
                </div>
                <div className="boss-table__cell">
                  <div className="boss-table__info">
                    <p className="boss-table__label">Rollbar Item Link</p>
                    <a
                      href={`https://app.rollbar.com/a/jsm/fix/item/Boss/${counter}`}
                      target="_blank"
                      rel="noreferrer"
                      className="boss-table__text boss-action__text"
                    >
                      Link
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
