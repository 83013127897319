import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import * as requests from '../requests';
import * as types from './types';

export const loadInitialData = createAction(types.LOAD_INITIAL_DATA);
export const addMarketingTaskToState = createAction(types.ADD_MARKETING_TASK);
export const addMarketingTaskRequestToState = createAction(types.ADD_MARKETING_TASK_REQUEST);
export const updateMarketingTaskRequestInState = createAction(types.UPDATE_MARKETING_TASK_REQUEST);
export const replaceMarketingTaskRequestInState = createAction(types.REPLACE_MARKETING_TASK_REQUEST);
export const updateMarketingTaskInState = createAction(types.UPDATE_MARKETING_TASK);
export const updateMarketingTaskDetails = createAction(types.UPDATE_MARKETING_TASK_DETAILS);
export const removeMarketingTaskFromState = createAction(types.REMOVE_MARKETING_TASK);

export const createMarketingTask = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return requests.createMarketingTask({
    values: values,
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(addMarketingTaskToState({ marketingTask, permissions }));
      onSuccess();
    },
  });
};

export const getMarketingTaskDetails = params => dispatch => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return requests.getMarketingTaskDetails({
    values: values,
    onSuccess(data) {
      const [marketingTask, marketingTaskRequests] = oFetch(data, 'marketingTask', 'marketingTaskRequests');
      dispatch(updateMarketingTaskDetails({ marketingTask, marketingTaskRequests }));
      onSuccess();
    },
  });
};

export const updateMarketingTask = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return requests.updateMarketingTask({
    values: values,
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(updateMarketingTaskInState({ marketingTask, permissions }));
      onSuccess(data);
    },
  });
};

export const removeMarketingTask = params => dispatch => {
  const [marketingTaskId, onSuccess] = oFetch(params, 'marketingTaskId', 'onSuccess');

  return requests.removeMarketingTask({
    values: {
      marketingTaskId,
    },
    onSuccess() {
      setTimeout(() => {
        dispatch(removeMarketingTaskFromState({ marketingTaskId: marketingTaskId }));
      }, 50);
      onSuccess();
    },
  });
};

export const createMarketingTaskRequest = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return requests.createMarketingTaskRequest({
    values: values,
    onSuccess(data) {
      const [marketingTaskRequest, permissions] = oFetch(data, 'marketingTaskRequest', 'permissions');
      dispatch(addMarketingTaskRequestToState({ marketingTaskRequest, permissions }));
      onSuccess(data);
    },
  });
};

export const updateMarketingTaskRequest = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const oldMarketingTaskRequestId = oFetch(values, 'requestId');
  return requests.updateMarketingTaskRequest({
    values: values,
    onSuccess(data) {
      const marketingTaskRequest = oFetch(data, 'marketingTaskRequest');
      dispatch(replaceMarketingTaskRequestInState({ marketingTaskRequest, oldMarketingTaskRequestId }));
      onSuccess(data);
    },
  });
};

export const createMarketingTaskRequestAnswer = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return requests.createMarketingTaskRequestAnswer({
    values: values,
    onSuccess(data) {
      const [marketingTaskRequest, permissions] = oFetch(data, 'marketingTaskRequest', 'permissions');
      dispatch(updateMarketingTaskRequestInState({ marketingTaskRequest, permissions }));
      onSuccess(data);
    },
  });
};

export const acceptMarketingTaskRequest = params => dispatch => {
  const values = oFetch(params, 'values');

  return requests.acceptMarketingTaskRequest({
    values: values,
    onSuccess(data) {
      const [marketingTaskRequest, permissions] = oFetch(data, 'marketingTaskRequest', 'permissions');
      dispatch(updateMarketingTaskRequestInState({ marketingTaskRequest, permissions }));
    },
  });
};

export const removeMarketingTaskRequest = params => dispatch => {
  const [taskId, requestId] = oFetch(params, 'taskId', 'requestId');

  return requests.removeMarketingTaskRequest({
    values: {
      taskId: taskId,
      requestId: requestId,
    },
    onSuccess(data) {
      const [marketingTaskRequest, permissions] = oFetch(data, 'marketingTaskRequest', 'permissions');
      dispatch(updateMarketingTaskRequestInState({ marketingTaskRequest, permissions }));
    },
  });
};

export const createMarketingTaskRequestClarification = params => dispatch => {
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');

  return requests.createMarketingTaskRequestClarification({
    values: values,
    onSuccess(data) {
      const [marketingTaskRequest, permissions] = oFetch(data, 'marketingTaskRequest', 'permissions');
      dispatch(updateMarketingTaskRequestInState({ marketingTaskRequest, permissions }));
      onSuccess(data);
    },
  });
};

export const completeMarketingTask = params => dispatch => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return requests.completeMarketingTask({
    values: values,
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(updateMarketingTaskInState({ marketingTask, permissions }));
      onSuccess();
    },
  });
};

export const acceptMarketingTask = params => dispatch => {
  const marketingTaskId = oFetch(params, 'marketingTaskId');

  return requests.acceptMarketingTask({
    values: {
      marketingTaskId,
    },
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(updateMarketingTaskInState({ marketingTask, permissions }));
    },
  });
};

export const rejectMarketingTask = params => dispatch => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return requests.rejectMarketingTask({
    values: values,
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(updateMarketingTaskInState({ marketingTask, permissions }));
      onSuccess();
    },
  });
};

export const grabMarketingTask = params => dispatch => {
  const marketingTaskId = oFetch(params, 'marketingTaskId');

  return requests.grabMarketingTask({
    values: {
      marketingTaskId,
    },
    onSuccess(data) {
      const [marketingTask, permissions] = oFetch(data, 'marketingTask', 'permissions');
      dispatch(updateMarketingTaskInState({ marketingTask, permissions }));
    },
    onFailure() {},
  });
};

export const reassingMarketingTask = params => dispatch => {
  const [values, onSuccess] = oFetch(params, 'values', 'onSuccess');

  return requests.reassingMarketingTask({
    values: values,
    onSuccess(data) {
      const [marketingTask, taskPermissions, requestsPermissions] = oFetch(
        data,
        'marketingTask',
        'taskPermissions',
        'requestsPermissions',
      );
      dispatch(
        updateMarketingTaskInState({ marketingTask, permissions: taskPermissions, requestsPermissions }),
      );
      onSuccess();
    },
    onFailure() {},
  });
};
