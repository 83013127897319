import React from 'react';
import oFetch from 'o-fetch';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { mGetTimeAsterisk } from '@/lib/rota-date';

export default function HeadDoorStaticTimeInterval(props) {
  const [sStartsAt, sEndsAt, specialSecurityPayRateName] = oFetch(
    props,
    'startsAt',
    'endsAt',
    'specialSecurityPayRateName',
  );
  const mStartsAt = safeMoment.iso8601Parse(sStartsAt);
  const formattedStartsAt = `${mGetTimeAsterisk({ mTime: mStartsAt })}${mStartsAt.format(utils.commonDateFormatTimeOnly())}`;
  const mEndsAt = safeMoment.iso8601Parse(sEndsAt);
  const formattedEndsAt = `${mGetTimeAsterisk({ mTime: mEndsAt })}${mEndsAt.format(utils.commonDateFormatTimeOnly())}`;

  return (
    <div className="boss-time-shift__group">
      <div className="boss-time-shift__time">
        <div className="boss-time-shift__interval">
          <div className="boss-time-shift__hours">
            <p className="boss-time-shift__label">
              <span className="boss-time-shift__label-text">Start</span>
            </p>
            <div className="boss-time-shift__select">
              <p className="boss-time-shift__select-value">{formattedStartsAt}</p>
            </div>
          </div>
          <div className="boss-time-shift__delimiter" />
          <div className="boss-time-shift__hours">
            <p className="boss-time-shift__label">
              <span className="boss-time-shift__label-text">End</span>
            </p>
            <div className="boss-time-shift__select">
              <p className="boss-time-shift__select-value">{formattedEndsAt}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="boss-time-shift__rate">
        <p className="boss-time-shift__label">
          <span className="boss-time-shift__label-text">Rate</span>
        </p>
        <div className="boss-time-shift__select">
          <p className="boss-time-shift__select-value">{specialSecurityPayRateName}</p>
        </div>
      </div>
    </div>
  );
}
