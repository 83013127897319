import React, { useState } from 'react';
import oFetch from 'o-fetch';
import { useModal } from '@/components/hooks-components/modals';
import cn from 'classnames';

import Dropzone, { BASE64 } from './dropzone';
import PhotoUploadResult from './photo-upload-result';
import { SimpleModal } from '../../modals';
import PhotoCropper from './photo-cropper';

export default function PhotoUploadField(props) {
  const maxSizeMB = oFetch(props, 'maxSizeMB');
  const { openModal } = useModal();
  const [input, meta] = oFetch(props, 'input', 'meta');
  const [onChange, value] = oFetch(input, 'onChange', 'value');
  const { submitError } = meta;

  function handlePhotoUpload(base64Photo) {
    openModal({
      onSubmit(closeModal, croppedPhoto) {
        closeModal();
        return onChange(croppedPhoto);
      },
      config: {
        title: 'Edit Photo',
      },
      props: {
        source: base64Photo,
      },
      ModalComponent: SimpleModal,
      ModalContent: PhotoCropper,
    });
  }

  function handlePhotoClear() {
    onChange(null);
  }

  function handleReupload() {
    handlePhotoClear();
  }

  const hasErrors = submitError && submitError.length > 0;
  const fieldClassNames = cn('purple-form-upload', { 'purple-form-upload_state_error': hasErrors });

  function renderErrors(errors) {
    return (
      <div className="purple-form-error purple-form-error_position_below">
        {errors.map((error, index) => {
          return (
            <p key={index} className="purple-form-error__text">
              {error}
            </p>
          );
        })}
      </div>
    );
  }

  return (
    <div className={fieldClassNames}>
      {!value && (
        <Dropzone onUpload={handlePhotoUpload} resultType={BASE64} maxSizeMB={maxSizeMB} />
      )}
      {value && (
        <PhotoUploadResult image={value} onReupload={handleReupload} onClear={handlePhotoClear} />
      )}
      {hasErrors && renderErrors(submitError)}
    </div>
  );
}

PhotoUploadField.defaultProps = {
  maxSizeMB: 1,
};
