import React from 'react';
import oFetch from 'o-fetch';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';

export default function Filter(props) {
  const [startDate, endDate, disabled] = oFetch(props, 'startDate', 'endDate', 'disabled');
  const [onChange, onFilterClick] = oFetch(props, 'onChange', 'onFilterClick');

  return (
    <div className="boss-board__manager-filter">
      <div className="boss-form">
        <div className="boss-form__row boss-form__row_align_center boss-form__row_hidden-l">
          <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_max">
            <p className="boss-form__label boss-form__label_type_light">
              <span className="boss-form__label-text">Filter</span>
            </p>
            <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
              <BossDateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                onApply={onChange}
                showClearDates={false}
                readOnly={disabled}
              />
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <button disabled={disabled} onClick={onFilterClick} className="boss-button boss-form__submit" type="button">
              Update
            </button>
          </div>
        </div>
        <div className="boss-form__group boss-form__group_position_last boss-form__group_visible-l">
          <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_max">
            <p className="boss-form__label boss-form__label_type_light">
              <span className="boss-form__label-text">Filter</span>
            </p>
            <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
              <BossDateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={startDate}
                endDate={endDate}
                onApply={onChange}
                showClearDates={false}
                readOnly={disabled}
              />
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <button disabled={disabled} onClick={onFilterClick} className="boss-button boss-form__submit" type="button">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
