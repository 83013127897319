import React, { Component } from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import AsyncButton from 'react-async-button';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';

export default function AlertItem(props) {
  const venueAlert = oFetch(props, 'venueAlert');
  const [title, message, createdBy, createdAt, isDisabled, disabledBy, disabledAt] = oFetch(
    venueAlert,
    'title',
    'message',
    'createdBy',
    'createdAt',
    'isDisabled',
    'disabledBy',
    'disabledAt',
  );
  const isEnabled = !isDisabled;
  const formattedCreatedAt = safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatCalendar());
  const formattedDisabledAt = isDisabled
    ? safeMoment.iso8601Parse(disabledAt).format(utils.commonDateFormatCalendar())
    : null;
  const alertClass = cn('boss-check boss-check_role_panel boss-check_page_venue-alert-levels', {
    'boss-check_state_inactive': isDisabled,
  });
  return (
    <div className={alertClass}>
      <div className="boss-check__header">
        <div className="boss-check__header-info">
          <div className="boss-check__header-group">
            <h3 className="boss-check__title">{title}</h3>
            <div className="boss-check__header-meta">
              <div className="boss-check__header-meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Created by </span>
                  {createdBy}
                  <span className="boss-check__text-light"> at </span>
                  {formattedCreatedAt}
                </p>
              </div>
              {isDisabled && (
                <div className="boss-check__header-meta-item">
                  <p className="boss-check__text">
                    <span className="boss-check__text-light">Dismissed by </span>
                    {disabledBy}
                    <span className="boss-check__text-light"> at </span>
                    {formattedDisabledAt}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="boss-check__row">
        <div className="boss-check__cell">
          <div className="boss-check__box">
            <p className="boss-check__text">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
