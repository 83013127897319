import React, { Component } from 'react';
import oFetch from 'o-fetch';

export default class UserItem extends Component {
  render() {
    const item = oFetch(this.props, 'item');
    const [avatarUrl, firstName, surname, staffType, staffTypeColor] = oFetch(
      item,
      'avatarUrl',
      'firstName',
      'surname',
      'staffType',
      'staffTypeColor',
    );
    return (
      <div className="boss-users__flow-item">
        <a href="#" className="boss-user-summary boss-user-summary_role_review-short boss-user-summary_role_link">
          <div className="boss-user-summary__side">
            <div className="boss-user-summary__avatar">
              <div className="boss-user-summary__avatar-inner">
                <img src={avatarUrl} alt="Johny Doble" className="boss-user-summary__pic" />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">
                {firstName} {surname}
              </h2>
              <p
                className="boss-button boss-button_type_label boss-user-summary__label"
                style={{ backgroundColor: staffTypeColor }}
              >
                {staffType}
              </p>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
