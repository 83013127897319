import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';

const START_DATE_FIELD_NAME = 'startDate';
const END_DATE_FIELD_NAME = 'endDate';

BossFormDaterangePicker.defaultProps = {
  label: null,
  fieldClassName: null,
};

function BossFormDaterangePicker(props) {
  const { fieldClassName, label, format } = props;
  const fieldClassNames = cn('boss-form__field', { [fieldClassName]: !!fieldClassName });

  function handleDatesChange({ startDate, endDate }) {
    if (format) {
      props[START_DATE_FIELD_NAME].input.onChange(startDate ? startDate.format(format) : startDate);
      props[END_DATE_FIELD_NAME].input.onChange(endDate ? endDate.format(format) : endDate);
    } else {
      props[START_DATE_FIELD_NAME].input.onChange(startDate);
      props[END_DATE_FIELD_NAME].input.onChange(endDate);
    }
  }

  function getDateValue(value) {
    if (format) {
      return value ? moment(value, format, true) : value;
    } else {
      return value;
    }
  }

  const startDate = props[START_DATE_FIELD_NAME];
  const endDate = props[END_DATE_FIELD_NAME];

  if (!startDate || !endDate) {
    throw new Error(
      `BossFormDaterangePicker: filed names '${START_DATE_FIELD_NAME}' & '${END_DATE_FIELD_NAME}' for the ReduxForm Fields component must be present.`,
    );
  }

  return (
    <div className={fieldClassNames}>
      {label && (
        <p className="boss-form__label">
          <span className="boss-form__label-text">{label}</span>
        </p>
      )}
      <div className="date-control date-control_type_icon date-control_type_interval-fluid date-control_adjust_third">
        <BossDateRangePicker
          startDateId="startDateId"
          endDateId="endDateId"
          startDate={getDateValue(startDate.input.value) || null}
          endDate={getDateValue(endDate.input.value) || null}
          onApply={handleDatesChange}
        />
      </div>
    </div>
  );
}

export default BossFormDaterangePicker;
