import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';

export default function CategoryItem(props) {
  const category = oFetch(props, 'category');
  const { renderActions } = props;

  const [
    name,
    color,
    completePercentage,
    rankName,
    multiplier,
    lastCompletedAt,
    allQuizzesCount,
    passedQuizzesCount,
    failedQuizzesCount,
    passedQuizzesPercentage,
    failedQuizzesPercentage,
    rankMainIconUrl,
    effectiveFirstTimeDeadline,
    effectiveCompletionDeadline,
    isAlertState,
    remainingQuestions,
    numberOfCompletions,
  ] = oFetch(
    category,
    'name',
    'color',
    'completePercentage',
    'rankName',
    'multiplier',
    'lastCompletedAt',
    'allQuizzesCount',
    'passedQuizzesCount',
    'failedQuizzesCount',
    'passedQuizzesPercentage',
    'failedQuizzesPercentage',
    'rankMainIconUrl',
    'effectiveFirstTimeDeadline',
    'effectiveCompletionDeadline',
    'isAlertState',
    'remainingQuestions',
    'numberOfCompletions',
  );

  const effectiveDeadline = effectiveFirstTimeDeadline || effectiveCompletionDeadline;
  const mEffectiveDeadline = effectiveDeadline && safeMoment.iso8601Parse(effectiveDeadline);
  const mLastCompletedAt = lastCompletedAt && safeMoment.iso8601Parse(lastCompletedAt);

  const categoryClassNames = cn('boss-check boss-check_role_panel boss-check_page_smp-quizzes-category', {
    'boss-check_state_alert': isAlertState,
  });

  const deadlineClassNames = cn('boss-check__text-marked', {
    'boss-check__text_role_alert': isAlertState,
  });

  return (
    <div className={categoryClassNames}>
      <div className="boss-check__header">
        <h3 className="boss-check__title">{name}</h3>
        <div className="boss-check__header-badge">
          <div className="boss-indicator">
            <img className="boss-indicator__badge_size_xs" src={rankMainIconUrl} alt="Rank icon" />
            <span className="boss-indicator__label boss-indicator__label_type_regular boss-indicator__label_size_s boss-indicator__label_position_after">
              {rankName} <span className="boss-indicator__label-faded"> x{multiplier}</span>
            </span>
          </div>
        </div>
        {renderActions(category)}
      </div>
      <div className="boss-check__content">
        <div className="boss-check__group">
          <div className="boss-check__group-content">
            <div className="boss-check__group-area">
              <div className="boss-progress-bar">
                <div className="boss-progress-bar__content">
                  <p className="boss-progress-bar__label boss-progress-bar__label_position_before boss-progress-bar__label_type_light">
                    Category Completion
                  </p>
                  <div className="boss-progress-bar__meter">
                    <div
                      className="boss-progress-bar__value"
                      style={{ width: `${completePercentage * 100}%`, backgroundColor: color }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="boss-check__meta">
              <div className="boss-check__meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Time Remaining: </span>
                  <span className={deadlineClassNames}>
                    {mEffectiveDeadline ? utils.formatDeadline(mEffectiveDeadline) : 'n/a'}
                  </span>
                </p>
              </div>
              <div className="boss-check__meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Remaining Questions: </span>
                  <span className="boss-check__text-marked">{remainingQuestions}</span>
                </p>
              </div>
              <div className="boss-check__meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Number of completions: </span>
                  <span className="boss-check__text-marked">{numberOfCompletions}</span>
                </p>
              </div>
              <div className="boss-check__meta-item">
                <p className="boss-check__text">
                  <span className="boss-check__text-light">Last completed at: </span>
                  <span className="boss-check__text-marked">
                    {mLastCompletedAt ? mLastCompletedAt.format(utils.humanDateFormatWithDayOfWeek()) : 'n/a'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="boss-check__row boss-check__row_no-border">
          <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_delimiter_light">
            <div className="boss-number boss-number_layout_fluid">
              <div className="boss-number__content">
                <h3 className="boss-number__value">{allQuizzesCount}</h3>
                <p className="boss-number__label boss-number__label_position_after">Quizzes Taken</p>
              </div>
            </div>
          </div>
          <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_delimiter_light">
            <div className="boss-number boss-number_layout_fluid">
              <div className="boss-number__content">
                <h3 className="boss-number__value">{passedQuizzesCount}</h3>
                <p className="boss-number__label boss-number__label_position_after">
                  Quizzes Passed <span className="boss-number__label-faded">({passedQuizzesPercentage}%)</span>
                </p>
              </div>
            </div>
          </div>
          <div className="boss-check__cell boss-check__cell_size_fourth boss-check__cell_delimiter_light">
            <div className="boss-number boss-number_layout_fluid">
              <div className="boss-number__content">
                <h3 className="boss-number__value">{failedQuizzesCount}</h3>
                <p className="boss-number__label boss-number__label_position_after">
                  Quizzes Failed <span className="boss-number__label-faded">({failedQuizzesPercentage}%)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
