import React, { Component } from 'react';
import oFetch from 'o-fetch';
import AssignedStaffType from './assigned-staff-type';

export default class AssignedStaffTypes extends Component {
  render() {
    const [staffTypes, onDelete] = oFetch(this.props, 'staffTypes', 'onDelete');
    if (staffTypes.length === 0) {
      return (
        <div className="boss-board__group">
          <h3>No staff types assigned</h3>
        </div>
      );
    }

    return (
      <div className="boss-board__group">
        {staffTypes.map(staffType => {
          const staffTypeId = oFetch(staffType, 'id');
          return <AssignedStaffType key={staffTypeId} staffType={staffType} onDelete={onDelete} />;
        })}
      </div>
    );
  }
}
