import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import staticReducer from './static';
import flaggedStaffMembers from './flagged-staff-members';
import steps from './steps';

export default combineReducers({
  form: formReducer,
  static: staticReducer,
  flaggedStaffMembers,
  steps,
});
