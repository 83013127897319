import React from 'react';
import { Collapse } from 'react-collapse';
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import { ReportTableHeader } from './report-table-header';
import { formatAsCurrency } from '@/lib/currency-helpers';
import { MossFinanceReport, StaffTypeSectionData } from '../types';
import { MossFinanceReportItem } from './moss-finance-report-item';
import { useModal } from '@/components/hooks-components/modals';
import { CloseInsideModalWrapper } from '@/components/modal-wrappers/close-inside-modal-wrapper';
import { NoteForm, OnSubmitValues } from './note-form';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  mouseWheel: false,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  // prevent stop scrolling after scroll ends
  enable_ofscroll: true,
} as const;

type StaffTypeSectionProps = {
  venueId: number;
  startDate: Date;
  endDate: Date;
  weekDates: Date[];
  staffTypeSectionData: StaffTypeSectionData;
};

export function StaffTypeSection(props: StaffTypeSectionProps): JSX.Element {
  const { openModal } = useModal();
  const [isOpened, setIsOpened] = React.useState(true);
  const updateMossFinanceReportNote = props.staffTypeSectionData.updateMossFinanceReportNote;

  function toggleDropDown(): void {
    setIsOpened(isOpened => !isOpened);
  }

  function openUpdateNoteModal(args: { mossFinanceReport: MossFinanceReport, edit: boolean }): void {
    const { mossFinanceReport, edit } = args;
    const { note, mossStaffMemberId } = mossFinanceReport;

    return openModal({
      ModalComponent: CloseInsideModalWrapper,
      ModalContent: NoteForm,
      onSubmit: (closeModal: () => void, values: OnSubmitValues) => {
        return updateMossFinanceReportNote({
          mossStaffMemberId,
          note: values.note,
          date: props.startDate,
          venueId: props.venueId,
          onSuccess() {
            closeModal();
          },
          onFailure() {
            closeModal();
          },
        });
      },
      config: {
        title: edit ? 'Edit Note' : 'Add Note',
      },
      props: {
        note: note || '',
        edit: edit,
      },
    });
  }


  function renderItems(): JSX.Element[] {
    return props.staffTypeSectionData.mossFinanceReports.map((mossFinanceReport) => {
      return (
        <MossFinanceReportItem
          key={`mossFinanceReport:${mossFinanceReport.id}`}
          mossFinanceReport={mossFinanceReport}
          startDate={props.startDate}
          endDate={props.endDate}
          weekDates={props.weekDates}
          onNoteClick={openUpdateNoteModal}
        />
      );
    });
  }

  const staffTypeUiColor  = props.staffTypeSectionData.uiColor;
  const staffTypeName = props.staffTypeSectionData.name;
  const totalCents = props.staffTypeSectionData.totalCents;

  return (
    <section className="boss-board boss-board_context_stack boss-board_role_finance-report">
      <header className="boss-board__header">
        <h2 className="boss-board__title boss-board__title_size_medium">
          <span
            className="boss-button boss-button_type_small boss-button_type_no-behavior boss-board__title-label"
            style={{ backgroundColor: `#${staffTypeUiColor}` }}
          >
            {staffTypeName}
          </span>
          <span className="boss-board__title-text">
            Total: <span className="boss-board__title-text-marked">{ formatAsCurrency({ cents: totalCents }) }</span>
          </span>
        </h2>
        <div className="boss-board__button-group">
          <button
            type="button"
            className={`boss-board__switch ${isOpened ? 'boss-board__switch_state_opened' : ''}`}
            onClick={toggleDropDown}
          />
        </div>
      </header>
      <Collapse
        isOpened={isOpened}
        className={`boss-board__content ${isOpened ? 'boss-board__content_state_opened' : ''}`}
        style={{ display: isOpened ? 'block' : 'none' }}
      >
        <div className="boss-board__content-inner">
          <div className="boss-board__table">
            <ReactIScroll
              iScroll={iScroll}
              options={scrollOptions}
              className="boss-board__scroll boss-board__scroll_type_native"
            >
              <div className="boss-board__scroll-content">
                <div className="boss-table boss-table_page_finance-reports">
                  <ReportTableHeader
                    staffTypeName={staffTypeName}
                    startDate={props.startDate}
                  />
                  {renderItems()}
                </div>
              </div>
            </ReactIScroll>
          </div>
        </div>
      </Collapse>
    </section>
  );
}