import { combineReducers } from 'redux';

import { marketingTasks } from './marketing-tasks';
import { marketingTaskRequests } from './marketing-task-requests';
import { staticReducer } from './static-reducer';
import { permissionsReducer } from './permissions-reducer';

export default combineReducers({
  marketingTasks,
  marketingTaskRequests,
  staticReducer,
  permissions: permissionsReducer,
});
