import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import oFetch from 'o-fetch';
import cn from 'classnames';
import utils from '@/lib/utils';

CollapsibleOverviewCard.defaultProps = {
  className: null,
  defaultOpen: false,
};

export default function CollapsibleOverviewCard(props) {
  const [title, children, className, defaultOpen] = oFetch(
    props,
    'title',
    'children',
    'className',
    'defaultOpen',
  );
  const [isOpen, setOpenState] = useState(defaultOpen);
  const arrowClassNames = cn('boss-overview__dropdown-icon', {
    'boss-overview__dropdown-icon_state_closed': !isOpen,
  });
  const cardClassNames = cn('boss-overview__dropdown boss-overview__dropdown_context_accordion', {
    [className]: !!className,
  });

  function toggleCard() {
    setOpenState(!isOpen);
  }

  function renderTitle() {
    if (utils.isFunction(title)) {
      return title();
    }
    return (
      <h2 className="boss-board__title boss-board__title_size_medium">
        <span className="boss-board__title-text">{title}</span>
      </h2>
    );
  }

  return (
    <div className={cardClassNames}>
      <div className="boss-overview__dropdown-header">
        {renderTitle()}
        <div onClick={toggleCard} className={arrowClassNames} />
      </div>
      <Collapse isOpened={isOpen} style={{ display: 'block' }}>
        <div className="boss-overview__dropdown-content">{children}</div>
      </Collapse>
    </div>
  );
}
