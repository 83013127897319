import React from 'react';
import oFetch from 'o-fetch';
import { appRoutes } from '@/lib/legacy-routes';

import { ResultJson } from './result-json';

export function MatchedStaffMemberItem(props) {
  const [staffMember, faceMatch] = oFetch(props, 'staffMember', 'faceMatch');
  const [
    id,
    avatarUrl,
    fullName,
    staffTypeColor,
    staffTypeName,
    masterVenue,
    disabled,
    workableVenues,
  ] = oFetch(
    staffMember,
    'id',
    'avatarUrl',
    'fullName',
    'staffTypeColor',
    'staffTypeName',
    'masterVenue',
    'disabled',
    'workableVenues',
  );

  return (
    <div style={{ borderBottom: '1px solid #eee' }}>
      <div className="boss-table__row" style={{ borderBottom: 'none' }}>
        <div className="boss-table__cell">
          <div className="boss-table__image">
            <div className="boss-table__link">
              <div className="boss-avatar boss-avatar_type_combined boss-avatar_type_scannable">
                <img src={avatarUrl} className="boss-avatar__image" />
              </div>
            </div>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Name</p>
            <p className="boss-table__text">
              <a
                target="_blank"
                rel="noreferrer"
                href={appRoutes.staffMemberProfile(id)}
                className="boss-table__link"
              >
                {fullName}
              </a>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Status</p>
            <p className="boss-table__text">
              {disabled && (
                <button className="boss-button boss-button_type_small boss-button_role_disabled boss-button_type_no-behavior">
                  Disabled
                </button>
              )}
              {!disabled && (
                <button className="boss-button boss-button_type_small boss-button_role_enabled boss-button_type_no-behavior">
                  Enabled
                </button>
              )}
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Type</p>
            <p className="boss-table__text">
              <span
                className="boss-button boss-button_type_small boss-button_type_no-behavior boss-user-summary__label"
                style={{ backgroundColor: staffTypeColor }}
              >
                {staffTypeName}
              </span>
            </p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Master Venue</p>
            <p className="boss-table__text">{masterVenue}</p>
          </div>
        </div>
        <div className="boss-table__cell">
          <div className="boss-table__info">
            <p className="boss-table__label">Work Venues</p>
            <p className="boss-table__text">{workableVenues}</p>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <ResultJson json={faceMatch} title={'Face Match'} />
      </div>
    </div>
  );
}
